export const repaymentFields = state => [
  {
    resultState: state.loanAppIdState,
    valueState: state.loanAppId,
    placeholder: "loan_app_id",
    name: "loanAppId",
    type: "text",
    component: "span",
    condition: "",
    errorMsg: "Enter valid loan app id.",
    value: 1,
    v2: false
  },
  {
    resultState: state.loanIdState,
    valueState: state.loanId,
    placeholder: "loan_id",
    name: "loanId",
    type: "text",
    component: "span",
    condition: "",
    errorMsg: "Enter valid loan id.",
    value: 1,
    v2: true
  },
  {
    resultState: state.borrowerIdState,
    valueState: state.borrowerId,
    placeholder: "borrower_id",
    name: "borrowerId",
    type: "text",
    component: "span",
    condition: "",
    errorMsg: "Enter valid Borrower id.",
    value: 1,
    v2: false
  },
  {
    resultState: state.partnerLoanAppIdState,
    valueState: state.partnerLoanAppId,
    placeholder: "partner_loan_app_id",
    name: "partnerLoanAppId",
    type: "text",
    component: "span",
    condition: "",
    errorMsg: "Enter valid partner loan app id.",
    value: 1,
    v2: false
  },
  {
    resultState: state.partnerLoanIdState,
    valueState: state.partnerLoanId,
    placeholder: "partner_loan_id",
    name: "partnerLoanId",
    type: "text",
    component: "span",
    condition: "",
    errorMsg: "Enter valid partner loan id.",
    value: 1,
    v2: true
  },
  {
    resultState: state.partnerBorrowerIdState,
    valueState: state.partnerBorrowerId,
    placeholder: "partner_borrower_id",
    name: "partnerBorrowerId",
    type: "text",
    component: "span",
    condition: "",
    errorMsg: "Enter valid partner borrower id.",
    value: 1,
    v2: false
  },
  {
    resultState: state.txnAmountState,
    valueState: state.txnAmount,
    placeholder: "txn_Amount",
    name: "txnAmount",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid txn amount",
    value: 1,
    v2: true
  },
  {
    resultState: state.txnReferenceState,
    valueState: state.txnReference,
    placeholder: "txn_reference",
    name: "txnReference",
    type: "text",
    component: "input",
    condition: "alnum",
    errorMsg: "Enter valid txn reference",
    value: 1,
    v2: true
  },
  {
    resultState: state.labelState,
    valueState: state.label,
    placeholder: "label",
    name: "label",
    type: "text",
    component: "input",
    condition: "alpha",
    errorMsg: "Enter valid label",
    value: 1,
    v2: true
  },
  {
    resultState: state.partPaymentSelected,
    valueState: state.reduce,
    placeholder: "reduce",
    name: "reduce",
    type: "text",
    component: "input",
    condition: "alpha",
    errorMsg: "Enter valid reduce",
    value: 1,
    v2: state.partPaymentSelected
  },
  {
    resultState: state.disbursementStatusState,
    valueState: state.disbursementStatus,
    placeholder: "disbursement_status",
    name: "disbursementStatus",
    type: "text",
    component: "input",
    condition: "alpha",
    errorMsg: "Enter valid disbursement status",
    value: 1,
    v2: false
  },
  {
    resultState: state.utrNumberState,
    valueState: state.utrNumber,
    placeholder: "utr_number",
    name: "utrNumber",
    type: "text",
    component: "input",
    condition: "alnum",
    errorMsg: "Enter valid utr number",
    value: 1,
    v2: true
  },
  {
    resultState: state.utrDateTimeStampState,
    valueState: state.utrDateTimeStamp,
    placeholder: "utr_date_time_stamp",
    name: "utrDateTimeStamp",
    type: "text",
    component: "input",
    condition: "date",
    errorMsg: "Enter valid utr date time stamp in YYYY-MM-DD format.",
    value: 1,
    v2: true
  },
  {
    resultState: state.recordMethodState,
    valueState: state.recordMethod,
    placeholder: "record_method",
    name: "recordMethod",
    type: "text",
    component: "input",
    condition: "alpha",
    errorMsg: "Enter valid record method",
    value: 1,
    v2: false
  },
  {
    resultState: state.repaymentDueAmountState,
    valueState: state.repaymentDueAmount,
    placeholder: "repayment_due_amount",
    name: "repaymentDueAmount",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid repayment due amount",
    value: 1,
    v2: false
  },
  {
    resultState: state.emiNumberState,
    valueState: state.emiNumber,
    placeholder: "emi_number",
    name: "emiNumber",
    type: "text",
    component: "input",
    condition: "number",
    errorMsg: "Enter valid emi number",
    value: 1,
    v2: false
  },
  {
    resultState: state.emiTypeState,
    valueState: state.emiType,
    placeholder: "emi_type",
    name: "emiType",
    type: "text",
    component: "input",
    condition: "alpha",
    errorMsg: "Enter valid emi type",
    value: 1,
    v2: false
  },
  {
    resultState: state.paidByState,
    valueState: state.paidBy,
    placeholder: "paid_by",
    name: "paidBy",
    type: "text",
    component: "input",
    condition: "alnumname",
    errorMsg: "Enter valid paid by",
    value: 1,
    v2: false
  },
  {
    resultState: state.txnReferenceDatetimeState,
    valueState: state.txnReferenceDatetime,
    placeholder: "txn_reference_datetime",
    name: "txnReferenceDatetime",
    type: "text",
    component: "input",
    condition: "date",
    errorMsg: "Enter valid txn reference datetime in YYYY-MM-DD format.",
    value: 1,
    v2: true
  },
  {
    resultState: state.principalAmountState,
    valueState: state.principalAmount,
    placeholder: "principal_amount",
    name: "principalAmount",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid principal amount",
    value: 1,
    v2: false
  },
  {
    resultState: state.paymentModeState,
    valueState: state.paymentMode,
    placeholder: "payment_mode",
    name: "paymentMode",
    type: "text",
    component: "input",
    condition: "alpha",
    errorMsg: "Enter valid payment mode",
    value: 1,
    v2: true
  },
  {
    resultState: state.principalPaidAmountState,
    valueState: state.principalPaidAmount,
    placeholder: "principal_paid_amount",
    name: "principalPaidAmount",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid principal paid amount",
    value: 1,
    v2: false
  },
  {
    resultState: state.repaymentTagState,
    valueState: state.repaymentTag,
    placeholder: "repayment_tag",
    name: "repaymentTag",
    type: "text",
    component: "input",
    condition: "alnumname",
    errorMsg: "Enter valid repayment tag",
    value: 1,
    v2: false
  },
  {
    resultState: state.principalDueAmountState,
    valueState: state.principalDueAmount,
    placeholder: "principal_due_amount",
    name: "principalDueAmount",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid principal paid amount",
    value: 1,
    v2: false
  },
  {
    resultState: state.interestDueAmountState,
    valueState: state.interestDueAmount,
    placeholder: "interest_due_amount",
    name: "interestDueAmount",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid principal paid amount",
    value: 1,
    v2: false
  },
  {
    resultState: state.repaymentDueDateState,
    valueState: state.repaymentDueDate,
    placeholder: "repayment_due_date",
    name: "repaymentDueDate",
    type: "text",
    component: "input",
    condition: "date",
    errorMsg: "Enter valid repayment due date in YYYY-MM-DD format.",
    value: 1,
    v2: false
  },
  {
    resultState: state.interestPaidAmountState,
    valueState: state.interestPaidAmount,
    placeholder: "interest_paid_amount",
    name: "interestPaidAmount",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid intrest paid amount",
    value: 1,
    v2: false
  },
  {
    resultState: state.additionalChargesPaidState,
    valueState: state.additionalChargesPaid,
    placeholder: "additional_charge_paid",
    name: "additionalChargesPaid",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid additional charge paid",
    value: 1,
    v2: false
  },
  {
    resultState: state.usageIdState,
    valueState: state.usageId,
    placeholder: "usage_id",
    name: "usageId",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid usage id",
    value: 1,
    v2: true
  },
  {
    resultState: state.tdsAmountState,
    valueState: state.tdsAmount,
    placeholder: "tds_amount",
    name: "tdsAmount",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid tds amount",
    value: 1,
    v2: true
  },
  {
    resultState: state.amountNetOfTdsState,
    valueState: state.amountNetOfTds,
    placeholder: "amount_net_of_tds",
    name: "amountNetOfTds",
    type: "text",
    component: "input",
    condition: "float",
    errorMsg: "Enter valid amount net of tds",
    value: 1,
    v2: true,
    disabled: true
  }
];
