import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function getDDRpsReportRequest(payload) {
  return axios.get(`${BASE_URL}dd-rps-report/${payload.loan_id}/${payload.usage_id}/${payload.product_id}/${payload.company_id}`, payload);
}

export function getPrepaymentDetailsRequest(payload) {
  return axios.get(`${BASE_URL}dd-prepayment/${payload.loan_id}/${payload.usage_id}/${payload.product_id}/${payload.company_id}/${payload.pre_payment_date}`, payload);
}
