import React, { memo, useEffect, useMemo, useState } from 'react';
import Table from 'react-sdk/dist/components/Table';
import DataUploadProgressBarUI from '../DataUploadProgressBarUI/DataUploadProgressBarUI';
import { convertTimeOfAttemptDateFormat, findDataUploadStatusColor } from '../LoanDataUploadStatus.helpers';
import LoanDataUploadAction from '../LoanDataUploadAction/LoanDataUploadAction';
import { useDispatch } from 'react-redux';
import { getLoanUploadStatus } from 'actions/loanUploadDataStatus';
import { getRefineLoanDataStatus } from 'actions/loanUploadDataStatus';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';

const LoanDataStatusDashboard = (props) => {
  const dispatch = useDispatch();
  const { searchValue, callApi, caseType } = props;
  const [loanUploadUserData, setLoanUploadUserData] = useState([]);
  const [refineLoanUploadUserData, setRefineLoanUploadUserData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [refinePageNumber, setRefinePageNumber] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [refineTotalResults, setRefineTotalResults] = useState(0);
  const [pageLimit, setPageLimit] = useState(0);
  const [refinePageLimit, setRefinePageLimit] = useState(0);

  const handleChangePage = (event) => {
    setPageNumber(event + 1);
  };

  const handleRefineResultsChangePage = (event) => {
    setRefinePageNumber(event + 1);
  };

  const fetchRefineLoanUploadUserData = async () => {
    new Promise((resolve, reject) => {
      const data = {
        uploadType: caseType === 'loan upload' ? 'New' : caseType === 'loan update' ? 'Update' : 'Payment',
        searchValue: searchValue,
        pageNumber: pageNumber.toString(),
      };
      dispatch(getRefineLoanDataStatus(data, resolve, reject));
    })
      .then((response) => {
        setRefineLoanUploadUserData(response?.data?.results);
        setRefineTotalResults(response?.data?.totalResults);
        setRefinePageLimit(response?.data?.limit);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };

  const fetchLoanUploadUserData = async () => {
    new Promise((resolve, reject) => {
      const data = {
        uploadType: caseType === 'loan upload' ? 'New' : caseType === 'loan update' ? 'Update' : 'Payment',
        pageNumber: pageNumber.toString(),
      };
      dispatch(getLoanUploadStatus(data, resolve, reject));
    })
      .then((response) => {
        setLoanUploadUserData(response?.data?.results);
        setTotalResults(response?.data?.totalResults);
        setPageLimit(response?.data?.limit);
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    fetchLoanUploadUserData();
  }, [pageNumber, caseType]);

  useEffect(() => {
    fetchRefineLoanUploadUserData();
  }, [callApi, refinePageNumber, caseType]);

  const caseList = loanUploadUserData.map((user, index) => {
    return {
      id: (pageNumber - 1) * pageLimit + (index + 1),
      file_name: user?.file_name,
      created_by: user?.created_by,
      status_model: <DataUploadProgressBarUI totalCount={user?.total_loans} failureCount={user?.failure_count} pendingCount={user?.total_loans - user?.processed_loans - user?.failure_count} successCount={user?.processed_loans} dataUploadColorValue={findDataUploadStatusColor(user.total_loans, user.processed_loans, user.failure_count, user.total_loans - user.processed_loans)} />,
      failure_count: user?.failure_count,
      duration: user?.duration,
      created_date: convertTimeOfAttemptDateFormat(user?.created_date),
      action: <LoanDataUploadAction _id={user?._id} file_name={user?.file_name} />,
    };
  });

  const caseLists = refineLoanUploadUserData.map((user, index) => {
    return {
      id: (refinePageNumber - 1) * refinePageLimit + (index + 1),
      file_name: user?.file_name,
      created_by: user?.created_by,
      status_model: <DataUploadProgressBarUI totalCount={user?.total_loans} failureCount={user?.failure_count} pendingCount={user?.total_loans - user?.processed_loans - user?.failure_count} successCount={user?.processed_loans} dataUploadColorValue={findDataUploadStatusColor(user.total_loans, user.processed_loans, user.failure_count, user.total_loans - user.processed_loans)} />,
      failure_count: user?.failure_count,
      duration: user?.duration,
      created_date: convertTimeOfAttemptDateFormat(user?.created_date),
      action: <LoanDataUploadAction _id={user?._id} />,
    };
  });

  return (
    <React.Fragment>
      <Table
        columns={[
          { id: 'id', label: 'SR NO.' },
          { id: 'file_name', label: 'File Name' },
          { id: 'created_by', label: 'Author' },
          { id: 'status_model', label: 'Status' },
          { id: 'failure_count', label: 'Failure Count' },
          { id: 'duration', label: 'Duration' },
          { id: 'created_date', label: 'Time Of Attempt' },
          { id: 'action', label: 'Action' },
        ]}
        data={caseLists.length > 0 ? caseLists : caseLists.length <= 0 && callApi ? [] : caseList}
        columnStyles={{
          color: '#1C1C1C',
          fontFamily: 'Montserrat-Regular',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
        }}
        customStyle={{
          width: '100%',
          display: 'grid',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
          gridTemplateColumns: '8% 12% 12% 12% 12% 12% 16% 15%',
          height: '5em',
        }}
      />
      {caseLists.length > 0 ? <Pagination onPageChange={handleRefineResultsChangePage} totalItems={refineTotalResults} itemsPerPage={10} goToPage={refinePageNumber} /> : caseLists.length <= 0 && searchValue ? null : <Pagination onPageChange={handleChangePage} totalItems={totalResults} itemsPerPage={10} goToPage={pageNumber} />}
    </React.Fragment>
  );
};

export default memo(LoanDataStatusDashboard);
