export const FOS_USER_FORM_FIELDS = {
  collection_agency_id: {
    label: 'Collection Agency Name',
    name: 'collection_agency_id',
    id: 'collection_agency_id',
    type: 'text',
    placeholder: 'Collection Agency Name',
    isRequired: true,
  },
  name: {
    label: 'Name',
    name: 'name',
    id: 'name',
    type: 'text',
    placeholder: 'Name',
    isRequired: true,
  },
  email: {
    label: 'Email ID',
    name: 'email',
    id: 'email',
    type: 'email',
    placeholder: 'Email ID',
    isRequired: true,
  },
  mobile: {
    label: 'Mobile Number',
    name: 'mobile',
    id: 'mobile',
    type: 'number',
    placeholder: 'Mobile Number',
    isRequired: true,
  },
  address_line_1: {
    label: 'Address 1',
    name: 'address_line_1',
    id: 'address_line_1',
    type: 'text',
    placeholder: 'Address 1',
    isRequired: true,
  },
  address_line_2: {
    label: 'Address 2',
    name: 'address_line_2',
    id: 'address_line_2',
    type: 'text',
    placeholder: 'Address 2',
    isRequired: false,
  },
  pincode: {
    label: 'Pincode',
    name: 'pincode',
    id: 'pincode',
    type: 'number',
    placeholder: 'Pincode',
    isRequired: true,
  },
  city: {
    label: 'City',
    name: 'city',
    id: 'city',
    type: 'text',
    placeholder: 'City',
    isRequired: true,
  },
  district: {
    label: 'District',
    name: 'district',
    id: 'district',
    type: 'text',
    placeholder: 'District',
    isRequired: true,
  },
  state: {
    label: 'State',
    name: 'state',
    id: 'state',
    type: 'text',
    placeholder: 'State',
    isRequired: true,
  },
};
