import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Preloader from '../../../../../../components/custom/preLoader.jsx';
import UploadCard from 'react-sdk/dist/components/UploadCard';
import ViewDocumentCustom from 'lap/components/document/ViewDocumentCustom.js';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import { getLapLeadDocumentWatcher } from 'lap/actions/lead.action.js';
import { uploadLoanDocumentsWatcher } from 'actions/loanDocuments';
import { storedList } from '../../../../../../util/localstorage.js';
import { capitalizeFirstLetter } from '../../../../../utils/helper.js';
import { EXCEPT_OPTIONAL_STR } from '../../../../../constants/pattern_constants.js';
import UPLOAD_FILE_INPUT_CONST from '../../../../../components/uploadFileInput/helper.js';

const { DOC_CODES_FOR_10MB_LIMIT, FILE_LIMITS, FILE_TYPE, SIZE, MAGIC_NUMBERS, ERROR, ONLY_XML_OR_JSON_ALERT, ONLY_IMG_FILES_OR_PDF_ALERT, FILE_SIZE_ERROR_PREFIX, ERROR_DELETING_FILE } = UPLOAD_FILE_INPUT_CONST;

import {
  // Applicant section document
  KYCDocumentPANTittle,
  KYCDocumentSelfieTittle,
  KYCDocumentPanXMLTittle,
  KYCAadharFrontTitle,
  KYCAadharBackTitle,
  AadharXML,
  ScreeningReport,
  BureauReport,
  // Entity section document
  URC_Certificate,
  GSTIN,
  Shop_Establishment_Certificate,
  Entity_KYC_Partnership_Upload,
  Entity_KYC_Authority_Letter_Upload,
  Entity_LOD_Upload,
  Entity_AOA_Upload,
  Entity_COI_Upload,
  Entity_MOA_Upload,
  // Co borrower section document
  CoBorrowerSelfie,
  CoBorrowerPanImage,
  CoBorrowerPanXml,
  CoBorrowerKYCAadharFrontTitle,
  CoBorrowerKYCAadharBackTitle,
  CoBorrowerKYCAadharBackXml,
  // Guarantor section document
  GuarantorSelfie,
  Guarantor_PanTittle,
  AadharImage,
  Guarantor_PanInputTittleXML,
  Guarantor_AadharInputTittleXML,
  // Share holding pattern section document
  SHAREHOLDING,
  // Property section document
  Property_Ownership_Proof,
  Property_Ownership_Report,
  Property_Legal_Report_Upload,
  Property_Valuation_Report_Upload,
  // Financial section document
  Financial_Documents,
  // Addtional section document
  Additional_Documents,
} from '../../../../bookLoans/uploadKycData.js';

import { Document_Sections } from '../../../../../constants/string_constants.js';

import { extractDocumentCode, generateArrayWithKey } from '../../../../../utils/helper.js';
import { Number_Constants } from 'lap/constants/number_constants.js';
function DocumentDetailsTab(props) {
  const styles = useStyles({ innerWidth, innerHeight });
  const { loanAppId: loan_app_id } = props;
  const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
  // #region view document states
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [documentViewData, setDocumentViewData] = useState({});
  const [documentUploadState, setDocumentUploadState] = useState({ sectionName: '', type: '', items: [] });
  const inputFileRef = React.useRef(null);
  // #endregion view document states
  // #region alert states
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  // #endregion
  const [leadDocuments, setLeadDocuments] = useState([]);
  const { Applicant, Entity, CoBorrower, Guarantor, Shareholding, Property, Financial, AdditionalDocuments } = Document_Sections;
  const allDocumentsItems = [
    // Applicant section document
    KYCDocumentPANTittle,
    KYCAadharFrontTitle,
    KYCAadharBackTitle,
    KYCDocumentSelfieTittle,
    ScreeningReport,
    BureauReport,
    KYCDocumentPanXMLTittle,
    AadharXML,
    // Entity section document
    URC_Certificate,
    GSTIN,
    Shop_Establishment_Certificate,
    Entity_KYC_Partnership_Upload,
    Entity_KYC_Authority_Letter_Upload,
    Entity_LOD_Upload,
    Entity_AOA_Upload,
    Entity_COI_Upload,
    Entity_MOA_Upload,
    // Co borrower section document
    CoBorrowerSelfie,
    CoBorrowerPanImage,
    CoBorrowerPanXml,
    CoBorrowerKYCAadharFrontTitle,
    CoBorrowerKYCAadharBackTitle,
    CoBorrowerKYCAadharBackXml,
    // Guarantor section document
    GuarantorSelfie,
    Guarantor_PanTittle,
    AadharImage,
    Guarantor_PanInputTittleXML,
    Guarantor_AadharInputTittleXML,
    // Share holding pattern section document
    SHAREHOLDING,
    // Property section document
    Property_Ownership_Proof,
    Property_Ownership_Report,
    Property_Valuation_Report_Upload,
    Property_Legal_Report_Upload,
    // Financial section document
    Financial_Documents,
    // Addtional section document
    Additional_Documents,
  ].flat();
  // #region Get document list on mount
  useEffect(() => {
    fetchDocumentList();
  }, []);
  // #endregion
  // #region Fetch document list and formatting the document list to render
  function fetchDocumentList() {
    // NAVRATTAN-1747750166995
    let payload = {
      // 'NAVRATTAN-1747750166995'
      loan_app_id,
      company_id: 557,
      product_id: 1035,
      user,
    };

    new Promise((resolve, reject) => {
      dispatch(getLapLeadDocumentWatcher(payload, resolve, reject));
    })
      .then((response) => {
        formatFetchDocumentList(response);
      })
      .catch((err) => {
        console.log({ err });
      });
  }
  function formatFetchDocumentList(documents) {
    /*
    Formatting document to feed the list to render documents as per in mock data
    [{
      "title": "Applicant",
      "docs": [
        {
          "_id": {
            "code": 1
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 1,
          "file_type": "pan_card",
          "name": "PAN"
        },
        title: 'co-borrower-1',
        docs: [

        ]
      ]
    }]
    */
    const documentList = documents;
    let documentListBySection = [];
    let Applicant_Document_Code = extractDocumentCode([KYCDocumentPANTittle, KYCDocumentSelfieTittle, KYCDocumentPanXMLTittle, KYCAadharFrontTitle, KYCAadharBackTitle, AadharXML, ScreeningReport, BureauReport]);
    let entity_document_code = extractDocumentCode([GSTIN, Shop_Establishment_Certificate, Entity_KYC_Partnership_Upload, Entity_KYC_Authority_Letter_Upload, URC_Certificate, Entity_LOD_Upload, Entity_AOA_Upload, Entity_COI_Upload, Entity_MOA_Upload]);
    let CoBorrower_Document_Code = extractDocumentCode([CoBorrowerSelfie, CoBorrowerPanImage, CoBorrowerPanXml, CoBorrowerKYCAadharFrontTitle, CoBorrowerKYCAadharBackTitle, CoBorrowerKYCAadharBackXml]);
    let Guarantor_Document_Code = extractDocumentCode([GuarantorSelfie, Guarantor_PanTittle, AadharImage, Guarantor_PanInputTittleXML, Guarantor_AadharInputTittleXML]);
    let Financial_Document_Code = extractDocumentCode([Financial_Documents]);
    let Shareholding_Document_Code = extractDocumentCode([SHAREHOLDING]);
    let Property_Document_Code = extractDocumentCode([Property_Ownership_Proof, Property_Ownership_Report, Property_Legal_Report_Upload, Property_Valuation_Report_Upload]);
    let Additional_Document_Code = extractDocumentCode([Additional_Documents]);
    let groupCoBorrowerId = {};
    let groupGuarantorId = {};
    allDocumentsItems.forEach((_doc) => {
      let doc = documentList.find((el) => el?.code == _doc.documentCode);
      let { _id = '', borrower_id = '', file_url = '' } = doc || {};
      // Replace underscore and capitalize first letter and place
      // space between words
      const sections = {
        'Primary Applicant': Applicant_Document_Code,
        Entity: entity_document_code,
        'Co-borrower': CoBorrower_Document_Code,
        Guarantor: Guarantor_Document_Code,
        Financial: Financial_Document_Code,
        Shareholding: Shareholding_Document_Code,
        Property: Property_Document_Code,
        'Additional Documents': Additional_Document_Code,
      };
      let section;
      for (let key in sections) {
        if (sections[key].includes(_doc.documentCode)) {
          if ((key === CoBorrower || key === Guarantor) && borrower_id) {
            const group = key === CoBorrower ? groupCoBorrowerId : groupGuarantorId;
            group[borrower_id] = group[borrower_id] ? group[borrower_id] : Object.keys(group).length + Number_Constants.ONE;
            section = `${key}-${group[borrower_id]}`;
          } else {
            section = key;
          }
          break;
        } else {
          section = 'Other';
        }
      }
      const documentObj = {
        _id,
        file_url: file_url ? file_url : '',
        code: _doc.documentCode,
        documentItems: _doc,
        file_type: _doc.id,
        id: _doc.id,
        // convert the name with first letter capital all other small
        name: _doc.DocSectionName ? _doc.DocSectionName : capitalizeFirstLetter(_doc.name.replace(EXCEPT_OPTIONAL_STR, '')),
      };
      const index = documentListBySection.findIndex((_section) => _section?.title === section);
      if (index === -1) {
        documentListBySection.push({
          title: section,
          docs: [documentObj],
        });
      } else {
        documentListBySection[index].docs.push(documentObj);
      }
    });
    // Update the Co-borrower and guarantor section with missing document code
    documentListBySection.forEach((el) => {
      let missingDocs = [];
      if (el.title.includes(CoBorrower)) {
        missingDocs = CoBorrower_Document_Code.filter((code) => !el.docs.find((doc) => doc.code == code));
      } else if (el.title.includes(Guarantor)) {
        missingDocs = Guarantor_Document_Code.filter((code) => !el.docs.find((doc) => doc.code == code));
      }
      missingDocs.forEach((code) => {
        const documentItems = allDocumentsItems.find((doc) => doc.documentCode == code);
        el.docs.push({
          _id: '',
          file_url: '',
          code,
          documentItems,
          file_type: documentItems.id,
          id: documentItems.id,
          name: capitalizeFirstLetter(documentItems.name.replace(EXCEPT_OPTIONAL_STR, '')),
        });
      });
    });
    documentListBySection = documentListBySection.filter((el) => ![CoBorrower, Guarantor].includes(el.title));

    // Order the documentListBySection as per the order of the document section
    const orderOfSection = [Applicant, Entity, Property, CoBorrower, ...generateArrayWithKey(CoBorrower, Number_Constants.FIFTEEN), Guarantor, ...generateArrayWithKey(Guarantor, Number_Constants.FIFTEEN), Financial, Shareholding, AdditionalDocuments, 'Other'];
    documentListBySection.sort((a, b) => orderOfSection.indexOf(a.title) - orderOfSection.indexOf(b.title));
    //#region [Need to update the code once we confirmed -- hardcoded to remove the gst certificate from entity]
    if (documentListBySection[5]?.docs && documentListBySection[1]?.docs.length) {
      const removedDoc = documentListBySection[1].docs.pop(); // Remove last element from documentListBySection[1].docs
      documentListBySection[5].docs.splice(0, 0, removedDoc); // Insert removedDoc at the beginning of documentListBySection[5].docs
    }
    //#endregion
    setLeadDocuments(documentListBySection);
  }
  // #endregion
  // #region View and upload document functionalities
  // To view a document
  const handleOnClick = (doc) => {
    setDocumentViewData({
      file_url: doc.file_url,
      file_type: doc.file_type,
      title: doc.name,
      isXML: doc.name.toLowerCase().includes('xml'),
    });
    setIsOpen(true);
  };
  // To upload a new document
  const handleOnUploadClick = (doc) => {
    const { file_type: type, documentItems: items, code: docCode, name } = doc;
    setDocumentUploadState({ id: items.id, sectionName: type, type, items: [items], docCode, isXML: name.includes('XML') });
    inputFileRef.current.click();
  };
  // #endregion
  // #region File upload functions
  // When input file change event
  function handleInputFileChange(event, id, docCode, sectionName, isXML = false) {
    const file = event.target.files[0];
    if (file) {
      setLoader(true);
      const selectedFile = event?.target?.files;
      const [maxFileLimit, maxFileLimitAsStr] = DOC_CODES_FOR_10MB_LIMIT.includes(docCode) ? FILE_LIMITS.ten_mb_tuple : FILE_LIMITS.five_mb_tuple;
      if (selectedFile[MAGIC_NUMBERS.zero][SIZE] > maxFileLimit) {
        showAlert(`${FILE_SIZE_ERROR_PREFIX} ${maxFileLimitAsStr}`, ERROR);
        setDocumentUploadState({ sectionName: '', type: '', items: [], isXML: false });
        setLoader(false);
        event.target.value = null;
        return;
      }
      const fileType = selectedFile[0]['name'];
      const fileExtension = fileType.split('.').pop();
      const fileExtensionInLowerCase = fileExtension.toLowerCase();
      const isFileExtentionNotAnXmlOrJson = isXML && ![FILE_TYPE.xml, FILE_TYPE.json].includes(fileExtensionInLowerCase);
      const isFileExtentionNotAnImgOrPdf = !isXML && ![FILE_TYPE.pdf, FILE_TYPE.png, FILE_TYPE.jpg, FILE_TYPE.jpeg].includes(fileExtensionInLowerCase);
      if (isFileExtentionNotAnXmlOrJson || isFileExtentionNotAnImgOrPdf) {
        showAlert(isXML ? ONLY_XML_OR_JSON_ALERT : ONLY_IMG_FILES_OR_PDF_ALERT, ERROR);
        setLoader(false);
        setDocumentUploadState({ sectionName: '', type: '', items: [], isXML: false });
        event.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        let base64Data = reader.result.split(',')[1];
        if ([FILE_TYPE.png, FILE_TYPE.jpeg, FILE_TYPE.jpg].includes(fileExtension)) {
          base64Data = convertImagesToPdf(base64Data);
        }
        const uploadFileName = file.name.length <= 20 ? file.name : file.name.slice(0, 20) + '...';
        const uploadedData = {
          id,
          uploadFileName,
          uploadFileBase64: base64Data,
          code: docCode,
        };
        if (loan_app_id != null && docCode != null && sectionName) {
          docUpload(uploadedData, loan_app_id, sectionName);
        }
      };
      reader.readAsDataURL(file);
    }
  }
  // Upload document api call
  async function docUpload(fileData) {
    const dataForUpload = {
      submitData: {
        base64pdfencodedfile: fileData['uploadFileBase64'],
        fileType: fileData['uploadFileName'],
        code: fileData['code'],
        loan_app_id,
        borrower_id: '',
      },
      userData: {
        company_id: 557,
        product_id: 1035,
        user_id: user._id,
      },
    };

    if (fileData.doc_index != null) {
      dataForUpload['submitData']['doc_index'] = fileData.doc_index;
    }

    try {
      await dispatch(
        uploadLoanDocumentsWatcher(
          dataForUpload,
          (response) => {
            fetchDocumentList();
            setLoader(false);
            showAlert('Document uploaded successfully', 'success');
          },
          (error) => {
            showAlert(error?.response?.data?.message, ERROR);
            setLoader(false);
          },
        ),
      );
    } catch (error) {}
  }
  // #endregion

  // #region Alert functions
  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };
  const showAlert = (msg, type) => {
    const element = document.getElementById('TopNavBar');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }

    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 10000);
  };
  // #endregion
  // #region Render UI functions
  const renderDocumentItem = (documentItem, index) => {
    return (
      <UploadCard
        key={index}
        hasDocument={documentItem.file_url ? true : false}
        heading={documentItem.name}
        viewOnClick={() => handleOnClick(documentItem)}
        uploadOnClick={() => {
          handleOnUploadClick(documentItem);
        }}
      />
    );
  };

  const renderDocumentBySection = (sectionalDocDetail, index) => {
    return (
      <div key={index}>
        <h5 style={styles['sectionTitleStyle']}>{sectionalDocDetail.title}</h5>
        <div style={styles['rowStyle']}>{sectionalDocDetail.docs && sectionalDocDetail.docs.map(renderDocumentItem)}</div>
        {/* {
             sectionalDocDetail?.title && sectionalDocDetail?.title === "Financial Documents" && 
              <PasswordViewer docPassword={docPassword} customStyle={{marginTop:"-35px", marginBottom :"30px"}}/>
            } */}
      </div>
    );
  };
  // #endregion

  return (
    <>
      {leadDocuments && leadDocuments.map(renderDocumentBySection)}
      {/* Document view pop up */}
      {isOpen ? <ViewDocumentCustom loan_app_id={loan_app_id} company_id={557} product_id={1035} doctype={documentViewData.file_type} awsurl={documentViewData.file_url} dispatch={dispatch} setIsOpen={setIsOpen} isOpen={isOpen} title={documentViewData.title} isXML={documentViewData.isXML} /> : null}
      {/* Document view pop up */}
      {/* upload file it's hide by default while clicking upload icon in UploadCard component it will get clicked */}
      <input
        style={{ display: 'none' }}
        ref={inputFileRef}
        type="file"
        accept={documentUploadState?.items[0]?.acceptFileType}
        onChange={(e) => {
          handleInputFileChange(e, documentUploadState?.id, documentUploadState?.docCode, documentUploadState?.sectionName, documentUploadState?.isXML);
        }}
      />
      {/* End upload file */}
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
      {loader && <Preloader />}
    </>
  );
}

export default DocumentDetailsTab;

const useStyles = () => {
  return {
    containerStyle: {
      marginTop: '30px',
    },
    rowStyle: {
      display: 'grid',
      gridColumnGap: 0,
      marginTop: '18px',
      marginBottom: '45px',
      gridTemplateColumns: '25% 25% 25% 25%',
      marginLeft: '12px',
    },
    sectionTitleStyle: {
      fontFamily: 'Montserrat-Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '27px',
      letterSpacing: '0px',
      textAlign: 'left',
      marginLeft: '12px',
    },
  };
};
