export function getReconSummaryWatcher(payload, resolve, reject) {
  return {
    type: 'GET_RECON_SUMMARY_WATCHER',
    payload,
    resolve,
    reject,
  };
}

export function getDownloadReportWatcher(payload, resolve, reject) {
  return {
    type: 'GET_DOWNLOAD_REPORT_WATCHER',
    payload,
    resolve,
    reject,
  };
}
