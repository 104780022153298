import * as React from 'react';
import Grid from '@mui/material/Grid';
import CompanyDropdown from '../../components/Company/CompanySelect';
import ProductDropdown from '../../components/Product/ProductSelect';
import CollectionBankAccountPopup from './CollectionBankAccountPopup';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Button, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getPendingRepaymentListWatcher, approveRepaymentsWatcher } from '../../actions/repaymentApproval';
import { AlertBox } from '../../components/AlertBox';
import { storedList } from '../../util/localstorage';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { checkAccessTags } from '../../util/uam';
import CustomSelect from '../../components/custom/customSelect';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from '@mui/material/IconButton';

import Table from 'react-sdk/dist/components/Table';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';

const user = storedList('user');
const defaultErrors = {
  txnAmountError: false,
  txnReferenceError: false,
  utrNumberError: false,
};

const RepaymentApproval = () => {
  const user = storedList('user');
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [company, setCompany] = useState(null);
  const [product, setProduct] = useState(null);
  const [isAllSelect, setIsAllSelect] = useState(false);
  const [count, setCount] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [result, setResult] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [filter, setFilter] = useState('');
  const [checkCounter, setCheckCounter] = useState(0);
  const [totalSelection, setTotalSelectCount] = useState(0);
  const [totalTransactionAmount, setTotalTransactionAmount] = useState(0);
  const [utrNumber, setUtrNumber] = useState('');
  const [txnReference, setTxnReference] = useState('');
  const [txnAmount, setTxnAmount] = useState('');
  const [errors, setErrors] = useState(defaultErrors);
  const [status, setStatus] = useState({ value: 'pending', label: 'Pending' });
  const [openPopup, setOpenPopup] = useState(false);

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  const statusObject = [
    { value: 'pending', label: 'Pending' },
    { value: 'hold', label: 'Hold' },
  ];

  useEffect(() => {
    if (isTagged && filter && checkAccessTags(['tag_repayment_approval_read', 'tag_repayment_approval_read_write'])) getRepaymentPendingList(page);
    if (!isTagged && filter) getRepaymentPendingList(page);
  }, [filter, page, rowsPerPage]);

  const handleSelectAllRecord = (data) => {
    setIsAllSelect(!isAllSelect);
    let selected = [];
    let sum = 0;
    result.map((item, index) => {
      selected.push({
        ...item,
        index,
        checked: !isAllSelect,
      });
      sum += parseFloat(item.txn_amount);
    });
    setResult(selected);
    if (data?.target?.checked) {
      setSelectedRecords(selected);
      setTotalSelectCount(selected.length);
      setTotalTransactionAmount(sum);
      setCheckCounter(result.length);
    } else {
      setSelectedRecords([]);
      setTotalSelectCount(0);
      setTotalTransactionAmount(0);
      setCheckCounter(0);
    }
  };

  const handleSelectSingleRecord = (event, idx) => {
    let newRecords = [...result];
    newRecords[idx].checked = !newRecords[idx].checked;
    setResult(newRecords);

    if (event?.target?.checked) {
      let selected = selectedRecords;
      selected.push(newRecords[idx]);
      setSelectedRecords(selected);
      let sum = 0;
      let count = 0;
      selected.map((i) => {
        sum += parseFloat(i.txn_amount);
        count += 1;
      });
      setTotalTransactionAmount(sum);
      setTotalSelectCount(count);
      setCheckCounter(checkCounter + 1);
      if (checkCounter + 1 === result.length) setIsAllSelect(true);
    } else {
      setCheckCounter(checkCounter - 1);
      setIsAllSelect(false);
      let sum = 0;
      let count = 0;
      let selected = [];
      selectedRecords.map((item) => {
        if (item.checked) {
          selected.push(item);
          sum += parseFloat(item.txn_amount);
          count += 1;
        }
      });
      setTotalTransactionAmount(sum);
      setTotalSelectCount(count);
      setSelectedRecords(selected);
    }
  };

  const getRepaymentPendingList = (page) => {
    const data = {
      company_id: company.value,
      product_id: product.value,
      txn_amount: txnAmount,
      txn_reference: txnReference,
      utr_number: utrNumber,
      page,
      limit: rowsPerPage,
      status: status?.value,
    };
    setSelectedRecords([]);
    setTotalSelectCount(0);
    setTotalTransactionAmount(0);
    dispatch(
      getPendingRepaymentListWatcher(
        data,
        (response) => {
          let result = [];
          Array.from(response.rows).forEach((row, index) => {
            result.push({
              ...row,
              index,
              checked: false,
            });
          });
          setResult(result);
          setCount(response.count);
          setIsAllSelect(false);
        },
        (error) => {
          showAlert(error.response.data.message, 'error');
          setResult([]);
        },
      ),
    );
  };

  const handleInputChange = (setValue) => (event) => {
    const { value } = event.target;
    setValue(value);
  };

  const handleSubmit = (status) => {
    if (!selectedRecords.length) return showAlert('Kindly select record for repayment approval', 'error');

    if (status === 'approve') {
      setOpenPopup(true);
      return;
    }

    const data = {
      company_id: company.value,
      product_id: product.value,
      user_id: user._id,
      page,
      limit: rowsPerPage,
      status: status === 'approve' ? 'Y' : status,
      bankName: null,
      bankAccountNumber: null,
    };
    setPage(0);
    const payload = { data, selectedRecords };
    dispatch(
      approveRepaymentsWatcher(
        payload,
        (response) => {
          showAlert(response?.message, 'success');
          getRepaymentPendingList(0);
        },
        (error) => {
          showAlert(error.response.data.message, 'error');
          getRepaymentPendingList(0);
        },
      ),
    );
  };

  const onSearchClick = () => {
    if (!company?.value) return showAlert('Please select company', 'error');
    if (!product?.value) return showAlert('Please select product', 'error');
    setIsAllSelect(false);
    setSelectedRecords([]);
    setTotalSelectCount(0);
    setTotalTransactionAmount(0);
    setPage(0);
    setResult([]);
    setCompany(company);
    setProduct(product);
    setCheckCounter(0);
    setFilter({
      company_id: company.value,
      product_id: product.value,
    });
  };

  const clearAll = () => {
    setSelectedRecords([]);
    setPage(0);
    setResult([]);
    setFilter('');
    setIsAllSelect(false);
    setCompany('');
    setProduct('');
    setTotalSelectCount(0);
    setTotalTransactionAmount(0);
    setTxnAmount('');
    setTxnReference('');
    setUtrNumber('');
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      setAlert(false);
    }, 4000);
  };

  const handleChangePage = (event) => {
    setPage(event);
    setTotalSelectCount(0);
    setTotalTransactionAmount(0);
    setIsAllSelect(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event));
    setSelectedRecords([]);
    setIsAllSelect(false);
    setPage(0);
  };

  const columnTaggedTrue = [
    {
      id: 'checked',
      format: (rowData) => <input style={{ height: '20px', width: '20px', marginTop: '3px' }} type="checkbox" id={rowData._id} key={rowData._id} checked={rowData.checked} onClick={(e) => handleSelectSingleRecord(e, rowData.index)} />,
      label: (
        <div>
          <div style={{ marginRight: '10px' }}>
            <input type="checkbox" style={{ height: '20px', width: '20px' }} checked={isAllSelect} disabled={isTagged ? !checkAccessTags(['tag_repayment_approval_read_write']) : false} onClick={handleSelectAllRecord} />
          </div>
        </div>
      ),
    },
    { id: 'loan_id', label: 'Loan Id' },
    { id: 'txn_amount', label: 'Transaction amount' },
    { id: 'amount_net_of_tds', label: 'Amount net of TDS' },
    { id: 'payment_mode', label: 'Payment mode' },
    { id: 'label', label: 'Label' },
    { id: 'utr_number', label: 'Repayment UTR number' },
    { id: 'utr_date_time_stamp', format: (rowData) => moment(rowData.utr_date_time_stamp).format('YYYY-MM-DD'), label: 'Transaction date' },
    { id: 'txn_reference', label: 'TXN reference' },
    { id: 'txn_reference_datetime', format: (rowData) => moment(rowData.txn_reference_datetime).format('YYYY-MM-DD'), label: 'Settlement date' },
  ];

  const columnTaggedFalse = [
    {
      id: 'checked',
      format: (rowData) => <input style={{ height: '20px', width: '20px', marginTop: '3px' }} type="checkbox" id={rowData._id} key={rowData._id} checked={rowData.checked} onClick={(e) => handleSelectSingleRecord(e, rowData.index)} />,
      label: (
        <div>
          <div style={{ marginRight: '10px' }}>
            <input type="checkbox" style={{ height: '20px', width: '20px' }} checked={isAllSelect} onClick={handleSelectAllRecord} />
          </div>
        </div>
      ),
    },
    { id: 'loan_id', label: 'Loan Id' },
    { id: 'txn_amount', label: 'Transaction amount' },
    { id: 'payment_mode', label: 'Payment mode' },
    { id: 'label', label: 'Label' },
    { id: 'utr_number', label: 'Repayment UTR number' },
    { id: 'utr_date_time_stamp', format: (rowData) => moment(rowData.utr_date_time_stamp).format('YYYY-MM-DD'), label: 'Transaction date' },
    { id: 'txn_reference', label: 'TXN reference' },
    { id: 'txn_reference_datetime', format: (rowData) => moment(rowData.txn_reference_datetime).format('YYYY-MM-DD'), label: 'Settlement date' },
  ];

  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={() => setAlert(false)} /> : null}
      {openPopup && <CollectionBankAccountPopup isOpen={openPopup} setIsOpen={setOpenPopup} showAlert={showAlert} company={company} product={product} user={user} page={page} rowsPerPage={rowsPerPage} setPage={setPage} getRepaymentPendingList={getRepaymentPendingList} selectedRecords={selectedRecords} />}
      <Typography sx={{ mt: 2, ml: 2 }} variant="h6">
        Repayment approval
      </Typography>
      <CardContent>
        <Grid sx={{ margin: '10px 0px' }}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <CompanyDropdown
                placeholder="Select company"
                company={company}
                onCompanyChange={(value) => {
                  setCompany(value ? value : null);
                  setProduct(null);
                  setResult([]);
                  setSelectedRecords([]);
                  setTotalSelectCount(0);
                  setTotalTransactionAmount(0);
                  setIsAllSelect(false);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <ProductDropdown
                placeholder="Select product"
                onProductChange={(value) => {
                  setProduct(value ? value : null);
                  setResult([]);
                  setSelectedRecords([]);
                  setTotalSelectCount(0);
                  setTotalTransactionAmount(0);
                  setIsAllSelect(false);
                }}
                company={company || null}
                product={product || null}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomSelect
                placeholder="Select status"
                data={statusObject}
                value={status}
                handleDropdownChange={(value) => {
                  if (status !== value) {
                    setSelectedRecords([]);
                    setPage(0);
                    setResult([]);
                    setFilter('');
                    setIsAllSelect(false);
                    setTotalSelectCount(0);
                    setTotalTransactionAmount(0);
                    setTxnAmount('');
                    setCheckCounter(0);
                    setTxnReference('');
                    setUtrNumber('');
                  }
                  setStatus(value);
                }}
              />
            </Grid>
            <Grid item style={{ marginBottom: '25px' }} alignSelf={'center'} textAlign={'center'}>
              <IconButton color="primary" onClick={onSearchClick}>
                <ManageSearchIcon
                  sx={{
                    fontSize: '40px',
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="filled" component={Box} width="100%" marginBottom="1.5rem!important">
                <TextField id="outlined-basic" label="Transaction Amount" variant="outlined" type="text" placeholder="Transaction Amount" value={txnAmount ?? ''} error={errors.txnAmountError} onChange={handleInputChange(setTxnAmount)} />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="filled" component={Box} width="100%" marginBottom="1.5rem!important">
                <TextField id="outlined-basic" label="UTR Number" variant="outlined" type="text" placeholder="UTR Number" value={utrNumber ?? ''} error={errors.utrNumberError} onChange={handleInputChange(setUtrNumber)} />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="filled" component={Box} width="100%" marginBottom="1.5rem!important">
                <TextField id="outlined-basic" label="TXN Reference" variant="outlined" type="text" placeholder="TXN Reference" value={txnReference ?? ''} error={errors.txnReferenceError} onChange={handleInputChange(setTxnReference)} />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ margin: '10px 0px' }}>
          <Grid container>
            {totalSelection > 0 ? (
              <Grid item xs={9}>
                <div style={{ width: '400px', color: 'black', border: '1px solid rgb(229, 229, 232)', borderRadius: '10px' }}>
                  <div style={{ textAlign: 'center', alignItems: 'center', backgroundColor: 'rgb(94, 114, 228)', color: 'rgb(0, 0, 0)', height: '35px', display: 'flex', justifyContent: 'space-evenly', borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>Selection summary</div>

                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', backgroundColor: '#E5E5E8', borderBottom: '1px solid rgb(229, 229, 232)', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                    <span>Selected Repayments Count:</span> <span>{totalSelection}</span>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', borderBottom: '1px solid rgb(229, 229, 232)', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                    <span>Total Transaction Amount:</span> <span> {Math.round((totalTransactionAmount * 1 + Number.EPSILON) * 100) / 100}</span>
                  </div>
                </div>
              </Grid>
            ) : null}
            <Grid textAlign={'right'} item xs={totalSelection > 0 ? 3 : 12} paddingRight={0}>
              <Button variant="contained" disabled={!selectedRecords.length} color={'success'} onClick={() => handleSubmit('approve')} sx={{ marginRight: '5px' }}>
                Approve
              </Button>
              {status?.value === 'pending' ? (
                <Button variant={'contained'} disabled={!selectedRecords.length} onClick={() => handleSubmit('hold')} color={'warning'} sx={{ marginRight: '5px' }}>
                  Hold
                </Button>
              ) : null}
              <Button variant={'contained'} disabled={!selectedRecords.length} onClick={() => handleSubmit('rejected')} color={'error'}>
                Reject
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {isTagged && result.length ? (
          checkAccessTags(['tag_repayment_approval_read', 'tag_repayment_approval_read_write']) ? (
            <>
              <div className="table-alignment-css">
                <Table customStyle={{ width: '100%', display: 'grid', gridTemplateColumns: '5% 16% 10% 10% 9% 7% 13% 9% 9% 12%' }} data={result} columns={columnTaggedTrue} />
              </div>
              <Pagination itemsPerPage={rowsPerPage} totalItems={count} rowsPerPageOptions={[10, 20, 50, 100]} onPageChange={handleChangePage} showOptions={true} setRowLimit={(e) => handleChangeRowsPerPage(e)} />
            </>
          ) : null
        ) : result.length ? (
          <>
            <div className="table-alignment-css">
              <Table customStyle={{ width: '100%', display: 'grid', gridTemplateColumns: '4% 20% 11% 11% 9% 15% 9% 11% 7%' }} data={result} columns={columnTaggedFalse} />
            </div>
            <Pagination itemsPerPage={rowsPerPage} totalItems={count} rowsPerPageOptions={[10, 20, 50, 100]} onPageChange={handleChangePage} showOptions={true} setRowLimit={(e) => handleChangeRowsPerPage(e)} />
          </>
        ) : null}
      </CardContent>
    </>
  );
};

export default RepaymentApproval;
