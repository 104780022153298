import { Saga_Action_Type } from '../constants/string_constants';

export function createLoanIDWatcher(data, resolve, reject) {
  return {
    type: 'CREATE_LAP_LOANID',
    payload: data,
    resolve,
    reject,
  };
}

export function getBookLoanDetailsWatcher(payload, resolve, reject) {
  return {
    type: 'GET_LAP_BOOK_LOAN_DETAILS',
    payload,
    resolve,
    reject,
  };
}

export function getLapLoanDocumentsWatcher(payload, resolve, reject) {
  return {
    type: Saga_Action_Type.Fetch_Lap_Loan_Document,
    payload,
    resolve,
    reject,
  };
}

export function GSTINVerifyWatcher(payload, resolve, reject) {
  return {
    type: 'GSTIN_VERIFY_STATUS',
    payload,
    resolve,
    reject,
  };
}

export function getGstStatusIDWatcher(data, resolve, reject) {
  return {
    type: 'GET_LAP_GST_ID_STATUS',
    payload: data,
    resolve,
    reject,
  };
}

export function postLoanDetailsWatcher(data, resolve, reject) {
  return {
    type: 'POST_LAP_LOAN_DETAILS',
    payload: data,
    resolve,
    reject,
  };
}
