import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import * as React from 'react';
import CustomDropdown from '../../components/custom/customSelect';
import Grid from '@mui/material/Grid';
import { getKeyByValue } from 'util/helper';

export const RejectLoanPopup = (props) => {
  const { isReject, handleClose, setRejectReason, setRejectRemark, handleLoanStatus } = props;

  const reasonsList = JSON.parse(process.env.REACT_APP_REJECT_REASONS);

  return (
    <div>
      <Dialog onClose={handleClose} open={isReject} fullWidth={'xs'}>
        <DialogTitle>Reject loan reason</DialogTitle>
        <DialogContent>
          <Grid mt={2}>
            <Grid item xs={12}>
              <CustomDropdown
                placeholder="Select reason"
                data={Object.values(reasonsList)}
                handleDropdownChange={(value) => {
                  const reasonCode = getKeyByValue(reasonsList, value);
                  setRejectReason(reasonCode ? reasonCode : '');
                }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid item xs={12} mt={2}>
              <TextField
                fullWidth
                autoFocus
                inputProps={{
                  maxlength: 250,
                }}
                label="Remarks"
                variant="standard"
                onChange={(event) => {
                  setRejectRemark(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleLoanStatus()}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
