import * as React from "react";
import { styled } from "@material-ui/core/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { drawDownRequestListWatcher } from "../../actions/transactionHistory";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { AlertBox } from "../../components/AlertBox";
import { checkAccessTags } from "../../util/uam";
import { storedList } from "../../util/localstorage";
import DrawDownRequestUi from "../lending/drawDownRequestUi";
import CustomizeTemplates from "../loanSchema/templateTabs";
import BulkUpload from "./bulkUpload.view";
import { Link } from "react-router-dom";
import Button from "react-sdk/dist/components/Button";
const user = storedList("user");
import Table from "react-sdk/dist/components/Table/Table";
import InfoIcon from "../lending/images/info-circle.svg";
import { getProductByIdWatcher } from "../../actions/product";
import Pagination from "../../../node_modules/react-sdk/dist/components/Pagination/Pagination";
import { downloadDataInXLSXFormat } from "../../util/helper";
import Img from "./images/download-button.svg";
import imgH from "./images/download-button-hover.svg";
import { getBorrowerDetailsByIdWatcher } from "actions/borrowerInfo";
import { getBalanceDetailWatcher } from "../../actions/borrowerInfo";
import LineDetails from "./lineDetails.view";

export default function LOCDrawdownRequests() {
  const dispatch = useDispatch();
  const { company_id, product_id, loan_id, loan_app_id } = useParams();
  const [drawdownList, setDrawdownList] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [showDrawdownRequests, setShowDrawdownRequests] = useState(true);
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [showLineDetails, setShowLineDetails] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState({});
  const [sampleData, setSampleData] = useState([]);
  const [productDetails, setproductDetails] = useState({})
  const [linePF, setLinePF] = useState(false);
  const [firstDD, setFirstDD] = useState(false);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState('request_id');
  const [isAscSort, setIsAscSort] = useState(true);
  const [borroData, setBorroData] =useState([]);
  const [balanceDetail, setBalanceDetail] =useState([]);

  var oldestEntry = null;
  var product = null;

  const statusToDisplay = {
    0: "In progress",
    1: "Disbursed",
    2: "In progress",
    9: "Error",
    4: "Rejected"
  };

  const isTagged =
    process.env.REACT_APP_BUILD_VERSION > 1
      ? user?.access_metrix_tags?.length
      : false;

  const columns = [
    { id: "request_id", label: "REQUEST ID", isSorting: true },
    { id: "usage_id", label: "USAGE ID" , isSorting: true },
    { id: "invoice_number", label: "INVOICE NUMBER", isSorting: true},
    { id: "drawdown_amount", label: "DRAWDOWN AMOUNT" , isSorting: true },
    { id: "net_drawdown_amount", label: "NET DRAWDOWN AMOUNT" , isSorting: true },
    { id: "disbursement_date_time", label: "DRAWDOWN DATE" , isSorting: true },
    { id: "repayment_due_date", label: "REPAYMENT DUE DATE" , isSorting: true },
    { id: "interest_rate", label: "INT RATE" , isSorting: true },
    { id: "status", label: "STATUS" , isSorting: true }
  ]

  const handleSort = (column) => {
    let sortByColm = column.id;

    if(column.id == "request_id")
      sortByColm = '_id';

    else if(column.id == "usage_id")
      sortByColm='loan_transaction_ledger._id';

    else if(column.id == "invoice_number")
      sortByColm='invoice_number'

    else if(column.id == "drawdown_amount")
      sortByColm = 'drawdown_amount';

    else if(column.id == 'net_drawdown_amount')
      sortByColm = 'net_drawdown_amount';

    else if(column.id == 'disbursement_date_time')
      sortByColm  = 'loan_transaction_ledger.disbursement_date_time';
       
    else if(column.id == "repayment_due_date")
      sortByColm = 'loan_transaction_ledger.repayment_due_date';

    else if(column.id == "interest_rate")
      sortByColm = 'new_interest_rate';
      
    else if(column.id == 'status')
      sortByColm = 'new_status'
    
    setSortBy(sortByColm);
    setIsAscSort((isAscSort)=>!isAscSort);

  }

  const handleChangePage = (event) => {
      setPage(event);
    };

  useEffect(() => {
    const params = {
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
  
    };
    let borrowerData;
    dispatch(
      getBorrowerDetailsByIdWatcher(
        params,
        result => {
  
           borrowerData =  result.data;
           setBorroData(borrowerData);
        },
        error => {
          return showAlert(error.response.data.message, "error");
        }
      )
    );
    let balanceDetailData
    dispatch(getBalanceDetailWatcher(
      {
        company_id: company_id,
        product_id: product_id,
        loan_ids: [loan_id],
    
      },
      result => {
        balanceDetailData =  result.balanceDetail;
         setBalanceDetail(result.balanceDetail ||[]);
      },
      error => {
        return showAlert(error.response?.data?.message||error.message||error, "error");
      }
    ));

    if ( isTagged && checkAccessTags([ "tag_drawdown_request_read", "tag_drawdown_request_read_write" ]) )
      fetchDrawDownList();
    
    if (!isTagged) 
      fetchDrawDownList();
    
      fetchProductDetails();

  }, [page , rowsPerPage, sortBy, isAscSort]);

  const handleAlertClose = () => {
    setAlert(false);
    setAlertMessage("");
    setSeverity("");
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const fetchAllDrawDownList = () => {
    const payload = {
      loan_id: loan_id,
      company_id,
      product_id,
      page: 0,
      limit: count
    };
    new Promise((resolve, reject) => {
      dispatch(drawDownRequestListWatcher(payload, resolve, reject));
    })
      .then((response) => {
        let jsonData = response?.data?.map((record) => {
          return {
            loan_id:loan_id,
            loan_app_id: record?.loan_app_id,
            request_id: record?._id ? record._id : "",
            invoice_number: record?.invoice_number ? record.invoice_number : "NA",
            usage_id: record?.loan_transaction_ledger.length > 0
              ? record.loan_transaction_ledger[0]?._id
              : "NA",
            first_name: record?.first_name || "NA",
            last_name: record?.last_name || "NA",
            line_pf:record?.line_pf || "NA",
            usage_fees_including_gst: record?.usage_fees_including_gst || "NA",
            usage_fee: record?.usage_fees || "NA",
            gst_usage_fee: record?.gst_usage_fees || "NA" ,
            cgst_usage_fee: record?.cgst_usage_fees || "NA",
            sgst_usage_fee: record?.sgst_usage_fees || "NA",
            igst_usage_fee: record?.igst_usage_fees || "NA",
            repayment_days: record?.repayment_days || "NA",
            upfront_interest: record?.upfront_int || "NA",
            drawdown_request_date: record?.drawadown_request_date || "NA",
            utrn_number: record?.utrn_number || "NA",
            bene_bank_name: response?.productAndSchemeMappingData[0]?.bene_bank_name || "NA",
            bene_bank_acc_number: response?.productAndSchemeMappingData[0]?.bene_bank_acc_num || "NA",
            bene_bank_ifsc: response?.productAndSchemeMappingData[0]?.bene_bank_ifsc || "NA",
            bene_bank_acc_holder_name: response?.productAndSchemeMappingData[0]?.bene_bank_account_holder_name || "NA",
            bene_bank_acc_type: response?.productAndSchemeMappingData[0]?.bene_bank_account_type || "NA",
            interest_type: record?.scheme[0]?.interest_type ? record.scheme[0].interest_type 
              : response?.productAndSchemeMappingData[0]?.interest_type || "NA",
            loan_interest_rate: record?.scheme[0]?.interest_rate ? record.scheme[0].interest_rate 
              : response?.productAndSchemeMappingData[0]?.loan_int_rate || "NA",
            invoice_number: record?.invoice_number || "NA",
            penal_interest_rate: record?.scheme[0]?.penal_rate || "NA",
            bounce_charges: record?.scheme[0]?.bounce_charge || "NA",
            due_date: record?.line_state_audit[0]?.due_date ? record.line_state_audit[0].due_date : "NA",
            drawdown_amount: record?.drawdown_amount
              ? new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR"
              }).format(record?.drawdown_amount)
              : "NA",
            netdrawdown_amount: record?.net_drawdown_amount
              ? new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR"
              }).format(record.net_drawdown_amount)
              : "NA",
            status: record?.line_state_audit.length > 0 &&
              record?.line_state_audit[0]?.status === "Closed" ? "Closed"
              : statusToDisplay[record?.status] || "NA"
          };
        });
        generateXLSXFile(jsonData, "xlsx")
      })
      .catch((error) => {
        showAlert("No drawdown records found for the loan id", "error");
      });
  };

  const fetchDrawDownList = () => {
    const payload = {
      loan_id: loan_id,
      company_id,
      product_id,
      page: page,
      limit: rowsPerPage,
      sortBy,
      isAscSort
    };
    new Promise((resolve, reject) => {
      dispatch(drawDownRequestListWatcher(payload, resolve, reject));
    })
      .then((response) => {
        if (response.data.length == 0) {
          setFirstDD(true);
        }
        setCount(response?.count)
        setProductData(response.productAndSchemeMappingData[0]);
        setSampleData(
          response?.data?.map((item, index) => ({
            "request_id": (
              <Link
                onClick={(event) => {
                  handleLocDetails(item);
                }}
              >
                {item._id ? item._id : "NA"}
              </Link>
            ),
            "usage_id":
              item?.loan_transaction_ledger.length > 0
                ? item.loan_transaction_ledger[0]._id
                : "NA",
            "invoice_number": item?.invoice_number ? item.invoice_number : "NA",
            "drawdown_amount": item?.drawdown_amount
              ? new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR"
                }).format(item?.drawdown_amount)
              : "NA",
            "net_drawdown_amount": (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {item?.net_drawdown_amount
                  ? new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR"
                    }).format(item.net_drawdown_amount)
                  : "NA"}
                {item?.processing_fees_including_gst ? (
                  <div style={{ cursor: "pointer" }}>
                    <Tooltip
                      title={`PF = ${item?.processing_fees_including_gst}`}
                    >
                      <img
                        style={{ marginLeft: "10px" }}
                        src={InfoIcon}
                        alt="svg"
                      />
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ),
            "disbursement_date_time":
              item?.loan_transaction_ledger.length > 0
                ? item.loan_transaction_ledger[0].disbursement_date_time &&
                  item.loan_transaction_ledger[0].disbursement_date_time != ""
                  ? moment(
                      item.loan_transaction_ledger[0].disbursement_date_time
                    ).format("YYYY-MM-DD")
                  : "NA"
                : "NA",
            "repayment_due_date":
              item?.loan_transaction_ledger.length > 0
                ? item.loan_transaction_ledger[0].repayment_due_date &&
                  item.loan_transaction_ledger[0].repayment_due_date != ""
                  ? (response?.productAndSchemeMappingData[0]?.reconType && response?.productAndSchemeMappingData[0]?.reconType === "Invoice - Monthly Interest") ?
                  <Link
                    onClick={(event) => {
                      const usageId = item?.loan_transaction_ledger.length > 0
                          ? item.loan_transaction_ledger[0]._id
                          : "N/A";
                      handleOpenRepaymentSchedule(item,usageId);
                    }}> 
                        {moment(
                          item.loan_transaction_ledger[0].repayment_due_date
                        ).format("YYYY-MM-DD")}
                    </Link> :
                      moment(
                        item.loan_transaction_ledger[0].repayment_due_date
                      ).format("YYYY-MM-DD")
                  : "NA"
                : "NA",
                "interest_rate":  item?.scheme[0]?.rate_slab && item?.scheme[0]?.rate_slab.length > 0 ? `${Math.min(...(item?.scheme[0]?.rate_slab.map(entry => entry.interest_rate)))} - ${Math.max(...(item?.scheme[0]?.rate_slab.map(entry => entry.interest_rate)))}` : item?.scheme[0]?.interest_rate ? 
                item.scheme[0].interest_rate
              : response?.productAndSchemeMappingData[0]?.loan_int_rate ?
                response.productAndSchemeMappingData[0].loan_int_rate : "NA",
            'status': item?.line_state_audit.length > 0 && 
            item?.line_state_audit[item.line_state_audit.length - 1]?.status === "Closed" 
              ? "Closed" 
              : statusToDisplay[item.status] || 'NA'
          }))
        );
        setDrawdownList(response.data);
        let dataArray = response.data;
        if (response?.data?.length > 0) {
          oldestEntry = dataArray.reduce((oldest, current) => {
            const oldestDate = new Date(oldest.created_at);
            const currentDate = new Date(current.created_at);
            return currentDate < oldestDate ? current : oldest;
          });
        }
        setProductData(response.productAndSchemeMappingData[0]);
      })
      .catch((error) => {
        showAlert("No drawdown records found for the loan id", "error");
      });
  };
  const fetchProductDetails = () => {
  new Promise((resolve, reject) => {
    dispatch(getProductByIdWatcher(product_id, resolve, reject));
  })
    .then(response => {
     setproductDetails(response);
     product = response;
    })
    .catch((error) => {
      showAlert("Error while getting product details", "error");
    });
    };


  const handleOpenInNewPage = (url, page) => {
    window.open(`${url}`, `${page}`);
  };

  const handleOpenRepaymentSchedule = (selectedRow,usageId) => {
    
   var selectedRowLinePf = (((!borroData?.line_pf)? (product?.line_pf === "drawdown")?true:(borroData?.line_pf === "drawdown" ):(borroData?.line_pf === "drawdown" )) && selectedRow._id === oldestEntry._id) ? true : false;
   
    handleOpenInNewPage(`/admin/lending/loan/loc_drawdown_request/${selectedRow.company_id}/${selectedRow.product_id
      }/${selectedRow.loan_id}/${selectedRow._id}/${selectedRow.loan_app_id
      }/${selectedRow.status}/${selectedRowLinePf}?tab=Repayment%20Schedule&usageId=${usageId}`, "_self");
  }
  
  const handleLocDetails = (selectedRow) => {
    if (
      ((!borroData?.line_pf)?(product?.line_pf === "drawdown")?true:(borroData?.line_pf === "drawdown"):(borroData?.line_pf === "drawdown")) &&
      selectedRow._id === oldestEntry._id      
    ) {
      handleOpenInNewPage(
        `/admin/lending/loan/loc_drawdown_request/${selectedRow.company_id}/${
          selectedRow.product_id
        }/${selectedRow.loan_id}/${selectedRow._id}/${
          selectedRow.loan_app_id
        }/${selectedRow.status}/${"true"}`,
        "_blank"
      );
    } else {
      handleOpenInNewPage(
        `/admin/lending/loan/loc_drawdown_request/${selectedRow.company_id}/${
          selectedRow.product_id
        }/${selectedRow.loan_id}/${selectedRow._id}/${
          selectedRow.loan_app_id
        }/${selectedRow.status}/${"false"}`,
        "_blank"
      );
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#5e72e4",
      color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.common.black
    }
  }));

  const generateXLSXFile = (data, ext) => {
    let fileName = `Drawdown_details_file_${loan_id}.${ext}`;
    downloadDataInXLSXFormat(fileName, data);
    setTimeout(() => {
      showAlert( "File Downloaded Successfully", "success");
    }, 2000);
  };

  const handleDrawdownRequests = () => {
    setShowDrawdownRequests(true);
    setShowBulkUpload(false);
    setShowLineDetails(false);
  };
  const handleBulkUpload = () => {
    setShowDrawdownRequests(false);
    setShowLineDetails(false);
    setShowBulkUpload(true);

  };

  const handleLineDetails = () => {
    setShowDrawdownRequests(false);
    setShowBulkUpload(false);
    setShowLineDetails(true);
  };

  const changeActiveTab = (tabName) => {
    const tabClickHandlers = {
      "drawdown requests": handleDrawdownRequests,
      "bulk upload": handleBulkUpload,
      "line details": handleLineDetails,
    };
    const tabClickHandler = tabClickHandlers[tabName];
    if (tabClickHandler) {
      tabClickHandler();
    }
  };

  return (
    <>
    
      <CustomizeTemplates
        templatesdata={["Drawdown Requests", "Bulk Upload", "Line Details"]}
        initialState={"Drawdown Requests"}
        onIndexChange={changeActiveTab}
      />
      {/* DRAWDOWN REQUESTS */}
      {showDrawdownRequests ?
        <>
      {alert ? (
        <AlertBox
          severity={severity}
          msg={alertMessage}
          onclose={handleAlertClose}
        />
      ) : null}
      <div style={{ margin: "24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "24px"
          }}
        >
          {drawdownList ? (
            <Button
              buttonType="secondary"
              customStyle={{ display:"flex",border: "1px solid #475BD8", boxShadow: "none", backgroundColor: "white", color: "#475BD8",marginRight: "10px" }}
              imageButton={Img}
              imageButtonHover={imgH}
              iconButton="btn-secondary-download-button"
              label="Drawdown"
              onClick={fetchAllDrawDownList}
            />
          ): null}
          <Button
            buttonType="primary"
            label="New Drawdown Request"
            onClick={() => {
              setIsOpen(true);
            }}
          />
        </div>
        <div>
          <div>
            <h4
              style={{
                fontSize: "20px",
                fontFamily: "Montserrat-SemiBold",
                fontWeight: 600,
                lineHeight: "150%"
              }}
            >
              Previous Drawdown Request
            </h4>
          </div>
        </div>
        {drawdownList ? (
          <div  className='table-alignment-css'>
            <Table
              columns={columns}
              data={sampleData}
              handleSort={handleSort}
              isAscending={isAscSort}
              sortBy={sortBy}
           customStyle={{ display: "grid", 
           gridTemplateColumns: "12% 12% 12% 10% 12% 12% 12% 8% 10%",
            height: "fit-Content",
               width: "100%"
                }}
            />
          </div>
        ) : null}
        {count ?  <Pagination
                onPageChange={handleChangePage}
                totalItems={count}
                itemsPerPage={rowsPerPage}
                showOptions ={true}
                rowsPerPageOptions={[10,20,30,40,50]}
                setRowLimit={setRowsPerPage}
              /> : null }
      </div>
      <>
      {isOpen ? (
           <DrawDownRequestUi
             setIsOpen={setIsOpen}
             productData={productData}
             firstDD={firstDD}
             productDetails={productDetails} 
             bData={borroData}
             balanceDetail={balanceDetail}            
           />
             ) : null}
      </>
        </> : null}

      {/* BULK UPLOAD */}
      {showBulkUpload ? (
        <BulkUpload />
      ) : null}

      {/* LINE DETAILS*/}
      {showLineDetails ? (
        <LineDetails />
      ) : null}
    </>
  );
}