import React, { useState } from 'react';
import '../components/uploadFileInput/UploadFileInput.css';

const stateMentButton = {
  border: 'none',
  outLine: 'none',
  background: 'transparent',
  color: '#134CDE',
  padding: '10px 16px 10px 5px',
  marginTop: '20px',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
};

const PasswordViewer = ({ docPassword, customStyle = {} }) => {
  const [showDocPassword, setShowDocPassword] = useState(false);
  return (
    <div>
      {docPassword && docPassword != '' ? (
        <div className="paraStyle" style={{ ...customStyle, display: 'flex', flexDirection: 'row', marginLeft: '10px', fontSize: '14px' }}>
          <div>{showDocPassword ? `Password - ` + docPassword : `Password - ` + '*'.repeat(docPassword.length)}</div>
          {
            <button onClick={() => setShowDocPassword(!showDocPassword)} style={{ ...stateMentButton, marginTop: '0px', fontSize: '13px', lineHeight: '0px' }} disabled={false}>
              {showDocPassword ? 'Hide' : 'Show'}
            </button>
          }
        </div>
      ) : null}
    </div>
  );
};

export default PasswordViewer;
