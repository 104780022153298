import Animation from '../../assets/Animation.gif';

export const GeneratingOfferComponent = () => {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <img src={Animation} alt="Animation" style={{ height: '200px', width: '200px' }} />
        <div
          style={{
            fontSize: '1.125rem',
            fontWeight: 600,
            lineHeight: '1.875rem',
            color: '#1C1C1C',
            fontFamily: 'Montserrat-Bold',
            marginBottom: '1.5rem',
          }}
        >
          {`Wait we're generating an offer`}
        </div>
      </div>
    </div>
  );
};
