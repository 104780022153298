import { documentCode } from 'msme/config/docCode';

// The file size mentioned here is in mb.
export const documentAttributes = {
  [documentCode.gst_certificate]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.shareholding_pattern]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.llp_agreement]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.list_of_partners]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.by_laws]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.hunter_report]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.crime_report]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.cb_pan]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.cb_pan_XML]: {
    fileType: '.xml, .json',
    fileSize: 5,
  },
  [documentCode.cb_aadhaar_front]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.cb_aadhaar_back]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.cb_aadhaar_XML]: {
    fileType: '.xml,.jsonf',
    fileSize: 5,
  },
  [documentCode.cb_selfie]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.cb_aadhaar_ovd]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.cb_screening_report]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.guar_pan]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.guar_pan_XML]: {
    fileType: '.xml, .json',
    fileSize: 5,
  },
  [documentCode.guar_aadhaar_front]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.guar_aadhaar_back]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.guar_aadhaar_XML]: {
    fileType: '.xml, .json',
    fileSize: 5,
  },
  [documentCode.guar_selfie]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.guar_aadhaar_ovd]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.guar_screening_report]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.gstr]: {
    fileType: '.pdf',
    fileSize: 10,
  },
  [documentCode.itr1]: {
    fileType: '.pdf',
    fileSize: 10,
  },
  [documentCode.itr2]: {
    fileType: '.pdf',
    fileSize: 10,
  },
  [documentCode.financial_statement1]: {
    fileType: '.pdf',
    fileSize: 10,
  },
  [documentCode.financial_statement2]: {
    fileType: '.pdf',
    fileSize: 10,
  },
  [documentCode.financial_statement3]: {
    fileType: '.pdf',
    fileSize: 10,
  },
  [documentCode.msme_bank_statement]: {
    fileType: '.pdf',
    fileSize: 10,
  },
  [documentCode.affidavit]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.name_change_certificate]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.lender_noc]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.deviation_certificate]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.clearance_certificate]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.water_bill]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.house_registry]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.relationship_proof]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.credit_card_bill]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.telecom_bill]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.self_declaration]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.loan_statement]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.vernacular_declaration]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.itr_json]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.bs_json]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.pa_cibil_report]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.cb_cibil_report]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.applicant_selfie]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.applicant_pan]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.applicant_pan_XML]: {
    fileType: '.xml,.json',
    fileSize: 5,
  },
  [documentCode.applicant_aadhaar_front]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.applicant_aadhaar_back]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.applicant_aadhaar_XML]: {
    fileType: '.xml, .json',
    fileSize: 5,
  },
  [documentCode.applicant_aadhaar_ovd]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.applicant_screening_report]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_business_pan]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_udhyam_certificate]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_shop_establishment]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_moa]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_aoa]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_coi]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_list_directors]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_board_resolution]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_authority_certificate]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_registration_certificate]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_partnership_deed]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_list_members]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.entity_screening_report]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.sanction_letter]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.lender_borrower_aggrement]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.commercial_bureau_report]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },

  [documentCode.moa]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },

  [documentCode.aoa]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.coi]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.partnership_deed]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.authority_letter]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.bureau_pull_consent]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.gst_certificate]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.list_of_directors]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.list_of_shareholders]: {
    fileType: '.jpg, .jpeg, .png, .pdf',
    fileSize: 5,
  },
  [documentCode.bank_statement_excel]: {
    fileType: '.xls, .xlsx, .csv',
    fileSize: 5,
  },
};
