export const drawDownRequestUiState = {
  loan_id: '',
  no_of_emi: '',
  drawdown_amount: '',
  withheld_percentage: '',
  withheld_amount: '',
  invoice_amount: '',
  invoice_date: '',
  collateral_percentage: '',
  collateral_amount: '',
  cc_utr_number: '',
  cc_utr_date: '',
  usage_fees_including_gst: '',
  net_drawdown_amount: '',
  repayment_days: '',
  processing_fees_including_gst: '',
};
