import boxShadows from 'assets/theme/box-shadow.js';

const componentStyles = () => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + '!important',
    border: '0!important',
  },
  cardHeaderRoot: {
    backgroundColor: 'initial!important',
  },
  textUppercase: {
    textTransform: 'uppercase',
  },
});

export default componentStyles;
