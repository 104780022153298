import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { useDispatch } from 'react-redux';

import Table from 'react-sdk/dist/components/Table';
import edit from '../../assets/img/edit.svg';

import ToggleSwitch from 'react-sdk/dist/components/ToggleSwitch/ToggleSwitch';

import { useHistory } from 'react-router-dom';
import { checkAccessTags } from '../../util/uam';
import { storedList } from '../../util/localstorage';
const user = storedList('user');

import { getAllServicesWatcher, toggleServiceStatusWatcher } from '../../actions/services';
import EditServices from './editServices';
import { AlertBox } from '../../components/AlertBox';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#82bdd6',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ManageService(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [severity, setSeverity] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [serviceLists, setServiceLists] = useState('');
  const [showEditForm, setShowEditForm] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [service, setService] = useState('');

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  const backToServiceList = () => {
    setShowEditForm(false);
    setShowTable(true);
  };

  const handleServiceList = () => {
    new Promise((resolve, reject) => {
      dispatch(getAllServicesWatcher(resolve, reject));
    })
      .then((response) => {
        setServiceLists(response.data);
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage(error.response.data.message);
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
      });
  };

  useEffect(() => {
    if (isTagged && checkAccessTags(['tag_manage_service_read', 'tag_manage_service_read_write'])) handleServiceList();
    if (!isTagged) handleServiceList();
  }, []);

  const toggleProductStatus = (e, id) => {
    const data = {
      id: id,
      status: e ? 0 : 1,
    };
    new Promise((resolve, reject) => {
      dispatch(toggleServiceStatusWatcher(data, resolve, reject));
    })
      .then((response) => {
        setAlert(true);
        setSeverity('success');
        setAlertMessage('Product status updated successfully');
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
        handleServiceList();
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage('error.response.data.message');
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
      });
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };
  const customCellCss = {
    width: 'fit-content',
  };
  const customTableClass = {
    width: '95%',
    marginLeft: '1%',
    gridTemplateColumns: `24% 45% 24% 10%`,
    overflowX: 'hidden',
    display: 'grid',
  };
  const handleNextStage = (item) => {
    history.push(`edit_service/${item._id}`);
    setShowEditForm(true);
    item.section = { label: item.section, value: item.section };
    item.type = { label: item.type, value: item.type };
    setService(item);
  };

  const columns = [
    {
      id: 'Service Id',
      label: 'Service Id',
      format: (rowData) => {
        return <div>{rowData?._id}</div>;
      },
    },
    {
      id: 'Service Name',
      label: 'Service Name',
      format: (rowData) => {
        return <div>{rowData?.service_name}</div>;
      },
    },

    {
      id: 'Status',
      label: 'Status',
      format: (rowData) => {
        return (
          <div style={{ marginRight: '0.938rem' }}>
            <ToggleSwitch checked={rowData.status ? true : false} onChange={(e) => toggleProductStatus(e, rowData._id)} />
          </div>
        );
      },
    },

    {
      id: 'Edit',
      label: 'Edit',
      format: (rowData) => (
        <div>
          <img onClick={() => handleNextStage(rowData)} src={edit} alt="editImg" />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div style={{ fontFamily: 'MontSerrat-Bold', fontSize: '18px', marginLeft: '1.5%' }}>Manage Service</div>
        {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
        {showEditForm ? (
          <Grid
            item
            xs={12}
            sx={{
              marginTop: '30px',
            }}
          >
            <EditServices selectedService={service} backToServiceList={backToServiceList} />
          </Grid>
        ) : null}

        {showTable && showEditForm == false && serviceLists.length ? <Table customStyle={customTableClass} customCellCss={customCellCss} data={serviceLists} columns={columns} /> : null}
      </div>
    </div>
  );
}
