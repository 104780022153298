import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { viewPartnerDetailsWatcher, fetchPartDocsWatcher, viewPartDocsWatcher, updatePartnerFormWatcher } from '../../actions/addPartner';
import { useParams } from 'react-router-dom';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import DocumentViewPopup from '../lending/DocumentViewPopup';
import PartnerDocumentUploadPopup from '../lending/PartnerDocumentUploadPopup';
import { b64ToBlob } from '../../util/helper';
import { appendScript } from '../../util/appendRemoveScript';
import UploadCard from 'react-sdk/dist/components/UploadCard';
import Button from 'react-sdk/dist/components/Button/Button';
import Alert from 'react-sdk/dist/components/Alert/Alert';

const PartnerDetails = (props) => {
  const { pid } = useParams();
  const dispatch = useDispatch();
  const [partnerData, setPartnerData] = useState(null);
  const [partnerDocumentArray, setPartnerDocumentArray] = useState([
    { name: 'COI', code: '145' },
    { name: 'MOA', code: '143' },
    { name: 'AOA', code: '144' },
    { name: 'Rating Document', code: '178' },
    { name: 'Financials for last 2 Financial Years', code: '139' },
    { name: 'Karza Report', code: '179' },
    { name: 'Google Scan - Entity', code: '180' },
    { name: 'Google Scan - Directors', code: '181' },
    { name: 'Undertaking to make payment to Escrow Account', code: '182' },
    { name: 'Anchor Approval CAM', code: '183' },
    { name: 'Others', code: '009' },
  ]);
  const [viewDocument, setViewDocument] = useState(false);
  const [viewDocumentUpload, setViewDocumentUpload] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [enabledFields, setEnabledFields] = useState(true);
  const [enabledVfCFFields, setEnabledVfCFFields] = useState(false);
  const [anchorLimit, setAnchorLimit] = useState('');
  const [vfLimitState, setVfLimitState] = useState('');
  const [cfLimitState, setCfLimitState] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  useEffect(() => {
    if (partnerData?.anchor_limit) {
      setAnchorLimit(partnerData?.anchor_limit);
    }
    if (partnerData?.cf_limit) {
      setCfLimitState(partnerData?.cf_limit);
    }
    if (partnerData?.vf_limit) {
      setVfLimitState(partnerData?.vf_limit);
    }
  }, [partnerData?.anchor_limit, partnerData?.anchor_limit, partnerData?.vf_limit]);

  const labelStyle = {
    marginLeft: '4%',
    alignSelf: 'center',
    fontSize: '0.875rem',
    fontFamily: 'Montserrat-Medium',
  };

  const fieldStyle = {
    padding: '0.313rem 0.626rem 0.313rem 0.313rem',
    borderRadius: '0.313rem',
    border: '0.063rem solid #BFC1CD',
    background: '#F6F7F9',
    height: '2.25rem',
    overflowX: 'hidden',
    wordWrap: 'break-word',
    maxWidth: '100%',
    fontFamily: 'Montserrat-Medium',
    width: '67%',
  };

  const documentStyle = {
    margin: '0.5rem 0 0 1rem',
    display: 'grid',
    gridColumnGap: '0',
    marginTop: '1.125rem',
    marginBottom: '2.813rem',
    gridTemplateColumns: '33% 33% 33%',
    marginLeft: '0.75rem',
  };

  const formFieldStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0.313rem 0 0.626rem 0',
  };

  const submitBtn = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    margin: '0.313rem 412px 0.626rem 0',
    width: '100%',
  };

  const customInputStyle = {
    width: '30rem',
    maxWidth: '28.125rem',
  };

  useEffect(() => {
    appendScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.5.141/pdf.min.js');
    dispatch(
      viewPartnerDetailsWatcher(
        { partner_id: pid },
        async (company) => {
          if (!(company?.cf_limit && company?.vf_limit)) {
            setEnabledVfCFFields(true);
          }
          setPartnerData(company);
        },
        (error) => {},
      ),
    );
  }, []);

  useEffect(() => {
    if (partnerData?._id) fetchPartnerDocs();
  }, [partnerData]);

  useEffect(() => {
    if (currentDoc?.blobUrl) {
      setViewDocument(true);
    }
  }, [currentDoc]);

  const fetchPartnerDocs = () => {
    dispatch(
      fetchPartDocsWatcher(
        { company_id: pid },
        async (docs) => {
          let copyPartnerDocumentArray = JSON.parse(JSON.stringify(partnerDocumentArray));
          docs?.data.forEach((element) => {
            copyPartnerDocumentArray.forEach((item) => {
              if (item.code === element.code) {
                item['doc_key'] = element.doc_key;
                item['file_url'] = element.file_url;
              }
            });
          });
          setPartnerDocumentArray(copyPartnerDocumentArray);
        },
        (error) => {},
      ),
    );
  };

  const handleUploadDoc = (doc) => {
    setCurrentDoc(doc);
    setViewDocumentUpload(true);
  };

  const handleViewDoc = (doc) => {
    let data = {
      company_id: pid,
      file_url: doc.file_url,
    };
    setCurrentDoc(doc);
    dispatch(
      viewPartDocsWatcher(
        { company_id: pid, file_url: doc.file_url },
        async (docs) => {
          handleDocumentPopUp(docs, null, doc);
        },
        (error) => {},
      ),
    );
  };

  const handleDocumentPopUp = (pdf, field, doc) => {
    try {
      let contentType = 'application/pdf';

      if (doc.code === '114' || doc.code === '116') {
        contentType = 'text/*';
        pdf = pdf.replace(/^data:(.*,)?/, '');
      }

      if (pdf.indexOf(`data:${contentType};base64,`) >= 0) pdf = pdf.replace(`data:${contentType};base64,`, '');
      const blob = b64ToBlob(pdf, contentType);
      const blobUrl = URL.createObjectURL(blob);
      setCurrentDoc({
        blobUrl: blobUrl,
        doc_key: doc.doc_key,
        doc_title: doc.name,
        currentDoc: doc,
        code: doc.code,
      });
    } catch (error) {}
  };

  const handleSubmit = async () => {
    let data = {
      anchor_limit: anchorLimit,
      cf_limit: cfLimitState,
      vf_limit: vfLimitState,
      company_id: pid,
      is_partner_anchor: partnerData.is_partner_anchor,
    };

    try {
      const response = await new Promise((resolve, reject) => {
        dispatch(updatePartnerFormWatcher(data, resolve, reject));
      });
      setAlert(true);
      setSeverity('success');
      console.log(response, 'response--');
      setAlertMessage(response?.message);
      setEnabledFields(true);
    } catch (error) {
      setAlert(true);
      setSeverity('error');
      console.log('error', error?.response?.data?.message);
      setAlertMessage(error?.response?.data?.message);
    }
  };

  const toggleFields = () => {
    setEnabledFields((prevState) => !prevState);
    console.log('hold');
  };

  const change = (event) => {
    console.log(event.id, 'helloworlds');
    if (event.id == 'vfLimit') {
      setVfLimitState(event.value);
    }
    if (event.id == 'anchorLimit') {
      setAnchorLimit(event.value);
    }
    if (event.id == 'cfLimit') {
      setCfLimitState(event.value);
    }
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  return (
    <div style={{ padding: '0.626rem 0.626rem 0 2.5rem' }}>
      {viewDocument && (
        <DocumentViewPopup
          title={currentDoc?.doc_title}
          handleClose={() => {
            setViewDocument(false);
          }}
          blobUrl={currentDoc?.blobUrl}
          openDialog={viewDocument}
          doc_key={currentDoc?.doc_key}
          doc={currentDoc}
        />
      )}
      {viewDocumentUpload && (
        <PartnerDocumentUploadPopup
          handleClose={() => {
            setViewDocumentUpload(false);
          }}
          openDialog={viewDocumentUpload}
          doc={currentDoc}
          refreshLoanDocs={() => {
            fetchPartnerDocs();
          }}
          loanData={{
            company_id: pid,
          }}
        />
      )}

      <div style={{ display: 'flex', width: '100%' }}>
        {partnerData && (
          <div style={{ display: 'flex', flexDirection: 'column', width: '46%', marginRight: '4%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ margin: '1.25rem 1.25 rem 0.626rem 0.626rem', fontFamily: 'Montserrat-Medium' }}>
                <HowToRegIcon style={{ marginRight: '0.626rem' }} />
                Partner Details
              </div>
              {partnerData?.is_partner_anchor === 'yes' && partnerData?.anchor_limit ? <Button buttonType="primary" isDisabled={!partnerData?.is_partner_anchor} customStyle={{ padding: '2px 10px', borderRadius: '8px', marginLeft: '28px', fontSize: '14px' }} label="Edit" onClick={toggleFields} /> : null}
            </div>
            <div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>Company ID</div>
                <InputBox label="" id={'companyId'} isDisabled={true} initialValue={partnerData._id} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>Company Name</div>
                <InputBox label="" id={'companyName'} isDisabled={true} initialValue={partnerData.name} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>CIN</div>
                <InputBox label="" id={'cin'} isDisabled={true} initialValue={partnerData.cin} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>GSTIN</div>
                <InputBox label="" id={'gstin'} isDisabled={true} initialValue={partnerData.gstin} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>Address</div>
                <InputBox label="" id={'address'} isDisabled={true} initialValue={partnerData.company_address} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>Website</div>
                <InputBox label="" id={'website'} isDisabled={true} initialValue={partnerData.website} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>Business Phone</div>
                <InputBox label="" id={'businessPhone'} isDisabled={true} initialValue={partnerData.business_phone} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>LMS Version</div>
                <InputBox label="" id={'lmsVersion'} isDisabled={true} initialValue={partnerData.lms_version} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>

              <div style={formFieldStyle}>
                <div style={labelStyle}>Short Code</div>
                <InputBox label="" id={'shortCode'} isDisabled={true} initialValue={partnerData.short_code || 'NA'} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>Partner Anchor</div>
                <InputBox label="" id={'is_partner_anchor'} isDisabled={true} initialValue={partnerData.is_partner_anchor || 'no'} customClass={fieldStyle} customInputClass={customInputStyle} />
              </div>
              <div style={formFieldStyle}>
                <div style={labelStyle}>Anchor Limit</div>
                <InputBox label="" id={'anchorLimit'} isDisabled={enabledFields} initialValue={anchorLimit} customClass={fieldStyle} customInputClass={customInputStyle} onClick={(event) => change(event)} />
              </div>

              <div style={formFieldStyle}>
                <div style={labelStyle}>CF Limit</div>
                <InputBox label="" id={'cfLimit'} isDisabled={enabledVfCFFields || enabledFields} initialValue={cfLimitState} customClass={fieldStyle} customInputClass={customInputStyle} onClick={(event) => change(event)} />
              </div>

              <div style={formFieldStyle}>
                <div style={labelStyle}>VF Limit</div>
                <InputBox label="" id={'vfLimit'} isDisabled={enabledVfCFFields || enabledFields} initialValue={vfLimitState} customClass={fieldStyle} customInputClass={customInputStyle} onClick={(event) => change(event)} />
              </div>
              {partnerData?.is_partner_anchor === 'yes' && partnerData?.anchor_limit ? (
                <div style={submitBtn}>
                  <Button
                    buttonType="primary"
                    isDisabled={enabledFields}
                    customStyle={{ height: '32px', borderRadius: '8px', width: '67%', fontSize: '14px' }}
                    label="Submit"
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div style={{ width: '50vh', height: '0.0626rem', backgroundColor: '#D8D9DE' }}></div>
            <div>
              <div style={{ margin: '0.626rem 1.25rem 0.626rem 0.626rem', fontFamily: 'Montserrat-Medium' }}>
                <HowToRegIcon style={{ marginRight: '0.626rem' }} />
                Director Details
              </div>
              {partnerData.directors.map((director, index) => (
                <div key={`Director ${index + 1}`} style={formFieldStyle}>
                  <div style={labelStyle}>{`Director ${index + 1}`}</div>
                  <InputBox label="" id={'director'} isDisabled={true} initialValue={director} customClass={fieldStyle} customInputClass={customInputStyle} />
                </div>
              ))}
            </div>
          </div>
        )}
        <div style={{ width: '50%' }}>
          <div style={{ marginTop: '1.25rem', fontFamily: 'Montserrat-Medium' }}>
            <TextSnippetIcon style={{ marginRight: '0.626rem' }} />
            Documents
          </div>
          <div style={documentStyle}>
            {partnerDocumentArray &&
              partnerDocumentArray.map((documentItem, index) => {
                return (
                  <UploadCard
                    key={index}
                    hasDocument={documentItem?.file_url ? true : false}
                    heading={documentItem.name}
                    viewOnClick={() => {
                      handleViewDoc(documentItem);
                    }}
                    uploadOnClick={() => {
                      handleUploadDoc(documentItem);
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>

      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} style={{ position: 'fixed', marginTop: '1vh' }} /> : null}
    </div>
  );
};
export default PartnerDetails;
