import { updatePreLoaderWatcher } from '../actions/user';
import { put, call, takeLatest } from 'redux-saga/effects';
import { getDisbursementRequestDetail } from '../apis/disbursementRequest';

export function* getDisbursementRequestEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getDisbursementRequestDetail, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getDisbursementRequestWatcherSaga() {
  yield takeLatest('GET_DISBURSEMENT_REQUEST_WATCHER', getDisbursementRequestEffectSaga);
}
