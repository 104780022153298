import loanCreation from './views/loans/loanCreation/loanCreation';
import loanDetailsAccordion from './views/loans/loanDetails/loanDetailsAccordion';
import settlementRequest from '../views/ServiceRequest/SettlementRequest';
import Recon from '../views/recon/Recon';
import FeesAndCharges from '../views/lending/chargesDemographic';
import AddNewWaiverRequest from '../views/lending/AddNewWaiverRequest.js';
import ForeclosureOfferRequest from '../views/ServiceRequest/ForeclosureOfferRequest.js';
import LoanRepaymentSchedule from '../views/lending/repaymentScheduleDemographics';
import { Leads, Loans, BookLoans, LoanDetails, SanctionLenderLoan, SanctionLenderLead, LeadReview, Natch, CreateLoan, EditBookLoans } from './views/msme.view';

var routes = [
  {
    path: '/msme/book_loan',
    name: 'Book Loan',
    miniName: 'BL',
    component: BookLoans,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: false,
    tags: ['tag_msme_write'],
  },
  {
    path: '/msme/leads/natch',
    name: 'Natch',
    miniName: 'NCH',
    component: Natch,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_lead_read', 'tag_msme_lead_read_write'],
  },
  {
    path: '/msme/leads/sanction-Lender',
    name: 'Sanction & Lender',
    miniName: 'SL',
    component: SanctionLenderLead,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_lead_read', 'tag_msme_lead_read_write'],
  },
  {
    path: '/msme/leads/:id',
    name: 'Lead Review',
    miniName: 'LE',
    component: LeadReview,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_lead_read', 'tag_msme_lead_read_write'],
  },
  {
    path: '/msme/lead/:id/view',
    name: 'Book Loans',
    miniName: 'BL',
    component: EditBookLoans,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_lead_read', 'tag_msme_lead_read_write'],
  },
  {
    path: '/msme/lead/:id/edit',
    name: 'Book Loans',
    miniName: 'BL',
    component: EditBookLoans,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_lead_read', 'tag_msme_lead_read_write'],
  },
  {
    path: '/msme/leads',
    name: 'Leads',
    miniName: 'LE',
    component: Leads,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: false,
    tags: ['tag_msme_lead_read', 'tag_msme_lead_read_write'],
  },
  {
    path: '/msme/loans/create',
    name: 'Create Loan',
    miniName: 'CL',
    component: CreateLoan,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_loan_read', 'tag_msme_loan_read_write'],
  },
  {
    path: '/msme/loans/loan_creation/:id',
    name: 'Loan creation',
    miniName: 'LC',
    component: loanCreation,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_loan_read', 'tag_msme_loan_read_write'],
  },
  {
    path: '/msme/loans/loan_details',
    name: 'Loans Details',
    miniName: 'LD',
    component: LoanDetails,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_loan_read', 'tag_msme_loan_read_write'],
  },
  {
    path: '/msme/loans/sanction-Lender',
    name: 'Sanction & Lender',
    miniName: 'SL',
    component: SanctionLenderLoan,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_loan_read', 'tag_msme_loan_read_write'],
  },
  {
    path: '/msme/loans',
    name: 'Loans',
    miniName: 'LO',
    component: Loans,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: false,
    tags: ['tag_msme_loan_read', 'tag_msme_loan_read_write'],
  },
  {
    path: '/msme/loan_details/:loan_id/:company_id/:product_id',
    name: 'Loans Details',
    miniName: 'LD',
    component: loanDetailsAccordion,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/loan_details/:loan_id/:company_id/:product_id/natch',
    name: 'Natch',
    miniName: 'NCH',
    component: Natch,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/loan_details/:loan_id/:company_id/:product_id/sanction-Lender',
    name: 'Sanction & Lender',
    miniName: 'SL',
    component: SanctionLenderLead,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/settlement-request/:loan_id/:company_id/:product_id',
    name: 'Settlement Request',
    miniName: 'SR',
    component: settlementRequest,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/loan-recon-details/:company_id/:product_id/:loan_id',
    name: 'Loan Recon',
    miniName: 'LR',
    component: Recon,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/charges/:company_id/:product_id/:loan_id',
    name: 'Fees & Charges',
    miniName: 'FC',
    component: FeesAndCharges,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/waiver-request-list/:company_id/:product_id/:loan_id',
    name: 'Waiver Request List',
    miniName: 'WRL',
    component: AddNewWaiverRequest,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/waiver-request-list/:company_id/:product_id/:loan_id',
    name: 'Waiver Request List',
    miniName: 'WRL',
    component: AddNewWaiverRequest,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/foreclosure-offers-requests/:loan_id/:company_id/:product_id',
    name: 'Foreclosure Offers Requests',
    miniName: 'FOR',
    component: ForeclosureOfferRequest,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/repaymentSchedule',
    name: 'Loan Repayment Schedule',
    miniName: 'LRS',
    component: LoanRepaymentSchedule,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
  {
    path: '/msme/foreclosure-offers-requests/:loan_id/:company_id/:product_id',
    name: 'Foreclosure Offers Requests',
    miniName: 'FOR',
    component: ForeclosureOfferRequest,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_msme_read', 'tag_msme_write'],
  },
];

export default routes;
