import React from 'react';
import './productCard.css';
import { convertIntoINR } from '../../../util/partners/helper';
const ProductCard = ({ productTitle, keys, values }) => {
  const renderKeyValuePairs = () => {
    if (!keys || !Array.isArray(keys) || !values || typeof values !== 'object') {
      return null;
    }

    const pairs = keys.map((key, index) => (
      <div key={key} className="product-card-key-value">
        <div className="product-card-key">{key}</div>
        <div className="product-card-value">{values[key] ? `₹ ${convertIntoINR(values[key])}` : '₹ 0'}</div>
      </div>
    ));

    const rows = [];
    for (let i = 0; i < pairs.length; i += 3) {
      rows.push(
        <div key={i} className="product-card-row">
          {pairs.slice(i, i + 3)}
        </div>,
      );
    }

    return rows;
  };

  return (
    <div className="product-card-container-style">
      <div className="product-card-title-container">
        <div className="product-card-title">{productTitle}</div>
      </div>
      <div className="product-card-key-values-container">{renderKeyValuePairs()}</div>
    </div>
  );
};

export default ProductCard;
