import * as React from 'react';
import { useState, useEffect } from 'react';
import 'react-sdk/dist/styles/_fonts.scss';
import TextField from '@mui/material/TextField';
import useDimensions from '../../../../../hooks/useDimensions';
import Button from 'react-sdk/dist/components/Button';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import { patchMsmeDetailsWatcher } from '../../../../actions/msme.action';
import { useDispatch } from 'react-redux';
import { storedList } from '../../../../../util/localstorage';

const user = { _id: storedList('user')?._id, id: storedList('user')?.id };

export default function LeadReviewAdditionalDocumentsForm(props) {
  const { loanDetailsData, setShouldFetch, createMsmeActivityLog } = props;

  const [selectReasonComment, setSelectReasonComment] = useState('');
  const [stateValidationData, setStateValidationData] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setInitialData();
  }, [loanDetailsData]);

  const setInitialData = () => {
    setSelectReasonComment(loanDetailsData?.additional_documents?.addi_docs_comment);
    setIsChanged(false);
  };

  const { innerWidth, innerHeight } = useDimensions();
  const styles = useStyles({ innerWidth, innerHeight });

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const showAlert = (msg, type) => {
    const element = document.getElementById('TopNavBar');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const handleSubmit = () => {
    let payload = {
      user_id: user._id,
      msme_company_id: loanDetailsData.company_id,
      msme_product_id: loanDetailsData.product_id,
      loan_app_id: loanDetailsData.loan_app_id,
      section: 'additional-documents',
      addi_docs_comment: selectReasonComment,
    };

    new Promise((resolve, reject) => {
      dispatch(patchMsmeDetailsWatcher(payload, resolve, reject));
    })
      .then((res) => {
        showAlert('Lead has been successfully updated', 'success');
        setShouldFetch((prev) => prev + 1);
        createMsmeActivityLog('additional_doc');
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  return (
    <div style={{ margin: '30px' }}>
      <h3 style={{ fontFamily: 'Montserrat-SemiBold', fontSize: '20px' }}>Please provide any relevant information here</h3>
      <div style={{ marginTop: '15px' }}>
        <TextField
          sx={{
            width: '98%',
            color: 'pink',
            fontFamily: 'Montserrat-Regular',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '150%',
          }}
          id="outlined-basic"
          label="Comment"
          value={selectReasonComment}
          variant="outlined"
          type="text"
          placeholder="Comment"
          multiline={true}
          minRows={4}
          size="medium"
          InputLabelProps={{ shrink: true }}
          InputProps={{ sx: { borderRadius: '8px' } }}
          onChange={(event) => {
            setIsChanged(true);
            setSelectReasonComment(event.target.value);
          }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '20px' }}>
        <div style={styles.leadReviewButtonContainer}>
          <Button
            buttonType="custom"
            label={'Discard'}
            onClick={() => setInitialData()}
            isDisabled={!isChanged}
            customStyle={{
              ...styles['customStyleButtonComment'],
              ...(!isChanged ? { backgroundColor: 'var(--neutrals-neutral-30, #CCCDD3)', color: 'var(--base-white, #FFF)', borderColor: 'var(--neutrals-neutral-30, #CCCDD3)' } : { backgroundColor: '#FFF', color: '#134CDE', borderColor: '#134CDE' }),
            }}
            validationData={stateValidationData}
          />
          <Button buttonType="custom" label="Save" onClick={handleSubmit} isDisabled={!isChanged} customStyle={{ ...styles['customStyleButton'], ...(!isChanged ? { backgroundColor: 'var(--neutrals-neutral-30, #CCCDD3)', color: 'var(--base-white, #FFF)' } : { backgroundColor: '#134CDE', color: 'white' }) }} validationData={stateValidationData} />
        </div>
      </div>
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}

const useStyles = ({ innerWidth, innerHeight }) => {
  return {
    customPopupStyles: {
      width: innerWidth > 900 ? '40vw' : innerWidth > 600 ? '50vw' : '80vw',
      height: '45vh',
      marginRight: '50vh',
    },
    customPopupStylesOustideModal: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    leadReviewButtonContainer: {
      paddingBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px',
    },
    customStyleButton: {
      backgroundColor: '#134CDE',
      color: 'white',
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    customStyleButtonComment: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      backgroundColor: '#FFF',
      borderColor: '#134CDE',
      color: '#134CDE',
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    negativeButton: {
      background: '#FFFFFF',
      color: '#475BD8',
      border: '1px solid #475BD8',
    },
  };
};
