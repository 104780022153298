import * as React from 'react';
import { bookLoansFormJsonFields } from './bookLoansFormJson';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storedList } from '../../../util/localstorage';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import 'react-sdk/dist/styles/_fonts.scss';
import { verifyDateAfter1800 } from '../../../util/helper';
import { validateData } from '../../utils/validation';
import { entityType } from '../../config/entityType';
import UploadFileInput from '../../components/uploadFileInput/UploadFileInput';
import { SectionData } from '../../config/sectionData';
import getSectionStatus from './GetLeadSectionStatus/GetLeadSectionStatus';
import getSubSectionRemarks from './GetLeadSectionStatus/GetLeadSubSectionRemarks';
import CustomDatePicker from 'react-sdk/dist/components/DatePicker/DatePicker';
import moment from 'moment';
import { getBookLoanDetailsWatcher, getLapLoanDocumentsWatcher } from '../../actions/bookLoan.action';
import { patchLapSaveAsDraftDetailsWatcher } from '../../actions/lap.action';
import { States, Cities } from '../../../constants/country-state-city-data';
import { patchLapDetailsWatcher, putLapDraftSaverWatcher } from '../../actions/lap.action';
import { GSTIN, Shop_Establishment_Certificate, Entity_KYC_Partnership_Upload, Entity_KYC_Authority_Letter_Upload, URC_Certificate, Entity_LOD_Upload, Entity_AOA_Upload, Entity_COI_Upload, Entity_MOA_Upload } from './uploadKycData';
import './bookLoans.style.css';
import './entityDetailsForm.style.css';
import { getLeadStatusWatcher } from '../../actions/lead.action';
import { getSubmitButton } from '../../util/resuableJsx/reusableJsx';
import { getSaveAsDraftButton } from 'lap/util/resuableJsx/reusableJsx';
import { Number_Constants } from '../../constants/number_constants';
import { Application_Mode, Entity_Type, Field_Label, Section_Status } from '../../constants/string_constants';
import SlidingAlert from '../../components/SlidingAlert/SlidingAlert';
import { postComprehensiveLogsWatcher } from 'actions/comprehensiveLogs.action';

const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
const sectionName = 'entity-details';

const BOOK_LOAN_FORM_JSON = bookLoansFormJsonFields();

const fetchObjectFieldsByDept = (deptName) => {
  const matchingObjects = [];
  for (let object of BOOK_LOAN_FORM_JSON) {
    if (object.dept === deptName) {
      matchingObjects.push(object.field);
    }
  }
  return matchingObjects;
};

const disabledFields = {
  view: [
    ...fetchObjectFieldsByDept('Entity Details'),
    ...fetchObjectFieldsByDept('Entity Details 1'),
    ...fetchObjectFieldsByDept('Communication Address'),
    ...fetchObjectFieldsByDept('Registered Address'),
    ...fetchObjectFieldsByDept('Entity-KYC pvtLtd'),
    ...fetchObjectFieldsByDept('Entity-KYC partnership'),
    ...fetchObjectFieldsByDept('Entity-KYC proprietor'),
    ...fetchObjectFieldsByDept('Entity-KYC individual'),
    ...fetchObjectFieldsByDept('Entity-KYC GSTIN'),
    ...fetchObjectFieldsByDept('Entity-KYC Shop Establishment Type'),
  ],
  edit: [],
};

const setDocumentView = (TitleOb, documents) => {
  return TitleOb.map((givenObj) => {
    const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
    if (matchingObj) {
      return {
        ...givenObj,
        s3_url: matchingObj.file_url,
        doc: matchingObj,
      };
    } else {
      return givenObj;
    }
  });
};

const checkDocStatus = (data) => {
  for (let ob of data) {
    if (!ob?.s3_url && ob.isRequired) {
      return false;
    }
  }
  return true;
};

export default function EntityDetailsForm(props) {
  const dispatch = useDispatch();

  const { setNavState, setNavIconPrefixState, loanAppId, entityData, setEntityData, navIconPrefixState, LAPCompanyId, LAPProductId, leadStatus, setLeadStatus, setShowShareHolding, documents, setShouldFetch } = props;
  const store = useSelector((state) => state);
  const [statusObject, setStatusObject] = useState('');
  const [stateData, setStateData] = useState(entityData ?? {});
  const [previousApplicantData, setPreviousApplicantData] = useState();
  const [validationData, setValidationData] = useState({});
  const [selectedFileType, setSelectedFileType] = useState('GSTIN');
  const [selectedEntityType, setSelectedEntityType] = useState(entityData ? entityData[`string_vl_select_entity_type`] : null);
  const [viewRegAddress, setViewRegAddress] = useState(true);
  const [newFile, setNewFile] = useState('');
  const [validForm, setValidForm] = useState(false);
  const [states, setStatesState] = useState(States);
  const [commCity, setCommCityState] = useState([]);
  const [regCity, setRegCityState] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [urcBorder, setUrcBorder] = useState('border: 1px solid #BBBFCC');
  const [gstBorder, setGstBorder] = useState('border: 1px solid #BBBFCC');
  const [isUrcVerified, setIsUrcVerified] = useState(false);
  const [isPanVerified, setIsPanVerified] = useState(false);
  const [urcButtonState, setUrcButtonState] = useState('button');
  const [cinBorder, setCinBorder] = useState('border: 1px solid #BBBFCC');
  const [isCinVerified, setIsCinVerified] = useState(false);
  const [cinButtonState, setCinButtonState] = useState('button');
  const [gstButtonState, setGstButtonState] = useState('button');
  const [disableDraftButton, setDisableDraftButton] = useState(false);
  const [base64String, setBase64String] = useState('');
  const [gstCertificateFileName, setGstCertificateFileName] = useState('');
  const [sectionStatusCheck, setSectionStatusCheck] = useState('');
  const [isLeadRejected, setIsLeadRejected] = useState(false);
  const sectionStatus = ['deviation', 'approved', 'rejected', 'failed'];
  const [documentStateData, setDocumentStateData] = useState({
    urc_certificate_value: false,
    entity_kyc_partnerShip_deed: false,
    entity_kyc_partnerShip_authority_letter: false,
    entity_kyc_partnerShip_gstin_certificate: false,
    entity_kyc_pvtLtd_lod: false,
    entity_kyc_pvtLtd_aoa: false,
    entity_kyc_pvtLtd_coi: false,
    entity_kyc_pvtLtd_moa: false,
    entity_supportDocuments_gstin_upload: false,
    entity_supportDocuments_shop_establishment_upload: false,
  });
  const [panButtonState, setPanButtonState] = useState('button');
  const [backgroundColorBlur, setBackgroundColorBlur] = useState(true);
  const [panBorder, setPanBorder] = useState('border: 1px solid #BBBFCC');
  const [showGstinSection, setShowGstinSection] = useState(true);
  const [commNatureOfOwnership, setCommNatureOfOwnership] = useState('Owned');
  const [perNatureOfOwnership, setPerNatureOfOwnership] = useState('Owned');
  const [entityURCDocUpload, setEntityURCDocUpload] = useState(URC_Certificate);
  const [entityPartnerShipDeedDocUpload, setentityPartnerShipDeedDocUpload] = useState(Entity_KYC_Partnership_Upload);
  const [entity_KYC_Authority_Letter_Upload, setEntity_KYC_Authority_Letter_Upload] = useState(Entity_KYC_Authority_Letter_Upload);
  const [entityLODDocUpload, setEntityLODDocUpload] = useState(Entity_LOD_Upload);
  const [entityAOADocUpload, setEntityAOADocUpload] = useState(Entity_AOA_Upload);
  const [entityCOIDocUpload, setEntityCOIDocUpload] = useState(Entity_COI_Upload);
  const [entityMOADocUpload, setEntityMOADocUpload] = useState(Entity_MOA_Upload);
  const [shopEstablishmentDocUpload, setShopEstablishmentDocUpload] = useState(Shop_Establishment_Certificate);
  const [gstinDocUpload, setGstInDocUpload] = useState(GSTIN);

  let intervalId;

  const fetchLeadStatus = () => {
    const payload = {
      loan_app_id: loanAppId,
      companyId: LAPCompanyId,
      productId: LAPProductId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLeadStatusWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setStatusObject(response.find((item) => item.section_code === 'entity'));
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  useEffect(() => {
    if ((props.type == Application_Mode.Edit || props.type == Application_Mode.View) && entityData) {
      handleEditView();
    }
  }, [props.type, entityData, props.loanDetailsSubsectionStatus]);

  const handleEditView = async () => {
    setStateData(entityData);
    setViewRegAddress(entityData.address_same ? false : true);
    if (props.loanDetailsSubsectionStatus && props.loanDetailsSubsectionStatus[200]) {
      const sectionStatus = props.loanDetailsSubsectionStatus[200];
      if (sectionStatus['entity_udyam'] == 'approved') {
        setIsUrcVerified(true);
        setUrcButtonState('icon');
        setUrcBorder('1px solid green');
        if (entityData && entityData.udyam_vintage_flag && entityData.udyam_vintage_flag !== 'approved') {
          setShowGstinSection(true);
        }
      } else if (sectionStatus['entity_udyam'] == 'deviation') {
        setIsUrcVerified(true);
        setUrcButtonState('icon');
        setUrcBorder('1px solid #f5781f');
        if (entityData && entityData.udyam_vintage_flag && entityData.udyam_vintage_flag !== 'approved') {
          setShowGstinSection(true);
        }
      }
      if (sectionStatus['entity_pan'] == 'approved') {
        setIsPanVerified(true);
        setPanButtonState('icon');
        setPanBorder('1px solid green');
      } else if (sectionStatus['entity_pan'] == 'deviation') {
        setIsPanVerified(true);
        setPanButtonState('icon');
        setPanBorder('1px solid #f5781f');
      }
      if (sectionStatus['entity_gst'] == 'approved') {
        setGstBorder('1px solid green');
        showAlert('GSTIN Verified Successfully', 'success');
        setGstButtonState('icon');
      } else if (sectionStatus['entity_gst'] == 'deviation') {
        setGstBorder('1px solid #f5781f');
        showAlert('GSTIN Verified Successfully', 'success');
        setGstButtonState('icon');
      }
      if (sectionStatus['entity_cin'] == 'approved') {
        setIsCinVerified(true);
        setCinBorder('1px solid green');
        setCinButtonState('icon');
      } else if (sectionStatus['entity_cin'] == 'deviation') {
        setIsCinVerified(true);
        setCinBorder('1px solid #f5781f');
        setCinButtonState('icon');
      }
    }
    if (props.loanDetailsStatus && props.loanDetailsStatus['entity'] == 'rejected') {
      setIsLeadRejected(true);
    }
  };

  const inputBoxCss = {
    marginTop: '8px',
    maxHeight: '500px',
    zIndex: 1,
    width: '105%',
  };
  const fileInputRef = useRef(null);
  const headingCss = {
    color: 'var(--neutrals-neutral-100, #161719)',
    fontFamily: 'Montserrat-semibold',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '150%',
    marginBottom: '24px',
    marginTop: '44px',
  };

  const radioCss = {
    color: 'var(--neutrals-neutral-100, #161719)',
    fontFamily: 'Montserrat-Regular',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
  };
  const radioInputCss = {
    accentColor: '#134CDE',
    marginRight: '8px',
    marginBottom: '4px',
    height: '20px',
    width: '20px',
    verticalAlign: 'middle',
  };

  const getLoanDocuments = () => {
    const payload = {
      loanAppID: loanAppId,
      companyId: LAPCompanyId,
      productId: LAPProductId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLapLoanDocumentsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        if (response) {
          let data;
          let stateDoc = {
            urc_certificate_value: false,
            entity_kyc_partnerShip_deed: false,
            entity_kyc_partnerShip_authority_letter: false,
            entity_kyc_partnerShip_gstin_certificate: false,
            entity_kyc_pvtLtd_lod: false,
            entity_kyc_pvtLtd_aoa: false,
            entity_kyc_pvtLtd_coi: false,
            entity_kyc_pvtLtd_moa: false,
            entity_supportDocuments_gstin_upload: false,
            entity_supportDocuments_shop_establishment_upload: false,
          };

          if (selectedEntityType == 'Individual') {
            data = setDocumentView(URC_Certificate, response);
            setEntityURCDocUpload(data);
            stateDoc['urc_certificate_value'] = checkDocStatus(data);

            data = setDocumentView(GSTIN, response);
            setGstInDocUpload(data);
            stateDoc['entity_supportDocuments_gstin_upload'] = checkDocStatus(data);

            data = setDocumentView(Shop_Establishment_Certificate, response);
            setShopEstablishmentDocUpload(data);
            stateDoc['entity_supportDocuments_shop_establishment_upload'] = checkDocStatus(data);
          } else if (selectedEntityType == 'Proprietor') {
            data = setDocumentView(URC_Certificate, response);
            setEntityURCDocUpload(data);
            stateDoc['urc_certificate_value'] = checkDocStatus(data);

            data = setDocumentView(GSTIN, response);
            setGstInDocUpload(data);
            stateDoc['entity_supportDocuments_gstin_upload'] = checkDocStatus(data);

            data = setDocumentView(Shop_Establishment_Certificate, response);
            setShopEstablishmentDocUpload(data);
            stateDoc['entity_supportDocuments_shop_establishment_upload'] = checkDocStatus(data);
          } else if (selectedEntityType == 'Partnership') {
            data = setDocumentView(URC_Certificate, response);
            setEntityURCDocUpload(data);
            stateDoc['urc_certificate_value'] = checkDocStatus(data);

            data = setDocumentView(GSTIN, response);
            setGstInDocUpload(data);
            stateDoc['entity_kyc_partnerShip_gstin_certificate'] = checkDocStatus(data);

            data = setDocumentView(Entity_KYC_Partnership_Upload, response);
            setentityPartnerShipDeedDocUpload(data);
            stateDoc['entity_kyc_partnerShip_deed'] = checkDocStatus(data);

            data = setDocumentView(Entity_KYC_Authority_Letter_Upload, response);
            setEntity_KYC_Authority_Letter_Upload(data);
            stateDoc['entity_kyc_partnerShip_authority_letter'] = checkDocStatus(data);
          } else if (selectedEntityType == 'Private Limited') {
            data = setDocumentView(URC_Certificate, response);
            setEntityURCDocUpload(data);
            stateDoc['urc_certificate_value'] = checkDocStatus(data);

            data = setDocumentView(Entity_AOA_Upload, response);
            setEntityAOADocUpload(data);
            stateDoc['entity_kyc_partnerShip_gstin_certificate'] = checkDocStatus(data);

            data = setDocumentView(Entity_LOD_Upload, response);
            setEntityLODDocUpload(data);
            stateDoc['entity_kyc_partnerShip_deed'] = checkDocStatus(data);

            data = setDocumentView(Entity_COI_Upload, response);
            setEntityCOIDocUpload(data);
            stateDoc['entity_kyc_partnerShip_authority_letter'] = checkDocStatus(data);

            data = setDocumentView(Entity_MOA_Upload, response);
            setEntityMOADocUpload(data);
            stateDoc['entity_kyc_partnerShip_authority_letter'] = checkDocStatus(data);
          }
          setDocumentStateData(stateDoc);
        }
      })
      .catch((error) => {
        showAlert(error.response?.data?.message, 'error');
      });
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
  };

  const handleGetCommCities = async (value, name) => {
    setCommCityState(Cities(value));
  };

  const handleGetRegCities = async (value, name) => {
    setRegCityState(Cities(value));
  };

  const change = (e, type, name) => {
    const buttonText = e.target?.textContent;
    const valued = buttonText;
    if (valued === 'Verify') {
      if (name === 'entity_kyc_proprietor_urc_no' || name === 'entity_kyc_individual_urc_no' || name === 'entity_kyc_partnerShip_urc_no' || name === 'entity_kyc_pvtLtd_urc_no') urcVerify(name, type);
      if (name === 'entity_kyc_pvtLtd_cin_llpin') cinVerify(name, type);
      if (name === 'gstin_value' || name === 'entity_kyc_partnerShip_gstin_no' || name === 'entity_kyc_pvtLtd_gstin_no') gstValueVerify(name, type);
      if (name === 'entity_kyc_partnerShip_pan' || name === 'entity_kyc_pvtLtd_pan') verifyPan(name, type);
    } else {
      let values = e.value;
      if (name === 'entity_kyc_pvtLtd_pan' || name === 'entity_kyc_partnerShip_pan') {
        values = e.value.toUpperCase();
        if (values?.length >= 10) {
          values = values.substring(0, 10);
        }
      } else if (name === 'comm_addr_pincode' || name === 'reg_addr_pincode') {
        values = e.value;
        if (values?.length >= 6) {
          values = values.substring(0, 6);
        }
      } else if (name === 'comm_addr_ln1' || name === 'reg_addr_ln1' || name === 'comm_addr_ln2' || name === 'reg_addr_ln2') {
        values = e.value;
        if (values?.length >= 65) {
          values = values.substring(0, 65);
        }
      } else if (name === 'comm_nature_of_ownership') {
        values = e.value;
        setCommNatureOfOwnership(e.value);
      } else if (name === 'reg_nature_of_ownership') {
        values = e.value;
        setPerNatureOfOwnership(e.value);
      }
      const value = values;
      let field = `${type}_vl_${name}`;
      let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), value);
      if (field.indexOf('comm') != -1 && !viewRegAddress) {
        const perField = field.replace('comm', 'reg');
        let isValidData = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
        setStateData((prevState) => ({
          ...prevState,
          [perField]: value,
        }));
        setValidationData((prevState) => ({
          ...prevState,
          [`${perField}State`]: !isValidData ? 'has-danger' : '',
        }));
      }
      if (field === 'string_vl_per_addr_ln1') {
        if (value.length <= 4) {
          isValid = false;
        }
      }
      if (name === 'entity_name') {
        const pattern = /^[a-zA-Z\s]{1,65}$/;
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: value && !pattern.test(value) ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'work_profile') {
        const pattern = /^[a-zA-Z\s]+$/;
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: value && !pattern.test(value) ? 'has-errorHelperTextRequired' : value && value.length > 65 ? 'has-errorHelperText1Required' : value === '' || !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'monthly_income') {
        const pattern = /^[1-9]\d*(\.\d+)?$/;
        const pattern2 = /^(?!0\.0*$)[1-9]\d*(\.\d{1,2})?$/;
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: value && !pattern.test(value) ? 'has-errorHelperTextRequired' : value && !pattern2.test(value) ? 'has-errorHelperText1Required' : !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'comm_monthly_rent' || name === 'reg_monthly_rent') {
        const pattern = /^[1-9]\d*(\.\d+)?$/;
        const pattern2 = /^(?!0\.0*$)[1-9]\d*(\.\d{1,2})?$/;
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: value && !pattern.test(value) ? 'has-errorHelperTextRequired' : value && !pattern2.test(value) ? 'has-errorHelperText1Required' : !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'comm_addr_pincode' || name === 'reg_addr_pincode') {
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: value && value.length >= 1 && value.length < 6 ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'reg_addr_ln2') {
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: value !== '' && !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'entity_kyc_proprietor_urc_no' || name === 'entity_kyc_individual_urc_no' || name === 'entity_kyc_partnerShip_urc_no' || name === 'entity_kyc_pvtLtd_urc_no') {
        const pattern = /^[A-Z]{5}-[A-Z]{2}-\d{2}-\d{7}$/;
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: value && !pattern.test(value) ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'entity_kyc_partnerShip_pan' || name === 'entity_kyc_pvtLtd_pan') {
        let pattern;
        if (selectedEntityType === 'Individual') {
          pattern = /^([A-Z]{3}(P){1}[A-Z]{1})(\d{4})([A-Z]{1})$/;
        } else {
          pattern = /^([A-Z]{3}(C){1}[A-Z]{1})(\d{4})([A-Z]{1})$/;
        }
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: value && !pattern.test(value) ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
        }));
      } else {
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid ? 'has-danger' : '',
        }));
      }
      setStateData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  useEffect(() => {
    if (!entityData) {
      setInitialState();
      handleRegisteredAddress(true);
    }
    setStateData((prevState) => ({
      ...prevState,
      string_vl_select_entity_type: selectedEntityType,
    }));
    // Setting share holding pattern in navigation based on the entity type
    setShowShareHolding(selectedEntityType && !['Individual'].includes(selectedEntityType));
  }, [selectedEntityType]);

  const commonFieldValidation = (forceCall = false) => {
    if (selectedEntityType) {
      let vlForm = 1;
      let vlDocument = 0;
      bookLoansFormJsonFields().map((item, idx) => {
        if ((selectedEntityType !== 'Individual' && item.dept == 'Entity Details 1') || (selectedEntityType === 'Individual' && item.dept == 'Entity Details') || item.dept == 'Communication Address' || item.dept == 'Registered Address') {
          if (item.isOptional == false) {
            if (!stateData[`${item.type}_vl_${item.field}`]) {
              if (item.field != 'reg_monthly_rent' && item.field != 'comm_monthly_rent') {
                vlForm = 0;
                if (forceCall) {
                  setValidationData((prevState) => ({
                    ...prevState,
                    [`${item.type}_vl_${item.field}State`]: 'has-danger',
                  }));
                }
              }
            }
          }
        }
      });
      if (forceCall) return vlForm;
      if (selectedEntityType === Entity_Type.Individual || selectedEntityType === Entity_Type.Proprietor) {
        if (selectedFileType === Field_Label.GSTIN) {
          if (documentStateData.urc_certificate_value) {
            vlDocument = 1;
          }
        } else {
          if (documentStateData.entity_supportDocuments_shop_establishment_upload) {
            vlDocument = 1;
          }
        }
      }
      if (selectedEntityType === Entity_Type.PrivateLimited) {
        if (documentStateData.urc_certificate_value && documentStateData.entity_ppl_lod && documentStateData.entity_opc_aoa && documentStateData.entity_ppl_coi && documentStateData.entity_opc_moa) {
          vlDocument = 1;
        }
      }
      if (selectedEntityType === Entity_Type.Partnership) {
        if (documentStateData.urc_certificate_value && documentStateData.entity_kyc_partnerShip_authority_letter && documentStateData.entity_kyc_partnerShip_deed) {
          vlDocument = 1;
        }
      }
      if (vlForm && vlDocument) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    }
  };
  useEffect(() => {
    commonFieldValidation(false);
  }, [stateData, gstButtonState, urcButtonState, cinButtonState, documentStateData, panButtonState, showGstinSection]);

  const FetchEntityData = (stage = 'urc') => {
    if (loanAppId && LAPCompanyId && LAPProductId) {
      const payload = {
        loan_app_id: loanAppId,
        companyId: LAPCompanyId,
        productId: LAPProductId,
        user: user,
      };
      new Promise((resolve, reject) => {
        dispatch(getBookLoanDetailsWatcher(payload, resolve, reject));
      })
        .then((response) => {
          setLeadStatus(response?.data?.[0]?.lead_status);
          let entityDetails = response?.data?.[0]?.entity_details || {};
          if (stage === 'urc') {
            if (entityDetails && entityDetails.udyam_vintage_flag && entityDetails.udyam_vintage_flag !== 'approved') {
              setShowGstinSection(true);
            }
            if (entityDetails) {
              setStateData({ ...stateData, udyam_vintage_flag: entityDetails.udyam_vintage_flag });
            }
          } else if (stage === 'gst') {
            if (entityDetails && entityDetails.gst_vintage_flag && entityDetails.gst_vintage_flag !== 'approved') {
              setSelectedFileType('Shop Establishment Certificate');
            }
            if (entityDetails) {
              setStateData({ ...stateData, gst_vintage_flag: entityDetails.gst_vintage_flag });
            }
          }
        })
        .catch((e) => {
          showAlert('Something went Wrong', 'error');
        });
    }
  };

  const setSubStatusCheckApi = async (loanAppID, sectionCode, subSectionCode, dispatch) => {
    intervalId = setInterval(async () => {
      try {
        let status = await getSectionStatus(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch);

        let subSectionRemarks = await getSubSectionRemarks(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch);
        const status_list = ['approved', 'deviation', 'rejected', 'failed'];
        status = status.toLowerCase();
        if (status_list.includes(status)) clearInterval(intervalId);
        if (subSectionCode === 'entity_pan') {
          if (status == 'approved') {
            setIsPanVerified(true);
            showAlert('Pan Verified Successfully', 'success');
            setPanButtonState('icon');
            setPanBorder('1px solid green');
          } else if (status == 'deviation') {
            setIsPanVerified(true);
            showAlert('Pan Verified Successfully', 'success');
            setPanButtonState('icon');
            setPanBorder('1px solid #f5781f');
          } else {
            setIsPanVerified(true);
            showAlert('Pan Verified Successfully', 'success');
            setPanButtonState('icon');
            setPanBorder('1px solid #f5781f');
          }
          clearInterval(intervalId);
        }
        if (subSectionCode === 'entity_cin') {
          if (status == 'approved' || status == 'deviation') {
            setIsCinVerified(true);
            showAlert('CIN Verified Successfully', 'success');
            setCinButtonState('icon');
            setCinBorder('1px solid green');
          }
          clearInterval(intervalId);
        }
        if (subSectionCode === 'entity_udyam') {
          if (status == 'approved') {
            setIsUrcVerified(true);
            FetchEntityData();
            showAlert('URC Number Verified Successfully', 'success');
            setUrcButtonState('icon');
            setUrcBorder('1px solid green');
          } else {
            setIsUrcVerified(true);
            FetchEntityData();
            showAlert('URC Number Verified Successfully', 'success');
            setUrcButtonState('icon');
            setUrcBorder('1px solid #f5781f');
          }
          clearInterval(intervalId);
        }
        if (subSectionCode === 'entity_gst') {
          if (status == 'approved' || status == 'deviation') {
            setGstBorder('1px solid green');
            showAlert('GSTIN Verified Successfully', 'success');
            setGstButtonState('icon');
            FetchEntityData('gst');
          }
          if (status == 'rejected') {
            showAlert(subSectionRemarks, 'error');
            setGstButtonState('button');
          }
          if (status == 'failed') {
            showAlert('Something went Wrong', 'error');
            setGstButtonState('button');
          }
        }
      } catch (error) {
        clearInterval(intervalId);
        if (subSectionCode === 'entity_udyam') setUrcButtonState('button');
        if (subSectionCode == 'entity_cin') setCinButtonState('button');
        if (subSectionCode === 'entity_pan') {
          setPanButtonState('button');
          showAlert(error?.response?.data?.message ?? error?.message, 'error');
        }
        if (subSectionCode === 'entity_gst') setGstButtonState('button');
        showAlert(error?.response?.data?.message ?? error?.message ?? 'Something went wrong, please try again.', 'error');
      }
    }, 20000);
  };

  const callValidator = async (payload, stage) => {
    const body = {
      ...payload,
      user_id: user._id,
      company_id: LAPCompanyId,
      product_id: LAPProductId,
      loan_app_id: loanAppId,
      section: 'entity-details',
      address_same: viewRegAddress ? 0 : 1,
      entity_type: selectedEntityType,
      entity_name: stateData[`string_vl_entity_name`] ?? '',
      date_of_incorporation: stateData[`date_vl_doi_value`] ?? '',
      com_addr_ln1: stateData[`string_vl_comm_addr_ln1`] ?? '',
      com_addr_ln2: stateData[`string_vl_comm_addr_ln2`] ?? '',
      com_city: stateData[`string_vl_comm_addr_city`] ?? '',
      com_state: stateData[`string_vl_comm_addr_state`] ?? '',
      com_pincode: stateData[`pincode_vl_comm_addr_pincode`] ?? '',
      com_nature_of_ownership: stateData[`string_vl_comm_nature_of_ownership`],
      no_of_years_com_addr: stateData[`string_vl_comm_no_of_years`],
      res_addr_ln1: stateData[`string_vl_reg_addr_ln1`] ?? '',
      res_addr_ln2: stateData[`string_vl_reg_addr_ln2`] ?? '',
      res_city: stateData[`string_vl_reg_addr_city`] ?? '',
      res_state: stateData[`string_vl_reg_addr_state`] ?? '',
      res_pincode: stateData[`pincode_vl_reg_addr_pincode`] ?? '',
      res_nature_of_ownership: stateData[`string_vl_reg_nature_of_ownership`],
      no_of_years_res_addr: stateData[`string_vl_reg_no_of_years`],
      gst_certificate: base64String,
      gst_certificate_file_name: gstCertificateFileName,
    };
    new Promise((resolve, reject) => {
      dispatch(patchLapDetailsWatcher(body, resolve, reject));
    })
      .then((response) => {
        setSubStatusCheckApi(loanAppId, body.section_code, body.sub_section_code, dispatch);
      })
      .catch((error) => {
        if (stage === 'urc') {
          setUrcButtonState('button');
          showAlert(`Invalid URC Number. Please enter the Valid Number`, 'error');
        }
        if (stage == 'cin') setCinButtonState('button');
        if (stage === 'pan') {
          showAlert(error?.response?.data?.message || 'Something went wrong', 'error');
          setPanButtonState('button');
        }
        if (stage === 'gst') setGstButtonState('button');
        showAlert(error?.response?.data?.message ?? error?.message ?? 'error while saving draft', 'error');
      });
  };

  const cinVerify = async (name, type) => {
    if (!stateData[`${type}_vl_${name}`] || stateData[`${type}_vl_${name}`] == '' || stateData[`${type}_vl_${name}`] == null) {
      setValidationData((prevState) => ({
        ...prevState,
        [`${type}_vl_${name}State`]: 'has-danger',
      }));
      showAlert('Kindly check for errors in fields', 'error');
      return;
    }
    setCinButtonState('loader');
    if (name === 'entity_kyc_pvtLtd_cin_llpin') {
      const data = {
        cin_no: stateData[`${type}_vl_${name}`],
        section_code: 'entity',
        section_name: 'Entity Details',
        sub_section_code: 'entity_cin',
        section_sequence_no: 200,
        sub_section_name: 'Entity CIN Check',
        sub_section_sequence_no: 4,
      };
      await callValidator(data, 'cin');
    } else {
      setCinButtonState('button');
      showAlert('CIN not verified please try again', 'error');
      setCinBorder('1px solid red');
    }
    if (cinButtonState == 'loader') {
      setCinButtonState('button');
    }
  };

  const urcVerify = async (name, type) => {
    let valid = commonFieldValidation(true);
    if (!stateData[`${type}_vl_${name}`] || stateData[`${type}_vl_${name}`] == '' || stateData[`${type}_vl_${name}`] == null) {
      setValidationData((prevState) => ({
        ...prevState,
        [`${type}_vl_${name}State`]: 'has-danger',
      }));
      showAlert('Kindly check for errors in fields', 'error');
      return;
    }
    if (!valid) {
      showAlert('Kindly check for errors in fields', 'error');
      return;
    }
    setUrcButtonState('loader');
    if (name === 'entity_kyc_proprietor_urc_no' || name === 'entity_kyc_pvtLtd_urc_no' || name === 'entity_kyc_individual_urc_no' || name === 'entity_kyc_partnerShip_urc_no') {
      const data = {
        address_same: viewRegAddress ? 0 : 1,
        entity_type: stateData[`string_vl_select_entity_type`] ?? '',
        entity_name: stateData[`string_vl_entity_name`] ?? '',
        date_of_incorporation: stateData[`date_vl_doi_value`] ?? '',
        com_addr_ln1: stateData[`string_vl_comm_addr_ln1`] ?? '',
        com_addr_ln2: stateData[`string_vl_comm_addr_ln2`] ?? '',
        com_city: stateData[`string_vl_comm_addr_city`] ?? '',
        com_state: stateData[`string_vl_comm_addr_state`] ?? '',
        com_pincode: stateData[`pincode_vl_comm_addr_pincode`] ?? '',
        no_of_years_com_addr: stateData[`string_vl_comm_no_of_years`] ?? '',
        com_nature_of_ownership: stateData[`string_vl_comm_nature_of_ownership`] ?? '',
        res_addr_ln1: stateData[`string_vl_reg_addr_ln1`] ?? '',
        res_addr_ln2: stateData[`string_vl_reg_addr_ln2`] ?? '',
        res_city: stateData[`string_vl_reg_addr_city`] ?? '',
        res_state: stateData[`string_vl_reg_addr_state`] ?? '',
        res_pincode: stateData[`pincode_vl_reg_addr_pincode`] ?? '',
        no_of_years_res_addr: stateData[`string_vl_reg_no_of_years`] ?? '',
        res_nature_of_ownership: stateData[`string_vl_reg_nature_of_ownership`] ?? '',
        date_of_commencement: stateData[`date_vl_business_commencement_date`] ?? '',
        nature_of_business: stateData[`string_vl_nature_of_business`] ?? '',
        work_profile: stateData[`workprofile_vl_work_profile`] ?? '',
        monthly_income: stateData[`monthlyincome_vl_monthly_income`] ?? '',
        nature_of_ownership: stateData[`string_vl_comm_nature_of_ownership`] ?? '',
        com_monthly_rent: stateData[`monthlyincome_vl_comm_monthly_rent`] ?? '',
        res_monthly_rent: stateData[`monthlyincome_vl_reg_monthly_rent`] ?? '',
        section_code: 'entity',
        section_name: 'Entity Details',
        sub_section_code: 'entity_udyam',
        section_sequence_no: 200,
        sub_section_name: 'Entity Udyam Check',
        sub_section_sequence_no: 2,
        urc_no: stateData[`${type}_vl_${name}`],
      };
      await callValidator(data, 'urc');
    } else {
      setUrcButtonState('button');
      showAlert('URC not verified please try again', 'error');
      setUrcBorder('1px solid red');
    }
  };

  const gstValueVerify = async (name, type) => {
    let valid = commonFieldValidation(true);
    if (!valid) {
      showAlert('Kindly check for errors in fields', 'error');
      return;
    }
    setBackgroundColorBlur(false);
    if (!stateData[`${type}_vl_${name}`] || stateData[`${type}_vl_${name}`] == '' || stateData[`${type}_vl_${name}`] == null) {
      setValidationData((prevState) => ({
        ...prevState,
        [`${type}_vl_${name}State`]: 'has-danger',
      }));
      showAlert('Kindly check for errors in fields', 'error');
      return;
    }
    setGstButtonState('loader');
    if (name === 'gstin_value') {
      const data = {
        gst_no: stateData[`${type}_vl_${name}`],
        section_code: 'entity',
        section_name: 'Entity Details',
        sub_section_code: 'entity_gst',
        section_sequence_no: 200,
        sub_section_name: 'Entity GST Check',
        sub_section_sequence_no: 3,
      };
      await callValidator(data, 'gst');
    } else {
      setGstButtonState('button');
      showAlert('Please provide additional supporting document.Vintage less than 1 year.', 'error');
      setGstBorder('1px solid red');
    }
  };

  const verifyPan = async (name, type) => {
    let valid = commonFieldValidation(true);
    if (!stateData[`${type}_vl_${name}`] || stateData[`${type}_vl_${name}`] == '' || stateData[`${type}_vl_${name}`] == null) {
      setValidationData((prevState) => ({
        ...prevState,
        [`pan_vl_${name}State`]: 'has-danger',
      }));
      showAlert('Kindly check for errors in fields', 'error');
      return;
    }
    if (!valid || validationData[`${type}_vl_${name}State`] === 'has-danger') {
      showAlert('Kindly check for errors in fields', 'error');
      return;
    }
    setBackgroundColorBlur(false);
    setPanButtonState('loader');
    if (type === 'pan') {
      const data = {
        pan_no: stateData[`${type}_vl_${name}`],
        address_same: viewRegAddress ? Number_Constants.ZERO : Number_Constants.ONE,
        entity_type: stateData[`string_vl_select_entity_type`] ?? '',
        entity_name: stateData[`string_vl_entity_name`] ?? '',
        date_of_incorporation: stateData[`date_vl_doi_value`] ?? '',
        com_addr_ln1: stateData[`string_vl_comm_addr_ln1`] ?? '',
        com_addr_ln2: stateData[`string_vl_comm_addr_ln2`] ?? '',
        com_city: stateData[`string_vl_comm_addr_city`] ?? '',
        com_state: stateData[`string_vl_comm_addr_state`] ?? '',
        com_pincode: stateData[`pincode_vl_comm_addr_pincode`] ?? '',
        no_of_years_com_addr: stateData[`string_vl_comm_no_of_years`] ?? '',
        com_nature_of_ownership: stateData[`string_vl_comm_nature_of_ownership`] ?? '',
        res_addr_ln1: stateData[`string_vl_reg_addr_ln1`] ?? '',
        res_addr_ln2: stateData[`string_vl_reg_addr_ln2`] ?? '',
        res_city: stateData[`string_vl_reg_addr_city`] ?? '',
        res_state: stateData[`string_vl_reg_addr_state`] ?? '',
        res_pincode: stateData[`pincode_vl_reg_addr_pincode`] ?? '',
        no_of_years_res_addr: stateData[`string_vl_reg_no_of_years`] ?? '',
        res_nature_of_ownership: stateData[`string_vl_reg_nature_of_ownership`] ?? '',
        date_of_commencement: stateData[`date_vl_business_commencement_date`] ?? '',
        nature_of_business: stateData[`string_vl_nature_of_business`] ?? '',
        work_profile: stateData[`workprofile_vl_work_profile`] ?? '',
        monthly_income: stateData[`monthlyincome_vl_monthly_income`] ?? '',
        nature_of_ownership: stateData[`string_vl_comm_nature_of_ownership`] ?? '',
        com_monthly_rent: stateData[`monthlyincome_vl_comm_monthly_rent`] ?? '',
        res_monthly_rent: stateData[`monthlyincome_vl_reg_monthly_rent`] ?? '',
        section_code: 'entity',
        section_name: 'Entity Details',
        sub_section_code: 'entity_pan',
        section_sequence_no: 200,
        sub_section_name: 'Entity PAN Check',
        sub_section_sequence_no: 1,
      };
      await callValidator(data, 'pan');
    } else {
      setPanButtonState('button');
      showAlert('Pan verified please try again', 'error');
      setPanBorder('1px solid red');
    }
  };

  const dropDownChange = (value, name, type = '') => {
    const buttonText = value.target?.textContent;
    const valued = buttonText;
    if (valued === 'Verify') {
      if (name === 'entity_kyc_proprietor_urc') urcVerify(name, type);
      if (name === 'gstin_value') gstValueVerify(name, type);
    } else if (name === 'gst_certificate_value' || name === 'udhyam_certificalte_value' || name === 'entity_kyc_partnerShip_moa' || name === 'entity_kyc_partnerShip_aoa' || name === 'entity_kyc_partnerShip_coi' || name === 'entity_kyc_partnerShip_LOD' || name === 'entity_kyc_partnerShip_al' || name === 'entity_kyc_partnerShip_rc' || name === 'entity_kyc_partnerShip_llom' || name === 'entity_kyc_partnerShip_by_laws' || name === 'entity_kyc_partnerShip_als') {
      fileInputRef.current.click(name);
      setNewFile(name);
    } else {
      if (name.indexOf('comm') != -1 && !viewRegAddress) {
        const perField = `string_vl_${name.replace('comm', 'reg')}`;
        let isValidData = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value.value);
        setStateData((prevState) => ({
          ...prevState,
          [perField]: value.value,
        }));
        setValidationData((prevState) => ({
          ...prevState,
          [`${perField}State`]: !isValidData ? 'has-danger' : '',
        }));
      }
      setStateData((prevState) => ({
        ...prevState,
        [`string_vl_${name}`]: value?.value ?? '',
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`string_vl_${name}State`]: '',
      }));
      if (name == 'select_entity_type') {
        setSelectedEntityType(value.key);
      }
      if (name === 'reg_addr_state') {
        handleGetRegCities(value, name);
        if (stateData.string_vl_reg_addr_city) {
          setStateData((prevState) => ({
            ...prevState,
            [`string_vl_reg_addr_city`]: '',
          }));
        }
      }
      if (name === 'comm_addr_state') {
        handleGetCommCities(value, name);
        if (stateData.string_vl_comm_addr_city) {
          setStateData((prevState) => ({
            ...prevState,
            [`string_vl_comm_addr_city`]: '',
          }));
        }
      }
    }
  };

  const changeDateSelected = (value, name) => {
    const date = verifyDateAfter1800(moment(value).format('YYYY-MM-DD')) ? moment(value).format('YYYY-MM-DD') : value;
    const isValid = validateData(name.substring(0, name.indexOf('_vl_')).toLowerCase(), date);
    setStateData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
    setValidationData((prevState) => ({
      ...prevState,
      [`${name}State`]: !isValid ? 'has-danger' : '',
    }));
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const fetchObjectsByDept = (deptName) => {
    const matchingObjects = [];
    for (const object of bookLoansFormJsonFields()) {
      if (object.dept === deptName) {
        matchingObjects.push(object);
      }
    }
    return matchingObjects;
  };

  const handleFileInputChange = (field, event, type) => {
    const selectedFile = event?.target?.files[0].name;
    const stateField = `${type}_vl_${newFile}`;
    setStateData((prevState) => ({
      ...prevState,
      [stateField]: selectedFile,
    }));
  };

  const disableFieldFunction = (row) => {
    if (props.type === Application_Mode.View) {
      return true;
    } else if (row.field === 'entity_kyc_pvtLtd_cin_llpin') {
      return !isUrcVerified ? true : false;
    } else {
      return (disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || (!viewRegAddress && row.dept === 'Registered Address') || isPanVerified || isUrcVerified;
    }
  };

  const saveDraftFunction = () => {
    let data = {
      section: 'entity-details',
      address_same: viewRegAddress ? 0 : 1,
      entity_type: selectedEntityType,
      entity_name: stateData[`string_vl_entity_name`] ?? '',
      date_of_incorporation: stateData[`date_vl_doi_value`] ?? '',
      com_addr_ln1: stateData[`string_vl_comm_addr_ln1`] ?? '',
      com_addr_ln2: stateData[`string_vl_comm_addr_ln2`] ?? '',
      com_city: stateData[`string_vl_comm_addr_city`] ?? '',
      com_state: stateData[`string_vl_comm_addr_state`] ?? '',
      com_pincode: stateData[`pincode_vl_comm_addr_pincode`] ?? '',
      no_of_years_com_addr: stateData[`string_vl_comm_no_of_years`] ?? '',
      com_nature_of_ownership: stateData[`string_vl_comm_nature_of_ownership`] ?? '',
      res_addr_ln1: stateData[`string_vl_reg_addr_ln1`] ?? '',
      res_addr_ln2: stateData[`string_vl_reg_addr_ln2`] ?? '',
      res_city: stateData[`string_vl_reg_addr_city`] ?? '',
      res_state: stateData[`string_vl_reg_addr_state`] ?? '',
      res_pincode: stateData[`pincode_vl_reg_addr_pincode`] ?? '',
      no_of_years_res_addr: stateData[`string_vl_reg_no_of_years`] ?? '',
      res_nature_of_ownership: stateData[`string_vl_reg_nature_of_ownership`] ?? '',
      date_of_commencement: stateData[`date_vl_business_commencement_date`] ?? '',
      nature_of_business: stateData[`string_vl_nature_of_business`] ?? '',
      work_profile: stateData[`workprofile_vl_work_profile`] ?? '',
      monthly_income: stateData[`monthlyincome_vl_monthly_income`] ?? '',
      nature_of_ownership: stateData[`string_vl_comm_nature_of_ownership`] ?? '',
      com_monthly_rent: stateData[`monthlyincome_vl_comm_monthly_rent`] ?? '',
      res_monthly_rent: stateData[`monthlyincome_vl_reg_monthly_rent`] ?? '',
      pan_no: stateData[`pan_vl_${selectedEntityType === 'Private Limited' ? 'entity_kyc_pvtLtd_pan' : selectedEntityType === 'Partnership' ? 'entity_kyc_partnerShip_pan' : ''}`] ?? '',
      urc_no: stateData[`string_vl_${selectedEntityType == 'Private Limited' ? 'entity_kyc_pvtLtd_urc_no' : selectedEntityType === 'Individual' ? 'entity_kyc_individual_urc_no' : selectedEntityType === 'Proprietor' ? 'entity_kyc_proprietor_urc_no' : selectedEntityType === 'Partnership' ? 'entity_kyc_partnerShip_urc_no' : ''}`] ?? '',
      cin_no: stateData[`string_vl_entity_kyc_pvtLtd_cin_llpin`] ?? '',
      gst_no: stateData[`string_vl_${selectedEntityType === 'Private Limited' ? 'entity_kyc_pvtLtd_gstin_no' : selectedEntityType === 'Partnership' ? 'entity_kyc_partnerShip_gstin_no' : 'entity_supportDocuments_gstin_no'}`] ?? '',
    };

    let bodyObject = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null && value !== '' && value !== ''));

    const payload = {
      loan_app_id: loanAppId,
      userData: {
        user_id: user._id ? user._id : '',
        company_id: LAPCompanyId,
        product_id: LAPProductId,
      },
      bodyData: bodyObject,
    };
    saveLogs(loanAppId, 'entity_details_save_as_draft');
    new Promise((resolve, reject) => {
      dispatch(patchLapSaveAsDraftDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        showAlert('Draft saved successfully', 'success');
      })
      .catch((error) => {
        showAlert(error?.message ?? 'error while saving draft', 'error');
      });
  };

  useEffect(() => {
    if (!viewRegAddress) {
      bookLoansFormJsonFields().map((item, idx) => {
        if (item.dept == 'Registered Address') {
          let addressField = `${item.type}_vl_${item.field}`;
          setStateData((prevState) => ({
            ...prevState,
            [addressField]: null,
          }));
          let field = item.field.replace('reg', 'comm');
          const value = stateData[`${item.type}_vl_${field}`];
          if (value) {
            let perField = `${item.type}_vl_${item.field}`;
            let isValid = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
            setStateData((prevState) => ({
              ...prevState,
              [perField]: value,
            }));
            setValidationData((prevState) => ({
              ...prevState,
              [`${perField}State`]: !isValid ? 'has-danger' : '',
            }));
          }
        }
      });
    } else {
      bookLoansFormJsonFields().map((item, idx) => {
        if (item.dept == 'Registered Address') {
          let field = `${item.type}_vl_${item.field}`;
          setStateData((prevState) => ({
            ...prevState,
            [field]: '',
          }));
        }
      });
    }
  }, [viewRegAddress]);

  useEffect(() => {
    if (LAPProductId || (LAPCompanyId && entityData)) {
      fetchLeadStatus();
      getLoanDocuments();
    }
  }, []);

  const handleRegisteredAddress = (forceCall = false) => {
    if (forceCall === true) {
      setViewRegAddress(true);
    } else {
      setViewRegAddress(!viewRegAddress);
    }
    setPerNatureOfOwnership('');
  };

  const renderDropDownSelectInputBox = (row, datePickerCss) => {
    return (
      <div style={datePickerCss}>
        <InputBox
          id={row.field}
          label={row.title}
          isDrawdown={panButtonState === 'icon' ? false : true}
          initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
          isDisabled={props.type === Application_Mode.View ? true : (disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || isUrcVerified || isPanVerified || (!viewRegAddress && row.dept == 'Registered Address') ? true : false}
          customDropdownClass={inputBoxCss}
          customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
          }}
          customInputClass={{
            marginTop: '0px',
            minWidth: '100%',
            backgroundColor: '#fff',
          }}
          onClick={(event) => change(event, row.type, row.field)}
          options={row.isDrawdown ? row.options : []}
          error={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
              : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
          }
          helperText={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                ? selectedEntityType === 'Individual' && (row.field === 'comm_no_of_years' || row.field === 'reg_no_of_years')
                  ? row.individualValidationMsg
                  : row.validationMsg
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                ? row.errorHelperTextRequired
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                ? row.errorHelperText1Required
                : ''
              : stateData[`${row.type}_vl_${row.field}`] !== '' &&
                (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? (selectedEntityType === 'Individual' && (row.field === 'comm_no_of_years' || row.field === 'reg_no_of_years') ? row.individualValidationMsg : row.validationMsg) : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
          }
        />
      </div>
    );
  };

  const renderIntegerInputBox = (row) => {
    return (
      <>
        <InputBox
          id={row.field}
          label={row.title}
          type={'number'}
          isDrawdown={false}
          initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
          onClick={(event) => change(event, row.type, row.field)}
          isDisabled={disableFieldFunction(row)}
          customDropdownClass={inputBoxCss}
          customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
          }}
          customInputClass={{
            marginTop: '0px',
            minWidth: '100%',
            backgroundColor: '#fff',
          }}
          error={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
              : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
          }
          helperText={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                ? row.validationMsg
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                ? row.errorHelperTextRequired
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                ? row.errorHelperText1Required
                : ''
              : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
          }
        />
      </>
    );
  };

  const renderStringInputBox = (row) => {
    return (
      <>
        <InputBox
          id={row.field}
          label={row.title}
          isDrawdown={row.isDrawdown ? row.isDrawdown : false}
          initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
          onClick={(event) => change(event, row.type, row.field)}
          isDisabled={props.type === Application_Mode.View ? true : row.field === 'entity_kyc_partnerShip_gstin_no' || row.field === 'entity_kyc_pvtLtd_gstin_no' ? (isPanVerified && isUrcVerified ? false : true) : isUrcVerified || isPanVerified ? true : false}
          customDropdownClass={inputBoxCss}
          customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
          }}
          customInputClass={{
            marginTop: '0px',
            minWidth: '100%',
            backgroundColor: '#fff',
          }}
          error={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
              : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
          }
          helperText={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                ? row.validationMsg
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                ? row.errorHelperTextRequired
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                ? row.errorHelperText1Required
                : ''
              : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
          }
        />
      </>
    );
  };

  const renderFields = (dept, applyClass = true) => {
    let deptArray = fetchObjectsByDept(dept);
    return (
      <div style={{ display: applyClass && 'grid', rowGap: '24px', gridTemplateColumns: '32.8% 32.8% 32.8%', columnGap: '1%', width: '98%' }}>
        {deptArray &&
          deptArray.map((row, index) => {
            const datePickerCss = row.field === 'business_commencement_date' || row.field === 'comm_no_of_years' || row.field === 'reg_no_of_years' ? { gridColumn: '1 / span 2' } : {};
            return (
              <>
                {row.field === 'comm_addr_state' || row.field === 'comm_addr_city' || row.field === 'reg_addr_state' || row.field === 'reg_addr_city' || row.field === 'comm_addr_sub_area' || row.field === 'reg_addr_sub_area' || row.field === 'gender' || row.field === 'select_entity_type' ? (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      options={row.field === 'select_entity_type' ? entityType : row.title === 'State' ? states : row.field === 'comm_addr_city' ? commCity : row.field === 'reg_addr_city' ? regCity : row.options}
                      isDrawdown={urcButtonState === 'icon' || panButtonState === 'icon' || (disabledFields[props.type] && disabledFields[props.type].includes(row.field)) ? false : row.dept == 'Registered Address' ? (row.isDrawdown && !viewRegAddress ? false : row.isDrawdown) : true}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      onClick={(value) => dropDownChange(value, row.field, row.type)}
                      isDisabled={props.type === Application_Mode.View ? true : (disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || (!viewRegAddress && row.dept == 'Registered Address') ? true : urcButtonState === 'icon' || panButtonState === 'icon' || panButtonState === 'icon' || cinButtonState === 'icon' || (row.field === 'select_entity_type' && selectedEntityType !== null) ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{ height: '3.5rem', width: '100%', maxWidth: '100%' }}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                        marginTop: !(urcButtonState === 'icon' || panButtonState === 'icon' ? false : row.dept == 'Registered Address' ? (row.isDrawdown && !viewRegAddress ? false : row.isDrawdown) : true) ? '2px' : '0px',
                      }}
                      error={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                      }
                    />
                  </>
                ) : row.field === 'entity_kyc_proprietor_urc_no' || row.field === 'entity_kyc_individual_urc_no' || row.field === 'entity_kyc_proprietor_urc_file_upload' || row.field === 'entity_kyc_individual_urc_file_upload' ? (
                  row.field === 'entity_kyc_proprietor_urc_no' || row.field === 'entity_kyc_individual_urc_no' ? (
                    <>
                      <InputBox
                        isBoxType={urcButtonState}
                        Buttonlabel={navIconPrefixState['Entity Details'] == 'success' ? '' : 'Verify'}
                        id={row.field}
                        label={row.title}
                        isDrawdown={false}
                        initialValue={stateData[`${row.type}_vl_${row.field}`]}
                        onClick={(event) => change(event, row.type, row.field)}
                        isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || (!viewRegAddress && row.dept == 'Registered Address') || isUrcVerified ? true : false}
                        customDropdownClass={inputBoxCss}
                        customClass={{
                          height: '56px',
                          width: '100%',
                          maxWidth: '100%',
                          border: urcBorder,
                          pointerEvents: urcButtonState === 'icon' ? 'none' : '',
                        }}
                        customInputClass={{
                          marginTop: (loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !viewRegAddress) || isFormDisabled ? '2px' : '0px',
                          maxWidth: '82%',
                          backgroundColor: '#fff',
                        }}
                        error={
                          row.checked.toLowerCase() === 'true'
                            ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                        }
                        helperText={
                          row.checked.toLowerCase() === 'true'
                            ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                              ? row.validationMsg
                              : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                              ? row.errorHelperTextRequired
                              : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                              ? row.errorHelperText1Required
                              : ''
                            : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                        }
                      />
                    </>
                  ) : row.field === 'entity_kyc_proprietor_urc_file_upload' || row.field === 'entity_kyc_individual_urc_file_upload' ? (
                    <UploadFileInput
                      backgroundColorChange={false}
                      items={entityURCDocUpload}
                      title=""
                      showAlert={showAlert}
                      setDocumentStateData={setDocumentStateData}
                      sectionName={sectionName}
                      data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                      loanAppId={loanAppId}
                      backgroundColorBlur={props.type == Application_Mode.View || isUrcVerified ? false : props.type == Application_Mode.Edit ? true : false}
                      LAPCompanyId={LAPCompanyId}
                      LAPProductId={LAPProductId}
                      isChange={props.type == Application_Mode.View ? false : true}
                      shouldDelete={true}
                    />
                  ) : null
                ) : row.field === 'entity_supportDocuments_gstin_no' || row.field === 'entity_supportDocuments_gstin_upload' || row.field === 'entity_supportDocuments_shop_establishment_upload' ? (
                  row.field === 'entity_supportDocuments_gstin_no' ? (
                    <>
                      <InputBox
                        id={row.field}
                        label={row.title}
                        isDrawdown={false}
                        initialValue={stateData[`${row.type}_vl_${row.field}`]}
                        onClick={(event) => change(event, row.type, row.field)}
                        isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || (!viewRegAddress && row.dept == 'Registered Address') || !isUrcVerified ? true : false}
                        customDropdownClass={inputBoxCss}
                        customClass={{
                          height: '56px',
                          width: '100%',
                          maxWidth: '100%',
                        }}
                        customInputClass={{
                          marginTop: '0px',
                          minWidth: '100%',
                          backgroundColor: '#fff',
                        }}
                        error={
                          row.checked.toLowerCase() === 'true'
                            ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                        }
                        helperText={
                          row.checked.toLowerCase() === 'true'
                            ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                              ? row.validationMsg
                              : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                              ? row.errorHelperTextRequired
                              : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                              ? row.errorHelperText1Required
                              : ''
                            : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                        }
                      />
                    </>
                  ) : row.field === 'entity_supportDocuments_gstin_upload' || row.field === 'entity_supportDocuments_shop_establishment_upload' ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={false}
                        items={row.field === 'entity_supportDocuments_gstin_upload' ? gstinDocUpload : shopEstablishmentDocUpload}
                        title=""
                        showAlert={showAlert}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        loanAppId={loanAppId}
                        backgroundColorBlur={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || (!viewRegAddress && row.dept == 'Registered Address') || !isUrcVerified ? true : false}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={props.type == Application_Mode.View ? false : true}
                        shouldDelete={true}
                      />
                    </>
                  ) : null
                ) : row.field === 'entity_kyc_pvtLtd_pan' || row.field === 'entity_kyc_partnerShip_pan' ? (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <InputBox
                        id={row.field}
                        label={row.title}
                        isBoxType={panButtonState}
                        Buttonlabel={navIconPrefixState['Entity Details'] === 'success' ? '' : 'Verify'}
                        options={row.field === 'select_entity_type' ? entityType : row.options}
                        isDrawdown={false}
                        initialValue={stateData[`${row.type}_vl_${row.field}`]}
                        onClick={(event) => change(event, row.type, row.field)}
                        isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || row.field === 'age' || (!viewRegAddress && row.dept == 'Registered Address') || isPanVerified ? true : false}
                        customDropdownClass={inputBoxCss}
                        customClass={{
                          height: '56px',
                          width: '100%',
                          maxWidth: '100%',
                          border: panBorder,
                          marginTop: '1px',
                        }}
                        customInputClass={{ minWidth: '100%', backgroundColor: '#fff' }}
                        error={
                          row.checked.toLowerCase() === 'true'
                            ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                        }
                        helperText={
                          row.checked.toLowerCase() === 'true'
                            ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                              ? row.validationMsg
                              : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                              ? row.errorHelperTextRequired
                              : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                              ? row.errorHelperText1Required
                              : ''
                            : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                        }
                      />
                    </div>
                  </>
                ) : row.field === 'entity_kyc_partnerShip_deed' || row.field === 'entity_kyc_partnerShip_authority_letter' ? (
                  row.field === 'entity_kyc_partnerShip_deed' || row.field === 'entity_kyc_partnerShip_authority_letter' ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={false}
                        items={row.field === 'entity_kyc_partnerShip_deed' ? entityPartnerShipDeedDocUpload : entity_KYC_Authority_Letter_Upload}
                        title=""
                        showAlert={showAlert}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        loanAppId={loanAppId}
                        backgroundColorBlur={props.type == Application_Mode.View || isUrcVerified ? false : props.type == Application_Mode.Edit ? true : false}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={props.type == Application_Mode.View ? false : true}
                        shouldDelete={true}
                      />
                    </>
                  ) : null
                ) : row.field === 'entity_kyc_partnerShip_urc_no' || row.field === 'entity_kyc_partnerShip_urc_file_upload' || row.field === 'entity_kyc_partnerShip_gstin_certificate' ? (
                  row.field === 'entity_kyc_partnerShip_urc_no' ? (
                    <>
                      <InputBox
                        isBoxType={urcButtonState}
                        Buttonlabel={navIconPrefixState['Entity Details'] == 'success' ? '' : 'Verify'}
                        id={row.field}
                        label={row.title}
                        isDrawdown={false}
                        initialValue={stateData[`${row.type}_vl_${row.field}`]}
                        onClick={(event) => change(event, row.type, row.field)}
                        isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || (!viewRegAddress && row.dept == 'Registered Address') || !isPanVerified || isUrcVerified ? true : false}
                        customDropdownClass={inputBoxCss}
                        customClass={{
                          height: '56px',
                          width: '100%',
                          maxWidth: '100%',
                          border: urcBorder,
                          pointerEvents: urcButtonState === 'icon' ? 'none' : '',
                        }}
                        customInputClass={{
                          marginTop: (loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !viewRegAddress) || isFormDisabled ? '2px' : '0px',
                          maxWidth: '82%',
                          backgroundColor: '#fff',
                        }}
                        error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                        helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                      />
                    </>
                  ) : row.field === 'entity_kyc_partnerShip_urc_file_upload' || row.field === 'entity_kyc_partnerShip_gstin_certificate' ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={false}
                        items={row.field === 'entity_kyc_partnerShip_urc_file_upload' ? entityURCDocUpload : gstinDocUpload}
                        title=""
                        showAlert={showAlert}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        loanAppId={loanAppId}
                        backgroundColorBlur={props.type == Application_Mode.View || isUrcVerified ? false : props.type == Application_Mode.Edit ? true : false}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={props.type == Application_Mode.View ? false : true}
                        shouldDelete={true}
                      />
                    </>
                  ) : null
                ) : row.field === 'entity_kyc_pvtLtd_lod' || row.field === 'entity_kyc_pvtLtd_aoa' || row.field === 'entity_kyc_pvtLtd_coi' || row.field === 'entity_kyc_pvtLtd_moa' ? (
                  row.field === 'entity_kyc_pvtLtd_lod' || row.field === 'entity_kyc_pvtLtd_aoa' || row.field === 'entity_kyc_pvtLtd_coi' || row.field === 'entity_kyc_pvtLtd_moa' ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={false}
                        items={row.field === 'entity_kyc_pvtLtd_lod' ? entityLODDocUpload : row.field === 'entity_kyc_pvtLtd_aoa' ? entityAOADocUpload : row.field === 'entity_kyc_pvtLtd_coi' ? entityCOIDocUpload : entityMOADocUpload}
                        title=""
                        showAlert={showAlert}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        loanAppId={loanAppId}
                        backgroundColorBlur={props.type == Application_Mode.View || isUrcVerified ? false : props.type == Application_Mode.Edit ? true : false}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={props.type == Application_Mode.View ? false : true}
                        shouldDelete={true}
                      />
                    </>
                  ) : null
                ) : row.field === 'entity_kyc_pvtLtd_urc_no' || row.field === 'entity_kyc_pvtLtd_urc_file_upload' ? (
                  row.field === 'entity_kyc_pvtLtd_urc_no' ? (
                    <>
                      <InputBox
                        isBoxType={urcButtonState}
                        Buttonlabel={navIconPrefixState['Entity Details'] == 'success' ? '' : 'Verify'}
                        id={row.field}
                        label={row.title}
                        isDrawdown={false}
                        initialValue={stateData[`${row.type}_vl_${row.field}`]}
                        onClick={(event) => change(event, row.type, row.field)}
                        isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || (!viewRegAddress && row.dept == 'Registered Address') || !isPanVerified || isUrcVerified ? true : false}
                        customDropdownClass={inputBoxCss}
                        customClass={{
                          height: '56px',
                          width: '100%',
                          maxWidth: '100%',
                          border: urcBorder,
                          pointerEvents: urcButtonState === 'icon' ? 'none' : '',
                        }}
                        customInputClass={{
                          marginTop: (loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !viewRegAddress) || isFormDisabled ? '2px' : '0px',
                          maxWidth: '82%',
                          backgroundColor: '#fff',
                        }}
                        error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                        helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                      />
                    </>
                  ) : row.field === 'entity_kyc_pvtLtd_urc_file_upload' ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={false}
                        items={entityURCDocUpload}
                        title=""
                        showAlert={showAlert}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        loanAppId={loanAppId}
                        backgroundColorBlur={props.type == Application_Mode.View || isUrcVerified ? false : props.type == Application_Mode.Edit ? true : false}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={props.type == Application_Mode.View ? false : true}
                        shouldDelete={true}
                      />
                    </>
                  ) : null
                ) : row.type === 'date' ? (
                  <>
                    <div style={datePickerCss}>
                      <CustomDatePicker
                        customInputClass={{ width: '100%', height: '56px' }}
                        label={row.title}
                        format={'YYYY-MM-DD'}
                        isBoxType={'icon'}
                        disableFuture={true}
                        onDateChange={(date) => changeDateSelected(date, `${row.type}_vl_${row.field}`)}
                        isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || urcButtonState === 'icon' || panButtonState === 'icon' ? true : isFormDisabled ? true : false}
                        initialValue={stateData[`${row.type}_vl_${row.field}`]}
                        error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                        helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                      />
                    </div>
                  </>
                ) : row.field === 'nature_of_business' || row.field === 'comm_no_of_years' || row.field === 'reg_no_of_years' || row.field === 'reg_nature_of_ownership' || row.field === 'comm_nature_of_ownership' ? (
                  renderDropDownSelectInputBox(row, datePickerCss)
                ) : row.field === 'entity_name' || row.field === 'work_profile' || row.field === 'entity_kyc_partnerShip_gstin_no' || row.field === 'entity_kyc_pvtLtd_gstin_no' || row.field === 'comm_addr_ln1' || row.field === 'comm_addr_ln2' ? (
                  renderStringInputBox(row)
                ) : row.field === 'monthly_income' || row.field === 'comm_monthly_rent' || row.field === 'reg_monthly_rent' || row.field === 'entity_kyc_pvtLtd_cin_llpin' || row.field === 'comm_addr_pincode' ? (
                  row.field === 'comm_monthly_rent' ? (
                    commNatureOfOwnership === 'Rented' ? (
                      renderIntegerInputBox(row)
                    ) : null
                  ) : row.field === 'reg_monthly_rent' ? (
                    perNatureOfOwnership === 'Rented' || (!viewRegAddress && row.dept === 'Registered Address' && commNatureOfOwnership === 'Rented') ? (
                      renderIntegerInputBox(row)
                    ) : null
                  ) : (
                    renderIntegerInputBox(row)
                  )
                ) : (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      type={row.field === 'comm_addr_pincode' || row.field === 'reg_addr_pincode' ? 'number' : 'text'}
                      isDrawdown={false}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      onClick={(event) => change(event, row.type, row.field)}
                      isDisabled={props.type === Application_Mode.View ? true : urcButtonState === 'icon' || panButtonState == 'icon' ? true : (disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || row.field === 'age' || (!viewRegAddress && row.dept == 'Registered Address') ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{ height: '3.5rem', width: '100%', maxWidth: '100%' }}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                        marginTop: (urcButtonState === 'icon' || panButtonState == 'icon' ? true : (disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || row.field === 'age' || (!viewRegAddress && row.dept == 'Registered Address') ? true : false) ? '2px' : '0px',
                      }}
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')
                      }
                    />
                  </>
                )}
              </>
            );
          })}
      </div>
    );
  };

  const handleRadioButton = (event) => {
    setSelectedFileType(event.target.value);
    if (event.target.value == Field_Label.Shop_Establishment_cert) {
      if (leadStatus == Section_Status.PENDING && documentStateData.entity_supportDocuments_shop_establishment_upload == true) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    } else {
      if (documentStateData.urc_certificate_value) setValidForm(true);
    }
  };

  const setInitialState = () => {
    const tempStateObj = {};
    const tempErrorObj = {};
    bookLoansFormJsonFields().map((item, idx) => {
      if (
        item.dept == 'Entity Details 1' ||
        item.dept == 'Entity Details' ||
        item.dept == 'Communication Address' ||
        item.dept == 'Registered Address' ||
        item.dept == `${selectedEntityType === 'Proprietor' ? 'Entity-KYC proprietor' : selectedEntityType === 'Partnership' && 'Entity-KYC partnership'}` ||
        item.dept == `${selectedEntityType === 'Individual' ? 'Entity-KYC individual' : selectedEntityType === 'Private Limited' && 'Entity-KYC private limited'}` ||
        item.dept == `${selectedFileType === 'GSTIN' ? 'GSTIN' : 'Shop Establishment Certificate'}`
      ) {
        const stateKey = `${item.type}_vl_${item.field}`;
        const errorKey = `${item.type}_vl_${item.field}State`;
        tempStateObj[stateKey] = '';
        tempErrorObj[errorKey] = '';
      }
    });
    setStateData(tempStateObj);
    setValidationData(tempErrorObj);
  };

  const handleSubmit = (event) => {
    if (isLeadRejected) {
      return showAlert(`Your lead has been rejected`, 'error');
    }

    const postData = {};
    let formValidated = true;
    if (formValidated) {
      setSectionStatusCheck('inProgress');
      postData.user_id = user._id;
      const payload = {
        user_id: user._id,
        company_id: LAPCompanyId,
        product_id: LAPProductId,
        loan_app_id: loanAppId,
        section: 'entity-details',
        entity_type: selectedEntityType,
        entity_name: stateData[`string_vl_entity_name`] ?? '',
        date_of_incorporation: stateData[`date_vl_doi_value`] ?? '',
        com_addr_ln1: stateData[`string_vl_comm_addr_ln1`] ?? '',
        com_addr_ln2: stateData[`string_vl_comm_addr_ln2`] ?? '',
        com_city: stateData[`string_vl_comm_addr_city`] ?? '',
        com_state: stateData[`string_vl_comm_addr_state`] ?? '',
        com_pincode: stateData[`pincode_vl_comm_addr_pincode`] ?? '',
        no_of_years_com_addr: stateData[`string_vl_comm_no_of_years`] ?? '',
        com_nature_of_ownership: stateData[`string_vl_comm_nature_of_ownership`] ?? '',
        com_monthly_rent: stateData[`monthlyincome_vl_comm_monthly_rent`] ?? '',
        res_addr_ln1: stateData[`string_vl_reg_addr_ln1`] ?? '',
        res_addr_ln2: stateData[`string_vl_reg_addr_ln2`] ?? '',
        res_city: stateData[`string_vl_reg_addr_city`] ?? '',
        res_state: stateData[`string_vl_reg_addr_state`] ?? '',
        res_pincode: stateData[`pincode_vl_reg_addr_pincode`] ?? '',
        no_of_years_res_addr: stateData[`string_vl_reg_no_of_years`] ?? '',
        res_nature_of_ownership: stateData[`string_vl_reg_nature_of_ownership`] ?? '',
        res_monthly_rent: stateData[`monthlyincome_vl_reg_monthly_rent`] ?? '',
        pan_no: stateData[`pan_vl_${selectedEntityType === 'Private Limited' ? 'entity_kyc_pvtLtd_pan' : selectedEntityType === 'Partnership' ? 'entity_kyc_partnerShip_pan' : ''}`] ?? '',
        urc_no: stateData[`string_vl_${selectedEntityType == 'Private Limited' ? 'entity_kyc_pvtLtd_urc_no' : selectedEntityType === 'Individual' ? 'entity_kyc_individual_urc_no' : selectedEntityType === 'Proprietor' ? 'entity_kyc_proprietor_urc_no' : selectedEntityType === 'Partnership' ? 'entity_kyc_partnerShip_urc_no' : ''}`] ?? '',
        cin_no: stateData[`string_vl_entity_kyc_pvtLtd_cin_llpin`] ?? '',
        gst_no: stateData[`string_vl_${selectedEntityType === 'Private Limited' ? 'entity_kyc_pvtLtd_gstin_no' : selectedEntityType === 'Partnership' ? 'entity_kyc_partnerShip_gstin_no' : 'entity_supportDocuments_gstin_no'}`] ?? '',
        gst_certificate: stateData[`string_vl_gstin_value`] ?? '',
        shop_est_certificate: '',
        authority_letter: '',
        moa_file: '',
        aoa_file: '',
        coi_file: '',
        directors_file: '',
        section_sequence_no: SectionData.entity.section_sequence_no,
        section_name: SectionData.entity.section_name,
        sub_section_code: SectionData.entity.entity_section_submit.sub_section_code,
        sub_section_name: SectionData.entity.entity_section_submit.sub_section_name,
        sub_section_sequence_no: SectionData.entity.entity_section_submit.sub_section_sequence_no,
        section_code: SectionData.entity.section_code,
      };

      new Promise((resolve, reject) => {
        dispatch(patchLapDetailsWatcher(payload, resolve, reject));
      })
        .then((response) => {
          setEntityData(stateData);
          setStatusCheckApi(loanAppId, SectionData.entity.section_code, SectionData.entity.entity_section_submit.sub_section_code, dispatch);
          showAlert(response?.message, 'success');
        })
        .catch((error) => {
          showAlert(error?.response?.data?.message, 'error');
        });
    } else {
      showAlert('Kindly check for errors in fields', 'error');
    }
  };

  const setStatusCheckApi = async (loanAppID, sectionCode, subSectionCode, dispatch) => {
    intervalId = setInterval(async () => {
      try {
        const status = await getSectionStatus(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch);
        if (status == 'approved') {
          if (subSectionCode === SectionData.entity.entity_section_submit.sub_section_code) {
            setNavState('Co-Borrower Details');
            setNavIconPrefixState((prevState) => ({
              ...prevState,
              'Entity Details': 'success',
            }));
            setSectionStatusCheck('completed');
            if (setShouldFetch) {
              setShouldFetch((prev) => prev + 1);
            }
          }
          clearInterval(intervalId);
        } else if (status == 'deviation' || status == 'in_review') {
          if (subSectionCode === SectionData.entity.entity_section_submit.sub_section_code) {
            setNavState('Co-Borrower Details');
            setNavIconPrefixState((prevState) => ({
              ...prevState,
              'Entity Details': 'deviation',
            }));
            setSectionStatusCheck('completed');
          }
          clearInterval(intervalId);
        } else if (status == 'rejected') {
          setIsLeadRejected(true);
          clearInterval(intervalId);
          setSectionStatusCheck('rejected');
          showAlert('Lead rejected', 'error');
        }
      } catch (error) {
        clearInterval(intervalId);
      }
    }, 4000);
  };

  const renderEntityKycUi = (entityType) => {
    if (entityType === 'Proprietor') {
      return renderFields('Entity-KYC proprietor');
    } else if (entityType === 'Individual') {
      return renderFields('Entity-KYC individual');
    } else if (entityType === 'Partnership') {
      return renderFields('Entity-KYC partnership');
    } else if (entityType === 'Private Limited') {
      return renderFields('Entity-KYC private limited');
    } else {
      return null;
    }
  };

  const saveLogs = (identifier, event_name) => {
    new Promise((resolve, reject) => {
      const payload = {
        user_id: user?._id,
        identifier: identifier,
        log_details: [
          {
            user_id: user?._id,
            event_name: event_name,
            timestamp: new Date(),
          },
        ],
      };
      if (payload?.user_id && identifier && event_name) {
        dispatch(postComprehensiveLogsWatcher(payload, resolve, reject));
      }
    });
  };

  const handleEntityCheckBox = () => {
    if (props.type == 'view') {
      return stateData['address_same'] ? stateData['address_same'] : false;
    } else {
      return !viewRegAddress;
    }
  };

  return (
    <div className="entity-details-container-style">
      <h4 className="entity-details-header-style">Entity Details</h4>
      <div>{selectedEntityType !== 'Individual' && renderFields('Entity Details 1')}</div>
      <div>{selectedEntityType == 'Individual' && renderFields('Entity Details')}</div>
      <h4 className="entity-details-header-style entity-details-sub-header-spacing-style">Communication Address</h4>
      <div>{renderFields('Communication Address')}</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h4 className="entity-details-header-style entity-details-sub-header-spacing-style">Registered Address</h4>
        <>
          <input className="entity-details-checkbox-style" type="checkbox" onClick={handleRegisteredAddress} disabled={props.type === Application_Mode.View ? true : urcButtonState === 'icon' || panButtonState === 'icon' ? true : false} checked={handleEntityCheckBox()} />
          <div className="entity-details-checkbox-label-style">Same as Communication Address</div>
        </>
      </div>
      <div>{renderFields('Registered Address')}</div>
      {selectedEntityType && (
        <div>
          <h4 style={headingCss}>Entity KYC</h4>
          <div>{renderEntityKycUi(selectedEntityType)}</div>
          {selectedEntityType == 'Proprietor' || selectedEntityType == 'Individual' ? (
            <div>
              <p className="entity-addition-document-title">Upload additional supporting documents</p>
              <>
                <div style={{ display: 'flex', flexDirection: 'row', columnGap: '5%' }}>
                  <label style={radioCss}>
                    <input type="radio" value="GSTIN" checked={selectedFileType === 'GSTIN'} onChange={handleRadioButton} style={radioInputCss} />
                    GSTIN
                  </label>
                  <label style={radioCss}>
                    <input type="radio" value="Shop Establishment Certificate" checked={selectedFileType === 'Shop Establishment Certificate'} onChange={handleRadioButton} style={radioInputCss} />
                    Shop Establishment Certificate
                  </label>
                </div>
                {showGstinSection ? selectedFileType === 'GSTIN' ? <div style={{ marginTop: '16px' }}>{selectedEntityType == 'Proprietor' || selectedEntityType == 'Individual' ? renderFields('Entity-KYC GSTIN') : null}</div> : selectedFileType === 'Shop Establishment Certificate' ? <div style={{ marginTop: '16px' }}>{selectedEntityType == 'Proprietor' || selectedEntityType == 'Individual' ? renderFields('Entity-KYC Shop Establishment Type') : null}</div> : null : null}
              </>
            </div>
          ) : null}
        </div>
      )}
      {props.type == Application_Mode.View || navIconPrefixState['Entity Details'] == 'success' ? null : (
        <div className="book-loan-button-container book-loan-button-alignment-double-button">
          {getSubmitButton('Verify & Next', handleSubmit, sectionStatusCheck === 'inProgress' ? true : false, validForm, {})}
          {leadStatus === 'draft' ? getSaveAsDraftButton('Save as Draft', saveDraftFunction, disableDraftButton) : null}
        </div>
      )}
      {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}
