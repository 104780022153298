import { keyValuePairs } from 'util/helper';
import { bookLoansFormJsonFields } from './bookLoansFormJson';
const BOOK_LOAN_FORM_JSON = bookLoansFormJsonFields();
const BOOK_LOAN_OBJECT = keyValuePairs(BOOK_LOAN_FORM_JSON, 'field');

const mapFields = () => {
  const fieldMaps = {};
  for (let object of BOOK_LOAN_FORM_JSON) {
    fieldMaps[object.field] = `${object.type}_vl_${object.field}`;
  }

  return fieldMaps; // Return the array of matching objects
};

const fieldsMapJson = mapFields();

export const primaryApplicantMapping = (loanDetailsData) => {
  let data = {};
  data = {
    ...data,
    lead_status: loanDetailsData['lead_status'],
    company_id: loanDetailsData['company_id'],
    product_id: loanDetailsData['product_id'],
    loan_app_id: loanDetailsData['loan_app_id'],
    address_same: loanDetailsData['address_same'],
    is_pan_pdf_upload: loanDetailsData['is_pan_pdf_upload'],
    is_aadhar_pdf_upload: loanDetailsData['is_aadhar_pdf_upload'],
    borrower_id: loanDetailsData && loanDetailsData['borrower_id'] ? loanDetailsData['borrower_id'] : null,
    [fieldsMapJson['partner_loan_app_id']]: loanDetailsData['partner_loan_app_id'],
    [fieldsMapJson['partner_borrower_id']]: loanDetailsData['partner_borrower_id'],
    [fieldsMapJson['requested_loan_amt']]: loanDetailsData['loan_amount'],
    [fieldsMapJson['tenure_in_months']]: loanDetailsData['loan_tenure'],
    [fieldsMapJson['purpose_of_loan']]: loanDetailsData['purpose_of_loan'].replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase()),
    [fieldsMapJson['type_of_loan']]: loanDetailsData['type_of_loan'],
    [fieldsMapJson['requested_int_rate']]: loanDetailsData['loan_interest_rate'],
    [fieldsMapJson['first_name']]: loanDetailsData['first_name'],
    [fieldsMapJson['middle_name']]: loanDetailsData['middle_name'],
    [fieldsMapJson['last_name']]: loanDetailsData['last_name'],
    [fieldsMapJson['gender']]: loanDetailsData['gender'],
    [fieldsMapJson['mobile_number']]: loanDetailsData['appl_phone'],
    [fieldsMapJson['date_of_birth']]: loanDetailsData['dob'],
    [fieldsMapJson['email']]: loanDetailsData['email_id'],
    [fieldsMapJson['father_full_name']]: loanDetailsData['father_fname'],
    [fieldsMapJson['occupation_type']]: loanDetailsData['occupation_type'],
    [fieldsMapJson['entity_type']]: loanDetailsData['entity_details']['entity_type'],
    [fieldsMapJson['curr_addr_ln1']]: loanDetailsData['resi_addr_ln1'],
    [fieldsMapJson['curr_addr_ln2']]: loanDetailsData['resi_addr_ln2'],
    [fieldsMapJson['curr_addr_city']]: loanDetailsData['city'],
    [fieldsMapJson['curr_addr_state']]: loanDetailsData['state'],
    [fieldsMapJson['curr_addr_pincode']]: loanDetailsData['pincode'],
    [fieldsMapJson['nature_of_ownership']]: loanDetailsData['nature_of_ownership'],
    [fieldsMapJson['no_of_years_curr_addr']]: loanDetailsData['no_of_years_current_addr'],
    [fieldsMapJson['monthly_rent']]: loanDetailsData['monthly_rent'],
    [fieldsMapJson['per_addr_ln1']]: loanDetailsData['per_addr_ln1'],
    [fieldsMapJson['per_addr_ln2']]: loanDetailsData['per_addr_ln2'],
    [fieldsMapJson['per_addr_city']]: loanDetailsData['per_city'],
    [fieldsMapJson['per_addr_state']]: loanDetailsData['per_state'],
    [fieldsMapJson['per_addr_pincode']]: loanDetailsData['per_pincode'],
    [fieldsMapJson['house_ownership']]: loanDetailsData['house_ownership'],
    [fieldsMapJson['no_of_years_per_addr']]: loanDetailsData['no_of_years_permanent_addr'],
    [fieldsMapJson['per_addr_occupancy']]: loanDetailsData['occupancy_type'],
    [fieldsMapJson['aadhaar_value']]: loanDetailsData['aadhar_card_num'],
    [fieldsMapJson['pan_value']]: loanDetailsData['appl_pan'],
  };

  return data;
};

export const entityDetailsMapping = (loanDetailsData) => {
  let data = {};
  data = {
    ...data,
    udyam_vintage: loanDetailsData['entity_details']['udyam_vintage'],
    udyam_vintage_flag: loanDetailsData['entity_details']['udyam_vintage_flag'],
    udyam_hit_count: loanDetailsData['entity_details']['udyam_hit_count'],
    gst_vintage: loanDetailsData['entity_details']['gst_vintage'],
    gst_vintage_flag: loanDetailsData['entity_details']['gst_vintage_flag'],
    string_vl_select_entity_type: loanDetailsData['entity_details']['entity_type'],
    string_vl_entity_name_value: loanDetailsData['entity_details']['entity_name'],
    address_same: loanDetailsData['entity_details']['address_same'],
    date_vl_doi_value: loanDetailsData['entity_details']['date_of_incorporation'],
    string_vl_comm_addr_ln1: loanDetailsData['entity_details']['com_addr_ln1'],
    string_vl_comm_addr_ln2: loanDetailsData['entity_details']['com_addr_ln2'],
    string_vl_comm_addr_city: loanDetailsData['entity_details']['com_city'],
    string_vl_comm_addr_state: loanDetailsData['entity_details']['com_state'],
    pincode_vl_comm_addr_pincode: loanDetailsData['entity_details']['com_pincode'],
    string_vl_comm_nature_of_ownership: loanDetailsData['entity_details']['com_nature_of_ownership'],
    string_vl_comm_no_of_years: loanDetailsData['entity_details']['no_of_years_com_addr'],
    monthlyincome_vl_comm_monthly_rent: loanDetailsData['entity_details']['com_monthly_rent'],
    string_vl_reg_addr_ln1: loanDetailsData['entity_details']['res_addr_ln1'],
    string_vl_reg_addr_ln2: loanDetailsData['entity_details']['res_addr_ln2'],
    string_vl_reg_addr_city: loanDetailsData['entity_details']['res_city'],
    string_vl_reg_addr_state: loanDetailsData['entity_details']['res_state'],
    pincode_vl_reg_addr_pincode: loanDetailsData['entity_details']['res_pincode'],
    string_vl_reg_nature_of_ownership: loanDetailsData['entity_details']['res_nature_of_ownership'],
    string_vl_reg_no_of_years: loanDetailsData['entity_details']['no_of_years_res_addr'],
    monthlyincome_vl_reg_monthly_rent: loanDetailsData['entity_details']['res_monthly_rent'],
    string_vl_nature_of_business: loanDetailsData['entity_details']['nature_of_business'],
    workprofile_vl_work_profile: loanDetailsData['entity_details']['work_profile'],
    date_vl_business_commencement_date: loanDetailsData['entity_details']['date_of_commencement'],
    monthlyincome_vl_monthly_income: loanDetailsData['entity_details']['monthly_income'],
    string_vl_entity_name: loanDetailsData['entity_details']['entity_name'],
    date_vl_doi_value: loanDetailsData['entity_details']['date_of_incorporation'],
    monthlyincome_vl_monthly_income: loanDetailsData['entity_details']['monthly_income'],
    string_vl_entity_kyc_proprietor_urc_no: loanDetailsData['entity_details']['urc_no'],
    string_vl_entity_kyc_individual_urc_no: loanDetailsData['entity_details']['urc_no'],
    pan_vl_entity_kyc_partnerShip_pan: loanDetailsData['entity_details']['pan_no'],
    string_vl_entity_kyc_partnerShip_urc_no: loanDetailsData['entity_details']['urc_no'],
    string_vl_entity_kyc_partnerShip_gstin_no: loanDetailsData['entity_details']['gst_no'],
    pan_vl_entity_kyc_pvtLtd_pan: loanDetailsData['entity_details']['pan_no'],
    string_vl_entity_kyc_pvtLtd_urc_no: loanDetailsData['entity_details']['urc_no'],
    string_vl_entity_kyc_pvtLtd_cin_llpin: loanDetailsData['entity_details']['cin_no'],
    string_vl_entity_kyc_pvtLtd_gstin_no: loanDetailsData['entity_details']['gst_no'],
    string_vl_entity_supportDocuments_gstin_no: loanDetailsData['entity_details']['gst_no'],
    string_vl_comm_nature_of_ownership: loanDetailsData['entity_details']['com_nature_of_ownership'],
    string_vl_reg_nature_of_ownership: loanDetailsData['entity_details']['res_nature_of_ownership'],
    string_vl_entity_kyc_partnerShip_gstin_no: loanDetailsData['entity_details']['gst_no'],
    string_vl_entity_kyc_pvtLtd_gstin_no: loanDetailsData['entity_details']['gst_no'],
    string_vl_entity_supportDocuments_gstin_no: loanDetailsData['entity_details']['gst_no'],
  };

  return data;
};

export const coBorrowersMapping = (loanDetailsData) => {
  let data = [];
  for (let i = 0; i < loanDetailsData['coborrower']?.length; i++) {
    const coBorrower = loanDetailsData['coborrower'][i];
    let temp = {};
    for (let key in coBorrower) {
      temp = {
        ...temp,
        [fieldsMapJson[key]]: coBorrower[key],
      };
    }
    temp = {
      ...temp,
      _id: coBorrower['_id'],
      borrower_id: coBorrower && coBorrower['borrower_id'] ? coBorrower['borrower_id'] : null,
      address_same: coBorrower['address_same'],
      is_pan_pdf_upload: coBorrower['is_pan_pdf_upload'],
      is_aadhar_pdf_upload: coBorrower['is_aadhar_pdf_upload'],
      panFileType: coBorrower['is_pan_pdf_upload'] === 1 ? { 'Image & PDF': true, 'Response XML/JSON': false } : { 'Image & PDF': false, 'Response XML/JSON': true },
      aadhaarFileType: coBorrower['is_aadhar_pdf_upload'] === 1 ? { 'Image & PDF': true, 'Response XML/JSON': false } : { 'Image & PDF': false, 'Response XML/JSON': true },
      [fieldsMapJson['cb_fname']]: coBorrower['cb_fname'],
      [fieldsMapJson['cb_mname']]: coBorrower['cb_mname'],
      [fieldsMapJson['cb_lname']]: coBorrower['cb_lname'],
      [fieldsMapJson['cb_dob']]: coBorrower['cb_dob'],
      [fieldsMapJson['cb_age']]: coBorrower['cb_age'],
      [fieldsMapJson['cb_gender']]: coBorrower['cb_gender'],
      [fieldsMapJson['cb_mobile']]: coBorrower['cb_mobile'],
      [fieldsMapJson['cb_email']]: coBorrower['cb_email'],
      [fieldsMapJson['cb_father_name']]: coBorrower['cb_father_name'],
      [fieldsMapJson['cb_relationship']]: coBorrower['cb_relationship'],
      [fieldsMapJson['cb_entity_shareholder']]: coBorrower['cb_entity_shareholder'],
      [fieldsMapJson['cb_property_owner']]: coBorrower['cb_property_owner'],
      [fieldsMapJson['cb_resi_addr_ln1']]: coBorrower['cb_resi_addr_ln1'],
      [fieldsMapJson['cb_resi_addr_ln2']]: coBorrower['cb_resi_addr_ln1'],
      [fieldsMapJson['cb_city']]: coBorrower['cb_city'],
      [fieldsMapJson['cb_state']]: coBorrower['cb_state'],
      [fieldsMapJson['cb_pincode']]: coBorrower['cb_pincode'],
      [fieldsMapJson['cb_per_addr_ln1']]: coBorrower['cb_per_addr_ln1'],
      [fieldsMapJson['cb_per_addr_ln2']]: coBorrower['cb_per_addr_ln1'],
      [fieldsMapJson['cb_per_city']]: coBorrower['cb_per_city'],
      [fieldsMapJson['cb_per_state']]: coBorrower['cb_per_state'],
      [fieldsMapJson['cb_pan']]: coBorrower['cb_pan'],
      [fieldsMapJson['cb_aadhaar']]: coBorrower['cb_aadhaar'],
    };
    data.push(temp);
  }
  return data;
};

export const guarantorDetailsMapping = (loanDetailsData) => {
  let data = [];

  for (let i = 0; i < loanDetailsData['guarantor']?.length; i++) {
    const guarantor = loanDetailsData['guarantor'][i];
    let temp = {};
    for (let key in guarantor) {
      temp = {
        ...temp,
        [fieldsMapJson[key]]: guarantor[key],
      };
    }

    temp = {
      ...temp,
      _id: guarantor['_id'],
      borrower_id: guarantor && guarantor['borrower_id'] ? guarantor['borrower_id'] : null,
      address_same: guarantor['address_same'],
      panFileType: guarantor['is_pan_pdf_upload'] === 1 ? { 'Image & PDF': true, 'Response XML/JSON': false } : { 'Image & PDF': false, 'Response XML/JSON': true },
      aadhaarFileType: guarantor['is_aadhar_pdf_upload'] === 1 ? { 'Image & PDF': true, 'Response XML/JSON': false } : { 'Image & PDF': false, 'Response XML/JSON': true },
      [fieldsMapJson['gua_pan']]: guarantor['gua_pan'],
      [fieldsMapJson['gua_aadhaar']]: guarantor['gua_aadhaar'],
      [fieldsMapJson['gua_dob']]: guarantor['gua_dob'],
      [fieldsMapJson['gua_gender']]: guarantor['gua_gender'],
      [fieldsMapJson['gua_fname']]: guarantor['gua_fname'],
      [fieldsMapJson['gua_mname']]: guarantor['gua_mname'],
      [fieldsMapJson['gua_lname']]: guarantor['gua_lname'],
      [fieldsMapJson['gua_pan']]: guarantor['gua_pan'],
      [fieldsMapJson['gua_resi_addr_ln1']]: guarantor['gua_resi_addr_ln1'],
      [fieldsMapJson['gua_resi_addr_ln2']]: guarantor['gua_resi_addr_ln1'],
      [fieldsMapJson['gua_city']]: guarantor['gua_city'],
      [fieldsMapJson['gua_state']]: guarantor['gua_state'],
      [fieldsMapJson['gua_pincode']]: guarantor['gua_pincode'],
      [fieldsMapJson['gua_per_addr_ln1']]: guarantor['gua_per_addr_ln1'],
      [fieldsMapJson['gua_per_addr_ln2']]: guarantor['gua_per_addr_ln2'],
      [fieldsMapJson['gua_per_city']]: guarantor['gua_per_city'],
      [fieldsMapJson['gua_per_state']]: guarantor['gua_per_state'],
      [fieldsMapJson['gua_per_pincode']]: guarantor['gua_per_pincode'],
      [fieldsMapJson['gua_aadhaar']]: guarantor['gua_aadhaar'],
      [fieldsMapJson['gua_father_name']]: guarantor['gua_father_name'],
      [fieldsMapJson['gua_mobile']]: guarantor['gua_mobile'],
      [fieldsMapJson['guarantor_email']]: guarantor['gua_email'],
    };
    data.push(temp);
  }
  return data;
};

export const propertyDocumentsMapping = (loanDetailsData) => {
  let data = {};
  if (loanDetailsData['property']) {
    data = {
      ...data,
      [fieldsMapJson[`prop_approx_property_value`]]: loanDetailsData['property']['prop_approx_property_value'],
      [fieldsMapJson[`prop_property_type`]]: loanDetailsData['property']['prop_property_type'],
      [fieldsMapJson[`prop_plot_size`]]: loanDetailsData['property']['prop_plot_size'],
      [fieldsMapJson[`prop_built_up_area`]]: loanDetailsData['property']['prop_built_up_area'],
      [fieldsMapJson[`prop_property_address`]]: loanDetailsData['property']['prop_property_address'],
      [fieldsMapJson[`prop_nature_of_property`]]: loanDetailsData['property']['prop_nature_of_property'],
      [fieldsMapJson[`prop_property_jurisdiction`]]: loanDetailsData['property']['prop_property_jurisdiction'],
    };
    for (let index = 0; index < loanDetailsData['property']['prop_property_owner_details'].length; index++) {
      data = {
        ...data,
        [fieldsMapJson[`prop_owner_full_name_${index + 1}`]]: loanDetailsData['property']['prop_property_owner_details'][index]['prop_owner_name'],
      };
    }
  }
  return data;
};

export const financialDocumentsMapping = (loanDetailsData) => {
  let data = {
    string_vl_borro_bank_name: loanDetailsData['financial_documents']?.borro_bank_name,
    number_vl_borro_bank_acc_num: loanDetailsData['financial_documents']?.borro_bank_acc_num,
    string_vl_borro_bank_type: loanDetailsData['financial_documents']?.borro_bank_type,
  };

  return data;
};

export const additionalDocumentsMapping = (loanDetailsData) => {
  let data = {};

  data = {
    ...data,
    additonal_document_comment: loanDetailsData['additional_documents']?.['addi_docs_comment'] ?? null,
  };

  return data;
};
