export function getLapLeadReviewDetailsWatcher(payload, resolve, reject) {
  return {
    type: 'GET_LAP_LEAD_REVIEW_DETAILS',
    payload,
    resolve,
    reject,
  };
}

export function updateLeadDetailsWatcher(payload, resolve, reject) {
  return {
    type: 'UPDATE_LAP_LEAD_STATUS',
    payload,
    resolve,
    reject,
  };
}
export function getLapActivityLogsWatcher(payload, resolve, reject) {
  return {
    type: 'GET_LAP_ACTIVITY_LOGS',
    payload,
    resolve,
    reject,
  };
}

export function commentDetailsWatcher(payload, resolve, reject) {
  return {
    type: 'COMMENT_LAP_UPDATE_DETAILS',
    payload,
    resolve,
    reject,
  };
}

export function getLeadStatusWatcher(payload, resolve, reject) {
  return {
    type: 'FETCH_LAP_LEAD_STATUS',
    payload,
    resolve,
    reject,
  };
}

export function getLeadOfferWcher(payload, resolve, reject) {
  return {
    type: 'FETCH_LAP_LEAD_OFFER',
    payload,
    resolve,
    reject,
  };
}

export function postLEADSoftOfferWatcher(payload, resolve, reject) {
  return {
    type: 'POST_LAP_LEAD_SOFT_OFFER',
    payload,
    resolve,
    reject,
  };
}

export function getCalculateFeesAndChargesWatcher(payload, resolve, reject) {
  return {
    type: 'FETCH_LAP_FEES_AND_CHARGES_DETAILS',
    payload,
    resolve,
    reject,
  };
}

export function postAadhaarOtpWcher(payload, resolve, reject) {
  return {
    type: 'POST_LAP_AADHAAR_OTP',
    payload,
    resolve,
    reject,
  };
}

export function createLapActivityLogWatcher(payload, resolve, reject) {
  return {
    type: 'CREATE_LAP_ACTIVITY_LOG',
    payload,
    resolve,
    reject,
  };
}

export function getLapLeadDocumentWatcher(payload, resolve, reject) {
  return {
    type: 'FETCH_LAP_LEAD_DOCUMENT',
    payload,
    resolve,
    reject,
  };
}
