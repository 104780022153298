export function getRestructureLoanWatcher(data, resolve, reject) {
  return {
    type: 'GET_RESTRUCTURE_LOAN',
    payload: data,
    resolve,
    reject,
  };
}

export function postRestructureLoanWatcher(data, resolve, reject) {
  return {
    type: 'POST_RESTRUCTURE_LOAN',
    payload: data,
    resolve,
    reject,
  };
}

export function getRestructureLoanTableWatcher(data, resolve, reject) {
  return {
    type: 'GET_RESTRUCTURE_LOAN_TABLE',
    payload: data,
    resolve,
    reject,
  };
}

export function postLoanSimulatorWatcher(data, resolve, reject) {
  return {
    type: 'POST_LOAN_SIMULATOR',
    payload: data,
    resolve,
    reject,
  };
}
