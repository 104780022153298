import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function getRestructureLoanDetails(payload) {
  return axios.get(`${BASE_URL}loan-details/${payload.loan_id}/${payload.company_id}/${payload.product_id}/${payload.user_id}`, payload);
}
export function getRestructureLoanTableDetails(payload) {
  return axios.get(`${BASE_URL}get-restructure-details/${payload.loan_id}/${payload.page}/${payload.limit}`, payload);
}
export function postRestructureLoanDetails(payload) {
  return axios.post(`${BASE_URL}restructure-loan-details`, payload);
}
export function postLoanSimulator(payload) {
  return axios.post(`${BASE_URL}loan-simulator`, payload);
}
