export const COLL_HISTORY_FIELDS = {
  eventType: {
    label: 'Event Type',
    type: 'text',
  },
  'event Type': {
    label: 'Event Type',
    type: 'text',
  },
  endTime: {
    label: 'End Time',
    type: 'time',
  },
  startTime: {
    label: 'Start Time',
    type: 'time',
  },
  scheduled_at: {
    label: 'Scheduled At',
    type: 'time',
  },
  newAddress: {
    label: 'New Address',
    type: 'text',
  },
  date: {
    label: 'Date',
    type: 'time',
  },
  comment: {
    label: 'Comment',
    type: 'text',
  },
  place_contacted: {
    label: 'Place Visited',
    type: 'text',
  },
  placeContacted: {
    label: 'Place Visited',
    type: 'text',
  },
  person_contacted: {
    label: 'Person Visited',
    type: 'text',
  },
  personContacted: {
    label: 'Person Visited',
    type: 'text',
  },
  payment_type: {
    label: 'Payment Type',
    type: 'text',
  },
  payment_mode: {
    label: 'Payment Mode',
    type: 'text',
  },
  payment_method: {
    label: 'Payment Method',
    type: 'text',
  },
  part_amount: {
    label: 'Part Amount',
    type: 'amount',
  },
  left_amount: {
    label: 'Left Amount',
    type: 'amount',
  },
  total_amount: {
    label: 'Total Amount',
    type: 'amount',
  },
  lms_id: {
    label: 'LMS ID',
    type: 'text',
  },
  case_id: {
    label: 'Case ID',
    type: 'text',
  },
  description: {
    label: 'Description',
    type: 'text',
  },
  deposition_status: {
    label: 'Deposition Status',
    type: 'text',
  },
  depositionStatus: {
    label: 'Deposition Status',
    type: 'text',
  },
  ptp_date: {
    label: 'PTP Date',
    type: 'time',
  },
  ptpDateTime: {
    label: 'PTP Date',
    type: 'time',
  },
  slot_id: {
    label: 'Slot ID',
    type: 'text',
  },
  ptp_amount: {
    label: 'PTP Amount',
    type: 'amount',
  },
  ptpAmount: {
    label: 'PTP Amount',
    type: 'amount',
  },
  ptp_action: {
    label: 'PTP Action',
    type: 'text',
  },
  ptpAction: {
    label: 'PTP Action',
    type: 'text',
  },
  case_id: {
    label: 'Case ID',
    type: 'text',
  },
  manager_id: {
    label: 'Manager ID',
    type: 'text',
  },
  borrower_email: {
    label: 'Borrower Email',
    type: 'email',
  },
  payment_date: {
    label: 'Payment Date',
    type: 'time',
  },
  settlement_amount: {
    label: 'Settlement Amount',
    type: 'amount',
  },
  status: {
    label: 'Status',
    type: 'text',
  },
  user: {
    label: 'User',
    type: 'text',
  },
};
