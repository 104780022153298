import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { storedList } from "../../util/localstorage";
import Button from "react-sdk/dist/components/Button/Button";
import { AlertBox } from "../../components/AlertBox";
import { convertIntoINR } from "../../util/helper";
import FormPopup from "react-sdk/dist/components/Popup/FormPopup";
import DownloadImg from "../../views/lending/images/download-button.svg";
import { locDrawdownGetLineDetailsWatcher, locDrawdownCloseLineWatcher, locDrawdownDownloadNOCWatcher } from '../../actions/locDrawdownLineDetails';

export default function LineDetails() {

    const user = storedList("user");
    const { company_id, product_id, loan_id } = useParams();
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [openPopup, setOpenPopup] = useState(false);
    const [data, setData] = useState({
        customer_name: "",
        total_amount_to_be_paid: 0,
        total_repayment_recived: 0,
        current_due: 0,
        current_prin_due: 0,
        current_int_due: 0,
        current_oi_due: 0,
        current_status: 0
    });

    const onClickDownloadReport = () => {
        const payload = {
            user_id: user?._id,
            company_id: company_id,
            product_id: product_id,
            loan_id: loan_id
        }

        new Promise((resolve, reject) => {
            dispatch(locDrawdownDownloadNOCWatcher(payload, resolve, reject))
        }).then(response => {
            const buffer = new Buffer.from(response, 'base64');
            const blob = new Blob([buffer], { type: 'application/pdf' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = href;
            link.download = "noc-file";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
        }).catch(error => {
            showAlert(error?.response?.data?.message ?? "Something went wrong!", 'error');
        })

    };


    const handleAlertClose = () => {
        setAlert(false);
        setSeverity("");
        setAlertMessage("");
    };

    const showAlert = (msg, type) => {
        setAlert(true);
        setSeverity(type);
        setAlertMessage(msg);
        setTimeout(() => {
            handleAlertClose();
        }, 4000);
    };
    const handlePopup = () => {
        setOpenPopup(true);
    }

    const handleClose = () => {
        setOpenPopup(false);
    };

    const handleConfirmed = () => {
        closeLine();
        setOpenPopup(false);
    };
    const closeLine = () => {
        const payload = {
            user_id: user?._id,
            company_id: company_id,
            product_id: product_id,
            loan_id: loan_id
        }

        new Promise((resolve, reject) => {
            dispatch(locDrawdownCloseLineWatcher(payload, resolve, reject))
        }).then(response => {
            getLocLineDetailsData();
            showAlert(response?.message, 'success');
        }).catch(error => {
            showAlert(error?.response?.data?.message ?? "Something went wrong!", 'error');
        })
    }

    const renderDeletePopup = () => {
        return (
            <>
                <FormPopup customStyles={{ top: "62%" }} heading="close" onClose={handleClose} customHeaderStyle={{ paddingLeft: "40%" }} isCancel={true} isOpen={openPopup} >
                    <div style={styles.deletePopupTextStyle}>
                        Are you sure you want to close the line?
                    </div>
                    <div style={{ display: 'flex', marginTop: '8%' }}>
                        <Button label="Cancel" buttonType="secondary" onClick={handleClose} customStyle={styles.customButton1} />
                        <Button label="Confirm" buttonType="primary" onClick={handleConfirmed} customStyle={styles.customButton} />
                    </div>
                </FormPopup>

            </>
        )
    }

    const styles = {
        container: {
            display: "grid",
            gridTemplateRows: "20% 40% 40%",
            backgroundColor: "#F9F8FA",
            borderRadius: "16px",
            width: "96%",
            maxWidth: "90vw",
            margin: "0 auto",
            paddingBottom: "2%",
            overflowX: "auto",
            boxSizing: "border-box",
            minWidth: "510px",
            fontFamily: "Montserrat-Regular-SemiBold",
            marginBottom: "10px",
            marginTop: "2%",
            border: "1px solid #EDEDED",
            height: "30vh"
        },
        ConfirmationText: {
            textAlign: "right",
            fontFamily: "Montserrat-Regular",
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "17px",
            letterSpacing: "0em",
            color: "#161719",
            paddingRight: "3%",
            paddingTop: "2%",
        },
        closeText: {
            color: "#CC0000",
            cursor: "pointer",
            marginLeft: "5px",
        },
        header: {
            display: "flex",
            textAlign: "centre",
            fontFamily: "Montserrat-SemiBold",
            fontSize: "25px",
            fontWeight: "600",
            lineHeight: "17px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#161719",
            paddingLeft: "1%",
            paddingTop: "2%",
        },
        rowStyle: {
            display: "grid",
            gridTemplateColumns: "20% 20% 20% 20% 20%",
            paddingTop: "4%",
            paddingLeft: "2%",
        },
        upperText: {
            fontFamily: "Montserrat-Regular",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "17px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#767888",
        },
        lowerText: {
            fontFamily: "Montserrat-SemiBold",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "17px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#161719",
        },
        rupeeSign: {
            fontFamily: "Open Sans Regular",
        },
        deletePopupTextStyle: {
            fontSize: "18px",
            marginTop: "10px",
            fontFamily: "Montserrat-Medium",
            color: "#A4A4A4",
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "150%",
            marginTop: "10%",
            paddingLeft: "10%",
            textAlign: "centre"
        },
        customButton1: {
            borderRadius: '8px',
            border: "1px solid #475BD8",
            width: '240px',
            height: '56px',
            fontSize: '16px',
            backgroundColor: "white"
        },
        customButton: {
            borderRadius: '8px',
            width: '240px',
            height: '56px',
            fontSize: '16px',
        },
        buttonStyle: {
            width: "max-content",
            height: "35px",
            marginTop: "-9vh",
            marginLeft: "73.5vw",
            backgroundColor: "#FFF",
            fontFamily: "Montserrat-Regular",
            padding: '10px 24px',
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            borderRadius: "21px",
            borderColor: "#475BD8",
            border: "1px solid #475BD8",
            color: "#475BD8",
            whiteSpace: "nowrap",
        },
        downloadButtonStyle: {
            position: "absolute",
            display: "flex",
            gap: "15px",
            marginTop: "3%"
        }
    }


    useEffect(() => {
        getLocLineDetailsData();
    }, []);

    const getLocLineDetailsData = () => {
        const payload = {
            user_id: user._id,
            company_id: company_id,
            product_id: product_id,
            loan_id: loan_id
        };
        new Promise((resolve, reject) => {
            dispatch(locDrawdownGetLineDetailsWatcher(payload, resolve, reject));
        })
            .then((response) => {
                setData(response?.data || {});
            })
            .catch((error) => {
                showAlert(error?.response?.data?.message, "error");
            });

    };

    return (
        <>
            <div style={{ display: "flex", alignItems: "center", marginTop: "2%" }}>
                {alert ? (
                    <AlertBox
                        severity={severity}
                        msg={alertMessage}
                        onClose={handleAlertClose}
                    />
                ) : null}
            </div>
            {openPopup ? renderDeletePopup() : null}
            {data.current_status === 999 && data.noc_status === "Y" && (
                <div style={styles.downloadButtonStyle}>
                    <Button
                        buttonType="secondary"
                        customStyle={styles.buttonStyle}
                        onClick={onClickDownloadReport}
                        label={
                            <React.Fragment>
                                <img
                                    src={DownloadImg}
                                    alt="Download Icon"
                                    style={{ marginRight: "8px", marginLeft: "4px", marginBottom: "2px" }}
                                />
                                Download NOC
                            </React.Fragment>
                        }
                    />
                </div>
            )}
            <div style={styles.container}>
                <div style={styles.header}> Line Details {data.current_status === 999 && "(Closed)"}</div>
                <div style={styles.rowStyle}>
                    <div>
                        <p style={styles.upperText}>LINE ID</p>
                        <p style={styles.lowerText}>{loan_id}</p>
                    </div>
                    <div>
                        <p style={styles.upperText}>CUSTOMER NAME</p>
                        <p style={styles.lowerText}>{data.customer_name}</p>
                    </div>
                    <div>
                        <p style={styles.upperText}>TOATAL AMOUNT TO BE PAID</p>
                        <p style={styles.lowerText}>
                            <span style={styles.rupeeSign}>₹</span>{" "}
                            {convertIntoINR(data.total_amount_to_be_paid)}
                        </p>
                    </div>
                    <div>
                        <p style={styles.upperText}>TOATAL REPAYMENT RECEIVED</p>
                        <p style={styles.lowerText}>
                            <span style={styles.rupeeSign}>₹</span>{" "}
                            {convertIntoINR(data.total_repayment_recived)}
                        </p>
                    </div>
                    <div>
                        <p style={styles.upperText}>CURRENT DUE</p>
                        <p style={styles.lowerText}>
                            <span style={styles.rupeeSign}>₹</span>{" "}
                            {convertIntoINR(data.current_due)}
                        </p>
                    </div>
                </div>
                <div style={styles.rowStyle}>
                    <div>
                        <p style={styles.upperText}>CURRENT PRINCIPAL DUE</p>
                        <p style={styles.lowerText}>
                            <span style={styles.rupeeSign}>₹</span>{" "}
                            {convertIntoINR(data.current_prin_due)}
                        </p>
                    </div>
                    <div>
                        <p style={styles.upperText}>CURRENT INT DUE</p>
                        <p style={styles.lowerText}>
                            <span style={styles.rupeeSign}>₹</span>{" "}
                            {convertIntoINR(data.current_int_due)}
                        </p>
                    </div>
                    <div>
                        <p style={styles.upperText}>CURRENT OI DUE</p>
                        <p style={styles.lowerText}>
                            <span style={styles.rupeeSign}>₹</span>{" "}
                            {convertIntoINR(data.current_oi_due)}
                        </p>
                    </div>
                </div>
            </div>
            {
                data.current_status !== 999 && (
                    <div style={styles.ConfirmationText}>
                        Want to close the line? <span style={styles.closeText} onClick={handlePopup}>Close</span>
                    </div>
                )
            }
        </>
    );

}