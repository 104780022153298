import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { viewAnchorDetailsWatcher, fetchAnchorDocsWatcher, viewAnchorDocsWatcher } from '../../actions/anchor';
import { useParams } from 'react-router-dom';
import DocumentViewPopup from '../lending/DocumentViewPopup';
import AnchorDocumentUploadPopup from '../lending/AnchorDocumentUploadPopup';
import { b64ToBlob } from '../../util/helper';
import { appendScript, removeScript } from '../../util/appendRemoveScript';
import './anchorList.style.css';
import personCheckIcon from 'assets/img/icons/personCheckIcon.svg';
import documentsIcon from 'assets/img/icons/documentsIcon.svg';
import UploadCard from 'react-sdk/dist/components/UploadCard';

const AnchorDetails = (props) => {
  const { pid } = useParams();
  const dispatch = useDispatch();
  const [anchorData, setAnchorData] = useState(null);
  const [anchorDocumentArray, setAnchorDocumentArray] = useState([
    { name: 'COI', code: '145' },
    { name: 'MOA', code: '143' },
    { name: 'AOA', code: '144' },
    { name: 'Rating Document', code: '178' },
    { name: 'Financials for last 2 Financial Years', code: '139' },
    { name: 'Karza Report', code: '179' },
    { name: 'Google Scan - Entity', code: '180' },
    { name: 'Google Scan - Directors', code: '181' },
    { name: 'Undertaking to make payment to Escrow Account', code: '182' },
    { name: 'Anchor Approval CAM', code: '183' },
  ]);
  const [viewDocument, setViewDocument] = useState(false);
  const [viewDocumentUpload, setViewDocumentUpload] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);

  useEffect(() => {
    appendScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.5.141/pdf.min.js');
    dispatch(
      viewAnchorDetailsWatcher(
        { anchor_id: pid },
        async (company) => {
          setAnchorData(company);
        },
        (error) => {},
      ),
    );
  }, []);

  useEffect(() => {
    if (anchorData?._id) fetchAnchorDocs();
  }, [anchorData]);

  useEffect(() => {
    if (currentDoc?.blobUrl) {
      setViewDocument(true);
    }
  }, [currentDoc]);

  const fetchAnchorDocs = () => {
    dispatch(
      fetchAnchorDocsWatcher(
        { anchor_id: pid },
        async (docs) => {
          let copyAnchorDocumentArray = JSON.parse(JSON.stringify(anchorDocumentArray));
          docs?.data.forEach((element) => {
            copyAnchorDocumentArray.forEach((item) => {
              if (item.code === element.code) {
                item['doc_key'] = element.doc_key;
                item['file_url'] = element.file_url;
              }
            });
          });
          setAnchorDocumentArray(copyAnchorDocumentArray);
        },
        (error) => {},
      ),
    );
  };

  const handleUploadDoc = (doc) => {
    setCurrentDoc(doc);
    setViewDocumentUpload(true);
  };

  const handleViewDoc = (doc) => {
    let data = {
      company_id: pid,
      file_url: doc.file_url,
    };
    setCurrentDoc(doc);
    dispatch(
      viewAnchorDocsWatcher(
        { anchor_id: pid, file_url: doc.file_url },
        async (docs) => {
          handleDocumentPopUp(docs, null, doc);
        },
        (error) => {},
      ),
    );
  };

  const handleDocumentPopUp = (pdf, field, doc) => {
    try {
      let contentType = 'application/pdf';

      if (doc.code === '114' || doc.code === '116') {
        contentType = 'text/*';
        pdf = pdf.replace(/^data:(.*,)?/, '');
      }

      if (pdf.indexOf(`data:${contentType};base64,`) >= 0) pdf = pdf.replace(`data:${contentType};base64,`, '');
      const blob = b64ToBlob(pdf, contentType);
      const blobUrl = URL.createObjectURL(blob);
      setCurrentDoc({
        blobUrl: blobUrl,
        doc_key: doc.doc_key,
        doc_title: doc.name,
        currentDoc: doc,
        code: doc.code,
      });
    } catch (error) {
      s;
    }
  };
  return (
    <>
      <div className="anchor-details-container">
        {viewDocument ? (
          <DocumentViewPopup
            title={currentDoc?.doc_title}
            handleClose={() => {
              setViewDocument(false);
            }}
            blobUrl={currentDoc?.blobUrl}
            openDialog={viewDocument}
            doc_key={currentDoc?.doc_key}
            doc={currentDoc}
          />
        ) : null}
        {viewDocumentUpload ? (
          <AnchorDocumentUploadPopup
            handleClose={() => {
              setViewDocumentUpload(false);
            }}
            openDialog={viewDocumentUpload}
            doc={currentDoc}
            refreshLoanDocs={() => {
              fetchAnchorDocs();
            }}
            loanData={{
              anchor_id: pid,
            }}
          />
        ) : null}
        <div style={{ flexBasis: '49%' }}>
          {anchorData && (
            <>
              <div className="anchor-details-header-style">
                <img src={personCheckIcon} height={30} />
                <div>Anchor Details</div>
              </div>
              <div style={{ margin: '0.5rem 0 0 1rem' }}>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">Anchor ID</div>
                  <div className="anchor-info-data-style">{anchorData._id}</div>
                </div>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">Anchor Name</div>
                  <div className="anchor-info-data-style">{anchorData.name}</div>
                </div>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">CIN</div>
                  <div className="anchor-info-data-style">{anchorData.cin}</div>
                </div>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">GSTIN</div>
                  <div className="anchor-info-data-style">{anchorData.gstin}</div>
                </div>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">Address</div>
                  <div className="anchor-info-data-style">{anchorData.anchor_address}</div>
                </div>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">State</div>
                  <div className="anchor-info-data-style">{anchorData.state}</div>
                </div>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">City</div>
                  <div className="anchor-info-data-style">{anchorData.city}</div>
                </div>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">Pin code</div>
                  <div className="anchor-info-data-style">{anchorData.pin_code}</div>
                </div>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">Website</div>
                  <div className="anchor-info-data-style">{anchorData.website}</div>
                </div>
                <div className="anchor-info-item-container">
                  <div className="anchor-info-item-style">Business Phone</div>
                  <div className="anchor-info-data-style">{anchorData.business_phone}</div>
                </div>
                <hr />
              </div>
              <div className="anchor-details-header-style">
                <img src={personCheckIcon} height={30} />
                <div>Director Details</div>
              </div>
              <div style={{ margin: '0.5rem 0 0 1rem' }}>
                {anchorData.directors.map((director, index) => {
                  return (
                    <div key={`Director ${index + 1}`} className="anchor-info-item-container">
                      <div className="anchor-info-item-style">{`Director ${index + 1}`}</div>
                      <div className="anchor-info-data-style">{director}</div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <div style={{ flexBasis: '49%' }}>
          <div className="anchor-details-header-style">
            <img src={documentsIcon} height={30} />
            <div>Documents</div>
          </div>
          <div className="document-section-container-style">
            {anchorDocumentArray.map((documentItem, index) => {
              return (
                <UploadCard
                  key={index}
                  hasDocument={documentItem?.file_url ? true : false}
                  heading={documentItem.name}
                  viewOnClick={() => {
                    handleViewDoc(documentItem);
                  }}
                  uploadOnClick={() => {
                    handleUploadDoc(documentItem);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default AnchorDetails;
