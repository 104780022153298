import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storedList } from '../../../util/localstorage';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import 'react-sdk/dist/styles/_fonts.scss';
import { validateData } from '../../../util/validation';
import { patchMsmeDetailsWatcher } from '../../actions/msme.action';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import './bookLoans.style.css';
import UploadFileInput from '../../components/uploadFileInput/UploadFileInput';
const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
import { getLeadStatusWatcher } from '../../actions/lead.action';
import { getBookLoanDetailsWatcher, getMsmeLoanDocumentsWatcher } from 'msme/actions/bookLoan.action';
import { postComprehensiveLogsWatcher } from '../../../actions/comprehensiveLogs.action';
import getSectionStatus from './GetLeadSectionStatus/GetLeadSectionStatus';
import { SectionData } from 'msme/config/sectionData';
import { SHAREHOLDING } from './uploadKycData';
import { getSubmitButton } from 'msme/util/resuableJsx/reusableJsx';
import { getSaveAsDraftButton } from 'msme/util/resuableJsx/reusableJsx';

export default function ShareHoldingPattern(props) {
  const { MSMECompanyId, MSMEProductId, documents } = props;
  const dispatch = useDispatch();
  const useAsyncState = (initialState) => {
    const [state, setState] = useState(initialState);

    const asyncSetState = (value) => {
      return new Promise((resolve) => {
        setState(value);

        setState((current) => {
          resolve(current);

          return current;
        });
      });
    };

    return [state, asyncSetState];
  };
  const { setNavIconPrefixState, setNavState, loanAppId, navIconPrefixState, statusMapping } = props;
  const store = useSelector((state) => state);
  const [rowData, setRowData] = useState([]);
  let intervalId;
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [sectionStatusCheck, setSectionStatusCheck] = useState('');
  const [validationData, setValidationData] = useState({});
  const [formComplete, setFormComplete] = useState(false);
  const [newFile, setNewFile] = useState();
  const [totalPercent, setTotalPercent] = useState(0);
  const [backgroundColorBlur, setBackgroundColorBlur] = useState(false);
  const [statusObject, setStatusObject] = useState('');
  const sectionName = 'share_holding_details';

  const [uploadSelectedFile, setUploadSelectedFile] = useState({
    share_holding_certificate_value: '',
  });

  const [documentStateData, setDocumentStateData] = useState({
    share_holding_certificate_value: false,
  });
  const sectionStatus = ['deviation', 'approved', 'rejected', 'failed'];

  const fetchLeadStatus = () => {
    const payload = {
      loan_app_id: loanAppId,
      companyId: MSMECompanyId,
      productId: MSMEProductId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLeadStatusWatcher(payload, resolve, reject));
    })
      .then((response) => {
        let shareholdingDetails = response.find((item) => item.section_code === 'share_holding') ?? {};
        setStatusObject(shareholdingDetails);
        fetchLoanDetails(shareholdingDetails);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  useEffect(() => {
    if (loanAppId) {
      fetchLeadStatus();
      getLoanDocuments();
    }
  }, []);

  const getLoanDocuments = () => {
    const payload = {
      loanAppID: loanAppId,
      companyId: MSMECompanyId,
      productId: MSMEProductId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getMsmeLoanDocumentsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        if (response) {
          setShareHoldingItem(
            SHAREHOLDING.map((givenObj) => {
              const matchingObj = response?.find((otherObj) => otherObj.code === givenObj.documentCode);
              if (matchingObj) {
                setUploadSelectedFile({ share_holding_certificate_value: givenObj.id });
                return {
                  ...givenObj,
                  s3_url: matchingObj.file_url,
                  doc: matchingObj,
                };
              }
              return givenObj;
            }),
          );
        }
      })
      .catch((error) => {
        showAlert(error.response?.data?.message, 'error');
      });
  };

  const formateShareHoldingRowData = (response) => {
    const rowData = [];
    if (response.primary_applicant) {
      rowData.push({
        id: response.borrower_id,
        name: response.primary_applicant.middle_name ? `${response.primary_applicant.first_name} ${response.primary_applicant.middle_name} ${response.primary_applicant.last_name}` : `${response.primary_applicant.first_name} ${response.primary_applicant.last_name}`,
        value: 0,
      });
    }
    if (response.co_applicant_details.length > 0) {
      response.co_applicant_details.map((item) => {
        rowData.push({
          id: item.borrower_id,
          name: item.cb_mname ? `${item.cb_fname} ${item.cb_mname} ${item.cb_lname}` : `${item.cb_fname} ${item.cb_lname}`,
          value: 0,
        });
      });
    }
    if (response.guarantor_details.length > 0) {
      response.guarantor_details.map((item) => {
        rowData.push({
          id: item.borrower_id,
          name: item.gua_mname ? `${item.gua_fname} ${item.gua_mname} ${item.gua_lname}` : `${item.gua_fname} ${item.gua_lname}`,
          value: 0,
        });
      });
    }
    let prevPercentage = 0;
    response?.share_holding_details?.forEach((el1) => {
      const index = rowData.findIndex((el2) => el2?.id == el1?.borrower_id);
      if (index > -1) {
        rowData[index].value = el1?.share_holder_perc ?? 0;
        prevPercentage += el1?.share_holder_perc ?? 0;
      }
    });
    setTotalPercent(Number(prevPercentage));
    rowData.push({
      id: rowData.length + 1,
      name: 'Total Percentage',
      value: prevPercentage,
    });
    setRowData(rowData);
  };

  const fetchLoanDetails = (leadSection) => {
    new Promise((resolve, reject) => {
      dispatch(
        getBookLoanDetailsWatcher(
          {
            loan_app_id: loanAppId,
            user: user,
          },
          resolve,
          reject,
        ),
      );
    })
      .then((response) => {
        const section_status = leadSection?.subsections && leadSection?.subsections?.length ? leadSection?.subsections[0]?.sub_section_status : leadSection?.section_status ?? 'in_progress';
        if (response.share_holding_details && response.share_holding_details.length && section_status != 'in_progress') {
          let shareHolderData = response.share_holding_details;
          let rowData = [];
          shareHolderData.forEach((item) => {
            rowData.push({
              id: item.borrower_id,
              name: item.share_holder_name,
              value: item.share_holder_perc,
            });
          });
          const totalPercentage = rowData.reduce((accumulator, item) => {
            return accumulator + Number(item.value);
          }, 0);
          rowData.push({
            id: rowData.length + 1,
            name: 'Total Percentage',
            value: Number(totalPercentage),
          });
          setTotalPercent(Number(totalPercentage));
          setRowData(rowData);
        } else {
          formateShareHoldingRowData(response);
        }
      })
      .catch((error) => {
        showAlert('Something went wrong.', 'error');
      });
  };

  const postShareHoldingDetails = (payload, draft = false) => {
    payload.user_id = user._id;
    payload.msme_company_id = MSMECompanyId;
    payload.msme_product_id = MSMEProductId;
    payload.share_holders = payload.data.map((item) => {
      return {
        borrower_id: item.id,
        share_holder_name: item.name,
        share_holder_perc: item.value,
      };
    });
    delete payload.data;
    payload.loan_app_id = loanAppId;
    (payload.sub_section_code = draft ? null : SectionData.shareholding_pattern.share_holding_section_submit.sub_section_code),
      (payload.section_code = SectionData.shareholding_pattern.section_code),
      (payload.section_sequence_no = SectionData.shareholding_pattern.section_sequence_no),
      (payload.section_name = SectionData.shareholding_pattern.section_name),
      (payload.sub_section_name = SectionData.shareholding_pattern.share_holding_section_submit.sub_section_name),
      (payload.sub_section_sequence_no = SectionData.shareholding_pattern.share_holding_section_submit.sub_section_sequence_no);
    saveLogs(loanAppId, `shareholding_pattern_${draft ? 'save_as_draft' : 'verify_and_next'}`);
    new Promise((resolve, reject) => {
      dispatch(patchMsmeDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        if (!draft) {
          setSectionStatusCheck('inProgress');
          setStatusCheckApi(loanAppId, SectionData.shareholding_pattern.section_code, SectionData.shareholding_pattern.share_holding_section_submit.sub_section_code, dispatch);
          showAlert(response?.message || 'Success', 'success');
        } else {
          showAlert('Draft Saved Successfully' || 'Success', 'success');
        }
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const [shareHoldingItem, setShareHoldingItem] = useState(
    SHAREHOLDING.map((givenObj) => {
      const matchingObj = documents?.find((otherObj) => otherObj.code === givenObj.documentCode);

      if (matchingObj) {
        return {
          ...givenObj,
          s3_url: matchingObj.file_url,
          doc: matchingObj,
        };
      } else {
        return givenObj;
      }
    }),
  );

  const setStatusCheckApi = async (loanAppID, sectionCode, subSectionCode, dispatch) => {
    intervalId = setInterval(async () => {
      try {
        const status = await getSectionStatus(loanAppID, user, MSMECompanyId, MSMEProductId, sectionCode, subSectionCode, dispatch);
        if (status == 'approved') {
          if (subSectionCode === SectionData.shareholding_pattern.share_holding_section_submit.sub_section_code) {
            setSectionStatusCheck('completed');
            setNavState('Financial Docs');
          }
          clearInterval(intervalId);
        } else if (status == 'deviation') {
          if (subSectionCode === SectionData.shareholding_pattern.share_holding_section_submit.sub_section_code) {
            setSectionStatusCheck('completed');
            setNavState('Financial Docs');
          }
          clearInterval(intervalId);
        } else if (status == 'rejected') {
          setSectionStatusCheck('');
          clearInterval(intervalId);
        }
        setNavIconPrefixState((prevState) => ({
          ...prevState,
          Shareholding: statusMapping(status),
        }));
      } catch (error) {
        clearInterval(intervalId);
      }
    }, 4000);
  };

  const showAlert = (msg, type) => {
    const element = document.getElementById('TopNavBar');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const change = (e, type, data) => {
    const value = Number(e.value);
    let field = `${type}_vl_${data.id}`;
    let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), value);
    setValidationData((prevState) => ({
      ...prevState,
      [`${field}State`]: !isValid ? 'has-danger' : '',
    }));
    let newSum = 0;
    if (value >= 0) {
      let tempSum = 0;
      let itemSum = 0;
      rowData.forEach((item) => {
        if (item.name !== 'Total Percentage') {
          tempSum += Number(item.value);
          if (item.id === data.id) {
            itemSum = item.value;
          }
        }
      });
      newSum = tempSum - itemSum + value;
      let tempRowData = JSON.parse(JSON.stringify(rowData));
      tempRowData.forEach((item) => {
        if (item.id === data.id) {
          item.value = value;
        }
      });
      setRowData(tempRowData);
      setTotalPercent(newSum);
      if (uploadSelectedFile?.share_holding_certificate_value && Math.max(newSum, totalPercent) >= 50) {
        setFormComplete(true);
      } else {
        setFormComplete(false);
      }
    }
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleSubmit = (event) => {
    const postData = {};
    let sectionDataArr = [];

    let formValidated = true;

    if (totalPercent > 100 || totalPercent < 50) {
      showAlert('Invalid Total Percentage', 'error');
      return;
    }

    if (uploadSelectedFile.share_holding_certificate_value == '') {
      showAlert('Invalid Total Percentage', 'error');
      return;
    }
    rowData.forEach((item) => {
      if (item.name !== 'Total Percentage') {
        if (Number(item.value) < 100 && Number(item.value) >= 0) {
          sectionDataArr.push({
            id: item.id,
            name: item.name,
            value: item.value,
          });
        } else {
          formValidated = false;
        }
      }
    });
    if (Object.keys(validationData).includes('has-danger')) {
      formValidated = false;
    }
    postData['section'] = 'share-holding-details';
    postData.data = sectionDataArr;
    if (uploadSelectedFile?.share_holding_certificate_value) {
      postData.document = uploadSelectedFile?.share_holding_certificate_value;
    } else {
      formValidated = false;
    }
    if (formValidated) {
      postShareHoldingDetails(postData);
    } else {
      showAlert('Invalid Data', 'error');
      setTimeout(() => {
        handleAlertClose();
      }, 4000);
    }
  };

  const handleDraftSubmit = (event) => {
    const postData = {};
    let sectionDataArr = [];

    let formValidated = true;

    rowData.forEach((item) => {
      if (item.name !== 'Total Percentage') {
        if (Number(item.value) < 100 && Number(item.value) >= 0) {
          sectionDataArr.push({
            id: item.id,
            name: item.name,
            value: item.value,
          });
        } else {
          formValidated = false;
        }
      }
    });
    if (Object.keys(validationData).includes('has-danger')) {
      formValidated = false;
    }
    postData['section'] = 'share-holding-details';
    postData.data = sectionDataArr;
    if (uploadSelectedFile?.share_holding_certificate_value) {
      postData.document = uploadSelectedFile?.share_holding_certificate_value;
    }

    if (formValidated) {
      postShareHoldingDetails(postData, true);
    } else {
      showAlert('Invalid Data', 'error');
      setTimeout(() => {
        handleAlertClose();
      }, 4000);
    }
  };

  const handleInputBoxClick = (event) => {
    setNewFile('share_holding_certificate_value');
    if (totalPercent >= 50) {
      setFormComplete(true);
    }
    setUploadSelectedFile({ share_holding_certificate_value: event });
  };

  const renderShareHolderPercentageUi = (shareHolderDetails) => {
    return (
      <div
        key={shareHolderDetails.id}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '75px',
          backgroundColor: 'white',
          color: 'black',
          fontSize: '16px',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid var(--neutrals-neutral-10, #E5E5E8)',
          borderLeft: '1px solid var(--neutrals-neutral-10, #E5E5E8)',
          borderRight: '1px solid var(--neutrals-neutral-10, #E5E5E8)',
          borderBottomLeftRadius: shareHolderDetails.name === 'Total Percentage' ? '15px' : '0px',
          borderBottomRightRadius: shareHolderDetails.name === 'Total Percentage' ? '15px' : '0px',
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
          }}
        >
          <span style={{ marginLeft: '24px' }}>{shareHolderDetails.name}</span>
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            height: '25px',
            alignItems: 'center',
            marginRight: '24px',
            marginLeft: '20px',
          }}
        >
          {shareHolderDetails.name === 'Total Percentage' ? (
            <>
              <span
                style={{
                  color: totalPercent > 100 ? 'red' : 'var(--neutrals-neutral-100, #161719)',
                  fontFamily: 'Montserrat-Regular',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '150%',
                }}
              >
                {totalPercent}%
              </span>
            </>
          ) : (
            <>
              <InputBox
                id={`${shareHolderDetails.id}_box`}
                label={'Enter Value'}
                type={'number'}
                isDrawdown={false}
                initialValue={shareHolderDetails.value ? shareHolderDetails.value : null}
                isDisabled={props.type == 'view' || navIconPrefixState['Shareholding'] == 'success' ? true : false}
                onClick={(event) => change(event, 'number', shareHolderDetails)}
                customClass={{
                  height: '56px',
                  width: '100%',
                  maxWidth: '100%',
                }}
                customInputClass={{
                  minWidth: '100%',
                  backgroundColor: '#fff',
                }}
                error={(() => {
                  return validationData[`number_vl_${shareHolderDetails.id}State`] === 'has-danger' ? true : false;
                })()}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const saveLogs = (identifier, event_name) => {
    new Promise((resolve, reject) => {
      const payload = {
        user_id: user?._id,
        identifier: identifier,
        log_details: [
          {
            user_id: user?._id,
            event_name: event_name,
            timestamp: new Date(),
          },
        ],
      };
      if (payload?.user_id && identifier && event_name) {
        dispatch(postComprehensiveLogsWatcher(payload, resolve, reject));
      }
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '98%',
        marginLeft: '1.7%',
        justifyContent: 'space-around',
      }}
    >
      <h4
        style={{
          color: 'var(--neutrals-neutral-100, #161719)',
          fontFamily: 'Montserrat-semibold',
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '150%',
        }}
      >
        Shareholding Pattern
      </h4>
      <div>
        <div style={{ maxWidth: '100%', marginTop: '20px' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#E5E5E8',
              color: 'black',
              fontSize: '16px',
              borderRadius: '15px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '64px',
                alignItems: 'center',
              }}
            >
              <div style={{ width: '50%' }}>
                <span
                  style={{
                    color: 'var(--neutrals-neutral-100, #161719)',
                    fontFamily: 'Montserrat-SemiBold',
                    marginLeft: '24px',
                  }}
                >
                  NAME
                </span>
              </div>
              <div style={{ width: '50%' }}>
                <span
                  style={{
                    color: 'var(--neutrals-neutral-100, #161719)',
                    fontFamily: 'Montserrat-SemiBold',
                  }}
                >
                  SHAREHOLDING PERCENTAGE
                </span>
              </div>
            </div>
            {rowData.map(renderShareHolderPercentageUi)}
          </div>
        </div>
      </div>
      <h5
        style={{
          color: 'var(--neutrals-neutral-100, #161719)',
          fontFamily: 'Montserrat-semibold',
          fontSize: '20px',
          marginBottom: '16px',
          marginTop: '40px',
        }}
      >
        Upload Shareholding Pattern Certificate
      </h5>
      <div style={{ display: 'flex', maxWidth: '20rem', flexDirection: 'column' }}>
        <div>
          <UploadFileInput
            onDataCallback={(event) => handleInputBoxClick(event)}
            backgroundColorChange={true}
            backgroundColorBlur={props.type && (props.type == 'view' || props.type === 'edit') ? false : backgroundColorBlur}
            items={shareHoldingItem}
            title=""
            showAlert={showAlert}
            isXML={false}
            setDocumentStateData={setDocumentStateData}
            loanAppId={loanAppId}
            sectionName={sectionName}
            data={{ company_id: MSMECompanyId, product_id: MSMEProductId }}
            MSMECompanyId={MSMECompanyId}
            MSMEProductId={MSMEProductId}
            isChange={sectionStatus.includes(statusObject?.section_status) ? false : true}
          />
        </div>
      </div>
      {props.type == 'view' || navIconPrefixState['Shareholding'] == 'success' ? null : (
        <div className="book-loan-button-containe book-loan-button-alignment-double-button">
          {getSubmitButton('Verify & Next', handleSubmit, sectionStatusCheck == 'inProgress' ? true : false, formComplete, {})}
          {getSaveAsDraftButton('Save as Draft', handleDraftSubmit, false)}
        </div>
      )}
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}
