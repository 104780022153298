import React, { useState, useEffect } from 'react';
import DashboardImage from './DashboardImage.svg';
import 'react-sdk/dist/styles/_fonts.scss';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import MonthlyCharts from './MonthlyCharts';
import DaysCharts from './DaysCharts';
import { useDispatch } from 'react-redux';
import { allAnchorListWatcher, anchorDashboardDataWatcher, DashboardDataWatcher } from 'actions/anchor';
import { checkAccessTags } from 'util/uam';
import DashboardCard from './DashboardCardComponent';
import { storedList } from '../../../util/localstorage';
import { colendersListWatcher } from 'actions/colenders';

function Dashboard() {
  const [anchor, setAnchor] = useState();
  const [weekLabel, setWeekLabel] = useState('Monthly');
  const [dashboardDetailsData, setDashboardDetailsData] = useState({});
  const [anchorList, setAnchorList] = useState([]);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const user = storedList('user');
  const [durationType, setDurationType] = useState(6);
  const [disbChartData, setDisbChartData] = useState([]);
  const [dueChartData, setDueChartData] = useState([]);
  const [isUserAnchor, setIsUserAnchor] = useState(false);
  const [coLenderList, setCoLenderList] = useState([]);
  const [selectedColender, setSelectedColender] = useState();

  useEffect(() => {
    fetchColenderList();
  }, []);

  useEffect(() => {
    // if (user?.type == 'admin' || isUserAnchor) {
    // fetchDashboardData(user?.type == 'admin' ? 'All': user.company_id);
    // }
    fetchDashboardData(null, durationType);
  }, []);

  const fetchColenderList = () => {
    new Promise((resolve, reject) => {
      dispatch(colendersListWatcher({}, resolve, reject));
    })
      .then((res) => {
        const coLenders = res.map((item) => {
          return {
            co_lender_name: item.co_lender_name,
            _id: item._id,
            co_lender_id: item.co_lender_id,
          };
        });
        setCoLenderList(coLenders);
      })
      .catch((error) => {
        showAlert(error.message, 'error');
      });
  };

  const handleColenderChange = (e) => {
    fetchDashboardData(e.value, durationType);
    setSelectedColender(e.value);
  };

  const handleDurationType = (e) => {
    fetchDashboardData(selectedColender, e.value);
    setDurationType(e.value);
  };

  const fetchDashboardData = (selectedColender, durationType) => {
    let obj = { duration: durationType };
    if (selectedColender) {
      obj['colender_id'] = selectedColender;
    }
    dispatch(
      DashboardDataWatcher(
        obj,
        (response) => {
          setDashboardDetailsData(response?.data);
          setDisbChartData(response?.data?.disb_chart_data);
          setDueChartData(response?.data?.appl_chart_data);
        },
        (error) => {
          console.log('error', error);
        },
      ),
    );
  };

  return (
    <>
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
          <div style={{ color: '#161719', fontSize: '20px', fontFamily: 'Montserrat-Bold' }}>Loan Insights</div>
          <div style={{ display: 'flex' }}>
            <InputBox
              customClass={{ height: '58px', width: '15vw', pointerEvents: user?.type && user.type == 'company' ? 'none' : 'auto' }}
              customDropdownClass={{ marginTop: '8px', maxHeight: '600px', zIndex: 5, width: '15vw' }}
              initialValue={user?.company_name ? user?.company_name : 'All'}
              isDisabled={user?.company_name ? true : false}
              onClick={handleColenderChange}
              label="NBFC"
              isDrawdown={true}
              options={[
                { value: '', label: 'All' },
                ...coLenderList.map((item) => {
                  return { value: item.co_lender_id, label: item.co_lender_name };
                }),
              ]}
            />
            <InputBox
              customClass={{ height: '58px', width: '15vw', marginLeft: '15px' }}
              customDropdownClass={{ marginTop: '8px', maxHeight: '600px', zIndex: 5, width: '15vw' }}
              label="Duration"
              initialValue={durationType ? `Past ${durationType} month` : 'Past 6 month'}
              onClick={handleDurationType}
              isDrawdown={true}
              options={[
                { value: '1', label: 'Past 1 month' },
                { value: '2', label: 'Past 2 month' },
                { value: '3', label: 'Past 3 month' },
                { value: '4', label: 'Past 4 month' },
                { value: '5', label: 'Past 5 month' },
                { value: '6', label: 'Past 6 month' },
              ]}
            />
          </div>
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: '53% 44%', padding: '20px', marginBottom: '30px', gap: '30px' }}>
          <span style={{ border: '2px solid #E5E5E8', padding: '15px', borderRadius: '10px' }}>
            <MonthlyCharts weekLabel={weekLabel.toLowerCase()} data={dueChartData} />
          </span>
          <span style={{ border: '2px solid #E5E5E8', padding: '15px', borderRadius: '10px' }}>
            <DaysCharts weekLabel={weekLabel.toLowerCase()} data={disbChartData} />
          </span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
          <div style={{ color: '#161719', fontSize: '20px', fontFamily: 'Montserrat-Bold' }}>Co-Lender Share</div>
        </div>
        <div>
          <div style={{ display: 'grid', gridTemplateColumns: '31.6% 31.6% 31.6%', gap: '30px', padding: '20px' }}>
            <DashboardCard title={'Loan Amount Disbursed'} rupeesIcon={true} value={dashboardDetailsData?.total_amt_disb} />
            <DashboardCard title={'Repayment Received'} rupeesIcon={true} value={dashboardDetailsData?.repayment_received} />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', marginTop: '30px' }}>
          <div style={{ color: '#161719', fontSize: '20px', fontFamily: 'Montserrat-Bold' }}>DA Insights</div>
          <div style={{ display: 'flex' }}>
            <InputBox
              customClass={{ height: '58px', width: '15vw', pointerEvents: user?.type && user.type == 'company' ? 'none' : 'auto' }}
              customDropdownClass={{ marginTop: '8px', maxHeight: '600px', zIndex: 5, width: '15vw' }}
              initialValue={user?.company_name ? user?.company_name : 'All'}
              isDisabled={user?.company_name ? true : false}
              onClick={handleColenderChange}
              label="Company"
              isDrawdown={true}
              options={[
                { value: 'All', label: 'All' },
                ...anchorList.map((item) => {
                  return { value: item._id, label: item.name };
                }),
              ]}
            />
            <InputBox
              customClass={{ height: '58px', width: '15vw', marginLeft: '15px', pointerEvents: user?.type && user.type == 'company' ? 'none' : 'auto' }}
              customDropdownClass={{ marginTop: '8px', maxHeight: '600px', zIndex: 5, width: '15vw' }}
              initialValue={user?.company_name ? user?.company_name : 'All'}
              isDisabled={user?.company_name ? true : false}
              onClick={handleColenderChange}
              label="DA"
              isDrawdown={true}
              options={[
                { value: 'All', label: 'All' },
                ...anchorList.map((item) => {
                  return { value: item._id, label: item.name };
                }),
              ]}
            />
          </div>
        </div>
        <div>
          <div style={{ display: 'grid', gridTemplateColumns: '31.6% 31.6% 31.6%', gap: '30px', padding: '20px' }}>
            <DashboardCard title={'Current Principal Outstanding'} rupeesIcon={true} value={dashboardDetailsData?.total_assigned_limit} change={dashboardDetailsData?.assigned_limit_perc || 0} bottomText={true} />
            <DashboardCard title={'Current Repayment Amount'} rupeesIcon={true} value={dashboardDetailsData?.total_available_limit} change={dashboardDetailsData?.available_limit_perc || 0} bottomText={true} />
            <DashboardCard title={'Current Int Outstanding'} value={dashboardDetailsData?.borrower_onboarded} change={dashboardDetailsData?.borrower_onboarded_perc || 0} bottomText={true} />
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: '31.6% 31.6% 31.6%', gap: '30px', padding: '20px' }}>
            <DashboardCard title={'Current Outstanding Loan Amount'} rupeesIcon={true} value={dashboardDetailsData?.total_disbursement} change={dashboardDetailsData?.disbursement_perc || 0} bottomText={true} />
          </div>
        </div>
      </>
    </>
  );
}

export default Dashboard;
