import { all } from 'redux-saga/effects';
import { createUserWatcherSaga, loginWatcherSaga, userListWatcherSaga, toggleUserStatusWatcherSaga, updateUserWatcherSaga, resetPasswordWatcherSaga, searchUserWatcherSaga } from './user';
import { addCompanyWatcherSaga, getAllCompaniesWatcherSaga, getAllLocCompaniesWatcherSaga, getAllCoLenderCompaniesWatcherSaga, getCompanyByIdWatcherSaga } from './company';
import { getRoleMetrixWatcherSaga, addDesignationWatcherSaga, addDepartmentWatcherSaga, addRoleWatcherSaga, getRoleWatcherSaga, getDepartmentWatcherSaga, getDesignationWatcherSaga, updateRoleWatcherSaga } from './roleMetrix';
import { addPartnerWatcherSaga, updatePartnerWatcherSaga, partnerListWatcherSaga, viewPartnerDetailsWatcherSaga, uploadPartDocsWatcherSaga, fetchPartDocsWatcherSaga, viewPartDocsWatcherSaga } from './addPartner';

import { addAnchorWatcherSaga, anchorListWatcherSaga, viewAnchorDetailsWatcherSaga, uploadAnchorDocsWatcherSaga, fetchAnchorDocsWatcherSaga, viewAnchorDocsWatcherSaga, allAnchorListWatcherSaga, anchorDashboardDataWatcherSaga , DashboardDataWatcherSaga } from './anchor';
import { getDisbursementRequestWatcherSaga } from './disbursementRequest';
import { getLoanTemplateNamesWatcherSaga, addLoanTypeWatcherSaga, addLoanTemplateNamesWatcherSaga, getLoanTypeWatcherSaga, tempXlsxToJsonWatcherSaga, getDefaultTemplatesWatcherSaga, getCompanyLoanSchemaWatcherSaga, addLoanDocTemplateWatcherSaga, getSchemaTemplatesWatcherSaga, updateCustomTemplatesWatcherSaga } from './loanType';
import { addLoanSchemaWatcherSaga, getLoanSchemaByCompanyIdWatcherSaga, updateLoanSchemaWatcherSaga, loadTemplateWatcherSaga } from './loanSchema';
import { createProductWatcherSaga, toggleProductStatusWatcherSaga, getAllProductByCompanyIdWatcherSaga, getAllProductByLocCompanyIdWatcherSaga, getPostmanCollectionLoanBookWatcherSaga, getProductByIdWatcherSaga, companyProductIdWatcherSaga, getProductByCompanyWatcherSaga, createProductWithConfigWatcherSaga } from './product';
import { updProductDueConfigWatcherSaga } from './productDueConfig';
import { getTokenWatcherSaga } from './generateToken';
import { addDisburseConfigChannelWatcherSaga, getAllDisburseConfigChannelWatcherSaga, updateDisburseConfigChannelWatcherSaga, updateDisburseConfigChannelStatusWatcherSaga, deleteDisburseConfigChannelByIdWatcherSaga, getDisburseConfigChannelByCIDPIDWatcherSaga, addColenderDisburseConfigChannelWatcherSaga } from './disbursementChannelConfig';
import {
  loanRequestFormWatcherSaga,
  loanRequestFormPutWatcherSaga,
  getLoanRequestDataWatcherSaga,
  getLoanRequestByLoanIdWatcherSaga,
  getActivityLogWatcherSaga,
  leadDetailsByIdWatcherSaga,
  getLeadDataByLoanAppIdWatcherSaga,
  deleteLeadWatcherSaga,
  leadManualReviewWatcherSaga,
  settlementRequestWatcherSaga,
  settlementDecisionWatcherSaga,
  getLeadDetailApiWatcherSaga,
  getLapLeadDetailApiWatcherSaga,
  getLeadDataByLoanIdWatcherSaga,
  updateLeadDataByLoanIdWatcherSaga,
  downloadCibilReportLoanWatcherSaga,
  leadSectionUpdateSaga,
} from './loanRequest';
import { repaymentScheduleForLocWatcherSaga, repaymentScheduleFormPostWatcherSaga, repaymentScheduleListWatcherSaga, repaymentScheduleForRaiseDueWatcherSaga } from './repaymentSchedule';
import { transactionHistoryListWatcherSaga, drawDownRequestListWatcherSaga, drawDownRequestDetailsWatcherSaga, rejectDrawDownRequestWatcherSaga } from './transactionHistory';
import { repaymentFormPostWatcherSaga } from './repayment';
import { fetchLoanSchemaCustomeDataWatcherSaga, getAllLoanBookingTemplateWatcherSaga } from './loanBooking';
import { addServiceWatcherSaga, getAllServicesWatcherSaga, toggleServiceStatusWatcherSaga, editServiceWatcherSaga, getServiceInvoiceWatcherSaga, getCompanyServicesWatcherSaga, toggleCompanyServicesWatcherSaga, getServicesPCByCompanyWatcherSaga, getServiceByIdWatcherSaga } from './services';
import {
  borrowerDetailsWatcherSaga,
  getBalanceDetailWatcherSaga,
  addBorrowerInfoSingleWatcherSaga,
  loanDisbursementWatcherSaga,
  borrowerDetailsByIdWatcherSaga,
  borrowerAcceptDetailsByIdWatcherSaga,
  updateBorrowerInfoCommonUncommonWatcherSaga,
  updateBorrowerInfoWatcherSaga,
  updateDaApprovalWatcherSaga,
  updateBankDetailsWatcherSaga,
  updateMiscDetailsWatcherSaga,
  updateUMRNDetailsWatcherSaga,
  getCustomerIdWatcherSaga,
  updateMarkRepoWatcherSaga,
  fetchBanksListWatcherSaga,
  getDownloadClosureLatterWatcherSaga,
  checkPartPaymentAllowedWatcherSaga,
} from './borrowerInfo';
import { getLoanDocsWatcherSaga, getProductDetailsWatcherSaga, getDocDetailsWatcherSaga, uploadLoanDocumentsWatcherSaga, viewDocsWatcherSaga, uploadDrawDownDocumentsWatcherSaga, getDrawDownDocsWatcherSaga, uploadLoanDocumentsXmlJsonWatcherSaga, getLoanDocumentsWatcherSaga } from './loanDocuments';
import { stateCityWatcherSaga } from './stateCity';
import { getLoanDataWatcherSaga, addLoanTransactionWatcherSaga, getDisbursmentDataWatcherSaga } from './clTransactions';
import { setAlertWatcherSaga } from './common';
import { sendEnhancedReviewWatcherSaga } from './enhancedReview';
import { getBroadcastEventByCompanyIdWatcherSaga, createBroadcastEventWatcherSaga, updateBroadcastEventWatcherSaga, getSubscriptionEventByCompanyIdWatcherSaga, createSubscriptionEventWatcherSaga, updateSubscriptionEventWatcherSaga, updateBroadcastEventStatusWatcherSaga } from './pubsub';
import {
  getListDisbursementChannelWatcherSaga,
  addDisbursementChannelWatcherSaga,
  deleteDisbursementChannelWatcherSaga,
  onboardDisbursementChannelWatcherSaga,
  updateDisbursementChannelWatcherSaga,
  compositeDisbursementWatcherSaga,
  processDrawdownPfWatcherSaga,
  getLoanByStatusWatcherSaga,
  getLoanByStatusForLocWatcherSaga,
  compositeDrawdownWatcherSaga,
  batchDisbursementWatcherWatcherSaga,
  fetchBankDetailsWatcherSaga,
  fetchSchemeDetailsWatcherSaga,
  updateDrawdownRequestWatcherSaga,
  calculateNetDrawDownAmountWatcherSaga,
  compositeTranchDisbursementWatcherSaga,
  rpsGenerateWatcherSaga,
} from './compositeDisbursement';
import { getListDisbursementChannelMasterWatcherSaga } from './disbursementChannelMaster';
import {
  getReportsWatcherSaga,
  generateReportsWatcherSaga,
  downloadReportsWatcherSaga,
  generateRefundReportWatcherSaga,
  getRefundReportWatcherSaga,
  downloadRefundReportWatcherSaga,
  generateInsuranceReportWatcherSaga,
  getInsuranceReportWatcherSaga,
  downloadInsuranceReportWatcherSaga,
  getCibilReportWatcherSaga,
  downloadCibilReportWatcherSaga,
  generateDisbursementFailureReportWatcherSaga,
  getDisbursementFailureReportWatcherSaga,
  downloadDisbursementFailureReportWatcherSaga,
  generateRejectionReportWatcherSaga,
  getRejectionReportWatcherSaga,
  downloadRejectionReportWatcherSaga,
} from './reports';
import { getCoLendersReportsWatcherSaga, generateEscrowReportsWatcherSaga, downloadCoLenderReportsWatcherSaga, downloadCoLenderLoansReportsWatcherSaga, getZipFilesWatcherSaga, getZipFileWatcherSaga } from './reports';
import { getBorrowerReportsWatcherSaga, generateBorrowerReportsWatcherSaga, downloadBorrowerReportsWatcherSaga, getP2pReportsWatcherSaga, generateP2pReportsWatcherSaga, downloadP2pReportsWatcherSaga, downloadUTRReportsWatcherSaga, getCoLenderRepaymentWatcherSaga } from './colenderReports';
import { getRepaymentDueReportsWatcherSaga, generateRepaymentDueReportsWatcherSaga, downloadRepaymentDueReportsWatcherSaga } from './repaymentDueReports';
import { getRepaymentReportsWatcherSaga, generateRepaymentReportsWatcherSaga, downloadRepaymentReportsWatcherSaga } from './repaymentReports';
import { getKycComplianceReportsWatcherSaga, generateKycComplianceReportsWatcherSaga, downloadKycComplianceReportsWatcherSaga } from './kycComplianceReports';
import { getCollateralListWatcherSaga, getCollateralByIdWatcherSaga, updateCollateralWatcherSaga, addCollateralWatcherSaga } from './collateral';
import { downloadSubventionReportsWatcherSaga, generateSubventionReportsWatcherSaga, getSubventionReportsWatcherSaga } from './subventionReport';
import { getEmiDataWatcherSaga, submitForNachPresentationWatcherSaga, nachDetailsWatcherSaga, nachHoldRegistrationWatcherSaga, nachRevokeRegistrationWatcherSaga, createSubscriptionWatcherSaga, nachRowDetailWatcherSaga, nachTransactionDetailWatcherSaga, nachCreatePresentmentWatcherSaga, nachGenerateTokenWatcherSaga, getMandatePurposeWatcherSaga, cancelNachRegistrationWatcherSaga, nachLiveBankDetailsWatcherSaga, getLoanDetailsNachWatcherSaga } from './enach';

import { transactionDetailsWatcherSaga, enachTransactionDetailsWatcherSaga } from './enachTransaction';
import { downloadDPDReportsWatcherSaga, generateDPDReportsWatcherSaga, getDPDReportsWatcherSaga } from './dpdReport';
import { downloadLocDrawdownReportWatcherSaga, generateLocDrawdownReportsWatcherSaga, getLocDrawdownReportsWatcherSaga } from './locDrawdownReport';
import { setCreditLimitWatcherSaga, updateCreditLimitWatcherSaga } from './creditLimit';
import { approveRepaymentsWatcherSaga, getPendingRepaymentListWatcherSaga } from './repaymentApproval';
import { repaymentV2FormPostWatcherSaga, getLapChargesDetailsWatcherSaga } from './repaymentV2';
import { getReconDetailsWatcherSaga } from './recon';
import { getServiceRequestWatcherSaga, putServiceRequestActionWatcherSaga, getForeclosureOfferRequestWatcherSaga, getWaiverRequestWatcherSaga, getWaiverRequestLoanWatcherSaga } from './serviceRequest';
import { getAllTokenWatcherSaga, deleteTokenByIdWatcherSaga, updateTokenWatcherSaga, updateTokenStatusWatcherSaga, getTokenByCompanyWatcherSaga } from './token';

import {
  productTypeListWatcherSaga,
  colendersListWatcherSaga,
  createColenderWatcherSaga,
  getColenderWatcherSaga,
  putColenderWatcherSaga,
  toggleStatusWatcherSaga,
  newColenderIdWatcherSaga,
  getColenderRepaymentScheduleWatcherSaga,
  getColenderTransactionHistoryWatcherSaga,
  utrUploadWatcherSaga,
  fileUploadApprovalWatcherSaga,
  getUTRfilesWatcherSaga,
  updateStatusWatcherSaga,
  colenderRepaymentWatcherSaga,
  colenderSummaryPopupWatcherSaga,
  colenderDisburseWatcherSaga,
  colenderMarkAsPaidWatcherSaga,
  downloadAllDocumentWatcherSaga,
  getEscrowLoansWatcherSaga,
  escrow20PercentdisbursementWatcherSaga,
} from './colenders';

import { validationsListWatcherSaga } from './validations';

import { getInstallmentAndRepaymentReportsWatcherSaga, generateInstallmentAndRepaymentReportsWatcherSaga, downloadInstallmentAndRepaymentReportsWatcherSaga } from './installmentAndRepaymentReport';
import { downloadMonthlyInsuranceBillingReportsWatcherSaga, generateMonthlyInsuranceBillingReportsWatcherSaga, getMonthlyInsuranceBillingReportsWatcherSaga } from './insuranceBillingReport';
import { getRefundDetailsWatcherSaga, initiateRefundWatcherSaga, initiateExcessRefundWatcherSaga, interestRefundWatcherSaga } from './refund';
import { patchfldgWatcherSaga, postfldgdetailsWatcherSaga, getFldgpartnerWatcherSaga } from '../sagas/fldg/fldg';

import { fetchCompaniesWatcherSaga } from './partnerAnalytics';
import { downloadRepaymentScheduleReportsWatcherSaga, generateRepaymentScheduleReportsWatcherSaga, getRepaymentScheduleReportsWatcherSaga } from './repaymentScheduleReport';
import { getAccessMetrixWatcherSaga, addAccessMetrixWatcherSaga, updateAccessMetrixWatcherSaga } from './accessMetrix';
import { getCkycReportsWatcherSaga, generateCkycReportsWatcherSaga, downloadCkycReportsWatcherSaga, ckycUploadWatcherSaga, getCkycTextFilesWatcherSaga, downloadCkycFileReportsWatcherSaga } from './ckycReport';
import { updateCamsDetailsWatcherSaga, getCamsDetailsWatcherSaga, getBreDetailsWatcherSaga, runCreditEngineWatcherSaga, getSelectorDetailsWatcherSaga, getSelectorDetailsDataWatcherSaga, submitCamsDetailsWatcherSaga, submitSelectorDetailsWatcherSaga, submitSelectorColenderDetailsWatcherSaga, getUdhyamRegistrationDetailsWatcherSaga, getCamsDataWatcherSaga, updateCamsDataWatcherSaga, getCamDetailsWatcherSaga } from './camsDetails';
//
import { getAScoreDataWatcherSaga, updateAScoreDataWatcherSaga } from './aScoreData';
//
import { getOfferDetailsWatcherSaga } from './offerDetails';
import { recalibrateSanctionWatcherSaga } from './recalibrateSanction';
import { getChargeTypesWatcherSaga, applyChargeWatcherSaga, getChargeWatcherSaga } from './charges';
import { getMonthlyCollectionReportsWatcherSaga, downloadMonthlyCollectionReportsWatcherSaga, getServiceUsageReportsWatcherSaga, downloadServiceUsageReportsWatcherSaga } from './monthlyCollectionReport';
import { getDailyCollectionReportsWatcherSaga, getDailyLeadReportsWatcherSaga, getDailyLoanReportsWatcherSaga, downloadDailyCollectionReportsWatcherSaga, downloadDailyLeadReportsWatcherSaga, downloadDailyLoanReportsWatcherSaga } from './dailyCollectionReport';
import { calculateInsurancePremiumWatcherSaga } from './insurance';
import { getInsurancePolicyWatcherSaga } from './downloadInsurancePolicy';
import { getStatusChangeLogsWatcherSaga } from './statusLogs';
import { getScreenReportsWatcherSaga, generateScreenReportsWatcherSaga, downloadScreenReportsWatcherSaga } from './screenReport';
import { getForeclosureOfferWatcherSaga, addForeclosureOfferWatcherSaga, getForceCloseWatcherSaga, addForceCloseWatcherSaga, getForeClosureDetailsByRequestIdWatcherSaga, updateForeClosureRequestSagaIdWatcherSaga, getForeclosureOffersRequestsWatcherSaga } from './foreclosureOffers';
import { getForceCancelWatcherSaga, postForceCancelWatcherSaga } from './forceCancel';
import { createWaiverRequestWatcherSaga, getWaiverRequestDetailsWatcherSaga, getWaiverRequestDetailsByReqIdWatcherSaga, updateWaiverRequestStatusWatcherSaga } from './waiverRequest';
import { getCoLenderTokenWatcherSaga } from './generateCoLenderToken';

import { getLoanStateByLoanIdWatcherSaga } from './loanState';
import { getSoaDetailsWatcherSaga, getSoaRequestWatcherSaga, downloadSoaRequestWatcherSaga } from './soa';
import { getSettlementRequestWatcherSaga } from './settlement';

import { accountHolderListWatcherSaga, addAccountHolderWatcherSaga, editAccountHolderWatcherSaga } from './bankDetails';
import { getTdsRefundRequestWatcherSaga } from './TdsRefundRequest';

import { getAllActiveProductRequestWatcherSaga, getAllProductRequestWatcherSaga, getAllProductSchemeMappingWatcherSaga, getAllProductSchemeWatcherSaga, toggleProductSchemeStatusWatcherSaga, getAllSchemesListWatcherSaga, productSchemeMappedWatcherSaga } from './productSchemeMapping';
import { cashCollateralWatcherSaga, disburseWithheldAmountWatcherSaga } from './cashCollateral';
import { getCollectionBankDetailsWatcherSaga } from './collectionBankDetails';
import { coLendingCamReportsWatcherSaga, downloadCoLendingReportsWatcherSaga, downloadReportRequestFileWatcherSaga, generateCoLendingCamReportsSaga, generateReportRequestWatcherSaga, getReportRequestsWatcherSaga, partnerOperationsReportsWatcherSaga, generatePartnerOperationsReportsSaga, downloadPartnerOperationsWatcherSaga } from './reportRequests';

import { getFosUsersWatcherSaga, getFosUserWatcherSaga, addFosUserWatcherSaga, updateFosUserWatcherSaga } from './collection/user.saga';

import { getDashboardFosSummaryWatcherSaga, getDepositionDataWatcherSaga, getDashboardCaseOverviewWatcherSaga } from './collection/dashboard.saga';
import { getLocationPincodesWatcherSaga } from './collection/location.saga';
import { getCollectionCasesWatcherSaga, getCollectionCaseByIdWatcherSaga, assignCollectionCasesWatcherSaga, deAssignCollectionCasesWatcherSaga, getCaseSourcingPartnerWatcherSaga, getCaseCollHistoryByIdWatcherSaga, getCaseCollPaymentDataByIdWatcherSaga, getCollectionCaseAssignWatcherSaga, getCollectionCaseLmsIdWatcherSaga, getCollectionCaseCollIdsWatcherSaga, getCollectionCaseSelectedWatcherSaga, viewCaseLoanDocumentLogsWatcherSaga } from './collection/cases.saga';
import { getAgenciesWatcherSaga, createAgencyWatcherSaga, updateAgencyWatcherSaga } from './collection/agency.saga';

import { getCustomerDataWatcherSaga, getCustomerDocsWatcherSaga, viewCustomerDocsWatcherSaga, getCustomerDetailsWatcherSaga } from './customerInfo';

import { getBatchTransactionDataWatcherSaga, uploadPresentmentFileWatcherSaga, downloadPresentmentFileWatcherSaga } from './batchTransaction';

import { getMsmeAgenciesWatcherSaga } from '../msme/sagas/agency.saga';

import { getMsmeDetailsWatcherSaga, updateLeadDetailsWatcherSaga, getMsmeActivityLogsWatcherSaga, commentDetailsWatcherSaga, getLeadStatusWatcherSaga, getLeadOfferWatcherSaga, validateBSApiWatcherSaga, leadResetWatcherSaga, getCalculateFeesAndChargesWatcherWatcherSaga, postAadhaarOtpWatcherSaga, createMsmeActivityLogWatcherSaga, updatePreliminaryCheckStatusLogWatcherSaga, changeToLeadDeviationWatcherSaga } from '../msme/sagas/lead.saga';

import { postMsmeApplicantDetailsWatcherSaga, patchMsmeDetailsWatcherSaga, putMsmeSaveDraftWatcherSaga, getMsmeCompaniesWatcherSaga, getMsmeProductByCompanyIdWatcherSaga, getMsmeSubmissionStatusWatcherSaga, patchMsmeDocDeleteWatcherSaga, subSectionDeleteWatcherSaga, postEsignRequestWatcherSaga, updateLeadDeviationWatcherSaga, ammendAPIOfferWatcherSaga, verifyAadhaarOtpWatcherSaga, getBicDataWatcherSaga, getInsuranceDetailsWatcherSaga, executeInsuranceDetailsWatcherSaga } from '../msme/sagas/msme.saga';

import { createLoanIDWatcherSaga, getBookLoanWatcherSaga, postLoanDetailsWatcherSaga, getMsmeLoanDocumentsWatcherSaga, getGstStatusIDWatcherSaga } from '../msme/sagas/bookLoan.saga';

import { getBulkUploadDataWatcherSaga, uploadBulkFileWatcherSaga, downloadBulkUploadFileWatcherSaga } from './nachBulkUpload';

import { fetchLocBulkUploadDataWatcherSaga, locDrawdownBulkUploadWatcherSaga, locDownloadBulkUploadWatcherSaga, downloadLocBulkSampleFileWatcherSaga } from './locDrawdownBulkUpload';

import { locDrawdownGetLineDetailsWatcherSaga, locDrawdownCloseLineWatcherSaga, locDrawdownDownloadNocWatcherSaga } from './locDrawdownLineDetails';

import { getNachReportDataWatcherSaga, downloadNachReportFileWatcherSaga } from './nachReports';

import { getMonthlyCoaReportsWatcherSaga, downloadMonthlyCoaReportsWatcherSaga } from './coaReport';

import { getLeadSectionStatusWatcherSaga } from './../msme/sagas/status.saga';

import { getFldgWatcherSaga } from './fldg';

import { getTdsRefundDataWatcherSaga, updateTDSRefundWatcherSaga, getTdsRefundDataDetailsWatcherSaga } from './tdsRefundData';
import { getSFRCompaniesWatcherSaga, getSFRProductsWatcherSaga, getSRFProductDetailsWatcherSaga, createBusinessModelWatcherSaga } from 'serviceFeeRecon/sagas/createBusinessModel.saga';
import { getPartnerProductWatcherSaga } from 'serviceFeeRecon/sagas/reconPartner.saga';
import { getReconSummaryWatcherSaga, getDownloadReportWatcherSaga } from 'serviceFeeRecon/sagas/reconSummary.saga';
import { deleteBusinessModelWatcherSaga } from 'serviceFeeRecon/sagas/reconPartner.saga';
import { getBusinessModelWatcherSaga } from 'serviceFeeRecon/sagas/createBusinessModel.saga';
import { checkBusinessModelExistWatcherSaga } from 'serviceFeeRecon/sagas/createBusinessModel.saga';
import { uploadExcelWatcherSaga } from '../views/collection/dataUpload/sagas/DataUpload';
import { getDownloadExcelSheetSaga, getErrorDownloadExcelSheetSaga, getLoanUpdateStatusSaga, getLoanUploadStatusSaga, getPaymentUpdateStatusSaga, getRefineLoanDataStatusSaga } from './loanDataUploadStatus';
import { getLapDetailsWatcherSaga, updateLapLeadDetailsWatcherSaga, getLapActivityLogsWatcherSaga, commentLapDetailsWatcherSaga, getLapLeadStatusWatcherSaga, getLapLeadOfferWatcherSaga, getLapCalculateFeesAndChargesWatcherWatcherSaga, postLapAadhaarOtpWatcherSaga, createLapActivityLogWatcherSaga, getLapLeadDocumentWatcherSaga, postLeadSoftOfferWatcherSaga } from '../lap/sagas/lead.saga';

import {
  postLapApplicantDetailsWatcherSaga,
  patchLapDetailsWatcherSaga,
  putLapSaveDraftWatcherSaga,
  getLapCompaniesWatcherSaga,
  getLapCompaniesOnlyWatcherSaga,
  getProductByCompanyIdWatcherSaga,
  getLapProductByCompanyIdWatcherSaga,
  getLapSubmissionStatusWatcherSaga,
  patchLapDocDeleteWatcherSaga,
  lapSubSectionDeleteWatcherSaga,
  postLapEsignRequestWatcherSaga,
  updateLapLeadDeviationWatcherSaga,
  ammendLapAPIOfferWatcherSaga,
  lapVerifyAadhaarOtpWatcherSaga,
  getLapBicDataWatcherSaga,
  patchLapSaveAsDraftDetailsWatcherSaga,
} from '../lap/sagas/lap.saga';

import { createLapLoanIDWatcherSaga, getLapBookLoanWatcherSaga, postLapLoanDetailsWatcherSaga, getLapLoanDocumentsWatcherSaga, getLapGstStatusIDWatcherSaga, gstinVerifyStatusWatcherSaga } from '../lap/sagas/bookLoan.saga';

import { getLapLeadSectionStatusWatcherSaga } from './../lap/sagas/status.saga';
import { getComprehensiveLogsWatcherSaga, addComprehensiveLogsWatcherSaga } from './comprehensiveLogs';
import { getLineReconDetailsWatcherSaga } from './lineRecon';
import { findDocumentsWatcherSaga, getDownloadDocumentsWatcherSaga } from './downloadDocuments';
import { updateLapCamsDataWatcherSaga, getLapCamsDataWatcherSaga } from '../lap/sagas/camsDetails.saga';
import { getP2pLoansWatcherSaga, getP2pCompaniesWatcherSaga, getP2pPennyDropReprocessWatcherSaga, getP2pPennyDropRejectWatcherSaga } from './p2ploans';
import { markAsDeviationWatcherSaga } from './markDeviation';
import { getDdRpsReportRequestWatcherSaga, getPrepaymentDetailsWatcherSaga } from './ddRpsReport';
import { downloadBulkFileWatcherSaga, getBulkFileListWatcherSaga, uploadBulkFileDefaultWatcherSaga } from './bulkFile';
import { getRestructureLoanWatcherSaga, postRestructureLoanWatcherSaga, getRestructureTableWatcherSaga, postLoanSimulatorWatcherSaga } from './restructureLoan';
export default function* rootSaga() {
  yield all([
    postLoanSimulatorWatcherSaga(),
    getRestructureLoanWatcherSaga(),
    getRestructureTableWatcherSaga(),
    postRestructureLoanWatcherSaga(),
    getDdRpsReportRequestWatcherSaga(),
    getP2pPennyDropRejectWatcherSaga(),
    getP2pPennyDropReprocessWatcherSaga(),
    getP2pLoansWatcherSaga(),
    getP2pCompaniesWatcherSaga(),
    createUserWatcherSaga(),
    addCompanyWatcherSaga(),
    updateDaApprovalWatcherSaga(),
    getRoleMetrixWatcherSaga(),
    addDesignationWatcherSaga(),
    addDepartmentWatcherSaga(),
    addRoleWatcherSaga(),
    getAllCompaniesWatcherSaga(),
    getAllLocCompaniesWatcherSaga(),
    getRoleWatcherSaga(),
    getDepartmentWatcherSaga(),
    getDesignationWatcherSaga(),
    addPartnerWatcherSaga(),
    updatePartnerWatcherSaga(),
    nachDetailsWatcherSaga(),
    nachHoldRegistrationWatcherSaga(),
    nachRevokeRegistrationWatcherSaga(),
    createSubscriptionWatcherSaga(),
    nachRowDetailWatcherSaga(),
    nachTransactionDetailWatcherSaga(),
    nachCreatePresentmentWatcherSaga(),
    nachGenerateTokenWatcherSaga(),
    getMandatePurposeWatcherSaga(),
    nachLiveBankDetailsWatcherSaga(),
    getLoanDetailsNachWatcherSaga(),
    viewPartnerDetailsWatcherSaga(),
    uploadPartDocsWatcherSaga(),
    commentDetailsWatcherSaga(),
    loginWatcherSaga(),
    userListWatcherSaga(),
    toggleUserStatusWatcherSaga(),
    getLoanTemplateNamesWatcherSaga(),
    addLoanTypeWatcherSaga(),
    updateCustomTemplatesWatcherSaga(),
    getSchemaTemplatesWatcherSaga(),
    addLoanSchemaWatcherSaga(),
    addLoanTemplateNamesWatcherSaga(),
    getLoanTypeWatcherSaga(),
    tempXlsxToJsonWatcherSaga(),
    getDefaultTemplatesWatcherSaga(),
    getLoanSchemaByCompanyIdWatcherSaga(),
    getCompanyLoanSchemaWatcherSaga(),
    updProductDueConfigWatcherSaga(),
    createProductWatcherSaga(),
    getAllProductByCompanyIdWatcherSaga(),
    getAllProductByLocCompanyIdWatcherSaga(),
    toggleProductStatusWatcherSaga(),
    getTokenWatcherSaga(),
    getPostmanCollectionLoanBookWatcherSaga(),
    addDisburseConfigChannelWatcherSaga(),
    addColenderDisburseConfigChannelWatcherSaga(),
    getAllDisburseConfigChannelWatcherSaga(),
    updateDisburseConfigChannelWatcherSaga(),
    updateDisburseConfigChannelStatusWatcherSaga(),
    deleteDisburseConfigChannelByIdWatcherSaga(),
    addServiceWatcherSaga(),
    loanRequestFormWatcherSaga(),
    loanRequestFormPutWatcherSaga(),
    fetchLoanSchemaCustomeDataWatcherSaga(),
    getAllLoanBookingTemplateWatcherSaga(),
    getAllServicesWatcherSaga(),
    toggleServiceStatusWatcherSaga(),
    downloadCoLenderLoansReportsWatcherSaga(),
    editServiceWatcherSaga(),
    patchfldgWatcherSaga(),
    postfldgdetailsWatcherSaga(),
    getLoanRequestDataWatcherSaga(),
    getCompanyByIdWatcherSaga(),
    getProductByIdWatcherSaga(),
    getLoanRequestByLoanIdWatcherSaga(),
    borrowerDetailsWatcherSaga(),
    getBalanceDetailWatcherSaga(),
    addBorrowerInfoSingleWatcherSaga(),
    loanDisbursementWatcherSaga(),
    borrowerDetailsByIdWatcherSaga(),
    borrowerAcceptDetailsByIdWatcherSaga(),
    updateBorrowerInfoCommonUncommonWatcherSaga(),
    getLoanDocsWatcherSaga(),
    getProductDetailsWatcherSaga(),
    uploadLoanDocumentsWatcherSaga(),
    uploadLoanDocumentsXmlJsonWatcherSaga(),
    viewDocsWatcherSaga(),
    getLoanDocumentsWatcherSaga(),
    downloadUTRReportsWatcherSaga(),
    updateBorrowerInfoWatcherSaga(),
    getServiceInvoiceWatcherSaga(),
    getCompanyServicesWatcherSaga(),
    toggleCompanyServicesWatcherSaga(),
    getServicesPCByCompanyWatcherSaga(),
    companyProductIdWatcherSaga(),
    getProductByCompanyWatcherSaga(),
    createProductWithConfigWatcherSaga(),
    getServiceByIdWatcherSaga(),
    addLoanDocTemplateWatcherSaga(),
    stateCityWatcherSaga(),
    getServiceUsageReportsWatcherSaga(),
    downloadServiceUsageReportsWatcherSaga(),
    getActivityLogWatcherSaga(),
    leadDetailsByIdWatcherSaga(),
    downloadCibilReportWatcherSaga(),
    getLeadDataByLoanAppIdWatcherSaga(),
    repaymentScheduleFormPostWatcherSaga(),
    repaymentScheduleListWatcherSaga(),
    repaymentFormPostWatcherSaga(),
    getLoanDataWatcherSaga(),
    addLoanTransactionWatcherSaga(),
    getAllCoLenderCompaniesWatcherSaga(),
    setAlertWatcherSaga(),
    updateLoanSchemaWatcherSaga(),
    getCoLendersReportsWatcherSaga(),
    generateEscrowReportsWatcherSaga(),
    downloadCoLenderReportsWatcherSaga(),
    getBorrowerReportsWatcherSaga(),
    getDocDetailsWatcherSaga(),
    generateBorrowerReportsWatcherSaga(),
    downloadBorrowerReportsWatcherSaga(),
    getP2pReportsWatcherSaga(),
    generateP2pReportsWatcherSaga(),
    downloadP2pReportsWatcherSaga(),
    transactionHistoryListWatcherSaga(),
    getDisbursmentDataWatcherSaga(),
    sendEnhancedReviewWatcherSaga(),
    getBroadcastEventByCompanyIdWatcherSaga(),
    createBroadcastEventWatcherSaga(),
    updateBroadcastEventWatcherSaga(),
    getSubscriptionEventByCompanyIdWatcherSaga(),
    createSubscriptionEventWatcherSaga(),
    updateSubscriptionEventWatcherSaga(),
    updateBroadcastEventStatusWatcherSaga(),
    getListDisbursementChannelWatcherSaga(),
    addDisbursementChannelWatcherSaga(),
    deleteDisbursementChannelWatcherSaga(),
    onboardDisbursementChannelWatcherSaga(),
    updateDisbursementChannelWatcherSaga(),
    getListDisbursementChannelMasterWatcherSaga(),
    getDisburseConfigChannelByCIDPIDWatcherSaga(),
    getReportsWatcherSaga(),
    generateReportsWatcherSaga(),
    downloadReportsWatcherSaga(),
    generateRefundReportWatcherSaga(),
    getRefundReportWatcherSaga(),
    getRepaymentDueReportsWatcherSaga(),
    generateRepaymentDueReportsWatcherSaga(),
    downloadRepaymentDueReportsWatcherSaga(),
    getRepaymentReportsWatcherSaga(),
    generateRepaymentReportsWatcherSaga(),
    downloadRepaymentReportsWatcherSaga(),
    getKycComplianceReportsWatcherSaga(),
    generateKycComplianceReportsWatcherSaga(),
    downloadKycComplianceReportsWatcherSaga(),
    getSubventionReportsWatcherSaga(),
    generateSubventionReportsWatcherSaga(),
    downloadSubventionReportsWatcherSaga(),
    getCollateralListWatcherSaga(),
    getCollateralByIdWatcherSaga(),
    updateCollateralWatcherSaga(),
    getLoanByStatusWatcherSaga(),
    compositeDisbursementWatcherSaga(),
    processDrawdownPfWatcherSaga(),
    setCreditLimitWatcherSaga(),
    updateCreditLimitWatcherSaga(),
    getEmiDataWatcherSaga(),
    submitForNachPresentationWatcherSaga(),
    getDPDReportsWatcherSaga(),
    generateDPDReportsWatcherSaga(),
    downloadDPDReportsWatcherSaga(),
    repaymentV2FormPostWatcherSaga(),
    downloadDPDReportsWatcherSaga(),
    getPendingRepaymentListWatcherSaga(),
    approveRepaymentsWatcherSaga(),
    productTypeListWatcherSaga(),
    getLapChargesDetailsWatcherSaga(),
    colendersListWatcherSaga(),
    getLineReconDetailsWatcherSaga(),
    getReconDetailsWatcherSaga(),
    getServiceRequestWatcherSaga(),
    getAllTokenWatcherSaga(),
    deleteTokenByIdWatcherSaga(),
    updateTokenWatcherSaga(),
    putServiceRequestActionWatcherSaga(),
    updateTokenWatcherSaga(),
    updateTokenStatusWatcherSaga(),
    getTokenByCompanyWatcherSaga(),
    createColenderWatcherSaga(),
    getColenderWatcherSaga(),
    putColenderWatcherSaga(),
    toggleStatusWatcherSaga(),
    newColenderIdWatcherSaga(),
    getInstallmentAndRepaymentReportsWatcherSaga(),
    generateInstallmentAndRepaymentReportsWatcherSaga(),
    downloadInstallmentAndRepaymentReportsWatcherSaga(),
    getColenderRepaymentScheduleWatcherSaga(),
    getColenderTransactionHistoryWatcherSaga(),
    fetchCompaniesWatcherSaga(),
    getMonthlyInsuranceBillingReportsWatcherSaga(),
    generateMonthlyInsuranceBillingReportsWatcherSaga(),
    downloadMonthlyInsuranceBillingReportsWatcherSaga(),
    updateBankDetailsWatcherSaga(),
    updateMiscDetailsWatcherSaga(),
    getCustomerIdWatcherSaga(),
    updateUMRNDetailsWatcherSaga(),
    repaymentScheduleForLocWatcherSaga(),
    getLoanByStatusForLocWatcherSaga(),
    compositeDrawdownWatcherSaga(),
    batchDisbursementWatcherWatcherSaga(),
    fetchBankDetailsWatcherSaga(),
    fetchSchemeDetailsWatcherSaga(),
    updateDrawdownRequestWatcherSaga(),
    calculateNetDrawDownAmountWatcherSaga(),
    getRefundDetailsWatcherSaga(),
    deleteLeadWatcherSaga(),
    leadManualReviewWatcherSaga(),
    settlementRequestWatcherSaga(),
    settlementDecisionWatcherSaga(),
    initiateRefundWatcherSaga(),
    initiateExcessRefundWatcherSaga(),
    interestRefundWatcherSaga(),
    getRepaymentScheduleReportsWatcherSaga(),
    generateRepaymentScheduleReportsWatcherSaga(),
    downloadRepaymentScheduleReportsWatcherSaga(),
    utrUploadWatcherSaga(),
    getUTRfilesWatcherSaga(),
    getAccessMetrixWatcherSaga(),
    addAccessMetrixWatcherSaga(),
    updateAccessMetrixWatcherSaga(),
    getCkycReportsWatcherSaga(),
    generateCkycReportsWatcherSaga(),
    downloadCkycReportsWatcherSaga(),
    updateUserWatcherSaga(),
    updateAccessMetrixWatcherSaga(),
    updateRoleWatcherSaga(),
    uploadDrawDownDocumentsWatcherSaga(),
    getDrawDownDocsWatcherSaga(),
    submitCamsDetailsWatcherSaga(),
    getCamsDetailsWatcherSaga(),
    getBreDetailsWatcherSaga(),
    runCreditEngineWatcherSaga(),
    updateCamsDetailsWatcherSaga(),
    getCamDetailsWatcherSaga(),
    getOfferDetailsWatcherSaga(),
    getZipFilesWatcherSaga(),
    getZipFileWatcherSaga(),
    addCollateralWatcherSaga(),
    recalibrateSanctionWatcherSaga(),
    getChargeTypesWatcherSaga(),
    applyChargeWatcherSaga(),
    updateStatusWatcherSaga(),
    getMonthlyCollectionReportsWatcherSaga(),
    downloadMonthlyCollectionReportsWatcherSaga(),
    getDailyCollectionReportsWatcherSaga(),
    getDailyLeadReportsWatcherSaga(),
    getDailyLoanReportsWatcherSaga(),
    downloadDailyCollectionReportsWatcherSaga(),
    downloadDailyLeadReportsWatcherSaga(),
    downloadDailyLoanReportsWatcherSaga(),
    calculateInsurancePremiumWatcherSaga(),
    getInsurancePolicyWatcherSaga(),
    calculateInsurancePremiumWatcherSaga(),
    getStatusChangeLogsWatcherSaga(),
    generateScreenReportsWatcherSaga(),
    getScreenReportsWatcherSaga(),
    downloadScreenReportsWatcherSaga(),
    resetPasswordWatcherSaga(),
    ckycUploadWatcherSaga(),
    getCkycTextFilesWatcherSaga(),
    downloadCkycFileReportsWatcherSaga(),
    getSelectorDetailsWatcherSaga(),
    submitSelectorDetailsWatcherSaga(),
    submitSelectorColenderDetailsWatcherSaga(),
    getSelectorDetailsDataWatcherSaga(),
    getLeadDetailApiWatcherSaga(),
    getLapLeadDetailApiWatcherSaga(),
    getChargeWatcherSaga(),
    createWaiverRequestWatcherSaga(),
    getForeclosureOfferWatcherSaga(),
    addForeclosureOfferWatcherSaga(),
    getForceCloseWatcherSaga(),
    addForceCloseWatcherSaga(),
    getForceCancelWatcherSaga(),
    postForceCancelWatcherSaga(),
    getWaiverRequestDetailsWatcherSaga(),
    getForeclosureOfferRequestWatcherSaga(),
    getWaiverRequestWatcherSaga(),
    getWaiverRequestDetailsByReqIdWatcherSaga(),
    updateWaiverRequestStatusWatcherSaga(),
    fileUploadApprovalWatcherSaga(),
    downloadLocDrawdownReportWatcherSaga(),
    generateLocDrawdownReportsWatcherSaga(),
    getLocDrawdownReportsWatcherSaga(),
    getForeClosureDetailsByRequestIdWatcherSaga(),
    updateForeClosureRequestSagaIdWatcherSaga(),
    getCoLenderTokenWatcherSaga(),
    getLeadDataByLoanIdWatcherSaga(),
    updateLeadDataByLoanIdWatcherSaga(),
    loadTemplateWatcherSaga(),
    getForeclosureOffersRequestsWatcherSaga(),
    getCoLenderRepaymentWatcherSaga(),
    drawDownRequestListWatcherSaga(),
    getWaiverRequestLoanWatcherSaga(),
    getLoanStateByLoanIdWatcherSaga(),
    colenderRepaymentWatcherSaga(),
    colenderSummaryPopupWatcherSaga(),
    colenderDisburseWatcherSaga(),
    colenderMarkAsPaidWatcherSaga(),
    getSoaDetailsWatcherSaga(),
    getSoaRequestWatcherSaga(),
    downloadSoaRequestWatcherSaga(),
    downloadRefundReportWatcherSaga(),
    searchUserWatcherSaga(),
    getSettlementRequestWatcherSaga(),
    generateInsuranceReportWatcherSaga(),
    getInsuranceReportWatcherSaga(),
    downloadInsuranceReportWatcherSaga(),
    accountHolderListWatcherSaga(),
    addAccountHolderWatcherSaga(),
    editAccountHolderWatcherSaga(),
    getCibilReportWatcherSaga(),
    downloadCibilReportLoanWatcherSaga(),
    drawDownRequestDetailsWatcherSaga(),
    rejectDrawDownRequestWatcherSaga(),
    getAllActiveProductRequestWatcherSaga(),
    getAllProductRequestWatcherSaga(),
    getAllProductSchemeMappingWatcherSaga(),
    getAllProductSchemeWatcherSaga(),
    toggleProductSchemeStatusWatcherSaga(),
    getAllSchemesListWatcherSaga(),
    productSchemeMappedWatcherSaga(),
    getDisbursementFailureReportWatcherSaga(),
    generateDisbursementFailureReportWatcherSaga(),
    downloadDisbursementFailureReportWatcherSaga(),
    partnerListWatcherSaga(),
    repaymentScheduleForRaiseDueWatcherSaga(),
    getAScoreDataWatcherSaga(),
    updateAScoreDataWatcherSaga(),
    cashCollateralWatcherSaga(),
    disburseWithheldAmountWatcherSaga(),
    getUdhyamRegistrationDetailsWatcherSaga(),
    fetchPartDocsWatcherSaga(),
    fetchPartDocsWatcherSaga(),
    viewPartDocsWatcherSaga(),
    getMsmeSubmissionStatusWatcherSaga(),
    addAnchorWatcherSaga(),
    anchorListWatcherSaga(),
    allAnchorListWatcherSaga(),
    anchorDashboardDataWatcherSaga(),
    DashboardDataWatcherSaga(),
    viewAnchorDetailsWatcherSaga(),
    uploadAnchorDocsWatcherSaga(),
    fetchAnchorDocsWatcherSaga(),
    viewAnchorDocsWatcherSaga(),
    downloadAllDocumentWatcherSaga(),
    getEscrowLoansWatcherSaga(),
    escrow20PercentdisbursementWatcherSaga(),
    getFosUsersWatcherSaga(),
    getFosUserWatcherSaga(),
    addFosUserWatcherSaga(),
    updateFosUserWatcherSaga(),
    getTdsRefundRequestWatcherSaga(),
    getLocationPincodesWatcherSaga(),
    getCollectionCasesWatcherSaga(),
    getCollectionCaseByIdWatcherSaga(),
    assignCollectionCasesWatcherSaga(),
    deAssignCollectionCasesWatcherSaga(),
    getDashboardFosSummaryWatcherSaga(),
    getAgenciesWatcherSaga(),
    createAgencyWatcherSaga(),
    updateAgencyWatcherSaga(),
    getDepositionDataWatcherSaga(),
    getCaseSourcingPartnerWatcherSaga(),
    getDashboardCaseOverviewWatcherSaga(),
    getCaseCollHistoryByIdWatcherSaga(),
    getCaseCollPaymentDataByIdWatcherSaga(),
    getCollectionCaseAssignWatcherSaga(),
    getCollectionCaseLmsIdWatcherSaga(),
    getCollectionCaseCollIdsWatcherSaga(),
    getCollectionCaseSelectedWatcherSaga(),
    viewCaseLoanDocumentLogsWatcherSaga(),
    getCollectionBankDetailsWatcherSaga(),
    getReportRequestsWatcherSaga(),
    generateReportRequestWatcherSaga(),
    downloadReportRequestFileWatcherSaga(),
    validationsListWatcherSaga(),
    getCustomerDataWatcherSaga(),
    getCustomerDocsWatcherSaga(),
    viewCustomerDocsWatcherSaga(),
    getCustomerDetailsWatcherSaga(),
    transactionDetailsWatcherSaga(),
    enachTransactionDetailsWatcherSaga(),
    getBatchTransactionDataWatcherSaga(),
    uploadPresentmentFileWatcherSaga(),
    downloadPresentmentFileWatcherSaga(),
    getMsmeAgenciesWatcherSaga(),
    postMsmeApplicantDetailsWatcherSaga(),
    cancelNachRegistrationWatcherSaga(),
    getMsmeDetailsWatcherSaga(),
    updateLeadDetailsWatcherSaga(),
    getBookLoanWatcherSaga(),
    postLoanDetailsWatcherSaga(),
    createLoanIDWatcherSaga(),
    patchMsmeDetailsWatcherSaga(),
    getMsmeActivityLogsWatcherSaga(),
    getBulkUploadDataWatcherSaga(),
    uploadBulkFileWatcherSaga(),
    locDrawdownBulkUploadWatcherSaga(),
    locDownloadBulkUploadWatcherSaga(),
    downloadLocBulkSampleFileWatcherSaga(),
    fetchLocBulkUploadDataWatcherSaga(),
    locDrawdownGetLineDetailsWatcherSaga(),
    locDrawdownCloseLineWatcherSaga(),
    locDrawdownDownloadNocWatcherSaga(),
    downloadBulkUploadFileWatcherSaga(),
    getNachReportDataWatcherSaga(),
    downloadNachReportFileWatcherSaga(),
    getMonthlyCoaReportsWatcherSaga(),
    downloadMonthlyCoaReportsWatcherSaga(),
    putMsmeSaveDraftWatcherSaga(),
    getMsmeCompaniesWatcherSaga(),
    getMsmeProductByCompanyIdWatcherSaga(),
    getMsmeLoanDocumentsWatcherSaga(),
    getLeadSectionStatusWatcherSaga(),
    getGstStatusIDWatcherSaga(),
    getLeadStatusWatcherSaga(),
    getLeadOfferWatcherSaga(),
    validateBSApiWatcherSaga(),
    leadResetWatcherSaga(),
    getCalculateFeesAndChargesWatcherWatcherSaga(),
    patchMsmeDocDeleteWatcherSaga(),
    getBicDataWatcherSaga(),
    getInsuranceDetailsWatcherSaga(),
    executeInsuranceDetailsWatcherSaga(),
    subSectionDeleteWatcherSaga(),
    postEsignRequestWatcherSaga(),
    updateLeadDeviationWatcherSaga(),
    leadSectionUpdateSaga(),
    ammendAPIOfferWatcherSaga(),
    postAadhaarOtpWatcherSaga(),
    createMsmeActivityLogWatcherSaga(),
    changeToLeadDeviationWatcherSaga(),
    getCamsDataWatcherSaga(),
    updateCamsDataWatcherSaga(),
    verifyAadhaarOtpWatcherSaga(),
    getTdsRefundDataWatcherSaga(),
    updateMarkRepoWatcherSaga(),
    fetchBanksListWatcherSaga(),
    getDownloadClosureLatterWatcherSaga(),
    checkPartPaymentAllowedWatcherSaga(),
    updateTDSRefundWatcherSaga(),
    getTdsRefundDataDetailsWatcherSaga(),
    getSFRCompaniesWatcherSaga(),
    getSFRProductsWatcherSaga(),
    getSRFProductDetailsWatcherSaga(),
    createBusinessModelWatcherSaga(),
    getPartnerProductWatcherSaga(),
    getReconSummaryWatcherSaga(),
    getDownloadReportWatcherSaga(),
    deleteBusinessModelWatcherSaga(),
    getBusinessModelWatcherSaga(),
    checkBusinessModelExistWatcherSaga(),
    uploadExcelWatcherSaga(),
    getLoanUploadStatusSaga(),
    getLoanUpdateStatusSaga(),
    getPaymentUpdateStatusSaga(),
    getRefineLoanDataStatusSaga(),
    getDownloadExcelSheetSaga(),
    getErrorDownloadExcelSheetSaga(),
    getFldgWatcherSaga(),
    getFldgpartnerWatcherSaga(),
    getLapDetailsWatcherSaga(),
    updateLapLeadDetailsWatcherSaga(),
    getLapActivityLogsWatcherSaga(),
    commentLapDetailsWatcherSaga(),
    getLapLeadStatusWatcherSaga(),
    getLapLeadOfferWatcherSaga(),
    getLapCalculateFeesAndChargesWatcherWatcherSaga(),
    postLapAadhaarOtpWatcherSaga(),
    createLapActivityLogWatcherSaga(),
    getLapLeadDocumentWatcherSaga(),
    postLeadSoftOfferWatcherSaga(),
    postLapApplicantDetailsWatcherSaga(),
    patchLapDetailsWatcherSaga(),
    putLapSaveDraftWatcherSaga(),
    getLapCompaniesWatcherSaga(),
    getLapCompaniesOnlyWatcherSaga(),
    getProductByCompanyIdWatcherSaga(),
    getLapProductByCompanyIdWatcherSaga(),
    getLapSubmissionStatusWatcherSaga(),
    patchLapDocDeleteWatcherSaga(),
    lapSubSectionDeleteWatcherSaga(),
    postLapEsignRequestWatcherSaga(),
    updateLapLeadDeviationWatcherSaga(),
    ammendLapAPIOfferWatcherSaga(),
    lapVerifyAadhaarOtpWatcherSaga(),
    getLapBicDataWatcherSaga(),
    createLapLoanIDWatcherSaga(),
    getLapBookLoanWatcherSaga(),
    postLapLoanDetailsWatcherSaga(),
    getLapLoanDocumentsWatcherSaga(),
    getLapGstStatusIDWatcherSaga(),
    getLapLeadSectionStatusWatcherSaga(),
    patchLapSaveAsDraftDetailsWatcherSaga(),
    getComprehensiveLogsWatcherSaga(),
    addComprehensiveLogsWatcherSaga(),
    getDownloadDocumentsWatcherSaga(),
    findDocumentsWatcherSaga(),
    updateLapCamsDataWatcherSaga(),
    getLapCamsDataWatcherSaga(),
    gstinVerifyStatusWatcherSaga(),
    markAsDeviationWatcherSaga(),
    getPrepaymentDetailsWatcherSaga(),
    updatePreliminaryCheckStatusLogWatcherSaga(),
    uploadBulkFileDefaultWatcherSaga(),
    getBulkFileListWatcherSaga(),
    downloadBulkFileWatcherSaga(),
    getDisbursementRequestWatcherSaga(),
    coLendingCamReportsWatcherSaga(),
    generateCoLendingCamReportsSaga(),
    downloadCoLendingReportsWatcherSaga(),
    compositeTranchDisbursementWatcherSaga(),
    rpsGenerateWatcherSaga(),
    generateRejectionReportWatcherSaga(),
    getRejectionReportWatcherSaga(),
    downloadRejectionReportWatcherSaga(),
    partnerOperationsReportsWatcherSaga(),
    generatePartnerOperationsReportsSaga(),
    downloadPartnerOperationsWatcherSaga(),
  ]);
}
