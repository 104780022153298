export function getDocumentWatcher(data, resolve, reject) {
  return {
    type: 'GET_ALL_DOCUMENTS',
    payload: data,
    resolve,
    reject,
  };
}

export function findAllDocumentWatcher(data, resolve, reject) {
  return {
    type: 'FIND_ALL_DOCUMENTS',
    payload: data,
    resolve,
    reject,
  };
}
