import { checkAccessTags } from '../../../util/uam';
import { Export_Type } from 'lap/constants/string_constants';
export const leadExportMapData = (loanDetailsData, ext) => {
  let data = { loanDetails: [], entityDetails: [], coborrowerDetails: [], guarantorDetails: [], shareHolderDetails: [], propertyDetails: [] };
  let additionalData = {};
  loanDetailsData.forEach((item) => {
    let loan_app_id = {
      loan_app_id: item.loan_app_id,
    };
    let loanDetails = {
      id: item._id,
      product_id: item.product_id,
      company_id: item.company_id,
      loan_app_id: item.loan_app_id,
      borrower_id: item.borrower_id,
      first_name: item.first_name,
      last_name: item.last_name,
      type_of_addr: item.type_of_addr,
      resi_addr_ln1: item.resi_addr_ln1,
      city: item.city,
      state: item.state,
      pincode: item.pincode,
      address_same: item.address_same,
      per_addr_ln1: item.per_addr_ln1,
      per_addr_ln2: item.per_addr_ln2,
      per_city: item.per_city,
      per_state: item.per_state,
      per_pincode: item.per_pincode,
      appl_phone: item.appl_phone,
      appl_pan: item.appl_pan,
      email_id: item.email_id,
      dob: item.dob,
      gender: item.gender,
      lead_status: item.lead_status,
      loan_status: item.loan_status,
      status: item.status,
    };
    if (checkAccessTags(['tag_lap_lead_view_ext_read_write'])) {
      additionalData = {
        aadhaar_fname: item.aadhaar_fname,
        aadhaar_lname: item.aadhaar_lname,
        aadhaar_dob: item.aadhaar_dob,
        aadhaar_pincode: item.aadhaar_pincode,
        parsed_aadhaar_number: item.parsed_aadhaar_number,
        pan_fname: item.pan_fname,
        pan_lname: item.pan_lname,
        pan_dob: item.pan_dob,
        pan_father_fname: item.pan_father_fname,
        pan_father_lname: item.pan_father_lname,
        parsed_pan_number: item.parsed_pan_number,
        urc_parsing_data: item.urc_parsing_data,
        urc_parsing_status: item.urc_parsing_status,
      };
    }
    loanDetails = {
      ...loanDetails,
      ...additionalData,
      father_fname: item.father_fname,
      loan_amount: item.loan_amount,
      loan_interest_rate: item.loan_interest_rate,
      purpose_of_loan: item.purpose_of_loan,
      co_applicant_owned_house: item.co_applicant_owned_house,
      applicant_owned_house: item.applicant_owned_house,
      self_owned_commercial_property: item.self_owned_commercial_property,
      self_owned_residential_property: item.self_owned_residential_property,
      occupation_type: item.occupation_type,
      house_ownership: item.house_ownership,
      nature_of_ownership: item.nature_of_ownership,
      occupancy_type: item.occupancy_type,
      no_of_years_current_addr: item.no_of_years_current_addr,
      no_of_years_permanent_addr: item.no_of_years_permanent_addr,
      created_at: item.created_at,
      coborrower: item.coborrower?.length,
      guarantor: item.guarantor?.length,
      share_holders: item.share_holders?.length,
      updated_at: item.updated_at,
      aadhar_card_num: item.aadhar_card_num,
      borro_bank_acc_num: item.borro_bank_acc_num,
      borro_bank_branch: item.borro_bank_branch,
      borro_bank_code: item.borro_bank_code,
      borro_bank_ifsc: item.borro_bank_ifsc,
      borro_bank_name: item.borro_bank_name,
      borro_bank_type: item.borro_bank_type,
      fina_docs_comment: item.fina_docs_comment,
      fina_docs_gstin: item.fina_docs_gstin,
      addi_docs_comment: item.addi_docs_comment,
    };
    let entityDetails = {
      entity_type: item.entity_details?.entity_type,
      entity_name: item.entity_details?.entity_name,
      date_of_incorporation: item.entity_details?.date_of_incorporation,
      com_addr_ln1: item.entity_details?.com_addr_ln1,
      com_addr_ln2: item.entity_details?.com_addr_ln2,
      com_city: item.entity_details?.com_city,
      com_state: item.entity_details?.com_state,
      com_pincode: item.entity_details?.com_pincode,
      res_addr_ln1: item.entity_details?.res_addr_ln1,
      res_addr_ln2: item.entity_details?.res_addr_ln2,
      res_city: item.entity_details?.res_city,
      res_state: item.entity_details?.res_state,
      res_pincode: item.entity_details?.res_pincode,
      pan_no: item.entity_details?.pan_no,
      urc_no: item.entity_details?.urc_no,
      cin_no: item.entity_details?.cin_no,
      gst_no: item.entity_details?.gst_no,
      date_of_entity_incorporation: item.entity_details?.date_of_entity_incorporation,
      date_of_commencement: item.entity_details?.date_of_commencement,
      nature_of_business: item.entity_details?.nature_of_business,
      work_profile: item.entity_details?.work_profile,
      monthly_income: item.entity_details?.monthly_income,
      com_nature_of_ownership: item.entity_details?.com_nature_of_ownership,
      com_monthly_rent: item.entity_details?.com_monthly_rent,
      res_monthly_rent: item.entity_details?.res_monthly_rent,
      no_of_years_com_addr: item.entity_details?.no_of_years_com_addr,
      no_of_years_res_addr: item.entity_details?.no_of_years_res_addr,
      res_nature_of_ownership: item.entity_details?.res_nature_of_ownership,
    };
    if (ext === Export_Type.XLSX) {
      entityDetails = {
        ...loan_app_id,
        ...entityDetails,
      };
    }
    let propertyDetails = {
      prop_property_address: item.property?.prop_approx_property_value,
      prop_approx_property_value: item.property?.prop_approx_property_value,
      prop_property_type: item.property?.prop_property_type,
      prop_plot_size: item.property?.prop_plot_size,
      prop_built_up_area: item.property?.prop_built_up_area,
      prop_nature_of_property: item.property?.prop_nature_of_property,
      prop_property_jurisdiction: item.property?.prop_property_jurisdiction,
    };
    if (checkAccessTags(['tag_lap_lead_view_ext_read_write'])) {
      propertyDetails = {
        ...propertyDetails,
        prop_actual_property_value: item.property?.prop_actual_property_value,
      };
    }
    if (ext === Export_Type.XLSX) {
      propertyDetails = {
        ...loan_app_id,
        ...propertyDetails,
      };
    }
    if (item.coborrower.length > 0) {
      item.coborrower.forEach((borrower) => {
        let coborrowerDetails = {
          borrower_id: borrower.borrower_id,
          cb_fname: borrower.cb_fname,
          cb_mname: borrower.cb_mname,
          cb_lname: borrower.cb_lname,
          cb_dob: borrower.cb_dob,
          cb_age: borrower.cb_age,
          cb_gender: borrower.cb_gender,
          cb_mobile: borrower.cb_mobile,
          cb_email: borrower.cb_email,
          cb_father_name: borrower.cb_father_name,
          cb_relationship: borrower.cb_relationship,
          cb_entity_shareholder: borrower.cb_entity_shareholder,
          cb_property_owner: borrower.cb_property_owner,
          cb_resi_addr_ln1: borrower.cb_resi_addr_ln1,
          cb_resi_addr_ln2: borrower.cb_resi_addr_ln2,
          cb_city: borrower.cb_city,
          cb_state: borrower.cb_state,
          cb_pincode: borrower.cb_pincode,
          is_pan_pdf_upload: borrower.is_pan_pdf_upload,
          is_aadhar_pdf_upload: borrower.is_aadhar_pdf_upload,
          cb_per_addr_ln1: borrower.cb_per_addr_ln1,
          cb_per_addr_ln2: borrower.cb_per_addr_ln2,
          cb_per_city: borrower.cb_per_city,
          cb_per_state: borrower.cb_per_state,
          cb_per_pincode: borrower.cb_per_pincode,
          cb_pan: borrower.cb_pan,
          cb_aadhaar: borrower.cb_aadhaar,
        };
        if (ext === Export_Type.XLSX) {
          coborrowerDetails = {
            ...loan_app_id,
            ...coborrowerDetails,
          };
        }
        data.coborrowerDetails.push(coborrowerDetails);
      });
    }
    if (item.guarantor.length > 0) {
      item.guarantor.forEach((guarantor) => {
        let guarantorDetails = {
          sequence_no: guarantor.sequence_no,
          borrower_id: guarantor.borrower_id,
          gua_fname: guarantor.gua_fname,
          gua_mname: guarantor.gua_mname,
          gua_lname: guarantor.gua_lname,
          gua_dob: guarantor.gua_dob,
          gua_gender: guarantor.gua_gender,
          gua_mobile: guarantor.gua_mobile,
          gua_email: guarantor.gua_email,
          gua_father_name: guarantor.gua_father_name,
          gua_resi_addr_ln1: guarantor.gua_resi_addr_ln1,
          gua_resi_addr_ln2: guarantor.gua_resi_addr_ln2,
          gua_city: guarantor.gua_city,
          gua_state: guarantor.gua_state,
          gua_pincode: guarantor.gua_pincode,
          gua_per_addr_ln1: guarantor.gua_per_addr_ln1,
          gua_per_addr_ln2: guarantor.gua_per_addr_ln2,
          gua_per_city: guarantor.gua_per_city,
          gua_per_state: guarantor.gua_per_state,
          gua_per_pincode: guarantor.gua_per_pincode,
          gua_pan: guarantor.gua_pan,
          gua_aadhaar: guarantor.gua_aadhaar,
        };
        if (ext === Export_Type.XLSX) {
          guarantorDetails = {
            ...loan_app_id,
            ...guarantorDetails,
          };
        }
        data.guarantorDetails.push(guarantorDetails);
      });
    }
    if (item.share_holders.length > 0) {
      item.share_holders.forEach((holder) => {
        let shareHolderDetails = {
          share_holder_name: holder.share_holder_name,
          share_holder_perc: holder.share_holder_perc,
        };
        if (ext === Export_Type.XLSX) {
          shareHolderDetails = {
            ...loan_app_id,
            ...shareHolderDetails,
          };
        }
        data.shareHolderDetails.push(shareHolderDetails);
      });
    }
    if (ext === Export_Type.CSV) {
      loanDetails = {
        ...loanDetails,
        ...entityDetails,
        ...propertyDetails,
      };
    }
    data.loanDetails.push(loanDetails);
    data.entityDetails.push(entityDetails);
    data.propertyDetails.push(propertyDetails);
  });
  return data;
};
