import * as React from 'react';
import { useState, useEffect } from 'react';
import 'react-sdk/dist/styles/_fonts.scss';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import { validateData } from '../../../../../util/validation';
import { useDispatch } from 'react-redux';
import Button from 'react-sdk/dist/components/Button';
import { storedList } from '../../../../../util/localstorage';
import { ONLY_COMMENT_FIELD } from '../fields';
import { setObjectKeysToDefault } from '../../../../../util/helper';
import { ActionPopup } from '../../../../components/ActionPopup/ActionPopup.component';
import { patchMsmeDetailsWatcher } from '../../../../actions/msme.action';

const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
const commentDataDefault = setObjectKeysToDefault(ONLY_COMMENT_FIELD);

export default function LeadReviewShareHoldingPatternForm(props) {
  const { loanDetailsData, setShouldFetch, createMsmeActivityLog } = props;
  const [totalPercent, setTotalPercent] = useState(0);
  const [validationData, setValidationData] = useState({});
  const [stateValidationData, setStateValidationData] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [rowData, setRowData] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dataErrors, setDataErrors] = useState(commentDataDefault);
  const [data, setData] = useState({ ...commentDataDefault });
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const dispatch = useDispatch();
  const styles = useStyles();

  useEffect(() => {
    setInitialData();
  }, [loanDetailsData]);

  useEffect(() => {
    if (totalPercent > 100 || totalPercent < 50) {
      setIsFormDisabled(true);
    } else {
      setIsFormDisabled(false);
    }
  }, [totalPercent]);

  const setInitialData = () => {
    let shareHolderData = loanDetailsData.share_holding_details;
    let rowData = [];
    shareHolderData.forEach((item) => {
      rowData.push({
        borrower_id: item.borrower_id,
        share_holder_name: item.share_holder_name,
        share_holder_perc: item.share_holder_perc,
      });
    });
    const totalPercentage = rowData.reduce((accumulator, item) => {
      return accumulator + Number(item.share_holder_perc);
    }, 0);
    rowData.push({
      borrower_id: rowData.length + 1,
      share_holder_name: 'Total Percentage',
      share_holder_perc: Number(totalPercentage),
    });
    setTotalPercent(Number(totalPercentage));
    setRowData(rowData);
    setIsChanged(false);
    setIsFormDisabled(true);
  };

  const change = (e, type, data) => {
    const value = Number(e.value);
    let field = `${type}_vl_${data.borrower_id}`;
    let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), value);
    setValidationData((prevState) => ({
      ...prevState,
      [`${field}State`]: !isValid ? 'has-danger' : '',
    }));
    let newSum = 0;
    if (value >= 0) {
      let tempSum = 0;
      let itemSum = 0;
      rowData.forEach((item) => {
        if (item.share_holder_name !== 'Total Percentage') {
          tempSum += Number(item.share_holder_perc);
          if (item.borrower_id === data.borrower_id) {
            itemSum = item.share_holder_perc;
          }
        }
      });
      newSum = tempSum - itemSum + value;
      let tempRowData = JSON.parse(JSON.stringify(rowData));
      tempRowData.forEach((item) => {
        if (item.borrower_id === data.borrower_id) {
          item.share_holder_perc = value;
        }
      });
      setRowData(tempRowData);
      setTotalPercent(newSum);
      setIsChanged(true);
    }
  };

  const postShareHoldingDetails = () => {
    let payload = {};
    payload.data = rowData;
    payload.user_id = user._id;
    payload.msme_company_id = loanDetailsData.company_id;
    payload.msme_product_id = loanDetailsData.product_id;
    payload.share_holders = payload.data.map((item) => {
      if (item.share_holder_name !== 'Total Percentage') {
        return {
          borrower_id: item.borrower_id,
          share_holder_name: item.share_holder_name,
          share_holder_perc: item.share_holder_perc,
        };
      }
    });
    payload.section = 'share-holding-details';
    delete payload.data;
    payload.loan_app_id = loanDetailsData.loan_app_id;
    payload.remarks = data['comment'] ?? '';

    new Promise((resolve, reject) => {
      dispatch(patchMsmeDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        showAlert('Lead has been successfully updated', 'success');
        setOpenDialog(false);
        setShouldFetch((prev) => prev + 1);
        createMsmeActivityLog('share_holding');
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
        setOpenDialog(false);
      });
  };

  const handleSubmit = () => {
    const postData = {};
    let sectionDataArr = [];

    let formValidated = true;

    if (totalPercent > 100 || totalPercent < 50) {
      showAlert('Invalid Total Percentage', 'error');
      return;
    }
    rowData.forEach((item) => {
      if (item.share_holder_name !== 'Total Percentage') {
        if (Number(item.share_holder_perc) < 100 && Number(item.share_holder_perc) >= 0) {
          sectionDataArr.push({
            id: item.borrower_id,
            name: item.share_holder_name,
            value: item.share_holder_perc,
          });
        } else {
          formValidated = false;
        }
      }
    });
    if (Object.values(validationData).includes('has-danger')) {
      formValidated = false;
    }
    postData['section'] = 'share-holding-details';
    postData.data = sectionDataArr;

    if (formValidated) {
      setOpenDialog(true);
    } else {
      showAlert('Invalid Data', 'error');
      setTimeout(() => {
        handleAlertClose();
      }, 4000);
    }
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const showAlert = (msg, type) => {
    const element = document.getElementById('TopNavBar');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const renderShareHolderPercentageUi = (shareHolderDetails) => {
    return (
      <div
        key={shareHolderDetails.borrower_id}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '75px',
          backgroundColor: 'white',
          color: 'black',
          fontSize: '16px',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid var(--neutrals-neutral-10, #E5E5E8)',
          borderLeft: '1px solid var(--neutrals-neutral-10, #E5E5E8)',
          borderRight: '1px solid var(--neutrals-neutral-10, #E5E5E8)',
          borderBottomLeftRadius: shareHolderDetails.share_holder_name === 'Total Percentage' ? '15px' : '0px',
          borderBottomRightRadius: shareHolderDetails.share_holder_name === 'Total Percentage' ? '15px' : '0px',
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
          }}
        >
          <span style={{ marginLeft: '24px' }}>{shareHolderDetails.share_holder_name}</span>
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            height: '25px',
            alignItems: 'center',
            marginRight: '24px',
            marginLeft: '20px',
          }}
        >
          {shareHolderDetails.share_holder_name === 'Total Percentage' ? (
            <>
              <span
                style={{
                  color: totalPercent > 100 ? 'red' : 'var(--neutrals-neutral-100, #161719)',
                  fontFamily: 'Montserrat-Regular',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '150%',
                }}
              >
                {totalPercent}%
              </span>
            </>
          ) : (
            <>
              <InputBox
                id={`${shareHolderDetails.borrower_id}_box`}
                label={'Enter Value'}
                type={'number'}
                isDrawdown={false}
                initialValue={shareHolderDetails.share_holder_perc ? shareHolderDetails.share_holder_perc : null}
                isDisabled={props.type == 'view' ? true : false}
                onClick={(event) => change(event, 'number', shareHolderDetails)}
                customClass={{
                  height: '56px',
                  width: '100%',
                  maxWidth: '100%',
                }}
                customInputClass={{
                  minWidth: '100%',
                  backgroundColor: '#fff',
                }}
                error={(() => {
                  return validationData[`number_vl_${shareHolderDetails.borrower_id}State`] === 'has-danger' ? true : false;
                })()}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '98%',
        marginLeft: '1.7%',
        justifyContent: 'space-around',
      }}
    >
      {' '}
      <h4
        style={{
          color: 'var(--neutrals-neutral-100, #161719)',
          fontFamily: 'Montserrat-semibold',
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '150%',
        }}
      >
        Shareholding Pattern
      </h4>
      <div>
        <div style={{ maxWidth: '100%', marginTop: '20px' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#E5E5E8',
              color: 'black',
              fontSize: '16px',
              borderRadius: '15px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '64px',
                alignItems: 'center',
              }}
            >
              <div style={{ width: '50%' }}>
                <span
                  style={{
                    color: 'var(--neutrals-neutral-100, #161719)',
                    fontFamily: 'Montserrat-SemiBold',
                    marginLeft: '24px',
                  }}
                >
                  NAME
                </span>
              </div>
              <div style={{ width: '50%' }}>
                <span
                  style={{
                    color: 'var(--neutrals-neutral-100, #161719)',
                    fontFamily: 'Montserrat-SemiBold',
                  }}
                >
                  SHAREHOLDING PERCENTAGE
                </span>
              </div>
            </div>
            {rowData.map(renderShareHolderPercentageUi)}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '20px' }}>
        <div style={styles.leadReviewButtonContainer}>
          <Button
            buttonType="custom"
            label={'Discard'}
            onClick={() => setInitialData()}
            isDisabled={!isChanged}
            customStyle={{
              ...styles['customStyleButtonComment'],
              ...(!isChanged ? { backgroundColor: 'var(--neutrals-neutral-30, #CCCDD3)', color: 'var(--base-white, #FFF)', borderColor: 'var(--neutrals-neutral-30, #CCCDD3)' } : { backgroundColor: '#FFF', color: '#134CDE', borderColor: '#134CDE' }),
            }}
            validationData={stateValidationData}
          />
          <Button buttonType="custom" label="Save" onClick={handleSubmit} isDisabled={!isChanged || isFormDisabled} customStyle={{ ...styles['customStyleButton'], ...(!isChanged || isFormDisabled ? { backgroundColor: 'var(--neutrals-neutral-30, #CCCDD3)', color: 'var(--base-white, #FFF)' } : { backgroundColor: '#134CDE', color: 'white' }) }} validationData={stateValidationData} />
        </div>
      </div>
      <ActionPopup
        heading="Add a Comment"
        fields={ONLY_COMMENT_FIELD}
        type="addComment"
        data={data}
        setData={setData}
        dataErrors={dataErrors}
        setDataErrors={setDataErrors}
        isModalOpen={openDialog}
        button={{
          primary: {
            label: 'Submit',
            style: {
              background: 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%)',
            },
            action: (props) => {
              postShareHoldingDetails();
            },
          },
          secondary: {
            label: 'Cancel',
            style: styles['negativeButton'],
            action: (props) => {
              setOpenDialog(false);
              setData('');
            },
          },
        }}
        buttonText={'Comment'}
        onClickOutsideModal={() => {
          setOpenDialog(false);
        }}
        callback={(isModalOpen) => {
          if (!isModalOpen) {
            setData('');
            setOpenDialog(false);
          }
        }}
      />
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}

const useStyles = () => {
  return {
    leadReviewButtonContainer: {
      paddingBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px',
    },
    customStyleButton: {
      backgroundColor: '#134CDE',
      color: 'white',
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    customStyleButtonComment: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      backgroundColor: '#FFF',
      borderColor: '#134CDE',
      color: '#134CDE',
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    negativeButton: {
      background: '#FFFFFF',
      color: '#475BD8',
      border: '1px solid #475BD8',
    },
  };
};
