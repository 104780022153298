import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import RolesDropDown from 'components/Dropdowns/RolesDropDown';
import { validateData } from '../../util/validation';
import { createUserWatcher, updateUserWatcher } from '../../actions/user';
import { checkAccessTags } from '../../util/uam';
import { storedList } from '../../util/localstorage';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import './User.style.css';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import { getAllCompaniesWatcher } from 'actions/company';
import Button from 'react-sdk/dist/components/Button';
import { colendersListWatcher } from 'actions/colenders';
const loggedUser = storedList('user');

const defaultErrors = {
  nameError: false,
  emailError: false,
  typeError: false,
  companyError: false,
  userRolesError: false,
};

const AddUser = (props) => {
  const { action, user, cancelProcess, refreshData, showAlert } = props;
  const dispatch = useDispatch();
  const [company, setCompany] = useState();
  const [coLender, setCoLender] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [rolesList, setRolesList] = useState([]);
  const rolesOptions = [
    { value: 'admin', label: 'admin' },
    { value: 'company', label: 'company' },
    { value: 'co-lender', label: 'co-lender' },
  ];

  const [companyList, setCompanyList] = useState([]);
  const [coLenderList, setCoLenderList] = useState([]);
  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? loggedUser?.access_metrix_tags?.length : false;
  const handleSetDataForCompanyUser = (result) => {
    const userCompany = result?.find((company) => company?._id === loggedUser?.company_id);

    setCompanyList([
      {
        value: userCompany?._id,
        label: userCompany?.short_code ? `${userCompany?.short_code} - ${userCompany.name}` : userCompany.name,
        code: `${userCompany?.code}`,
      },
    ]);
  };

  const handleSetCompanyData = (result) => {
    const companiesData = result?.map((item) => {
      return {
        value: item._id,
        label: item?.short_code ? `${item?.short_code} - ${item.name}` : item.name,
        code: `${item.code}`,
        lms_version: `${item.lms_version}`,
      };
    });

    return setCompanyList(companiesData);
  };

  const fetchCompanyList = () => {
    new Promise((resolve, reject) => {
      dispatch(getAllCompaniesWatcher(resolve, reject, {}));
    })
      .then((result) => {
        if (loggedUser.type === 'company') {
          handleSetDataForCompanyUser(result);
        } else {
          handleSetCompanyData(result);
        }
      })
      .catch((error) => {
        console;
        showAlert(error.message, error);
      });
  };

  const fetchCoLenderList = () => {
    new Promise((resolve, reject) => {
      dispatch(colendersListWatcher({}, resolve, reject));
    })
      .then((res) => {
        const coLenders = res.map((item) => {
          return {
            label: item.co_lender_name,
            _id: item._id,
            co_lender_id: item.co_lender_id,
          };
        });
        setCoLenderList(coLenders);
      })
      .catch((error) => {
        showAlert(error.message, 'error');
      });
  };

  useEffect(() => {
    if ((type?.value === 'company' || type?.value === '') && companyList.length === 0) {
      fetchCompanyList();
    }
    if (type?.value === 'co-lender' && coLenderList.length === 0) {
      fetchCoLenderList();
    }
  }, [type]);

  useEffect(() => {
    if (user && action === 'edit') {
      setName(user.username);
      setEmail(user.email);
      setType({ value: user.type, label: user.type });
      setUserRoles(user.userroles);
      setCoLender({
        co_lender_id: user.co_lender_id,
        label: user.co_lender_name,
      });
      setCompany({
        value: user.company_id,
        label: user.company_name,
      });
      setErrors({
        ...errors,
        ['name' + 'Error']: !validateData('fullname', user.username),
      });
    }
  }, [user]);

  const handleInputChange = (field, validationType, setValue) => (event) => {
    const value = event.value;
    setValue(value);
    setErrors({
      ...errors,
      [field + 'Error']: !validateData(validationType, value),
    });
  };

  const handleSelectChange = (field, value, setValue, isMulti) => {
    if (field === 'userRoles') {
      let flag = false;
      userRoles.forEach((role) => {
        value.forEach((val) => {
          if (role === val.value) flag = true;
        });
      });
      if (flag) return;
    }
    if (value === null) {
      setValue('');
    } else {
      setValue(value);
    }
    setErrors({
      ...errors,
      [field + 'Error']: isMulti ? !value?.length : !value,
    });
  };

  const validate = () => {
    if (!validateData('string', name)) {
      setErrors({
        ...defaultErrors,
        nameError: true,
      });
      return false;
    }
    if (!validateData('email', email)) {
      setErrors({
        ...defaultErrors,
        emailError: true,
      });
      return false;
    }
    if (!type && !type?.value) {
      setErrors({
        ...defaultErrors,
        typeError: true,
      });
      return false;
    }
    if (type?.value === 'company' && !company?.value) {
      setErrors({
        ...defaultErrors,
        companyError: true,
      });
      return false;
    }
    return true;
  };

  const handleClear = () => {
    cancelProcess();
  };

  const handleSaveUpdate = () => {
    let userRoleArray = [];
    userRoles.forEach((role) => {
      userRoleArray.push({ title: role.value, id: role.id });
    });
    if (!validateData('fullname', name)) return showAlert('Please enter valid full name', 'error');
    if (name && email && !type) {
      return showAlert('Please select user type.', 'error');
    }
    if ((type?.value === 'co-lender' || type === 'co-lender') && !coLender) {
      return showAlert('Please select co-lender.', 'error');
    }
    if ((type?.value === 'company' || type === 'company') && !company) {
      return showAlert('Please select company.', 'error');
    }
    if (!validate()) return;

    setSaving(true);
    const payload = {
      username: name,
      email,
      type: type?.value,
      company_id: company ? company.value : '',
      co_lender_id: coLender?.co_lender_id || '',
      co_lender_name: coLender?.label || '',
      userroles: userRoles.map((role) => role.value),
      role_metrix: userRoleArray,
    };

    new Promise((resolve, reject) => {
      if (action === 'new')
        dispatch(
          createUserWatcher(
            payload,
            (response) => {
              setSaving(false);
              showAlert(response.message, 'success');
              setTimeout(() => {
                refreshData();
                cancelProcess();
              }, 1000);
            },
            (error) => {
              setSaving(false);
              return showAlert(error.response?.data?.message, 'error');
            },
          ),
        );
      if (action === 'edit') {
        const roles = [];
        const roleMetrix = [];
        userRoles.map((item) => {
          roles.push(item.value || item);
          rolesList.map((roleItem) => {
            if ((item.value || item) === roleItem.title)
              roleMetrix.push({
                title: roleItem.title,
                id: roleItem._id,
              });
          });
        });

        const updatePayload = {
          id: user._id,
          username: name,
          email: email,
          type: type?.value || type,
          userroles: roles,
          role_metrix: roleMetrix,
          co_lender_name: coLender?.label || '',
          co_lender_id: coLender?.co_lender_id || '',
          company_name: company?.label || '',
          company_id: company?.value || '',
        };
        dispatch(
          updateUserWatcher(
            updatePayload,
            (response) => {
              setSaving(false);
              showAlert(response.message, 'success');
              setTimeout(() => {
                refreshData();
                cancelProcess();
              }, 1000);
            },
            (error) => {
              setSaving(false);
              return showAlert(error.response?.data?.message, 'error');
            },
          ),
        );
      }
    });
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const customDropdownClass = {
    zIndex: '1000',
    marginTop: '2%',
    color: '#141519',
  };

  return (
    <>
      <div className="add-user-container-style">
        <div className="add-user-header-style">{action === 'new' ? 'Add User' : 'Edit User'}</div>
        <InputBox
          id={'fullName'}
          label="Full Name"
          onClick={handleInputChange('name', 'fullname', setName)}
          customClass={{
            minWidth: '100%',
            marginBottom: '2rem',
            height: '56px',
            fontFamily: 'Montserrat-Regular',
          }}
          customInputClass={{
            minWidth: 'none',
            maxWidth: 'none',
            width: '100%',
          }}
          type="text"
          initialValue={name ? name : ''}
          error={errors.nameError}
          helperText={errors.nameError ? 'Enter valid name' : ''}
        />
        <InputBox
          id={'emailId'}
          label="Email ID"
          onClick={handleInputChange('email', 'email', setEmail)}
          customClass={{
            minWidth: '100%',
            marginBottom: '2rem',
            height: '56px',
            fontFamily: 'Montserrat-Regular',
          }}
          customInputClass={{
            minWidth: 'none',
            maxWidth: 'none',
            width: '100%',
            marginTop: `${action === 'edit' ? '-0.2rem' : 'auto'}`,
          }}
          type="text"
          initialValue={email ?? ''}
          isDisabled={action === 'edit' ? true : false}
          error={errors.emailError}
          helperText={errors.emailError ? 'Enter valid email address' : ''}
        />

        <InputBox
          id={'select-type'}
          label="Select type of user"
          isDrawdown={action === 'edit' ? false : true}
          initialValue={type?.value ? type?.value : ''}
          onClick={(value) => {
            if (value?.value === 'admin') setCompany(null);
            handleSelectChange('type', value, setType, false);
          }}
          customClass={{
            minWidth: '100%',
            marginBottom: '2rem',
            height: '56px',
            fontFamily: 'Montserrat-Regular',
          }}
          customInputClass={{
            minWidth: 'none',
            maxWidth: 'none',
            width: '100%',
            marginTop: `${action === 'edit' ? '-0.2rem' : 'auto'}`,
          }}
          customDropdownClass={customDropdownClass}
          isDisabled={action === 'edit' ? true : false}
          options={rolesOptions}
        />
        <div style={{ marginBottom: '2rem' }}>
          <RolesDropDown
            id="select-role"
            placeholder="Select role"
            valueData={userRoles}
            error={errors.userRolesError}
            helperText={errors.userRolesError ? 'At least 1 role is required' : ''}
            setRolesList={setRolesList}
            onValueChange={(value) => {
              handleSelectChange('userRoles', value.value || value, setUserRoles, true);
            }}
          />
        </div>

        {type?.value === '' || type?.value === 'company' ? (
          <InputBox
            id={'select-company'}
            label="Select company"
            isDrawdown={action === 'edit' ? false : true}
            initialValue={company?.label ? company?.label : ''}
            onClick={(value) => {
              if (value?.value === 'admin') setCoLender('');
              if (value?.value === 'admin') setCompany(null);
              handleSelectChange('company', value, setCompany, false);
            }}
            customClass={{
              minWidth: '100%',
              marginBottom: '2rem',
              height: '56px',
              fontFamily: 'Montserrat-Regular',
            }}
            customInputClass={{
              minWidth: 'none',
              maxWidth: 'none',
              width: '100%',
              marginTop: `${action === 'edit' ? '-0.2rem' : 'auto'}`,
            }}
            customDropdownClass={customDropdownClass}
            isDisabled={action === 'edit' ? true : false}
            options={companyList}
            error={errors.typeError}
            helperText={errors.typeError ? 'company is required' : ''}
          />
        ) : null}
        {type?.value === 'co-lender' ? (
          <>
            <InputBox
              id={'co-lender'}
              label="Select co-lender"
              isDrawdown={action === 'edit' ? false : true}
              initialValue={coLender?.label ? coLender?.label : ''}
              onClick={(value) => {
                setCompany('');
                handleSelectChange('coLender', value, setCoLender, false);
              }}
              customClass={{
                minWidth: '100%',
                marginBottom: '2rem',
                height: '56px',
                fontFamily: 'Montserrat-Regular',
              }}
              customInputClass={{
                minWidth: 'none',
                maxWidth: 'none',
                width: '100%',
                marginTop: `${action === 'edit' ? '-0.2rem' : 'auto'}`,
              }}
              customDropdownClass={customDropdownClass}
              isDisabled={action === 'edit' ? true : false}
              options={coLenderList}
            />
          </>
        ) : null}
        <div className="submit-button-container-style">
          <Button
            label={action === 'new' ? 'SAVE' : 'UPDATE'}
            buttonType="primary"
            isDisabled={saving || (isTagged ? !checkAccessTags(['tag_user_read_write']) : false)}
            onClick={handleSaveUpdate}
            customStyle={{
              height: '1rem',
              width: '5.5rem',
              fontSize: '16px',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: 'none',
              backgroundColor: 'white',
              fontFamily: 'Montserrat-Regular',
              padding: '1rem',
              border: '1px solid #134CDE',
              borderRadius: '5px',
            }}
          />
          <Button
            label="CANCEL"
            buttonType="primaryBTN"
            isDisabled={saving || (isTagged ? !checkAccessTags(['tag_user_read_write']) : false)}
            onClick={handleClear}
            customStyle={{
              color: 'white',
              backgroundColor: '#D32F2F',
              height: '1rem',
              width: '5.5rem',
              fontSize: '16px',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: 'none',
              fontFamily: 'Montserrat-Regular',
              padding: '1rem',
              borderRadius: '5px',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AddUser;
