import React from 'react';
import LeadDetailsTab from './LeadDetailsTab/LeadDetailsTab';
import DocumentDetailsTab from './DocumentDetailsTab/DocumentDetailsTab';
import CamsDetailsTab from './CamsDetailsTab/CamsDetailsTab';

const LEAD_REVIEW_TABS = ['lead details', 'documents', 'cams'];
const ERR_MSG = 'Error! Wrong configuration! Please check the code!';
function TabSection(props) {
  const { selectedTab, loanAppId, fetchActivityLogs, handlePopupVisibility, handlePopupSubmit, handleApproveButton, isEdit, setIsEdit, setOffer, setLeadStatus, setOfferResponse, getLeadOffer, updateOffer, offerLoading, offerDetails } = props;
  // Define sections and their corresponding messages
  const sections = {
    'lead details': <LeadDetailsTab fetchActivityLogs={fetchActivityLogs} handlePopupVisibility={handlePopupVisibility} handlePopupSubmit={handlePopupSubmit} handleApproveButton={handleApproveButton} loanAppId={loanAppId} isEdit={isEdit} setOffer={setOffer} setLeadStatus={setLeadStatus} setOfferResponse={setOfferResponse} getLeadOffer={getLeadOffer} updateOffer={updateOffer} offerLoading={offerLoading} offerDetails={offerDetails} />,
    documents: <DocumentDetailsTab loanAppId={loanAppId} />,
    cams: <CamsDetailsTab loanAppId={loanAppId} isEdit={isEdit} setIsEdit={setIsEdit} />,
  };

  // Check if selectedTab is valid, else display an error message
  const errorMessage = !LEAD_REVIEW_TABS.includes(selectedTab) ? <p>{ERR_MSG}</p> : '';

  // Determine the message to display based on selectedTab
  const tabContentSection = selectedTab in sections ? <>{sections[selectedTab]}</> : '';

  return (
    <div>
      {errorMessage}
      {tabContentSection}
    </div>
  );
}

export default TabSection;
