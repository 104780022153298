import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function getReportRequests(payload) {
  return axios.post(BASE_URL + `report-request/${payload.report_name}/${payload.page}/${payload.limit}`, payload);
}

export function generateReportRequest(payload) {
  return axios.post(BASE_URL + 'report-request', payload);
}

export function downloadReportRequestFile(payload) {
  return axios.post(BASE_URL + `report-request/download/${payload.request_id}`, payload);
}

export function getCoLendingCamReports(payload) {
  return axios.post(`${BASE_URL}co-lending-cam-reports/${payload.page}/${payload.limit}`, payload);
}

export function generateCoLendingCamReports(payload) {
  return axios.post(`${BASE_URL}generate-co-lending-cam-report`, payload);
}

export function downloadCoLenderReportAPI(payload) {
  return axios.post(BASE_URL + `colending-cams-report/download/${payload.request_id}`, payload);
}

export function getPartnerOperationsReports(payload) {
  return axios.post(`${BASE_URL}partner-operations-reports/${payload.page}/${payload.limit}`, payload);
}

export function generatePartnerOperationsReports(payload) {
  return axios.post(`${BASE_URL}generate-partner-operations-reports`, payload);
}

export function downloadPartnerOperationsReportAPI(payload) {
  return axios.post(`${BASE_URL}download-partner-operations-reports/download/${payload.request_id}`, payload);
}
