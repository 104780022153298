import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import TabButton from 'react-sdk/dist/components/TabButton';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import 'react-sdk/dist/styles/_fonts.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AlertBox } from '../../../../components/CustomAlertbox';
import useDimensions from 'hooks/useDimensions';
import { storedList } from '../../../../util/localstorage';
import Button from 'react-sdk/dist/components/Button/Button';
import { convertDataIntoAccordionData, deepCopy, toOrdinalCounting, toSnakeCase } from '../../../../util/msme/helper';
import { CustomAccordion, DocumentsList, OfferGenerate, Tooltip } from '../../../components/msme.component';
import StatusIcon from '../../../components/StatusIcon/StatusIcon';
import { AuditLog } from '../../../components/AuditLog/AuditLog';
import SanctionLenderLead from '../sanctionAndLender/sanctionLenderLead.view';
import { CreateLoan } from 'msme/views/msme.view';
import { Natch } from 'msme/views/msme.view';
import './leadReview.style.css';
import CrossCircle from '../../../../assets/img/close-circle.svg';
import InfoCircle from '../../../../assets/img/info-circle.svg';
import { getMsmeActivityLogsWatcher, getMsmeLeadReviewDetailsWatcher, updateLeadDetailsWatcher, commentDetailsWatcher, getLeadOfferWcher, markAsDeviationWatcher, markAsPreliminaryCheckStatus, changeToLeadDeviationWatcher, leadResetWcher } from '../../../actions/lead.action';
import { LeadStatus, EditLeadStatus } from '../../../config/LeadStatus';
import { COMMENT_FIELDS, REJECT_FIELDS } from './fields';
import { setObjectKeysToDefault, toCamel } from '../../../../util/helper';
import { ActionPopup } from '../../../components/ActionPopup/ActionPopup.component';
import { checkAccessTags } from '../../../../util/uam';
import Preloader from '../../../../components/custom/preLoader';
import Animation from './Animation.gif';
import { getMsmeLoanDocumentsWatcher } from 'msme/actions/bookLoan.action';
import { postComprehensiveLogsWatcher } from '../../../../actions/comprehensiveLogs.action';
import { documentCode as DocumentCodeList } from 'msme/config/docCode';
import { docSectionCode } from 'msme/config/docSectionCode';
import CamsSection from 'msme/views/loans/loanCreation/camsSection';
import LeadReviewForm from './leadReviewForm/leadReviewForm';
import Checkbox from '@mui/material/Checkbox';
const user = { _id: storedList('user')?._id, id: storedList('user')?.id };

const commentDataDefault = setObjectKeysToDefault(COMMENT_FIELDS);
const showSelectedRemarksList = ['addComment'];
const typeFields = {
  approve: 'Approve',
  sectionApprove: 'Approve',
  reject: 'Reject',
  addComment: 'Add Comment',
  requestUpdate: 'Request an Update',
  sectionMarkAsDeviation: 'Mark As Deviation',
  backToLeadDeviation: 'Back To Lead Deviation',
};
const mapSubSection = {
  primary_applicant: 'primary',
  entity_details: 'entity',
  'co-applicant_details': 'co_borrower',
  guarantor_details: 'guarantor',
  financial_documents: 'financial_doc',
  additional_documents: 'additional_doc',
};

export default function LeadReview(props) {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const isLoading = useSelector((state) => state.profile.loading);
  const [offer, setOffer] = useState();
  const [accordionData, setAccordionData] = useState([]);
  const [leadData, setLeadData] = useState({});
  const [actionId, setActionId] = useState({});
  const [actiontype, setActionType] = useState({});
  const [data, setData] = useState({ ...commentDataDefault, remarkReasons: [] });
  const [type, setType] = useState('rejectReason');
  const [openPopup, setOpenPopup] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [productId, setProductId] = useState('');
  const [dataErrors, setDataErrors] = useState(commentDataDefault);
  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState('');
  const paramsData = useLocation().search.split('=').slice(-1);
  const selectedTab = decodeURIComponent(paramsData);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [auditLogs, setAuditLogs] = useState([]);
  const [disableApproveBtn, setDisableApproveBtn] = useState('false');
  const [leadStatus, setLeadStatus] = useState('');
  const [loanDocuments, setLoanDocuments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [flag, setFlag] = useState(false);
  const [sectionRemarks, setSectionRemarks] = useState({});
  const [validationRemarks, setValidationRemarks] = useState({});
  const [commentFields, setCommentFields] = useState(COMMENT_FIELDS);
  const [rejectionReason, setRejectionReason] = useState();
  const [preliminaryCheck, setPreliminaryCheck] = useState(false);
  const [showLeadResetPopup, setShowLeadResetPopup] = React.useState(false);
  const [isLeadResetLoading, setIsLeadResetLoading] = React.useState(false);
  let tabsAvailable = ['Lead Details', 'Documents', 'Cams'];
  if (checkAccessTags(['tag_msme_lead_view_int_read', 'tag_msme_lead_view_int_read_write'])) {
    tabsAvailable = ['Lead Details', 'Documents', 'Cams'];
  }
  const [leadDetailSection, setLeadDetailSection] = useState(tabsAvailable.includes(selectedTab) ? selectedTab.toLowerCase() : tabsAvailable[0].toLowerCase());
  const history = useHistory();
  const params = useParams();
  const loanAppId = params.id;
  const { innerWidth, innerHeight } = useDimensions();
  const styles = useStyles({ innerWidth, innerHeight });

  useEffect(() => {
    if (!isEdit) {
      fetchLeadDetails();
    }
  }, [history, isEdit]);

  useEffect(() => {
    if (Object.keys(leadData).length != 0) {
      formattedData(leadData);
    }
  }, [leadData]);

  useEffect(() => {
    fetchActivityLogs();
  }, []);

  useEffect(() => {
    getLoanDocuments();
  }, [isEdit]);

  const initializeLoanDocuments = (documents) => {
    const subsectionsDocList = [];
    for (const key in docSectionCode) {
      if (docSectionCode.hasOwnProperty(key)) {
        if (key === 'Co-Applicant' || key === 'Guarantor') {
          const DocList = documents.filter((item) => docSectionCode[key].includes(item.code));
          if (DocList.length) {
            const groupedCoApplicantDocList = DocList.reduce((acc, obj) => {
              const key = obj.borrower_id;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(obj);
              return acc;
            }, {});
            let count = 1;
            for (const newkey in groupedCoApplicantDocList) {
              if (groupedCoApplicantDocList.hasOwnProperty(newkey)) {
                const subsectionDocList = {
                  title: `${key}-${count}`,
                };
                subsectionDocList['docs'] = groupedCoApplicantDocList[newkey];
                subsectionsDocList.push(subsectionDocList);
                count = count + 1;
              }
            }
          }
        } else if (key === 'Financial Documents') {
          let DocListFinancialSection = documents.filter((item) => docSectionCode[key].includes(item.code));
          const bankStatementDoc = DocListFinancialSection.filter((item) => item.code === DocumentCodeList.msme_bank_statement);
          const bankStatementAnalysis = DocListFinancialSection.filter((item) => item.code === DocumentCodeList.bank_statement_excel);
          if (bankStatementAnalysis.length == 0 && isEdit) {
            const BankStatementAnalysis = {
              file_type: 'bank_statement_analysis',
              code: DocumentCodeList.bank_statement_excel,
              loan_app_id: DocListFinancialSection[0]?.loan_app_id,
              borrower_id: DocListFinancialSection[0]?.borrower_id,
              partner_loan_app_id: DocListFinancialSection[0]?.partner_loan_app_id,
              partner_borrower_id: DocListFinancialSection[0]?.partner_borrower_id,
            };
            DocListFinancialSection.push(BankStatementAnalysis);
          }
          if (bankStatementDoc.length) {
            DocListFinancialSection = DocListFinancialSection.filter((item) => item.code !== DocumentCodeList.msme_bank_statement);
            for (let i = 0; i < bankStatementDoc[0].additional_file_url.length; i++) {
              if (bankStatementDoc[0].additional_file_url[i]) {
                let newBankDoc = { ...bankStatementDoc[0] };
                newBankDoc.file_url = bankStatementDoc[0].additional_file_url[i];
                newBankDoc.doc_index = i;
                newBankDoc.file_type = `${newBankDoc.file_type} ${toOrdinalCounting(i + 1)} year`;
                DocListFinancialSection.push(newBankDoc);
              } else {
                break;
              }
            }
          }
          const subsectionDocList = {
            title: key,
          };
          subsectionDocList['docs'] = DocListFinancialSection;
          if (subsectionDocList['docs'].length) subsectionsDocList.push(subsectionDocList);
        } else {
          const subsectionDocList = {
            title: key,
          };
          subsectionDocList['docs'] = documents.filter((item) => docSectionCode[key].includes(item.code));
          if (subsectionDocList['docs'].length) subsectionsDocList.push(subsectionDocList);
        }
      }
    }
    setLoanDocuments(subsectionsDocList);
  };

  const getLoanDocuments = () => {
    const { companyId, productId } = history.location.state || {};
    const payload = {
      loanAppID: params.id,
      companyId: companyId,
      productId: productId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getMsmeLoanDocumentsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        initializeLoanDocuments(response);
      })
      .catch((error) => {
        showAlert(error.response?.data?.message, 'error');
      });
  };

  const fetchActivityLogs = () => {
    const { companyId, productId } = history.location.state || {};
    const payload = {
      loanAppId: params.id,
      companyId,
      productId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getMsmeActivityLogsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setAuditLogs(response);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const fetchLeadDetails = () => {
    const { companyId, productId } = history.location.state || {};

    const payload = {
      loanAppId: params.id,
      companyId,
      productId,
      user: user,
    };

    new Promise((resolve, reject) => {
      dispatch(getMsmeLeadReviewDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setCompanyId(response.company_id);
        setProductId(response.product_id);
        delete response.company_id;
        delete response.product_id;
        setLeadStatus(response.lead_status);
        delete response.lead_status;
        setLeadData(response);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const handleLeadStatus = (value) => {
    const { companyId, productId } = history.location.state || {};
    const payload = {
      loanAppId: params.id,
      companyId,
      productId,
      user: user._id,
      status: value,
      remarks: data.comment ?? ' ',
    };
    if (value === 'rejected') {
      payload.reason = data.remarkReasons[data.remarkReasons.length - 1];
      payload.remarks = data?.reject_comment && data?.reject_comment.length > 0 ? data.reject_comment : `${data.reject_here} ${data.remarkReasons[data.remarkReasons.length - 1]}`;
    }
    saveLogs(payload.loanAppId, `lead_details_page_${payload.status}_button`);
    new Promise((resolve, reject) => {
      dispatch(updateLeadDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setOpenDialog(false);
        showAlert(response?.message, 'success');
        fetchActivityLogs();
        fetchLeadDetails();
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const handlePopupSubmit = (actionId, title, type) => {
    let sequence = 100;

    if (actionId && actionId.split('_')[0] === 'co-applicant') {
      sequence = +(actionId.split('_')[1] - 1) + 300;
      actionId = 'co-applicant_details';
    } else if (actionId && actionId.split('_')[0] === 'guarantor') {
      sequence = +(actionId.split('_')[1] - 1) + 400;
      actionId = 'guarantor_details';
    } else if (actionId === 'primary_applicant') {
      sequence = 100;
    } else if (actionId === 'entity_details') {
      sequence = 200;
    } else if (actionId === 'shareholding_pattern') {
      sequence = 700;
    } else if (actionId === 'financial_documents') {
      sequence = 500;
    } else if (actionId === 'additional_documents') {
      sequence = 600;
    }
    const { companyId, productId } = history.location.state || {};
    let payload = {
      companyId,
      productId,
      user: user,
      type: 'remarks',
      remarks: type == 'sectionApprove' ? 'Approved' : data.comment,
      loan_app_id: params.id,
      category: mapSubSection[actionId],
      status: type == 'sectionApprove' ? 'approved' : 'deviation',
      sequence: sequence,
    };

    if (type != 'sectionApprove') {
      payload = {
        ...payload,
        remark_reasons: data.remarkReasons ?? [],
      };
    }

    if (!payload.remarks) {
      delete payload['remarks'];
    }

    saveLogs(payload.loan_app_id, `lead_details_page_${payload.status}_button`);

    new Promise((resolve, reject) => {
      dispatch(commentDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        fetchActivityLogs();
        fetchLeadDetails();
        setOpenDialog(false);
        showAlert(response?.message, 'success');
        setData('');
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleAddComment = (title, id, remarks, remarkReasons) => {
    setTitle(title);
    setActionId(id);
    setActionType('remarks');
    setType('addComment');
    setOpenDialog(true);
    setCommentFields(handleCommentField(false));
    setData({
      comment: remarks,
      select_here: null,
      reject_here: null,
      remarkReasons: remarkReasons,
    });
  };
  const resetAll = (title, id) => {
    setTitle(title);
    setActionId(id);
    setActionType('');
    setType('');
    setOpenDialog(false);
  };
  const handleCommentField = (flag, type = 'normalFlow') => {
    let tempField = type === 'normalFlow' ? commentFields : REJECT_FIELDS;
    tempField['comment']['isRequired'] = flag;
    return tempField;
  };

  const handleApprove = (title, id) => {
    setTitle(title);
    setActionId(id);
    setTitle(typeFields['approve']);
    setType('approve');
    setOpenDialog(true);
    setCommentFields(handleCommentField(false));
  };

  const handleSectionApprove = (title, id) => {
    setActionId(id);
    setTitle(typeFields['sectionApprove']);
    setType('sectionApprove');
    handlePopupSubmit(id, typeFields['sectionApprove'], 'sectionApprove');
  };

  const handleBackToLeadDeviationSubmit = () => {
    const payload = {
      loan_app_id: loanAppId,
      user: user._id,
      remarks: data.comment,
      productId: productId,
      companyId: companyId,
    };
    new Promise((resolve, reject) => {
      dispatch(changeToLeadDeviationWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setOpenDialog(false);
        showAlert(response?.message, 'success');
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const handlPopupClose = () => {
    setOpenPopup(false);
  };

  const handleConfirmed = () => {
    const payload = {
      section_sequence_no: 500,
      loan_app_id: loanAppId,
      companyId: companyId,
      productId: productId,
      user: user._id,
    };
    new Promise((resolve, reject) => {
      dispatch(markAsDeviationWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setOpenPopup(false);
        fetchActivityLogs();
        fetchLeadDetails();
        showAlert(response?.message, 'success');
        setTimeout(() => {
          history.push(`/admin/msme/leads/${loanAppId}`);
        }, 3000);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const handleReject = () => {
    setTitle(typeFields['reject']);
    setType('reject');
    setOpenDialog(true);
    setCommentFields(handleCommentField(true, 'reject'));
  };

  const handleRequestUpdate = () => {
    setTitle(typeFields['requestUpdate']);
    setType('requestUpdate');
    setOpenDialog(true);
    setCommentFields(handleCommentField(true));
  };

  const handlePreliminarySave = () => {
    const payload = {
      preliminary_check: preliminaryCheck,
      loan_app_id: loanAppId,
      companyId: companyId,
      productId: productId,
      user: user._id,
    };
    new Promise((resolve, reject) => {
      dispatch(markAsPreliminaryCheckStatus(payload, resolve, reject));
    })
      .then((response) => {
        showAlert(response?.message, 'success');
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const handlePreliminaryCheckBox = (event) => {
    setPreliminaryCheck(event.target.checked);
  };

  const handleBackToLeadDeviation = () => {
    setTitle(typeFields['backToLeadDeviation']);
    setType('backToLeadDeviation');
    setOpenDialog(true);
    setCommentFields(handleCommentField(true));
  };

  const handleLeadReset = () => {
    setShowLeadResetPopup(true);
  };

  const handleLeadResetConfirmed = () => {
    try {
      const payload = {
        loan_app_id: loanAppId,
        user_id: user?._id,
        product_id: productId,
        company_id: companyId,
      };
      setIsLeadResetLoading(true);
      new Promise((resolve, reject) => {
        dispatch(leadResetWcher(payload, resolve, reject));
      })
        .then((response) => {
          setIsLeadResetLoading(false);
          setShowLeadResetPopup(false);
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        })
        .catch((error) => {
          setIsLeadResetLoading(false);
          showAlert(error?.response?.data?.message, 'error');
        });
    } catch (error) {
      return error;
    }
  };

  const formatAccordHeader = (label) => <span style={styles['accordionHeader']}>{label.toUpperCase()}</span>;
  const formatAccordBody = (value) => <span style={styles['accordionBody']}>{typeof value === 'string' && value.trim() ? value : typeof value === 'number' ? value : 'N/A'}</span>;

  const formatStatus = (value) => <StatusIcon status={value.toLowerCase()} />;

  const formatValidationChecklistStatus = (value, remarks) => (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '105px' }}>
        <StatusIcon status={value.toLowerCase()} />
        {value == 'in_review' || value == 'rejected' ? <Tooltip content={remarks ? remarks : ''} /> : <div style={{ width: '21px', height: '18px', backgroundColor: 'transparent' }} />}
      </div>
    </React.Fragment>
  );

  const checkButtonAccess = () => {
    return checkAccessTags(['tag_msme_lead_view_int_lead_details_btn_others', 'tag_msme_lead_view_int_read_write', 'tag_msme_lead_view_int_lead_details_read_write']);
  };
  const bottomComponent = (title, id, remarks, remarkReasons, accordionStatus) => {
    let isRiskTag = false;
    let isRiskResponsible = false;
    if (checkAccessTags(['tag_offer_deviation_risk'])) {
      isRiskTag = true;
    }
    if (offer?.responsibility && offer?.responsibility.toLowerCase() === 'risk') {
      isRiskResponsible = true;
    }
    return (isRiskResponsible && isRiskTag) || (!isRiskResponsible && !isRiskTag) ? (
      <div style={styles['leadReviewBottomComponent']}>
        {(checkAccessTags(['tag_msme_lead_view_int_lead_details_btn_add_comment', 'tag_msme_lead_view_int_read']) || checkButtonAccess()) && accordionStatus === 'in_review' ? <Button buttonType="custom" label={remarks || (remarkReasons && remarkReasons.length > 0) ? 'Update Comment' : 'Add Comment'} onClick={() => handleAddComment(title, id, remarks, remarkReasons)} isDisabled={false} customStyle={styles['customStyleButtonComment']} /> : null}
        {checkAccessTags(['tag_msme_lead_view_int_lead_details_btn_mark_as_deviation']) && id == 'financial_documents' && accordionStatus === 'approved' ? (
          <Button
            buttonType="custom"
            label={`Mark As Deviation`}
            onClick={() => {
              setOpenPopup(true);
            }}
            isDisabled={false}
            customStyle={styles['customStyleButtonComment']}
          />
        ) : null}
        {(checkAccessTags(['tag_msme_lead_view_int_lead_details_btn_approve', 'tag_msme_lead_view_int_read']) || checkButtonAccess()) && accordionStatus === 'in_review' ? (
          <Button
            buttonType="custom"
            label="Approve"
            onClick={() => {
              handleSectionApprove(title, id);
            }}
            isDisabled={false}
            customStyle={styles['customStyleButton']}
          />
        ) : null}
      </div>
    ) : null;
  };

  const getLeadOffer = () => {
    const { companyId, productId } = history.location.state || {};
    const payload = {
      loan_app_id: loanAppId,
      companyId: companyId,
      productId: productId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLeadOfferWcher(payload, resolve, reject));
    })
      .then((response) => {
        setOffer(response.data);
        setRejectionReason(response?.data?.rejection_reasons);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getLeadOffer();
  }, []);

  const formattedData = (leadArray) => {
    let leadReviewData = leadArray;
    let tempReviewData = deepCopy(leadReviewData);
    const statusCheck = {};
    const bottomComponents = {};
    let tempRemarks = {};
    let tempValidationRemarks = {};

    for (let lead in tempReviewData) {
      let accordionStatus = tempReviewData[lead].status;

      if (tempReviewData[lead].status) {
        statusCheck[lead] = formatStatus(tempReviewData[lead].status);
        delete tempReviewData[lead].status;
      }

      if (tempReviewData[lead].validation_checklist_remarks) {
        (tempValidationRemarks[lead] = tempReviewData[lead].validation_checklist_remarks), delete tempReviewData[lead].validation_checklist_remarks;
      }

      if (Object.keys(tempReviewData[lead]).length && lead != 'co-applicant_details' && lead != 'guarantor_details' && leadStatus === LeadStatus.lead_deviation.value && accordionStatus === 'in_review') {
        bottomComponents[lead] = bottomComponent(toCamel(lead, true), lead, tempReviewData[lead].remarks, tempReviewData[lead].section_remark_reasons, accordionStatus);
      }

      if (Object.keys(tempReviewData[lead]).length && lead == 'financial_documents' && leadStatus === LeadStatus.lead_deviation.value) {
        bottomComponents[lead] = bottomComponent(toCamel(lead, true), lead, tempReviewData[lead].remarks, tempReviewData[lead].section_remark_reasons, accordionStatus);
      }

      if (tempReviewData[lead]) {
        tempRemarks[lead] = tempReviewData[lead].remarks;
        delete tempReviewData[lead].remarks;
        delete tempReviewData[lead].section_remark_reasons;
      }
    }

    var coapplicant_details = tempReviewData['co-applicant_details'] ? Object.keys(tempReviewData['co-applicant_details']) : [];
    var guarantor_details = tempReviewData['guarantor_details'] ? Object.keys(tempReviewData['guarantor_details']) : [];

    for (let coapplicant of coapplicant_details) {
      let accordionStatus = tempReviewData['co-applicant_details'][coapplicant].status;
      if (tempReviewData['co-applicant_details'][coapplicant].status) {
        statusCheck[coapplicant] = formatStatus(tempReviewData['co-applicant_details'][coapplicant].status);
        delete tempReviewData['co-applicant_details'][coapplicant].status;
      }

      if (tempReviewData['co-applicant_details'][coapplicant].validation_checklist_remarks) {
        tempValidationRemarks[coapplicant] = tempReviewData['co-applicant_details'][coapplicant].validation_checklist_remarks;
        delete tempReviewData['co-applicant_details'][coapplicant].validation_checklist_remarks;
      }

      if (Object.keys(tempReviewData['co-applicant_details'][coapplicant]).length && leadStatus === LeadStatus.lead_deviation.value && accordionStatus === 'in_review') {
        bottomComponents[coapplicant] = bottomComponent(toCamel(coapplicant, true), coapplicant, tempReviewData['co-applicant_details'][coapplicant].remarks, tempReviewData['co-applicant_details'][coapplicant].section_remark_reasons, accordionStatus);
      }

      if (tempReviewData['co-applicant_details'][coapplicant]) {
        tempRemarks[coapplicant] = tempReviewData['co-applicant_details'][coapplicant].remarks;
        delete tempReviewData['co-applicant_details'][coapplicant].remarks;
        delete tempReviewData['co-applicant_details'][coapplicant].section_remark_reasons;
      }
    }

    for (let guarantor of guarantor_details) {
      let accordionStatus = tempReviewData['guarantor_details'][guarantor].status;
      if (tempReviewData['guarantor_details'][guarantor].status) {
        statusCheck[guarantor] = formatStatus(tempReviewData['guarantor_details'][guarantor].status);
        delete tempReviewData['guarantor_details'][guarantor].status;
      }

      if (tempReviewData['guarantor_details'][guarantor].validation_checklist_remarks) {
        tempValidationRemarks[guarantor] = tempReviewData['guarantor_details'][guarantor].validation_checklist_remarks;
        delete tempReviewData['guarantor_details'][guarantor].validation_checklist_remarks;
      }

      if (Object.keys(tempReviewData['guarantor_details'][guarantor]).length && leadStatus === LeadStatus.lead_deviation.value && accordionStatus === 'in_review') {
        bottomComponents[guarantor] = bottomComponent(toCamel(guarantor, true), guarantor, tempReviewData['guarantor_details'][guarantor].remarks, tempReviewData['guarantor_details'][guarantor].section_remark_reasons, accordionStatus);
      }

      if (tempReviewData['guarantor_details'][guarantor]) {
        tempRemarks[guarantor] = tempReviewData['guarantor_details'][guarantor].remarks;
        delete tempReviewData['guarantor_details'][guarantor].remarks;
        delete tempReviewData['guarantor_details'][guarantor].section_remark_reasons;
      }
    }
    setSectionRemarks(tempRemarks);
    setValidationRemarks(tempValidationRemarks);
    setPreliminaryCheck(tempReviewData.primary_applicant.preliminary_check);

    let data = convertDataIntoAccordionData(
      tempReviewData,
      formatAccordHeader,
      formatAccordBody,
      ['validation_checklist'],
      {
        validation_checklist: (value, key, headTitle) => {
          return formatValidationChecklistStatus(value, tempValidationRemarks?.[headTitle]?.[key] ?? '');
        },
      },
      statusCheck,
      bottomComponents,
      [...coapplicant_details, ...guarantor_details],
    );

    let allApproved = data.every((item) => item.rightComponent.props.status.toUpperCase() === 'APPROVED');
    setDisableApproveBtn(!allApproved);

    setAccordionData(data);
  };

  const handleNavigate = (navState) => {
    history.replace(`/admin/msme/leads/${params.id}?tab=${navState}`);
  };

  const handleLeadResetPopupClose = () => {
    setShowLeadResetPopup(false);
  };

  const leadStatusMappings = {
    approve: 'offer_in_progress',
    reject: 'rejected',
    requestUpdate: 'pending',
  };

  const saveLogs = (identifier, event_name) => {
    new Promise((resolve, reject) => {
      const payload = {
        user_id: user?._id,
        identifier: identifier,
        log_details: [
          {
            user_id: user?._id,
            event_name: event_name,
            timestamp: new Date(),
          },
        ],
      };
      if (payload?.user_id && identifier && event_name) {
        dispatch(postComprehensiveLogsWatcher(payload, resolve, reject));
      }
    });
  };

  return (
    <div style={{ padding: '0px 24px 24px 24px', backgroundColor: '#fafafa' }}>
      <div
        style={{
          display: leadDetailSection === 'lead details' ? 'flex' : null,
          flexDirection: leadDetailSection === 'lead details' ? 'row' : null,
        }}
      >
        {showLeadResetPopup ? (
          <FormPopup customStyles={{ width: '410px', top: '55%' }} heading="Lead Reset" customHeaderStyle={{ fontWeight: '600px', fontSize: '20px', lineHeight: '30px' }} onClose={() => {}} isCancel={true} isOpen={showLeadResetPopup}>
            <div className="lead-reset-popup-text-style">All data and documents pertaining to lead will be wiped from the system and lead will move to the draft stage. Please confirm if you want to take this action?</div>
            <div style={{ display: 'flex', marginTop: '8%' }}>
              <Button label="Cancel" buttonType="secondary" onClick={handleLeadResetPopupClose} customStyle={{ ...styles.customButton1, borderRadius: '7px', flex: 1, color: '#134CDE', border: '1px solid #134CDE', fontWeight: '600px', fontSize: '16px', lineHeight: '24px' }} />
              <Button label="Confirm" isLoading={isLeadResetLoading} buttonType="primary" onClick={handleLeadResetConfirmed} customStyle={{ ...styles.customButton2, borderRadius: '7px', flex: 1, fontWeight: '600px', fontSize: '16px', lineHeight: '24px' }} />
            </div>
          </FormPopup>
        ) : null}

        {openPopup ? (
          <FormPopup customStyles={{ width: '380px', top: '55%' }} heading="Mark as deviation" onClose={handlPopupClose} customHeaderStyle={{ paddingLeft: '5%' }} isCancel={true} isOpen={openPopup}>
            <div className="confirmation-popup-text-style">Are you sure, you want to mark this section as a deviation?</div>
            <div style={{ display: 'flex', marginTop: '8%', justifyContent: 'center' }}>
              <Button label="Cancel" buttonType="secondary" onClick={handlPopupClose} customStyle={{ ...styles.customButton1, borderRadius: '7px', color: '#134CDE', border: '1px solid #134CDE' }} />
              <Button label="Accept" buttonType="primary" onClick={handleConfirmed} customStyle={{ ...styles.customButton2, borderRadius: '7px' }} />
            </div>
          </FormPopup>
        ) : null}
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ margin: '24px 0px 24px 0px' }}>
              {tabsAvailable.map((navMenu, index) => {
                return <TabButton label={navMenu} isDisabled={false} key={index} onClick={() => handleNavigate(navMenu)} selected={navMenu.toLowerCase() === leadDetailSection.toLowerCase()} setSelectedOption={setLeadDetailSection} />;
              })}
            </div>
            {((leadDetailSection === 'lead details' && checkAccessTags(['tag_msme_lead_view_int_lead_details_read_write', 'tag_msme_lead_view_int_read_write', 'tag_msme_lead_view_int_lead_details_btn_edit'])) || (leadDetailSection === 'documents' && checkAccessTags(['tag_msme_lead_view_int_documents_read_write', 'tag_msme_lead_view_int_read_write']))) && EditLeadStatus.includes(leadStatus) ? (
              <div style={styles.leadReviewEditViewContainer} onClick={() => setIsEdit(!isEdit)}>
                {isEdit ? 'View' : 'Edit'}
              </div>
            ) : (
              <div />
            )}
          </div>
          {leadDetailSection === 'lead details' && checkAccessTags(['tag_msme_lead_view_int_lead_details_read_write', 'tag_msme_lead_view_int_read_write', 'tag_msme_lead_view_int_read']) && (
            <React.Fragment>
              <div className="lead-review-detail-container">
                {leadStatus === LeadStatus?.offer_in_progress.value ? (
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <img src={Animation} alt="Animation" style={{ height: '200px', width: '200px' }} />
                    </div>
                  </div>
                ) : null}

                {(offer?.credit_deviation?.length > 0 || offer?.fraud_deviation?.length > 0 || offer?.risk_deviation?.length > 0) && (
                  <div>
                    <div className="alert-container" style={{ backgroundColor: '#FFF5E6', border: '2px solid #DB8400', borderRadius: '8px' }}>
                      <img style={{ margin: '17px 0px 0px 16px' }} alt="icon" src={InfoCircle} className="menuIcon" />
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div className="alert-container-title" style={{ fontFamily: 'Montserrat-SemiBold', color: '#DB8400' }}>
                            Deviation Messages
                          </div>
                          {offer?.credit_deviation?.length > 0 && (
                            <>
                              <div className="alert-container-msg" style={{ fontFamily: 'Montserrat-SemiBold' }}>
                                Credit Deviation
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {offer?.credit_deviation?.map((reason, index) => (
                                  <div key={index} className="alert-container-msg" style={{ fontFamily: 'Montserrat-Medium', marginLeft: '10px' }}>{`${index + 1}. ${reason}`}</div>
                                ))}
                              </div>
                            </>
                          )}
                          {offer?.fraud_deviation?.length > 0 && (
                            <>
                              <div className="alert-container-msg" style={{ fontFamily: 'Montserrat-SemiBold' }}>
                                Fraud Deviation
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {offer?.fraud_deviation?.map((reason, index) => (
                                  <div key={index} className="alert-container-msg" style={{ fontFamily: 'Montserrat-Medium', marginLeft: '10px' }}>{`${index + 1}. ${reason}`}</div>
                                ))}
                              </div>
                            </>
                          )}
                          {offer?.risk_deviation?.length > 0 && (
                            <>
                              <div className="alert-container-msg" style={{ fontFamily: 'Montserrat-SemiBold' }}>
                                Risk Deviation
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {offer?.risk_deviation?.map((reason, index) => (
                                  <div key={index} className="alert-container-msg" style={{ fontFamily: 'Montserrat-Medium', marginLeft: '10px' }}>{`${index + 1}. ${reason}`}</div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {leadStatus === LeadStatus.rejected.value ? (
                  <div>
                    <div className="alert-container" style={{ backgroundColor: '#FFECEC', border: '2px solid #C00', borderRadius: '8px' }}>
                      <img style={{ margin: '17px 0px 0px 16px' }} alt="icon" src={CrossCircle} className="menuIcon" />
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div className="alert-container-title" style={{ fontFamily: 'Montserrat-SemiBold', color: '#C00' }}>
                            Offer Rejected
                          </div>
                          {rejectionReason && rejectionReason.length ? (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {
                                <div className="alert-container-msg" style={{ fontFamily: 'Montserrat-SemiBold' }}>
                                  Rejection Reason
                                </div>
                              }
                              {rejectionReason.map((reason, index) => (
                                <div key={index} className="alert-container-msg" style={{ fontFamily: 'Montserrat-Medium', marginLeft: '10px' }}>{`${index + 1}. ${reason}`}</div>
                              ))}
                            </div>
                          ) : (
                            <div className="alert-container-msg" style={{ fontFamily: 'Montserrat-Medium' }}>
                              We are sorry to inform that your application is rejected. Please try again later.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {offer && <OfferGenerate customStyle={{ marginTop: '20px' }} loanAppId={params.id} MSMECompanyId={history.location.state?.companyId} MSMEProductId={history.location.state?.productId} showAlert={showAlert} leadStatus={leadStatus} fetchLeadDetails={fetchLeadDetails} />}
                {!(isEdit && checkAccessTags(['tag_msme_lead_view_int_lead_details_read_write', 'tag_msme_lead_view_int_read_write', 'tag_msme_lead_view_int_lead_details_btn_edit'])) ? (
                  <div>
                    <CustomAccordion accordionData={accordionData} customRightComponentContainer={{ marginTop: '20px' }} />
                  </div>
                ) : (
                  <div>
                    <LeadReviewForm companyId={companyId} productId={productId} loanAppId={loanAppId} leadData={leadData} fetchActivityLogs={fetchActivityLogs} />
                  </div>
                )}
              </div>
              {leadStatus === LeadStatus.lead_deviation.value && (
                <div style={{ display: 'flex' }}>
                  <Checkbox checked={preliminaryCheck} onChange={handlePreliminaryCheckBox} />
                  <span style={{ marginRight: '10px', marginTop: '12px' }}>preliminary check</span>

                  <Button
                    buttonType="custom"
                    customStyle={{
                      ...styles['button'],
                      backgroundColor: '#FFFFFF',
                      color: '#475BD8',
                      border: '1px solid #475BD8',
                    }}
                    customLoaderClass={styles['buttonLoader']}
                    isLoading={isLoading}
                    isDisabled={false}
                    label={'Save'}
                    onClick={() => {
                      handlePreliminarySave();
                    }}
                  />
                </div>
              )}
              <div className="lead-review-button-container">
                <div />
                {!isEdit ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {leadStatus === LeadStatus.lead_deviation.value && ((checkAccessTags(['tag_msme_lead_view_int_lead_details_btn_update']) && checkAccessTags(['tag_msme_lead_view_int_read'])) || checkButtonAccess()) && (
                      <Button
                        buttonType="custom"
                        customStyle={{
                          ...styles['button'],
                          backgroundColor: '#FFFFFF',
                          color: '#475BD8',
                          border: '1px solid #475BD8',
                        }}
                        customLoaderClass={styles['buttonLoader']}
                        isLoading={isLoading}
                        isDisabled={false}
                        label={'Request an Update'}
                        onClick={() => {
                          handleRequestUpdate();
                        }}
                      />
                    )}
                    {leadStatus === LeadStatus.offer_deviation.value && (checkAccessTags(['tag_msme_lead_view_int_lead_details_btn_update', 'tag_msme_lead_view_int_read']) || checkButtonAccess()) && (
                      <Button
                        buttonType="custom"
                        customStyle={{
                          ...styles['button'],
                          backgroundColor: '#FFFFFF',
                          color: '#475BD8',
                          border: '1px solid #475BD8',
                        }}
                        customLoaderClass={styles['buttonLoader']}
                        isLoading={isLoading}
                        isDisabled={false}
                        label={'Back to Lead Deviation'}
                        onClick={() => {
                          handleBackToLeadDeviation();
                        }}
                      />
                    )}
                    {checkAccessTags(['tag_msme_lead_reset']) && (
                      <Button
                        buttonType="custom"
                        customStyle={{
                          ...styles['button'],
                          backgroundColor: '#FFFFFF',
                          color: '#475BD8',
                          border: '1px solid #475BD8',
                        }}
                        customLoaderClass={styles['buttonLoader']}
                        isDisabled={leadStatus === LeadStatus.offer_in_progress.value || leadStatus === LeadStatus.in_progress.value || leadStatus === LeadStatus.approved.value}
                        label={'Lead Reset'}
                        onClick={() => {
                          handleLeadReset();
                        }}
                      />
                    )}

                    {leadStatus !== LeadStatus.new.value && leadStatus !== LeadStatus.rejected.value && leadStatus !== LeadStatus.approved.value && ((checkAccessTags(['tag_msme_lead_view_int_lead_details_btn_reject']) && checkAccessTags(['tag_msme_lead_view_int_read'])) || checkButtonAccess()) && (
                      <Button
                        buttonType="custom"
                        customStyle={{
                          ...styles['button'],
                          backgroundColor: '#FFFFFF',
                          color: '#CC0000',
                          border: '1px solid #CC0000',
                        }}
                        customLoaderClass={styles['buttonLoader']}
                        isLoading={isLoading}
                        isDisabled={false}
                        label={'Reject'}
                        onClick={(e) => {
                          handleReject();
                        }}
                      />
                    )}

                    {leadStatus === LeadStatus.lead_deviation.value && ((checkAccessTags(['tag_msme_lead_view_int_lead_details_btn_approve_global']) && checkAccessTags(['tag_msme_lead_view_int_read'])) || checkButtonAccess()) && (
                      <Button
                        buttonType="custom"
                        customStyle={
                          disableApproveBtn
                            ? {
                                ...styles['button'],
                                backgroundColor: 'var(--neutrals-neutral-30, #CCCDD3)',
                                color: 'var(--base-white, #FFF)',
                              }
                            : {
                                ...styles['button'],
                                backgroundColor: '#FFFFFF',
                                color: '#008042',
                                border: '1px solid #008042',
                              }
                        }
                        customLoaderClass={styles['buttonLoader']}
                        isLoading={isLoading}
                        isDisabled={disableApproveBtn}
                        label={'Approve'}
                        onClick={() => {
                          handleApprove();
                        }}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          )}

          {leadDetailSection === 'documents' && checkAccessTags(['tag_msme_lead_view_int_documents_read_write', 'tag_msme_lead_view_int_read_write']) && (
            <>
              <DocumentsList loanDocuments={loanDocuments} companyId={companyId} productId={productId} loanAppId={loanAppId} fetchLoanDocuments={getLoanDocuments} isEdit={isEdit} />
            </>
          )}
          {leadDetailSection === 'loan details' && <CreateLoan />}
          {leadDetailSection === 'sl & lba' && <SanctionLenderLead />}
          {leadDetailSection === 'nach' && <Natch />}

          {leadDetailSection === 'cams' && <CamsSection leadStatus={leadStatus} companyId={companyId} productId={productId} loanAppId={loanAppId} />}
        </div>
        {leadDetailSection === 'lead details' ? <AuditLog data={auditLogs} /> : null}
      </div>
      <ActionPopup
        heading={title}
        fields={commentFields}
        type={type}
        showSelectedRemarks={showSelectedRemarksList.includes(type)}
        data={data}
        setData={setData}
        dataErrors={dataErrors}
        setDataErrors={setDataErrors}
        isModalOpen={openDialog}
        button={{
          primary: {
            label: type === 'reject' ? 'Reject' : type == 'approve' ? 'Approve' : 'Submit',
            style: {
              background: type == 'reject' ? '#CC0000' : 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%)',
            },
            action: (props) => {
              if (type == 'sectionApprove') {
                return;
              }

              if (type == 'backToLeadDeviation') {
                handleBackToLeadDeviationSubmit();
                return;
              }

              if (type == 'addComment') {
                handlePopupSubmit(actionId, title, type);
                resetAll(true);
              } else {
                handleLeadStatus(leadStatusMappings[type]);
              }
            },
          },
          secondary: {
            label: 'Cancel',
            style: styles['negativeButton'],
            action: (props) => {
              setOpenDialog(false);
              setData('');
            },
          },
        }}
        buttonText={'Comment'}
        onClickOutsideModal={() => {
          setOpenDialog(false);
        }}
        callback={(isModalOpen) => {
          if (!isModalOpen) {
            setData('');
            setOpenDialog(false);
          }
        }}
      />
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      {isLoading && <Preloader />}
    </div>
  );
}

const useStyles = ({ innerWidth, innerHeight }) => {
  return {
    accordionHeader: {
      fontFamily: 'Montserrat-Regular',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#6B6F80',
    },
    accordionBody: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '16px',
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#151515',
    },
    leadReviewBottomComponent: {
      paddingBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px',
    },
    customStyleButton: {
      backgroundColor: '#008042',
      color: 'white',
      height: '40px',
      width: '120px',
      fontSize: '13px',
      padding: '12px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    negativeButton: {
      background: '#FFFFFF',
      color: '#475BD8',
      border: '1px solid #475BD8',
    },
    button: {
      height: '40px',
      width: '200px',
      borderRadius: '20px',
      marginLeft: '16px',
      fontSize: '14px',
      padding: 0,
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: '#475BD8',
      color: '#FFF',
      fontFamily: 'Montserrat-Regular',
    },
    buttonLoader: {
      border: '3px solid white',
      borderTop: '3px solid transparent',
      marginLeft: '40%',
    },
    leadReviewBottomComponent: {
      paddingBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px',
    },
    customStyleButton: {
      backgroundColor: '#008042',
      color: 'white',
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    customStyleButtonComment: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      backgroundColor: '#FFF',
      borderColor: '#134CDE',
      color: '#134CDE',
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    leadReviewEditViewContainer: {
      width: '66px',
      alignSelf: 'center',
      fontSize: '14px',
      fontFamily: 'Montserrat-Medium',
      color: '#134CDE',
      cursor: 'pointer',
    },
    button: {
      height: '48px',
      width: 'max-content',
      borderRadius: '40px',
      gap: '10px',
      fontSize: '16px',
      lineHeight: '24px',
      padding: '12px 24px',
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: '#475BD8',
      color: '#FFF',
      fontFamily: 'Montserrat-Regular',
    },
    buttonLoader: {
      border: '3px solid white',
      borderTop: '3px solid transparent',
      marginLeft: '40%',
    },
  };
};
