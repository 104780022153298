//import { BulkUploadStatusList } from 'msme/config/LoanStatus';
import React from 'react';
import { BulkUploadStatusList } from 'config/LoanStatus';
const BulkUploadStatusIcon = ({ status }) => {
    console.log("staus",status)
  const styles = useStyles();

  const handleStatusCss = (status) => {
    let content;

    switch (status) {
      case BulkUploadStatusList.open.value:
        content = {
          ...styles.statusStyle,
          border: '1px solid var(--primary-50, #475BD8)',
          color: 'var(--primary-50, #475BD8)',
          background: 'var(--primary-0, #EDEFFB)',
        };
        break;
      case BulkUploadStatusList.approved.value:
        content = {
            ...styles.statusStyle,
            border: '1px solid var(--utility-success-50, #008042)',
            color: 'var(--utility-success-50, #008042)',
            background: 'var(--utility-success-0, #EEFFF7)',
        };
        break;
      case BulkUploadStatusList.rejected.value:
        content = {
          ...styles.statusStyle,
          border: '1px solid var(--utility-danger-30, #B30000)',
          color: 'var(--utility-danger-30, #B30000)',
          background: 'var(--utility-danger-0, #FFECEC)',
        };
        break;
      case BulkUploadStatusList.inProcess.value:
        content = {
            ...styles.statusStyle,
            border: '1px solid var(--utility-warning-20, #DB8400)',
            color: 'var(--utility-warning-20, #DB8400)',
            background: 'var(--utility-warning-0, #FFF5E6)',
        };
        break;
      case BulkUploadStatusList.failed.value:
        content = {
        ...styles.statusStyle,
        border: '1px solid var(--utility-danger-30, #B30000)',
        color: 'var(--utility-danger-30, #B30000)',
        background: 'var(--utility-danger-0, #FFECEC)',
        };
        break;
      case BulkUploadStatusList.partialSuccess.value:
        content = {
            ...styles.statusStyle,
            border: '1px solid var(--utility-danger-30, #4B4D57)',
            color: 'var(--utility-danger-30, #4B4D57)',
            background: 'var(--utility-danger-0, #F1F1F3)',
        };
        break;
    }
    return content;
  }; 
  return <div style={handleStatusCss(status)}>{BulkUploadStatusList[status] ? BulkUploadStatusList[status]?.label : status}</div>;
};

const useStyles = () => {
  return {
    statusStyle: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '12px',
      display: 'flex',
      padding: '2px 8px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
    },
  };
};

export default BulkUploadStatusIcon;
