import * as React from "react";
import { styled } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import { drawDownRequestDetailsWatcher, rejectDrawDownRequestWatcher } from "../../actions/transactionHistory";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {Button, Divider} from "@mui/material";
import ButtonNew from "react-sdk/dist/components/Button/Button"
import RightCircle from '../../../src/assets/img/rightCircle.svg';
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AlertBox } from "../../components/AlertBox";
import { checkAccessTags } from "../../util/uam";
import { storedList } from "../../util/localstorage";
import { Box, IconButton } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { convertImagesToPdf, convertTextFileToPdf, downloadDataInXLSXFormat } from "../../util/helper";
import { uploadDrawDownDocumentsWatcher } from "../../actions/loanDocuments";
import { getDrawDownDocsWatcher } from "../../actions/loanDocuments";
import { viewDocsWatcher } from "../../actions/loanDocuments";
import { b64ToBlob } from "../../util/helper";
import CustomizeTemplates from "../loanSchema/templateTabs";
import Accordion from "react-sdk/dist/components/Accordion/Accordion";
import UploadCard from "react-sdk/dist/components/UploadCard";
import Preloader from "../../components/custom/preLoader";
import Table from "react-sdk/dist/components/Table/Table"
import Img from "./images/download-button.svg"
import imgH from "./images/download-button-hover.svg";
import { getBorrowerDetailsWatcher } from "actions/borrowerInfo";
import moment from "moment";
import DownloadLoanFiles from "./DownloadLoanFiles";
import { getDDRpsReportWatcher, getPrepaymentDetailsWatcher } from "../../actions/ddRpsReport"
import InputBox from "react-sdk/dist/components/InputBox/InputBox";
import CustomDatePicker from 'react-sdk/dist/components/DatePicker/DatePicker';
import Buttoned from "react-sdk/dist/components/Button";
import FormPopup from "react-sdk/dist/components/Popup/FormPopup";
import { repaymentV2FormPostWatcher } from "../../actions/repaymentV2";



const user = storedList("user");
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3)
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",

            right: 8,

            top: 8,

            color: "black"
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function LOCDrawdownRequests() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { company_id, product_id, loan_id, request_id,loan_app_id,status,line_pf} = useParams();

  var urlParams = new URLSearchParams(window.location.search);

  var tabValue = urlParams.get('tab');

  var usageIdValue = urlParams.get('usageId')

  const [cardsData, setCardsData] = useState([]);

  const [alert, setAlert] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");

  const [expanded, setExpanded] = useState(true);

  const [openUpload, setOpenUpload] = useState(false);

  const [severity, setSeverity] = useState("");

  const [pageType, setPageType] = useState(tabValue ? tabValue : "Details");

  const [fileData, setFileData] = useState(null);

  const [submitType, setSubmitType] = useState("");

  const [code, setCode] = useState(0);

  const [uploadComplete, setUploadComplete] = useState(false);

  const [fileName, setFileName] = useState("");

  const [isInvoiceUploaded, setisInvoiceUploaded] = useState(false);

  const [isDrawdownUploaded, setIsDrawdownUploaded] = useState(false);

  const [isProfarmaInvoiceUploaded, setProfarmaInvoiceUploaded] = useState(false);

  const [isPurchaseOrder, setPurchaseOrder] = useState(false);

  const [isOther1, setOther1] = useState(false);

  const [isOther2, setOther2] = useState(false);

  const [isOther3, setOther3] = useState(false);


  const [popupContent, setPopupContent] = useState("");

  const [loader, setLoader] = useState(false);

  const [buttonLoader, setButtonLoader] = useState(false);

  const [ddRpsReportData, setDdRpsReportData] = useState([]);

  const [prePaymentData, setPrePaymentData] = useState({});
  
  const [requestId, setRequestId] = useState("");
  
  const [usageId, setUsageId] = useState("");
  
  const [invoiceNum, setInvoiceNum] = useState("");
  
  const [uTRNumber, setUTRNumber] = useState("");
  
  const [utrDate, setUtrDate] = useState('');

  const [tXNRefDate, setTXNRefDate] = useState('');
  
  const [paymentMode, setPaymentMode] = useState("");

  const [tXNRef, setTXNRef] = useState("");
  
  const [tDSAmount, setTDSAmount] = useState("");

  const [prePaymentAmt, setPrePaymentAmt] = useState("");

  const [prePaymentDate, setPrePaymentDate ] = useState("");

  const [prePaymentFormData, setPrePaymentFormData] = useState({})

  const [isDisabled, setIsDisabled ] = useState(true);

  const [isMIProduct, setIsMIProduct] = useState(false);

  const [disbusrmentDate, setDisbusrmentDate] = useState('');
  
  const [dueDate, setDueDate] = useState('');

  const useAsyncState = initialState => {
    const [state, setState] = useState(initialState);

    const asyncSetState = value => {
      return new Promise(resolve => {
        setState(value);

        setState(current => {
          resolve(current);

          return current;
        });
      });
    };

    return [state, asyncSetState];
  };
  const isCollateral = false;
  const [companyGlobal, setCompanyGlobal] = useAsyncState(null);
  const [productGlobal, setProductGlobal] = useAsyncState(null);
  const [fetchedPreviousData, setFetchedPreviousData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const isLoading = useSelector(state => state.profile.loading);
  const [rejectPopup, setRejectPopup] = useState(false);

  const customRejectButton = {
    borderRadius:'8px',
    width: '48%',
    height: '50px',
    fontSize: '16px',
}

  const handleClick = () => {
    setRejectPopup(true);
  };
  const handleRejectClose = () => {
    setRejectPopup(false);
}






  const handleAlertClose = () => {
    setAlert(false);

    setSeverity("");

    setAlertMessage("");
  };

  const fetchDdRpsReportDetails = () => {

    const usageId = usageIdValue ? usageIdValue : cardsData[0]?.data?.find(row => row.head == 'USAGE ID')?.body ;
    const params = {
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
      user_id: user._id,
      usage_id: usageId != 'N/A' ? usageId : 0,
    };
    dispatch(
      getDDRpsReportWatcher(
        params,
        (result) => {
          setDdRpsReportData(result.data);
        },
        (error) => {

          return showAlert(error?.response?.data?.message, "error");
        }
      )
    );
  };

  const fetchPrepaymentDetails = (date) => {

    const usageId = usageIdValue ? usageIdValue : cardsData[0]?.data?.find(row => row.head == 'USAGE ID')?.body ;
    
    
     date = date === null ? null: moment(date,'DD-MM-YYYY').format("DD-MM-YYYY")
   
    const params = {
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
      user_id: user._id,
      usage_id: usageId != 'N/A' ? usageId : 0,
      pre_payment_date: date
    };
    setPrePaymentFormData(params)

    dispatch(
      getPrepaymentDetailsWatcher(
        params,
        (result) => {
          setPrePaymentDate(result?.data?.pre_payment_date)

          setPrePaymentAmt(result?.data?.pre_payment_amount)
        setDisbusrmentDate(result?.data?.disb_date)
        setDueDate(result?.data?.due_date)
          setPrePaymentData(result);
        },
        (error) => {

          return showAlert(error?.response?.data?.message, "error");
        }
      )
    );
  };

  const changeDateSelected = (value, name) => {
    if(name == 'TXN'){
      setTXNRefDate(moment(value,'DD-MM-YYYY').format("YYYY-MM-DD"));
    }
    else if(name == 'PREPAYMENT'){
      setPrePaymentDate(moment(value,'DD-MM-YYYY').add(5,'hours').add(30,'minutes').format("YYYY-MM-DD HH:mm"));
      fetchPrepaymentDetails(value);
    }
    else if(name == 'UTR'){
      setUTRDate(moment(value,'DD-MM-YYYY').format("YYYY-MM-DD"))
    }   
  };

  const handleResponsePopUp = (message, severity) => {
    setAlert(true);

    setAlertMessage(message);

    setSeverity(severity);

    setTimeout(() => {
      handleAlertClose();
    }, 4000);
  };

  const getBase64Data = async fileData => {
    const b64Data = await convertImagesToPdf(fileData);

    setFileData(b64Data);
  };

  const handleFileChange = event => {
    let selectedFileEvent = event.target.files;

    if (selectedFileEvent[0]["size"] > 10e6) {
      handleResponsePopUp(
        "File size should not be greater than 8 MB",

        "error"
      );
    }

    const fileType = selectedFileEvent[0]["name"];

    const fileExtension = fileType.split(".").pop();

    setFileName(selectedFileEvent[0]["name"]);

    if (
      (selectedFileEvent.length > 0 && fileExtension === "png") ||
      fileExtension === "jpeg" ||
      fileExtension === "jpg"
    ) {
      let fileToLoad = selectedFileEvent[0];

      let fileReader = new FileReader();

      fileReader.onload = fileLoadedEvent => {
        getBase64Data(fileLoadedEvent.target.result);
      };

      fileReader.readAsDataURL(fileToLoad);
    }

    if (selectedFileEvent.length > 0 && fileExtension === "pdf") {
      let fileToLoad = selectedFileEvent[0];

      let fileReader = new FileReader();

      fileReader.onload = fileLoadedEvent => {
        setFileData(fileLoadedEvent.target.result);
      };

      fileReader.readAsDataURL(fileToLoad);
    }

    setTimeout(() => {
      setUploadComplete(true);
    }, 3000);
  };

  const handleFileUpload = () => {
    setButtonLoader(true);

    if (!fileData) {
      return handleResponsePopUp(
        "Please select file",

        "error"
      );
    }

    const foundItem = cardsData[0]?.data.find(
      item => item.head === "Loan App ID"
    );

    const data = {
      submitData: {
        loan_app_id: foundItem?.body || loan_app_id,

        drawdown_request_id: request_id,

        doc: [{ code: code, base64pdfencodedfile: fileData }]
      },

      userData: {
        company_id: company_id,

        product_id: product_id,

        user_id: user._id
      }
    };

    new Promise((resolve, reject) => {
      dispatch(uploadDrawDownDocumentsWatcher(data, resolve, reject));
    })

      .then(result => {
        delete data.submitData.base64pdfencodedfile;

        delete data.submitData.fileType;

        setFileData("");

        setFileName("");

        setCode("");

        setUploadComplete(false);

        handleResponsePopUp(
          result.uploadDocumentData[0].message,

          "success"
        );

        if (code === 104) {
          setisInvoiceUploaded(true);
        } else if (code === 102) {
          setIsDrawdownUploaded(true);
        }
        else if (code === 338) {
          setProfarmaInvoiceUploaded(true);
        }
        else if (code === 337) {
          setPurchaseOrder(true);
        }
        else if (code === "339") {
          setOrder1(true);
        }
        else if (code === "340") {
          setOrder2(true);
        }
        else if (code === "341") {
          setOrder3(true);
        }

        fetchPreviousDocuments();

        setButtonLoader(false);

        handleClose();
      })

      .catch(error => {
        setCode("");

        handleClose();

        handleResponsePopUp(error?.response?.data, "error");
      });
  };

  const handleViewDoc = (awsurl, doctype) => {
    const foundItem = cardsData[0]?.data.find(
      item => item.head === "Loan App ID"
    );

    let data = {
      company_id: company_id,

      product_id: product_id,

      loan_app_id: foundItem?.body || loan_app_id,

      awsurl,

      user_id: user._id
    };

    dispatch(
      viewDocsWatcher(
        data,

        response => {
          handleDocumentPopUp(response, doctype);
        },
        error => { }
      )
    );
  };

  const handleDocumentPopUp = (pdf, fileType) => {
    if (pdf.indexOf("data:application/pdf;base64,") >= 0) {
      pdf = pdf.replace("data:application/pdf;base64,", "");
    }

    const contentType = "application/pdf";

    const blob = b64ToBlob(pdf, contentType);

    const blobUrl = URL.createObjectURL(blob);

    setPopupContent(
      <ReactBSAlert
        style={{
          display: "block",

          marginTop: "-350px",

          width: "900px",

          padding: "15px 4px 3px 3px",

          position: "relative"
        }}
        title={fileType}
        confirmBtnBsStyle="success"
        btnSize="md"
        showConfirm={false}
      >
        <div>
          <button
            type="button"
            className="close"
            style={{
              position: "absolute",

              top: "21px",

              right: "20px",

              zIndex: "999"
            }}
            onClick={() => setPopupContent(false)}
          >
            <CloseIcon />
          </button>

          <iframe
            src={blobUrl}
            type="application/pdf"
            width="100%"
            height="450px"
          />
        </div>
      </ReactBSAlert>
    );
  };

  const isTagged =
    process.env.REACT_APP_BUILD_VERSION > 1
      ? user?.access_metrix_tags?.length
      : false;

  useEffect(() => {
    if (
      isTagged &&
      checkAccessTags([
        "tag_drawdown_request_read",

        "tag_drawdown_request_read_write",

        "tag_drawdown_request_details_read_write"
      ])
    )
      fetchDrawDownDetailsList();

    if (!isTagged) fetchDrawDownDetailsList();
  }, []);

  useEffect(() => {
    if (usageIdValue && usageIdValue != 'N/A')
    fetchDdRpsReportDetails();
  }, [])

  const showAlert = (msg, type) => {
    setAlert(true);

    setSeverity(type);

    setAlertMessage(msg);

    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const fetchDrawDownDetailsList = () => {
    const payload = {
      user_id: user._id,

      request_id,

      loan_id: loan_id,

      company_id,

      product_id
    };

    new Promise((resolve, reject) => {
      dispatch(drawDownRequestDetailsWatcher(payload, resolve, reject));
    })

      .then(response => {
        setIsMIProduct(response.is_prepayment_eligible);
        const amountFields = [
          "USAGE FEE INCLUDING GST", "USAGE FEE", "GST USAGE FEE", "CGST USGAE FEE", "SGST USAGE FEE", "IGST USAGE FEE",
          "UPFRONT INTEREST", "BOUNCE CHARGES", "DUE AMOUNT"
        ];

        
       
        const formattedData = {};

        for (const key in response.data) {
          if (Object.hasOwnProperty.call(response.data, key)) {
            formattedData[key] = response.data[key].map(item => formatItem(item));
          }
        }
        
        const details = [
          {
            title: "Drawdown Details",
            data: formattedData.respObj
          },
          {
            title: "Deductions",
            data: formattedData.deductions
          },
          {
            title: "Drawdown Summary",
            data: formattedData.drawdownSummary
          },
          {
            title: "Bank Details",
            data: formattedData.bankdetails
          },
          {
            title: "Status",
            data: formattedData.status
          },
          {
            title: "Other Details",
            data: formattedData.otherdetails
          }
        ];
        
        function formatItem(item) {
          let bodyValue;
        
          if (amountFields.includes(item.key.toUpperCase())) {
            bodyValue = (item.value === "" || item.value === "N/A" || (!item.value && item.value != 0)) ? "N/A"
              : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.value);
          } else {
            bodyValue = item.value;
          }
        
          return ({
            body: bodyValue,
            head: item.key.toUpperCase()
          });
        }
              

        setCardsData(details);
        setDisabled(false)
      })

      .catch(() => {
        showAlert("No drawdown records found for the loan id", "error");
        setDisabled(true)
      });
  };

  const rejectDrawDownReject = () => {
    const payload = {
      user_id: user._id,
      request_id: request_id,
      loan_id: loan_id,
      company_id: company_id,
      product_id: product_id,
    };
    new Promise((resolve, reject) => {
      dispatch(rejectDrawDownRequestWatcher(payload, resolve, reject));
    })
      .then(response => {
        showAlert(response?.message, "success")
        setDisabled(true)
        setTimeout(() => {
          history.goBack();
        }, 2000);
      })
      .catch((error) => {
        console.log("error", error)
        showAlert(error?.response?.data?.message || "Error while rejecting drawdown request", "error");
      });
  };

  const handleClose = () => {
    setFileData(null);

    setOpenUpload(false);

    setUploadComplete(false);
  };

  const handleSubmitPrePayment = () => {
      const data ={ 
        company_id: company_id,
        product_id: product_id,
        loan_id: loan_id,

        loan_app_id: prePaymentData?.data?.loan_app_id,
        partner_loan_id: prePaymentData?.data?.partner_loan_id,

        txn_amount: Number(prePaymentAmt),
        txn_reference: tXNRef,

        label: "repayment",
        repayment_tag: 'prepayment',

        utr_number: uTRNumber,
        utr_date_time_stamp: moment(prePaymentDate).local().format('YYYY-MM-DD hh:mm:ss'),
        txn_reference_datetime: moment(tXNRefDate,'YYYY-MM-DD').format("YYYY-MM-DD hh:mm:ss"),
        payment_mode: paymentMode,
        usage_id: prePaymentData?.data?.usage_id,
        tds_amount: Number(tDSAmount || 0)
      };
         new Promise((resolve, reject) => {
        dispatch(repaymentV2FormPostWatcher(data, resolve, reject));
      })
        .then(response => {
          fetchPrepaymentDetails(moment().format('DD-MM-YYYY'));
          setRejectPopup(false);
          showAlert(response.message, "success");
        })
        .catch(error => {
          const displayError = error.response?.data?.data?.body?.details
            ? error.response?.data?.data?.body?.details
            : error?.response?.data?.message
            ? error?.response?.data.message
            : "Something went wrong while adding repayment data.";
          showAlert(displayError, "error");
        });
  };

  const renderUploadModal = () => (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openUpload}
        maxWidth={"lg"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ color: "#151515", display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              fonSize: "18px",

              fontWeight: "500",

              lineHeight: "150%"
            }}
          >
            {submitType.toLowerCase()==="invoice"
              ? "Upload Invoice"
              : ""}
              {submitType === "drawDown"
              ? "Upload Drawdown Request"
              : ""}
             {submitType.toLowerCase() === "proformainvoice"
              ? "Upload Proforma Invoice"
              : ""}
                {submitType.toLowerCase() ==="purchaseorder"
              ? "Upload Purchase Order"
              : ""}
               {submitType.toLowerCase().includes("other") 
             
              ?"Upload Other Documents": ""}
          </div>

          <div
            style={{
              fontSize: "12px",

              fontWeight: "400",

              lineHeight: "150%",

              color: "#9E9E9E"
            }}
          >
            {"PDF, PNG, JPG and JPEG files are allowed"}
          </div>
        </BootstrapDialogTitle>

        <div
          style={{
            backgroundColor: "#fff",
            width: "430px",
            margin: "15px 15px",
            display: "flex",
            flexDirection: "column",
            color: "var(--neutrals-9-e, #9E9E9E)"
          }}
        >
          {uploadComplete ? (
            <div
              style={{
                bordeRadius: "4px",

                border: "1px dashed var(--success-500-main, #0DB928)",

                background: "#EEFFF1",

                justifyContent: "center",

                alignItems: "center",

                display: "flex",

                flexDirection: "column",

                height: "250px"
              }}
            >
              <div>
                <CheckCircleIcon style={{ fontSize: "80", color: "#0DB928" }} />
              </div>

              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >{`${fileName} upload successfully`}</div>
            </div>
          ) : (
            <div
              style={{
                bordeRadius: "4px",

                border: "1px dashed var(--neutrals-d-9, #D9D9D9)",

                background: "var(--neutrals-f-9, #F9F9F9)",

                justifyContent: "center",

                alignItems: "center",

                display: "flex",

                flexDirection: "column",

                height: "250px"
              }}
            >
              <input
                type="file"
                accept=".png,.jpeg,.jpg,.pdf"
                style={{ display: "none" }}
                onChange={e => {
                  handleFileChange(e);
                }}
                id="file-upload"
              />

              <label htmlFor="file-upload">
                <div>
                  <FileUploadOutlinedIcon style={{ fontSize: "80" }} />
                </div>
              </label>

              <div>{"Drag and drop or browse to choose a file"}</div>
            </div>
          )}

          <div
            style={{
              marginTop: "20px"
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",

                alignItems: "center",

                width: "100%",

                paddingTop: "14px",

                paddingBottom: "14px"
              }}
              disabled={!uploadComplete}
              onClick={handleFileUpload}
            >
              {buttonLoader == true ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "Upload"
              )}
            </Button>
          </div>
        </div>
      </BootstrapDialog>
    </>
  );

  const fetchPreviousDocuments = () => {
    const foundItem = cardsData[0]?.data.find(
      item => item.head === "Loan App ID"
    );

    const params = {
      company_id: company_id,
      product_id: product_id,
      doc_stage: "draw_down_document",
      loan_app_id: foundItem?.body || loan_app_id,
      user_id: user._id
    };

    new Promise((resolve, reject) => {
      dispatch(getDrawDownDocsWatcher(params, resolve, reject));
    })

      .then(result => {
        const tempData = result.data.filter((item) => {
          return item.drawdown_request_id == request_id;
        });
        const splitCodeData = tempData.reduce((acc, curr) => {
          const code = curr.code;

          if (acc[code]) {
            acc[code].push(curr);
          } else {
            acc[code] = [curr];
          }

          return acc;
        }, {});

        if (splitCodeData["104"] && splitCodeData["104"].length >= 2) {
          splitCodeData["104"].reverse();
        }

        if (splitCodeData["102"] && splitCodeData["102"].length >= 2) {
          splitCodeData["102"].reverse();
        }
        if (splitCodeData["338"] && splitCodeData["338"].length >= 2) {
          splitCodeData["338"].reverse();
        }
        if (splitCodeData["337"] && splitCodeData["337"].length >= 2) {
          splitCodeData["337"].reverse();
        }
        if (splitCodeData['339'] && splitCodeData['339'].length >= 2) {
          splitCodeData['339'].reverse();
        }
        if (splitCodeData['340'] && splitCodeData['340'].length >= 2) {
          splitCodeData['340'].reverse();
        }
        if (splitCodeData['341'] && splitCodeData['341'].length >= 2) {
          splitCodeData['341'].reverse();
        }

        setFetchedPreviousData(splitCodeData);

        if (splitCodeData["104"] && splitCodeData["104"].length > 0) {

          setisInvoiceUploaded(true);
        }

        if (splitCodeData["102"] && splitCodeData["102"].length > 0) {

          setIsDrawdownUploaded(true);
        }
        if (splitCodeData["338"] && splitCodeData["338"].length > 0) {

          setProfarmaInvoiceUploaded(true);
        }
        if (splitCodeData["337"] && splitCodeData["337"].length > 0) {

          setPurchaseOrder(true);
        }
        if (splitCodeData["339"] && splitCodeData["339"].length > 0) {

          setOther1(true);
        }
        if (splitCodeData["340"] && splitCodeData["340"].length > 0) {

          setOther2(true);
        }
        if (splitCodeData["341"] && splitCodeData["341"].length > 0) {

          setOther3(true);
        }
      })

      .catch(error => {
        handleResponsePopUp(
          error?.response?.data?.message || "Something went wrong",

          "error"
        );
      });
  };
  
  const columns = [
    { id: "usage_id", label: "USAGE ID" },
    { id: "due_date", label: "DUE DATE" },
    {
      id: "interest_due", label: "INTEREST DUE",
      format: (row) => (row.interest_due >= 0
        ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row.interest_due)
        : "NA")
    },
    {
      id: "principal_due", label: "PRINCIPAL DUE",
      format: (row) => (row.principal_due >= 0
        ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row.principal_due)
        : "NA")
    }
  ];

  const handleDocuments = () => {
    fetchPreviousDocuments();

    setPageType("Documents");
  };

  const handleDetails = () => {
    setPageType("Details");
  };
  const handleRepaymentSchedule = () => {
    fetchDdRpsReportDetails();
    setPageType("Repayment Schedule");
  };

  const handlePrePayment = () => {
    fetchPrepaymentDetails(null);
    setPageType("Pre-Payment");
  };

  const changeActiveTab = tabName => {
    let tabClickHandlers = {
       details: handleDetails,
      documents: handleDocuments,
      'repayment schedule': handleRepaymentSchedule};
    if(isMIProduct){
       tabClickHandlers["pre-payment"] = handlePrePayment;
    }

    const tabClickHandler = tabClickHandlers[tabName];

    if (tabClickHandler) {
      tabClickHandler();
    }
  };
  const customButtonCss = { padding: "12px 24px", height: "48px", width: "101px", display: "flex", alignItems: "center", color: "red", bordeRadius: "26px", border: "1px solid red" }
  const customDisableButtons = { padding: "12px 24px", height: "48px", width: "101px", display: "flex", alignItems: "center", color: "#DADBE2", bordeRadius: "26px", border: "1px solid #DADBE2" }

  const generateXLSXFile = (data, ext) => {
    let jsonData = data?.map((record) => {
      return {
        ...record,
      };
    });
    let fileName = `RepaymentFile.${ext}`
    downloadDataInXLSXFormat(fileName, jsonData);
    setTimeout(() => {
      console.log('mouse')
      showAlert("File Downloaded Successfully", "success");
    }, 2000);
  };

  const handleNextStage = () =>{
    setRejectPopup(true);
  }

  const handleIsDisabled = () => {
    if(prePaymentDate !== '' && prePaymentAmt !== '' && tXNRef !== '' && uTRNumber !== '' && tXNRefDate !== ''&& paymentMode !== ''){
      setIsDisabled(false)
    } else{
      setIsDisabled(true);
    }
  }
  
  return (
    <>
      {alert ? (
        <AlertBox
          severity={severity}
          msg={alertMessage}
          onclose={handleAlertClose}
        />
      ) : null}
      {rejectPopup&& 
    <FormPopup
    isOpen={rejectPopup}
    onClose={handleRejectClose}
    customStyles={{width:"700px"}}>
    <div style={{fontFamily:"Montserrat-SemiBold", fontSize:"20px", color:"rgba(28, 28, 28, 1)", fontWeight:"600",marginTop:"8%",marginBottom:"5%", display:"flex", justifyContent:"center", textAlign:"center"}}>
    You are submitting a prepayment request.
    <br/>
    Make sure all the payment details provided here are correct.
    </div>
        <div style={{marginTop:"4%",display:"flex", marginBottom:"2%", justifyContent:"center"}}>
        <Buttoned
        id="cancel"
        buttonType="secondary"
        label="Cancel"
        customStyle={{...customRejectButton,border:"1px solid rgba(204, 0, 0, 1)",color:"rgba(204, 0, 0, 1)",width:"180px"}}
        onClick={handleRejectClose}
        />
        <Buttoned
        id="submit"
        buttonType="primary"
        label="Okay"
        customStyle={{...customRejectButton,width:"180px"}}
        onClick={(event)=>{
            handleSubmitPrePayment();
        }}
        />
        </div>

    </FormPopup>}

      {popupContent}

      {openUpload ? renderUploadModal() : null}

      <div style={{ margin: "24px" }}>
        {isMIProduct ? <CustomizeTemplates
          templatesdata={["Details", "Documents", "Repayment Schedule",'Pre-Payment']}
          initialState={tabValue? tabValue : "Details"}
          onIndexChange={changeActiveTab}
          marginLeft={"0px"}
        />:
         <CustomizeTemplates
          templatesdata={["Details", "Documents", "Repayment Schedule"]}
          initialState={tabValue? tabValue : "Details"}
          onIndexChange={changeActiveTab}
          marginLeft={"0px"}
        />}
      </div>

      {pageType == "Details" ? (  
        <div style={{ margin: "24px", display: "flex", flexDirection: "column", gap: "24px" }}>
           {cardsData.length ? (
             <Accordion
               accordionData={cardsData}
               customClass={{ width: "100%" }}
             />
           ) : null}{" "}
           <div style={{ display: "flex", justifyContent: "flex-end" }}>
             <ButtonNew
               label="Reject"
               buttonType="secondary"
               isDisabled={status == 1 || status == 2 || status == 4 || disabled || line_pf === "true" ? true : false}
               onClick={rejectDrawDownReject}
               customStyle={(status == 1 || status == 2 || status == 4 || disabled || line_pf === "true") ? customDisableButtons : customButtonCss}
             />
               </div>
         </div>
      ) : pageType == "Documents" ? (
        <div style={{  display: "grid",  gridTemplateRows:"repeat(2,1fr)" , gridTemplateColumns:"repeat(4,1fr)" }}>
          <UploadCard
            hasDocument={isInvoiceUploaded}
            heading="Invoice"
            viewOnClick={() =>
              handleViewDoc(
                fetchedPreviousData["104"][0].value,

                "Invoice"
              )
            }
            uploadOnClick={() => {
              setCode(104);

              setSubmitType("invoice");

              setOpenUpload(true);
            }}
          />

          <UploadCard
            heading="Drawdown Agreement"
            hasDocument={isDrawdownUploaded}
            viewOnClick={() =>
              handleViewDoc(
                fetchedPreviousData["102"][0].value,

                "DrawdownRequest"
              )
            }
            uploadOnClick={() => {
              setCode(102);

              setSubmitType("drawDown");

              setOpenUpload(true);
            }}
          />
          <UploadCard
            heading="Proforma Invoice"
            hasDocument={isProfarmaInvoiceUploaded}
            viewOnClick={() =>
              handleViewDoc(
                fetchedPreviousData["338"][0].value,

                "ProformaInvoice"
              )
            }
            uploadOnClick={() => {
              setCode(338);

              setSubmitType("proformainvoice");

              setOpenUpload(true);
            }}
          />
          <UploadCard
            heading="Purchase Order"
            hasDocument={isPurchaseOrder}
            viewOnClick={() =>
              handleViewDoc(
                fetchedPreviousData["337"][0].value,

                "PurchaseOrder",
              )

            }
            
            uploadOnClick={() => {
              setCode(337);
              setSubmitType("purchaseorder");
              setOpenUpload(true);
            }}     
          />
         
          <UploadCard
            heading="Other Drawdown Doc 1"
            hasDocument={isOther1}
            viewOnClick={() =>
              handleViewDoc(
                fetchedPreviousData["339"][0].value,

                "Other1"
              )
            }
            uploadOnClick={() => {
              setCode(339);

              setSubmitType("other1");

              setOpenUpload(true);
            }}
          />
          <UploadCard
            heading="Other Drawdown Doc 2"
            hasDocument={isOther2}
            viewOnClick={() =>
              handleViewDoc(
                fetchedPreviousData["340"][0].value,

                "Other2"
              )
            }
            uploadOnClick={() => {
              setCode(340);

              setSubmitType("other2");

              setOpenUpload(true);
            }}
          />
          <UploadCard
            heading="Other Drawdown Doc 3"
            hasDocument={isOther3}
            viewOnClick={() =>
              handleViewDoc(
                fetchedPreviousData["341"][0].value,

                "Other3"
              )
            }
            uploadOnClick={() => {
              setCode(341);

              setSubmitType("other3");

              setOpenUpload(true);
            }}
          />
        </div>
      ) : pageType == "Repayment Schedule" ? (
          
        <div style={{ padding: "8px 20px 20px 20px", maxWidth: "100%" }}>
          <div style={{ display: 'flex', justifyContent: "end" }}>
            <ButtonNew
              buttonType="secondary"
              customStyle={{ width: "109px", height: "40px", border: "1px solid #475BD8", color: "#475BD8", borderRadius: "26px", color: "rgb(71, 91, 216)", fontSize: "12px", display: "flex", justifyContent: "center", boxShadow: "none", backgroundColor: "white" }}
              imageButton={Img}
              imageButtonHover={imgH}
              iconButton="btn-secondary-download-button"
              onClick={
                () => generateXLSXFile(ddRpsReportData, "xlsx")

              }
              label="XLSX"
            />
          </div>
          <Table
            customStyle={{ width: "100%" }}
            data={ddRpsReportData}
            columns={columns}
          />
        </div>

      ) : pageType == "Pre-Payment" ? ( 

        <>
        {prePaymentData?.status == 'PENDING' ? 
            (<div style={{ margin: "24px" , display: "flex" , flexDirection: "column" , gap: "24px" }}>
              <div style={{display:"flex", justifyContent:"center",alignItems:"center",height:"50vh", width:"100%",
                      backgroundColor:"rgba(249, 249, 249, 1)",fontFamily:"Montserrat-SemiBold", fontSize:"18px",
                      color:"rgba(28, 28, 28, 1)"}}>
                      Your Prepayment request is taken.
              </div>
            </div>) : 
        prePaymentData?.status == 'DONE' ? 
            ( <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', marginTop: '12%' }}>
                <div style={{ textAlign: 'center' }}>
                  <div>
                    <img src={RightCircle} alt="RightCircle icon" />
                  </div>
                  <div style={{ fontFamily: 'Montserrat-SemiBold', fontSize: '18px', color: '#161719', marginTop: '15px' }}>
                    Prepayment request is processed.  <br/> Drawdown is closed now.
                  </div>
                </div>
              </div>): 
            (<div style={{ padding: "8px 20px 20px 20px", maxWidth: "100%" }}>
            <div style={{marginBottom:"20px", color:"rgba(20, 21, 25, 1)", fontFamily:'Montserrat-SemiBold',fontSize:"16px"}}>
              Pre-Payment Details
            </div>
            <div style={{display:"grid",gridTemplateColumns:"repeat(3,1fr)", gap:"10px"}}>
          <InputBox
                id={"Request Id"}
                label="Request Id"
                initialValue={prePaymentData?.data?.loan_id}
                isDisabled={true}
                customClass = {{
                  minWidth: "100%",
                  marginBottom:'2rem',
                  height:'56px',
                  fontFamily:'Montserrat-Regular',
                }}
                customInputClass={{
                  minWidth:'none',
                  maxWidth:'none',
                  width:'100%',
                }}
                type="text"
                />
                 <InputBox
                id={"Usage ID"}
                label="Usage ID"
                initialValue={prePaymentData?.data?.usage_id}
                isDisabled={true}
                isRequired={true}
                customClass = {{
                  minWidth: "100%",
                  marginBottom:'2rem',
                  height:'56px',
                  fontFamily:'Montserrat-Regular',
                }}
                customInputClass={{
                  minWidth:'none',
                  maxWidth:'none',
                  width:'100%',
                }}
                type="text"
                />
                 <InputBox
                id={"Invoice Number"}
                label="Invoice Number"
                initialValue={prePaymentData?.data?.invoice_number}
                isRequired={true}
                isDisabled={true}
                customClass = {{
                  minWidth: "100%",
                  marginBottom:'2rem',
                  height:'56px',
                  fontFamily:'Montserrat-Regular',
                }}
                customInputClass={{
                  minWidth:'none',
                  maxWidth:'none',
                  width:'100%',
                }}
                type="text"
                />
            </div>
            <div style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)", gap:"10px"}}>
               <CustomDatePicker   customInputClass={{height: "56px", maxWidth: "100%",  width: "100%"}} 
                              label="PRE-PAYMENT DATE" format={"DD-MM-YYYY"} 
                              customStartDate={disbusrmentDate}
                              customEndDate={dueDate}
                               isRequired={true}
                               startingDate={1960} endingDate={2050}
                               isBoxType={"icon"} 
                               initialValue={prePaymentDate ? moment(prePaymentDate).format('DD-MM-YYYY') :null}
                               onDateChange={date =>
                               changeDateSelected(date,'PREPAYMENT')
                               
                              } 
      
                              />
                 <InputBox
                id={"PRE-PAYMENT AMOUNT"}
                label={"PRE-PAYMENT AMOUNT"}
                initialValue={prePaymentAmt}
                isRequired={true}
                isDisabled={true}
                onClick={(e) => {
                      setPrePaymentAmt(e.value);
                      handleIsDisabled();
                    }
                  }
                customClass = {{
                  minWidth: "100%",
                  marginBottom:'2rem',
                  height:'56px',
                  fontFamily:'Montserrat-Regular',
                }}
                customInputClass={{
                  minWidth:'none',
                  maxWidth:'none',
                  width:'100%',
                }}
                type="number"
                />
            </div>
            <div style={{display:"grid",gridTemplateColumns:"repeat(3,1fr)", gap:"10px"}}>
               <InputBox
                id={"TXN Reference"}
                label="TXN Reference"
                isRequired={true}
                initialValue={tXNRef}
                type="text"
                isDisabled={false}
                onClick={(e) => {
                      setTXNRef(e.value);
                      handleIsDisabled();
                    }
                  }
                customClass = {{
                  minWidth: "100%",
                  marginBottom:'2rem',
                  height:'56px',
                  fontFamily:'Montserrat-Regular',
                }}
                customInputClass={{
                  minWidth:'none',
                  maxWidth:'none',
                  width:'100%',
                }}
                />
                 <InputBox
                id={"UTR Number"}
                label="UTR Number"
                isRequired={true}
                initialValue={uTRNumber}
                type="text"
                isDisabled={false}
                onClick={(e) => {
                      setUTRNumber(e.value);
                      handleIsDisabled();
                    }
                  }
                customClass = {{
                  minWidth: "100%",
                  marginBottom:'2rem',
                  height:'56px',
                  fontFamily:'Montserrat-Regular',
                }}
                customInputClass={{
                  minWidth:'none',
                  maxWidth:'none',
                  width:'100%',
                }}
                />
               <CustomDatePicker   customInputClass={{height: "56px", width: "100%", maxWidth: "100%"}} 
                               disableFuture={true}  label="UTR Date Time Stamp" format={"DD-MM-YYYY"} 
                               startingDate={1960} endingDate={2050}
                               initialValue={prePaymentDate ? moment(prePaymentDate).format('DD-MM-YYYY') :null}
                               isRequired={true}
                               isDisabled={true}
                               isBoxType={"icon"} 
                              />
            </div>
            <div style={{display:"grid",gridTemplateColumns:"repeat(3,1fr)", gap:"10px"}}>
            <CustomDatePicker   customInputClass={{height: "56px", width: "100%", maxWidth: "100%"}} 
                            label="TXN Reference Date Time" format={"DD-MM-YYYY"} 
                            startingDate={1960} endingDate={2050}
                              initialValue={tXNRefDate}
                            isBoxType={"icon"} 
                           onDateChange={date =>{
                            handleIsDisabled();
                            changeDateSelected(date, 'TXN');
                           }
                          } 
      
                          />
              <InputBox
            id={"Payment Mode"}
            label="Payment Mode"
            initialValue={paymentMode}
            isRequired={true}
            isDisabled={false}
            onClick={(e) => {
                  setPaymentMode(e.value);
                  handleIsDisabled();
                }
              }
            customClass = {{
              minWidth: "100%",
              marginBottom:'2rem',
              height:'56px',
              fontFamily:'Montserrat-Regular',
            }}
            customInputClass={{
              minWidth:'none',
              maxWidth:'none',
              width:'100%',
            }}
            type="text"
            />
            <InputBox
            id={"TDS Amount"}
            label="TDS Amount (Optional)"
            type="number"
            initialValue={tDSAmount}
            isDisabled={false}
            onClick={(e) => {
                  setTDSAmount(e.value);
                }
              }
            customClass = {{
              minWidth: "100%",
              marginBottom:'2rem',
              height:'56px',
              fontFamily:'Montserrat-Regular',
            }}
            customInputClass={{
              minWidth:'none',
              maxWidth:'none',
              width:'100%',
            }}
            />
            </div>
            <div style={{float:"right"}}>
              <Buttoned label="Pre-pay" 
              buttonType={isDisabled ? 'disabled':'primary'}
                customStyle={{
                  height: "50px",
                  borderRadius: ".625rem",
                  color: "white",
                  fontWeight: "600",
                  border: isDisabled ? 'none':"1px solid #475BD8",
                  boxShadow: "none",
                  backgroundColor: isDisabled ? '#c1c1c1':'#475BD8',
                  fontSize:"16px"
                }}
                isDisabled={(prePaymentDate !== '' && prePaymentAmt !== '' && tXNRef !== '' && uTRNumber !== '' && tXNRefDate !== ''&& paymentMode !== '' ) ? false : true}
                onClick={handleNextStage}
                  />
            </div>
          </div>)}
        
    

    </>

) :
       null
      }
      {isLoading && <Preloader />}
    </>
  );
}
