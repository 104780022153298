import * as React from 'react';
import { bookLoansFormJsonFields } from './bookLoansFormJson';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { storedList, saveToStorage } from '../../../util/localstorage';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button';
import { getLapLoanDocumentsWatcher } from '../../actions/bookLoan.action';
import 'react-sdk/dist/styles/_fonts.scss';
import CustomDatePicker from 'react-sdk/dist/components/DatePicker/DatePicker';
import { validateData } from '../../utils/validation';
import { keyValuePairs, verifyDateAfter1800 } from '../../../util/helper';
import { patchLapDetailsWatcher, patchLapDocDeleteWatcher, patchLapSaveAsDraftDetailsWatcher } from '../../actions/lap.action';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import { getLapCompaniesWatcher } from '../../actions/lap.action';
import { getLapProductByCompanyIDWatcher } from '../../actions/lap.action';
import getSectionStatus from './GetLeadSectionStatus/GetLeadSectionStatus';
import { SectionData } from '../../config/sectionData';
import { getLeadStatusWatcher } from '../../actions/lead.action';
import AadharVerifyInputbox from './reusableComponents/aadharVerifyInputbox';
import { useHistory } from 'react-router-dom';
import getSubSectionRemarks from './GetLeadSectionStatus/GetLeadSubSectionRemarks';
import './bookLoans.style.css';
const sectionStatus = ['deviation', 'approved', 'rejected', 'failed'];
import { KYCDocumentPANTittle, KYCDocumentSelfieTittle, KYCDocumentPanXMLTittle, KYCAadharFrontTitle, KYCAadharBackTitle, AadharXML } from './uploadKycData';
import UploadFileInput from '../../components/uploadFileInput/UploadFileInput';
import { States, Cities } from '../../../constants/country-state-city-data';
import moment from 'moment';
import { createLoanIDWatcher } from './../../actions/bookLoan.action';
import { documentCode } from '../../config/docCode';
import { REACT_APP_UPPERAGELIMIT_LAP, REACT_APP_AGE_LAP } from 'lap/helpers/constants';
import SlidingAlert from '../../components/SlidingAlert/SlidingAlert';
import { getSaveAsDraftButton } from 'lap/util/resuableJsx/reusableJsx';
import { LeadNewStatus } from '../../config/LeadStatus';

const BOOK_LOAN_OBJECT = keyValuePairs(bookLoansFormJsonFields(), 'field');
const user = storedList('user');
const BOOK_LOAN_FORM_JSON = bookLoansFormJsonFields();
const sectionName = 'primary-applicants';
const fetchObjectFieldsByDept = (deptName) => {
  const matchingObjects = [];
  for (let object of BOOK_LOAN_FORM_JSON) {
    if (object.dept === deptName) {
      matchingObjects.push(object.field);
    }
  }
  return matchingObjects;
};

const disabledFields = {
  view: ['company_id', 'product_id', 'loan_app_id', 'partner_borrower_id', 'partner_loan_app_id', 'requested_loan_amount', 'tenure_in_months', 'interest_rate', 'pan_value', ...fetchObjectFieldsByDept('Applicant Details'), ...fetchObjectFieldsByDept('KYC Document PAN'), ...fetchObjectFieldsByDept('Current Address'), ...fetchObjectFieldsByDept('Permanent Address')],
  edit: ['company_id', 'product_id', 'partner_borrower_id', 'partner_loan_app_id', 'loan_app_id', 'pan_value', ...fetchObjectFieldsByDept('Loan Requirement'), ...fetchObjectFieldsByDept('Applicant Details'), ...fetchObjectFieldsByDept('KYC Document PAN'), ...fetchObjectFieldsByDept('Current Address'), ...fetchObjectFieldsByDept('Permanent Address')],
};

const setDocumentView = (TitleOb, documents) => {
  return TitleOb.map((givenObj) => {
    const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
    if (matchingObj) {
      return {
        ...givenObj,
        s3_url: matchingObj.file_url,
        doc: matchingObj,
      };
    } else {
      return givenObj;
    }
  });
};

const checkDocStatus = (data) => {
  for (let ob of data) {
    if (!ob?.s3_url && ob.isRequired) {
      return false;
    }
  }
  return true;
};

export default function ApplicantDetailsForm(props) {
  const { setNavState, setNavIconPrefixState, loanAppId, setLoanAppId, applicantData, setApplicantData, navIconPrefixState, setLAPCompanyId, setLAPProductID, statusCheck, setStatusCheck, section_code, sub_section_code, documents, LAPCompanyId, LAPProductId, loanDetailsSubsectionStatus, leadStatus, setLeadStatus, setShouldFetch, loanDetailsStatus, entityDetailsNavItemShow, setEntityDetailsNavItemShow, setShouldFetchDocument } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLeadRejected, setIsLeadRejected] = useState(false);
  const [panRejectionStatus, setPanRejectionStatus] = useState(false);
  const [AadharValue, setAadharValue] = useState('');
  const [selfieItem, setSelfiItem] = useState(KYCDocumentSelfieTittle);
  const [panImageItem, setPanImage] = useState(KYCDocumentPANTittle);
  const [panXmlItem, setPanXml] = useState(KYCDocumentPanXMLTittle);
  const [aadharFrontItem, setAadharfront] = useState(KYCAadharFrontTitle);
  const [aadharBackItem, setAadharback] = useState(KYCAadharBackTitle);
  const [aadharfrontXmlItem, setAadharfrontXml] = useState(AadharXML);
  const [subSectionStatus, setSubsectionStatus] = useState('');
  const [entityTypeValue, setEntityTypeValue] = useState('');
  const [natureOfOwnership, setNatureOfOwnership] = useState('');
  const [saveAsDraft, setsaveAsDraft] = useState(false);
  const [isNameMismatch, setIsNameMisMatch] = useState(false);

  const fetchLeadDetails = () => {
    setSubsectionStatus(loanDetailsSubsectionStatus['100']?.applicant_okyc);
  };

  const fetchLeadStatus = () => {
    const payload = {
      loan_app_id: loanAppId,
      companyId: LAPCompanyId,
      productId: LAPProductId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLeadStatusWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setStatusObject(response.find((item) => item.section_code === 'primary'));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (loanAppId && LAPCompanyId && LAPProductId) {
      if (loanDetailsStatus && loanDetailsStatus['primary'] == 'rejected') {
        setIsLeadRejected(true);
      }
      fetchLeadStatus();
    }
  }, [loanAppId, LAPCompanyId, LAPProductId, leadStatus, loanDetailsStatus]);

  useEffect(() => {
    if (loanDetailsSubsectionStatus && loanDetailsSubsectionStatus['100']) {
      fetchLeadDetails();
      setPanRejectionStatus(loanDetailsSubsectionStatus['100']?.primary_pan === 'rejected' ? true : false);
    }
  }, [loanDetailsSubsectionStatus]);

  useEffect(() => {
    if (entityTypeValue) {
      if (entityTypeValue === 'Individual') {
        setEntityDetailsNavItemShow(true);
      } else {
        setEntityDetailsNavItemShow(false);
      }
    }
  }, [entityTypeValue]);

  useEffect(() => {
    if ((stateData?.borrower_id && documents) || props.type == 'view') {
      getLoanDocuments();
    }
  }, [documents]);

  const getLoanDocuments = () => {
    const payload = {
      loanAppID: loanAppId,
      companyId: LAPCompanyId,
      productId: LAPProductId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLapLoanDocumentsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        if (response) {
          let stateDoc = {
            applicant_image_value: false,
            aadhaar_front_image_value: false,
            aadhaar_back_image_value: false,
            pan_image_value: false,
            pan_xml_image_value: false,
            aadhaar_xml_image_value: false,
          };

          let data = setDocumentView(KYCDocumentSelfieTittle, response);
          setSelfiItem(data);
          stateDoc['applicant_image_value'] = checkDocStatus(data);

          data = setDocumentView(KYCDocumentPANTittle, response);
          setPanImage(data);
          stateDoc['pan_image_value'] = checkDocStatus(data);

          data = setDocumentView(KYCDocumentPanXMLTittle, response);
          setPanXml(data);
          stateDoc['pan_xml_image_value'] = checkDocStatus(data);

          data = setDocumentView(KYCAadharFrontTitle, response);
          setAadharfront(data);
          stateDoc['aadhaar_front_image_value'] = checkDocStatus(data);

          data = setDocumentView(KYCAadharBackTitle, response);
          setAadharback(data);
          stateDoc['aadhaar_back_image_value'] = checkDocStatus(data);

          data = setDocumentView(AadharXML, response);
          setAadharfrontXml(data);
          stateDoc['aadhaar_xml_image_value'] = checkDocStatus(data);

          for (let obj of response) {
            if (obj.code == '116') {
              setSelectedFileType((prevState) => ({
                ...prevState,
                ['Response XML/JSON']: false,
              }));
            }
            if (obj.code == '114') {
              setSelectedAadhaarFileType('Response XML/JSON');
            }
          }
          setDocumentStateData(stateDoc);
        }
      })
      .catch((error) => {
        console.log('this is error', error);
      });
  };

  let intervalId;
  const [statusObject, setStatusObject] = useState('');
  const [stateData, setStateData] = useState(
    { ...applicantData, string_vl_occupation_type: 'Self Employed' } ?? {
      string_vl_occupation_type: 'Self Employed',
    },
  );
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [company, setCompany] = useState([]);
  const [product, setProduct] = useState([]);
  const [companyID, setCompanyID] = useState();
  const [productID, setProductID] = useState();
  const [validationData, setValidationData] = useState({});
  const [selectedFileType, setSelectedFileType] = useState({ 'Image & PDF': true, 'Validation Doc': false });
  const [selectedAadhaarFileType, setSelectedAadhaarFileType] = useState({ 'Image & PDF': true, 'Validation Doc': false });
  const [paViewPerAddress, setPaViewPerAddress] = useState(true);
  const [states, setStatesState] = useState(States ?? []);
  const [city, setCityState] = useState([]);
  const [perCity, setPerCityState] = useState([]);
  const [newFile, setNewFile] = useState();
  const [panButtonState, setPanButtonState] = useState('button');
  const [panBorder, setPanBorder] = useState('border: 1px solid #BBBFCC');
  const [isPanValid, setIsPanValid] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [backgroundColorBlur, setBackgroundColorBlur] = useState(true);
  const [companyUser, setCompanyUser] = useState(false);
  const [rejectedState, setRejectedState] = useState(false);
  const [retryCall, setRetryCall] = useState('N');
  const [isFormDisabled, setIsFormDisabled] = useState(navIconPrefixState['Applicant Details'] == 'success' ? true : false);
  const [uploadSelectedFile, setUploadSelectedFile] = useState({
    aadhaar_front_image_value: '',
    aadhaar_back_image_value: '',
    applicant_image_value: '',
    pan_image_value: '',
    pan_xml_image_value: '',
    aadhaar_xml_image_value: '',
  });
  const [documentStateData, setDocumentStateData] = useState({
    applicant_image_value: false,
    aadhaar_front_image_value: false,
    aadhaar_back_image_value: false,
    pan_image_value: false,
    pan_xml_image_value: false,
    aadhaar_xml_image_value: false,
  });
  const [sectionStatusCheck, setSectionStatusCheck] = useState('');
  const [sectionStatusCheckForDraft, setSectionStatusCheckForDraft] = useState('');

  useEffect(() => {
    if (user && user._id) {
      dispatch(
        getLapCompaniesWatcher(
          user?._id,
          (result) => {
            let tempCompany = result?.company;
            let allCompany = [];
            let findTempCompanyArray = Array.isArray(tempCompany);
            findTempCompanyArray
              ? tempCompany.forEach((comp) => {
                  allCompany.push({
                    label: comp.name,
                    value: comp.name,
                    _id: comp._id ? comp._id : '',
                  });
                })
              : allCompany.push({
                  label: tempCompany.name,
                  value: tempCompany.name,
                  _id: tempCompany._id ? tempCompany._id : '',
                });
            setCompany(allCompany);
          },
          (error) => {
            console.log('company listing error ->', error);
          },
        ),
      );
    }
  }, [user]);

  useEffect(() => {
    if (user && user._id) {
      dispatch(
        getLapProductByCompanyIDWatcher(
          user?._id,
          (result) => {
            let tempProduct = result?.products;
            let allProduct = [];
            let findTempProductArray = Array.isArray(tempProduct);
            findTempProductArray
              ? tempProduct.forEach((prod) => {
                  if (prod.is_lap === 1) {
                    allProduct.push({
                      label: prod.name,
                      value: prod.name,
                      _id: prod._id ?? prod.id,
                    });
                  }
                })
              : tempProduct[is_lap] &&
                allProduct.push({
                  label: tempProduct.name,
                  value: tempProduct.name,
                  _id: tempProduct._id ?? prod.id,
                });
            setProduct(allProduct);
          },
          (error) => {
            console.log('Products listing error ->', error);
          },
        ),
      );
    }
  }, [companyID]);

  useEffect(() => {
    if ((props.type == 'edit' || props.type == 'view') && applicantData) {
      setStateData(applicantData);
      if (applicantData.loan_app_id) {
        setLoanAppId(applicantData.loan_app_id);
        setPaViewPerAddress(applicantData.address_same ? false : true);
        if (props?.type == 'view' || (leadStatus == LeadNewStatus.Pending && applicantData)) {
          setSelectedFileType({ 'Image & PDF': applicantData?.is_pan_pdf_upload ? true : false, 'Validation Doc': applicantData?.is_pan_pdf_upload ? false : true });
          setSelectedAadhaarFileType({ 'Image & PDF': applicantData?.is_aadhar_pdf_upload ? true : false, 'Validation Doc': applicantData?.is_aadhar_pdf_upload ? false : true });
        }
        if (loanDetailsSubsectionStatus && loanDetailsSubsectionStatus[100]) {
          if (loanDetailsSubsectionStatus[100]['primary_pan'] == 'approved') {
            setStatusCheck(true);
            setIsPanValid(true);
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            setPanBorder('1px solid green');
          } else if (loanDetailsSubsectionStatus[100]['primary_pan'] == 'in_review') {
            setStatusCheck(true);
            setIsPanValid(true);
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            setPanBorder('1px solid #f5781f');
          }
        }
      }
      if (company.length !== 0 && applicantData.company_id) {
        const data = company.find((item) => item._id === applicantData.company_id);
        dropDownChange(data, 'company_id');
      }
    } else {
      if (company.length !== 0 && user?.company_id) {
        const data = company.find((item) => item._id === user.company_id);
        dropDownChange(data, 'company_id');
        setCompanyUser(true);
      }
    }
  }, [props.type, company, applicantData, user]);

  useEffect(() => {
    if (product.length != 0) {
      if (props.type == 'edit' || props.type == 'view') {
        const data = product.find((item) => item._id === applicantData.product_id);
        dropDownChange(data, 'product_id');
      } else {
        const data = product[0];
        dropDownChange(data, 'product_id');
      }
    }
  }, [props.type, product]);

  const calculateAge = (birthMonth, birthDay, birthYear) => {
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth();
    var currentDay = currentDate.getDate();
    var calculatedAge = currentYear - birthYear;

    if (currentMonth < parseInt(birthMonth) - 1) {
      calculatedAge--;
    }
    if (parseInt(birthMonth) - 1 == currentMonth && currentDay < parseInt(birthDay)) {
      calculatedAge--;
    }
    return calculatedAge;
  };

  useEffect(() => {
    if (stateData['curr_addr_state'] || stateData['per_addr_state']) {
      setCurrCitiesData(Cities(stateData['curr_addr_state']));
    }
  }, [stateData['curr_addr_state'] || stateData['per_addr_state']]);

  useEffect(() => {
    let formValidated = false;
    let documentValidated = false;

    bookLoansFormJsonFields().map((item, idx) => {
      if (item.dept == 'Applicant Details' || item.dept == 'select_company' || item.dept == 'select_partner' || item.dept == 'Current Address' || item.dept == 'Loan Requirement' || item.dept == 'Permanent Address' || item.dept == 'Applicant KYC 1') {
        if (item.isOptional == false) {
          if (!stateData[`${item.type}_vl_${item.field}`]) {
            if (item.field != 'monthly_rent') {
              formValidated = true;
            }
          }
        }
      }
    });
    if (selectedFileType['Image & PDF'] && selectedAadhaarFileType['Image & PDF']) {
      if (documentStateData.applicant_image_value && documentStateData.aadhaar_front_image_value && documentStateData.pan_image_value) {
        documentValidated = true;
      }
    }
    if (selectedFileType['Validation Doc'] && selectedAadhaarFileType['Image & PDF']) {
      if (documentStateData.applicant_image_value && documentStateData.aadhaar_front_image_value && documentStateData.pan_xml_image_value) {
        documentValidated = true;
      }
    }
    if (selectedFileType['Validation Doc'] && selectedAadhaarFileType['Validation Doc']) {
      if (documentStateData.applicant_image_value && documentStateData.aadhaar_xml_image_value && documentStateData.pan_xml_image_value) {
        documentValidated = true;
      }
    }
    if (selectedFileType['Image & PDF'] && selectedAadhaarFileType['Validation Doc']) {
      if (documentStateData.applicant_image_value && documentStateData.aadhaar_xml_image_value && documentStateData.pan_image_value) {
        documentValidated = true;
      }
    }
    if (!formValidated && documentValidated) {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
  }, [stateData, documentStateData]);

  const handleGetPerCities = async (value) => {
    setPerCityState(Cities(value));
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
  };

  useEffect(() => {
    if (stateData['curr_addr_state'] || stateData['per_addr_state']) {
      setCurrCitiesData(Cities(stateData['curr_addr_state']));
    }
  }, [stateData['curr_addr_state'] || stateData['per_addr_state']]);

  const handleGetCities = async (value) => {
    setCityState(Cities(value));
  };
  const inputBoxCss = {
    marginTop: '8px',
    maxHeight: '500px',
    zIndex: 1,
    width: '105%',
  };
  const headingCss = {
    color: 'var(--neutrals-neutral-100, #161719)',
    fontFamily: 'Montserrat-semibold',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '150%',
    marginBottom: '24px',
    marginTop: '40px',
  };
  const radioCss = {
    color: 'var(--neutrals-neutral-100, #161719)',
    fontFamily: 'Montserrat-Regular',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    marginTop: '5px',
  };
  const radioInputCss = {
    accentColor: panButtonState === 'icon' ? '#134CDE' : 'var(--neutrals-neutral-60, #767888)',
    marginRight: '8px',
    marginBottom: '4px',
    height: '20px',
    width: '20px',
    verticalAlign: 'middle',
  };
  const subHeadingCss = {
    color: 'var(--neutrals-neutral-100, #161719)',
    fontFamily: 'Montserrat-semibold',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '150%',
    marginBottom: '24px',
    marginTop: '30px',
  };

  const handleDataFromChild = (id, data) => {
    console.log(`Received data for item with id ${id}:`, data);
  };

  const handleRadioButton = ({ event, updateSelectedFileType, selectedFileType, docCode, docNames }) => {
    if (!selectedFileType[event.target.value]) {
      let isDocumentAvailable = docNames.find((item) => documentStateData[item]);
      if (isDocumentAvailable) {
        let updatedDocStateData = {};
        docNames.forEach((item) => {
          updatedDocStateData = { ...updatedDocStateData, [item]: false };
        });
        deleteFile([...docCode]);
        setDocumentStateData({ ...documentStateData, ...updatedDocStateData });
      }
    }
    if (event.target.value === 'Image & PDF' && !selectedFileType['Image & PDF']) {
      updateSelectedFileType((prevState) => ({
        ...prevState,
        ['Image & PDF']: true,
        ['Validation Doc']: false,
      }));
    } else if (event.target.value === 'Validation Doc' && !selectedFileType['Validation Doc']) {
      updateSelectedFileType((prevState) => ({
        ...prevState,
        ['Image & PDF']: false,
        ['Validation Doc']: true,
      }));
    }
  };

  useEffect(() => {
    if (!paViewPerAddress) {
      bookLoansFormJsonFields().map((item, idx) => {
        if (item.dept == 'Permanent Address') {
          let addressField = `${item.type}_vl_${item.field}`;
          setStateData((prevState) => ({
            ...prevState,
            [addressField]: '',
          }));
          let field = item.field.replace('per', 'curr');
          const value = stateData[`${item.type}_vl_${field}`];
          if (value) {
            let perField = `${item.type}_vl_${item.field}`;
            let isValid = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
            setStateData((prevState) => ({
              ...prevState,
              [perField]: value,
            }));
            setValidationData((prevState) => ({
              ...prevState,
              [`${perField}State`]: !isValid ? 'has-danger' : '',
            }));
          }
        }
      });
    } else {
      if (!applicantData) {
        bookLoansFormJsonFields().map((item, idx) => {
          if (item.dept == 'Permanent Address') {
            let field = `${item.type}_vl_${item.field}`;
            setStateData((prevState) => ({
              ...prevState,
              [field]: '',
            }));
          }
        });
      }
    }
  }, [!paViewPerAddress]);

  useEffect(() => {
    if (stateData['date_vl_date_of_birth']) {
      const dob = stateData['date_vl_date_of_birth'];
      const yyyyMmDdRegExp = /^\d{4}-\d{2}-\d{2}$/.test(dob);
      if (yyyyMmDdRegExp) {
        const age = calculateAge(dob.substring(5, 7), dob.substring(8, 10), dob.substring(0, 4));
        setStateData((prevState) => ({
          ...prevState,
          number_vl_age: age,
        }));
        if (props.type == 'view' || props.type == 'edit') {
          setApplicantData((prevState) => ({
            ...prevState,
            number_vl_age: age,
          }));
        }
        let field = `number_vl_age`;
        let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), age);
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid ? 'has-danger' : '',
        }));
      } else {
        setStateData((prevState) => ({
          ...prevState,
          number_vl_age: ' ',
        }));
      }
    }
  }, [stateData['date_vl_date_of_birth']]);

  const change = (e, type, name) => {
    const buttonText = e.target?.textContent;
    const valued = buttonText;
    if (valued === 'Send OTP') {
    } else if (valued === 'Verify') {
      createLoanAppID();
    } else {
      let values = e.value;
      if (name === 'pan_value') {
        values = e.value.toUpperCase();
        if (values?.length >= 10) {
          values = values.substring(0, 10);
        }
      } else if (name === 'aadhaar_value') {
        values = e.value;
        if (values?.length >= 12) {
          values = values.substring(0, 12);
        }
      } else if (name === 'mobile_number') {
        values = e.value;
        if (values?.length >= 10) {
          values = values.substring(0, 10);
        }
      } else if (name === 'curr_addr_pincode' || name === 'per_addr_pincode') {
        values = e.value;
        if (values?.length >= 6) {
          values = values.substring(0, 6);
        }
      } else if (name === 'curr_addr_ln1' || name === 'curr_addr_ln2' || name === 'per_addr_ln1' || name === 'per_addr_ln2') {
        values = e.value;
        if (values?.length >= 65) {
          values = values.substring(0, 65);
        }
      } else if (name === 'requested_int_rate') {
        let rawValue = e.value;
        if (rawValue < 0) {
          values = 0;
        } else if (rawValue > 100) {
          values = 100;
        } else {
          values = rawValue;
        }
      } else if (name === 'entity_type') {
        let rawValue = e.value;
        if (rawValue) {
          setEntityTypeValue(rawValue);
        }
      } else if (name === 'nature_of_ownership') {
        let rawValue = e.value;
        if (rawValue) {
          setNatureOfOwnership(rawValue);
        }
      } else {
        values = e.value;
      }

      const value = values;
      let field = `${type}_vl_${name}`;
      let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), value);

      if (field.indexOf('curr') != -1 && !paViewPerAddress) {
        const perField = field.replace('curr', 'per');
        let isValidData = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
        setStateData((prevState) => ({
          ...prevState,
          [perField]: value,
        }));
        setValidationData((prevState) => ({
          ...prevState,
          [`${perField}State`]: !isValidData ? 'has-danger' : '',
        }));
      }
      setStateData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
      if (name === 'mobile_number') {
        const pattern = /^[5-9]\d*$/;
        const decimalFindPattern = /\.\d*[^]+$/;
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid && value && decimalFindPattern.test(value) ? 'has-decimalErrorHelperTextRequired' : !isValid && value && !pattern.test(value) ? 'has-errorHelperTextRequired' : !isValid && value && value.length >= 1 && pattern.test(value) ? 'has-errorHelperText1Required' : !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'curr_addr_pincode' || name === 'per_addr_pincode') {
        const decimalFindPattern = /\.\d*[^]+$/;
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid && value && decimalFindPattern.test(value) ? 'has-decimalErrorHelperTextRequired' : !isValid && value.length >= 1 && value.length < 6 ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'tenure_in_months') {
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: value !== '12 months' && value !== '24 months' && value !== '' ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
        }));
      } else {
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid && value.length >= 1 ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
        }));
      }
    }
  };

  const dropDownChange = (value, name) => {
    if (value) {
      setStateData((prevState) => ({
        ...prevState,
        [`string_vl_${name}`]: value?.value ?? '',
      }));

      if (name === 'company_id') {
        setCompanyID(value._id ? value._id : '');
        setProduct([]);
        saveToStorage('lap_company_id', value._id ? value._id : '');
        setLAPCompanyId(value._id ? value._id : '');
      }

      if (name === 'product_id') {
        setProductID(value._id ? value._id : '');
        saveToStorage('lap_product_id', value._id ? value._id : '');
        setLAPProductID(value._id ? value._id : '');
      }
    }

    if ((name === 'state' || name === 'curr_addr_state') && value !== undefined && value !== null) {
      handleGetCities(value);
      if (stateData.string_vl_curr_addr_city) {
        setStateData((prevState) => ({
          ...prevState,
          [`string_vl_curr_addr_city`]: '',
        }));
      }
    }

    if (name === 'purpose_of_loan' && value !== undefined && value !== null) {
      if (stateData.string_vl_purpose_of_loan) {
        setStateData((prevState) => ({
          ...prevState,
          string_vl_purpose_of_loan: value.label,
        }));
      }
    }

    if (name === 'per_addr_state' && value !== undefined && value !== null) {
      handleGetPerCities(value);
      if (stateData.string_vl_per_addr_city) {
        setStateData((prevState) => ({
          ...prevState,
          [`string_vl_per_addr_city`]: '',
        }));
      }
    }

    if (name.indexOf('curr') != -1 && !paViewPerAddress) {
      const field = `string_vl_${name}`;
      const perField = field.replace('curr', 'per');
      let isValidData = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value?.value);
      setStateData((prevState) => ({
        ...prevState,
        [perField]: value?.value,
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`${perField}State`]: !isValidData ? 'has-danger' : '',
      }));
    }

    const validatingType = name === 'pincode' ? 'pincode' : 'string';
    const isValid = validateData(validatingType, value?.value);

    setValidationData((prevState) => ({
      ...prevState,
      [`${validatingType}_vl_${name}State`]: !isValid ? 'has-danger' : '',
    }));
  };

  const changeDateSelected = (value, name) => {
    const pastYear = new Date(new Date().setFullYear(new Date().getFullYear()));
    const selectedDate = moment(pastYear).format('YYYY-MM-DD');
    if (selectedDate > value) {
      const date = verifyDateAfter1800(moment(value).format('YYYY-MM-DD')) ? moment(value).format('YYYY-MM-DD') : value;
      const isValid = validateData(name.substring(0, name.indexOf('_vl_')).toLowerCase(), date);
      setStateData((prevState) => ({
        ...prevState,
        [name]: date,
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`${name}State`]: !isValid ? 'has-danger' : '',
      }));
    } else {
      const isValid = false;
      setStateData((prevState) => ({
        ...prevState,
        number_vl_age: ' ',
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`${name}State`]: !isValid ? 'has-danger' : '',
      }));
    }
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const fetchObjectsByDept = (deptName) => {
    const matchingObjects = [];
    for (const object of bookLoansFormJsonFields()) {
      if (object.dept === deptName) {
        matchingObjects.push(object);
      }
    }
    return matchingObjects;
  };

  const createLoanAppID = () => {
    let formValidated = true;

    bookLoansFormJsonFields().map((item, idx) => {
      if ((item.dept == 'Applicant Details' || item.dept == 'Loan Requirement' || item.dept == 'select_company' || item.dept == 'Current Address' || item.dept == 'Permanent Address' || item.field == 'pan_value') && item.isOptional == false) {
        const field = `${item.type}_vl_${item.field}`;
        if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: validationData[`${item.type}_vl_${item.field}State`] ?? 'has-danger',
          }));
          formValidated = false;
        }
        if (!stateData[field]) {
          if (field != 'monthlyincome_vl_monthly_rent') {
            setValidationData((prevState) => ({
              ...prevState,
              [`${field}State`]: validationData[`${item.type}_vl_${item.field}State`] ?? 'has-danger',
            }));
            formValidated = false;
          }
        }
      }
    });
    if (formValidated) {
      let obj_purpose_of_loan = BOOK_LOAN_OBJECT['purpose_of_loan']?.options?.find((item) => item.label === stateData.string_vl_purpose_of_loan);
      if (!obj_purpose_of_loan) {
        return showAlert('Please select purpose of loan', 'error');
      }
      const purpose_of_loan_data = stateData.string_vl_purpose_of_loan.replace(/ /g, '_').toLowerCase();
      let data = {
        loan_app_id: loanAppId,
        product_id: productID,
        company_id: companyID,
        address_same: paViewPerAddress ? 0 : 1,
        loan_amount: stateData.loanamount_vl_requested_loan_amt,
        partner_loan_app_id: stateData.string_vl_partner_loan_app_id,
        partner_borrower_id: stateData.string_vl_partner_borrower_id,
        loan_tenure: stateData.string_vl_tenure_in_months,
        loan_interest_rate: stateData.number_vl_requested_int_rate,
        appl_pan: stateData.pan_vl_pan_value,
        dob: stateData.date_vl_date_of_birth,
        age: stateData.number_vl_age,
        first_name: stateData.formatname_vl_first_name,
        middle_name: stateData.formatname_vl_middle_name,
        last_name: stateData.formatname_vl_last_name,
        email_id: stateData.email_vl_email,
        father_fname: stateData.formatname_vl_father_full_name,
        type_of_addr: 'Permanent',
        resi_addr_ln1: stateData.string_vl_curr_addr_ln1,
        resi_addr_ln2: stateData.string_vl_curr_addr_ln2,
        city: stateData.string_vl_curr_addr_city,
        state: stateData.string_vl_curr_addr_state,
        pincode: stateData.pincode_vl_curr_addr_pincode,
        per_addr_ln1: stateData.string_vl_per_addr_ln1,
        per_addr_ln2: stateData.string_vl_per_addr_ln2,
        per_city: stateData.string_vl_per_addr_city,
        per_state: stateData.string_vl_per_addr_state,
        per_pincode: stateData.pincode_vl_per_addr_pincode,
        appl_phone: stateData.mobile_vl_mobile_number,
        gender: stateData.string_vl_gender,
        sub_section_code: SectionData.primary.primary_pan.sub_section_code,
        section_code: SectionData.primary.section_code,
        section_sequence_no: SectionData.primary.section_sequence_no,
        section_name: SectionData.primary.section_name,
        sub_section_name: SectionData.primary.primary_pan.sub_section_name,
        sub_section_sequence_no: SectionData.primary.primary_pan.sub_section_sequence_no,
        purpose_of_loan: purpose_of_loan_data,
        type_of_loan: stateData.string_vl_type_of_loan,
        occupation_type: stateData.string_vl_occupation_type,
        monthly_rent: stateData.monthlyincome_vl_monthly_rent,
        house_ownership: stateData.string_vl_house_ownership,
        nature_of_ownership: stateData.string_vl_nature_of_ownership,
        occupancy_type: stateData.string_vl_per_addr_occupancy,
        no_of_years_current_addr: stateData.string_vl_no_of_years_curr_addr,
        no_of_years_permanent_addr: stateData.string_vl_no_of_years_per_addr,
        retry_name_mismatch: retryCall,
        entity_details: {
          entity_type: stateData.string_vl_entity_type,
        },
      };
      setPanButtonState('loader');
      new Promise((resolve, reject) => {
        dispatch(createLoanIDWatcher(data, resolve, reject));
      })
        .then((response) => {
          setLoanAppId(response.data.loan_app_id);
          setStatusCheckApi(response.data.loan_app_id, SectionData.primary.section_code, SectionData.primary.primary_pan.sub_section_code, dispatch);
        })
        .catch((error) => {
          if (error?.response?.data?.name_mismatch == 'Yes') {
            setRejectedState(true);
            setIsNameMisMatch(true);
            setPanButtonState('button');
            setRetryCall('Y');
            setLoanAppId(error?.response?.data?.loan_app_id);
            showAlert(error?.response?.data?.message, 'error');
          } else {
            showAlert(error?.response?.data?.message || 'Something went wrong', 'error');
            setPanButtonState('button');
          }
        });
    } else {
      showAlert('Kindly check for errors in fields', 'error');
    }
  };

  const dropDownOptions = (row) => {
    switch (row.field) {
      case 'curr_addr_state':
      case 'per_addr_state':
        return states;
      case 'curr_addr_city':
        return city;
      case 'per_addr_city':
        return perCity;
      case 'company_id':
        return company;
      case 'product_id':
        return product;
      default:
        return row.options;
    }
  };

  const rejectionEnabled = (row) => {
    if (['first_name', 'middle_name', 'last_name', 'pan_value'].includes(row?.field) && panRejectionStatus) {
      return true;
    }
    return false;
  };

  const renderDropDownSelectInputBox = (row) => {
    return (
      <>
        <InputBox
          id={row.field}
          label={row.title}
          isDrawdown={panButtonState === 'icon' ? false : true}
          initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
          isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || row.field === 'occupation_type' || (row.dept == 'Permanent Address' && !paViewPerAddress && row.field != 'house_ownership' && row.field != 'per_addr_occupancy') || (props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value') || rejectedState ? true : false}
          customDropdownClass={inputBoxCss}
          customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
          }}
          customInputClass={{
            marginTop: '0px',
            minWidth: '100%',
            backgroundColor: '#fff',
          }}
          onClick={(event) => change(event, row.type, row.field)}
          options={row.isDrawdown ? row.options : []}
          error={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
              : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
          }
          helperText={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                ? row.validationMsg
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                ? row.errorHelperTextRequired
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                ? row.errorHelperText1Required
                : ''
              : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
          }
        />
      </>
    );
  };

  const renderIntegerInputBox = (row) => {
    return (
      <>
        <InputBox
          id={row.field}
          label={row.title}
          type={'number'}
          isDrawdown={false}
          initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
          onClick={(event) => change(event, row.type, row.field)}
          isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || (props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value') || rejectedState ? true : false}
          customDropdownClass={inputBoxCss}
          customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
          }}
          customInputClass={{
            marginTop: '0px',
            minWidth: '100%',
            backgroundColor: '#fff',
          }}
          error={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
              : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
          }
          helperText={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                ? row.validationMsg
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                ? row.errorHelperTextRequired
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                ? row.errorHelperText1Required
                : ''
              : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
          }
        />
      </>
    );
  };

  const renderStringInputBox = (row) => {
    return (
      <>
        <InputBox
          id={row.field}
          label={row.title}
          isDrawdown={row.isDrawdown ? row.isDrawdown : false}
          initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
          onClick={(event) => change(event, row.type, row.field)}
          isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || (props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value') || (rejectedState && row.field === 'father_full_name') ? true : false}
          customDropdownClass={inputBoxCss}
          customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
          }}
          customInputClass={{
            marginTop: '0px',
            minWidth: '100%',
            backgroundColor: '#fff',
          }}
          error={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
              : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
          }
          helperText={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                ? row.validationMsg
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                ? row.errorHelperTextRequired
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                ? row.errorHelperText1Required
                : ''
              : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
          }
        />
      </>
    );
  };

  const checkIsFieldReadOnly = (item) => {
    const disableFields = ['pan_value'];
    if (isNameMismatch && disableFields?.includes(item?.field)) return true;
    return false;
  };

  const renderFields = (dept) => {
    const deptArray = fetchObjectsByDept(dept);
    return (
      <div
        style={{
          display: 'grid',
          rowGap: '28px',
          gridTemplateColumns: '32.8% 32.8% 32.8%',
          columnGap: '1%',
          width: '98%',
        }}
      >
        {deptArray &&
          deptArray.map((row, index) => {
            return (
              <>
                {row.field === 'curr_addr_state' || row.field === 'curr_addr_city' || row.field === 'per_addr_state' || row.field === 'per_addr_city' || row.field === 'curr_addr_sub_area' || row.field === 'per_addr_sub_area' || row.field === 'purpose_of_loan' || row.field === 'type_of_loan' || row.field === 'company_id' ? (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      isDrawdown={disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? false : row.dept == 'Current Address' && panButtonState === 'icon' ? false : row.dept == 'Permanent Address' ? (row.isDrawdown && !paViewPerAddress ? false : row.isDrawdown) : row.isDrawdown}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      onClick={(value) => dropDownChange(value, row.field)}
                      isDisabled={disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : (props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value') || rejectedState ? true : row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? true : row.field === 'company_id' && companyUser ? true : false}
                      customDropdownClass={row.field == 'gender' || row.field == 'purpose_of_loan' ? { ...inputBoxCss, width: '105%' } : inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      options={dropDownOptions(row)}
                      error={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                      }
                    />
                  </>
                ) : row.field === 'aadhaar_value' ? (
                  subSectionStatus === 'in_progress' && leadStatus === 'follow_up_kyc' ? (
                    <AadharVerifyInputbox row={row} stateData={stateData} props={props} disabledFields={disabledFields} loanAppId={loanAppId} viewPerAddress={paViewPerAddress} isFormDisabled={isFormDisabled} validationData={validationData} statusCheck={statusCheck} setValidationData={setValidationData} validateData={validateData} setStateData={setStateData} sectionCode={'primary'} sectionSequenceNumber={100} showAlert={showAlert} setShouldFetch={setShouldFetch} />
                  ) : (
                    <InputBox
                      id={row.field}
                      label={row.title}
                      type={(row.field == 'aadhaar_value' && props.type == 'view') || leadStatus != 'draft' || stateData[`${row.type}_vl_${row.field}`] ? 'text' : 'number'}
                      isDrawdown={false}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      onClick={(event) => change(event, row.type, row.field)}
                      isDisabled={panRejectionStatus || leadStatus != 'draft' || (row.field == 'aadhaar_value' && props.type == 'view') ? true : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && !isPanValid && row.field === 'aadhaar_value' ? true : row.field === 'age' || (!paViewPerAddress && row.dept == 'Permanent Address') || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      error={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                      }
                    />
                  )
                ) : row.field === 'requested_loan_amount' || row.field === 'curr_addr_pincode' || row.field === 'per_addr_pincode' || row.field === 'mobile_number' || row.field === 'aadhaar_value' ? (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      type={'number'}
                      isDrawdown={false}
                      initialValue={row.field === 'aadhaar_value' ? null : stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      isBoxType={row.field === 'aadhaar_value' ? 'button' : null}
                      Buttonlabel={row.field === 'aadhaar_value' ? 'Send OTP' : null}
                      onClick={(event) => change(event, row.type, row.field)}
                      isDisabled={rejectionEnabled(row) ? false : row.field === 'aadhaar_value' ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : (props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value') || rejectedState ? true : row.field === 'age' || (!paViewPerAddress && row.dept == 'Permanent Address') || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      error={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-decimalErrorHelperTextRequired' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' || validationData[`${row.type}_vl_${row.field}State`] === 'has-decimalErrorHelperTextRequired'
                      }
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-decimalErrorHelperTextRequired'
                            ? row.decimalErrorHelperTextRequired
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' &&
                            (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : validationData[`${row.type}_vl_${row.field}State`] === 'has-decimalErrorHelperTextRequired' ? row.decimalErrorHelperTextRequired : '')
                      }
                    />
                  </>
                ) : row.field === 'aadhaar_front_image_value' || row.field === 'aadhaar_back_image_value' ? (
                  selectedAadhaarFileType['Image & PDF'] && row.field === 'aadhaar_front_image_value' ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={true}
                        backgroundColorBlur={props.type && (props.type == 'view' || props.type === 'edit') ? false : backgroundColorBlur}
                        items={aadharFrontItem}
                        title="ad-f"
                        showAlert={showAlert}
                        isXML={false}
                        loanAppId={loanAppId}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: companyID, product_id: productID }}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        borrowerId={stateData?.borrower_id}
                        isChange={props.type && props.type == 'edit' && !sectionStatus.includes(statusObject?.section_status)}
                        type={props.type}
                      />
                    </>
                  ) : selectedAadhaarFileType['Image & PDF'] && row.field === 'aadhaar_back_image_value' ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={true}
                        backgroundColorBlur={props.type && (props.type == 'view' || props.type === 'edit') ? false : backgroundColorBlur}
                        items={aadharBackItem}
                        title=""
                        showAlert={showAlert}
                        isXML={false}
                        loanAppId={loanAppId}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: companyID, product_id: productID }}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        borrowerId={stateData?.borrower_id}
                        isChange={props.type && props.type == 'edit' && !sectionStatus.includes(statusObject?.section_status)}
                        type={props.type}
                      />
                    </>
                  ) : null
                ) : row.field === 'aadhaar_xml_image_value' ? (
                  selectedAadhaarFileType['Validation Doc'] ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={true}
                        backgroundColorBlur={props.type && (props.type == 'view' || props.type === 'edit') ? false : backgroundColorBlur}
                        items={aadharfrontXmlItem}
                        title=""
                        showAlert={showAlert}
                        isXML={true}
                        loanAppId={loanAppId}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: companyID, product_id: productID }}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        borrowerId={stateData?.borrower_id}
                        isChange={props.type && props.type == 'edit' && !sectionStatus.includes(statusObject?.section_status)}
                        type={props.type}
                      />
                    </>
                  ) : null
                ) : row.field === 'applicant_image_value' ? (
                  <>
                    <UploadFileInput
                      onDataCallback={handleDataFromChild}
                      backgroundColorChange={true}
                      backgroundColorBlur={props.type && (props.type == 'view' || props.type === 'edit') ? false : backgroundColorBlur}
                      items={selfieItem}
                      title=""
                      showAlert={showAlert}
                      isXML={false}
                      setDocumentStateData={setDocumentStateData}
                      loanAppId={loanAppId}
                      sectionName={sectionName}
                      data={{ company_id: companyID, product_id: productID }}
                      LAPCompanyId={LAPCompanyId}
                      LAPProductId={LAPProductId}
                      borrowerId={stateData?.borrower_id}
                      isChange={props.type && props.type == 'edit' && !sectionStatus.includes(statusObject?.section_status)}
                      type={props.type}
                    />
                  </>
                ) : row.field === 'pan_image_value' ? (
                  selectedFileType['Image & PDF'] ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={true}
                        backgroundColorBlur={props.type == 'view' || props.type === 'edit' ? false : backgroundColorBlur}
                        title=""
                        isSubmit={false}
                        showAlert={showAlert}
                        isXML={false}
                        setDocumentStateData={setDocumentStateData}
                        loanAppId={loanAppId}
                        sectionName={sectionName}
                        data={{ company_id: companyID, product_id: productID }}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        items={panImageItem}
                        borrowerId={stateData?.borrower_id}
                        isChange={props.type && props.type == 'edit' && !sectionStatus.includes(statusObject?.section_status)}
                        type={props.type}
                      />
                    </>
                  ) : null
                ) : row.field === 'pan_xml_image_value' ? (
                  selectedFileType['Validation Doc'] ? (
                    <>
                      <UploadFileInput
                        backgroundColorChange={true}
                        backgroundColorBlur={props.type && (props.type == 'view' || props.type === 'edit') ? false : backgroundColorBlur}
                        items={panXmlItem}
                        title=""
                        showAlert={showAlert}
                        isXML={true}
                        setDocumentStateData={setDocumentStateData}
                        loanAppId={loanAppId}
                        sectionName={sectionName}
                        data={{ company_id: companyID, product_id: productID }}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        borrowerId={stateData?.borrower_id}
                        isChange={props.type && props.type == 'edit' && !sectionStatus.includes(statusObject?.section_status)}
                        type={props.type}
                      />
                    </>
                  ) : null
                ) : row.type === 'pan' ? (
                  <>
                    <InputBox
                      isBoxType={panButtonState}
                      Buttonlabel={navIconPrefixState['Applicant Details'] == 'success' ? '' : 'Verify'}
                      id={row.field}
                      label={row.title}
                      isDrawdown={false}
                      initialValue={(stateData[`${row.type}_vl_${row.field}`] ?? '').toUpperCase()}
                      onClick={(event) => change(event, 'pan', row.field)}
                      isDisabled={rejectionEnabled(row) ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && loanAppId && isPanValid && row.field != 'aadhaar_value' ? true : row.field === 'age' || isFormDisabled ? true : false}
                      isReadOnly={checkIsFieldReadOnly(row)}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                        border: panBorder,
                        pointerEvents: panButtonState === 'icon' ? 'none' : '',
                      }}
                      customInputClass={{
                        maxWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      error={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                      }
                    />
                  </>
                ) : row.field === 'gender' ? (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      isDrawdown={panButtonState === 'icon' ? false : true}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      isDisabled={rejectionEnabled(row) ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : (props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value') || rejectedState ? true : row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      onClick={(event) => change(event, row.type, row.field)}
                      options={row.isDrawdown ? row.options : []}
                      error={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                      }
                    />
                  </>
                ) : row.type === 'date' ? (
                  <>
                    <CustomDatePicker
                      customInputClass={{ width: '100%', height: '56px' }}
                      label="Date of Birth"
                      format={'YYYY-MM-DD'}
                      disableFuture={true}
                      age={parseInt(REACT_APP_AGE_LAP)}
                      startingDate={REACT_APP_UPPERAGELIMIT_LAP ? parseInt(new Date().getFullYear()) - parseInt(REACT_APP_UPPERAGELIMIT_LAP) : 1964}
                      endingDate={parseInt(new Date().getFullYear()) - parseInt(REACT_APP_AGE_LAP)}
                      isBoxType={'icon'}
                      onDateChange={(date) => {
                        changeDateSelected(date, `${row.type}_vl_${row.field}`);
                      }}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ? moment(stateData[`${row.type}_vl_${row.field}`]).format('DD-MM-YYYY') : '' || null}
                      isDisabled={rejectionEnabled(row) ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : (props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value') || rejectedState ? true : isFormDisabled ? true : false}
                      error={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                      }
                    />
                  </>
                ) : row.field === 'product_id' ? (
                  <>
                    <InputBox
                      key={companyID}
                      id={row.field}
                      label={row.title}
                      isDrawdown={disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? false : row.isDrawdown}
                      initialValue={stateData[`${row.type}_vl_${row.field}`]}
                      onClick={(value) => dropDownChange(value, row.field)}
                      isDisabled={disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : (props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value') || rejectedState ? true : row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      options={dropDownOptions(row)}
                      error={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
                      }
                    />
                  </>
                ) : row.field === 'occupation_type' || row.field === 'entity_type' || row.field === 'nature_of_ownership' || row.field === 'no_of_years_curr_addr' || row.field === 'no_of_years_per_addr' || row.field === 'per_addr_occupancy' || row.field === 'house_ownership' || row.field == 'tenure_in_months' ? (
                  renderDropDownSelectInputBox(row)
                ) : row.field === 'monthly_rent' || row.field === 'requested_loan_amt' || row.field === 'requested_int_rate' ? (
                  row.field === 'monthly_rent' ? (
                    natureOfOwnership === 'Rented' ? (
                      renderIntegerInputBox(row)
                    ) : null
                  ) : (
                    renderIntegerInputBox(row)
                  )
                ) : row.field === 'first_name' || row.field === 'last_name' || row.field === 'middle_name' || row.field === 'father_full_name' || row.field == 'partner_loan_app_id' || row.field == 'partner_borrower_id' ? (
                  renderStringInputBox(row)
                ) : (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      isDrawdown={false}
                      initialValue={row.field == 'age' ? stateData[`${row.type}_vl_${row.field}`] ?? '{Auto populate}' : stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      onClick={(event) => change(event, row.type, row.field)}
                      isDisabled={rejectionEnabled(row) ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : (props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value') || rejectedState ? true : row.field === 'age' || (!paViewPerAddress && row.dept == 'Permanent Address') || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={
                        row.field === 'age'
                          ? {
                              height: '56px',
                              width: '100%',
                              maxWidth: '100%',
                              background: '#f4f4f4',
                            }
                          : {
                              height: '56px',
                              width: '100%',
                              maxWidth: '100%',
                            }
                      }
                      customInputClass={
                        row.field === 'age'
                          ? {
                              minWidth: '100%',
                              backgroundColor: '#f4f4f4',
                            }
                          : {
                              minWidth: '100%',
                              backgroundColor: '#fff',
                            }
                      }
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={
                        row.checked.toLowerCase() === 'true'
                          ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                            ? row.validationMsg
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                            ? row.errorHelperTextRequired
                            : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                            ? row.errorHelperText1Required
                            : ''
                          : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')
                      }
                    />
                  </>
                )}
              </>
            );
          })}
      </div>
    );
  };

  const deleteFile = async (documentCodes) => {
    const payload = {
      company_id: LAPCompanyId,
      product_id: LAPProductId,
      user: user,
      loanAppId: loanAppId,
      code: [...documentCodes],
      borrowerId: stateData?.borrower_id,
    };
    new Promise((resolve, reject) => {
      dispatch(patchLapDocDeleteWatcher(payload, resolve, reject));
    })
      .then((response) => {
        showAlert(response.message, 'success');
        if (setShouldFetchDocument) {
          setShouldFetchDocument((prev) => prev + 1);
        }
      })
      .catch((error) => {
        showAlert('Error deleting file', 'error');
      });
  };

  const handleCheckboxButton = (event) => {
    if (event.target.value == 'Image & PDF' && selectedFileType['Image & PDF'] && !selectedFileType['Validation Doc']) return;
    else if (event.target.value == 'Validation Doc' && !selectedFileType['Image & PDF'] && selectedFileType['Validation Doc']) return;
    if (selectedFileType[event.target.value]) {
      setSelectedFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: false,
      }));
    } else {
      setSelectedFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: true,
      }));
    }
    if (event.target.value === 'Validation Doc' && documentStateData.pan_xml_image_value) {
      deleteFile([documentCode.applicant_pan_XML]);
      setDocumentStateData({ ...documentStateData, pan_xml_image_value: false });
    } else if (event.target.value === 'Image & PDF' && documentStateData.pan_image_value) {
      deleteFile([documentCode.applicant_pan]);
      setDocumentStateData({ ...documentStateData, pan_image_value: false });
      setUploadSelectedFile((prevState) => ({
        ...prevState,
        pan_image_value: '',
      }));
    }
  };

  const handleSubmitSaveAsDraft = (event) => {
    if (isLeadRejected) {
      return showAlert('Your lead has been rejected', 'error');
    }

    let postData = {};
    let formValidated = true;

    bookLoansFormJsonFields().map((item, idx) => {
      if ((item.dept == 'Applicant Details' || item.dept == 'Loan Requirement' || item.dept == 'select_company' || item.dept == 'select_partner' || item.dept == 'Current Address' || item.dept == 'Permanent Address') && item.isOptional == false) {
        const field = `${item.type}_vl_${item.field}`;
        if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
        if (!stateData[field]) {
          if (field != 'monthlyincome_vl_monthly_rent') {
            setValidationData((prevState) => ({
              ...prevState,
              [`${field}State`]: 'has-danger',
            }));
            formValidated = false;
          }
        }
      }
    });
    if (formValidated) {
      setSectionStatusCheckForDraft('inProgress');
      postData = {
        loan_app_id: loanAppId,
        section: 'primary-applicants',
        aadhar_card_num: stateData.aadhaar_vl_aadhaar_value ?? '',
        userData: {
          company_id: companyID,
          product_id: productID,
          user_id: user._id ? user._id : '',
        },
      };
      new Promise((resolve, reject) => {
        dispatch(patchLapSaveAsDraftDetailsWatcher(postData, resolve, reject));
      })
        .then((response) => {
          showAlert(response?.message, 'success');
          setSectionStatusCheckForDraft('completed');
        })
        .catch((error) => {
          showAlert(error?.response?.data?.message, 'error');
        });
    } else {
      showAlert('Kindly check for errors in fields', 'error');
    }
  };
  const handleSubmit = (event) => {
    if (isLeadRejected) {
      return showAlert('Your lead has been rejected', 'error');
    }

    let postData = {};
    let formValidated = true;

    bookLoansFormJsonFields().map((item, idx) => {
      if ((item.dept == 'Applicant Details' || item.dept == 'Loan Requirement' || item.dept == 'select_company' || item.dept == 'select_partner' || item.dept == 'Current Address' || item.dept == 'Permanent Address' || item.dept == 'Applicant KYC 1') && item.isOptional == false) {
        const field = `${item.type}_vl_${item.field}`;
        if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
        if (!stateData[field]) {
          if (field != 'monthlyincome_vl_monthly_rent') {
            setValidationData((prevState) => ({
              ...prevState,
              [`${field}State`]: 'has-danger',
            }));
            formValidated = false;
          }
        }
      }
    });
    if (formValidated) {
      setSectionStatusCheck('inProgress');
      postData = {
        loan_app_id: loanAppId,
        address_same: paViewPerAddress ? 0 : 1,
        section: 'primary-applicants',
        lap_company_id: companyID,
        lap_product_id: productID,
        user_id: user._id ? user._id : '',
        loan_amount: stateData.float_vl_requested_loan_amount,
        loan_tenure: stateData.string_vl_tenure_in_months,
        loan_interest_rate: stateData.float_vl_interest_rate,
        type_of_loan: stateData.string_vl_type_of_loan,
        aadhar_card_num: stateData.aadhaar_vl_aadhaar_value ?? '',
        section_sequence_no: SectionData.primary.section_sequence_no,
        section_name: SectionData.primary.section_name,
        sub_section_code: SectionData.primary.primary_section_submit.sub_section_code,
        sub_section_name: SectionData.primary.primary_section_submit.sub_section_name,
        sub_section_sequence_no: SectionData.primary.primary_section_submit.sub_section_sequence_no,
        section_code: SectionData.primary.section_code,
        is_pan_pdf_upload: selectedFileType['Image & PDF'] ? 1 : 0,
        is_aadhar_pdf_upload: selectedAadhaarFileType['Image & PDF'] ? 1 : 0,
      };
      new Promise((resolve, reject) => {
        dispatch(patchLapDetailsWatcher(postData, resolve, reject));
      })
        .then((response) => {
          setApplicantData(stateData);
          setStatusCheckApi(loanAppId, SectionData.primary.section_code, SectionData.primary.primary_section_submit.sub_section_code, dispatch);
          showAlert(response?.message, 'success');
        })
        .catch((error) => {
          showAlert(error?.response?.data?.message, 'error');
        });
    } else {
      showAlert('Kindly check for errors in fields', 'error');
    }
  };
  const handlePermanentAddress = () => {
    setPaViewPerAddress(!paViewPerAddress);
  };

  const setStatusCheckApi = async (loanAppID, sectionCode, subSectionCode, dispatch) => {
    intervalId = setInterval(async () => {
      try {
        const status = await getSectionStatus(loanAppID, user, companyID, productID, sectionCode, subSectionCode, dispatch);
        const subSectionRemarks = await getSubSectionRemarks(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch);
        if (status == 'approved') {
          if (subSectionCode == SectionData.primary.primary_pan.sub_section_code) {
            setPanRejectionStatus(false);
            setStatusCheck(true);
            setIsPanValid(true);
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            setPanBorder('1px solid green');
            showAlert('Lead Created Succesfully', 'success');
            setTimeout(() => {
              history.replace({
                pathname: `/admin/lap/lead/${loanAppID}/edit`,
                state: {
                  companyId: companyID,
                  productId: productID,
                },
              });
            }, 1000);
          } else {
            setNavState('Entity Details');
            setNavIconPrefixState((prevState) => ({
              ...prevState,
              'Applicant Details': 'success',
            }));
            setSectionStatusCheck('completed');
            if (setShouldFetch) {
              setShouldFetch((prev) => prev + 1);
            }
          }
          clearInterval(intervalId);
        } else if (status == 'deviation' || status == 'in_review') {
          if (subSectionCode == SectionData.primary.primary_pan.sub_section_code) {
            setStatusCheck(true);
            setIsPanValid(true);
            setBackgroundColorBlur(false);
            setPanButtonState('');
            showAlert('Lead Created Succesfully', 'success');
            setPanBorder('1px solid #f5781f');
            setTimeout(() => {
              history.replace({
                pathname: `/admin/lap/lead/${loanAppID}/edit`,
                state: {
                  companyId: companyID,
                  productId: productID,
                },
              });
            }, 1000);
          } else {
            setNavState('Entity Details');
            setNavIconPrefixState((prevState) => ({
              ...prevState,
              'Applicant Details': 'deviation',
            }));
            setSectionStatusCheck('completed');
          }
          clearInterval(intervalId);
        } else if (status == 'rejected') {
          if (subSectionCode == SectionData.primary.primary_pan.sub_section_code) {
            setPanRejectionStatus(true);
            showAlert(subSectionRemarks, 'error');
            setPanButtonState('button');
          } else {
            showAlert(subSectionRemarks, 'error');
            setSectionStatusCheck('completed');
            setIsLeadRejected(true);
          }
          setStatusCheck(false);
          clearInterval(intervalId);
        }
      } catch (error) {
        showAlert(error?.message ?? 'Unable to fetch sub section status.', 'error');
        clearInterval(intervalId);
      }
    }, 10000);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '98%',
        marginLeft: '1.7%',
        justifyContent: 'space-around',
      }}
    >
      <h4
        style={{
          color: 'var(--neutrals-neutral-100, #161719)',
          fontFamily: 'Montserrat-semibold',
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '150%',
          marginBottom: '24px',
        }}
      >
        Select Company
      </h4>
      <div style={{ marginBottom: '1.3rem' }}>{renderFields('select_company')}</div>
      <div>{renderFields('select_partner')}</div>
      <h4 style={headingCss}>Loan Requirement</h4>
      <div>{renderFields('Loan Requirement')}</div>
      <h4 style={headingCss}>Applicant Details</h4>
      <div>{renderFields('Applicant Details')}</div>
      <h4 style={{ ...subHeadingCss, marginBottom: '16px' }}>Current Address</h4>
      <div>{renderFields('Current Address')}</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h4 style={{ ...subHeadingCss, marginBottom: '16px' }}>Permanent Address</h4>
        <>
          <input
            style={{
              marginLeft: '16px',
              marginTop: '16px',
              width: '1rem',
              height: '1rem',
            }}
            type="checkbox"
            checked={!paViewPerAddress}
            onClick={handlePermanentAddress}
            disabled={panButtonState === 'icon' ? true : false}
          ></input>
          <div
            style={{
              fontFamily: 'Montserrat-Regular',
              fontSize: '0.9vw',
              marginLeft: '8px',
              color: '#767888',
              marginTop: '16px',
            }}
          >
            {' '}
            Same as current address
          </div>
        </>
      </div>
      {<div>{renderFields('Permanent Address')}</div>}
      <h4 style={headingCss}>Applicant KYC</h4>
      <div>{renderFields('Applicant KYC 1')}</div>
      <h4 style={{ ...subHeadingCss, marginBottom: '0px' }}>KYC Documents</h4>
      <div className="ar-applicant-section-selfie-file-upload">{renderFields('Applicant Selfie')}</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '2%',
          marginTop: '34px',
        }}
      >
        <p
          style={{
            color: 'var(--neutrals-neutral-60, #767888)',
            fontFamily: 'Montserrat-Regular',
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          Select PAN document type
        </p>
        <label
          style={{
            ...radioCss,
            color: panButtonState === 'icon' ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8',
          }}
        >
          <input
            type="checkbox"
            value="Image & PDF"
            checked={selectedFileType['Image & PDF']}
            onChange={(e) =>
              handleRadioButton({
                event: e,
                updateSelectedFileType: setSelectedFileType,
                selectedFileType: selectedFileType,
                docCode: [documentCode.applicant_pan_XML],
                docNames: ['pan_xml_image_value'],
              })
            }
            style={radioInputCss}
            disabled={props.type == 'view'}
          />
          Image & PDF
        </label>
        <label
          style={{
            ...radioCss,
            color: panButtonState === 'icon' ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8',
          }}
        >
          <input
            type="checkbox"
            value="Validation Doc"
            checked={selectedFileType['Validation Doc']}
            onChange={(e) =>
              handleRadioButton({
                event: e,
                updateSelectedFileType: setSelectedFileType,
                selectedFileType: selectedFileType,
                docCode: [documentCode.applicant_pan],
                docNames: ['pan_image_value'],
              })
            }
            style={radioInputCss}
            disabled={props.type == 'view' ? true : panButtonState === 'icon' ? false : true}
          />
          Response XML / JSON
        </label>
      </div>
      <div className="ar-applicant-section-pan-file-upload">{renderFields('KYC Document PAN')}</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '2%',
          marginTop: '34px',
        }}
      >
        <p
          style={{
            color: 'var(--neutrals-neutral-60, #767888)',
            fontFamily: 'Montserrat-Regular',
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          Select Aadhaar document type
        </p>
        <label
          style={{
            ...radioCss,
            color: panButtonState === 'icon' ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8',
          }}
        >
          <input
            type="checkbox"
            value="Image & PDF"
            checked={selectedAadhaarFileType['Image & PDF']}
            style={radioInputCss}
            onChange={(e) =>
              handleRadioButton({
                event: e,
                updateSelectedFileType: setSelectedAadhaarFileType,
                selectedFileType: selectedAadhaarFileType,
                docCode: [documentCode.applicant_aadhaar_XML],
                docNames: ['aadhaar_xml_image_value'],
              })
            }
            disabled={props.type == 'view'}
          />
          Image & PDF
        </label>
        <label
          style={{
            ...radioCss,
            color: panButtonState === 'icon' ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8',
          }}
        >
          <input
            type="checkbox"
            value="Validation Doc"
            checked={selectedAadhaarFileType['Validation Doc']}
            onChange={(e) =>
              handleRadioButton({
                event: e,
                updateSelectedFileType: setSelectedAadhaarFileType,
                selectedFileType: selectedAadhaarFileType,
                docCode: [documentCode.applicant_aadhaar_front, documentCode.applicant_aadhaar_back],
                docNames: ['aadhaar_front_image_value', 'aadhaar_back_image_value'],
              })
            }
            style={radioInputCss}
            disabled={props.type == 'view' ? true : panButtonState === 'icon' ? false : true}
          />
          Response XML / JSON
        </label>
      </div>
      <div className="ar-applicant-section-aadhar-file-upload">{renderFields('KYC Document Aadhaar')}</div>
      {props.type == 'edit' || !applicantData || sectionStatusCheck === 'inProgress' || sectionStatusCheckForDraft === 'inProgress' ? (
        <div className="book-loan-button-container book-loan-button-alignment-single-button">
          <Button
            label="Verify & Next"
            onClick={handleSubmit}
            buttonType="primarys"
            isLoading={sectionStatusCheck === 'inProgress' ? true : false}
            customStyle={{
              display: 'inline - flex',
              height: '48px',
              padding: '10px 24px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
              color: '#FFF',
              fontFamily: 'Montserrat-Regular',
              fontSize: '16px',
              fontWeight: '800',
              width: 'max-content',
              pointerEvents: !formComplete === true ? 'none' : 'auto',
              lineHeight: '150%',
              flexShrink: '0',
              borderRadius: '40px',
              background: formComplete ? 'var(--primary-blue-button-gradient, linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%))' : 'var(--neutrals-neutral-30, #CCCDD3)',
            }}
            customLoaderClass={{
              borderTop: '4px solid #fff',
            }}
          ></Button>
          {leadStatus === 'draft' ? getSaveAsDraftButton('Save as Draft', handleSubmitSaveAsDraft, saveAsDraft) : null}
        </div>
      ) : null}
      {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}
