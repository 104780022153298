export function getP2pCompaniesWatcher(resolve, reject) {
  return {
    type: 'P2P_COMPANIES',
    resolve,
    reject,
  };
}

export function getP2pLoansWatcher(data, resolve, reject) {
  return {
    type: 'P2P_LOANS',
    payload: data,
    resolve,
    reject,
  };
}

export function P2pPennyDropReprocessWatcher(data, resolve, reject) {
  return {
    type: 'P2P_PENNY_DROP_REPROCESS',
    payload: data,
    resolve,
    reject,
  };
}

export function P2pPennyDropRejectWatcher(data, resolve, reject) {
  return {
    type: 'P2P_PENNY_DROP_REJECT',
    payload: data,
    resolve,
    reject,
  };
}
