export const entityType = [
  { key: 'Proprietor', label: 'Proprietor' },
  { key: 'Private Limited', label: 'Private Limited' },
  { key: 'Public Limited', label: 'Public Limited' },
  { key: 'LLP', label: 'LLP' },
  { key: 'OPC', label: 'OPC' },
  { key: 'Society', label: 'Society' },
  { key: 'Trust', label: 'Trust' },
  { key: 'Partnership', label: 'Partnership' },
];
