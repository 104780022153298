import { updatePreLoaderWatcher } from 'actions/user';
import { getFldgAPI } from '../apis/fldg';
import { takeLatest, call, put } from 'redux-saga/effects';

export function* getFldgEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getFldgAPI, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getFldgWatcherSaga() {
  yield takeLatest('GET_FLDG', getFldgEffectSaga);
}
