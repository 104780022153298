import { remarkReason } from '../../../config/remarkReason';

export const COMMENT_FIELDS = {
  select_here: {
    label: 'Select here ',
    name: 'select_here',
    id: 'select_here',
    type: 'text',
    remarks: 'text',
    placeholder: 'Select here',
    isRequired: false,
    category: 'dropdown',
    section: ["addComment"],
    options: Object.entries(remarkReason).map(([key, value]) => {
      return {
        label: value,
        value: key,
      };
    }),
  },
  comment: {
    label: 'Comment',
    name: 'comment',
    id: 'comment',
    type: 'text',
    remarks: 'text',
    placeholder: 'Comment',
    isRequired: false,
  },
};

export const REJECT_FIELDS = {
  reject_here: {
    label: 'Reject Reason',
    name: 'select_here',
    id: 'reject_here',
    type: 'text',
    remarks: 'text',
    placeholder: 'Select Reason',
    isRequired: true,
    category: 'dropdown',
    section: ["reject"],
    options: Object.entries(JSON.parse(process?.env?.REACT_APP_REJECT_REASONS)).map(([key, value]) => {
      return {
        label: value,
        value: key,
      };
    }),
  },
  comment: {
    label: 'Comment',
    name: 'comment',
    id: 'reject_comment',
    type: 'text',
    remarks: 'text',
    placeholder: 'Comment',
    isRequired: false,
  },
};

export const ONLY_COMMENT_FIELD = {
  comment: {
    label: 'Comment',
    name: 'comment',
    id: 'comment',
    type: 'text',
    remarks: 'text',
    placeholder: 'Comment',
    isRequired: true,
  },
}
