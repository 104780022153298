import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewBasicFilter from '../../components/Filter/newBasicFilter';
import Table from 'react-sdk/dist/components/Table/Table';
import Button from 'react-sdk/dist/components/Button';
import { getEscrowLoansWatcher, escrowDisbursementWatcher } from '../../actions/colenders.js';
import { storedList } from '../../util/localstorage';
import Preloader from '../../components/custom/preLoader';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import FormPopUp from 'react-sdk/dist/components/Popup/FormPopup';
import { CSVLink, CSVDownload } from 'react-csv';
import Img from '../../views/lending/images/download-button.svg';
import Grid from '@material-ui/core/Grid';
import { EXCLUDE_CO_LENDER_SHORTCODE, INCLUDE_CO_LENDER_MODE } from '../../constants/colenderConstants.js';

const escrowDisbursement = () => {
  const [company, setCompany] = useState('');
  const [product, setProduct] = useState('');
  const [filterParams, setFilterData] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const user = storedList('user');
  const dispatch = useDispatch();
  const [roleList, setRoleList] = useState([]);
  const [colender, setColender] = useState('');
  const [selecredRows, setSelecredRows] = useState([]);
  const [isSelected, setIsSelected] = useState(true);
  const isLoading = useSelector((state) => state.profile.loading);
  const [goToPage, setGoToPage] = useState(null);
  const [escrowLoans, setEscrowLoans] = useState([]);
  const customButton = { height: '3.5rem', width: '9.0625rem', borderRadius: '0.5rem', fontSize: '0.9375rem', color: '#FFF', backgroundColor: isSelected ? '#C0C1C8' : '#475BD8' };
  const customDownloadButton = { height: '2.875rem', fontSize: '1rem', display: 'flex', justifyContent: 'center', boxShadow: 'none', backgroundColor: 'white', fontFamily: 'Montserrat-Regular', padding: '1.375rem', border: '0.0625rem solid #134CDE', color: 'rgba(19, 76, 222, 1)', borderRadius: '1.625rem', marginTop: '1.25rem' };
  const customPopup = { width: '70%', minHeight: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column', float: 'right' };
  const [loanStatus, setLoanStatus] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isDisburstOpen, setIsDisburstOpen] = useState(false);
  const columns = [
    { id: 'LOAN ID', label: 'LOAN ID' },
    { id: 'CUSTOMER NAME', label: 'CUSTOMER NAME' },
    { id: 'LOAN AMOUNT', label: 'LOAN AMOUNT' },
    { id: 'NET DISBURSEMENT AMOUNT', label: 'NET DISBURSEMENT AMOUNT' },
  ];
  const refundStatus = [
    { label: 'Total Request', value: selecredRows.length },
    { label: 'Successful Request', value: successCount },
    { label: 'Failed Request', value: failedCount },
  ];

  useEffect(() => {
    if (company) fetchEscrowLoans(true);
  }, [page, rowsPerPage]);

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 2000);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const fetchEscrowLoans = (firstcall = false, data) => {
    let payload = {};
    if (!firstcall) {
      setPage(1);
      payload = {
        user_id: user._id,
        company_id: data.company.value,
        product_id: data.product.value,
        co_lender_id: data.colender,
        page: 1,
        limit: Number(rowsPerPage),
      };
    } else {
      payload = {
        user_id: user._id,
        company_id: company.value,
        product_id: product.value,
        co_lender_id: colender,
        page: page,
        limit: Number(rowsPerPage),
      };
    }
    setRoleList([]);
    new Promise((resolve, reject) => {
      dispatch(getEscrowLoansWatcher(payload, resolve, reject));
    })
      .then((res) => {
        setEscrowLoans(res.data);
        setRoleList(
          res.data.map((item) => ({
            'LOAN ID': item.loan_id,
            'CUSTOMER NAME': item.first_name + ' ' + item.last_name,
            'LOAN AMOUNT': new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.co_lend_loan_amount),
            'NET DISBURSEMENT AMOUNT': new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.net_disbursement_amount),
          })),
        );
        setCount(res.totalCount);
        setPage(res.currentPage);
        setGoToPage(res.currentPage);
        if (data) showAlert(res.message, 'success');
      })
      .catch((error) => {
        showAlert(error.response.data.message, 'error');
      });
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
    setIsDisburstOpen(!isModalVisible);
  };

  const handleClose = (e) => {
    setModalVisible(!isModalVisible);
    setIsSelected(true);
    fetchEscrowLoans(true);
  };

  const handleDisburse = async () => {
    setLoanStatus([]);
    toggleModal();
    let success = 0;
    let failed = 0;
    let loanID = '';
    for (let i = 0; i < selecredRows.length; i++) {
      const row = selecredRows[i];
      const payload = {
        user_id: user._id,
        company_id: company.value,
        product_id: product.value,
        loan_app_id: row.loan_app_id,
        loan_id: row.loan_id,
        borrower_id: row.borrower_id,
        partner_loan_id: row.partner_loan_id,
        partner_borrower_id: row.partner_borrower_id,
        net_disbur_amt: row.net_disbursement_amount,
        co_lend_loan_id: row.co_lend_loan_id,
      };
      loanID = row.loan_id;
      await new Promise((resolve, reject) => {
        dispatch(escrowDisbursementWatcher(payload, resolve, reject));
      })
        .then((response) => {
          success += 1;
          let status = {
            loan_id: loanID,
            status: 'Initiated',
            info: response.response?.data?.remarks || 'Disbursement initiated succesfully',
          };
          setLoanStatus((prevoius) => [...prevoius, status]);
        })
        .catch((error) => {
          failed += 1;
          let status = {
            loan_id: loanID,
            status: 'Failed',
            info: error?.response?.data?.message,
          };
          setLoanStatus((prevoius) => [...prevoius, status]);
        });
      setSuccessCount(success);
      setFailedCount(failed);
    }
  };

  const handleChangePage = (event) => {
    setPage(event + 1);
  };

  const handleSelectedRows = (rows) => {
    const newArray = [];
    rows.forEach((selectedRow) => {
      const matchingObject = escrowLoans.find((obj) => obj['loan_id'] === selectedRow['LOAN ID']);
      if (matchingObject) {
        newArray.push(matchingObject);
      }
    });
    setSelecredRows(newArray);
    if (rows.length > 0) {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
  };

  return (
    <div style={{ margin: '0.5rem' }}>
      {alert ? <Alert severity={severity} message={alertMessage} onClose={handleAlertClose} /> : null}
      {isModalVisible && (
        <FormPopUp heading={'Escrow Disbursement Progress'} isOpen={isDisburstOpen} onClose={handleClose} customStyles={customPopup}>
          <div onClick={() => toggleModal}>
            <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem', margin: '1rem 0 1rem 0' }}>
              <table className="ir-status-table">
                <tbody>
                  {refundStatus.map((status, index) => (
                    <tr key={index}>
                      <td style={{ fontWeight: 'bold' }}>{status.label}</td>
                      <td>{status.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {loanStatus?.length > 0 && (
                <CSVLink
                  className="btn-secondary-download-button"
                  style={customDownloadButton}
                  data={loanStatus?.map((el) => {
                    return {
                      'Loan ID': el?.loan_id,
                      Status: el?.status,
                      Remark: el?.info,
                    };
                  })}
                  filename={`escrow-disbursement-history.csv`}
                >
                  <img src={Img} />
                  CSV
                </CSVLink>
              )}
            </Grid>
            <div className="ir-wrapper">
              <table className="ir-model-table">
                <thead>
                  <tr className="ir-model-table-head">
                    <th>Loan ID</th>
                    <th>Status</th>
                    <th>Info</th>
                  </tr>
                </thead>
                <tbody>
                  {loanStatus.map((status) => (
                    <tr key={status.loan_id} className="ir-model-table-body">
                      <td>{status.loan_id}</td>
                      <td>{status.status}</td>
                      <td>{status.info}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </FormPopUp>
      )}
      <div style={{ display: 'flex', marginRight: '1rem' }}>
        <NewBasicFilter
          company={company}
          product={product}
          colender={colender}
          onSearchClick={(data) => {
            setCompany(data.company);
            setProduct(data.product);
            setColender(data.colender);
            fetchEscrowLoans(false, data);
          }}
          setFilterParameters={setFilterData}
          isCustomDatePicker={true}
          isViewSearch={true}
          mandatoryFields={{
            company: true,
            product: true,
            colender: true,
          }}
          isColender={true}
          isRequiredColender={EXCLUDE_CO_LENDER_SHORTCODE}
          isRequiredColenderMode={INCLUDE_CO_LENDER_MODE}
        />
        <Button label="Disburse" buttonType="" isDisabled={isSelected} onClick={handleDisburse} customStyle={customButton} />
      </div>
      {roleList.length > 0 ? (
        <div style={{ width: '98%', marginLeft: '1rem', marginTop: '2rem' }}>
          <Table
            customStyle={{ display: 'grid', gridTemplateColumns: '4% 28% 28% 20% 20%' }}
            data={roleList}
            columns={columns}
            isPagination={true}
            rowsPerPageOptions={[5, 10, 20]}
            itemsPerPage={rowsPerPage}
            setRowLimit={setRowsPerPage}
            onPageChange={handleChangePage}
            totalItems={count}
            showOptions={true}
            isChecKbox={true}
            handleSelectedRows={(rows) => {
              handleSelectedRows(rows);
            }}
            goToPage={goToPage}
          />
        </div>
      ) : null}
      {isLoading && <Preloader />}
    </div>
  );
};

export default escrowDisbursement;
