import { takeLatest, call, put } from 'redux-saga/effects';
import { updatePreLoaderWatcher } from '../../actions/user';
import { patchfldgAPI, postfldgAPI, getFldgPartnerAPI } from '../../apis/fldg/fldg';

export function* patchfldgEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(patchfldgAPI, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* patchfldgWatcherSaga() {
  yield takeLatest('PATCH_FLDG_DETAILS', patchfldgEffectSaga);
}

export function* postfldgdetailsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(postfldgAPI, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* postfldgdetailsWatcherSaga() {
  yield takeLatest('POST_FLDG_DETAILS', postfldgdetailsEffectSaga);
}

export function* getFldgpartnerEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getFldgPartnerAPI, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    //  action.reject(e);
  }
}

export function* getFldgpartnerWatcherSaga() {
  yield takeLatest('GET_FLDG_PARTNER_AVAILABLE', getFldgpartnerEffectSaga);
}
