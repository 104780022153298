export const convertIntoINR = (amount, fractionDigits) => {
  if (fractionDigits == null || fractionDigits == undefined) {
    fractionDigits = 2;
  }

  const amountAsFloat = parseFloat(amount);

  if (!Number.isNaN(amountAsFloat)) {
    const amountInINR = amountAsFloat.toLocaleString('en-IN', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
    return amountInINR;
  } else {
    return amount;
  }
};
