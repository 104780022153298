import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDownloadExcel } from 'actions/loanUploadDataStatus';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import { getErrorDownloadExcel } from 'actions/loanUploadDataStatus';

const LoanDataUploadAction = (props) => {
  const { _id, file_name } = props;
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const dispatch = useDispatch();

  const errorDownloadapiResolver = async (response) => {
    // Assuming this function is triggered on the click of a download button
    try {
      // Create a blob URL for the file
      const url = window.URL.createObjectURL(new Blob([response]));
      // Create a link element and click it to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file_name}_errorlog.xlsx`);
      document.body.appendChild(link);
      link.click();
      // Remove the link after the download
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const downloadapiResolver = async (response) => {
    // Assuming this function is triggered on the click of a download button
    try {
      // Create a blob URL for the file
      const url = window.URL.createObjectURL(new Blob([response]));
      // Create a link element and click it to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file_name}.xlsx`);
      document.body.appendChild(link);
      link.click();
      // Remove the link after the download
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const downloadExcelFile = (event) => {
    new Promise((resolve, reject) => {
      const data = {
        _id: _id,
        responseType: 'blob',
      };
      dispatch(getDownloadExcel(data, resolve, reject));
    })
      .then((response) => {
        downloadapiResolver(response);
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage('File not found');
      });
  };

  const downloadErrorExcelFile = (event) => {
    new Promise((resolve, reject) => {
      const data = {
        _id: _id,
        responseType: 'blob',
      };
      dispatch(getErrorDownloadExcel(data, resolve, reject));
    })
      .then((response) => {
        errorDownloadapiResolver(response);
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage('File not found');
      });
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  return (
    <React.Fragment>
      <div className="ar-loan-data-upload-status-action-dashboard">
        <button className="ar-action-download-button" onClick={(e) => downloadExcelFile(e)}>
          Download
        </button>
        {' | '}
        <button className="ar-action-report-button" onClick={(e) => downloadErrorExcelFile(e)}>
          Report
        </button>
      </div>
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} style={{ position: 'fixed', marginTop: '1vh' }} /> : null}
    </React.Fragment>
  );
};

export default memo(LoanDataUploadAction);
