import axios from 'axios';
import { BASE_URL } from '../../constants/apiUrls';

export function getLeadSectionStatusApi(payload) {
  return axios.post(`${BASE_URL}lap/leads/section-status`, payload);
}

export function getLeadSectionStatusForLoanAppIdApi(payload) {
  return axios.get(`${BASE_URL}lap/lead/section-status/${payload?.loan_app_id}`, {
    params: payload,
  });
}

export function postLapSectionStatus(payload) {
  return axios.put(`${BASE_URL}lap/lead/section_status_update`, payload);
}
