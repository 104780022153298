import axios from 'axios';
import { BASE_URL } from '../../constants/apiUrls';
import { storedList } from '../../util/localstorage';

export function createLoanIDApi(payload) {
  const user = storedList('user') ? storedList('user') : { id: null };
  payload.user_id = user._id;
  return axios.post(BASE_URL + 'lap/lead', payload);
}

export function getBookLoanAPI(payload) {
  //const user = storedList("user") ? storedList("user") : { id: null };
  return axios.get(BASE_URL + 'lap/lead/' + payload.loan_app_id, {
    params: payload,
  });
}

export function postLoanDetailsApi(payload) {
  return axios.post(`${BASE_URL}lap/loan`, payload);
}

export function getLapLoanDocumentsApi(payload) {
  return axios.get(`${BASE_URL}lap/lead/${payload.loanAppID}/document`, {
    params: payload,
  });
}

export function gstinVerifyStatusApi(payload) {
  return axios.post(`${BASE_URL}lap/lead/gstverify`, payload);
}

export function getGstStatusIDApi(payload) {
  return axios.post(`${BASE_URL}lap/lead/gstin/${payload.loan_app_id}`, payload);
}
