const GSTData = [
  {
    title: 'GST',
    data: [
      {
        field: 'gst_turnover_m0',
        title: 'GST Turnover M0',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'gst_turnover_m1',
        title: 'GST Turnover M1',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'gst_turnover_m2',
        title: 'GST Turnover M2',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'gst_turnover_m3',
        title: 'GST Turnover M3',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'gst_turnover_m4',
        title: 'GST Turnover M4',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'gst_turnover_m5',
        title: 'GST Turnover M5',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'gst_turnover_m6',
        title: 'GST Turnover M6',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'gst_turnover_m7',
        title: 'GST Turnover M7',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'gst_turnover_m8',
        title: 'GST Turnover M8',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'gst_turnover_m9',
        title: 'GST Turnover M9',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'gst_turnover_m10',
        title: 'GST Turnover M10',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'gst_turnover_m11',
        title: 'GST Turnover M11',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'gst_turnover_m12',
        title: 'GST Turnover M12',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'ttl_gst_turnover_l2m',
        title: 'Total GST Turnover (12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'ttl_gst_turnover_l6m',
        title: 'Total GST Paid (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'ttl_gst_turnover_l3m',
        title: 'Total GST Turnover (3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'lst_mnth_gst_turnover',
        title: 'Last Month GST Turnover',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'ttl_gst_paid_l12m',
        title: 'Total_GST_Paid_(Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'ttl_gst_paid_l3m',
        title: 'Total GST Paid (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'lst_mnth_gst_paid',
        title: 'Last Month GST Paid',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'null_gst_filing_l12m',
        title: 'Null GST Filing(Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'null_gst_filing_l6m',
        title: 'Null GST Filing(Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'one_shot_gst_filing_l6m',
        title: 'One Shot GST Filing(Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'cnt_of_gst_filing_missed_l12m',
        title: 'Count of GST Filing Missed (Last 12 mon)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'cnt_of_gst_filing_missed_l6m',
        title: 'Count of GST Filing Missed (Last 6 mon)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'cnt_of_gst_filing_missed_l3m',
        title: 'Count of GST Filing Missed (Last 3 mon)',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'latest_gst_period',
        title: 'Latest GST Period',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'gst_business_name',
        title: 'GST Business Name',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
];

const CamsData = [
  {
    title: 'Commercial Bureau',
    data: [
      {
        field: '1+dpd_oustanding_perc_com',
        title: '1+ DPD Oustanding Perc',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '30+dpd_oustanding_perc_com',
        title: '30+ DPD Oustanding Perc',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '60+dpd_oustanding_perc_com',
        title: '60+ DPD Oustanding Perc',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '90+dpd_oustanding_perc_com',
        title: '90+ DPD Oustanding Perc',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cmr_score_com',
        title: 'Cmr Score',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'CC_OD_limit_com',
        title: 'CC OD Limit',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'CC_OD_overdue_com',
        title: 'CC OD Overdue',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'WC_Limit_com',
        title: 'WC Limit',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cnt_active_unsecured_loans_com',
        title: 'Cnt Active Unsecured Loans',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'count_total_active_loans_com',
        title: 'Count Total Active Loans',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'current_overdue_amount_com',
        title: 'Current Overdue Amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_1_months_secured_com',
        title: 'DPD In Last 1 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_1_months_Unsecured_com',
        title: 'DPD In Last 1 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_com',
        title: 'DPD In Last 12 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_secured_com',
        title: 'DPD In Last 12 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_unsecured_com',
        title: 'DPD In Last 12 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_com',
        title: 'DPD In Last 24 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_secured_com',
        title: 'DPD In Last 24 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_unsecured_com',
        title: 'DPD In Last 24 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_com',
        title: 'DPD In Last 3 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_secured_com',
        title: 'DPD In Last 3 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_unsecured_com',
        title: 'DPD In Last 3 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_com',
        title: 'DPD In Last 36 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_com',
        title: 'DPD In Last 36 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_unsecured_com',
        title: 'DPD In Last 36 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_com',
        title: 'DPD In Last 6 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_secured_com',
        title: 'DPD In Last 6 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_unsecured_com',
        title: 'DPD In Last 6 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_1_months_com',
        title: 'Enquiries In Last 1 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_1_months_unsecured_com',
        title: 'Enquiries In Last 1 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_com',
        title: 'Enquiries In Last 3 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_com',
        title: 'Enquiries In Last 3 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_com',
        title: 'Enquiries In Last 6 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_com',
        title: 'Enquiries In Last 6 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_com',
        title: 'Loans Opened Last 12 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_com',
        title: 'Loans Opened Last 3 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_com',
        title: 'Loans Opened Last 6 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'outstanding_loan_amt_com',
        title: 'Outstanding Loan Amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'settlement_amount_com',
        title: 'Settlement Amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_amount_com',
        title: 'Suitfiled Amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_flag_com',
        title: 'Suitfiled Flag',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'unsecured_outstanding_loan_amt_com',
        title: 'Unsecured Outstanding Loan Amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_amount_com',
        title: 'Written Off Settled Amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_com',
        title: 'Written Off Settled Flag',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'current_emi_com',
        title: 'Current EMI',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_12m_com',
        title: 'SMA Flag Last 12 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_24m_com',
        title: 'SMA Flag Last 24 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_36m_com',
        title: 'SMA Flag Last 36 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'escore_com',
        title: 'Escore',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'ITR',
    data: [
      {
        field: 'itr_turnover_fy1',
        title: 'ITR Turnover FY1',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'itr_turnover_fy2',
        title: 'ITR Turnover FY2',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'itr_turnover_fy3',
        title: 'ITR Turnover FY3',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'annual_expenditure_fy1',
        title: 'Annual Expenditure FY1',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'annual_expenditure_fy2',
        title: 'Annual Expenditure FY2',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'annual_expenditure_fy2',
        title: 'Annual Expenditure FY3',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'pbt_fy1',
        title: 'PBT FY1',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'pbt_fy2',
        title: 'PBT FY2',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'pbt_fy3',
        title: 'PBT FY3',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tax_paid_fy1',
        title: 'Tax Paid FY1',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tax_paid_fy2',
        title: 'Tax Paid FY2',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tax_paid_fy3',
        title: 'Tax Paid FY3',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'filing_date_fy1',
        title: 'Filing Date FY1',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'filing_date_fy2',
        title: 'Filing Date FY2',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'is_original_return',
        title: 'Is Original Return',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Banking',
    data: [
      {
        field: 'average_balance',
        title: 'Average Balance',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'average_balance_last_1m',
        title: 'Average Balance (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'average_balance_last_3m',
        title: 'Average Balance (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'average_balance_last_6m',
        title: 'Average Balance (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'average_balance_last_12m',
        title: 'Average Balance (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_credits',
        title: 'Avg Monthly Credits',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_credits_last_1m',
        title: 'Avg Monthly Credits (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_credits_last_3m',
        title: 'Avg Monthly Credits (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_credits_last_6m',
        title: 'Avg Monthly Credits (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_credits_last_12m',
        title: 'Avg Monthly Credits (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_debits',
        title: 'Avg Monthly Debits',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_debits_last_1m',
        title: 'Avg Monthly Debits (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_debits_last_3m',
        title: 'Avg Monthly Debits (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_debits_last_6m',
        title: 'Avg Monthly Debits (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_monthly_debits_last_12m',
        title: 'Avg Monthly Debits (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_credit_counts_last_1m',
        title: 'Cash Credit Counts (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_credit_counts_last_3m',
        title: 'Cash Credit Counts (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_credit_counts_last_6m',
        title: 'Cash Credit Counts (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_credit_counts_last_12m',
        title: 'Cash Credit Counts (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_credits_count',
        title: 'Cash Credits Count',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_withdrawals_count',
        title: 'Cash Withdrawals Count',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_withdrawals_count_last_1m',
        title: 'Cash Withdrawals Count (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_withdrawals_count_last_3m',
        title: 'Cash Withdrawals Count (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_withdrawals_count_last_6m',
        title: 'Cash Withdrawals Count (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cash_withdrawals_count_last_12m',
        title: 'Cash Withdrawals Count (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'current_balance',
        title: 'Current Balance',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'deposit_count',
        title: 'Deposit Count',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'deposit_count_last_1m',
        title: 'Deposit Count (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'deposit_count_last_3m',
        title: 'Deposit Count (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'deposit_count_last_6m',
        title: 'Deposit Count (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'deposit_count_last_12m',
        title: 'Deposit Count (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'end_date',
        title: 'End Date',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_balance',
        title: 'Max Balance',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_balance_last_1m',
        title: 'Max Balance (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_balance_last_3m',
        title: 'Max Balance (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_balance_last_6m',
        title: 'Max Balance (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'min_balance_last_12m',
        title: 'Max Balance (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'min_balance',
        title: 'Min Balance',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'min_balance_last_1m',
        title: 'Min Balance (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'min_balance_last_3m',
        title: 'Min Balance (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'min_balance_last_6m',
        title: 'Min Balance (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'min_balance_last_12m',
        title: 'Min Balance (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'monthly_credit_median_last_1m',
        title: 'Monthly Credit Median (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'monthly_credit_median_last_3m',
        title: 'Monthly Credit Median (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'monthly_credit_median_last_6m',
        title: 'Monthly Credit Median (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'monthly_credit_median_last_12m',
        title: 'Monthly Credit Median (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'name',
        title: 'Name',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'opening_balance',
        title: 'Opening Balance',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounce_amount',
        title: 'Inward Bounce Amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounce_amount_last_1m',
        title: 'Inward Bounce Amount (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounce_amount_last_3m',
        title: 'Inward Bounce Amount (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounce_amount_last_6m',
        title: 'Inward Bounce Amount (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounce_amount_last_12m',
        title: 'Inward Bounce Amount (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounces_count',
        title: 'Inward Bounces Count',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounces_count_last_1m',
        title: 'Inward Bounces Count (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounces_count_last_3m',
        title: 'Inward Bounces Count (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounces_count_last_6m',
        title: 'Inward Bounces Count (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc179',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'inward_bounces_count_last_12m',
        title: 'Inward Bounces Count (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'pos_upi',
        title: 'Pos Upi',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tot_pos_upi_last_1m',
        title: 'Tot Pos Upi (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tot_pos_upi_last_3m',
        title: 'Tot Pos Upi (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tot_pos_upi_last_6m',
        title: 'Tot Pos Upi (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tot_pos_upi_last_12m',
        title: 'Tot Pos Upi (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'regular_debits_count',
        title: 'Regular Debits Count',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'regular_debits_count_last_1m',
        title: 'Regular Debits Count (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc187',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'regular_debits_count_last_3m',
        title: 'Regular Debits Count (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc188',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'regular_debits_count_last_6m',
        title: 'Regular Debits Count (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc189',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'regular_debits_count_last_12m',
        title: 'Regular Debits Count (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc190',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'salary_dates',
        title: 'Salary Dates',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc191',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'salary_flag',
        title: 'Salary Flag',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc192',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'stable_monthly_inflow',
        title: 'Stable Monthly Inflow',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc193',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'start_date',
        title: 'Start Date',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc194',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_cash_withdrawal',
        title: 'Total Cash Withdrawal',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc195',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_cash_withdrawal_last_1m',
        title: 'Total Cash Withdrawal (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc196',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_cash_withdrawal_last_3m',
        title: 'Total Cash Withdrawal (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc197',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_cash_withdrawal_last_6m',
        title: 'Total Cash Withdrawal (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc198',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_cash_withdrawal_last_12m',
        title: 'Total Cash Withdrawal (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc199',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_credit_last_1m',
        title: 'Total Credit (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc200',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_credit_last_3m',
        title: 'Total Credit (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc201',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_credit_last_6m',
        title: 'Total Credit (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc202',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_credit_last_12m',
        title: 'Total Credit (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc203',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_credits',
        title: 'Total Credits',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc204',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debit_to_credit_ratio',
        title: 'Total Debit To Credit Ratio',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc205',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debit_to_credit_ratio_last_1m',
        title: 'Total Debit To Credit Ratio (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc206',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debit_to_credit_ratio_last_3m',
        title: 'Total Debit To Credit Ratio (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc207',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debit_to_credit_ratio_last_6m',
        title: 'Total Debit To Credit Ratio (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc208',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debit_to_credit_ratio_last_12m',
        title: 'Total Debit To Credit Ratio (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc209',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits',
        title: 'Total Debits',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc210',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits_count',
        title: 'Total Debits Count',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc211',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits_count_last_1m',
        title: 'Total Debits Count (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc212',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits_count_last_3m',
        title: 'Total Debits Count (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc213',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits_count_last_6m',
        title: 'Total Debits Count (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc214',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits_count_last_12m',
        title: 'Total Debits Count (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc215',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits_last_1m',
        title: 'Total Debits (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc216',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits_last_3m',
        title: 'Total Debits (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc217',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits_last_6m',
        title: 'Total Debits (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc218',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_debits_last_12m',
        title: 'Total Debits (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc219',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_deposit',
        title: 'Total Deposit',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc220',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_deposit_last_1m',
        title: 'Total Deposit (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc221',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_deposit_last_3m',
        title: 'Total Deposit (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc222',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_deposit_last_6m',
        title: 'Total Deposit (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc223',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_deposit_last_12m',
        title: 'Total Deposit (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc224',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_negative_incedent_count',
        title: 'Total Negative Incedent Count',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc225',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_negative_incedent_count_last_1m',
        title: 'Total Negative Incedent Count (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc226',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_negative_incedent_count_last_3m',
        title: 'Total Negative Incedent Count (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc227',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_negative_incedent_count_last_6m',
        title: 'Total Negative Incedent Count (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc228',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_negative_incedent_count_last_12m',
        title: 'Total Negative Incedent Count (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc229',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'latest_salary',
        title: 'Latest Salary',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc230',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_surplus',
        title: 'Total Surplus',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc231',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_surplus_last_1m',
        title: 'Total Surplus (Last 1 month)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc232',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_surplus_last_3m',
        title: 'Total Surplus (Last 3 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc233',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_surplus_last_6m',
        title: 'Total Surplus (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc234',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_surplus_last_12m',
        title: 'Total Surplus (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc235',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'transaction_count',
        title: 'Transaction Count',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc236',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'account_number',
        title: 'Account Number',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc237',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'account_type',
        title: 'Account Type',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc238',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bank_name',
        title: 'Bank Name',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc239',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'min_avg_mthly_bal_l6m',
        title: 'Minimum Average Monthly Balance (Last 6 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc240',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'min_avg_mthly_bal_l12m',
        title: 'Minimum Average Monthly Balance (Last 12 months)',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tt_tpv_last_1m',
        title: 'Total Payments Volume Last 1 Month',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tt_tpv_last_3m',
        title: 'Total Payments Volume Last 3 Month',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'avg_tpv_last_3m',
        title: 'Average Total Payments Volume Last 3 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'median_tpv_last_3m',
        title: 'Median Of Weekly Total Payments Volume For Last 3 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_transacting_days_m1',
        title: 'Active Transacting Days Month 1',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_transacting_days_m2',
        title: 'Active Transacting Days Month 2',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_transacting_days_m3',
        title: 'Active Transacting Days Month 3',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'tpv_coverage_top_10_perc_cust',
        title: 'Total Payments Volume Contribution By Top 10% Customers In Last 3 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'platform_vintage',
        title: 'Platform Vintage',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'target_segment',
        title: 'Target Segment',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'nationality',
        title: 'Nationality',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'negative_category',
        title: 'Negative Category',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'risk_segment',
        title: 'Risk Segment',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Crime Check',
    data: [
      {
        field: 'tot_num_cases',
        title: 'Total Number Of Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_disposed_cases',
        title: 'Number Of Disposed Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_pending_cases',
        title: 'Number Of Pending Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_convicted_cases',
        title: 'Number Of Convicted Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_pend_civil_cases',
        title: 'Number Of Pending Civil Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_pend_criminal_cases',
        title: 'Number Of Pending Criminal Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_pend_tribunal_cases',
        title: 'Number Of Pending Tribunal Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_pend_negotiable_instr_act_cases',
        title: 'Number Of Pending Negotiable Instr Act Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_pend_traffic_rule_cases',
        title: 'Number Of Pending Traffic Rule Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_pend_arbitration_cases',
        title: 'Number Of Pending Arbitration Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_disp_civil_cases',
        title: 'Number Of Disposed Civil Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_disp_criminal_cases',
        title: 'Number Of Disposed Criminal Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_disp_tribunal_cases',
        title: 'Number Of Disposed Tribunal Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_disp_negotiable_instr_act_cases',
        title: 'Number Of Disposed Negotiable Instr Act Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_disp_traffic_rule_cases',
        title: 'Number Of Disposed Traffic Rule Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_disp_arbitration_cases',
        title: 'Number Of Disposed Arbitration Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_conv_civil_cases',
        title: 'Number Of Convicted Civil Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_conv_criminal_cases',
        title: 'Number Of Convicted Criminal Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_conv_tribunal_cases',
        title: 'Number Of Convicted Tribunal Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_conv_negotiable_instr_act_cases',
        title: 'Number Of Convicted Negotiable Instr Act Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_conv_traffic_rule_cases',
        title: 'Number Of Convicted Traffic Rule Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'num_conv_arbitration_cases',
        title: 'Number Of Convicted Arbitration Cases',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'nclt_movement_flag',
        title: 'NCLT Movement Flag',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'ibbi_flag',
        title: 'IBBI Flag',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'case_description',
        title: 'Case Description',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Hunter',
    data: [
      {
        field: 'pan_match',
        title: 'Pan Match',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dob_match',
        title: 'Dob Match',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'phone_match',
        title: 'Phone Match',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'name_match',
        title: 'Name Match',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'score',
        title: 'Score',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'hunter_match',
        title: 'Hunter Match',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Parsed Udyam Details',
    data: [
      {
        field: 'urc_block',
        title: 'Block',
        type: 'string',
        validationmsg: 'Please enter Block',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_building',
        title: 'Building',
        type: 'string',
        validationmsg: 'Please enter Building',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_city',
        title: 'City',
        type: 'string',
        validationmsg: 'Please enter City',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_commencement_dt',
        title: 'Commencement Date',
        type: 'string',
        validationmsg: 'Please enter Commencement Date',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_district',
        title: 'District',
        type: 'string',
        validationmsg: 'Please enter District',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_addr',
        title: 'Address',
        type: 'string',
        validationmsg: 'Please enter Address',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_ent_activity',
        title: 'Entity Activity',
        type: 'string',
        validationmsg: 'Please enter Entity Activity',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_ent_type',
        title: 'Entity Type',
        type: 'string',
        validationmsg: 'Please enter Entity Type',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_flat',
        title: 'Flat',
        type: 'string',
        validationmsg: 'Please enter Flat',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_gender',
        title: 'Gender',
        type: 'string',
        validationmsg: 'Please enter Gender',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_incorporation_dt',
        title: 'Incorporation Date',
        type: 'string',
        validationmsg: 'Please enter Incorporation Date',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_investment',
        title: 'Investment',
        type: 'string',
        validationmsg: 'Please enter Investment',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_name_of_ent',
        title: 'Name Of Entity',
        type: 'string',
        validationmsg: 'Please enter Name Of Entity',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_nic',
        title: 'Nic',
        type: 'string',
        validationmsg: 'Please enter Nic',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_pan',
        title: 'Udyam PAN',
        type: 'string',
        validationmsg: 'Please enter Udyam PAN',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_org_type',
        title: 'Org Type',
        type: 'string',
        validationmsg: 'Please enter Org Type',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_owner_name',
        title: 'Owner Name',
        type: 'string',
        validationmsg: 'Please enter Owner Name',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_pin',
        title: 'Pin',
        type: 'string',
        validationmsg: 'Please enter Pin',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_reg_no',
        title: 'Reg No',
        type: 'string',
        validationmsg: 'Please enter Reg No',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_road',
        title: 'Road',
        type: 'string',
        validationmsg: 'Please enter Road',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_state',
        title: 'State',
        type: 'string',
        validationmsg: 'Please enter State',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_turnover',
        title: 'Turnover',
        type: 'string',
        validationmsg: 'Please enter Turnover',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_udym_reg_dt',
        title: 'Udym Reg Date',
        type: 'string',
        validationmsg: 'Please enter Udym Reg Date',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_unit_name',
        title: 'Unit Name',
        type: 'string',
        validationmsg: 'Please enter Unit Name',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'urc_village_Town',
        title: 'Village Town',
        type: 'string',
        validationmsg: 'Please enter Village Town',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
];

const FinancialData = [
  {
    title: 'Current Year',
    data: [
      {
        field: 'share_capital_1',
        title: 'Share Capital',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'reserves_surplus_1',
        title: 'Reserves Surplus',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'other_equity_1',
        title: 'Other Equity',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_equity_1',
        title: 'Total Equity',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'long_term_borrowings_1',
        title: 'Long Term Borrowings',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_def_tax_liabilities_1',
        title: 'Net Def Tax Liabilities',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_long_term_liabilities_1',
        title: 'Other Long Term Liabilities',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'long_term_provisions_1',
        title: 'Long Term Provisions',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_long_term_liabilities_1',
        title: 'Total Long Term Liabilities',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'short_term_borrowings_1',
        title: 'Short Term Borrowings',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'trade_payables_1',
        title: 'Trade Payables',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_current_liabilities_1',
        title: 'Other Current Liabilities',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'short_term_provisions_1',
        title: 'Short Term Provisions',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_current_liabilities_1',
        title: 'Total Current Liabilities',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_equity_liabilities_1',
        title: 'Total Equity Liabilities',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_fixed_assets_1',
        title: 'Net Fixed Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'tangible_assets_1',
        title: 'Tangible Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'intangible_assets_1',
        title: 'Intangible Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'tot_net_fixed_assets_1',
        title: 'Tot Net Fixed Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'capital_wip_1',
        title: 'Capital Wip',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_non_current_assets_1',
        title: 'Other Non Current Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'non_current_investments_1',
        title: 'Non Current Investments',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_def_tax_assets_1',
        title: 'Net Def Tax Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'long_term_loans_advances_1',
        title: 'Long Term Loans Advances',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_non_current_assets_1',
        title: 'Other Non Current Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'tot_other_non_curr_assets_1',
        title: 'Tot Other Non Curr Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'current_assets_1',
        title: 'Current Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'current_investments_1',
        title: 'Current Investments',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'inventories_1',
        title: 'Inventories',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'trade_receivables_1',
        title: 'Trade Receivables',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'cash_bank_balances_1',
        title: 'Cash Bank Balances',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'short_term_loans_advances_1',
        title: 'Short Term Loans Advances',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_current_assets_1',
        title: 'Other Current Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_current_assets_1',
        title: 'Total Current Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_assets_1',
        title: 'Total Assets',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_revenue_1',
        title: 'Net Revenue',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'operating_cost_1',
        title: 'Operating Cost',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'cost_materials_consumed_1',
        title: 'Cost Materials Consumed',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'purchases_stock_in_trade_1',
        title: 'Purchases Stock In Trade',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'changes_inventories_finished_goods_1',
        title: 'Changes Inventories Finished Goods',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'employee_benefit_expense_1',
        title: 'Employee Benefit Expense',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_expenses_1',
        title: 'Other Expenses',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_operating_cost_1',
        title: 'Total Operating Cost',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'operating_profit_1',
        title: 'Operating Profit',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_income_1',
        title: 'Other Income',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'depreciation_amortization_expense_1',
        title: 'Depreciation Amortization Expense',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_before_interest_tax_1',
        title: 'Profit Before Interest Tax',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'finance_costs_1',
        title: 'Finance Costs',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_before_tax_exceptional_items_1',
        title: 'Profit Before Tax Exceptional Items',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'exceptional_items_before_tax_1',
        title: 'Exceptional Items Before Tax',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_before_tax_1',
        title: 'Profit Before Tax',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'income_tax_1',
        title: 'Income Tax',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_period_continuing_operations_1',
        title: 'Profit Period Continuing Operations',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_discontinuing_operations_tax_1',
        title: 'Profit Discontinuing Operations Tax',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_for_period_1',
        title: 'Profit For Period',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Penultimate Year',
    data: [
      {
        field: 'share_capital_2',
        title: 'Share Capital ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'reserves_surplus_2',
        title: 'Reserves Surplus ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'other_equity_2',
        title: 'Other Equity ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_equity_2',
        title: 'Total Equity ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'long_term_borrowings_2',
        title: 'Long Term Borrowings ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_def_tax_liabilities_2',
        title: 'Net Def Tax Liabilities ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_long_term_liabilities_2',
        title: 'Other Long Term Liabilities ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'long_term_provisions_2',
        title: 'Long Term Provisions ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_long_term_liabilities_2',
        title: 'Total Long Term Liabilities ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'short_term_borrowings_2',
        title: 'Short Term Borrowings ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'trade_payables_2',
        title: 'Trade Payables ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_current_liabilities_2',
        title: 'Other Current Liabilities ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'short_term_provisions_2',
        title: 'Short Term Provisions ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_current_liabilities_2',
        title: 'Total Current Liabilities ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_equity_liabilities_2',
        title: 'Total Equity Liabilities ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_fixed_assets_2',
        title: 'Net Fixed Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'tangible_assets_2',
        title: 'Tangible Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'intangible_assets_2',
        title: 'Intangible Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'tot_net_fixed_assets_2',
        title: 'Tot Net Fixed Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'capital_wip_2',
        title: 'Capital Wip ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_non_current_assets_2',
        title: 'Other Non Current Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'non_current_investments_2',
        title: 'Non Current Investments ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_def_tax_assets_2',
        title: 'Net Def Tax Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'long_term_loans_advances_2',
        title: 'Long Term Loans Advances ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_non_current_assets_2',
        title: 'Other Non Current Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'tot_other_non_curr_assets_2',
        title: 'Tot Other Non Curr Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'current_assets_2',
        title: 'Current Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'current_investments_2',
        title: 'Current Investments ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'inventories_2',
        title: 'Inventories ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'trade_receivables_2',
        title: 'Trade Receivables ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'cash_bank_balances_2',
        title: 'Cash Bank Balances ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'short_term_loans_advances_2',
        title: 'Short Term Loans Advances ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_current_assets_2',
        title: 'Other Current Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_current_assets_2',
        title: 'Total Current Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_assets_2',
        title: 'Total Assets ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_revenue_2',
        title: 'Net Revenue ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'operating_cost_2',
        title: 'Operating Cost ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'cost_materials_consumed_2',
        title: 'Cost Materials Consumed ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'purchases_stock_in_trade_2',
        title: 'Purchases Stock In Trade ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'changes_inventories_finished_goods_2',
        title: 'Changes Inventories Finished Goods ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'employee_benefit_expense_2',
        title: 'Employee Benefit Expense ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_expenses_2',
        title: 'Other Expenses ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_operating_cost_2',
        title: 'Total Operating Cost ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'operating_profit_2',
        title: 'Operating Profit ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_income_2',
        title: 'Other Income ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'depreciation_amortization_expense_2',
        title: 'Depreciation Amortization Expense ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_before_interest_tax_2',
        title: 'Profit Before Interest Tax ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'finance_costs_2',
        title: 'Finance Costs ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_before_tax_exceptional_items_2',
        title: 'Profit Before Tax Exceptional Items ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'exceptional_items_before_tax_2',
        title: 'Exceptional Items Before Tax ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_before_tax_2',
        title: 'Profit Before Tax ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'income_tax_2',
        title: 'Income Tax ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_period_continuing_operations_2',
        title: 'Profit Period Continuing Operations ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_discontinuing_operations_tax_2',
        title: 'Profit Discontinuing Operations Tax ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_for_period_2',
        title: 'Profit For Period ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },

  {
    title: 'Antepenultimate Year',
    data: [
      {
        field: 'share_capital_3',
        title: 'Share Capital   ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'reserves_surplus_3',
        title: 'Reserves Surplus   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'other_equity_3',
        title: 'Other Equity   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_equity_3',
        title: 'Total Equity   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'long_term_borrowings_3',
        title: 'Long Term Borrowings   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_def_tax_liabilities_3',
        title: 'Net Def Tax Liabilities   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_long_term_liabilities_3',
        title: 'Other Long Term Liabilities   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'long_term_provisions_3',
        title: 'Long Term Provisions',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_long_term_liabilities_3',
        title: 'Total Long Term Liabilities   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'short_term_borrowings_3',
        title: 'Short Term Borrowings   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'trade_payables_3',
        title: 'Trade Payables   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_current_liabilities_3',
        title: 'Other Current Liabilities   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'short_term_provisions_3',
        title: 'Short Term Provisions   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_current_liabilities_3',
        title: 'Total Current Liabilities   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_equity_liabilities_3',
        title: 'Total Equity Liabilities   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_fixed_assets_3',
        title: 'Net Fixed Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'tangible_assets_3',
        title: 'Tangible Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'intangible_assets_3',
        title: 'Intangible Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'tot_net_fixed_assets_3',
        title: 'Tot Net Fixed Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'capital_wip_3',
        title: 'Capital Wip   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_non_current_assets_3',
        title: 'Other Non Current Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'non_current_investments_3',
        title: 'Non Current Investments   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_def_tax_assets_3',
        title: 'Net Def Tax Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'long_term_loans_advances_3',
        title: 'Long Term Loans Advances   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_non_current_assets_3',
        title: 'Other Non Current Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'tot_other_non_curr_assets_3',
        title: 'Tot Other Non Curr Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'current_assets_3',
        title: 'Current Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'current_investments_3',
        title: 'Current Investments   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'inventories_3',
        title: 'Inventories   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'trade_receivables_3',
        title: 'Trade Receivables   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'cash_bank_balances_3',
        title: 'Cash Bank Balances   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'short_term_loans_advances_3',
        title: 'Short Term Loans Advances   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_current_assets_3',
        title: 'Other Current Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_current_assets_3',
        title: 'Total Current Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_assets_3',
        title: 'Total Assets   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'net_revenue_3',
        title: 'Net Revenue   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'operating_cost_3',
        title: 'Operating Cost   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'cost_materials_consumed_3',
        title: 'Cost Materials Consumed   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'purchases_stock_in_trade_3',
        title: 'Purchases Stock In Trade   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'changes_inventories_finished_goods_3',
        title: 'Changes Inventories Finished Goods   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'employee_benefit_expense_3',
        title: 'Employee Benefit Expense   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_expenses_3',
        title: 'Other Expenses   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'total_operating_cost_3',
        title: 'Total Operating Cost   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'operating_profit_3',
        title: 'Operating Profit   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'other_income_3',
        title: 'Other Income   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'depreciation_amortization_expense_3',
        title: 'Depreciation Amortization Expense   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_before_interest_tax_3',
        title: 'Profit Before Interest Tax   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'finance_costs_3',
        title: 'Finance Costs   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_before_tax_exceptional_items_3',
        title: 'Profit Before Tax Exceptional Items   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'exceptional_items_before_tax_3',
        title: 'Exceptional Items Before Tax   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_before_tax_3',
        title: 'Profit Before Tax   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'income_tax_3',
        title: 'Income Tax   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_period_continuing_operations_3',
        title: 'Profit Period Continuing Operations   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_discontinuing_operations_tax_3',
        title: 'Profit Discontinuing Operations Tax   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },

      {
        field: 'profit_for_period_3',
        title: 'Profit For Period   ',
        type: 'string',
        validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
        isOptional: 'FALSE',
        checked: 'TRUE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
];

const ConsumerBureau = [
  {
    title: 'Primary Applicant',
    data: [
      {
        field: '1+dpd_oustanding_perc_br',
        title: '1 + DPD Oustanding Perc',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '30+dpd_oustanding_perc_br',
        title: '30 + DPD Oustanding Perc',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '60+dpd_oustanding_perc_br',
        title: '60 + DPD Oustanding Perc',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '90+dpd_oustanding_perc_br',
        title: '90 + DPD Oustanding Perc',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_secured_loan_or_credit_card_br',
        title: 'Active Secured Loan Or Credit Card',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_outstanding_loan_amt_br',
        title: 'Bureau Outstanding Loan Amt',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_score_br',
        title: 'Bureau Score',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cnt_active_unsecured_loans_br',
        title: 'Cnt Active Unsecured Loans',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'count_total_active_loans_br',
        title: 'Count Total Active Loans',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'credit_card_settlement_amount_br',
        title: 'Credit Card Settlement Amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'current_overdue_value_br',
        title: 'Current Overdue Value',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_1_months_secured_br',
        title: 'DPD In Last 1 Month Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_br',
        title: 'DPD In Last 12 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_credit_card_br',
        title: 'DPD In Last 12 Months Credit Card',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_secured_br',
        title: 'DPD In Last 12 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_unsecured_br',
        title: 'DPD In Last 12 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_br',
        title: 'DPD In Last 24 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_secured_br',
        title: 'DPD In Last 24 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_unsecured_br',
        title: 'DPD In Last 24 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_br',
        title: 'DPD In Last 3 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_credit_card_br',
        title: 'DPD In Last 3 Months Credit Card',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_secured_br',
        title: 'DPD In Last 3 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_unsecured_br',
        title: 'DPD In Last 3 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_30_days_unsecured_br',
        title: 'DPD In Last 30 Days Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_br',
        title: 'DPD In Last 36 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_unsecured_br',
        title: 'DPD In Last 36 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_br',
        title: 'DPD In Last 6 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_credit_card_br',
        title: 'DPD In Last 6 Months Credit Card',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_secured_br',
        title: 'DPD In Last 6 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_unsecured_br',
        title: 'DPD In Last 6 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_9_months_br',
        title: 'DPD In Last 9 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_bureau_30_days_br',
        title: 'Enquiries Bureau 30 Days',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_1_months_unsecured_br',
        title: 'Enquiries In Last 1 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_br',
        title: 'Enquiries In Last 3 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_br',
        title: 'Enquiries In Last 3 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_excl_cc_br',
        title: 'Enquiries In Last 3 Months Unsecured Excl CC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_30_days_unsecured_excl_cc_br',
        title: 'Enquiries In Last 30 Days Unsecured Excl CC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_br',
        title: 'Enquiries In Last 6 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_br',
        title: 'Enquiries In Last 6 Months Unsecured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_excl_cc_br',
        title: 'Enquiries In Last 6 Months Unsecured Excl CC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_br',
        title: 'Loans Opened Last 12 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_unsecured_excl_cc_br',
        title: 'Loans Opened Last 12 Months Unsecured Excl CC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_br',
        title: 'Loans Opened Last 3 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_unsecured_excl_cc_br',
        title: 'Loans Opened Last 3 Months Unsecured Excl CC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_br',
        title: 'Loans Opened Last 6 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_unsecured_excl_cc_br',
        title: 'Loans Opened Last 6 Months Unsecured Excl CC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'ninety_plus_dpd_in_last_24_months_br',
        title: 'Ninety Plus DPD In Last 24 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_amount_br',
        title: 'Suitfiled Amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_flag_br',
        title: 'Suitfiled Flag',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'sum_of_credit_balance_in_all_credit_cards_br',
        title: 'Sum Of Credit Balance In All Credit Cards',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_overdues_in_cc_br',
        title: 'Total Overdues In CC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'unsecured_outstanding_loan_amt_br',
        title: 'Unsecured Outstanding Loan Amt',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_br',
        title: 'Written Off Settled',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_br',
        title: 'Written Off Settled Flag',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_br',
        title: 'DPD In Last 36 Months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_credit_card_br',
        title: 'DPD In Last 36 Months Credit Card',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_br',
        title: 'DPD In Last 36 Months Secured',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc179',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'curren_emi_br',
        title: 'Curren Emi',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_24m_br',
        title: 'Max Emi Last 24m',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_12m_br',
        title: 'Max Emi Last 12m',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'emi_drop_next_6m_br',
        title: 'Emi Drop Next 6m',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_12m_br',
        title: 'SMA Flag Last 12m',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_24m_br',
        title: 'SMA Flag Last 24m',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_36m_br',
        title: 'SMA Flag Last 36m',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_1_month_exc_gl_ml_el_kcc_cb3',
        title: 'DPD in last 1 month excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_month_exc_gl_ml_el_kcc_cb3',
        title: 'DPD in last 3 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_month_exc_gl_ml_el_kcc_cb3',
        title: 'DPD in last 6 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_month_exc_gl_ml_el_kcc_cb3',
        title: 'DPD in last 12 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_month_exc_gl_ml_el_kcc_cb3',
        title: 'DPD in last 24 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_month_exc_gl_ml_el_kcc_cb3',
        title: 'DPD in last 36 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'overdue_amount_exc_gl_ml_el_kcc_cb3',
        title: 'Current Overdue Value excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_exc_gl_ml_el_kcc_cb3',
        title: 'Written Off Settled last 24 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_exc_gl_ml_el_kcc_cb3',
        title: 'Written Off Settled Flag last 24 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_in_last_36_month_exc_gl_ml_el_kcc_cb3',
        title: 'Written Off Settled last 36 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_in_last_36_month_exc_gl_ml_el_kcc_cb3',
        title: 'Written Off Settled Flag last 36 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_exc_gl_ml_el_kcc_cb3',
        title: 'Suitfiled amount last 24 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_flag_exc_gl_ml_el_kcc_cb3',
        title: 'Suitfiled Flag last 24 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_in_last_36_month_exc_gl_ml_el_kcc_cb3',
        title: 'Suitfiled amount last 36 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_flag_in_last_36_month_exc_gl_ml_el_kcc_cb3',
        title: 'Suitfiled Flag last 36 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_flag_exc_gl_ml_el_kcc_cb3',
        title: 'SMA Flag last 24 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_flag_in_last_36_month_exc_gl_ml_el_kcc_cb3',
        title: 'SMA Flag last 36 months excluding GL/ML/EL/KCC',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_pl_loan_cnt_gt_10k_cb3',
        title: 'Active PL count with GT 10K loan amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_pl_loan_amt_cb3',
        title: 'Active PL loan amount',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'unsec_loan_amount_last_3m_cb3',
        title: 'Unsecured loan amount opened in last 3 months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_last_36m_cb3',
        title: 'Written Off Settled Flag last 36 months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_tw_cb3',
        title: 'Enquiries In Last 6 Months for TW loans',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_vintage_all_accts_in_mnths',
        title: 'Maximum bureau vintage in months',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Co-Applicant 1',
    data: [
      {
        field: '1+dpd_oustanding_perc_cb1',
        title: '1 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '30+dpd_oustanding_perc_cb1',
        title: '30 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '60+dpd_oustanding_perc_cb1',
        title: '60 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '90+dpd_oustanding_perc_cb1',
        title: '90 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_secured_loan_or_credit_card_cb1',
        title: 'Active Secured Loan Or Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_outstanding_loan_amt_cb1',
        title: 'Bureau Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_score_cb1',
        title: 'Bureau Score ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cnt_active_unsecured_loans_cb1',
        title: 'Cnt Active Unsecured Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'count_total_active_loans_cb1',
        title: 'Count Total Active Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'credit_card_settlement_amount_cb1',
        title: 'Credit Card Settlement Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'current_overdue_value_cb1',
        title: 'Current Overdue Value ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_1_months_secured_cb1',
        title: 'DPD In Last 1 Month Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_cb1',
        title: 'DPD In Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_credit_card_cb1',
        title: 'DPD In Last 12 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_secured_cb1',
        title: 'DPD In Last 12 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_unsecured_cb1',
        title: 'DPD In Last 12 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_cb1',
        title: 'DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_secured_cb1',
        title: 'DPD In Last 24 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_unsecured_cb1',
        title: 'DPD In Last 24 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_cb1',
        title: 'DPD In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_credit_card_cb1',
        title: 'DPD In Last 3 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_secured_cb1',
        title: 'DPD In Last 3 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_unsecured_cb1',
        title: 'DPD In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_30_days_unsecured_cb1',
        title: 'DPD In Last 30 Days Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb1',
        title: 'DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_unsecured_cb1',
        title: 'DPD In Last 36 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_cb1',
        title: 'DPD In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_credit_card_cb1',
        title: 'DPD In Last 6 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_secured_cb1',
        title: 'DPD In Last 6 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_unsecured_cb1',
        title: 'DPD In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_9_months_cb1',
        title: 'DPD In Last 9 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_bureau_30_days_cb1',
        title: 'Enquiries Bureau 30 Days ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_1_months_unsecured_cb1',
        title: 'Enquiries In Last 1 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_cb1',
        title: 'Enquiries In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_cb1',
        title: 'Enquiries In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_excl_cc_cb1',
        title: 'Enquiries In Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_30_days_unsecured_excl_cc_cb1',
        title: 'Enquiries In Last 30 Days Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_cb1',
        title: 'Enquiries In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_cb1',
        title: 'Enquiries In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_excl_cc_cb1',
        title: 'Enquiries In Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_cb1',
        title: 'Loans Opened Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_unsecured_excl_cc_cb1',
        title: 'Loans Opened Last 12 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_cb1',
        title: 'Loans Opened Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_unsecured_excl_cc_cb1',
        title: 'Loans Opened Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_cb1',
        title: 'Loans Opened Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_unsecured_excl_cc_cb1',
        title: 'Loans Opened Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'ninety_plus_dpd_in_last_24_months_cb1',
        title: 'Ninety Plus DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_amount_cb1',
        title: 'Suitfiled Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_flag_cb1',
        title: 'Suitfiled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'sum_of_credit_balance_in_all_credit_cards_cb1',
        title: 'Sum Of Credit Balance In All Credit Cards ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_overdues_in_cc_cb1',
        title: 'Total Overdues In CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'unsecured_outstanding_loan_amt_cb1',
        title: 'Unsecured Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_cb1',
        title: 'Written Off Settled ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_cb1',
        title: 'Written Off Settled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_cb1',
        title: 'DPD In Last 36 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_credit_card_cb1',
        title: 'DPD In Last 36 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb1',
        title: 'DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc179',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'curren_emi_cb1',
        title: 'Curren Emi ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_24m_cb1',
        title: 'Max Emi Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_12m_cb1',
        title: 'Max Emi Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'emi_drop_next_6m_cb1',
        title: 'Emi Drop Next 6m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_12m_cb1',
        title: 'SMA Flag Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_24m_cb1',
        title: 'SMA Flag Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_36m_cb1',
        title: 'SMA Flag Last 36m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Co-Applicant 2',
    data: [
      {
        field: '1+dpd_oustanding_perc_cb2',
        title: ' 1 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '30+dpd_oustanding_perc_cb2',
        title: ' 30 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '60+dpd_oustanding_perc_cb2',
        title: ' 60 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '90+dpd_oustanding_perc_cb2',
        title: ' 90 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_secured_loan_or_credit_card_cb2',
        title: ' Active Secured Loan Or Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_outstanding_loan_amt_cb2',
        title: ' Bureau Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_score_cb2',
        title: ' Bureau Score ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cnt_active_unsecured_loans_cb2',
        title: ' Cnt Active Unsecured Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'count_total_active_loans_cb2',
        title: ' Count Total Active Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'credit_card_settlement_amount_cb2',
        title: ' Credit Card Settlement Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'current_overdue_value_cb2',
        title: ' Current Overdue Value ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_1_months_secured_cb2',
        title: ' DPD In Last 1 Month Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_cb2',
        title: ' DPD In Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_credit_card_cb2',
        title: ' DPD In Last 12 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_secured_cb2',
        title: ' DPD In Last 12 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_unsecured_cb2',
        title: ' DPD In Last 12 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_cb2',
        title: ' DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_secured_cb2',
        title: ' DPD In Last 24 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_unsecured_cb2',
        title: ' DPD In Last 24 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_cb2',
        title: ' DPD In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_credit_card_cb2',
        title: ' DPD In Last 3 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_secured_cb2',
        title: ' DPD In Last 3 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_unsecured_cb2',
        title: ' DPD In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_30_days_unsecured_cb2',
        title: ' DPD In Last 30 Days Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb2',
        title: ' DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_unsecured_cb2',
        title: ' DPD In Last 36 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_cb2',
        title: ' DPD In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_credit_card_cb2',
        title: ' DPD In Last 6 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_secured_cb2',
        title: ' DPD In Last 6 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_unsecured_cb2',
        title: ' DPD In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_9_months_cb2',
        title: ' DPD In Last 9 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_bureau_30_days_cb2',
        title: ' Enquiries Bureau 30 Days ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_1_months_unsecured_cb2',
        title: ' Enquiries In Last 1 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_cb2',
        title: ' Enquiries In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_cb2',
        title: ' Enquiries In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_excl_cc_cb2',
        title: ' Enquiries In Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_30_days_unsecured_excl_cc_cb2',
        title: ' Enquiries In Last 30 Days Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_cb2',
        title: ' Enquiries In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_cb2',
        title: ' Enquiries In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_excl_cc_cb2',
        title: ' Enquiries In Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_cb2',
        title: ' Loans Opened Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_unsecured_excl_cc_cb2',
        title: ' Loans Opened Last 12 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_cb2',
        title: ' Loans Opened Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_unsecured_excl_cc_cb2',
        title: ' Loans Opened Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_cb2',
        title: ' Loans Opened Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_unsecured_excl_cc_cb2',
        title: ' Loans Opened Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'ninety_plus_dpd_in_last_24_months_cb2',
        title: ' Ninety Plus DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_amount_cb2',
        title: ' Suitfiled Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_flag_cb2',
        title: ' Suitfiled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'sum_of_credit_balance_in_all_credit_cards_cb2',
        title: ' Sum Of Credit Balance In All Credit Cards ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_overdues_in_cc_cb2',
        title: ' Total Overdues In CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'unsecured_outstanding_loan_amt_cb2',
        title: ' Unsecured Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_cb2',
        title: ' Written Off Settled ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_cb2',
        title: ' Written Off Settled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_cb2',
        title: ' DPD In Last 36 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_credit_card_cb2',
        title: ' DPD In Last 36 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb2',
        title: ' DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc179',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'curren_emi_cb2',
        title: ' Curren Emi ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_24m_cb2',
        title: ' Max Emi Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_12m_cb2',
        title: ' Max Emi Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'emi_drop_next_6m_cb2',
        title: ' Emi Drop Next 6m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_12m_cb2',
        title: ' SMA Flag Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_24m_cb2',
        title: ' SMA Flag Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_36m_cb2',
        title: ' SMA Flag Last 36m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Co-Applicant 3',
    data: [
      {
        field: '1+dpd_oustanding_perc_cb3',
        title: '  1 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '30+dpd_oustanding_perc_cb3',
        title: '  30 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '60+dpd_oustanding_perc_cb3',
        title: '  60 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '90+dpd_oustanding_perc_cb3',
        title: '  90 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_secured_loan_or_credit_card_cb3',
        title: '  Active Secured Loan Or Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_outstanding_loan_amt_cb3',
        title: '  Bureau Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_score_cb3',
        title: '  Bureau Score ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cnt_active_unsecured_loans_cb3',
        title: '  Cnt Active Unsecured Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'count_total_active_loans_cb3',
        title: '  Count Total Active Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'credit_card_settlement_amount_cb3',
        title: '  Credit Card Settlement Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'current_overdue_value_cb3',
        title: '  Current Overdue Value ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_1_months_secured_cb3',
        title: '  DPD In Last 1 Month Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_cb3',
        title: '  DPD In Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_credit_card_cb3',
        title: '  DPD In Last 12 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_secured_cb3',
        title: '  DPD In Last 12 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_unsecured_cb3',
        title: '  DPD In Last 12 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_cb3',
        title: '  DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_secured_cb3',
        title: '  DPD In Last 24 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_unsecured_cb3',
        title: '  DPD In Last 24 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_cb3',
        title: '  DPD In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_credit_card_cb3',
        title: '  DPD In Last 3 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_secured_cb3',
        title: '  DPD In Last 3 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_unsecured_cb3',
        title: '  DPD In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_30_days_unsecured_cb3',
        title: '  DPD In Last 30 Days Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb3',
        title: '  DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_unsecured_cb3',
        title: '  DPD In Last 36 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_cb3',
        title: '  DPD In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_credit_card_cb3',
        title: '  DPD In Last 6 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_secured_cb3',
        title: '  DPD In Last 6 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_unsecured_cb3',
        title: '  DPD In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_9_months_cb3',
        title: '  DPD In Last 9 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_bureau_30_days_cb3',
        title: '  Enquiries Bureau 30 Days ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_1_months_unsecured_cb3',
        title: '  Enquiries In Last 1 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_cb3',
        title: '  Enquiries In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_cb3',
        title: '  Enquiries In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_excl_cc_cb3',
        title: '  Enquiries In Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_30_days_unsecured_excl_cc_cb3',
        title: '  Enquiries In Last 30 Days Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_cb3',
        title: '  Enquiries In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_cb3',
        title: '  Enquiries In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_excl_cc_cb3',
        title: '  Enquiries In Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_cb3',
        title: '  Loans Opened Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_unsecured_excl_cc_cb3',
        title: '  Loans Opened Last 12 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_cb3',
        title: '  Loans Opened Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_unsecured_excl_cc_cb3',
        title: '  Loans Opened Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_cb3',
        title: '  Loans Opened Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_unsecured_excl_cc_cb3',
        title: '  Loans Opened Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'ninety_plus_dpd_in_last_24_months_cb3',
        title: '  Ninety Plus DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_amount_cb3',
        title: '  Suitfiled Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_flag_cb3',
        title: '  Suitfiled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'sum_of_credit_balance_in_all_credit_cards_cb3',
        title: '  Sum Of Credit Balance In All Credit Cards ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_overdues_in_cc_cb3',
        title: '  Total Overdues In CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'unsecured_outstanding_loan_amt_cb3',
        title: '  Unsecured Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_cb3',
        title: '  Written Off Settled ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_cb3',
        title: '  Written Off Settled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_cb3',
        title: '  DPD In Last 36 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_credit_card_cb3',
        title: '  DPD In Last 36 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb3',
        title: '  DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc179',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'curren_emi_cb3',
        title: '  Curren Emi ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_24m_cb3',
        title: '  Max Emi Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_12m_cb3',
        title: '  Max Emi Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'emi_drop_next_6m_cb3',
        title: '  Emi Drop Next 6m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_12m_cb3',
        title: '  SMA Flag Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_24m_cb3',
        title: '  SMA Flag Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_36m_cb3',
        title: '  SMA Flag Last 36m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Co-Applicant 4',
    data: [
      {
        field: '1+dpd_oustanding_perc_cb4',
        title: '    1 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '30+dpd_oustanding_perc_cb4',
        title: '    30 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '60+dpd_oustanding_perc_cb4',
        title: '    60 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '90+dpd_oustanding_perc_cb4',
        title: '    90 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_secured_loan_or_credit_card_cb4',
        title: '    Active Secured Loan Or Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_outstanding_loan_amt_cb4',
        title: '    Bureau Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_score_cb4',
        title: '    Bureau Score ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cnt_active_unsecured_loans_cb4',
        title: '    Cnt Active Unsecured Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'count_total_active_loans_cb4',
        title: '    Count Total Active Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'credit_card_settlement_amount_cb4',
        title: '    Credit Card Settlement Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'current_overdue_value_cb4',
        title: '    Current Overdue Value ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_1_months_secured_cb4',
        title: '    DPD In Last 1 Month Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_cb4',
        title: '    DPD In Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_credit_card_cb4',
        title: '    DPD In Last 12 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_secured_cb4',
        title: '    DPD In Last 12 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_unsecured_cb4',
        title: '    DPD In Last 12 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_cb4',
        title: '    DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_secured_cb4',
        title: '    DPD In Last 24 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_unsecured_cb4',
        title: '    DPD In Last 24 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_cb4',
        title: '    DPD In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_credit_card_cb4',
        title: '    DPD In Last 3 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_secured_cb4',
        title: '    DPD In Last 3 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_unsecured_cb4',
        title: '    DPD In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_30_days_unsecured_cb4',
        title: '    DPD In Last 30 Days Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb4',
        title: '    DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_unsecured_cb4',
        title: '    DPD In Last 36 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_cb4',
        title: '    DPD In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_credit_card_cb4',
        title: '    DPD In Last 6 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_secured_cb4',
        title: '    DPD In Last 6 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_unsecured_cb4',
        title: '    DPD In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_9_months_cb4',
        title: '    DPD In Last 9 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_bureau_30_days_cb4',
        title: '    Enquiries Bureau 30 Days ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_1_months_unsecured_cb4',
        title: '    Enquiries In Last 1 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_cb4',
        title: '    Enquiries In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_cb4',
        title: '    Enquiries In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_excl_cc_cb4',
        title: '    Enquiries In Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_30_days_unsecured_excl_cc_cb4',
        title: '    Enquiries In Last 30 Days Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_cb4',
        title: '    Enquiries In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_cb4',
        title: '    Enquiries In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_excl_cc_cb4',
        title: '    Enquiries In Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_cb4',
        title: '    Loans Opened Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_unsecured_excl_cc_cb4',
        title: '    Loans Opened Last 12 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_cb4',
        title: '    Loans Opened Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_unsecured_excl_cc_cb4',
        title: '    Loans Opened Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_cb4',
        title: '    Loans Opened Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_unsecured_excl_cc_cb4',
        title: '    Loans Opened Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'ninety_plus_dpd_in_last_24_months_cb4',
        title: '    Ninety Plus DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_amount_cb4',
        title: '    Suitfiled Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_flag_cb4',
        title: '    Suitfiled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'sum_of_credit_balance_in_all_credit_cards_cb4',
        title: '    Sum Of Credit Balance In All Credit Cards ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_overdues_in_cc_cb4',
        title: '    Total Overdues In CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'unsecured_outstanding_loan_amt_cb4',
        title: '    Unsecured Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_cb4',
        title: '    Written Off Settled ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_cb4',
        title: '    Written Off Settled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_cb4',
        title: '    DPD In Last 36 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_credit_card_cb4',
        title: '    DPD In Last 36 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb4',
        title: '    DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc179',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'curren_emi_cb4',
        title: '    Curren Emi ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_24m_cb4',
        title: '    Max Emi Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_12m_cb4',
        title: '    Max Emi Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'emi_drop_next_6m_cb4',
        title: '    Emi Drop Next 6m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_12m_cb4',
        title: '    SMA Flag Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_24m_cb4',
        title: '    SMA Flag Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_36m_cb4',
        title: '    SMA Flag Last 36m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
  {
    title: 'Co-Applicant 5',
    data: [
      {
        field: '1+dpd_oustanding_perc_cb5',
        title: '      1 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '30+dpd_oustanding_perc_cb5',
        title: '      30 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '60+dpd_oustanding_perc_cb5',
        title: '      60 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: '90+dpd_oustanding_perc_cb5',
        title: '      90 + DPD Outstanding Perc ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'active_secured_loan_or_credit_card_cb5',
        title: '      Active Secured Loan Or Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_outstanding_loan_amt_cb5',
        title: '      Bureau Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'bureau_score_cb5',
        title: '      Bureau Score ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'cnt_active_unsecured_loans_cb5',
        title: '      Cnt Active Unsecured Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'count_total_active_loans_cb5',
        title: '      Count Total Active Loans ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'credit_card_settlement_amount_cb5',
        title: '      Credit Card Settlement Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'current_overdue_value_cb5',
        title: '      Current Overdue Value ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_1_months_secured_cb5',
        title: '      DPD In Last 1 Month Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_cb5',
        title: '      DPD In Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_credit_card_cb5',
        title: '      DPD In Last 12 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_secured_cb5',
        title: '      DPD In Last 12 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_12_months_unsecured_cb5',
        title: '      DPD In Last 12 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_cb5',
        title: '      DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_secured_cb5',
        title: '      DPD In Last 24 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_24_months_unsecured_cb5',
        title: '      DPD In Last 24 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_cb5',
        title: '      DPD In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_credit_card_cb5',
        title: '      DPD In Last 3 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_secured_cb5',
        title: '      DPD In Last 3 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_3_months_unsecured_cb5',
        title: '      DPD In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_30_days_unsecured_cb5',
        title: '      DPD In Last 30 Days Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb5',
        title: '      DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_unsecured_cb5',
        title: '      DPD In Last 36 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_cb5',
        title: '      DPD In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_credit_card_cb5',
        title: '      DPD In Last 6 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_secured_cb5',
        title: '      DPD In Last 6 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_6_months_unsecured_cb5',
        title: '      DPD In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_9_months_cb5',
        title: '      DPD In Last 9 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_bureau_30_days_cb5',
        title: '      Enquiries Bureau 30 Days ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_1_months_unsecured_cb5',
        title: '      Enquiries In Last 1 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_cb5',
        title: '      Enquiries In Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_cb5',
        title: '      Enquiries In Last 3 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_3_months_unsecured_excl_cc_cb5',
        title: '      Enquiries In Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_30_days_unsecured_excl_cc_cb5',
        title: '      Enquiries In Last 30 Days Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_cb5',
        title: '      Enquiries In Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_cb5',
        title: '      Enquiries In Last 6 Months Unsecured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'enquiries_in_last_6_months_unsecured_excl_cc_cb5',
        title: '      Enquiries In Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_cb5',
        title: '      Loans Opened Last 12 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_12_months_unsecured_excl_cc_cb5',
        title: '      Loans Opened Last 12 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_cb5',
        title: '      Loans Opened Last 3 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_3_months_unsecured_excl_cc_cb5',
        title: '      Loans Opened Last 3 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_cb5',
        title: '      Loans Opened Last 6 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'loans_opened_last_6_months_unsecured_excl_cc_cb5',
        title: '      Loans Opened Last 6 Months Unsecured Excl CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'ninety_plus_dpd_in_last_24_months_cb5',
        title: '      Ninety Plus DPD In Last 24 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_amount_cb5',
        title: '      Suitfiled Amount ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'suitfiled_flag_cb5',
        title: '      Suitfiled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'sum_of_credit_balance_in_all_credit_cards_cb5',
        title: '      Sum Of Credit Balance In All Credit Cards ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'total_overdues_in_cc_cb5',
        title: '      Total Overdues In CC ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'unsecured_outstanding_loan_amt_cb5',
        title: '      Unsecured Outstanding Loan Amt ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_cb5',
        title: '      Written Off Settled ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'written_off_settled_flag_cb5',
        title: '      Written Off Settled Flag ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_cb5',
        title: '      DPD In Last 36 Months ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_credit_card_cb5',
        title: '      DPD In Last 36 Months Credit Card ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'dpd_in_last_36_months_secured_cb5',
        title: '      DPD In Last 36 Months Secured ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc179',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'curren_emi_cb5',
        title: '      Curren Emi ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_24m_cb5',
        title: '      Max Emi Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'max_emi_last_12m_cb5',
        title: '      Max Emi Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'emi_drop_next_6m_cb5',
        title: '      Emi Drop Next 6m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_12m_cb5',
        title: '      SMA Flag Last 12m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_24m_cb5',
        title: '      SMA Flag Last 24m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
      {
        field: 'SMA_Flag_last_36m_cb5',
        title: '      SMA Flag Last 36m ',
        type: 'string',
        validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
        isOptional: 'FALSE',
        isCommon: 'TRUE',
        initialValue: '',
      },
    ],
  },
];

export { GSTData, CamsData, FinancialData, ConsumerBureau };
