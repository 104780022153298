import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AlertBox } from '../../components/AlertBox';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { storedList } from '../../util/localstorage';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button/Button';
import Table from 'react-sdk/dist/components/Table/Table';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import UploadPopup from 'react-sdk/dist/components/Popup/UploadPopup';
import Img from '../lending/images/document-upload.svg';
import Imgh from '../lending/images/document-upload.svg';
import DownloadImg from '../lending/images/download-button.svg';
import { fetchLocBulkUploadDataWatcher, locDrawdownBulkUploadWatcher, locDownloadBulkUploadWatcher, downloadLocBulkSampleFileWatcher } from '../../actions/locDrawdownBulkUpload';

const BulkUpload = () => {
  const user = storedList('user');
  const { company_id, product_id, loan_id } = useParams();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState('');
  const [data, setData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [file, setFile] = useState(null);
  const [filetype_name, setFileType_name] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileExtensionType, setFileExtensionType] = useState('');
  const [fileBase64, setFileBase64] = useState('');
  const [allowedFileType, setAllowedFileType] = useState('');
  const [saving, setSaving] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [isOpenRemarks, setIsOpenRemarks] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogRemarks, setOpenDialogRemarks] = useState(false);

  const columns = [
    {
      label: <span style={{ fontSize: '15px' }}>{'FILE NAME'}</span>,
      format: (fileDetail) => (
        <>
          <style>
            {`
                 div.namespan {
                     white-space: nowrap; 
                     overflow: hidden; 
                     color: #475BD8;
                     width: 110.4px;
                     max-width : 110.4px;
                     text-overflow: ellipsis;
                 }
                 div.namespan .link {
                     display:"flex";
                     visibility: hidden;
                     color:white;
                     background-color: #152067;
                     text-align: center;
                     border-radius: 8px;
                     padding: 5px 0;
                     position: absolute;
                     z-index: 1;
                     padding: 10px;
                     left: 5%;
                     margin-top: -4%;
                 }
                 div.namespan .link::after {
                     content: " ";
                     position: absolute;
                     top: 100%;
                     left: 18%;
                     margin-left: -10px;
                     border-width: 10px;
                     border-style: solid;
                     border-color: #152067 transparent transparent transparent;
                 }
                 div.namespan:hover .link {
                     visibility: visible;
                 }
               `}
          </style>
          <div className="namespan">
            <span>
              <Link onClick={() => handleFileDownload(fileDetail._id)}>{fileDetail.file_name}</Link>
            </span>
            <span className="link">{fileDetail.file_name}</span>
          </div>
        </>
      ),
    },
    {
      label: <span style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>{'FILE TYPE'}</span>,
      format: (fileDetail) => <span>{fileDetail.file_type}</span>,
    },
    {
      label: <span style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>{'UPLOAD DATE'}</span>,
      format: (fileDetail) => <span>{moment(fileDetail.created_at).format('DD-MM-YYYY')}</span>,
    },
    {
      label: <span style={{ fontSize: '15px' }}>{'RECORDS'}</span>,
      format: (fileDetail) => <span>{fileDetail.total_records}</span>,
    },
    {
      label: <span style={{ fontSize: '15px' }}>{'SUCCESSFUL RECORDS'}</span>,
      format: (fileDetail) => <span>{fileDetail.total_success_records}</span>,
    },
    {
      label: <span style={{ fontSize: '15px' }}>{'FAILED RECORDS'}</span>,
      format: (fileDetail) => <span>{fileDetail.total_failure_records}</span>,
    },
    {
      label: <span style={{ fontSize: '15px' }}>{'VALIDATION STATUS'}</span>,
      format: (fileDetail) => <span>{fileDetail.validation_status}</span>,
    },
    {
      label: <span style={{ fontSize: '15px' }}>{'REMARK'}</span>,
      format: (fileDetail) => (
        <>
          <style>
            {`
                     div.remark::before {
                       display: flex;
                       justify-content: center;
                       content: "!";
                       border-radius: 60%;
                       border: 1px solid #475BD8;
                       width: 1.375rem;
                       height: 1.375rem;
                       text-align: center;
                       color: #475BD8;
                       font-weight: 600;
                     }
                   `}
          </style>
          <div
            className="remark"
            onClick={() => {
              setIsOpenRemarks(!isOpenRemarks);
              setOpenDialogRemarks(!openDialogRemarks);
              handleRemarks(fileDetail);
            }}
          ></div>
        </>
      ),
    },
  ];

  const validationStatusType = [
    {
      label: 'Approved',
      value: 'Approved',
    },
    {
      label: 'Fail',
      value: 'Fail',
    },
    {
      label: 'Success',
      value: 'Success',
    },
    {
      label: 'Awaiting for approval',
      value: 'Awaiting for approval',
    },
    {
      label: 'Rejected',
      value: 'Rejected',
    },
  ];

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 4000);
  };
  const handleFileDownload = (id) => {
    let data = {
      id: id,
      user_id: user._id,
      company_id: company_id,
      product_id: product_id,
    };
    new Promise((resolve, reject) => {
      dispatch(locDownloadBulkUploadWatcher(data, resolve, reject));
    })
      .then((response) => {
        window.open(response, '_blank');
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  useEffect(() => {
    getBulkData();
  }, []);

  const getBulkData = () => {
    const payload = {
      user_id: user._id,
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
    };
    new Promise((resolve, reject) => {
      dispatch(fetchLocBulkUploadDataWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setCount(response?.data?.count);
        setMasterData(response?.data?.rows);
        setData(response?.data?.rows);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleFilterChange = (e) => {
    setFilter(e.value);
    setColumn(null);
  };

  const handleColumnChange = (e) => {
    setColumn(e.value);
  };

  const handleFiletypeChange = (value) => {
    if (value.label === 'Drawdown') {
      setFileType_name('Drawdown');
      setAllowedFileType('.csv');
      setFileExtensionType('csv');
    }
  };

  const handleReset = () => {
    setColumn('');
    setFilter('');
    setData(masterData);
  };

  const handleRemarks = (data) => {
    setRemarks(data.remarks);
  };

  const filterData = (item) => {
    if (filter === 'file_type' && item.file_type == column) {
      return true;
    }
    if (filter === 'validation_status' && item.validation_status == column) {
      return true;
    }
    if (filter === 'upload_date' && column.trim() === moment(item.created_at).format('DD-MM-YYYY')) {
      return true;
    }
    return false;
  };

  const handleSearch = () => {
    if (column != 0 && !column) {
      showAlert('Please enter value', 'error');
    } else if (!filter) {
      showAlert('Please select filter', 'error');
    } else if (!filter && !column) {
      showAlert('Please select filter', 'error');
    } else {
      setData(masterData.filter(filterData));
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setFile(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      setFileBase64(reader.result);
    };
    setFileName(fileObj.name);
    event.target.value = null;
  };

  const handleUpload = () => {
    const data = {
      user_id: user._id,
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
      base64: fileBase64.toString(),
      file_type: filetype_name,
      file_name: fileName,
      file_extension_type: fileExtensionType,
    };
    new Promise((resolve, reject) => {
      dispatch(locDrawdownBulkUploadWatcher(data, resolve, reject));
    })
      .then(() => {
        showAlert('File uploaded', 'success');
        setSaving(false);
        setisOpen(!isOpen);
        setFileBase64('');
        setFile(null);
        setFileName('');
        setPage(0);
        setRowsPerPage(10);
        getBulkData();
      })
      .catch((error) => {
        showAlert(error.response.data.message, 'error');
        setSaving(false);
        setFileBase64('');
        setFile(null);
        setFileName('');
      });
  };

  const handleClose = () => {
    setOpenDialog(!openDialog);
    setisOpen(!isOpen);
    setFileBase64('');
    setFile(null);
    setFileName('');
    setAllowedFileType('');
    setFileExtensionType('');
  };

  const handleCloseRemarks = () => {
    setIsOpenRemarks(!isOpenRemarks);
    setOpenDialogRemarks(!openDialogRemarks);
  };
  const handleDownloadSample = () => {
    let payload = {
      user_id: user._id,
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
    };
    new Promise((resolve, reject) => {
      dispatch(downloadLocBulkSampleFileWatcher(payload, resolve, reject));
    })
      .then((response) => {
        const buffer = new Buffer.from(response, 'base64');
        const blob = new Blob([buffer], { type: 'text/csv' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = 'sample-file';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message ?? 'Something went wrong!', 'error');
      });
  };

  const renderBulkUploadFile = () => (
    <UploadPopup
      heading="Bulk Upload File"
      isOpen={isOpen}
      onClose={handleClose}
      customStyles={{
        height: '59rem',
        position: 'absolute',
        width: '543px',
        right: 0,
        top: 0,
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      accept={allowedFileType}
      onUpload={handleUpload}
      onFileSelect={handleFileChange}
      filename={fileName}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '60%', marginTop: '2.5%' }}>
        <InputBox
          label="Select File type"
          isDrawdown={true}
          initialValue={''}
          options={uploadFileType}
          customClass={{
            minWidth: '100%',
            height: '3.5rem',
            borderRadius: '8px',
            float: 'left',
            fontSize: '17px',
          }}
          customDropdownClass={{ marginTop: '8px', zIndex: '1' }}
          onClick={handleFiletypeChange}
        ></InputBox>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '8px',
          position: 'relative',
          top: '17rem',
          cursor: 'pointer',
        }}
        onClick={handleDownloadSample}
      >
        <img src={DownloadImg} alt="Download" style={{ marginRight: '8px' }} />
        <span style={{ color: '#475BD8', fontSize: '16px' }}>sample</span>
      </div>
    </UploadPopup>
  );

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>{alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}</div>
      {openDialogRemarks && (
        <FormPopup onClose={handleCloseRemarks} isOpen={openDialogRemarks} heading={'Remarks'}>
          <span>{remarks}</span>
        </FormPopup>
      )}
      <div style={{ display: 'flex', marginLeft: '16px', marginTop: '16px', flexDirection: 'column' }}>
        {isOpen ? renderBulkUploadFile() : null}
        <div style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}>
          <div>
            <div style={{ float: 'left', display: 'flex', gap: '16px' }}>
              <InputBox
                label="Filter"
                isDrawdown={true}
                options={filters}
                onClick={handleFilterChange}
                initialValue={''}
                customClass={{
                  width: '220px',
                  height: '56px',
                  borderRadius: '8px',
                  fontSize: '17px',
                }}
                customDropdownClass={{ marginTop: '8px', zIndex: '1' }}
              ></InputBox>
              {filter === 'file_type' || filter === 'validation_status' ? (
                <InputBox
                  label="Filter"
                  isDrawdown={true}
                  options={filter === 'file_type' ? uploadFileType : validationStatusType}
                  onClick={handleColumnChange}
                  initialValue={''}
                  customClass={{
                    width: '220px',
                    height: '56px',
                    borderRadius: '8px',
                    fontSize: '17px',
                  }}
                  customDropdownClass={{ marginTop: '8px', zIndex: '1' }}
                ></InputBox>
              ) : (
                <InputBox
                  label="Value"
                  onClick={handleColumnChange}
                  initialValue={column}
                  customClass={{
                    width: '220px',
                    height: '56px',
                    borderRadius: '8px',
                    fontSize: '17px',
                  }}
                ></InputBox>
              )}
              <Button
                label="Search"
                buttonType="primary"
                onClick={handleSearch}
                customStyle={{
                  width: '120px',
                  height: '56px',
                  borderRadius: '8px',
                  fontSize: '15px',
                }}
              ></Button>
              <Button
                label="Reset"
                buttonType="secondarys"
                onClick={handleReset}
                customStyle={{
                  width: '120px',
                  height: '56px',
                  color: '#475BD8',
                  borderRadius: '8px',
                  backgroundColor: '#FFFFFF',
                  fontSize: '15px',
                  marginLeft: '-6px',
                  borderRadius: '8px',
                  border: '1px solid #475BD8',
                }}
              ></Button>
            </div>
            <div style={{ float: 'right', marginRight: '25px' }}>
              <Button
                imageButton={Img}
                imageButtonHover={Imgh}
                iconButton="btn-secondary-download-button"
                label="Upload File"
                buttonType="primary"
                customStyle={{
                  width: '150px',
                  height: '56px',
                  padding: '0px 0px 0px 0px',
                  borderRadius: '8px',
                  fontSize: '15px',
                  display: 'flex',
                }}
                onClick={() => {
                  setisOpen(!isOpen);
                  setOpenDialog(!openDialog);
                }}
              ></Button>
            </div>
          </div>
          {data.length ? (
            <div>
              <Table data={data.slice(page * rowsPerPage, (page + 1) * rowsPerPage)} columns={columns} customStyle={{ width: '98%' }}></Table>
            </div>
          ) : null}
          {data.length ? (
            <div>
              <Pagination onPageChange={(newPage) => handleChangePage(newPage, rowsPerPage)} totalItems={count} itemsPerPage={rowsPerPage} showOptions={true} rowsPerPageOptions={[5, 10, 15]} setRowLimit={setRowsPerPage}></Pagination>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const filters = [
  { label: 'File Type', value: 'file_type' },
  { label: 'Validation Status', value: 'validation_status' },
  { label: 'Upload Date', value: 'upload_date' },
];

const uploadFileType = [{ label: 'Drawdown', value: 'Drawdown' }];
export default BulkUpload;
