import { remarkReason } from '../../../config/remarkReason';

export const COMMENT_FIELDS = {
  reasons: {
    label: 'Select reasons ',
    name: 'reasons',
    id: 'reasons',
    type: 'text',
    remarks: 'text',
    placeholder: 'Select reasons',
    isRequired: false,
    category: 'dropdown',
    section: ['reject'],
    options: Object.entries(remarkReason).map(([key, value]) => {
      return {
        label: value,
        value: key,
      };
    }),
  },
  comment: {
    label: 'Comment',
    name: 'comment',
    id: 'comment',
    type: 'text',
    remarks: 'text',
    placeholder: 'Comment',
    isRequired: false,
    section: ['addComment'],
  },
  remarks: {
    label: 'Remarks',
    name: 'remarks',
    id: 'remarks',
    type: 'text',
    remarks: 'text',
    placeholder: 'Remarks',
    isRequired: false,
    section: ['reject'],
  },
};
