// data.js

import { documentCode as documentCodeList } from '../../config/docCode';

// export const FSInputTittle = [
//   {
//     id: "fs1",
//     name: "FS 1st Year",
//     fileSize: "PDF upto 10MB",
//     acceptFileType: ".pdf",
//     fileType: "file",
//     documentCode: documentCodeList.financial_statement1
//   },
//   {
//     id: "fs2",
//     name: "FS 2nd Year",
//     fileSize: "PDF upto 10MB",
//     acceptFileType: ".pdf",
//     fileType: "file",
//     documentCode: documentCodeList.financial_statement2

//   },
//   {
//     id: "fs3",
//     name: "FS 3rd Year",
//     fileSize: "PDF upto 10MB",
//     acceptFileType: ".pdf",
//     fileType: "file",
//     documentCode: documentCodeList.financial_statement3

//   },
// ];

export const ITRInputTittle = [
  {
    id: 'itr1',
    name: 'ITR 1st Year',
    fileSize: 'PDF upto 10MB',
    acceptFileType: '.pdf',
    fileType: 'file',
    documentCode: documentCodeList.itr1,
  },
  {
    id: 'itr2',
    name: 'ITR 2nd Year',
    fileSize: 'PDF upto 10MB',
    acceptFileType: '.pdf',
    fileType: 'file',
    documentCode: documentCodeList.itr2,
  },
];

export const GSTInputArray = [
  {
    id: 'gst1',
    name: 'GST Certificate',
    fileSize: 'JPG, JPEG, PNG, PDF upto 10MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: documentCodeList.gst_certificate,
  },
  {
    id: 'gst2',
    name: 'GSTR',
    fileSize: 'PDF upto 10MB',
    acceptFileType: '.pdf',
    fileType: 'file',
    documentCode: documentCodeList.gstr,
  },
];

export const BankList = [
  {
    label: 'Abhyudaya Co-operative Bank',
    value: 'FI00088',
  },
  {
    label: 'Abu Dhabi Commercial Bank',
    value: 'FI00202',
  },
  {
    label: 'Adarsh Co-operative Bank',
    value: 'FI00254',
  },
  {
    label: 'Ahmednagar Merchant Co-operative Bank',
    value: 'FI00207',
  },
  {
    label: 'Ahmednagar Shahar Sahakari Bank',
    value: 'FI00229',
  },
  {
    label: 'Akhand Anand Co-operative Bank',
    value: 'FI00147',
  },
  {
    label: 'Allahabad Bank',
    value: 'FI00002',
  },
  {
    label: 'Ambajogai Peoples Co-operative Bank',
    value: 'FI00242',
  },
  {
    label: 'Ambernath Jai Hind Co-operative Bank',
    value: 'FI00252',
  },
  {
    label: 'Andhra Bank',
    value: 'FI00003',
  },
  {
    label: 'Andhra Pragathi Grameena Bank',
    value: 'FI00125',
  },
  {
    label: 'Andhrapradesh Grameena Vikas Bank',
    value: 'FI00204',
  },
  {
    label: 'Annasaheb Magar Sahakari Bank',
    value: 'FI00151',
  },
  {
    label: 'Apna Sahakari Bank',
    value: 'FI00089',
  },
  {
    label: 'Associate Co-operative Bank',
    value: 'FI00191',
  },
  {
    label: 'AU Small Finance Bank',
    value: 'FI00004',
  },
  {
    label: 'Axis Bank',
    value: 'FI00005',
  },
  {
    label: 'Bandhan Bank',
    value: 'FI00008',
  },
  {
    label: 'Bank of America',
    value: 'FI00144',
  },
  {
    label: 'Bank of Baroda',
    value: 'FI00009',
  },
  {
    label: 'Bank of India',
    value: 'FI00010',
  },
  {
    label: 'Bank of Maharashtra',
    value: 'FI00011',
  },
  {
    label: 'Bassein Catholic Co-operative Bank',
    value: 'FI00127',
  },
  {
    label: 'Bhagini Nivedita Sahakari Bank',
    value: 'FI00236',
  },
  {
    label: 'Bharat Co-operative Bank',
    value: 'FI00100',
  },
  {
    label: 'Bharati Sahakari Bank',
    value: 'FI00150',
  },
  {
    label: 'BNI Bank',
    value: 'FI00139',
  },
  {
    label: 'BNP Paribas',
    value: 'FI00120',
  },
  {
    label: 'Bombay Mercantile Co-operative Bank',
    value: 'FI00113',
  },
  {
    label: 'Buldana Urban Co-operative Credit Society',
    value: 'FI00226',
  },
  {
    label: 'Canara Bank',
    value: 'FI00012',
  },
  {
    label: 'Capital Small Finance Bank',
    value: 'FI00110',
  },
  {
    label: 'Catholic Syrian Bank',
    value: 'FI00015',
  },
  {
    label: 'Central Bank of India',
    value: 'FI00016',
  },
  {
    label: 'Citi Bank',
    value: 'FI00018',
  },
  {
    label: 'Citizen Credit Co-operative Bank',
    value: 'FI00119',
  },
  {
    label: 'City Union Bank',
    value: 'FI00019',
  },
  {
    label: 'Corporation Bank',
    value: 'FI00020',
  },
  {
    label: 'Cosmos Bank',
    value: 'FI00080',
  },
  {
    label: 'CTBC Bank',
    value: 'FI00220',
  },
  {
    label: 'DBS Bank',
    value: 'FI00021',
  },
  {
    label: 'DCB Bank',
    value: 'FI00022',
  },
  {
    label: 'Dena Bank',
    value: 'FI00023',
  },
  {
    label: 'Deutsche Bank',
    value: 'FI00024',
  },
  {
    label: 'Dhanlaxmi Bank',
    value: 'FI00025',
  },
  {
    label: 'DMK Jaoli Bank',
    value: 'FI00223',
  },
  {
    label: 'Dombivli Nagari Sahakari Bank',
    value: 'FI00114',
  },
  {
    label: 'DSP Co-operative Bank',
    value: 'FI00192',
  },
  {
    label: 'Equitas Small Finance Bank',
    value: 'FI00122',
  },
  {
    label: 'ESAF Small Finance Bank',
    value: 'FI00186',
  },
  {
    label: 'Excellent Co-operative Bank',
    value: 'FI00161',
  },
  {
    label: 'Federal Bank',
    value: 'FI00028',
  },
  {
    label: 'Fincare Small Finance Bank',
    value: 'FI00184',
  },
  {
    label: 'Fingrowth Co-operative Bank',
    value: 'FI00167',
  },
  {
    label: 'Five Star Bank',
    value: 'FI00243',
  },
  {
    label: 'GP Parsik Bank',
    value: 'FI00079',
  },
  {
    label: 'GS Mahanagar Co-operative Bank',
    value: 'FI00126',
  },
  {
    label: 'Gujarat Ambuja Co-operative Bank',
    value: 'FI00232',
  },
  {
    label: 'Gujarat State Co-operative Bank',
    value: 'FI00117',
  },
  {
    label: 'HDFC Bank',
    value: 'FI00030',
  },
  {
    label: 'HSBC Bank',
    value: 'FI00032',
  },
  {
    label: 'ICICI Bank',
    value: 'FI00033',
  },
  {
    label: 'IDBI Bank',
    value: 'FI00034',
  },
  {
    label: 'IDFC First Bank',
    value: 'FI00035',
  },
  {
    label: 'Indian Bank',
    value: 'FI00039',
  },
  {
    label: 'Indian Overseas Bank',
    value: 'FI00040',
  },
  {
    label: 'Indrayani Co-operative Bank',
    value: 'FI00136',
  },
  {
    label: 'IndusInd Bank',
    value: 'FI00042',
  },
  {
    label: 'J&K Bank',
    value: 'FI00044',
  },
  {
    label: 'Jana Small Finance Bank',
    value: 'FI00137',
  },
  {
    label: 'Janakalyan Co-operative Bank',
    value: 'FI00250',
  },
  {
    label: 'Janakalyan Sahakari Bank',
    value: 'FI00164',
  },
  {
    label: 'Janaseva Sahakari Bank',
    value: 'FI00107',
  },
  {
    label: 'Janata Sahakari Bank Pune',
    value: 'FI00106',
  },
  {
    label: 'Kankariaa Maninagar Nagrik Sahakari Bank',
    value: 'FI00175',
  },
  {
    label: 'Karnataka Bank',
    value: 'FI00046',
  },
  {
    label: 'Karnataka Gramin Bank',
    value: 'FI00155',
  },
  {
    label: 'Karur Vysya Bank',
    value: 'FI00047',
  },
  {
    label: 'Kerala Gramin Bank',
    value: 'FI00248',
  },
  {
    label: 'Khamgaon Urban Co-operative Bank',
    value: 'FI00103',
  },
  {
    label: 'Kokan Mercantile Co-operative Bank',
    value: 'FI00171',
  },
  {
    label: 'Kotak Mahindra Bank',
    value: 'FI00048',
  },
  {
    label: 'Kurla Nagrik Sahakari Bank',
    value: 'FI00170',
  },
  {
    label: 'Lakshmi Vilas Bank',
    value: 'FI00051',
  },
  {
    label: 'Maharashtra Gramin Bank',
    value: 'FI00140',
  },
  {
    label: 'Mahesh Bank',
    value: 'FI00118',
  },
  {
    label: 'Mahesh Sahakari Bank',
    value: 'FI00143',
  },
  {
    label: 'Malviya Urban Co-operative Bank',
    value: 'FI00227',
  },
  {
    label: 'Mangal Co-operative Bank',
    value: 'FI00219',
  },
  {
    label: 'Model Co-operative Bank',
    value: 'FI00196',
  },
  {
    label: 'Msant Sopankaka Sahakari Bank',
    value: 'FI00101',
  },
  {
    label: 'MUFG Bank',
    value: 'FI00138',
  },
  {
    label: 'Mumbai District Central Co-operative Bank',
    value: 'FI00197',
  },
  {
    label: 'Nagarik Sahakari Bank',
    value: 'FI00240',
  },
  {
    label: 'Nagpur Nagarik Sahakari Bank',
    value: 'FI00172',
  },
  {
    label: 'Nainital Bank',
    value: 'FI00055',
  },
  {
    label: 'New India Co-operative Bank',
    value: 'FI00145',
  },
  {
    label: 'Nidhi Co-operative Bank',
    value: 'FI00131',
  },
  {
    label: 'NKGSB Co-operative Bank',
    value: 'FI00132',
  },
  {
    label: 'Nutan Nagarik Sahakari Bank',
    value: 'FI00112',
  },
  {
    label: 'Omprakash Deora Peoples Co-operative Bank',
    value: 'FI00246',
  },
  {
    label: 'Oriental Bank of Commerce',
    value: 'FI00056',
  },
  {
    label: 'Pandharpur Urban Co-operative Bank',
    value: 'FI00104',
  },
  {
    label: 'Pavana Sahakari Bank',
    value: 'FI00217',
  },
  {
    label: 'Paytm Payment Bank',
    value: 'FI00212',
  },
  {
    label: 'Pimpri Chinchwad Sahakari Bank',
    value: 'FI00149',
  },
  {
    label: 'Prerana Co-operative Bank',
    value: 'FI00230',
  },
  {
    label: 'Prime Co-operative Bank',
    value: 'FI00178',
  },
  {
    label: 'Progressive Co-operative Bank',
    value: 'FI00090',
  },
  {
    label: 'Pune Cantonment Sahakari Bank',
    value: 'FI00234',
  },
  {
    label: 'Pune Urban Co-operative Bank',
    value: 'FI00121',
  },
  {
    label: 'Punjab & Maharashtra Co-operative Bank',
    value: 'FI00091',
  },
  {
    label: 'Punjab & Sind Bank',
    value: 'FI00058',
  },
  {
    label: 'Punjab National Bank',
    value: 'FI00059',
  },
  {
    label: 'Rajarambapu Sahakari Bank',
    value: 'FI00206',
  },
  {
    label: 'Rajarshi Shahu Sahakari Bank',
    value: 'FI00142',
  },
  {
    label: 'Rajgurunagar Sahakari Bank',
    value: 'FI00158',
  },
  {
    label: 'Rajkot Nagarik Sahakari Bank',
    value: 'FI00198',
  },
  {
    label: 'Rajkot Peoples Co-operative Bank',
    value: 'FI00099',
  },
  {
    label: 'Ramrajya Sahakari Bank',
    value: 'FI00237',
  },
  {
    label: 'RBL Bank',
    value: 'FI00060',
  },
  {
    label: 'Retail Bank',
    value: 'FI00093',
  },
  {
    label: 'Sahebrao Deshmukh Co-operative Bank',
    value: 'FI00189',
  },
  {
    label: 'Samata Sahakari Bank',
    value: 'FI00169',
  },
  {
    label: 'Sangli District Central Co-operative Bank',
    value: 'FI00225',
  },
  {
    label: 'Sangli Urban Co-operative Bank',
    value: 'FI00105',
  },
  {
    label: 'Saraspur Nagarik Co-operative Bank',
    value: 'FI00179',
  },
  {
    label: 'Saraswat Co-operative Bank',
    value: 'FI00063',
  },
  {
    label: 'Sarva Haryana Gramin Bank',
    value: 'FI00205',
  },
  {
    label: 'Saurashtra Gramin Bank',
    value: 'FI00097',
  },
  {
    label: 'Shamrao Vithal Co-operative Bank',
    value: 'FI00111',
  },
  {
    label: 'Shikshak Sahakari Bank',
    value: 'FI00203',
  },
  {
    label: 'Shivaji Nagari Sahakari Bank',
    value: 'FI00249',
  },
  {
    label: 'Shree Co-operative Bank',
    value: 'FI00215',
  },
  {
    label: 'Shree Kadi Nagarik Sahakari Bank',
    value: 'FI00094',
  },
  {
    label: 'Shree Parswanath Co-operative Bank',
    value: 'FI00193',
  },
  {
    label: 'Shree Warana Sahakari Bank',
    value: 'FI00141',
  },
  {
    label: 'Shri Adinath Co-operative Bank',
    value: 'FI00177',
  },
  {
    label: 'Shri Arihant Co-operative Bank',
    value: 'FI00129',
  },
  {
    label: 'Shri Chhatrapati Rajarshi Shahu Urban Co- operative Bank',
    value: 'FI00165',
  },
  {
    label: 'Shri Mahalaxmi Co-operative Bank',
    value: 'FI00166',
  },
  {
    label: 'Shrikrishna Co-operative Bank',
    value: 'FI00247',
  },
  {
    label: 'Shushruti Souharda Sahakara Bank',
    value: 'FI00228',
  },
  {
    label: 'SIDBI',
    value: 'FI00069',
  },
  {
    label: 'Silicon Valley Bank',
    value: 'FI00152',
  },
  {
    label: 'Sir M Visvesvaraya Co-operative Bank',
    value: 'FI00098',
  },
  {
    label: 'Solapur Janata Sahakari Bank',
    value: 'FI00102',
  },
  {
    label: 'South Indian Bank',
    value: 'FI00065',
  },
  {
    label: 'Standard Chartered Bank',
    value: 'FI00067',
  },
  {
    label: 'State Bank of India',
    value: 'FI00068',
  },
  {
    label: 'State Bank of Mauritius',
    value: 'FI00092',
  },
  {
    label: 'Sterling Urban Co-operative Bank',
    value: 'FI00128',
  },
  {
    label: 'Sundarlal Sawji Urban Co-operative Bank',
    value: 'FI00083',
  },
  {
    label: 'Surat National Co-operative Bank',
    value: 'FI00124',
  },
  {
    label: 'Suryoday Small Finance Bank',
    value: 'FI00185',
  },
  {
    label: 'Suvrnayug Sahakari Bank',
    value: 'FI00218',
  },
  {
    label: 'Syndicate Bank',
    value: 'FI00071',
  },
  {
    label: 'Tamil Nadu State Apex Co-operative Bank',
    value: 'FI00241',
  },
  {
    label: 'Tamilnad Mercantile Bank',
    value: 'FI00072',
  },
  {
    label: 'Thane Bharat Sahakari Bank',
    value: 'FI00146',
  },
  {
    label: 'The Abhinav Sahakari Bank',
    value: 'FI00133',
  },
  {
    label: 'The Agrasen Co-operative Urban Bank',
    value: 'FI00211',
  },
  {
    label: 'The Ahmedabad District Co-operative Bank',
    value: 'FI00156',
  },
  {
    label: 'The Ahmedabad Mercantile Co-operative Bank',
    value: 'FI00168',
  },
  {
    label: 'The Akola Janata Commercial Co-operative Bank',
    value: 'FI00224',
  },
  {
    label: 'The Akola Urban Co-operative Bank',
    value: 'FI00148',
  },
  {
    label: 'The Bhagyalakshmi Mahila Sahakari Bank',
    value: 'FI00244',
  },
  {
    label: 'The Bhagyodaya Co-operative Bank',
    value: 'FI00251',
  },
  {
    label: 'The Chitnavispura Sahakari Bank',
    value: 'FI00188',
  },
  {
    label: 'The Commercial Co-operative Bank',
    value: 'FI00160',
  },
  {
    label: 'The Co-operative Bank of Rajkot',
    value: 'FI00222',
  },
  {
    label: 'The Dahod Urban Co-operative Bank',
    value: 'FI00239',
  },
  {
    label: 'The Darussalam Co-operative Urban Bank',
    value: 'FI00245',
  },
  {
    label: 'The Deccan Merchants Co-operative Bank',
    value: 'FI00209',
  },
  {
    label: 'The Financial Co-operative Bank',
    value: 'FI00190',
  },
  {
    label: 'The Greater Bombay Co-operative Bank',
    value: 'FI00208',
  },
  {
    label: 'The Hotel Industrialists Co-operative Bank',
    value: 'FI00199',
  },
  {
    label: 'The Jalgaon Peoples Co-operative Bank',
    value: 'FI00180',
  },
  {
    label: 'The Kalupur Commercial Co-operative Bank',
    value: 'FI00087',
  },
  {
    label: 'The Kalyan Janata Sahakari Bank',
    value: 'FI00134',
  },
  {
    label: 'The Kanakamahalakshmi Co-operative Bank',
    value: 'FI00213',
  },
  {
    label: 'The Karad Urban Co-operative Bank',
    value: 'FI00108',
  },
  {
    label: 'The Karnavati Co-operative Bank',
    value: 'FI00231',
  },
  {
    label: 'The Khattri Co-operative Urban Bank',
    value: 'FI00183',
  },
  {
    label: 'The Kolhapur Urban Co-operative Bank',
    value: 'FI00154',
  },
  {
    label: 'The Kukarwada Nagarik Sahakari Bank',
    value: 'FI00173',
  },
  {
    label: 'The Kunbi Sahakari Bank',
    value: 'FI00238',
  },
  {
    label: 'The Malad Sahakari Bank',
    value: 'FI00210',
  },
  {
    label: 'The Mehsana Urban Co-operative Bank',
    value: 'FI00086',
  },
  {
    label: 'The Nasik Merchant Co-operative Bank',
    value: 'FI00159',
  },
  {
    label: 'The National Co-operative Bank',
    value: 'FI00174',
  },
  {
    label: 'The Nawanagar Co-operative Bank',
    value: 'FI00181',
  },
  {
    label: 'The Sarangpur Co-operative Bank',
    value: 'FI00201',
  },
  {
    label: 'The Sarvodaya Sahakari Bank',
    value: 'FI00135',
  },
  {
    label: 'The Seva Vikas Co-operative Bank',
    value: 'FI00176',
  },
  {
    label: 'The Social Co-operative Bank',
    value: 'FI00194',
  },
  {
    label: 'The Surat People Co-operative Bank',
    value: 'FI00115',
  },
  {
    label: 'The Sutex Co-operative Bank',
    value: 'FI00123',
  },
  {
    label: 'The Thane District Co-operative Bank',
    value: 'FI00130',
  },
  {
    label: 'The Vaidyanath Urban Co-operative Bank',
    value: 'FI00153',
  },
  {
    label: 'The Varachha Co-operative Bank',
    value: 'FI00116',
  },
  {
    label: 'The Vijay Co-operative Bank',
    value: 'FI00095',
  },
  {
    label: 'The Vishweshwar Sahakari Bank',
    value: 'FI00163',
  },
  {
    label: 'The Yavatmal Urban Co-operative Bank',
    value: 'FI00221',
  },
  {
    label: 'The Zoroastrian Co-operative Bank',
    value: 'FI00182',
  },
  {
    label: 'TJSB Sahakari Bank',
    value: 'FI00081',
  },
  {
    label: 'Tumkur Grain Merchants Co-operative Bank',
    value: 'FI00235',
  },
  {
    label: 'Tumkur Veerashaiva Co-operative Bank',
    value: 'FI00233',
  },
  {
    label: 'UCO Bank',
    value: 'FI00074',
  },
  {
    label: 'Ujjivan Small Finance Bank',
    value: 'FI00157',
  },
  {
    label: 'Union Bank of India',
    value: 'FI00075',
  },
  {
    label: 'United Bank of India',
    value: 'FI00076',
  },
  {
    label: 'Utkarsh Small Finance Bank',
    value: 'FI00187',
  },
  {
    label: 'Vardhaman Mahila Co-operative Urban Bank',
    value: 'FI00096',
  },
  {
    label: 'Vasai Janata Sahakari Bank',
    value: 'FI00214',
  },
  {
    label: 'Vasai Vikas Sahakari Bank',
    value: 'FI00162',
  },
  {
    label: 'Vidarbha Merchants Urban Co-operative Bank',
    value: 'FI00253',
  },
  {
    label: 'Vidharbha Konkan Gramin Bank',
    value: 'FI00200',
  },
  {
    label: 'Vidya Sahakari Bank',
    value: 'FI00195',
  },
  {
    label: 'Vijaya Bank',
    value: 'FI00077',
  },
  {
    label: 'Wardhaman Urban Co-operative Bank',
    value: 'FI00216',
  },
  {
    label: 'Yes Bank',
    value: 'FI00078',
  },
];

export const BankType = [
  { label: 'Savings Account', value: 'SAVINGS' },
  { label: 'Current Account', value: 'CURRENT' },
];
