import { call, put, takeLatest } from 'redux-saga/effects';
import { getDownloadExcelSheet, getErrorDownloadExcelSheet, getLoanUpdateStatus, getLoanUploadStatus, getPaymentUpdateStatus, getRefineLoanUserStatus } from '../apis/loanDataUploadStatus';
import { updatePreLoaderWatcher } from 'actions/user';

export function* getLoanUploadDetails(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getLoanUploadStatus, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getLoanUpdateDetails(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getLoanUpdateStatus, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getPaymentUpdateDetails(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getPaymentUpdateStatus, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getRefineLoanDataDetails(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getRefineLoanUserStatus, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getDownloadExcelCopy(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getDownloadExcelSheet, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getErrorDownloadExcelCopy(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getErrorDownloadExcelSheet, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getLoanUploadStatusSaga() {
  yield takeLatest('GET_LOAN_UPLOAD_STATUS', getLoanUploadDetails);
}

export function* getLoanUpdateStatusSaga() {
  yield takeLatest('GET_LOAN_UPDATE_STATUS', getLoanUpdateDetails);
}

export function* getPaymentUpdateStatusSaga() {
  yield takeLatest('GET_PAYMENT_UPDATE_STATUS', getPaymentUpdateDetails);
}

export function* getRefineLoanDataStatusSaga() {
  yield takeLatest('GET_REFINE_LOAN_DATA_STATUS', getRefineLoanDataDetails);
}

export function* getDownloadExcelSheetSaga() {
  yield takeLatest('GET_DOWNLOAD_EXCEL', getDownloadExcelCopy);
}

export function* getErrorDownloadExcelSheetSaga() {
  yield takeLatest('GET_ERROR_DOWNLOAD_EXCEL', getErrorDownloadExcelCopy);
}
