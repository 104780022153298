const themeColors = {
  white: {
    main: '#FFFFFF',
  },
  black: {
    light: '#12263F',
    main: '#000000',
  },
  transparent: {
    main: 'transparent',
  },
  gray: {
    100: '#f6f9fc',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#8898aa',
    700: '#525f7f',
    800: '#32325d',
    900: '#212529',
  },
  primary: {
    main: '#5e72e4',
    dark: '#233dd2',
    snackbar: '#7889e8',
    badgeBg: '#eaecfb',
    badgeBgHover: '#2a44db',
    badge: '#2643e9',
    inputCredit: '#324cdd',
  },
  secondary: {
    main: '#f7fafc',
    snackbar: '#f8fbfc',
    badgeBgHover: '#cadeeb',
    btnOutline: '#4385b1',
    btnActive: '#d2e3ee',
  },
  warning: {
    tableLight: '#fed3ca',
    tableLightHover: '#febeb1',
    light: '#ffd600',
    main: '#fb6340',
    snackbar: '#fc7c5f',
    badgeBg: '#fee6e0',
    badgeBgHover: '#f93305',
    badge: '#ff3709',
  },
  error: {
    light: '#f3a4b5',
    main: '#f5365c',
    snackbar: '#f75676',
    badgeBg: '#fdd1da',
    badgeBgHover: '#e30b36',
    badge: '#f80031',
    dialogNotification: '#f56036',
  },
  info: {
    main: '#5e72e4',
    snackbar: '#37d5f2',
    badgeBg: '#aaedf9',
    badgeBgHover: '#0c9cb7',
    badge: '#03acca',
  },
  background: {
    default: '#f8f9fe',
  },
  text: {
    primary: '#525f7f',
  },
  dark: {
    tableBorder: '#1f3a68',
    tableHeadColor: '#4d7bca',
    tableHeadBgColor: '#1c345d',
    main: '#172b4d',
    dark: '#0b1526',
    snackbar: '#3c4d69',
    badgeBg: '#4172c6',
    badgeBgHover: '#09111e',
  },
  success: {
    tableLightHover: '#afecd2',
    tableLight: '#c4f1de',
    main: '#2dce89',
    snackbar: '#4fd69c',
    badgeBg: '#b0eed3',
    badgeBgHover: '#229c68',
    badge: '#1aae6f',
  },
  facebook: {
    main: '#3b5999',
    dark: '#2d4474',
  },
  twitter: {
    main: '#1da1f2',
    dark: '#0c85d0',
  },
  google: {
    main: '#dd4b39',
    dark: '#c23321',
  },
  instagram: {
    main: '#e4405f',
    dark: '#d31e40',
  },
  pinterest: {
    main: '#bd081c',
    dark: '#8c0615',
  },
  youtube: {
    main: '#cd201f',
    dark: '#a11918',
  },
  vimeo: {
    main: '#04A0F0',
    dark: '#037fbe',
  },
  slack: {
    main: '#3aaf85',
    dark: '#2d8968',
  },
  dribbble: {
    main: '#ea4c89',
    dark: '#e51e6b',
  },
  github: {
    main: '#222222',
    dark: '#090909',
  },
};

export default themeColors;
