import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function uploadBulkFileApi(payload) {
  return axios.post(`${BASE_URL}bulk-file`, payload);
}

export function getBulkFileListApi(payload) {
  return axios.get(`${BASE_URL}bulk-file`, { params: payload });
}

export function downloadBulkFileApi(payload) {
  return axios.get(`${BASE_URL}bulk-file/${payload.id}`, { params: payload, responseType: 'arraybuffer' });
}
