import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { storedList } from "../../../../util/localstorage";
import { validateData } from "../../../../util/validation";
import { newLoanCreationData, insuranceBenefitDetails } from "./newLoanCreationData";
import InputBox from "react-sdk/dist/components/InputBox/InputBox";
import Button from "react-sdk/dist/components/Button/Button";
import "./createLoan.style.css";
import { getBookLoanDetailsWatcher, postLoanDetailsWatcher } from "../../../../msme/actions/bookLoan.action";
import { AlertBox } from "../../../../components/CustomAlertbox";
import Preloader from "../../../../components/custom/preLoader";
import { LeadStatus } from "../../../config/LeadStatus";
import { getCalculateFeesAndChargesWatcher } from "../../../../msme/actions/lead.action";
import BasicDatePicker from "components/DatePicker/basicDatePicker";
import { verifyDateAfter1800 } from "util/helper";
import moment from "moment";
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import { fetchBanksListWatcher } from "../../../../actions/borrowerInfo"

const user = { _id: storedList('user')?._id, id: storedList('user')?.id };

export default function CreateLoan() {
  const [stateData, setStateData] = useState({});
  const [validationData, setValidationData] = useState({});
  const [sameAsBeneficiary, setSameAsBeneficiary] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [initialData, setInitialData] = useState("")
  const [companyId, setCompanyID] = useState("")
  const [productId, setProductID] = useState("")
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const isLoading = useSelector((state) => state.profile.loading);
  const [isDisabled, setIsDisabled] = useState(false);
  const [offeredAMount, setOfferedAMount] = useState("");
  const [offeredIntRate, setOfferedIntRate] = useState("");
  const [isConvenience, setIsConvenience] = useState(false);
  const [isApplicationFee, setIsApplicationFee] = useState(false);
  const [isBrokenInt, setIsBrokenInt] = useState(false);
  const [isCalculate, setIsCalculate] = useState(true);
  const [onPageLoad, setOnPageLoad] = useState(true);
  const [BankList, setBankList] = useState([]);
  const BankType = [
    { value: "Current", label: "Current" },
    { value: "Saving", label: "Savings" },
    { value: "Others", label: "Others" },
  ];
  const [validationPopup, setValidationPopup] = useState(false);
  const [viewBenefitsPopup, setViewBenefitsPopup] = useState(false);
  const [isInsuranceOptout, setIsInsuranceOptout] = useState(false);
  const [isInsuranceChecked, setIsInsuranceChecked] = useState(true);
  const [benefits, setBenefits] = useState([]);
  const customButton1 = {
    borderRadius: '8px',
    border: "1px solid linear-gradient(180deg, #134CDE 0%, #163FB7 100%)",
    color: "1px solid linear-gradient(180deg, #134CDE 0%, #163FB7 100%)",
    width: '240px',
    height: '56px',
    fontSize: '16px',
    backgroundColor: "white"
  }

  const customButton2 = {
    borderRadius: '8px',
    width: '240px',
    height: '56px',
    fontSize: '16px',
    color: '#FFF',
    background: 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%)'
  }

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity("");
    setAlertMessage("");
  };

  const handleCheckboxPopup = () => {
    if (isInsuranceChecked) {
      setIsInsuranceChecked(false)
      setValidationPopup(false)
      setIsCalculate(false)
    }
    if (!isInsuranceChecked) {
      setIsInsuranceChecked(!isInsuranceChecked)
    }
    if (!benefits.success && benefits.price === 0) {
      setIsCalculate(true)
    }
  };

  const changeDateSelected = (value, name) => {
    const date = verifyDateAfter1800(moment(value).format("YYYY-MM-DD"))
      ? moment(value).format("YYYY-MM-DD")
      : value;
    const isValid = validateData(
      name.substring(0, name.indexOf("_vl_")).toLowerCase(),
      date
    );
    setStateData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
    setValidationData((prevState) => ({
      ...prevState,
      [`${name}State`]: !isValid ? "has-danger" : "",
    }));
  };

  const change = (e, type, name) => {
    setOnPageLoad(false)
    let editableFields = ["processing_fees_perc", "sanction_amount", "application_fee_perc", "insurance_amount"]
    if (editableFields.includes(name)) {
      setIsCalculate(false)
    }
    const value = e.value;
    let field = `${type}_vl_${name}`;
    let isValid = validateData(
      field.substring(0, field.indexOf("_vl_")).toLowerCase(),
      value
    );
    setStateData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setInitialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setValidationData((prevState) => ({
      ...prevState,
      [`${field}State`]: !isValid ? "has-danger" : "",
    }));
    if (name === "bene_confirm_bank_acc_no") {
      let isConfirm = (e.value !== stateData.String_vl_bene_bank_acc_num) ? true : false;
      setValidationData((prevState) => ({
        ...prevState,
        [`${field}State`]: isConfirm ? "has-danger" : "",
      }))
    }
    if (name === "borro_confirm_bank_acc_no") {
      let isConfirm = (e.value !== stateData.String_vl_borro_bank_acc_num) ? true : false;
      setValidationData((prevState) => ({
        ...prevState,
        [`${field}State`]: isConfirm ? "has-danger" : "",
      }))
    }
    if (name === "sanction_amount") {
      if (e.value > offeredAMount)
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: "has-danger",
        }))
    }
    let data = stateData;
    data = {
      ...data,
      [field]: value,
    }
    if (field.indexOf("bene") != -1 && sameAsBeneficiary) {
      copyBeneficiaryToBorrower(sameAsBeneficiary, data);
    }
  };

  const copyBeneficiaryToBorrower = (ischecked, data) => {
    let newStateData = []
    if (data) newStateData = data;
    else {
      newStateData = stateData;
    }
    const beneficiaryFields = newLoanCreationData.filter(
      (row) => row.dept === "Enter Beneficiary Bank Details"
    );
    const borrowerFields = newLoanCreationData.filter(
      (row) => row.dept === "Enter Borrower Bank Details"
    );
    beneficiaryFields.forEach((beneficiaryField) => {
      const borrowerField = borrowerFields.find((row) => {
        const borrowerFieldName = row.field.replace("borro_", "bene_");
        return borrowerFieldName === beneficiaryField.field;
      });
      if (borrowerField) {
        setStateData((prevState) => ({
          ...prevState,
          [`${borrowerField.type}_vl_${borrowerField.field}`]: ischecked
            ? newStateData[`${beneficiaryField.type}_vl_${beneficiaryField.field}`]
            : "",
        }));
        setInitialData((prevState) => ({
          ...prevState,
          [borrowerField.field]: ischecked
            ? newStateData[`${beneficiaryField.type}_vl_${beneficiaryField.field}`]
            : "",
        }))
        setValidationData((prevState) => ({
          ...prevState,
          [`${`${borrowerField.type}_vl_${borrowerField.field}`}State`]: !ischecked ? "has-danger" : "",
        }));
      }
    });
  };

  const handlePaste = (event, name) => {
    if (name === "bene_confirm_bank_acc_no" || name === "borro_confirm_bank_acc_no") {
      event.preventDefault();
    }
  }

  const handleSubmit = () => {
    const payload = {
      companyId: initialData.company_id,
      productId: initialData.product_id,
      user: user._id,
      data: {
        loan_app_id: initialData.loan_app_id ?? "",
        partner_loan_app_id: initialData.partner_loan_app_id ?? "",
        partner_borrower_id: initialData.partner_borrower_id ?? "",
        borrower_id: initialData.borrower_id ?? "",
        first_name: initialData.primary_applicant.first_name ?? "",
        middle_name: initialData.primary_applicant.middle_name ?? "",
        last_name: initialData.primary_applicant.last_name ?? "",
        partner_loan_id: initialData.partner_loan_id ?? "",
        loan_app_date: initialData.loan_app_date ?? "",
        sanction_amount: initialData.sanction_amount ?? "",
        first_inst_date: initialData.first_installment_date ?? "",
        final_approve_date: initialData.final_approval_date ?? "",
        loan_int_rate: initialData.loan_interest_rate ?? "",
        offered_amount: offeredAMount ?? "",
        offered_int_rate: offeredIntRate ?? "",
        processing_fees_perc: initialData.processing_fees_perc ?? "",
        processing_fees_amt: Number(initialData.processing_fees_amt) - Number(initialData.gst_on_pf_amt) ?? "",
        gst_on_pf_amt: initialData.gst_on_pf_amt ?? "",
        insurance_amount: initialData.insurance_amount ?? "",
        broken_interest: initialData.broken_interest ?? "",
        net_disbur_amt: initialData.net_disbur_amt ?? "",
        tenure: initialData.tenure ?? "",
        bene_bank_name: initialData.bene_bank_name ?? "",
        bene_bank_ifsc: initialData.bene_bank_ifsc ?? "",
        bene_bank_acc_num: initialData.bene_bank_acc_num ?? "",
        bene_confirm_bank_acc_no: initialData.bene_confirm_bank_acc_no ?? "",
        bene_bank_account_holder_name: initialData.bene_bank_account_holder_name ?? "",
        bene_bank_account_type: stateData.dropdown_vl_bene_bank_account_type ?? "",
        borro_bank_name: initialData.borro_bank_name ?? "",
        borro_bank_ifsc: stateData.IFSC_vl_borro_bank_ifsc ?? "",
        borro_bank_acc_num: stateData.String_vl_borro_bank_acc_num ?? "",
        borro_confirm_bank_acc_no: stateData.String_vl_borro_confirm_bank_acc_no ?? "",
        borro_bank_account_holder_name: stateData.String_vl_borro_bank_account_holder_name ?? "",
        borro_bank_account_type: stateData.dropdown_vl_borro_bank_account_type ?? "",
        igst_amount: initialData.igst_amount ?? "",
        cgst_amount: initialData.cgst_amount ?? "",
        sgst_amount: initialData.sgst_amount ?? "",
        gst_on_application_fees: initialData.gst_on_application_fees ?? "",
        cgst_on_application_fees: initialData.cgst_on_application_fees ?? "",
        sgst_on_application_fees: initialData.sgst_on_application_fees ?? "",
        igst_on_application_fees: initialData.igst_on_application_fees ?? "",
        conv_fees_excluding_gst: initialData.conv_fees_excluding_gst ?? "",
        application_fees_excluding_gst: initialData.application_fees_excluding_gst ?? "",
        conv_fees: initialData.conv_fees ?? "",
        application_fees: initialData.application_fees ?? "",
        application_fee_perc: initialData.application_fee_perc ?? "",
        gst_on_conv_fees: initialData.gst_on_conv_fees,
        cgst_on_conv_fees: initialData.cgst_on_conv_fees,
        sgst_on_conv_fees: initialData.sgst_on_conv_fees,
        igst_on_conv_fees: initialData.igst_on_conv_fees,
        include_insurance: isInsuranceChecked
      }
    };
    let errorFiedls = false
    if (newLoanCreationData) {
      newLoanCreationData.map((item) => {
        let name = item.field
        let field = `${item.type}_vl_${item.field}`;
        if ((initialData[name] === "" || initialData[name] === undefined || initialData[name] === null)
          && name !== 'insurance_amount' && name !== 'calculate' && name !== "include_insurance"
        ) {
          if ((name === 'application_fee_perc' || name === "application_fees") && !isApplicationFee) {
            setValidationData((prevState) => ({ ...prevState, [`${field}State`]: "" }));
          }
          else if (!isBrokenInt && name === "broken_interest") {
            setValidationData((prevState) => ({ ...prevState, [`${field}State`]: "" }));
          }
          else if (!isConvenience && name === "conv_fees") {
            setValidationData((prevState) => ({ ...prevState, [`${field}State`]: "" }));
          }
          else {
            setValidationData((prevState) => ({
              ...prevState,
              [`${field}State`]: "has-danger",
            }));
            errorFiedls = true;
          }
        }
      })
    }
    const formValid = Object.values(validationData).every(value => value !== 'has-danger');
    if (!formValid) {
      showAlert("Kindly check errors", "error")
    }
    if (formValid && !errorFiedls) {
      new Promise((resolve, reject) => {
        dispatch(postLoanDetailsWatcher(payload, resolve, reject));
      })
        .then((response) => {
          showAlert(response?.message, "success");
          setIsDisabled(true)
          setTimeout(() => {
            window.open(`/admin/msme/loan_details/${response.data.loan_id}/${initialData.company_id}/${initialData.product_id}?tab=${"SL & LBA"}`, "_self");
          }, 3000);
        })
        .catch((error) => {
          showAlert(error?.response?.data?.data?.message, "error");
        });
    }
  };

  const handleInsuranceCheckBox = () => {
    if (isInsuranceChecked) { setValidationPopup(true) }
    else {
      setIsInsuranceChecked(true)
      setIsCalculate(false)
    }
  }

  const renderFields = () => {
    let fieldsArray = newLoanCreationData
    if (!isConvenience) {
      const filteredArray = newLoanCreationData.filter(obj => obj.field !== 'conv_fees');
      fieldsArray = filteredArray
    }
    if (!isApplicationFee) {
      let idsToDelete = ['application_fee_perc', 'application_fees']
      const filteredArray = fieldsArray.filter(obj => !idsToDelete.includes(obj.field));
      fieldsArray = filteredArray
    }
    if (!isBrokenInt) {
      const filteredArray = fieldsArray.filter(obj => obj.field !== 'broken_interest');
      fieldsArray = filteredArray
    }
    const groupedFields = {};
    fieldsArray.forEach((row) => {
      const dept = row.dept;
      if (!groupedFields[dept]) {
        groupedFields[dept] = [];
      }
      groupedFields[dept].push(row);
    });

    return (
      <div className="create-loan-body">
        {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
        {Object.keys(groupedFields).map((dept) => (
          <div key={dept}>
            <div className="create-loan-header">
              {dept}
              {dept === 'Enter Borrower Bank Details' && !isDisabled ? (
                <>
                  <input
                    style={{
                      marginLeft: '16px',
                      marginTop: '6px',
                      width: '1rem',
                      height: '1rem',
                    }}
                    type="checkbox"
                    checked={sameAsBeneficiary}
                    onClick={() => {
                      setSameAsBeneficiary(!sameAsBeneficiary);
                      copyBeneficiaryToBorrower(!sameAsBeneficiary);
                    }}
                  ></input>
                  <div
                    style={{
                      fontFamily: 'Montserrat-Regular',
                      fontSize: '16px',
                      marginLeft: '8px',
                      fontWeight: '500',
                      lineHeight: '24px',
                      color: '#767888',
                      marginTop: '2px',
                    }}
                  >
                    Same as beneficiary bank details
                  </div>
                </>
              ) : null}
            </div>
            <div className="create-loan-container">
              {groupedFields[dept].map((row) => (
                <div key={`${row.type}_vl_${row.field}`}>
                  {row.type === 'Date' ? (
                    <BasicDatePicker
                      disableFutureDate={true}
                      disabled={initialData[row.field] || isDisabled}
                      style={{
                        width: '100%',
                        backgroundColor: '#fff',
                      }}
                      placeholder={row.title}
                      value={initialData[row.field]}
                      onDateChange={(date) => changeDateSelected(date, `${row.type}_vl_${row.field}`)}
                      format="dd-MM-yyyy"
                      error={stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationmsg : ''}
                    />
                  ) : row.type === 'button' ? (
                    <Button buttonType="" label={row.title} onClick={handleCalculate} isDisabled={isCalculate || isDisabled} customStyle={customSubmitButton} />
                  ) : row.type === 'checkbox' ? (
                    <>
                      {validationPopup ? (
                        <FormPopup customStyles={{ width: "30.813rem", height: "13.125rem", top: "50%" }} heading="Insurance Opt-out" onClose={() => { setValidationPopup(false) }} customHeaderStyle={{ paddingLeft: "0%" }} isCancel={true} isOpen={validationPopup} >
                          <div className="confirmation-popup-text-style">
                            Opting out of Insurance is not advised
                          </div>
                          <div style={{ display: 'flex', marginTop: '8%', marginBottom: "5%" }}>
                            <Button label="Cancel" buttonType="secondary" onClick={() => { setValidationPopup(false) }} customStyle={customButton1} />
                            <Button label="Confirm" buttonType="" customStyle={customButton2} onClick={handleCheckboxPopup} />
                          </div>
                        </FormPopup>
                      ) : null}
                      {viewBenefitsPopup ? (
                        <FormPopup customStyles={{ width: "41.438rem", height: "44.188rem", top: "50%", padding: "1.563rem", overflowY: "scroll" }} heading="Insurance Benefits" onClose={() => { setViewBenefitsPopup(false) }} customHeaderStyle={{ paddingBottom: "1.25rem" }} isCancel={true} isOpen={viewBenefitsPopup} >
                          {insuranceBenefitDetails.map((obj, index) => (
                            <div key={index} style={{ padding: "1.25rem 0 0 0" }}>
                              <div className="benefit-popup-fields" key={index}>{obj.title === "Benefits" ? null : obj.title}</div>
                              <div className="benefit-head" key={index}>
                                {obj?.field === "price" ? new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", }).format(benefits[obj.field]) :
                                  obj?.field === "logo" ? <img className="benefit-logo" src={benefits.source[obj.field]} alt="icon" /> :
                                    obj?.field === "benefits" ?
                                      <>
                                        <span className="benefit-title">{obj.title} </span>
                                        {benefits["benefits"].map((item, index) => (
                                          item?.included ? (
                                            <div key={index} style={{ fontFamily: "Montserrat-Regular", padding: "10px 0px" }}>
                                              <div >
                                                <div className="benefit-label">Benefit {index + 1}</div>
                                                <div className="benefit-description">{item?.benefit ?? ""}</div>
                                              </div>
                                              <div>
                                                <div className="benefit-label">Description</div>
                                                <div className="benefit-description" style={{ padding: "0.625rem 0", borderBottom: "1px solid #DCDEE6" }}>{item?.description ?? ""}</div>
                                              </div>
                                            </div>
                                          ) : null))}
                                      </>
                                      : benefits.source ? <span style={{ fontFamily: "Montserrat-Medium" }}>{benefits.source[obj.field]}</span> : ""}
                              </div>
                            </div>
                          ))}
                          <div className="benefit-cancel">
                            <Button label="Cancel" buttonType="" customStyle={{ ...customButton2, width: "6.563rem" }} onClick={() => { setViewBenefitsPopup(false) }} />
                          </div>
                          <div style={{height:"10px",width:"100%"}}></div>
                        </FormPopup>
                      ) : null}
                      <input
                        style={{ marginLeft: '0.75rem', marginTop: '1rem', width: '1rem', height: '1rem', cursor: 'pointer' }}
                        type={row.type}
                        checked={isInsuranceChecked}
                        onClick={handleInsuranceCheckBox}
                        disabled={!isInsuranceOptout}
                      ></input>
                      <span className="span" style={{ color: isInsuranceOptout ? "#161719" : "#A4A4A4" }}>{row.title}</span>
                      <span className="span-insurance" style={{ color: isInsuranceOptout && benefits["benefits"] ? "#134CDE" : "#A4A4A4" }} onClick={() => { isInsuranceOptout && benefits["benefits"] ? setViewBenefitsPopup(true) : null }}>
                        View Insurance Benefits
                      </span>
                    </>
                  )
                    : row.type === 'dropdown' ? (
                      <InputBox
                        label={row.title}
                        isDrawdown={true}
                        customClass={{
                          height: '56px',
                          width: '500px',
                          maxWidth: '100%',
                        }}
                        customInputClass={{
                          width: '100%',
                          backgroundColor: '#fff',
                        }}
                        error={validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? true : false}
                        helperText={validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationmsg : ''}
                        initialValue={stateData[`${row.type}_vl_${row.field}`] || initialData[row.field]}
                        isDisabled={isDisabled || (row.dept === 'Enter Borrower Bank Details' && sameAsBeneficiary)}
                        customDropdownClass={{ marginTop: '7px', zIndex: 5 }}
                        options={
                          row.field === 'bene_bank_account_type' || row.field === 'borro_bank_account_type' ? BankType : BankList}
                        onClick={(value) => handleDropdownAccTypeChange(value, row.type, row.field)}
                      />
                    ) : (
                      <InputBox
                        id={row.field}
                        label={row.title}
                        customClass={{
                          height: '56px',
                          width: '500px',
                          maxWidth: '100%',
                        }}
                        customInputClass={{
                          width: '100%',
                          backgroundColor: '#fff',
                          marginTop: row.isDisabled ? '-3px' : '3px',
                        }}
                        initialValue={row.field === 'conv_fees' && initialData[row.field] === 0 ? '0' : stateData[`${row.type}_vl_${row.field}`] || initialData[row.field]}
                        onClick={(event) => change(event, row.type, row.field)}
                        helperText={validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationmsg : ''}
                        error={validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                        isDisabled={row.isDisabled || (row.dept === 'Enter Borrower Bank Details' && sameAsBeneficiary) || isDisabled}
                        onPaste={(event) => handlePaste(event, row.field)}
                      />
                    )}
                </div>
              ))}
            </div>
          </div>
        ))}
        {isLoading && <Preloader />}
      </div>
    );
  };

  const fetchLoanDetails = () => {
    new Promise((resolve, reject) => {
      dispatch(
        getBookLoanDetailsWatcher(
          {
            loan_app_id: params.id,
            user: user
          },
          resolve,
          reject
        )
      );
    })
      .then(response => {
        setInitialData(response);
        setCompanyID(response.company_id)
        setProductID(response.product_id)
        if (response.lead_status === LeadStatus.approved.value) {
          history.push(`/admin/msme/loans`, "_self")
        }
      })
      .catch(error => {
        showAlert("Error while Fetching Lead Details", "error")
      });
  };

  const fetchBanksListDetails = () => {
    new Promise((resolve, reject) => {
      dispatch(
        fetchBanksListWatcher(
          {
            user_id: user._id
          },
          resolve,
          reject
        )
      );
    })
      .then(response => {
        setBankList(response?.data)
      })
      .catch(error => {
        showAlert(error?.response?.data?.message, "error")
      });
  };

  const handleCalculate = () => {
    setOnPageLoad(false)
    const formValid = Object.values(validationData).every(value => value !== 'has-danger');
    if (!formValid) {
      showAlert("Kindly check errors", "error")
    }
    else {
      let data = {
        "processing_fees_perc": initialData["processing_fees_perc"] ?? "",
        "sanction_amount": initialData["sanction_amount"] ?? "",
        "application_fee_perc": initialData["application_fee_perc"] ?? "",
        "insurance_amount": initialData["insurance_amount"] ?? "",
      }
      getCalculateFeesAndCharges(data)
    }
  };

  const getCalculateFeesAndCharges = (data) => {
    const payload = {
      loan_app_id: params.id,
      companyId: companyId,
      productId: productId,
      user_id: user._id,
    };
    if (data) {
      payload.processing_fees_perc = data.processing_fees_perc
      payload.sanction_amount = data.sanction_amount
      payload.application_fee_perc = data.application_fee_perc
      payload.include_insurance = isInsuranceChecked
    }
    new Promise((resolve, reject) => {
      dispatch(getCalculateFeesAndChargesWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setOfferedAMount(response?.data?.offered_amount)
        setOfferedIntRate(response?.data?.loan_int_rate)
        setInitialData((prevState) => ({
          ...prevState,
          "conv_fees": response?.data?.conv_fees ?? "",
          'processing_fees_perc': response?.data?.processing_fees_perc ?? "",
          "processing_fees_amt": response?.data?.processing_fees_amt === "NaN" ? "" : Number(response?.data?.processing_fees_amt) + Number(response?.data?.gstCalculationOnPF?.calculatedGstAmt) ?? "",
          'net_disbur_amt': response?.data?.net_disbursement_amount === null ? "" : response?.data?.net_disbursement_amount + "" ?? "",
          "first_installment_date": response?.data.first_inst_date ?? "",
          "final_approval_date": response?.data.final_approve_date ?? "",
          "loan_app_date": response?.data.loan_app_date ?? "",
          "tenure": response?.data?.tenure ?? "",
          "sanction_amount": response?.data?.sanction_amount + "" ?? "",
          "loan_interest_rate": response?.data?.loan_int_rate ?? "",
          "gst_on_pf_amt": response?.data?.gstCalculationOnPF?.calculatedGstAmt ?? "",
          "application_fee_perc": response?.data?.application_fee_perc ?? "",
          'application_fees': response?.data?.application_fees ?? "",
          'conv_fees': response?.data?.conv_fees ?? "",
          "broken_interest": response?.data?.broken_interest + "" ?? "",
          "igst_amount": response?.data?.gstCalculationOnPF?.calculatedIgst ?? "",
          "cgst_amount": response?.data?.gstCalculationOnPF?.calculatedCgst ?? "",
          "sgst_amount": response?.data?.gstCalculationOnPF?.calculatedSgst ?? "",
          "gst_on_application_fees": response?.data?.gstOnApplicationFees?.calculatedGstAmt ?? "",
          "cgst_on_application_fees": response?.data?.gstOnApplicationFees?.calculatedCgst ?? "",
          "sgst_on_application_fees": response?.data?.gstOnApplicationFees?.calculatedSgst ?? "",
          "igst_on_application_fees": response?.data?.gstOnApplicationFees?.calculatedIgst ?? "",
          "gst_on_conv_fees": response?.data?.gstOnConvFees?.calculatedGstAmt ?? "",
          "cgst_on_conv_fees": response?.data?.gstOnConvFees?.calculatedCgst ?? "",
          "sgst_on_conv_fees": response?.data?.gstOnConvFees?.calculatedSgst ?? "",
          "igst_on_conv_fees": response?.data?.gstOnConvFees?.calculatedIgst ?? "",
          "conv_fees_excluding_gst": response?.data?.gstOnConvFees?.convFeesExcludingGst ?? "",
          "application_fees_excluding_gst": response?.data?.gstOnApplicationFees?.applFeesExcludingGst ?? "",
          "insurance_amount": response?.data?.insurance_details ? response?.data?.insurance_details?.price + "" : 0 + ""
        }))
        if (response.data.isConvFeeApplicable) {
          setIsConvenience(true)
        }
        if (response.data.isApplicationFeeApplicable) {
          setIsApplicationFee(true)
        }
        if (response.data.isBrokenInterestApplicable) {
          setIsBrokenInt(true)
        }
        setIsInsuranceOptout(response?.data?.insurance_opt_out ?? false)
        setBenefits(response?.data?.insurance_details)
        setIsCalculate(true)
        if (response?.data?.insurance_details?.success === false) {
          showAlert(response?.data?.insurance_details?.message, "error")
          setIsCalculate(false)
        }
      })
      .catch((error) => {
        showAlert(error?.response?.data?.data?.message, "error")
        setIsCalculate(false)
      });
  };

  useEffect(() => {
    fetchLoanDetails();
    fetchBanksListDetails();
  }, []);

  useEffect(() => {
    if (companyId && productId) {
      getCalculateFeesAndCharges();
    }
  }, [companyId, productId]);

  const customSubmitButton = {
    display: 'inline - flex',
    height: '48px',
    width: 'max-content',
    padding: '8px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    color: '#FFF',
    fontFamily: 'Montserrat-Regular',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '150%',
    flexShrink: '0',
    borderRadius: '40px',
    background: isCalculate ? '#CCCDD3' : 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%)',
  };

  const handleDropdownAccTypeChange = (event, type, name) => {
    let field = `${type}_vl_${name}`;
    setStateData((prevState) => ({
      ...prevState,
      [field]: event?.label ?? null,
    }));
    setInitialData((prevState) => ({
      ...prevState,
      [name]: event?.label ?? null,
    }));
    setValidationData((prevState) => ({
      ...prevState,
      [`${field}State`]: "",
    }));
    let data = stateData;
    data = {
      ...data,
      [field]: event?.label ?? null,
    }
    if (field.indexOf("bene") != -1 && sameAsBeneficiary) {
      copyBeneficiaryToBorrower(sameAsBeneficiary, data);
    }
  };

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        {renderFields()}
      </div>
      <div>
        <div className="create-loan-button-css">
          <Button
            buttonType=""
            label="Submit & Next"
            onClick={handleSubmit}
            isDisabled={isDisabled || onPageLoad || !isCalculate}
            customStyle={{ ...customSubmitButton, background: !isCalculate || isDisabled || onPageLoad ? '#CCCDD3' : 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%)' }}
          />
        </div>
      </div>
    </>
  );
}
