import { documentCode } from 'msme/config/docCode';

export const AdditionalDocumentData = [
  { value: 'affidavit', label: 'Affidavit', documentCode: documentCode.affidavit },
  { value: 'name_change', label: 'Name change certificate', documentCode: documentCode.name_change_certificate },
  { value: 'lender_noc', label: 'Lender NOC', documentCode: documentCode.lender_noc },
  { value: 'deviation_certificate', label: 'Deviation certificate', documentCode: documentCode.deviation_certificate },
  { value: 'clearance_certificate', label: 'Clearance certificate', documentCode: documentCode.clearance_certificate },
  { value: 'water_bill', label: 'Water Bill', documentCode: documentCode.water_bill },
  { value: 'house_registry', label: 'House Registry', documentCode: documentCode.house_registry },
  { value: 'relationship_proof', label: 'Relationship proof', documentCode: documentCode.relationship_proof },
  { value: 'credit_card', label: 'Credit Card Bill', documentCode: documentCode.credit_card_bill },
  { value: 'telecom_bill', label: 'Telecom Bill', documentCode: documentCode.telecom_bill },
  { value: 'self_declaration', label: 'Self Declaration', documentCode: documentCode.self_declaration },
  { value: 'loan_statement', label: 'Loan Statement', documentCode: documentCode.loan_statement },
  { value: 'udyam_reg_cert', label: 'Udhyam Registration Certificate', documentCode: documentCode.entity_udhyam_certificate },
  { value: 'board_resolution', label: 'Board Resolution', documentCode: documentCode.entity_board_resolution },
  { value: 'guar_cibil_report', label: 'Guarantor Cibil Report', documentCode: documentCode.guar_cibil_report },
  { value: 'entity_business_pan', label: 'Business PAN', documentCode: documentCode.entity_business_pan },

  { value: 'moa', label: 'MOA', documentCode: documentCode?.moa },
  { value: 'aoa', label: 'AOA', documentCode: documentCode?.aoa },
  { value: 'coi', label: 'COI', documentCode: documentCode?.coi },
  { value: 'partnership_deed', label: 'Partnership Deed', documentCode: documentCode?.partnership_deed },
  { value: 'authority_letter', label: 'Authority Letter', documentCode: documentCode?.authority_letter },
  { value: 'bureau_pull_consent', label: 'Bureau Pull Consent', documentCode: documentCode?.bureau_pull_consent },
  { value: 'gst_certificate', label: 'GST Certificate', documentCode: documentCode?.gst_certificate },
  { value: 'list_of_directors', label: 'List of Directors', documentCode: documentCode?.list_of_directors },
  { value: 'list_of_shareholders', label: 'List of Shareholders', documentCode: documentCode?.list_of_shareholders },

  { value: 'name_change_certificate_2', label: 'Name Change Certificate 2', documentCode: documentCode?.name_change_certificate_2 },
  { value: 'name_change_certificate_3', label: 'Name Change Certificate 3', documentCode: documentCode?.name_change_certificate_3 },
  { value: 'dob_declaration_1', label: 'DOB Declaration 1', documentCode: documentCode?.dob_declaration_1 },
  { value: 'dob_declaration_2', label: 'DOB Declaration 2', documentCode: documentCode?.dob_declaration_2 },
  { value: 'dob_declaration_3', label: 'DOB Declaration 3', documentCode: documentCode?.dob_declaration_3 },
  { value: 'clearance_certificate_2', label: 'Clearance Certificate 2', documentCode: documentCode?.clearance_certificate_2 },
  { value: 'clearance_certificate_3', label: 'Clearance Certificate 3', documentCode: documentCode?.clearance_certificate_3 },
  { value: 'field_investigation_report', label: 'Field Investigation Report', documentCode: documentCode?.field_investigation_report },
  { value: 'sales_ledger_1', label: 'Sales Ledger 1', documentCode: documentCode?.sales_ledger_1 },
  { value: 'sales_ledger_2', label: 'Sales Ledger 2', documentCode: documentCode?.sales_ledger_2 },
  { value: 'sales_ledger_3', label: 'Sales Ledger 3', documentCode: documentCode?.sales_ledger_3 },
];
