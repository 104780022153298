import React, { useEffect, useState } from 'react';

import './createBusinessModel.style.css';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import { businessModelFieldMapping, fieldsToValidate } from './businessModelFieldMapping';
import Button from 'react-sdk/dist/components/Button';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { createBusinessModelWatcher } from 'serviceFeeRecon/actions/createBusinessModel.action';
import { storedList } from 'util/localstorage';
import BasicDatePicker from 'components/DatePicker/basicDatePicker';
import { getAllCompaniesWatcher } from 'actions/company';
import { getAllProductByCompanyIDWatcher } from 'actions/product';
import { verifyDateAfter1800 } from 'util/helper';
import moment from 'moment';
import { validateData } from 'util/validation';
import { getBusinessModelWatcher } from 'serviceFeeRecon/actions/createBusinessModel.action';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import { CheckBusinessModelExistWatcher } from 'serviceFeeRecon/actions/createBusinessModel.action';
const user = storedList('user');

export default function createBusinessModel() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const query = useLocation();
  const [companyList, setCompanyList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [productId, setProductId] = useState();
  const [company, setCompany] = useState();
  const [product, setProduct] = useState();
  const [productDetails, setProductDetails] = useState({});
  const [feesShareType, setFeesShareType] = useState({
    processing_fees: 'percentage',
    application_fees: 'percentage',
    convenience_fees: 'percentage',
    misc_fees: 'percentage',
    bounce_charge: 'percentage',
    lpi: 'percentage',
  });
  const [chargesFeature, setChargeFeature] = useState({
    isHurdleChargesApplicable: false,
    isLpiChargesApplicable: false,
    isBpiShared: false,
    hasSubvention: false,
    hasInsurance: false,
  });

  const [stateData, setStateData] = useState({});
  const [validateForm, setValidateForm] = useState(false);
  const [disabledFields, setDisabledFields] = useState(['partner_name', 'product_name', 'product_id', 'interest_type', 'loan_tenure_type', 'repayment_type']);
  const [isFieldDisable, setIsFieldDisable] = useState(false);

  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const validateFields = () => {
    let isInvalidForm = fieldsToValidate.some((item) => {
      return !stateData[item];
    });

    let formShareTypeNotSelected = Object.keys(feesShareType).some((item) => {
      return !feesShareType[item];
    });

    if (!isInvalidForm && !formShareTypeNotSelected) {
      setValidateForm(true);
    } else {
      setValidateForm(false);
    }
  };

  useEffect(() => {
    validateFields();
  }, [stateData, feesShareType]);

  const fetchAllCompanies = (existingCompanyId) => {
    new Promise((resolve, reject) => {
      dispatch(getAllCompaniesWatcher(resolve, reject));
    })
      .then((result) => {
        let temp = result;
        let allCompany = [];
        temp.forEach((comp) => {
          allCompany.push({
            label: comp.name,
            value: comp.name,
            id: comp._id,
          });
        });
        if (params?.businessModelId) {
          setCompany(allCompany.filter((item) => item.id === existingCompanyId)[0]);
          setCompanyList(allCompany);
          return;
        }
        setCompanyList(allCompany);
      })
      .catch((error) => {
        showAlert(error.message ?? 'Something went wrong.', 'error');
      });
  };

  const setInitialData = (response) => {
    setChargeFeature({
      isHurdleChargesApplicable: response.is_hurdle_charges_applicable,
      isLpiChargesApplicable: response.is_lpi_charges_applicable,
      isBpiShared: response.is_bpi_shared,
      hasSubvention: response.has_subvention,
      hasInsurance: response.has_insurance,
    });
    let tempData = {
      duration_of_model_value: response.model_date,
      default_cut_off_value: response.default_cut_off,
      portfolio_coverage_percentage_value: response.portfolio_coverage_percent,
      performance_security_settlement_value: response.performance_security_settlement,
    };
    let tempSplitData = {};
    response?.shares_array?.map((item) => {
      if (item.share_id === 1) {
        tempData = {
          ...tempData,
          ['bounce_charge_partner_share_value']: item.partner_share,
          ['bounce_charge_lender_share_value']: item.company_share,
        };

        tempSplitData = {
          ...tempSplitData,
          bounce_charge: item.split_type,
        };
      }
      if (item.share_id === 2) {
        tempData = {
          ...tempData,
          ['processing_fee_partner_share_value']: item.partner_share,
          ['processing_fee_lender_share_value']: item.company_share,
        };
        tempSplitData = {
          ...tempSplitData,
          processing_fees: item.split_type,
        };
      }
      if (item.share_id === 3) {
        tempData = {
          ...tempData,
          ['misc_fee_partner_share_value']: item.partner_share,
          ['misc_fee_lender_share_value']: item.company_share,
        };
        tempSplitData = {
          ...tempSplitData,
          misc_fees: item.split_type,
        };
      }
      if (item.share_id === 4) {
        tempData = {
          ...tempData,
          ['application_fee_partner_share_value']: item.partner_share,
          ['application_fee_lender_share_value']: item.company_share,
        };
        tempSplitData = {
          ...tempSplitData,
          application_fees: item.split_type,
        };
      }
      if (item.share_id === 6) {
        tempData = {
          ...tempData,
          ['convenient_fee_partner_share_value']: item.partner_share,
          ['convenient_fee_lender_share_value']: item.company_share,
        };
        tempSplitData = {
          ...tempSplitData,
          convenience_fees: item.split_type,
        };
      }
      if (item.share_id === 97) {
        tempData = {
          ...tempData,
          ['lpi_partner_share_value']: item.partner_share,
          ['lpi_lender_share_value']: item.company_share,
        };
        tempSplitData = {
          ...tempSplitData,
          lpi: item.split_type,
        };
      }
      if (item.share_id === 98) {
        tempData = {
          ...tempData,
          ['interest_rate_value']: item.partner_share,
          ['hurdle_rate_value']: item.company_share,
        };
      }
      if (item.share_id === 1001) {
        tempData = {
          ...tempData,
          ['partner_gst_share_value']: item.partner_share,
          ['lender_gst_share_value']: item.company_share,
        };
      }
    });
    setStateData(tempData);
    setFeesShareType(tempSplitData);
  };

  const fetchBusinessModel = (companyId, productId, businessModelId) => {
    const payload = {
      company_id: companyId,
      product_id: productId,
      model_id: businessModelId,
      user_id: user?.id ?? user?._id,
    };
    new Promise((resolve, reject) => {
      dispatch(getBusinessModelWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setCompanyId(response.company_id);
        setProductId(response.product_id);
        fetchAllCompanies(response.company_id);
        setInitialData(response);
      })
      .catch((error) => {
        showAlert(error.message ?? 'Something went wrong.', 'error');
      });
  };

  useEffect(() => {
    if (params?.companyId && params?.productId && params?.businessModelId) {
      setIsFieldDisable(true);
      fetchBusinessModel(params?.companyId, params?.productId, params?.businessModelId);
      return;
    }
    fetchAllCompanies();
  }, []);

  const fetAllProductByCompanyId = () => {
    new Promise((resolve, reject) => {
      dispatch(getAllProductByCompanyIDWatcher(company.id, resolve, reject));
    })
      .then((result) => {
        let temp = result;
        let allProduct = [];
        temp.forEach((prod) => {
          allProduct.push({
            label: prod.name,
            value: prod.name,
            id: prod._id,
            interestType: prod.interest_rate_type ?? 'NA',
            loanTenureType: prod.loan_tenure_type ?? 'NA',
            repaymentType: prod.repayment_type ?? 'NA',
          });
        });
        if (params?.businessModelId) {
          setProduct(allProduct.filter((item) => item.id === productId)[0]);
          return;
        }
        setProductList(allProduct);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message ?? 'Something went wrong.', 'error');
      });
  };

  useEffect(() => {
    if (company && companyList.indexOf(company) > -1) {
      setProductList([]);
      setProduct(null);
      fetAllProductByCompanyId();
    }
  }, [company, companyList]);

  useEffect(() => {
    if (product && company) {
      setStateData({
        ...stateData,
        [`partner_name_value`]: company?.label ?? null,
        [`product_name_value`]: product?.label ?? null,
        [`product_id_value`]: product?.id ?? null,
        [`interest_type_value`]: product?.interestType ?? null,
        [`loan_tenure_type_value`]: product?.loanTenureType ?? null,
        [`repayment_type_value`]: product?.repaymentType ?? null,
      });
    }
  }, [product, company]);

  const fetchObjectsByDept = (deptName) => {
    const matchingObjects = [];
    for (const object of businessModelFieldMapping) {
      if (object.dept === deptName) {
        matchingObjects.push(object);
      }
    }

    return matchingObjects;
  };

  const onInputChange = (event, field) => {
    let value = event.value;
    setStateData({
      ...stateData,
      [`${field}_value`]: value,
    });
  };

  const dropDownOption = (field) => {
    if (field === 'select_company_id') {
      return companyList;
    }
    if (field === 'select_product_id') {
      return productList;
    }
  };

  const onDropDownChange = (value, field) => {
    if (field === 'select_company_id') {
      if (typeof value === 'object') {
        setCompany(value);
      }
    }
    if (field === 'select_product_id' && productList.indexOf(value) != -1) {
      if (typeof value === 'object') {
        const payload = {
          company_id: company.id,
          product_id: value.id,
          user_id: user?.id ?? user?._id,
        };
        new Promise((resolve, reject) => {
          dispatch(CheckBusinessModelExistWatcher(payload, resolve, reject));
        })
          .then((response) => {
            if (response.exist === true) {
              showAlert(response.message, 'error');
              return;
            }
            setProduct(value);
          })
          .catch((error) => {
            showAlert(error?.response?.data?.message ?? 'Something went wrong.', 'error');
          });
      }
    }
  };

  const changeDateSelected = (value, field) => {
    const date = verifyDateAfter1800(moment(value).format('YYYY-MM-DD')) ? moment(value).format('YYYY-MM-DD') : value;
    const isValid = validateData('date', date);
    if (isValid) {
      setStateData((prevState) => ({
        ...prevState,
        [`${field}_value`]: date,
      }));
    }
  };

  const renderFields = (dept) => {
    let deptArray = fetchObjectsByDept(dept);
    return (
      <div
        style={{
          display: 'grid',
          rowGap: '28px',
          gridTemplateColumns: ['processing_fees', 'application_fees', 'convenience_fees', 'misc_fees', 'interest_rate', 'bounce_charge', 'lpi'].includes(dept) ? '48% 48%' : '24% 24% 24% 24%',
          columnGap: ['processing_fees', 'application_fees', 'convenience_fees', 'misc_fees', 'interest_rate', 'bounce_charge', 'lpi'].includes(dept) ? '2%' : '1%',
          width: '100%',
        }}
      >
        {deptArray &&
          deptArray.map((row, index) => {
            return (
              <>
                {row.field === 'select_company_id' || row.field === 'select_product_id' ? (
                  <InputBox
                    id={row.field}
                    label={row.title}
                    isDrawdown={isFieldDisable ? false : true}
                    initialValue={row.field === 'select_company_id' ? company?.label : row.field === 'select_product_id' ? product?.label : null}
                    onClick={(value) => onDropDownChange(value, row.field)}
                    isDisabled={isFieldDisable}
                    customDropdownClass={{
                      zIndex: '2',
                      marginTop: '2%',
                    }}
                    customClass={{
                      height: '56px',
                      width: '100%',
                      maxWidth: '100%',
                    }}
                    customInputClass={{}}
                    options={dropDownOption(row.field)}
                  />
                ) : row.type === 'date' ? (
                  <>
                    <BasicDatePicker
                      disabled={isFieldDisable}
                      placeholder={'Duration of Model'}
                      style={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      value={stateData[`${row.field}_value`] || null}
                      format="dd-MM-yyyy"
                      shouldDisableDate={false}
                      shouldDisableYear={false}
                      onDateChange={(date) => {
                        changeDateSelected(date, row.field);
                      }}
                      error={false}
                      helperText={''}
                    />
                  </>
                ) : (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      isDrawdown={false}
                      initialValue={stateData[`${row.field}_value`]}
                      onClick={(event) => onInputChange(event, row.field)}
                      isDisabled={disabledFields.includes(row.field) ? true : isFieldDisable}
                      customDropdownClass={{}}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                        marginTop: '0px',
                      }}
                      error={false}
                      helperText={''}
                    />
                  </>
                )}
              </>
            );
          })}
      </div>
    );
  };

  const checkFeesShareType = (feesType, shareType) => {
    return feesShareType[feesType] === shareType;
  };

  const updateFeesShareType = (feesType, shareType) => {
    setFeesShareType({ ...feesShareType, [feesType]: shareType });
  };

  const renderRadioButton = (label, dept) => {
    return (
      <div onClick={() => updateFeesShareType(dept, label.toLowerCase())}>
        <input type="radio" checked={checkFeesShareType(dept, label.toLowerCase())} onChange={() => updateFeesShareType(dept, label.toLowerCase())} disabled={isFieldDisable} />
        <label className="input-label-style">{label}</label>
      </div>
    );
  };

  const renderFeesUi = (title, dept) => {
    return (
      <div>
        <div className="fees-heading-style">{title}</div>
        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          {renderRadioButton('Percentage', dept)}
          {renderRadioButton('Absolute', dept)}
          {renderRadioButton('Ratio', dept)}
        </div>
        {renderFields(dept)}
      </div>
    );
  };

  const customCancelButtonStyle = {
    fontSize: '16px',
    color: '#134CDE',
    border: '1px solid #134CDE',
    width: 'max-content',
    padding: '13px 44px',
    borderRadius: '8px',
  };

  const customCreateButtonStyle = {
    display: 'inline - flex',
    height: '48px',
    padding: '13px 44px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    color: '#FFF',
    fontFamily: 'Montserrat-Regular',
    fontSize: '16px',
    fontWeight: '800',
    width: 'max-content',
    pointerEvents: 'auto',
    lineHeight: '150%',
    flexShrink: '0',
    borderRadius: '8px',
    background: true ? 'var(--primary-blue-button-gradient, linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%))' : 'var(--neutrals-neutral-30, #CCCDD3)',
  };

  const onChangeCheckBox = (chargeType) => {
    setChargeFeature({
      ...chargesFeature,
      [chargeType]: !chargesFeature[chargeType],
    });
  };

  const renderCheckBoxUi = (label, fieldType) => {
    return (
      <div>
        <input className="input-checkbox-style" type="checkbox" checked={chargesFeature[fieldType]} onChange={() => onChangeCheckBox(fieldType)} disabled={isFieldDisable} />
        <label className="input-label-style"> {label} </label>
      </div>
    );
  };

  const handleCancelButtonClick = () => {
    history.goBack();
  };

  const createBusinessModel = (payload) => {
    payload = {
      ...payload,
      user_id: user?.id ?? user?._id,
      product_id: product.id,
      company_id: company.id,
    };
    new Promise((resolve, reject) => {
      dispatch(createBusinessModelWatcher(payload, resolve, reject));
    })
      .then((response) => {
        showAlert(response.message ?? '', 'success');
        history.goBack();
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message ?? 'Something went wrong.', 'error');
      });
  };

  const handleCreateBusinessModel = () => {
    const postData = {
      model_duration: stateData['duration_of_model_value'],
      default_cut_off: `${stateData[`default_cut_off_value`]} days`,
      portfolio_coverage_percent: `${stateData['portfolio_coverage_percentage_value']}`,
      performance_security_settlement: `${stateData['performance_security_settlement_value']}`,
      is_hurdle_charges_applicable: chargesFeature['isHurdleChargesApplicable'],
      is_lpi_charges_applicable: chargesFeature['isLpiChargesApplicable'],
      is_bpi_shared: chargesFeature['isBpiShared'],
      has_subvention_flag: chargesFeature['hasSubvention'],
      has_insurance_flag: chargesFeature['hasInsurance'],
    };
    const sharedArray = [
      //bounce charges
      {
        share_id: 1,
        share_type: 'bounce_charge',
        company_share: Number(stateData['bounce_charge_lender_share_value'] ?? 0),
        partner_share: Number(stateData['bounce_charge_partner_share_value'] ?? 0),
        split_type: feesShareType['bounce_charge'],
      },
      //processing fees
      {
        share_id: 2,
        share_type: 'processing_fees',
        company_share: Number(stateData['processing_fee_lender_share_value'] ?? 0),
        partner_share: Number(stateData['processing_fee_partner_share_value'] ?? 0),
        split_type: feesShareType['processing_fees'],
      },
      //misc fees
      {
        share_id: 3,
        share_type: 'misc_fees',
        company_share: Number(stateData['misc_fee_lender_share_value'] ?? 0),
        partner_share: Number(stateData['misc_fee_partner_share_value'] ?? 0),
        split_type: feesShareType['misc_fees'],
      },
      //application fees
      {
        share_id: 4,
        share_type: 'application_fees',
        company_share: Number(stateData['application_fee_lender_share_value'] ?? 0),
        partner_share: Number(stateData['application_fee_partner_share_value'] ?? 0),
        split_type: feesShareType['application_fees'],
      },
      //convenient fees
      {
        share_id: 6,
        share_type: 'convenience_fees',
        company_share: Number(stateData['convenient_fee_lender_share_value'] ?? 0),
        partner_share: Number(stateData['convenient_fee_partner_share_value'] ?? 0),
        split_type: feesShareType['convenience_fees'],
      },
      //lpi
      {
        share_id: 97,
        share_type: 'lpi',
        company_share: Number(stateData['lpi_lender_share_value'] ?? 0),
        partner_share: Number(stateData['lpi_partner_share_value'] ?? 0),
        split_type: feesShareType['lpi'],
      },
      //interest
      {
        share_id: 98,
        share_type: 'interest',
        company_share: Number(stateData['hurdle_rate_value'] ?? 0),
        partner_share: Number(stateData['interest_rate_value'] ?? 0),
        split_type: 'percentage',
      },
      //gst
      {
        share_id: 1001,
        share_type: 'gst',
        company_share: Number(stateData['lender_gst_share_value'] ?? 0),
        partner_share: Number(stateData['partner_gst_share_value'] ?? 0),
        split_type: 'percentage',
      },
    ];
    postData['share_array'] = sharedArray;
    createBusinessModel(postData);
  };

  const renderSubmitButton = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Button label="Cancel" onClick={handleCancelButtonClick} buttonType="primary-button" customStyle={customCancelButtonStyle} />
        <Button label="Create" onClick={handleCreateBusinessModel} buttonType="primary-button" customStyle={customCreateButtonStyle} isDisabled={!validateForm} />
      </div>
    );
  };

  const showAlert = (msg, type) => {
    const element = document.getElementById('TopNavBar');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }

    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);

    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  return (
    <div className="create-business-modal-container-style">
      <div className="create-business-modal-header">Select</div>
      {renderFields('select_company_product')}
      {company && product ? (
        <>
          <hr />
          <div className="create-business-modal-header">General Information</div>
          {renderFields('general_info_model')}
          <hr />

          <div className="create-business-modal-header">Fees</div>
          <div style={{ display: 'flex', marginBottom: '2rem' }}>
            <div style={{ flexBasis: '50%' }}>{renderFeesUi('Processing Fees', 'processing_fees')}</div>
            <div style={{ flexBasis: '50%' }}>{renderFeesUi('Application Fees', 'application_fees')}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: '2rem' }}>
            <div style={{ flexBasis: '50%' }}>{renderFeesUi('Convenience Fees', 'convenience_fees')}</div>
            <div style={{ flexBasis: '50%' }}>{renderFeesUi('Misc Fees', 'misc_fees')}</div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ flexBasis: '50%' }}>{renderFeesUi('Bounce Charge', 'bounce_charge')}</div>
            <div style={{ flexBasis: '50%' }}>{renderFeesUi('OI', 'lpi')}</div>
          </div>
          <hr />
          <div className="create-business-modal-header">Interest</div>
          <div style={{ display: 'flex', marginBottom: '2rem', alignItems: 'center' }}>
            <div style={{ flexBasis: '50%' }}>{renderFields('interest_rate')}</div>
            <div style={{ flexBasis: '50%', display: 'grid', gridTemplateColumns: '32% 32% 32%' }}>
              {renderCheckBoxUi('OI', 'isLpiChargesApplicable')}
              {renderCheckBoxUi('BPI Sharing', 'isBpiShared')}
              {renderCheckBoxUi('Hurdle Charges', 'isHurdleChargesApplicable')}
            </div>
          </div>
          <hr />
          <div className="create-business-modal-header">GST</div>
          {renderFields('gst')}
          <hr />
          <div className="create-business-modal-header">Others</div>
          {renderFields('other_fields')}
          <div style={{ display: 'flex', margin: '1.5rem 0' }}>
            {renderCheckBoxUi('Subvention', 'hasSubvention')}
            {renderCheckBoxUi('Insurance', 'hasInsurance')}
          </div>
          <div className="submit-button-container">{isFieldDisable ? null : renderSubmitButton()}</div>
        </>
      ) : null}
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}
