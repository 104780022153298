import * as React from 'react';
import { Guarantor_Dept, Guarantor_Field, bookLoansFormJsonFields } from './bookLoansFormJson';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storedList } from '../../../util/localstorage';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button';
import { validateData } from '../../utils/validation';
import { verifyDateAfter1800 } from '../../../util/helper';
import 'react-sdk/dist/styles/_fonts.scss';
import _keys from 'lodash/keys'
import { States, Cities } from '../../../constants/country-state-city-data';
import moment from 'moment';
import UploadFileInput from '../../components/uploadFileInput/UploadFileInput';
import {
	patchLapDetailsWatcher,
	patchLapSaveAsDraftDetailsWatcher
} from '../../actions/lap.action';
import { patchMsmeDocDeleteWatcher } from '../../../msme/actions/msme.action'
import SlidingAlert from '../../components/SlidingAlert/SlidingAlert'
import { GuarantorSelfie, Guarantor_PanTittle, AadharImage, Guarantor_PanInputTittleXML, Guarantor_AadharInputTittleXML } from './uploadKycData';
import { getBookLoanDetailsWatcher, getLapLoanDocumentsWatcher } from './../../actions/bookLoan.action';
import { postComprehensiveLogsWatcher } from '../../../actions/comprehensiveLogs.action';
import getSectionStatus from './GetLeadSectionStatus/GetLeadSectionStatus';
import { SectionData } from '../../config/sectionData';
import { LeadNewStatus } from '../../config/LeadStatus';
import getSubSectionRemarks from './GetLeadSectionStatus/GetLeadSubSectionRemarks';
import { documentCode } from '../../config/docCode';
import { CommentViewer } from 'lap/components/CommentViewer/CommentViewer';
import CustomDatePicker from 'react-sdk/dist/components/DatePicker/DatePicker';
import { getSubmitButton } from '../../util/resuableJsx/reusableJsx';
import { getSaveAsDraftButton } from 'lap/util/resuableJsx/reusableJsx';
import FormPopup from "react-sdk/dist/components/Popup/FormPopup";
import { REACT_APP_UPPERAGELIMIT_LAP, REACT_APP_AGE_LAP, SECTION_STATUS } from 'lap/helpers/constants';
import { Fragment } from 'react';
import _isEmpty from 'lodash/isEmpty'
import { Alert_Type, Application_Mode, Error_Message, Field_Label, Field_Type, Lap_Screens, Section_Status, Section_Status_Type, Sub_Section_Code, Success_Message } from '../../constants/string_constants';
import { Colors } from '../../constants/color_constant';
import { Number_Constants } from '../../constants/number_constants';
import { isSectionCompleted, isDisplaySubmitButton, isMaskedAadhaar } from '../../utils/helper';
const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
const sectionName = 'guarantors';
const BOOK_LOAN_FORM_JSON = bookLoansFormJsonFields();
const MAX_GUARANTOR_COUNT = 5;

const setDocumentView = (stateData, TitleOb, documents) => {
	return TitleOb.map((givenObj) => {
		const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : '') && otherObj?.borrower_id === stateData?.borrower_id);
		if (matchingObj) {
			return {
				...givenObj,
				s3_url: matchingObj.file_url,
				doc: matchingObj,
			};
		} else {
			return givenObj;
		}
	});
};

const checkDocStatus = (data) => {
	for (let ob of data) {
		if (!ob?.s3_url && ob.isRequired) {
			return false;
		}
	}
	return true;
};

const sectionStatus = ['deviation', 'approved', 'rejected', 'failed'];

const fetchObjectFieldsByDept = (deptName) => {
	const matchingObjects = [];
	for (let object of BOOK_LOAN_FORM_JSON) {
		if (object.dept === deptName) {
			matchingObjects.push(object.field);
		}
	}

	return matchingObjects;
};

const disabledFields = {
	view: [...fetchObjectFieldsByDept('Guarantor Details'), ...fetchObjectFieldsByDept('Guarantor Current Address'), ...fetchObjectFieldsByDept('Guarantor Permanent Address'), ...fetchObjectFieldsByDept('Guarantor KYC'), ...fetchObjectFieldsByDept('Guarantor Aadhar Details'), ...fetchObjectFieldsByDept('Guarantor PAN Details'), ...fetchObjectFieldsByDept('Guarantor KYC Documents')],
	edit: [],
};

export default function GuarantorForm(props) {
	const { item, itemIndex, navIconPrefixState, loanDetailsStatus, setNavIconPrefixState, setNavState, GuarantorCount, setGuarantorCount, guarantorsData, setGuarantorsData, guarantorArray, setGuarantorArray, guarantorStatus, setGuarantorStatus, loanAppId, LAPCompanyId, LAPProductId, leadStatus, setLeadStatus, showShareHolding, documents, loanDetailsSubsectionStatus, setShouldFetch, loanSectionObject, setLoanSectionObject, setShouldFetchDocument } = props;
	const [guarantorIndex, setGuarantorIndex] = useState(item.id);
	const guarantorSectionStatus = loanDetailsStatus[`guarantor_details_${guarantorIndex}`]
	const dispatch = useDispatch();
	const [alert, setAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [severity, setSeverity] = useState('');
	const [viewPerAddress, setViewPerAddress] = useState(true);
	const [stateData, setStateData] = useState(guarantorsData[itemIndex] ? guarantorsData[itemIndex] : {});
	const [backgroundColorBlur, setBackgroundColorBlur] = useState(true);
	const [validationData, setValidationData] = useState({});
	const [panButtonState, setPanButtonState] = useState('button');
	const [selectedPANFileType, setSelectedPANFileType] = useState({ 'Image & PDF': true, 'Response XML/JSON': false });
	const [selectedAadharFileType, setAadharFileType] = useState({ 'Image & PDF': true, 'Response XML/JSON': false });
	const [states, setStatesState] = useState(States);
	const [currCity, setCurrCityState] = useState([]);
	const [perCity, setPerCityState] = useState([]);
	const defaultPanBorder = 'border: 1px solid #BBBFCC'
	const [panBorder, setPanBorder] = useState(defaultPanBorder);
	const [sectionStatusCheck, setSectionStatusCheck] = useState('');
	const [addGuarantorLoader, setAddGuarantorLoader] = useState('');
	const [validForm, setValidForm] = useState(true);
	const [panVerify, setPanVerify] = useState(false);
	const [isDraftButtonDisabled, setIsDraftButtonDisable] = useState(true);
	const [isFormCompleted, setIsFormCompleted] = useState(false);
	const [statusObject, setStatusObject] = useState('');
	const [disableDraftButton, setDisableDraftButton] = useState(false);
	const [isDraftLoading, setIsDraftLoading] = useState(false);
	const [isVerifyNextLoading, setIsVerifyNextLoading] = useState(false);
	const [isAddGuarantorLoading, setIsAddGuarantorLoading] = useState(false);
	const [isLeadRejected, setIsLeadRejected] = useState(false);
	const [isNameMismatch, setIsNameMisMatch] = useState(false)
	const [sectionVerified, setSectionVerified] = useState(false)

	const [documentStateData, setDocumentStateData] = useState({
		Guarantor_Selfie_1: false,
		Pan_1: false,
		Pan_XML: false,
		Aadhar_XML: false,
		Aadhar_Front_1: false,
		Aadhar_Back_1: false,
		aadhar_front: false,
		Aadhar_back: false,
	});

	const deleteFile = async (documentCodes) => {
		const payload = {
			company_id: LAPCompanyId,
			product_id: LAPProductId,
			user: user,
			loanAppId: loanAppId,
			code: [...documentCodes],
			borrowerId: stateData?.borrower_id,
		};
		new Promise((resolve, reject) => {
			dispatch(patchMsmeDocDeleteWatcher(payload, resolve, reject));
		})
			.then((response) => {
				showAlert(response.message, Alert_Type.Success);
				if (setShouldFetchDocument) {
					setShouldFetchDocument((prev) => prev + 1);
				}
			})
			.catch((error) => {
				showAlert('Error deleting file', 'error');
			});
	};

	const handleRadioButton = ({
		event,
		updateSelectedFileType,
		selectedFileType,
		docCode,
		docNames
	}) => {
		if (!selectedFileType[event.target.value]) {
			let isDocumentAvailable = docNames.find(item => documentStateData[item])
			if (isDocumentAvailable) {
				let updatedDocStateData = {}
				docNames.forEach(item => {
					updatedDocStateData = { ...updatedDocStateData, [item]: false }
				})
				deleteFile([...docCode]);
				setDocumentStateData({ ...documentStateData, ...updatedDocStateData });
			}
		}
		if (event.target.value === Field_Label.IMAGE_PDF && !selectedFileType[Field_Label.IMAGE_PDF]) {
			updateSelectedFileType((prevState) => ({
				...prevState,
				[Field_Label.IMAGE_PDF]: true,
				[Field_Label.RESPONSE_XML_JSON]: false,
			}));
		} else if (event.target.value === Field_Label.RESPONSE_XML_JSON && !selectedFileType[Field_Label.RESPONSE_XML_JSON]) {
			updateSelectedFileType((prevState) => ({
				...prevState,
				[Field_Label.IMAGE_PDF]: false,
				[Field_Label.RESPONSE_XML_JSON]: true,
			}));
		}
	};

	const uploadFileName = 'guarantor_details';

	let intervalId;

	const guarantorCode = 399;

	let code = guarantorCode + (itemIndex + 1);

	const [guarantorSelfieState, setGuarantorSelfieState] = useState(GuarantorSelfie);
	const [guarantorPanState, setGuarantorPanState] = useState(Guarantor_PanTittle);
	const [guarantorPanXMLState, setGuarantorPanXMLState] = useState(Guarantor_PanInputTittleXML);
	const [guarantorAadharState, setGuarantorAadharState] = useState(AadharImage);
	const [guarantorAadharXMLState, setGuarantorAadharXMLState] = useState(Guarantor_AadharInputTittleXML);

	useEffect(() => {
		if (loanDetailsSubsectionStatus && loanDetailsSubsectionStatus[code]) {
			if (loanDetailsSubsectionStatus[code]['guarantor_pan'] == 'approved') {
				setBackgroundColorBlur(false);
				setPanButtonState('icon');
				fetchLoanDetails(0);
				setPanBorder('1px solid green');
				setPanVerify(true);
			}
			if (loanDetailsSubsectionStatus[code]['guarantor_pan'] == 'deviation') {
				setBackgroundColorBlur(false);
				setPanButtonState('icon');
				fetchLoanDetails(0);
				setPanBorder('1px solid yellow');
				setPanVerify(true);
			}
		}
		if (props.loanDetailsStatus && props.loanDetailsStatus[`guarantor_details_${parseInt(item[item.length - 1])}`] == 'rejected') {
			setIsLeadRejected(true);
		}
	}, [loanDetailsSubsectionStatus, statusObject]);

	useEffect(() => {
		if (loanDetailsSubsectionStatus[code]) {
			if (loanDetailsSubsectionStatus[code][Sub_Section_Code.Guarantor.Guarantor_Bureau] &&
				!loanDetailsSubsectionStatus[code][Sub_Section_Code.Guarantor.Guarantor_Section_Submit]) {
				if (!sectionVerified) {
					setSectionVerified(true)
				}
			}
		}
	}, [loanDetailsSubsectionStatus[code], sectionVerified])

	const radioCss = {
		color: 'var(--neutrals-neutral-100, #161719)',
		fontFamily: 'Montserrat-Regular',
		fontSize: '16px',
		fontWeight: '500',
		lineHeight: '20px',
		marginTop: '1%',
	};

	const radioInputCss = {
		accentColor: '#134CDE',
		marginRight: '8px',
		marginBottom: '4px',
		height: '20px',
		width: '20px',
		verticalAlign: 'middle',
	};

	const handleGuarantorArray = () => {
		let temp = [...guarantorArray];
		for (let i = 1; i <= GuarantorCount; i++) {
			if (i > guarantorArray.length) {
				temp.push({ id: i, name: `Guarantor ${i}` });
			}
		}
		setGuarantorArray(temp);
	};

	const InputBoxStyle = {
		marginTop: '8px',
		maxHeight: '500px',
		zIndex: 1,
		width: '105%',
	};

	const customButton = {
		borderRadius: '8px',
		width: '240px',
		height: '56px',
		fontSize: '16px',
	};
	const customButton1 = {
		borderRadius: '8px',
		width: '240px',
		height: '56px',
		fontSize: '16px',
		backgroundColor: "white"
	};

	const CustomHeaderStyle = {
		fontFamily: 'Montserrat-Bold',
		marginLeft: '2%',
		marginTop: '30px',
		color: '#161719',
		fontSize: '1.3vw',
		marginBottom: '20px',
	};

	const CustomTitleStyle = {
		fontFamily: 'Montserrat-Bold',
		marginLeft: '2%',
		color: '#161719',
		fontSize: '1.3vw',
		marginBottom: '20px',
	};

	const customSubHeaderStyle = {
		fontFamily: 'Montserrat-SemiBold',
		marginLeft: '2%',
		marginTop: '30px',
		color: '#161719',
		fontSize: '1.2vw',
		marginRight: '1%',
		marginBottom: '15px',
	};

	const CustomHeader = {
		fontFamily: 'Montserrat-SemiBold',
		marginLeft: '2%',
		marginTop: '1%',
		color: '#161719',
		fontSize: '1.0vw',
		marginRight: '1%',
		color: '#767888',
	};

	const calculateAge = (birthMonth, birthDay, birthYear) => {
		var currentDate = new Date();
		var currentYear = currentDate.getFullYear();
		var currentMonth = currentDate.getMonth();
		var currentDay = currentDate.getDate();
		var calculatedAge = currentYear - birthYear;
		if (currentMonth < parseInt(birthMonth) - 1) {
			calculatedAge--;
		}
		if (parseInt(birthMonth) - 1 == currentMonth && currentDay < parseInt(birthDay)) {
			calculatedAge--;
		}
		return calculatedAge;
	};

	const handleAlertClose = () => {
		setAlert(false);
		setSeverity('');
		setAlertMessage('');
	};

	const showAlert = (msg, type) => {
		setAlert(true);
		setSeverity(type);
		setAlertMessage(msg);
	};

	const checkCityAndState = (section) => {
		let currArr;
		let perArr;
		if (section == 'city') {
			currArr = currCity;
			perArr = perCity;
		} else {
			currArr = States;
			perArr = States;
		}
		let isCurrCityValid = false;
		let isPerCityValid = false;
		if (!viewPerAddress) {
			if (stateData[`string_vl_gua_${section}`]) {
				currArr.map((item, idx) => {
					if (item.label == stateData[`string_vl_gua_${section}`]) {
						isCurrCityValid = true;
					}
				});
			}
			if (!isCurrCityValid) {
				setValidationData((prevState) => ({
					...prevState,
					[`string_vl_gua_${section}State`]: 'has-danger',
				}));
			}
			return isCurrCityValid;
		} else {
			if (stateData[`string_vl_gua_${section}`]) {
				currArr.map((item, idx) => {
					if (item.label == stateData[`string_vl_gua_${section}`]) {
						isCurrCityValid = true;
					}
				});
				if (!isCurrCityValid) {
					setValidationData((prevState) => ({
						...prevState,
						[`string_vl_gua_${section}State`]: 'has-danger',
					}));
					return isCurrCityValid;
				}
			}
			if (stateData[`string_vl_gua_per_${section}`]) {
				perArr.map((item, idx) => {
					if (item.label == stateData[`string_vl_gua_per_${section}`]) {
						isPerCityValid = true;
					}
				});
				if (!isPerCityValid) {
					setValidationData((prevState) => ({
						...prevState,
						[`string_vl_gua_per_${section}State`]: 'has-danger',
					}));
				}
				return isCurrCityValid && isPerCityValid;
			}
		}
		return false;
	};

	const handleAddGuarantor = ({
		message,
		toastType
	}) => {
		let newGuarantor = {}
		if (GuarantorCount < MAX_GUARANTOR_COUNT) {
			newGuarantor = { id: GuarantorCount + 1, name: `Guarantor ${GuarantorCount + 1}` }
			{ guarantorArray?.length >= guarantorsData?.length && setGuarantorsData((prevData) => [...prevData, {}]) }
		} else {
			let guarantorAdded = false
			guarantorArray.forEach((item, index) => {
				if (item.id !== index + 1 && !guarantorAdded) {
					newGuarantor = { id: index + 1, name: `Guarantor ${index + 1}` }
					guarantorAdded = true
				}
			})
		}
		setGuarantorArray((prevData) => [...prevData, newGuarantor]);
		setBackgroundColorBlur(true);
		showAlert(message, toastType);
		setShouldFetchDocument((prevData) => prevData + 1);
	};

	useEffect(() => {
		getLeadStatus();
	}, [loanSectionObject, guarantorIndex]);

	useEffect(() => {
		if (!validForm ||
			!documentStateData?.Guarantor_Selfie_1 ||
			!(documentStateData?.Pan_1 || documentStateData?.Pan_XML) ||
			!(documentStateData?.aadhar_front || documentStateData?.Aadhar_XML) ||
			sectionStatusCheck == 'inProgress') {
			setIsFormCompleted(false)
		} else {
			setIsFormCompleted(true)
		}
	}, [selectedPANFileType, selectedAadharFileType, validForm, documentStateData])

	const fetchLoanDocuments = () => {
		const borrowerId = stateData?.borrower_id;
		const response = documents.filter((item) => item.borrower_id == borrowerId);
		let stateDoc = {
			Guarantor_Selfie_1: false,
			Pan_1: false,
			Pan_XML: false,
			Aadhar_XML: false,
			Aadhar_Front_1: false,
			Aadhar_Back_1: false,
			aadhar_front: false,
			Aadhar_back: false,
		};

		let data = setDocumentView(stateData, GuarantorSelfie, response);
		setGuarantorSelfieState(data);
		stateDoc['Guarantor_Selfie_1'] = checkDocStatus(data);

		data = setDocumentView(stateData, Guarantor_PanTittle, response);
		setGuarantorPanState(data);
		stateDoc['Pan_1'] = checkDocStatus(data);

		data = setDocumentView(stateData, Guarantor_PanInputTittleXML, response);
		setGuarantorPanXMLState(data);
		stateDoc['Pan_XML'] = checkDocStatus(data);

		data = setDocumentView(stateData, AadharImage, response);
		setGuarantorAadharState(data);
		stateDoc['Aadhar_Front_1'] = checkDocStatus(data);
		stateDoc['aadhar_front'] = checkDocStatus(data);

		data = setDocumentView(stateData, Guarantor_AadharInputTittleXML, response);
		setGuarantorAadharXMLState(data);
		stateDoc['Aadhar_XML'] = checkDocStatus(data);
		stateDoc['aadhar_front'] = checkDocStatus(data) || stateDoc['aadhar_front'];

		if (leadStatus == LeadNewStatus.Pending) {
			for (let obj of response) {
				if (obj.code == '203') {
					setSelectedPANFileType({
						['Response XML/JSON']: true,
					});
				} else if (obj.code == '199') {
					setSelectedPANFileType({
						['Image & PDF']: true,
						['Response XML/JSON']: false,
					});
				}
				else if (obj.code == '204') {
					setAadharFileType({
						['Image & PDF']: false,
						['Response XML/JSON']: true,
					});
				} else {
					setAadharFileType({
						['Image & PDF']: true,
						['Response XML/JSON']: false,
					});
				}
			}
		}
		setDocumentStateData(stateDoc);
	}

	useEffect(() => {
		if (stateData?.borrower_id && documents) {
			fetchLoanDocuments();
		}
	}, [documents]);

	useEffect(() => {
		if (props.type == 'view') {
			fetchLoanDetails(0);
		}
	}, []);

	const getLeadStatus = () => {
		if (loanSectionObject && loanSectionObject[`guarantor_details_${guarantorIndex}`]) {
			setStatusObject(loanSectionObject[`guarantor_details_${guarantorIndex}`]);
		}
	};

	const handleGetCurrCities = async (value, name) => {
		setCurrCityState(Cities(value));
	};

	const handleGetPerCities = async (value, name) => {
		setPerCityState(Cities(value));
	};

	const isDuplicatePan = () => {
		return guarantorsData.find(item => item.pan_vl_gua_pan === stateData.pan_vl_gua_pan && item._id !== stateData?._id)
	}

	const change = (e, type, name) => {
		setIsDraftButtonDisable(false);
		const buttonText = e.target?.textContent;
		const valued = buttonText;
		if (valued === 'Verify') {
			let formValidated = true;
			bookLoansFormJsonFields().map((item, idx) => {
				if ((item.dept == 'Guarantor Details' || item.dept == 'Guarantor Current Address' || item.dept == 'Guarantor Permanent Address' || item.field == 'gua_pan') && item.isOptional == false) {
					const field = `${item.type}_vl_${item.field}`;
					if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
						setValidationData((prevState) => ({
							...prevState,
							[`${field}State`]: prevState[`${field}State`] || 'has-danger',
						}));
						formValidated = false;
					}
					if (!stateData[field]) {
						setValidationData((prevState) => ({
							...prevState,
							[`${field}State`]: 'has-danger',
						}));
						formValidated = false;
					}
				}
			});
			if (formValidated && checkCityAndState('city') && checkCityAndState('state')) {
				if (isDuplicatePan()) {
					showAlert('PAN already exists', 'error');
				} else {
					patchCall();
				}
			} else {
				showAlert('Please fill all mandatory details to verify pan', 'error');
			}
		} else {
			let value = e.value;
			if (name === 'gua_pan') {
				if (value?.length >= 10) {
					value = value.substring(0, 10);
				}
				value = value?.toUpperCase();
			}
			else if (name === 'gua_mobile') {
				if (value?.length >= 10) {
					value = value.substring(0, 10);
				}
			} else if (name === 'gua_aadhaar') {
				if (value?.length >= 12) {
					value = value.substring(0, 12);
				}
				if (value && (value[value.length - 1] < '0' || value[value.length - 1] > '9')) {
					value = value.substring(0, value.length - 1);
				}
			} else if (name === 'gua_pincode' || name === 'gua_per_pincode') {
				if (value?.length >= 6) {
					value = value.substring(0, 6);
				}
			} else if (name === 'gua_resi_addr_ln1' || name === 'gua_per_addr_ln1' || name === 'gua_resi_addr_ln2' || name === 'gua_per_addr_ln2') {
				if (value?.length >= 65) {
					value = value.substring(0, 65);
				}
			}
			const field = `${type}_vl_${name}`;
			let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), value);
			if (field.indexOf('curr') != -1 && !viewPerAddress) {
				const perField = field.replace('curr', 'per');
				let isValidData = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
				setStateData((prevState) => ({
					...prevState,
					[perField]: value,
				}));
				setValidationData((prevState) => ({
					...prevState,
					[`${perField}State`]: !isValidData ? 'has-danger' : '',
				}));
			}
			setStateData((prevState) => ({
				...prevState,
				[field]: value,
			}));
			if (name === 'gua_mobile') {
				const pattern = /^[5-9]\d*$/;
				setValidationData((prevState) => ({
					...prevState,
					[`${field}State`]: !isValid && value && !pattern.test(value) ? 'has-errorHelperTextRequired'
						: !isValid && value && value.length >= 1 && pattern.test(value) ? 'has-errorHelperText1Required' : !isValid ? 'has-danger' : '',
				}));
			} else {
				setValidationData((prevState) => ({
					...prevState,
					[`${field}State`]: !isValid && value.length >= 1 ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
				}));
			}
		}
		let addrfield = `${type}_vl_${name}`;
		if ((addrfield.indexOf('resi') != -1 || addrfield == 'string_vl_gua_city' || addrfield == 'string_vl_gua_state' || addrfield == 'pincode_vl_gua_pincode') && !viewPerAddress) {
			let perField;
			if (addrfield == 'string_vl_gua_city') {
				perField = `string_vl_gua_per_city`;
			} else if (addrfield == 'string_vl_gua_state') {
				perField = `string_vl_gua_per_state`;
			} else if (addrfield == 'pincode_vl_gua_pincode') {
				perField = `pincode_vl_gua_per_pincode`;
			} else {
				perField = field.replace('resi', 'per');
			}
			let isValidData = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), e.value);
			setStateData((prevState) => ({
				...prevState,
				[perField]: e.value,
			}));
			setValidationData((prevState) => ({
				...prevState,
				[`${perField}State`]: !isValidData ? 'has-danger' : '',
			}));
		}
		if (name === 'gua_state') {
			handleGetCurrCities(e, name);
			if (stateData.string_vl_gua_city) {
				setStateData((prevState) => ({
					...prevState,
					[`string_vl_gua_city`]: '',
				}));
			}
		}
		if (name === 'gua_per_state') {
			handleGetPerCities(e, name);
			if (stateData.string_vl_gua_per_city) {
				setStateData((prevState) => ({
					...prevState,
					[`string_vl_gua_per_city`]: '',
				}));
			}
		}
	};

	const updateGuarantorDetails = (response) => {
		setStateData((prevState) => ({
			...prevState,
			[`_id`]: response?.guarantor_id || stateData?.guarantor_id,
			[`borrower_id`]: response?.borrower_id || stateData?.borrower_id,
			sequence_no: response?.sequence_no
		}));
	}

	const constructPayload = (data, isPanVerify = false) => {
		let constructedPayload = {
			gua_fname: data[`formatname_vl_gua_fname`],
			gua_mname: data[`formatname_vl_gua_mname`] ?? ' ',
			gua_lname: data[`formatname_vl_gua_lname`],
			gua_dob: data[`date_vl_gua_dob`],
			gua_age: stateData[`number_vl_gua_age`],
			gua_gender: data[`string_vl_gua_gender`],
			gua_mobile: data[`mobile_vl_gua_mobile`],
			gua_email: data['email_vl_guarantor_email'],
			gua_father_name: data[`formatname_vl_gua_father_name`],
			gua_resi_addr_ln1: data[`string_vl_gua_resi_addr_ln1`],
			gua_resi_addr_ln2: data[`string_vl_gua_resi_addr_ln2`] ?? ' ',
			gua_city: data[`string_vl_gua_city`],
			gua_state: data[`string_vl_gua_state`],
			gua_pincode: data[`pincode_vl_gua_pincode`],
			address_same: viewPerAddress ? 0 : 1,
			gua_per_addr_ln1: data[`string_vl_gua_per_addr_ln1`],
			gua_per_addr_ln2: data[`string_vl_gua_per_addr_ln2`] ?? ' ',
			gua_per_city: data[`string_vl_gua_per_city`],
			gua_per_state: data[`string_vl_gua_per_state`],
			gua_per_pincode: data[`pincode_vl_gua_per_pincode`],
			gua_pan: data[`pan_vl_gua_pan`],
			section: 'guarantors',
			loan_app_id: loanAppId,
			user_id: user._id ? user._id : '',
			lap_company_id: LAPCompanyId,
			lap_product_id: LAPProductId,
		}
		if (!isPanVerify) {
			constructedPayload = {
				...constructedPayload,
				address_same: data['address_same'],
				gua_aadhaar: data['aadhaar_vl_gua_aadhaar'],
				is_pan_pdf_upload: selectedPANFileType['Image & PDF'] ? 1 : 0,
				is_aadhar_pdf_upload: selectedAadharFileType['Image & PDF'] ? 1 : 0,
				sequence_no: code,
				_id: data["_id"]
			}
		}

		return constructedPayload
	}

	const updatePanField = ({
		message,
		toastType,
		borderStyle
	}) => {
		setBackgroundColorBlur(false);
		setPanButtonState('icon');
		showAlert(message, toastType);
		fetchLoanDetails(0);
		setPanBorder(borderStyle);
		setPanVerify(true);
		setIsNameMisMatch(false);
	}

	const setStatusCheckApi = async (loanAppID, sectionCode, subSectionCode, dispatch, addGuarantor) => {
		intervalId = setInterval(async () => {
			try {
				const status = await getSectionStatus(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch, false, code);
				let subSectionRemarks = await getSubSectionRemarks(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch);
				if (status == Section_Status.Approved) {
					if (subSectionCode == Sub_Section_Code.Guarantor.Guarantor_Pan) {
						updatePanField({
							message: Success_Message.Pan_Success,
							toastType: Alert_Type.Success,
							borderStyle: `1px solid ${Colors.Approved_Color}`
						})
					} else if (subSectionCode === Sub_Section_Code.Guarantor.Guarantor_Bureau) {
						handleAddGuarantor({
							message: Field_Label.Guarantor_success_message,
							toastType: Alert_Type.Success,
						})
					} else if (subSectionCode === Sub_Section_Code.Guarantor.Guarantor_Section_Submit) {
						if (addGuarantor == Number_Constants.TWO) {
							setShouldFetch((prev) => prev + 1)
							setIsVerifyNextLoading(false)
						} else {
							navigateToNextSection(Section_Status_Type.Success, false)
						}
					}
				} else if (status == Section_Status.In_Review) {
					if (subSectionCode == Sub_Section_Code.Guarantor.Guarantor_Pan) {
						updatePanField({
							message: subSectionRemarks,
							toastType: Alert_Type.Success,
							borderStyle: `1px solid ${Colors.Deviation_Color}`
						})
					} else if (subSectionCode === Sub_Section_Code.Guarantor.Guarantor_Bureau) {
						handleAddGuarantor({
							message: Field_Label.Guarantor_success_message,
							toastType: Alert_Type.Success,
						})
					} else if (subSectionCode === Sub_Section_Code.Guarantor.Guarantor_Section_Submit) {
						if (addGuarantor == Number_Constants.TWO) {
							setShouldFetch((prev) => prev + 1)
							setIsVerifyNextLoading(false)
						} else {
							navigateToNextSection(Section_Status_Type.Success, false)
						}
					}
				} else if (status == Section_Status.Rejected) {
					if (subSectionCode == Sub_Section_Code.Guarantor.Guarantor_Pan) {
						showAlert(subSectionRemarks, Alert_Type.Error);
					}
				}
			} catch (error) {
				showAlert(Error_Message.Technical_Error, Alert_Type.Error);
				clearInterval(intervalId);
			} finally {
				clearInterval(intervalId);
				setIsAddGuarantorLoading(false)
				setIsVerifyNextLoading(false)
			}
		}, 5000);
	}

	const patchCall = () => {
		setPanButtonState('loader');
		const payload = {
			sequence_no: code,
			section_code: SectionData.guarantor.section_code,
			section_name: SectionData.guarantor.section_name,
			section_sequence_no: code,
			sub_section_code: SectionData.guarantor.guarantor_pan.sub_section_code,
			sub_section_name: SectionData.guarantor.guarantor_pan.sub_section_name,
			sub_section_sequence_no: SectionData.guarantor.guarantor_pan.sub_section_sequence_no,
			...constructPayload(stateData, true),
		};
		if (stateData[`_id`]) {
			payload._id = stateData[`_id`];
		}
		saveLogs(loanAppId, `guarantor_${guarantorIndex}_pan_verify`);
		dispatch(
			patchLapDetailsWatcher(
				payload,
				async (result) => {
					const panCheckStatus = result?.data?.pan_check_status
					if (panCheckStatus === SECTION_STATUS.APPROVED || panCheckStatus === SECTION_STATUS.INREVIEW) {
						updateGuarantorDetails({ ...result?.data, sequence_no: payload.sequence_no })
						setStatusCheckApi(loanAppId, SectionData.guarantor.section_code, SectionData.guarantor.guarantor_pan.sub_section_code, dispatch, 0);
					}
					//need to handle for error and retry scenario.
				},
				(error) => {
					const errorResponse = error?.response?.data
					let errorMessage = errorResponse?.message || 'Something went wrong'
					if (!_isEmpty(errorResponse?.data)) {
						updateGuarantorDetails({ ...errorResponse.data, sequence_no: payload.sequence_no })
						if (errorResponse?.data?.name_mismatch === 'Yes') {
							setIsNameMisMatch(true)
							errorMessage = 'Name doesn’t match with the Pan entered. Please retry'
						}
					}
					showAlert(errorMessage, 'error');
					setPanButtonState('button');
				},
			),
		);
	};

	const isFormFieldError = () => {
		return _keys(validationData).filter(item => validationData[item])?.length
	}

	const handlePermanentAddress = () => {
		setViewPerAddress(!viewPerAddress);
	};

	useEffect(() => {
		let data = {
			...stateData,
		};
		if (stateData && stateData['date_vl_gua_dob']) {
			data = {
				...data,
				number_vl_gua_age: handleAge(),
			};
		}
		setStateData({ ...data, ...guarantorsData[guarantorIndex - 1] });
		if (guarantorStatus[guarantorIndex - 1]) {
			setBackgroundColorBlur(true);
		}
	}, [guarantorStatus]);


	useEffect(() => {
		let vlForm = 1;
		bookLoansFormJsonFields().map((item, idx) => {
			if (item.field != 'gua_age' && item.field != 'gua_father_name' && (item.dept == 'Guarantor Details' || item.dept === 'Guarantor KYC' || item.dept == 'Guarantor Current Address' || item.dept == 'Guarantor Permanent Address' || item.dept == 'Guarantor KYC')) {
				if (item.isOptional == false) {
					if (!stateData) {
						vlForm = 0;
					}
					if (stateData && !stateData[`${item.type}_vl_${item.field}`]) {
						vlForm = 0;
					}
				}
			}
		});
		if (vlForm) {
			setValidForm(true);
		} else {
			setValidForm(false);
		}
		if (guarantorStatus[guarantorIndex - 1] == false) {
			const guarantorData = [...guarantorsData];
			guarantorData[guarantorIndex - 1] = stateData;
			setGuarantorsData(guarantorData);
		}
		if (stateData && stateData['string_vl_gua_state']) {
			const stateInfoObject = states.filter((item) => item.name === stateData['string_vl_gua_state']);
			handleGetCurrCities(stateInfoObject[0], '');
		}
		if (stateData && stateData['string_vl_gua_per_state']) {
			const stateInfoObject = states.filter((item) => item.name === stateData['string_vl_gua_per_state']);
			handleGetPerCities(stateInfoObject[0], '');
		}
	}, [stateData, viewPerAddress]);

	useEffect(() => {
		if (stateData && stateData['date_vl_gua_dob']) {
			handleAge();
		}
	}, [stateData?.date_vl_gua_dob]);

	useEffect(() => {
		handleGuarantorArray();
		fetchLoanDetails(1);
	}, [GuarantorCount]);

	useEffect(() => {
		if (!viewPerAddress) {
			bookLoansFormJsonFields().map((item, idx) => {
				if (item.dept == 'Guarantor Permanent Address') {
					let addressField = `${item.type}_vl_${item.field}`;
					setStateData((prevState) => ({
						...prevState,
						[addressField]: null,
					}));
					const str = item.field == 'gua_per_state' || item.field == 'gua_per_city' || item.field == 'gua_per_pincode' ? '' : 'resi_';
					let field = item.field.replace('per_', str);
					const value = stateData[`${item.type}_vl_${field}`];
					if (value) {
						let perField = `${item.type}_vl_${item.field}`;
						let isValid = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
						setStateData((prevState) => ({
							...prevState,
							[perField]: value,
						}));
						setValidationData((prevState) => ({
							...prevState,
							[`${perField}State`]: !isValid ? 'has-danger' : '',
						}));
					}
				}
			});
		} else {
			if (!guarantorStatus[guarantorIndex - 1] && props.type !== 'edit' && props.type !== 'view') {
				bookLoansFormJsonFields().map((item, idx) => {
					if (item.dept == 'Guarantor Permanent Address') {
						let field = `${item.type}_vl_${item.field}`;
						setStateData((prevState) => ({
							...prevState,
							[field]: null,
						}));
					}
				});
			}
		}
	}, [viewPerAddress]);

	const handleAge = () => {
		const dob = stateData['date_vl_gua_dob'];
		const yyyyMmDdRegExp = /^\d{4}-\d{2}-\d{2}$/.test(dob);
		if (yyyyMmDdRegExp) {
			const age = calculateAge(dob.substring(5, 7), dob.substring(8, 10), dob.substring(0, 4));
			setStateData((prevState) => ({
				...prevState,
				number_vl_gua_age: age,
			}));
			let field = `number_vl_gua_age`;
			let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), age);
			setValidationData((prevState) => ({
				...prevState,
				[`${field}State`]: !isValid ? 'has-danger' : '',
			}));
			return age;
		} else {
			setStateData((prevState) => ({
				...prevState,
				number_vl_gua_age: '',
			}));
			return ' ';
		}
	};

	const fetchLoanDetails = (addGua) => {
		const payload = {
			user_id: user._id,
			companyId: LAPCompanyId,
			productId: LAPProductId,
			loan_app_id: loanAppId,
			user: JSON.stringify(user),
		};
		dispatch(
			getBookLoanDetailsWatcher(
				payload,
				async (res) => {
					let arr = [...guarantorsData];
					setLeadStatus(res?.data?.[0]?.lead_status);
					let resGuarantorData = res?.data?.[0];
					if (resGuarantorData?.guarantor) {
						let statusArr = Array.from({ length: 5 }, () => false);
						for (let i = 0; i < (resGuarantorData['guarantor'].length >= 5 ? 5 : resGuarantorData['guarantor'].length); i++) {
							const result = resGuarantorData['guarantor'][i];
							bookLoansFormJsonFields().map((item, idx) => {
								if (item.dept == 'Guarantor Details' || item.dept === 'Guarantor Current Address' || item.dept === 'Guarantor Permanent Address' || item.dept === 'Guarantor KYC') {
									if (result[`${item.field}`]) {
										arr[i][`${item.type}_vl_${item.field}`] = result[`${item.field}`] ?? null;
									} else if (result[`_id`]) {
										addGua = 0;
									}
								}
							});
							arr[i][`_id`] = result[`_id`];
							if (result[`borrower_id`]) {
								arr[i][`borrower_id`] = result[`borrower_id`];
							}
							arr[i][`address_same`] = result[`address_same`];
							if (result['is_pan_pdf_upload'] !== null) {
								arr[i]['panFileType'] = result['is_pan_pdf_upload'] === 1 ? { 'Image & PDF': true, 'Response XML/JSON': false } : { 'Image & PDF': false, 'Response XML/JSON': true };
							}
							if (result['is_aadhar_pdf_upload'] !== null) {
								arr[i]['aadhaarFileType'] = result['is_aadhar_pdf_upload'] === 1 ? { 'Image & PDF': true, 'Response XML/JSON': false } : { 'Image & PDF': false, 'Response XML/JSON': true };
							}
						}
						setGuarantorStatus(statusArr);
						setGuarantorsData(arr);
						setStateData({ ...arr[guarantorIndex - 1] });
					}
				},
				(error) => { },
			),
		);
	};

	const changeDateSelected = (value, name) => {
		const pastYear = new Date(new Date().setFullYear(new Date().getFullYear()));
		const selectedDate = moment(pastYear).format('YYYY-MM-DD');
		if (selectedDate > value) {
			const date = verifyDateAfter1800(moment(value).format('YYYY-MM-DD')) ? moment(value).format('YYYY-MM-DD') : value;
			const isValid = validateData(name.substring(0, name.indexOf('_vl_')).toLowerCase(), date);
			setStateData((prevState) => ({
				...prevState,
				[name]: date,
			}));
			setValidationData((prevState) => ({
				...prevState,
				[`${name}State`]: !isValid ? 'has-danger' : '',
			}));
		} else {
			const isValid = false;
			setStateData((prevState) => ({
				...prevState,
				number_vl_age: ' ',
			}));
			setValidationData((prevState) => ({
				...prevState,
				[`${name}State`]: !isValid ? 'has-danger' : '',
			}));
		}
	};

	const handleDraft = () => {
		if (isFormFieldError()) {
			showAlert('Kindly check for errors in fields', 'error');
		} else {
			const payload = {
				// the 2nd boolean arg is to identify whether is panverify or not
				section: 'guarantors',
				loan_app_id: loanAppId,
				...constructPayload(stateData, false),
				user: JSON.stringify(user),
				userData: {
					company_id: LAPCompanyId,
					product_id: LAPProductId,
					user_id: user._id ? user._id : ''
				},
			};
			setIsDraftLoading(true)
			saveLogs(loanAppId, `guarantor_${guarantorIndex}_save_as_draft`);
			new Promise((resolve, reject) => {
				dispatch(patchLapSaveAsDraftDetailsWatcher(payload, resolve, reject));
			})
				.then((response) => {
					updateGuarantorDetails({ ...response?.data, sequence_no: payload.sequence_no })
					showAlert('Draft saved successfully', 'success');
					setIsDraftLoading(false)
					setIsDraftButtonDisable(true)
				})
				.catch((error) => {
					showAlert(error?.message ?? 'Error while saving draft', 'error');
					setIsDraftLoading(false)
					setIsDraftButtonDisable(true)
				});
		}
	}

	const rejectionEnabled = (item) => {
		if (['gua_fname', 'gua_mname', 'gua_lname', 'gua_dob', 'gua_gender', 'gua_mobile', 'guarantor_email', 'gua_father_name', 'gua_pan'].includes(item?.field) && !panVerify) {
			return true;
		}
		return false;
	};

	const checkIsNameMismatch = (item) => {
		const disableFields = ['gua_dob', 'gua_gender', 'gua_mobile', 'guarantor_email', 'gua_father_name']
		const disableDept = ['Guarantor Current Address', 'Guarantor Permanent Address']
		if (isNameMismatch && (disableFields?.includes(item?.field) || disableDept?.includes(item?.dept))) return true;
		return false;
	}
	const checkIsFieldReadOnly = (item) => {
		const disableFields = ['gua_pan']
		if (isNameMismatch && (disableFields?.includes(item?.field))) return true;
		return false;
	}

	const numberTypeFields = ['gua_mobile', 'gua_pincode', 'gua_per_pincode']

	const renderFields = (department) => {
		return (
			<>
				<div
					style={{
						display: 'grid',
						rowGap: '15px',
						gridTemplateColumns: '32.8% 32.8% 32.8%',
						columnGap: '1%',
						width: '98%',
						marginLeft: '1.7%',
					}}
				>
					{bookLoansFormJsonFields().map((item, idx) => {
						return (
							<>
								{item.dept == department ? (
									item.field == 'gua_dob' ? (
										<CustomDatePicker
											customInputClass={{ height: '58px', width: '100%', maxWidth: '100%' }}
											label="Date of Birth"
											format={'YYYY-MM-DD'}
											disableFuture={true}
											isBoxType={'icon'}
											age={parseInt(REACT_APP_AGE_LAP)}
											startingDate={REACT_APP_UPPERAGELIMIT_LAP ? parseInt(new Date().getFullYear()) - parseInt(REACT_APP_UPPERAGELIMIT_LAP) : 1964}
											endingDate={parseInt(new Date().getFullYear()) - parseInt(REACT_APP_AGE_LAP)}
											onDateChange={(date) => changeDateSelected(date, `${item.type}_vl_${item.field}`)}
											isDisabled={isSectionCompleted(guarantorSectionStatus) || panVerify ? true : checkIsNameMismatch(item) ? true : !rejectionEnabled(item)}
											initialValue={(stateData && stateData[`${item.type}_vl_${item.field}`]) || null}
											error={item.checked.toLowerCase() === 'true' ? validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired' : stateData[`${item.type}_vl_${item.field}`] !== '' && validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'}
											helperText={item.checked.toLowerCase() === 'true' ? (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? item.validationMsg : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired' ? item.errorHelperTextRequired : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' ? item.errorHelperText1Required : '') : stateData[`${item.type}_vl_${item.field}`] !== '' && (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? item.validationMsg : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired' ? item.errorHelperTextRequired : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' ? item.errorHelperText1Required : '')}
										/>
									) : (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<InputBox
												id={item.field}
												key={item.field}
												label={item.title}
												type={numberTypeFields?.includes(item?.field) ? Field_Type.Number : item?.field === Guarantor_Field.Gua_Aadhaar ? (props.type == 'view' || leadStatus != 'draft' || guarantorIndex < guarantorArray?.length || isMaskedAadhaar(stateData[`${item.type}_vl_${item.field}`]) ? Field_Type.Text : Field_Type.Number) : Field_Type.Text}
												isDrawdown={disabledFields[props.type] && item?.field && disabledFields[props.type].includes(item.field) ? false : isSectionCompleted(guarantorSectionStatus) ? false : panVerify && item.field != Guarantor_Field.Gua_Aadhaar ? false : guarantorStatus[guarantorIndex - 1] || (department == Guarantor_Dept.Guarantor_Perm_Add && !viewPerAddress) || item.field == Guarantor_Field.Gua_Age ? false : item?.isDrawdown ? true : false}
												initialValue={item.field == Guarantor_Field.Gua_Age ? (stateData[`${item.type}_vl_${item.field}`] ?? Field_Label.Auto_populate) : stateData[`${item.type}_vl_${item.field}`] ?? ''}
												isDisabled={isSectionCompleted(guarantorSectionStatus) ? true : guarantorIndex < guarantorArray?.length ? true : checkIsNameMismatch(item) ? true : disabledFields[props.type] && item?.field && disabledFields[props.type].includes(item.field) ? true : backgroundColorBlur && item.field == Guarantor_Field.Gua_Aadhaar ? true : panVerify && item.field != Guarantor_Field.Gua_Aadhaar ? true : guarantorStatus[guarantorIndex - 1] || (department == Guarantor_Dept.Guarantor_Perm_Add && !viewPerAddress) || item.field == Guarantor_Field.Gua_Age || (leadStatus != 'draft' && item.field == Guarantor_Field.Gua_Aadhaar) ? true : false}
												isReadOnly={checkIsFieldReadOnly(item)}
												onClick={(event) => {
													if (item.field === 'gua_pan' && (panVerify || panButtonState === 'loader')) {
														return;
													} else {
														change(event, item.type, item.field)
													}
												}}
												customDropdownClass={InputBoxStyle}
												customInputClass={{
													minWidth: '100%',
													marginTop: `${isSectionCompleted(guarantorSectionStatus) ? '-3px' : panVerify && item.field != 'gua_aadhaar' ? '-3px' : guarantorStatus[guarantorIndex - 1] || (department == 'Guarantor Permanent Address' && !viewPerAddress) || item.field == 'gua_age' ? '-3px' : '0px'}`,

												}}
												error={item.checked.toLowerCase() === 'true' ? (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired') : stateData && stateData[`${item.type}_vl_${item.field}`] !== '' && (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired')}
												helperText={item.checked.toLowerCase() === 'true' ? (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? item.validationMsg : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired' ? item.errorHelperTextRequired : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' ? item.errorHelperText1Required : '') : stateData && stateData[`${item.type}_vl_${item.field}`] !== '' && (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? item.validationMsg : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired' ? item.errorHelperTextRequired : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' ? item.errorHelperText1Required : '')}
												customClass={{
													height: '56px',
													width: '100%',
													maxWidth: '100%',
													border: `${item.field == 'gua_pan' ? panBorder : ''}`,
												}}
												options={item.isDrawdown ? (item.title === 'State' ? states : item.field === 'gua_city' ? currCity : item.field === 'gua_per_city' ? perCity : item.options) : []}
												isBoxType={department === 'Guarantor PAN Details' || department === 'Guarantor Aadhar Details' || department === 'Guarantor KYC Documents' || item.field === 'gua_pan' || item.field === 'aadhaar_front_image_value' || item.field === 'aadhaar_back_image_value' || item.field === 'aadhaar_xml_image_value' ? panButtonState : ''}
												Buttonlabel={guarantorStatus[guarantorIndex - 1] || props.type == 'view' ? '' : department === 'Guarantor PAN Details' || department === 'Guarantor Aadhar Details' || department === 'Guarantor KYC Documents' || item.field === 'aadhaar_front_image_value' || item.field === 'aadhaar_back_image_value' || item.field === 'aadhaar_xml_image_value' ? 'Upload' : item.field == 'gua_pan' ? 'Verify' : ''}
											/>
											<p className="book-loan-helper-text">
												{(selectedPANFileType['Response XML/JSON'] && department === 'Guarantor PAN Details') || (selectedAadharFileType['Response XML/JSON'] && department === 'Guarantor Aadhar Details') || item.field === 'aadhaar_xml_image_value'
													? ' XML or JSON upto 5MB'
													: department === 'Guarantor KYC Documents' || department === 'Guarantor PAN Details' || department === 'Guarantor Aadhar Details' || item.field === 'aadhaar_back_image_value' || item.field === 'aadhaar_front_image_value'
														? ' JPG, JPEG, PNG, PDF upto 5MB'
														: ''}
											</p>
										</div>
									)
								) : null}
							</>
						);
					})}
				</div>
			</>
		);
	};

	const handleVerifyAndNext = (clickFrom) => {
		const isVerifyNextCall = clickFrom === 'verifyNext' || clickFrom === 'saveNext'
		if (isVerifyNextCall) {
			setIsVerifyNextLoading(true)
		} else {
			setIsAddGuarantorLoading(true)
		}
		setIsDraftButtonDisable(true)
		setSectionStatusCheck('inProgress')
		const payload = {
			company_id: LAPCompanyId,
			product_id: LAPProductId,
			loan_amount: "50000",
			sequence_no: code,
			...constructPayload(stateData, false),
			isVerifyAndNext: clickFrom === 'verifyNext' ? true : false,
			isSaveAndNext: clickFrom === 'saveNext' ? true : false,
		};
		saveLogs(loanAppId, `guarantor_${guarantorIndex}_verify_and_next`);
		dispatch(
			patchLapDetailsWatcher(
				payload,
				async (result) => {
					if (isVerifyNextCall) {
						setStatusCheckApi(loanAppId, SectionData.guarantor.section_code, SectionData.guarantor.guarantor_section_submit.sub_section_code, dispatch, clickFrom === 'saveNext' ? 2 : 0);
					} else {
						setStatusCheckApi(loanAppId, SectionData.guarantor.section_code, SectionData.guarantor.guarantor_bureau.sub_section_code, dispatch, 1);
					}
				},
				(error) => {
					showAlert(error?.response?.data?.message ?? 'Something went wrong', 'error');
					setIsVerifyNextLoading(false)
					setIsAddGuarantorLoading(false)
					setSectionStatusCheck('')
				},
			),
		);
	};

	const handleSkipGuarantorSection = (isGuarantor) => {
		const bookLoanPayload = {
			user_id: user._id,
			companyId: LAPCompanyId,
			productId: LAPProductId,
			loan_app_id: loanAppId,
			user: JSON.stringify(user),
		};
		new Promise((resolve, reject) => {
			dispatch(getBookLoanDetailsWatcher(bookLoanPayload, resolve, reject));
		})
			.then((response) => {
				const payload = {
					section: 'guarantors',
					section_code: SectionData.guarantor.section_code,
					loan_app_id: loanAppId,
					lap_company_id: LAPCompanyId,
					lap_product_id: LAPProductId,
					user_id: user._id,
					is_skip: true,
					data: {
						partner_loan_app_id: loanAppId,
						section: 'guarantors',
						lap_company_id: LAPCompanyId,
						lap_product_id: LAPProductId,
						user_id: user._id,
						is_skip: true,
						section_code: SectionData.guarantor.section_code,
					}
				}
				new Promise((resolve, reject) => {
					dispatch(patchLapDetailsWatcher(payload, resolve, reject));
				})
					.then((response) => {
						setGuarantorArray(prevData => prevData.filter((item, index) => index !== itemIndex))
						setGuarantorsData(prevData => prevData.filter((item, index) => index !== itemIndex))
						isGuarantor && setGuarantorCount(GuarantorCount - 1)
						setNavIconPrefixState((prevState) => ({
							...prevState,
							[Lap_Screens.Guarantor_Details]: Section_Status_Type.Success,
						}));
						if (showShareHolding) {
							setNavState(Lap_Screens.Share_Holding_pattern)
						} else {
							setNavState(Lap_Screens.Property_Details);
						}
					})
					.catch((error) => {
						showAlert(error, 'error');
					});
			})
			.catch((error) => {
				showAlert('Error in fetching loan details', 'error');
				setSectionStatusCheck('');
			});
	}

	const navigateToNextSection = (status = Section_Status_Type.Success, isSkip = false) => {
		if (!isSkip) {
			showAlert(Field_Label.Guarantor_success_message, Alert_Type.Success);
		}
		setTimeout(() => {
			setNavIconPrefixState((prevState) => ({
				...prevState,
				[Lap_Screens.Guarantor_Details]: status,
			}));
			if (showShareHolding) {
				setNavState(Lap_Screens.Share_Holding_pattern)
			} else {
				setNavState(Lap_Screens.Property_Details);
			}
			setSectionStatusCheck('completed');
			if (props?.setShouldFetch) {
				setShouldFetch((prev) => prev + 1);
			}
		}, Number_Constants.THOUSAND);
	}

	const handleSkipButton = () => {
		if (guarantorArray.length > 1) {
			handleSkipGuarantorSection(true)
		} else if (!panVerify) {
			setGuarantorsData(prevData => prevData.filter((item, index) => index !== itemIndex))
			handleSkipGuarantorSection(false)
		}
	};

	const saveLogs = (identifier, event_name) => {
		new Promise((resolve, reject) => {
			const payload = {
				user_id: user?._id,
				identifier: identifier,
				log_details: [
					{
						user_id: user?._id,
						event_name: event_name,
						timestamp: new Date(),
					},
				],
			};
			if (payload?.user_id && identifier && event_name) {
				dispatch(postComprehensiveLogsWatcher(payload, resolve, reject));
			}
		});
	};

	//#region Button should be visible only till n-1 guarantor and also all the mandatory fields should be filled 
	const isAddGuarantorVisible = () => guarantorArray?.length < 5;
	const isAddGuarnatorEnable = isAddGuarantorVisible() && isFormCompleted
	const isFileInputAllowedToChange = () => props.type == 'view' || navIconPrefixState['Guarantor Details'] == LeadNewStatus.Approved || navIconPrefixState['Guarantor Details'] == LeadNewStatus.Deviation || (leadStatus == LeadNewStatus.Draft && guarantorIndex < guarantorArray.length) || sectionStatus.includes(statusObject?.section_status) ? false : true;
	const customButtonStyle = {
		fontSize: '16px',
		color: isAddGuarnatorEnable ? '#134CDE' : '#ffffff',
		border: isAddGuarnatorEnable && '1px solid #134CDE',
		pointerEvents: isAddGuarnatorEnable ? "auto" : "none",
		marginLeft: '2%',
		// width: 'auto',
		padding: '10px 24px',
		borderRadius: '32px',
		backgroundColor: !isAddGuarnatorEnable && 'var(--neutrals-neutral-30, #CCCDD3)'
	};

	const handlePermanentCheckbox = (status) => {
		if (status === 'checkedStatus') {
			if (props.type == 'view') {
				return stateData['address_same'] ? stateData['address_same'] : false;
			} else {
				return guarantorIndex < guarantorArray?.length || panButtonState === 'icon' ? stateData['address_same'] : !viewPerAddress;
			}
		} else if ('disabledStatus') {
			if (props.type == 'view') {
				return true;
			} else {
				return guarantorIndex < guarantorArray?.length || panButtonState === 'icon' ? true : false;
			}
		}
	}

	const handleDocumentUploadCheckbox = (fileType, fileFormat) => {
		if (fileType === 'panFileType') {
			if (props.type == 'view') {
				return stateData?.[fileType]?.[fileFormat] ? stateData?.[fileType]?.[fileFormat] : false;
			} else {
				if (loanSectionObject[`guarantor_details_${guarantorIndex}`]?.section_status == LeadNewStatus.InProgress && leadStatus == LeadNewStatus.Pending) {
					return selectedPANFileType[fileFormat];
				} else {
					return guarantorIndex < guarantorArray?.length ? stateData?.[fileType]?.[fileFormat] : selectedPANFileType[fileFormat];
				}
			}
		} else if (fileType === 'aadhaarFileType') {
			if (props.type == 'view') {
				return stateData?.[fileType]?.[fileFormat] ? stateData?.[fileType]?.[fileFormat] : false;
			} else {
				if (loanSectionObject[`guarantor_details_${guarantorIndex}`]?.section_status == LeadNewStatus.InProgress && leadStatus == LeadNewStatus.Pending) {
					return selectedAadharFileType[fileFormat];
				} else {
					return guarantorIndex < guarantorArray?.length ? stateData?.[fileType]?.[fileFormat] : selectedAadharFileType[fileFormat];
				}
			}
		}
	}

	const handleDocumentUploadDisabledCheckbox = () => {
		if (props.type == 'view') {
			return true;
		} else {
			return (guarantorIndex < guarantorArray?.length && leadStatus == LeadNewStatus.Draft) || (panButtonState != 'icon' && leadStatus == LeadNewStatus.Draft) ? true :
				leadStatus == LeadNewStatus.Pending && loanSectionObject[`guarantor_details_${guarantorIndex}`]?.section_status == LeadNewStatus.InProgress ? false : leadStatus == LeadNewStatus.Pending && loanSectionObject[`guarantor_details_${guarantorIndex}`]?.section_status ? true : false;
		}
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '98%',
				justifyContent: 'space-around',
			}}
		>
			{props.type == 'edit' && props.leadComment[`guarantor_details_${code - guarantorCode}`] && (props.leadComment[`guarantor_details_${code - guarantorCode}`].remark_reasons || props.leadComment[`guarantor_details_${code - guarantorCode}`].remarks) && <CommentViewer head="Credit Manager Comment" reasons={props.leadComment[`guarantor_details_${code - guarantorCode}`].remark_reasons} additionalComment={props.leadComment[`guarantor_details_${code - guarantorCode}`].remarks} />}
			<div
				style={{
					display: 'flex',
					position: 'sticky',
					top: '0',
					paddingTop: '1rem',
					marginBottom: '1rem',
					background: '#fff',
					zIndex: '2',
					justifyContent: 'space-between',
				}}
			>
				<div style={CustomTitleStyle}>{item.name} Details</div>
				{props.type == 'edit' && <>
					{!panVerify ? (
						<Button
							id="skip"
							label="Skip"
							buttonType="link-button"
							onClick={handleSkipButton}
							customStyle={{
								fontFamily: 'Montserrat-Regular',
								height: '32px',
								width: '64px',
								fontSize: '14px',
								lineHeight: '21px',
								color: '#134CDE',
							}}
						/>
					) : null}
				</>}
			</div>
			<div>{renderFields('Guarantor Details')}</div>
			<div style={customSubHeaderStyle}>Current Address</div>
			<div>{renderFields('Guarantor Current Address')}</div>
			<div style={{ display: 'flex' }}>
				<div style={customSubHeaderStyle}>Permanent Address</div>
				<>
					<input
						style={{
							marginLeft: '16px',
							marginTop: '32px',
							width: '1rem',
							height: '1rem',
						}}
						type="checkbox"
						onClick={handlePermanentAddress}
						checked={handlePermanentCheckbox('checkedStatus')}
						disabled={handlePermanentCheckbox('disabledStatus')} />
					<div
						style={{
							fontFamily: 'Montserrat-Regular',
							fontSize: '0.9vw',
							marginTop: '32px',
							marginLeft: '0.5%',
							color: '#767888',
						}}
					>
						{' '}
						Same as current address
					</div>
				</>
			</div>
			<div>{renderFields('Guarantor Permanent Address')}</div>
			<div style={CustomHeaderStyle}>Guarantor KYC</div>
			<div>{renderFields('Guarantor KYC')}</div>
			<div style={customSubHeaderStyle}>KYC Documents</div>
			<div style={{ marginLeft: '2%' }}>
				<UploadFileInput
					borrowerIndex={guarantorIndex}
					uploadFileName={uploadFileName}
					items={guarantorSelfieState}
					title=""
					backgroundColorBlur={backgroundColorBlur}
					backgroundColorChange={true}
					isSubmit={props.type == 'view' ? false : guarantorStatus[guarantorIndex - 1]}
					isChange={isFileInputAllowedToChange()}
					isXML={false}
					loanAppId={loanAppId}
					setDocumentStateData={setDocumentStateData}
					sectionName={sectionName}
					data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
					showAlert={showAlert}
					LAPCompanyId={LAPCompanyId}
					LAPProductId={LAPProductId}
					type={props.type}
					guarantorStyle={true}
					borrowerId={stateData?.borrower_id}
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', columnGap: '5%' }}>
				<div style={CustomHeader}>Select PAN document type</div>
				<label
					style={{
						...radioCss,
						color: panVerify ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8',
					}}
				>
					<input type="checkbox" id={`guarantor_pan_pdf_image_${item.id}`} value={Field_Label.IMAGE_PDF} checked={handleDocumentUploadCheckbox('panFileType', 'Image & PDF')}
						onChange={(e) => handleRadioButton({
							event: e,
							updateSelectedFileType: setSelectedPANFileType,
							selectedFileType: selectedPANFileType,
							docCode: [documentCode.guar_pan_XML],
							docNames: [Field_Label.Pan_XML]
						})}
						style={radioInputCss}
						disabled={handleDocumentUploadDisabledCheckbox()}
					/>
					{Field_Label.IMAGE_PDF}
				</label>
				<label
					style={{
						...radioCss,
						color: panVerify ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8',
					}}
				>
					<input type="checkbox" id={`guarantor_pan_xml_image_${item.id}`} value={Field_Label.RESPONSE_XML_JSON} checked={handleDocumentUploadCheckbox('panFileType', 'Response XML/JSON')}
						onChange={(e) => handleRadioButton({
							event: e,
							updateSelectedFileType: setSelectedPANFileType,
							selectedFileType: selectedPANFileType,
							docCode: [documentCode.guar_pan],
							docNames: [Field_Label.Pan_1]
						})}
						style={radioInputCss}
						disabled={handleDocumentUploadDisabledCheckbox()}
					/>
					{Field_Label.RESPONSE_XML_JSON}
				</label>
			</div>
			<div style={{ display: 'flex' }}>
				{handleDocumentUploadCheckbox('panFileType', 'Image & PDF') ? (
					<div style={{ marginLeft: '2%' }}>
						<UploadFileInput
							key={selectedPANFileType['Image & PDF']}
							borrowerIndex={guarantorIndex}
							uploadFileName={uploadFileName}
							items={guarantorPanState}
							title=""
							backgroundColorBlur={!panVerify ? backgroundColorBlur : isSectionCompleted(guarantorSectionStatus) || props.type == 'view' ? backgroundColorBlur : false}
							backgroundColorChange={true}
							isSubmit={props.type == 'view' ? false : guarantorStatus[guarantorIndex - 1]}
							isXML={false}
							loanAppId={loanAppId}
							setDocumentStateData={setDocumentStateData}
							sectionName={sectionName}
							data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
							showAlert={showAlert}
							borrowerId={stateData?.borrower_id}
							LAPCompanyId={LAPCompanyId}
							LAPProductId={LAPProductId}
							isChange={isFileInputAllowedToChange()}
							type={props.type}
							guarantorStyle={true}
						/>
					</div>
				) : null}
				{handleDocumentUploadCheckbox('panFileType', 'Response XML/JSON') ? (
					<div style={{ marginLeft: '2%' }}>
						<UploadFileInput
							key={selectedPANFileType['Response XML/JSON']}
							borrowerIndex={guarantorIndex}
							uploadFileName={uploadFileName}
							items={guarantorPanXMLState}
							title=""
							backgroundColorBlur={!panVerify ? backgroundColorBlur : sectionVerified ? true : isSectionCompleted(guarantorSectionStatus) || props.type == 'view' ? backgroundColorBlur : false}
							backgroundColorChange={true}
							isSubmit={props.type == 'view' ? false : guarantorStatus[guarantorIndex - 1]}
							isXML={true}
							loanAppId={loanAppId}
							setDocumentStateData={setDocumentStateData}
							sectionName={sectionName}
							data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
							showAlert={showAlert}
							borrowerId={stateData?.borrower_id}
							LAPCompanyId={LAPCompanyId}
							LAPProductId={LAPProductId}
							isChange={isFileInputAllowedToChange()}
							type={props.type}
							guarantorStyle={true}
						/>
					</div>
				) : null}
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', columnGap: '5%' }}>
				<div style={CustomHeader}>Select Aadhaar document type</div>
				<label
					style={{
						...radioCss,
						color: panVerify ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8',
					}}
				>
					<input
						type="checkbox"
						value={Field_Label.IMAGE_PDF}
						id={`guarantor_aadhaar_pdf_image_${item.id}`}
						checked={handleDocumentUploadCheckbox('aadhaarFileType', 'Image & PDF')}
						onChange={(e) => handleRadioButton({
							event: e,
							updateSelectedFileType: setAadharFileType,
							selectedFileType: selectedAadharFileType,
							docCode: [documentCode.guar_aadhaar_XML],
							docNames: [Field_Label.Aadhar_XML]
						})}
						style={radioInputCss}
						disabled={handleDocumentUploadDisabledCheckbox()}
					/>
					{Field_Label.IMAGE_PDF}
				</label>
				<label
					style={{
						...radioCss,
						color: panVerify ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8',
					}}
				>
					<input
						type="checkbox"
						value={Field_Label.RESPONSE_XML_JSON}
						id={`guarantor_aadhaar_xml_image_${item.id}`}
						checked={handleDocumentUploadCheckbox('aadhaarFileType', 'Response XML/JSON')}
						onChange={(e) => handleRadioButton({
							event: e,
							updateSelectedFileType: setAadharFileType,
							selectedFileType: selectedAadharFileType,
							docCode: [documentCode.guar_aadhaar_front, documentCode.guar_aadhaar_back],
							docNames: [Field_Label.aadhar_front, Field_Label.Aadhar_back]
						})}
						style={radioInputCss}
						disabled={handleDocumentUploadDisabledCheckbox()}
					/>
					Response XML/JSON
				</label>
			</div>
			<div style={{ display: 'flex' }}>
				{handleDocumentUploadCheckbox('aadhaarFileType', 'Image & PDF') ? (
					<div style={{ marginLeft: '2%' }}>
						<UploadFileInput
							key={selectedAadharFileType['Image & PDF']}
							borrowerIndex={guarantorIndex}
							uploadFileName={uploadFileName}
							items={guarantorAadharState}
							title=""
							backgroundColorBlur={!panVerify ? backgroundColorBlur : sectionVerified ? true : isSectionCompleted(guarantorSectionStatus) || guarantorIndex < guarantorArray?.length || props.type == 'view' ? backgroundColorBlur : false}
							backgroundColorChange={true}
							isSubmit={props.type == 'view' ? false : guarantorStatus[guarantorIndex - 1]}
							isXML={false}
							loanAppId={loanAppId}
							setDocumentStateData={setDocumentStateData}
							sectionName={sectionName}
							data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
							showAlert={showAlert}
							borrowerId={stateData?.borrower_id}
							LAPCompanyId={LAPCompanyId}
							LAPProductId={LAPProductId}
							isChange={isFileInputAllowedToChange()}
							type={props.type}
							guarantorStyle={true}
						/>
					</div>
				) : null}
				{handleDocumentUploadCheckbox('aadhaarFileType', 'Response XML/JSON') ? (
					<div style={{ marginLeft: '2%' }}>
						<UploadFileInput
							key={selectedAadharFileType['Response XML/JSON']}
							borrowerIndex={guarantorIndex}
							uploadFileName={uploadFileName}
							items={guarantorAadharXMLState}
							title=""
							backgroundColorBlur={!panVerify ? backgroundColorBlur : isSectionCompleted(guarantorSectionStatus) || props.type == 'view' ? backgroundColorBlur : false}
							backgroundColorChange={true}
							isSubmit={props.type == 'view' ? false : guarantorStatus[guarantorIndex - 1]}
							isXML={true}
							loanAppId={loanAppId}
							setDocumentStateData={setDocumentStateData}
							sectionName={sectionName}
							data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
							showAlert={showAlert}
							borrowerId={stateData?.borrower_id}
							LAPCompanyId={LAPCompanyId}
							LAPProductId={LAPProductId}
							isChange={isFileInputAllowedToChange()}
							type={props.type}
							guarantorStyle={true}
						/>
					</div>
				) : null}
			</div>
			{isDisplaySubmitButton(navIconPrefixState[Lap_Screens.Guarantor_Details], props.type) ? null :
				<>
					{itemIndex === (guarantorArray.length - 1) && leadStatus != LeadNewStatus.Pending ? (
						<div
							style={{
								display: 'flex',
								marginTop: '2%',
								marginBottom: '2%',
								justifyContent: 'space-between',
							}}
							className={`book-loan-button-container ${isAddGuarantorVisible() ? 'book-loan-button-alignment-triple-button' : 'book-loan-button-alignment-double-button'}`}
						>
							{isAddGuarantorVisible() ? (<Button id="addGuarantor" label="Add Guarantor" isLoading={isAddGuarantorLoading} buttonType="secondary" isDisabled={!isAddGuarnatorEnable} onClick={() => handleVerifyAndNext('addGuarantor')} customStyle={customButtonStyle} />) : null}
							<div className="book-loan-button-alignment-double-button">
								{getSubmitButton('Verify & Next', () => handleVerifyAndNext('verifyNext'), isVerifyNextLoading, isFormCompleted)}
								{getSaveAsDraftButton('Save as Draft', handleDraft, isDraftButtonDisabled, isDraftLoading)}
							</div>
						</div>
					) : loanSectionObject[`guarantor_details_${guarantorIndex}`]?.section_status == LeadNewStatus.InProgress && leadStatus == LeadNewStatus.Pending && (
						<div className="book-loan-button-alignment-double-button">
							{getSubmitButton('Save & Next', () => handleVerifyAndNext('saveNext'), isVerifyNextLoading, isFormCompleted)}
							{leadStatus != LeadNewStatus.Pending && getSaveAsDraftButton('Save as Draft', handleDraft, disableDraftButton)}
						</div>
					)
					}
				</>
			}
			{alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
		</div>
	);
}
