import createBusinessModel from './views/CreateBusinessModel/createBusinessModel';
import ServiceFeeReconView from './views/Partners/ServiceFeeRecon.view';
import reconSummary from './views/ReconSummary/reconSummary';

var routes = [
  {
    path: '/service-fee-recon/create-business-model',
    name: 'Create Business Model',
    miniName: 'CBM',
    component: createBusinessModel,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_service_fee_recon_read', 'tag_service_fee_recon_write'],
  },
  {
    path: '/service-fee-recon/recon-summary/',
    name: 'Recon Summary',
    miniName: 'RS',
    component: reconSummary,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_service_fee_recon_read', 'tag_service_fee_recon_write'],
  },
  {
    path: '/service-fee-recon/business-model-details/:companyId/:productId/:businessModelId',
    name: 'Business Model Details',
    miniName: 'BMD',
    component: createBusinessModel,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_service_fee_recon_read', 'tag_service_fee_recon_write'],
  },
  {
    path: '/service-fee-recon',
    name: 'Service Fee Recon',
    miniName: 'SFR',
    component: ServiceFeeReconView,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: false,
    tags: ['tag_service_fee_recon_read', 'tag_service_fee_recon_write'],
  },
];

export default routes;
