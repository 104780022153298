// to get all loc drawdown reports lists on reports page
export function getReportRequestsWatcher(data, resolve, reject) {
  return {
    type: 'GET_REPORT_REQUESTS',
    payload: data,
    resolve,
    reject,
  };
}

export function generateReportRequestWatcher(data, resolve, reject) {
  return {
    type: 'GENERATE_REPORT_REQUEST',
    payload: data,
    resolve,
    reject,
  };
}

export function downloadReportRequestFileWatcher(data, resolve, reject) {
  return {
    type: 'DOWNLOAD_REPORT_REQUEST',
    payload: data,
    resolve,
    reject,
  };
}

export function generateCoLendingCamsReport(data, resolve, reject) {
  return {
    type: 'GENERATE_CO_LENDING_CAMS_REPORT',
    payload: data,
    resolve,
    reject,
  };
}

export function getColenderReport(data, resolve, reject) {
  return {
    type: 'GET_COLENDER_REPORT',
    payload: data,
    resolve,
    reject,
  };
}

export function downloadCoLenderReportWatcher(data, resolve, reject) {
  return {
    type: 'DOWNLOAD_CO_LENDER_CAM_REPORT',
    payload: data,
    resolve,
    reject,
  };
}

export function generatePartnerOperationsReportWatcher(data, resolve, reject) {
  return {
    type: 'GENERATE_PARTNER_OPERATIONS_REPORT',
    payload: data,
    resolve,
    reject,
  };
}

export function getPartnerOperationsReportWatcher(data, resolve, reject) {
  return {
    type: 'GET_PARTNER_OPERATIONS_REPORT',
    payload: data,
    resolve,
    reject,
  };
}

export function downloadPartnerOperationsReportWatcher(data, resolve, reject) {
  return {
    type: 'DOWNLOAD_PARTNER_OPERATIONS_REPORT',
    payload: data,
    resolve,
    reject,
  };
}
