import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js';

const MonthlyChart = ({ data }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(data || []);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setChartData(data || []);
  }, [data]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonthIndex = new Date().getMonth();
    let labels = [];
    let values1 = [];
    let values2 = [];
    let values3 = [];

    // Generate labels for the past 6 months
    labels = Array.from({ length: chartData.length }, (_, i) => {
      const month = new Date(currentYear, currentMonthIndex - i);
      return month.toLocaleString('en-US', { month: 'short' });
    }).reverse();

    // Extract data for each dataset
    chartData.forEach((element) => {
      values1.push(element.values[0]);
      values2.push(element.values[1]);
      values3.push(element.values[2]);
    });

    const dataForChart = {
      labels,
      datasets: [
        {
          label: 'Applications Received',
          data: values1,
          backgroundColor: '#6498CC',
          borderColor: '#6498CC',
          borderWidth: 1,
          maxBarThickness: 15,
        },
        {
          label: 'Incomplete Applications',
          data: values2,
          backgroundColor: '#407CB7',
          borderColor: '#407CB7',
          borderWidth: 1,
          maxBarThickness: 15,
        },
        {
          label: 'Decision Pending',
          data: values3,
          backgroundColor: '#2F629A',
          borderColor: '#2F629A',
          borderWidth: 1,
          maxBarThickness: 15,
        },
      ],
    };

    const chartInstance = new Chart(chartRef.current.getContext('2d'), {
      type: 'bar',
      data: dataForChart,
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            stacked: true,
          },
        },
        // plugins: {
        // title: {
        //   display: true,
        //   text: 'Disbursement (in ₹)',
        // },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          display: true,
          position: 'top',
        },
        // },
      },
    });

    setWidth(chartInstance.width);
    setHeight(chartInstance.height);

    return () => chartInstance.destroy();
  }, [chartData]);

  return (
    <div style={{ height: '500px', position: 'relative' }}>
      {!chartData.length && (
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: height / 2 + 'px',
            zIndex: 20,
          }}
        >
          <b>No data to display!</b>
        </div>
      )}
      <canvas ref={chartRef} style={{ height: '100%' }}></canvas>
    </div>
  );
};

export default MonthlyChart;
