import Table from 'react-sdk/dist/components/Table';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button';
import ImgH from '../../assets/img/plus-symbol.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { partnerListWatcher } from '../../actions/addPartner';
import { storedList } from '../../util/localstorage';
import { checkAccessTags } from '../../util/uam';

const user = storedList('user');

const PartnerList = () => {
  const dispatch = useDispatch();
  const [str, setStr] = useState(null);
  const [partnerList, setPartnerList] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const styles = useStyles({ innerWidth, innerHeight });

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  React.useEffect(() => {
    if (isTagged && checkAccessTags(['tag_user_read', 'tag_user_read_write'])) fetchPartnerList();
    if (!isTagged) fetchPartnerList();
  }, []);

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const fetchPartnerList = () => {
    const payload = { page, limit, str: str ? str : 'null' };
    new Promise((resolve, reject) => {
      dispatch(partnerListWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setPartnerList(response.companies);
        setCount(response.count);
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage(error.response.data.message);
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
      });
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };
  useEffect(() => {
    if (page >= 0) {
      fetchPartnerList();
    }
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(event);
  };

  const handleClick = () => {
    if (page === 0) fetchPartnerList();
    if (page !== 0) setPage(0);
  };

  const openAddPartner = () => {
    history.push(`/admin/partner`);
  };

  const openPartnerDetails = (id) => {
    history.push(`/admin/partner_details/${id}`);
  };

  const columns = [
    {
      id: '_id',
      label: <span style={{ marginLeft: '-0.626rem' }}>{'COMPANY ID'}</span>,
    },
    {
      id: 'name',
      label: 'COMPANY NAME',
      format: (item) => <span style={{ whiteSpace: 'nowrap' }}>{item.name}</span>,
    },
    { id: 'short_code', label: 'SHORT CODE', format: (item) => (item.short_code ? item.short_code : 'NA') },
  ];

  return (
    <>
      <div style={styles.customOuterClass}>
        <div style={{ display: 'flex', marginLeft: '0.8%' }}>
          <InputBox
            label="Search partner"
            isDrawdown={false}
            isSearch={true}
            onClick={(event) => {
              if (event.target) {
                handleClick();
              } else {
                setStr(event.value);
              }
            }}
            customClass={{
              maxWidth: 'none',
              width: '100%',
              height: '56px',
              borderRadius: '8px',
              fontFamily: 'Montserrat-Medium',
            }}
            customInputClass={{
              maxWidth: 'none',
              width: '300px',
              fontFamily: 'Montserrat-Medium',
            }}
          />
        </div>
        <div style={styles.customAddPartnerClass}>
          <Button
            buttonType="primary"
            customStyle={styles.customAddPartnerButtonClass}
            onClick={openAddPartner}
            label={
              <React.Fragment>
                <img height={25} width={25} style={{ marginBottom: '2.5%', marginRight: '2%' }} src={ImgH} alt="Add Icon" />
                Add Partner
              </React.Fragment>
            }
          />
        </div>
      </div>
      {partnerList && <Table customStyle={styles.customTableClass} data={partnerList} columns={columns} rowClickFunction={openPartnerDetails} rowClickValue={'_id'} />}
      {count ? <Pagination itemsPerPage={limit} totalItems={count} onPageChange={handleChangePage} /> : null}
    </>
  );
};

export default PartnerList;

const useStyles = () => {
  return {
    customAddPartnerClass: {
      display: 'flex',
      float: 'right',
      whiteSpace: 'nowrap',
    },
    customOuterClass: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '0.5%',
      paddingRight: '1%',
    },
    customButtonClass: {
      fontSize: '1rem',
      fontFamily: 'Montserrat-SemiBold',
      borderRadius: '0.5rem',
      width: '9.063rem',
      height: '3.5rem',
    },
    customAddPartnerButtonClass: {
      fontSize: '1rem',
      fontFamily: 'Montserrat-SemiBold',
      borderRadius: '0.5rem',
      width: '10.938rem',
      height: '3.5rem',
    },
    customClass: {
      height: '3.5rem',
      width: '20vw',
      maxWidth: '100%',
      paddingTop: '0.3%',
      marginLeft: '2%',
      fontSize: '0.8vw',
      marginRight: '2%',
    },
    customTableClass: {
      width: '98%',
      marginLeft: '1%',
      display: 'grid',
      gridTemplateColumns: `25% 50% 25%`,
      fontFamily: 'Montserrat-Medium',
    },
    customInputClass: {
      width: '100%',
      maxWidth: '100%',
      paddingTop: '1%',
      paddingBottom: '0.1%',
      fontSize: '1vw',
      fontFamily: 'Montserrat-Regular',
      marginLeft: '2%',
    },
  };
};
