import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Table from 'react-sdk/dist/components/Table/Table';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import { storedList } from '../../util/localstorage';
import { Button, Divider } from '@mui/material';

import { statusToDisplay } from '../../util/helper';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import { compositeDisbursementWatcher, getLoanByStatusForLocWatcher, getLoanByStatusWatcher, compositeDrawdownWatcher, processDrawdownPfWatcher } from '../../actions/compositeDisbursement';
import CompanyDropdown from '../../components/Company/SelectCompany';
import ProductDropdown from '../../components/Product/SelectProduct';
import DisbursementRequestPopUp from './disbursementRequestPopUp';
import DDRPFProcessPopUp from './DDRPFProcessPopUp';
import { checkAccessTags } from '../../util/uam';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import { getProductByIdWatcher } from '../../actions/product';
import DrawDownRequestUi from '../lending/drawDownRequestUi';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';

import { Link } from 'react-router-dom';
import { getBorrowerDetailsByIdWatcher, getBalanceDetailWatcher } from 'actions/borrowerInfo';

const user = storedList('user');

const typeToDisplay = {
  Colend_loans: 'Yes',
  Non_Colend_loans: 'No',
};

const DisbursementRequest = (props) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [company, setCompany] = useState(null);
  const [product, setProduct] = useState(null);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState('');
  const [isAllSelect, setIsAllSelect] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disbursementRecords, setDisbursementRecords] = useState([]);
  const [selectedDisbursementRecords, setSelectedDisbursementRecords] = useState([]);
  const [successRows, setSuccessRows] = useState([]);
  const [failRows, setFailRows] = useState([]);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50, 100]);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [isDDRPFOpen, setIsDDRPFOpen] = useState(false);
  const [errorRowsText, setErrorRowsText] = React.useState('');
  const [successRowsText, setSuccessRowsText] = React.useState('');
  const [isProgressStop, setIsProgressStop] = React.useState(false);
  const [isProgressStart, setIsProgressStart] = React.useState(false);
  const [totalSelection, setTotalSelectCount] = useState(0);
  const [totalSanctionAmount, setTotalSanctionAmount] = useState(0);
  const [totalNetDisbAmount, setTotalNetDisbAmount] = useState(0);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [walletConfigCheck, setWalletConfig] = useState(false);
  const [type, setType] = useState('N');
  const [typetodisplay, setTypeToDisplay] = useState('No');
  const [drawDownErrorMessage, setDrawDownErrorMessage] = useState('');
  const [productDetails, setproductDetails] = useState({});
  const [ccReceivedPop, setCcReceivedPop] = useState(false);
  const [borroData, setBorroData] = useState([]);
  const [checkDisbursementPopUp, setCheckDisbursementPopUp] = useState(false);
  const [pendingBalanceDetail, setpendingBalanceDetail] = useState({});
  const [loanIdsExceedDisbursementAmount, setloanIdsExceedDisbursementAmount] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});
  const user = storedList('user');

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  const getDisbursalApprovedList = () => {
    const payload = {
      sendData: {
        ...filter,
        page: page,
        limit: rowsPerPage,
        status: 'disbursal_approved',
        stage: 3,
      },
      userData: {
        user_id: user._id,
        ...filter,
      },
    };
    new Promise((resolve, reject) => {
      dispatch(getLoanByStatusWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setIsAllSelect(false);
        setDisbursementRecords(
          response?.rows.map((item) => {
            return {
              ...item,
              checked: false,
            };
          }),
        );
        setAvailableBalance(response.availableBalance);
        setWalletConfig(response.walletConfigCheck);
        setCount(response?.count);
      })
      .catch((error) => {
        setDisbursementRecords([]);
        showAlert(error?.response?.data?.message, 'error');
      });
  };
  const getDisbursalApprovedListForLoc = () => {
    const payload = {
      sendData: {
        ...filter,
        page: page,
        limit: rowsPerPage,
        status: 0,
      },
      userData: {
        user_id: user._id,
        ...filter,
      },
    };

    new Promise((resolve, reject) => {
      dispatch(getLoanByStatusForLocWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setIsAllSelect(false);
        setDisbursementRecords(
          response?.rows.map((item) => {
            return {
              ...item,
              checked: false,
            };
          }),
        );
        setAvailableBalance(response.availableBalance);
        setWalletConfig(response.walletConfigCheck || false);
        setCount(response?.count);
      })
      .catch((error) => {
        setDisbursementRecords([]);
        showAlert(error?.response?.data?.message, 'error');
      });
  };
  const errorTextToRender = () => {
    var newLine = '\r\n';
    var text = newLine;
    failRows.map((item, index) => {
      text = text + `${index + 1})  ${item.loan_id} - ${item.message}` + newLine;
    });
    setErrorRowsText(text);
    return text;
  };
  const successTextToRender = () => {
    var newLine = '\r\n';
    var text = newLine;
    successRows.map((item, index) => {
      text = text + `${index + 1}) ${item.loan_id} -  ${item.response.txn_id} - ${item.response?.data?.remarks}` + newLine;
    });
    setSuccessRowsText(text);
    return text;
  };

  const handleDisbursement = (count) => {
    if (!selectedDisbursementRecords.length) return;
    setIsProgressStart(true);
    const data = selectedDisbursementRecords[count];
    if (count === selectedDisbursementRecords.length) {
      errorTextToRender();
      setIsProgressStop(true);
      setIsProgressStart(false);
      setIsAllSelect(false);
      clearSelectionSummary();
      getDisbursalApprovedList();
      return;
    }
    const payload = {
      submitData: {
        loan_app_id: data?.loan_app_id,
        loan_id: data.loan_id,
        borrower_id: data?.borrower_id,
        partner_loan_app_id: data?.partner_loan_app_id,
        partner_loan_id: data?.partner_loan_id,
        partner_borrower_id: data?.partner_borrower_id,
        borrower_mobile: data?.loan_request[0]?.appl_phone,
        txn_date: moment(new Date()).format('YYYY-MM-DD'),
        sanction_amount: data?.sanction_amount,
        net_disbur_amt: data?.net_disbur_amt,
      },
      userData: {
        user_id: user._id,
        ...filter,
      },
    };

    new Promise((resolve, reject) => {
      dispatch(compositeDisbursementWatcher(payload, resolve, reject));
    })
      .then((response) => {
        successRows.push(data);
        handleDisbursement(count + 1);
      })
      .catch((error) => {
        failRows.push({
          loan_id: data.loan_id,
          message: error?.response?.data?.message,
        });
        handleDisbursement(count + 1);
      });
  };
  const handleCompositeDrawdown = (count) => {
    if (!selectedDisbursementRecords.length) return;
    setDrawDownErrorMessage('');
    let finalProcessData = selectedDisbursementRecords.map((item) => {
      let obj = {
        _id: item?._id,
        loan_app_id: item?.loan_app_id,
        loan_id: item.loan_id,
        borrower_id: item?.borrower_id,
        partner_loan_app_id: item?.partner_loan_app_id,
        partner_loan_id: item?.partner_loan_id,
        partner_borrower_id: item?.partner_borrower_id,
        txn_date: moment(new Date()).format('YYYY-MM-DD'),
        sanction_amount: item?.sanction_amount,
        net_disbur_amt: item?.net_disbur_amt,
        borrower_mobile: item?.borrower_mobile,
        drawadown_request_date: moment(new Date()).format('YYYY-MM-DD'),
        drawdown_amount: item?.drawdown_amount,
        net_drawdown_amount: item?.net_drawdown_amount,
        usage_fees: item?.usage_fees,
        usage_fees_including_gst: item?.usage_fees_including_gst,
        no_of_emi: Number(item?.no_of_emi),
        repayment_days: Number(item?.repayment_days),
        withheld_amount: item?.withheld_amount,
        invoice_number: item?.invoice_number,
      };
      if (item.hasOwnProperty('processing_fees_including_gst')) obj['processing_fees_including_gst'] = item.processing_fees_including_gst;
      return obj;
    });

    // updated payload
    const payloadProcess = {
      submitData: finalProcessData,
      userData: {
        user_id: user._id,
        ...filter,
      },
    };

    new Promise((resolve, reject) => {
      dispatch(processDrawdownPfWatcher(payloadProcess, resolve, reject));
    })
      .then((response) => {
        processDrawdownFinal(count);
      })
      .catch((error) => {
        setDrawDownErrorMessage(error?.response?.data?.message);
        setIsDDRPFOpen(true);
        //showAlert(error?.response?.data?.message, 'error');
      });
  };

  const handleStartDisbursement = () => {
    if (product?.isLoc) {
      handleCompositeDrawdown(0);
    } else {
      setIsOpenPopUp(!isOpenPopUp);
      handleDisbursement(0);
    }
  };

  const getProductDetails = (product_id) => {
    new Promise((resolve, reject) => {
      dispatch(getProductByIdWatcher(product_id, resolve, reject));
    })
      .then((response) => {
        setproductDetails(response);
        // product = response;
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message || 'Error while getting product details', 'error');
      });
  };

  const processDrawdownFinal = (count) => {
    setIsOpenPopUp(!isOpenPopUp);
    setIsProgressStart(true);
    const data = selectedDisbursementRecords[count];
    if (count === selectedDisbursementRecords.length) {
      errorTextToRender();
      successTextToRender();
      setIsProgressStop(true);
      setIsProgressStart(false);
      setIsAllSelect(false);
      clearSelectionSummary();
      getDisbursalApprovedListForLoc();
      return;
    }

    let payload = {
      submitData: {
        _id: data?._id,
        loan_app_id: data?.loan_app_id,
        loan_id: data.loan_id,
        borrower_id: data?.borrower_id,
        partner_loan_app_id: data?.partner_loan_app_id,
        partner_loan_id: data?.partner_loan_id,
        partner_borrower_id: data?.partner_borrower_id,
        txn_date: moment(new Date()).format('YYYY-MM-DD'),
        sanction_amount: data?.sanction_amount,
        net_disbur_amt: data?.net_disbur_amt,
        borrower_mobile: data?.borrower_mobile,
        drawadown_request_date: moment(new Date()).format('YYYY-MM-DD'),
        drawdown_amount: data?.drawdown_amount,
        net_drawdown_amount: data?.net_drawdown_amount,
        usage_fees: data?.usage_fees,
        usage_fees_including_gst: data?.usage_fees_including_gst,
        no_of_emi: Number(data?.no_of_emi),
        repayment_days: Number(data?.repayment_days),
        withheld_amount: data?.withheld_amount,
        invoice_number: data?.invoice_number,
      },
      userData: {
        user_id: user._id,
        ...filter,
      },
    };

    if (data.hasOwnProperty('processing_fees_including_gst')) payload.submitData['processing_fees_including_gst'] = data.processing_fees_including_gst;

    new Promise((resolve, reject) => {
      dispatch(compositeDrawdownWatcher(payload, resolve, reject));
    })
      .then((response) => {
        successRows.push(response);
        processDrawdownFinal(count + 1);
      })
      .catch((error) => {
        failRows.push({
          loan_id: data.loan_id,
          message: error?.response?.data?.message,
        });
        processDrawdownFinal(count + 1);
      });
  };

  useEffect(() => {
    if (isTagged && checkAccessTags(['tag_disbursement_read', 'tag_disbursement_read_write'])) {
      if (product?.isLoc && filter) {
        getDisbursalApprovedListForLoc();
      } else if (filter && !product?.isLoc) {
        getDisbursalApprovedList();
      }
    }
    if (!isTagged) {
      if (product?.isLoc && filter) {
        getDisbursalApprovedListForLoc();
      } else if (filter && !product?.isLoc) {
        getDisbursalApprovedList();
      }
    }
  }, [filter, page, rowsPerPage, borroData]);

  const getLoanDetails = (loan_id) => {
    const params = {
      company_id: company.value,
      product_id: product.value,
      loan_id: loan_id,
    };
    let borrowerData;
    dispatch(
      getBorrowerDetailsByIdWatcher(
        params,
        (result) => {
          borrowerData = result.data;
          setBorroData(borrowerData);
        },
        (error) => {
          return showAlert(error.response.data.message, 'error');
        },
      ),
    );
  };

  const getBalanceDetail = async () => {
    let pendingBalance = {};
    let loanIds = selectedDisbursementRecords.map((data) => {
      pendingBalance[data.loan_id] = pendingBalance[data.loan_id] ? pendingBalance[data.loan_id] + data.drawdown_amount : data.drawdown_amount;
      return data.loan_id;
    });

    setpendingBalanceDetail(pendingBalance);

    return new Promise((resolve, reject) => {
      dispatch(
        getBalanceDetailWatcher(
          {
            company_id: company.value,
            product_id: product.value,
            loan_ids: loanIds,
          },
          (result) => {
            let balanceDetailData = result.balanceDetail;
            resolve(balanceDetailData);

            //  setBalanceDetail(result.balanceDetail ||[]);
          },
          (error) => {
            reject(error.response.data.message || error.message || error);
            return showAlert(error.response?.data?.message || error.message || error, 'error');
          },
        ),
      );
    });
  };
  const ddExceedPopupConfirmation = async () => {
    await getBalanceDetail().then((result) => {
      const loans = result.filter((data) => {
        let check = data.limit_for_use_amount < pendingBalanceDetail[data.loan_id] + data.used_limit;
        return check;
      });
      const loanIds = loans.map((data) => data.loan_id);
      setloanIdsExceedDisbursementAmount(loanIds);

      if (loanIds.length) {
        setCheckDisbursementPopUp(true);
      } else {
        ccPopupConfirmation();
      }
    });
  };
  const ccPopupConfirmation = () => {
    if (borroData?.cash_collateral === 'received' || product?.cash_collateral_received == true) {
      setCcReceivedPop(true);
    } else {
      handleStartDisbursement();
    }
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 4000);
  };

  const onSearchClick = (data) => {
    if (!company?.value) return showAlert('Please select company', 'error');
    if (!product?.value) return showAlert('Please select product', 'error');
    if (!type) return showAlert('Please select Co-lend Loan', 'error');
    setIsAllSelect(false);
    setSelectedDisbursementRecords([]);
    setPage(0);
    setDisbursementRecords([]);
    setFilter({
      company_id: company.value,
      product_id: product.value,
      co_lend_flag: type,
    });
    clearSelectionSummary();
  };

  const handleChangePage = (event, newPage) => {
    setPage(event);
    clearSelectionSummary();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event));
    setIsAllSelect(false);
    setSelectedDisbursementRecords([]);
    setPage(0);
  };

  const handleSelectSingleRecord = (data, event) => {
    let newRecords = [...disbursementRecords];
    const rowToChange = newRecords.findIndex((item) => {
      return item._id === data._id;
    });
    newRecords[rowToChange].checked = !newRecords[rowToChange].checked;
    setDisbursementRecords(newRecords);
    let sanctionSum = Number(totalSanctionAmount);
    let netDisbSum = Number(totalNetDisbAmount);
    let count = Number(totalSelection);
    if (!event?.target?.checked) {
      let selectedRecords = [...selectedDisbursementRecords];
      const index = selectedRecords.findIndex((item) => {
        if (item._id === data._id) {
          if (product.isLoc) {
            if (data.drawdown_amount) {
              sanctionSum -= parseFloat(item.drawdown_amount);
            }
            if (data.net_drawdown_amount) {
              netDisbSum -= parseFloat(item.net_drawdown_amount);
            }
          } else {
            if (data.sanction_amount) {
              sanctionSum -= parseFloat(item.sanction_amount);
            }
            if (data.net_disbur_amt) {
              netDisbSum -= parseFloat(item.net_disbur_amt);
            }
          }
          count -= 1;
        }
        return item._id === data._id;
      });
      selectedRecords.splice(index, 1);
      setSelectedDisbursementRecords(selectedRecords);
      setIsAllSelect(false);
    }
    if (event?.target?.checked) {
      setSelectedDisbursementRecords([...selectedDisbursementRecords, data]);
      if (product.isLoc) {
        if (data.drawdown_amount) {
          sanctionSum += parseFloat(data.drawdown_amount);
        }
        if (data.net_drawdown_amount) netDisbSum += parseFloat(data.net_drawdown_amount);
      } else {
        if (data.sanction_amount) {
          sanctionSum += parseFloat(data.sanction_amount);
        }
        if (data.net_disbur_amt) netDisbSum += parseFloat(data.net_disbur_amt);
      }
      count += 1;
    }
    setTotalSelectCount(count);
    setTotalSanctionAmount(sanctionSum);
    setTotalNetDisbAmount(netDisbSum);
  };

  React.useEffect(() => {
    if (disbursementRecords?.length && selectedDisbursementRecords?.length === disbursementRecords?.length) {
      setIsAllSelect(true);
    }
  }, [selectedDisbursementRecords]);

  const handleSelectAllRecord = (data) => {
    let sanctionSum = 0;
    let netDisbSum = 0;
    if (data?.target?.checked) {
      setIsAllSelect(!isAllSelect);
      setDisbursementRecords(
        disbursementRecords.map((item) => {
          if (product.isLoc) {
            if (item.drawdown_amount) {
              sanctionSum += parseFloat(item.drawdown_amount);
            }
            if (item.net_drawdown_amount) {
              netDisbSum += parseFloat(item.net_drawdown_amount);
            }
          } else {
            if (item.sanction_amount) {
              sanctionSum += parseFloat(item.sanction_amount);
            }
            if (item.net_disbur_amt) {
              netDisbSum += parseFloat(item.net_disbur_amt);
            }
          }
          return {
            ...item,
            checked: !isAllSelect,
          };
        }),
      );
      setSelectedDisbursementRecords(disbursementRecords);
      setTotalSelectCount(disbursementRecords.length);
      setTotalSanctionAmount(sanctionSum);
      setTotalNetDisbAmount(netDisbSum);
    }
    if (!data?.target?.checked) {
      setIsAllSelect(!isAllSelect);
      setTotalSelectCount(0);
      setTotalSanctionAmount(0);
      setTotalNetDisbAmount(0);
      setDisbursementRecords(
        disbursementRecords.map((item) => {
          return {
            ...item,
            checked: !isAllSelect,
          };
        }),
      );
      setSelectedDisbursementRecords([]);
    }
  };

  const clearSelectionSummary = () => {
    setTotalSelectCount(0);
    setTotalSanctionAmount(0);
    setTotalNetDisbAmount(0);
    setAvailableBalance(0);
    setSelectedDisbursementRecords([]);
  };

  const handleChangeCompanyProduct = () => {
    setIsAllSelect(false);
    setDisbursementRecords(
      disbursementRecords.map((item) => {
        return {
          ...item,
          checked: false,
        };
      }),
    );
    setSelectedDisbursementRecords([]);
    setDisbursementRecords([]);
    clearSelectionSummary();
  };

  const handleClosePopUp = () => {
    setIsOpenPopUp(!isOpenPopUp);
    setSelectedDisbursementRecords([]);
    setErrorRowsText('');
    setSuccessRows([]);
    setFailRows([]);
  };

  const handleOpenInNewPage = (url, page) => {
    window.open(`${url}`, `${page || '_blank'}`);
  };

  const handleDdrDetails = (selectedRow) => {
    if ((!borroData?.line_pf ? (product?.line_pf === 'drawdown' ? true : borroData?.line_pf === 'drawdown') : borroData?.line_pf === 'drawdown') && selectedRow._id === oldestEntry._id) {
      handleOpenInNewPage(`/admin/lending/loan/loc_drawdown_request/${selectedRow.company_id}/${selectedRow.product_id}/${selectedRow.loan_id}/${selectedRow._id}/${selectedRow.loan_app_id}/${selectedRow.status}/${'true'}`);
    } else {
      handleOpenInNewPage(`/admin/lending/loan/loc_drawdown_request/${selectedRow.company_id}/${selectedRow.product_id}/${selectedRow.loan_id}/${selectedRow._id}/${selectedRow.loan_app_id}/${selectedRow.status}/${'false'}`);
    }
  };

  const handleConfirmed = () => {
    handleStartDisbursement();
    setCcReceivedPop(false);
  };

  const exceedConfirmHandle = () => {
    setCheckDisbursementPopUp(false);
    ccPopupConfirmation();
  };

  const exceedHandlPopupClose = () => {
    setCheckDisbursementPopUp(false);
  };

  const handlPopupClose = () => {
    setCcReceivedPop(false);
  };

  const customButton1 = {
    borderRadius: '8px',
    border: '1px solid #475BD8',
    width: '240px',
    height: '56px',
    fontSize: '16px',
    marginLeft: '5%',
    backgroundColor: 'white',
    width: '200px',
  };

  const customButton2 = {
    borderRadius: '8px',
    width: '240px',
    height: '56px',
    fontSize: '16px',
    width: '200px',
  };

  const columnsFirst = [
    {
      id: 'status',
      format: (rowData) => <input disabled={isTagged ? !checkAccessTags(['tag_disbursement_read_write']) : false} style={{ height: '20px', width: '20px', marginLeft: '-20px' }} type="checkbox" id={rowData._id} key={rowData._id} checked={rowData.checked} onChange={(e) => handleSelectSingleRecord(rowData, e)} />,
      label: (
        <div>
          <div style={{ marginRight: '10px' }}>
            <input disabled={isTagged ? !checkAccessTags(['tag_disbursement_read_write']) : false} type="checkbox" style={{ height: '20px', width: '20px', marginTop: '3px', marginLeft: '-20px' }} checked={isAllSelect} onChange={handleSelectAllRecord} />
          </div>
        </div>
      ),
    },
    { id: 'loan_id', label: 'Loan Id' },
    ...(product?.isLoc
      ? [
          {
            id: 'Cust Name',
            format: (rowData) => (
              <>
                <Link
                  onClick={(event) => {
                    handleDdrDetails(rowData);
                  }}
                >
                  {rowData?._id}
                </Link>
                {rowData?.processing_fees_including_gst ? (
                  <Tooltip title={`PF = ${rowData?.processing_fees_including_gst}`}>
                    <IconButton>
                      <InfoIcon style={{ color: 'blue' }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            ),
            label: 'Drawdown Request Id',
          },
        ]
      : []),
    { id: 'Customer Name', format: (rowData) => (rowData?.first_name ? rowData?.first_name : 'NA') + ' ' + (rowData?.last_name ? rowData?.last_name : ''), label: 'Customer Name' },
    { id: 'DD Amt', format: (rowData) => (product?.isLoc ? rowData?.drawdown_amount : rowData?.sanction_amount), label: product?.isLoc ? 'Drawdown Amt' : 'Loan Amount' },
    { id: 'Net DD Amount', format: (rowData) => (product?.isLoc ? rowData?.net_drawdown_amount : rowData?.net_disbur_amt), label: product?.isLoc ? 'Net Drawdown Amt' : ' Net disbursement amount' },
    { id: 'Date', format: (rowData) => (rowData.created_at ? moment(rowData.created_at).format('YYYY-MM-DD') : ''), label: product?.isLoc ? 'Request Date' : 'Loan sanction date' },
    { id: 'Channel', format: (rowData) => rowData?.channel, label: 'Channel' },
    { id: 'Status', format: (rowData) => statusToDisplay[rowData.status], label: 'Status' },
    {
      id: 'net_disbur_amt',
      format: (rowData) =>
        product?.isLoc ? (
          <Tooltip title="Edit">
            <EditIcon
              style={{
                color: '#5e72e4',
                cursor: 'pointer',
              }}
              disabled={isTagged ? checkAccessTags(['tag_drawdown_request_edit_read_write']) : false}
              onClick={() => {
                getProductDetails(rowData?.product_id);
                getLoanDetails(rowData?.loan_id);
                setIsOpen(true);
                setData(rowData);
              }}
            />
          </Tooltip>
        ) : (
          'NA'
        ),
      label: 'Action',
    },
  ];

  const columnsSecond = [
    {
      id: 'Select',
      format: (rowData) => (
        <>
          <input style={{ height: '20px', width: '20px', marginTop: '3px', marginLeft: '-20px' }} type="checkbox" id={rowData._id} key={rowData._id} checked={rowData.checked} onChange={(e) => handleSelectSingleRecord(rowData, e)} />
          <label htmlFor={rowData._id}></label>
        </>
      ),
      label: (
        <div>
          <div style={{ marginRight: '10px' }}>
            <input type="checkbox" style={{ height: '20px', width: '20px', marginLeft: '-20px' }} checked={isAllSelect} onChange={handleSelectAllRecord} />
            <label></label>
          </div>
        </div>
      ),
    },
    { id: 'loan_id', label: 'Loan Id' },
    ...(product?.isLoc ? [{ id: '_id', format: (rowData) => rowData?._id, label: 'Drawdown Request Id' }] : []),
    { id: 'Customer Name', format: (rowData) => (rowData?.first_name ? rowData?.first_name : 'NA') + ' ' + (rowData?.last_name ? rowData?.last_name : ''), label: 'Customer Name' },
    { id: 'DD Amt', format: (rowData) => (product?.isLoc ? rowData?.drawdown_amount : rowData?.sanction_amount), label: product?.isLoc ? 'Drawdown Amt' : 'Loan Amount' },
    { id: 'Net DD Amount', format: (rowData) => (product?.isLoc ? rowData?.net_drawdown_amount : rowData?.net_disbur_amt), label: product?.isLoc ? 'Net Drawdown Amt' : ' Net disbursement amount' },
    { id: 'Date', format: (rowData) => (rowData.created_at ? moment(rowData.created_at).format('YYYY-MM-DD') : ''), label: product?.isLoc ? 'Request Date' : 'Loan sanction date' },
    { id: 'Status', format: (rowData) => statusToDisplay[rowData.status], label: 'Status' },
    {
      id: 'Action',
      format: (rowData) =>
        product?.isLoc ? (
          <Tooltip title="Edit">
            <EditIcon
              style={{
                color: '#5e72e4',
                cursor: 'pointer',
              }}
              onClick={() => {
                getProductDetails(rowData?.product_id);
                getLoanDetails(rowData?.loan_id);
                setIsOpen(true);
                setData(rowData);
              }}
            />
          </Tooltip>
        ) : (
          'NA'
        ),
      label: 'Action',
    },
  ];

  return (
    <>
      {alert ? (
        <Stack
          lg={{
            width: '80%',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Alert severity={severity} onClose={handleAlertClose}>
            {alertMessage}
          </Alert>
        </Stack>
      ) : null}
      <DisbursementRequestPopUp isOpen={isOpenPopUp} successRows={successRows} failRows={failRows} totalRequest={selectedDisbursementRecords} errorRowsText={errorRowsText} isProgressStop={isProgressStop} setIsOpen={() => handleClosePopUp()} successRowsText={successRowsText} isProgressStart={isProgressStart} title="Disbursement progress" />
      {isDDRPFOpen && <DDRPFProcessPopUp setIsOpen={() => setIsDDRPFOpen(false)} title="Request" message={drawDownErrorMessage} />}

      <Typography
        sx={{
          mt: 2,
          ml: 2,
        }}
        variant="h6"
      >
        Disbursement request
      </Typography>
      <CardContent>
        <Grid
          xs={12}
          sx={{
            margin: '10px 0',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={2.5}>
              <CompanyDropdown
                placeholder="Select company"
                isDisbursed={true}
                company={company}
                onCompanyChange={(value) => {
                  setCompany(value ? value : '');
                  setProduct([]);
                  handleChangeCompanyProduct();
                }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <ProductDropdown
                placeholder="Select product"
                onProductChange={(value) => {
                  setProduct(value ? value : '');
                  handleChangeCompanyProduct();
                }}
                isDisbursed={true}
                company={company || null}
                product={product || null}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                id="combo-box-demo"
                value={typetodisplay}
                options={[typeToDisplay.Colend_loans, typeToDisplay.Non_Colend_loans]}
                onChange={(event, value) => {
                  if (value == null) {
                    setTypeToDisplay('');
                    setType('');
                  }
                  if (value == 'Yes') {
                    setType('Y');
                    setTypeToDisplay(typeToDisplay.Colend_loans);
                  }
                  if (value == 'No') {
                    setType('N');
                    setTypeToDisplay(typeToDisplay.Non_Colend_loans);
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Co-Lend Loan" />}
              />
            </Grid>
            <Grid item xs={2} sx={{ mt: 1, ml: 2 }}>
              <Button variant="contained" onClick={onSearchClick}>
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button variant="contained" size="large" onClick={() => ddExceedPopupConfirmation()} disabled={!selectedDisbursementRecords.length}>
              Disburse
            </Button>
          </Grid>
          <Grid sx={{ margin: '10px 0px' }}>
            <Grid container>
              {totalSelection > 0 ? (
                <Grid item xs={4}>
                  <div style={{ width: '400px', color: 'black', border: '1px solid rgb(229, 229, 232)', borderRadius: '10px' }}>
                    <div style={{ textAlign: 'center', alignItems: 'center', backgroundColor: 'rgb(94, 114, 228)', color: 'rgb(0, 0, 0)', height: '35px', display: 'flex', justifyContent: 'space-evenly', borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>Selection summary</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', backgroundColor: '#E5E5E8', borderBottom: '1px solid rgb(229, 229, 232)', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                      <span>Available Balance:</span>
                      <span>{Number(walletConfigCheck) ? Number(availableBalance).toFixed(2) : 'NA'}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', borderBottom: '1px solid rgb(229, 229, 232)', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                      <span>Selected rows:</span>
                      <span>{totalSelection}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', backgroundColor: '#E5E5E8', borderBottom: '1px solid rgb(229, 229, 232)', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                      <span>Total loan amount:</span>
                      <span>{totalSanctionAmount.toFixed(2)}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                      <span>Total transaction amount:</span>
                      <span>{totalNetDisbAmount.toFixed(2)}</span>
                    </div>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid sx={{ mt: 1 }}>
            <Divider />
          </Grid>
          {disbursementRecords.length ? (
            <Grid xs={12}>
              {isTagged ? (
                checkAccessTags(['tag_disbursement_read', 'tag_disbursement_read_write']) ? (
                  <div className="table-alignment-css">
                    <Table customStyle={{ width: '100%', display: 'grid', gridTemplateColumns: product?.isLoc ? '4% 17% 11% 11% 11% 10% 10% 9% 9% 8%' : '4% 20% 12% 12% 12% 10% 10% 10% 10%', overflow: 'hidden' }} data={disbursementRecords} columns={columnsFirst} />
                    {count ? <Pagination itemsPerPage={rowsPerPage} totalItems={count} rowsPerPageOptions={rowsPerPageOptions} onPageChange={handleChangePage} showOptions={true} setRowLimit={(e) => handleChangeRowsPerPage(e)} /> : null}
                  </div>
                ) : null
              ) : (
                <div className="table-alignment-css">
                  <Table customStyle={{ width: '100%', display: 'grid', gridTemplateColumns: product?.isLoc ? '4% 20% 12% 15% 12% 10% 10% 9% 6%' : '6% 22% 15% 15% 12% 10% 12% 8%', overflow: 'hidden' }} data={disbursementRecords} columns={columnsSecond} />
                  {count ? <Pagination itemsPerPage={rowsPerPage} totalItems={count} rowsPerPageOptions={rowsPerPageOptions} onPageChange={handleChangePage} showOptions={true} setRowLimit={(e) => handleChangeRowsPerPage(e)} /> : null}
                </div>
              )}
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      {isOpen && <DrawDownRequestUi setIsOpen={setIsOpen} isEdit={true} data={data} onSearchClick={onSearchClick} productDetails={productDetails} bData={borroData} />}

      {ccReceivedPop ? (
        <FormPopup custom Styles={{ width: '530px', top: '55%' }} heading="Cash collateral confirmation" onClose={handlPopupClose} customHeaderStyle={{ display: 'flex', justifyContent: 'center' }} isCancel={true} isOpen={ccReceivedPop}>
          <div className="confirmation-popup-text-style">
            <h4 style={{ color: '#BBBBBB', letterSpacing: '1px', textAlign: 'center' }}>These are cash collateral cases. Please check if CC is received for the case/s.Only disburse those case/s for which cc is received.</h4>
          </div>
          <div style={{ display: 'flex', paddingTop: '40px', justifyContent: 'space-between' }}>
            <Button variant="outlined" label="No" buttonType="secondary" onClick={handlPopupClose} style={customButton1}>
              Cancel
            </Button>
            <Button variant="contained" label="Yes" buttonType="primary" onClick={handleConfirmed} style={customButton2}>
              Confirm
            </Button>
          </div>
        </FormPopup>
      ) : null}

      {/* disbursement check POPUP */}

      {checkDisbursementPopUp ? (
        <FormPopup custom Styles={{ width: '530px', top: '55%' }} heading="Full PF not received" onClose={exceedHandlPopupClose} customHeaderStyle={{ display: 'flex', justifyContent: 'center' }} isCancel={true} isOpen={checkDisbursementPopUp}>
          <div className="confirmation-popup-text-style">
            <h4 style={{ color: '#BBBBBB', letterSpacing: '1px', textAlign: 'center' }}>
              For below lines pf is not received. Are you sure you want to do disbursement.
              <br />
              <ol style={{ listStyleType: 'circle', padding: 0 }}>
                {selectedDisbursementRecords.map((data, index) => (
                  <li key={index} style={{ display: 'inline' }}>
                    {index + 1}. {data.loan_id}{' '}
                  </li>
                ))}
              </ol>
            </h4>
          </div>
          <div style={{ display: 'flex', paddingTop: '40px', justifyContent: 'space-between' }}>
            <Button variant="outlined" label="No" buttonType="secondary" onClick={exceedHandlPopupClose} style={customButton1}>
              Cancel
            </Button>
            <Button variant="contained" label="Yes" buttonType="primary" onClick={exceedConfirmHandle} style={customButton2}>
              Confirm
            </Button>
          </div>
        </FormPopup>
      ) : null}
    </>
  );
};

export default DisbursementRequest;
