import EditBookLoans from './views/bookLoans/editBookLoans/editBookLoans';
import { BookLoans, Leads, Loans, LeadReview } from './views/lap.views';

var routes = [
  {
    path: '/LAP/Book Loan',
    name: 'Book Loan',
    miniName: 'BL',
    component: BookLoans,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: false,
    tags: ['tag_LAP_bookloan_read_write'],
  },
  {
    path: '/lap/leads',
    name: 'Leads',
    miniName: 'LE',
    component: Leads,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: false,
    tags: ['tag_LAP_lead_read', 'tag_LAP_lead_read_write'],
  },
  {
    path: '/lap/leads-review/:id',
    name: 'Lead Review',
    miniName: 'LE',
    component: LeadReview,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_LAP_lead_read', 'tag_LAP_lead_read_write'],
  },
  {
    path: '/LAP/Loans',
    name: 'Loans',
    miniName: 'LO',
    component: Loans,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: false,
    tags: ['tag_LAP_loan_read', 'tag_LAP_loan_read_write'],
  },
  {
    path: '/lap/lead/:id/edit',
    name: 'Book Loans',
    miniName: 'BL',
    component: EditBookLoans,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_LAP_lead_read', 'tag_LAP_lead_read_write'],
  },
  {
    path: '/lap/lead/:id/view',
    name: 'Book Loans',
    miniName: 'BL',
    component: EditBookLoans,
    layout: '/admin',
    accessBy: ['admin', 'company'],
    invisible: true,
    tags: ['tag_LAP_lead_read', 'tag_LAP_lead_read_write'],
  },
];

export default routes;
