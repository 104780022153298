import { takeLatest, call, put } from 'redux-saga/effects';
import { updatePreLoaderWatcher } from '../actions/user';
import { markAsDeviationApi } from '../apis/markAsDeviation';

export function* markAsDeviationEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(markAsDeviationApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* markAsDeviationWatcherSaga() {
  yield takeLatest('MARK_AS_DEVIATION', markAsDeviationEffectSaga);
}
