const EN_US_DATEFORMAT_OPTIONS = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};
const EN_US_LOCALES = 'en-US';

module.exports = {
  EN_US_DATEFORMAT_OPTIONS,
  EN_US_LOCALES,
};
