import * as React from 'react';
import { useState } from 'react';
import Button from 'react-sdk/dist/components/Button';
import TabButton from 'react-sdk/dist/components/TabButton';
import './uploadGrid.css';
import LoanUploadSampleExcel from '../dataUpload/assets/file/Loan upload.xlsx';
import LoanUpdateSampleExcel from '../dataUpload/assets/file/Loan update.xlsx';
import uploadSvg from './images/document-upload.svg';
import Img from './images/download-button.svg';
import imgH from './images/download-button-hover.svg';
import { dataUploadExcel } from './action/DataUpload';
import { useDispatch } from 'react-redux';
import { storedList } from '../../../util/localstorage';
import Alert from 'react-sdk/dist/components/Alert/Alert';

export default function DataUpload() {
  const dispatch = useDispatch();
  const [caseType, setCaseType] = useState('loans');
  const [selectedOption, setSelectedOption] = useState('Upload');
  const uploadfileInputRef = React.useRef(null);
  const updatefileInputRef = React.useRef(null);
  const [enableUpload, setEnableUpload] = useState(false);
  const [enableUpdate, setEnableUpdate] = useState(true);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value == 'Upload') {
      setEnableUpload(false);
      setEnableUpdate(true);
    }

    if (event.target.value == 'Update') {
      setEnableUpload(true);
      setEnableUpdate(false);
    }
  };

  const onSubmit = async (e, type) => {
    const file = e.target.files[0];
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const user = storedList('user');
      const data = new FormData();
      data.append('type', type);
      data.append('user', user.username);
      data.append('file', file);
      new Promise((resolve, reject) => {
        dispatch(dataUploadExcel(data, resolve, reject));
      })
        .then((response) => {
          if (response?.status === 200) {
            setAlert(true);
            setSeverity('success');
            setAlertMessage('File was uploaded and added to queue.');
          }
        })
        .catch((error) => {
          setAlert(true);
          setSeverity('error');
          setAlertMessage('File was not uploaded successfully');
        });
    } else {
      setAlert(true);
      setSeverity('error');
      setAlertMessage('Only accepts .xlsx format');
    }
  };

  const selectedStyleUpload = {
    border: selectedOption === 'Upload' ? '1px solid blue' : '',
  };

  const selectedStyleUpdate = {
    border: selectedOption === 'Update' ? '1px solid blue' : '',
  };

  const downloadFile = (hyperLink, name) => {
    const link = document.createElement('a');
    link.href = hyperLink;
    link.download = name;
    link.click();
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between',
          margin: '0 20px',
        }}
      >
        <div>
          {['Loans', 'Payment'].map((template, index) => {
            return <TabButton label={template} isDisabled={true} key={index} selected={template.toLowerCase() === caseType.toLowerCase() ? true : false} setSelectedOption={setCaseType} />;
          })}
        </div>
      </div>
      <div className="upload-grid-card" style={selectedStyleUpload}>
        <input type="radio" name="option" value="Upload" defaultChecked={selectedOption === 'Upload'} checked={selectedOption === 'Upload'} onChange={handleOptionChange} />
        <label className="label-margin">Upload</label>

        <p className="text-margin">Use this setting to upload a new loan data.</p>
        <div className="bulk-upload-button-containers text-margin">
          <Button
            buttonType="secondary"
            label="Download Sample Excel"
            isDisabled={enableUpload}
            customStyle={{
              width: '300px',
              height: '40px',
              border: '1px solid #475BD8',
              color: '#475BD8',
              borderRadius: '26px',
              fontSize: '12px',
              display: 'flex',
              boxShadow: 'none',
              backgroundColor: 'white',
              marginRight: '20px',
            }}
            onClick={() => {
              downloadFile(LoanUploadSampleExcel, 'Loan upload.xlsx');
            }}
            imageButton={Img}
            imageButtonHover={imgH}
            iconButton="btn-secondary-download-button"
          />

          <Button
            buttonType="secondary"
            label="Upload Excel"
            isDisabled={enableUpload}
            customStyle={{
              width: '300px',
              height: '40px',
              borderRadius: '26px',
              fontSize: '12px',
              display: 'flex',
              marginRight: '20px',
              boxShadow: 'none',
              backgroundColor: '#475BD8',
              color: '#FFFFFF',
              border: '1px solid #475BD8',
            }}
            id="Upload Excel"
            onClick={() => uploadfileInputRef.current.click()}
            imageButtonHover={uploadSvg}
            imageButton={uploadSvg}
            iconButton="btn-secondary-download-button"
          />

          <input
            type="file"
            ref={uploadfileInputRef}
            id="upload_excel_hidden"
            accept=".xlsx"
            style={{ display: 'none' }}
            onChange={(e) => {
              onSubmit(e, 'New');
              uploadfileInputRef.current.value = '';
            }}
          ></input>

          {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} style={{ position: 'fixed', marginTop: '1vh' }} /> : null}
        </div>
      </div>

      <div className="upload-grid-card" style={selectedStyleUpdate}>
        <input type="radio" name="option" value="Update" checked={selectedOption === 'Update'} onChange={handleOptionChange} />

        <label className="label-margin">Update</label>
        <p className="text-margin">Use this setting to update existing loan data.</p>
        <div className="bulk-upload-button-containers text-margin">
          <Button
            buttonType="secondary"
            label="Download Sample Excel"
            isDisabled={enableUpdate}
            customStyle={{
              width: '300px',
              height: '40px',
              border: '1px solid #475BD8',
              color: '#475BD8',
              borderRadius: '26px',
              fontSize: '12px',
              display: 'flex',
              boxShadow: 'none',
              backgroundColor: 'white',
              marginRight: '20px',
            }}
            onClick={() => {
              downloadFile(LoanUpdateSampleExcel, 'Loan update.xlsx');
            }}
            imageButton={Img}
            imageButtonHover={imgH}
            iconButton="btn-secondary-download-button"
          />

          <Button
            buttonType="secondary"
            label="Upload Excel"
            isDisabled={enableUpdate}
            customStyle={{
              width: '300px',
              height: '40px',
              borderRadius: '26px',
              fontSize: '12px',
              display: 'flex',
              marginRight: '20px',
              boxShadow: 'none',
              backgroundColor: '#475BD8',
              color: '#FFFFFF',
              border: '1px solid #475BD8',
            }}
            id="Update Excel"
            onClick={() => updatefileInputRef.current.click()}
            imageButtonHover={uploadSvg}
            imageButton={uploadSvg}
            iconButton="btn-secondary-download-button"
          />
          <input
            type="file"
            ref={updatefileInputRef}
            id="update_excel_hidden"
            accept=".xlsx"
            style={{ display: 'none' }}
            onChange={(e) => {
              onSubmit(e, 'Update');
              updatefileInputRef.current.value = '';
            }}
          ></input>
        </div>
      </div>
    </>
  );
}
