import React, { useState, useEffect } from 'react'
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import UTILS from '../../utils'
import { validateData } from '../../utils/validation'
import UploadFileInput from '../../components/uploadFileInput/UploadFileInput';
import Button from 'react-sdk/dist/components/Button';
import { getBookLoanDetailsWatcher, getLapLoanDocumentsWatcher } from '../../actions/bookLoan.action';
import { storedList } from '../../../util/localstorage';
import { useDispatch } from 'react-redux';
import { Property_Ownership_Proof, Property_Ownership_Report } from './uploadKycData';
import _keys from 'lodash/keys'
import { patchLapDetailsWatcher } from '../../actions/lap.action';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import { LeadNewStatus } from '../../config/LeadStatus';
import { includesSubstring } from '../../utils/stringValidation';
import { Number_Constants } from '../../constants/number_constants';
import { File_Label, Application_Mode, Lap_Screens } from '../../constants/string_constants';
import SlidingAlert from '../../components/SlidingAlert/SlidingAlert'
import { SectionData } from '../../../lap/config/sectionData';
import getSectionStatus from './GetLeadSectionStatus/GetLeadSectionStatus';
const sectionStatus = ['deviation', 'approved', 'rejected', 'failed'];

const inputBoxCss = {
    marginTop: '8px',
    maxHeight: '500px',
    zIndex: 1,
    width: '105%',
};

const headingCss = {
    color: 'var(--neutrals-neutral-100, #161719)',
    fontFamily: 'Montserrat-semibold',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '150%',
    marginBottom: '24px',
    marginTop: '40px',
};
const sectionName = 'property-details';
const max6Digit2DeciamlValRegex = /^\d{0,6}(?:\.\d{0,2})?$/;
const propertyValueMaxDigitsAllowed = Number_Constants.NINE;

export default function PropertyDetailsForm(props) {
    //#region [State Variables]
    const dispatch = useDispatch();
    const {
        applicantData,
        navIconPrefixState,
        loanAppId,
        LAPCompanyId,
        LAPProductId,
        type,
        setNavIconPrefixState,
        setNavState,
        leadStatus,
        setShouldFetch
    } = props
    const [propertyOwnershipProof, setPropertyOwnershipProof] = useState(Property_Ownership_Proof);
    const [propertyOwnershipReport, setPropertyOwnershipReport] = useState(Property_Ownership_Report);
    const [stateData, setStateData] = useState({ ...applicantData } ?? {});
    const [validationData, setValidationData] = useState({});
    //const [enabledOwnerNameFields, setEnabledOwnerNameFields] = useState(['owner_full_name_1']);
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [documentStateData, setDocumentStateData] = useState({
        Property_Ownership_Proof: false,
        Property_FI_Report: false,
    });
    let intervalId;
    const [savedValue, setSavedValue] = useState(stateData);
    const [arrOwnerInfo, setArrOwnerInfo] = useState([]);
    const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
    const [arrSelectedOwnerInfo, setArrSelectedOwnerInfo] = useState([]);
    const prevArrayRef = React.useRef();
    const [propertyDetailsFields, setpropertyDetailsFields] = useState([]);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [count, setCount] = useState(4);
    const [isChangeOccurred, setIsChangeOccurred] = useState(false);
    const [isDDError, setIsDDError] = useState('');
    const [isDDErrorRenderNeeded, setIsDDErrorRenderNeeded] = useState(1);
    const [isAddMoreOwnerDisabled, setIsAddMoreOwnerDisabled] = useState(true);
    const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
    const [statusObject, setStatusObject] = useState('');
    const [sectionStatusCheck, setSectionStatusCheck] = useState('');
    //#endregion

    //#region [UseEffect Methods]
    //#region [Initial Page Rendering]
    useEffect(async () => {
        let mandatoryFields = [...UTILS.fetchObjectsByDept('property_details'), ...UTILS.fetchObjectsByDept('property_ownership_documents'), ...UTILS.fetchObjectsByDept('property_owner_details')]
        setpropertyDetailsFields(mandatoryFields);
        getLoanData();
        getLoanDocuments();
    }, []);
    //#endregion

    //#region [To set the Selected Owner Information in the ref hook and disable the owner button]
    useEffect(() => {
        prevArrayRef.current = arrSelectedOwnerInfo;
        setIsAddMoreOwnerDisabled(arrSelectedOwnerInfo.length === count && arrSelectedOwnerInfo.length < 15 && arrSelectedOwnerInfo.length < arrOwnerInfo.length ? false : true);
    }, [arrSelectedOwnerInfo]);
    //#endregion

    //#region   [To handle the enable and disable functionalities of save and verify button]
    useEffect(() => {
        const boolIsSaveButtonDisabled = stateData === savedValue || (stateData !== savedValue && !(Object.values(stateData).some(value => value !== '')))
        setIsSaveButtonDisabled(boolIsSaveButtonDisabled);
        handleSubmitButtonVisibility();
    }, [stateData]);

    useEffect(() => {
        const ele = document.getElementById(isDDError);
        if (ele) {
            ele.classList.add('focused');
        }
    }, [isDDErrorRenderNeeded])


    //#endregion

    //#region [To fix the dropdown css error]
    /*
    When we make the error as visible in the dropdown field the selected text was override the bottom of the box.  
    The 'Focused' css was added in the normal textboxes when error occurred but in dropdown that element wasn't added.
    To fix this issue we are using the below code. 
    */
    useEffect(() => {
        const ele = document.getElementById(isDDError);
        if (ele) {
            ele.classList.add('focused');
        }
    }, [isDDErrorRenderNeeded])
    //#endregion

    //#region [To handle the file uplad error]
    useEffect(() => {
        const documentErrorObject = {
            [`string_vl_prop_property_ownership_proofState`]: '',
            [`string_vl_prop_property_fi_reportState`]: ''
        }
        setValidationData((prevState) => ({
            ...prevState,
            ...documentErrorObject,
        }));
        handleSubmitButtonVisibility();
    }, [documentStateData])
    //#endregion
    //#endregion

    //#region [To get lead status]
    useEffect(() => {
        if (loanAppId && LAPCompanyId && LAPProductId) {
            fetchLeadStatus();
        }
    }, [loanAppId, LAPCompanyId, LAPProductId]);
    //#endregion

    //#region [UI rendering methods]
    //#region [To render the input fileds for 'Property details' section]
    const renderFields = (dept, deptArray = null) => {
        const divId = dept === 'property_owner_details' ? 'property-owner-details-id' : "property_details-id";
        if (deptArray === null) {
            deptArray = UTILS.fetchObjectsByDept(dept);
        }
        return (
            <div id={divId}
                style={{
                    display: 'grid',
                    rowGap: '28px',
                    gridTemplateColumns: '32.8% 32.8% 32.8%',
                    columnGap: '1%',
                    width: '98%',
                }}
            >
                {deptArray &&
                    deptArray.map((row, index) => {
                        return <>
                            {
                                dept === 'property_details' ? <InputBox
                                    id={row.field}
                                    label={row.title}
                                    type={row.type}
                                    isDrawdown={row.isDrawdown}
                                    options={(row.isDrawdown && row.dept === 'property_details') ? row.options : []}
                                    initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                                    isBoxType={row?.box_type}
                                    Buttonlabel={navIconPrefixState['Property Details'] ? '' : documentStateData[row.field] === '' ? File_Label.Upload : File_Label.Change}
                                    onClick={(event) => change(event, row.type, row.field, row.dept)}
                                    isDisabled={type === Application_Mode.View || navIconPrefixState['Property Details'] ? true : false}
                                    customDropdownClass={inputBoxCss}
                                    customClass={{
                                        height: '56px',
                                        width: '100%',
                                        maxWidth: '100%',
                                    }}
                                    customInputClass={{
                                        marginTop: '0px',
                                        minWidth: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                    error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'}
                                    helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')}
                                /> : <div className='ar-propertydetails-fileupload'><UploadFileInput
                                    items={row.field == 'prop_property_ownership_proof' ? propertyOwnershipProof : propertyOwnershipReport}
                                    backgroundColorBlur={type === Application_Mode.View || navIconPrefixState['Property Details'] ? true : false}
                                    backgroundColorChange={type === Application_Mode.View || navIconPrefixState['Property Details'] ? true : false}
                                    isSubmit={false}
                                    isXML={false}
                                    loanAppId={loanAppId}
                                    setDocumentStateData={setDocumentStateData}
                                    sectionName={sectionName}
                                    data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                                    showAlert={showAlert}
                                    borrowerId={stateData?.borrower_id}
                                    LAPCompanyId={LAPCompanyId}
                                    LAPProductId={LAPProductId}
                                    isChange={(type === Application_Mode.View || navIconPrefixState['Property Details'] == 'success') && !sectionStatus.includes(statusObject?.section_status) ? false : true}
                                    type={type}
                                    errorValidation={validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                                    onFileUpload={(e) => handleFileSelect(e)}
                                    // initialValue = {stateData[`${row.type}_vl_${row.field}State`] ?? ''}
                                    initialValue={`${row.type}_vl_${row.field}State`}
                                />
                                </div>
                            }
                        </>
                    })
                }

            </div>

        )
    }
    //#endregion

    //#region [To set the existing values in the state]
    const setExistingValues = async (property_details) => {
        try {
            let stateValues = {};
            const propertyDetailsSectionFields = [...UTILS.fetchObjectsByDept('property_details'), ...UTILS.fetchObjectsByDept('property_ownership_documents')];
            propertyDetailsSectionFields.forEach((fieldInfo) => {
                let field = `${fieldInfo.type}_vl_${fieldInfo.field}`;
                stateValues = { ...stateValues, [field]: property_details[fieldInfo.field] };
            });
            if (property_details?.prop_property_owner_details) {
                let arrSelectedOwners = [];
                property_details?.prop_property_owner_details.forEach((ownerInfo) => {
                    let field = `string_vl_prop_owner_full_name_${ownerInfo.prop_owner_no}`;
                    stateValues = { ...stateValues, [field]: ownerInfo?.prop_owner_name };

                    const selectedOwnerInfo = {
                        prop_owner_name: ownerInfo?.prop_owner_name,
                        prop_owner_pan: ownerInfo?.prop_owner_pan,
                        prop_owner_type: ownerInfo?.prop_owner_type,
                        prop_owner_no: ownerInfo?.prop_owner_no
                    }
                    arrSelectedOwners.push(selectedOwnerInfo);
                });
                setArrSelectedOwnerInfo(arrSelectedOwners)
            }
            setStateData(stateValues);
            setSavedValue(stateValues);
        } catch (error) {
            throw error;
        }
    }
    //#endregion

    const setStatusCheckApi = async (loanAppID, sectionCode, subSectionCode, dispatch) => {
        intervalId = setInterval(async () => {
            try {
                const status = await getSectionStatus(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch);
                if (status == 'approved') {
                    if (subSectionCode == 'property_documents_section_submit') {
                        navigateToNextSection();
                    }
                    clearInterval(intervalId);
                } else if (status == 'deviation') {
                    if (subSectionCode == 'property_documents_section_submit') {
                        navigateToNextSection();
                    }
                    clearInterval(intervalId);
                }
            } catch (error) {
                showAlert('Technical error, please try again.', 'error');
                clearInterval(intervalId);
            }
        }, 3000);
    };

    //#region [To return the dropdown UI elements for the Owner Details]
    const renderInputDD = (countOfOwners, arrOwnerInformation = null) => {
        const boxes = [];
        for (let i = 1; i <= countOfOwners; i++) {
            let row = {
                title: i == 1 ? `Owner Full Name ${i}` : `Owner Full Name ${i} (Optional)`,
                field: `prop_owner_full_name_${i}`,
                type: 'string',
                isDrawdown: true,
                isOptional: i == 1 ? false : true,
                dept: 'property_owner_details',
                validationMsg: i === 1 ? `Owner full name ${i} is required` : '',
                errorHelperTextRequired: 'The owner with the same name was already added',
                checked: 'true'
            }
            //boxes.push(<input key={i} type="text" placeholder={`Input Box ${i + 1}`} />);
            boxes.push(
                <div>
                    <InputBox
                        id={row.field}
                        label={row.title}
                        type={row.type}
                        isDrawdown={row.isDrawdown}
                        options={(row.isDrawdown && row.dept === 'property_details') ? row.options : row.isDrawdown && row.dept === 'property_owner_details' ? arrOwnerInformation != null ? arrOwnerInformation : arrOwnerInfo : []}
                        initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                        isBoxType={row?.box_type}
                        Buttonlabel={navIconPrefixState['Property Details'] ? '' : documentStateData[row.field] === '' ? File_Label.Upload : File_Label.Change}
                        onClick={(event) => change(event, row.type, row.field, row.dept)}
                        customDropdownClass={inputBoxCss}
                        customClass={{
                            height: '56px',
                            width: '100%',
                            maxWidth: '100%',
                        }}
                        customInputClass={{
                            //marginTop: '0px',
                            minWidth: '100%',
                            backgroundColor: '#fff',
                        }}
                        isDisabled={type === Application_Mode.View || navIconPrefixState['Property Details'] ? true : false}
                        error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'}
                        helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')}
                    /></div>
            )
        }
        return boxes;
    };
    //#endregion

    //#region [To set the options for the dropdown]
    const setOwnerDropDownValues = (loanInfo) => {
        try {
            const arrOwnerInformation = [];
            const applicantInfo = {
                label: `${loanInfo?.first_name || ''} ${loanInfo?.middle_name || ''} ${loanInfo?.last_name || ''}`.trim(),
                value: `${loanInfo?.first_name || ''} ${loanInfo?.middle_name || ''} ${loanInfo?.last_name || ''}`.trim(),
                prop_owner_type: "Applicant",
                prop_owner_pan: loanInfo?.appl_pan
            };
            arrOwnerInformation.push(applicantInfo);
            if (loanInfo?.entity_details && loanInfo?.entity_details?.entity_type !== 'Individual') {
                const objEntityInfo = {
                    label: loanInfo?.entity_details?.entity_name,
                    value: loanInfo?.entity_details?.entity_name,
                    prop_owner_type: "Entity",
                    prop_owner_pan: loanInfo?.entity_details?.pan_no
                }
                arrOwnerInformation.push(objEntityInfo);
            };
            if (loanInfo?.coborrower) {
                const arrCoborrowerInfo = loanInfo?.coborrower.map((coborrowerInfo) => ({
                    label: `${coborrowerInfo?.cb_fname || ''} ${coborrowerInfo?.cb_mname || ''} ${coborrowerInfo?.cb_lname || ''}`.trim(),
                    value: `${coborrowerInfo?.cb_fname || ''} ${coborrowerInfo?.cb_mname || ''} ${coborrowerInfo?.cb_lname || ''}`.trim(),
                    prop_owner_type: "Co-Borrower",
                    prop_owner_pan: coborrowerInfo?.cb_pan
                }));
                arrOwnerInformation.push(...arrCoborrowerInfo)
            }
            return arrOwnerInformation;
        } catch (error) {
            throw error;
        }
    }
    //#endregion

    //#region [Document Name handling]
    const handleFileSelect = (event) => {
        const id = event?.target?.id.toLowerCase();
        const field = `string_vl_${id}`;
        setStateData((prevState) => ({
            ...prevState,
            [field]: event?.target?.files[0]?.name
        }));
    };
    //#endregion

    const getLoanDocuments = () => {
        const payload = {
            loanAppID: loanAppId,
            companyId: LAPCompanyId,
            productId: LAPProductId,
            user: user,
        };
        new Promise((resolve, reject) => {
            dispatch(getLapLoanDocumentsWatcher(payload, resolve, reject));
        })
            .then((response) => {
                if (response) {
                    let stateDoc = {
                        Property_Ownership_Proof: false,
                        Property_FI_Report: false
                    };

                    let data = setDocumentView(Property_Ownership_Proof, response);
                    setPropertyOwnershipProof(data);
                    stateDoc['Property_Ownership_Proof'] = checkDocStatus(data);

                    data = setDocumentView(Property_Ownership_Report, response);
                    setPropertyOwnershipReport(data);
                    stateDoc['Property_FI_Report'] = checkDocStatus(data);
                    setDocumentStateData(stateDoc);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const checkDocStatus = (data) => {
        for (let ob of data) {
            if (!ob?.s3_url && ob.isRequired) {
                return false;
            }
        }
        return true;
    };

    const setDocumentView = (TitleOb, documents) => {
        return TitleOb.map((givenObj) => {
            const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
            if (matchingObj) {
                return {
                    ...givenObj,
                    s3_url: matchingObj.file_url,
                    doc: matchingObj,
                };
            } else {
                return givenObj;
            }
        });
    };
    //#endregion

    //#region [Button Clicks]
    //#region [save draft]
    const handleSaveDraft = async () => {
        handleDBUpdate(true);
    }
    //#endregion

    const handleSubmitButtonVisibility = () => {
        let isValidForm = true;
        let mandatoryFields = propertyDetailsFields;
        mandatoryFields = mandatoryFields.filter((ele) => { return ele.isOptional == false && ele.dept != 'property_ownership_documents' });
        let errorObject = {};
        mandatoryFields.forEach((item) => {
            if (!stateData[`${item.type}_vl_${item.field}`]) {
                errorObject = { ...errorObject, [`${item.type}_vl_${item.field}State`]: 'has-danger' }
                isValidForm = false;
            }
        })
        if (documentStateData.Property_Ownership_Proof == false) {
            errorObject = { ...errorObject, [`string_vl_prop_property_ownership_proofState`]: 'has-danger' }
            isValidForm = false;
        }
        setIsSubmitButtonEnabled(isValidForm);
    }

    //#region [Verify & Next]
    const handleSubmit = async () => {
        try {
            let isValidForm = true;
            let mandatoryFields = propertyDetailsFields;
            mandatoryFields = mandatoryFields.filter((ele) => { return ele.isOptional == false && ele.dept != 'property_ownership_documents' });
            let errorObject = {};
            mandatoryFields.forEach((item) => {
                if (!stateData[`${item.type}_vl_${item.field}`]) {
                    errorObject = { ...errorObject, [`${item.type}_vl_${item.field}State`]: 'has-danger' }
                    isValidForm = false;
                }
            })
            if (documentStateData.Property_Ownership_Proof == false) {
                errorObject = { ...errorObject, [`string_vl_prop_property_ownership_proofState`]: 'has-danger' }
                isValidForm = false;
            }
            setValidationData((prevState) => ({
                ...prevState,
                ...errorObject,
            }));
            if (isValidForm) {
                try {
                    setSectionStatusCheck('inProgress');
                    await handleDBUpdate()
                } catch (error) {
                    showAlert(error?.response?.data?.message ?? 'Something went wrong', 'error');
                }
            }
            else {
                showAlert('Kindly check for errors in fields', 'error');
            }
        } catch (error) {
            console.log(error);
        }
    }
    //#endregion

    //#region [Add More Owner]
    const handleAddOwner = () => {
        setCount(count + 1);
        renderInputDD(count + 1);
        setIsAddMoreOwnerDisabled(true);
    };
    //#endregion
    //#endregion

    //#region [DB Manipulations]
    //#region [To get the loan information from db]
    const getLoanData = async () => {
        if (loanAppId) {
            const payload = {
                loan_app_id: loanAppId,
                user: user,
            };
            new Promise((resolve, reject) => {
                dispatch(getBookLoanDetailsWatcher(payload, resolve, reject));
            })
                .then((response) => {
                    const arrOwnerInformation = setOwnerDropDownValues(response?.data?.[0]);
                    response?.data?.[0]?.property && setExistingValues(response?.data?.[0]?.property);  //if property exists invoke the method.
                    setArrOwnerInfo(arrOwnerInformation);
                    renderInputDD(count, arrOwnerInformation);
                })
                .catch((e) => {
                    showAlert('Something went Wrong', 'error');
                });
        }
    };
    //#endregion

    //#region [To save the property details in the db]
    const handleDBUpdate = async (isSaveDraft = false) => {
        if (!isFormFieldError()) {

            const payload = {
                loan_app_id: loanAppId,
                section: sectionName,
                lap_company_id: LAPCompanyId,
                lap_product_id: LAPProductId,
                user_id: user._id ? user._id : '',
                section_sequence_no: 800,
                prop_approx_property_value: stateData?.number_vl_prop_approx_property_value,
                prop_property_type: stateData?.string_vl_prop_property_type,
                prop_plot_size: stateData?.number_vl_prop_plot_size,
                prop_built_up_area: stateData?.number_vl_prop_built_up_area,
                prop_property_address: stateData?.string_vl_prop_property_address,
                prop_nature_of_property: stateData?.string_vl_prop_nature_of_property,
                prop_property_jurisdiction: stateData?.string_vl_prop_property_jurisdiction,
                prop_property_owner_details: arrSelectedOwnerInfo,
                prop_property_owner_proof: stateData?.string_vl_prop_property_ownership_proof,
                prop_property_fi_report: stateData?.string_vl_property_fi_report,
                isSave: isSaveDraft
            };
            new Promise((resolve, reject) => {
                dispatch(patchLapDetailsWatcher(payload, resolve, reject));
            })
                .then((response) => {
                    setIsSaveButtonDisabled(true);
                    setSavedValue(stateData);
                    if (isSaveDraft) {
                        showAlert('Draft saved successfully', 'success');
                    }
                    else {
                        setStatusCheckApi(loanAppId, SectionData.property_details.section_code, SectionData.property_details.property_section_submit.sub_section_code, dispatch);
                    }
                    return response;
                })
                .catch((error) => {
                    showAlert(error?.response?.data?.message, 'error');
                });
        } else {
            showAlert('Kindly check for errors in fields', 'error');
        }
    }
    //#endregion
    //#endregion

    //#region [On change handling]
    const change = (e, type, name, dept) => {

        setIsChangeOccurred(true);
        //#region [To set the value in a state]
        let value = type.toLowerCase() === "string" ? e.value.trimStart() : e.value;
        if (((name === 'prop_plot_size' || name === 'prop_built_up_area') && !max6Digit2DeciamlValRegex.test(value))) {    //Property value field restriction
            value = value.slice(0, -1);
        }
        else if (name == 'prop_approx_property_value' && value.length > propertyValueMaxDigitsAllowed) {
            value = value.substring(0, propertyValueMaxDigitsAllowed);
        }

        let field = `${type}_vl_${name}`;
        setStateData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
        //#endregion

        //#region [Field Validations]
        if (dept === 'property_details' || name === 'prop_owner_full_name_1') {
            let isValid = validateData(type.toLowerCase(), value) && value.length > Number_Constants.ZERO;
            setValidationData((prevState) => ({
                ...prevState,
                [`${field}State`]: !isValid ? 'has-danger' : ''
            }));
            if ((name === 'prop_approx_property_value') && value.length > Number_Constants.ZERO) {
                const isValid = !includesSubstring(value, '.');
                setValidationData((prevState) => ({
                    ...prevState,
                    [`${field}State`]: value && !isValid ? 'has-errorHelperTextRequired' : ''
                    //[`${field}State`]: !isValid ? 'has-danger' : '',
                }));
            }
        }
        //#endregion

        //#region [Property Owner Dropdown handling]
        if (dept === 'property_owner_details') {
            //#region [Validation]
            let arrSelectedOwners = prevArrayRef.current ?? []; //To get the arrSelectedOwners
            const isValid = !arrSelectedOwners.some(ownerInfo => ownerInfo.prop_owner_pan === e.prop_owner_pan && ownerInfo.prop_owner_type === e.prop_owner_type && ownerInfo.prop_owner_no !== parseInt(name.split('prop_owner_full_name_')[1]));    //To check whether the user choosing the existing pan
            //#endregion

            const selectedOwnerInfo = {
                prop_owner_name: value,
                prop_owner_pan: e.prop_owner_pan,
                prop_owner_type: e.prop_owner_type,
                prop_owner_no: parseInt(name.split('prop_owner_full_name_')[1])
            }
            if (isValid) {
                if (arrSelectedOwners.length > 0) {
                    arrSelectedOwners = arrSelectedOwners.filter(ownerInfo => ownerInfo.prop_owner_no !== parseInt(name.split('prop_owner_full_name_')[1]));
                }
                setArrSelectedOwnerInfo([...arrSelectedOwners, selectedOwnerInfo])
            }
            else {
                setIsDDError(name);
                setIsDDErrorRenderNeeded((prevState) => prevState + 1);
            }
            setValidationData((prevState) => ({
                ...prevState,
                [`${field}State`]: value && !isValid ? 'has-errorHelperTextRequired' : ''
            }));
        }
        //#endregion
    };
    //#endregion

    //#region [Alert handling]
    //#region To clsose the Alert
    const handleAlertClose = () => {
        setAlert(false);
        setSeverity('');
        setAlertMessage('');
    };
    //#endregion

    //#region To show the alert
    const showAlert = (msg, type) => {
        setAlert(true);
        setSeverity(type);
        setAlertMessage(msg);
    };
    //#endregion
    //#endregion

    //#region [Success & Navigation]
    const navigateToNextSection = () => {
        showAlert('Lead updated successfully', 'success');
        setNavIconPrefixState((prevState) => ({
            ...prevState,
            'Property Details': LeadNewStatus.Deviation,
        }));
        setSectionStatusCheck('completed');
        setTimeout(() => {
            if (props?.setShouldFetch) {
                setShouldFetch((prev) => prev + 1);
            }
        }, 1000);
    }
    //#endregion

    //#region [To return whether any input fields contains error or not]
    const isFormFieldError = () => {
        return _keys(validationData).filter(item => validationData[item])?.length
    }
    //#endregion

    //#region [To fetch the lead status]
    const fetchLeadStatus = () => {
        const payload = {
            loan_app_id: loanAppId,
            companyId: LAPCompanyId,
            productId: LAPProductId,
            user: user,
        };
        new Promise((resolve, reject) => {
            dispatch(getLeadStatusWatcher(payload, resolve, reject));
        })
            .then((response) => {
                setStatusObject(response.find((item) => item.section_code === 'property_doc'));
            })
            .catch((error) => { });
    };
    //#endregion

    return (
        <div className="applicant-details-container">
            <h4 className="applicant-details-header-style">Property Details</h4>
            <div>{renderFields('property_details')}</div>
            <h4 style={headingCss} className="applicant-details-header-style">Property Ownership Documents</h4>
            <div>{renderFields('property_ownership_documents')}</div>
            <h4 style={headingCss} className="applicant-details-header-style">Owner Details</h4>
            <div style={{
                display: 'grid',
                rowGap: '28px',
                gridTemplateColumns: '32.8% 32.8% 32.8%',
                columnGap: '1%',
                width: '98%',
            }}>{renderInputDD(count)}</div>
            {type == Application_Mode.View || navIconPrefixState[Lap_Screens.Property_Details] ? null : <Button
                label="Add More Owner"
                buttonType="primarys"
                isDisabled={type === Application_Mode.View || navIconPrefixState['Property Details'] ? true : isAddMoreOwnerDisabled}
                onClick={handleAddOwner}
                customStyle={{
                    marginTop: '40px',
                    height: '48px',
                    padding: '10px 24px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '16px',
                    color: '#134CDE',
                    fontFamily: 'Montserrat-Regular',
                    fontSize: '16px',
                    fontWeight: '800',
                    width: 'max-content',
                    lineHeight: '150%',
                    flexShrink: '0',
                    borderRadius: '40px',
                    marginRight: '8px',
                    border: '1px solid #134CDE',
                    pointerEvents: isAddMoreOwnerDisabled ? 'none' : 'auto',
                    background: 'var(--neutrals-neutral-30, #ffffff)',
                }}
                customLoaderClass={{
                    borderTop: '4px solid #fff',
                }}>
            </Button>}
            {type == Application_Mode.View || navIconPrefixState[Lap_Screens.Property_Details] == 'success' ? null : <div className="book-loan-button-container book-loan-button-alignment-double-button">
                <Button
                    id="btnSubmit"
                    label="Verify & Next"
                    isDisabled={!isSubmitButtonEnabled}
                    onClick={handleSubmit}
                    buttonType="primarys"
                    isLoading={sectionStatusCheck === 'inProgress' ? true : false}
                    customStyle={{
                        display: 'inline - flex',
                        height: '48px',
                        padding: '10px 24px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '16px',
                        color: '#FFF',
                        fontFamily: 'Montserrat-Regular',
                        fontSize: '16px',
                        fontWeight: '800',
                        width: 'max-content',
                        // pointerEvents: type === Application_Mode.View || navIconPrefixState['Property Details'] || !isSubmitButtonEnabled ? 'none' : 'auto',
                        lineHeight: '150%',
                        flexShrink: '0',
                        borderRadius: '40px',
                        background: isSubmitButtonEnabled
                            ? "var(--primary-blue-button-gradient, linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%))"
                            : "var(--neutrals-neutral-30, #CCCDD3)",
                    }}
                    customLoaderClass={{
                        borderTop: '4px solid #fff',
                    }}
                ></Button>
                {leadStatus == 'pending' ? null :
                    <Button
                        id="btnSave"
                        label="Save as Draft"
                        isDisabled={type === Application_Mode.View || navIconPrefixState['Property Details'] ? true : isSaveButtonDisabled}
                        buttonType="primarys"
                        onClick={handleSaveDraft}
                        customStyle={{
                            fontSize: '1rem',
                            color: isSaveButtonDisabled ? '#ffffff' : '#134CDE',
                            border: !isSaveButtonDisabled && '1px solid #134CDE',
                            height: '3rem',
                            width: 'max-content',
                            padding: '10px 24px',
                            borderRadius: '40px',
                            pointerEvents: (type === Application_Mode.View || navIconPrefixState['Property Details'] || isSaveButtonDisabled) ? "none" : "auto",
                            background: isSaveButtonDisabled && "var(--neutrals-neutral-30, #CCCDD3)"
                        }}
                        customLoaderClass={{
                            borderTop: '4px solid #fff',
                        }}>

                    </Button>
                }
            </div>}

            {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
        </div>
    )
}