import { bookLoansFormJsonFields } from '../views/bookLoans/bookLoansFormJson';

const fetchObjectsByDept = (deptName) => {
  const matchingObjects = [];
  for (const object of bookLoansFormJsonFields()) {
    if (object.dept === deptName) {
      matchingObjects.push(object);
    }
  }
  return matchingObjects;
};

export default {
  fetchObjectsByDept,
};
