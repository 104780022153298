import { updatePreLoaderWatcher } from '../actions/user';
import { takeLatest, call, put } from 'redux-saga/effects';
import { getDDRpsReportRequest, getPrepaymentDetailsRequest } from '../apis/ddRpsReport';

export function* getDdRpsReportRequestEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getDDRpsReportRequest, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getDdRpsReportRequestWatcherSaga() {
  yield takeLatest('DD_RPS_REPORT', getDdRpsReportRequestEffectSaga);
}

export function* getPrepaymentDetailsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getPrepaymentDetailsRequest, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getPrepaymentDetailsWatcherSaga() {
  yield takeLatest('PRE_PAYMENT_DETAIL', getPrepaymentDetailsEffectSaga);
}
