export const COLLECTION_AGENCY_FORM_FIELDS = {
  name: {
    label: 'Name',
    name: 'name',
    id: 'name',
    type: 'text',
    placeholder: 'Name',
    isRequired: true,
  },
};
