import { takeLatest, call, put } from 'redux-saga/effects';
import { getLeadSectionStatusApi, getLeadSectionStatusForLoanAppIdApi, postLapSectionStatus } from './../apis/status.api';
import { Saga_Action_Type } from '../constants/string_constants';

export function* getLeadSectionStatusEffectSaga(action) {
  try {
    const response = yield call(getLeadSectionStatusApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}
export function* getLeadSectionStatusForLoanAppIdEffectSaga(action) {
  try {
    const response = yield call(getLeadSectionStatusForLoanAppIdApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* postLapSectionStatusEffectSaga(action) {
  try {
    const { data } = yield call(postLapSectionStatus, action.payload);
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* getLapLeadSectionStatusWatcherSaga() {
  yield takeLatest('GET_LAP_LEAD_SECTION_STATUS', getLeadSectionStatusEffectSaga);
  yield takeLatest(Saga_Action_Type.Get_Lap_Lead_Section_Status_For_Lapid, getLeadSectionStatusForLoanAppIdEffectSaga);
  yield takeLatest(Saga_Action_Type.Post_Lap_Section_Status, postLapSectionStatusEffectSaga);
}
