export const remarkReason = {
  I01: 'Incorrect loan amount I01',
  I02: 'Incorrect net disbursement amount or fees I02',
  I03: 'Incorrect tenure I03',
  I04: 'Incorrect interest type I04',
  I05: 'Incorrect interest rate I05',
  I06: 'Incorrect repayment type I06',
  I07: 'Incorrect borrower details I07',
  I08: 'Disbursement could not happen on time I08',
  I09: 'Screening failed I09',
  I10: 'Borrower is looking for lesser Rate of Interest',
  I11: "Sanction amount is lower than Borrower's requirement",
  I12: 'Borrower has meanwhile taken loan from somewhere else',
  I13: 'Borrower is not responding',
  I14: 'Borrower is not able to provide updated financial documents',
  I15: 'Borrower is not able to provide OSV of KYC documents',
  I16: 'Borrower is not able to provide CIBIL overdue NOC',
  I17: 'Borrower is looking for lesser tenure',
  I18: 'Borrower is looking for higher tenure',
  I19: 'Borrower has lost interest due to delay in loan application processing',
};
