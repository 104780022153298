import { updatePreLoaderWatcher } from 'actions/user';
import { downloadBulkFileApi } from 'apis/BulkFile';
import { uploadBulkFileApi } from 'apis/BulkFile';
import { getBulkFileListApi } from 'apis/BulkFile';
import { takeLatest, call, put } from 'redux-saga/effects';

export function* uploadBulkFileDefaultEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const response = yield call(uploadBulkFileApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(response);
  } catch (error) {
    action.reject(error);
  }
}

export function* uploadBulkFileDefaultWatcherSaga() {
  yield takeLatest('UPLOAD_BULK_FILE_DEFAULT', uploadBulkFileDefaultEffectSaga);
}

export function* getBulkFileListEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const response = yield call(getBulkFileListApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(response.data);
  } catch (error) {
    action.reject(error);
  }
}

export function* getBulkFileListWatcherSaga() {
  yield takeLatest('GET_BULK_FILE_LIST', getBulkFileListEffectSaga);
}

export function* downloadBulkFileEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const response = yield call(downloadBulkFileApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(response.data);
  } catch (error) {
    action.reject(error);
  }
}

export function* downloadBulkFileWatcherSaga() {
  yield takeLatest('DOWNLOAD_BULK_FILE', downloadBulkFileEffectSaga);
}
