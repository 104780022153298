import { takeLatest, call } from 'redux-saga/effects';
import { getComprehensiveLogsAPI, postComprehensiveLogsAPI } from '../apis/comprehensiveLogs';

export function* getComprehensiveLogsEffectSaga(action) {
  try {
    const { data } = yield call(getComprehensiveLogsAPI, action.payload);
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* getComprehensiveLogsWatcherSaga() {
  yield takeLatest('GET_COMPREHENSIVE_LOGS', getComprehensiveLogsEffectSaga);
}

export function* addComprehensiveLogsEffectSaga(action) {
  try {
    const { data } = yield call(postComprehensiveLogsAPI, action.payload);
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* addComprehensiveLogsWatcherSaga() {
  yield takeLatest('POST_COMPREHENSIVE_LOGS', addComprehensiveLogsEffectSaga);
}
