import { Number_Constants } from "../../../constants/number_constants";
import { formatTitle } from "../../../../util/lap/helper";
import { Entity_Type, Field_Label, Section_Status, Section_Status_Name } from "../../../constants/string_constants";
import { calculateAge, getfillersFromList, getFillers } from "../../../utils/helper";
import { FORMAT_AS_IND_CURRENCY, GET_FINAL_SECTION_VALIDATION_CHECKLIST, GET_OWNER_DETAILS, GET_SHARE_HOLDERS_DETAILS, FORMAT_AS_SQFT, GET_DOCUMENT_LIST, GET_PROPERTY_TITLE_DOCUMENTS } from "./helper";
import _isEmpty from 'lodash/isEmpty'

export const leadsAccordionData = () => {
  return {
    primary_applicants: {
      status: 'in_review',
      first_name: 'Aman',
      middle_name: 'Singh',
      last_name: 'Gujral',
      age: 10,
      gender: 'N/A',
      mobile_number: 1,
      email: 'arjitbhandari222830@gmail.com',
      father_name: 'Harsh Singh Gujral',
      PAN: 'SDFGF2332S',
      aadhaar_number: '33212312',
      current_address: {
        address_line_1: 'Astha Niwas',
        address_line_2: 'Sahastradhara Road',
        city: 'Dehradun',
        state: 'Uttarakahnd',
        pincode: '248001',
        residence_status: 'N/A',
      },
      permanent_address: {
        address_line_1: 'Astha Niwas',
        address_line_2: 'Sahastradhara Road',
        city: 'Dehradun',
        state: 'Uttarakahnd',
        pincode: '248001',
        residence_status: 'N/A',
      },
      validation_checklist: {
        nsdl_verification: 'approved',
        ckyc: 'in_review',
        bureau_verification: 'approved',
        hunter_validation: 'in_review',
        check_1: 'approved',
        check_2: 'in_review',
      },
    },
    entity_details: {
      status: 'in_progress',
      entity_type: 'Proprietor',
      entity_name: 'XYZ',
      date_of_incorporation: '06-08-2023',
      udyam_registration_number: 'ETDHP5458B',
      udayam_certificate: 'checked',
      communication_address: {
        address_line_1: 'Astha Niwas',
        address_line_2: 'Sahastradhara Road',
        state: 'Uttarakahnd',
        city: 'Dehradun',
        pincode: '248001',
      },
      registered_address: {
        address_line_1: 'Astha Niwas',
        address_line_2: 'Sahastradhara Road',
        state: 'Uttarakahnd',
        city: 'Dehradun',
        pincode: '248001',
      },
    },
    'co-applicant_details': {
      'co-applicants_details_1': {
        status: 'pending',
        first_name: 'Aman',
        middle_name: 'Singh',
        last_name: 'Gujral',
        age: 10,
        gender: 'N/A',
        mobile_number: 1,
        email: 'arjitbhandari222830@gmail.com',
        father_name: 'Harsh Singh Gujral',
        PAN: 'SDFGF2332S',
        aadhaar_number: '33212312',
        current_address: {
          address_line_1: 'Astha Niwas',
          address_line_2: 'Sahastradhara Road',
          city: 'Dehradun',
          state: 'Uttarakahnd',
          pincode: '248001',
          residence_status: 'N/A',
        },
        permanent_address: {
          address_line_1: 'Astha Niwas',
          address_line_2: 'Sahastradhara Road',
          city: 'Dehradun',
          state: 'Uttarakahnd',
          pincode: '248001',
          residence_status: 'N/A',
        },
        validation_checklist: {
          pan: 'approved',
          aadhar: 'approved',
          applicant_selfie: 'in_review',
        },
      },
      'co-applicants_details_2': {
        status: 'approved',
        first_name: 'Aman',
        middle_name: 'Singh',
        last_name: 'Gujral',
        age: 10,
        gender: 'N/A',
        mobile_number: 1,
        email: 'arjitbhandari222830@gmail.com',
        father_name: 'Harsh Singh Gujral',
        PAN: 'SDFGF2332S',
        aadhaar_number: '33212312',
        current_address: {
          address_line_1: 'Astha Niwas',
          address_line_2: 'Sahastradhara Road',
          city: 'Dehradun',
          state: 'Uttarakahnd',
          pincode: '248001',
          residence_status: 'N/A',
        },
        permanent_address: {
          address_line_1: 'Astha Niwas',
          address_line_2: 'Sahastradhara Road',
          city: 'Dehradun',
          state: 'Uttarakahnd',
          pincode: '248001',
          residence_status: 'N/A',
        },
        validation_checklist: {
          pan: 'approved',
          aadhar: 'approved',
          applicant_selfie: 'in_review',
        },
      },
    },
    guarantor_details: {
      guarantor_details_1: {
        status: 'approved',
        first_name: 'Aman',
        middle_name: 'Singh',
        last_name: 'Gujral',
        age: 10,
        gender: 'N/A',
        mobile_number: 1,
        email: 'arjitbhandari222830@gmail.com',
        father_name: 'Harsh Singh Gujral',
        PAN: 'SDFGF2332S',
        aadhaar_number: '33212312',
        current_address: {
          address_line_1: 'Astha Niwas',
          address_line_2: 'Sahastradhara Road',
          city: 'Dehradun',
          state: 'Uttarakahnd',
          pincode: '248001',
          residence_status: 'N/A',
        },
        permanent_address: {
          address_line_1: 'Astha Niwas',
          address_line_2: 'Sahastradhara Road',
          city: 'Dehradun',
          state: 'Uttarakahnd',
          pincode: '248001',
          residence_status: 'N/A',
        },
        validation_checklist: {
          pan: 'approved',
          aadhar: 'approved',
          applicant_selfie: 'in_review',
        },
      },
      gurantor_details_2: {
        status: 'rejected',
        first_name: 'Aman',
        middle_name: 'Singh',
        last_name: 'Gujral',
        age: 10,
        gender: 'N/A',
        mobile_number: 1,
        email: 'arjitbhandari222830@gmail.com',
        father_name: 'Harsh Singh Gujral',
        PAN: 'SDFGF2332S',
        aadhaar_number: '33212312',
        current_address: {
          address_line_1: 'Astha Niwas',
          address_line_2: 'Sahastradhara Road',
          city: 'Dehradun',
          state: 'Uttarakahnd',
          pincode: '248001',
          residence_status: 'N/A',
        },
        permanent_address: {
          address_line_1: 'Astha Niwas',
          address_line_2: 'Sahastradhara Road',
          city: 'Dehradun',
          state: 'Uttarakahnd',
          pincode: '248001',
          residence_status: 'N/A',
        },
        validation_checklist: {
          pan: 'approved',
          aadhar: 'approved',
          applicant_selfie: 'in_review',
        },
      },
    },
    financial_documents: {
      status: 'approved',
      itr_1st_yearL: 'checked',
      itr_2nd_yearL: 'checked',
      itr_3rd_yearL: 'checked',
      bank_statement_1st_yearL: 'checked',
      bank_statement_2nd_yearL: 'checked',
      bank_statement_3rd_yearL: 'checked',
    },
    validation_checklist: {
      status: 'in_review',
      doc1: 'approved',
      doc2: 'approved',
    },
  };
};

export const leadsData = () => [
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'pending',
    action: 'update',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'approved',
    action: 'view',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'in_review',
    action: 'view',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'rejected',
    action: 'view',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'lead_deviation',
    action: 'view',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'draft',
    action: 'Resume',
  },
];

export const AuditLogData = () => [
  {
    title: 'Lead Submission',
    data: [
      {
        head: 'Name',
        body: 'Abhishek Anand',
      },
      {
        head: 'User ID',
        body: 'abhishek.anand @ominifi.in',
      },
      {
        head: 'Role',
        body: 'Finance Manager',
      },
      {
        head: 'Date & Time',
        body: '11-10-2023, 03:58 PM',
      },
      {
        head: 'Comment',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
      },
    ],
  },
  {
    title: 'Lead Submission',
    data: [
      {
        head: 'Name',
        body: 'Abhishek Anand',
      },
      {
        head: 'User ID',
        body: 'abhishek.anand @ominifi.in',
      },
      {
        head: 'Role',
        body: 'Finance Manager',
      },
      {
        head: 'Date & Time',
        body: '11-10-2023, 03:58 PM',
      },
      {
        head: 'Comment',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
      },
    ],
  },
  {
    title: 'Lead Submission',
    data: [
      {
        head: 'Name',
        body: 'Abhishek Anand',
      },
      {
        head: 'User ID',
        body: 'abhishek.anand @ominifi.in',
      },
      {
        head: 'Role',
        body: 'Finance Manager',
      },
      {
        head: 'Date & Time',
        body: '11-10-2023, 03:58 PM',
      },
      {
        head: 'Comment',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
      },
    ],
  },
  {
    title: 'Lead Submission',
    data: [
      {
        head: 'Name',
        body: 'Abhishek Anand',
      },
      {
        head: 'User ID',
        body: 'abhishek.anand @ominifi.in',
      },
      {
        head: 'Role',
        body: 'Finance Manager',
      },
      {
        head: 'Date & Time',
        body: '11-10-2023, 03:58 PM',
      },
      {
        head: 'Comment',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
      },
    ],
  },
  {
    title: 'Lead Submission',
    data: [
      {
        head: 'Name',
        body: 'Abhishek Anand',
      },
      {
        head: 'User ID',
        body: 'abhishek.anand @ominifi.in',
      },
      {
        head: 'Role',
        body: 'Finance Manager',
      },
      {
        head: 'Date & Time',
        body: '11-10-2023, 03:58 PM',
      },
      {
        head: 'Comment',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
      },
    ],
  },
  {
    title: 'Rejection',
    data: [
      {
        head: 'Name',
        body: 'Abhishek Anand',
      },
      {
        head: 'User ID',
        body: 'abhishek.anand @ominifi.in',
      },
      {
        head: 'Role',
        body: 'Finance Manager',
      },
      {
        head: 'Date & Time',
        body: '11-10-2023, 03:58 PM',
      },
      {
        head: 'Comment',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
      },
    ],
  },
];

export const PRIMARY_VALIDATION_CHECKLIST = [
    {
        "validation_code": "ASCORE",
        "validation_name": "A_SCORE",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "UNSCR",
        "validation_name": "UNSCR_CHECK",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "CKYC",
        "validation_name": "CKYC_SEARCH",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "FACE_MATCH",
        "validation_name": "FACE_MATCH",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "NEGATIVE_AREA",
        "validation_name": "NEGATIVE_AREA_SEARCH",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    }
]

export const ENTITY_VALIDATION_CHECKLIST = [
    {
        "validation_code": "UDYAM",
        "validation_name": "UDYAM_PARSER",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "NEGATIVE_AREA",
        "validation_name": "NEGATIVE_AREA_SEARCH",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "UNSCR",
        "validation_name": "UNSCR_CHECK",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "CRIME_CHECK",
        "validation_name": "CRIME_CHECK",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    }
]

export const FINANCIAL_VALIDATION_CHECKLIST = [
    {
        "validation_code": "ITR1",
        "validation_name": "ITR1",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "ITR2",
        "validation_name": "ITR2",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "GST_RETURN",
        "validation_name": "GST_RETURN",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "BANK_STATEMENT",
        "validation_name": "BANK_STATEMENT",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    }
]

export const COBORROWER_VALIDATION_CHECKLIST = [
    {
        "validation_code": "ASCORE",
        "validation_name": "A_SCORE",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "CKYC",
        "validation_name": "CKYC_SEARCH",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "UNSCR",
        "validation_name": "UNSCR_CHECK",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "FACE_MATCH",
        "validation_name": "FACE_MATCH",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
]
export const GUARANTOR_VALIDATION_CHECKLIST = [
    {
        "validation_code": "UNSCR",
        "validation_name": "UNSCR_CHECK",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "CKYC",
        "validation_name": "CKYC_SEARCH",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
    {
        "validation_code": "FACE_MATCH",
        "validation_name": "FACE_MATCH",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
]

export const PROPERTY_VALIDATION_CHECKLIST = [
    {
        "validation_code": "PROPERTY_DOCUMENT_OWNERSHIP",
        "validation_name": "PROPERTY_DOCUMENT_OWNERSHIP",
        "validation_status": Section_Status.YET_TO_START,
        "validation_remarks": Field_Label.VALIDATION_IN_PROGRESS
    },
]

export const LEAD_DETAILS_TAB_ACCORDION_SECTIONS = ['Applicant Details', 'Entity Details', 'Co-Borrower Details', 'Guarantor Details', 'Property Details', 'Financial Documents', 'Shareholding Structure', 'Property Documents (For Credit Team)', 'Additional Documents'];
export const FORM_FIELD_TYPES = ['field', 'filler', 'subHeading', 'separator', 'validation_checklist_fields', 'validation_checklist_fields_with_parser_status', 'input_text_field', 'input_upload_field', 'input_button_field', 'input_action_button_field', 'custom_input_text_field', 'custom_date_picker', 'custom_dropdown', 'custom_yes_or_no_radio_btns', 'custom_age_field', 'custom_shareholding_table', 'accordion_divider', 'title_document_row'];

export const LEAD_DETAILS_TAB_ACCORDION_FIELDS = ({leadDetails, documents, allDocuments, titleDocDetails}) =>{ 
  const {
    first_name,
    middle_name,
    last_name,
    dob, 
    gender,
    appl_phone,
    email_id,
    father_fname,
    occupation_type,
    entity_details,
    appl_pan,
    loan_amount,
    loan_tenure,
    resi_addr_ln1,
    resi_addr_ln2,
    state,
    city,
    pincode,
    nature_of_ownership,
    house_ownership,
    no_of_years_current_addr,
    loan_interest_rate,
    per_addr_ln1,
    per_addr_ln2,
    per_state,
    per_city,
    per_pincode,
    no_of_years_permanent_addr,
    guarantor,
    coborrower,
    property,
    share_holders,
    type_of_loan,
    purpose_of_loan,
    addi_docs_comment = '',
    product_id,
    company_id,
    loan_app_id,
    property_documents
  } = leadDetails
  const primarySectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.primary_final_submit?.validation_checklist ?? PRIMARY_VALIDATION_CHECKLIST)
  const enitySectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.entity_final_submit?.validation_checklist ?? ENTITY_VALIDATION_CHECKLIST)
  const financialSectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.financial_doc_final_submit?.validation_checklist ?? FINANCIAL_VALIDATION_CHECKLIST)
  const propertySectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.property_final_submit?.validation_checklist ?? PROPERTY_VALIDATION_CHECKLIST)
  const propertyOwnerDetails = GET_OWNER_DETAILS(property?.prop_property_owner_details)
  const shareHolders = GET_SHARE_HOLDERS_DETAILS(share_holders)
  const propertyTitleDocuments = GET_PROPERTY_TITLE_DOCUMENTS(allDocuments, titleDocDetails, property_documents?.title_documents);
  const {valuationReport, legalReport} = allDocuments;
  const additionalDocs = GET_DOCUMENT_LIST(documents)
  const getAddressTitle = () => {
    if(entity_details?.entity_type?.toLowerCase() === Field_Label.Individual){
      return Field_Label.Current_Address
    } return Field_Label.Communication_Address
  }
  let financeCheckList =[]
  if (financialSectionValidationCheckList?.length) {
    const setCheckList = (sectionTitle, checkListKeyWord) => {
      const checkList = financialSectionValidationCheckList.filter(item => item?.label?.includes(checkListKeyWord))
      if (checkList?.length) {
        financeCheckList = [...financeCheckList, {
          label: sectionTitle,
          value: '',
          type: 'subHeading',
          direction: 'row',

        }, ...checkList, ...getfillersFromList(checkList, Number_Constants.FOUR,true),]
      }
    }
    setCheckList('ITR', 'ITR')
    setCheckList('GST', 'GST')
    setCheckList('Financial Statement', 'BANK')
  }
  return [
  {
    section: 'applicant_details',
    sectionName: 'Applicant Details',
    isHideSection: appl_pan? false: true,
    sectionStatusName: Section_Status_Name.Primary_Applicant,
    isContainsAccordion: false,
    isSubAccordion: false,
    subAccordionData: [],
    fieldsArr: [
      {
        label: 'first name',
        value: first_name,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'middle name',
        value: middle_name,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'last name',
        value: last_name,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'date of birth',
        value: dob,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'age',
        value: calculateAge(dob).toString(),
        type: 'field',
        direction: 'column',
      },
      {
        label: 'gender',
        value: gender,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'mobile no',
        value: appl_phone,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'email',
        value: email_id,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'father full name',
        value: father_fname,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'occupation type',
        value: occupation_type,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'entity type',
        value: entity_details?.entity_type,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'pan',
        value: appl_pan,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'loan details',
        value: '',
        type: 'subHeading',
        direction: 'row',
      },
      {
        label: 'requested loan amount',
        value: FORMAT_AS_IND_CURRENCY(loan_amount),
        type: 'field',
        direction: 'column',
      },
      {
        label: 'tenure in months',
        value: loan_tenure,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'loan type',
        value: type_of_loan,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'loan purpose',
        value: formatTitle(purpose_of_loan),
        type: 'field',
        direction: 'column',
      },
      {
        label: 'interest rate',
        value: `${loan_interest_rate}%`,
        type: 'field',
        direction: 'column',
      },
    //   ...getFillers(Number_Constants.THREE),
      {
        label: getAddressTitle(),
        value: '',
        type: 'subHeading',
        direction: 'row',
      },
      {
        label: 'address line 1',
        value: resi_addr_ln1,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'address line 2',
        value: resi_addr_ln2,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'state',
        value: state,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'city',
        value: city,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'pincode',
        value: pincode?.toString(),
        type: 'field',
        direction: 'column',
      },
      {
        label: 'nature of ownership',
        value: nature_of_ownership,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'no of years',
        value: no_of_years_current_addr,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'permanent address',
        value: '',
        type: 'subHeading',
        direction: 'row',
      },
      {
        label: 'address line 1',
        value: per_addr_ln1,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'address line 2',
        value: per_addr_ln2,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'state',
        value: per_state,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'city',
        value: per_city,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'pincode',
        value: per_pincode?.toString(),
        type: 'field',
        direction: 'column',
      },
      {
        label: 'nature of ownership',
        value: house_ownership,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'no of years',
        value: no_of_years_permanent_addr,
        type: 'field',
        direction: 'column',
      },
      ...primarySectionValidationCheckList
    ],
  },
  {
    section: 'entity_details',
    sectionName: 'Entity Details',
    sectionStatusName: Section_Status_Name.Entity_Details,
    isHideSection: _isEmpty(entity_details) ? true : false,
    isContainsAccordion: false,
    isSubAccordion: false,
    subAccordionData: [],
    fieldsArr: [
      {
        label: 'entity type',
        value: entity_details?.entity_type,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'business commencement date',
        value: entity_details?.date_of_incorporation,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'entity name',
        value: entity_details?.entity_name,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'nature of business',
        value: entity_details?.nature_of_business,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'work profile',
        value: entity_details?.work_profile,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'current address',
        value: '',
        type: 'subHeading',
        direction: 'row',
      },
      {
        label: 'address line 1',
        value: entity_details?.com_addr_ln1,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'address line 2',
        value: entity_details?.com_addr_ln2,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'state',
        value: entity_details?.com_state,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'city',
        value: entity_details?.com_city,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'pincode',
        value: entity_details?.com_pincode,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'nature of ownership',
        value: entity_details?.com_nature_of_ownership,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'no of years',
        value: entity_details?.no_of_years_com_addr,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'permanent address',
        value: '',
        type: 'subHeading',
        direction: 'row',
      },
      {
        label: 'address line 1',
        value: entity_details?.res_addr_ln1,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'address line 2',
        value: entity_details?.res_addr_ln2,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'state',
        value: entity_details?.res_state,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'city',
        value: entity_details?.res_city,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'pincode',
        value: entity_details?.res_pincode,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'nature of ownership',
        value: entity_details?.res_nature_of_ownership,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'no of years',
        value: entity_details?.no_of_years_res_addr,
        type: 'field',
        direction: 'column',
      },
          ...enitySectionValidationCheckList
        ],
      },
  {
    section: 'co_borrower_details',
    sectionName: 'Co-Borrower Details',
    isHideSection: coborrower?.filter((item)=>!item?.is_skip)?.length ? false : true,
    isContainsAccordion: true,
    isSubAccordion: false,
    subAccordionData: coborrower?.map((item, index)=>{
      const {
        cb_fname,
        cb_mname,
        cb_lname,
        cb_dob,
        cb_gender,
        cb_mobile,
        cb_father_name,
        cb_pan,
        cb_aadhaar,
        cb_email,
        cb_relationship,
        cb_entity_shareholder,
        cb_property_owner,
        cb_resi_addr_ln1,
        cb_resi_addr_ln2,
        cb_state,
        cb_city,
        cb_pincode,
        cb_per_addr_ln1,
        cb_per_addr_ln2,
        cb_per_state,
        cb_per_city,
        cb_per_pincode,
      } = item
      const coBorrowerIndex = index+1
      const coBorrowerSectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.[`Co-Borrower-${coBorrowerIndex}_final_submit`]?.validation_checklist ?? COBORROWER_VALIDATION_CHECKLIST)
      return {
        subIndex: coBorrowerIndex,
        section: 'co_borrower_details',
        sectionName: 'Co-Borrower Details',
        sectionStatusName: `co_borrower-${coBorrowerIndex}`,
        isContainsAccordion: false,
        isSubAccordion: true,
        fieldsArr: [
          {
            label: 'first name',
            value: cb_fname,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'middle name',
            value: cb_mname,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'last name',
            value: cb_lname,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'date of birth',
            value: cb_dob,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'age',
            value: calculateAge(cb_dob).toString(),
            type: 'field',
            direction: 'column',
          },
          {
            label: 'gender',
            value: cb_gender,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'mobile no',
            value: cb_mobile,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'email',
            value: cb_email,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'father full name',
            value: cb_father_name,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'pan',
            value: cb_pan,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'aadhar number',
            value: cb_aadhaar,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'relation with applicant',
            value: cb_relationship,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'entity shareholder',
            value: cb_entity_shareholder,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'property owner',
            value: cb_property_owner,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'current address',
            value: '',
            type: 'subHeading',
            direction: 'row',
          },
          {
            label: 'address line 1',
            value: cb_resi_addr_ln1,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'address line 2',
            value: cb_resi_addr_ln2,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'state',
            value: cb_state,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'city',
            value: cb_city,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'pincode',
            value: cb_pincode?.toString(),
            type: 'field',
            direction: 'column',
          },
          {
            label: 'permanent address',
            value: '',
            type: 'subHeading',
            direction: 'row',
          },
          {
            label: 'address line 1',
            value: cb_per_addr_ln1,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'address line 2',
            value: cb_per_addr_ln2,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'state',
            value: cb_per_state,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'city',
            value: cb_per_city,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'pincode',
            value: cb_per_pincode?.toString(),
            type: 'field',
            direction: 'column',
          },
          ...coBorrowerSectionValidationCheckList,
        ],
      }
    }) ?? [],
    fieldsArr: [],
  },
  {
    section: 'guarantor_details',
    isHideSection: guarantor?.filter((item)=>!item?.is_skip)?.length ? false : true,
    sectionName: 'Guarantor Details',
    isContainsAccordion: true,
    isSubAccordion: false,
    subAccordionData: guarantor?.map((item, index)=>{
      const {
        gua_fname,
        gua_mname,
        gua_lname,
        gua_dob,
        gua_gender,
        gua_mobile,
        gua_email,
        gua_aadhaar,
        gua_father_name,
        gua_pan,
        gua_resi_addr_ln1,
        gua_resi_addr_ln2,
        gua_state,
        gua_city,
        gua_pincode,
        gua_per_addr_ln1,
        gua_per_addr_ln2,
        gua_per_state,
        gua_per_city,
        gua_per_pincode,
      } = item
      const guarantorIndex = index+1
      const guarantorSectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.[`Guarantor-${guarantorIndex}_final_submit`]?.validation_checklist ?? GUARANTOR_VALIDATION_CHECKLIST)
      return {
        subIndex: guarantorIndex,
        section: 'guarantor_details',
        sectionName: 'Guarantor Details',
        isContainsAccordion: false,
        sectionStatusName: `Guarantor-${index}`,
        isSubAccordion: true,
        fieldsArr: [
          {
            label: 'first name',
            value: gua_fname,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'middle name',
            value: gua_mname,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'last name',
            value: gua_lname,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'date of birth',
            value: gua_dob,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'age',
            value: calculateAge(gua_dob).toString(),
            type: 'field',
            direction: 'column',
          },
          {
            label: 'gender',
            value: gua_gender,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'mobile no',
            value: gua_mobile,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'email',
            value: gua_email,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'father full name',
            value: gua_father_name,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'pan',
            value: gua_pan,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'aadhar number',
            value: gua_aadhaar,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'current address',
            value: '',
            type: 'subHeading',
            direction: 'row',
          },
          {
            label: 'address line 1',
            value: gua_resi_addr_ln1,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'address line 2',
            value: gua_resi_addr_ln2,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'state',
            value: gua_state,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'city',
            value: gua_city,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'pincode',
            value: gua_pincode,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'permanent address',
            value: '',
            type: 'subHeading',
            direction: 'row',
          },
          {
            label: 'address line 1',
            value: gua_per_addr_ln1,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'address line 2',
            value: gua_per_addr_ln2,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'state',
            value: gua_per_state,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'city',
            value: gua_per_city,
            type: 'field',
            direction: 'column',
          },
          {
            label: 'pincode',
            value: gua_per_pincode,
            type: 'field',
            direction: 'column',
          },
          ...guarantorSectionValidationCheckList,
        ],
      }
    }) ?? [],
    fieldsArr: [],
  },
  {
    section: 'property_details',
    sectionName: 'Property Details',
    sectionStatusName: Section_Status_Name.Property_Details,
    isHideSection: _isEmpty(property) ? true : false,
    isContainsAccordion: false,
    isSubAccordion: false,
    subAccordionData: [],
    fieldsArr: [
      {
        label: 'approx value of property',
        value: property?.prop_approx_property_value?.toString(),
        type: 'field',
        direction: 'column',
      },
      {
        label: 'property type',
        value: property?.prop_property_type,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'plot size',
        value: property?.prop_plot_size?.toString(),
        type: 'field',
        direction: 'column',
      },
      {
        label: 'build-up area',
        value: property?.prop_built_up_area?.toString(),
        type: 'field',
        direction: 'column',
      },
      {
        label: 'property address',
        value: property?.prop_property_address,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'nature of property',
        value: property?.prop_nature_of_property,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'property jurisdiction',
        value: property?.prop_property_jurisdiction,
        type: 'field',
        direction: 'column',
      },
      {
        label: 'ownership details',
        value: '',
        type: 'subHeading',
        direction: 'row',
      },
      ...propertyOwnerDetails,
      ...propertySectionValidationCheckList
    ],
  },
  {
    section: 'financial_documents',
    sectionName: 'Financial Documents',
    sectionStatusName: Section_Status_Name.Financial_Documents,
    isContainsAccordion: false,
    isHideSection: financeCheckList?.length ? false : true,
    isSubAccordion: false,
    subAccordionData: [],
    fieldsArr: financeCheckList
  },
  {
    section: 'shareholding_structure',
    sectionName: 'Shareholding Structure',
    isHideSection: !entity_details?.entity_type ? true : entity_details?.entity_type === Entity_Type.Individual ? true : !shareHolders?.length ? true: false,
    sectionStatusName: Section_Status_Name.Share_Holding_Details,
    isContainsAccordion: false,
    isSubAccordion: false,
    subAccordionData: [],
    fieldsArr: [
      ...shareHolders,
    ]
  },
  {
    section: 'property_documents',
    sectionName: 'Property Documents (For Credit Team)',
    sectionStatusName: Section_Status_Name.Property_Documents,
    isContainsAccordion: false,
    isSubAccordion: false,
    subAccordionData: [],
    fieldsArr: [
          
        {
          label: 'Valuation Report',
          value: 'file2.pdf',
          type: 'input_upload_field',
          direction: 'column',
          loanAppId: loan_app_id, 
          company_id, 
          product_id,
          isDisabled: true,
          propertyDocs: ([valuationReport] || Property_Valuation_Report_Upload).map(val => {
            return {...val, name: "Report"}
          })
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: 'Value',
          header: 'Acutal Property Value',
          value: property_documents?.prop_value,
          type: 'custom_input_text_field',
          direction: 'column',
          stateKey: "property-documents:prop_value",
          isDisabled: true,
        },
        {
            label: '',
            value: '',
            type: 'filler',
            direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: 'Approve',
          value: 'Approve',
          type: 'input_button_field',
          direction: 'column',
          loanAppId: loan_app_id, 
          company_id, 
          product_id,
        },
        {
          label: "",
          value: '',
          type: 'accordion_divider',
          direction: 'column',
        },
        {
          label: 'Legal Report',
          value: 'file.pdf',
          type: 'input_upload_field',
          direction: 'column',
          loanAppId: loan_app_id, 
          company_id, 
          product_id,
          isDisabled: true,
          propertyDocs: ([legalReport] || Property_Legal_Report_Upload).map(val => {
            return {...val, name: "Report"}
          }) 
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: 'title document',
          value: '',
          type: 'separator',
          direction: 'row',
        },
        {
          label: "",
          value: "",
          type: "title_document_row",
          direction: 'column',
          loanAppId: loan_app_id, 
          company_id, 
          product_id,
          documentNameLabel: "Document Name",
          documents: propertyTitleDocuments,
          isDisabled: true
        }, 
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        }
  ]
  },
  {
    section: 'additional_documents',
    sectionName: 'Additional Documents',
    sectionStatusName: 'Additional Document',
    isContainsAccordion: false,
    isSubAccordion: false,
    subAccordionData: [],
    fieldsArr: [
        ...additionalDocs,
        {
            label: 'Comment',
            value: addi_docs_comment,
            type: 'subHeading',
            direction: 'column',
        },
    ]
  },
]};

export const LEAD_DOCUMENTS_TAB_DATA = [
    {
      "title": "Applicant",
      "docs": [
        {
          "_id": {
            "code": 1
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 1,
          "file_type": "PAN"
        },
        {
          "_id": {
            "code": 2
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 2,
          "file_type": "Aadhaar Front"
        },
        {
          "_id": {
            "code": 3
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 3,
          "file_type": "Aadhaar Back"
        },
        {
          "_id": {
            "code": 4
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 4,
  
          "file_type": "Selfie"
        },
        {
          "_id": {
            "code": 5
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 5,
          "file_type": "Screening Report"
        },
        {
          "_id": {
            "code": 6
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 5,
          "file_type": "Bureau Report"
        }
      ]
    },
  
    {
      "title": "Entity",
      "docs": [
        {
          "_id": {
            "code": 1
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 1,
          "file_type": "Udyam Registration Certificate"
        },
        {
          "_id": {
            "code": 2
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 2,
          "file_type": "GSTN"
        },
        {
          "_id": {
            "code": 3
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 3,
          "file_type": "Shop Establishment Certificate"
        }
      ]
    },
    {
      "title": "Co-Borrower-1",
      "docs": [
        {
          "_id": {
            "code": 1
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
  
          "code": 1,
  
          "file_type": "PAN"
        },
        {
          "_id": {
            "code": 2
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 2,
          "file_type": "Aadhaar Front"
        },
        {
          "_id": {
            "code": 3
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 3,
          "file_type": "Aadhaar Back"
        },
        {
          "_id": {
            "code": 4
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 4,
          "file_type": "Selfie"
        },
        {
          "_id": {
            "code": 5
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 5,
          "file_type": "Bureau Report"
        }
      ]
    },
    {
      "title": "Co-Borrower-2",
      "docs": [
        {
          "_id": {
            "code": 1
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
  
          "code": 1,
  
          "file_type": "PAN"
        },
        {
          "_id": {
            "code": 2
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 2,
          "file_type": "Aadhaar Front"
        },
        {
          "_id": {
            "code": 3
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 3,
          "file_type": "Aadhaar Back"
        },
        {
          "_id": {
            "code": 4
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 4,
          "file_type": "Selfie"
        },
        {
          "_id": {
            "code": 5
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 5,
          "file_type": "Bureau Report"
        }
      ]
    },
    {
      "title": "Guarantor",
      "docs": [
        {
          "_id": {
            "code": 1
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
  
          "code": 1,
  
          "file_type": "PAN"
        },
        {
          "_id": {
            "code": 2
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 2,
          "file_type": "Aadhaar Front"
        },
        {
          "_id": {
            "code": 3
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 3,
          "file_type": "Aadhaar Back"
        },
        {
          "_id": {
            "code": 4
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 4,
          "file_type": "Selfie"
        },
        {
          "_id": {
            "code": 5
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 5,
          "file_type": "Bureau Report"
        }
      ]
    },
    {
      "title": "Financial Documents",
      "docs": [
        {
          "_id": {
            "code": 1
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 1,
          "file_type": "ITR 1st Year"
        },
        {
          "_id": {
            "code": 2
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 2,
          "file_type": "ITR 2nd Year"
        },
        {
          "_id": {
            "code": 3
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 3,
          "file_type": "Bank Statement 1 (6 Months)"
        },
        {
          "_id": {
            "code": 4
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 4,
          "file_type": "Bank Statement 2 (6 Months)"
        },
        {
          "_id": {
            "code": 5
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 5,
          "file_type": "Bank Statement 3 (6 Months)"
        }
      ]
    },
    {
      "title": "Shareholding",
      "docs": [
        {
          "_id": {
            "code": 1
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 1,
          "file_type": "Shareholding Certificate"
        }
      ]
    },
    {
      "title": "Additional Documents",
      "docs": [
        {
          "_id": {
            "code": 1
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 1,
          "file_type": "Doc1"
        },
        {
          "_id": {
            "code": 2
          },
          "file_url": "https://elaap-s3-dev.s3.ap-south-1.amazonaws.com/loandocument/CKY0048/CKYCT/1684306781272/CKYCT-9814043309549/selfie.txt",
          "code": 2,
          "file_type": "Doc2"
        }
      ]
    }
  ];

  export const GSTData = [
    {
        title: 'GST',
        data: [
            {
                field: 'gst_turnover_m0',
                title: 'GST Turnover M0',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""

            },
            {
                field: 'gst_turnover_m1',
                title: 'GST Turnover M1',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'gst_turnover_m2',
                title: 'GST Turnover M2',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'gst_turnover_m3',
                title: 'GST Turnover M3',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'gst_turnover_m4',
                title: 'GST Turnover M4',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'gst_turnover_m5',
                title: 'GST Turnover M5',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'gst_turnover_m6',
                title: 'GST Turnover M6',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'gst_turnover_m7',
                title: 'GST Turnover M7',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'gst_turnover_m8',
                title: 'GST Turnover M8',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'gst_turnover_m9',
                title: 'GST Turnover M9',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'gst_turnover_m10',
                title: 'GST Turnover M10',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'gst_turnover_m11',
                title: 'GST Turnover M11',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'gst_turnover_m12',
                title: 'GST Turnover M12',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            
            {
              field: 'ttl_gst_turnover_l6m',
              title: 'Total GST Turnover (6 months)',
              type: 'number',
              validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
              isOptional: 'FALSE',
              checked: 'TRUE',
              isCommon: 'TRUE',
              initialValue: ""
          },

            {
                field: 'ttl_gst_turnover_l12m',
                title: 'Total GST Turnover (12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
              field: 'ttl_gst_turnover_l3m',
              title: 'Total GST Turnover (3 months)',
              type: 'number',
              validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
              isOptional: 'FALSE',
              checked: 'TRUE',
              isCommon: 'TRUE',
              initialValue: ""
            },

            {
                field: 'lst_mnth_gst_turnover',
                title: 'Last Month GST Turnover',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'ttl_gst_paid_l6m',
                title: 'Total GST Paid (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },


            {
                field: 'ttl_gst_paid_l12m',
                title: 'Total GST Paid (Last 12 months)', // need to remove underscore
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'ttl_gst_paid_l3m',
                title: 'Total GST Paid (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'lst_mnth_gst_paid',
                title: 'Last Month GST Paid',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'null_gst_filing_l12m',
                title: 'Null GST Filing(Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'null_gst_filing_l6m',
                title: 'Null GST Filing(Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
              field: 'one_shot_gst_filing_l12m',
              title: 'One Shot GST Filing(Last 12 months)',
              type: 'number',
              validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
              isOptional: 'FALSE',
              checked: 'TRUE',
              isCommon: 'TRUE',
              initialValue: ""
            },

            {
                field: 'one_shot_gst_filing_l6m',
                title: 'One Shot GST Filing(Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'cnt_of_gst_filing_missed_l12m',
                title: 'Count of GST Filing Missed (Last 12 mon)',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'cnt_of_gst_filing_missed_l6m',
                title: 'Count of GST Filing Missed (Last 6 mon)',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'cnt_of_gst_filing_missed_l3m',
                title: 'Count of GST Filing Missed (Last 3 mon)',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'latest_gst_period',
                title: 'Latest GST Period',
                type: 'string',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'gst_business_name',
                title: 'GST Business Name',
                type: 'string',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
        ],
    },


]

const ConsumerBureauType = Object.freeze({
    coborrower: "cb",
    guarantor: "gua"
});

const getCamsCoBorrowerOrGuarantorAccordianSeed = (noOfConsumers = 0, type = ConsumerBureauType.coborrower) => {
  return Array(noOfConsumers).fill("").map((_, index) => {
    const suffix = type + `${index+1}`;
    const title = type === ConsumerBureauType.coborrower ? `Co-Borrower ${index+1}` : `Guarantor ${index+1}`;
    return  {
      title,
      data: [
          {
              field: `1+dpd_oustanding_perc_${suffix}`,
              title: '1+ DPD Outstanding Perc ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `30+dpd_oustanding_perc_${suffix}`,
              title: '30+ DPD Outstanding Perc ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `60+dpd_oustanding_perc_${suffix}`,
              title: '60+ DPD Outstanding Perc ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `90+dpd_oustanding_perc_${suffix}`,
              title: '90+ DPD Outstanding Perc ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `active_secured_loan_or_credit_card_${suffix}`,
              title: 'Active Secured Loan Or Credit Card ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `bureau_outstanding_loan_amt_${suffix}`,
              title: 'Bureau Outstanding Loan Amt ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `bureau_score_${suffix}`,
              title: 'Bureau Score ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `cnt_active_unsecured_loans_${suffix}`,
              title: 'Cnt Active Unsecured Loans ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `count_total_active_loans_${suffix}`,
              title: 'Count Total Active Loans ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `credit_card_settlement_amount_${suffix}`,
              title: 'Credit Card Settlement Amount ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `current_overdue_value_${suffix}`,
              title: 'Current Overdue Value ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_1_months_secured_${suffix}`,
              title: 'DPD In Last 1 Month Secured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_12_months_${suffix}`,
              title: 'DPD In Last 12 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_12_months_credit_card_${suffix}`,
              title: 'DPD In Last 12 Months Credit Card ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_12_months_secured_${suffix}`,
              title: 'DPD In Last 12 Months Secured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_12_months_unsecured_${suffix}`,
              title: 'DPD In Last 12 Months Unsecured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_24_months_${suffix}`,
              title: 'DPD In Last 24 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_24_months_secured_${suffix}`,
              title: 'DPD In Last 24 Months Secured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_24_months_unsecured_${suffix}`,
              title: 'DPD In Last 24 Months Unsecured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_3_months_${suffix}`,
              title: 'DPD In Last 3 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_3_months_credit_card_${suffix}`,
              title: 'DPD In Last 3 Months Credit Card ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_3_months_secured_${suffix}`,
              title: 'DPD In Last 3 Months Secured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_3_months_unsecured_${suffix}`,
              title: 'DPD In Last 3 Months Unsecured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_30_days_unsecured_${suffix}`,
              title: 'DPD In Last 30 Days Unsecured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_36_months_secured_${suffix}`,
              title: 'DPD In Last 36 Months Secured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_36_months_unsecured_${suffix}`,
              title: 'DPD In Last 36 Months Unsecured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_6_months_${suffix}`,
              title: 'DPD In Last 6 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_6_months_credit_card_${suffix}`,
              title: 'DPD In Last 6 Months Credit Card ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_6_months_secured_${suffix}`,
              title: 'DPD In Last 6 Months Secured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_6_months_unsecured_${suffix}`,
              title: 'DPD In Last 6 Months Unsecured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_9_months_${suffix}`,
              title: 'DPD In Last 9 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `enquiries_bureau_30_days_${suffix}`,
              title: 'Enquiries Bureau 30 Days ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `enquiries_in_last_1_months_unsecured_${suffix}`,
              title: 'Enquiries In Last 1 Months Unsecured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `enquiries_in_last_3_months_${suffix}`,
              title: 'Enquiries In Last 3 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `enquiries_in_last_3_months_unsecured_${suffix}`,
              title: 'Enquiries In Last 3 Months Unsecured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `enquiries_in_last_3_months_unsecured_excl_cc_${suffix}`,
              title: 'Enquiries In Last 3 Months Unsecured Excl CC ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `enquiries_in_last_30_days_unsecured_excl_cc_${suffix}`,
              title: 'Enquiries In Last 30 Days Unsecured Excl CC ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `enquiries_in_last_6_months_${suffix}`,
              title: 'Enquiries In Last 6 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `enquiries_in_last_6_months_unsecured_${suffix}`,
              title: 'Enquiries In Last 6 Months Unsecured ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `enquiries_in_last_6_months_unsecured_excl_cc_${suffix}`,
              title: 'Enquiries In Last 6 Months Unsecured Excl CC ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `loans_opened_last_12_months_${suffix}`,
              title: 'Loans Opened Last 12 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `loans_opened_last_12_months_unsecured_excl_cc_${suffix}`,
              title: 'Loans Opened Last 12 Months Unsecured Excl CC ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `loans_opened_last_3_months_${suffix}`,
              title: 'Loans Opened Last 3 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `loans_opened_last_3_months_unsecured_excl_cc_${suffix}`,
              title: 'Loans Opened Last 3 Months Unsecured Excl CC ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `loans_opened_last_6_months_${suffix}`,
              title: 'Loans Opened Last 6 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `loans_opened_last_6_months_unsecured_excl_cc_${suffix}`,
              title: 'Loans Opened Last 6 Months Unsecured Excl CC ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `ninety_plus_dpd_in_last_24_months_${suffix}`,
              title: 'Ninety Plus DPD In Last 24 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `suitfiled_amount_${suffix}`,
              title: 'Suitfiled Amount ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `suitfiled_flag_${suffix}`,
              title: 'Suitfiled Flag ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `sum_of_credit_balance_in_all_credit_cards_${suffix}`,
              title: 'Sum Of Credit Balance In All Credit Cards ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `total_overdues_in_cc_${suffix}`,
              title: 'Total Overdues In CC ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `unsecured_outstanding_loan_amt_${suffix}`,
              title: 'Unsecured Outstanding Loan Amt ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `written_off_settled_${suffix}`,
              title: 'Written Off Settled ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `written_off_settled_flag_${suffix}`,
              title: 'Written Off Settled Flag ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_36_months_${suffix}`,
              title: 'DPD In Last 36 Months ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `dpd_in_last_36_months_credit_card_${suffix}`,
              title: 'DPD In Last 36 Months Credit Card ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `curren_emi_${suffix}`,
              title: 'Curren Emi ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `max_emi_last_24m_${suffix}`,
              title: 'Max Emi Last 24m ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `max_emi_last_12m_${suffix}`,
              title: 'Max Emi Last 12m ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `emi_drop_next_6m_${suffix}`,
              title: 'Emi Drop Next 6m ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `SMA_Flag_last_12m_${suffix}`,
              title: 'SMA Flag Last 12m ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `SMA_Flag_last_24m_${suffix}`,
              title: 'SMA Flag Last 24m ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: `SMA_Flag_last_36m_${suffix}`,
              title: 'SMA Flag Last 36m ',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
      ],
    }
  })
}
export const getCamsConsumerBureau = (noOfCoBorrowers, noOfGuarantors) => {
  return [
    {
      title: 'Primary Applicant',
      data: [
          {
              field: '1+dpd_oustanding_perc_br',
              title: '1 + DPD Oustanding Perc',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: '30+dpd_oustanding_perc_br',
              title: '30+ DPD Oustanding Perc',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: '60+dpd_oustanding_perc_br',
              title: '60+ DPD Oustanding Perc',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: '90+dpd_oustanding_perc_br',
              title: '90+ DPD Oustanding Perc',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'active_secured_loan_or_credit_card_br',
              title: 'Active Secured Loan Or Credit Card',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'bureau_outstanding_loan_amt_br',
              title: 'Bureau Outstanding Loan Amt',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'bureau_score_br',
              title: 'Bureau Score',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'cnt_active_unsecured_loans_br',
              title: 'Cnt Active Unsecured Loans',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'count_total_active_loans_br',
              title: 'Count Total Active Loans',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'credit_card_settlement_amount_br',
              title: 'Credit Card Settlement Amount',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'current_overdue_value_br',
              title: 'Current Overdue Value',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_1_months_secured_br',
              title: 'DPD In Last 1 Month Secured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_12_months_br',
              title: 'DPD In Last 12 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_12_months_credit_card_br',
              title: 'DPD In Last 12 Months Credit Card',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_12_months_secured_br',
              title: 'DPD In Last 12 Months Secured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_12_months_unsecured_br',
              title: 'DPD In Last 12 Months Unsecured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_24_months_br',
              title: 'DPD In Last 24 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_24_months_secured_br',
              title: 'DPD In Last 24 Months Secured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_24_months_unsecured_br',
              title: 'DPD In Last 24 Months Unsecured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_3_months_br',
              title: 'DPD In Last 3 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_3_months_credit_card_br',
              title: 'DPD In Last 3 Months Credit Card',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_3_months_secured_br',
              title: 'DPD In Last 3 Months Secured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_3_months_unsecured_br',
              title: 'DPD In Last 3 Months Unsecured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_30_days_unsecured_br',
              title: 'DPD In Last 30 Days Unsecured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_36_months_secured_br',
              title: 'DPD In Last 36 Months Secured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_36_months_unsecured_br',
              title: 'DPD In Last 36 Months Unsecured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_6_months_br',
              title: 'DPD In Last 6 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_6_months_credit_card_br',
              title: 'DPD In Last 6 Months Credit Card',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_6_months_secured_br',
              title: 'DPD In Last 6 Months Secured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_6_months_unsecured_br',
              title: 'DPD In Last 6 Months Unsecured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_9_months_br',
              title: 'DPD In Last 9 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'enquiries_bureau_30_days_br',
              title: 'Enquiries Bureau 30 Days',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'enquiries_in_last_1_months_unsecured_br',
              title: 'Enquiries In Last 1 Months Unsecured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'enquiries_in_last_3_months_br',
              title: 'Enquiries In Last 3 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'enquiries_in_last_3_months_unsecured_br',
              title: 'Enquiries In Last 3 Months Unsecured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'enquiries_in_last_3_months_unsecured_excl_cc_br',
              title: 'Enquiries In Last 3 Months Unsecured Excl CC',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'enquiries_in_last_30_days_unsecured_excl_cc_br',
              title: 'Enquiries In Last 30 Days Unsecured Excl CC',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'enquiries_in_last_6_months_br',
              title: 'Enquiries In Last 6 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'enquiries_in_last_6_months_unsecured_br',
              title: 'Enquiries In Last 6 Months Unsecured',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'enquiries_in_last_6_months_unsecured_excl_cc_br',
              title: 'Enquiries In Last 6 Months Unsecured Excl CC',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'loans_opened_last_12_months_br',
              title: 'Loans Opened Last 12 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'loans_opened_last_12_months_unsecured_excl_cc_br',
              title: 'Loans Opened Last 12 Months Unsecured Excl CC',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'loans_opened_last_3_months_br',
              title: 'Loans Opened Last 3 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'loans_opened_last_3_months_unsecured_excl_cc_br',
              title: 'Loans Opened Last 3 Months Unsecured Excl CC',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'loans_opened_last_6_months_br',
              title: 'Loans Opened Last 6 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'loans_opened_last_6_months_unsecured_excl_cc_br',
              title: 'Loans Opened Last 6 Months Unsecured Excl CC',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'ninety_plus_dpd_in_last_24_months_br',
              title: 'Ninety Plus DPD In Last 24 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'suitfiled_amount_br',
              title: 'Suitfiled Amount',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'suitfiled_flag_br',
              title: 'Suitfiled Flag',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'sum_of_credit_balance_in_all_credit_cards_br',
              title: 'Sum Of Credit Balance In All Credit Cards',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'total_overdues_in_cc_br',
              title: 'Total Overdues In CC',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'unsecured_outstanding_loan_amt_br',
              title: 'Unsecured Outstanding Loan Amt',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'written_off_settled_br',
              title: 'Written Off Settled',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'written_off_settled_flag_br',
              title: 'Written Off Settled Flag',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_36_months_br',
              title: 'DPD In Last 36 Months',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'dpd_in_last_36_months_credit_card_br',
              title: 'DPD In Last 36 Months Credit Card',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'curren_emi_br',
              title: 'Curren Emi',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'max_emi_last_24m_br',
              title: 'Max Emi Last 24m',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'max_emi_last_12m_br',
              title: 'Max Emi Last 12m',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'emi_drop_next_6m_br',
              title: 'Emi Drop Next 6m',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'SMA_Flag_last_12m_br',
              title: 'SMA Flag Last 12m',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'SMA_Flag_last_24m_br',
              title: 'SMA Flag Last 24m',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
          {
              field: 'SMA_Flag_last_36m_br',
              title: 'SMA Flag Last 36m',
              type: 'number',
              validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
              isOptional: 'FALSE',
              isCommon: 'TRUE',
              initialValue: ""
          },
      ],
    },
    ...getCamsCoBorrowerOrGuarantorAccordianSeed(15, ConsumerBureauType.coborrower),
    ...getCamsCoBorrowerOrGuarantorAccordianSeed(5, ConsumerBureauType.guarantor),
  ]
}

export const CommercialBureau = [
    {
        title: 'Commercial Bureau',
        data: [
            {
                field: '1+dpd_oustanding_perc_com',
                title: '1+ DPD Oustanding Perc',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: "",
                value: 100
            },
            {
                field: '30+dpd_oustanding_perc_com',
                title: '30+ DPD Oustanding Perc',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: '60+dpd_oustanding_perc_com',
                title: '60+ DPD Oustanding Perc',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: '90+dpd_oustanding_perc_com',
                title: '90+ DPD Oustanding Perc',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cmr_score_com',
                title: 'Cmr Score',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'CC_OD_limit_com',
                title: 'CC OD Limit',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'CC_OD_overdue_com',
                title: 'CC OD Overdue',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'WC_Limit_com',
                title: 'WC Limit',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cnt_active_unsecured_loans_com',
                title: 'Cnt Active Unsecured Loans',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'count_total_active_loans_com',
                title: 'Count Total Active Loans',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'current_overdue_amount_com',
                title: 'Current Overdue Amount',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_1_months_secured_com',
                title: 'DPD In Last 1 Months Secured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_1_months_Unsecured_com',
                title: 'DPD In Last 1 Months Unsecured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_12_months_com',
                title: 'DPD In Last 12 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_12_months_secured_com',
                title: 'DPD In Last 12 Months Secured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_12_months_unsecured_com',
                title: 'DPD In Last 12 Months Unsecured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_24_months_com',
                title: 'DPD In Last 24 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_24_months_secured_com',
                title: 'DPD In Last 24 Months Secured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_24_months_unsecured_com',
                title: 'DPD In Last 24 Months Unsecured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_3_months_com',
                title: 'DPD In Last 3 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_3_months_secured_com',
                title: 'DPD In Last 3 Months Secured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_3_months_unsecured_com',
                title: 'DPD In Last 3 Months Unsecured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_36_months_com',
                title: 'DPD In Last 36 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_36_months_secured_com',
                title: 'DPD In Last 36 Months Secured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_36_months_unsecured_com',
                title: 'DPD In Last 36 Months Unsecured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_6_months_com',
                title: 'DPD In Last 6 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_6_months_secured_com',
                title: 'DPD In Last 6 Months Secured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'dpd_in_last_6_months_unsecured_com',
                title: 'DPD In Last 6 Months Unsecured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'enquiries_in_last_1_months_com',
                title: 'Enquiries In Last 1 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'enquiries_in_last_1_months_unsecured_com',
                title: 'Enquiries In Last 1 Months Unsecured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'enquiries_in_last_3_months_com',
                title: 'Enquiries In Last 3 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'enquiries_in_last_3_months_unsecured_com',
                title: 'Enquiries In Last 3 Months Unsecured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'enquiries_in_last_6_months_com',
                title: 'Enquiries In Last 6 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'enquiries_in_last_6_months_unsecured_com',
                title: 'Enquiries In Last 6 Months Unsecured',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'loans_opened_last_12_months_com',
                title: 'Loans Opened Last 12 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'loans_opened_last_3_months_com',
                title: 'Loans Opened Last 3 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'loans_opened_last_6_months_com',
                title: 'Loans Opened Last 6 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'outstanding_loan_amt_com',
                title: 'Outstanding Loan Amount',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'settlement_amount_com',
                title: 'Settlement Amount',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'suitfiled_amount_com',
                title: 'Suitfiled Amount',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'suitfiled_flag_com',
                title: 'Suitfiled Flag',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'unsecured_outstanding_loan_amt_com',
                title: 'Unsecured Outstanding Loan Amount',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'written_off_settled_amount_com',
                title: 'Written Off Settled Amount',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'written_off_settled_flag_com',
                title: 'Written Off Settled Flag',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'current_emi_com',
                title: 'Current EMI',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'SMA_Flag_last_12m_com',
                title: 'SMA Flag Last 12 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'SMA_Flag_last_24m_com',
                title: 'SMA Flag Last 24 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'SMA_Flag_last_36m_com',
                title: 'SMA Flag Last 36 Months',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'escore_com',
                title: 'Escore',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
        ],
    },
    {
        title: 'ITR',
        data: [
            {
                field: 'itr_turnover_fy1',
                title: 'ITR Turnover FY1',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'itr_turnover_fy2',
                title: 'ITR Turnover FY2',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'itr_turnover_fy3',
                title: 'ITR Turnover FY3',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'annual_expenditure_fy1',
                title: 'Annual Expenditure FY1',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'annual_expenditure_fy2',
                title: 'Annual Expenditure FY2',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'annual_expenditure_fy3',
                title: 'Annual Expenditure FY3',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'pbt_fy1',
                title: 'PBT FY1',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'pbt_fy2',
                title: 'PBT FY2',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'pbt_fy3',
                title: 'PBT FY3',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'tax_paid_fy1',
                title: 'Tax Paid FY1',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'tax_paid_fy2',
                title: 'Tax Paid FY2',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'tax_paid_fy3',
                title: 'Tax Paid FY3',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'filing_date_fy1',
                title: 'Filing Date FY1',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'is_original_return',
                title: 'Is Original Return',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
        ],
    },

    {
        title: 'Banking',
        data: [
            {
                field: 'average_balance',
                title: 'Average Balance',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'average_balance_last_1m',
                title: 'Average Balance (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'average_balance_last_3m',
                title: 'Average Balance (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'average_balance_last_6m',
                title: 'Average Balance (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'average_balance_last_12m',
                title: 'Average Balance (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_credits',
                title: 'Avg Monthly Credits',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_credits_last_1m',
                title: 'Avg Monthly Credits (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_credits_last_3m',
                title: 'Avg Monthly Credits (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_credits_last_6m',
                title: 'Avg Monthly Credits (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_credits_last_12m',
                title: 'Avg Monthly Credits (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_debits',
                title: 'Avg Monthly Debits',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_debits_last_1m',
                title: 'Avg Monthly Debits (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_debits_last_3m',
                title: 'Avg Monthly Debits (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_debits_last_6m',
                title: 'Avg Monthly Debits (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'avg_monthly_debits_last_12m',
                title: 'Avg Monthly Debits (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_credit_counts_last_1m',
                title: 'Cash Credit Counts (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_credit_counts_last_3m',
                title: 'Cash Credit Counts (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_credit_counts_last_6m',
                title: 'Cash Credit Counts (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_credit_counts_last_12m',
                title: 'Cash Credit Counts (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_credits_count',
                title: 'Cash Credits Count',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_withdrawals_count',
                title: 'Cash Withdrawals Count',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_withdrawals_count_last_1m',
                title: 'Cash Withdrawals Count (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_withdrawals_count_last_3m',
                title: 'Cash Withdrawals Count (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_withdrawals_count_last_6m',
                title: 'Cash Withdrawals Count (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'cash_withdrawals_count_last_12m',
                title: 'Cash Withdrawals Count (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'current_balance',
                title: 'Current Balance',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc148',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'deposit_count',
                title: 'Deposit Count',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc149',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'deposit_count_last_1m',
                title: 'Deposit Count (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc150',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'deposit_count_last_3m',
                title: 'Deposit Count (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc151',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'deposit_count_last_6m',
                title: 'Deposit Count (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc152',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'deposit_count_last_12m',
                title: 'Deposit Count (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc153',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'end_date',
                title: 'End Date',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc154',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'max_balance',
                title: 'Max Balance',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc155',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'max_balance_last_1m',
                title: 'Max Balance (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc156',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'max_balance_last_3m',
                title: 'Max Balance (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc157',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'max_balance_last_6m',
                title: 'Max Balance (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc158',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'max_balance_last_12m',
                title: 'Max Balance (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc159',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'min_balance',
                title: 'Min Balance',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc160',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'min_balance_last_1m',
                title: 'Min Balance (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc161',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'min_balance_last_3m',
                title: 'Min Balance (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc162',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'min_balance_last_6m',
                title: 'Min Balance (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc163',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'min_balance_last_12m',
                title: 'Min Balance (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc164',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'monthly_credit_median_last_1m',
                title: 'Monthly Credit Median (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc165',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'monthly_credit_median_last_3m',
                title: 'Monthly Credit Median (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc166',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'monthly_credit_median_last_6m',
                title: 'Monthly Credit Median (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc167',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'monthly_credit_median_last_12m',
                title: 'Monthly Credit Median (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc168',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'name',
                title: 'Name',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc169',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'opening_balance',
                title: 'Opening Balance',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc170',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounce_amount',
                title: 'Inward Bounce Amount',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc171',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounce_amount_last_1m',
                title: 'Inward Bounce Amount (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc172',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounce_amount_last_3m',
                title: 'Inward Bounce Amount (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc173',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounce_amount_last_6m',
                title: 'Inward Bounce Amount (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc174',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounce_amount_last_12m',
                title: 'Inward Bounce Amount (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc175',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounces_count',
                title: 'Inward Bounces Count',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc176',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounces_count_last_1m',
                title: 'Inward Bounces Count (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc177',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounces_count_last_3m',
                title: 'Inward Bounces Count (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc178',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounces_count_last_6m',
                title: 'Inward Bounces Count (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc179',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'inward_bounces_count_last_12m',
                title: 'Inward Bounces Count (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc180',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'pos_upi',
                title: 'Pos Upi',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc181',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'tot_pos_upi_last_1m',
                title: 'Tot Pos Upi (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc182',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'tot_pos_upi_last_3m',
                title: 'Tot Pos Upi (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc183',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'tot_pos_upi_last_6m',
                title: 'Tot Pos Upi (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc184',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'tot_pos_upi_last_12m',
                title: 'Tot Pos Upi (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc185',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'regular_debits_count',
                title: 'Regular Debits Count',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc186',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'regular_debits_count_last_1m',
                title: 'Regular Debits Count (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc187',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'regular_debits_count_last_3m',
                title: 'Regular Debits Count (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc188',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'regular_debits_count_last_6m',
                title: 'Regular Debits Count (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc189',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'regular_debits_count_last_12m',
                title: 'Regular Debits Count (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc190',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'salary_dates',
                title: 'Salary Dates',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc191',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'salary_flag',
                title: 'Salary Flag',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc192',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'stable_monthly_inflow',
                title: 'Stable Monthly Inflow',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc193',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'start_date',
                title: 'Start Date',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc194',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_cash_withdrawal',
                title: 'Total Cash Withdrawal',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc195',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_cash_withdrawal_last_1m',
                title: 'Total Cash Withdrawal (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc196',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_cash_withdrawal_last_3m',
                title: 'Total Cash Withdrawal (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc197',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_cash_withdrawal_last_6m',
                title: 'Total Cash Withdrawal (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc198',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_cash_withdrawal_last_12m',
                title: 'Total Cash Withdrawal (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc199',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_credit_last_1m',
                title: 'Total Credit (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc200',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_credit_last_3m',
                title: 'Total Credit (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc201',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_credit_last_6m',
                title: 'Total Credit (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc202',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_credit_last_12m',
                title: 'Total Credit (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc203',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_credits',
                title: 'Total Credits',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc204',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debit_to_credit_ratio',
                title: 'Total Debit To Credit Ratio',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc205',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debit_to_credit_ratio_last_1m',
                title: 'Total Debit To Credit Ratio (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc206',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debit_to_credit_ratio_last_3m',
                title: 'Total Debit To Credit Ratio (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc207',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debit_to_credit_ratio_last_6m',
                title: 'Total Debit To Credit Ratio (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc208',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debit_to_credit_ratio_last_12m',
                title: 'Total Debit To Credit Ratio (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc209',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits',
                title: 'Total Debits',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc210',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits_count',
                title: 'Total Debits Count',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc211',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits_count_last_1m',
                title: 'Total Debits Count (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc212',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits_count_last_3m',
                title: 'Total Debits Count (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc213',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits_count_last_6m',
                title: 'Total Debits Count (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc214',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits_count_last_12m',
                title: 'Total Debits Count (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc215',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits_last_1m',
                title: 'Total Debits (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc216',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits_last_3m',
                title: 'Total Debits (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc217',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits_last_6m',
                title: 'Total Debits (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc218',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_debits_last_12m',
                title: 'Total Debits (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc219',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_deposit',
                title: 'Total Deposit',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc220',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_deposit_last_1m',
                title: 'Total Deposit (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc221',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_deposit_last_3m',
                title: 'Total Deposit (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc222',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_deposit_last_6m',
                title: 'Total Deposit (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc223',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_deposit_last_12m',
                title: 'Total Deposit (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc224',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_negative_incedent_count',
                title: 'Total Negative Incedent Count',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc225',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_negative_incedent_count_last_1m',
                title: 'Total Negative Incedent Count (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc226',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_negative_incedent_count_last_3m',
                title: 'Total Negative Incedent Count (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc227',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_negative_incedent_count_last_6m',
                title: 'Total Negative Incedent Count (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc228',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_negative_incedent_count_last_12m',
                title: 'Total Negative Incedent Count (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc229',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'latest_salary',
                title: 'Latest Salary',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc230',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_surplus',
                title: 'Total Surplus',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc231',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_surplus_last_1m',
                title: 'Total Surplus (Last 1 month)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc232',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_surplus_last_3m',
                title: 'Total Surplus (Last 3 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc233',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_surplus_last_6m',
                title: 'Total Surplus (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc234',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'total_surplus_last_12m',
                title: 'Total Surplus (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc235',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'transaction_count',
                title: 'Transaction Count',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc236',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'account_number',
                title: 'Account Number',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc237',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'account_type',
                title: 'Account Type',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc238',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'bank_name',
                title: 'Bank Name',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc239',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'min_avg_mthly_bal_l6m',
                title: 'Minimum Average Monthly Balance (Last 6 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc240',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'min_avg_mthly_bal_l12m',
                title: 'Minimum Average Monthly Balance (Last 12 months)',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc241',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
        ],
    },
    {
        title: 'Udyam',
        data: [
            {
                field: 'loan_app_id',
                title: 'Loan App Id',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_addr',
                title: 'Address',
                type: 'number',
                validationmsg: 'Please enter Address',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_block',
                title: 'Block',
                type: 'string',
                validationmsg: 'Please enter Block',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_building',
                title: 'Building',
                type: 'string',
                validationmsg: 'Please enter Building',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_city',
                title: 'City',
                type: 'string',
                validationmsg: 'Please enter City',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_commencement_dt',
                title: 'Commencement Date (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Commencement Date',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_district',
                title: 'District',
                type: 'string',
                validationmsg: 'Please enter District',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_ent_activity',
                title: 'Entity Activity (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Entity Activity',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_ent_type',
                title: 'Entity Type',
                type: 'string',
                validationmsg: 'Please enter Entity Type',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_flat',
                title: 'Flat',
                type: 'string',
                validationmsg: 'Please enter Flat',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_gender',
                title: 'Gender',
                type: 'string',
                validationmsg: 'Please enter Gender',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_incorporation_dt',
                title: 'Incorporation Date (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Incorporation Date',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_investment',
                title: 'Investment (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Investment',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_name_of_ent',
                title: 'Name Of Entity (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Name Of Entity',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_nic',
                title: 'Nic (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Nic',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_org_type',
                title: 'Org Type (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Org Type',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_owner_name',
                title: 'Owner Name (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Owner Name',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_pin',
                title: 'Pin (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Pin',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_reg_no',
                title: 'Reg No (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Reg No',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_road',
                title: 'Road',
                type: 'string',
                validationmsg: 'Please enter Road',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_state',
                title: 'State',
                type: 'string',
                validationmsg: 'Please enter State',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_turnover',
                title: 'Turnover (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Turnover',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_udym_reg_dt',
                title: 'Udyam Reg Date (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Udym Reg Date',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_unit_name',
                title: 'Unit Name',
                type: 'string',
                validationmsg: 'Please enter Unit Name',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_village_town',
                title: 'Village Town',
                type: 'string',
                validationmsg: 'Please enter Village Town',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'urc_pan',
                title: 'Udyam PAN (mandatory)',
                type: 'string',
                validationmsg: 'Please enter Udyam PAN',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
        ]
    },
    {
        title: 'Crime Check',
        data: [
            {
                field: 'tot_num_cases',
                title: 'Total Number Of Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_disposed_cases',
                title: 'Number Of Disposed Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc124',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_pending_cases',
                title: 'Number Of Pending Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc125',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_convicted_cases',
                title: 'Number Of Convicted Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc126',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_pend_civil_cases',
                title: 'Number Of Pending Civil Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc127',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_pend_criminal_cases',
                title: 'Number Of Pending Criminal Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc128',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_pend_tribunal_cases',
                title: 'Number Of Pending Tribunal Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc129',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_pend_negotiable_instr_act_cases',
                title: 'Number Of Pending Negotiable Instr Act Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc130',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_pend_traffic_rule_cases',
                title: 'Number Of Pending Traffic Rule Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc131',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_pend_arbitration_cases',
                title: 'Number Of Pending Arbitration Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc132',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_disp_civil_cases',
                title: 'Number Of Disposed Civil Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc133',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_disp_criminal_cases',
                title: 'Number Of Disposed Criminal Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc134',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_disp_tribunal_cases',
                title: 'Number Of Disposed Tribunal Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc135',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_disp_negotiable_instr_act_cases',
                title: 'Number Of Disposed Negotiable Instr Act Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc136',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_disp_traffic_rule_cases',
                title: 'Number Of Disposed Traffic Rule Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc137',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_disp_arbitration_cases',
                title: 'Number Of Disposed Arbitration Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc138',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_conv_civil_cases',
                title: 'Number Of Convicted Civil Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc139',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_conv_criminal_cases',
                title: 'Number Of Convicted Criminal Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc140',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_conv_tribunal_cases',
                title: 'Number Of Convicted Tribunal Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc141',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_conv_negotiable_instr_act_cases',
                title: 'Number Of Convicted Negotiable Instr Act Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc142',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_conv_traffic_rule_cases',
                title: 'Number Of Convicted Traffic Rule Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc143',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'num_conv_arbitration_cases',
                title: 'Number Of Convicted Arbitration Cases',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc144',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'nclt_movement_flag',
                title: 'NCLT Movement Flag',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc145',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'ibbi_flag',
                title: 'IBBI Flag',
                type: 'string',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc146',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'case_description',
                title: 'Case Description',
                type: 'string', // object
                validationmsg: 'Please enter Partner Loan App Id Ex:abc147',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
        ],
    },

];

export const FinancialData = [
    {
        title: 'Current Year',
        data: [
            {
                field: 'share_capital_1',
                title: 'Share Capital',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'reserves_surplus_1',
                title: 'Reserves Surplus',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'other_equity_1',
                title: 'Other Equity',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_equity_1',
                title: 'Total Equity',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'long_term_borrowings_1',
                title: 'Long Term Borrowings',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_def_tax_liabilities_1',
                title: 'Net Def Tax Liabilities',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_long_term_liabilities_1',
                title: 'Other Long Term Liabilities',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'long_term_provisions_1',
                title: 'Long Term Provisions',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_long_term_liabilities_1',
                title: 'Total Long Term Liabilities',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'short_term_borrowings_1',
                title: 'Short Term Borrowings',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'trade_payables_1',
                title: 'Trade Payables',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_current_liabilities_1',
                title: 'Other Current Liabilities',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'short_term_provisions_1',
                title: 'Short Term Provisions',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_current_liabilities_1',
                title: 'Total Current Liabilities',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_equity_liabilities_1',
                title: 'Total Equity Liabilities',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_fixed_assets_1',
                title: 'Net Fixed Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'tangible_assets_1',
                title: 'Tangible Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'intangible_assets_1',
                title: 'Intangible Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'tot_net_fixed_assets_1',
                title: 'Tot Net Fixed Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'capital_wip_1',
                title: 'Capital Wip',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_non_current_assets_1',
                title: 'Other Non Current Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'non_current_investments_1',
                title: 'Non Current Investments',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_def_tax_assets_1',
                title: 'Net Def Tax Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'long_term_loans_advances_1',
                title: 'Long Term Loans Advances',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_non_current_assets_1',
                title: 'Other Non Current Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'tot_other_non_curr_assets_1',
                title: 'Tot Other Non Curr Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'current_assets_1',
                title: 'Current Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'current_investments_1',
                title: 'Current Investments',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'inventories_1',
                title: 'Inventories',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'trade_receivables_1',
                title: 'Trade Receivables',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'cash_bank_balances_1',
                title: 'Cash Bank Balances',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'short_term_loans_advances_1',
                title: 'Short Term Loans Advances',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_current_assets_1',
                title: 'Other Current Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_current_assets_1',
                title: 'Total Current Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_assets_1',
                title: 'Total Assets',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_revenue_1',
                title: 'Net Revenue',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'operating_cost_1',
                title: 'Operating Cost',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'cost_materials_consumed_1',
                title: 'Cost Materials Consumed',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'purchases_stock_in_trade_1',
                title: 'Purchases Stock In Trade',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'changes_inventories_finished_goods_1',
                title: 'Changes Inventories Finished Goods',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'employee_benefit_expense_1',
                title: 'Employee Benefit Expense',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_expenses_1',
                title: 'Other Expenses',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_operating_cost_1',
                title: 'Total Operating Cost',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'operating_profit_1',
                title: 'Operating Profit',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_income_1',
                title: 'Other Income',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'depreciation_amortization_expense_1',
                title: 'Depreciation Amortization Expense',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_before_interest_tax_1',
                title: 'Profit Before Interest Tax',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'finance_costs_1',
                title: 'Finance Costs',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_before_tax_exceptional_items_1',
                title: 'Profit Before Tax Exceptional Items',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'exceptional_items_before_tax_1',
                title: 'Exceptional Items Before Tax',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_before_tax_1',
                title: 'Profit Before Tax',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'income_tax_1',
                title: 'Income Tax',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_period_continuing_operations_1',
                title: 'Profit Period Continuing Operations',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_discontinuing_operations_tax_1',
                title: 'Profit Discontinuing Operations Tax',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_for_period_1',
                title: 'Profit For Period',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
        ],
    },
    {
        title: 'Penultimate Year',
        data: [
            {
                field: 'share_capital_2',
                title: 'Share Capital ',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'reserves_surplus_2',
                title: 'Reserves Surplus ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'other_equity_2',
                title: 'Other Equity ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_equity_2',
                title: 'Total Equity ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'long_term_borrowings_2',
                title: 'Long Term Borrowings ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_def_tax_liabilities_2',
                title: 'Net Def Tax Liabilities ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_long_term_liabilities_2',
                title: 'Other Long Term Liabilities ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'long_term_provisions_2',
                title: 'Long Term Provisions ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_long_term_liabilities_2',
                title: 'Total Long Term Liabilities ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'short_term_borrowings_2',
                title: 'Short Term Borrowings ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'trade_payables_2',
                title: 'Trade Payables ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_current_liabilities_2',
                title: 'Other Current Liabilities ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'short_term_provisions_2',
                title: 'Short Term Provisions ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_current_liabilities_2',
                title: 'Total Current Liabilities ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_equity_liabilities_2',
                title: 'Total Equity Liabilities ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_fixed_assets_2',
                title: 'Net Fixed Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'tangible_assets_2',
                title: 'Tangible Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'intangible_assets_2',
                title: 'Intangible Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'tot_net_fixed_assets_2',
                title: 'Tot Net Fixed Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'capital_wip_2',
                title: 'Capital Wip ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_non_current_assets_2',
                title: 'Other Non Current Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'non_current_investments_2',
                title: 'Non Current Investments ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_def_tax_assets_2',
                title: 'Net Def Tax Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'long_term_loans_advances_2',
                title: 'Long Term Loans Advances ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_non_current_assets_2',
                title: 'Other Non Current Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'tot_other_non_curr_assets_2',
                title: 'Tot Other Non Curr Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'current_assets_2',
                title: 'Current Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'current_investments_2',
                title: 'Current Investments ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'inventories_2',
                title: 'Inventories ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'trade_receivables_2',
                title: 'Trade Receivables ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'cash_bank_balances_2',
                title: 'Cash Bank Balances ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'short_term_loans_advances_2',
                title: 'Short Term Loans Advances ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_current_assets_2',
                title: 'Other Current Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_current_assets_2',
                title: 'Total Current Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_assets_2',
                title: 'Total Assets ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_revenue_2',
                title: 'Net Revenue ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'operating_cost_2',
                title: 'Operating Cost ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'cost_materials_consumed_2',
                title: 'Cost Materials Consumed ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'purchases_stock_in_trade_2',
                title: 'Purchases Stock In Trade ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'changes_inventories_finished_goods_2',
                title: 'Changes Inventories Finished Goods ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'employee_benefit_expense_2',
                title: 'Employee Benefit Expense ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_expenses_2',
                title: 'Other Expenses ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_operating_cost_2',
                title: 'Total Operating Cost ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'operating_profit_2',
                title: 'Operating Profit ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_income_2',
                title: 'Other Income ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'depreciation_amortization_expense_2',
                title: 'Depreciation Amortization Expense ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_before_interest_tax_2',
                title: 'Profit Before Interest Tax ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'finance_costs_2',
                title: 'Finance Costs ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_before_tax_exceptional_items_2',
                title: 'Profit Before Tax Exceptional Items ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'exceptional_items_before_tax_2',
                title: 'Exceptional Items Before Tax ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_before_tax_2',
                title: 'Profit Before Tax ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'income_tax_2',
                title: 'Income Tax ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_period_continuing_operations_2',
                title: 'Profit Period Continuing Operations ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_discontinuing_operations_tax_2',
                title: 'Profit Discontinuing Operations Tax ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_for_period_2',
                title: 'Profit For Period ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
        ],
    },

    {
        title: 'Antepenultimate Year',
        data: [
            {
                field: 'share_capital_3',
                title: 'Share Capital   ',
                type: 'number',
                validationmsg: 'Please enter Partner Loan App Id Ex:abc123',
                isOptional: 'FALSE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'reserves_surplus_3',
                title: 'Reserves Surplus   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
            {
                field: 'other_equity_3',
                title: 'Other Equity   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_equity_3',
                title: 'Total Equity   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'long_term_borrowings_3',
                title: 'Long Term Borrowings   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_def_tax_liabilities_3',
                title: 'Net Def Tax Liabilities   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_long_term_liabilities_3',
                title: 'Other Long Term Liabilities   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'long_term_provisions_3',
                title: 'Long Term Provisions',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_long_term_liabilities_3',
                title: 'Total Long Term Liabilities   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'short_term_borrowings_3',
                title: 'Short Term Borrowings   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'trade_payables_3',
                title: 'Trade Payables   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_current_liabilities_3',
                title: 'Other Current Liabilities   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'short_term_provisions_3',
                title: 'Short Term Provisions   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_current_liabilities_3',
                title: 'Total Current Liabilities   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_equity_liabilities_3',
                title: 'Total Equity Liabilities   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_fixed_assets_3',
                title: 'Net Fixed Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'tangible_assets_3',
                title: 'Tangible Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'intangible_assets_3',
                title: 'Intangible Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'tot_net_fixed_assets_3',
                title: 'Tot Net Fixed Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'capital_wip_3',
                title: 'Capital Wip   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_non_current_assets_3',
                title: 'Other Non Current Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'non_current_investments_3',
                title: 'Non Current Investments   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_def_tax_assets_3',
                title: 'Net Def Tax Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'long_term_loans_advances_3',
                title: 'Long Term Loans Advances   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_non_current_assets_3',
                title: 'Other Non Current Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'tot_other_non_curr_assets_3',
                title: 'Tot Other Non Curr Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'current_assets_3',
                title: 'Current Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'current_investments_3',
                title: 'Current Investments   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'inventories_3',
                title: 'Inventories   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'trade_receivables_3',
                title: 'Trade Receivables   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'cash_bank_balances_3',
                title: 'Cash Bank Balances   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'short_term_loans_advances_3',
                title: 'Short Term Loans Advances   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_current_assets_3',
                title: 'Other Current Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_current_assets_3',
                title: 'Total Current Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_assets_3',
                title: 'Total Assets   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'net_revenue_3',
                title: 'Net Revenue   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'operating_cost_3',
                title: 'Operating Cost   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'cost_materials_consumed_3',
                title: 'Cost Materials Consumed   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'purchases_stock_in_trade_3',
                title: 'Purchases Stock In Trade   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'changes_inventories_finished_goods_3',
                title: 'Changes Inventories Finished Goods   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'employee_benefit_expense_3',
                title: 'Employee Benefit Expense   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_expenses_3',
                title: 'Other Expenses   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'total_operating_cost_3',
                title: 'Total Operating Cost   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'operating_profit_3',
                title: 'Operating Profit   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'other_income_3',
                title: 'Other Income   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'depreciation_amortization_expense_3',
                title: 'Depreciation Amortization Expense   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_before_interest_tax_3',
                title: 'Profit Before Interest Tax   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'finance_costs_3',
                title: 'Finance Costs   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_before_tax_exceptional_items_3',
                title: 'Profit Before Tax Exceptional Items   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'exceptional_items_before_tax_3',
                title: 'Exceptional Items Before Tax   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_before_tax_3',
                title: 'Profit Before Tax   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'income_tax_3',
                title: 'Income Tax   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_period_continuing_operations_3',
                title: 'Profit Period Continuing Operations   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_discontinuing_operations_tax_3',
                title: 'Profit Discontinuing Operations Tax   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },

            {
                field: 'profit_for_period_3',
                title: 'Profit For Period   ',
                type: 'number',
                validationmsg: 'Please enter Partner Borrower Id Ex:jsssk888',
                isOptional: 'FALSE',
                checked: 'TRUE',
                isCommon: 'TRUE',
                initialValue: ""
            },
        ],
    },
];


