import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import offerImage from '../../assets/images/Offer_image.svg';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import './OfferGenerate.style.css';
import useDimensions from '../../../hooks/useDimensions';
import { setObjectKeysToDefault } from '../../../util/helper';
import { offerGenerateJson } from './offerGenerateJson';
import Button from 'react-sdk/dist/components/Button';
import { LeadStatus } from '../../config/LeadStatus';
import { LeadNewStatus } from '../../config/LeadStatus';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import { Alert_Type, Error_Message, Field_Label, LEAD_STATUS, USER_ROLES, LEAD_INFO_MESSAGES } from '../../constants/string_constants';
import { storedList } from 'util/localstorage';
import { useDispatch } from 'react-redux';
import { updateLeadDetailsWatcher } from '../../actions/lead.action'
import SlidingAlert from '../SlidingAlert/SlidingAlert';
import { checkAccessTags } from "../../../util/uam";
import { ActionPopup } from '../ActionPopup/ActionPopup.component';
import { COMMENT_FIELDS } from '../../views/leads/LeadReview/fields';
import SuccessIcon from '../../../assets/img/successIcon.svg';
import InfoIcon from '../../assets/images/info-circle (3) (1).svg';
import CustomButton from "react-sdk/dist/components/Button/Button";

const offerFormDetails = offerGenerateJson();

export const OfferGenerate = (props) => {
    const {
        customStyle,
        loanAppId,
        leadStatus,
        heading = 'Offer Generated',
        handlePopupVisibility,
        companyId,
        productId,
        setNavState,
        setIsRejected,
        offerDetails,
        isPartnerView,
        updateOffer
    } = props;
    const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
    const [offerError, setOfferError] = React.useState({});
    const [isApproved, setIsApproved] = React.useState(false);
    const [amendOffer, setAmendOffer] = React.useState(false);
    const [loanAmount, setLoanAmount] = React.useState("");
    const [tenure, setTenure] = React.useState("");
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [interestRate, setInterestRate] = React.useState("")
    const { innerWidth, innerHeight } = useDimensions();
    const [openPopup, setOpenPopup] = React.useState(false);
    const styles = useStyles({ innerWidth, innerHeight });
    const dispatch = useDispatch();
    const history = useHistory();
    const [commentFields, setCommentFields] = useState(COMMENT_FIELDS);
    const [title, setTitle] = useState('Add Comment');
    const [type, setType] = useState('addComment');
    const commentDataDefault = setObjectKeysToDefault(COMMENT_FIELDS);
    const [data, setData] = useState({ ...commentDataDefault, remarkReasons: [] });
    const [dataErrors, setDataErrors] = useState(commentDataDefault);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentSectionName, setCurrentSectionName] = useState('primary');
    const [popupMessage, setPopupMessage] = useState(Field_Label.APPROVE_CONFIRMATION_POPUP);
    const [popupTitle, setPopupTitle] = useState(Field_Label.ACCEPT_OFFER);
    const [infoMessage, setInfoMessage] = useState('');
    const [riskInfoMessage, setRiskInfoMessage] = useState()
    const [userRole, setUserRole] = useState(USER_ROLES.PARTNER);
    const [isApproveDisabled, setIsApproveDisabled] = useState(true);
    const [isAmendDisabled, setIsAmendDisabled] = useState(true);
    const [isRequestAnUpdateDisabled, setIsRequestAnUpdateDisabled] = useState(true);
    const [showSearchButton, setShowSearchButton] = useState(false);
    const riskDeviation = ['D1', 'D2', 'D3'];
    const riskAndCreditDeviation = ['D5', 'D6', 'D7']


    const customStyleButton = {
        fontFamily: 'Montserrat-Regular',
        fontSize: '16px',
        padding: '8px 24px',
        fontWeight: 600,
        lineHeight: '24px',
        border: '1px solid #134CDE',
        gap: '10px',
        height: '40px',
        width: 'max-content',
        borderRadius: '40px',
        gap: '10px',
        backgroundColor: "#fff",
    };

    const customAmendButtonStyle = {
        fontFamily: 'Montserrat-Regular',
        fontSize: '16px',
        padding: '8px 24px',
        fontWeight: 600,
        lineHeight: '24px',
        border: '1px solid #134CDE',
        gap: '10px',
        height: '40px',
        width: 'max-content',
        borderRadius: '40px',
        gap: '10px',
        background: "linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%)",
    };

    const customRejectButtonStyle = {
        fontFamily: 'Montserrat-Regular',
        fontSize: '16px',
        padding: '8px 24px',
        fontWeight: 600,
        lineHeight: '24px',
        border: '1px solid #134CDE',
        height: '40px',
        width: 'max-content',
        borderRadius: '40px',
        gap: '10px',
        border: '1px solid #CC0000',
        background: 'var(--neutrals-neutral-30, #FFFFFF)',
        color: '#CC0000',
    }

    useEffect(() => {
        const user = storedList("user");
        if (user?.role_metrix && user?.role_metrix.length > 0) {
            const isRiskUser = user.role_metrix.some(userInfo => { return userInfo.title.toLowerCase() === 'risk manager' });
            const isCreditUser = user.role_metrix.some(userInfo => { return userInfo.title.toLowerCase() === 'credit manager' });
            isRiskUser && setUserRole(USER_ROLES.RISK);
            isCreditUser && setUserRole(USER_ROLES.CREDIT);
        }
    },[]);

    //To handle the buttons. 
    useEffect(() => {
        if (leadStatus == LeadStatus.offer_generated.value) {
            //#region [Button handlings]
            setIsApproveDisabled(false);
            setIsAmendDisabled(false)
            setIsRequestAnUpdateDisabled(true);
            setIsApproved(false)    //To make the mark as final offer visible.
            setInfoMessage(LEAD_INFO_MESSAGES.OFFER_GENERATED);
            //#endregion
        } else if (leadStatus == LeadStatus.offer_deviation.value) {
            if (offerDetails?.deviation_cat != 'D4') {
                if (userRole === USER_ROLES.CREDIT) {
                    //#region [Button handlings]
                    setIsApproveDisabled(offerDetails?.is_risk_resolved && riskAndCreditDeviation.includes(offerDetails?.deviation_cat) ? false : true);
                    setIsAmendDisabled(offerDetails?.is_risk_resolved ? false : true)
                    setIsRequestAnUpdateDisabled(offerDetails?.is_risk_resolved && riskAndCreditDeviation.includes(offerDetails?.deviation_cat) ? false : true);
                    setIsApproved(!offerDetails?.is_risk_resolved || riskAndCreditDeviation.includes(offerDetails?.deviation_cat) ? false : true)    //To make the mark as final offer visible.
                    //#endregion
                    //#region [Info Messages]
                    if ((riskDeviation.includes(offerDetails?.deviation_cat) || riskAndCreditDeviation.includes(offerDetails?.deviation_cat)) && offerDetails?.is_risk_resolved) {
                        setInfoMessage(LEAD_INFO_MESSAGES.RISK_RESOLVED);
                    } else if (riskDeviation.includes(offerDetails?.deviation_cat)) {
                        console.log("role", true)
                        setInfoMessage(LEAD_INFO_MESSAGES.RISK);
                    } else if (riskAndCreditDeviation.includes(offerDetails?.deviation_cat)) {
                        setInfoMessage(LEAD_INFO_MESSAGES.RISK_AND_CREDIT);
                    }
                    //#endregion
                } else if (userRole === USER_ROLES.RISK) {
                    setIsApproveDisabled(false)
                    setIsAmendDisabled(false)
                    setIsRequestAnUpdateDisabled(false)
                    //setIsMarkAsFinalOfferDisabled(false)
                } 
            } else {
                //D4
                setIsApproveDisabled(false);
                setIsAmendDisabled(false)
                setIsRequestAnUpdateDisabled(false);
                setIsApproved(false)    //To make the mark as final offer visible.
                setInfoMessage(LEAD_INFO_MESSAGES.CREDIT_USER_DEVIATION);
            }
        } else if (leadStatus == LeadStatus.pending.value && offerDetails?.deviation_cat === 'D4') {
            //Need to update the below code. 
            setInfoMessage(LEAD_INFO_MESSAGES.CREDIT_USER_DEVIATION);
        } else if (leadStatus == LeadStatus.rejected.value) {
            //Need to show the Rejected UI.
        } else if (leadStatus === LeadStatus.new.value) {
            setShowSearchButton(true);
        }
    }, [offerDetails, leadStatus, userRole]);

    const setInitialOfferDetails = (offerDetails) => {
        setLoanAmount(offerDetails?.offered_amount);
        setTenure(offerDetails?.tenure);
        setInterestRate(offerDetails?.offered_int_rate)
    }

    useEffect(() => {
        setInitialOfferDetails(offerDetails)
        handleCancel()
    }, [offerDetails])


    const handleUpdate = () => {
        handlePopupVisibility('Request an Update', true)
    };

    const handleClose = () => {
        handlePopupVisibility('', false)
    };

    const handleAmend = () => {
        setAmendOffer(true);
    }

    const handlPopupClose = () => {
        setOpenPopup(false);
    };

    const handlePopupSubmitHandler = () => {
        if (type == "reject") {
            handleApproveReject();
        } else {
            handleConfirmed();
        }
    }

    const handleConfirmed = () => {
        const payload = {
            loanAppId: loanAppId,
            companyId: companyId,
            productId: productId,
            user: user._id,
            status: LeadStatus.new.value,
            remarks: LeadStatus.new.label,
        };
        new Promise((resolve, reject) => {
            dispatch(updateLeadDetailsWatcher(payload, resolve, reject));
        })
            .then((response) => {
                setOpenPopup(false);
                showAlert(response?.message, 'success');
                setTimeout(() => {
                    history.push(`/admin/lap/loans/loan_creation/${loanAppId}/`);
                }, 3000);
            })
            .catch((error) => {
                showAlert(error?.response?.data?.message, 'error');
            });
    };

    const renderConfirmationPopup = () => {
        return (
            <>
                <FormPopup customStyles={{ width: "425px", top: "55%" }} heading={popupTitle} onClose={handleClose} customHeaderStyle={{ paddingLeft: "5%", fontSize: "20px" }} isCancel={true} isOpen={openPopup} >
                    <div className="confirmation-popup-text-style" style={{ fontSize: '16px' }}>
                        {popupMessage}
                    </div>
                    <div style={{ display: 'flex', marginTop: '8%' }} className='confirm-popup-button-container'>
                        <Button label={Field_Label.NO} buttonType="secondary" onClick={handlPopupClose} customStyle={styles.customButton1} />
                        <Button label={Field_Label.YES} buttonType="primary" onClick={handlePopupSubmitHandler} customStyle={styles.customButton2} />
                    </div>
                </FormPopup>
            </>
        )
    }


    const handleCancel = () => {
        setInitialOfferDetails(offerDetails)
        setAmendOffer(false);
    }

    const handleReset = () => {
        setInitialOfferDetails(offerDetails)
    }

    const handleSubmit = () => {
        const updatedOfferData = {
            offered_amount: loanAmount,
            offered_int_rate: interestRate,
            tenure
        }
        const isROIChange = offerDetails?.tenure !== tenure || offerDetails?.offered_int_rate !== interestRate
        updateOffer({ offerData: updatedOfferData, isROIChange })
    }

    const handleApprove = async () => {
        userRole === USER_ROLES.RISK ? handleRiskApproval() : setIsApproved(true);
    };

    const handleRiskApproval = () => {
        if (loanAppId) {
            const payload = {
                loanAppId,
                companyId: companyId,
                productId: productId,
                user: user._id,
                status: LeadStatus.approved.value,
                remarks: LeadStatus.risk_resolved.value,
                is_risk_resolved: true
            };
            new Promise((resolve, reject) => {
                dispatch(updateLeadDetailsWatcher(payload, resolve, reject));
            })
                .then((response) => {
                    setOpenPopup(false);
                    showAlert("Lead approved successfully.", 'success');
                })
                .catch((error) => {
                    showAlert(error?.response?.data?.message, 'error');
                });
        }
    };

    const showAlert = (msg, type) => {
        try {
            setAlert(true);
            setSeverity(type);
            setAlertMessage(msg);
        } catch (error) {
            console.log(error)
        }
    };

    const handleAlertClose = () => {
        setAlert(false);
        setSeverity('');
        setAlertMessage('');
    };

    const handleMarkAsFinalOffer = () => {
        try {
            const payload = {
                loanAppId: loanAppId,
                companyId,
                productId,
                user: user._id,
                remarks: '',
                status: LEAD_STATUS.OFFER_GENERATED
            };
            new Promise((resolve, reject) => {
                dispatch(updateLeadDetailsWatcher(payload, resolve, reject));
            })
                .then(async (response) => {
                    showAlert(response?.message, Alert_Type.Success);
                })
                .catch((error) => {
                    showAlert(Error_Message.Technical_Error, Alert_Type.Error);
                });
        } catch (error) {
            throw error
        }
    };

    const handleAmmendPartner = () => {
        // setAmendOffer(true);
        setTitle("Reject Offer");
        setType('reject');
        setOpenDialog(true);
        setCurrentSectionName("rejected");
        setCommentFields(handleCommentField(false));
    }

    const handleCommentField = (flag) => {
        let tempField = commentFields;
        tempField[`comment`][`isRequired`] = flag;
        tempField[`remarks`][`isRequired`] = !flag;
        tempField[`reasons`][`isRequired`] = !flag;
        return tempField;
    };

    const hanldleAccept = (value) => {
        setOpenPopup(true);
    };

    const handleSearch = () => {
        if (leadStatus === LeadStatus.new.value) {
            history.push(`/admin/lap/loans/loan_creation/${loanAppId}/`);
        }
        else {
            history.push(`/admin/lap/loan_details/${loanId}/${companyId}/${productId}`);
        }
    };

    const handlePopupSubmit = async () => {
        try {
            setOpenDialog(false);
            if (currentSectionName == 'approved' || currentSectionName == 'rejected') {
                setPopupMessage(Field_Label.REJECT_CONFIRMATION_POPUP);
                setPopupTitle(Field_Label.REJECT_OFFER);
                setOpenPopup(true);
                // handleApproveReject()
            }
        } catch (error) {
            throw error
        }
    };

    const handleApproveReject = async () => {
        try {
            const { companyId, productId } = history.location.state || {};
            const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
            const payload = {
                loanAppId: loanAppId,
                companyId: companyId,
                productId: productId,
                user: user._id,
                remarks: type === 'reject' ? data.remarks : data.comment,
                remark_reasons: data.reasons ?? null,
                type: type,
                status: currentSectionName.toLowerCase()
            };
            new Promise((resolve, reject) => {
                dispatch(updateLeadDetailsWatcher(payload, resolve, reject));
            })
                .then(async (response) => {
                    showAlert(popupTitle == Field_Label.REJECT_OFFER ? 'Offer was rejected successfully' : 'Offer was accepted successfully', 'success');
                    setData('');
                    setOpenPopup(false);
                    setNavState('Applicant Details');
                    setIsRejected(true);
                })
                .catch((error) => {
                    showAlert(Error_Message.Technical_Error, Alert_Type.Error);
                });
        } catch (error) {
            throw error
        }
    }

    const offerDetailsState = {
        offered_amount: {
            value: loanAmount,
            updateFn: setLoanAmount
        },
        tenure: {
            value: tenure,
            updateFn: setTenure
        },
        offered_int_rate: {
            value: interestRate,
            updateFn: setInterestRate
        }
    };

    return (
        <>
            {openPopup ? renderConfirmationPopup() : null}
            {( (userRole === USER_ROLES.CREDIT || userRole=== USER_ROLES.RISK)  && (leadStatus !== LeadStatus.offer_generated.value || leadStatus !== LeadStatus.rejected.value || offerDetails?.deviation_cat !== 'D4')) ? (
                <>
                    <div style={{ backgroundColor: `${leadStatus == LeadStatus.offer_deviation.value || leadStatus == LeadStatus.follow_up_kyc.value ? '#FFF5E6' : '#EEFFF7'}`, height: '80px', width: '98.2%', marginBottom: '1%', borderRadius: '8px', border: `${leadStatus == LeadStatus.offer_deviation.value || leadStatus == LeadStatus.follow_up_kyc.value ? '1px solid #EDA12F' : '1px solid #008042'}` }}>
                        <div style={{ display: 'flex', alignItems: 'center', height: "100%" }}>
                            <img src={leadStatus == LeadStatus.offer_deviation.value || leadStatus == LeadStatus.follow_up_kyc.value ? InfoIcon : SuccessIcon} style={{ marginLeft: '2%', width: '22px' }} />
                            {leadStatus == LeadStatus.offer_deviation.value || leadStatus == LeadStatus.follow_up_kyc.value ? <div style={{ marginLeft: '1%', color: '#EDA12F', fontSize: '18px', fontFamily: 'Montserrat-SemiBold' }}>{userRole === USER_ROLES.CREDIT ? infoMessage : LEAD_INFO_MESSAGES.RIKS_USER_DEVIATION}</div> : <div style={{ marginLeft: '1%', color: '#008042', fontSize: '18px', fontFamily: 'Montserrat-SemiBold' }}>{infoMessage}</div>}
                        </div>
                    </div>
                </>
            ) : null}
            <div className="containerStyle lap-offer" style={customStyle}>
                <div className='offerImageContainer'>
                    <img className="offerImage" src={offerImage} alt="Offer Image" />
                </div>
                <div className='flex-1 flex-column offer-details-container'>
                    <h2 className="offerHead">{heading}</h2>
                    <div
                        className='field-container'
                    >
                        {Object.keys(offerFormDetails).map((item, index) => {
                            return <InputBox
                                key={index}
                                customClass={styles['textStyleHead']}
                                customInputClass={styles['textStyleData']}
                                id={offerFormDetails[item]['id']}
                                label={offerFormDetails[item]['label']}
                                type={offerFormDetails[item]["type"]}
                                name={offerFormDetails[item]['name']}
                                placeholder={offerFormDetails[item]['placeholder']}
                                isRequired={offerFormDetails[item]['isRequired']}
                                initialValue={offerDetailsState[item].value}
                                error={offerError[item]}
                                helperText={offerError[item]}
                                isDrawdown={false}
                                isDisabled={amendOffer ? false : true}
                                onClick={e => offerDetailsState[item].updateFn(e.value)}
                            />
                        })}
                    </div>
                    {/* below commented code if for editable view */}
                    {amendOffer && <div className="offer-detail-buttons button-container">
                        <Button label={Field_Label.CANCEL} buttonType="secondary" customStyle={customStyleButton} onClick={handleCancel} />
                        <Button label={Field_Label.SUBMIT} buttonType="primary" isDisabled={loanAmount == offerDetails.offered_amount && tenure == offerDetails.tenure && interestRate == offerDetails.offered_int_rate ? true : false} customStyle={customAmendButtonStyle} onClick={handleSubmit} />
                    </div>}
                    {/* below commented code is for partner view. So render based on condition*/}

                    {(checkAccessTags(['tag_lap_lead_view_ext_read'])) && leadStatus === LeadNewStatus.OfferGenerated && <div className="offer-detail-buttons button-container">
                        <Button label={Field_Label.ACCEPT} onClick={hanldleAccept} isDisabled={false} buttonType="primary" customStyle={customAmendButtonStyle}></Button>
                        <Button label={Field_Label.REJECT} onClick={handleAmmendPartner} isDisabled={false} buttonType="secondary" customStyle={customRejectButtonStyle}></Button>
                    </div>}
                    {(checkAccessTags(['tag_lap_lead_view_ext_read'])) && leadStatus === LeadNewStatus.New && <div className="offer-detail-buttons button-container">
                        <Button label={Field_Label.BACK_TO_LOAN_DETAILS} onClick={handleSearch} isDisabled={false} buttonType="primary" customStyle={customAmendButtonStyle}></Button>
                    </div>}
                    {/* below commented code is for credit view. So render based on condition*/}
                    {isPartnerView ? null : <div className='cmview-button-container'>
                        <Button label={Field_Label.REQUEST_AN_UPDATE} customClass='text-button' buttonType="link-button" isDisabled={isRequestAnUpdateDisabled} customStyle={{ color: '#134CDE' }} onClick={handleUpdate} />
                        <Button label={Field_Label.AMEND} customClass='text-button' buttonType="link-button" isDisabled={isAmendDisabled} customStyle={{ color: `${'#134CDE'}` }} onClick={handleAmend} />
                        <Button label={Field_Label.APPROVE} customClass='text-button' buttonType="link-button" isDisabled={isApproveDisabled} customStyle={{ color: `${'#134CDE'}` }} onClick={handleApprove} />
                    </div>}

                    {isApproved && <div className='final-offer-button-container'>
                        <Button label={Field_Label.MARK_FINAL_OFFER}
                            customClass='final-offer-button'
                            buttonType="link-button"
                            onClick={handleMarkAsFinalOffer}
                        //isDisabled={isMarkAsFinalOfferDisabled}
                        />
                    </div>}
                </div>
            </div>
            
            <ActionPopup
                heading={title}
                fields={commentFields}
                type={type}
                data={data}
                setData={setData}
                dataErrors={dataErrors}
                setDataErrors={setDataErrors}
                isModalOpen={openDialog}
                button={{
                    primary: {
                        label: type === 'reject' ? 'Reject' : type == 'approve' ? 'Approve' : 'Submit',
                        style: {
                            background: type === 'reject' ? '#CC0000' : 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%)',
                        },
                        action: (comment) => {
                            if (type == 'addComment' || type == 'reject') {
                                handlePopupSubmit(comment);
                            }
                        },
                    },
                    secondary: {
                        label: 'Cancel',
                        style: styles['negativeButton'],
                        action: (props) => {
                            setOpenDialog(false);
                            setData('');
                        },
                    },
                }}
                buttonText={'Comment'}
                onClickOutsideModal={() => {
                    setOpenDialog(false);
                }}
                callback={(isModalOpen) => {
                    if (!isModalOpen) {
                        setOpenDialog(false);
                    }
                }}
            />
            {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
        </>
    );
};
const useStyles = ({ innerWidth }) => {
    return {
        textStyleHead: {
            background: '#F1F1F3',
            borderRadius: '10px',
            height: '65px',
            borderColor: '#D8D9DE',
            textAlign: 'left',
            color: '#767888',
            marginRight: '10px',
            width: '100%'
        },
        customButton1: {
            borderRadius: '50px',
            border: "1px solid #475BD8",
            width: '240px',
            height: '56px',
            fontSize: '16px',
            marginLeft: "5%",
            backgroundColor: "white"
        },
        customButton2: {
            borderRadius: '50px',
            width: '240px',
            height: '56px',
            fontSize: '16px',
        },
        textStyleData: {
            textAlign: 'left',
            background: '#F1F1F3',
            fontSize: innerWidth > 1400 ? '14px' : '12px',
            width: '100%',
        },
    };
};
