export const documentCode = {
  gst_certificate: '041',
  shareholding_pattern: '185',
  llp_agreement: '186',
  list_of_partners: '187',
  by_laws: '188',
  hunter_report: '189',
  crime_report: '190',
  cb_pan: '191',
  cb_pan_XML: '195',
  cb_aadhaar_front: '192',
  cb_aadhaar_back: '193',
  cb_aadhaar_XML: '196',
  cb_selfie: '194',
  cb_aadhaar_ovd: '197',
  cb_screening_report: '198',
  guar_pan: '199',
  guar_pan_XML: '203',
  guar_aadhaar_front: '200',
  guar_aadhaar_back: '201',
  guar_aadhaar_XML: '204',
  guar_selfie: '202',
  guar_aadhaar_ovd: '205',
  guar_screening_report: '206',
  gstr: '207',
  itr1: '208',
  itr2: '209',
  financial_statement1: '210',
  financial_statement2: '211',
  financial_statement3: '212',
  lap_bank_statement: '213',
  affidavit: '214',
  name_change_certificate: '215',
  lender_noc: '216',
  deviation_certificate: '217',
  clearance_certificate: '218',
  water_bill: '219',
  house_registry: '220',
  relationship_proof: '221',
  credit_card_bill: '222',
  telecom_bill: '223',
  self_declaration: '224',
  loan_statement: '225',
  fi_report_2: '500',
  fi_report_3: '501',
  vernacular_declaration: '226',
  itr_json: '227',
  bs_json: '228',
  pa_cibil_report: '229',
  cb_cibil_report: '230',
  applicant_selfie: '003',
  applicant_pan: '005',
  applicant_pan_XML: '116',
  applicant_aadhaar_front: '006',
  applicant_aadhaar_back: '115',
  applicant_aadhaar_XML: '114',
  applicant_aadhaar_ovd: '117',
  applicant_screening_report: '124',
  entity_business_pan: '037',
  entity_udhyam_certificate: '106',
  entity_shop_establishment: '101',
  entity_moa: '143',
  entity_aoa: '144',
  entity_coi: '145',
  entity_list_directors: '141',
  entity_board_resolution: '140',
  entity_authority_certificate: '147',
  entity_registration_certificate: '109',
  entity_partnership_deed: '108',
  entity_list_members: '146',
  entity_screening_report: '172',
  sanction_letter: '002',
  guar_cibil_report: '234',
  lender_borrower_aggrement: '001',
  commercial_bureau_report: '231',
  bank_statement_excel: '130',
  moa: '143',
  aoa: '144',
  coi: '145',
  partnership_deed: '108',
  authority_letter: '147',
  bureau_pull_consent: '113',
  list_of_directors: '141',
  list_of_shareholders: '142',
  prop_property_ownership_proof: '252',
  prop_property_fi_report: '253',
  Property_Valuation_Report_Upload: '254',
  Title_Report_1: '255',
  Title_Report_2: '256',
  Title_Report_3: '257',
  Title_Report_4: '258',
  Title_Report_5: '259',
  Title_Report_6: '260',
  Title_Report_7: '261',
  Title_Report_8: '262',
  Title_Report_9: '263',
  Title_Report_10: '264',
  Title_Report_11: '265',
  Title_Report_12: '266',
  Title_Report_13: '267',
  Title_Report_14: '268',
  Title_Report_15: '269',
  Title_Report_16: '270',
  Title_Report_17: '271',
  Title_Report_18: '272',
  Title_Report_19: '273',
  Title_Report_20: '274',
  Title_Report_21: '275',
  Title_Report_22: '276',
  Title_Report_23: '277',
  Title_Report_24: '278',
  Title_Report_25: '279',
  Property_Legal_Report_Upload: '280',
};
