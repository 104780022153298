const componentStyles = (theme) => ({
  addDocButton: {
    fontSize: '25px',
    marginRight: '10px',
  },
  usageId: {
    marginTop: '35px',
    marginBottom: '25px',
    fontSize: '14px',
  },
  docGrid: {
    marginTop: '10px',
    display: 'flex',
    direction: 'row',
  },
  docItem: {
    border: '1.5px solid #b0b5b0',
    borderRadius: '3px',
    margin: '15px',
    padding: '15px 10px',
    color: 'green',
    fontWeight: 'bold',
  },
  fileIcon: {
    cursor: 'pointer',
    marginLeft: '15px',
    color: 'black',
  },
  fileInput: {
    marginTop: '25px',
    float: 'left',
  },
  dialogTitle: {
    backgroundColor: '#4b76d1',
  },
  drawBox: {
    color: 'black',
  },
});

export default componentStyles;
