export const productFields = (state) => [
  {
    resultState: state.productNameState,
    placeholder: 'Product name*',
    name: 'productName',
    type: 'text',
    component: 'text',
    condition: 'name',
    errorMsg: 'Please enter product name with no special characters.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.maxLoanAmountState,
    placeholder: 'Max loan amount*',
    name: 'maxLoanAmount',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter valid maximum loan amount.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.minLoanAmountState,
    placeholder: 'Min loan amount*',
    name: 'minLoanAmount',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter valid min loan amount.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.bureauPartnerState,
    placeholder: 'Bureau partner*',
    name: 'bureauPartner',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select bureau partner name.',
    value: 1,
    dataSet: 'bureuPartnerOptions',
    createAllow: true,
  },
  {
    resultState: state.intRateTypeState,
    placeholder: 'Interest rate type*',
    name: 'intRateType',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select valid interest rate type.',
    value: 1,
    dataSet: 'intRateTypeOptions',
    createAllow: true,
  },
  {
    resultState: state.interestTypeState,
    placeholder: 'Interest Type',
    name: 'interestType',
    type: 'text',
    component: 'select',
    condition: '',
    value: 1,
    dataSet: 'interestTypeOptions',
    createAllow: true,
  },
  {
    resultState: state.linePfState,
    placeholder: 'Line PF',
    name: 'linePF',
    type: 'text',
    component: 'select',
    condition: '',
    value: 1,
    dataSet: 'linePFOptions',
    createAllow: true,
  },
  {
    resultState: state.workDayWeekState,
    placeholder: 'Work day week*',
    name: 'workDayWeek',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select valid Work Day Week type.',
    value: 1,
    dataSet: 'workDayWeekptions',
    createAllow: true,
  },
  {
    resultState: state.repaymentScheduleState,
    placeholder: 'Repayment schedule*',
    name: 'repaymentSchedule',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select valid repayment schedule type.',
    value: 1,
    dataSet: 'repaymentScheduleOptions',
    createAllow: true,
  },
  {
    resultState: state.loanTenureTypeState,
    placeholder: 'Loan tenure type*',
    name: 'loanTenureType',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select valid Loan Tenure Type.',
    value: 1,
    dataSet: 'loanTenureOptions',
    createAllow: true,
  },

  {
    resultState: state.repaymentTypeState,
    placeholder: 'Repayment type*',
    name: 'repaymentType',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select valid Repayment type.',
    value: 1,
    dataSet: 'repaymentTypeOptions',
    createAllow: true,
  },
  {
    resultState: state.productTypeState,
    placeholder: 'Product Type*',
    name: 'productType',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select Product Type.',
    value: 1,
    dataSet: 'productTypeOptions',
    createAllow: true,
  },
  {
    resultState: state.colenderState,
    placeholder: 'Colender',
    name: 'colender',
    type: 'text',
    component: 'select',
    condition: '',
    id: 'tags-standard',
    errorMsg: 'Please select Colender.',
    value: 1,
    dataSet: 'colenderOptions',
    createAllow: true,
  },
  {
    resultState: state.chargesShareState,
    placeholder: 'Charges Share',
    name: 'chargesShare',
    condition: '',
    type: 'text',
    component: 'text',
    condition: 'number',
    id: 'tags-standard',
    errorMsg: 'Please select Charges Share.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.daysInCalenderYearState,
    placeholder: 'Days in Calender Year',
    name: 'daysInCalenderYear',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select Days in Calender Year.',
    value: 1,
    dataSet: 'daysInCalenderYearOptions',
    createAllow: true,
  },
  {
    resultState: state.beneficiaryBankSourceState,
    placeholder: 'Beneficiary Bank Source',
    name: 'beneficiaryBankSource',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select Beneficiary Bank Source',
    value: 1,
    dataSet: 'beneficiaryBankSourceOptions',
    createAllow: true,
  },
  {
    resultState: state.reconTypeState,
    placeholder: 'Recon Type',
    name: 'reconType',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select Recon Type',
    value: 1,
    dataSet: 'reconTypeOptions',
    createAllow: true,
  },
  {
    resultState: state.partPaymentState,
    placeholder: 'Part Payment',
    name: 'partPayment',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select ',
    value: '',
    dataSet: 'partPaymentOptions',
    createAllow: true,
  },
  {
    resultState: state.loanTenureState,
    placeholder: 'Loan tenure*',
    name: 'loanTenure',
    type: 'text',
    component: 'text',
    condition: 'number',
    errorMsg: 'Please select valid Loan Tenure.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.repaymentDaysState,
    placeholder: 'Repayment days',
    name: 'repaymentDays',
    type: 'text',
    component: 'text',
    condition: 'number',
    errorMsg: 'Please enter valid repayment days.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.subventionFeesState,
    placeholder: 'Subvention fees i.e (UA | UP)',
    name: 'subventionFees',
    type: 'text',
    component: 'text',
    condition: 'subvention',
    errorMsg: 'Enter Valid Subvention Fees.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.processingFeesState,
    placeholder: 'Processing fees i.e (0.02UA | 0.02UP | 0.02RP | 0.02RA)',
    name: 'processingFees',
    type: 'text',
    component: 'text',
    condition: 'overdues',
    errorMsg: 'Enter Valid Processing Fees.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.usageFeeState,
    placeholder: 'Usage fee i.e (0.02UA | 0.02UP | 0.02RP | 0.02RA)',
    name: 'usageFee',
    type: 'text',
    component: 'text',
    condition: 'overdues',
    errorMsg: 'Enter Valid Usage Fee.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.intValueState,
    placeholder: 'Int value i.e (0.02P | 0.02A)',
    name: 'intValue',
    type: 'text',
    component: 'text',
    condition: 'interest',
    errorMsg: 'Enter Valid int Value.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.penalInterestState,
    placeholder: 'Overdue Interest Rate',
    name: 'penalInterest',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter Valid Overdue Interest Rate.',
    value: 0,
    createAllow: true,
  },
  {
    resultState: state.penalChargeState,
    placeholder: 'Penal Charge %',
    name: 'penalCharge',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter Valid Penal Charge.',
    value: 0,
    createAllow: true,
  },
  {
    resultState: state.bounceChargesState,
    placeholder: 'Bounce charges(Exclusive of GST)',
    name: 'bounceCharges',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter Valid bounce charges',
    value: 0,
    createAllow: true,
  },
  {
    resultState: state.cancellationPeriodState,
    placeholder: 'Cancellation period',
    name: 'cancellationPeriod',
    type: 'text',
    component: 'text',
    condition: 'number',
    errorMsg: 'Enter valid cancellation period.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.lockInPeriodState,
    placeholder: 'Lock in period',
    name: 'lockInPeriod',
    type: 'text',
    component: 'text',
    condition: 'number',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.foreclosureChargeState,
    placeholder: 'Foreclosure charge i.e. 2RA | 2RP',
    name: 'foreclosureCharge',
    type: 'text',
    component: 'text',
    condition: 'rear2',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.foreclosureOfferDaysState,
    placeholder: 'Foreclosure offer days',
    name: 'foreclosureOfferDays',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select foreclosure Type.',
    value: 0,
    dataSet: 'foreclosureOfferDaysOptions',
    createAllow: true,
  },
  {
    resultState: state.maximumNumberOfEmiState,
    placeholder: 'Maximum number of emi',
    name: 'maximumNumberOfEmi',
    type: 'text',
    component: 'text',
    condition: 'number',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.cgstOnPfPercState,
    placeholder: 'CGST %',
    name: 'cgstOnPfPerc',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter Valid CGST %.',
    value: 1,
    createAllow: false,
  },
  {
    resultState: state.sgstOnPfPercState,
    placeholder: 'SGST %',
    name: 'sgstOnPfPerc',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter Valid SGST %.',
    value: 1,
    createAllow: false,
  },
  {
    resultState: state.igstOnPfPercState,
    placeholder: 'IGST %',
    name: 'igstOnPfPerc',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter Valid IGST %.',
    value: 1,
    createAllow: false,
  },
  {
    resultState: state.gstOnPfPercState,
    placeholder: 'GST %',
    name: 'gstOnPfPerc',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter Valid GST %.',
    value: 1,
    createAllow: false,
  },
  {
    resultState: state.cashCollateralAmountState,
    placeholder: 'Cash Collateral Amount i.e (22UA | 10.23UP)',
    name: 'cashCollateralAmount',
    type: 'text',
    component: 'text',
    condition: 'upfront',
    errorMsg: 'Enter Valid CashCollateral Amount',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.firstInstallmentDateState,
    placeholder: 'First Installment Date*',
    name: 'firstInstallmentDate',
    type: 'text',
    component: 'select',
    condition: '',
    errorMsg: 'Please select First Installment Date.',
    value: 1,
    dataSet: 'firstInstallmentDateOptions',
    createAllow: true,
  },
  {
    resultState: state.vintage,
    placeholder: 'Vintage',
    name: 'vintage',
    type: 'text',
    component: 'text',
    condition: 'float',
    errorMsg: 'Enter Valid Vintage.',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.calculateBrokenInterestState,
    placeholder: 'Calculate broken interest',
    name: 'calculateBrokenInterest',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.insuranceChargesState,
    placeholder: 'Insurance charges',
    name: 'insuranceCharges',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.stampChargesState,
    placeholder: 'Stamp charges',
    name: 'stampCharges',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.applicatrionFeesState,
    placeholder: 'Application fees',
    name: 'applicatrionFees',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.subventionBasedState,
    placeholder: 'Subvention based',
    name: 'subventionBased',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.advanceEMIState,
    placeholder: 'Advance EMI',
    name: 'advanceEMI',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.calculateGstForProductState,
    placeholder: 'Calculate GST for product',
    name: 'calculateGstForProduct',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.enhancedReviewRequiredState,
    placeholder: 'Enhanced review required',
    name: 'enhancedReviewRequired',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.kycState,
    placeholder: 'KYC',
    name: 'ckycSearch',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.bureauCheckState,
    placeholder: 'Bureau check',
    name: 'bureauCheck',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.bureauParserState,
    placeholder: 'Bureau parser',
    name: 'bureauParser',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.allowLoc,
    placeholder: 'Allow loc',
    name: 'allowLoc',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.allowSubLoans,
    placeholder: 'Allow sub loans',
    name: 'allowSubLoans',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.foreclosureState,
    placeholder: 'Foreclosure',
    name: 'foreclosure',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.ascoreState,
    placeholder: 'A score',
    name: 'ascore',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.bscoreState,
    placeholder: 'B score',
    name: 'bscore',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.isLenderSelectorFlag,
    placeholder: 'IsLenderSelectorFlag',
    name: 'isLenderSelectorFlag',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.forceUsageConvertToEmiState,
    placeholder: 'Force usage convert to emi',
    name: 'forceUsageConvertToEmi',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    value: 1,
    createAllow: true,
  },
  {
    resultState: state.downpaymentState,
    placeholder: 'Downpayment',
    name: 'downpayment',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.cashCollateralWithHeldState,
    placeholder: 'Cash Collateral (Withheld)',
    name: 'cashCollateralWithheld',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.cashCollateralReceivedState,
    placeholder: 'Cash Collateral (Received)',
    name: 'cashCollateralReceived',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.pennyDropState,
    placeholder: 'Penny Drop',
    name: 'pennyDrop',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.eNach,
    placeholder: 'E-Nach',
    name: 'eNach',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.bureauCoApplicant,
    placeholder: 'Bureau Co-Applicant',
    name: 'bureauCoApplicant',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.msmeAutomation,
    placeholder: 'MSME Automation',
    name: 'isMsmeAutomation',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.insuranceOptOut,
    placeholder: 'Insurance Opt Out',
    name: 'isInsuranceOptOut',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.convenienceFeesState,
    placeholder: 'Convenience Fees',
    name: 'convenienceFees',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.lap,
    placeholder: 'Loan Against Property',
    name: 'isLap',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.multiTranchDisb,
    placeholder: 'Multi Tranch Disbursement',
    name: 'isMultiTranchDisb',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.restructureAllowed,
    placeholder: ' Restructure Allowed',
    name: 'isRestructureAllowed',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.partPaymentAllowed,
    placeholder: ' Part Payment Allowed',
    name: 'isPartPaymentAllowed',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
  {
    resultState: state.templatizeSLandLBA,
    placeholder: 'SL/LBA Templatisation',
    name: 'templatizeSLandLBA',
    type: 'checkbox',
    component: 'checkbox',
    condition: '',
    createAllow: true,
  },
];
