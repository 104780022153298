import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Submit from 'react-sdk/dist/components/Button';
import * as xlsx from 'xlsx';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useDimensions from '../../hooks/useDimensions';

import { validateData } from '../../util/validation';
import { editServiceWatcher, getServiceByIdWatcher } from '../../actions/services';
import GetAppIcon from '@mui/icons-material/GetApp';
import { AlertBox } from '../../components/AlertBox';

export default function EditServices(props) {
  const { service_id } = useParams();

  const defaultErrors = {
    nameError: false,
    loanTemplatesError: false,
    templateNameError: false,
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [serviceSection, setServiceSection] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [serviceUrl, setServiceUrl] = useState('');
  const [inputFileName, setInputFileName] = useState('');
  const [inputFileJson, setInputFileJson] = useState('');
  const { innerWidth, innerHeight } = useDimensions();
  const styles = useStyles({ innerWidth, innerHeight });

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 4000);
  };

  const handleFormValues = () => {
    new Promise((resolve, reject) => {
      dispatch(getServiceByIdWatcher(service_id, resolve, reject));
    })
      .then((response) => {
        const selectedService = response;
        setServiceId(selectedService._id);
        setServiceName(selectedService.service_name);
        setVendorName(selectedService.vendor_name);
        setServiceSection(selectedService.section);
        setServiceType(selectedService.type);
        setServiceUrl(selectedService.url.replace('api/', ''));
        setInputFileJson(selectedService.file);
      })
      .catch((error) => {
        return showAlert(error.response.data.message, 'error');
      });
  };

  useEffect(() => {
    if (service_id) {
      handleFormValues();
    }
  }, []);

  const serviceSectionList = [
    { label: 'Bureau', value: 'Bureau' },
    { label: 'KYC', value: 'KYC' },
    { label: 'Disbursement', value: 'Disbursement' },
    { label: 'E-sign', value: 'E-sign' },
  ];

  const serviceTypeList = [
    { label: 'GET', value: 'GET' },
    { label: 'POST', value: 'POST' },
  ];

  const handleInputChange = (field, validationType, setValue) => (event) => {
    const { value } = event;
    setValue(value);
    setErrors({
      ...errors,
      [field + 'Error']: !validateData(validationType, value),
    });
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const onFileChangeHandler = (event) => {
    setInputFileName(event.target.files[0]);
  };

  const downloadServiceTemplate = () => {
    const newWB = xlsx.utils.book_new();
    const newWS = xlsx.utils.json_to_sheet(inputFileJson);
    xlsx.utils.book_append_sheet(newWB, newWS, 'File');
    xlsx.writeFile(newWB, serviceName + '-' + moment().format('Do MMMM YYYY') + '.xlsx');
  };

  const handleServiceSectionChange = (value) => {
    if (!value) {
      value = '';
    }
    setServiceSection(value ? value.value : '');
  };

  const handleServiceTypeChange = (value) => {
    if (!value) {
      value = '';
    }
    setServiceType(value ? value.value : '');
  };

  const handleSubmit = () => {
    const file = new FormData();
    file.append('file', inputFileName);
    if (!serviceName || serviceName === '') {
      return showAlert('Service name is required', 'error');
    }
    if (!vendorName || vendorName === '') {
      return showAlert('Vendor name is required', 'error');
    }
    if (!serviceSection || serviceSection === '') {
      return showAlert('Service section is required', 'error');
    }
    if (!serviceType || serviceType === '') {
      return showAlert('Service type is required', 'error');
    }
    if (!serviceUrl || serviceUrl === '') {
      return showAlert('Service url is required', 'error');
    }
    const reqData = {
      service_id: serviceId,
      service_name: serviceName,
      vendor_name: vendorName,
      section: serviceSection,
      type: serviceType,
      url: serviceUrl,
    };
    new Promise((resolve, reject) => {
      dispatch(editServiceWatcher(reqData, file, resolve, reject));
    })
      .then((response) => {
        handleFormValues();
        showAlert(response.message, 'success');
      })
      .catch((error) => {
        return showAlert(error.response.data.message, 'error');
      });
  };

  return (
    <div style={{ paddingTop: '10px', height: '100%', marginLeft: '1.3rem!important' }}>
      <div style={styles.headerStyle}>Edit service</div>
      <div>{alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}</div>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '1%',
          }}
        >
          <InputBox id={'service-name'} label="Service Name" placeholder="Service name" initialValue={serviceName ? serviceName : ''} onClick={handleInputChange('serviceName', 'string', setServiceName)} customClass={styles.inputBoxStyle}></InputBox>

          <InputBox id={'service-name'} label="Vendor name" placeholder="Vendor name" initialValue={vendorName ? vendorName : ''} onClick={handleInputChange('vendorName', 'string', setVendorName)} customClass={styles.inputBoxStyle}></InputBox>

          <InputBox id={serviceId} label="Service section" placeholder="Service section" initialValue={serviceSection ? serviceSection : ''} isDrawdown={true} options={serviceSectionList} customDropdownClass={styles.dropdownStyle} onClick={(value) => handleServiceSectionChange(value)} customClass={styles.inputBoxStyle}></InputBox>

          <InputBox id={serviceId} label="Service type" placeholder="Service type" customDropdownClass={styles.dropdownStyle} initialValue={serviceType ? serviceType : ''} isDrawdown={true} onClick={(value) => handleServiceTypeChange(value)} options={serviceTypeList} customClass={styles.inputBoxStyle}></InputBox>

          <InputBox id={'serviceUrl'} label="Service URL" initialValue={serviceUrl ? serviceUrl : ''} onClick={handleInputChange('serviceUrl', 'string', setServiceUrl)} customClass={styles.inputBoxStyle}></InputBox>
          <FormControl variant="filled" component={Box} marginBottom="1.5rem!important">
            {inputFileJson ? (
              <Grid item className="fileinput">
                <div style={{ fontFamily: 'MontSerrat-Bold', fontSize: '16px', marginLeft: '1px', marginTop: '2%' }}>
                  Download template for Service :
                  <Button
                    size="sm"
                    onClick={() => {
                      downloadServiceTemplate();
                    }}
                  >
                    <GetAppIcon />
                  </Button>
                </div>
              </Grid>
            ) : null}
            <div className="fileinput" style={styles.belowTextStyle}>
              Select the template in .xlsx format only
              <div>
                <input style={styles.fileUploadButtonStyle} type="file" name="file" accept=".xlsx" onChange={(e) => onFileChangeHandler(e, inputFileName)} />
              </div>
            </div>
          </FormControl>
        </div>
      </div>

      <Submit label="SUBMIT" buttonType="primary" customStyle={styles.buttonStyle} onClick={handleSubmit}></Submit>
    </div>
  );
}

const useStyles = ({ innerWidth, innerHeight }) => {
  return {
    inputBoxStyle: {
      width: '50%',
      maxWidth: 'none',
      height: '56px',
      borderRadius: '8px',
      marginTop: '1%',
      marginLeft: '1%',
    },
    dropdownStyle: {
      zIndex: '10',
    },
    headerStyle: {
      fontSize: '1.25rem',
      fontFamily: 'MontSerrat-Bold',
      marginLeft: '2%',
    },
    belowTextStyle: {
      fontSize: '16px',
      fontFamily: 'MontSerrat-Regular',
      marginLeft: '1%',
      marginTop: '1%',
      color: '#141519',
    },
    buttonStyle: {
      borderRadius: '8px',
      width: '145px',
      height: '56px',
      fontSize: '16px',
      marginLeft: '2%',
    },
    fileUploadButtonStyle: {
      display: 'block',
      color: 'black',
    },
  };
};
