import ErrorConstants from '../../constants/error_constants';

export const FinancialDocDeptEnum = Object.freeze({
  fd_itr: 'Financial Document ITR',
  fd_gst: 'Financial Document GST',
  fd_bs: 'Financial Document BS',
  fd_upload_bs: 'Financial Document Upload BS',
  fd_bs_password: 'Financial Document BS Password',
});
export const FinancialDocFieldEnum = Object.freeze({
  fd_itr: 'fd_itr',
  fd_gstin: 'fd_gstin',
  fd_gst_docs: 'fd_gst_docs',
  fd_gst_add_doc: 'fd_gst_add_doc',
  fd_bank_name: 'fd_bank_name',
  fd_bank_acc_no: 'fd_bank_acc_no',
  fd_bank_acc_type: 'fd_bank_acc_type',
  fd_bank_statement: 'fd_bank_statement',
  fd_bs_Password: 'fd_bs_Password',
});

export const Guarantor_Field = {
  Gua_Aadhaar: 'gua_aadhaar',
  Gua_Age: 'gua_age',
};
export const Guarantor_Dept = {
  Guarantor_Perm_Add: 'Guarantor Permanent Address',
};
export const bookLoansFormJsonFields = () => [
  //#region Applicant section
  {
    title: 'Company',
    field: 'company_id',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'select_company',
    validationMsg: 'Company is required',
    checked: 'true',
  },
  {
    title: 'Product',
    field: 'product_id',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'select_company',
    validationMsg: 'Product is required',
    checked: 'true',
  },
  {
    title: 'Partner Loan App ID',
    field: 'partner_loan_app_id',
    type: 'string',
    isOptional: false,
    dept: 'select_partner',
    validationMsg: 'Partner loan app ID is Required',
    checked: 'true',
  },
  {
    title: 'Partner Borrower ID',
    field: 'partner_borrower_id',
    type: 'string',
    isOptional: false,
    dept: 'select_partner',
    validationMsg: 'Partner borrower ID is Required',
    checked: 'true',
  },
  {
    title: 'Requested Loan Amount (INR)',
    field: 'requested_loan_amt',
    type: 'loanamount',
    isOptional: false,
    dept: 'Loan Requirement',
    validationMsg: 'Requested Loan Amount is required',
    errorHelperTextRequired: 'Provide the amount between Rs.1 Lakh - Rs.2 Crores',
    checked: 'true',
  },
  {
    title: 'Tenure in Months',
    field: 'tenure_in_months',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: '12 months', label: '12' },
      { value: '24 months', label: '24' },
      { value: '36 months', label: '36' },
      { value: '48 months', label: '48' },
      { value: '60 months', label: '60' },
      { value: '72 months', label: '72' },
      { value: '84 months', label: '84' },
      { value: '96 months', label: '96' },
      { value: '108 months', label: '108' },
      { value: '120 months', label: '120' },
    ],
    isOptional: false,
    dept: 'Loan Requirement',
    validationMsg: 'Tenure in Months is required',
    errorHelperTextRequired: 'Select the range between 12 months - 24 months',
    checked: 'true',
  },
  {
    title: 'Purpose of Loan',
    field: 'purpose_of_loan',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Working Capital', label: 'Working Capital' },
      { value: 'Business Expansion', label: 'Business Expansion' },
      { value: 'Purchase of Business Fixed Assets', label: 'Purchase of Business Fixed Assets' },
      { value: 'Inventory', label: 'Inventory' },
      { value: 'Expansion of Premises', label: 'Expansion of Premises' },
      { value: 'Personal Requirement', label: 'Personal Requirement' },
      { value: 'Others', label: 'Others' },
    ],
    isOptional: false,
    dept: 'Loan Requirement',
    validationMsg: 'Purpose of Loan is required',
    checked: 'true',
  },
  {
    title: 'Type of Loan',
    field: 'type_of_loan',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Regular', label: 'Regular' },
      { value: 'Balance Transfer', label: 'Balance Transfer' },
    ],
    isOptional: false,
    dept: 'Loan Requirement',
    validationMsg: 'Type of Loan is required',
    checked: 'true',
  },
  {
    title: 'Requested Interest Rate (%)',
    field: 'requested_int_rate',
    type: 'number',
    isOptional: false,
    dept: 'Loan Requirement',
    validationMsg: 'Requested Interest Rate is required',
    errorHelperTextRequired: 'This field only accepts numbers between 0 to 100',
    checked: 'true',
  },
  {
    title: 'First Name',
    field: 'first_name',
    type: 'formatname',
    isOptional: false,
    dept: 'Applicant Details',
    validationMsg: 'First Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed ',
    checked: 'true',
  },
  {
    title: 'Middle Name (optional)',
    field: 'middle_name',
    type: 'formatname',
    isOptional: true,
    dept: 'Applicant Details',
    validationMsg: 'field is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed ',
    checked: 'false',
  },
  {
    title: 'Last Name',
    field: 'last_name',
    type: 'formatname',
    isOptional: false,
    dept: 'Applicant Details',
    validationMsg: 'Last Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed ',
    checked: 'true',
  },
  {
    title: 'Date Of Birth',
    field: 'date_of_birth',
    type: 'date',
    isOptional: false,
    dept: 'Applicant Details',
    validationMsg: `DOB is required`,
    checked: 'true',
  },
  {
    title: 'Age',
    field: 'age',
    type: 'number',
    isOptional: true,
    dept: 'Applicant Details',
    validationMsg: 'Age is required',
    checked: 'false',
  },
  {
    title: 'Gender',
    field: 'gender',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Female', label: 'Female' },
      { value: 'Male', label: 'Male' },
      { value: 'Others', label: 'Others' },
    ],
    isOptional: false,
    dept: 'Applicant Details',
    validationMsg: 'Gender is required',
    checked: 'true',
  },
  {
    title: 'Mobile No.',
    field: 'mobile_number',
    type: 'mobile',
    isOptional: false,
    dept: 'Applicant Details',
    validationMsg: 'Mobile No. is required',
    checked: 'true',
    errorHelperTextRequired: 'Mobile No. should start with 5, 6, 7, 8, or 9',
    errorHelperText1Required: 'Mobile no. should have 10 digits',
    decimalErrorHelperTextRequired: 'Special characters are not allowed',
  },
  {
    title: 'Email ',
    field: 'email',
    type: 'email',
    isOptional: false,
    dept: 'Applicant Details',
    validationMsg: 'Email is required',
    errorHelperTextRequired: 'Invalid email format. Please use example@example.com',
    checked: 'true',
  },
  {
    title: `Father's Full Name`,
    field: 'father_full_name',
    type: 'formatname',
    isOptional: false,
    dept: 'Applicant Details',
    validationMsg: `Father's Full Name is required`,
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'true',
  },
  {
    title: 'Occupation Type',
    field: 'occupation_type',
    type: 'string',
    isDrawdown: false,
    options: [{ value: 'Self Employed', label: 'Self Employed' }],
    isOptional: false,
    dept: 'Applicant Details',
    validationMsg: 'Occupation type field is required',
    checked: 'true',
  },
  {
    title: 'Entity Type',
    field: 'entity_type',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Individual', label: 'Individual' },
      { value: 'Proprietor', label: 'Proprietor' },
      { value: 'Partnership', label: 'Partnership' },
      { value: 'Private Limited', label: 'Private Limited' },
    ],
    isOptional: false,
    dept: 'Applicant Details',
    validationMsg: 'Entity type is required',
    checked: 'true',
  },
  {
    title: 'Address Line 1',
    field: 'curr_addr_ln1',
    type: 'string',
    isOptional: false,
    dept: 'Current Address',
    validationMsg: 'Address Line 1 is required',
    checked: 'true',
  },
  {
    title: 'Address Line 2 (optional)',
    field: 'curr_addr_ln2',
    type: 'string',
    isOptional: true,
    dept: 'Current Address',
    validationMsg: 'Address Line 2 is required',
    checked: 'false',
  },
  {
    title: 'State',
    field: 'curr_addr_state',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'Current Address',
    validationMsg: 'State is required',
    checked: 'true',
  },
  {
    title: 'City',
    field: 'curr_addr_city',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'Current Address',
    validationMsg: 'City is required',
    checked: 'true',
  },
  {
    title: 'Pincode',
    field: 'curr_addr_pincode',
    type: 'pincode',
    isOptional: false,
    dept: 'Current Address',
    validationMsg: 'Pincode is required',
    errorHelperTextRequired: 'Pincode should be of 6 digits',
    decimalErrorHelperTextRequired: 'Special characters are not allowed',
    checked: 'true',
  },
  {
    title: 'Nature of Ownership',
    field: 'nature_of_ownership',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Owned', label: 'Owned' },
      { value: 'Rented', label: 'Rented' },
    ],
    isOptional: false,
    dept: 'Current Address',
    validationMsg: 'Nature of Ownership is required',
    checked: 'true',
  },
  {
    title: 'No.of Years at current Address',
    field: 'no_of_years_curr_addr',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: '0 - 1 Years', label: '0 - 1 Years' },
      { value: '1 - 2 Years', label: '1 - 2 Years' },
      { value: '2 - 3 Years', label: '2 - 3 Years' },
      { value: '3 - 4 Years', label: '3 - 4 Years' },
      { value: '4 - 5 Years', label: '4 - 5 Years' },
      { value: '5 - 6 Years', label: '5 - 6 Years' },
      { value: '6 - 7 Years', label: '6 - 7 Years' },
      { value: '7 - 8 Years', label: '7 - 8 Years' },
      { value: '8 - 9 Years', label: '8 - 9 Years' },
      { value: '9 - 10 Years', label: '9 - 10 Years' },
      { value: '10 - 11 Years', label: '10 - 11 Years' },
      { value: '11 - 12 Years', label: '11 - 12 Years' },
    ],
    isOptional: false,
    dept: 'Current Address',
    validationMsg: 'No. of Years at Current Address is required',
    checked: 'true',
  },
  {
    title: 'Monthly Rent (INR)',
    field: 'monthly_rent',
    type: 'monthlyincome',
    isOptional: false,
    dept: 'Current Address',
    validationMsg: 'Monthly Rent',
    errorHelperTextRequired: 'Monthly Rent cannot be zero',
    checked: 'true',
  },
  {
    title: 'Address Line 1',
    field: 'per_addr_ln1',
    type: 'string',
    isOptional: false,
    dept: 'Permanent Address',
    validationMsg: 'Address Line 1 is required',
    checked: 'true',
  },
  {
    title: 'Address Line 2 (optional)',
    field: 'per_addr_ln2',
    type: 'string',
    isOptional: true,
    dept: 'Permanent Address',
    validationMsg: 'Address Line 2 is required',
    checked: 'false',
  },
  {
    title: 'State',
    field: 'per_addr_state',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'Permanent Address',
    validationMsg: 'State is required',
    checked: 'true',
  },
  {
    title: 'City',
    field: 'per_addr_city',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'Permanent Address',
    validationMsg: 'City is required',
    checked: 'true',
  },
  {
    title: 'Pincode',
    field: 'per_addr_pincode',
    type: 'pincode',
    isOptional: false,
    dept: 'Permanent Address',
    validationMsg: 'Pincode is required',
    errorHelperTextRequired: 'Pincode should be of 6 digits',
    decimalErrorHelperTextRequired: 'Special characters are not allowed',
    checked: 'true',
  },
  {
    title: 'House Ownership',
    field: 'house_ownership',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Self Owned', label: 'Self Owned' },
      { value: 'Owned by Spouse', label: 'Owned by Spouse' },
      { value: 'Owned by Father', label: 'Owned by Father' },
      { value: 'Owned by Mother', label: 'Owned by Mother' },
      { value: 'Owned by Brother', label: 'Owned by Brother' },
      { value: 'Owned by Son', label: 'Owned by Son' },
    ],
    isOptional: false,
    dept: 'Permanent Address',
    validationMsg: 'House Ownership is required',
    checked: 'true',
  },
  {
    title: 'No.of Years at Permanent Address',
    field: 'no_of_years_per_addr',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: '0 - 1 Years', label: '0 - 1 Years' },
      { value: '1 - 2 Years', label: '1 - 2 Years' },
      { value: '2 - 3 Years', label: '2 - 3 Years' },
      { value: '3 - 4 Years', label: '3 - 4 Years' },
      { value: '4 - 5 Years', label: '4 - 5 Years' },
      { value: '5 - 6 Years', label: '5 - 6 Years' },
      { value: '6 - 7 Years', label: '6 - 7 Years' },
      { value: '7 - 8 Years', label: '7 - 8 Years' },
      { value: '8 - 9 Years', label: '8 - 9 Years' },
      { value: '9 - 10 Years', label: '9 - 10 Years' },
      { value: '10 - 11 Years', label: '10 - 11 Years' },
      { value: '11 - 12 Years', label: '11 - 12 Years' },
    ],
    isOptional: false,
    dept: 'Permanent Address',
    validationMsg: 'No. of Years at Permanent Address is required',
    checked: 'true',
  },
  {
    title: 'Occupancy',
    type: 'string',
    field: 'per_addr_occupancy',
    isDrawdown: true,
    options: [
      { value: 'Self-Occupied', label: 'Self-Occupied' },
      { value: 'Immediate Family', label: 'Immediate Family' },
      { value: 'Job Work', label: 'Job Work' },
      { value: 'Rented', label: 'Rented' },
      { value: 'Vacant', label: 'Vacant' },
    ],
    isOptional: false,
    dept: 'Permanent Address',
    validationMsg: 'Occupancy is required',
    checked: 'true',
  },
  {
    title: 'PAN',
    field: 'pan_value',
    type: 'pan',
    isOptional: false,
    dept: 'Applicant KYC 1',
    validationMsg: 'PAN is required',
    errorHelperTextRequired: 'Please enter valid PAN',
    checked: 'true',
  },
  {
    title: 'Aadhaar Number',
    field: 'aadhaar_value',
    type: 'aadhaar',
    isOptional: false,
    dept: 'Applicant KYC 1',
    validationMsg: 'Aadhaar Number is required',
    errorHelperTextRequired: 'Aadhaar Number should be of 12 digits',
    checked: 'true',
  },
  {
    title: 'Applicant Selfie',
    field: 'applicant_image_value',
    type: 'string',
    isOptional: false,
    dept: 'Applicant Selfie',
    box_type: 'upload',
    validationMsg: 'Applicant Selfie is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'true',
  },
  {
    title: 'PAN',
    field: 'pan_image_value',
    type: 'string',
    isOptional: false,
    dept: 'KYC Document PAN',
    box_type: 'upload',
    validationMsg: 'PAN image is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'true',
  },
  {
    title: 'PAN',
    field: 'pan_xml_image_value',
    type: 'string',
    isOptional: false,
    dept: 'KYC Document PAN',
    box_type: 'upload',
    validationMsg: 'PAN XML/JSON is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (XML/JSON)',
    checked: 'true',
  },
  {
    title: 'Aadhaar',
    field: 'aadhaar_front_image_value',
    type: 'string',
    isOptional: true,
    dept: 'KYC Document Aadhaar',
    box_type: 'upload',
    validationMsg: 'Aadhaar image is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'true',
  },
  {
    title: 'Aadhaar Back (optional)',
    field: 'aadhaar_back_image_value',
    type: 'string',
    isOptional: true,
    dept: 'KYC Document Aadhaar',
    box_type: 'upload',
    validationMsg: 'field is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'true',
  },
  {
    title: 'Aadhaar',
    field: 'aadhaar_xml_image_value',
    type: 'string',
    isOptional: true,
    dept: 'KYC Document Aadhaar',
    box_type: 'upload',
    validationMsg: 'Aadhaar XML/JSON is required',
    checked: 'true',
  },
  //#region Entity section
  {
    title: 'Entity Type',
    field: 'select_entity_type',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Individual', label: 'Individual' },
      { value: 'Proprietor', label: 'Proprietor' },
      { value: 'Partnership', label: 'Partnership' },
      { value: 'Private Limited', label: 'Private Limited' },
    ],
    isOptional: false,
    dept: 'Entity Details 1',
    validationMsg: 'Entity type is required',
    checked: 'true',
  },
  {
    title: 'Entity Name',
    field: 'entity_name',
    type: 'string',
    isOptional: false,
    dept: 'Entity Details 1',
    validationMsg: 'Entity Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'true',
  },
  {
    title: 'Date of Incorporation',
    field: 'doi_value',
    type: 'date',
    isOptional: false,
    dept: 'Entity Details 1',
    validationMsg: 'Date of Incorporation is required',
    checked: 'true',
  },
  {
    title: 'Business Commencement Date',
    field: 'business_commencement_date',
    type: 'date',
    isOptional: false,
    dept: 'Entity Details 1',
    validationMsg: `Business commencement date is required`,
    checked: 'true',
  },
  {
    title: 'Nature of Business',
    field: 'nature_of_business',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Retail Shop', label: 'Retail Shop' },
      { value: 'Goods Trading', label: 'Goods Trading' },
      { value: 'Manufacturing', label: 'Manufacturing' },
      { value: 'Job Work', label: 'Job Work' },
      { value: 'Recycling Business', label: 'Recycling Business' },
      { value: 'Steel Scrap', label: 'Steel Scrap' },
      { value: 'Trading and Service', label: 'Trading and Service' },
    ],
    isOptional: false,
    dept: 'Entity Details 1',
    validationMsg: 'Nature of Business is required',
    checked: 'true',
  },
  {
    title: `Work Profile`,
    field: 'work_profile',
    type: 'workprofile',
    isOptional: false,
    dept: 'Entity Details 1',
    validationMsg: 'Work Profile is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    errorHelperText1Required: 'Max Character length limit is 65',
    checked: 'true',
  },
  {
    title: `Monthly Income (INR)`,
    field: 'monthly_income',
    type: 'monthlyincome',
    isOptional: false,
    dept: 'Entity Details 1',
    validationMsg: 'Monthly Income is required',
    errorHelperTextRequired: 'Monthly Income cannot be 0',
    errorHelperText1Required: 'Please provide the value between only 2 decimal',
    checked: 'true',
  },
  {
    title: 'Entity Type',
    field: 'select_entity_type',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Individual', label: 'Individual' },
      { value: 'Proprietor', label: 'Proprietor' },
      { value: 'Partnership', label: 'Partnership' },
      { value: 'Private Limited', label: 'Private Limited' },
    ],
    isOptional: false,
    dept: 'Entity Details',
    validationMsg: 'Entity type is required',
    checked: 'true',
  },
  {
    title: 'Nature of Business',
    field: 'nature_of_business',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Retail Shop', label: 'Retail Shop' },
      { value: 'Goods Trading', label: 'Goods Trading' },
      { value: 'Manufacturing', label: 'Manufacturing' },
      { value: 'Job Work', label: 'Job Work' },
      { value: 'Recycling Business', label: 'Recycling Business' },
      { value: 'Steel Scrap', label: 'Steel Scrap' },
      { value: 'Trading and Service', label: 'Trading and Service' },
    ],
    isOptional: false,
    dept: 'Entity Details',
    validationMsg: 'Nature of Business is required',
    checked: 'true',
  },
  {
    title: `Work Profile`,
    field: 'work_profile',
    type: 'workprofile',
    isOptional: false,
    dept: 'Entity Details',
    validationMsg: 'Work Profile is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    errorHelperText1Required: 'Max Character length limit is 65',
    checked: 'true',
  },
  {
    title: 'Business Commencement Date',
    field: 'business_commencement_date',
    type: 'date',
    isOptional: false,
    dept: 'Entity Details',
    validationMsg: `Business commencement date is required`,
    checked: 'true',
  },
  {
    title: `Monthly Income (INR)`,
    field: 'monthly_income',
    type: 'monthlyincome',
    isOptional: false,
    dept: 'Entity Details',
    validationMsg: 'Monthly Income is required',
    errorHelperTextRequired: 'Monthly Income cannot be 0',
    errorHelperText1Required: 'Please provide the value between only 2 decimal',
    checked: 'true',
  },
  {
    title: 'Address Line 1',
    field: 'comm_addr_ln1',
    type: 'string',
    isOptional: false,
    dept: 'Communication Address',
    validationMsg: 'Address Line 1 is required',
    checked: 'true',
  },
  {
    title: 'Address Line 2 (optional)',
    field: 'comm_addr_ln2',
    type: 'string',
    isOptional: true,
    dept: 'Communication Address',
    validationMsg: 'Address Line 2 is required',
    checked: 'false',
  },
  {
    title: 'State',
    field: 'comm_addr_state',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'Communication Address',
    validationMsg: 'State is required',
    checked: 'true',
  },
  {
    title: 'City',
    field: 'comm_addr_city',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'Communication Address',
    validationMsg: 'City is required',
    checked: 'true',
  },
  {
    title: 'Pincode',
    field: 'comm_addr_pincode',
    type: 'pincode',
    isOptional: false,
    dept: 'Communication Address',
    validationMsg: 'Pincode is required',
    errorHelperTextRequired: 'Pincode should be of 6 digits',
    checked: 'true',
  },
  {
    title: 'Nature of Ownership',
    field: 'comm_nature_of_ownership',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Owned', label: 'Owned' },
      { value: 'Rented', label: 'Rented' },
    ],
    isOptional: false,
    dept: 'Communication Address',
    validationMsg: 'Nature of Ownership is required',
    checked: 'true',
  },
  {
    title: 'No.of Years at Communication Address',
    individualTitle: 'No.of Years at Current Address',
    field: 'comm_no_of_years',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: '0 - 1 Years', label: '0 - 1 Years' },
      { value: '1 - 2 Years', label: '1 - 2 Years' },
      { value: '2 - 3 Years', label: '2 - 3 Years' },
      { value: '3 - 4 Years', label: '3 - 4 Years' },
      { value: '4 - 5 Years', label: '4 - 5 Years' },
      { value: '5 - 6 Years', label: '5 - 6 Years' },
      { value: '6 - 7 Years', label: '6 - 7 Years' },
      { value: '7 - 8 Years', label: '7 - 8 Years' },
      { value: '8 - 9 Years', label: '8 - 9 Years' },
      { value: '9 - 10 Years', label: '9 - 10 Years' },
      { value: '10 - 11 Years', label: '10 - 11 Years' },
      { value: '11 - 12 Years', label: '11 - 12 Years' },
    ],
    isOptional: false,
    dept: 'Communication Address',
    validationMsg: 'No. of Years at Communication Address is required',
    individualValidationMsg: 'No. of Years at Current Address is required',
    checked: 'true',
  },
  {
    title: 'Monthly Rent (INR)',
    field: 'comm_monthly_rent',
    type: 'monthlyincome',
    isOptional: false,
    dept: 'Communication Address',
    validationMsg: 'Monthly Rent is required',
    errorHelperTextRequired: 'Monthly Rent cannot be zero',
    errorHelperText1Required: 'Please provide the value between only 2 decimal',
    checked: 'true',
  },
  {
    title: 'Address Line 1',
    field: 'reg_addr_ln1',
    type: 'string',
    isOptional: false,
    dept: 'Registered Address',
    validationMsg: 'Address Line 1 is required',
    checked: 'true',
  },
  {
    title: 'Address Line 2 (optional)',
    field: 'reg_addr_ln2',
    type: 'string',
    isOptional: true,
    dept: 'Registered Address',
    validationMsg: 'Address Line 2 is required',
    checked: 'false',
  },
  {
    title: 'State',
    field: 'reg_addr_state',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'Registered Address',
    validationMsg: 'State is required',
    checked: 'true',
  },
  {
    title: 'City',
    field: 'reg_addr_city',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'Registered Address',
    validationMsg: 'City is required',
    checked: 'true',
  },
  {
    title: 'Pincode',
    field: 'reg_addr_pincode',
    type: 'pincode',
    isOptional: false,
    dept: 'Registered Address',
    validationMsg: 'Pincode is required',
    errorHelperTextRequired: 'Pincode should be of 6 digits',
    checked: 'true',
  },
  {
    title: 'Nature of Ownership',
    field: 'reg_nature_of_ownership',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Owned', label: 'Owned' },
      { value: 'Rented', label: 'Rented' },
    ],
    isOptional: false,
    dept: 'Registered Address',
    validationMsg: 'Nature of Ownership is required',
    checked: 'true',
  },
  {
    title: 'No.of Years at Registered Address',
    individualTitle: 'No.of Years at Permanent Address',
    field: 'reg_no_of_years',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: '0 - 1 Years', label: '0 - 1 Years' },
      { value: '1 - 2 Years', label: '1 - 2 Years' },
      { value: '2 - 3 Years', label: '2 - 3 Years' },
      { value: '3 - 4 Years', label: '3 - 4 Years' },
      { value: '4 - 5 Years', label: '4 - 5 Years' },
      { value: '5 - 6 Years', label: '5 - 6 Years' },
      { value: '6 - 7 Years', label: '6 - 7 Years' },
      { value: '7 - 8 Years', label: '7 - 8 Years' },
      { value: '8 - 9 Years', label: '8 - 9 Years' },
      { value: '9 - 10 Years', label: '9 - 10 Years' },
      { value: '10 - 11 Years', label: '10 - 11 Years' },
      { value: '11 - 12 Years', label: '11 - 12 Years' },
    ],
    isOptional: false,
    dept: 'Registered Address',
    validationMsg: 'No. of Years at Registered Address is required',
    individualValidationMsg: 'No. of Years at Permanent Address is required',
    checked: 'true',
  },
  {
    title: 'Monthly Rent (INR)',
    field: 'reg_monthly_rent',
    type: 'monthlyincome',
    isOptional: false,
    dept: 'Registered Address',
    validationMsg: 'Monthly Rent is required',
    errorHelperTextRequired: 'Monthly Rent cannot be zero',
    errorHelperText1Required: 'Please provide the value between only 2 decimal',
    checked: 'true',
  },
  {
    title: 'URC Number',
    field: 'entity_kyc_proprietor_urc_no',
    type: 'string',
    isOptional: false,
    box_type: 'verify',
    dept: 'Entity-KYC proprietor',
    validationMsg: 'URC Number is required',
    errorHelperTextRequired: 'Please enter valid URC Number. Example (UDYAM-XY-07-1234567)',
    checked: 'true',
  },
  {
    title: 'URC',
    field: 'entity_kyc_proprietor_urc_file_upload',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC proprietor',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'URC Number',
    field: 'entity_kyc_individual_urc_no',
    type: 'string',
    isOptional: false,
    box_type: 'verify',
    dept: 'Entity-KYC individual',
    validationMsg: 'URC Number is required',
    errorHelperTextRequired: 'Please enter valid URC Number. Example (UDYAM-XY-07-1234567)',
    checked: 'true',
  },
  {
    title: 'URC',
    field: 'entity_kyc_individual_urc_file_upload',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC individual',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'PAN',
    field: 'entity_kyc_partnerShip_pan',
    type: 'pan',
    isOptional: false,
    box_type: 'verify',
    dept: 'Entity-KYC partnership',
    validationMsg: 'PAN is required',
    errorHelperTextRequired: 'Please enter valid PAN',
    checked: 'true',
  },
  {
    title: 'URC Number',
    field: 'entity_kyc_partnerShip_urc_no',
    type: 'string',
    isOptional: false,
    box_type: 'verify',
    dept: 'Entity-KYC partnership',
    validationMsg: 'URC Number is required',
    errorHelperTextRequired: 'Please enter valid URC Number. Example (UDYAM-XY-07-1234567)',
    checked: 'true',
  },
  {
    title: 'URC',
    field: 'entity_kyc_partnerShip_urc_file_upload',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC partnership',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'Partnership Deed',
    field: 'entity_kyc_partnerShip_deed',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC partnership',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'Authority Letter',
    field: 'entity_kyc_partnerShip_authority_letter',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC partnership',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'GST Certificate (Optional)',
    field: 'entity_kyc_partnerShip_gstin_certificate',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC partnership',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'GSTIN (Optional)',
    field: 'entity_kyc_partnerShip_gstin_no',
    type: 'string',
    isOptional: false,
    dept: 'Entity-KYC partnership',
    validationMsg: 'Please enter a valid GSTIN No.',
    checked: 'true',
  },
  {
    title: 'PAN',
    field: 'entity_kyc_pvtLtd_pan',
    type: 'pan',
    isOptional: false,
    box_type: 'verify',
    dept: 'Entity-KYC private limited',
    validationMsg: 'PAN is required',
    errorHelperTextRequired: 'Please enter valid PAN',
    checked: 'true',
  },
  {
    title: 'URC Number',
    field: 'entity_kyc_pvtLtd_urc_no',
    type: 'string',
    isOptional: false,
    box_type: 'verify',
    dept: 'Entity-KYC private limited',
    validationMsg: 'URC Number is required',
    errorHelperTextRequired: 'Please enter valid URC Number. Example (UDYAM-XY-07-1234567)',
    checked: 'true',
  },
  {
    title: 'URC',
    field: 'entity_kyc_pvtLtd_urc_file_upload',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC private limited',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'CIN/LLPIN',
    field: 'entity_kyc_pvtLtd_cin_llpin',
    type: 'string',
    isOptional: false,
    dept: 'Entity-KYC private limited',
    validationMsg: 'Please enter a valid CIN/LLPIN No.',
    checked: 'true',
  },
  {
    title: 'List of Directors',
    field: 'entity_kyc_pvtLtd_lod',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC private limited',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'AOA',
    field: 'entity_kyc_pvtLtd_aoa',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC private limited',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'COI',
    field: 'entity_kyc_pvtLtd_coi',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC private limited',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'MOA',
    field: 'entity_kyc_pvtLtd_moa',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC private limited',
    validationMsg: 'Please Upload the file',
    checked: 'true',
  },
  {
    title: 'GSTIN (Optional)',
    field: 'entity_kyc_pvtLtd_gstin_no',
    type: 'string',
    isOptional: false,
    dept: 'Entity-KYC private limited',
    validationMsg: 'Please enter a valid GSTIN No.',
    checked: 'true',
  },
  {
    title: 'GST Certificate (Optional)',
    field: 'entity_supportDocuments_gstin_upload',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC GSTIN',
    validationMsg: 'Please enter a valid GSTIN file.',
    checked: 'true',
  },
  {
    title: 'GSTIN (Optional)',
    field: 'entity_supportDocuments_gstin_no',
    type: 'string',
    isOptional: false,
    box_type: 'verify',
    dept: 'Entity-KYC GSTIN',
    validationMsg: 'Please enter a valid GSTIN No.',
    checked: 'true',
  },
  {
    title: 'Upload Certificate',
    field: 'entity_supportDocuments_shop_establishment_upload',
    type: 'string',
    isOptional: false,
    box_type: 'upload',
    dept: 'Entity-KYC Shop Establishment Type',
    validationMsg: 'Please enter a valid file.',
    checked: 'true',
  },
  //#region Co-Borrower section
  {
    title: 'First Name',
    dept: 'Co-Borrower Details',
    type: 'formatname',
    isDrawdown: false,
    field: `cb_fname`,
    isOptional: false,
    validationMsg: 'First Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'true',
  },
  {
    title: 'Middle Name (optional)',
    dept: 'Co-Borrower Details',
    type: 'formatname',
    isDrawdown: false,
    field: `cb_mname`,
    isOptional: true,
    validationMsg: 'Middle Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'false',
  },
  {
    title: 'Last Name',
    dept: 'Co-Borrower Details',
    type: 'formatname',
    isDrawdown: false,
    field: `cb_lname`,
    isOptional: false,
    validationMsg: 'Last Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'true',
  },
  {
    title: 'Date of Birth',
    dept: 'Co-Borrower Details',
    type: 'date',
    isDrawdown: false,
    field: `cb_dob`,
    isOptional: false,
    validationMsg: 'DOB is required',
    checked: 'true',
  },
  {
    title: 'Age',
    dept: 'Co-Borrower Details',
    type: 'number',
    isDrawdown: false,
    field: `cb_age`,
    isOptional: true,
    validationMsg: 'Age is required',
    checked: 'false',
  },
  {
    title: 'Gender',
    dept: 'Co-Borrower Details',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Female', label: 'Female' },
      { value: 'Male', label: 'Male' },
      { value: 'Others', label: 'Others' },
    ],
    field: `cb_gender`,
    isOptional: false,
    validationMsg: 'Gender is required',
    checked: 'true',
  },
  {
    title: 'Mobile No.',
    dept: 'Co-Borrower Details',
    type: 'mobile',
    isDrawdown: false,
    field: `cb_mobile`,
    isOptional: false,
    validationMsg: 'Mobile No. is required',
    errorHelperTextRequired: 'Mobile No. should start with 5, 6, 7, 8, or 9',
    errorHelperText1Required: 'Mobile no. should have 10 digits',
    decimalErrorHelperTextRequired: 'Special characters are not allowed',
    checked: 'true',
  },
  {
    title: 'Email (optional)',
    dept: 'Co-Borrower Details',
    type: 'email',
    isDrawdown: false,
    field: `cb_email`,
    isOptional: true,
    validationMsg: 'Email is required',
    errorHelperTextRequired: 'Invalid email format. Please use example@example.com',
    checked: 'true',
  },
  {
    title: 'Father Full Name',
    dept: 'Co-Borrower Details',
    type: 'formatname',
    isDrawdown: false,
    field: `cb_father_name`,
    isOptional: false,
    validationMsg: 'Father Full Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'true',
  },
  {
    title: 'Relationship with Applicant',
    field: 'cb_relationship',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Spouse', label: 'Spouse' },
      { value: 'Mother', label: 'Mother' },
      { value: 'Father', label: 'Father' },
      { value: 'Brother', label: 'Brother' },
    ],
    isOptional: false,
    dept: 'Co-Borrower Details',
    validationMsg: 'Relationship with Applicant is required',
    checked: 'true',
  },
  {
    title: 'Entity Shareholder',
    field: 'cb_entity_shareholder',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'Co-Borrower Details',
    validationMsg: 'Entity Shareholder is required',
    checked: 'true',
  },
  {
    title: 'Property Owner',
    field: 'cb_property_owner',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'Co-Borrower Details',
    validationMsg: 'Property Owner is required',
    checked: 'true',
  },
  {
    title: 'Address Line 1',
    dept: 'Co-Borrower Current Address',
    type: 'string',
    isDrawdown: false,
    field: `cb_resi_addr_ln1`,
    isOptional: false,
    validationMsg: 'Address Line 1 is required',
    checked: 'true',
  },
  {
    title: 'Address Line 2 (optional)',
    dept: 'Co-Borrower Current Address',
    type: 'string',
    isDrawdown: false,
    field: `cb_resi_addr_ln2`,
    isOptional: true,
    validationMsg: 'field is required',
    checked: 'false',
  },
  {
    title: 'State',
    dept: 'Co-Borrower Current Address',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'State1', label: 'State1' },
      { value: 'State2', label: 'State2' },
      { value: 'State3', label: 'State3' },
      { value: 'State4', label: 'State4' },
    ],
    field: `cb_state`,
    isOptional: false,
    validationMsg: 'State is required',
    checked: 'true',
  },
  {
    title: 'City',
    dept: 'Co-Borrower Current Address',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'City1', label: 'City1' },
      { value: 'City2', label: 'City2' },
      { value: 'City3', label: 'City3' },
      { value: 'City4', label: 'City4' },
    ],
    field: `cb_city`,
    isOptional: false,
    validationMsg: 'City is required',
    checked: 'true',
  },
  {
    title: 'Pincode',
    dept: 'Co-Borrower Current Address',
    type: 'pincode',
    isDrawdown: false,
    field: `cb_pincode`,
    isOptional: false,
    validationMsg: 'Pincode is required',
    errorHelperTextRequired: 'Pincode should be of 6 digits',
    decimalErrorHelperTextRequired: 'Special characters are not allowed',
    checked: 'true',
  },
  {
    title: 'Address Line 1',
    dept: 'Co-Borrower Permanent Address',
    type: 'string',
    isDrawdown: false,
    field: `cb_per_addr_ln1`,
    isOptional: false,
    validationMsg: 'Address Line 1 is required',
    checked: 'true',
  },
  {
    title: 'Address Line 2 (optional)',
    dept: 'Co-Borrower Permanent Address',
    type: 'string',
    isDrawdown: false,
    field: `cb_per_addr_ln2`,
    isOptional: true,
    validationMsg: 'field is required',
    checked: 'false',
  },
  {
    title: 'State',
    dept: 'Co-Borrower Permanent Address',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'State1', label: 'State1' },
      { value: 'State2', label: 'State2' },
      { value: 'State3', label: 'State3' },
      { value: 'State4', label: 'State4' },
    ],
    field: `cb_per_state`,
    isOptional: false,
    validationMsg: 'State is required',
    checked: 'true',
  },
  {
    title: 'City',
    dept: 'Co-Borrower Permanent Address',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'City1', label: 'City1' },
      { value: 'City2', label: 'City2' },
      { value: 'City3', label: 'City3' },
      { value: 'City4', label: 'City4' },
    ],
    field: `cb_per_city`,
    isOptional: false,
    validationMsg: 'City is required',
    checked: 'true',
  },
  {
    title: 'Pincode',
    dept: 'Co-Borrower Permanent Address',
    type: 'pincode',
    isDrawdown: false,
    field: `cb_per_pincode`,
    isOptional: false,
    validationMsg: 'Pincode is required',
    errorHelperTextRequired: 'Pincode should be of 6 digits',
    decimalErrorHelperTextRequired: 'Special characters are not allowed',
    checked: 'true',
  },
  {
    title: 'PAN',
    dept: 'Co-Borrower KYC',
    type: 'pan',
    isDrawdown: false,
    field: `cb_pan`,
    isOptional: false,
    validationMsg: 'PAN is required',
    errorHelperTextRequired: 'Please enter valid PAN',
    checked: 'true',
  },
  {
    title: 'Aadhaar Number',
    dept: 'Co-Borrower KYC',
    type: 'aadhaar',
    isDrawdown: false,
    field: `cb_aadhaar`,
    isOptional: false,
    validationMsg: 'Aadhaar Number is required',
    errorHelperTextRequired: 'Aadhaar Number should be of 12 digits',
    checked: 'true',
  },
  {
    title: 'Co-Borrower Selfie',
    field: 'cb_selfie_image_value',
    type: 'string',
    isOptional: false,
    dept: 'Co-Borrower Selfie',
    box_type: 'upload',
    validationMsg: 'Co-Borrower Selfie is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'true',
  },
  {
    title: 'PAN',
    field: 'cb_pan_image_value',
    type: 'string',
    isOptional: false,
    dept: 'Co-Borrower KYC Document PAN',
    box_type: 'upload',
    validationMsg: 'PAN image is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'true',
  },
  {
    title: 'PAN',
    field: 'cb_pan_xml_image_value',
    type: 'string',
    isOptional: false,
    dept: 'Co-Borrower KYC Document PAN',
    box_type: 'upload',
    validationMsg: 'PAN XML/JSON is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (XML/JSON)',
    checked: 'true',
  },
  {
    title: 'Aadhaar',
    field: 'cb_aadhaar_front_image_value',
    type: 'string',
    isOptional: false,
    dept: 'Co-Borrower KYC Document Aadhaar',
    box_type: 'upload',
    validationMsg: 'Aadhaar image is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'true',
  },
  {
    title: 'Aadhaar Back (optional)',
    field: 'cb_aadhaar_back_image_value',
    type: 'string',
    isOptional: true,
    dept: 'Co-Borrower KYC Document Aadhaar',
    box_type: 'upload',
    validationMsg: 'field is required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'false',
  },
  {
    title: 'Aadhaar',
    field: 'cb_aadhaar_xml_image_value',
    type: 'string',
    isOptional: true,
    dept: 'Co-Borrower KYC Document Aadhaar',
    box_type: 'upload',
    validationMsg: 'Aadhaar XML/JSON is required',
    checked: 'true',
  },
  //#region Guarantor section
  {
    title: 'First Name',
    dept: 'Guarantor Details',
    type: 'formatname',
    isDrawdown: false,
    field: `gua_fname`,
    isOptional: false,
    validationMsg: 'First Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'true',
  },
  {
    title: 'Middle Name (optional)',
    dept: 'Guarantor Details',
    type: 'formatname',
    isDrawdown: false,
    field: `gua_mname`,
    isOptional: true,
    validationMsg: 'Middle Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'false',
  },
  {
    title: 'Last Name',
    dept: 'Guarantor Details',
    type: 'formatname',
    isDrawdown: false,
    field: `gua_lname`,
    isOptional: false,
    validationMsg: 'Last Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'true',
  },
  {
    title: 'Date of Birth',
    dept: 'Guarantor Details',
    type: 'date',
    isDrawdown: false,
    field: `gua_dob`,
    isOptional: false,
    validationMsg: `DOB is required`,
    checked: 'true',
  },
  {
    title: 'Age',
    dept: 'Guarantor Details',
    type: 'number',
    isDrawdown: false,
    field: Guarantor_Field.Gua_Age,
    isOptional: true,
    validationMsg: 'Age is required',
    checked: 'false',
  },
  {
    title: 'Gender',
    dept: 'Guarantor Details',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'Female', label: 'Female' },
      { value: 'Male', label: 'Male' },
      { value: 'Others', label: 'Others' },
    ],
    field: `gua_gender`,
    isOptional: false,
    validationMsg: 'Gender is required',
    checked: 'true',
  },
  {
    title: 'Mobile No.',
    dept: 'Guarantor Details',
    type: 'mobile',
    isDrawdown: false,
    field: `gua_mobile`,
    isOptional: false,
    checked: 'true',
    errorHelperTextRequired: 'Mobile No. should start with 5, 6, 7, 8, or 9',
    errorHelperText1Required: 'Mobile No. should have 10 digits',
    validationMsg: 'Mobile No. is required',
  },
  {
    title: 'Email (optional)',
    dept: 'Guarantor Details',
    type: 'email',
    isDrawdown: false,
    field: `guarantor_email`,
    isOptional: true,
    errorHelperTextRequired: 'Invalid email format. Please use example@example.com',
    checked: 'false',
  },
  {
    title: 'Father Full Name',
    dept: 'Guarantor Details',
    type: 'formatname',
    isDrawdown: false,
    field: `gua_father_name`,
    isOptional: false,
    validationMsg: 'Father Full Name is required',
    errorHelperTextRequired: 'Special characters and numbers are not allowed',
    checked: 'true',
  },
  {
    title: 'Address Line 1',
    dept: 'Guarantor Current Address',
    type: 'string',
    isDrawdown: false,
    field: `gua_resi_addr_ln1`,
    isOptional: false,
    validationMsg: 'Address Line 1 is required',
    checked: 'true',
  },
  {
    title: 'Address Line 2 (Optional)',
    dept: 'Guarantor Current Address',
    type: 'string',
    isDrawdown: false,
    field: `gua_resi_addr_ln2`,
    isOptional: true,
    validationMsg: 'Address Line 2 is required',
    checked: 'false',
  },
  {
    title: 'State',
    dept: 'Guarantor Current Address',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'State1', label: 'State1' },
      { value: 'State2', label: 'State2' },
      { value: 'State3', label: 'State3' },
      { value: 'State4', label: 'State4' },
    ],
    field: `gua_state`,
    isOptional: false,
    validationMsg: 'State is required',
    checked: 'true',
  },
  {
    title: 'City',
    dept: 'Guarantor Current Address',
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'City1', label: 'City1' },
      { value: 'City2', label: 'City2' },
      { value: 'City3', label: 'City3' },
      { value: 'City4', label: 'City4' },
    ],
    field: `gua_city`,
    isOptional: false,
    validationMsg: 'City is required',
    checked: 'true',
  },
  {
    title: 'Pincode',
    dept: 'Guarantor Current Address',
    type: 'pincode',
    isDrawdown: false,
    field: `gua_pincode`,
    isOptional: false,
    validationMsg: 'Pincode is required',
    errorHelperTextRequired: 'Pincode should be of 6 digits',
    checked: 'true',
  },
  {
    title: 'Address Line 1',
    dept: Guarantor_Dept.Guarantor_Perm_Add,
    type: 'string',
    isDrawdown: false,
    field: `gua_per_addr_ln1`,
    isOptional: false,
    validationMsg: 'Address Line 1 is required',
    checked: 'true',
  },
  {
    title: 'Address Line 2 (Optional)',
    dept: Guarantor_Dept.Guarantor_Perm_Add,
    type: 'string',
    isDrawdown: false,
    field: `gua_per_addr_ln2`,
    isOptional: true,
    validationMsg: 'Address Line 2 is required',
    checked: 'false',
  },
  {
    title: 'State',
    dept: Guarantor_Dept.Guarantor_Perm_Add,
    type: 'string',
    isDrawdown: true,
    options: [
      { value: 'State1', label: 'State1' },
      { value: 'State2', label: 'State2' },
      { value: 'State3', label: 'State3' },
      { value: 'State4', label: 'State4' },
    ],
    field: `gua_per_state`,
    isOptional: false,
    validationMsg: 'State is required',
    checked: 'true',
  },
  {
    title: 'City',
    dept: Guarantor_Dept.Guarantor_Perm_Add,
    type: 'string',
    isDrawdown: true,
    field: `gua_per_city`,
    isOptional: false,
    validationMsg: 'City is required',
    checked: 'true',
  },
  {
    title: 'Pincode',
    dept: Guarantor_Dept.Guarantor_Perm_Add,
    type: 'pincode',
    isDrawdown: false,
    field: `gua_per_pincode`,
    isOptional: false,
    validationMsg: 'Pincode is required',
    errorHelperTextRequired: 'Pincode should be of 6 digits',
    checked: 'true',
  },
  {
    title: 'PAN',
    dept: 'Guarantor KYC',
    type: 'pan',
    isDrawdown: false,
    field: `gua_pan`,
    isOptional: false,
    validationMsg: 'PAN is required',
    errorHelperTextRequired: 'Please enter valid PAN',
    checked: 'true',
  },
  {
    title: 'Aadhaar Number',
    dept: 'Guarantor KYC',
    type: 'aadhaar',
    isDrawdown: false,
    field: Guarantor_Field.Gua_Aadhaar,
    isOptional: false,
    validationMsg: 'Aadhaar Number is required',
    errorHelperTextRequired: 'Aadhaar Number should be of 12 digits',
    checked: 'true',
  },
  //#region Property details form
  {
    title: 'Approx Value of Property (INR)',
    field: 'prop_approx_property_value',
    type: 'number',
    isDrawdown: false,
    isOptional: false,
    dept: 'property_details',
    validationMsg: 'Approx value of property is required',
    errorHelperTextRequired: ErrorConstants.SPECIAL_CHARACTERS_ARE_NOT_ALLOWED,
    checked: 'true',
  },
  {
    title: 'Property Type',
    field: 'prop_property_type',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'property_details',
    validationMsg: 'Property type is required',
    checked: 'true',
    options: [
      { value: 'Occupied Residential Property', label: 'Occupied Residential Property' },
      { value: 'Self - occupied Commercial Property', label: 'Self - occupied Commercial Property' },
      { value: 'Constructed Residential Property', label: 'Constructed Residential Property' },
      { value: 'Constructed Commercial Property', label: 'Constructed Commercial Property' },
    ],
  },
  {
    title: 'Plot Size (Sq.ft)',
    field: 'prop_plot_size',
    type: 'number',
    isDrawdown: false,
    isOptional: false,
    dept: 'property_details',
    validationMsg: 'Plot size is required',
    errorHelperTextRequired: ' Please enter the plot size with up to 6 digits.',
    checked: 'true',
  },
  {
    title: 'Built-up Area (Sq.ft)',
    field: 'prop_built_up_area',
    type: 'number',
    isDrawdown: false,
    isOptional: false,
    dept: 'property_details',
    validationMsg: 'Built-up area is required',
    errorHelperTextRequired: ' Please enter the Built-up Area with up to 6 digits.',
    checked: 'true',
  },
  {
    title: 'Property Address',
    field: 'prop_property_address',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'property_details',
    validationMsg: 'Property address is required',
    checked: 'true',
  },
  {
    title: 'Nature of Property',
    field: 'prop_nature_of_property',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'property_details',
    validationMsg: 'Nature of property is required',
    checked: 'true',
    options: [{ value: 'Non-Agriculture', label: 'Non-Agriculture' }],
  },
  {
    title: 'Property Jurisdiction',
    field: 'prop_property_jurisdiction',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'property_details',
    validationMsg: 'Property Jurisdiction is required',
    checked: 'true',
    options: [
      { value: 'Municipal Corporation', label: 'Municipal Corporation' },
      { value: 'Gram Panchayat', label: 'Gram Panchayat' },
      { value: 'Town & Country Planning', label: 'Town & Country Planning' },
      { value: 'Tehsildar', label: 'Tehsildar' },
      { value: 'Zilla Parishad', label: 'Zilla Parishad' },
      { value: 'Society', label: 'Society' },
      { value: 'Others', label: 'Others' },
    ],
  },
  {
    title: 'Property Ownership Proof',
    field: 'prop_property_ownership_proof',
    type: 'string',
    isOptional: false, //need to change once we got the doc id
    dept: 'property_ownership_documents',
    box_type: 'button',
    validationMsg: 'Property ownership proof is Required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'true',
  },
  {
    title: 'Property FI Report',
    field: 'prop_property_fi_report',
    type: 'string',
    isOptional: false, //need to change once we got the doc id
    dept: 'property_ownership_documents',
    box_type: 'button',
    validationMsg: 'Property fi report is Required',
    errorHelperTextRequired: 'Please retry and  Please use a valid file type (JPG, JPEG, PNG, PDF) upto 5MB',
    checked: 'true',
  },
  {
    title: 'Owner Full Name 1',
    field: 'prop_owner_full_name_1',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'property_owner_details',
    validationMsg: 'Owner full name 1 is required',
    errorHelperTextRequired: 'The owner with the same name is already added',
    checked: 'true',
  },
  {
    title: 'Owner Name Full 2 (Optional)',
    field: 'prop_owner_full_name_2',
    type: 'string',
    isDrawdown: true,
    isOptional: true,
    dept: 'property_owner_details',
    errorHelperTextRequired: 'The owner with the same name is already added',
    checked: 'false',
  },
  {
    title: 'Owner Name Full 3 (Optional)',
    field: 'prop_owner_full_name_3',
    type: 'string',
    isDrawdown: true,
    isOptional: true,
    dept: 'property_owner_details',
    errorHelperTextRequired: 'The owner with the same name is already added',
    checked: 'false',
  },
  {
    title: 'Owner Name Full 4 (Optional)',
    field: 'prop_owner_full_name_4',
    type: 'string',
    isDrawdown: true,
    isOptional: true,
    dept: 'property_owner_details',
    errorHelperTextRequired: 'The owner with the same name is already added',
    checked: 'false',
  },
  //#endregion
  {
    field: FinancialDocFieldEnum.fd_itr,
    dept: FinancialDocDeptEnum.fd_itr,
  },
  {
    field: FinancialDocFieldEnum.fd_gstin,
    dept: FinancialDocDeptEnum.fd_gst,
  },
  {
    field: FinancialDocFieldEnum.fd_gst_docs,
    dept: FinancialDocDeptEnum.fd_gst,
  },
  {
    field: FinancialDocFieldEnum.fd_gst_add_doc,
    dept: FinancialDocDeptEnum.fd_gst,
  },
  {
    field: FinancialDocFieldEnum.fd_bank_name,
    dept: FinancialDocDeptEnum.fd_bs,
  },
  {
    field: FinancialDocFieldEnum.fd_bank_acc_no,
    dept: FinancialDocDeptEnum.fd_bs,
    errorHelperTextRequired: 'This field only accepts numbers',
  },
  {
    field: FinancialDocFieldEnum.fd_bank_acc_type,
    dept: FinancialDocDeptEnum.fd_bs,
  },
  {
    field: FinancialDocFieldEnum.fd_bank_statement,
    dept: FinancialDocDeptEnum.fd_upload_bs,
  },
  {
    field: FinancialDocFieldEnum.fd_bs_Password,
    dept: FinancialDocDeptEnum.fd_bs_password,
  },
];
