import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { storedList } from '../../util/localstorage';
import { Button, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { statusToDisplay } from '../../util/helper';
import { getLoanByStatusWatcher } from '../../actions/compositeDisbursement';
import CompanyDropdown from '../../components/Company/CompanySelect';
import ProductDropdown from '../../components/Product/ProductSelect';
import DisbursementRequestPopUp from './disbursementRequestPopUp';
import { updateBorrowerInfoCommonUncommonWatcher } from '../../actions/borrowerInfo';
import { AlertBox } from '../../components/AlertBox';
import { checkAccessTags } from '../../util/uam';
import Table from 'react-sdk/dist/components/Table/Table';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';

const DisbursementApprove = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [company, setCompany] = useState(null);
  const [product, setProduct] = useState(null);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState('');
  const [isAllSelect, setIsAllSelect] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loansRecords, setLoansRecords] = useState([]);
  const [selectedLoanRecords, setSelectedLoanRecords] = useState([]);
  const [successRows, setSuccessRows] = useState([]);
  const [failRows, setFailRows] = useState([]);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50, 100]);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [errorRowsText, setErrorRowsText] = React.useState('');
  const [isProgressStop, setIsProgressStop] = React.useState(false);
  const [isProgressStart, setIsProgressStart] = React.useState(false);
  const [totalSelection, setTotalSelectCount] = useState(0);
  const [totalSanctionAmount, setTotalSanctionAmount] = useState(0);
  const [totalNetDisbAmount, setTotalNetDisbAmount] = useState(0);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [walletConfigCheck, setWalletConfigCheck] = useState(false);
  const user = storedList('user');

  const handleSelectAllRecord = (data) => {
    let sanctionSum = 0;
    let netDisbSum = 0;
    if (data?.target?.checked) {
      setIsAllSelect(!isAllSelect);
      setLoansRecords(
        loansRecords.map((item) => {
          if (item.sanction_amount) sanctionSum += parseFloat(item.sanction_amount);
          if (item.net_disbur_amt) netDisbSum += parseFloat(item.net_disbur_amt);
          return {
            ...item,
            checked: !isAllSelect,
          };
        }),
      );
      setTotalSelectCount(loansRecords.length);
      setSelectedLoanRecords(loansRecords);
      setTotalSanctionAmount(sanctionSum);
      setTotalNetDisbAmount(netDisbSum);
    }
    if (!data?.target?.checked) {
      setTotalSelectCount(0);
      setTotalSanctionAmount(0);
      setTotalNetDisbAmount(0);
      setIsAllSelect(!isAllSelect);
      setLoansRecords(
        loansRecords.map((item) => {
          return {
            ...item,
            checked: !isAllSelect,
          };
        }),
      );
      setSelectedLoanRecords([]);
    }
  };

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  const getLoansByStatus = () => {
    const payload = {
      sendData: {
        ...filter,
        page: page,
        limit: rowsPerPage,
        status: 'credit_approved',
        stage: 2,
      },
      userData: {
        user_id: user._id,
        ...filter,
      },
    };
    new Promise((resolve, reject) => {
      dispatch(getLoanByStatusWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setIsAllSelect(false);
        setLoansRecords(
          response?.rows.map((item) => {
            return {
              ...item,
              checked: false,
            };
          }),
        );
        setCount(response?.count);
        setAvailableBalance(response.availableBalance);
        setWalletConfigCheck(response.walletConfigCheck);
      })
      .catch((error) => {
        setLoansRecords([]);
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const clearSelectionSummary = () => {
    setTotalSelectCount(0);
    setTotalSanctionAmount(0);
    setTotalNetDisbAmount(0);
    setAvailableBalance(0);
    setSelectedLoanRecords([]);
  };

  const handleUpdateLoanStatus = (count, status) => {
    if (!selectedLoanRecords.length) return;
    setIsProgressStart(true);
    const data = selectedLoanRecords[count];
    if (count === selectedLoanRecords.length) {
      errorTextToRender();
      setIsProgressStop(true);
      setIsProgressStart(false);
      setIsAllSelect(false);
      clearSelectionSummary();
      getLoansByStatus();
      return;
    }

    const params = {
      company_id: data?.company_id,
      product_id: data?.product_id,
      loan_id: data?.loan_id,
      loan_app_id: data?.loan_app_id,
      partner_loan_id: data?.partner_loan_id,
      partner_borrower_id: data?.partner_borrower_id,
      borrower_id: data?.borrower_id,
      status,
    };

    new Promise((resolve, reject) => {
      dispatch(updateBorrowerInfoCommonUncommonWatcher(params, resolve, reject));
    })
      .then((response) => {
        successRows.push(data);
        handleUpdateLoanStatus(count + 1);
      })
      .catch((error) => {
        failRows.push({
          loan_id: data.loan_id,
          message: error?.response?.data?.message,
        });
        handleUpdateLoanStatus(count + 1);
      });
  };

  const handleStartStatusUpdate = (status) => {
    setIsOpenPopUp(!isOpenPopUp);
    handleUpdateLoanStatus(0, status);
  };

  const errorTextToRender = async () => {
    var newLine = '\r\n';
    var text = newLine;
    await failRows.map((item, index) => {
      text = text + `${index + 1})  ${item.loan_id} - ${item.message}` + newLine;
    });
    setErrorRowsText(text);
    return text;
  };

  useEffect(() => {
    if (isTagged && filter && checkAccessTags(['tag_disbursement_approval_read', 'tag_disbursement_approval_read_write'])) getLoansByStatus();
    if (!isTagged && filter) getLoansByStatus();
  }, [filter, page, rowsPerPage]);

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 4000);
  };

  const onSearchClick = (data) => {
    if (!company?.value) return showAlert('Please select company', 'error');
    setIsAllSelect(false);
    setSelectedLoanRecords([]);
    setPage(0);
    setTotalSelectCount(0);
    setTotalSanctionAmount(0);
    setTotalNetDisbAmount(0);
    setAvailableBalance(0);
    setLoansRecords([]);
    setFilter({
      company_id: company.value,
      product_id: product.value,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(event);
    setTotalSelectCount(0);
    setTotalSanctionAmount(0);
    setTotalNetDisbAmount(0);
    setAvailableBalance(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event));
    setIsAllSelect(false);
    setSelectedLoanRecords([]);
    setPage(0);
  };

  const handleSelectSingleRecord = (data, event) => {
    let newRecords = [...loansRecords];
    const rowToChange = newRecords.findIndex((item) => {
      return item._id === data._id;
    });
    newRecords[rowToChange].checked = !newRecords[rowToChange].checked;
    setLoansRecords(newRecords);
    let sanctionSum = Number(totalSanctionAmount);
    let netDisbSum = Number(totalNetDisbAmount);
    let count = Number(totalSelection);

    if (!event?.target?.checked) {
      let selectedRecords = [...selectedLoanRecords];
      const index = selectedRecords.findIndex((item) => {
        return item._id === data._id;
      });
      selectedRecords.splice(index, 1);
      setSelectedLoanRecords(selectedRecords);
      setIsAllSelect(false);
      selectedLoanRecords.map((item) => {
        if (item._id === data._id) {
          if (data.sanction_amount) sanctionSum -= parseFloat(item.sanction_amount);
          if (data.net_disbur_amt) netDisbSum -= parseFloat(item.net_disbur_amt);
          count -= 1;
        }
      });
    }
    if (event?.target?.checked) {
      setSelectedLoanRecords([...selectedLoanRecords, data]);
      if (data.sanction_amount) {
        sanctionSum += parseFloat(data.sanction_amount);
      }
      if (data.net_disbur_amt) netDisbSum += parseFloat(data.net_disbur_amt);
      count += 1;
    }
    setTotalSelectCount(count);
    setTotalSanctionAmount(sanctionSum);
    setTotalNetDisbAmount(netDisbSum);
  };

  React.useEffect(() => {
    if (loansRecords?.length && selectedLoanRecords?.length === loansRecords?.length) {
      setIsAllSelect(true);
    }
  }, [selectedLoanRecords]);

  const handleChangeCompanyProduct = () => {
    setIsAllSelect(false);
    setLoansRecords(
      loansRecords.map((item) => {
        return {
          ...item,
          checked: false,
        };
      }),
    );
    setSelectedLoanRecords([]);
    setLoansRecords([]);
    setTotalSelectCount(0);
    setTotalSanctionAmount(0);
    setTotalNetDisbAmount(0);
    setAvailableBalance(0);
  };

  const handleClosePopUp = () => {
    setIsOpenPopUp(!isOpenPopUp);
    setSelectedLoanRecords([]);
    setErrorRowsText('');
    setSuccessRows([]);
    setFailRows([]);
  };

  const columnsFirst = [
    {
      id: 'status',
      format: (rowData) => <input disabled={!checkAccessTags(['tag_disbursement_approval_read_write']) ? true : false} style={{ height: '20px', width: '20px', marginLeft: '-20px' }} type="checkbox" id={rowData._id} key={rowData._id} checked={rowData.checked} onChange={(e) => handleSelectSingleRecord(rowData, e)} />,
      label: (
        <div>
          <div style={{ marginRight: '10px' }}>
            <input disabled={!checkAccessTags(['tag_disbursement_approval_read_write']) ? true : false} type="checkbox" style={{ height: '20px', width: '20px', marginTop: '3px', marginLeft: '-20px' }} checked={isAllSelect} onChange={handleSelectAllRecord} />
          </div>
        </div>
      ),
    },
    { id: 'loan_id', label: 'Loan Id' },
    { id: 'Cust Name', format: (rowData) => rowData.first_name + ' ' + rowData.last_name, label: 'Customer Name' },
    { id: 'status', format: (rowData) => rowData.company[0].name + ' ' + rowData.company[0].code, label: 'Company Name' },
    { id: 'status', format: (rowData) => rowData.product[0].name, label: 'Product Name' },
    { id: 'sanction_amount', label: 'Loan Amt' },
    { id: 'net_disbur_amt', label: 'Net Dis Amt' },
    { id: 'Application Date', format: (rowData) => moment(rowData.created_at).format('YYYY-MM-DD'), label: 'Appl Date' },
    { id: 'Statuse', format: (rowData) => statusToDisplay[rowData.status], label: 'Status' },
  ];

  const columnSecond = [
    {
      id: 'status',
      format: (rowData) => (
        <>
          <input style={{ height: '20px', width: '20px', marginTop: '3px', marginLeft: '-20px' }} type="checkbox" id={rowData._id} key={rowData._id} checked={rowData.checked} onChange={(e) => handleSelectSingleRecord(rowData, e)} />
          <label htmlFor={rowData._id}></label>
        </>
      ),
      label: (
        <div>
          <div style={{ marginRight: '10px' }}>
            <input type="checkbox" style={{ height: '20px', width: '20px', marginLeft: '-20px' }} checked={isAllSelect} onChange={handleSelectAllRecord} />
            <label></label>
          </div>
        </div>
      ),
    },
    { id: 'loan_id', label: 'Loan Id' },
    { id: 'Cust Name', format: (rowData) => rowData.first_name + ' ' + rowData.last_name, label: 'Customer Name' },
    { id: 'status', format: (rowData) => rowData.company[0].name + ' ' + rowData.company[0].code, label: 'Company Name' },
    { id: 'status', format: (rowData) => rowData.product[0].name, label: 'Product Name' },
    { id: 'sanction_amount', label: 'Loan Amt' },
    { id: 'net_disbur_amt', label: 'Net Dis Amt' },
    { id: 'Application Date', format: (rowData) => moment(rowData.created_at).format('YYYY-MM-DD'), label: 'Appl Date' },
    { id: 'Statuse', format: (rowData) => statusToDisplay[rowData.status], label: 'Status' },
  ];

  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      <DisbursementRequestPopUp isOpen={isOpenPopUp} successRows={successRows} failRows={failRows} totalRequest={selectedLoanRecords} errorRowsText={errorRowsText} isProgressStop={isProgressStop} setIsOpen={() => handleClosePopUp()} isProgressStart={isProgressStart} title="Disbursement approve progress" />
      <Typography
        sx={{
          mt: 2,
          ml: 2,
        }}
        variant="h6"
      >
        Disbursement approval
      </Typography>
      <CardContent>
        <Grid
          xs={12}
          item
          sx={{
            margin: '10px 0',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <CompanyDropdown
                placeholder="Select company"
                company={company}
                onCompanyChange={(value) => {
                  setCompany(value ? value : '');
                  setProduct([]);
                  handleChangeCompanyProduct();
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <ProductDropdown
                placeholder="Select product"
                onProductChange={(value) => {
                  setProduct(value ? value : '');
                  handleChangeCompanyProduct();
                }}
                company={company || null}
                product={product || null}
              />
            </Grid>
            <Grid item xs={3} sx={{ mt: 1, ml: 2 }}>
              <Button variant="contained" onClick={onSearchClick}>
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button variant="contained" size="large" onClick={() => handleStartStatusUpdate('disbursal_approved')} disabled={!selectedLoanRecords.length}>
              Approve
            </Button>
            <Button className="ml-2" variant="contained" size="large" color="error" onClick={() => handleStartStatusUpdate('rejected')} disabled={!selectedLoanRecords.length}>
              Reject
            </Button>
          </Grid>
          <Grid sx={{ margin: '10px 0px' }}>
            <Grid container>
              {totalSelection > 0 ? (
                <Grid item xs={4}>
                  <div style={{ width: '400px', color: 'black', border: '1px solid rgb(229, 229, 232)', borderRadius: '10px' }}>
                    <div style={{ textAlign: 'center', alignItems: 'center', backgroundColor: 'rgb(94, 114, 228)', color: 'rgb(0, 0, 0)', height: '35px', display: 'flex', justifyContent: 'space-evenly', borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>Selection summary</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', backgroundColor: '#E5E5E8', borderBottom: '1px solid rgb(229, 229, 232)', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                      <span>Available Balance:</span>
                      <span>{Number(walletConfigCheck) ? Number(availableBalance).toFixed(2) : 'NA'}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', borderBottom: '1px solid rgb(229, 229, 232)', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                      <span>Selected rows:</span>
                      <span>{totalSelection}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', backgroundColor: '#E5E5E8', borderBottom: '1px solid rgb(229, 229, 232)', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                      <span>Total loan amount:</span>
                      <span>{totalSanctionAmount.toFixed(2)}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', height: '33px', padding: '8px', textAlign: 'center', alignItems: 'center' }}>
                      <span>Total transaction amount:</span>
                      <span>{totalNetDisbAmount.toFixed(2)}</span>
                    </div>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid sx={{ mt: 1 }}>
            <Divider />
          </Grid>

          {loansRecords.length ? (
            <div>
              {isTagged ? (
                checkAccessTags(['tag_disbursement_approval_read', 'tag_disbursement_approval_read_write']) ? (
                  <div className="table-alignment-css">
                    <Table customStyle={{ width: '100%', display: 'grid', gridTemplateColumns: '4% 20% 12% 12% 12% 10% 10% 9% 9%' }} data={loansRecords} columns={columnsFirst} />
                    <Pagination itemsPerPage={rowsPerPage} totalItems={count} rowsPerPageOptions={[10, 20, 50, 100]} onPageChange={handleChangePage} showOptions={true} setRowLimit={(e) => handleChangeRowsPerPage(e)} />
                  </div>
                ) : null
              ) : (
                <div className="table-alignment-css">
                  <Table customStyle={{ width: '100%', display: 'grid', gridTemplateColumns: '4% 20% 12% 12% 12% 10% 10% 9% 9%' }} data={loansRecords} columns={columnSecond} />
                  <Pagination itemsPerPage={rowsPerPage} totalItems={count} rowsPerPageOptions={[10, 20, 50, 100]} onPageChange={handleChangePage} showOptions={true} setRowLimit={(e) => handleChangeRowsPerPage(e)} />
                </div>
              )}
            </div>
          ) : null}
        </Grid>
      </CardContent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(null, null)(DisbursementApprove);
