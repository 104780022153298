import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function getMonthlyCoaReportsAPI(payload) {
  return axios.post(BASE_URL + `monthly-coa-report`, payload);
}

export function downloadMonthlyCoaReportsAPI(payload) {
  return axios.post(BASE_URL + `download-monthly-coa-report`, payload);
}
