export function repaymentV2FormPostWatcher(data, resolve, reject) {
  return {
    type: "REPAYMENTV2_FORM_POST_WATCHER",
    payload: data,
    resolve,
    reject
  };
}

export function getLapChargesDetailsWatcher(data, resolve, reject) {
  return {
    type: "GET_LAP_CHARGES_DETAILS",
    payload: data,
    resolve,
    reject
  };
}
