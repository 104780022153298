import { Alert_Type } from './string_constants';

const Colors = {
  Deviation_Color: '#EDA12F',
  Upload_btn_color: 'rgba(19, 76, 222, 1)',
  Upload_btn_disable: '#808080',
  Approved_Color: 'green',
  Separator_Color: '#C0C1C8',
  Border_Hightlightened_Color: '2px solid green',
};

const Alert_Font_Color = {
  [Alert_Type.Error]: '#D92B2B',
  [Alert_Type.Info]: '#4d94ff',
  [Alert_Type.Success]: '#22A53F',
  [Alert_Type.Warning]: '#e77b00',
};
const Alert_Bg_Color = {
  [Alert_Type.Error]: 'rgb(247,224,225)',
  [Alert_Type.Info]: 'rgb(230, 240, 255)',
  [Alert_Type.Success]: '#F4FEF7',
  [Alert_Type.Warning]: 'rgb(255, 235, 214)',
};

export { Colors, Alert_Font_Color, Alert_Bg_Color };
