export function getSFRCompaniesWatcher(payload, resolve, reject) {
  return {
    type: 'GET_SFR_COMPANIES_WATCHER',
    payload,
    resolve,
    reject,
  };
}

export function getSFRProductsWatcher(payload, resolve, reject) {
  return {
    type: 'GET_SFR_PRODUCTS_WATCHR',
    payload,
    resolve,
    reject,
  };
}

export function getProductDetailsWatcher(payload, resolve, reject) {
  return {
    type: 'GET_SRF_PRODUCT_DETAILS_WATCHER',
    payload,
    resolve,
    reject,
  };
}

export function createBusinessModelWatcher(payload, resolve, reject) {
  return {
    type: 'CREATE_BUSINESS_MODEL_WATCHER',
    payload,
    resolve,
    reject,
  };
}

export function getBusinessModelWatcher(payload, resolve, reject) {
  return {
    type: 'GET_BUSINESS_MODEL_WATCHER',
    payload,
    resolve,
    reject,
  };
}

export function CheckBusinessModelExistWatcher(payload, resolve, reject) {
  return {
    type: 'CHECK_BUSINESS_MODEL_EXIST',
    payload,
    resolve,
    reject,
  };
}
