import axios from 'axios';
import { BASE_URL } from '../../constants/apiUrls';

export function patchfldgAPI(payload) {
  return axios.put(BASE_URL + `fldg-partner/${payload?.id}`, payload);
}

export function postfldgAPI(payload) {
  return axios.post(BASE_URL + `fldg-partner`, payload);
}

export function getFldgPartnerAPI(payload) {
  return axios.post(BASE_URL + `fldg-partner-available`, payload);
}
