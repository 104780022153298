import React, { useState, ref, useEffect } from 'react';
import Button from 'react-sdk/dist/components/Button/Button';
import PlusIcon from '../../../views/lending/images/add-icon.svg';
import DeleteIcon from '../../../views/lending/images/trash.svg';
import TextField from '@mui/material/TextField';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import ReveiwIcon from '../../../views/lending/images/Appication-under-review-icon.svg';
import './bookLoans.style.css';
import { AdditionalDocumentData } from './AdditionalDocumentData';
import { patchLapDetailsWatcher, patchLapSaveAsDraftDetailsWatcher, patchLapDocDeleteWatcher } from '../../actions/lap.action';
import { storedList } from '../../../util/localstorage';
import { useDispatch, useSelector } from 'react-redux';
import { getBookLoanDetailsWatcher, getLapLoanDocumentsWatcher } from '../../../lap/actions/bookLoan.action';
import { uploadLoanDocumentsWatcher } from '../../../../src/actions/loanDocuments';
import { getLapLeadSectionStatusWatcher } from './../../actions/status.action';
import ViewDocumentCustom from '../../components/document/ViewDocumentCustom';
import { SectionData } from 'lap/config/sectionData';
import { documentCode as documentCodeList } from 'lap/config/docCode';
import { convertImagesToPdf } from '../../components/uploadFileInput/util/helper';
import { useHistory } from 'react-router-dom';
import { LeadStatus } from '../../config/LeadStatus';
import Preloader from '../../../components/custom/preLoader';
import { getSubmitButton } from 'lap/util/resuableJsx/reusableJsx';
import { getSaveAsDraftButton } from 'lap/util/resuableJsx/reusableJsx';
import { Number_Constants } from '../../constants/number_constants';
import { ErrorConstants } from 'lap/constants/error_constants';
import { Application_Mode, Additional_Docs_Label, Comment, Verify_Status, Success_Message } from '../../constants/string_constants';
import { Colors } from '../../constants/color_constant';
import { Pointer_Events } from '../../constants/css_event_constant';
import SlidingAlert from 'lap/components/SlidingAlert/SlidingAlert';
import { ALLOW_ONLY_SINGLE_SPACE } from '../../constants/pattern_constants';

const AdditionalDocuments = (props) => {
  const { setNavState, setNavIconPrefixState, companyId, LAPProductId, loanAppId, navIconPrefixState } = props;
  const initialItemStates = {};
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectFile, setSelectFile] = useState('');
  const [uploadFileName, setUploadFileName] = useState([]);
  const [uploadFile, setUploadFIle] = useState(false);
  const [uploadFileCount, setUploadFileCount] = useState(Number_Constants.ZERO);
  const [options, setOptions] = useState(AdditionalDocumentData);
  const [updateDocumentList, setUpdateDocumentList] = useState([]);
  const [clickedOkayButton, setClickedOkayButton] = useState(false);
  const [selectReasonComment, setSelectReasonComment] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [itemStates, setItemStates] = useState(initialItemStates);
  const [documentCode, setDocumentCode] = useState();
  const [sectionStatusCheck, setSectionStatusCheck] = useState('');
  const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.profile.loading);
  const [documentUploadList, setDocumentUploadList] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [addDocument, setAddDocument] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentDocType, setCurrentDocType] = useState('');
  const [currentDocUrl, setCurrentDocUrl] = useState('');
  const [fileTitle, setFileTitle] = useState('');
  const [onlyView, setOnlyView] = useState(false);
  const [disableDraftButton, setDisableDraftButton] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const sectionName = 'additional-documents';
  const fileInputRefs = {};
  const navigate = useHistory();

  const hasMatchingCode = rows.some((item) => ['130', '229', '230', '231', documentCodeList.guar_cibil_report].includes(item.code)) || addDocument.some((item) => ['130', '229', '230', '231', documentCodeList.guar_cibil_report].includes(item.code));
  let totalCount;

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOkay = () => {
    setOpenDialog(false);
    setClickedOkayButton(true);
    setNavState('Offer');
    setNavIconPrefixState((prevState) => ({
      ...prevState,
      'Additional Docs': 'success',
    }));
  };

  const handleDelete = async (id, row) => {
    const payload = {
      loanAppId: loanAppId,
      code: [row.code],
      tokenData: {
        user_id: user._id,
        product_id: LAPProductId,
        company_id: companyId,
      },
    };
    new Promise((resolve, reject) => {
      dispatch(patchLapDocDeleteWatcher(payload, resolve, reject));
    })
      .then((response) => {
        if (row.code) {
          const deletedRow = AdditionalDocumentData.filter((item) => item.documentCode === row.code);
          setOptions((previousOptions) => [...deletedRow, ...previousOptions]);
          setUploadFileCount(uploadFileCount - Number_Constants.ONE);
        }
        let updatedRow = [];
        updatedRow = rows.reduce((accumulator, row) => {
          if (row.id !== id) {
            accumulator.push({
              ...row,
              id: accumulator.length + 1,
            });
          }
          return accumulator;
        }, updatedRow);
        setRows(updatedRow);
        setDisableButton(false);
        showAlert(response?.message || 'Document deleted successfully', 'success');
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const handleAddDocument = () => {
    const newRow = {
      id: rows.length + 1,
      document: 'New Document',
      action: 'Upload',
    };
    setRows((prevRows) => [...prevRows, newRow]);
    setDisableButton(true);
  };
  const handleAdditionalDocumentDraft = () => {
    setDisableDraftButton(true);
    let data = {
      section: 'additional-documents',
      addi_docs_comment: selectReasonComment,
    };
    let bodyObject = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null && value !== ' ' && value !== ''));
    const payload = {
      loan_app_id: loanAppId,
      userData: {
        user_id: user._id,
        product_id: LAPProductId,
        company_id: companyId,
      },
      bodyData: bodyObject,
    };
    new Promise((resolve, reject) => {
      dispatch(patchLapSaveAsDraftDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setDisableDraftButton(false);
        showAlert('Draft saved successfully', 'success');
      })
      .catch((error) => {
        showAlert(error?.message ?? 'Error while saving draft', 'error');
      });
  };
  async function docUpload(fileData, loanAppId, sectionName) {
    let payload = {
      loan_app_id: loanAppId,
      section: sectionName,
      user: user,
      companyId: companyId,
      productId: LAPProductId,
    };

    const response = await new Promise((resolve, reject) => {
      dispatch(getBookLoanDetailsWatcher(payload, resolve, reject));
    });
    const dataForUpload = {
      submitData: {
        base64pdfencodedfile: fileData['uploadFileBase64'],
        fileType: fileData['uploadFileName'],
        code: fileData['code'],
        loan_app_id: loanAppId,
        borrower_id: response.data[0].borrower_id,
      },
      userData: {
        company_id: companyId,
        product_id: LAPProductId,
        user_id: user._id,
      },
    };
    try {
      dispatch(
        uploadLoanDocumentsWatcher(dataForUpload, (response) => {
          setUploadFileCount(uploadFileCount + Number_Constants.ONE);
          setUploadFIle(true);
          setDisableButton(false);
          showAlert(response?.uploadDocumentData?.message, 'success');
          setDocumentUploadList((prevState) => [
            ...prevState,
            {
              doc_name: selectFile,
              doc_code: fileData['code'],
            },
          ]);
          (error) => {
            showAlert(error?.response?.data?.message, 'error');
          };
        }),
      );
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }
  const handleFileInputChange = (event, id, isXML = false) => {
    const file = event.target.files[0];
    setItemStates((prevState) => ({
      ...prevState,
      [id]: {
        reuploadFile: false,
        uploadFileName: '',
        uploadFileBase64: '',
        buttonState: 'loader',
      },
    }));

    if (file) {
      const selectedFile = event?.target?.files;
      if (selectedFile[0]['size'] > 5e6) {
        showAlert('File size should not be greater than 5 MB', 'error');
        setItemStates((prevState) => ({
          ...prevState,
          [id]: {
            reuploadFile: false,
            uploadFileName: '',
            uploadFileBase64: '',
            buttonState: 'button',
          },
        }));
        return;
      }
      const fileType = selectedFile[0]['name'];
      const fileExtension = fileType.split('.').pop();
      if ((isXML && fileExtension.toLowerCase() != 'xml' && fileExtension.toLowerCase() != 'json') || (!isXML && fileExtension.toLowerCase() != 'pdf' && fileExtension.toLowerCase() != 'png' && fileExtension.toLowerCase() != 'jpg' && fileExtension.toLowerCase() != 'jpeg')) {
        showAlert(`${isXML ? 'Only XML file is allowed ' : 'Only JPG,JPEG,PDF & PNG file is allowed'}`, 'error');
        setItemStates((prevState) => ({
          ...prevState,
          [id]: {
            reuploadFile: false,
            uploadFileName: '',
            uploadFileBase64: '',
            buttonState: 'button',
          },
        }));
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        let base64Data = reader.result.split(',')[1]; // Extract the base64 data part
        if (['png', 'jpeg', 'jpg'].includes(fileExtension)) {
          base64Data = convertImagesToPdf(base64Data);
        }
        const uploadFileName = file.name.length <= 20 ? file.name : file.name.slice(0, 20) + '...';
        const newAction = Application_Mode.Views;
        const updatedRows = rows.map((row) => {
          if (row.id === id) {
            return { ...row, action: newAction };
          }
          return row;
        });
        setRows(updatedRows);
        setItemStates((prevState) => ({
          ...prevState,
          [id]: {
            reuploadFile: true,
            uploadFileName,
            uploadFileBase64: base64Data,
            buttonState: 'button',
          },
        }));

        const uploadedData = {
          id,
          uploadFileName,
          uploadFileBase64: base64Data,
        };
        let docCode = rows.find((item) => item.id === id);
        // Assign the uploaded file name to display the file name in the UI
        setUploadFileName((prevState) => ({
          ...prevState,
          [docCode.code]: fileType,
        }));

        uploadedData.code = docCode.code;
        loanAppId != null && documentCode != null && sectionName && docUpload(uploadedData, loanAppId, sectionName);
      };
      reader.readAsDataURL(file);
    }
  };

  const customOkayButton = {
    height: '48px',
    padding: '10px 24px',
    width: 'max-content',
    gap: '16px',
    color: '#FFF',
    fontFamily: 'Montserrat-Regular',
    fontSize: '16px',
    borderRadius: '40px',
    background: 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%)',
  };

  const shouldDisplayHeaders = rows.length >= 1;

  const handleDropdownChange = (event, row) => {
    row.code = event.documentCode;
    setDocumentCode(event.documentCode);
    setSelectFile(event.value);
    const updatedOptions = options.filter((option) => option.value == event.value);
    setUpdateDocumentList([...updateDocumentList, ...updatedOptions]);
  };

  const triggerFileInput = (itemId, docCode) => {
    docCode ? setDocumentCode(docCode) : null;
    fileInputRefs[itemId].click();
  };
  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
  };

  // Get a count of a row value for the selected document
  function countOccurrences(array, key) {
    let count = 0;
    array.forEach((obj) => {
      if (obj[key]) {
        count++;
      }
    });
    return count;
  }

  const handleFinalSubmit = (e) => {
    totalCount = countOccurrences(rows, 'code');
    if (uploadFileCount === totalCount) {
      setSectionStatusCheck('inProgress');
      const postData = {
        user_id: user._id,
        section: sectionName,
        loan_app_id: loanAppId,
        lap_company_id: companyId,
        lap_product_id: LAPProductId,
        status: 'in_progress',
        addi_docs_comment: selectReasonComment,
        section_sequence_no: SectionData.additional_docs.section_sequence_no,
        section_name: SectionData.additional_docs.section_name,
        sub_section_code: SectionData.additional_docs.additional_doc_section_submit.sub_section_code,
        sub_section_name: SectionData.additional_docs.additional_doc_section_submit.sub_section_name,
        sub_section_sequence_no: SectionData.additional_docs.additional_doc_section_submit.sub_section_sequence_no,
        section_code: SectionData.additional_docs.section_code,
      };
      new Promise((resolve, reject) => {
        dispatch(patchLapDetailsWatcher(postData, resolve, reject));
      })
        .then((response) => {
          if (props.leadStatus == LeadStatus.draft.value) {
            showAlert(Success_Message.Lead_Submit, 'success');
          }
          setSectionStatusCheck('completed');
          setOpenDialog(true);
          if (props.leadStatus == LeadStatus.follow_up_doc.value) {
            navigate.push(`/admin/lap/lead/${loanAppId}/view`);
          }
          setIsDisabled(true);
        })
        .catch((error) => {
          showAlert(error?.message ?? 'Error while creating lead', 'error');
        });
    } else {
      showAlert(ErrorConstants.ERROR_MSG_FOR_NOT_UPLOAD_THE_FILE, 'error');
    }
  };

  const fetchLoanDetails = () => {
    let payload = {
      loan_app_id: loanAppId,
      section: 'additional-documents',
      user: user,
      companyId: companyId,
      productId: LAPProductId,
    };
    new Promise((resolve, reject) => {
      dispatch(getBookLoanDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setSelectReasonComment(response.data[Number_Constants.ZERO].addi_docs_comment);
      })
      .catch((error) => {
        console.log(error, error?.message);
      });
  };
  useEffect(() => {
    if (loanAppId && LAPProductId && companyId) {
      const payloadForDoc = {
        loanAppID: loanAppId,
        companyId: companyId ?? '',
        productId: LAPProductId ?? '',
        user: user,
      };
      let FetchedLoanDocuments;
      new Promise((resolve, reject) => {
        dispatch(getLapLoanDocumentsWatcher(payloadForDoc, resolve, reject));
      })
        .then((response) => {
          FetchedLoanDocuments = response;
          let newArr = [];
          FetchedLoanDocuments.map((item) => {
            if ((parseInt(item?.code) >= documentCodeList?.affidavit && parseInt(item?.code) <= documentCodeList?.loan_statement) || parseInt(item?.code) == documentCodeList?.fi_report_2 || parseInt(item?.code) == documentCodeList?.fi_report_3) {
              newArr.push({ ...item, id: item?._id });
            }
          });
          setUploadFileCount(newArr.length);
          setAddDocument(newArr);
          let payload = {
            loanAppID: loanAppId,
            user: user,
            company_id: companyId ?? '',
            product_id: LAPProductId ?? '',
          };
          new Promise((resolve, reject) => {
            dispatch(getLapLeadSectionStatusWatcher(payload, resolve, reject));
          })
            .then((response) => {
              const object = response?.find((obj) => obj['section_code'] == 'additional_doc');
              if (object && object.subsections[Number_Constants.ZERO].sub_section_status && object.section_status != 'in_progress') {
                setOnlyView(true);
              } else {
                let arr = [];
                FetchedLoanDocuments.map((item) => {
                  if (parseInt(item?.code) == 130 || parseInt(item?.code) == 229 || parseInt(item?.code) == 230 || parseInt(item?.code) == 231 || parseInt(item?.code) == documentCodeList.guar_cibil_report) {
                    arr.push({ ...item, id: item._id });
                  }
                  if ((parseInt(item?.code) >= documentCodeList.affidavit && parseInt(item?.code) <= documentCodeList.loan_statement) || parseInt(item?.code) == documentCodeList?.fi_report_2 || parseInt(item?.code) == documentCodeList?.fi_report_3) {
                    arr.push({ ...item, id: item._id });
                  }
                });
                setRows(arr);
                if (arr.length > 0) {
                  setDisableButton(false);
                }
                const filteredOptions = options.filter((option) => !arr.some((item) => item.file_type === option.value));
                setOptions(filteredOptions);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          showAlert(error.response?.data?.message, 'error');
        });
      fetchLoanDetails();
    }
  }, [documentUploadList]);

  useEffect(() => {
    setOptions((prevOptions) => prevOptions.filter((option) => !documentUploadList.some((upload) => upload.doc_code === option.documentCode)));
  }, [documentCode, documentUploadList]);

  const renderViewDocumentLabel = (documentDetails) => {
    const response = AdditionalDocumentData.find((doc) => doc.value === documentDetails.file_type);
    return response.label;
  };

  const renderViewDocumentsUi = (documentDetails) => {
    return (
      <div
        key={documentDetails._id}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '80px',
          backgroundColor: 'white',
          color: 'black',
          fontSize: '16px',
          marginTop: '10px',
          padding: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #ccc',
        }}
      >
        <div
          style={{
            width: '78%',
            display: 'flex',
            height: '25px',
            alignItems: 'center',
          }}
        >
          {renderViewDocumentLabel(documentDetails)}
        </div>
        <div
          onClick={() => {
            if (onlyView) {
              setCurrentDocType(documentDetails?.file_type);
              setCurrentDocUrl(documentDetails?.file_url);
              setFileTitle(documentDetails?.file_type);
              setIsOpen(true);
            } else {
              triggerFileInput(documentDetails.id);
            }
          }}
          style={{
            width: '20%',
            color: 'rgba(19, 76, 222, 1)',
            cursor: 'pointer',
            fontFamily: 'Montserrat-SemiBold',
            fontSize: '16px',
          }}
        >
          {onlyView ? Application_Mode.View : Application_Mode.Views}
        </div>
        {/* <input type="file" ref={(ref) => (fileInputRefs[documentDetails.id] = ref)} id={documentDetails._id} style={{ display: 'none' }} accept={".jpg, .jpeg, .png, .pdf"} onChange={(event) => handleFileInputChange(event, documentDetails._id)} /> */}
      </div>
    );
  };

  const handleCommentSection = (event) => {
    const formattedStr = (event.target.value || '').trimStart().replaceAll(ALLOW_ONLY_SINGLE_SPACE, ' ');
    const limittedValue = Array.from(formattedStr).splice(Number_Constants.ZERO, Number_Constants.TWO_HUNDRED).join('');
    setSelectReasonComment(limittedValue);
  };

  const renderUploadDocumentUi = (documentDetails) => {
    const isNonEditableCode = ['130', '229', '230', '231', documentCodeList.guar_cibil_report].includes(documentDetails.code);
    return (
      <div
        key={documentDetails.id}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '80px',
          backgroundColor: 'white',
          color: 'black',
          fontSize: '16px',
          marginTop: '5px',
          padding: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #ccc',
        }}
      >
        <div
          style={{
            width: '78%',
            display: 'flex',
            height: '25px',
            alignItems: 'center',
          }}
        >
          {!isNonEditableCode && <img src={DeleteIcon} alt="Non Editable" style={{ cursor: 'pointer' }} onClick={(event) => handleDelete(documentDetails.id, documentDetails)} />}
          <span style={{ marginLeft: isNonEditableCode ? '55px' : '30px' }}>
            <InputBox
              label={Additional_Docs_Label.Select_Document_Label}
              isDrawdown={documentDetails.file_type ? false : true}
              isBoxType={documentDetails.file_type ? Verify_Status.Icon : ''}
              isDisabled={false}
              initialValue={AdditionalDocumentData.find((doc) => doc.documentCode === documentDetails.code)?.label}
              customClass={{
                height: '56px',
                maxWidth: '40vw',
                width: '40vw',
                border: documentDetails.file_url ? Colors.Border_Hightlightened_Color : ' ',
              }}
              id={documentDetails?._id}
              customDropdownClass={{ marginTop: '7px', zIndex: 5 }}
              options={options}
              onClick={(value) => handleDropdownChange(value, documentDetails)}
            />
          </span>
        </div>
        <div
          onClick={() => {
            if (isNonEditableCode || documentDetails.file_url) {
              setCurrentDocType(documentDetails?.file_type);
              setCurrentDocUrl(documentDetails?.file_url);
              setFileTitle(documentDetails?.file_type);
              setIsOpen(true);
            } else {
              triggerFileInput(documentDetails.id);
            }
          }}
          style={{
            width: '20%',
            color: documentDetails.code ? Colors.Upload_btn_color : Colors.Upload_btn_disable,
            cursor: documentDetails.code ? Pointer_Events.Cursor_Pointer : Pointer_Events.Cursor_None,
            fontFamily: 'Montserrat-SemiBold',
            fontSize: '16px',
          }}
        >
          {!isNonEditableCode ? (documentDetails?.action ? documentDetails?.action : Application_Mode.Views) : Application_Mode.View}
        </div>
        <input disabled={documentDetails?.code ? false : true} type="file" ref={(ref) => (fileInputRefs[documentDetails.id] = ref)} id={documentDetails.id} style={{ display: 'none' }} accept=".jpg, .jpeg, .png, .pdf" onChange={(event) => handleFileInputChange(event, documentDetails.id)} />
      </div>
    );
  };

  return (
    <div className="additionalDocuments-container-style">
      {openDialog && (
        <FormPopup open={openDialog} isOpen={openDialog} onClose={handleClose} customHeaderStyle={{ padding: '0px 16px 0px 16px' }} isCancel={true}>
          <div>
            <div style={{}}>
              <div style={{ textAlign: 'center' }}>
                <img src={ReveiwIcon} alt="search gif" />
                <div
                  style={{
                    fontFamily: 'Montserrat-Bold',
                    fontSize: '24px',
                    color: '#161719',
                    marginTop: '35px',
                  }}
                >
                  Your application {loanAppId} is in review
                </div>
                <div
                  style={{
                    fontFamily: 'Montserrat-Medium',
                    fontSize: '18px',
                    color: '#767888',
                    marginTop: '5px',
                  }}
                >
                  {' '}
                  We will notify you once the offer is <br /> generated.
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {' '}
              <Button customStyle={customOkayButton} label="Okay" buttonType="primary" onClick={handleOkay} />{' '}
            </div>
          </div>
        </FormPopup>
      )}
      {clickedOkayButton ? (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '17%',
          }}
        ></div>
      ) : (
        <div style={{ marginLeft: '20px' }}>
          <h2 style={{ fontFamily: 'Montserrat-Bold', fontSize: '24px' }}>{Additional_Docs_Label.Upload_Doc_Label}</h2>
          {!onlyView && <div style={{ fontFamily: 'Montserrat-Medium', fontSize: '16px' }}>Upload any other supporting document.</div>}
          {!onlyView && rows.length == 0 && navIconPrefixState['Additional Docs'] !== 'success' ? (
            <div style={{ marginTop: '25px' }}>
              <Button
                customStyle={{
                  height: '48px',
                  fontSize: '16px',
                  display: 'flex',
                  justifyContent: 'center',
                  boxShadow: 'none',
                  backgroundColor: 'white',
                  fontFamily: 'Montserrat-Regular',
                  padding: '22px',
                  border: '1px solid #134CDE',
                  color: 'rgba(19, 76, 222, 1)',
                }}
                label="Add Document"
                buttonType="secondary"
                imageButton={PlusIcon}
                imageButtonHover={PlusIcon}
                iconButton="btn-secondary-download-button"
                onClick={handleAddDocument}
              />
            </div>
          ) : null}

          {onlyView && (
            <div
              style={{
                width: '98%',
                display: 'flex',
                flexDirection: 'column',
                color: 'black',
                fontSize: '16px',
                marginTop: '25px',
                borderRadius: '10px',
                padding: '10px',
                border: '1px solid #E5E5E8',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '75px',
                  alignItems: 'center',
                  backgroundColor: '#E5E5E8',
                  padding: '30px',
                  borderTopRightRadius: '10px',
                  borderTopLeftRadius: '10px',
                  fontFamily: 'Montserrat-SemiBold',
                }}
              >
                <div style={{ width: '78%' }}>DOCUMENT</div>
                <div style={{ width: '20%' }}>ACTION</div>
              </div>

              {addDocument.map(renderViewDocumentsUi)}
              <div style={{ marginTop: '25px' }}>
                <Button
                  customStyle={{
                    height: '48px',
                    fontSize: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: 'none',
                    backgroundColor: 'white',
                    border: 'none',
                    fontFamily: 'Montserrat-SemiBold',
                    marginBottom: '15px',
                    padding: '25px',
                    marginLeft: '10px',
                    color: '#134CDE',
                  }}
                  label="Add Document"
                  buttonType="secondary"
                  imageButton={PlusIcon}
                  imageButtonHover={PlusIcon}
                  iconButton="btn-secondary-download-button"
                  onClick={handleAddDocument}
                  isDisabled={disableButton}
                />
              </div>
            </div>
          )}

          {shouldDisplayHeaders && (
            <div
              style={{
                width: '98%',
                display: 'flex',
                flexDirection: 'column',
                color: 'black',
                fontSize: '16px',
                marginTop: '25px',
                borderRadius: '10px',
                padding: '10px',
                border: '1px solid #E5E5E8',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '75px',
                  alignItems: 'center',
                  backgroundColor: '#E5E5E8',
                  padding: '30px',
                  borderTopRightRadius: '10px',
                  borderTopLeftRadius: '10px',
                  fontFamily: 'Montserrat-SemiBold',
                }}
              >
                <div style={{ width: '78%' }}>DOCUMENT</div>
                <div style={{ width: '20%' }}>ACTION</div>
              </div>

              {rows.map(renderUploadDocumentUi)}
              <div style={{ marginTop: '25px' }}>
                <Button
                  customStyle={{
                    height: '48px',
                    fontSize: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: 'none',
                    backgroundColor: 'white',
                    border: 'none',
                    fontFamily: 'Montserrat-SemiBold',
                    marginBottom: '15px',
                    padding: '25px',
                    marginLeft: '10px',
                    color: '#134CDE',
                  }}
                  label="Add Document"
                  buttonType="secondary"
                  imageButton={PlusIcon}
                  imageButtonHover={PlusIcon}
                  iconButton="btn-secondary-download-button"
                  onClick={handleAddDocument}
                  isDisabled={disableButton}
                />
              </div>
            </div>
          )}

          <div style={{ marginTop: rows?.length ? '50px' : '90px' }}>
            <h3 style={{ fontFamily: 'Montserrat-SemiBold', fontSize: '20px' }}>Please provide any relevant information here</h3>
            <div style={{ marginTop: '15px', display: 'grid' }}>
              <TextField
                sx={{
                  width: '98%',
                  color: 'pink',
                  fontFamily: 'Montserrat-Regular',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '150%',
                  gridColumn: '1 / span 1',
                  gridRow: '1 / span 1',
                }}
                fullWidth
                disabled={navIconPrefixState['Additional Docs'] == 'success' ? true : onlyView ? true : false}
                value={selectReasonComment ?? ''}
                variant="outlined"
                type="text"
                placeholder="Comment"
                multiline={true}
                minRows={5}
                size="medium"
                InputLabelProps={{ shrink: false }}
                InputProps={{ sx: { borderRadius: '8px' } }}
                onChange={(event) => handleCommentSection(event)}
              />
              <p
                style={{
                  gridColumn: '1 / span 1',
                  gridRow: '1 / span 1',
                  alignSelf: 'end',
                  margin: '0px',
                  padding: '16.5px 14px',
                }}
              >
                {Comment.Comment_Data}
              </p>
            </div>
          </div>
          {props.type == Application_Mode.View || isDisabled || navIconPrefixState['Additional Docs'] === 'success' ? null : (
            <div className="book-loan-button-container book-loan-button-alignment-double-button">
              <React.Fragment>
                {getSubmitButton(addDocument?.length > 0 ? (props.leadStatus !== LeadStatus.draft ? 'Save & Submit' : 'Submit & Next') : 'Skip & Submit', (e) => handleFinalSubmit(e), sectionStatusCheck == 'inProgress' ? true : false, !disableSubmit)}
                {getSaveAsDraftButton('Save as Draft', handleAdditionalDocumentDraft, disableDraftButton)}
              </React.Fragment>
            </div>
          )}
          {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
        </div>
      )}
      {isOpen ? <ViewDocumentCustom loan_app_id={loanAppId} company_id={companyId} product_id={LAPProductId} doctype={currentDocType} awsurl={currentDocUrl} dispatch={dispatch} setIsOpen={setIsOpen} isOpen={isOpen} title={fileTitle} /> : null}
      {isLoading && <Preloader />}
    </div>
  );
};

export default AdditionalDocuments;
