export function uploadBulkFileDefaultWatcher(data, resolve, reject) {
  return {
    type: 'UPLOAD_BULK_FILE_DEFAULT',
    payload: data,
    resolve,
    reject,
  };
}
export function getBulkFileListWatcher(data, resolve, reject) {
  return {
    type: 'GET_BULK_FILE_LIST',
    payload: data,
    resolve,
    reject,
  };
}

export function downloadBulkFileWatcher(data, resolve, reject) {
  return {
    type: 'DOWNLOAD_BULK_FILE',
    payload: data,
    resolve,
    reject,
  };
}
