import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Pagination from '../../../node_modules/react-sdk/dist/components/Pagination/Pagination';

import CompanyDropdown from '../../components/Company/SelectCompany';

import CustomDropdown from '../../components/custom/customSelect';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import moment from 'moment';
import DatePicker from 'react-sdk/dist/components/DatePicker/DatePicker';
import CustomDatePicker from '../../components/DatePicker/datePickerCustom';
import Table from 'react-sdk/dist/components/Table/Table';

import { getServiceInvoiceWatcher } from '../../actions/services';
import { AlertBox } from '../../components/AlertBox';
import { getAllServicesWatcher } from '../../actions/services';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';

import { verifyDateAfter1800 } from '../../util/helper';

const types = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'request',
    label: 'Request',
  },
  {
    value: 'response',
    label: 'Response',
  },
];
let filterObj = {
  company: null,
  product: null,
  fromDate: null,
  toDate: null,
  status: null,
  searchText: null,
};

export default function ServiceInvoice(props) {
  // const {isCustomDatePicker} = props;
  const { reportName, isCustomDatePicker, text, onSearchClick, mandatoryFields, isViewSearch = false, isViewStatus = false, reportButton = false, sendData = () => {}, isDisabledFromDateDropdown = false, statusList, loanStatusList, isViewMinAmount = false, isViewMaxAmount = false, isRepaymentReport, isCKYCReport, isServiceUsage, isViewMonthDropdown, isViewYearDropdown, isViewDayDropdown, isScreenFlag, isViewFromDate, isViewToDate, ...other } = props;
  const dispatch = useDispatch();
  const [company, setCompany] = useState('');
  const [product, setProduct] = useState({});
  const [customDate, setCustomDate] = useState(true);
  const [service, setService] = useState([]);
  const [serviceLabel, setServiceLabel] = useState('');
  const [type, setType] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [serviceInvoice, setServiceInvoice] = useState([]);
  const [severity, setSeverity] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [filterData, setFilterdData] = useState(filterObj);

  const handleChangePage = (event, newPage) => {
    setPage(event);
    filterData.page = event;
    filterData.limit = 15;
  };

  useEffect(() => {
    dispatch(
      getAllServicesWatcher(
        (result) => {
          setService(
            result.data.map((item) => {
              return {
                value: item._id,
                label: item.service_name,
              };
            }),
          );
        },
        (error) => {},
      ),
    );
  }, []);

  useEffect(() => {
    if (company && fromDate && toDate && service) handleSearchClick();
  }, [page]);

  const handleSearchClick = () => {
    const filterData = {};
    if (company) filterData.company_id = company?.value;
    if (serviceLabel) filterData.service_id = serviceLabel;
    if (type && type.value !== 'all') filterData.type = type.value;
    if (fromDate) filterData.from_date = moment(fromDate).format('YYYY-MM-DD');
    if (toDate) filterData.to_date = moment(toDate).format('YYYY-MM-DD');
    filterData.page = page;
    dispatch(
      getServiceInvoiceWatcher(
        filterData,
        (response) => {
          setServiceInvoice(response.data);
          setCount(response?.count);
        },
        (error) => {
          return showAlert(error.response.data.message, 'error');
        },
      ),
    );
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };
  const customCellCss = {
    width: 'fit-content',
  };
  const customTableClass = {
    width: '95%',
    marginLeft: '2.5%',
    gridTemplateColumns: `18% 18% 18% 18% 18% 18%`,
    overflowX: 'hidden',
    display: 'grid',
  };

  const dropdownStyle = {
    width: '103%',
    marginTop: '4%',
    zIndex: '10',
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 4000);
  };
  const sampleData = serviceInvoice.map((item, index) => ({
    'Sr No': page * 15 + index + 1,
    'Company Name': item?.company_name,
    'API Name': item?.api_name,
    Date: moment(item.timestamp).format('YYYY-MM-DD'),
    Type: item.request_type,
    Status: item.api_response_status,
  }));

  return (
    <div>
      <div style={{ marginLeft: '1.5rem', fontSize: '18px', fontFamily: 'MontSerrat-Bold', marginBottom: '1rem' }}>Service Usage</div>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '1rem', marginLeft: '1.5rem' }}>
        <CompanyDropdown
          placeholder="Select company"
          company={company}
          onCompanyChange={(value) => {
            setCompany(value);
            setServiceInvoice([]);
          }}
        />

        <InputBox
          id={'service'}
          placeholder="Select Service"
          isDrawdown={true}
          options={service}
          onClick={(event) => {
            setServiceLabel(event.value);
            setServiceInvoice([]);
          }}
          customDropdownClass={dropdownStyle}
          helperText={!service ? 'Service is required' : ''}
          customClass={{ height: '58px', width: '15vw' }}
        ></InputBox>

        <CustomDatePicker
          placeholder="Duration"
          onDateChange={(date) => {
            if (date.state == 'custom') {
              setCustomDate(false);
              setFromDate('');
              setToDate('');
            } else {
              setCustomDate(true);
              setFromDate(date.fromDate);
              setToDate(date.toDate);
              setFilterdData({
                ...filterData,
                fromDate: moment(date.fromDate).format('YYYY-MM-DD'),
                toDate: moment(date.toDate).format('YYYY-MM-DD'),
              });
            }
          }}
          width="10rem"
        />
        {!customDate ? (
          <DatePicker
            label="From date"
            isBoxType="icon"
            initialValue={fromDate || null}
            onDateChange={(date) => {
              setFromDate(verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date);
              setFilterdData({
                ...filterData,
                fromDate: verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date,
              });
            }}
            format="MM/DD/YYYY"
            customInputClass={{ width: '12vw', height: '58px' }}
          />
        ) : null}
        {!customDate ? (
          <DatePicker
            label="To date"
            isBoxType="icon"
            initialValue={toDate || null}
            onDateChange={(date) => {
              setToDate(verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date);
              setFilterdData({
                ...filterData,
                toDate: verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date,
              });
              if (date === null) {
                setFilterdData({
                  ...filterData,
                  toDate: null,
                });
              }
            }}
            format="MM/DD/YYYY"
            customInputClass={{ width: '12vw', height: '58px' }}
          />
        ) : null}

        <InputBox
          label="Select type"
          placeholder="Select type"
          isDrawdown={true}
          initialValue={type.label ? type.label : ''}
          options={types}
          onClick={(value) => {
            setType(value);
            setServiceInvoice([]);
          }}
          customDropdownClass={dropdownStyle}
          customClass={{ width: '15vw', height: '58px' }}
        />
        <IconButton
          aria-label="handle-search"
          onClick={() => {
            handleSearchClick();
          }}
        >
          <ManageSearchIcon fontSize="large" />
        </IconButton>
      </div>
      <div style={{ marginLeft: '-1rem' }}>
        {serviceInvoice.length ? (
          <Table
            customStyle={customTableClass}
            customCellCss={customCellCss}
            data={sampleData}
            columns={[
              { id: 'Sr No', label: 'Sr No' },
              { id: 'Company Name', label: 'Company Name' },
              { id: 'API Name', label: 'API Name' },
              { id: 'Date', label: 'Date' },
              { id: 'Type', label: 'Type' },
              { id: 'Status', label: 'Status' },
            ]}
          />
        ) : null}
        {count && serviceInvoice.length ? <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={15} /> : null}
      </div>
    </div>
  );
}
