import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import Button from "@mui/material/Button";
import ButtonGroup from '@mui/material/ButtonGroup';
import GridOnIcon from '@mui/icons-material/GridOn';
import { getLapLeadDetailsWatcher } from '../../actions/loanRequest.js';
import { downloadDataWithMultipleSheetInXLSXFormat } from '../../util/helper';
import moment from 'moment';
import Button from 'react-sdk/dist/components/Button/Button.js';
import { leadExportMapData } from './leads/leadExportMapData.js';
import { Export_Type } from '../constants/string_constants.js';

import Img from '../images/download-button.svg';
import imgH from '../images/download-button-hover.svg';

const DownloadFile = ({ filter, handleAlert, company, product, disabled, checkAccessTags, isTagged }) => {
  const dispatch = useDispatch();

  const getDownloadData = (ext) => {
    dispatch(
      getLapLeadDetailsWatcher(
        {
          ...filter,
          isExport: true,
          company_id: company.value ? company.value : company._id,
          product_id: product.value ? product.value : product._id,
        },
        (result) => {
          let data = leadExportMapData(result, ext);
          let fileName = `Leads_${company.label || company.name}_${product.label || product.name}_${moment(filter.from_date).format('DD_MMM_YYYY') !== 'Invalid date' ? moment(filter.from_date).format('DD_MMM_YYYY') : 'NA'}_${moment(filter.to_date).format('DD_MMM_YYYY') !== 'Invalid date' ? moment(filter.to_date).format('DD_MMM_YYYY') : 'NA'}.${ext}`;
          downloadDataWithMultipleSheetInXLSXFormat(fileName, data);
          handleAlert('File downloaded successfully', 'success');
        },
        (error) => {
          return handleAlert('Problem downloading file', 'error');
        },
      ),
    );
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        {isTagged && checkAccessTags(['tag_lap_lead_btn_xlsx']) && (
          <Button
            buttonType="secondary"
            label="XLSX"
            customStyle={{
              width: '109px',
              height: '40px',
              border: '1px solid #475BD8',
              color: '#475BD8',
              borderRadius: '26px',
              fontSize: '12px',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: 'none',
              backgroundColor: 'white',
            }}
            onClick={() => getDownloadData(Export_Type.XLSX)}
            imageButton={Img}
            imageButtonHover={imgH}
            iconButton="btn-secondary-download-button"
          />
        )}

        {isTagged && checkAccessTags(['tag_lap_lead_btn_csv']) && (
          <Button
            label="CSV"
            buttonType="secondary"
            customStyle={{
              width: '109px',
              height: '40px',
              marginLeft: '12px',
              border: '1px solid #475BD8',
              color: '#475BD8',
              borderRadius: '26px',
              fontSize: '12px',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: 'none',
              backgroundColor: 'white',
            }}
            onClick={() => getDownloadData(Export_Type.CSV)}
            imageButton={Img}
            imageButtonHover={imgH}
            iconButton="btn-secondary-download-button"
          />
        )}
      </div>
    </>
  );
};

export default DownloadFile;
