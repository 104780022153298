import React, { useEffect, useState } from 'react';
import SummaryCard from './overviewCard';
import ProductCard from './productCard';
import Button from 'react-sdk/dist/components/Button/Button';
import DownloadImg from '../../assets/Images/DownloadImg.svg';
import NewDatePicker from 'react-sdk/dist/components/NewDatePicker/NewDatePicker';
import './reconSummary.style.css';
import { getReconSummaryWatcher, getDownloadReportWatcher } from 'serviceFeeRecon/actions/reconSummary.action';
import { storedList } from 'util/localstorage';
import { useDispatch } from 'react-redux';
import { AlertBox } from 'components/AlertBox';
import { useLocation } from 'react-router-dom';

const user = storedList('user');
export default function reconSummary() {
  const param = useLocation();
  const dispatch = useDispatch();
  const [summaryData, setSummaryData] = useState({
    summary: {
      company_name: '',
      disbursment_sf: 0,
      repayment_sf: 0,
      gross_sf: 0,
      base_value: 0,
      gst_calculation: 0,
      net_sf_payable: 0,
    },
    products: [],
  });

  const [selectedDate, setSelectedDate] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };
  const onClickDownloadReport = () => {
    if (selectedDate === '') {
      showAlert('Please Select date', 'error');
    } else {
      downloadReport();
    }
  };

  useEffect(() => {
    fetchReconSummary();
  }, [selectedDate]);

  const dateObject = new Date(selectedDate + ' 01');
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
  const lastDate = new Date(year, month, 0).getDate();
  const toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDate}`;

  const fetchReconSummary = () => {
    const payload = {
      year: year,
      month: month,
      user: { id: user?.id ?? user?._id, _id: user?.id ?? user?._id },
      companyId: param.state.companyId,
      productId: param.state.productId,
    };

    new Promise((resolve, reject) => {
      dispatch(getReconSummaryWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setSummaryData(response.data);
      })
      .catch((error) => {});
  };

  const downloadReport = () => {
    const payload = {
      fromDate: fromDate,
      toDate: toDate,
      user: { id: user?.id ?? user?._id, _id: user?.id ?? user?._id },
      companyId: param.state.companyId,
      productId: param.state.productId,
    };

    new Promise((resolve, reject) => {
      dispatch(getDownloadReportWatcher(payload, resolve, reject));
    })
      .then((response) => {
        window.open(`${response}`);
        showAlert('Report Downloaded Successfully', 'success');
      })
      .catch((error) => {
        showAlert('No Report Exists', 'error');
      });
  };

  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      <div className="partner-recon-summary-heading">
        <div className="recon-summary-heading">{summaryData?.summary?.company_name}</div>
        <div className="summary-buttons">
          <Button
            buttonType="secondary"
            customStyle={{
              width: 'max-content',
              height: '42px',
              marginTop: '8px',
              backgroundColor: '#FFF',
              fontFamily: 'Montserrat-Regular',
              padding: '10px 24px',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: 'normal',
              borderRadius: '21px',
              borderColor: '#475BD8',
              color: '#475BD8',
              whiteSpace: 'nowrap',
            }}
            onClick={onClickDownloadReport}
            label={
              <React.Fragment>
                <img src={DownloadImg} alt="Download Icon" style={{ marginRight: '8px', marginLeft: '4px', marginBottom: '2px' }} />
                Download Report
              </React.Fragment>
            }
          />
          <NewDatePicker
            label={'Duration'}
            isBoxType={'icon'}
            onDateChange={(date) => {
              setSelectedDate(date);
            }}
            selectedDate={selectedDate}
          />
        </div>
      </div>
      <div className="overview-container">
        <SummaryCard title={'DISBURSEMENT SF'} amount={summaryData?.summary?.disbursment_sf} />
        <SummaryCard title={'REPAYMENT SF'} amount={summaryData?.summary?.repayment_sf} />
        <SummaryCard title={'GROSS SF'} amount={summaryData?.summary?.gross_sf} />
        <SummaryCard title={'BASE VALUE'} amount={summaryData?.summary?.base_value} />
        <SummaryCard title={'GST CALCULATION'} amount={summaryData?.summary?.gst_calculation} />
        <SummaryCard title={'NET SF PAYABLE'} amount={summaryData?.summary?.net_sf_payable} />
      </div>
      <div className="product-container">
        {summaryData.products.map((product, index) => (
          <ProductCard
            key={index}
            productTitle={product.product_name}
            keys={['DISBURSEMENT SF', 'REPAYMENT SF', 'GROSS SF', 'BASE VALUE', 'GST CALCULATION', 'NET SF PAYABLE']}
            values={{
              'DISBURSEMENT SF': product?.disbursment_sf,
              'REPAYMENT SF': product?.repayment_sf,
              'GROSS SF': product?.gross_sf,
              'BASE VALUE': product?.base_value,
              'GST CALCULATION': product?.gst_calculation,
              'NET SF PAYABLE': product?.net_sf_payable,
            }}
          />
        ))}
      </div>
    </>
  );
}
