import axios from 'axios';
import { BASE_URL } from 'constants/apiUrls';

export function getSFRCompaniesApi(payload) {
  return axios.get(`${BASE_URL}/companies`, {
    params: payload,
  });
}

export function getSFRProductsApi(payload) {
  return axios.get(`${BASE_URL}/products`, {
    params: payload,
  });
}

export function getSRFProductDetailsApi(payload) {
  return axios.get(`${BASE_URL}products/${payload.productId}`, {
    params: payload,
  });
}

export function createBusinessModelApi(payload) {
  return axios.post(`${BASE_URL}business-model`, payload);
}

export function getBusinessModelApi(payload) {
  return axios.get(`${BASE_URL}business-model/${payload.model_id}`, {
    params: payload,
  });
}

export function CheckBusinessModelExistApi(payload) {
  return axios.get(`${BASE_URL}bussiness-model/exist`, {
    params: payload,
  });
}
