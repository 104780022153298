export const loanCreationJsonFields = [
  {
    title: 'Partner Loan Application ID',
    dept: 'Loan Details',
    type: 'String',
    field: 'partner_loan_app_id',
    isOptional: false,
    validationmsg: 'Please enter Partner Loan Application ID in correct format',
  },

  {
    title: 'Partner Borrower ID',
    dept: 'Loan Details',
    type: 'String',
    field: 'partner_borrower_id',
    isOptional: false,
    validationmsg: 'Please enter Borrower ID in correct format',
  },
  {
    title: 'Loan Application ID',
    dept: 'Loan Details',
    type: 'String',
    field: 'loan_app_id',
    isOptional: false,
    validationmsg: 'Please enter Loan Appliaction ID in correct format',
  },
  {
    title: 'Borrower ID',
    dept: 'Loan Details',
    type: 'String',
    field: 'borrower_id',
    isOptional: false,
    validationmsg: 'Please enter Borrower ID in correct format',
  },
  {
    title: 'Partner Loan ID',
    dept: 'Loan Details',
    type: 'String',
    field: 'partner_loan_id',
    isOptional: false,
    validationmsg: 'Please enter Partner Loan ID in correct format',
  },
  {
    title: 'Loan Application Date',
    dept: 'Loan Details',
    type: 'Date',
    field: 'loan_app_date',
    isOptional: false,
    validationmsg: 'Please enter Loan Application Date in dd-mm-yyyy format',
  },
  {
    title: 'Final Approval Date',
    dept: 'Loan Details',
    type: 'Date',
    field: 'final_approve_date',
    isOptional: false,
    validationmsg: 'Please enter Final Approval Date in correct format',
    isDisabled: true,
  },
  {
    title: 'First Installment Date',
    dept: 'Loan Details',
    type: 'Date',
    field: 'first_inst_date',
    isOptional: false,
    validationmsg: 'Please enter First Installment Date in correct format',
    isDisabled: true,
  },
  {
    title: 'Loan Amount',
    dept: 'Loan Details',
    type: 'float',
    field: 'loan_amt',
    isOptional: false,
    validationmsg: 'Please enter Loan Amount in correct format',
  },
  {
    title: 'Loan Interest Rate (%)',
    dept: 'Loan Details',
    type: 'float',
    field: 'loan_int_rate',
    isOptional: false,
    validationmsg: 'Please enter Loan Amount in correct format',
  },
  {
    title: 'Processing Fee Percentage',
    dept: 'Fees, charges and Net disbursement',
    type: 'Float',
    field: 'processing_fees_perc',
    isOptional: false,
    validationmsg: 'Please enter Processing Fees Percentage in correct format',
  },
  {
    title: 'Processing Fee (incl gst)',
    dept: 'Fees, charges and Net disbursement',
    type: 'Float',
    field: 'processing_fees_amt',
    isOptional: false,
    validationmsg: 'Please enter Processing Fees Amount in correct format',
  },
  {
    title: 'Convenience fee (incl gst)',
    dept: 'Fees, charges and Net disbursement',
    type: 'Float',
    field: 'conv_fees',
    isOptional: true,
    validationmsg: 'Please enter Application Fee in correct format',
  },
  {
    title: 'Application fee percentage',
    dept: 'Fees, charges and Net disbursement',
    type: 'Float',
    field: 'application_fee_perc',
    isOptional: true,
    validationmsg: 'Please enter Application Fee Percentage in correct format',
  },
  {
    title: 'Application Fee (incl gst)',
    dept: 'Fees, charges and Net disbursement',
    type: 'Float',
    field: 'application_fees',
    isOptional: true,
    validationmsg: 'Please enter Application Fee in correct format',
  },
  {
    title: 'Insurance Amount (Optional)',
    dept: 'Fees, charges and Net disbursement',
    type: 'Float',
    field: 'insurance_amount',
    isOptional: true,
    validationmsg: 'Please enter Insurance Amount in correct format',
  },
  {
    title: 'Broken Period Interest Amount',
    dept: 'Fees, charges and Net disbursement',
    type: 'Float',
    field: 'broken_period_int_amt',
    isOptional: false,
    validationmsg: 'Please enter Broken Period Interest Amount in correct format',
  },
  {
    title: 'Net Disbursement Amount',
    dept: 'Fees, charges and Net disbursement',
    type: 'Float',
    field: 'net_disbur_amt',
    isOptional: false,
    validationmsg: 'Please enter Net Disbursement Amount in correct format',
  },
  {
    title: 'Tenure',
    dept: 'Loan Details',
    type: 'Number',
    field: 'tenure',
    isOptional: false,
    validationmsg: 'Please enter Tenure in correct format',
  },
  {
    title: 'Bank Name',
    dept: 'Enter Bank Beneficiary Details',
    type: 'String',
    field: 'bene_bank_name',
    isOptional: false,
    validationmsg: 'Please enter Bank Name in correct format',
  },
  {
    title: 'Bank IFSC',
    dept: 'Enter Bank Beneficiary Details',
    type: 'IFSC',
    field: 'bene_bank_ifsc',
    isOptional: false,
    validationmsg: 'Please enter Bank IFSC in correct format',
  },
  {
    title: 'Bank A/C No.',
    dept: 'Enter Bank Beneficiary Details',
    type: 'String',
    field: 'bene_bank_acc_num',
    isOptional: false,
    validationmsg: 'Please enter Bank Account Number in correct format',
  },
  {
    title: 'Confirm Bank A/C No.',
    dept: 'Enter Bank Beneficiary Details',
    type: 'String',
    field: 'bene_confirm_bank_acc_no',
    isOptional: false,
    validationmsg: 'Please enter Bank Account Number in correct format',
  },
  {
    title: 'Bank A/C Holder Name',
    dept: 'Enter Bank Beneficiary Details',
    type: 'String',
    field: 'bene_bank_account_holder_name',
    isOptional: false,
    validationmsg: 'Please enter Bank Account Holder Name in correct format',
  },
  {
    title: 'Bank Name',
    dept: 'Enter Borrower Bank Details',
    type: 'String',
    field: 'borro_bank_name',
    isOptional: false,
    validationmsg: 'Please enter Bank Name in correct format',
  },
  {
    title: 'Bank IFSC',
    dept: 'Enter Borrower Bank Details',
    type: 'IFSC',
    field: 'borro_bank_ifsc',
    isOptional: false,
    validationmsg: 'Please enter Bank IFSC in correct format',
  },
  {
    title: 'Bank A/C No.',
    dept: 'Enter Borrower Bank Details',
    type: 'String',
    field: 'borro_bank_acc_num',
    isOptional: false,
    validationmsg: 'Please enter Bank Account Number in correct format',
  },
  {
    title: 'Confirm Bank A/C No.',
    dept: 'Enter Borrower Bank Details',
    type: 'String',
    field: 'borro_confirm_bank_acc_no',
    isOptional: false,
    validationmsg: 'Please enter Bank Account Number in correct format',
  },
  {
    title: 'Bank A/C Holder Name',
    dept: 'Enter Borrower Bank Details',
    type: 'String',
    field: 'borro_bank_account_holder_name',
    isOptional: false,
    validationmsg: 'Please enter Bank Account Holder Name in correct format',
  },
];
