import { updatePreLoaderWatcher } from 'actions/user';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getReportRequests, generateReportRequest, downloadReportRequestFile, getCoLendingCamReports, generateCoLendingCamReports, downloadCoLenderReportAPI, getPartnerOperationsReports, generatePartnerOperationsReports, downloadPartnerOperationsReportAPI } from '../apis/reportRequests';

// watcher saga to get report requests
export function* getReportRequestsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getReportRequests, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getReportRequestsWatcherSaga() {
  yield takeLatest('GET_REPORT_REQUESTS', getReportRequestsEffectSaga);
}

// watcher saga to generate report request
export function* generateReportRequestEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(generateReportRequest, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* generateReportRequestWatcherSaga() {
  yield takeLatest('GENERATE_REPORT_REQUEST', generateReportRequestEffectSaga);
}

// watcher saga to download report file
export function* downloadReportRequestFileEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(downloadReportRequestFile, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* downloadReportRequestFileWatcherSaga() {
  yield takeLatest('DOWNLOAD_REPORT_REQUEST', downloadReportRequestFileEffectSaga);
}

//this EffectSaga used to fetch the co lenders reports
export function* coLendingCamReportsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getCoLendingCamReports, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* coLendingCamReportsWatcherSaga() {
  yield takeLatest('GET_COLENDER_REPORT', coLendingCamReportsEffectSaga);
}

//this EffectSaga used to generate the co lenders reports
export function* coLendingCamReportsGeneratorSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(generateCoLendingCamReports, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* generateCoLendingCamReportsSaga() {
  yield takeLatest('GENERATE_CO_LENDING_CAMS_REPORT', coLendingCamReportsGeneratorSaga);
}

//this EffectSaga used to download the co lenders reports
export function* downloadCoLendingReportsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(downloadCoLenderReportAPI, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* downloadCoLendingReportsWatcherSaga() {
  yield takeLatest('DOWNLOAD_CO_LENDER_CAM_REPORT', downloadCoLendingReportsEffectSaga);
}

export function* partnerOperationsReportsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getPartnerOperationsReports, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* partnerOperationsReportsWatcherSaga() {
  yield takeLatest('GET_PARTNER_OPERATIONS_REPORT', partnerOperationsReportsEffectSaga);
}

export function* partnerOperationsReportsGeneratorSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(generatePartnerOperationsReports, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* generatePartnerOperationsReportsSaga() {
  yield takeLatest('GENERATE_PARTNER_OPERATIONS_REPORT', partnerOperationsReportsGeneratorSaga);
}

export function* downloadPartnerOperationsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(downloadPartnerOperationsReportAPI, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* downloadPartnerOperationsWatcherSaga() {
  yield takeLatest('DOWNLOAD_PARTNER_OPERATIONS_REPORT', downloadPartnerOperationsEffectSaga);
}
