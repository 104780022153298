import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '../../../node_modules/react-sdk/dist/components/Pagination/Pagination';
import Table from 'react-sdk/dist/components/Table/Table';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button';
import edit from '../../assets/img/edit.svg';
import ToggleSwitch from 'react-sdk/dist/components/ToggleSwitch/ToggleSwitch';
import { validateData } from '../../util/validation';
import { checkAccessTags } from '../../util/uam';
import { storedList } from '../../util/localstorage';
const user = storedList('user');

import { createBroadcastEventWatcher, broadcastEventListWatcher, updateBroadcastEventWatcher, updateBroadcastEventStatusWatcher } from '../../actions/pubsub';
import { AlertBox } from '../../components/AlertBox';

const defaultErrors = {
  titleError: false,
  descriptionError: false,
  keyError: false,
};

const BroadcastEvent = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [key, setKey] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [userList, setUserList] = useState([]);
  const [count, setCount] = useState(0);
  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  React.useEffect(() => {
    if (isTagged && checkAccessTags(['tag_broadcast_event_read', 'tag_broadcast_event_read_write'])) fetchEventList();
    if (!isTagged) fetchEventList();
  }, []);

  const fetchEventList = () => {
    const payload = {};
    new Promise((resolve, reject) => {
      dispatch(broadcastEventListWatcher(payload, resolve, reject));
    })
      .then((response) => {
        const eventsWithIndex = response.map((event, index) => ({
          ...event,
          index: index + 1,
        }));
        setEventList(eventsWithIndex);
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage(error.response.data.message);
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
      });
  };

  const handleInputChange = (field, validationType, setValue) => (event) => {
    const value = event.value;
    setValue(value);
    setErrors({
      ...errors,
      [field + 'Error']: !validateData(validationType, value),
    });
  };

  const handleSelectChange = (field, value, setValue, isMulti) => {
    if (value === null) {
      setValue('');
    } else {
      setValue(value);
    }
    setErrors({
      ...errors,
      [field + 'Error']: isMulti ? !value?.length : !value,
    });
  };

  const validate = () => {
    let formValidated = true;
    if (!validateData('title', title) || title === '') {
      setErrors({
        ...errors,
        titleError: true,
      });
      formValidated = false;
    }
    if (!validateData('description', description) || description === '') {
      setErrors({
        ...errors,
        descriptionError: true,
      });
    }
    if (!validateData('title', key) || key === '') {
      setErrors({
        ...errors,
        keyError: true,
      });
      formValidated = false;
    }
    return formValidated;
  };

  const handleClear = () => {
    setTitle('');
    setDescription('');
    setKey('');
    setCurrentEvent(null);
    setErrors(defaultErrors);
  };

  const handleSubmit = () => {
    if (saving) return;
    if (validate()) {
      setSaving(true);
      const payload = {
        title,
        description,
        key,
      };
      new Promise((resolve, reject) => {
        dispatch(createBroadcastEventWatcher(payload, resolve, reject));
      })
        .then((response) => {
          setAlert(true);
          setSeverity('success');
          handleClear();
          setAlertMessage(response.message);
          setTimeout(() => {
            handleAlertClose();
          }, 4000);
          setSaving(false);
          fetchEventList();
        })
        .catch((error) => {
          setAlert(true);
          setSeverity('error');
          setAlertMessage(error.response.data.message);
          setTimeout(() => {
            handleAlertClose();
          }, 4000);
          setSaving(false);
        });
    }
  };

  const handleEditChange = (event) => {
    setTitle(event.title);
    setDescription(event.description);
    setKey(event.key);
    setCurrentEvent(event);
  };

  const handleEventUpdateSubmit = (eventItem) => {
    if (!validate()) return;
    setSaving(true);
    const payload = {
      title,
      description,
      key,
      id: currentEvent._id,
    };
    new Promise((resolve, reject) => {
      dispatch(updateBroadcastEventWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setAlert(true);
        setSeverity('success');
        //handleClear();
        setAlertMessage(response.message);
        setTimeout(() => {
          handleAlertClose();
        }, 10000);
        setSaving(false);
        fetchEventList();
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage(error.response.data.message);
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
        setSaving(false);
      });
  };

  const handleStatusToggle = (eventItem) => {
    setSaving(true);
    const payload = {
      title: eventItem.title,
      description: eventItem.description,
      key: eventItem.key,
      id: eventItem._id,
      status: eventItem.status === 'Inactive' ? 'active' : 'Inactive',
    };
    new Promise((resolve, reject) => {
      dispatch(updateBroadcastEventStatusWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setAlert(true);
        setSeverity('success');
        //handleClear();
        setAlertMessage(response.message);
        setTimeout(() => {
          handleAlertClose();
        }, 10000);
        setSaving(false);
        fetchEventList();
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage(error.response.data.message);
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
        setSaving(false);
      });
  };
  const columns = [
    {
      id: 'Sr.No',
      label: 'Sr.No',
      format: (rowData) => {
        return <div>{rowData?.index}</div>;
      },
    },
    {
      id: 'Title',
      label: 'Title',
      format: (rowData) => {
        return <div>{rowData?.title}</div>;
      },
    },

    {
      id: 'Description',
      label: 'Description',
      format: (rowData) => `${rowData.description}`,
    },
    {
      id: 'Key',
      label: 'Key',
      format: (rowData) => rowData?.key,
    },
    {
      id: 'Action',
      label: 'Action',
      format: (rowData) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '0.625rem' }}>
          <div style={{ marginRight: '0.938rem' }}>
            <ToggleSwitch color="secondary" checked={rowData.status === 'active'} disabled={!checkAccessTags(['tag_broadcast_event_read_write'])} onChange={(e) => handleStatusToggle(rowData)} />
          </div>
          <div>
            <img onClick={() => handleEditChange(rowData)} src={edit} alt="editImg" />
          </div>
        </div>
      ),
    },
  ];

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };
  const customButtonClass = {
    fontSize: '1rem',
    fontFamily: 'Montserrat-SemiBold',
    borderRadius: '0.5rem',
    width: '7.5rem',
    height: '2.7rem',
    marginLeft: '0.5rem',
    marginTop: '0.4rem',
  };
  const customClass = {
    height: '3.5rem',
    width: '22rem',
    maxWidth: '100%',
    paddingTop: '0.4%',
    marginLeft: '0.5%',
    marginRight: '0.5%',
    fontFamily: 'Montserrat-Regular',
  };
  const customCellCss = {
    width: 'fit-content',
    fontFamily: 'Montserrat-Medium',
  };
  const customTableClass = {
    width: '95%',
    marginLeft: '2.5%',
    gridTemplateColumns: `10% 15% 50% 15% 10%`,
    // overflowX: "hidden",
    display: 'grid',
  };
  const customInputClass = {
    width: '100%',
    maxWidth: '100%',
    paddingTop: '0.3%',
    paddingBottom: '0.1%',
    fontSize: '1rem',
    fontFamily: 'Montserrat-Regular',
    marginLeft: '2%',
  };
  const customHeaderCss = {
    fontFamily: 'Montserrat-Bold',
    fontSize: '1.25rem',
    marginLeft: '2.5rem',
    marginTop: '0.313rem',
    marginBottom: '0.938rem',
  };
  const customInputBoxCss = {
    display: 'flex',
    gap: '0.063rem',
    paddingBottom: '1%',
    marginLeft: '2.188rem',
    marginRight: '6.25rem',
  };

  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      <div>
        <div style={customHeaderCss}>Broadcast event</div>
      </div>
      <div style={customInputBoxCss}>
        <InputBox id="title" label="Title" placeholder="Title" type="text" customClass={customClass} error={errors.titleError} helperText={errors.titleError ? 'Enter valid title' : ''} initialValue={title} customInputClass={customInputClass} onClick={handleInputChange('title', 'title', setTitle)} style={{ marginRight: '1.25rem', marginLeft: '1.875rem' }} />
        <InputBox id="description" label="Description" customClass={customClass} error={errors.descriptionError} helperText={errors.descriptionError ? 'Enter valid description' : ''} initialValue={description} customInputClass={customInputClass} onClick={handleInputChange('description', 'title', setDescription)} style={{ marginRight: '1.25rem' }} />
        <InputBox id="key" label="Key" customClass={customClass} error={errors.keyError} helperText={errors.keyError ? 'Enter valid key' : ''} initialValue={key} customInputClass={customInputClass} onClick={handleInputChange('key', 'title', setKey)} style={{ marginRight: '1.25rem' }} />
        {!currentEvent ? (
          <Button id="SUBMIT" label="SUBMIT" buttonType="primary" onClick={handleSubmit} isDisabled={saving || isTagged ? !checkAccessTags(['tag_broadcast_event_read_write']) || !title || !description || !key || errors.titleError || errors.descriptionError || errors.keyError : !title || !description || !key || errors.titleError || errors.descriptionError || errors.keyError} customStyle={customButtonClass} />
        ) : (
          <Button id="UPDATE" label="UPDATE" buttonType="primary" onClick={handleEventUpdateSubmit} isDisabled={saving || isTagged ? !checkAccessTags(['tag_broadcast_event_read_write']) || !title || !description || !key || errors.titleError || errors.descriptionError || errors.keyError : !title || !description || !key || errors.titleError || errors.descriptionError || errors.keyError} customStyle={customButtonClass} />
        )}
        <Button id="CLEAR" label="CLEAR" buttonType="primary" onClick={handleClear} isDisabled={!title && !description && !key} customStyle={customButtonClass} />
      </div>

      {eventList.length ? <Table customStyle={customTableClass} customCellCss={customCellCss} data={eventList} columns={columns} /> : null}
      {count ? <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={rowsPerPage} /> : null}
    </>
  );
};

export default BroadcastEvent;
