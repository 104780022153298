import { takeLatest, call, put } from 'redux-saga/effects';
import { updatePreLoaderWatcher } from '../actions/user';
import { findDocumentsApi, getDocumentsApi } from '../apis/downloadDocument';

export function* getDownloadDocumentsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getDocumentsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getDownloadDocumentsWatcherSaga() {
  yield takeLatest('GET_ALL_DOCUMENTS', getDownloadDocumentsEffectSaga);
}

export function* findDocumentsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(findDocumentsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* findDocumentsWatcherSaga() {
  yield takeLatest('FIND_ALL_DOCUMENTS', findDocumentsEffectSaga);
}
