import React from 'react';
import './overviewCard.css';
import { convertIntoINR } from '../../../util/partners/helper';
const SummaryCard = ({ title, amount }) => {
  return (
    <div className="overview-card-container-style">
      <div className="overview-card-title-container">
        <div className="overview-card-title">{title}</div>
        <div className="overview-card-amount">{amount ? `₹ ${convertIntoINR(amount)}` : '₹ 0'}</div>
      </div>
    </div>
  );
};

export default SummaryCard;
