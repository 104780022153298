import React, { useState } from 'react';
import 'react-sdk/dist/styles/_fonts.scss';
import { Slider } from '../Slider/Slider';
import { formatTitle, formatDateTime } from 'util/msme/helper';
import './AuditLog.style.css';
import { activityLogNamingFormat } from 'msme/config/activityLogNaming';
import { remarkReason } from 'msme/config/remarkReason';

export const AuditLog = ({ data }) => {
  const getRemarksFromCategory = (category) => {
    switch (category) {
      case 'lead_rejected':
        return JSON.parse(process.env.REACT_APP_REJECT_REASONS);
      default:
        return remarkReason;
    }
  };

  const populateReasons = (category, reasonsArr) => {
    const remarksConfig = getRemarksFromCategory(category);
    return reasonsArr.reduce((result, currentKey, currentIndex) => {
      if (currentIndex === 0) {
        return remarksConfig[currentKey];
      } else {
        return result + ', ' + remarksConfig[currentKey];
      }
    }, '');
  };

  const renderLog = (logItem, index) => {
    return (
      <>
        <div key={index} className="audit-title">
          {formatTitle(activityLogNamingFormat[logItem?.category] ? activityLogNamingFormat[logItem?.category] : logItem?.category)} {logItem.sequence >= 300 && logItem.sequence <= 700 ? `${1 + (logItem.sequence % 100)}` : ''} {logItem.sequence >= 800 && logItem.sequence <= 900 ? `${+logItem.sequence / 100 - 7}` : ''}
        </div>

        <div className="audit-text">
          <span className="log-header"> Name: </span>
          <span style={{ color: '#767888' }}>{logItem?.updated_by?.username}</span>
        </div>
        <div className="audit-text">
          <span className="log-header"> User ID: </span>
          <span style={{ color: '#767888' }}>{logItem?.updated_by?.email}</span>
        </div>
        <div className="audit-text">
          <span className="log-header"> Date & Time: </span>
          <span className="log-value">{formatDateTime(logItem.updatedAt)}</span>
        </div>
        <div className="audit-text">
          <span className="log-header"> Comment: </span>
          <span className="log-value">
            {logItem && logItem.remark_reasons && logItem.remark_reasons.length > 0 && (
              <span>
                {populateReasons(logItem.category, logItem.remark_reasons)}
                <br />
              </span>
            )}
            {logItem.remarks}
          </span>
        </div>
      </>
    );
  };

  return (
    <Slider>
      <div className="audit-div">
        <div className="audit-header">AUDIT LOG</div>
        <div>{data && data.length > 0 ? data.map(renderLog) : <p className="log-empty">No audit logs found!</p>}</div>
      </div>
    </Slider>
  );
};
