import * as React from 'react';
import { bookLoansFormJsonFields } from './bookLoansFormJson';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storedList } from '../../../util/localstorage';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button';
import { validateData } from '../../utils/validation';
import { verifyDateAfter1800 } from '../../../util/helper';
import moment from 'moment';
import 'react-sdk/dist/styles/_fonts.scss';
import './coBorrowerForm.style.css';
import { States, Cities } from '../../../constants/country-state-city-data';
import { patchLapDetailsWatcher, patchLapDocDeleteWatcher, patchLapSaveAsDraftDetailsWatcher, putLapDraftSaverWatcher } from '../../actions/lap.action';
import { getBookLoanDetailsWatcher } from '../../actions/bookLoan.action';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import UploadFileInput from '../../components/uploadFileInput/UploadFileInput';
import getSectionStatus from './GetLeadSectionStatus/GetLeadSectionStatus';
import { CoBorrowerSelfie, CoBorrowerPanImage, CoBorrowerPanXml, CoBorrowerKYCAadharFrontTitle, CoBorrowerKYCAadharBackTitle, CoBorrowerKYCAadharBackXml } from './uploadKycData';
import { SectionData } from '../../config/sectionData';
import { LeadNewStatus } from '../../config/LeadStatus';
import getSubSectionRemarks from './GetLeadSectionStatus/GetLeadSubSectionRemarks';
import CustomDatePicker from 'react-sdk/dist/components/DatePicker/DatePicker';
import { documentCode } from '../../config/docCode';
import { CommentViewer } from '../../components/CommentViewer/CommentViewer';
import { getSubmitButton } from '../../util/resuableJsx/reusableJsx';
import { getSaveAsDraftButton } from '../../util/resuableJsx/reusableJsx';
import { REACT_APP_AGE_LAP, REACT_APP_UPPERAGELIMIT_LAP } from '../../helpers/constants';
import { postComprehensiveLogsWatcher } from 'actions/comprehensiveLogs.action';
import _isEmpty from 'lodash/isEmpty';
import SlidingAlert from '../../components/SlidingAlert/SlidingAlert';

const BOOK_LOAN_FORM_JSON = bookLoansFormJsonFields();
const user = { _id: storedList('user')?._id, id: storedList('user')?.id };

const fetchObjectFieldsByDept = (deptName) => {
  const matchingObjects = [];
  for (let object of BOOK_LOAN_FORM_JSON) {
    if (object.dept === deptName) {
      matchingObjects.push(object.field);
    }
  }
  return matchingObjects;
};

const uploadFileName = 'co_borrower';

const disabledFields = {
  view: [...fetchObjectFieldsByDept('Co-Borrower Details'), ...fetchObjectFieldsByDept('Co-Borrower Current Address'), ...fetchObjectFieldsByDept('Co-Borrower Permanent Address'), ...fetchObjectFieldsByDept('Co-Borrower Current Address'), ...fetchObjectFieldsByDept('Co-Borrower KYC'), ...fetchObjectFieldsByDept('Co-Borrower Selfie'), ...fetchObjectFieldsByDept('Co-Borrower KYC Document PAN'), ...fetchObjectFieldsByDept('Co-Borrower KYC Document Aadhaar')],
  edit: ['company_id', 'product_id', 'loan_app_id', 'partner_borrower_id', 'pan_value'],
};

const setDocumentView = (TitleOb, documents) => {
  return TitleOb.map((givenObj) => {
    const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
    if (matchingObj) {
      return {
        ...givenObj,
        s3_url: matchingObj.file_url,
        doc: matchingObj,
      };
    } else {
      return givenObj;
    }
  });
};

const checkDocStatus = (data) => {
  for (let ob of data) {
    if (!ob?.s3_url && ob.isRequired) {
      return false;
    }
  }

  return true;
};

const sectionStatus = ['deviation', 'approved', 'rejected', 'failed'];

export default function CoBorrowerForm(props) {
  const { item, itemIndex, setNavIconPrefixState, navIconPrefixState, setNavState, coBorrowersData, setCoBorrowersData, coBorrowerArray, setCoBorrowerArray, CoBorrowerCount, setCoBorrowerCount, coAppStatus, setCoAppStatus, loanAppId, LAPCompanyId, LAPProductId, documents, leadStatus, loanDetailsSubsectionStatus, setLeadStatus, setShouldFetch, loanSectionObject, setLoanSectionObject, setShouldFetchDocument } = props;
  const dispatch = useDispatch();
  const [coAppIndex, setCoAppIndex] = useState(item.id);
  const [viewPerAddress, setViewPerAddress] = useState(true);
  const [stateData, setStateData] = useState(coBorrowersData[itemIndex] ? coBorrowersData[itemIndex] : {});
  const [validationData, setValidationData] = useState({});
  const [selectedPANFileType, setSelectedPANFileType] = useState({ 'Image & PDF': true, 'Response XML/JSON': false });
  const [selectedAadharFileType, setAadharFileType] = useState({ 'Image & PDF': true, 'Response XML/JSON': false });
  const [states, setStatesState] = useState(States);
  const [currCity, setCurrCityState] = useState([]);
  const [perCity, setPerCityState] = useState([]);
  const [backgroundColorBlur, setBackgroundColorBlur] = useState(true);
  const [panButtonState, setPanButtonState] = useState('button');
  const [panVerify, setPanVerify] = useState(false);
  const [validForm, setValidForm] = useState(true);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [panBorder, setPanBorder] = useState('border: 1px solid #BBBFCC');
  const [sectionStatusCheck, setSectionStatusCheck] = useState('');
  const [disableDraftButton, setDisableDraftButton] = useState(true);
  const [statusObject, setStatusObject] = useState('');
  const [documentStateData, setDocumentStateData] = useState({
    Borrower_Selfie_1: false,
    Pan_1: false,
    Pan_XML: false,
    Aadhar_XML: false,
    Aadhar_Front_1: false,
    Aadhar_Back_1: false,
  });
  let intervalId;
  const [subSectionStatus, setSubsectionStatus] = useState('');
  const [isLeadRejected, setIsLeadRejected] = useState(false);
  const [coBorrowerSelfieState, setCoBorrowerSelfieState] = useState(CoBorrowerSelfie);
  const [panView, setPanView] = useState(CoBorrowerPanImage);
  const [panXMLView, setPanXMLView] = useState(CoBorrowerPanXml);
  const [aadharFrontView, setAadharfront] = useState(CoBorrowerKYCAadharFrontTitle);
  const [aadharBackView, setAadharback] = useState(CoBorrowerKYCAadharBackTitle);
  const [aadharfrontXML, setAadharfrontXml] = useState(CoBorrowerKYCAadharBackXml);
  let code = 299 + (itemIndex + 1);
  const [addCoBorrowerLoader, setAddCoBorrowerLoader] = useState('');
  const [docError, setDocError] = useState(false);
  const [isNameMismatch, setIsNameMisMatch] = useState(false);
  const [isFormCompleted, setIsFormCompleted] = useState(false);

  const customButton = {
    borderRadius: '8px',
    width: '240px',
    height: '56px',
    fontSize: '16px',
  };

  const customButton1 = {
    borderRadius: '8px',
    width: '240px',
    height: '56px',
    fontSize: '16px',
    backgroundColor: 'white',
  };

  useEffect(() => {
    if (loanDetailsSubsectionStatus && loanDetailsSubsectionStatus[code]) {
      if (loanDetailsSubsectionStatus[code]['co-borrowers-pan-verify'] == 'approved') {
        setBackgroundColorBlur(false);
        setPanButtonState('icon');
        fetchLoanDetails(0);
        setPanBorder('1px solid green');
        setPanVerify(true);
      }
      if (loanDetailsSubsectionStatus[code]['co-borrowers-pan-verify'] == 'deviation') {
        setBackgroundColorBlur(false);
        setPanButtonState('icon');
        fetchLoanDetails(0);
        setPanBorder('1px solid yellow');
        setPanVerify(true);
      }
    }
    if (props.loanDetailsStatus && props.loanDetailsStatus[`co_borrower-${parseInt(item[item.length - 1])}`] == 'rejected') {
      setIsLeadRejected(true);
    }
  }, [loanDetailsSubsectionStatus, statusObject]);

  useEffect(() => {
    handleCoBorrowerArray();
    fetchLoanDetails(1);
  }, [CoBorrowerCount]);

  useEffect(() => {
    if (!validForm || !documentStateData.Borrower_Selfie_1 || !(documentStateData.Aadhar_Front_1 || documentStateData.Aadhar_XML) || !(documentStateData.Pan_1 || documentStateData.Pan_XML) || sectionStatusCheck == 'inProgress') {
      setIsFormCompleted(false);
    } else {
      setIsFormCompleted(true);
    }
  }, [selectedPANFileType, selectedAadharFileType, validForm, documentStateData]);

  const getLoanDocuments = () => {
    const borrowerId = stateData?.borrower_id;
    const response = documents.filter((item) => item.borrower_id == borrowerId);
    let stateDoc = {
      Borrower_Selfie_1: false,
      Pan_1: false,
      Pan_XML: false,
      Aadhar_XML: false,
      Aadhar_Front_1: false,
      Aadhar_Back_1: false,
    };

    let data = setDocumentView(CoBorrowerSelfie, response);
    setCoBorrowerSelfieState(data);
    stateDoc['Borrower_Selfie_1'] = checkDocStatus(data);

    data = setDocumentView(CoBorrowerPanImage, response);
    stateDoc['Pan_1'] = checkDocStatus(data);
    setPanView(data);

    data = setDocumentView(CoBorrowerPanXml, response);
    stateDoc['Pan_XML'] = checkDocStatus(data);
    setPanXMLView(data);

    data = setDocumentView(CoBorrowerKYCAadharFrontTitle, response);
    stateDoc['Aadhar_Front_1'] = checkDocStatus(data);
    setAadharfront(data);

    data = setDocumentView(CoBorrowerKYCAadharBackTitle, response);
    stateDoc['Aadhar_Back_1'] = checkDocStatus(data);
    setAadharback(data);

    data = setDocumentView(CoBorrowerKYCAadharBackXml, response);
    stateDoc['Aadhar_XML'] = checkDocStatus(data);
    setAadharfrontXml(data);
    if (leadStatus == LeadNewStatus.Pending) {
      for (let obj of response) {
        if (obj.code == '195') {
          setSelectedPANFileType({
            ['Image & PDF']: false,
            ['Response XML/JSON']: true,
          });
        } else if (obj.code == '191') {
          setSelectedPANFileType({
            ['Image & PDF']: true,
            ['Response XML/JSON']: false,
          });
        } else if (obj.code == '196') {
          setAadharFileType({
            ['Image & PDF']: false,
            ['Response XML/JSON']: true,
          });
        } else {
          setAadharFileType({
            ['Image & PDF']: true,
            ['Response XML/JSON']: false,
          });
        }
      }
    }

    setDocumentStateData(stateDoc);
  };

  useEffect(() => {
    let vlForm = 1;
    bookLoansFormJsonFields().map((item, idx) => {
      if (item.field != 'cb_age' && item.field != 'cb_father_name' && (item.dept == 'Co-Borrower Details' || item.dept == 'Co-Borrower Current Address' || item.dept == 'Co-Borrower Permanent Address' || item.dept == 'Co-Borrower KYC')) {
        if (item.isOptional == false) {
          if (stateData && !stateData[`${item.type}_vl_${item.field}`]) {
            vlForm = 0;
          }
        }
      }
    });
    if (vlForm) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
    if (coAppStatus[coAppIndex - 1] == false) {
      const coAppData = [...coBorrowersData];
      coAppData[coAppIndex - 1] = stateData;
      setCoBorrowersData(coAppData);
    }
    if (stateData && stateData['string_vl_cb_state']) {
      const stateInfoObject = states.filter((item) => item.name === stateData['string_vl_cb_state']);
      handleGetCurrCities(stateInfoObject[0], '');
    }
    if (stateData && stateData['string_vl_cb_per_state']) {
      const stateInfoObject = states.filter((item) => item.name === stateData['string_vl_cb_per_state']);
      handleGetPerCities(stateInfoObject[0], '');
    }
  }, [stateData]);

  useEffect(() => {
    if (!viewPerAddress) {
      bookLoansFormJsonFields().map((item, idx) => {
        if (item.dept == 'Co-Borrower Permanent Address') {
          let addressField = `${item.type}_vl_${item.field}`;
          setStateData((prevState) => ({
            ...prevState,
            [addressField]: null,
          }));
          const str = item.field == 'cb_per_state' || item.field == 'cb_per_city' || item.field == 'cb_per_pincode' ? '' : 'resi_';
          let field = item.field.replace('per_', str);
          const value = stateData[`${item.type}_vl_${field}`];
          if (value) {
            let perField = `${item.type}_vl_${item.field}`;
            let isValid = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
            setStateData((prevState) => ({
              ...prevState,
              [perField]: value,
            }));
            setValidationData((prevState) => ({
              ...prevState,
              [`${perField}State`]: !isValid ? 'has-danger' : '',
            }));
          }
        }
      });
    } else {
      if (!coAppStatus[coAppIndex - 1]) {
        bookLoansFormJsonFields().map((item, idx) => {
          if (item.dept == 'Co-Borrower Permanent Address') {
            let field = `${item.type}_vl_${item.field}`;
            setStateData((prevState) => ({
              ...prevState,
              [field]: null,
            }));
          }
        });
      }
    }
  }, [viewPerAddress]);

  useEffect(() => {
    if (stateData && stateData['date_vl_cb_dob']) {
      handleAge();
    }
  }, [stateData?.date_vl_cb_dob]);

  const handleAge = () => {
    const dob = stateData['date_vl_cb_dob'];
    const yyyyMmDdRegExp = /^\d{4}-\d{2}-\d{2}$/.test(dob);
    if (yyyyMmDdRegExp) {
      const age = calculateAge(dob.substring(5, 7), dob.substring(8, 10), dob.substring(0, 4));

      setStateData((prevState) => ({
        ...prevState,
        number_vl_cb_age: age,
      }));
      let field = `number_vl_cb_age`;
      let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), age);
      setValidationData((prevState) => ({
        ...prevState,
        [`${field}State`]: !isValid ? 'has-danger' : '',
      }));
      return age;
    } else {
      setStateData((prevState) => ({
        ...prevState,
        number_vl_cb_age: ' ',
      }));
      return ' ';
    }
  };

  useEffect(() => {
    let data = {
      ...stateData,
    };
    if (stateData && stateData['date_vl_cb_dob']) {
      data = {
        ...data,
        number_vl_cb_age: handleAge(),
      };
    }

    setStateData({ ...data, ...coBorrowersData[coAppIndex - 1] });
    if (coAppStatus[coAppIndex - 1]) {
      setBackgroundColorBlur(true);
    }
  }, [coAppStatus]);

  useEffect(() => {
    if (stateData?.borrower_id && documents) {
      getLoanDocuments();
    }
  }, [documents]);

  useEffect(() => {
    getLeadStatus();
  }, [loanSectionObject, coAppIndex]);

  const fetchSectionStatus = () => {
    loanDetailsSubsectionStatus && loanDetailsSubsectionStatus[code] && loanDetailsSubsectionStatus[code]?.borrower_okyc && setSubsectionStatus(loanDetailsSubsectionStatus[code]?.borrower_okyc);
  };

  useEffect(() => {
    if (loanAppId && LAPCompanyId && LAPProductId) {
      fetchSectionStatus();
    }
  }, []);

  const getLeadStatus = () => {
    if (loanSectionObject && loanSectionObject[`co_borrower-${coAppIndex}`]) {
      setStatusObject(loanSectionObject[`co_borrower-${coAppIndex}`]);
    }
  };

  const radioCss = {
    color: 'var(--neutrals-neutral-100, #161719)',
    fontFamily: 'Montserrat-Regular',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    marginTop: '1%',
  };

  const radioInputCss = {
    accentColor: '#134CDE',
    marginRight: '8px',
    marginBottom: '4px',
    height: '20px',
    width: '20px',
    verticalAlign: 'middle',
  };

  const InputBoxStyle = {
    marginTop: '8px',
    maxHeight: '500px',
    zIndex: 1,
    width: '105%',
  };

  const customButtonStyle = {
    fontSize: '16px',
    color: '#134CDE',
    border: '1px solid #134CDE',
    marginLeft: '2%',
    width: '20vw',
    height: '48px',
    paddingLeft: '1%',
    paddingRight: '1%',
    borderRadius: '32px',
    pointerEvents: disableDraftButton === true ? 'none' : 'auto',
  };
  const isAddCoBorrowerVisible = () => coBorrowerArray?.length < 15;
  const isCoBorrowerEnable = isAddCoBorrowerVisible() && isFormCompleted;
  const isFileInputAllowedToChange = () => (props.type == 'view' || navIconPrefixState['Co-Borrower Details'] == LeadNewStatus.Approved || navIconPrefixState['Co-Borrower Details'] == LeadNewStatus.Deviation || (leadStatus == LeadNewStatus.Draft && coAppIndex < coBorrowerArray.length) || sectionStatus.includes(statusObject?.section_status) ? false : true);

  const customAddCoBorrowerButtonStyle = {
    fontSize: '16px',
    marginLeft: '2%',
    width: '20vw',
    height: '48px',
    paddingLeft: '1%',
    paddingRight: '1%',
    borderRadius: '32px',
    fontSize: '16px',
    color: isCoBorrowerEnable ? '#134CDE' : '#ffffff',
    border: isCoBorrowerEnable && '1px solid #134CDE',
    pointerEvents: isCoBorrowerEnable ? 'auto' : 'none',
    backgroundColor: !isCoBorrowerEnable && 'var(--neutrals-neutral-30, #CCCDD3)',
  };

  const CustomHeaderStyle = {
    fontFamily: 'Montserrat-Bold',
    marginLeft: '2%',
    marginTop: '2%',
    marginBottom: '20px',
    color: '#161719',
    fontSize: '1.3vw',
  };

  const customSubHeaderStyle = {
    fontFamily: 'Montserrat-SemiBold',
    marginLeft: '2%',
    marginTop: '2%',
    color: '#161719',
    fontSize: '1.2vw',
    marginRight: '1%',
    marginBottom: '15px',
  };

  const CustomHeader = {
    fontFamily: 'Montserrat-SemiBold',
    marginLeft: '2%',
    marginTop: '1%',
    color: '#161719',
    fontSize: '1.0vw',
    marginRight: '1%',
    color: '#767888',
  };

  const handlePANRadioButton = (event) => {
    if (event.target.value == 'Image & PDF' && selectedPANFileType['Image & PDF'] && !selectedPANFileType['Response XML/JSON']) return;
    else if (event.target.value == 'Response XML/JSON' && !selectedPANFileType['Image & PDF'] && selectedPANFileType['Response XML/JSON']) return;
    if (selectedPANFileType[event.target.value]) {
      setSelectedPANFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: false,
      }));
    } else {
      setSelectedPANFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: true,
      }));
    }
    if (event.target.value === 'Response XML/JSON' && documentStateData.Pan_XML) {
      deleteFile([documentCode.cb_pan_XML]);
      setDocumentStateData({ ...documentStateData, Pan_XML: false });
    } else if (event.target.value === 'Image & PDF' && documentStateData.Pan_1) {
      setDocumentStateData({ ...documentStateData, Pan_1: false });
      deleteFile([documentCode.cb_pan]);
    }
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const sectionName = 'co-borrowers';

  const setStatusCheckApi = async (loanAppID, sectionCode, subSectionCode, dispatch, addCoApp) => {
    intervalId = setInterval(async () => {
      try {
        const status = await getSectionStatus(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch, false, code);
        if (status == 'approved') {
          if (subSectionCode == 'co-borrowers-pan-verify') {
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            showAlert('PAN verified successfully', 'success');
            setPanBorder('1px solid green');
            setPanVerify(true);
            setIsNameMisMatch(false);
            fetchLoanDetails(0);
          } else {
            if (addCoApp == 1) {
              handleCoAppNumber();
            } else {
              setNavIconPrefixState((prevState) => ({
                ...prevState,
                'Co-Borrower Details': 'success',
              }));
              setSectionStatusCheck('completed');
              setTimeout(() => {
                if (props?.setShouldFetch) {
                  setShouldFetch((prev) => prev + 1);
                }
              }, 1000);
            }
          }
          clearInterval(intervalId);
        } else if (status == 'deviation') {
          if (subSectionCode == 'co-borrowers-pan-verify') {
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            showAlert('PAN verified successfully', 'success');
            setPanBorder('1px solid yellow');
            setPanVerify(true);
            setIsNameMisMatch(false);
            fetchLoanDetails(0);
          } else {
            if (addCoApp == 1) {
              handleCoAppNumber();
            } else {
              setNavIconPrefixState((prevState) => ({
                ...prevState,
                'Co-Borrower Details': 'deviation',
              }));
              setSectionStatusCheck('completed');
              setTimeout(() => {
                if (props?.setShouldFetch) {
                  setShouldFetch((prev) => prev + 1);
                }
              }, 1000);
            }
          }
          clearInterval(intervalId);
        } else if (status == 'in_review') {
          if (subSectionCode == 'co-borrowers-pan-verify') {
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            showAlert('PAN verified successfully', 'success');
            setPanBorder('1px solid yellow');
            setPanVerify(true);
            setIsNameMisMatch(false);
            fetchLoanDetails(0);
          } else {
            if (addCoApp == 1) {
              handleCoAppNumber();
            } else {
              setNavIconPrefixState((prevState) => ({
                ...prevState,
                'Co-Borrower Details': 'deviation',
              }));
              setSectionStatusCheck('completed');
              setTimeout(() => {
                if (props?.setShouldFetch) {
                  setShouldFetch((prev) => prev + 1);
                }
              }, 1000);
            }
          }
          clearInterval(intervalId);
        } else if (status == 'in_progress') {
          if (subSectionCode == 'co-borrowers-pan-verify') {
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            showAlert('PAN verified successfully', 'success');
            setPanBorder('1px solid yellow');
            setPanVerify(true);
            setIsNameMisMatch(false);
            fetchLoanDetails(0);
          } else {
            if (addCoApp == 1) {
              handleCoAppNumber();
            } else {
              setNavIconPrefixState((prevState) => ({
                ...prevState,
                'Co-Borrower Details': 'deviation',
              }));
              setSectionStatusCheck('completed');
              setTimeout(() => {
                if (props?.setShouldFetch) {
                  setShouldFetch((prev) => prev + 1);
                }
              }, 1000);
            }
          }
          clearInterval(intervalId);
        } else if (status == 'rejected') {
          if (subSectionCode == 'co-borrowers-pan-verify') {
            setPanVerify(false);
            setPanButtonState('button');
            setBackgroundColorBlur(true);
            fetchLoanDetails(0);
            showAlert('Pan Rejected', 'error');
          } else {
            showAlert('Bureau Rejected.', 'error');
            setSectionStatusCheck('');
            setAddCoBorrowerLoader('');
            setIsLeadRejected(true);
          }
          clearInterval(intervalId);
        }
      } catch (error) {
        showAlert('Technical error, please try again.', 'error');
        clearInterval(intervalId);
      }
    }, 3000);
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
  };

  const updateCoborrowerDetails = (response) => {
    setStateData((prevState) => ({
      ...prevState,
      [`_id`]: response?.coborrower || response?.coborrower_id || stateData?._id,
      [`borrower_id`]: response?.borrower_id || stateData?.borrower_id,
      sequence_no: response?.sequence_no,
    }));
  };

  const deleteFile = async (documentCodes) => {
    const payload = {
      company_id: LAPCompanyId,
      product_id: LAPProductId,
      user: user,
      loanAppId: loanAppId,
      code: [...documentCodes],
      borrowerId: stateData?.borrower_id,
    };
    new Promise((resolve, reject) => {
      dispatch(patchLapDocDeleteWatcher(payload, resolve, reject));
    })
      .then((response) => {
        showAlert(response.message, 'success');
        setShouldFetchDocument((prev) => prev + 1);
      })
      .catch((error) => {
        showAlert(error, 'error');
      });
  };

  const handleAadharRadioButton = (event) => {
    if (event.target.value == 'Image & PDF' && selectedAadharFileType['Image & PDF'] && !selectedAadharFileType['Response XML/JSON']) return;
    else if (event.target.value == 'Response XML/JSON' && !selectedAadharFileType['Image & PDF'] && selectedAadharFileType['Response XML/JSON']) return;
    if (selectedAadharFileType[event.target.value]) {
      setAadharFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: false,
      }));
    } else {
      setAadharFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: true,
      }));
    }
    if (event.target.value === 'Response XML/JSON' && documentStateData.Aadhar_XML) {
      setDocumentStateData({ ...documentStateData, Aadhar_XML: false });
      deleteFile([documentCode.cb_aadhaar_XML]);
    } else if (event.target.value === 'Image & PDF' && documentStateData.Aadhar_Front_1) {
      setDocumentStateData({ ...documentStateData, Aadhar_Front_1: false, Aadhar_Back_1: false });
      deleteFile([documentCode.cb_aadhaar_front, documentCode.cb_aadhaar_back]);
    }
  };

  const saveCoBorrowerDraft = (firstDraft = false) => {
    const payloadtoFetch = {
      user_id: user._id,
      companyId: LAPCompanyId,
      productId: LAPProductId,
      loan_app_id: loanAppId,
      lap_company_id: LAPCompanyId,
      lap_product_id: LAPProductId,
      user: JSON.stringify(user),
    };
    dispatch(
      getBookLoanDetailsWatcher(
        payloadtoFetch,
        async (response) => {
          let id;
          if (response?.data?.[0]?.coborrower?.[coAppIndex - 1]?._id) {
            id = response?.data?.[0]?.coborrower?.[coAppIndex - 1][`_id`];
          } else {
            id = -1;
          }
          if (id) {
            setDisableDraftButton(false);
            const payload = {
              section: 'co-borrowers',
              verify_next: false,
              loan_app_id: loanAppId,
              lap_company_id: LAPCompanyId,
              company_id: LAPCompanyId,
              lap_product_id: LAPProductId,
              product_id: LAPProductId,
              user_id: user._id,
              sequence_no: parseInt(SectionData.co_borrower.section_sequence_no) + (coAppIndex - 1),
              userData: {
                company_id: LAPCompanyId,
                product_id: LAPProductId,
                user_id: user._id ? user._id : '',
              },
              is_delete: false,
              partner_loan_app_id: loanAppId,
              section: 'co-borrowers',
              lap_company_id: LAPCompanyId,
              company_id: LAPCompanyId,
              lap_product_id: LAPProductId,
              product_id: LAPProductId,
              user_id: user._id,
              address_same: viewPerAddress ? 0 : 1,
              cb_fname: stateData[`formatname_vl_cb_fname`],
              cb_email: stateData['email_vl_cb_email'],
              cb_mname: stateData[`formatname_vl_cb_mname`] ?? ' ',
              cb_lname: stateData[`formatname_vl_cb_lname`],
              cb_father_name: stateData[`formatname_vl_cb_father_name`],
              cb_mobile: stateData[`mobile_vl_cb_mobile`],
              cb_resi_addr_ln1: stateData[`string_vl_cb_resi_addr_ln1`],
              cb_resi_addr_ln2: stateData[`string_vl_cb_resi_addr_ln2`] ?? ' ',
              cb_city: stateData[`string_vl_cb_city`],
              cb_state: stateData[`string_vl_cb_state`],
              cb_pincode: stateData[`pincode_vl_cb_pincode`],
              cb_per_addr_ln1: stateData[`string_vl_cb_per_addr_ln1`],
              cb_per_addr_ln2: stateData[`string_vl_cb_per_addr_ln2`] ?? ' ',
              cb_per_city: stateData[`string_vl_cb_per_city`],
              cb_per_state: stateData[`string_vl_cb_per_state`],
              cb_per_pincode: stateData[`pincode_vl_cb_per_pincode`],
              cb_pan: stateData[`pan_vl_cb_pan`],
              cb_dob: stateData[`date_vl_cb_dob`],
              cb_age: stateData[`number_vl_cb_age`],
              cb_gender: stateData[`string_vl_cb_gender`],
              cb_relationship: stateData[`string_vl_cb_relationship`],
              cb_property_owner: stateData[`string_vl_cb_property_owner`],
              cb_entity_shareholder: stateData[`string_vl_cb_entity_shareholder`],
              cb_aadhaar: stateData[`aadhaar_vl_cb_aadhaar`],
              sub_section_code: SectionData.co_borrower.co_borrower_pan.sub_section_code,
              section_sequence_no: parseInt(SectionData.co_borrower.section_sequence_no) + coAppIndex,
              section_name: SectionData.co_borrower.section_name + `${coAppIndex}`,
              sub_section_name: SectionData.co_borrower.co_borrower_pan.sub_section_name,
              sub_section_sequence_no: SectionData.co_borrower.co_borrower_pan.sub_section_sequence_no,
              section_code: SectionData.co_borrower.section_code,
              _id: stateData[`_id`],
            };
            if (stateData[`_id`]) {
              payload._id = stateData[`_id`];
            }
            saveLogs(loanAppId, `co_borrower_${coAppIndex}_save_as_draft`);
            new Promise((resolve, reject) => {
              dispatch(patchLapSaveAsDraftDetailsWatcher(payload, resolve, reject));
            })
              .then((response) => {
                updateCoborrowerDetails({ ...response?.data, sequence_no: payload.sequence_no });
                setDisableDraftButton(true);
                showAlert('Draft saved successfully', 'success');
              })
              .catch((error) => {
                showAlert(error?.message ?? 'Error while saving draft', 'error');
              });
          } else {
          }
        },
        (error) => {},
      ),
    );
  };

  const patchCall = () => {
    const bookLoanPayload = {
      user_id: user._id,
      companyId: LAPCompanyId,
      productId: LAPProductId,
      loan_app_id: loanAppId,
      user: JSON.stringify(user),
    };
    setPanButtonState('loader');
    new Promise((resolve, reject) => {
      dispatch(getBookLoanDetailsWatcher(bookLoanPayload, resolve, reject));
    })
      .then((response) => {
        let apiResponse = response?.data?.[0];
        const payload = {
          section: 'co-borrowers',
          verify_next: false,
          pan_verify: panVerify,
          loan_app_id: loanAppId,
          lap_company_id: LAPCompanyId,
          lap_product_id: LAPProductId,
          user_id: user._id,
          sequence_no: parseInt(SectionData.co_borrower.section_sequence_no) + (coAppIndex - 1),
          data: {
            partner_loan_app_id: loanAppId,
            section: 'co-borrowers',
            lap_company_id: LAPCompanyId,
            lap_product_id: LAPProductId,
            user_id: user._id,
            address_same: viewPerAddress ? 0 : 1,
            cb_fname: stateData[`formatname_vl_cb_fname`],
            cb_email: stateData['email_vl_cb_email'],
            cb_mname: stateData[`formatname_vl_cb_mname`] ?? ' ',
            cb_lname: stateData[`formatname_vl_cb_lname`],
            cb_father_name: stateData[`formatname_vl_cb_father_name`],
            cb_mobile: stateData[`mobile_vl_cb_mobile`],
            cb_resi_addr_ln1: stateData[`string_vl_cb_resi_addr_ln1`],
            cb_resi_addr_ln2: stateData[`string_vl_cb_resi_addr_ln2`] ?? ' ',
            cb_city: stateData[`string_vl_cb_city`],
            cb_state: stateData[`string_vl_cb_state`],
            cb_pincode: stateData[`pincode_vl_cb_pincode`],
            cb_per_addr_ln1: stateData[`string_vl_cb_per_addr_ln1`],
            cb_per_addr_ln2: stateData[`string_vl_cb_per_addr_ln2`] ?? ' ',
            cb_per_city: stateData[`string_vl_cb_per_city`],
            cb_per_state: stateData[`string_vl_cb_per_state`],
            cb_per_pincode: stateData[`pincode_vl_cb_per_pincode`],
            cb_pan: stateData[`pan_vl_cb_pan`],
            cb_dob: stateData[`date_vl_cb_dob`],
            cb_age: stateData[`number_vl_cb_age`],
            cb_gender: stateData[`string_vl_cb_gender`],
            cb_relationship: stateData[`string_vl_cb_relationship`],
            cb_property_owner: stateData[`string_vl_cb_property_owner`],
            cb_entity_shareholder: stateData[`string_vl_cb_entity_shareholder`],
            sub_section_code: SectionData.co_borrower.co_borrower_pan.sub_section_code,
            section_sequence_no: parseInt(SectionData.co_borrower.section_sequence_no) + (coAppIndex - 1),
            section_name: SectionData.co_borrower.section_name + `${coAppIndex}`,
            sub_section_name: SectionData.co_borrower.co_borrower_pan.sub_section_name,
            sub_section_sequence_no: SectionData.co_borrower.co_borrower_pan.sub_section_sequence_no,
            section_code: SectionData.co_borrower.section_code,
            _id: stateData[`_id`],
          },
        };
        if (stateData[`_id`]) {
          payload._id = stateData[`_id`];
        }
        saveLogs(loanAppId, `co_borrower_${coAppIndex}_pan_verify`);
        new Promise((resolve, reject) => {
          dispatch(patchLapDetailsWatcher(payload, resolve, reject));
        })
          .then((response) => {
            updateCoborrowerDetails({ ...response?.data, sequence_no: payload.sequence_no });
            setStatusCheckApi(loanAppId, SectionData.co_borrower.section_code, SectionData.co_borrower.co_borrower_pan.sub_section_code, dispatch, 0);
          })
          .catch((error) => {
            const errorResponse = error?.response?.data;
            if (!_isEmpty(errorResponse?.data)) {
              updateCoborrowerDetails({ ...errorResponse?.data, sequence_no: payload.sequence_no });
              if (errorResponse?.data?.name_mismatch === 'Yes') {
                setIsNameMisMatch(true);
              }
            }
            showAlert(error?.response?.data?.message, 'error');
            setPanButtonState('button');
          });
      })
      .catch((error) => {
        showAlert(error?.message ?? 'Unable to update lead details.', 'error');
        setPanButtonState('button');
      });
  };

  const handleCoBorrowerArray = () => {
    let temp = [...coBorrowerArray];
    for (let i = 1; i <= CoBorrowerCount; i++) {
      if (i > coBorrowerArray.length) {
        temp.push({ id: i, name: `Co-Borrower ${i}` });
      }
    }
    setCoBorrowerArray(temp);
  };

  const fetchLoanDetails = (addCoApp) => {
    let coAppNumber = 0;
    const payload = {
      user_id: user._id,
      companyId: LAPCompanyId,
      productId: LAPProductId,
      loan_app_id: loanAppId,
      user: JSON.stringify(user),
    };
    if (loanAppId) {
      dispatch(
        getBookLoanDetailsWatcher(
          payload,
          async (res) => {
            let arr = [...coBorrowersData];
            setLeadStatus(res?.data?.[0]?.lead_status);
            let resCoBorrowersData = res?.data?.[0];
            if (resCoBorrowersData?.coborrower) {
              let statusArr = Array.from({ length: 15 }, () => false);
              coAppNumber = resCoBorrowersData['coborrower'].length >= 15 ? 15 : resCoBorrowersData['coborrower'].length;
              for (let i = 0; i < (resCoBorrowersData['coborrower'].length >= 15 ? 15 : resCoBorrowersData['coborrower'].length); i++) {
                const result = resCoBorrowersData['coborrower'][i];
                bookLoansFormJsonFields().map((item, idx) => {
                  if (item.dept == 'Co-Borrower Details' || item.dept === 'Co-Borrower Current Address' || item.dept === 'Co-Borrower Permanent Address' || item.dept === 'Co-Borrower KYC') {
                    if (result[`${item.field}`]) {
                      arr[i][`${item.type}_vl_${item.field}`] = result[`${item.field}`] ?? '';
                    } else if (result[`_id`]) {
                      addCoApp = 0;
                    }
                  }
                });
                arr[i][`_id`] = result[`_id`];
                if (result[`borrower_id`]) {
                  arr[i][`borrower_id`] = result[`borrower_id`];
                }
                arr[i][`address_same`] = result[`address_same`];
                if (result['is_pan_pdf_upload'] !== null) {
                  arr[i]['panFileType'] = result['is_pan_pdf_upload'] === 1 ? { 'Image & PDF': true, 'Response XML/JSON': false } : { 'Image & PDF': false, 'Response XML/JSON': true };
                }
                if (result['is_aadhar_pdf_upload'] !== null) {
                  arr[i]['aadhaarFileType'] = result['is_aadhar_pdf_upload'] === 1 ? { 'Image & PDF': true, 'Response XML/JSON': false } : { 'Image & PDF': false, 'Response XML/JSON': true };
                }
              }
              setCoAppStatus(statusArr);
              setCoBorrowersData(arr);
              setStateData({ ...arr[coAppIndex - 1] });
            }
          },
          (error) => {},
        ),
      );
    }
  };

  const handleNextCoBorrowerAdd = () => {
    let formValidated = true;
    setDocError(true);
    bookLoansFormJsonFields().map((item, idx) => {
      if (item.field == 'cb_aadhaar' && item.isOptional === false) {
        const field = `${item.type}_vl_${item.field}`;
        if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
        if (!stateData[field]) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
      }
    });
    if (formValidated && documentStateData.Borrower_Selfie_1 && (documentStateData.Aadhar_Front_1 || documentStateData.Aadhar_XML) && (documentStateData.Pan_1 || documentStateData.Pan_XML)) {
      const bookLoanPayload = {
        user_id: user._id,
        companyId: LAPCompanyId,
        productId: LAPProductId,
        loan_app_id: loanAppId,
        user: JSON.stringify(user),
      };
      new Promise((resolve, reject) => {
        dispatch(getBookLoanDetailsWatcher(bookLoanPayload, resolve, reject));
      })
        .then((response) => {
          let id = -1;
          if (response?.data?.[0]?.coborrower?.length == parseInt(item[item.length - 1])) {
            id = response?.data?.[0]?.coborrower[parseInt(item[item.length - 1]) - 1]?._id ?? -1;
          } else if (stateData[`_id`]) {
            id = stateData[`_id`];
          }
          stateData.loanAppId = loanAppId;
          stateData.section = 'co-borrowers';
          const payload = {
            section: 'co-borrowers',
            verify_next: false,
            pan_verify: panVerify,
            loan_app_id: loanAppId,
            lap_company_id: LAPCompanyId,
            lap_product_id: LAPProductId,
            user_id: user._id,
            data: {
              partner_loan_app_id: loanAppId,
              section: 'co-borrowers',
              lap_company_id: LAPCompanyId,
              lap_product_id: LAPProductId,
              user_id: user._id,
              address_same: viewPerAddress ? 0 : 1,
              cb_fname: stateData[`formatname_vl_cb_fname`],
              cb_email: stateData['email_vl_cb_email'],
              cb_mname: stateData[`formatname_vl_cb_mname`] ?? ' ',
              cb_lname: stateData[`formatname_vl_cb_lname`],
              cb_father_name: stateData[`formatname_vl_cb_father_name`],
              cb_mobile: stateData[`mobile_vl_cb_mobile`],
              cb_resi_addr_ln1: stateData[`string_vl_cb_resi_addr_ln1`],
              cb_resi_addr_ln2: stateData[`string_vl_cb_resi_addr_ln2`] ?? ' ',
              cb_city: stateData[`string_vl_cb_city`],
              cb_state: stateData[`string_vl_cb_state`],
              cb_pincode: stateData[`pincode_vl_cb_pincode`],
              cb_per_addr_ln1: stateData[`string_vl_cb_per_addr_ln1`],
              cb_per_addr_ln2: stateData[`string_vl_cb_per_addr_ln2`] ?? ' ',
              cb_per_city: stateData[`string_vl_cb_per_city`],
              cb_per_state: stateData[`string_vl_cb_per_state`],
              cb_per_pincode: stateData[`pincode_vl_cb_per_pincode`],
              cb_pan: stateData[`pan_vl_cb_pan`],
              cb_dob: stateData[`date_vl_cb_dob`],
              cb_age: stateData[`number_vl_cb_age`],
              cb_gender: stateData[`string_vl_cb_gender`],
              cb_relationship: stateData[`string_vl_cb_relationship`],
              cb_property_owner: stateData[`string_vl_cb_property_owner`],
              cb_entity_shareholder: stateData[`string_vl_cb_entity_shareholder`],
              cb_aadhaar: stateData[`aadhaar_vl_cb_aadhaar`],
              sub_section_code: SectionData.co_borrower.co_borrower_add_coborrower.sub_section_code,
              section_sequence_no: code,
              section_name: SectionData.co_borrower.section_name + `${coAppIndex}`,
              sub_section_name: SectionData.co_borrower.co_borrower_add_coborrower.sub_section_name,
              sub_section_sequence_no: SectionData.co_borrower.co_borrower_add_coborrower.sub_section_sequence_no,
              section_code: SectionData.co_borrower.section_code,
              is_pan_pdf_upload: selectedPANFileType['Image & PDF'] ? 1 : 0,
              is_aadhar_pdf_upload: selectedAadharFileType['Image & PDF'] ? 1 : 0,
            },
          };
          setAddCoBorrowerLoader('inProgress');
          new Promise((resolve, reject) => {
            dispatch(patchLapDetailsWatcher(payload, resolve, reject));
          })
            .then((response) => {
              setStatusCheckApi(loanAppId, SectionData.co_borrower.section_code, SectionData.co_borrower.co_borrower_add_coborrower.sub_section_code, dispatch, 1);
            })
            .catch((error) => {
              showAlert(error, 'error');
            });
        })
        .catch((error) => {
          showAlert('Error in fetching loan details', 'error');
        });
    } else {
      showAlert('Kindly check for errors in fields', 'error');
    }
  };

  const handleCoAppNumber = () => {
    let formValidated = true;
    setDocError(true);
    bookLoansFormJsonFields().map((item, idx) => {
      if (item.field == 'cb_aadhaar' && item.isOptional === false) {
        const field = `${item.type}_vl_${item.field}`;
        if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
        if (!stateData[field]) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
      }
    });
    if (formValidated && documentStateData.Borrower_Selfie_1 && (documentStateData.Aadhar_Front_1 || documentStateData.Aadhar_XML) && (documentStateData.Pan_1 || documentStateData.Pan_XML)) {
      let newCoBorrower = {};
      if (CoBorrowerCount < 15) {
        newCoBorrower = { id: CoBorrowerCount + 1, name: `Co-Borrower ${CoBorrowerCount + 1}` };
        {
          coBorrowerArray?.length >= coBorrowersData?.length && setCoBorrowersData((prevData) => [...prevData, {}]);
        }
      } else {
        let coBorrowerAdded = false;
        coBorrowerArray.forEach((item, index) => {
          if (item.id !== index + 1 && !coBorrowerAdded) {
            newCoBorrower = { id: index + 1, name: `Co-Borrower ${index + 1}` };
            coBorrowerAdded = true;
          }
        });
      }
      setCoBorrowerArray((prevData) => [...prevData, newCoBorrower]);
      setAddCoBorrowerLoader('completed');
      showAlert('Co-borrower details added successfully', 'success');
      setCoBorrowerCount(CoBorrowerCount + 1);
      setDocError(false);
      setBackgroundColorBlur(true);
      setShouldFetchDocument((prevData) => prevData + 1);
    } else {
      showAlert('Kindly check for errors in fields', 'error');
    }
  };

  const calculateAge = (birthMonth, birthDay, birthYear) => {
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth();
    var currentDay = currentDate.getDate();
    var calculatedAge = currentYear - birthYear;
    if (currentMonth < parseInt(birthMonth) - 1) {
      calculatedAge--;
    }
    if (parseInt(birthMonth) - 1 == currentMonth && currentDay < parseInt(birthDay)) {
      calculatedAge--;
    }
    return calculatedAge;
  };

  const changeDateSelected = (value, name) => {
    const pastYear = new Date(new Date().setFullYear(new Date().getFullYear()));
    const selectedDate = moment(pastYear).format('YYYY-MM-DD');
    if (selectedDate > value) {
      const date = verifyDateAfter1800(moment(value).format('YYYY-MM-DD')) ? moment(value).format('YYYY-MM-DD') : value;
      const isValid = validateData(name.substring(0, name.indexOf('_vl_')).toLowerCase(), date);
      setStateData((prevState) => ({
        ...prevState,
        [name]: date,
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`${name}State`]: !isValid ? 'has-danger' : '',
      }));
    } else {
      const isValid = false;
      setStateData((prevState) => ({
        ...prevState,
        number_vl_age: ' ',
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`${name}State`]: !isValid ? 'has-danger' : '',
      }));
    }
  };

  const handleGetCurrCities = async (value, name) => {
    setCurrCityState(Cities(value));
  };

  const handleGetPerCities = async (value, name) => {
    setPerCityState(Cities(value));
  };

  const checkCityAndState = (section) => {
    let currArr;
    let perArr;
    if (section == 'city') {
      currArr = currCity;
      perArr = perCity;
    } else {
      currArr = States;
      perArr = States;
    }
    let isCurrCityValid = false;
    let isPerCityValid = false;
    if (!viewPerAddress) {
      if (stateData[`string_vl_cb_${section}`]) {
        currArr.map((item, idx) => {
          if (item.label == stateData[`string_vl_cb_${section}`]) {
            isCurrCityValid = true;
          }
        });
      }
      if (!isCurrCityValid) {
        setValidationData((prevState) => ({
          ...prevState,
          [`string_vl_cb_${section}State`]: 'has-danger',
        }));
      }
      return isCurrCityValid;
    } else {
      if (stateData[`string_vl_cb_${section}`]) {
        currArr.map((item, idx) => {
          if (item.label == stateData[`string_vl_cb_${section}`]) {
            isCurrCityValid = true;
          }
        });
        if (!isCurrCityValid) {
          setValidationData((prevState) => ({
            ...prevState,
            [`string_vl_cb_${section}State`]: 'has-danger',
          }));
          return isCurrCityValid;
        }
      }
      if (stateData[`string_vl_cb_per_${section}`]) {
        perArr.map((item, idx) => {
          if (item.label == stateData[`string_vl_cb_per_${section}`]) {
            isPerCityValid = true;
          }
        });
        if (!isPerCityValid) {
          setValidationData((prevState) => ({
            ...prevState,
            [`string_vl_cb_per_${section}State`]: 'has-danger',
          }));
        }
        return isCurrCityValid && isPerCityValid;
      }
    }
    return false;
  };

  const isDuplicatePan = () => {
    return coBorrowersData.find((items) => {
      return items.pan_vl_cb_pan === stateData.pan_vl_cb_pan && items._id !== stateData?._id;
    });
  };

  const change = (e, type, name) => {
    setDisableDraftButton(false);
    const buttonText = e.target?.textContent;
    const valued = buttonText;
    if (valued === 'Verify') {
      let formValidated = true;
      bookLoansFormJsonFields().map((item, idx) => {
        if ((item.dept == 'Co-Borrower Details' || item.dept == 'Co-Borrower Current Address' || item.dept == 'Co-Borrower Permanent Address' || item.field == 'cb_pan') && item.isOptional == false) {
          const field = `${item.type}_vl_${item.field}`;
          if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
            setValidationData((prevState) => ({
              ...prevState,
              [`${field}State`]: validationData[`${item.type}_vl_${item.field}State`] ?? 'has-danger',
            }));
            formValidated = false;
          }
          if (!stateData[field]) {
            setValidationData((prevState) => ({
              ...prevState,
              [`${field}State`]: validationData[`${item.type}_vl_${item.field}State`] ?? 'has-danger',
            }));
            formValidated = false;
          }
        }
      });
      if (formValidated && checkCityAndState('city') && checkCityAndState('state')) {
        if (isDuplicatePan()) {
          showAlert('PAN already exists', 'error');
        } else {
          patchCall();
        }
      } else {
        showAlert('Please fill all mandatory details to verify pan', 'error');
      }
    } else {
      let values;
      if (name === 'cb_pan') {
        values = e?.value?.toUpperCase();
        if (values?.length >= 10) {
          values = values.substring(0, 10);
        }
      } else if (name === 'cb_property_owner') {
        values = e.target.value;
      } else if (name === 'cb_entity_shareholder') {
        values = e.target.value;
      } else if (name === 'cb_aadhaar') {
        values = e.value;
        if (values?.length >= 12) {
          values = values.substring(0, 12);
        }
        if (values && (values[values.length - 1] < '0' || values[values.length - 1] > '9')) {
          values = values.substring(0, values.length - 1);
        }
      } else if (name === 'cb_mobile') {
        values = e.value;
        if (values?.length >= 10) {
          values = values.substring(0, 10);
        }
      } else if (name === 'cb_pincode' || name === 'cb_per_pincode') {
        values = e.value;
        if (values?.length >= 6) {
          values = values.substring(0, 6);
        }
      } else if (name === 'cb_resi_addr_ln1' || name === 'cb_per_addr_ln1') {
        values = e.value;
        if (values?.length >= 65) {
          values = values.substring(0, 65);
        }
      } else {
        values = e.value;
      }
      const value = values;
      const field = `${type}_vl_${name}`;
      let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), value);
      if ((field.indexOf('resi') != -1 || field == 'string_vl_cb_city' || field == 'string_vl_cb_state' || field == 'pincode_vl_cb_pincode') && !viewPerAddress) {
        let perField;
        if (field == 'string_vl_cb_city') {
          perField = `string_vl_cb_per_city`;
        } else if (field == 'string_vl_cb_state') {
          perField = `string_vl_cb_per_state`;
        } else if (field == 'pincode_vl_cb_pincode') {
          perField = `pincode_vl_cb_per_pincode`;
        } else {
          perField = field.replace('resi', 'per');
        }
        let isValidData = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
        setStateData((prevState) => ({
          ...prevState,
          [perField]: value,
        }));
        setValidationData((prevState) => ({
          ...prevState,
          [`${perField}State`]: !isValidData ? 'has-danger' : '',
        }));
      }
      setStateData((prevState) => ({
        ...prevState,
        [field]: value,
      }));

      setValidationData((prevState) => ({
        ...prevState,
        [field]: value,
      }));

      if (name === 'cb_mobile') {
        const pattern = /^[5-9]\d*$/;
        const decimalFindPattern = /\.\d*[^]+$/;
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid && value && decimalFindPattern.test(value) ? 'has-decimalErrorHelperTextRequired' : !isValid && value && !pattern.test(value) ? 'has-errorHelperTextRequired' : !isValid && value && value.length >= 1 && pattern.test(value) ? 'has-errorHelperText1Required' : !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'cb_pincode' || name === 'cb_per_pincode') {
        const decimalFindPattern = /\.\d*[^]+$/;
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid && value && decimalFindPattern.test(value) ? 'has-decimalErrorHelperTextRequired' : !isValid && value.length >= 1 && value.length < 6 ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
        }));
      } else if (name === 'cb_email') {
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid && value.length >= 1 ? 'has-errorHelperTextRequired' : '',
        }));
      } else {
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid && value.length >= 1 ? 'has-errorHelperTextRequired' : !isValid ? 'has-danger' : '',
        }));
      }
    }
    if (name === 'cb_state') {
      handleGetCurrCities(e, name);
      if (stateData.string_vl_cb_city) {
        setStateData((prevState) => ({
          ...prevState,
          [`string_vl_cb_city`]: '',
        }));
      }
    }
    if (name === 'cb_per_state') {
      handleGetPerCities(e, name);
      if (stateData.string_vl_cb_per_city) {
        setStateData((prevState) => ({
          ...prevState,
          [`string_vl_cb_per_city`]: '',
        }));
      }
    }
  };

  const rejectionEnabled = (item) => {
    if (['cb_fname', 'cb_mname', 'cb_lname', 'cb_dob', 'cb_gender', 'cb_mobile', 'cb_email', 'cb_father_name', 'cb_pan'].includes(item?.field) && !panVerify && (navIconPrefixState['Co-Borrower Details'] !== 'success' || props.type !== 'view')) {
      return true;
    }
    return false;
  };

  const renderDropDownSelectInputBox = (row) => {
    return (
      <>
        <InputBox
          id={row.field}
          label={row.title}
          isDrawdown={row.isDrawdown}
          initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
          isDisabled={(disabledFields[props.type] && disabledFields[props.type].includes(row.field)) || navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' || panVerify || checkIsNameMismatch(row) || coAppStatus[coAppIndex - 1] ? true : false}
          customDropdownClass={InputBoxStyle}
          customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
          }}
          customInputClass={{
            marginTop: '0px',
            minWidth: '100%',
            backgroundColor: '#fff',
          }}
          onClick={(event) => change(event, row.type, row.field)}
          isReadOnly={checkIsFieldReadOnly(row)}
          options={row.isDrawdown ? row.options : []}
          error={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
              : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
          }
          helperText={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                ? row.validationMsg
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                ? row.errorHelperTextRequired
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                ? row.errorHelperText1Required
                : ''
              : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
          }
        />
      </>
    );
  };

  const renderIntegerInputBox = (row) => {
    return (
      <>
        <InputBox
          id={row.field}
          label={row.title}
          type={row.field === 'cb_aadhaar' ? (props.type == 'view' || leadStatus != 'draft' ? 'text' : coAppIndex < coBorrowerArray?.length ? 'text' : 'text') : 'number'}
          isDrawdown={row.isDrawdown}
          initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
          isDisabled={
            (disabledFields[props.type] && disabledFields[props.type].includes(row.field)) ||
            (row.field === 'cb_aadhaar' && !panVerify) ||
            (row.dept == 'Co-Borrower Permanent Address' && !viewPerAddress) ||
            (panVerify && row.field !== 'cb_aadhaar') ||
            navIconPrefixState['Co-Borrower Details'] == 'success' ||
            props.type == 'view' ||
            checkIsNameMismatch(row) ||
            coAppStatus[coAppIndex - 1] ||
            (backgroundColorBlur && row.field == 'cb_aadhaar') ||
            ((leadStatus != 'draft' || coAppIndex < coBorrowerArray?.length) && row.field == 'cb_aadhaar')
              ? true
              : false
          }
          customDropdownClass={InputBoxStyle}
          customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
          }}
          customInputClass={{
            marginTop: '0px',
            minWidth: '100%',
            backgroundColor: '#fff',
          }}
          onClick={(event) => change(event, row.type, row.field)}
          isReadOnly={checkIsFieldReadOnly(row)}
          options={row.isDrawdown ? row.options : []}
          error={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-decimalErrorHelperTextRequired' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
              : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' || validationData[`${row.type}_vl_${row.field}State`] === 'has-decimalErrorHelperTextRequired'
          }
          helperText={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                ? row.validationMsg
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                ? row.errorHelperTextRequired
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                ? row.errorHelperText1Required
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-decimalErrorHelperTextRequired'
                ? row.decimalErrorHelperTextRequired
                : ''
              : stateData[`${row.type}_vl_${row.field}`] !== '' &&
                (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : validationData[`${row.type}_vl_${row.field}State`] === 'has-decimalErrorHelperTextRequired' ? row.decimalErrorHelperTextRequired : '')
          }
        />
      </>
    );
  };

  const renderStringInputBox = (row) => {
    return (
      <>
        <InputBox
          id={row.field}
          label={row.title}
          isDrawdown={row.isDrawdown ? row.isDrawdown : false}
          initialValue={row.field == 'cb_age' ? (stateData[`${row.type}_vl_${row.field}`] ? stateData[`${row.type}_vl_${row.field}`] : '{Auto populate}') : stateData[`${row.type}_vl_${row.field}`] ? stateData[`${row.type}_vl_${row.field}`] : ''}
          onClick={(event) => change(event, row.type, row.field)}
          isDisabled={(row.dept == 'Co-Borrower Permanent Address' && !viewPerAddress) || row.field === 'cb_age' || panVerify || navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? true : checkIsNameMismatch(row) || coAppStatus[coAppIndex - 1] ? true : false}
          customDropdownClass={InputBoxStyle}
          isReadOnly={checkIsFieldReadOnly(row)}
          customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
          }}
          customInputClass={{
            marginTop: '0px',
            minWidth: '100%',
            backgroundColor: '#fff',
          }}
          error={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
              : (stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger') || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
          }
          helperText={
            row.checked.toLowerCase() === 'true'
              ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'
                ? row.validationMsg
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'
                ? row.errorHelperTextRequired
                : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required'
                ? row.errorHelperText1Required
                : ''
              : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' ? row.errorHelperTextRequired : validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' ? row.errorHelperText1Required : '')
          }
        />
      </>
    );
  };

  const renderFields = (department) => {
    return (
      <>
        <div
          style={{
            display: 'grid',
            rowGap: '28px',
            gridTemplateColumns: '32.8% 32.8% 32.8%',
            columnGap: '1%',
            width: '98%',
            marginLeft: '1.7%',
          }}
        >
          {bookLoansFormJsonFields().map((item, idx) => {
            return (
              <>
                {item.dept == department ? (
                  item.field == 'cb_dob' ? (
                    <CustomDatePicker
                      customInputClass={{ height: '58px', width: '100%', maxWidth: '100%' }}
                      label="Date of Birth"
                      format={'YYYY-MM-DD'}
                      isBoxType={'icon'}
                      age={parseInt(REACT_APP_AGE_LAP)}
                      startingDate={REACT_APP_UPPERAGELIMIT_LAP ? parseInt(new Date().getFullYear()) - parseInt(REACT_APP_UPPERAGELIMIT_LAP) : 1964}
                      endingDate={2050}
                      onDateChange={(date) => changeDateSelected(date, `${item.type}_vl_${item.field}`)}
                      isDisabled={navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' || panVerify || checkIsNameMismatch(item) ? true : coAppStatus[coAppIndex - 1] ? true : false}
                      isReadOnly={checkIsFieldReadOnly(item)}
                      initialValue={stateData && stateData[`${item.type}_vl_${item.field}`] ? stateData && stateData[`${item.type}_vl_${item.field}`] : '' || null}
                      error={
                        item.checked.toLowerCase() === 'true'
                          ? validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${item.type}_vl_${item.field}`] !== '' && validationData[`${item.type}_vl_${item.field}State`] === 'has-danger') || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        item.checked.toLowerCase() === 'true'
                          ? validationData[`${item.type}_vl_${item.field}State`] === 'has-danger'
                            ? item.validationMsg
                            : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                            ? item.errorHelperTextRequired
                            : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required'
                            ? item.errorHelperText1Required
                            : ''
                          : stateData[`${item.type}_vl_${item.field}`] !== '' && (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? item.validationMsg : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired' ? item.errorHelperTextRequired : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' ? item.errorHelperText1Required : '')
                      }
                    />
                  ) : item.field == 'cb_fname' || item.field == 'cb_mname' || item.field == 'cb_lname' || item.field == 'cb_father_name' || item.field == 'cb_email' ? (
                    renderStringInputBox(item)
                  ) : item.field == 'cb_age' || item.field == 'cb_resi_addr_ln1' || item.field == 'cb_resi_addr_ln2' || item.field == 'cb_per_addr_ln1' || item.field == 'cb_per_addr_ln2' ? (
                    renderStringInputBox(item)
                  ) : item.field == 'cb_aadhaar' || item.field == 'cb_mobile' || item.field == 'cb_pincode' || item.field == 'cb_per_pincode' ? (
                    renderIntegerInputBox(item)
                  ) : item.field == 'cb_gender' || item.field == 'cb_relationship' ? (
                    renderDropDownSelectInputBox(item)
                  ) : item.field == 'cb_state' || item.field == 'cb_city' || item.field == 'cb_per_state' || item.field == 'cb_per_city' ? (
                    <InputBox
                      id={item.field}
                      label={item.title}
                      type={item.field == 'cb_aadhaar' && props.type == 'view' ? 'text' : item.field === 'cb_aadhaar' || item.field === 'cb_mobile' || item.field === 'cb_pincode' || item.field === 'cb_per_pincode' ? 'number' : 'text'}
                      isDrawdown={navIconPrefixState['Co-Borrower Details'] == 'success' ? false : item.field != 'cb_aadhaar' && panVerify ? false : (department == 'Co-Borrower Permanent Address' && !viewPerAddress) || item.field == 'cb_age' ? false : item?.isDrawdown ? true : false}
                      isDisabled={navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? true : item.field == 'cb_aadhaar' && !panVerify ? true : panVerify && item.field != 'cb_aadhaar' ? true : (department == 'Co-Borrower Permanent Address' && !viewPerAddress) || item.field == 'cb_age' || checkIsNameMismatch(item) || coAppStatus[coAppIndex - 1] ? true : false}
                      initialValue={stateData && stateData[`${item.type}_vl_${item.field}`] ? stateData[`${item.type}_vl_${item.field}`] : ''}
                      onClick={(event) => change(event, item.type, item.field)}
                      customDropdownClass={InputBoxStyle}
                      customClass={{ height: '56px', width: '100%', maxWidth: '100%' }}
                      isReadOnly={checkIsFieldReadOnly(item)}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                        marginTop: navIconPrefixState['Co-Borrower Details'] == 'success' ? '-3px' : item.field != 'cb_aadhaar' && panVerify ? '-3px' : (department == 'Co-Borrower Permanent Address' && !viewPerAddress) || item.field == 'cb_age' ? '-3px' : '0px',
                      }}
                      error={
                        item.checked.toLowerCase() === 'true'
                          ? validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${item.type}_vl_${item.field}`] !== '' && validationData[`${item.type}_vl_${item.field}State`] === 'has-danger') || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        item.checked.toLowerCase() === 'true'
                          ? validationData[`${item.type}_vl_${item.field}State`] === 'has-danger'
                            ? item.validationMsg
                            : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                            ? item.errorHelperTextRequired
                            : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required'
                            ? item.errorHelperText1Required
                            : ''
                          : stateData[`${item.type}_vl_${item.field}`] !== '' && (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? item.validationMsg : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired' ? item.errorHelperTextRequired : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' ? item.errorHelperText1Required : '')
                      }
                      options={item.isDrawdown ? (item.title === 'State' ? states : item.field === 'cb_city' ? currCity : item.field === 'cb_per_city' ? perCity : item.options) : []}
                    />
                  ) : item.field == 'cb_pan' ? (
                    <InputBox
                      isBoxType={panButtonState}
                      Buttonlabel={coAppStatus[coAppIndex - 1] || props.type == 'view' ? '' : 'Verify'}
                      id={item.field}
                      label={item.title}
                      isDisabled={rejectionEnabled(item) ? false : navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? true : coAppStatus[coAppIndex - 1] || panVerify || checkIsNameMismatch(item) ? true : false}
                      initialValue={(stateData && stateData[`${item.type}_vl_${item.field}`] ? stateData[`${item.type}_vl_${item.field}`] : '').toUpperCase() ?? ''}
                      onClick={(event) => change(event, item.type, item.field)}
                      isReadOnly={checkIsFieldReadOnly(item)}
                      customDropdownClass={InputBoxStyle}
                      customClass={{ height: '56px', width: '100%', maxWidth: '100%', border: panBorder }}
                      customInputClass={{
                        minWidth: '100%',
                        backgroundColor: '#fff',
                        marginTop: `${navIconPrefixState['Co-Borrower Details'] == 'success' ? '-3px' : coAppStatus[coAppIndex - 1] || panVerify ? '-3px' : '0px'}`,
                      }}
                      error={
                        item.checked.toLowerCase() === 'true'
                          ? validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                          : (stateData[`${item.type}_vl_${item.field}`] !== '' && validationData[`${item.type}_vl_${item.field}State`] === 'has-danger') || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                      }
                      helperText={
                        item.checked.toLowerCase() === 'true'
                          ? validationData[`${item.type}_vl_${item.field}State`] === 'has-danger'
                            ? item.validationMsg
                            : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                            ? item.errorHelperTextRequired
                            : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required'
                            ? item.errorHelperText1Required
                            : ''
                          : stateData[`${item.type}_vl_${item.field}`] !== '' && (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? item.validationMsg : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired' ? item.errorHelperTextRequired : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' ? item.errorHelperText1Required : '')
                      }
                      options={item.isDrawdown ? (item.title === 'State' ? states : item.field === 'cb_city' ? currCity : item.field === 'cb_per_city' ? perCity : item.options) : []}
                    />
                  ) : item.field == 'cb_selfie_image_value' ? (
                    <UploadFileInput
                      borrowerIndex={coAppIndex}
                      uploadFileName={uploadFileName}
                      items={coBorrowerSelfieState}
                      title=""
                      backgroundColorBlur={!panVerify ? backgroundColorBlur : navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? backgroundColorBlur : false}
                      backgroundColorChange={true}
                      isSubmit={props.type == 'view' ? false : coAppStatus[coAppIndex - 1]}
                      isXML={false}
                      loanAppId={loanAppId}
                      setDocumentStateData={setDocumentStateData}
                      data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                      showAlert={showAlert}
                      sectionName={sectionName}
                      LAPCompanyId={LAPCompanyId}
                      LAPProductId={LAPProductId}
                      isChange={isFileInputAllowedToChange()}
                      type={props.type}
                      errorValidation={!documentStateData.Borrower_Selfie_1 && panVerify && docError ? true : false}
                      borrowerId={stateData?.borrower_id}
                    />
                  ) : item.field == 'cb_pan_image_value' ? (
                    handleDocumentUploadCheckbox('panFileType', 'Image & PDF') ? (
                      <UploadFileInput
                        key={selectedPANFileType['Image & PDF']}
                        borrowerIndex={coAppIndex}
                        uploadFileName={uploadFileName}
                        items={panView}
                        title=""
                        backgroundColorBlur={!panVerify ? backgroundColorBlur : navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? backgroundColorBlur : false}
                        backgroundColorChange={true}
                        isSubmit={props.type == 'view' ? false : coAppStatus[coAppIndex - 1]}
                        isXML={false}
                        loanAppId={loanAppId}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        showAlert={showAlert}
                        borrowerId={stateData?.borrower_id}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={isFileInputAllowedToChange()}
                        type={props.type}
                        errorValidation={!(documentStateData.Pan_1 || documentStateData.Pan_XML) && panVerify && docError ? true : false}
                      />
                    ) : null
                  ) : item.field == 'cb_pan_xml_image_value' ? (
                    handleDocumentUploadCheckbox('panFileType', 'Response XML/JSON') ? (
                      <UploadFileInput
                        key={selectedPANFileType['Response XML/JSON']}
                        borrowerIndex={coAppIndex}
                        uploadFileName={uploadFileName}
                        items={panXMLView}
                        title=""
                        backgroundColorBlur={!panVerify ? backgroundColorBlur : navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? backgroundColorBlur : false}
                        backgroundColorChange={true}
                        isSubmit={props.type == 'view' ? false : coAppStatus[coAppIndex - 1]}
                        isXML={true}
                        loanAppId={loanAppId}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        borrowerId={stateData?.borrower_id}
                        showAlert={showAlert}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={isFileInputAllowedToChange()}
                        type={props.type}
                        errorValidation={!(documentStateData.Pan_1 || documentStateData.Pan_XML) && panVerify && docError ? true : false}
                      />
                    ) : null
                  ) : item.field == 'cb_aadhaar_front_image_value' || item.field == 'cb_aadhaar_back_image_value' ? (
                    handleDocumentUploadCheckbox('aadhaarFileType', 'Image & PDF') && item.field == 'cb_aadhaar_front_image_value' ? (
                      <UploadFileInput
                        key={selectedAadharFileType['Image & PDF']}
                        borrowerIndex={coAppIndex}
                        uploadFileName={uploadFileName}
                        items={aadharFrontView}
                        title=""
                        backgroundColorBlur={!panVerify ? backgroundColorBlur : navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? backgroundColorBlur : false}
                        backgroundColorChange={true}
                        isSubmit={props.type == 'view' ? false : coAppStatus[coAppIndex - 1]}
                        isXML={false}
                        loanAppId={loanAppId}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        borrowerId={stateData?.borrower_id}
                        showAlert={showAlert}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={isFileInputAllowedToChange()}
                        type={props.type}
                        errorValidation={!(documentStateData.Aadhar_Front_1 || documentStateData.Aadhar_XML) && panVerify && docError ? true : false}
                      />
                    ) : handleDocumentUploadCheckbox('aadhaarFileType', 'Image & PDF') && item.field == 'cb_aadhaar_back_image_value' ? (
                      <UploadFileInput
                        key={selectedAadharFileType['Image & PDF']}
                        borrowerIndex={coAppIndex}
                        uploadFileName={uploadFileName}
                        items={aadharBackView}
                        title=""
                        backgroundColorBlur={!panVerify ? backgroundColorBlur : navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? backgroundColorBlur : false}
                        backgroundColorChange={true}
                        isSubmit={props.type == 'view' ? false : coAppStatus[coAppIndex - 1]}
                        isXML={false}
                        loanAppId={loanAppId}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        borrowerId={stateData?.borrower_id}
                        showAlert={showAlert}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={isFileInputAllowedToChange()}
                        type={props.type}
                      />
                    ) : null
                  ) : item.field == 'cb_aadhaar_xml_image_value' ? (
                    handleDocumentUploadCheckbox('aadhaarFileType', 'Response XML/JSON') ? (
                      <UploadFileInput
                        key={selectedAadharFileType['Response XML/JSON']}
                        borrowerIndex={coAppIndex}
                        uploadFileName={uploadFileName}
                        items={aadharfrontXML}
                        title=""
                        backgroundColorBlur={!panVerify ? backgroundColorBlur : navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? backgroundColorBlur : false}
                        backgroundColorChange={true}
                        isSubmit={props.type == 'view' ? false : coAppStatus[coAppIndex - 1]}
                        isXML={true}
                        loanAppId={loanAppId}
                        setDocumentStateData={setDocumentStateData}
                        sectionName={sectionName}
                        data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                        borrowerId={stateData?.borrower_id}
                        showAlert={showAlert}
                        LAPCompanyId={LAPCompanyId}
                        LAPProductId={LAPProductId}
                        isChange={isFileInputAllowedToChange()}
                        type={props.type}
                        errorValidation={!(documentStateData.Aadhar_Front_1 || documentStateData.Aadhar_XML) && panVerify && docError ? true : false}
                      />
                    ) : null
                  ) : item.field == 'cb_entity_shareholder' ? (
                    <div className="ar-cb-entity-shareholder">
                      <p className="ar-cb-entity-shareholder-paragraph">{item.title}</p>
                      <div className="ar-cb-entity-shareholder-value">
                        <input type="radio" id={`${item.field}_yes-${itemIndex + 1}`} disabled={panVerify || navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' || checkIsNameMismatch(item) ? true : false} name={`${item.field}_yes-${itemIndex + 1}`} value={'yes'} checked={stateData[`${item.type}_vl_${item.field}`] === 'yes'} onChange={(event) => change(event, item.type, item.field)} />
                        <label htmlFor={`${item.field}_yes-${itemIndex + 1}`}>Yes</label>
                        <input type="radio" id={`${item.field}_no-${itemIndex + 1}`} disabled={panVerify || navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' || checkIsNameMismatch(item) ? true : false} name={`${item.field}_no-${itemIndex + 1}`} value={'no'} checked={stateData[`${item.type}_vl_${item.field}`] === 'no'} onChange={(event) => change(event, item.type, item.field)} />
                        <label htmlFor={`${item.field}_no-${itemIndex + 1}`}>No</label>
                      </div>
                      {validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? (
                        <div style={{ marginTop: '0' }} className={`error-message`}>
                          {item.validationMsg}
                        </div>
                      ) : null}
                    </div>
                  ) : item.field == 'cb_property_owner' ? (
                    <div className="ar-cb-property-owner">
                      <p className="ar-cb-property-owner-paragraph">{item.title}</p>
                      <div className="ar-cb-property-owner-value">
                        <input type="radio" id={`${item.field}_yes-${itemIndex + 1}`} disabled={panVerify || navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' || checkIsNameMismatch(item) ? true : false} name={`${item.field}_yes-${itemIndex + 1}`} value={'yes'} checked={stateData[`${item.type}_vl_${item.field}`] === 'yes'} onChange={(event) => change(event, item.type, item.field)} />
                        <label htmlFor={`${item.field}_yes-${itemIndex + 1}`}>Yes</label>
                        <input type="radio" id={`${item.field}_no-${itemIndex + 1}`} disabled={panVerify || navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' || checkIsNameMismatch(item) ? true : false} name={`${item.field}_no-${itemIndex + 1}`} value={'no'} checked={stateData[`${item.type}_vl_${item.field}`] === 'no'} onChange={(event) => change(event, item.type, item.field)} />
                        <label htmlFor={`${item.field}_no-${itemIndex + 1}`}>No</label>
                      </div>
                      {validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? (
                        <div style={{ marginTop: '0' }} className={`error-message`}>
                          {item.validationMsg}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <InputBox
                        id={item.field}
                        label={item.title}
                        isBoxType="button"
                        Buttonlabel={'Upload'}
                        isDrawdown={item?.isDrawdown ? true : false}
                        isDisabled={rejectionEnabled(item) ? false : navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? true : coAppStatus[coAppIndex - 1] || (department == 'Co-Borrower Permanent Address' && !viewPerAddress) ? true : false}
                        initialValue={stateData[`${item.type}_vl_${item.field}`] ?? ''}
                        onClick={(event) => change(event, item.type, item.field)}
                        customDropdownClass={InputBoxStyle}
                        customInputClass={{
                          minWidth: '100%',
                          backgroundColor: '#fff',
                          marginTop: `${navIconPrefixState['Co-Borrower Details'] == 'success' ? '-3px' : coAppStatus[coAppIndex - 1] || (department == 'Co-Borrower Permanent Address' && !viewPerAddress) ? '-3px' : '0px'}`,
                        }}
                        error={
                          item.checked.toLowerCase() === 'true'
                            ? validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                            : (stateData[`${item.type}_vl_${item.field}`] !== '' && validationData[`${item.type}_vl_${item.field}State`] === 'has-danger') || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' || validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                        }
                        helperText={
                          item.checked.toLowerCase() === 'true'
                            ? validationData[`${item.type}_vl_${item.field}State`] === 'has-danger'
                              ? item.validationMsg
                              : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired'
                              ? item.errorHelperTextRequired
                              : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required'
                              ? item.errorHelperText1Required
                              : ''
                            : stateData[`${item.type}_vl_${item.field}`] !== '' && (validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? item.validationMsg : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperTextRequired' ? item.errorHelperTextRequired : validationData[`${item.type}_vl_${item.field}State`] === 'has-errorHelperText1Required' ? item.errorHelperText1Required : '')
                        }
                        customClass={{ height: '56px', width: '100%', maxWidth: '100%' }}
                        options={item.isDrawdown ? item.options : []}
                      />
                      <p className="book-loan-helper-text">{(selectedPANFileType['Response XML/JSON'] && item.field === 'cb_pan_doc') || (selectedAadharFileType['Response XML/JSON'] && (item.field === 'cb_aadhar_front' || item.field === 'cb_aadhar_back')) ? ' XML or JSON upto 5MB' : item.field !== 'cb_pan' ? ' JPG, JPEG, PNG, PDF upto 5MB' : ''}</p>
                    </div>
                  )
                ) : null}
              </>
            );
          })}
        </div>
      </>
    );
  };

  const checkIsNameMismatch = (item) => {
    const disableFields = ['cb_dob', 'cb_gender', 'cb_mobile', 'cb_email', 'cb_father_name', 'cb_relationship', 'cb_entity_shareholder', 'cb_property_owner'];
    const disableDept = ['Co-Borrower Current Address', 'Co-Borrower Permanent Address'];
    if (isNameMismatch && (disableFields?.includes(item?.field) || disableDept?.includes(item?.dept))) return true;
    return false;
  };

  const checkIsFieldReadOnly = (item) => {
    const disableFields = ['cb_pan'];
    if (isNameMismatch && disableFields?.includes(item?.field)) return true;
    return false;
  };

  const handlePermanentAddress = () => {
    setViewPerAddress(!viewPerAddress);
  };

  const handleSaveAndNextData = () => {
    let formValidated = true;
    setSectionStatusCheck('inProgress');
    bookLoansFormJsonFields().map((item, idx) => {
      if (item.field == 'cb_aadhaar' && item.isOptional == false) {
        const field = `${item.type}_vl_${item.field}`;
        if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
        if (!stateData[field]) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
      }
    });
    if (formValidated && documentStateData.Borrower_Selfie_1 && (documentStateData.Aadhar_Front_1 || documentStateData.Aadhar_XML) && (documentStateData.Pan_1 || documentStateData.Pan_XML)) {
      const bookLoanPayload = {
        user_id: user._id,
        companyId: LAPCompanyId,
        productId: LAPProductId,
        loan_app_id: loanAppId,
        user: JSON.stringify(user),
      };
      new Promise((resolve, reject) => {
        dispatch(getBookLoanDetailsWatcher(bookLoanPayload, resolve, reject));
      })
        .then((response) => {
          let id = -1;
          if (response?.data?.[0]?.coborrower?.length == parseInt(item[item.length - 1])) {
            id = response?.data?.[0]?.coborrower[parseInt(item[item.length - 1]) - 1]?._id ?? -1;
          } else if (stateData[`_id`]) {
            id = stateData[`_id`];
          }
          stateData.loanAppId = loanAppId;
          stateData.section = 'co-borrowers';
          const payload = {
            section: 'co-borrowers',
            save_next: true,
            pan_verify: panVerify,
            loan_app_id: loanAppId,
            lap_company_id: LAPCompanyId,
            lap_product_id: LAPProductId,
            user_id: user._id,
            data: {
              partner_loan_app_id: loanAppId,
              section: 'co-borrowers',
              lap_company_id: LAPCompanyId,
              lap_product_id: LAPProductId,
              user_id: user._id,
              address_same: viewPerAddress ? 0 : 1,
              cb_fname: stateData[`formatname_vl_cb_fname`],
              cb_email: stateData['email_vl_cb_email'],
              cb_mname: stateData[`formatname_vl_cb_mname`] ?? ' ',
              cb_lname: stateData[`formatname_vl_cb_lname`],
              cb_father_name: stateData[`formatname_vl_cb_father_name`],
              cb_mobile: stateData[`mobile_vl_cb_mobile`],
              cb_resi_addr_ln1: stateData[`string_vl_cb_resi_addr_ln1`],
              cb_resi_addr_ln2: stateData[`string_vl_cb_resi_addr_ln2`] ?? ' ',
              cb_city: stateData[`string_vl_cb_city`],
              cb_state: stateData[`string_vl_cb_state`],
              cb_pincode: stateData[`pincode_vl_cb_pincode`],
              cb_per_addr_ln1: stateData[`string_vl_cb_per_addr_ln1`],
              cb_per_addr_ln2: stateData[`string_vl_cb_per_addr_ln2`] ?? ' ',
              cb_per_city: stateData[`string_vl_cb_per_city`],
              cb_per_state: stateData[`string_vl_cb_per_state`],
              cb_per_pincode: stateData[`pincode_vl_cb_per_pincode`],
              cb_pan: stateData[`pan_vl_cb_pan`],
              cb_dob: stateData[`date_vl_cb_dob`],
              cb_age: stateData[`number_vl_cb_age`],
              cb_gender: stateData[`string_vl_cb_gender`],
              cb_relationship: stateData[`string_vl_cb_relationship`],
              cb_property_owner: stateData[`string_vl_cb_property_owner`],
              cb_entity_shareholder: stateData[`string_vl_cb_entity_shareholder`],
              cb_aadhaar: stateData[`aadhaar_vl_cb_aadhaar`],
              sub_section_code: SectionData.co_borrower.co_borrower_section_submit.sub_section_code,
              section_sequence_no: code,
              section_name: SectionData.co_borrower.section_name + `${coAppIndex}`,
              sub_section_name: SectionData.co_borrower.co_borrower_section_submit.sub_section_name,
              sub_section_sequence_no: SectionData.co_borrower.co_borrower_section_submit.sub_section_sequence_no,
              section_code: SectionData.co_borrower.section_code,
              is_pan_pdf_upload: selectedPANFileType['Image & PDF'] ? 1 : 0,
              is_aadhar_pdf_upload: selectedAadharFileType['Image & PDF'] ? 1 : 0,
            },
          };
          saveLogs(loanAppId, `co_borrower_${coAppIndex}_verify_and_next`);
          new Promise((resolve, reject) => {
            dispatch(patchLapDetailsWatcher(payload, resolve, reject));
          })
            .then((response) => {
              setShouldFetch((prev) => prev + 1);
            })
            .catch((error) => {
              showAlert(error, 'error');
            });
        })
        .catch((error) => {
          showAlert('Error in fetching loan details', 'error');
          setSectionStatusCheck('');
        });
    } else {
      showAlert('Kindly check for errors in fields', 'error');
      setSectionStatusCheck('');
    }
  };

  const handleAddData = () => {
    let formValidated = true;
    setSectionStatusCheck('inProgress');
    bookLoansFormJsonFields().map((item, idx) => {
      if (item.field == 'cb_aadhaar' && item.isOptional == false) {
        const field = `${item.type}_vl_${item.field}`;
        if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
        if (!stateData[field]) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
      }
    });
    if (formValidated && documentStateData.Borrower_Selfie_1 && (documentStateData.Aadhar_Front_1 || documentStateData.Aadhar_XML) && (documentStateData.Pan_1 || documentStateData.Pan_XML)) {
      const bookLoanPayload = {
        user_id: user._id,
        companyId: LAPCompanyId,
        productId: LAPProductId,
        loan_app_id: loanAppId,
        user: JSON.stringify(user),
      };
      new Promise((resolve, reject) => {
        dispatch(getBookLoanDetailsWatcher(bookLoanPayload, resolve, reject));
      })
        .then((response) => {
          let id = -1;
          if (response?.data?.[0]?.coborrower?.length == parseInt(item[item.length - 1])) {
            id = response?.data?.[0]?.coborrower[parseInt(item[item.length - 1]) - 1]?._id ?? -1;
          } else if (stateData[`_id`]) {
            id = stateData[`_id`];
          }
          stateData.loanAppId = loanAppId;
          stateData.section = 'co-borrowers';
          const payload = {
            section: 'co-borrowers',
            verify_next: true,
            pan_verify: panVerify,
            loan_app_id: loanAppId,
            lap_company_id: LAPCompanyId,
            lap_product_id: LAPProductId,
            user_id: user._id,
            data: {
              partner_loan_app_id: loanAppId,
              section: 'co-borrowers',
              lap_company_id: LAPCompanyId,
              lap_product_id: LAPProductId,
              user_id: user._id,
              address_same: viewPerAddress ? 0 : 1,
              cb_fname: stateData[`formatname_vl_cb_fname`],
              cb_email: stateData['email_vl_cb_email'],
              cb_mname: stateData[`formatname_vl_cb_mname`] ?? ' ',
              cb_lname: stateData[`formatname_vl_cb_lname`],
              cb_father_name: stateData[`formatname_vl_cb_father_name`],
              cb_mobile: stateData[`mobile_vl_cb_mobile`],
              cb_resi_addr_ln1: stateData[`string_vl_cb_resi_addr_ln1`],
              cb_resi_addr_ln2: stateData[`string_vl_cb_resi_addr_ln2`] ?? ' ',
              cb_city: stateData[`string_vl_cb_city`],
              cb_state: stateData[`string_vl_cb_state`],
              cb_pincode: stateData[`pincode_vl_cb_pincode`],
              cb_per_addr_ln1: stateData[`string_vl_cb_per_addr_ln1`],
              cb_per_addr_ln2: stateData[`string_vl_cb_per_addr_ln2`] ?? ' ',
              cb_per_city: stateData[`string_vl_cb_per_city`],
              cb_per_state: stateData[`string_vl_cb_per_state`],
              cb_per_pincode: stateData[`pincode_vl_cb_per_pincode`],
              cb_pan: stateData[`pan_vl_cb_pan`],
              cb_dob: stateData[`date_vl_cb_dob`],
              cb_age: stateData[`number_vl_cb_age`],
              cb_gender: stateData[`string_vl_cb_gender`],
              cb_relationship: stateData[`string_vl_cb_relationship`],
              cb_property_owner: stateData[`string_vl_cb_property_owner`],
              cb_entity_shareholder: stateData[`string_vl_cb_entity_shareholder`],
              cb_aadhaar: stateData[`aadhaar_vl_cb_aadhaar`],
              sub_section_code: SectionData.co_borrower.co_borrower_section_submit.sub_section_code,
              section_sequence_no: code,
              section_name: SectionData.co_borrower.section_name + `${coAppIndex}`,
              sub_section_name: SectionData.co_borrower.co_borrower_section_submit.sub_section_name,
              sub_section_sequence_no: SectionData.co_borrower.co_borrower_section_submit.sub_section_sequence_no,
              section_code: SectionData.co_borrower.section_code,
              is_pan_pdf_upload: selectedPANFileType['Image & PDF'] ? 1 : 0,
              is_aadhar_pdf_upload: selectedAadharFileType['Image & PDF'] ? 1 : 0,
            },
          };
          saveLogs(loanAppId, `co_borrower_${coAppIndex}_verify_and_next`);
          new Promise((resolve, reject) => {
            dispatch(patchLapDetailsWatcher(payload, resolve, reject));
          })
            .then((response) => {
              setDisableDraftButton(true);
              showAlert('Co-borrower details updated successfully', 'success');
              setStatusCheckApi(loanAppId, SectionData.co_borrower.section_code, SectionData.co_borrower.co_borrower_section_submit.sub_section_code, dispatch, 0);
            })
            .catch((error) => {
              showAlert(error, 'error');
            });
        })
        .catch((error) => {
          showAlert('Error in fetching loan details', 'error');
          setSectionStatusCheck('');
        });
    } else {
      showAlert('Kindly check for errors in fields', 'error');
      setSectionStatusCheck('');
    }
  };

  const handleSkipCoborrowerSection = (isCoborrower) => {
    const bookLoanPayload = {
      user_id: user._id,
      companyId: LAPCompanyId,
      productId: LAPProductId,
      loan_app_id: loanAppId,
      user: JSON.stringify(user),
    };
    new Promise((resolve, reject) => {
      dispatch(getBookLoanDetailsWatcher(bookLoanPayload, resolve, reject));
    })
      .then((response) => {
        const payload = {
          section: 'co-borrowers',
          loan_app_id: loanAppId,
          lap_company_id: LAPCompanyId,
          lap_product_id: LAPProductId,
          user_id: user._id,
          is_skip: true,
          data: {
            partner_loan_app_id: loanAppId,
            section: 'co-borrowers',
            lap_company_id: LAPCompanyId,
            lap_product_id: LAPProductId,
            user_id: user._id,
            is_skip: true,
            section_code: SectionData.co_borrower.section_code,
          },
        };
        new Promise((resolve, reject) => {
          dispatch(patchLapDetailsWatcher(payload, resolve, reject));
        })
          .then((response) => {
            setCoBorrowerArray((prevData) => prevData.filter((item, index) => index !== itemIndex));
            setCoBorrowersData((prevData) => prevData.filter((item, index) => index !== itemIndex));
            isCoborrower && setCoBorrowerCount(CoBorrowerCount - 1);
            setNavIconPrefixState((prevState) => ({
              ...prevState,
              'Co-Borrower Details': 'success',
            }));
            setNavState('Guarantor Details');
          })
          .catch((error) => {
            showAlert(error, 'error');
          });
      })
      .catch((error) => {
        showAlert('Error in fetching loan details', 'error');
        setSectionStatusCheck('');
      });
  };

  const handleSkipButton = () => {
    if (coBorrowerArray.length > 1) {
      handleSkipCoborrowerSection(true);
    } else {
      handleSkipCoborrowerSection(false);
    }
  };

  const saveLogs = (identifier, event_name) => {
    new Promise((resolve, reject) => {
      const payload = {
        user_id: user?._id,
        identifier: identifier,
        log_details: [
          {
            user_id: user?._id,
            event_name: event_name,
            timestamp: new Date(),
          },
        ],
      };
      if (payload?.user_id && identifier && event_name) {
        dispatch(postComprehensiveLogsWatcher(payload, resolve, reject));
      }
    });
  };

  const handleRadioButton = ({ event, updateSelectedFileType, selectedFileType, docCode, docNames }) => {
    if (!selectedFileType[event.target.value]) {
      let isDocumentAvailable = docNames.find((item) => documentStateData[item]);
      if (isDocumentAvailable) {
        let updatedDocStateData = {};
        docNames.forEach((item) => {
          updatedDocStateData = { ...updatedDocStateData, [item]: false };
        });
        deleteFile([...docCode]);
        setDocumentStateData({ ...documentStateData, ...updatedDocStateData });
      }
    }
    if (event.target.value === 'Image & PDF' && !selectedFileType['Image & PDF']) {
      updateSelectedFileType((prevState) => ({
        ...prevState,
        ['Image & PDF']: true,
        ['Response XML/JSON']: false,
      }));
    } else if (event.target.value === 'Response XML/JSON' && !selectedFileType['Response XML/JSON']) {
      updateSelectedFileType((prevState) => ({
        ...prevState,
        ['Image & PDF']: false,
        ['Response XML/JSON']: true,
      }));
    }
  };

  const handlePermanentCheckbox = (status) => {
    if (status === 'checkedStatus') {
      if (props.type == 'view') {
        return stateData['address_same'] ? stateData['address_same'] : false;
      } else {
        return coAppIndex < coBorrowerArray?.length || panButtonState === 'icon' ? stateData['address_same'] : !viewPerAddress;
      }
    } else if ('disabledStatus') {
      if (props.type == 'view') {
        return true;
      } else {
        return coAppIndex < coBorrowerArray?.length || panButtonState === 'icon' ? true : false;
      }
    }
  };

  const handleDocumentUploadCheckbox = (fileType, fileFormat) => {
    if (fileType === 'panFileType') {
      if (props.type == 'view') {
        return stateData?.[fileType]?.[fileFormat] ? stateData?.[fileType]?.[fileFormat] : false;
      } else {
        if (loanSectionObject[`co_borrower-${coAppIndex}`]?.section_status == LeadNewStatus.InProgress && leadStatus == LeadNewStatus.Pending) {
          return selectedPANFileType[fileFormat];
        } else {
          return coAppIndex < coBorrowerArray?.length ? stateData?.[fileType]?.[fileFormat] : selectedPANFileType[fileFormat];
        }
      }
    } else if (fileType === 'aadhaarFileType') {
      if (props.type == 'view') {
        return stateData?.[fileType]?.[fileFormat] ? stateData?.[fileType]?.[fileFormat] : false;
      } else {
        if (loanSectionObject[`co_borrower-${coAppIndex}`]?.section_status == LeadNewStatus.InProgress && leadStatus == LeadNewStatus.Pending) {
          return selectedAadharFileType[fileFormat];
        } else {
          return coAppIndex < coBorrowerArray?.length ? stateData?.[fileType]?.[fileFormat] : selectedAadharFileType[fileFormat];
        }
      }
    }
  };

  const handleDocumentUploadDisabledCheckbox = () => {
    if (props.type == 'view') {
      return true;
    } else {
      return (coAppIndex < coBorrowerArray?.length && leadStatus == LeadNewStatus.Draft) || (panButtonState != 'icon' && leadStatus == LeadNewStatus.Draft) ? true : leadStatus == LeadNewStatus.Pending && loanSectionObject[`co_borrower-${coAppIndex}`]?.section_status == LeadNewStatus.InProgress ? false : leadStatus == LeadNewStatus.Pending && loanSectionObject[`co_borrower-${coAppIndex}`]?.section_status ? true : false;
    }
  };

  return (
    <div className="coBorrower-container-style">
      {props.type == 'edit' && props.leadComment[`co_borrower-${code - 299}`] && (props.leadComment[`co_borrower-${code - 299}`].remark_reasons || props.leadComment[`co_borrower-${code - 299}`].remarks) && <CommentViewer head="Credit Manager Comment" reasons={props.leadComment[`co_borrower-${code - 299}`].remark_reasons} additionalComment={props.leadComment[`co_borrower-${code - 299}`].remarks} />}
      <div className="coBorrower-details-heading-container-style">
        <div className="coBorrower-details-heading-style">{item.name} Details</div>
        {leadStatus != 'new' && leadStatus != 'offer_generated' && leadStatus != 'approved' && leadStatus != 'pending' && props.type != 'view' ? (
          <>
            {props.type !== 'view' ? (
              panVerify ? null : (
                <Button
                  id="skip"
                  label="Skip"
                  buttonType="link-button"
                  onClick={handleSkipButton}
                  customStyle={{
                    height: '32px',
                    width: '64px',
                    fontSize: '14px',
                    color: '#134CDE',
                  }}
                />
              )
            ) : null}
          </>
        ) : null}
      </div>
      <div>{renderFields('Co-Borrower Details')}</div>
      <div style={customSubHeaderStyle}>Current Address</div>
      <div>{renderFields('Co-Borrower Current Address')}</div>
      <div style={{ display: 'flex' }}>
        <div style={customSubHeaderStyle}>Permanent Address</div>
        <input style={{ marginLeft: '16px', marginTop: '2.5%', width: '1rem', height: '1rem' }} type="checkbox" id={`coborrower_${itemIndex + 1}_PermanentAddress`} onClick={handlePermanentAddress} checked={handlePermanentCheckbox('checkedStatus')} disabled={handlePermanentCheckbox('disabledStatus')} />
        <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '0.9vw', marginTop: '2.3%', marginLeft: '0.5%', color: '#767888' }}> Same as current Address</div>
      </div>
      <div>{renderFields('Co-Borrower Permanent Address')}</div>
      <div style={CustomHeaderStyle}>Co-Borrower KYC</div>
      <div>{renderFields('Co-Borrower KYC')}</div>
      <div style={customSubHeaderStyle}>KYC Documents</div>
      <div className="ar-coBorrower-section-selfie-file-upload">{renderFields('Co-Borrower Selfie')}</div>
      <div style={{ display: 'flex', flexDirection: 'row', columnGap: '5%' }}>
        <div style={CustomHeader}>Select PAN document type</div>
        <label style={{ ...radioCss, color: coAppStatus && coAppIndex && coAppStatus[coAppIndex - 1] ? '#C0C1C8' : panButtonState === 'icon' ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8' }}>
          <input
            type="checkbox"
            id={`coborrower_pan_pdf_image_${item.id}`}
            value="Image & PDF"
            checked={handleDocumentUploadCheckbox('panFileType', 'Image & PDF')}
            onChange={(e) =>
              handleRadioButton({
                event: e,
                updateSelectedFileType: setSelectedPANFileType,
                selectedFileType: selectedPANFileType,
                docCode: [documentCode.cb_pan_XML],
                docNames: ['Pan_XML'],
              })
            }
            style={radioInputCss}
            disabled={handleDocumentUploadDisabledCheckbox()}
          />
          Image & PDF
        </label>
        <label style={{ ...radioCss, color: coAppStatus && coAppIndex && coAppStatus[coAppIndex - 1] ? '#C0C1C8' : panButtonState === 'icon' ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8' }}>
          <input
            type="checkbox"
            id={`coborrower_pan_xml_image_${item.id}`}
            value="Response XML/JSON"
            checked={handleDocumentUploadCheckbox('panFileType', 'Response XML/JSON')}
            onChange={(e) =>
              handleRadioButton({
                event: e,
                updateSelectedFileType: setSelectedPANFileType,
                selectedFileType: selectedPANFileType,
                docCode: [documentCode.cb_pan],
                docNames: ['Pan_1'],
              })
            }
            style={radioInputCss}
            disabled={handleDocumentUploadDisabledCheckbox()}
          />
          Response XML/JSON
        </label>
      </div>
      <div className="ar-coBorrower-section-pan-file-upload">{renderFields('Co-Borrower KYC Document PAN')}</div>
      <div style={{ display: 'flex', flexDirection: 'row', columnGap: '5%' }}>
        <div style={CustomHeader}>Select Aadhaar document type</div>
        <label style={{ ...radioCss, color: coAppStatus && coAppIndex && coAppStatus[coAppIndex - 1] ? '#C0C1C8' : panButtonState === 'icon' ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8' }}>
          <input
            type="checkbox"
            value="Image & PDF"
            id={`coborrower_aadhaar_pdf_image_${item.id}`}
            checked={handleDocumentUploadCheckbox('aadhaarFileType', 'Image & PDF')}
            onChange={(e) =>
              handleRadioButton({
                event: e,
                updateSelectedFileType: setAadharFileType,
                selectedFileType: selectedAadharFileType,
                docCode: [documentCode.cb_aadhaar_XML],
                docNames: ['Aadhar_XML'],
              })
            }
            style={radioInputCss}
            disabled={handleDocumentUploadDisabledCheckbox()}
          />
          Image & PDF
        </label>
        <label style={{ ...radioCss, color: coAppStatus && coAppIndex && coAppStatus[coAppIndex - 1] ? '#C0C1C8' : panButtonState === 'icon' || (coAppStatus && coAppIndex && coAppStatus[coAppIndex - 1]) ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8' }}>
          <input
            type="checkbox"
            value="Response XML/JSON"
            id={`coborrower_aadhaar_xml_image_${item.id}`}
            checked={handleDocumentUploadCheckbox('aadhaarFileType', 'Response XML/JSON')}
            onChange={(e) =>
              handleRadioButton({
                event: e,
                updateSelectedFileType: setAadharFileType,
                selectedFileType: selectedAadharFileType,
                docCode: [documentCode.cb_aadhaar_front, documentCode.cb_aadhaar_back],
                docNames: ['Aadhar_Front_1', 'Aadhar_Back_1'],
              })
            }
            style={radioInputCss}
            disabled={handleDocumentUploadDisabledCheckbox()}
          />
          Response XML/JSON
        </label>
      </div>
      <div className="ar-coBorrower-section-aadhar-file-upload">{renderFields('Co-Borrower KYC Document Aadhaar')}</div>
      {navIconPrefixState['Co-Borrower Details'] == 'success' || props.type == 'view' ? null : !coAppStatus[coAppIndex - 1] ? (
        <>
          {item != 'Co-Borrower 15' && itemIndex === coBorrowerArray.length - 1 && leadStatus != LeadNewStatus.Pending ? (
            <div style={{ display: 'flex', marginTop: '2%', marginBottom: '2%', justifyContent: leadStatus != 'pending' ? 'space-between' : 'flex-end' }} className="book-loan-button-container book-loan-button-alignment-triple-button">
              {leadStatus != LeadNewStatus.Pending ? <Button id="verifyCoApp" label="Add Co-Borrower" buttonType="secondary" isLoading={addCoBorrowerLoader === 'inProgress' ? true : false} isDisabled={!isCoBorrowerEnable} onClick={handleNextCoBorrowerAdd} customStyle={customAddCoBorrowerButtonStyle} /> : null}
              <div className="book-loan-button-alignment-double-button">
                {leadStatus != LeadNewStatus.Pending ? getSubmitButton('Verify & Next', handleAddData, sectionStatusCheck === 'inProgress' ? true : false, !(statusObject?.section_status === LeadNewStatus.InProgress && leadStatus === LeadNewStatus.Pending ? false : !validForm || !documentStateData.Borrower_Selfie_1 || !(documentStateData.Aadhar_Front_1 || documentStateData.Aadhar_XML) || !(documentStateData.Pan_1 || documentStateData.Pan_XML) || addCoBorrowerLoader == 'inProgress'), {}) : null}
                {leadStatus != LeadNewStatus.Pending ? getSaveAsDraftButton('Save as Draft', saveCoBorrowerDraft, disableDraftButton) : null}
              </div>
            </div>
          ) : (
            loanSectionObject[`co_borrower-${coAppIndex}`]?.section_status == LeadNewStatus.InProgress &&
            leadStatus == LeadNewStatus.Pending && (
              <div className="book-loan-button-alignment-double-button">
                {getSubmitButton('Save & Next', handleSaveAndNextData, sectionStatusCheck === 'inProgress' ? true : false, !(statusObject?.section_status != LeadNewStatus.InProgress && leadStatus != LeadNewStatus.Pending ? false : !validForm || !documentStateData.Borrower_Selfie_1 || !(documentStateData.Aadhar_Front_1 || documentStateData.Aadhar_XML) || !(documentStateData.Pan_1 || documentStateData.Pan_XML)), {})}
                {leadStatus != LeadNewStatus.Pending ? getSaveAsDraftButton('Save as Draft', saveCoBorrowerDraft, disableDraftButton) : null}
              </div>
            )
          )}
        </>
      ) : null}
      {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}
