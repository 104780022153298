import { updatePreLoaderWatcher } from '../actions/user';
import { takeLatest, call, put } from 'redux-saga/effects';
import { getRestructureLoanDetails, postRestructureLoanDetails, getRestructureLoanTableDetails, postLoanSimulator } from '../apis/restructureLoan';

export function* restructureLoanEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getRestructureLoanDetails, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getRestructureLoanWatcherSaga() {
  yield takeLatest('GET_RESTRUCTURE_LOAN', restructureLoanEffectSaga);
}

export function* restructureLoanPostEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(postRestructureLoanDetails, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* postRestructureLoanWatcherSaga() {
  yield takeLatest('POST_RESTRUCTURE_LOAN', restructureLoanPostEffectSaga);
}

export function* restructureLoanTableSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getRestructureLoanTableDetails, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getRestructureTableWatcherSaga() {
  yield takeLatest('GET_RESTRUCTURE_LOAN_TABLE', restructureLoanTableSaga);
}

export function* restructureLoanSimulatorSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(postLoanSimulator, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* postLoanSimulatorWatcherSaga() {
  yield takeLatest('POST_LOAN_SIMULATOR', restructureLoanSimulatorSaga);
}
