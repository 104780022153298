import React from 'react';
import ArrowUp from './Arrowup.svg';
import ArrowDown from './Arrowdown.svg';

const DashboardCard = ({ title, value, change, changeColor, rupeesIcon = false , bottomText = false }) => (
  <div style={{ border: '2px solid #E5E5E8', padding: '15px', borderRadius: '8px' }}>
    <div style={{ color: '#4B4D57', fontSize: '16px', fontFamily: 'Montserrat-Bold' }}>{title}</div>
    <div style={{ color: '#161719', fontSize: '24px', fontFamily: 'Montserrat-Bold', marginTop: '25px', wordWrap: 'break-word' }}>
      {rupeesIcon ? (value ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value) : 'NA') : value ? value : 'NA'}

      {/* {rupeesIcon ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value) : value} */}
      {bottomText &&
      <span style={{ marginLeft: '15px' }}>
        <img src={change >= 0 ? ArrowUp : ArrowDown} alt={change > 0 ? 'ArrowUp' : 'ArrowDown'} />
        <span style={{ color: changeColor, fontSize: '12px', fontFamily: 'Montserrat-Bold', marginLeft: '10px' }}>{change && Math.abs(change)}%</span>
      </span>}
    </div>
    {bottomText ? 
    <div style={{ color: '#767888', fontSize: '14px', fontFamily: 'Montserrat-Medium', marginTop: '10px' }}>{title} - {rupeesIcon ? (value ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value) : 'NA') : value ? value : 'NA'}</div> :
    <div style={{ color: '#767888', fontSize: '14px', fontFamily: 'Montserrat-Medium', marginTop: '10px' }}>in crores</div> }
  </div>
);

export default DashboardCard;
