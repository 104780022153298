import { updatePreLoaderWatcher } from 'actions/user';
import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteBusinessModelApi } from 'serviceFeeRecon/apis/reconPartner.api';
import { getPartnerProductApi } from 'serviceFeeRecon/apis/reconPartner.api';

export function* getPartnerProductEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getPartnerProductApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getPartnerProductWatcherSaga() {
  yield takeLatest('GET_PARTNER_PRODUCT_WATCHER', getPartnerProductEffectSaga);
}

export function* deleteBusinessModelEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(deleteBusinessModelApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* deleteBusinessModelWatcherSaga() {
  yield takeLatest('DELETE_BUSINESS_MODEL', deleteBusinessModelEffectSaga);
}
