import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchLocBulkUploadDataApi, locDrawdownBulkUploadApi, locDownloadBulkUploadApi, downloadLocBulkSampleFileApi } from '../apis/locDrawdownBulkUpload';
import { updatePreLoaderWatcher } from '../actions/user';

export function* locDrawdownBulkUploadEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(locDrawdownBulkUploadApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* locDrawdownBulkUploadWatcherSaga(action) {
  yield takeLatest('LOC_DRAWDOWN_BULK_UPLOAD', locDrawdownBulkUploadEffectSaga);
}

export function* locDownloadBulkUploadEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(locDownloadBulkUploadApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* locDownloadBulkUploadWatcherSaga(action) {
  yield takeLatest('LOC_DOWNLOAD_BULK_UPLOAD', locDownloadBulkUploadEffectSaga);
}

export function* fetchLocBulkUploadDataEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(fetchLocBulkUploadDataApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* fetchLocBulkUploadDataWatcherSaga(action) {
  yield takeLatest('FETCH_LOC_DRAWDOWN_BULK_UPLOAD', fetchLocBulkUploadDataEffectSaga);
}

export function* downloadLocBulkSampleFileEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(downloadLocBulkSampleFileApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* downloadLocBulkSampleFileWatcherSaga(action) {
  yield takeLatest('DOWNLOAD_LOC_BULK_SAMPLE_FILE', downloadLocBulkSampleFileEffectSaga);
}
