import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../colendingLoans/view.css';
import BasicDatePicker from 'components/DatePicker/basicDatePicker';
import { verifyDateAfter1800 } from '../../util/helper';
import moment from 'moment';
import Button from 'react-sdk/dist/components/Button';
import Table from 'react-sdk/dist/components/Table';
import { storedList } from '../../util/localstorage';
import CustomDatePicker from '../../components/DatePicker/datePickerCustom';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import 'react-sdk/dist/styles/_fonts.scss';
import { AlertBox } from '../../components/CustomAlertbox';
import Preloader from '../../components/custom/preLoader';
import downloadIcon from './images/downloadcon.svg';
import { getNachReportDataWatcher, downloadNachReportFileWatcher } from '../../actions/nachReports';

const user = storedList('user');

const NachReport = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.profile.loading);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [customDate, setCustomDate] = useState(true);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [count, setCount] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  useEffect(() => {
    getReportData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    getReportData(0, 10);
  }, []);

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSearch = () => {
    getReportData(page, rowsPerPage);
  };

  const getReportData = (page, rowsPerPage) => {
    const payload = {
      user_id: user._id,
      fromDate: fromDate,
      toDate: toDate,
      page: page,
      limit: rowsPerPage,
      reportType: 'enach_migration_report',
    };
    new Promise((resolve, reject) => {
      dispatch(getNachReportDataWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setCount(response?.count);
        setData(response?.data);
      })
      .catch((error) => {
        showAlert(error.response.data?.message, 'error');
      });
  };

  const handleFileDownload = (id) => {
    let data = {
      id: id,
      user_id: user._id,
    };
    new Promise((resolve, reject) => {
      dispatch(downloadNachReportFileWatcher(data, resolve, reject));
    })
      .then((response) => {
        window.open(response, '_blank');
      })
      .catch((error) => {
        showAlert(error.response.data.message, 'error');
      });
  };

  const columns = [
    { id: 'file_name', label: 'FILE NAME' },
    { id: 'requested_by_name', label: 'GENERATED BY' },
    {
      id: 'GENERATION DATE',
      label: 'GENERATION DATE',
      format: (row) => moment(row.generated_date).format('DD-MM-YYYY'),
    },
    {
      id: 'DOWNLOAD',
      label: <span>{'DOWNLOAD'}</span>,
      format: (row) => (
        <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', marginLeft: '15%' }}>
          <img
            src={downloadIcon}
            alt="svg"
            onClick={() => {
              handleFileDownload(row._id);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ margin: '0px 24px 24px 24px' }}>
        {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '16px',
            marginBottom: '20px',
          }}
        >
          <div style={{ display: 'flex', gap: '16px' }}>
            <CustomDatePicker
              placeholder="Duration"
              width="200px"
              onDateChange={(date) => {
                if (date.state == 'custom') {
                  setCustomDate(false);
                  setFromDate('');
                  setToDate('');
                } else {
                  setCustomDate(true);
                  setFromDate(date.fromDate);
                  setToDate(date.toDate);
                }
              }}
            />
            {!customDate ? (
              <BasicDatePicker
                placeholder="From date"
                value={fromDate || null}
                onDateChange={(date) => {
                  setFromDate(verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date);
                }}
                style={{ width: '200px', borderRadius: '8px' }}
              />
            ) : null}
            {!customDate ? (
              <BasicDatePicker
                placeholder={'To date'}
                value={toDate || null}
                onDateChange={(date) => {
                  setToDate(verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date);
                  if (date === null) {
                  }
                }}
                style={{ width: '200px', borderRadius: '8px' }}
              />
            ) : null}
            <Button
              buttonType="primary"
              label="Search"
              customStyle={{
                width: '145px',
                height: '56px',
                padding: '13px 44px',
                borderRadius: '8px',
                fontSize: '16px',
              }}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div>
          <Table
            customStyle={{
              display: 'grid',
              gridTemplateColumns: '30% 30% 20% 20%',
              fontFamily: 'Montserrat-Medium',
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
              whiteSpace: 'nowrap',
              overflowX: 'hidden',
              overflowY: 'hidden',
            }}
            columns={columns}
            data={data}
          />
          <Pagination itemsPerPage={rowsPerPage} totalItems={count} rowsPerPageOptions={[10, 20, 30]} onPageChange={handleChangePage} showOptions={true} setRowLimit={setRowsPerPage} />
        </div>
        {isLoading && <Preloader />}
      </div>
    </>
  );
};

export default NachReport;
