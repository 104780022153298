import React from "react";

const RejectionScreen = () => {
  const commonStyle = {
    fontFamily: 'Montserrat-semibold',
    textAlign: 'center',
    textStyle: 'bold',
    margin: 'auto',
  }
  const buttonStyle = {
    ...commonStyle,
    margin: "0 auto",
    border: "1px solid #134CDE",
    color: "#134CDE",
    borderRadius: '2rem',
    backgroundColor: "white",
    cursor: "pointer",
    textStyle: "bold"
  }
  const textStyle = {
    ...commonStyle,
    color: '#161719',
    width: '55%',
    fontSize: '0.9rem',
    fontWeight: '500',
  }
  const headingStyle = {
    ...commonStyle,
    color: '#CC0000',
    fontWeight: '600',
    fontSize: '1.1rem'
  }
  const circle = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: '#F9DADA',
    margin: 'auto'
  }

  return (

    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: '100%', height: '50vh' }} >
      <div style={{ display: "flex", flexDirection: 'column', gap: '.7rem' }}>
        <div style={circle}></div>
        <h3 style={headingStyle}>
          The application you submitted has been rejected
        </h3>
        <p style={textStyle}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys
        </p>
        <button style={buttonStyle}>Try Again</button>
      </div>
    </div>
  );
}
// Export RejectionScreen component
export default RejectionScreen;
