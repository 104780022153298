import { getP2pCompaniesWatcher, getP2pLoansWatcher } from 'actions/p2pLoans';
import { p2pLoans, p2pCompanies, p2pPennyDropReprocess, p2pPennyDropReject } from '../apis/p2pLoans';
import { takeLatest, call, put } from 'redux-saga/effects';
import { updatePreLoaderWatcher } from 'actions/user';

export function* getP2pLoansEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(p2pLoans, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getP2pLoansWatcherSaga() {
  yield takeLatest('P2P_LOANS', getP2pLoansEffectSaga);
}

export function* getP2pCompaniesEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(p2pCompanies);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getP2pCompaniesWatcherSaga() {
  yield takeLatest('P2P_COMPANIES', getP2pCompaniesEffectSaga);
}

export function* getP2pPennyDropReprocessEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(p2pPennyDropReprocess, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getP2pPennyDropReprocessWatcherSaga() {
  yield takeLatest('P2P_PENNY_DROP_REPROCESS', getP2pPennyDropReprocessEffectSaga);
}

export function* getP2pPennyDropRejectEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(p2pPennyDropReject, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getP2pPennyDropRejectWatcherSaga() {
  yield takeLatest('P2P_PENNY_DROP_REJECT', getP2pPennyDropRejectEffectSaga);
}
