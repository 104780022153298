export const activityLogNamingFormat = {
  primary: 'Primary_Applicant',
  entity: 'Entity_Details',
  co_borrower: 'Co_Applicant_Details',
  guarantor: 'Guarantor Details',
  financial_doc: 'financial_doc',
  additional_doc: 'additional_doc',
  offer_in_progress: 'offer_in_progress',
  lead_accepted: 'lead_accepted',
  lead_request_update: 'lead_request_update',
  new: 'new',
  pending: 'pending',
  rejected: 'rejected',
  in_review: 'in_review',
  in_progress: 'in_progress',
  lead_deviation: 'lead_deviation',
  offer_generated: 'offer_generated',
  offer_deviation: 'offer_deviation',
  approved: 'approved',
  cams_update: 'cams_update',
  lead_rejected: 'lead_rejected',
  follow_up_doc: 'follow_up_doc',
  follow_up_kyc: 'follow_up_kyc',
  amend_offer_update: 'amend_offer_update',
};
