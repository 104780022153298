// Create functional component DropDownWithBtn
// Import React
import React from 'react';
// Import useState and useEffect hooks from react
import { useState, useEffect, useRef } from 'react';
import './DropdownWithBtn.scss';
import upArrow from './assets/images/upArrow.svg';
import downArrow from './assets/images/downArrow.svg';
export default function DropDownWithBtn({ id, label = 'Name', onClick, customClass, customDropdownClass, initialValue, isDisabled = false, addBtnDisabled = false, isDrawdown = false, options = [], error, helperText, placeholder, regex, onDrawdownSelect, isBoxType, filterOption = true }) {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [arrowType, setArrowType] = useState(true);
  const [dropdownHeight, setDropdownHeight] = useState('54.9px');
  const [inputValue, setInputValue] = useState(initialValue || '');
  const [selectedOption, setSelectedOption] = useState(initialValue || '');
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const [selectedOptionElement, setSelectedOptionElement] = useState(null);
  const filteredOptions = filterOption ? [...options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase())), ...options.filter((option) => !option.name.toLowerCase().includes(inputValue.toLowerCase()))] : [...options];
  const newRef = useRef(null);
  const handleArrowKeyNavigation = (e) => {
    if (arrowType) {
      return;
    }
    if (filteredOptions.length === 0) return;
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        moveSelection(-1);
        break;
      case 'ArrowDown':
        e.preventDefault();
        moveSelection(1);
        break;
      case 'Enter':
        e.preventDefault();
        e.stopPropagation();
        if (selectedOptionIndex >= 0) {
          handleDropdownSelection(filteredOptions[selectedOptionIndex].label, filteredOptions[selectedOptionIndex]);
        }
        break;
      default:
        break;
    }
  };
  const moveSelection = (step) => {
    let newIndex = selectedOptionIndex + step;
    newIndex = Math.max(0, Math.min(newIndex, filteredOptions.length - 1));
    if (newIndex >= 0 && newIndex < filteredOptions.length) {
      // Calculate the height of each option div
      const optionHeight = 52.77; // Adjust as needed
      const container = newRef.current;

      // Calculate the offset to scroll the selected option into view
      const offset = newIndex * optionHeight;

      // Scroll the container to the calculated offset
      container.scrollTop = offset;
      setSelectedOptionIndex(newIndex);
    }
  };
  useEffect(() => {
    // Add event listener for arrow key navigation when the component mounts
    if (isDrawdown === true) {
      document.addEventListener('keydown', handleArrowKeyNavigation);
      console.log(filteredOptions.length * 53.77 < 200);
      let height = `${filteredOptions.length * 55 < 250 ? filteredOptions.length * 55 : 250}px`; // Adjust the 52.77 as needed
      if (height === '0px') height = '54.9px';
      setDropdownHeight(height);
      return () => {
        // Remove the event listener when the component unmounts
        document.removeEventListener('keydown', handleArrowKeyNavigation);
      };
    } else {
      document.addEventListener('keydown', (e) => {
        const arrowKeys = ['ArrowUp', 'ArrowDown'];
        if (arrowKeys.includes(e.key)) {
          e.preventDefault();
        }
      });
    }
  }, [filteredOptions, selectedOption]);
  useEffect(() => {
    if (initialValue && String(initialValue).length > 0) {
      setInputValue(initialValue);
      setSelectedOption(initialValue);
      setHasValue(true);
    } else {
      setInputValue('');
    }
  }, [initialValue]);
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });
  const handleInputChange = (event) => {
    event.data = event.data ? event.data : event.target;
    if (isDrawdown) {
      if (arrowType === true) setArrowType(false);
      if (event.target.value.length > 0) {
        setHasValue(true);
      } else {
        setSelectedOption('');
        setHasValue(false);
        setIsFocused(true);
      }
    }
  };
  const handleInputFocus = () => {
    setIsFocused(true);
    setHasValue(false);
  };
  const toggleDrawdownVisibility = () => {
    if (!isDisabled) {
      setIsFocused(!isFocused);
      setArrowType(!arrowType);
    }
  };
  const handleDropdownSelection = (data) => {
    onClick({
      name: data.name,
      value: data.value,
    });
    toggleDrawdownVisibility();
  };
  const handleOutsideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setIsFocused(!isFocused);
      setArrowType(!arrowType);
    } else {
      // setIsVisible(false);
    }
  };
  return (
    <div className={error ? 'input-container-error' : `input-container ${customClass} ${isDisabled ? 'disabled' : ''} ${isFocused ? 'focused' : ''} ${isBoxType == 'icon' ? 'borderfocused' : ''} ${hasValue ? 'hasValue' : ''}`} style={{ ...customClass, backgroundColor: isDisabled ? 'rgb(244, 244, 244)' : '' }}>
      <>
        <input
          type="text"
          className={`${isBoxType === 'icon' ? 'custom-inputdropdown' : 'custom-input'} ${isFocused ? 'focused' : ''}`}
          placeholder={placeholder && placeholder.length > 0 ? placeholder : label}
          id={id}
          autoComplete="off"
          disabled={isDisabled}
          value={inputValue}
          onChange={(e) => {
            handleInputChange(e);
          }}
          onFocus={(e) => {
            handleInputFocus(e);
            toggleDrawdownVisibility(e);
          }}
        />

        {error ? <div className={`error-message ${isFocused ? 'focused' : ''}`}>{helperText}</div> : null}

        {!arrowType && (
          <div className="dropdown-options" ref={newRef} key={`${label}-dropdown-options`} style={{ minHeight: dropdownHeight, ...customDropdownClass }}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div className={`dropdown-options-div ${selectedOptionIndex === index ? 'selected' : ''}`} key={`${label}${index}`} id={`option-${index}`} ref={selectedOptionIndex === index ? selectedOptionElement : null}>
                  <span style={{ marginLeft: '11px' }}>{option.name}</span>
                  <button
                    style={{
                      color: addBtnDisabled ? '#b3b3b3' : '#134CDE',
                      cursor: addBtnDisabled ? 'not-allowed' : 'pointer',
                      backgroundColor: 'transparent',
                      border: 'none',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      padding: '0 1rem',
                      margin: '0',
                    }}
                    disabled={addBtnDisabled}
                    onClick={() => handleDropdownSelection(option)}
                  >
                    Add
                  </button>
                  {/* {selectedOption === option.label && <img src={check} alt="check" />} */}
                </div>
              ))
            ) : (
              <div className="dropdown-options-div">
                <span style={{ marginLeft: '11px' }}>No Record</span>
              </div>
            )}
          </div>
        )}

        <span className="password-toggle" onClick={toggleDrawdownVisibility}>
          <img src={arrowType ? downArrow : upArrow} alt="arrow" />
        </span>
      </>
    </div>
  );
}
