export const LoanPatchFields = () => [
  {
    title: 'Sanction amount',
    name: 'sanction_amount',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Loan int rate',
    name: 'loan_int_rate',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Tenure',
    name: 'tenure',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'EMI count',
    name: 'emi_count',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'First inst date',
    name: 'first_inst_date',
    presentInLoanApi: 'No',
    type: 'Date',
  },
  {
    title: 'Final approval date',
    name: 'final_approve_date',
    presentInLoanApi: 'No',
    type: 'Date',
  },
  {
    title: 'Processing fees perc',
    name: 'processing_fees_perc',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Processing fee amount',
    name: 'processing_fees_amt',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Gst on pf amount',
    name: 'gst_on_pf_amt',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Application fees',
    name: 'application_fees',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Fees',
    name: 'fees',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Usage fee',
    name: 'usage_fee',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Conv fees',
    name: 'conv_fees',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Subvention fee per',
    name: 'subvention_fee_per',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Subvention fees',
    name: 'subvention_fees',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Broken period int amt',
    name: 'broken_period_int_amt',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Upfront interest',
    name: 'upfront_interest',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Insurance amount',
    name: 'insurance_amount',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'EMI amount',
    name: 'emi_amount',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Total charges',
    name: 'total_charges',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Invoice amount',
    name: 'invoice_amount',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Loan interest amount',
    name: 'loan_int_amt',
    presentInLoanApi: 'No',
    type: 'Float',
  },
  {
    title: 'Net disbur amt',
    name: 'net_disbur_amt',
    presentInLoanApi: 'No',
    type: 'Float',
  },
];
