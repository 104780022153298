import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { styled } from "@material-ui/core/styles";
import Switch from '@mui/material/Switch';
import { Button } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { AlertBox } from '../../components/AlertBox';
import { storedList } from '../../util/localstorage';
import { getAllScheme, updateScheme, postSchemeDetails } from '../../apis/interestRateScheme';
import { Autocomplete, Card } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import deleteIcon from '../../assets/img/delete.png';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory } from 'react-router-dom';

import CTable from 'react-sdk/dist/components/Table';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';

const InterestRateScheme = () => {
  const [scheme, setScheme] = useState('');
  const [schemeName, setSchemeName] = useState('');
  const [isNotValidSchemeName, setNotValidSchemeName] = useState(false);
  const [schemeNameError, setSchemeNameError] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [isNotValidInterestRate, setNotValidInterestRate] = useState(false);
  const [interestRateError, setInterestRateError] = useState('');
  const [intRateType, setIntRateType] = useState('');
  const [isNotValidIntType, setNotValidIntType] = useState(false);
  const [intRateTypeError, setIntRateTypeError] = useState('');
  const [penalRate, setPenalRate] = useState('');
  const [isNotValidPenalRate, setNotValidPenalRate] = useState(false);
  const [penalRateError, setPenalRateError] = useState('');
  const [bounceCharge, setBounceCharge] = useState('');
  const [isNotValidBounceChare, setNotValidBounceCharge] = useState(false);
  const [bounceChargeError, setBounceChargeError] = useState('');
  const [repaymentDays, setRepaymentDays] = useState('');
  const [isNotValidRepaymentDays, setIsNotValidRepaymentDays] = useState(false);
  const [repaymentDaysError, setRepaymentDaysError] = useState('');
  const [schemeDetails, setSchemeDetails] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const user = storedList('user');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [isPopUp, setPopUp] = useState(false);
  const [isUpdateStatusPop, setUpdateStatusPop] = useState(false);
  const [mappedProducts, setMappedProducts] = useState(0);
  const [updatableScheme, setUpdatableScheme] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [viewData, setViewData] = useState({ viewSchemeName: '', viewInterestRate: '', viewInterestType: '', viewBounceCharge: '', viewOverdueInterest: '', viewRepaymentDays: '' });
  const [isSetUp, setIsSetUp] = useState('No');
  const [repaymentDaysIsSetUp, setRepaymentDaysIsSetUp] = useState('');
  const [rowIsOpen, setRowIsOpen] = useState(false);
  const [singleRowData, setSingleRowData] = useState([]);
  const [slabs, setSlubs] = useState([
    { start_day: '1', end_day: '', interest_rate: '' },
    { start_day: '', end_day: '', interest_rate: '' }, // Initial slub object
  ]);

  const history = useHistory();

  const handleSlubChange = (index, field, value) => {
    const updatedSlabs = [...slabs];
    updatedSlabs[index][field] = value;

    if (field == 'end_day') {
      if (index == slabs.length - 2 && updatedSlabs[index + 1]) {
        updatedSlabs[index + 1]['start_day'] = parseInt(value) + 1;
      } else {
        for (let index = 0; index < slabs.length - 1; index++) {
          slabs[index + 1]['start_day'] = parseInt(slabs[index]['end_day']) + 1;
        }
      }
    }
    // Directly extract the last item row end day
    setSlubs(updatedSlabs);
    const lastItem = updatedSlabs[updatedSlabs.length - 1];
    setRepaymentDaysIsSetUp(lastItem?.end_day);
  };
  const handleAddSlubs = () => {
    let startDay = '';
    if (slabs[slabs.length - 1].end_day) startDay = parseInt(slabs[slabs.length - 1]['end_day']) + 1;
    const newSlub = [{ start_day: startDay, end_day: '', interest_rate: '' }];

    setSlubs((prevSlubs) => [...prevSlubs, ...newSlub]);
  };

  // const [sortBy, setSortBy] = useState('_id');
  // const [isAscSort, setIsAscSort] = useState(true);

  const [sortBy, setSortBy] = useState('_id');
  const [isAscSort, setIsAscSort] = useState(true);

  const handleClickOpen = () => {
    setPopUp(true);
  };

  const handleToggle = (event) => {
    setIsSetUp(event.target.value);
    setSchemeName('');
    setIntRateType('');
    setBounceCharge('');
    setSlubs([
      { start_day: '1', end_day: '', interest_rate: '' },
      { start_day: '', end_day: '', interest_rate: '' },
    ]);
    setIsChecked(!isChecked);
  };

  const handlePopupClose = () => {
    handleDiscard();
    setPopUp(false);
  };

  const handleChangePage = (event) => {
    setPage(parseInt(event));
  };
  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };
  const showAlert = (alertMessage, severity) => {
    setAlert(true);
    setSeverity(severity);
    setAlertMessage(alertMessage);
    setTimeout(() => {
      handleAlertClose();
    }, 4000);
  };
  const handleSchemeNameInput = (event) => {
    setSchemeName(event.target.value);
    let tempName = event.target.value;
    setNotValidSchemeName(!/^[a-zA-Z0-9\s]*$/gm.exec(tempName));
    setSchemeNameError('Please provide alphanumeric value');
  };
  const handleInterestRateInput = (event) => {
    const tempIntRate = event.target.value;
    if (tempIntRate === '') {
      setInterestRate('');
      setPenalRate('');
      setNotValidInterestRate(false);
      setInterestRateError('');
    } else {
      const floatValue = parseFloat(tempIntRate);
      if (floatValue <= 100) {
        const isValidInput = /^\d+(\.\d{0,2})?$/.test(tempIntRate);
        if (isValidInput) {
          setInterestRate(tempIntRate);
          setPenalRate(tempIntRate);
          setNotValidInterestRate(false);
          setInterestRateError('Please enter valid value');
        }
      }
    }
  };
  const handlePenalRateInput = (event) => {
    const tempPenalRate = event.target.value;
    if (tempPenalRate === '') {
      setPenalRate('');
      setNotValidPenalRate(false);
      setPenalRateError('');
    } else {
      const floatValue = parseFloat(tempPenalRate);
      if (floatValue <= 100) {
        const isValidInput = /^\d+(\.\d{0,2})?$/.test(tempPenalRate);
        if (isValidInput) {
          setPenalRate(tempPenalRate);
          setNotValidPenalRate(false);
          setPenalRateError('Please enter valid value');
        }
      }
    }
  };
  const handleBounceChargeInput = (event) => {
    const tempBounceChare = event.target.value;
    if (/^\d{0,8}(\.\d{0,2})?$/.test(tempBounceChare)) {
      setBounceCharge(tempBounceChare);
      setNotValidBounceCharge(false);
      setBounceChargeError('Please enter valid value');
    }
  };
  const handleRepaymentDaysInput = (event) => {
    const tempRepaymentdays = event.target.value;
    if (/^\d{0,8}$/.test(tempRepaymentdays)) {
      setRepaymentDays(tempRepaymentdays);
      setIsNotValidRepaymentDays(false);
      setRepaymentDaysError('Please enter valid value');
    }
  };

  const handleDiscard = () => {
    setSchemeName('');
    setInterestRate('');
    setIntRateType('');
    setPenalRate('');
    setBounceCharge('');
    setRepaymentDays('');
    setNotValidSchemeName(false);
    setNotValidInterestRate(false);
    setNotValidIntType(false);
    setNotValidPenalRate(false);
    setNotValidBounceCharge(false);
    setIsNotValidRepaymentDays(false);
    setSchemeNameError('');
    setInterestRateError('');
    setIntRateTypeError('');
    setBounceChargeError('');
    setPenalRateError('');
    setRowIsOpen(false);
    setIsSetUp('No');
    setPopUp(false);
    setIsChecked(false);
    setViewData({ viewSchemeName: '', viewInterestRate: '', viewInterestType: '', viewBounceCharge: '', viewOverdueInterest: '', viewRepaymentDays: '' });
  };

  React.useEffect(() => {
    fetchSchemeDetails();
  }, [page, limit, sortBy, isAscSort]);

  const fetchSchemeDetails = (search) => {
    const req = {
      user_id: user._id,
      page,
      limit,
      sortBy,
      isAscSort,
      search: isNaN(search) ? search : parseInt(search),
    };
    getAllScheme(req)
      .then((response) => {
        const data = response.data?.data.rows.map((item) => {
          item.is_step_up = item.is_step_up ? 'Step-up' : 'Normal';
          if (item.is_step_up === 'Step-up' && item?.rate_slab?.length > 0) {
            item.interest_rate = `${Math.min(...item?.rate_slab.map((entry) => entry.interest_rate))}% - ${Math.max(...item?.rate_slab.map((entry) => entry.interest_rate))}`;
          }

          item.status = (
            <div>
              <Switch checked={item.status} value={item.status} onChange={() => handleStatusSwitchChange(item)}></Switch>
            </div>
          );
          return item;
        });
        setSchemeDetails(data);
        setCount(response.data?.data.count);
      })
      .catch((error) => showAlert(error?.response?.data?.message || error.message || error, 'error'));
  };

  const updateSchemeStatus = (schemeDetails) => {
    setUpdateStatusPop(false);
    const req = {
      user_id: user._id,
      scheme_id: schemeDetails._id,
      status: schemeDetails.status ? '0' : '1',
    };
    updateScheme(req)
      .then((response) => {
        showAlert(response.data.message, 'success');
        fetchSchemeDetails(scheme);
      })
      .catch((error) => showAlert(error.response.data.message, 'error'));
  };

  const handleSchemeAdd = () => {
    if (!schemeName) {
      setNotValidSchemeName(true);
      setSchemeNameError('Please provide scheme name');
      return;
    }
    if (!intRateType) {
      setNotValidIntType(true);
      setIntRateTypeError('Please select interest type');
      return;
    }
    if (!interestRate && isSetUp == 'No') {
      setNotValidInterestRate(true);
      setInterestRateError('Please provide interest rate');
      return;
    }
    if (!penalRate && isSetUp == 'No') {
      setNotValidPenalRate(true);
      setPenalRateError('Please provide penal rate');
      return;
    }
    if (!bounceCharge) {
      setNotValidBounceCharge(true);
      setBounceChargeError('Please provide bounce charge');
      return;
    }
    if (!repaymentDays && isSetUp == 'No') {
      setIsNotValidRepaymentDays(true);
      setRepaymentDaysError('Please provide repayment Days');
      return;
    }

    if (isSetUp == 'Yes' && slabs?.length < 2) {
      showAlert('please add atleat 2 slabs', 'error');
    } else {
      addSchemeDetails();
    }
  };

  const addSchemeDetails = () => {
    const req = {
      user_id: user._id,
      scheme_name: schemeName,
      interest_type: intRateType.label,
      interest_rate: interestRate,
      penal_rate: penalRate,
      bounce_charge: bounceCharge,
      repayment_days: repaymentDays,
      is_step_up: isSetUp == 'Yes' ? true : false,
    };
    const reqIsSetUp = {
      user_id: user._id,
      scheme_name: schemeName,
      interest_type: intRateType.label,
      bounce_charge: bounceCharge,
      repayment_days: repaymentDaysIsSetUp,
      is_step_up: isSetUp == 'Yes' ? true : false,
      rate_slab: slabs,
    };
    if (isSetUp == 'Yes') {
      postSchemeDetails(reqIsSetUp)
        .then((response) => {
          showAlert(response.data.message, 'success');
          handlePopupClose();
          fetchSchemeDetails('');
        })
        .catch((error) => {
          showAlert(error.response.data.message, 'error');
          fetchSchemeDetails('');
        });
    } else {
      postSchemeDetails(req)
        .then((response) => {
          showAlert(response.data.message, 'success');
          handlePopupClose();
          fetchSchemeDetails('');
        })
        .catch((error) => {
          showAlert(error.response.data.message, 'error');
          fetchSchemeDetails('');
        });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event));
  };

  const handleUpdateStatusPopUpClose = () => {
    setUpdateStatusPop(false);
  };

  const handleStatusSwitchChange = (schemeDetails) => {
    if (!schemeDetails.status) {
      updateSchemeStatus(schemeDetails);
      return;
    }
    if (schemeDetails.status && !schemeDetails.product_count) {
      updateSchemeStatus(schemeDetails);
      return;
    }
    setUpdateStatusPop(schemeDetails.product_count);
    setMappedProducts(schemeDetails.product_count);
    setUpdatableScheme(schemeDetails);
  };

  const handleRemoveSlab = (indexToRemove) => {
    // Create a copy of the slabs array
    const updatedSlabs = [...slabs];
    // Remove the item at the specified index
    updatedSlabs.splice(indexToRemove, 1);
    // Update the state with the modified array
    setSlubs(updatedSlabs);
  };

  const customStyles = {
    position: 'fixed',
    left: 'auto',
    right: '-269px',
    height: '98%',
    overflowX: 'hidden',
    overflowY: 'scroll',
  };
  const customStyles1 = {
    paddingTop: '5%',
  };
  const customHeaderStyle = {
    position: 'absolute',
    paddingRight: '5%',
    overflowY: 'scroll',
    backgroundColor: 'white',
    zIndex: '500',
    paddingTop: '10px',
  };
  const commanTextFieldstyle = {
    width: '100%',
    marginBottom: '0.5cm',
  };
  const commanTextFieldIsSetup = {
    fontSize: '4px',
    marginTop: '10px',
    marginRight: '10px',
    padding: '10px !important',
  };
  const addSlabStyle = {
    color: '#475bd8',
    float: 'right',
    marginBottom: '4px',
    fontWeight: '500',
    cursor: 'pointer',
    marginTop: '10px',
    display: rowIsOpen ? 'none' : null,
  };
  const buttonParentStyle = {
    position: 'fixed',
    bottom: '10px',
    width: '90%',
    backgroundColor: 'white',
    marginTop: '60%',
  };
  const deleteImgStyle = {
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  };
  const parentContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  };
  const repaymentFieldStyle = {
    width: '50%',
    marginBottom: '0.5cm',
  };
  const addButtonStyle = {
    backgroundColor: '#475bd8',
    color: 'white',
    float: 'right',
    width: '46%',
    marginRight: '3%',
  };
  const cancelButtonStyle = {
    backgroundColor: 'white',
    color: '#475bd8',
    float: 'center',
    width: '46%',
    border: '1px solid #475bd8',
  };
  const deleteParentStyle = {
    height: '25px',
    width: '25px',
  };
  const parentContainer = {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
  };

  {
    /* const columnsScheme = [
        { id: '_id', label: 'Scheme ID', isSorting: true  },
        { id: 'scheme_name', label: 'Scheme Name', isSorting: true  },
        { id: 'interest_type', label: 'Interest Type', isSorting: true  },
        { id: 'interest_rate', label: 'Interest Rate', isSorting: true  },
        { id: 'penal_rate', label: 'Overdue Interest Rate', isSorting: true  },
        { id: 'bounce_charge', label: 'Bounce Charge', isSorting: true  },
        { id: 'repayment_days', label: 'Repayment Days', isSorting: true  },
        { id: 'status', label: 'Status', isSorting: true  }

    ] */
  }

  {
    /* const handleSort = (column) => {
        let sortByColm = column.id;
        setSortBy(sortByColm);
        setIsAscSort((isAscSort)=>!isAscSort);
    
      } */
  }

  const openPartnerDetails = (id) => {
    const row = schemeDetails.find((row) => row._id === id);
    setSingleRowData(row);
    setPopUp(true);
    setRowIsOpen(true);
    setIsChecked(false);
    setViewData({ viewSchemeName: row?.scheme_name, viewInterestRate: row?.interest_rate, viewInterestType: row?.interest_type, viewBounceCharge: row?.bounce_charge, viewOverdueInterest: row?.penal_rate, viewRepaymentDays: row?.repayment_days });
    return row;
  };

  const columnsScheme = [
    { id: '_id', label: 'Scheme ID', isSorting: true },
    { id: 'scheme_name', label: 'Scheme Name', isSorting: true },
    { id: 'interest_type', label: 'Interest Type', isSorting: true },
    { id: 'is_step_up', label: 'Scheme Type', isSorting: true },
    { id: 'interest_rate', format: (row) => (row.interest_rate ? row.interest_rate + ' %' : '-'), label: 'Interest Rate', isSorting: true },
    { id: 'penal_rate', format: (row) => (row.penal_rate ? row.penal_rate + ' %' : '-'), label: 'Overdue Interest Rate', isSorting: true },
    { id: 'bounce_charge', label: 'Bounce Charge', isSorting: true },
    { id: 'repayment_days', label: 'Repayment Days', isSorting: true },
    { id: 'status', label: 'Status', isSorting: true },
  ];

  const handleSort = (column) => {
    let sortByColm = column.id;
    if (column.id === 'interest_rate') {
      sortByColm = 'new_interest_rate';
    }

    setSortBy(sortByColm);
    setIsAscSort((isAscSort) => !isAscSort);
  };

  return (
    <Grid item xs={12}>
      <Typography sx={{ mt: 2, mb: 2 }} variant="h6">
        Interest Rate Scheme
      </Typography>
      <div style={parentContainerStyle}>
        {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}

        <TextField
          variant="outlined"
          label="Search scheme"
          type="text"
          name="searchScheme"
          autoComplete="off"
          placeholder="Search scheme"
          value={scheme}
          onChange={(e) => {
            setScheme(e.target.value);
          }}
          style={{ marginLeft: '6px' }}
        />
        <Button
          style={{
            backgroundColor: '#5e72e4',
            color: 'white',
            marginLeft: '10px',
          }}
          onClick={() => {
            fetchSchemeDetails(scheme);
          }}
        >
          Search
        </Button>
        <Button
          style={{
            float: 'right',
            backgroundColor: '#5e72e4',
            color: '#fff',
            marginLeft: 'auto',
            marginRight: '15px',
          }}
          onClick={handleClickOpen}
        >
          <AddIcon></AddIcon>
          Add New Scheme
        </Button>
        {isPopUp && (
          <FormPopup heading="Add New Scheme" isOpen={isPopUp} onClose={handlePopupClose} customStyles={customStyles} customStyles1={customStyles1} customHeaderStyle={customHeaderStyle}>
            <CardContent>
              <h4 style={{ marginTop: '20px' }}>Is this a step up scheme?</h4>
              <div className="content" style={{ overflowY: 'scroll', maxHeight: '70vh' }}>
                <div style={{ display: 'flex', flexDirection: 'inherit' }}>
                  {rowIsOpen === true ? null : (
                    <RadioGroup aria-labelledby="" name="" value={isSetUp} onChange={(event) => handleToggle(event)} style={{ display: 'flex', flexDirection: 'inherit' }}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  )}

                  {rowIsOpen === true ? (
                    <RadioGroup aria-labelledby="" name="" value={singleRowData?.is_step_up === 'Step-up' ? 'Yes' : 'No'} disabled style={{ display: 'flex', flexDirection: 'inherit' }}>
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  ) : null}
                </div>
                <TextField variant="outlined" label="Scheme Name" type="text" name="searchName" autoComplete="off" placeholder="Ex : 'ABC102'" disabled={rowIsOpen} value={schemeName ? schemeName : viewData?.viewSchemeName} onChange={handleSchemeNameInput} helperText={isNotValidSchemeName ? `${schemeNameError}` : null} error={isNotValidSchemeName} style={commanTextFieldstyle} />
                <Autocomplete
                  disablePortal
                  id="interest-type-select"
                  options={interestRateType}
                  value={intRateType ? intRateType : viewData?.viewInterestType}
                  disabled={rowIsOpen}
                  onChange={(event, newValue) => {
                    setIntRateType(newValue);
                    setNotValidIntType(false);
                  }}
                  style={commanTextFieldstyle}
                  renderInput={(params) => <TextField {...params} label="Interest Type" helperText={isNotValidIntType ? `${intRateTypeError}` : null} error={isNotValidIntType} />}
                />

                {isSetUp === 'Yes' ? null : <TextField variant="outlined" label="Interest Rate" type="text" name="interestRate" autoComplete="off" placeholder="Ex: '12' or '12.5' or 12.45'" disabled={rowIsOpen} value={interestRate ? interestRate : viewData?.viewInterestRate} onChange={handleInterestRateInput} helperText={isNotValidInterestRate ? `${interestRateError}` : null} error={isNotValidInterestRate} style={commanTextFieldstyle} />}

                <div>{isSetUp === 'Yes' && <h4>Define interest rate step up slabs </h4>}</div>
                <div
                  style={{
                    overflowY: 'scroll',
                    maxHeight: '35vh',
                  }}
                >
                  {isChecked
                    ? slabs?.map((slab, index) => (
                        <div key={index} style={parentContainer}>
                          <div style={{ width: '25%' }}>
                            <TextField
                              variant="outlined"
                              label="Start "
                              type="number"
                              disabled={true}
                              value={slab.start_day}
                              // onChange={(e) => handleSlubChange(index, 'start_day', e.target.value)}
                              placeholder="Ex: 1 "
                              style={commanTextFieldIsSetup}
                            />
                          </div>
                          <div style={{ width: '25%' }}>
                            <TextField variant="outlined" label="End" type="number" value={slab.end_day} inputProps={{ inputmode: 'numeric', pattern: '[0-9]*' }} onChange={(e) => handleSlubChange(index, 'end_day', e.target.value)} placeholder="Ex: 30 " style={commanTextFieldIsSetup} />
                          </div>
                          <div style={{ width: '45%' }}>
                            <TextField variant="outlined" label="Interest rate" type="number" value={slab.interest_rate} onChange={(e) => handleSlubChange(index, 'interest_rate', e.target.value)} placeholder="Ex: 12 " style={commanTextFieldIsSetup} />
                          </div>

                          {index > 1 && index == slabs.length - 1 && (
                            <div style={deleteParentStyle}>
                              <img onClick={() => handleRemoveSlab(index)} style={deleteImgStyle} src={deleteIcon} alt="deleteIcon" />
                            </div>
                          )}
                        </div>
                      ))
                    : null}
                </div>
                {rowIsOpen &&
                  singleRowData?.rate_slab?.map((slab, index) => (
                    <div key={index} style={parentContainer}>
                      <div style={{ width: '25%' }}>
                        <TextField variant="outlined" label="Start " type="number" value={slab.start_day} onChange={(e) => handleSlubChange(index, 'start_day', e.target.value)} placeholder="Ex: '1' " disabled={rowIsOpen} style={commanTextFieldIsSetup} />
                      </div>
                      <div style={{ width: '25%' }}>
                        <TextField variant="outlined" label="End" type="number" value={slab.end_day} disabled={rowIsOpen} onChange={(e) => handleSlubChange(index, 'end_day', e.target.value)} placeholder="Ex: '30' " style={commanTextFieldIsSetup} />
                      </div>
                      <div style={{ width: '45%' }}>
                        <TextField variant="outlined" label="Interest rate" type="text" value={slab.interest_rate} disabled={rowIsOpen} onChange={(e) => handleSlubChange(index, 'interest_rate', e.target.value)} placeholder="Ex: '12' " style={commanTextFieldIsSetup} />
                      </div>
                      {index > 0 && <div style={deleteParentStyle}>{rowIsOpen ? null : <img onClick={() => handleRemoveSlab(index)} style={deleteImgStyle} src={deleteIcon} alt="deleteIcon" />}</div>}
                    </div>
                  ))}

                {isSetUp === 'Yes' && (
                  <p disabled={rowIsOpen} style={addSlabStyle} onClick={handleAddSlubs}>
                    Add Slab
                  </p>
                )}

                {isSetUp === 'No' || singleRowData?.is_step_up === false ? <TextField variant="outlined" label="Overdue Interest" type="text" name="penalRate" autoComplete="off" placeholder="Ex: '12' or '12.5' or 12.45'" value={penalRate ? penalRate : viewData?.viewOverdueInterest} disabled={true} onChange={handlePenalRateInput} helperText={isNotValidPenalRate ? penalRateError : null} error={isNotValidPenalRate} style={{ width: '100%', marginBottom: '0.5cm', marginTop: '4%' }} /> : null}
                <div>
                  <div style={{ display: 'flex', width: '100%', marginTop: '20px' }}>
                    <TextField variant="outlined" label="Bounce Charge" type="text" name="bounceCharge" autoComplete="off" placeholder="Ex: '12' or '12.5' or 12.45'" value={bounceCharge ? bounceCharge : viewData?.viewBounceCharge} onChange={handleBounceChargeInput} helperText={isNotValidBounceChare ? `${bounceChargeError}` : null} error={isNotValidBounceChare} disabled={rowIsOpen} style={{ width: '50%', marginBottom: '0.5cm', marginRight: '20px' }} />
                    {!isChecked && <TextField variant="outlined" label="Repayment Days" type="number" name="repaymentDays" autoComplete="off" placeholder="Ex: '12'" value={repaymentDays ? repaymentDays : viewData?.viewRepaymentDays} onChange={handleRepaymentDaysInput} helperText={isNotValidRepaymentDays ? `${repaymentDaysError}` : null} error={isNotValidRepaymentDays} disabled={rowIsOpen} style={repaymentFieldStyle} />}

                    {isChecked && <TextField variant="outlined" label="Repayment Days" type="number" name="repaymentDays" autoComplete="off" disabled={isChecked} placeholder="Ex: '12'" value={repaymentDaysIsSetUp ? repaymentDaysIsSetUp : viewData?.viewRepaymentDays} helperText={isNotValidRepaymentDays ? `${repaymentDaysError}` : null} error={isNotValidRepaymentDays} style={repaymentFieldStyle} />}
                  </div>
                </div>
              </div>

              {rowIsOpen ? null : (
                <div style={buttonParentStyle}>
                  <Button disabled={rowIsOpen} style={addButtonStyle} onClick={handleSchemeAdd}>
                    Add
                  </Button>
                  <Button disabled={rowIsOpen} style={cancelButtonStyle} onClick={handleDiscard}>
                    Cancel
                  </Button>
                </div>
              )}
            </CardContent>
          </FormPopup>
        )}
      </div>
      <CardContent>
        <>
          <div className="table-alignment-css">
            <CTable
              customStyle={{
                display: 'grid',
                height: 'max-containts',
                gridTemplateColumns: '10% 13% 11% 12% 10% 12% 12% 10% 10%',
              }}
              data={schemeDetails}
              columns={columnsScheme}
              handleSort={handleSort}
              isAscending={isAscSort}
              rowClickValue={'_id'}
              rowClickFunction={openPartnerDetails}
              sortBy={sortBy}
            />
          </div>
          <Pagination itemsPerPage={limit} totalItems={count} rowsPerPageOptions={[5, 10, 15]} onPageChange={handleChangePage} showOptions={true} setRowLimit={(e) => handleChangeRowsPerPage(e)} />
        </>

        <Dialog open={isUpdateStatusPop} onClose={handleUpdateStatusPopUpClose} aria-labelledby="alert-dialog-update-status" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-update-status" align="center">
            {'Are you sure?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" align="center">
              The scheme you are trying to deactive is already mapped to {mappedProducts} products
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleUpdateStatusPopUpClose}
              style={{
                color: '#5e72e4',
                float: 'center',
                width: '3cm',
                borderBlockColor: '#5e72e4',
                borderInlineStartColor: '#5e72e4',
                borderInlineEndColor: '#5e72e4',
              }}
              autoFocus
            >
              No
            </Button>
            <Button
              onClick={() => updateSchemeStatus(updatableScheme)}
              style={{
                backgroundColor: '#5e72e4',
                color: 'white',
                float: 'center',
                width: '3cm',
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Grid>
  );
};

const interestRateType = [{ label: 'Rear-end' }, { label: 'Upfront' }];

export default InterestRateScheme;
