import { Number_Constants } from '../constants/number_constants';
import { EN_US_DATEFORMAT_OPTIONS, EN_US_LOCALES } from '../constants/date_format_constants.js';
import { Application_Mode, Section_Status_Type } from '../constants/string_constants';

export const trimText = (str) => {
  // Remove extra spaces
  const trimmedString = str.replace(/\s+/g, ' ').trim();
  return trimmedString;
};
export const generateRandomId = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  for (let i = 0; i < 4; i++) {
    result += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }
  result += characters.charAt(Math.floor(Math.random() * characters.length));
  return result;
};

export const extractDocumentCode = (documentList, codeKey = 'documentCode') => {
  return documentList.flat().map((document) => document[codeKey]);
};
export const generateArrayWithKey = (key = '', length = 15) => {
  return Array.from({ length }, (_, i) => `${key}-${i + 1}`);
};

export const capitalizeFirstLetter = (string) => {
  string = string.replace(/co-borrower/gi, '');
  string = string.replace(/guarantor/gi, '');
  string = string.replace(/applicant/gi, '');
  string = string.trim();
  let result = string.replace(/\b\w/g, (char) => char.toUpperCase());
  // if string length is below 4 characters then make the string uppercase
  if (string.length <= 4) {
    return string.toUpperCase();
  }
  // if string contains xml / json then make the xml / json in a string uppercase
  result = result.replace(/xml/gi, 'XML');
  result = result.replace(/json/gi, 'JSON');
  result = result.replace(/pan/gi, 'PAN');
  result = result.replace(/gst/gi, 'GST');
  return result;
};

export const formatDateTime = (timestamp) => {
  try {
    const formattedDate = new Date(timestamp).toLocaleDateString(EN_US_LOCALES, EN_US_DATEFORMAT_OPTIONS);
    const [month, day, year] = formattedDate.split('/');
    return `${day}-${month}-${year}`;
  } catch (error) {
    throw error;
  }
};

export const calculateAge = (formattedDate = '') => {
  let age = '';
  if (formattedDate) {
    const birthMonth = formattedDate.substring(5, 7);
    const birthDay = formattedDate.substring(8, 10);
    const birthYear = formattedDate.substring(0, 4);
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth();
    var currentDay = currentDate.getDate();
    age = currentYear - birthYear;

    if (currentMonth < birthMonth - 1) {
      age--;
    }
    if (birthMonth - 1 == currentMonth && currentDay < birthDay) {
      age--;
    }
    return age;
  }
  return age;
};

export const truncateText = (inputStr = '', count = 150) => {
  if (inputStr.length > count) {
    return inputStr.substring(0, count) + '...';
  }
  return inputStr;
};

export const getfillersFromList = (list, columns, isMultiColList) => {
  const listLength = isMultiColList && list?.length ? list?.length * 2 : list?.length ?? 0;
  let count = Number_Constants.ZERO;
  if (listLength && listLength % columns) {
    count = columns - (listLength % columns);
  }
  return getFillers(count);
};
export const getFillers = (count) => {
  let fillerSections = [];
  if (count) {
    for (let i = Number_Constants.ZERO; i < count; i++) {
      fillerSections.push({
        label: '',
        value: '',
        type: 'filler',
        direction: 'column',
      });
    }
  }
  return fillerSections;
};

export const isSectionCompleted = (status) => {
  if (status === Section_Status_Type.Success || status === Section_Status_Type.Deviation) {
    return true;
  }
  return false;
};
export const isDisplaySubmitButton = (status, viewType) => {
  if (viewType == Application_Mode.View && isSectionCompleted(status)) {
    return true;
  }
  return false;
};

export const isMaskedAadhaar = (value) => {
  if (value && value?.includes('*')) {
    return true;
  }
  return false;
};
