import React, { useEffect, useState } from 'react';
import { CustomAccordion } from 'lap/components/CustomAccordion/CustomAccordion.component';
import { GET_FINAL_SUB_SECTION_SUBMIT_DATA, GET_MOCK_ACCORDION_DATA, getAllPropertyDocuments, OFFER_STATUS } from '../../helper';
import './LeadDetailsTab.scss';
import { storedList } from 'util/localstorage';
import { useDispatch } from 'react-redux';
import { getBookLoanDetailsWatcher, getLapLoanDocumentsWatcher } from '../../../../../actions/bookLoan.action';
import _isEmpty from 'lodash/isEmpty';
import _intersectionWith from 'lodash/intersectionWith';
import { getLapLeadSectionStatusForLoanAppidWatcher, postLapSectionStatusWatcher } from '../../../../../actions/status.action';
import { postLEADSoftOfferWatcher } from '../../../../../actions/lead.action';
import { Alert_Type, Error_Message, Section_Status } from '../../../../../constants/string_constants';
import Preloader from '../../../../../components/custom/preLoader.jsx';
import SlidingAlert from 'lap/components/SlidingAlert/SlidingAlert';
import { LeadNewStatus } from '../../../../../config/LeadStatus';
import LeadRejectedCard from '../../Components/LeadRejectedCard';
import { Additional_Documents } from '../../../../bookLoans/uploadKycData';

const user = { _id: storedList('user')?._id, id: storedList('user')?.id };

function LeadDetailsTab(props) {
  const { isSubAccordion = false, subAccordionData = [], loanAppId, fetchActivityLogs, handlePopupVisibility, handlePopupSubmit, handleApproveButton = null, isEdit = false, setOffer, updateOffer, offerLoading, offerDetails } = props;
  const [sectionStatusLoading, setSectionStatusLoading] = useState(false);
  const [LAPCompanyId, setLAPCompanyId] = useState('');
  const [LAPProductId, setLAPProductId] = useState('');
  const [leadDetails, setLeadDetails] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [documents, setDocuments] = useState([]);
  const dispatch = useDispatch();
  const [sectionStatusList, setSectionStatusList] = useState([]);
  const [allAccordionState, setAllAccordionState] = useState({});
  const [allDocuments, setAllDocuments] = useState({});
  const [titleDocDetails, setTitleDocDetails] = useState({});
  const getLoanData = () => {
    if (loanAppId) {
      const payload = {
        loan_app_id: loanAppId,
        user: user,
      };
      new Promise((resolve, reject) => {
        dispatch(getBookLoanDetailsWatcher(payload, resolve, reject));
      })
        .then((response) => {
          if (response?.data?.length) {
            setLeadDetails((prevData) => ({ ...prevData, ...response?.data[0] }));
          }
        })
        .catch((e) => {
          showAlert('Something went Wrong', 'error');
        });
    }
  };

  const getLoanDocuments = () => {
    const payload = {
      loanAppID: loanAppId,
      companyId: LAPCompanyId,
      productId: LAPProductId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLapLoanDocumentsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        if (response) {
          const additionalDocument = _intersectionWith(Additional_Documents, response, (allAddtDoc, savedDoc) => {
            if (allAddtDoc?.documentCode === savedDoc?.code) {
              return savedDoc;
            }
          });
          setDocuments(additionalDocument);
        }
      })
      .catch((error) => {
        console.log('this is error', error);
      });
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const checkAndSetOffer = (response = []) => {
    const propertyDocSection = response.find((val) => val.section_name === Section_Status_Name.Property_Documents);
    const isApproved = propertyDocSection?.section_status === Section_Status.Approved;
    if (isApproved) {
      setOffer(OFFER_STATUS.soft_offer);
    }
  };
  const getSectionStatus = () => {
    if (loanAppId) {
      const payload = {
        loan_app_id: loanAppId,
        companyId: LAPCompanyId,
        productId: LAPProductId,
        user: user,
      };
      new Promise((resolve, reject) => {
        dispatch(getLapLeadSectionStatusForLoanAppidWatcher(payload, resolve, reject));
      })
        .then((response) => {
          if (response?.length) {
            const finalSubmitCheckList = GET_FINAL_SUB_SECTION_SUBMIT_DATA(response);
            setLeadDetails((prevData) => ({ ...prevData, ...finalSubmitCheckList }));
            setSectionStatusList(response);
            console.log('get section status', response);
            const allApprovedExceptSpecific = response.every((item) => {
              if (item.section_code === 'property-documents' || item.section_code === 'additional_doc') {
                return true;
              } else {
                return item.section_status === LeadNewStatus.Approved;
              }
            });
            handleApproveButton(allApprovedExceptSpecific);
            //handleApproveButton(response.every(item => item.section_status === LeadNewStatus.Approved));
            checkAndSetOffer(response);
          }
        })
        .catch((e) => {});
    }
  };

  const approveSection = (sectionData) => {
    if (loanAppId) {
      const payload = {
        loan_app_id: sectionData?.loan_app_id || loanAppId,
        companyId: LAPCompanyId,
        productId: LAPProductId,
        user: user,
        status: Section_Status.Approved,
        section_sequence_no: sectionData?.section_sequence_no?.toString() || '1000',
        section_code: sectionData?.section_code || 'property-documents',
      };
      setSectionStatusLoading(true);
      new Promise((resolve, reject) => {
        dispatch(postLapSectionStatusWatcher(payload, resolve, reject));
      })
        .then((response) => {
          showAlert(`${sectionData?.sectionName} section is approved`, Alert_Type?.Success);
          if (response?.success) {
            getSectionStatus();
            if (sectionData?.sectionName === 'property-documents') {
              updateOffer({ offerDetails, isROIChange: true });
            }
          }
        })
        .catch((e) => {
          showAlert(Error_Message?.Something_Wrong, Alert_Type?.Error);
        })
        .finally(() => {
          setSectionStatusLoading(false);
        });
    }
  };

  useEffect(() => {
    if (_isEmpty(leadDetails)) {
      getLoanData();
    }
  }, [loanAppId]);
  useEffect(() => {
    if (!_isEmpty(leadDetails)) {
      setLAPCompanyId(leadDetails?.company_id);
      setLAPProductId(leadDetails?.product_id);
    }
  }, [leadDetails]);
  useEffect(() => {
    if (LAPCompanyId && LAPProductId) {
      getSectionStatus();
      getLoanDocuments();
      getAllPropertyDocuments(loanAppId, LAPCompanyId, LAPProductId, showAlert, dispatch, setAllDocuments);
    }
  }, [LAPCompanyId, LAPProductId]);
  const accordionData = GET_MOCK_ACCORDION_DATA({ isEdit, isSubAccordion, subAccordionData, leadDetails, sectionStatusList, documents, approveSection, allAccordionState, setAllAccordionState, loanAppId, companyID: LAPCompanyId, productID: LAPProductId, dispatch, showAlert, setLeadDetails, allDocuments, setAllDocuments, titleDocDetails, setTitleDocDetails, setOffer }, handlePopupVisibility, handlePopupSubmit);
  const isLoading = offerLoading || sectionStatusLoading;
  return (
    <div className={`lap-accordion-section ${isEdit ? 'edit-section' : ''}`}>
      <LeadRejectedCard leadStatus={leadDetails?.lead_status} />
      <CustomAccordion fetchActivityLogs={fetchActivityLogs} accordionData={accordionData} customRightComponentContainer={{ marginTop: '20px' }} />
      {isLoading && <Preloader />}
      {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}
export default LeadDetailsTab;
