const ErrorConstants = {
  SPECIAL_CHARACTERS_ARE_NOT_ALLOWED: 'Special characters are not allowed',
  PLEASE_ENTER_NAME: 'Please enter name',
  PLEASE_CHECK_ERROR_IN_THE_FIELDS: 'Please check error in the fields',
  PLEASE_ENTER_VALID_PERC: 'Please enter valid %',
  TOTAL_PERCENTAGE_SHOULD_BE_100: 'Please note that total Sharing holding percentage should be 100%. Please ensure to add all shareholders.',
  SPECIAL_CHARACTERS_AND_NUMBERS_ARE_NOT_ALLOWED: 'Special characters and numbers are not allowed',
  ERROR_MSG_FOR_NOT_UPLOAD_THE_FILE: 'File is not uploaded. Please check and upload',
  VALUE_IS_REQUIRED: 'Value is required',
  VALUE_EXCEEDED: 'Value exceeded. Total value should be 100%.',
};

module.exports = {
  ErrorConstants,
};
