import * as React from 'react';
import { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import Table from '../../../node_modules/react-sdk/dist/components/Table/Table';
import Pagination from '../../../node_modules/react-sdk/dist/components/Pagination/Pagination';
import { downloadDataInXLSXFormat } from '../../util/helper';
import Button from 'react-sdk/dist/components/Button';
import Img from '../../views/lending/images/download-button.svg';
import imgH from '../../views/lending/images/download-button-hover.svg';
import { AlertBox } from '../AlertBox';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5e72e4',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.black,
  },
}));

export default function Payments(props) {
  const { data, onModalClose, open, setOpen, usageId } = props;
  const [responseData, setResponseData] = useState(data);
  const [payments, setPayments] = useState(data.slice(0, 10));
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(data?.length);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(event);
    const f = event * 10;
    const l = event * 10 + 10;
    const data = responseData.slice(f, l);
    setPayments(data);
  };

  const buttonCss = {
    display: 'flex',
    height: '2.7rem',
    width: '7rem',
    borderRadius: '1.625rem',
    fontSize: '0.75rem',
    border: '0.063rem solid #475BD8',
    color: '#475BD8',
    fontFamily: 'Montserrat-Regular',
    padding: '0.625rem 1.5rem',
    backgroundColor: '#FFF',
  };

  const columns = [
    { id: 'utr', label: 'UTR NUMBER' },
    { id: 'Date', label: 'DATE', format: (rowData) => moment(rowData.utr_date).format('YYYY-MM-DD') },
    {
      id: 'Principal',
      label: 'PRINCIPAL',
      format: (rowData) => {
        const prinPaid = rowData?.prin_paid;
        return <div>{prinPaid || prinPaid == 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(getVal(prinPaid)) : 'NA'}</div>;
      },
    },
    {
      id: 'Interest',
      label: 'INTEREST',
      format: (rowData) => {
        const intPaid = rowData?.int_paid;
        return <div>{intPaid || intPaid == 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(getVal(intPaid)) : 'NA'}</div>;
      },
    },
    {
      id: 'amount_paid',
      label: 'AMOUNT PAID',
      format: (rowData) => {
        const amtPaid = rowData?.amount_paid;
        return <div>{amtPaid || amtPaid == 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(getVal(amtPaid)) : 'NA'}</div>;
      },
    },
    {
      id: 'LPI',
      label: 'OI',
      format: (rowData) => {
        const lpiPaid = rowData?.lpi_paid;
        return <div>{lpiPaid || lpiPaid == 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(getVal(lpiPaid)) : 'NA'}</div>;
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);
    onModalClose('', '');
  };
  const getVal = (value) => {
    if (value?.$numberDecimal !== undefined) {
      return parseFloat(value.$numberDecimal.toString());
    } else if (typeof value === 'object') {
      return parseFloat(value.toString());
    }
    return value;
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 2000);
  };

  const generateCsvFile = (data, ext) => {
    const date = new Date();
    let fileName = `Repayments_${usageId}_${moment(date).format('DD_MMM_YYYY')}.${ext}`;
    downloadDataInXLSXFormat(fileName, data);
    setTimeout(() => {
      showAlert('File Downloaded Successfully', 'success');
    }, 2000);
  };

  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}

      {open ? (
        <FormPopup onClose={handleClose} heading="Repayments" isOpen={open} open={open} customStyles={{ width: '75%', overflowX: 'hidden', height: '80%', overflowY: 'auto' }}>
          {payments.length ? (
            <div>
              <div style={{ width: '100%', display: 'grid' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '4rem', gap: '1rem', marginTop: '-2.3rem' }}>
                  <Button
                    label="XLSX"
                    customStyle={buttonCss}
                    onClick={() => {
                      generateCsvFile(data, 'xlsx');
                    }}
                    imageButton={Img}
                    imageButtonHover={imgH}
                    iconButton="btn-secondary-download-button"
                    buttonType="secondary"
                  />
                  <Button
                    label="CSV"
                    customStyle={buttonCss}
                    onClick={() => {
                      generateCsvFile(data, 'csv');
                    }}
                    imageButton={Img}
                    imageButtonHover={imgH}
                    iconButton="btn-secondary-download-button"
                    buttonType="secondary"
                  />
                </div>
                <Table customStyle={{ width: '100%' }} data={payments} columns={columns} />
                <div style={{ width: '100%' }}> {count ? <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={10} /> : null}</div>
              </div>
            </div>
          ) : (
            ''
          )}
        </FormPopup>
      ) : null}
    </>
  );
}
