import React from 'react';
import { Slider } from '../Slider/Slider';
import { formatDateTime } from 'lap/utils/helper';
import { activityLogNamingFormat } from 'lap/config/activityLogNaming';
import './AuditLog.style.css';

const renderLog = (logItem, index) => {
  return (
    <div key={index} className="audit-log-item">
      <div className="audit-title">{activityLogNamingFormat[logItem?.category]}</div>

      <div className="audit-text">
        <span className="log-header">Name: </span>
        <span className="log-value" style={{ color: '#767888' }}>
          {logItem?.updated_by?.username}
        </span>
      </div>

      <div className="audit-text">
        <span className="log-header">User ID: </span>
        <span className="log-value" style={{ color: '#767888' }}>
          {logItem?.updated_by?.email}
        </span>
      </div>

      <div className="audit-text">
        <span className="log-header">Date & Time: </span>
        <span className="log-value">{formatDateTime(logItem.updatedAt)}</span>
      </div>

      <div className="audit-text">
        <span className="log-header">Comment: </span>
        <span className="log-value">{logItem.remarks}</span>
      </div>
    </div>
  );
};

export const AuditLog = ({ data }) => {
  return (
    <Slider>
      <div className="audit-div">
        <div className="audit-header">AUDIT LOG</div>
        {data && data.length > 0 ? data.map(renderLog) : <p className="log-empty">No audit logs found!</p>}
      </div>
    </Slider>
  );
};
