import * as React from 'react';
import { useState, useEffect } from 'react';
import ApplicantDetailsForm from './applicantDetailsForm';
import 'react-sdk/dist/styles/_fonts.scss';
import SuccessIcon from '../../../assets/img/successIcon.svg';
import DeviationIcon from '../../../assets/img/DeviationIconForLap.svg';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import { useLocation } from 'react-router-dom';
import { LogViewer } from '../../components/LogViewer/LogViewer';
import EntityDetailsForm from './entityDetailsForm';
import CoBorrowerForm from './coBorrowerForm';
import './bookLoans.scss';
import ShareHoldingPatternForm from './ShareHoldingPatternForm';
import GuarantorForm from './guarantorDetailsForm';
import PropertyDetailsForm from './propertyDetailsForm';
import RejectionScreen from './rejectionScreen';
import AdditionalDocuments from './additionalDocuments';
import FinancialDocument from './financialDocument';
import OfferPage from './bookLoansDocumentMapping/offerPage';
import { Number_Constants } from '../../constants/number_constants';
import { Colors } from '../../constants/color_constant';
import { LeadNewStatus } from '../../config/LeadStatus';
import { LeadStatus } from 'lap/config/LeadStatus';
import { AuditLog } from 'lap/components/AuditLog/AuditLog';
import { getLapActivityLogsWatcher } from 'lap/actions/lead.action';
import { storedList } from 'util/localstorage';
import { Section_Status, AUDIT_LOG_KEYS } from '../../constants/string_constants';
import { useDispatch } from 'react-redux';
import { ACTIVITY_LOG_SECTION_NAME_AND_CODE } from 'lap/config/activityLogNaming';
import Preloader from '../../../components/custom/preLoader';

export default function BookLoans(props) {
  const location = useLocation();
  const type = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const [navState, setNavState] = useState('Applicant Details');
  const [isRejected, setIsRejected] = useState(false);
  const [additionalDocuments, setAdditionalDocuments] = useState(null);
  const [applicantData, setApplicantData] = useState('');
  const [entityData, setEntityData] = useState(null);
  const [propertyData, setPropertyData] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [LAPCompanyId, setLAPCompanyId] = useState('');
  const [LAPProductId, setLAPProductID] = useState('');
  const [leadStatus, setLeadStatus] = useState('');
  const [statusCheck, setStatusCheck] = useState(false);
  const [loanAppId, setLoanAppId] = useState('');
  const [entityDetailsNavItemShow, setEntityDetailsNavItemShow] = useState(false);
  const [showShareHolding, setShowShareHolding] = useState(false);
  const defaultCoborrower = [{ id: 1, name: 'Co-Borrower 1' }];
  const [coBorrowerArray, setCoBorrowerArray] = useState(defaultCoborrower);
  const [coBorrowersData, setCoBorrowersData] = useState([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  const [coAppStatus, setCoAppStatus] = useState(Array.from({ length: 15 }, () => false));
  const [CoBorrowerCount, setCoBorrowerCount] = useState(1);
  const [guarantorsData, setGuarantorsData] = useState([{}, {}]);
  const defaultGuarantor = [{ id: 1, name: 'Guarantor 1' }];
  const [guarantorArray, setGuarantorArray] = useState(defaultGuarantor);
  const [GuarantorCount, setGuarantorCount] = useState(1);
  const [guarantorStatus, setGuarantorStatus] = useState(Array.from({ length: 5 }, () => false));
  const [auditLogs, setAuditLogs] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [navIconPrefixState, setNavIconPrefixState] = useState({
    'Applicant Details': null,
    'Entity Details': null,
    'Co-Borrower Details': null,
    'Guarantor Details': null,
    'Share Holding Pattern': null,
    'Property Details': null,
    'Financial Docs': null,
    'Additional Docs': null,
    Offer: null,
  });
  const [navIconBottomState, setNavIconBottomState] = useState({
    'Applicant Details': null,
    'Entity Details': null,
    'Co-Borrower Details': null,
    'Guarantor Details': null,
    'Property Details': null,
    'Financial Docs': null,
    'Additional Docs': null,
  });

  useEffect(() => {
    if (props.loanAppId) {
      setLoanAppId(props.loanAppId);
    } else {
      setIsLoading(false);
    }
  }, [props.loanAppId]);

  useEffect(() => {
    if (!coBorrowerArray?.length) {
      setCoBorrowerArray(defaultCoborrower);
    }
  }, [coBorrowerArray]);

  useEffect(() => {
    if (leadStatus !== undefined && leadStatus !== '' && leadStatus !== LeadNewStatus.Rejected) {
      setIsLoading(false);
    }
  }, [leadStatus]);

  useEffect(() => {
    handleEditView();
    setCoBorrowerCount(props?.fetchedCoBorrowerDetails?.length > 0 ? renderCoborrowerCount() : 1);
    function renderCoborrowerCount() {
      const coborrowerSectionStatus = props?.loanSectionObject?.[`co_borrower-${props.fetchedCoBorrowerDetails?.length}`]?.section_status;
      const coborrowerBureauSectionObject = props?.loanSectionObject?.[`co_borrower-${props.fetchedCoBorrowerDetails?.length}`]?.subsections?.[1]?.['sub_section_status'];
      if ((coborrowerBureauSectionObject == LeadNewStatus.Approved && coborrowerSectionStatus == LeadNewStatus.InProgress && type != 'view' && leadStatus == LeadNewStatus.Draft) || (coborrowerBureauSectionObject == LeadNewStatus.Deviation && coborrowerSectionStatus == LeadNewStatus.InProgress && type != 'view' && leadStatus == LeadNewStatus.Draft)) {
        return props.fetchedCoBorrowerDetails?.length + 1;
      } else {
        return props.fetchedCoBorrowerDetails?.length;
      }
    }
    setGuarantorCount(props?.fetchedGuarantorDetails?.length > 0 ? renderGuarantorCount() : 1);
    function renderGuarantorCount() {
      const guarantorSectionStatus = props?.loanSectionObject?.[`guarantor_details_${props.fetchedGuarantorDetails?.length}`]?.section_status;
      const guarantorBureauSectionObject = props?.loanSectionObject?.[`guarantor_details_${props.fetchedGuarantorDetails?.length}`]?.subsections?.[1]?.['sub_section_status'];
      if ((guarantorBureauSectionObject == LeadNewStatus.Approved && guarantorSectionStatus == LeadNewStatus.InProgress && type != 'view' && leadStatus == LeadNewStatus.Draft) || (guarantorBureauSectionObject == LeadNewStatus.Deviation && guarantorSectionStatus == LeadNewStatus.InProgress && type != 'view' && leadStatus == LeadNewStatus.Draft)) {
        return props.fetchedGuarantorDetails?.length + 1;
      } else {
        return props.fetchedGuarantorDetails?.length;
      }
    }
  }, [props.fetchedApplicantDetails, props.fetchedEntityDetails, props.fetchedCoBorrowerDetails, props.propertyDocumentsDetails, props.fetchedGuarantorDetails, props.navPrefixStateEdit, props.shouldFetch, isRejected, leadStatus]);

  useEffect(() => {
    loanAppId && props.leadStatus != LeadNewStatus.Draft && props.isEditBookLoan && fetchActivityLogs();
  }, [navState]);

  const handleEditView = () => {
    loanAppId && props.leadStatus != LeadNewStatus.Draft && fetchActivityLogs();
    if (props.leadStatus && props.leadStatus === LeadNewStatus.Rejected) {
      setNavState('Applicant Details');
      setIsRejected(true);
      setIsLoading(false);
    } else {
      if (props.fetchedApplicantDetails) {
        setApplicantData(props.fetchedApplicantDetails);
        if (props.fetchedApplicantDetails.lead_status) {
          setLeadStatus(props.fetchedApplicantDetails.lead_status);
        }
        if (props.fetchedApplicantDetails.company_id) {
          setLAPCompanyId(props.fetchedApplicantDetails.company_id);
        }
        if (props.fetchedApplicantDetails.product_id) {
          setLAPProductID(props.fetchedApplicantDetails.product_id);
        }
      }
      if (props.fetchedEntityDetails) {
        setEntityData(props.fetchedEntityDetails);
        if (props.fetchedEntityDetails.string_vl_select_entity_type && props.fetchedEntityDetails.string_vl_select_entity_type !== 'Individual') {
          setShowShareHolding(true);
        }
      }
      if (props.fetchedCoBorrowerDetails) {
        var temp = [];
        for (var i = 0; i < props.fetchedCoBorrowerDetails?.length; i++) {
          temp.push({ id: i + 1, name: `Co-Borrower ${i + 1}` });
        }
        if (type == 'edit' && temp.length == 0 && props.leadStatus === LeadNewStatus.Draft) {
          setCoBorrowerArray(defaultCoborrower);
          setCoBorrowersData([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        } else {
          setCoBorrowerArray(temp);
          setCoBorrowersData(props.fetchedCoBorrowerDetails);
        }
      }
      if (props.fetchedGuarantorDetails) {
        var temp = [];
        for (let i = 0; i < props.fetchedGuarantorDetails?.length; i++) {
          temp.push({ id: i + 1, name: `Guarantor ${i + 1}` });
        }
        if (type == 'edit' && temp.length == 0 && props.leadStatus === LeadNewStatus.Draft) {
          setGuarantorArray(defaultGuarantor);
          setGuarantorsData([{}, {}, {}, {}, {}]);
        } else {
          setGuarantorArray(temp);
          setGuarantorsData(props.fetchedGuarantorDetails);
        }
      }
      if (props.propertyDocumentsDetails) {
        setPropertyData(props.propertyDocumentsDetails);
      }
      if (props.additionalDocumentsDetails) {
        setAdditionalDocuments(props.additionalDocumentsDetails);
      }

      if (props.navPrefixStateEdit) {
        setNavIconPrefixState(props.navPrefixStateEdit);
        for (let tempNavState of Object.keys(props.navPrefixStateEdit)) {
          if (props.navPrefixStateEdit[tempNavState] != 'success') {
            if (props.navPrefixStateEdit[tempNavState] == 'deviation' && props.fetchedApplicantDetails && props.fetchedApplicantDetails.lead_status == 'draft') {
              continue;
            } else {
              setNavState(tempNavState);
              break;
            }
          }
        }
      }
      if (props.leadStatus === LeadStatus.new.value) {
        setNavState('Offer');
      }
    }
  };

  const navItemCss = {
    color: 'var(--neutrals-neutral-60, #767888)',
    fontFamily: 'Montserrat-Regular',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    marginTop: '36px',
    cursor: 'pointer',
  };

  const selectedItemCss = {
    color: 'var(--neutrals-neutral-100, #161719)',
    fontFamily: 'Montserrat-SemiBold',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '21px',
    marginTop: '36px',
    cursor: 'pointer',
  };

  const separatorCss = {
    height: '2px',
    width: '96%',
    backgroundColor: Colors.Separator_Color,
    marginBottom: '2%',
    marginLeft: '2%',
    marginTop: leadStatus == LeadNewStatus.Pending ? '2%' : '0',
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleNavChange = (section) => {
    if (!isRejected) {
      if (type == 'view') {
        setNavState(section);
      } else {
        if (section == 'Applicant Details') {
          setNavState('Applicant Details');
        } else if (section == 'Entity Details') {
          if (navIconPrefixState['Applicant Details'] && (navIconPrefixState['Applicant Details'] === 'success' || navIconPrefixState['Applicant Details'] === 'deviation')) {
            setNavState(section);
          }
        } else if (section == 'Co-Borrower Details') {
          if (navIconPrefixState['Entity Details'] && (navIconPrefixState['Entity Details'] === 'success' || navIconPrefixState['Entity Details'] === 'deviation')) {
            setNavState(section);
          }
        } else if (section == 'Guarantor Details') {
          if (navIconPrefixState['Co-Borrower Details'] && (navIconPrefixState['Co-Borrower Details'] === 'success' || navIconPrefixState['Co-Borrower Details'] === 'deviation')) {
            setNavState(section);
          }
        } else if (section == 'Share Holding Pattern') {
          if (navIconPrefixState['Guarantor Details'] && (navIconPrefixState['Guarantor Details'] === 'success' || navIconPrefixState['Guarantor Details'] === 'deviation')) {
            setNavState(section);
          }
        } else if (section == 'Property Details') {
          let preScreen = showShareHolding ? 'Share Holding Pattern' : 'Guarantor Details';
          if (navIconPrefixState[preScreen] && (navIconPrefixState[preScreen] === 'success' || navIconPrefixState[preScreen] === 'deviation')) {
            setNavState(section);
          }
        } else if (section == 'Financial Docs') {
          if (navIconPrefixState['Guarantor Details'] && (navIconPrefixState['Guarantor Details'] === 'success' || navIconPrefixState['Guarantor Details'] === 'deviation')) {
            setNavState(section);
          }
        } else {
          if (navIconPrefixState['Financial Docs']) {
            setNavState(section);
          }
        }
      }
    }
  };

  const renderNavItem = (item) => {
    return (
      <span style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={navIconPrefixState[`${item}`] == 'deviation' ? { display: 'flex', flexDirection: 'column-reverse' } : { display: 'flex', flexDirection: 'row' }}>
          {navIconPrefixState[`${item}`] == 'success' ? (
            <img style={{ marginTop: '16px', marginBottom: '16px', marginRight: '4px' }} src={SuccessIcon} alt="Icon" />
          ) : navIconPrefixState[`${item}`] == 'deviation' ? (
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <span>
                <img style={{ marginTop: '4px', marginBottom: '16px', marginRight: '4px' }} src={DeviationIcon} alt="Icon" />
              </span>
              <p style={{ marginLeft: '4px', marginTop: '1px', color: '#ff8800', fontWeight: '400' }}>Deviation</p>
            </span>
          ) : null}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={
                navState === item
                  ? {
                      ...selectedItemCss,
                      marginTop: '16px',
                      marginBottom: '16px',
                      marginRight: '10px',
                    }
                  : { ...navItemCss, marginTop: '16px', marginBottom: '16px', marginRight: '10px', color: navIconPrefixState[`${item}`] == 'success' || navIconPrefixState[`${item}`] == 'deviation' ? '#161719' : '#767888' }
              }
              onClick={() => handleNavChange(item)}
            >
              {item}
            </span>
            {navIconBottomState[`${item}`] === 'Deviation' ? (
              <span style={{ display: 'flex' }}>
                <span>
                  <img src={DeviationIcon} alt="Icon" />
                </span>
              </span>
            ) : null}
          </div>
        </span>
      </span>
    );
  };
  useEffect(() => {
    if (!guarantorArray?.length) {
      setGuarantorArray(defaultGuarantor);
    }
  }, [guarantorArray]);

  const renderGuarantorDetails = (guarantorDetail, index) => {
    return (
      <div key={guarantorDetail?.id}>
        <div>
          <GuarantorForm
            item={guarantorDetail}
            itemIndex={index}
            type={((navIconPrefixState['Guarantor Details'] == 'success' || navIconPrefixState['Guarantor Details'] == 'deviation') && leadStatus == 'draft') || (props?.loanSectionObject?.[`guarantor_details_${parseInt(index) + 1}`]?.section_status != LeadNewStatus.InProgress && leadStatus == LeadNewStatus.Pending) ? 'view' : type}
            guarantorsData={guarantorsData}
            setGuarantorsData={setGuarantorsData}
            setNavIconPrefixState={setNavIconPrefixState}
            setNavState={setNavState}
            setGuarantorArray={setGuarantorArray}
            guarantorArray={guarantorArray}
            GuarantorCount={GuarantorCount}
            setGuarantorCount={setGuarantorCount}
            guarantorStatus={guarantorStatus}
            setGuarantorStatus={setGuarantorStatus}
            navIconPrefixState={navIconPrefixState}
            loanAppId={loanAppId}
            leadStatus={leadStatus}
            setLeadStatus={setLeadStatus}
            LAPCompanyId={LAPCompanyId}
            LAPProductId={LAPProductId}
            showShareHolding={showShareHolding}
            documents={props.customerDocs}
            loanDetailsSubsectionStatus={props.loanDetailsSubsectionStatus}
            setShouldFetch={props.setShouldFetch}
            loanSectionObject={props.loanSectionObject}
            setLoanSectionObject={props.setLoanSectionObject}
            loanDetailsStatus={props.loanDetailsStatus}
            leadComment={props.leadComment}
            setShouldFetchDocument={props.setShouldFetchDocument}
          />
        </div>
        {index + 1 < GuarantorCount && (
          <div
            style={{
              height: '2px',
              width: '96%',
              backgroundColor: `#C0C1C8`,
              marginBottom: '2%',
              marginLeft: '2%',
              marginTop: leadStatus == LeadNewStatus.Pending ? '2%' : '0',
            }}
          ></div>
        )}
      </div>
    );
  };

  const renderCoBorrowerDetail = (coBorrowerDetail, index) => {
    return (
      <div key={coBorrowerDetail?.id}>
        <div>
          <CoBorrowerForm
            item={coBorrowerDetail}
            itemIndex={index}
            type={((navIconPrefixState['Co-Borrower Details'] == 'success' || navIconPrefixState['Co-Borrower Details'] == 'deviation') && leadStatus == 'draft') || (props?.loanSectionObject?.[`co_borrower-${parseInt(index) + 1}`]?.section_status != LeadNewStatus.InProgress && leadStatus == LeadNewStatus.Pending) ? 'view' : type}
            coBorrowersData={coBorrowersData}
            setCoBorrowersData={setCoBorrowersData}
            setNavIconPrefixState={setNavIconPrefixState}
            setNavState={setNavState}
            setCoBorrowerArray={setCoBorrowerArray}
            coBorrowerArray={coBorrowerArray}
            CoBorrowerCount={CoBorrowerCount}
            setCoBorrowerCount={setCoBorrowerCount}
            coAppStatus={coAppStatus}
            loanAppId={loanAppId}
            leadStatus={leadStatus}
            setLeadStatus={setLeadStatus}
            navIconPrefixState={navIconPrefixState}
            setCoAppStatus={setCoAppStatus}
            LAPCompanyId={LAPCompanyId}
            LAPProductId={LAPProductId}
            documents={props.customerDocs}
            setLoanSectionObject={props.setLoanSectionObject}
            loanDetailsSubsectionStatus={props.loanDetailsSubsectionStatus}
            setShouldFetch={props.setShouldFetch}
            loanSectionObject={props.loanSectionObject}
            loanDetailsStatus={props.loanDetailsStatus}
            leadComment={props.leadComment}
            setShouldFetchDocument={props.setShouldFetchDocument}
          />
        </div>
        {index + 1 < CoBorrowerCount && (
          <div
            style={{
              height: '2px',
              width: '96%',
              backgroundColor: `#C0C1C8`,
              marginBottom: '2%',
              marginLeft: '2%',
              marginTop: leadStatus == LeadNewStatus.Pending ? '2%' : '0',
            }}
          ></div>
        )}
      </div>
    );
  };

  const getViewType = (section, mappingSectionName) => {
    return ((navIconPrefixState[section] == 'success' || navIconPrefixState[section] == 'deviation') && leadStatus == 'draft') || (props?.loanSectionObject?.[mappingSectionName]?.section_status != 'in_progress' && leadStatus == LeadNewStatus.Pending) ? 'view' : type;
  };

  const fetchActivityLogs = () => {
    try {
      const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
      const payload = {
        loanAppId: loanAppId,
        companyId: LAPCompanyId,
        productId: LAPProductId,
        user: user,
        category: ACTIVITY_LOG_SECTION_NAME_AND_CODE[navState],
      };
      new Promise((resolve, reject) => {
        dispatch(getLapActivityLogsWatcher(payload, resolve, reject));
      })
        .then((response) => {
          setAuditLogValue(response);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      //throw error
      console.log(error);
    }
  };

  const setAuditLogValue = (auditLogs) => {
    try {
      const index = auditLogs.findIndex((obj) => obj[AUDIT_LOG_KEYS.CATEGORY] === Section_Status.Approved || obj[AUDIT_LOG_KEYS.CATEGORY] === Section_Status.Rejected);
      if (index !== -1 && index !== 0) {
        const movedItem = auditLogs.splice(index, 1)[0];
        auditLogs.unshift(movedItem);
      }
      setAuditLogs(auditLogs);
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="ar-lap-book-loan">
      <div style={{ display: 'flex', background: '#E5E5E8)' }}>
        <div style={{ paddingLeft: '22px', width: '14%' }}>
          <div>
            {renderNavItem('Applicant Details')}
            {renderNavItem('Entity Details')}
            {renderNavItem('Co-Borrower Details')}
            {renderNavItem('Guarantor Details')}
            {showShareHolding && renderNavItem('Share Holding Pattern')}
            {renderNavItem('Property Details')}
            {renderNavItem('Financial Docs')}
            {renderNavItem('Additional Docs')}
            {renderNavItem('Offer')}
          </div>
        </div>
        <div
          style={{
            backgroundColor: 'var(--neutrals-neutral-10, #E5E5E8)',
            width: '1px',
            marginTop: '1%',
          }}
        ></div>
        <div style={{ width: '86%' }}>
          {type == 'edit' && props.leadComment[`${navState}`] && navState !== 'Co-Borrower Details' && navState !== 'Guarantor Details' && <LogViewer head="Credit Manager Comment" body={props.leadComment[`${navState}`]} />}
          <div style={{ display: 'flex' }}>
            <div style={{ marginTop: '15px', width: '100%' }}>
              {isLoading && <Preloader />}
              {isRejected && <RejectionScreen />}
              {navState === 'Entity Details' ? (
                <EntityDetailsForm
                  type={((navIconPrefixState['Entity Details'] == 'success' || navIconPrefixState['Entity Details'] == 'deviation') && leadStatus == 'draft') || (props?.loanSectionObject?.['entity']?.section_status != 'in_progress' && leadStatus == LeadNewStatus.Pending) ? 'view' : type}
                  setNavState={setNavState}
                  setNavIconPrefixState={setNavIconPrefixState}
                  loanAppId={loanAppId}
                  entityData={entityData}
                  setEntityData={setEntityData}
                  navIconPrefixState={navIconPrefixState}
                  LAPCompanyId={LAPCompanyId}
                  LAPProductId={LAPProductId}
                  leadStatus={leadStatus}
                  setLeadStatus={setLeadStatus}
                  loanDetailsSubsectionStatus={props.loanDetailsSubsectionStatus}
                  setShowShareHolding={(value) => {
                    setShowShareHolding(value);
                  }}
                  documents={props.customerDocs}
                  loanDetailsStatus={props.loanDetailsStatus}
                  setShouldFetch={props.setShouldFetch}
                />
              ) : null}
              {navState === 'Applicant Details' && !isLoading && !isRejected ? (
                <ApplicantDetailsForm
                  type={((navIconPrefixState['Applicant Details'] == 'success' || navIconPrefixState['Applicant Details'] == 'deviation') && leadStatus == 'draft') || (props?.loanSectionObject?.['primary']?.section_status != 'in_progress' && leadStatus == LeadNewStatus.Pending) ? 'view' : type}
                  setNavState={setNavState}
                  setStatusCheck={setStatusCheck}
                  statusCheck={statusCheck}
                  setNavIconPrefixState={setNavIconPrefixState}
                  loanAppId={loanAppId}
                  setLoanAppId={setLoanAppId}
                  applicantData={applicantData}
                  setApplicantData={setApplicantData}
                  navIconPrefixState={navIconPrefixState}
                  setLAPCompanyId={setLAPCompanyId}
                  setLAPProductID={setLAPProductID}
                  LAPCompanyId={LAPCompanyId}
                  LAPProductId={LAPProductId}
                  documents={props.customerDocs}
                  leadStatus={leadStatus}
                  setLeadStatus={setLeadStatus}
                  loanDetailsSubsectionStatus={props.loanDetailsSubsectionStatus}
                  loanDetailsStatus={props.loanDetailsStatus}
                  setShouldFetch={props.setShouldFetch}
                  entityDetailsNavItemShow={entityDetailsNavItemShow}
                  setEntityDetailsNavItemShow={setEntityDetailsNavItemShow}
                  setShouldFetchDocument={props.setShouldFetchDocument}
                />
              ) : null}
              {navState === 'Co-Borrower Details' && !isLoading && !isRejected ? coBorrowerArray?.map(renderCoBorrowerDetail) : null}
              {navState === 'Guarantor Details' && !isLoading && !isRejected ? guarantorArray?.map(renderGuarantorDetails) : null}
              {navState === 'Share Holding Pattern' && !isLoading && !isRejected && <ShareHoldingPatternForm loanAppId={loanAppId} LAPCompanyId={LAPCompanyId} LAPProductId={LAPProductId} setNavState={setNavState} type={getViewType('Share Holding Pattern', 'share_holding')} setNavIconPrefixState={setNavIconPrefixState} setShouldFetch={props.setShouldFetch} />}
              {navState === 'Property Details' && !isLoading && !isRejected ? (
                <PropertyDetailsForm
                  navIconPrefixState={navIconPrefixState}
                  type={((navIconPrefixState['Property Details'] == 'success' || navIconPrefixState['Property Details'] == 'deviation') && leadStatus == 'draft') || (props?.loanSectionObject?.['property_doc']?.section_status != 'in_progress' && leadStatus == LeadNewStatus.Pending) ? 'view' : type}
                  LAPCompanyId={LAPCompanyId}
                  LAPProductId={LAPProductId}
                  applicantData={applicantData}
                  loanAppId={loanAppId}
                  setNavIconPrefixState={setNavIconPrefixState}
                  setNavState={setNavState}
                  setShouldFetch={props.setShouldFetch}
                  leadStatus={leadStatus}
                  setPropertyData={setPropertyData}
                  propertyData={propertyData}
                />
              ) : null}
              {navState === 'Financial Docs' && !isLoading && !isRejected ? (
                <FinancialDocument
                  type={((navIconPrefixState['Financial Docs'] == 'success' || navIconPrefixState['Financial Docs'] == 'deviation') && leadStatus == 'draft') || (props?.loanSectionObject?.['financial_doc']?.section_status != 'in_progress' && leadStatus == LeadNewStatus.Pending) ? 'view' : type}
                  setNavIconPrefixState={setNavIconPrefixState}
                  setNavState={setNavState}
                  loanAppId={loanAppId}
                  LAPCompanyId={LAPCompanyId}
                  LAPProductId={LAPProductId}
                  navIconPrefixState={navIconPrefixState}
                  documents={props.customerDocs}
                  loanDetailsStatus={props.loanDetailsStatus}
                  setShouldFetch={props.setShouldFetch}
                />
              ) : null}
              {navState === 'Offer' && !isLoading && !isRejected ? <OfferPage loanAppId={loanAppId} companyId={LAPCompanyId} productId={LAPProductId} leadStatus={leadStatus} setNavState={setNavState} setIsRejected={setIsRejected} /> : null}
              {navState === 'Additional Docs' && !isLoading && !isRejected ? (
                <AdditionalDocuments
                  type={((navIconPrefixState['Additional Docs'] == 'success' || navIconPrefixState['Additional Docs'] == 'deviation') && leadStatus == 'draft') || (props?.loanSectionObject?.['additional_doc']?.section_status != 'in_progress' && leadStatus == LeadNewStatus.Pending) ? 'view' : type}
                  rawType={type}
                  setNavIconPrefixState={setNavIconPrefixState}
                  setNavState={setNavState}
                  loanAppId={props.loanAppId ?? loanAppId}
                  companyId={LAPCompanyId}
                  LAPProductId={LAPProductId}
                  navIconPrefixState={navIconPrefixState}
                  additionalDocument={additionalDocuments}
                  documents={props?.customerDocs}
                  leadStatus={props?.leadStatus}
                  loanDetailsStatus={props.loanDetailsStatus}
                  setShouldFetch={props.setShouldFetch}
                />
              ) : null}
            </div>
          </div>
        </div>
        {auditLogs && !isRejected && props.leadStatus != LeadNewStatus.Draft && props.isEditBookLoan && <AuditLog data={auditLogs} />}
      </div>
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}
