import React, { useState, useEffect } from 'react';
import Table from 'react-sdk/dist/components/Table';
import Button from 'react-sdk/dist/components/Button';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import searchIcon from '../../msme/images/searchIcon.svg';
import { useDispatch } from 'react-redux';
import { getfldgWatcher } from '../../actions/fldg';
import { storedList } from '../../util/localstorage';
import SelectCompany from '../../components/Company/SelectCompany';
import { getfldgPartnerWatcher } from '../../actions/fldg/fldg';
import edit from '../../assets/img/edit.svg';
import FLDGPopup from '../../../../reactjs/src/views/fldg/FLDGPopup';
import { AlertBox } from 'components/CustomAlertbox';
import { checkAccessTags } from '../../../src/util/uam';

const user = { ...storedList('user') };

const inputBoxCss = {
  marginTop: '0px',
  marginLeft: '2px',
  maxHeight: '500px',
  zIndex: 5,
  padding: '0px 16px',
};

const tableStyle = {
  width: '99%',
  height: 'auto',
  display: 'grid',
  overflowX: 'hidden',
  marginLeft: '0px',
  color: '#161719',
  gridTemplateColumns: '12.8% 12.2% 12.2% 12.2% 12.2% 12.2% 12.2% 12.1%',
};

const buttonClass = {
  height: '30px',
  width: '90px',
  borderRadius: '8px',
  marginLeft: '28px',
  fontSize: '16px',
  boxSizing: 'content-box',
};

const inputDiv = {
  display: 'flex',
  width: '99%',
  fontFamily: 'MontSerrat-Regular',
};
const inputDivParent = {
  display: 'flex',
  width: '99%',
};

const fldgDiv = {
  padding: '10px',
};

const searchDiv = {
  height: '52px',
  width: '52px',
  marginLeft: '10px',
};
const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

const Fldg = () => {
  const dispatch = useDispatch();
  const [fldgDataList, setFldgDataList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [company, setCompany] = useState('');
  const [companyDetails, setcompanyDetails] = useState([]);

  const [editValue, setEditValue] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [open, setOpen] = useState(false);
  const [initialState, setInitialState] = useState(true);
  const [datamsg, setDatamsg] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleSubmit = () => {
    setCompany('');
    dispatch(
      getfldgPartnerWatcher(
        {
          user: user,
        },
        async (selectedcompany) => {
          const companiesData = selectedcompany.data;
          await setcompanyDetails(companiesData);
        },
        (error) => {},
      ),
    );

    setInitialState(false);
    setOpen(true);
  };
  const handleClose = () => {
    setCompany('');
    setOpen(false);
  };

  useEffect(() => {
    getFldgData();
  }, [open]);

  const buttonCss = {
    boxShadow: 'none',
    padding: '0px',
  };

  const getFldgData = () => {
    const payload = {
      company_id: user?.company_id || company?.value || 'null',
      user_id: user?._id || null,
      page: page,
      limit: 10,
    };
    new Promise((resolve, reject) => {
      dispatch(getfldgWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setCount(response?.data?.count);
        setFldgDataList(
          response?.data?.rows.map((item, index) => ({
            partner_name: item?.company?.name,
            fldg: item?.fldg_perc,
            fldg_amount: item?.amount,
            old_amount: item?.ocd_amount,
            total_coverage_amount: item?.total_coverage_amount,
            afipl_coverage_amount: item?.afipl_coverage_amount,
            p2p_coverage_amount: item?.p2p_coverage_amount,
            edit: (
              <div>
                <Button label="" customStyle={buttonCss} onClick={() => handleEdit(item)} isDisabled={isTagged ? !checkAccessTags(['tag_operations_fldg_r']) || !checkAccessTags(['tag_operations_fldg_w']) : true} imageButtonHover={edit} imageButton={edit} iconButton="btn-secondary-download-button" buttonType="" />
              </div>
            ),
          })),
        );
      })
      .catch((error) => {
        showAlert(error.response.data.message, 'error', '');
      });
  };

  const handleEdit = (item, index) => {
    setEditValue(item);
    setInitialState(true);
    setOpen(true);
  };

  const handleCompanySearch = (event) => {
    getFldgData();
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const showAlert = (msg, type, datamsg) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setDatamsg(datamsg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  return (
    <div style={fldgDiv}>
      <div style={inputDivParent}>
        <div style={inputDiv}>
          <SelectCompany
            label_name="Partners"
            placeholder="Partner"
            company={company}
            onCompanyChange={(value) => {
              setCompany(value);
            }}
            customStyle={{
              marginTop: '8px',
              zIndex: '1',
              width: '106%',
              fontSize: '17px',
              fontFamily: 'Montserrat-Regular',
            }}
            height="56px"
            width="290px"
          />
          <div style={searchDiv} className="search-button">
            <img onClick={handleCompanySearch} src={searchIcon} alt="search" style={{ height: '24px', width: '48px' }} />
          </div>
        </div>
        <Button buttonType="primary" customStyle={buttonClass} label="Add New" onClick={handleSubmit} isDisabled={isTagged ? !checkAccessTags(['tag_operations_fldg_r']) || !checkAccessTags(['tag_operations_fldg_w']) : true} />
      </div>
      <Table
        customStyle={tableStyle}
        customCellCss={{ width: 'fit-content', marginLeft: '12px' }}
        columns={[
          { id: 'partner_name', label: 'PARTNER NAME' },
          { id: 'fldg', label: 'FLDG %' },
          { id: 'fldg_amount', label: 'FLDG AMOUNT' },
          { id: 'old_amount', label: 'OCD AMOUNT' },
          { id: 'total_coverage_amount', label: 'TOTAL COVERAGE AMOUNT' },
          { id: 'afipl_coverage_amount', label: 'AFIPL COVERAGE AMOUNT' },
          { id: 'p2p_coverage_amount', label: 'P2P COVERAGE AMOUNT' },
          { id: 'edit', label: 'EDIT ' },
        ]}
        data={fldgDataList}
      />
      <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={rowsPerPage} />
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} data={datamsg} /> : null}
      {open ? <FLDGPopup handleClose={handleClose} select={companyDetails} handleAlertClose={handleAlertClose} showalert={showAlert} singlerow={initialState === false ? [] : editValue} /> : null}
    </div>
  );
};

export default Fldg;
