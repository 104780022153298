export const useAccordionStyles = () => {
  return {
    accordionHeader: {
      fontFamily: 'Montserrat-Bold',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#6B6F80',
    },
    accordionBody: {
      fontFamily: 'Montserrat-Regular',
      fontSize: '16px',
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#151515',
    },
    leadReviewBottomComponent: {
      paddingBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px',
    },
    customStyleButton: {
      backgroundColor: '#008042',
      color: 'white',
      height: '40px',
      width: '120px',
      fontSize: '13px',
      padding: '12px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    customStyleButtonDisabled: {
      backgroundColor: '#CCCDD3',
      color: 'white',
      height: '40px',
      width: '120px',
      fontSize: '13px',
      padding: '12px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    sectionStatusBadgeContainer: {
      marginTop: '7px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    negativeButton: {
      background: '#FFFFFF',
      color: '#475BD8',
      border: '1px solid #475BD8',
    },
    button: {
      height: '40px',
      width: '200px',
      borderRadius: '20px',
      marginLeft: '16px',
      fontSize: '14px',
      padding: 0,
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: '#475BD8',
      color: '#FFF',
      fontFamily: 'Montserrat-Regular',
    },
    buttonLoader: {
      border: '3px solid white',
      borderTop: '3px solid transparent',
      marginLeft: '40%',
    },
    leadReviewBottomComponent: {
      paddingBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '20px',
    },
    customStyleButton: {
      backgroundColor: '#008042',
      color: 'white',
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    customStyleButtonComment: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      backgroundColor: '#FFF',
      borderColor: '#134CDE',
      color: '#134CDE',
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    customStyleDisabledButtonComment: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      backgroundColor: '#FFF',
      borderColor: '#767888',
      color: '#767888',
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    customStyleButtonDanger: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      backgroundColor: '#FFF',
      borderColor: '#cc0000',
      color: '#cc0000',
      height: '40px',
      width: 'max-content',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '8px 24px',
      borderRadius: '40px',
      gap: '10px',
    },
    leadReviewEditViewContainer: {
      width: '66px',
      alignSelf: 'center',
      fontSize: '14px',
      fontFamily: 'Montserrat-Medium',
      color: '#134CDE',
      cursor: 'pointer',
    },
    button: {
      height: '48px',
      width: 'max-content',
      borderRadius: '40px',
      gap: '10px',
      fontSize: '16px',
      lineHeight: '24px',
      padding: '12px 24px',
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: '#475BD8',
      color: '#FFF',
      fontFamily: 'Montserrat-Regular',
    },
    buttonLoader: {
      border: '3px solid white',
      borderTop: '3px solid transparent',
      marginLeft: '40%',
    },
  };
};
