import { takeLatest, call, put } from 'redux-saga/effects';
import { getLapActivityLogsApi, getLapLeadReviewDetailsApi, updateLeadDetailsApi, commentdetailsApi, getLeadStatusApi, getLeadOfferApi, getCalculateFeesAndChargesWatcherApi, postAadhaarOtpApi, createLapActivityLogApi, getLapLeadDocumentApi, getSoftOfferApi } from '../apis/lead.api';

export function* getLapLeadReviewDetailsEffectSaga(action) {
  try {
    const response = yield call(getLapLeadReviewDetailsApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* getLapDetailsWatcherSaga() {
  yield takeLatest('GET_LAP_LEAD_REVIEW_DETAILS', getLapLeadReviewDetailsEffectSaga);
}

export function* getLapActivityLogsEffectSaga(action) {
  try {
    const response = yield call(getLapActivityLogsApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* getLapActivityLogsWatcherSaga() {
  yield takeLatest('GET_LAP_ACTIVITY_LOGS', getLapActivityLogsEffectSaga);
}

export function* updateLeadDetailsEffectSaga(action) {
  try {
    const response = yield call(updateLeadDetailsApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* updateLapLeadDetailsWatcherSaga() {
  yield takeLatest('UPDATE_LAP_LEAD_STATUS', updateLeadDetailsEffectSaga);
}
export function* commentdetailEffectSaga(action) {
  try {
    const response = yield call(commentdetailsApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}
export function* commentLapDetailsWatcherSaga() {
  yield takeLatest('COMMENT_LAP_UPDATE_DETAILS', commentdetailEffectSaga);
}

export function* getLeadStatusEffectSaga(action) {
  try {
    const response = yield call(getLeadStatusApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}
export function* getLapLeadStatusWatcherSaga() {
  yield takeLatest('FETCH_LAP_LEAD_STATUS', getLeadStatusEffectSaga);
}

export function* getLeadOfferEffectSaga(action) {
  try {
    const response = yield call(getLeadOfferApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* getLapLeadOfferWatcherSaga() {
  yield takeLatest('FETCH_LAP_LEAD_OFFER', getLeadOfferEffectSaga);
}

export function* getCalculateFeesAndChargesWatcherEffectSaga(action) {
  try {
    const response = yield call(getCalculateFeesAndChargesWatcherApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* postAadhaarOtpEffectSaga(action) {
  try {
    const response = yield call(postAadhaarOtpApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* getLapCalculateFeesAndChargesWatcherWatcherSaga() {
  yield takeLatest('FETCH_LAP_FEES_AND_CHARGES_DETAILS', getCalculateFeesAndChargesWatcherEffectSaga);
}

export function* postLapAadhaarOtpWatcherSaga() {
  yield takeLatest('POST_LAP_AADHAAR_OTP', postAadhaarOtpEffectSaga);
}

export function* createLapActivityLogEffectSaga(action) {
  try {
    const response = yield call(createLapActivityLogApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* createLapActivityLogWatcherSaga() {
  yield takeLatest('CREATE_LAP_ACTIVITY_LOG', createLapActivityLogEffectSaga);
}
export function* getLapLeadDocumentEffectSaga(action) {
  try {
    const response = yield call(getLapLeadDocumentApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* getLapLeadDocumentWatcherSaga() {
  yield takeLatest('FETCH_LAP_LEAD_DOCUMENT', getLapLeadDocumentEffectSaga);
}

//#region [Soft Offer Saga]
export function* postLeadSoftOfferEffectSaga(action) {
  try {
    const response = yield call(getSoftOfferApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}

export function* postLeadSoftOfferWatcherSaga() {
  yield takeLatest('POST_LAP_LEAD_SOFT_OFFER', postLeadSoftOfferEffectSaga);
}
//#endregion
