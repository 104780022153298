import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import DropdownWithBtn from '../../components/DropdownWithBtn/DropDownWithBtn';
import UploadFileInput from '../../components/uploadFileInput/UploadFileInput';
import SlidingAlert from '../../components/SlidingAlert/SlidingAlert';
import { SHAREHOLDING } from './uploadKycData';
import { storedList } from '../../../util/localstorage';
import { trimText, generateRandomId } from '../../utils/helper'
import { getBookLoanDetailsWatcher, getLapLoanDocumentsWatcher } from '../../actions/bookLoan.action';
import { getSubmitButton, getSaveAsDraftButton } from '../../util/resuableJsx/reusableJsx';
import { patchLapDetailsWatcher } from '../../actions/lap.action';
import { Application_Mode, Section_Status, shareHoldingPattern } from '../../constants/string_constants.js';
import { ErrorConstants } from '../../constants/error_constants';
import { Shareholding_Label } from '../../constants/string_constants';
// import css file
import './ShareHoldingPatternForm.css';

export default function ShareHoldingPatternForm({ loanAppId, LAPCompanyId, LAPProductId, setNavState, type, setNavIconPrefixState }) {
	const sectionName = 'share-holding-details';
	const subSectionCode = 'share_holding_section_submit';
	const dispatch = useDispatch();
	const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
	const [formDisabled, setFormDisabled] = useState(false);
	const [disableAddShareHolderButton, setDisableAddShareHolderButton] = useState(false);
	const [shareHolderSelectData, setShareHolderSelectData] = useState([]);
	const [shareHolderSelectDataTemp, setShareHolderSelectDataTemp] = useState([]);
	// #region Share holding pattern TABLE form states
	const [validationData, setValidationData] = useState({});
	const [rowData, setRowData] = React.useState([])
	const [totalPercent, setTotalPercent] = React.useState(0);
	// #endregion
	// #region Document states
	const [documentStateData, setDocumentStateData] = useState({
		share_holding_certificate_value: false,
	});
	const [documentUploadEnable, setDocumentUploadEnable] = useState(true)
	const [verifyNextButtonDisable, setVerifyNextButtonDisable] = useState(true)
	const [shareHoldingPatternItem, setShareHoldingPatternItem] = useState(SHAREHOLDING);
	// #endregion
	// #region Submit button states
	const [sectionStatusCheck, setSectionStatusCheck] = useState('');
	// #endregion
	// #region Alert states
	const [alert, setAlert] = useState(false);
	const [severity, setSeverity] = useState('success');
	const [alertMessage, setAlertMessage] = useState('');
	// #endregion
	const MAGIC_NUMBERS = { ZERO: 0, FIFTEEN: 15, HUNDRED: 100 }
	// #region get loan details, set select shareholding data and post share holding details functionalities
	const formateShareHoldingSelectData = (response) => {
		response = response.data[MAGIC_NUMBERS.ZERO]
		const rowData = [];
		if (response) {
			let first_name = response?.first_name ? response?.first_name : '';
			let middle_name = response?.middle_name ? response?.middle_name : '';
			let last_name = response?.last_name ? response?.last_name : '';
			rowData.push({
				name: trimText(`${first_name} ${middle_name} ${last_name}`),
				value: response.borrower_id,
			})
		}
		if (response.coborrower.length > 0) {
			const coborrowers = response.coborrower.map((item) => {
				if (item) {
					return {
						name: trimText(`${item.cb_fname} ${item.cb_mname} ${item.cb_lname}`),
						value: item.borrower_id,
					};
				}
			});
			rowData.push(...coborrowers);
		}
		if (response.guarantor.length > 0) {
			let guarantors = response.guarantor.map((item) => {
				return {
					name: trimText(`${item.gua_fname} ${item.gua_mname} ${item.gua_lname}`),
					value: item.borrower_id,
				};
			});
			rowData.push(...guarantors);
		}
		setShareHolderSelectData(rowData)
		setShareHolderSelectDataTemp(rowData)
	};
	const fetchLoanDetails = () => {
		new Promise((resolve, reject) => {
			dispatch(
				getBookLoanDetailsWatcher(
					{
						loan_app_id: loanAppId,
						user: user,
					},
					resolve,
					reject,
				),
			);
		})
			.then((response) => {
				if (response?.data[MAGIC_NUMBERS.ZERO]?.share_holders && response?.data[MAGIC_NUMBERS.ZERO]?.share_holders?.length) {
					let shareHolderData = response?.data[MAGIC_NUMBERS.ZERO].share_holders;
					let rowData = [];
					shareHolderData.forEach((item, index) => {
						rowData.push({
							id: item.borrower_id,
							index,
							name: item.share_holder_name,
							value: item.share_holder_perc,
						});
					});
					setRowData(rowData);
				}
				formateShareHoldingSelectData(response);
			})
			.catch((error) => {
				showAlert('Something went wrong.', 'error');
			});
	};
	const getLoanDocuments = () => {
		const payload = {
			loanAppID: loanAppId,
			companyId: LAPCompanyId,
			productId: LAPProductId,
			user: user,
		};
		new Promise((resolve, reject) => {
			dispatch(getLapLoanDocumentsWatcher(payload, resolve, reject));
		}).then((response) => {
			let shareHoldingDocument = response?.filter(el => el.file_type === shareHoldingPattern)[MAGIC_NUMBERS.ZERO]
			if (shareHoldingDocument) {
				setShareHoldingPatternItem((preState) => {
					return preState.map((el) => {
						return {
							...el,
							s3_url: shareHoldingDocument.file_url,
							doc: shareHoldingDocument,
						};
					})
				})
			}
			if (type === Application_Mode.View) {
				setDocumentUploadEnable(false)
			}

		})
	}
	const validateForm = () => {
		// Check if any row data has empty value and name field if it is there then update the validationData
		let emptyRowData = rowData.filter((item) => {
			if (item.name == '' || item.value == '' || item.value == 0 || item.value > MAGIC_NUMBERS.HUNDRED) {
				setValidationData((preState) => {
					return {
						...preState,
						[`name_vl_${item.id}_State`]: item.name === '' ? 'has-danger' : 'has-success',
						[`value_vl_${item.id}_State`]: item.value == '' || item.value == 0 || item.value > MAGIC_NUMBERS.HUNDRED ? 'has-danger' : 'has-success',
					}
				});
				return true;
			} else {
				return false;
			}
		});
		// totalPercent !== MAGIC_NUMBERS.HUNDRED
		if (emptyRowData.length > 0) {
			showAlert(ErrorConstants.PLEASE_CHECK_ERROR_IN_THE_FIELDS, 'error');
			return false;
		} else {
			return true;
		}
	}
	const postShareHoldingDetails = (draft = false, is_del = false, share_holder_data = [], deleteIconClick = false) => {
		if (draft && !deleteIconClick) {
			// Check if any row data value or name is empty
			let emptyRowData = rowData.filter(item => item.name === '' || item.value === '');
			if (emptyRowData.length > 0) {
				showAlert(ErrorConstants.PLEASE_CHECK_ERROR_IN_THE_FIELDS, 'error');
				return;
			}
		}
		const payload = {
			loan_app_id: loanAppId,
			section: sectionName,
			lap_company_id: LAPCompanyId,
			lap_product_id: LAPProductId,
			user_id: user._id ? user._id : '',
			section_sequence_no: 700,
			isSave: draft,
		};
		if (is_del) {
			payload.share_holders = share_holder_data.map((item) => {
				return {
					borrower_id: item.id,
					share_holder_name: item.name,
					share_holder_perc: item.value,
				}
			})
		} else {
			payload.share_holders = rowData.map((item) => {
				if (item.new) {
					return {
						borrower_id: generateRandomId(),
						share_holder_name: item.name,
						share_holder_perc: item.value,
					};
				} else {
					return {
						borrower_id: item.id,
						share_holder_name: item.name,
						share_holder_perc: item.value,
					}
				}
			})
		};
		if (!draft) {
			setSectionStatusCheck(Section_Status.In_Progress)
			payload.sub_section_code = subSectionCode
		}
		new Promise((resolve, reject) => {
			dispatch(patchLapDetailsWatcher(payload, resolve, reject));
		})
			.then((response) => {
				if (draft && !deleteIconClick) {
					fetchLoanDetails();
					showAlert('Draft Saved Successfully' || 'Success', 'success');
				} else {
					if (!deleteIconClick) {
						showAlert(response?.message || 'Success', 'success');
					}
					setSectionStatusCheck('')
				}
			})
			.catch((error) => {
				setSectionStatusCheck('')
				showAlert(error?.response?.data?.message, 'error');
			});
	};
	const handleSaveAsDraft = async () => {
		if (validateForm()) {
			await postShareHoldingDetails(true)
		}
	}
	const handleSubmitBtn = async () => {
		// check if any row data value or name is empty
		if (validateForm()) {
			if (totalPercent !== MAGIC_NUMBERS.HUNDRED) {
				showAlert(ErrorConstants.TOTAL_PERCENTAGE_SHOULD_BE_100, 'error');
				setFormDisabled(false);
				return;
			}
			await postShareHoldingDetails()
			setNavIconPrefixState((prevState) => ({
				...prevState,
				'Share Holding Pattern': 'deviation'
			}));
			setTimeout(() => setNavState('Property Details'), 3000)
		}
	}
	// #endregion
	// #region Get the loan documents while the component is mounted
	useEffect(() => {
		//#region Get the loan documents
		// getLoanData()
		fetchLoanDetails();
		getLoanDocuments();
		//#endregion
	}, []);
	// #endregion
	// #region Sum total percentage based on rowData value change and show alert if total percent is greater than 100
	useEffect(() => {
		let sum = 0;
		rowData.forEach((item) => {
			if (item.name !== 'Total Percentage') {
				sum += parseFloat(item.value ? item.value : 0);
			}
			if (sum === MAGIC_NUMBERS.HUNDRED) {
				setValidationData((preState) => {
					return {
						...preState,
						[`name_vl_${item.id}_State`]: 'has-success',
						[`value_vl_${item.id}_State`]: item.value == '' ? 'has-danger' : 'has-success',
					}
				});
			}
		});
		// Check if rowData value and name is not empty
		setDisableAddShareHolderButton(rowData.length > MAGIC_NUMBERS.FIFTEEN);
		// set document upload enable if the total percent is less than 100
		setTotalPercent(sum);
		// Remove errors if total percentage is 100 in validateData
		if (sum === MAGIC_NUMBERS.HUNDRED) {

		}
	}, [rowData]);
	// #endregion
	// #region After document upload disable all the form fields and enable verify and next button
	useEffect(() => {
		let tableFormDisableState = documentStateData?.certificate ? true : false;
		setVerifyNextButtonDisable(tableFormDisableState)
	}, [documentStateData])
	// #endregion

	// #region Alert functions
	const handleAlertClose = () => {
		setAlert(false);
		setSeverity('');
		setAlertMessage('');
	};
	const showAlert = (msg, type) => {

		setAlert(true);
		setSeverity(type);
		setAlertMessage(msg);
		setTimeout(() => {
			handleAlertClose();
		}, 10000);
	};
	// #endregion

	// #region input validation and on change functions
	const inputValCheck = (id, type) => {
		return validationData[`${type}_vl_${id}_State`] === 'has-danger' ? true : false;
	}
	const inputValHelperText = (id, type, value = '') => {
		if (validationData[`${type}_vl_${id}_State`] === 'has-danger') {
			if (type === 'name') {
				let regex = /[^0-9]/g;
				if (regex.test(value)) {
					return ErrorConstants.SPECIAL_CHARACTERS_AND_NUMBERS_ARE_NOT_ALLOWED;
				} else if (value === '') {
					return ErrorConstants.PLEASE_ENTER_NAME;
				}
			} else if (type === 'value') {
				if (value == MAGIC_NUMBERS.ZERO) {
					return ErrorConstants.PLEASE_ENTER_VALID_PERC;
				}
				return value ? ErrorConstants.VALUE_EXCEEDED : ErrorConstants.VALUE_IS_REQUIRED;
			} else {
				return '';
			}
		} else {
			return '';
		}
	}
	const dropdownChange = (event) => {
		const { name, value } = event;
		const filterSelectList = shareHolderSelectDataTemp.filter(item => item.value != value);
		setShareHolderSelectDataTemp(filterSelectList)
		setRowData(preState => {
			return [...preState, { index: preState.length + 1, id: value, name: name, value: 0 }]
		})

	}
	const change = (event, type, field) => {
		let value = event.value;
		// Setting the row data after the validation
		setRowData(rowData.map((item) => {
			if (item.id === field.id) {
				item[type] = value;
			}
			return item;
		}));

		let errVal = value === '' ? 'has-danger' : 'has-success';
		if (type === 'name') {
			// Check if the name is a alphabet capital or small anything
			if (!value.match(/^[a-zA-Z\s]+$/)) {
				errVal = 'has-danger';
				value = '';
			} else {
				errVal = 'has-success';
			}
		} else if (type === 'value') {
			// Check if the value is a number and between 1 and 100
			if (value.match(/^[0-9]+$/)) {
				// Check if the numberVal is less that 100 and sum of all the rowData value is less that 100
				let sum = 0;
				// use reduce method to sum the rowData value
				rowData.forEach((item) => {
					if (item.value !== '') {
						sum += parseFloat((item.value || item.value !== '') ? item.value : 0);
					}
				});
				errVal = sum > MAGIC_NUMBERS.HUNDRED || value == MAGIC_NUMBERS.ZERO ? 'has-danger' : 'has-success';

			} else {
				value = '';
			}
		}

		// Setting the validation error after the validation
		setValidationData((preState) => {
			return {
				...preState,
				[`${type}_vl_${field.id}_State`]: errVal
			}
		});
	}
	// #endregion
	// #region Add and delete share holder operation
	const addNewShareHolder = () => {
		// Check if any row data value or name is empty
		let emptyRowData = rowData.filter(item => item.name === '' || item.value === '');
		rowData.forEach((item) => {
			setValidationData((preState) => {
				return {
					...preState,
					[`name_vl_${item.id}_State`]: item.name == '' ? 'has-danger' : 'has-success',
					[`value_vl_${item.id}_State`]: item.value == '' ? 'has-danger' : 'has-success',
				}
			})
		})
		if (emptyRowData.length > 0) {
			showAlert(ErrorConstants.PLEASE_CHECK_ERROR_IN_THE_FIELDS, 'error');
		} else {
			setRowData((preState) => {
				return [
					...preState,
					{
						index: `${preState.length + 1}`,
						// Generate unique random id for each row
						id: generateRandomId(),
						name: '',
						value: '',
						added: true
					}
				]
			});
		}

	}
	const deleteShareHolder = (row_id) => {
		const filteredRowData = rowData.filter(item => item.id != row_id).map((el, index) => {
			el.index = index + 1;
			return el
		})
		setRowData(filteredRowData);
		// #region Push the removed share holder to the dropdown list
		let deletedShareHolder = shareHolderSelectData.find(item => item.value == row_id);
		if (deletedShareHolder) {
			setShareHolderSelectDataTemp((preState) => {
				return [{ name: deletedShareHolder.name, value: row_id }, ...preState]
			});
		}
		postShareHoldingDetails(true, true, filteredRowData, true)
		// #endregion
	}
	// #endregion

	const renderShareHolderPercentageUi = (shareHolderDetails) => {
		return (
			<div key={shareHolderDetails.id} className='share-holding-table-row'>
				<div className='share-holding-table-name-cell'>
					{shareHolderDetails.added
						?
						<InputBox
							id={`${shareHolderDetails.id}_name_box`}
							label={'Enter Name'}
							regex={/^[a-zA-Z\s]+$/}
							isDrawdown={false}
							type={'text'}
							initialValue={shareHolderDetails.name ? shareHolderDetails.name : null}
							isDisabled={formDisabled || type === Application_Mode.View}
							onClick={(event) => change(event, 'name', shareHolderDetails)}
							customClass={{ height: '56px', width: '100%', maxWidth: '100%' }}
							customInputClass={{ minWidth: '100%', backgroundColor: '#fff' }}
							error={inputValCheck(shareHolderDetails.id, 'name')}
							helperText={inputValHelperText(shareHolderDetails.id, 'name', shareHolderDetails.name)}
						/>
						: shareHolderDetails.name}
				</div>
				<div className='share-holding-table-value-cell'>
					<InputBox
						id={`${shareHolderDetails.id}_number_box`}
						label={'Enter Value'}
						isDrawdown={false}
						regex={/^[0-9]+$/}
						type={'text'}
						initialValue={shareHolderDetails.value ? shareHolderDetails.value : null}
						isDisabled={formDisabled || type === Application_Mode.View}
						onClick={(event) => change(event, 'value', shareHolderDetails)}
						customClass={{ height: '56px', width: '100%', maxWidth: '100%' }}
						customInputClass={{ minWidth: '100%', backgroundColor: '#fff' }}
						error={inputValCheck(shareHolderDetails.id, 'value')}
						helperText={inputValHelperText(shareHolderDetails.id, 'value', shareHolderDetails.value)}
					/>
				</div>
				<div className='share-holding-table-action-cell'>
					{type !== Application_Mode.View &&
						(<svg style={{ cursor: 'pointer' }} onClick={() => deleteShareHolder(shareHolderDetails.id)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M15 4L20 4V6L18 6L18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20L3 20C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19L2 6H0L0 4L5 4L5 1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0L14 0C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6L4 6L4 18L16 18L16 6ZM7 9H9V15H7L7 9ZM11 9H13V15H11V9ZM7 2L7 4L13 4V2L7 2Z" fill="#CC0000" />
						</svg>)
					}
				</div>
			</div>
		);
	};

	return (
		<div className='share-holding-pattern-form-container'>
			{/* Heading */}
			<h4 className='share-holding-pattern-form-title'>{Shareholding_Label.header_label}</h4>
			{/* Share holder selection button */}
			{type !== Application_Mode.View && <DropdownWithBtn
				id='share_holder_selection'
				label='Select Shareholders'
				type='dropdown'
				isDrawdown={true}
				addBtnDisabled={disableAddShareHolderButton}
				options={shareHolderSelectDataTemp}
				initialValue={''}
				isBoxType={false}
				onClick={(event) => dropdownChange(event)}
				customDropdownClass={{
					zIndex: 1,
					marginTop: '2rem',
				}}
				customClass={{
					fontStyle: 'Montserrat',
					height: '56px',
					width: '40%',
					maxWidth: '100%',
				}}
				customInputClass={{
					minWidth: '100%',
					backgroundColor: '#fff',
				}}
				isDisabled={formDisabled}
				error={false}
				helperText={''}
			/>}
			{(rowData && rowData.length > 0 || type === Application_Mode.View) && (
				<>
					{/* Share holding Input table */}
					<div className='share-holding-table-container'>
						<div className='share-holding-table-header'>
							<div className='share-holding-table-header-name'>NAME</div>
							<div className='share-holding-table-header-value'>SHAREHOLDING PERCENTAGE</div>
							<div className='share-holding-table-header-action'>{type !== Application_Mode.View && 'Actions'}</div>
						</div>
						{rowData.sort((a, b) => parseInt(a.index) - parseInt(b.index)).map(renderShareHolderPercentageUi)}
						{/* Total Percentage row in table */}
						<div className={type !== 'view' ? 'share-holding-table-row' : 'share-holding-table-row border-curve'}>
							<div className='share-holding-table-name-cell'>
								Total Percentage
							</div>
							<div className='share-holding-table-value-cell'>
								<span className='share-holding-table-total-percent-value' style={{ color: totalPercent > 100 ? 'red' : 'var(--neutrals-neutral-100, #161719)' }}>
									{totalPercent}%
								</span>
							</div>
							<div className='share-holding-table-action-cell'></div>
						</div>
						{/* Add new Share holder button in table */}
						{type !== Application_Mode.View && (<div className={type !== Application_Mode.View ? 'share-holding-add-new-container' : 'border-curve share-holding-add-new-container'}>
							<button onClick={addNewShareHolder} disabled={disableAddShareHolderButton || formDisabled}>+ Add New Shareholder</button>
							<p>Please note that total Sharing holding percentage should be 100%. Please ensure to add all shareholders.</p>
						</div>)}
					</div>
					{/* Share holding pattern certificate upload */}
					<div className='share-holding-cerficate-upload-container'>
						<h5> Upload Shareholding Pattern Certificate</h5>
						<UploadFileInput
							customWidth='20rem'
							backgroundColorChange={true}
							backgroundColorBlur={!documentUploadEnable}
							items={shareHoldingPatternItem}
							title=""
							showAlert={showAlert}
							isXML={false}
							loanAppId={loanAppId}
							setDocumentStateData={setDocumentStateData}
							sectionName={sectionName}
							data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
							LAPCompanyId={LAPCompanyId}
							LAPProductId={LAPProductId}
							isChange={documentUploadEnable}
							type={type}
						/>
					</div>
					{/* Final submit button and save as draft button */}
					{type !== Application_Mode.View && (
						<div className='share-holding-final-submit-container'>
							{getSubmitButton('Verify & Next', handleSubmitBtn, sectionStatusCheck == 'inProgress' ? true : false, verifyNextButtonDisable, {})}
							{getSaveAsDraftButton('Save as Draft', handleSaveAsDraft, false)}
						</div>
					)}
				</>
			)}

			{alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
		</div>
	)
};