import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function p2pLoans(payload) {
  return axios.post(`${BASE_URL}fetch-co-lender-p2p-loans`, payload);
}

export function p2pCompanies() {
  return axios.get(`${BASE_URL}p2p-company`);
}

export function p2pPennyDropReprocess(payload) {
  return axios.post(`${BASE_URL}penny-drop-reprocess/${payload.loan_id}`, payload);
}

export function p2pPennyDropReject(payload) {
  return axios.post(`${BASE_URL}penny-drop-reject/${payload.loan_id}`, payload);
}
