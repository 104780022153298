export function locDrawdownGetLineDetailsWatcher(data, resolve, reject) {
  return {
    type: 'LOC_DRAWDOWN_GET_LINE_DETAILS',
    payload: data,
    resolve,
    reject,
  };
}

export function locDrawdownCloseLineWatcher(data, resolve, reject) {
  return {
    type: 'LOC_DRAWDOWN_CLOSE_LINE',
    payload: data,
    resolve,
    reject,
  };
}

export function locDrawdownDownloadNOCWatcher(data, resolve, reject) {
  return {
    type: 'LOC_DRAWDOWN_DOWNLOAD_NOC',
    payload: data,
    resolve,
    reject,
  };
}
