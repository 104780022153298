import axios from 'axios';
import { BASE_URL } from '../../constants/apiUrls';

export function getLapLeadReviewDetailsApi(payload) {
  return axios.get(`${BASE_URL}lap/lead/${payload.loanAppId}/review`, {
    params: payload,
  });
}

export function updateLeadDetailsApi(payload) {
  return axios.put(`${BASE_URL}lap/lead/${payload.loanAppId}/status_update/${payload.status}`, payload);
}

export function getLapActivityLogsApi(payload) {
  return axios.get(`${BASE_URL}lap/activity-logs/${payload.loanAppId}/${payload.category}`, {
    params: payload,
  });
}
export function commentdetailsApi(payload) {
  return axios.post(`${BASE_URL}lap/add-comment`, payload);
}

export function getLeadStatusApi(payload) {
  return axios.get(`${BASE_URL}lap/lead/${payload.loan_app_id}/section`, {
    params: payload,
  });
}

export function getLeadOfferApi(payload) {
  return axios.post(`${BASE_URL}lap/lead/${payload.loan_app_id}/offer`, { payload });
}

export function getCalculateFeesAndChargesWatcherApi(payload) {
  return axios.post(`${BASE_URL}lap/loan/calculateFeesAndCharges`, payload);
}

export function postAadhaarOtpApi(payload) {
  return axios.post(`${BASE_URL}lap/leads/okyc-aadhar-otp`, { payload });
}

export function createLapActivityLogApi(payload) {
  return axios.post(`${BASE_URL}lap/activity-logs`, payload);
}

export function getLapLeadDocumentApi(payload) {
  return axios.get(`${BASE_URL}lap/lead/${payload.loan_app_id}/document`, {
    params: payload,
  });
}

//#region [Soft Offer Generation]
export function getSoftOfferApi(payload) {
  return axios.post(`${BASE_URL}lap/lead/${payload.loan_app_id}/soft-offer`, { payload });
}
//#endregion
