import PropTypes, { number } from 'prop-types';
import UPLOAD_FILE_INPUT_CONST from '../uploadFileInput/helper';
import { getFileTypeErrorMsg } from './helper';
import { convertImagesToPdf } from '../uploadFileInput/util/helper';
import { Number_Constants } from '../../constants/number_constants';
import { useDispatch, useSelector } from 'react-redux';
import { uploadLoanDocumentsWatcher } from 'actions/loanDocuments';
import ViewDocumentCustom from '../document/ViewDocumentCustom';
import { storedList, saveToStorage } from '../../../util/localstorage';
import React, { useState, useEffect, useRef } from 'react';
import './UploadAndViewFileInput.scss';

export function UploadAndViewFileInput({ documentDetails, onUploadCallback, showAlert, loanAppId, LAPCompanyId, LAPProductId, sectionName, data, borrowerId = '', isXML = false, maxFileLimit = 5e6, maxFileLimitAsStr = '5MB', shouldRestStateOnUploadCallback = false }) {
  let { company_id, product_id } = data;
  const user = storedList('user');
  let fileInputRefs = {};
  const dispatch = useDispatch();
  const { isDisabled, id, acceptFileType = '', fileType, name, fileSize, s3_url, file_type, documentCode, docIndex } = documentDetails;
  const { SIZE, FILE_SIZE_ERROR_PREFIX, FILE_TYPE, ERROR } = UPLOAD_FILE_INPUT_CONST;

  const defaultDocDetailsState = {
    reuploadFile: false,
    uploadFileName: '',
    uploadFileBase64: '',
    buttonState: 'button',
    documentCode: documentCode,
  };
  const [currentDocType, setCurrentDocType] = useState('');
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [currentDocUrl, setCurrentDocUrl] = useState('');
  const [fileTitle, setFileTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [docDetailsState, setDocDetailsState] = useState(defaultDocDetailsState);
  const handleInputBoxClick = (id) => {
    fileInputRefs[id].click();
  };

  async function docUpload(fileData, loanAppId) {
    const dataForUpload = {
      submitData: {
        base64pdfencodedfile: fileData['uploadFileBase64'],
        fileType: fileData['uploadFileName'],
        code: fileData['code'],
        loan_app_id: loanAppId,
        borrower_id: borrowerId,
      },
      userData: {
        company_id: company_id,
        product_id: product_id,
        user_id: user._id,
      },
    };

    if (fileData.doc_index != null) {
      dataForUpload['submitData']['doc_index'] = fileData.doc_index;
    }

    try {
      await dispatch(
        uploadLoanDocumentsWatcher(
          dataForUpload,
          (response) => {
            onUploadCallback();
            if (shouldRestStateOnUploadCallback) {
              setDocDetailsState(defaultDocDetailsState);
            }
          },
          (error) => {
            showAlert(error?.response?.data?.message, ERROR);
          },
        ),
      );
    } catch (error) {}
  }
  const handleFileInputChange = (event, currId, docCode, docIndex) => {
    const file = event.target.files[0];
    if (file) {
      const selectedFile = event?.target?.files;
      const fileName = selectedFile[Number_Constants.ZERO]['name'];
      const fileExtension = fileName.split('.').pop();
      const fileExtensionInLowerCase = fileExtension.toLowerCase();
      const expectedFileTypes = acceptFileType.replaceAll('.', '').split(', ');
      if (!expectedFileTypes.includes(fileExtensionInLowerCase)) {
        const errorMessage = getFileTypeErrorMsg(acceptFileType);
        showAlert(errorMessage, ERROR);
        return;
      } else if (selectedFile[Number_Constants.ZERO][SIZE] > maxFileLimit) {
        showAlert(`${FILE_SIZE_ERROR_PREFIX} ${maxFileLimitAsStr}`, ERROR);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        let base64Data = reader.result.split(',')[Number_Constants.ONE];
        if ([FILE_TYPE.png, FILE_TYPE.jpeg, FILE_TYPE.jpg].includes(fileExtension)) {
          base64Data = convertImagesToPdf(base64Data);
        }
        const uploadFileName = file.name;
        setDocDetailsState({
          reuploadFile: true,
          uploadFileName,
          uploadFileBase64: base64Data,
          buttonState: 'button',
          documentCode: documentCode,
        });
        const uploadedData = {
          id,
          uploadFileName,
          uploadFileBase64: base64Data,
          code: docCode,
        };
        if (docIndex != null) {
          uploadedData['doc_index'] = docIndex;
        }
        loanAppId != null && docCode != null && sectionName && docUpload(uploadedData, loanAppId, sectionName);
      };
      reader.readAsDataURL(file);
    }
  };
  const actionLabel = s3_url !== undefined ? 'View' : 'Upload';
  const fileName = s3_url ? s3_url.split('/').pop() : undefined;
  const initialValue = docDetailsState.uploadFileName !== '' ? docDetailsState.uploadFileName : fileName || '';
  const isValidValue = initialValue && initialValue !== '';
  return (
    <div style={{ width: '100%' }} className="lap-upload-and-view-file-input-wrapper">
      <label className={`spanParent ${s3_url !== undefined ? 'greenBorder' : ''}`}>
        <input
          className="inputBox uploadAndViewFileInput"
          label={name}
          disabled={s3_url !== undefined || isDisabled}
          type={fileType}
          ref={(ref) => {
            fileInputRefs[id] = ref;
          }}
          id={id}
          style={{ textOverflow: 'ellipsis' }}
          accept={acceptFileType}
          onChange={(event) => handleFileInputChange(event, id, documentCode, docIndex)}
        />
        <div className="inputContent">
          <div className={isValidValue ? 'fileLabel' : 'fileName'}>{name}</div>
          {isValidValue ? <div className="fileName">{initialValue}</div> : null}
        </div>
        <div className="actionBtn">
          <button
            className="lableStyle"
            onClick={(event) => {
              event.preventDefault();
              if (s3_url) {
                setCurrentDocType(file_type);
                setCurrentDocUrl(s3_url);
                setFileTitle(name);
                setIsOpen(true);
              } else {
                handleInputBoxClick(id);
              }
            }}
          >
            {actionLabel}
          </button>
        </div>
        {isOpen ? <ViewDocumentCustom loan_app_id={loanAppId} company_id={LAPCompanyId} product_id={LAPProductId} doctype={currentDocType} awsurl={currentDocUrl} dispatch={dispatch} setIsOpen={setIsOpen} isOpen={isOpen} title={fileTitle} isXML={isXML} /> : null}
      </label>
      <div className="errorParentButtonStyle">
        <p className="paraStyle">{fileSize}</p>
      </div>
    </div>
  );
}

// UploadAndViewFileInput.prototype = {
//     documentDetails: PropTypes.object,
//     onRefCallback: PropTypes.func,
//     showAlert: PropTypes.func,
//     maxFileLimit: PropTypes.number
// }
/*
    name: PropTypes.string,
    id: PropTypes.string,
    fileSize: PropTypes.string,
    acceptFileType: PropTypes.string,
    fileType: PropTypes.string,
    documentCode: PropTypes.string
*/
