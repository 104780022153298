import { Field_Label, Section_Status } from '../../../../constants/string_constants';
import CrossCircle from '../../../../../assets/img/close-circle.svg';
import React from 'react';
import '../TabSection/LeadDetailsTab/LeadDetailsTab.scss';

export default function LeadRejectedCard(props) {
  const { leadStatus = '' } = props;
  return leadStatus === Section_Status.Rejected ? (
    <div>
      <div className="rejected-alert-container">
        <img alt="icon" src={CrossCircle} className="cross-icon" />
        <div>
          <div className="rejected-alert-container-title">{Field_Label.Offer_Rejected}</div>
          <div className="rejected-alert-container-msg">{Field_Label.Offer_Rejection_Appologize}</div>
        </div>
      </div>
    </div>
  ) : null;
}
