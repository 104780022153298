export function patchfldgWatcher(data, resolve, reject) {
  return {
    type: 'PATCH_FLDG_DETAILS',
    payload: data,
    resolve,
    reject,
  };
}

export function postfldgWatcher(data, resolve, reject) {
  return {
    type: 'POST_FLDG_DETAILS',
    payload: data,
    resolve,
    reject,
  };
}

export function getfldgPartnerWatcher(data, resolve, reject) {
  return {
    type: 'GET_FLDG_PARTNER_AVAILABLE',
    payload: data,
    resolve,
    reject,
  };
}
