import {useState} from 'react';
import StatusIcon from '../../../components/StatusIcon/StatusIcon';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import PlusIcon from '../../../../views/lending/images/add-icon.svg';
import DeleteIcon from '../../../../views/lending/images/trash.svg';
import CustomDatePicker from 'react-sdk/dist/components/DatePicker/DatePicker';
import { Tooltip } from "@material-ui/core";
import _reduce from 'lodash/reduce'
import FinancialDocsStatusIcon from '../../../components/StatusIcon/FinancialDocsStatusIcon';
import UploadFileInput from '../../../components/uploadFileInput/UploadFileInput';
import { UploadAndViewFileInput } from '../../../components/uploadAndViewFileInput/uploadAndViewFileInput.jsx';
import { useAccordionStyles } from './TabSection/LeadDetailsTab/Styles';
import Button from 'react-sdk/dist/components/Button/Button';
import { FORM_FIELD_TYPES, LEAD_DETAILS_TAB_ACCORDION_FIELDS, LEAD_DETAILS_TAB_ACCORDION_SECTIONS } from './mockData';
import { Multi_Values_Section, Section_Status } from '../../../constants/string_constants';
import { LeadStatus } from '../../../config/LeadStatus';
import InfoIcon from "../../../../assets/img/info-circle.svg";
import { LEAD_DETAILS_TAB_EDIT_ACCORDION_FIELDS } from "./leadDetailsEditModeData";
import { REACT_APP_AGE_LAP, REACT_APP_UPPERAGELIMIT_LAP } from "../../../helpers/constants";
import moment from 'moment';
import { patchLapDetailsWatcher, patchLapDocDeleteWatcher } from '../../../actions/lap.action';
import { storedList } from '../../../../util/localstorage';
import { Cities, States } from '../../../../constants/country-state-city-data';
import { calculateAge } from "../../../utils/helper";
import { ErrorConstants } from '../../../constants/error_constants';
import { validateData } from '../../../utils/validation';
import { getfillersFromList, truncateText } from '../../../utils/helper';
import { Number_Constants } from '../../../constants/number_constants';
import { Property_Legal_Report_Upload, Property_Valuation_Report_Upload, Property_Title_Report_DocCodes } from '../../../views/bookLoans/uploadKycData';
import { getLapLoanDocumentsWatcher } from '../../../actions/bookLoan.action';
import { documentCode as DocumentListCode } from "../../../config/docCode";

const styles = useAccordionStyles();
const SUCCESS = "success";
const ERROR = "error";
const CREDIT = "credit";

const formatAccordHeader = (label) => <span style={styles['accordionHeader']}>{label.toUpperCase()}</span>;
const formatAccordBody = (value) => (
  <span style={styles['accordionBody']}>
    <p style={{ fontFamily: "Montserrat-SemiBold" }}>{typeof value === 'string' && value.trim() ? value : ''}</p>
  </span>
);

const formatStatus = (value) => {
  let status = ''
  if (value === Section_Status?.Approved) {
    status = value
  } else if(value === Section_Status.NOT_APPLICABLE){
    status = ''
  } else {
    status = Section_Status?.In_Review
  }
  return <StatusIcon status={status.toLowerCase()} />;
}

const formatStatusForField = (field) => {
  return (
    <div style={styles["sectionStatusBadgeContainer"]}>
      {field?.value && <StatusIcon status={field?.value.toLowerCase()} />}
      <div style={{marginLeft: '5px', visibility: field?.value === Section_Status.Approved ? 'hidden' : 'visible' }}>
        <Tooltip title={truncateText(field?.remarks)} arrow placement="top">
          <img src={InfoIcon} alt="InfoIcon" style={{ width: '20px', height: '20px' }} />
        </Tooltip>
      </div>
    </div>
  )
};

const formatStatusForFieldWithParsedStatus = (parserStatus, camsStatus) => (
    <>
    <div style={{ marginTop: '7px', marginRight: '7px' }}>
      <FinancialDocsStatusIcon status={parserStatus.toLowerCase()} />
    </div>
    <div style={{ marginTop: '7px', marginRight: '7px' }}>
      <FinancialDocsStatusIcon status={camsStatus.toLowerCase()} />
    </div>
    </>
  );

  const getSectionName = (section) => {
    switch(section) {
      case "applicant_details": 
        return "primary-applicants";
      case "co_borrower_details":
        return "co-borrowers";
      case "guarantor_details":
        return "guarantors";
      case "shareholding_structure":
        return "share-holding-details";
      case "property_details":
        return "property-details";
      case "property_documents":
        return "property-documents";
      default:
         return section.replaceAll("_", "-");
    }
  }
  const getCurrSectoinId = (section, leadDetails, subIndex) => {
    switch(section) {
      case "co_borrower_details":
        const currCoBorrower = (leadDetails.coborrower[subIndex] || {});
        const coBorrowerId = currCoBorrower._id;
        return coBorrowerId;
      case "guarantor_details": 
          const currGuarantor = (leadDetails.guarantor[subIndex] || {});
          const guarantorId = currGuarantor._id;
          return guarantorId;
      default:
          return "";
    }
  }
  const getPayload = (
    newAccordionStates,
    section,
    loanAppId,
    companyID,
    productID,
    leadDetails,
    subIndex,
    user
    ) => {

    switch(section) {
      case "co_borrower_details":
        const currCoBorrower = (leadDetails.coborrower[subIndex] || {});
        const {_id, cb_aadhaar} = currCoBorrower
        const coBorrowerId = _id;
        // console.log("coBorrowerId", coBorrowerId);
        const currStates = newAccordionStates[coBorrowerId];
        return {
          loan_app_id: loanAppId,
          section: getSectionName(section),
          lap_company_id: companyID,
          lap_product_id: productID,
          user_id: user._id ? user._id : '',
          
          data: {
            type: CREDIT,
            cb_aadhaar,
            ...currStates,
            _id: coBorrowerId
          }
        }
      case "guarantor_details": 
          const currGuarantor = (leadDetails.guarantor[subIndex] || {});
          const guarantorId = currGuarantor._id;
          // console.log("guarantorId", guarantorId, currGuarantor);
          const updatedStates = newAccordionStates[guarantorId];
          return {
            loan_app_id: loanAppId,
            section: getSectionName(section),
            lap_company_id: companyID,
            lap_product_id: productID,
            user_id: user._id ? user._id : '',
            type: CREDIT,
            ...currGuarantor,
            ...updatedStates  
          }
        case "shareholding_structure":
          const share_holders = (leadDetails?.share_holders || []).map(shareholdersItem => {
            const shareholderId = shareholdersItem._id;
            const updatedState = newAccordionStates[shareholderId] || shareholdersItem;
            return updatedState;
          })
          return {
            loan_app_id: loanAppId,
            section: getSectionName(section),
            lap_company_id: companyID,
            lap_product_id: productID,
            user_id: user._id ? user._id : '',
            type: CREDIT,
            share_holders
          }
          case "property_details":
            let propertyOwnerIds = []
            const propertyDetails = (leadDetails?.property?.prop_property_owner_details || []).map(propertyItem => {
              const propertyDetailsId = propertyItem._id;
              if (propertyDetailsId) {
                propertyOwnerIds.push(propertyDetailsId);
              }
              const updatedState = newAccordionStates[propertyDetailsId] || {};
              return {...propertyItem, ...updatedState};
            })
            propertyOwnerIds.forEach(propertyOwnerId => {
              delete newAccordionStates[propertyOwnerId];
            })
            return {
              loan_app_id: loanAppId,
              section: getSectionName(section),
              lap_company_id: companyID,
              lap_product_id: productID,
              user_id: user._id ? user._id : '',
              type: CREDIT,
              ...newAccordionStates,
              prop_property_owner_details: propertyDetails
            }
        case "property_documents":
          const {prop_value} = newAccordionStates;
          return {
            loan_app_id: loanAppId,
            section: getSectionName(section),
            lap_company_id: companyID,
            lap_product_id: productID,
            user_id: user._id ? user._id : '',
            type: CREDIT,
            ...newAccordionStates,
            prop_value: (parseInt(prop_value, 10) || 0)
          }
        default: 
          return {
            loan_app_id: loanAppId,
            section: getSectionName(section),
            lap_company_id: companyID,
            lap_product_id: productID,
            user_id: user._id ? user._id : '',
            type: CREDIT,
            ...newAccordionStates
          };
    }
  }

export const getAllPropertyDocuments = (loanAppId, LAPCompanyId, LAPProductId, showAlert, dispatch, setAllDocuments, count = 0) => {
    const user = storedList('user');
    // setLoader(true);
    const payload = {
        loanAppID: loanAppId,
        companyId: LAPCompanyId,
        productId: LAPProductId,
        user: user,
        user_id: user?._id,
    };
    new Promise((resolve, reject) => {
        dispatch(getLapLoanDocumentsWatcher(payload, resolve, reject));
    })
      .then((response) => {
          if (response && Array.isArray(response)) {
         
              const total_docs = response.filter((doc) => Property_Title_Report_DocCodes.includes(doc.code));
             
              const titleDocuments = total_docs.map((item, index) => {
                const defaultTitleDocDetails = getTitleDocumentDetails(index+1);
                return {
                  ...defaultTitleDocDetails,
                  s3_url: item?.file_url,
                  doc: item
              }
            });
            const getMatchingObj = (docCodeToMatch, defaultItem) => {
              const item = response.find((doc) => doc.code === docCodeToMatch);
              return {
                ...defaultItem,
                s3_url: item?.file_url,
                doc: item
              }
            }
            const valuationReport = getMatchingObj(DocumentListCode.Property_Valuation_Report_Upload, Property_Valuation_Report_Upload[0]);
            const legalReport = getMatchingObj(DocumentListCode.Property_Legal_Report_Upload, Property_Legal_Report_Upload[0]);
            
            // console.log("total_docs", total_docs, titleDocuments, "valuationReport", valuationReport, "legalReport", legalReport);
            setAllDocuments({titleDocuments, valuationReport, legalReport});
            // if (total_docs === count) {
            //     clearInterval(fileInterval);
            // }
            // setLoader(false);
          }
      })
      .catch((error) => {
          console.log(error.response?.data?.message);
          showAlert(error.response?.data?.message, ERROR);
          // setLoader(false);
          // if (fileInterval) clearInterval(fileInterval);
      });
};

const handlePropertyDocDelete = async (id, loanAppId, LAPCompanyId, LAPProductId, showAlert, dispatch, allDocuments, setAllDocuments, titleDocDetails, setTitleDocDetails, allAccordionState, leadDetails, title_documents) => {
  const user = storedList('user');
  const docTitle = `Title_Report_${id}`;
  const documentCode = DocumentListCode[docTitle];
  const code = [documentCode];
  const payload = {
    loanAppId: loanAppId,
    code,
    tokenData: {
      user_id: user._id,
      product_id: LAPProductId,
      company_id: LAPCompanyId,
    },
  };
  const existingStateInDb = title_documents.find((val => val.id === documentCode)) || {};
  const newState = { ...existingStateInDb, doc_name:  "", doc_code: documentCode, id };
  const mappedTitleDocDetails = title_documents.reduce((acc, curr) => {
    return {...acc, [curr.doc_code]: curr}
  }, {})
  console.log("mappedTitleDocDetails", mappedTitleDocDetails, titleDocDetails, newState)
  const newTitleDocDetails = {...mappedTitleDocDetails, ...titleDocDetails, [documentCode]: newState};

  new Promise((resolve, reject) => {
    dispatch(patchLapDocDeleteWatcher(payload, resolve, reject));
  })
    .then((response) => {
      showAlert(response?.message || 'Document deleted successfully', SUCCESS);
      const newaAllDocuments = (allDocuments.titleDocuments || []).filter(doc => doc.documentCode !== documentCode);
      setAllDocuments({...allDocuments, titleDocuments: newaAllDocuments});
 

      setTitleDocDetails(newTitleDocDetails);
    })
    .catch((error) => {
      console.log("error", error)
      showAlert(error?.response?.data?.message, ERROR);
    });
    onSubmitSave("title_document", allAccordionState, loanAppId, LAPCompanyId, LAPProductId, leadDetails, 0, showAlert, dispatch, newTitleDocDetails);
};

const DiscardAndSaveBtns = ({
    allAccordionState,
    setAllAccordionState,
    section,
    loanAppId,
    companyID,
    productID,
    dispatch,
    showAlert,
    setLeadDetails,
    leadDetails,
    subIndex
  }) => {
    const buttonSaveStyle = {
      padding: '12px 24px',
      borderRadius: '40px',
      backgroundColor: "#fff",
      cursor: 'pointer',
      outline: 'none',
      border: '1px solid #134cde',
      gap: '10px',
      color: '#134cde',
      width: 'max-content',
  };
  const user = storedList('user');
  
  const getLoanData = () => {
    if (loanAppId) {
      const payload = {
        loan_app_id: loanAppId,
        user: user,
      };
      new Promise((resolve, reject) => {
        dispatch(getBookLoanDetailsWatcher(payload, resolve, reject));
      })
        .then((response) => {
          if (response?.data?.length) {
            setLeadDetails((prevData) => ({ ...prevData, ...response?.data[0] }))
          }
        })
        .catch((e) => {
          showAlert('Something went Wrong', 'error');
        });
    }
  };
  const getErrorSectionKey = () => {
    const suffix = "_error_fields";
    const computedCurrSection = section.replaceAll("_", "-");
    const currSection = computedCurrSection === "applicant-details" ? "primary-applicants" : computedCurrSection;
    const currId = getCurrSectoinId(section, leadDetails, subIndex);
    const idAsSuffix = currId === "" ? "" : `:${currId}`;
    console.log("checkIsValidSection", `${currSection}${idAsSuffix}${suffix}`);
    return `${currSection}${idAsSuffix}${suffix}`;
  }
  const onDiscard = () => {
    const computedCurrSection = section.replaceAll("_", "-");
    const currSection = computedCurrSection === "applicant-details" ? "primary-applicants" : computedCurrSection;
    let newState = {
      ...allAccordionState
    };
    const errorSectionKey = getErrorSectionKey();
    
    delete newState[errorSectionKey];
    switch(section) {
      case "co_borrower_details":
      case "guarantor_details":
        const suffix = "_error_fields";
        const idRequired = errorSectionKey.replace(suffix, "");
        const split = idRequired.split(":");
        if(split.length == 2) {
          const currSubsectionState = newState[split[0]] || {};
          delete currSubsectionState[split[1]];
          newState[split[0]] = {
            ...currSubsectionState
          }
        } else {
          throw new Error(`${idRequired} is unhandled`)
        }
        break;
      default:
          delete newState[currSection];
    }
    console.log("newState", newState);
    setAllAccordionState(newState);
    showAlert("Changes discarded successfully.", "success");
  }
  //section, allAccordionState, loanAppId, companyID, productID, leadDetails, subIndex, user, showAlert
  const onSave = () => {
    const computedCurrSection = section.replaceAll("_", "-");
    const currSection = computedCurrSection === "applicant-details" ? "primary-applicants" : computedCurrSection;
    const currAccordionStates = allAccordionState[currSection];
    const postData = getPayload(currAccordionStates, section, loanAppId, companyID, productID, leadDetails, subIndex, user);
    
    console.log("postData", postData);
    new Promise((resolve, reject) => {
      dispatch(patchLapDetailsWatcher(postData, resolve, reject));
    })
      .then((response) => {
        console.log(response);
        showAlert(response?.message, SUCCESS);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, ERROR);
        console.log(error?.response?.data?.message, ERROR);
      });
  }
 
  const checkIsValidSection = (allAccordionState) => {
    // const suffix = "_error_fields";
    // const computedCurrSection = section.replaceAll("_", "-");
    // const currSection = computedCurrSection === "applicant-details" ? "primary-applicants" : computedCurrSection;
    // const currId = getCurrSectoinId(section, leadDetails, subIndex);
    // const idAsSuffix = currId === "" ? "" : `:${currId}`;
    // console.log("checkIsValidSection", `${currSection}${idAsSuffix}${suffix}`);
    const errorFields = allAccordionState[getErrorSectionKey()] || []; 
    return errorFields.length === 0
  }
  const isValidSection = checkIsValidSection(allAccordionState);
  return (<div style={styles['leadReviewBottomComponent']}>
    <button onClick={onDiscard} style={buttonSaveStyle} className="buttonSave">
        Discard
    </button>
    <button onClick={() => {
      onSave()
      // onSave(section, allAccordionState, loanAppId, companyID, productID, leadDetails, subIndex, user, showAlert);
    }} className="buttonNext" disabled={!isValidSection} >
        Save
    </button> 
  </div>)
}

const bottomComponent = ({
  sectionName, 
  subSectionName = '',
  handlePopupVisibility, 
  approveSection,
  sectionStatus,
  isEdit = false,
  allAccordionState,
  setAllAccordionState,
  section,
  loanAppId,
  companyID,
  productID,
  dispatch,
  showAlert,
  setLeadDetails,
  leadDetails,
  subIndex
}) => {
  const isDisabled = sectionStatus?.section_status === Section_Status.Approved;
  const isHideApproveButton = sectionName === 'Additional Documents'

  const AddCommentAndApproveBtns = () => <div style={styles['leadReviewBottomComponent']}>
    <Button buttonType="custom" label={'Add Comment'} onClick={(e) => handlePopupVisibility(sectionName, true, sectionStatus)} isDisabled={false} customStyle={styles['customStyleButtonComment']} />
    {!isHideApproveButton &&<Button
      buttonType="custom"
      label="Approve"
      onClick={() => {
        approveSection({...sectionStatus, sectionName: subSectionName || sectionName})
      }}
      isDisabled={isDisabled || false}
      customStyle={isDisabled ? styles['customStyleButtonDisabled'] : styles['customStyleButton']}
    />}
  </div>;
  
  return isEdit 
    ? <DiscardAndSaveBtns  
        allAccordionState={allAccordionState}
        setAllAccordionState={setAllAccordionState}
        section={section}
        loanAppId={loanAppId}
        companyID={companyID}
        productID={productID}
        dispatch={dispatch}
        showAlert={showAlert}
        setLeadDetails={setLeadDetails}
        leadDetails={leadDetails}
        subIndex={subIndex}
      /> 
    : <AddCommentAndApproveBtns />
};

const getShareholdingTableHeader = () => {
  const headerStyle= {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "15px",
    color: "rgb(22, 23, 25)",
    fontFamily: "Montserrat-SemiBold"
  }
  return <div style={{
    width: "100%", 
    // height: "56px", 
    backgroundColor: "#e5e5e8", 
    borderRadius: "15px 15px 0px 0px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "10px",
    padding: "15px"
  }}>
    <div style={headerStyle} > Name </div>
    <div style={headerStyle} > Shareholding Percentage </div>
  </div>
}
const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const showAlert = (msg, type) => {
    const element = document.getElementById('TopNavBar');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }

    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);

    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

const renderUploadInputBox = (field, allDocuments, setAllDocuments, showAlert, dispatch) => {
  const {loanAppId, company_id, product_id, isDisabled: backgroundColorBlur, propertyDocs=[]} = field;
    // const [backgroundColorBlur, setBackgroundColorBlur] = useState(isDisabled);
    // const [propertyItem, setPropertyLegalReport] = useState(propertyDocs);
    // console.log("renderUploadInputBox", field, propertyDocs);
  //   return <div><UploadFileInput
  //   backgroundColorChange={backgroundColorBlur}
  //   backgroundColorBlur={backgroundColorBlur}
  //   items={propertyDocs}
  //   title=""
  //   showAlert={showAlert}
  //   isXML={false}
  //   loanAppId={loanAppId}
  //   sectionName={'property-documents'}
  //   data={{ company_id, product_id }}
  //   LAPCompanyId={company_id}
  //   LAPProductId={product_id}
  //   isChange={false}
  //   type={'edit'}
  //   onDataCallback={(event) => {
  //     // console.log("onDataCallback", company_id, product_id, loanAppId, JSON.stringify(event));
  //     // setBackgroundColorBlur(true);
  //     // const 
  //     // setAllDocuments()
  //   }}
  // /></div>
  return <>
    <UploadAndViewFileInput 
     documentDetails={propertyDocs[0]}
     showAlert={showAlert}
     loanAppId={loanAppId}
     LAPCompanyId={company_id}
     LAPProductId={product_id}
     data={{ company_id, product_id }}
     sectionName={'property-documents'}
     onUploadCallback={() => {
      getAllPropertyDocuments(loanAppId, company_id, product_id, showAlert, dispatch, setAllDocuments)
     }}
     shouldRestStateOnUploadCallback={true}
    />
  </>
}
const inputBoxCss = {
    marginTop: '8px',
    maxHeight: '500px',
    zIndex: 1,
    width: '105%',
  };
const bodyLabelCss = {
  fontSize: '12px', 
  fontWeight: 400,
  marginTop: '10px', 
  color: '#6B6F80', 
  fontFamily: 'Montserrat-Regular'
};
const renderInputBox = (field) => {
    return  <InputBox
            id={field?.label + '_id'}
            label={field?.label}
            type={'text'}
            isDrawdown={false}
            initialValue={''}
            // isBoxType={'button'}
            // Buttonlabel={'Send OTP'}
            onClick={(event) => console.log(event)}
            isDisabled={false}
            customDropdownClass={inputBoxCss}
            customClass={{
            height: '56px',
            width: '100%',
            maxWidth: '100%',
            }}
            customInputClass={{
            minWidth: '100%',
            backgroundColor: '#fff',
            }}
            error={''}
            helperText={'Enter Value (INR)'}
            placeholder={'Enter Value (INR)'}
        />
}
const getDefaultShareholdingState = (field) => {
  const { nameStateKey = "", percentageStateKey = "", name = "", value, id = ""} = field;
  return {
    [nameStateKey]: name,
    [percentageStateKey]: value,
    _id: id
  }
}
const getTotalShareHoldingPercentage = (shareholdingData, allAccordionState, PRIMARY_APPLICANT ) => {
  const totalPercentage = shareholdingData.filter(field => field.type === "custom_shareholding_table").reduce((acc, field) => {
    const defaultState = getDefaultShareholdingState(field);
    const updatedShareholdingState = getExistingAccordianState(field?.stateKey,  PRIMARY_APPLICANT, allAccordionState) || defaultState;
    const currValue = parseInt(`${updatedShareholdingState[field?.percentageStateKey]}`, 10) || 0;
    const sum = acc + currValue;
    return sum
  }, 0);
  const headerStyle= {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "0px 15px",
    
  }
  const errorComponent = totalPercentage !== 100
    ? <div style={{
        color: "#EA2A33",
        fontSize: "10px",
        padding: "0px 17px",
        width: "100%"
      }} >
        {ErrorConstants.TOTAL_PERCENTAGE_SHOULD_BE_100}
      </div>
    : null

  const lastRow = <div style={{
    width: "100%", 
    // minHeight: "66px",
    borderRadius: " 0px 0px 15px 15px", 
    border: "1px solid #e5e5e8",
    borderTopWidth: "0",
    padding: "22px",
    display: "flex",
    flexDirection: "column",
  }}>
    <div style={{
         display: "grid",
         gridTemplateColumns: "1fr 1fr",
         gridColumnGap: "10px",
         width: "100%",
    }}>
      <div style={headerStyle} > Total Percentage </div>
      <div style={headerStyle} > {totalPercentage}%  </div>
    </div>
    { errorComponent }
  </div>
  return [lastRow, totalPercentage]
}
const getExistingAccordianState = (stateKey, PRIMARY_APPLICANT, allAccordionState) => {
  const nestedKeys = stateKey.split(":");
  let innerAccordionState = {};
  if(nestedKeys.length === 1) {
    innerAccordionState = (allAccordionState[PRIMARY_APPLICANT] || {})[nestedKeys[0]];
  } else if (nestedKeys.length === 2) {
    innerAccordionState = (allAccordionState[nestedKeys[0]] || {})[nestedKeys[1]];
  } else if (nestedKeys.length === 3) {
    const subAccordionState = (allAccordionState[nestedKeys[0]] || {})[nestedKeys[1]];
    innerAccordionState = (subAccordionState || {})[nestedKeys[2]];
  } else {
    throw new Error(`updating ${stateKey} is not handled`);
  }
  return innerAccordionState;
}
const getUpdatedAccordionState = (PRIMARY_APPLICANT, valueToUpdate, allAccordionState, stateKey, isValidValue, errorKey) => {
  const nestedKeys = stateKey.split(":");
  const errorField = !isValidValue ? "" : (errorKey || stateKey);
  let currState = {};
  const suffix = "_error_fields";
  const errorKeyToSave = errorKey || stateKey;
  const getNewErrorFields = (newArr) => {
    return newArr.filter(fieldKey => fieldKey !== errorField);
  }
  if(nestedKeys.length === 1) {
    const innerAccordionState = allAccordionState[PRIMARY_APPLICANT] || {};
    const pa_key = `${PRIMARY_APPLICANT}${suffix}`;
    const pa_error_fields = allAccordionState[pa_key] || [];
    currState = {
      ...allAccordionState, 
      [PRIMARY_APPLICANT]: {
        ...innerAccordionState,
        [nestedKeys[0]]: valueToUpdate
      },
      [pa_key]: getNewErrorFields([...pa_error_fields, errorKeyToSave])
    }
  } else if (nestedKeys.length === 2) {
    const innerAccordionState = allAccordionState[nestedKeys[0]] || {};
    const newErrorKey = `${[nestedKeys[0]]}${suffix}`;
    const curr_error_fields = allAccordionState[newErrorKey] || [];
    currState = {
      ...allAccordionState, 
      [nestedKeys[0]]: {
        ...innerAccordionState,
        [nestedKeys[1]]: valueToUpdate
      },
      [newErrorKey]: getNewErrorFields([...curr_error_fields, errorKeyToSave])
    }
  }  else if (nestedKeys.length === 3) {
    const accordionState = allAccordionState[nestedKeys[0]] || {};
    const currSubAccordionState = accordionState[nestedKeys[1]] || {};
    const idAsStr = nestedKeys[0] === "property-details" ? "": `:${nestedKeys[1]}`;
    const newErrorKey = `${nestedKeys[0]}${idAsStr}${suffix}`;
    const curr_error_fields = allAccordionState[newErrorKey] || [];
    currState = {
      ...allAccordionState,
      [nestedKeys[0]]: {
        ...accordionState,
        [nestedKeys[1]]: {
          ...currSubAccordionState,
          [nestedKeys[2]]: valueToUpdate
        }
      },
      [newErrorKey]: getNewErrorFields([...curr_error_fields, errorKeyToSave])
    }
  } else {
    throw new Error(`updating ${stateKey} is not handled`);
  }
  return currState;
}
const updateAllAccordionState = (PRIMARY_APPLICANT, valueToUpdate, stateKey, allAccordionState, setAllAccordionState, validator = ((value) => [true, ""]), errorKey = null, validateCityDropdown = (() => [true, ""])) => {
    const [isValidValue, _] = validator(valueToUpdate);
    const [isValidCity, cityKey] = validateCityDropdown();
    let currState = getUpdatedAccordionState(PRIMARY_APPLICANT, valueToUpdate, allAccordionState, stateKey, isValidValue, errorKey);
    if (!isValidCity) {
      currState = getUpdatedAccordionState(PRIMARY_APPLICANT, "", currState, cityKey, isValidCity, errorKey);
    }
    console.log("currState", currState);
    setAllAccordionState(currState);
}
export const getCustomInputBoxData = (value, validator, stateKey,  PRIMARY_APPLICANT, allAccordionState) => {
  const existingStateValue = getExistingAccordianState(stateKey,  PRIMARY_APPLICANT, allAccordionState)
  const initialValue = existingStateValue ?? (value || "");
  const [isValidValue = true, helperText = ""] = validator(initialValue);
  return {
    initialValue,
    isValidValue,
    helperText
  }
}
const renderCustomInputBox = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
  const {value, label = "", stateKey = "", isDisabled = false, placeholder = "", maxLength = undefined, validator = ((value) => [true, ""])} = field;
  // const existingStateValue = getExistingAccordianState(stateKey,  PRIMARY_APPLICANT, allAccordionState)
  // const initialValue = existingStateValue ?? (value || "");
  // const [isValidValue = true, helperText = ""] = validator(initialValue);
  const {
    initialValue,
    isValidValue,
    helperText
  } = getCustomInputBoxData(value, validator, stateKey,  PRIMARY_APPLICANT, allAccordionState);
    const computedHelperText = isValidValue ? "" : helperText;
  // console.log("initialValue",initialValue, "existingStateValue", existingStateValue);
  // const [fieldValue, setFieldValue] = useState(initialValue);
  return  <div style={{height: "6rem", width: "100%"}}>
      <InputBox
          id={label + '_id'}
          label={label}
          type={'text'}
          isDrawdown={false}
          initialValue={initialValue}
          onClick={(event) => {
            const trimmedValue = event.value.substring(0, maxLength);
            // const [isValidNewValue = true, _] = validateData(trimmedValue);
            // const errorCount = isValidNewValue ? 0 : 1;
            updateAllAccordionState(PRIMARY_APPLICANT, trimmedValue, stateKey, allAccordionState, setAllAccordionState, validator);
          }}
          isDisabled={isDisabled}
          customDropdownClass={inputBoxCss}
          customClass={{
          height: '56px',
          width: '100%',
          maxWidth: '100%',
          }}
          customInputClass={{
          minWidth: '100%',
          backgroundColor: '#fff',
          }}
          error={!isValidValue}
          helperText={computedHelperText}
          placeholder={placeholder}
      />
    </div>
}
const renderCustomAgeBox = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
  const { stateKey = "", dateStateKey = "", value = "", label = "", isDisabled = false } = field;
  const existingStateValue = getExistingAccordianState(stateKey,  PRIMARY_APPLICANT, allAccordionState);
  const existingDateValue = getExistingAccordianState(dateStateKey,  PRIMARY_APPLICANT, allAccordionState) || existingStateValue;
  const initialValue = existingDateValue || value;
  // console.log(stateKey, dateStateKey, "initialValue", initialValue, "existingStateValue", existingStateValue);
  return  <InputBox
          id={label + '_id'}
          label={label}
          type={'text'}
          isDrawdown={false}
          initialValue={calculateAge(initialValue).toString()}
          onClick={(event) => {
            updateAllAccordionState(PRIMARY_APPLICANT, event.value, stateKey, allAccordionState, setAllAccordionState);
          }}
          isDisabled={isDisabled || false}
          customDropdownClass={inputBoxCss}
          customClass={{
          height: '56px',
          width: '100%',
          maxWidth: '100%',
          }}
          customInputClass={{
          minWidth: '100%',
          backgroundColor: '#fff',
          }}
          error={''}
          helperText={field?.helperText || ""}
          placeholder={field?.placeholder || ""}
      />
}
const renderCustomDatePicker = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
  const {value, helperText = "", stateKey = "", label = ""} = field;
  const updatedDate = getExistingAccordianState(stateKey, PRIMARY_APPLICANT, allAccordionState) || value;
  // console.log("updatedDate", updatedDate, allAccordionState);
  return <CustomDatePicker
    customInputClass={{ width: '100%', height: '56px' }}
    label={label}
    format={'YYYY-MM-DD'}
    disableFuture={true}
    age={parseInt(REACT_APP_AGE_LAP)}
    startingDate={REACT_APP_UPPERAGELIMIT_LAP ? parseInt(new Date().getFullYear()) - parseInt(REACT_APP_UPPERAGELIMIT_LAP) : 1964}
    endingDate={parseInt(new Date().getFullYear()) - parseInt(REACT_APP_AGE_LAP)}
    isBoxType={'icon'}
    onDateChange={(date) => {
      updateAllAccordionState(PRIMARY_APPLICANT, date, stateKey, allAccordionState, setAllAccordionState);
      // changeDateSelected(date, `${row.type}_vl_${row.field}`);
    }}
    initialValue={updatedDate ? moment(updatedDate).format('DD-MM-YYYY') : '' || null}
    isDisabled={false}
    // error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperText1Required' || validationData[`${row.type}_vl_${row.field}State`] === 'has-errorHelperTextRequired'}
    helperText={helperText}
  />
}
const validateNewCity = (PRIMARY_APPLICANT, allAccordionState, selectedState, selectedStateKey, cityKey, cityValue) => {
  const currState = getExistingAccordianState(selectedStateKey, PRIMARY_APPLICANT, allAccordionState) ?? selectedState;
  const currStateInfo = (States.filter(state => (state.value !== "") && (state.value === currState) ) || [])[0];
  const newOptions = Cities(currStateInfo);
  const updatedDropdownValue = getExistingAccordianState(cityKey, PRIMARY_APPLICANT, allAccordionState) ?? cityValue;
  const isValidCity = newOptions.filter(city => city?.value === updatedDropdownValue).length > 0;
  return isValidCity;
}
const renderCustomDropDown = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
  const {value, options = [], label = "", stateKey = "", cityKey = "", selectedCity = "", isCityDropdown = false, isStateDropdown = false , selectedState, selectedStateKey = "", validator = ((value) => [true, ""])} = field;
  const currState = getExistingAccordianState(selectedStateKey, PRIMARY_APPLICANT, allAccordionState) || selectedState;
  const currStateInfo = (States.filter(state => state.value === currState) || [])[0];
  const newOptions = isCityDropdown ? Cities(currStateInfo) : options;
  const updatedDropdownValue = getExistingAccordianState(stateKey, PRIMARY_APPLICANT, allAccordionState) ?? value;
  const isValidCity = newOptions.filter(city => city?.value === updatedDropdownValue).length > 0;
  // console.log("currStateInfo", stateKey, selectedStateKey, allAccordionState, updatedDropdownValue, newOptions)
  
  const defaultValue = updatedDropdownValue ?? '';
  const cityValue = isValidCity ? defaultValue : "";
  const initialValue = isCityDropdown ?  cityValue : defaultValue;
  const [isValidValue = true, helperText = ""] = validator(initialValue);
  const computedHelperText = isValidValue ? "" : helperText;
  
  return  <div className="dropdownWrapper" style={{height: "6rem", width: "100%"}}>
    <InputBox
      id={label}
      label={label}
      isDrawdown={true}
      initialValue={initialValue}
      isDisabled={false}
      customDropdownClass={{ marginTop: '8px',
      maxHeight: '500px',
      zIndex: 1,
      width: '105%',}}
      customClass={{
        height: '56px',
        width: '100%',
        maxWidth: '100%',
      }}
      customInputClass={{
        marginTop: '0px',
        minWidth: '100%',
        backgroundColor: '#fff',
      }}
      onClick={(event) => {
        const validateCityDropdown = () => {
          let newCity = ""
          let newState = ""
          if(isStateDropdown) {
            newCity = cityValue;
            newState = event.value;
          } else if (isCityDropdown) {
            newCity = event.value;
            newState = currState;
          }
          const isValidCity = validateNewCity(PRIMARY_APPLICANT, allAccordionState, event.value, selectedStateKey, cityKey, cityValue);
          return [isValidCity, cityKey];
        }
        updateAllAccordionState(PRIMARY_APPLICANT, event.value, stateKey, allAccordionState, setAllAccordionState, validator, null, validateCityDropdown);
      }}
      options={newOptions}
      error={!isValidValue}
      helperText={computedHelperText}
      // isReadOnly={true}
    />
  </div>
}

const renderCustomYesOrNoRadioBtns = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
  const {value, helperText = "", label = "", stateKey = "", itemIndex} = field;
  const existingStateValue = getExistingAccordianState(stateKey,  PRIMARY_APPLICANT, allAccordionState)
  const currValue = existingStateValue || value;
  const onChange = (event) => {
    // console.log("radio-event", event)
    updateAllAccordionState(PRIMARY_APPLICANT, event?.target?.defaultValue, stateKey, allAccordionState, setAllAccordionState)
  }
  return (
    <div className='ar-cb-entity-shareholder'>
      <p className='ar-cb-entity-shareholder-paragraph'>{label}</p>
      <div className='ar-cb-entity-shareholder-value' style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
      }}>
        <div style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center"
        }}>
          <input style={{marginBottom: "0.5rem", marginRight: "0.5rem"}} type="radio" id={`${stateKey}_yes-${itemIndex + 1}`} disabled={false} name={`${stateKey}_yes-${itemIndex + 1}`} value={'yes'} checked={currValue === 'yes'} onChange={onChange} />
          <label htmlFor={`${stateKey}_yes-${itemIndex + 1}`}>Yes</label>
        </div>
        <div style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center"
        }}>
          <input style={{marginBottom: "0.5rem", marginRight: "0.5rem"}} type="radio" id={`${stateKey}_no-${itemIndex + 1}`} disabled={false} name={`${stateKey}_no-${itemIndex + 1}`} value={'no'} checked={currValue === 'no'} onChange={onChange} />
          <label htmlFor={`${stateKey}_no-${itemIndex + 1}`}>No</label>
        </div>
      </div>
      {/* {validationData[`${item.type}_vl_${item.field}State`] === 'has-danger' ? (
        <div style={{ marginTop: '0' }} className={`error-message`}>
          {item.validationMsg}
        </div>
      ) : null} */}
    </div>
  )
}
const renderCustomShareholdingTable = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
  const {value, nameHelperText = "", namePlaceHolder = "", percPlaceHolder = "", label = "", stateKey = "", nameStateKey = "", percentageStateKey = "", allPercentageValues = []} = field;
  const defaultState = getDefaultShareholdingState(field);
  const updatedShareholdingState = getExistingAccordianState(stateKey,  PRIMARY_APPLICANT, allAccordionState) || defaultState;
  const initialName = updatedShareholdingState[nameStateKey];
  const initialPercentage = updatedShareholdingState[percentageStateKey];
  // console.log("field", field, "updatedShareholdingState", updatedShareholdingState)
  
  const isValidName = validateData("fullname", initialName);
  const helperText = initialName === "" ? "Name is required" : nameHelperText;
  return <div className='shareholdingRow' style={{
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "10px",
    width: "100%",
    gridAutoColumns: "1fr",
    border: "1px solid #e5e5e8",
    borderTopWidth: "0",
    padding: "25px 10px"
  }}>
    <InputBox
          id={nameStateKey + '_id'}
          label={""}
          type={'text'}
          isDrawdown={false}
          initialValue={initialName}
          onClick={(event) => {
            const newState = {...updatedShareholdingState, [nameStateKey]: event.value };
            const validator = (newValue) => {
              const isValid = (newValue !== "") && validateData("fullname", newValue.share_holder_name)
              return [isValid, ""]
            };
            updateAllAccordionState(PRIMARY_APPLICANT, newState, stateKey, allAccordionState, setAllAccordionState, validator);
          }}
          isDisabled={false}
          customDropdownClass={inputBoxCss}
          customClass={{
          height: '56px',
          width: '100%',
          maxWidth: '100%',
          }}
          customInputClass={{
          minWidth: '100%',
          backgroundColor: '#fff',
          }}
          error={!isValidName}
          helperText={helperText || ""}
          placeholder={namePlaceHolder || ""}
      />
      <InputBox
          id={percentageStateKey + '_id'}
          label={""}
          type={'text'}
          isDrawdown={false}
          initialValue={initialPercentage}
          onClick={(event) => {
            const newValue = event.value.replaceAll(/\D/g, "");
            const newState = {...updatedShareholdingState, [percentageStateKey]: newValue };
            
            const validator = (newValue) => {
              const newFields = field?.allPercentageValues.map(item => {
                if(item?.id === newState?._id) {
                  item.value = parseInt(event.value, 10);
                } else {
                  const existingUpdatedState = (getExistingAccordianState(item.stateKey,  PRIMARY_APPLICANT, allAccordionState) || {})[percentageStateKey];
                  item.value = existingUpdatedState || item.value;
                }
                return item
              });
              const totalPercent = newFields.reduce((acc, currField) => {
                const currValue = parseInt(`${currField?.value}`, 10) || 0;
                const sum = acc + currValue;
                return sum;
              }, 0)
              return [totalPercent === 100, ""]
            }
            const errorKey = "share_holding_percentage_error";
            updateAllAccordionState(PRIMARY_APPLICANT, newState, stateKey, allAccordionState, setAllAccordionState, validator, errorKey);
          }}
          isDisabled={field?.isDisabled || false}
          customDropdownClass={inputBoxCss}
          customClass={{
          height: '56px',
          width: '100%',
          maxWidth: '100%',
          }}
          customInputClass={{
          minWidth: '100%',
          backgroundColor: '#fff',
          }}
          error={''}
          helperText={""}
          placeholder={percPlaceHolder || ""}
      />
  </div>
}

export const validateTitleDocumentName = (newValue, documentCode, titleDocErrorData, documentNameLabel, setTitleDocErrorData = null) => {
  let errorMessage = "";
  let isValidValue = true;
  const namePattern = /^[\w,\s-]+$/; // \.[A-Za-z]{3}
  if (newValue === "") {
    isValidValue = false;
    errorMessage = `${documentNameLabel} is required`;
  } else if (!namePattern.test(newValue)) {
    isValidValue = false;
    errorMessage = `Please enter valid ${documentNameLabel}`;
  }
  if (isValidValue) {
    delete titleDocErrorData[documentCode];
    // console.log("titleDocErrorData", titleDocErrorData);
    if(setTitleDocErrorData) {
      setTitleDocErrorData(titleDocErrorData);
    }
    return titleDocErrorData;
  } else {
    const newTitleDocErrorData = {...titleDocErrorData,  [documentCode]: errorMessage};
    // console.log("titleDocErrorData", newTitleDocErrorData);
    if(setTitleDocErrorData) {
      setTitleDocErrorData(newTitleDocErrorData);
    }
    return newTitleDocErrorData;
  }
}

const renderTitleDocRow = (
  documentObj, 
  field,
  loanAppId, 
  company_id, 
  product_id,
  isDisabled,
  allDocuments, 
  setAllDocuments,
  showAlert,
  dispatch,
  titleDocDetails, 
  setTitleDocDetails,
  allAccordionState,
  leadDetails
  ) => {
  const { documents = [], documentNameLabel = "" } = field;
  const noOfDocs = documents.length;
  const {sectionName = "", name = "", id = "", nameStateKey = "", titleDocData, title_documents} = documentObj;
  const defaultTitleDoc = getTitleDocumentDetails(id)
  const items =  [
    titleDocData || defaultTitleDoc
  ];
  const newField = { 
    loanAppId, 
    company_id, 
    product_id,
    isDisabled, 
    propertyDocs: items,
    id: `Title_Report_${id}`,
  };
  const shouldShowAddDoc = isDisabled ? false : (id === noOfDocs && noOfDocs < 25);
  const shouldhideDeleteIconFor1stRow = noOfDocs === 1 && items[0].s3_url !== undefined;
  const shouldShowDelete =  isDisabled ? false : (id === noOfDocs && (shouldhideDeleteIconFor1stRow || noOfDocs > 1));
  const shouldEnableAddDoc = items.reduce((acc, curr) => {
    return acc && curr.s3_url !== undefined;
  }, true);
  const titleErrorValidationData = documents.reduce((acc, curr) => {
    const idValue = parseInt(curr.id, 10) || 0;
  const documentCode = DocumentListCode[`Title_Report_${idValue}`];
    return validateTitleDocumentName(curr.name, documentCode, acc, documentNameLabel);
  },{});
  // console.log("titleErrorValidationData", titleErrorValidationData)
  const onAddTitleDocument = () => {
    const {titleDocuments = []} = allDocuments;
    const newId = titleDocuments.length + 1;
    const newDocCode = DocumentListCode[`Title_Report_${newId}`]
    const newTitleDoc = getTitleDocumentDetails(newId, newDocCode);
    const allDocs = [...titleDocuments, newTitleDoc]
    // console.log("allDocs", allDocs, titleDocuments)
    setAllDocuments({...allDocuments, titleDocuments: allDocs})
  }
  const idValue = parseInt(id, 10) || 0;
  const documentCode = DocumentListCode[`Title_Report_${idValue}`];
  const nameErrorMessage = titleErrorValidationData[documentCode] || ""; // titleDocErrorData[documentCode] || ""
  const isValidName = isDisabled || nameErrorMessage === "";
  return <div style={{
    display: "grid",
    gridTemplateColumns: "0.5fr 1fr 1fr auto",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    columnGap: "1.125rem"
  }}>
    <div style={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "56px",
      fontFamily: "Montserrat-SemiBold"
    }}>{sectionName}</div>
    <div>
      <InputBox
          id={nameStateKey + id + '_id'}
          label={documentNameLabel}
          type={'text'}
          isDrawdown={false}
          initialValue={name}
          onClick={(event) => {
           
            const newState = { doc_name:  event.value, doc_code: documentCode, id: idValue };
            // console.log("newState", newState);
            const mappedTitleDocDetails = title_documents.reduce((acc, curr) => {
              return {...acc, [curr.doc_code]: curr}
            }, {})
            setTitleDocDetails({...mappedTitleDocDetails, ...titleDocDetails, [documentCode]: newState});
            // validateTitleDocumentName(event.value, documentCode, titleErrorValidationData, documentNameLabel);
          }}

          isDisabled={isDisabled}
          customDropdownClass={inputBoxCss}
          customClass={{
          height: '56px',
          width: '100%',
          maxWidth: '100%',
          }}
          customInputClass={{
          minWidth: '100%',
          backgroundColor: '#fff',
          }}
          error={!isValidName}
          helperText={nameErrorMessage || ""}
          // placeholder={namePlaceHolder || ""}
      />
     {shouldShowAddDoc ? <Button
            key={`add-doc-btn-${id}`}
            customStyle={{
              height: '48px',
              fontSize: '16px',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: 'none',
              backgroundColor: 'transparent',
              fontFamily: 'Montserrat-Regular',
              padding: '22px',
              border: '0px',
              color: shouldEnableAddDoc ? 'rgba(19, 76, 222, 1)' : "#767888",
              filter: shouldEnableAddDoc ? "unset": "invert(0.4)",
              marginTop: "1.5rem"
            }}
            label="Add Document"  
            buttonType="secondary"
            imageButton={PlusIcon}
            imageButtonHover={PlusIcon}
            iconButton="btn-secondary-download-button"
            onClick={() => onAddTitleDocument()}
            isDisabled={!shouldEnableAddDoc}
        /> : null}
    </div>
    <div>{renderUploadInputBox(newField, allDocuments, setAllDocuments, showAlert, dispatch)}</div>
  
    <div 
    key={`delete-doc-btn-${id}`}
    style={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "56px"
    }}>
      {
        <img src={DeleteIcon} alt="Non Editable" style={{ cursor: shouldShowDelete ? 'pointer' : "none", visibility: shouldShowDelete ? "visible": "hidden" }} onClick={(event) => {
          // console.log("delete btn clicked");
          handlePropertyDocDelete(id, loanAppId, company_id, product_id, showAlert, dispatch, allDocuments, setAllDocuments, titleDocDetails, setTitleDocDetails, allAccordionState, leadDetails, title_documents);
        }} />
      }
    </div>

  </div>
}
const renderPropertyTitleDocument = (field, allDocuments, setAllDocuments, showAlert, dispatch, titleDocDetails, setTitleDocDetails, allAccordionState, leadDetails) => {
  // const {} = field;
  
  const { 
    documents = [],
    loanAppId, 
    company_id, 
    product_id,
    isDisabled = false
  } = field;
  return <div style={{ width: "100%" }} name={field.type}>
      {documents.map(((documentObj, index) => {
        return <div key={`titleDocRow-${index}`}>{renderTitleDocRow(documentObj, field, loanAppId, company_id, product_id, isDisabled, allDocuments, setAllDocuments, showAlert, dispatch, titleDocDetails, setTitleDocDetails, allAccordionState, leadDetails)}</div>
      }))}
  </div>
}

const onSubmitSave = (section, allAccordionState, loanAppId, company_id, product_id, leadDetails, subIndex, showAlert, dispatch, titleDocDetails) => {
  let postData = {};
  const user = storedList('user');
  if( section === "title_document" ) {
    const titleDocs = Property_Title_Report_DocCodes.map((docCode, index) => {
      const defaultState = { doc_name: "", doc_code: docCode, id: index + 1 };
      return titleDocDetails[docCode] || defaultState;
    });
   
    postData = {
      loan_app_id: loanAppId,
      section: "property-documents",
      lap_company_id: company_id,
      lap_product_id: product_id,
      user_id: user._id ? user._id : '',
      type: CREDIT,
      title_documents: titleDocs
    }
  } else {
    const computedCurrSection = section.replaceAll("_", "-");
    const currSection = computedCurrSection === "applicant-details" ? "primary-applicants" : computedCurrSection;
    const currAccordionStates = allAccordionState[currSection];
    postData = getPayload(currAccordionStates, section, loanAppId, company_id, product_id, leadDetails, subIndex, user);
  }
  
  
  console.log("postData", postData);
  new Promise((resolve, reject) => {
    dispatch(patchLapDetailsWatcher(postData, resolve, reject));
  })
    .then((response) => {
      console.log(response);
      showAlert(response?.message, SUCCESS);
    })
    .catch((error) => {
      showAlert(error?.response?.data?.message, ERROR);
      console.log(error?.response?.data?.message, ERROR);
    });
}
const SubmitBtnComponent = ({field, allAccordionState, leadDetails, subIndex, showAlert, dispatch, titleDocDetails, approveSection, sectionStatus, subSectionName, sectionName}) => {

  const {loanAppId, company_id, product_id, section = "", isDisabled = false} = field;
  const label = field?.label ? field?.label : field;
  const isApproveBtn = label === "Approve";

  const newIsDisabled = isApproveBtn 
    ? (sectionStatus?.section_status === Section_Status.Approved) || isDisabled
    : isDisabled;

  const customStyle = isApproveBtn
    ? (newIsDisabled ? styles['customStyleButtonDisabled'] : styles['customStyleButton'])
    : (newIsDisabled ? styles['customStyleDisabledButtonComment'] : styles['customStyleButtonComment'])
  
  return <Button 
    buttonType="custom"
    label={label}
    onClick={() => {
      console.log(field?.label +' button clicked!');
      if(isApproveBtn) {
        approveSection({...sectionStatus, sectionName: subSectionName || sectionName})
      } else {
        onSubmitSave(section, allAccordionState, loanAppId, company_id, product_id, leadDetails, subIndex, showAlert, dispatch, titleDocDetails);
      }
      // onSave(section, allAccordionState, loanAppId, company_id, product_id, leadDetails, subIndex, user, showAlert);
      // setAllAccordionState({});
    }}
    isDisabled={newIsDisabled}
    customStyle={customStyle} 
  />
}

const renderInputButtonOutlined = (field, allAccordionState, leadDetails, subIndex, showAlert, dispatch, titleDocDetails, approveSection, sectionStatus, subSectionName, sectionName) => {
  // const {loanAppId, company_id, product_id, section} = field;
  // console.log("field", field);
  return <SubmitBtnComponent
    field={field}
    allAccordionState={allAccordionState}
    leadDetails={leadDetails}
    subIndex={subIndex}
    showAlert={showAlert}
    dispatch={dispatch}
    titleDocDetails={titleDocDetails}
    approveSection={approveSection}
    sectionStatus={sectionStatus}
    subSectionName={subSectionName} 
    sectionName={sectionName}
   />
}

const renderInputDangerButtonOutlined = (field) => {
    return <Button buttonType="custom" label={field?.label ? field?.label : field} onClick={() => console.log(field?.label +' button clicked!')} isDisabled={false} customStyle={styles['customStyleButtonDanger']} />
}

const getFormFieldOrFillerData = (field) => {
  if (!field || (field.type !== 'field' && field.type !== 'filler')) {
    console.error('ERROR: Invalid Field Type!');
    return;
  }
  const label = field.type !== 'filler' ? field.label.toUpperCase() : '';
  const value = field.type !== 'filler' ? formatAccordBody(field.value) : '';

  return {
    head: <label style={bodyLabelCss}>{label}</label>,
    body: <label className='lap-accordian-field-value'>{value}</label>,
    type: 'column',
  };
};

const getFormSeparatorData = (field) => {
  if (!field || field.type !== 'separator') {
    console.error('ERROR: Invalid Field Type!');
    return;
  }
  const rowFillerCount = 3;
  const rowTitleData = {
    head: (
      <div style={{ marginLeft: 0, marginTop: '20px', marginBottom: '15px', textTransform: 'capitalize' }} className={'title-name-head'}>
        {field.label}
      </div>
    ),
    body: formatAccordBody(''),
    type: 'row',
  };

  const rowFillerData = {
    head: <div style={{ fontSize: '15px', marginTop: '10px' }}>{''.toUpperCase()}</div>,
    body: formatAccordBody(''),
    type: 'row',
  };

  return [rowTitleData, ...Array(rowFillerCount).fill(rowFillerData)];
};

const getSubheadingData = (field) => {
  if (!field || field.type !== 'subHeading') {
    return;
  }
  const rowTitleData = {
    head: (
      <div style={{ marginLeft: 0, marginTop: '20px', marginBottom: '15px', textTransform: 'capitalize' }} className={'title-name-head'} name={field?.type}>
        {field.label}
      </div>
    ),
    body: <label className='lap-accordian-field-value'>{field?.value}</label>,
    type: 'column',
  };
  return [rowTitleData];
}

const getFormValidationCheckListFieldsData = (field) => {
  if (!field || field.type !== 'validation_checklist_fields') {
    console.error('ERROR: Invalid Field Type!');
    return;
  }
  const labelData = {
    head: <label style={{ fontSize: '15px', marginTop: '10px' }}>{field?.label.toUpperCase()} </label>,
    body: formatAccordBody(''),
    type: 'row',
  };
  const valueData = {
    head: <label style={{ fontSize: '15px', marginTop: '10px' }}>{''.toUpperCase()} </label>,
    body: formatStatusForField(field),
    type: 'row',
  };

  return [labelData, valueData];
};

const getFormValidationCheckListWithParserStatusFieldsData = (field) => {
    if (!field || field.type !== 'validation_checklist_fields_with_parser_status') {
      console.error('ERROR: Invalid Field Type!');
      return;
    }
    const labelData = {
      head: <label style={{ fontSize: '15px', marginTop: '10px' }}>{field?.label.toUpperCase()} </label>,
      body: formatAccordBody(''),
      type: 'row',
    };
    const valueData = {
      head: <label style={{ fontSize: '15px', marginTop: '10px' }}>{''.toUpperCase()} </label>,
      body: formatStatusForFieldWithParsedStatus(field?.parsedValue.toLowerCase(), field?.camsvalue.toLowerCase()),
      type: 'row',
    };
  
    return [labelData, valueData];
  };

 
  const getFormUploadInputFieldData = (field, allDocuments, setAllDocuments, showAlert, dispatch) => {
    if (!field || field.type !== 'input_upload_field') {
      console.error('ERROR: Invalid Field Type!');
      return;
    }
    // console.log("getFormUploadInputFieldData", field)
    const label = field?.label // .toUpperCase();
    const value = renderUploadInputBox(field, allDocuments, setAllDocuments, showAlert, dispatch);
  //style={{marginTop: '-20px'}}
    return {
      head: <label style={{    
        fontSize: "16px",
        lineHeight: "17px",
        letterSpacing: "0em",
        textAlign: "left",
        color: "rgb(21, 21, 21)",
        marginTop: '10px', 
        fontFamily: "Montserrat-SemiBold"
      }}>
        {label}
      </label>,
      body: <>{value}</>,
      type: 'column',
    };
  };

  
  const getFormInputFieldData = (field) => {
    if (!field || field.type !== 'input_text_field') {
      console.error('ERROR: Invalid Field Type!');
      return;
    }
    const label = field?.label.toUpperCase();
    const value = renderInputBox(field);
  
    return {
      head: <label style={{ fontSize: '15px', marginTop: '10px', fontFamily: "Montserrat-SemiBold" }}>{label}</label>,
      body: <>{value}</>,
      type: 'column',
    };
  };

  const getCustomFormInputFieldData = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
    if (!field || field.type !== 'custom_input_text_field') {
      console.error('ERROR: Invalid Field Type!');
      return;
    }
    const value = renderCustomInputBox(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState);
    const header = field?.header 
      ? <label style={{    
        fontSize: "16px",
        lineHeight: "17px",
        letterSpacing: "0em",
        textAlign: "left",
        color: "rgb(21, 21, 21)",
        marginTop: '10px', 
        fontFamily: "Montserrat-SemiBold"
      }}>
        { field?.header }
      </label> 
    : <></>
    return {
      head: header,
      body: <>{value}</>,
      type: 'column',
    };
  };
  const getCustomAgeBox = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
    if (!field || field.type !== 'custom_age_field') {
      console.error('ERROR: Invalid Field Type!');
      return;
    }
    const value = renderCustomAgeBox(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState)
    
    return {
      head: <></>,
      body: <>{value}</>,
      type: 'column',
    }; 
  }
  const getCustomDatePicker = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
    if (!field || field.type !== 'custom_date_picker') {
      console.error('ERROR: Invalid Field Type!');
      return;
    }
    const value = renderCustomDatePicker(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState)
    return {
      head: <></>,
      body: <>{value}</>,
      type: 'column',
    };
  }

  const getCustomDropDown = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
    if (!field || field.type !== 'custom_dropdown') {
      console.error('ERROR: Invalid Field Type!');
      return;
    }
    const value = renderCustomDropDown(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState)
    return {
      head: <></>,
      body: <>{value}</>,
      type: 'column',
    };
  }
  const getCustomYesOrNoRadioBtns = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
    if (!field || field.type !== 'custom_yes_or_no_radio_btns') {
      console.error('ERROR: Invalid Field Type!');
      return;
    }
    const value = renderCustomYesOrNoRadioBtns(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState)
    return {
      head: <></>,
      body: <>{value}</>,
      type: 'column',
    };
  }

const getCustomShareholdingTable = (field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState) => {
  if (!field || field.type !== 'custom_shareholding_table') {
    console.error('ERROR: Invalid Field Type!');
    return;
  }
  const value = renderCustomShareholdingTable(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState)
  return value;
}
const getAccordion_divider = (field) => {
  if (!field || field.type !== "accordion_divider") {
    console.error('ERROR: Invalid Field Type!');
    return;
  }
  return {
    head: <></>,
    body: <><div name={field.type} style={{borderTop: "0.063rem solid #BBBFCC", width: "100%", height: "1px", marginBottom: '1rem' }} /></>,
    type: 'column',
  };
}
const getPropertyTitleDocument = (field, allDocuments, setAllDocuments, showAlert, dispatch, titleDocDetails, setTitleDocDetails, allAccordionState, leadDetails) => {
  if (!field || field.type !== "title_document_row") {
    console.error('ERROR: Invalid Field Type!');
    return;
  }
  return {
    head: <></>,
    body: <>
      {renderPropertyTitleDocument(field, allDocuments, setAllDocuments, showAlert, dispatch, titleDocDetails, setTitleDocDetails, allAccordionState, leadDetails)}
    </>,
    type: 'column',
  };
}
const getFormInputButtonOutlinedData = (field, allAccordionState, leadDetails, subIndex, showAlert, dispatch, titleDocDetails, approveSection, sectionStatus, subSectionName, sectionName) => {
    if (!field || field.type !== 'input_button_field') {
        console.error('ERROR: Invalid Field Type!');
        return;
    }
    const label = ' ';
    const value = renderInputButtonOutlined(field, allAccordionState, leadDetails, subIndex, showAlert, dispatch, titleDocDetails, approveSection, sectionStatus, subSectionName, sectionName);
    const additionalStyle = (field.label || "") === "Approve" ? {marginLeft: "auto"} : {}
    return {
      head: <label style={{ fontSize: '15px', marginTop: '10px' }}>{label}</label>,
      body: <div style={{marginTop: '20px', ...additionalStyle}}>{value}</div>,
      type: 'column',
    };

}

const getFormInputActionButtonOutlinedData = (field) => {
    if (!field || field.type !== 'input_action_button_field') {
        console.error('ERROR: Invalid Field Type!');
        return;
    }
    const label = ' ';
    const value = <>
        {renderInputButtonOutlined('+ Add')}
        {renderInputDangerButtonOutlined('- Remove')}
    </>
  
    return {
      head: <label style={{ fontSize: '15px', marginTop: '10px' }}>{label}</label>,
      body: <div style={{display: 'flex', alignItems: 'center'}}>{value}</div>,
      type: 'row',
    };

}

const getFullLineMsg = (field) => {
  return {
    head: '',
    body: <div name={'full_line_msg'} style={{display: 'flex', alignItems: 'center'}}>No Data Found</div>,
    type: 'row',
  };
}

const getDynamicLeadFormFields = (accordionSection, accordionFields = [], isSubAccordion = false, sIndex = 0, allAccordionState, setAllAccordionState, leadDetails, showAlert, dispatch, allDocuments, setAllDocuments, titleDocDetails, setTitleDocDetails, approveSection, sectionStatus, subSectionName, sectionName) => {
  if (LEAD_DETAILS_TAB_ACCORDION_SECTIONS.includes(accordionSection)) {
    const selectedSectionFields = accordionFields.filter((accordion) => (isSubAccordion ? accordion?.sectionName.toLowerCase() === accordionSection?.toLowerCase() && accordion?.subIndex == sIndex : accordion?.sectionName.toLowerCase() === accordionSection?.toLowerCase()));
    if (selectedSectionFields.length == 0) {
      console.error('ERROR: No data found! for the accordion section ' + accordionSection + ' Please check the accordion data!');
      return [];
    }
    const PRIMARY_APPLICANT = "primary-applicants"
    let renderFormFields = [];
    selectedSectionFields.forEach((accordion) => {
      let shareHoldingFields = [getShareholdingTableHeader()];
        accordion?.fieldsArr.forEach((field) => {
          if (!FORM_FIELD_TYPES.includes(field.type)) {
            console.error('ERROR: Invalid field type! Please check the input data!');
            return;
          }

        switch (field.type) {
          case 'field':
          case 'filler':
            renderFormFields.push(getFormFieldOrFillerData(field));
            break;
          case 'subHeading':
            renderFormFields = renderFormFields.concat(getSubheadingData(field));
            break;
          case 'separator':
            renderFormFields = renderFormFields.concat(getFormSeparatorData(field));
            break;
          case 'validation_checklist_fields':
            renderFormFields = renderFormFields.concat(getFormValidationCheckListFieldsData(field));
            break;
          case 'validation_checklist_fields_with_parser_status':
            renderFormFields = renderFormFields.concat(getFormValidationCheckListWithParserStatusFieldsData(field));
            break; 
          case 'input_upload_field':
            renderFormFields.push(getFormUploadInputFieldData(field, allDocuments, setAllDocuments, showAlert, dispatch));
            break;
          case 'input_text_field':
            renderFormFields.push(getFormInputFieldData(field));
            break;
          case 'input_button_field':
            renderFormFields.push(getFormInputButtonOutlinedData(field, allAccordionState, leadDetails, sIndex, showAlert, dispatch, titleDocDetails, approveSection, sectionStatus, subSectionName, sectionName));
            break; 
          case 'input_action_button_field':
            renderFormFields.push(getFormInputActionButtonOutlinedData(field));
            break;
          case 'custom_input_text_field': 
            renderFormFields.push(getCustomFormInputFieldData(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState));
            break;
          case 'custom_date_picker': 
            renderFormFields.push(getCustomDatePicker(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState));
            break;
          case 'custom_dropdown': 
            renderFormFields.push(getCustomDropDown(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState));
            break;
          case 'custom_yes_or_no_radio_btns':
            renderFormFields.push(getCustomYesOrNoRadioBtns(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState));
            break;
          case 'custom_age_field': 
            renderFormFields.push(getCustomAgeBox(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState));
            break;
          case 'custom_shareholding_table':
            shareHoldingFields.push(getCustomShareholdingTable(field, PRIMARY_APPLICANT, allAccordionState, setAllAccordionState));
            break;
          case 'accordion_divider':
            renderFormFields.push(getAccordion_divider(field));
            break;
          case 'title_document_row':
            renderFormFields.push(getPropertyTitleDocument(field, allDocuments, setAllDocuments, showAlert, dispatch, titleDocDetails, setTitleDocDetails, allAccordionState, leadDetails));
            break;
          default:
            break;
        }
      });
      // console.log("shareHoldingFields", shareHoldingFields)
      if(shareHoldingFields.length > 1) {
        const [lastRow, totalPercentage] = getTotalShareHoldingPercentage(accordion?.fieldsArr, allAccordionState, PRIMARY_APPLICANT);
        const shareholdingTableComponent = <div style={{width: "100%"}}>
          {shareHoldingFields}
          {lastRow}
          {/* {
            totalPercentage !== 100
              ? <div style={{
                  color: "#EA2A33",
                  fontSize: "10px",
                  padding: "10px 22px",
                  width: "100%"
                }} >
                  {ErrorConstants.TOTAL_PERCENTAGE_SHOULD_BE_100}
                </div>
              : null 
          } */}
        </div>
        const shareholdingAccordion =  {
            head: <></>,
            body: <>{shareholdingTableComponent}</>,
            type: 'column',
          };
        renderFormFields.push(shareholdingAccordion);
      }
    });

    return renderFormFields;
  }
};


const renderEmptyList = () => {
  let renderFormFields = [];
  renderFormFields.push(getFullLineMsg())
  return renderFormFields;
}

export const GET_MOCK_ACCORDION_DATA = ({isEdit= false, subAccordion = false, subAccordionData = [], leadDetails = {}, documents=[], sectionStatusList = [], approveSection, allAccordionState, setAllAccordionState, loanAppId, companyID, productID, dispatch, showAlert, setLeadDetails, allDocuments, setAllDocuments, titleDocDetails, setTitleDocDetails, setOffer  }, handlePopupVisibility, handlePopupSubmit) => {
  try {
    const getFilteredFields = () => {
      let fields = LEAD_DETAILS_TAB_ACCORDION_FIELDS
      if(isEdit){
        fields = LEAD_DETAILS_TAB_EDIT_ACCORDION_FIELDS
      }
      return fields({leadDetails, documents, allDocuments, titleDocDetails, allAccordionState}).filter(item=>!item?.isHideSection)
    }
    const lead_accordian_fields = isEdit ? LEAD_DETAILS_TAB_EDIT_ACCORDION_FIELDS : LEAD_DETAILS_TAB_ACCORDION_FIELDS;
    const accordionFields = subAccordion ? subAccordionData : LEAD_DETAILS_TAB_ACCORDION_FIELDS;
    const filtered_lead_accordian_fields = getFilteredFields()

    return filtered_lead_accordian_fields.map((accordion, index) => {
      if (accordion?.isContainsAccordion) {
        let sectionStatus = '';
        let subSectionStatuses = []
        const updateSectionStatus = (status) => {
          subSectionStatuses.push(status)
          if(!subSectionStatuses?.includes(LeadStatus.approved.value)){
            sectionStatus = status
          } else {
            sectionStatus = LeadStatus.approved.value
          }
        }
        return {
          head: formatAccordHeader(accordion?.sectionName),
          iconHoverData: 'test',
          body: formatAccordBody('Body content 1'),
          data: accordion?.subAccordionData?.length ? accordion?.subAccordionData.map((subAccordion, subIndex) => {
            const subSectionStatus = sectionStatusList?.find(item=>item?.section_name === subAccordion?.sectionStatusName)
            updateSectionStatus(subSectionStatus?.section_status)
            const title= subAccordion?.isSubAccordion ? subAccordion?.sectionName + ' ' + (subIndex + 1) : subAccordion?.sectionName
            // console.log("subAccordion", subAccordion);
            return {
              head: formatAccordHeader(subAccordion?.sectionName),
              iconHoverData: 'test',
              body: formatAccordBody('Body content 1'),
              data: getDynamicLeadFormFields(accordion?.sectionName, accordion?.subAccordionData, true, subAccordion?.subIndex, allAccordionState, setAllAccordionState, leadDetails, showAlert, dispatch, allDocuments, setAllDocuments, titleDocDetails, setTitleDocDetails, approveSection, subSectionStatus, title, subAccordion?.sectionName),
              title: title,
              subtitle: null,
              rightComponent: formatStatus(subSectionStatus?.section_status ?? ''),
              bottomComponent: bottomComponent({
                sectionName: accordion?.sectionName, 
                subSectionName: title,
                handlePopupVisibility, 
                approveSection, 
                sectionStatus:subSectionStatus, 
                isEdit,
                allAccordionState,
                setAllAccordionState,
                section: subAccordion.section,
                loanAppId,
                companyID,
                productID,
                dispatch,
                showAlert,
                setLeadDetails,
                leadDetails,
                subIndex
              }),
              type: 'column',
              subType: accordion?.isContainsAccordion ? 'accordion' : null,
            };
          }) : renderEmptyList(),
          title: accordion?.sectionName,
          subtitle: null,
          rightComponent: formatStatus(sectionStatus ?? ''),
          bottomComponent: null,
          type: 'column',
          subType: accordion?.isContainsAccordion ? 'accordion' : null,
        };
      } else {
        let sectionStatus = sectionStatusList?.find(item=>item?.section_name === accordion?.sectionStatusName)
        if(accordion?.sectionStatusName === 'Additional Document'){
          sectionStatus = {...sectionStatus, section_status: Section_Status.NOT_APPLICABLE}
        }
        const shouldRemoveBottomComponent = ["property_documents", "additional_documents"].includes(accordion.section);
        console.log("accordion", accordion);
        return {
          head: formatAccordHeader(accordion?.sectionName),
          iconHoverData: 'test',
          body: formatAccordBody('Body content 1'),
          data: getDynamicLeadFormFields(accordion?.sectionName, lead_accordian_fields({leadDetails, documents, allDocuments, titleDocDetails, allAccordionState}), false, 0, allAccordionState, setAllAccordionState, leadDetails, showAlert, dispatch, allDocuments, setAllDocuments, titleDocDetails, setTitleDocDetails, approveSection, sectionStatus, accordion?.sectionStatusName, accordion?.sectionName),
          title: accordion?.isSubAccordion ? accordion?.sectionName + ' ' + (index + 1) : accordion?.sectionName,
          subtitle: null,
          rightComponent: formatStatus(sectionStatus?.section_status ?? ''),
          bottomComponent: shouldRemoveBottomComponent 
           ? null
           : bottomComponent({
            sectionName: accordion?.sectionName, 
            handlePopupVisibility, 
            approveSection, 
            sectionStatus,
            isEdit,
            allAccordionState,
            setAllAccordionState,
            section: accordion.section,
            loanAppId,
            companyID,
            productID,
            dispatch,
            showAlert,
            setLeadDetails,
            leadDetails
          }),
          type: 'column',
          subType: accordion?.isContainsAccordion ? 'accordion' : null,
        };
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const GET_DOCUMENT_LIST = (fieldsArr = []) => {
  return fieldsArr.map((item, index) => {
    return {
      label: `Document ${index+1}`,
      value: item?.name,
      type: 'field',
      direction: 'column',
    }
  })
}

export const GET_FINAL_SUB_SECTION_SUBMIT_DATA = (sectionStatusList) => {
  let finalSubSectionData = {}
  const getSectionCode = (section, sub_section) => {
    if(Multi_Values_Section.includes(section.section_code)) return `${section.section_name}_final_submit`;
    else return sub_section?.sub_section_code
  }
  sectionStatusList.forEach(section=>{
    section.subsections.forEach(subSection=>{
      if (subSection?.sub_section_code?.includes('final')) {
        finalSubSectionData = {
          ...finalSubSectionData,
          [getSectionCode(section, subSection)]: subSection
        }
      }
    })
  })
  return finalSubSectionData
}
 export const GET_VALIDATION_STATUS = (status) => {
  if(!status) return '';
  switch (status) {
    case Section_Status.Approved:
    case Section_Status.Rejected:
      return status;
    case Section_Status.YET_TO_START:
      return Section_Status.In_Progress;
    default:
      return Section_Status.In_Review;
  }
 }

export const GET_FINAL_SECTION_VALIDATION_CHECKLIST = (validation_checklist) => {
  if (validation_checklist?.length) {
    const checkList = validation_checklist?.map((item) => {
      return {
        label: item?.validation_name?.replace(/_/g, ' '),
        value: GET_VALIDATION_STATUS(item?.validation_status),
        type: 'validation_checklist_fields',
        direction: 'column',
        remarks: item?.validation_remarks,
      }
    })
    if (checkList?.length) {
      return [{
        label: 'validation checklist',
        value: '',
        type: 'subHeading',
        direction: 'row',
      }, ...checkList]
    }
    return []
  }
  return []
}
export const GET_OWNER_DETAILS = (owners, leadDetails, isEdit=false) => {
  if (owners?.length) {
    return owners?.map((owner, index) => {
      const {_id = ""} = owner;
      return {
        label: `Owner Full Name ${index+1}`,
        value: owner?.prop_owner_name,
        type: isEdit ? 'custom_dropdown' : 'field',
        direction: 'column',
        options: isEdit ? setOwnerDropDownValues(leadDetails) : null,
        stateKey: isEdit ? `property-details:${_id}:prop_owner_name` : null,
        validator: (newValue) => {
          // const allOwners = owners?.filter((currOwner) => currOwner !== _id).map(currOwner => currOwner?.prop_owner_name);
          if(newValue === "") {
            return [false , `Owner full name ${index+1} is required`];
          } else {
            const isValid = true// !allOwners.includes(newValue);
            return [isValid , "The owner with the same name is already added"];
          }
        }
      }
    })
  }
  return []
}
 
const setOwnerDropDownValues = (loanInfo) => {
  try {
      const arrOwnerInformation = [];
      const applicantInfo = {
          label: `${loanInfo?.first_name || ''} ${loanInfo?.middle_name || ''} ${loanInfo?.last_name || ''}`.trim(),
          value: `${loanInfo?.first_name || ''} ${loanInfo?.middle_name || ''} ${loanInfo?.last_name || ''}`.trim(),
          prop_owner_type: "Applicant",
          prop_owner_pan: loanInfo?.appl_pan
      };
      arrOwnerInformation.push(applicantInfo);
      if (loanInfo?.entity_details && loanInfo?.entity_details?.entity_type !== 'Individual') {
          const objEntityInfo = {
              label: loanInfo?.entity_details?.entity_name,
              value: loanInfo?.entity_details?.entity_name,
              prop_owner_type: "Entity",
              prop_owner_pan: loanInfo?.entity_details?.pan_no
          }
          arrOwnerInformation.push(objEntityInfo);
      };
      if (loanInfo?.coborrower) {
          const arrCoborrowerInfo = loanInfo?.coborrower.map((coborrowerInfo) => ({
              label: `${coborrowerInfo?.cb_fname || ''} ${coborrowerInfo?.cb_mname || ''} ${coborrowerInfo?.cb_lname || ''}`.trim(),
              value: `${coborrowerInfo?.cb_fname || ''} ${coborrowerInfo?.cb_mname || ''} ${coborrowerInfo?.cb_lname || ''}`.trim(),
              prop_owner_type: "Co-Borrower",
              prop_owner_pan: coborrowerInfo?.cb_pan
          }));
          arrOwnerInformation.push(...arrCoborrowerInfo)
      }
      return arrOwnerInformation;
  } catch (error) {
      throw error;
  }
}
export const GET_SHARE_HOLDERS_DETAILS = (shareHolders, leadDetails = null, isEdit = false) => {
  if (shareHolders?.length) {
    const allPercentageValues = shareHolders?.map((shareHolder) => {
      return {
        value: shareHolder?.share_holder_perc,
        id: shareHolder?._id,
        stateKey: `shareholding-structure:${shareHolder?._id}`,
      }
    });
    const shareHolderJSON = shareHolders?.map((shareHolder) => {
      // console.log("shareHolder", shareHolder)
      return {
        type: isEdit ? 'custom_shareholding_table' : 'field',
        direction: 'column',
        stateKey: `shareholding-structure:${shareHolder?._id}`,
        label: isEdit ? `${shareHolder?.share_holder_name} Percentage` : `share holder name & percentage`,
        value: isEdit ? shareHolder?.share_holder_perc : `${shareHolder?.share_holder_name} | ${shareHolder?.share_holder_perc}%`,
        id: shareHolder?._id,
        name: shareHolder?.share_holder_name,
        nameStateKey: "share_holder_name",
        percentageStateKey: "share_holder_perc",
        nameHelperText: 'Special characters and numbers are not allowed',
        allPercentageValues
      }
    })

    if (shareHolderJSON?.length) {
      return isEdit ? shareHolderJSON : [...shareHolderJSON, ...getfillersFromList(shareHolderJSON, Number_Constants.FOUR), {
        label: 'Total Percentage',
        value: `${_reduce(shareHolders, (sum, shareHolder)=> {
          return sum + shareHolder?.share_holder_perc;
        }, 0)}%`,
        type: 'field',
        direction: 'column',
      }]
    }
  }
  return []
}

export const FORMAT_AS_IND_CURRENCY = (num) => {
  if (num) {
    let rupee = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    });
    return rupee.format(num)?.replace('₹', '₹ ');
  }
  return ''
};

export const FORMAT_AS_SQFT = (value) => {
  if (value) {
    return `${value} Sqft`
  }
  return ''
};
const getTitleDocumentDetails = (id) => {
  const idName = `Title_Report_${id}`;
  const documentCode = DocumentListCode[idName]
  return {
    name: "Document",
    id: idName,// "property_legal_report",
    fileSize: "JPG, JPEG, PNG, PDF upto 5MB",
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode,
    docIndex: id-1,
  }
}
export const GET_PROPERTY_TITLE_DOCUMENTS = (allDocuments = {}, titleDocDetails = {}, title_documents = []) => {
  const defaultTitleDocument = [
    getTitleDocumentDetails(1)
  ];
  const { titleDocuments = [] } = allDocuments;
  const newTitleDocs = titleDocuments.length > 0 ? titleDocuments : defaultTitleDocument;
  const titleReportDocs = newTitleDocs.map(titleDocData => {
    const docId = titleDocData.docIndex + 1;
    const docCode = DocumentListCode[`Title_Report_${docId}`];
    const docDetailsToLookup = titleDocDetails[docCode] || title_documents[docId-1] || {};
    const name = docDetailsToLookup.doc_name || "";
    return {
      sectionName: `Title Document ${docId}`,
      id: docId,
      isDisabled: false,
      nameStateKey: `property-documents:title_documents:${docId}`,
      titleDocData,
      name,
      title_documents
    }
  });
  return titleReportDocs;
}
export const OFFER_STATUS = Object.freeze({
  none: "none",
  in_progress: "in_progress",
  conditional_offer: "conditional_offer",
  soft_offer: "soft_offer"
});
