import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';
import { createLoanIDApi, getBookLoanAPI, getLapLoanDocumentsApi, getGstStatusIDApi, postLoanDetailsApi, gstinVerifyStatusApi } from './../apis/bookLoan.api';
import { updatePreLoaderWatcher } from '../../actions/user';
import { Saga_Action_Type } from '../constants/string_constants';

export function* createLoanIDEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(createLoanIDApi, action.payload);
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* createLapLoanIDWatcherSaga() {
  yield takeLatest('CREATE_LAP_LOANID', createLoanIDEffectSaga);
}

export function* getBookLoanEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getBookLoanAPI, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getLapBookLoanWatcherSaga() {
  yield takeLatest('GET_LAP_BOOK_LOAN_DETAILS', getBookLoanEffectSaga);
}

export function* postLoanDetailsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(postLoanDetailsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* postLapLoanDetailsWatcherSaga() {
  yield takeLatest('POST_LAP_LOAN_DETAILS', postLoanDetailsEffectSaga);
}

export function* getLapLoanDocumentsEffectSaga(action) {
  try {
    const response = yield call(getLapLoanDocumentsApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}
export function* getLapLoanDocumentsWatcherSaga() {
  yield takeEvery(Saga_Action_Type.Fetch_Lap_Loan_Document, getLapLoanDocumentsEffectSaga);
}

export function* gstinVerifyStatusEffectSaga(action) {
  try {
    const response = yield call(gstinVerifyStatusApi, action.payload);
    action.resolve(response.data);
  } catch (e) {
    action.reject(e);
  }
}
export function* gstinVerifyStatusWatcherSaga() {
  yield takeEvery('GSTIN_VERIFY_STATUS', gstinVerifyStatusEffectSaga);
}

export function* getGstStatusIDEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getGstStatusIDApi, action.payload);
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* getLapGstStatusIDWatcherSaga() {
  yield takeLatest('GET_LAP_GST_ID_STATUS', getGstStatusIDEffectSaga);
}
