import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-sdk/dist/components/Button';
import 'react-sdk/dist/styles/_fonts.scss';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import { addRoleWatcher, getRoleWatcher, updateRoleWatcher } from '../../actions/roleMetrix';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import AccessMetrixDropDown from '../../components/Dropdowns/AccessMetrixDropdown';
import WriteIcon from '../../assets/img/icons/editIcon.svg';
import { checkAccessTags } from '../../util/uam';
import { storedList } from '../../util/localstorage';
import Pagination from '../../../node_modules/react-sdk/dist/components/Pagination/Pagination';
import Table from 'react-sdk/dist/components/Table/Table';
const user = storedList('user');

function CreateRole() {
  const dispatch = useDispatch();
  const [role, setRole] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [buttonTitle, setButtonTitle] = useState('Add');
  const [selectedRow, setSelectedRow] = useState({});
  const [tags, setTags] = useState([]);
  const [count, setCount] = useState(true);
  const [page, setPage] = useState(0);
  const [displayRoleList, setDisplayRoleList] = useState([]);
  const [label, setLabel] = useState('Add role');
  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  useEffect(() => {
    if (isTagged && checkAccessTags(['tag_role_matrix_read', 'tag_role_matrix_read_write'])) getRoleList();
    if (!isTagged) getRoleList();
  }, []);

  const ButtonStyle = {
    width: '12%',
    borderRadius: '16px',
    fontSize: '15px',
    border: '1px solid white',
    color: 'white',
    marginLeft: '2%',
    height: '56px',
  };

  const titleStyle = {
    marginLeft: '-40%',
  };

  const titleStyle1 = {
    fontSize: '14px',
    marginLeft: '-40%',
    fontFamily: 'Montserrat-Regular',
  };

  const tagStyle = {
    marginLeft: '-70%',
    fontFamily: 'Montserrat-Regular',
    marginRight: '-50%',
    height: '45px',
    overflow: 'hidden',
  };

  const ActionStyle = {
    marginLeft: '60%',
  };

  const iconStyle = {
    marginLeft: '65%',
    height: '30px',
    filter: 'invert(33%) sepia(94%) saturate(1094%) hue-rotate(186deg) brightness(92%) contrast(90%)',
  };

  const columns = [
    {
      id: 'Sr. No.',
      label: 'SR. NO.',
      format: (rowData) => {
        return <div style={{ fontFamily: 'Montserrat-Regular' }}>{displayRoleList.indexOf(rowData) + 1 + page * 10}</div>;
      },
    },
    {
      id: 'title',
      label: <div style={titleStyle}>TITLE</div>,
      format: (rowData) => {
        return <div style={titleStyle1}>{rowData.title}</div>;
      },
    },
    {
      id: 'tag',
      label: 'TAG',
      format: (rowData) => {
        return (
          <div style={tagStyle}>
            <div style={{ overflowY: 'auto', height: '45px', width: '101.7%' }}>{Array(rowData.tags).toString()}</div>
          </div>
        );
      },
    },
    {
      id: 'action',
      label: <div style={ActionStyle}>ACTION</div>,
      format: (rowData) => (
        <img
          style={iconStyle}
          onClick={() => {
            if (isTagged && checkAccessTags(['tag_role_matrix_read_write'])) {
              handleSetForUpdate(rowData);
            }
          }}
          src={WriteIcon}
        />
      ),
    },
  ];

  const getRoleList = () => {
    new Promise((resolve, reject) => {
      dispatch(getRoleWatcher(resolve, reject));
    })
      .then((response) => {
        setRoleList(response);
        setDisplayRoleList(response.slice(0, 10));
        setCount(response?.length);
      })
      .catch((error) => {
        console.log(error);
        setAlert(true);
        setSeverity('error');
        setAlertMessage(error.response.data.message);
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
      });
    handleClear();
  };

  const handleSubmit = () => {
    let tagsList = [];
    tags?.map((item) => {
      tagsList.push(item.label || item);
    });
    const data = {
      title: role,
      tags: tagsList,
    };
    if (role.match(/^[a-zA-Z0-9\-_\s]+$/)) {
      if (buttonTitle === 'Add') {
        new Promise((resolve, reject) => {
          dispatch(addRoleWatcher(data, resolve, reject));
        })
          .then((response) => {
            setAlert(true);
            setSeverity('success');
            setAlertMessage(response.message);
            setTimeout(() => {
              handleAlertClose();
            }, 4000);
            getRoleList();
          })
          .catch((error) => {
            setAlert(true);
            setSeverity('error');
            setAlertMessage(error.response.data.message);
            setTimeout(() => {
              handleAlertClose();
            }, 4000);
          });
      } else if (buttonTitle === 'Update') {
        new Promise((resolve, reject) => {
          data.id = selectedRow._id;
          dispatch(updateRoleWatcher(data, resolve, reject));
        })
          .then((response) => {
            setAlert(true);
            setSeverity('success');
            setAlertMessage(response.message);
            setTimeout(() => {
              handleAlertClose();
            }, 4000);
            getRoleList();
            setRole('');
            setLabel('Add role');
          })
          .catch((error) => {
            setAlert(true);
            setSeverity('error');
            setAlertMessage(error.response?.data?.message || 'Failed to update the role');
            setTimeout(() => {
              handleAlertClose();
            }, 4000);
          });
      }
    } else {
      setAlert(true);
      setSeverity('error');
      setAlertMessage('Title must be a string with no special characters except underscore(_) or hyphen (-)');
      setTimeout(() => {
        handleAlertClose();
      }, 4000);
    }
  };

  const handleClear = () => {
    setButtonTitle('Add');
    setSelectedRow({});
    setRole('');
    setTags([]);
    setPage(0);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleChangePage = (event) => {
    setPage(event);
    setDisplayRoleList(roleList.slice(event * 10, event * 10 + 10));
  };

  const handleSetForUpdate = (row) => {
    setLabel('Edit role');
    setRole(row?.title);
    setTags(row?.tags || []);
    setButtonTitle('Update');
    setSelectedRow(row);
  };

  const setTagValues = (value) => {
    let flag = true;
    tags?.forEach((tag) => {
      value.forEach((val) => {
        if (tag === val.label) flag = false;
      });
    });
    if (flag) setTags(value);
  };

  const headerStyle = {
    fontFamily: 'Montserrat-Bold',
    fontSize: '24px',
    marginLeft: '2%',
    marginBottom: '2%',
  };

  const InputBoxStyle = {
    width: '100%',
    height: '56px',
    marginLeft: '2%',
    fontFamily: 'Montserrat-Regular',
  };

  const metrixStyle = {
    width: '20%',
    marginLeft: '2%',
  };

  const TableStyle = {
    width: '96%',
    marginLeft: '2%',
    width: '96%',
  };

  const TableStyle1 = {
    width: '20%',
  };

  return (
    <>
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
      <header style={headerStyle}>{label}</header>
      <div style={{ display: 'flex' }}>
        <InputBox
          label="Title"
          type="text"
          placeholder="Title"
          initialValue={role}
          onClick={(event) => {
            setRole(event.value);
          }}
          customClass={InputBoxStyle}
        />
        <div style={metrixStyle}>
          <AccessMetrixDropDown
            onValueChange={(value) => {
              setTagValues(value);
            }}
            valueData={tags}
            placeholder={'Select tag names'}
            id={'access metrix'}
            helperText={'Please select at least one tag'}
          />
        </div>

        <Button label={buttonTitle} buttonType="primary" isDisabled={isTagged ? !checkAccessTags(['tag_role_matrix_read_write']) : false} onClick={handleSubmit} customStyle={ButtonStyle} />
        <Button label="Clear" buttonType="secondary" isDisabled={isTagged ? !checkAccessTags(['tag_role_matrix_read_write']) : false} onClick={handleClear} customStyle={{ ...ButtonStyle, backgroundColor: '#CC0000' }} />
      </div>
      <div>
        {isTagged ? (
          checkAccessTags(['tag_role_matrix_read', 'tag_role_matrix_read_write']) ? (
            <>
              <Table data={displayRoleList} columns={columns} customStyle={TableStyle} customCellCss={TableStyle1} />
              <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={10} />
            </>
          ) : null
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default CreateRole;
