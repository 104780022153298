import { documentCode } from '../../config/docCode';
const DOC_CODES_FOR_10MB_LIMIT = ['207', '208', '209', '041', '213'];
// FILE_LIMITS object holds a tuple against each property. Tuple format [fileLimitAsValue, fileLimitAsStrForErrorMsg]
const FILE_LIMITS = Object.freeze({
  five_mb_tuple: [5e6, '5 MB'],
  ten_mb_tuple: [10e6, '10 MB'],
});
const FILE_TYPE = {
  xml: 'xml',
  json: 'json',
  pdf: 'pdf',
  png: 'png',
  jpeg: 'jpeg',
  jpg: 'jpg',
};
const SIZE = 'size';
const MAGIC_NUMBERS = {
  zero: 0,
};
const ERROR = 'error';
const ONLY_XML_OR_JSON_ALERT = 'Only XML and JSON file is allowed ';
const ONLY_IMG_FILES_OR_PDF_ALERT = 'Only JPG,JPEG,PDF & PNG file is allowed';
const ONLY_PDF_ALERT = 'Only PDF file is allowed';
const FILE_SIZE_ERROR_PREFIX = 'File size should not be greater than';
const ERROR_DELETING_FILE = 'Error deleting file';

const UPLOAD_FILE_INPUT_CONST = {
  DOC_CODES_FOR_10MB_LIMIT,
  FILE_LIMITS,
  FILE_TYPE,
  SIZE,
  MAGIC_NUMBERS,
  ERROR,
  ONLY_XML_OR_JSON_ALERT,
  ONLY_IMG_FILES_OR_PDF_ALERT,
  ONLY_PDF_ALERT,
  FILE_SIZE_ERROR_PREFIX,
  ERROR_DELETING_FILE,
};

export default UPLOAD_FILE_INPUT_CONST;
