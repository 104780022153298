import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addAnchorFormWatcher } from "../../actions/anchor";
import { ANCHOR_BUSINESS_DETAILS_FIELDS } from "./formFields";
import {
  Countries,
  States,
  Cities,
  getStatesData,
  getCitiesData,
  getPincodeData
} from "../../constants/country-state-city-data";
import { stateCityWatcher } from "../../actions/stateCity";
import {
  verifyEmail,
  verifyUrl,
  verifyGSTIN,
  verifyPincode,
  verifyPhone,
  verifyMobile,
  verifyCIN,
  verifyAlphaNeumericName,
  verifyTIN,
  verifyState,
  verifyAlphaNeumeric,
  verifyAddress,
  verifyAlpha
} from "../../util/helper";
import Alert from "react-sdk/dist/components/Alert/Alert";
import "./anchorList.style.css";
import InputBox from "react-sdk/dist/components/InputBox/InputBox";
import circlePlusIcon from "assets/img/icons/circlePlusIcon.svg"
import circleMinusIcon from "assets/img/icons/circleMinusIcon.svg";
import Button from "react-sdk/dist/components/Button"
class AddAnchor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: Countries,
      states: States,
      cities: [],
      stateCityData: [],
      pincodes: [],
      //alert params
      alert: false,
      severity: "",
      alertMessage: "",
      anchorname: "",
      cin: "",
      address: "",
      pincode: "",
      city: "",
      state: "",
      gstin: "",
      tin: "",
      phone: "",
      directors: [],
      website: "",
      // type validation form
      anchornameState: "",
      cinState: "",
      gstinState: "",
      addressState: "",
      stateState: "",
      cityState: "",
      pincodeState: "",
      websiteState: "",
      phoneState: "",
      tinState: ""
    };
  }

  handleClear = () => {
    this.setState({
      anchorname: "",
      cin: "",
      address: "",
      pincode: "",
      city: null,
      state: null,
      gstin: "",
      tin: "",
      phone: "",
      directors: [],
      website: "",
      anchornameState: "",
      cinState: "",
      addressState: "",
      pincodeState: "",
      cityState: "",
      stateState: "",
      gstinState: "",
      tinState: "",
      phoneState: "",
      websiteState: ""
    });
  };

  handleDropdownChange = country => {
    if (country.value !== "") {
      this.setState({
        abCountry: country.value,
        abCountryState: "has-success"
      });
    } else {
      this.setState({ abCountryState: "has-danger" });
    }
  };

  handleDirectorNameChange = (evt, idx, stateNameEqualTo) => {
    const newdirectors = this.state.directors.map((director, sidx) => {
      if (idx !== sidx) return director;
      if (
        evt.value.length >= stateNameEqualTo &&
        verifyAlphaNeumericName(evt.value)
      ) {
        return {
          ...director,
          name: evt.value,
          resultState: "has-success"
        };
      } else {
        return {
          ...director,
          name: evt.value,
          resultState: "has-danger"
        };
      }
    });
    this.setState({ directors: newdirectors });
  };

  handleAddDirector = () => {
    if (this.state.directors.length < 7) {
      this.setState({
        directors: this.state.directors.concat([{ name: "", resultState: "" }])
      });
    } else {
      return false;
    }
  };

  handleRemoveDirector = idx => () => {
    if (this.state.directors.length > 0) {
      this.setState({
        directors: this.state.directors.filter((s, sidx) => {
          return idx !== sidx;
        })
      });
    } else {
      return false;
    }
  };

  change = (event, stateName, type, stateNameEqualTo, Value) => {
    switch (type) {
      case "length":
        if (event.value.length >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "email":
        if (verifyEmail(event.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "url":
        if (verifyUrl(event.value) || event.value === "") {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "gstin":
        if (verifyGSTIN(event.value) || event.value === "") {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "cin":
        if (verifyCIN(event.value) || event.value === "") {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "name":
        if (verifyAlphaNeumericName(event.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "tin":
        if (verifyTIN(event.value) || event.value === "") {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "state":
        if (verifyState(event.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "pincode":
        if (verifyPincode(event.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "phone":
        if (verifyPhone(event.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "mobile":
        if (verifyMobile(event.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "address":
        if (verifyAddress(event.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.value });
  };

  handleSubmit = () => {
    let data = {};
    const isValidated = this.isValidated();
    if (!isValidated) {
      this.setState({ alert: true });
      this.setState({ severity: "error" });
      this.setState({ alertMessage: "Kindly check for errors in fields." });
      setTimeout(() => {
        this.handleAlertClose();
      }, 4000);
    }

    if (isValidated === true) {
      data = {
        name: this.state.anchorname,
        cin: this.state.cin,
        anchor_address: this.state.address,
        pin_code: this.state.pincode,
        city: this.state.city.value,
        state: this.state.state.value,
        gstin: this.state.gstin,
        tin: this.state.tin,
        business_phone: this.state.phone,
        directors: this.state.directors.map(d => {
          return d.name;
        }),
        website: this.state.website
      };
    } else {
      return;
    }

    new Promise((resolve, reject) => {
      this.props.addAnchorFormWatcher(data, resolve, reject);
    })
      .then(response => {
        this.setState({ alert: true });
        this.setState({ severity: "success" });
        this.setState({ alertMessage: response.message });
        setTimeout(() => {
          this.handleAlertClose();
        }, 4000);
        this.handleClear();
      })
      .catch(error => {
        this.setState({ alert: true });
        this.setState({ severity: "error" });
        this.setState({ alertMessage: error.response.data.message });
        setTimeout(() => {
          this.handleAlertClose();
        }, 4000);
      });
  };

  isValidated = () => {
    if (
      this.state.anchornameState === "has-success" &&
      (this.state.cin === "" || this.state.cinState === "has-success") &&
      (this.state.gstin === "" || this.state.gstinState === "has-success") &&
      this.state.addressState === "has-success" &&
      this.state.stateState === "has-success" &&
      this.state.cityState === "has-success" &&
      this.state.pincodeState === "has-success" &&
      (this.state.website === "" ||
        this.state.websiteState === "has-success") &&
      this.state.phoneState === "has-success" &&
      (this.state.tin === "" || this.state.tinState === "has-success") &&
      this.state.directors.every(
        director => director.resultState === "has-success"
      )
    ) {
      return true;
    } else {
      if (this.state.anchornameState !== "has-success") {
        this.setState({ anchornameState: "has-danger" });
      }
      if (this.state.cin !== "" && this.state.cinState !== "has-success") {
        this.setState({ cinState: "has-danger" });
      }
      if (this.state.addressState !== "has-success") {
        this.setState({ addressState: "has-danger" });
      }
      if (this.state.pincodeState !== "has-success") {
        this.setState({ pincodeState: "has-danger" });
      }
      if (this.state.cityState !== "has-success") {
        this.setState({ cityState: "has-danger" });
      }
      if (this.state.stateState !== "has-success") {
        this.setState({ stateState: "has-danger" });
      }
      if (this.state.gstin !== "" && this.state.gstinState !== "has-success") {
        this.setState({ gstinState: "has-danger" });
      }
      if (this.state.tin !== "" && this.state.tinState !== "has-success") {
        this.setState({ tinState: "has-danger" });
      }
      if (
        this.state.website !== "" &&
        this.state.websiteState !== "has-success"
      ) {
        this.setState({ websiteState: "has-danger" });
      }

      if (this.state.phoneState !== "has-success") {
        this.setState({ phoneState: "has-danger" });
      }
      const validatedDirectors = this.state.directors.map(director => {
        if (director.resultState !== "has-success") {
          return {
            ...director,
            resultState: "has-danger"
          };
        } else {
          return {
            ...director,
            resultState: "has-success"
          };
        }
      });
      this.setState({ directors: validatedDirectors });
      return false;
    }
  };

  handleGetCities = async () => {
    this.setState({ cities: Cities(this.state.state) });
  };

  handleGetPincodes = async () => {
    const pincodesData = await getPincodeData(
      this.state.stateCityData,
      this.state.city
    );
    this.setState({ pincodes: pincodesData });
  };

  handleClearDropdown = name => {
    if (name === "state") {
      this.setState({ city: "", pincode: "", cities: [], pincodes: [] });
    }
    if (name === "city") {
      this.setState({ pincode: "", pincodes: [] });
    }
    if (name === "lmsVersion") {
      this.setState({ lmsVersionState: "", lmsVersion: [] });
    }
  };

  dropDownChange = (value, field) => {
    value = this.state[field.dataSet].find(item=>item?.label === value);
    if (field.name === "pincode" && value !== null && value !== undefined) {
      value = value.label;
    }
    this.setState(
      {
        [field.name]: value,
        [`${field.name}State`]: "has-success"
      },
      () => {
        if (value === null || value === undefined)
          return this.handleClearDropdown(field.name);
        if (field.name === "state" && value !== null && value !== undefined) {
          this.handleClearDropdown(field.name);
          this.handleGetCities();
        }
        if (field.name === "city" && value !== null && value !== undefined) {
          this.setState({ pincode: "", pincodes: [] });
          this.handleGetPincodes();
        }
      }
    );
  };

  handleAlertClose = () => {
    this.setState({ alert: false });
    this.setState({ severity: "" });
    this.setState({ alertMessage: "" });
    setTimeout(() => {
      this.handleAlertClose();
    }, 4000);
  };

  getStatseData = async data => {
    const statesData = await getStatesData(data);
    this.setState({ states: statesData });
  };

  handleGetStateCity = async () => {
    const { stateCity } = this.props;
    if (stateCity?.length) {
      this.setState({ stateCityData: stateCity });
      this.getStatseData(stateCity);
    } else {
      new Promise((resolve, reject) => {
        this.props.stateCityWatcher(null, resolve, reject);
      })
        .then(response => {
          this.setState({ stateCityData: response });
          this.getStatseData(response);
        })
        .catch(error => {
        });
    }
  };



  render() {
    const { classes } = this.props;
    const { city } = this.state;
    return (
      <>
        {this.state.alert ? (
          <Alert
            severity={this.state.severity}
            message={this.state.alertMessage}
            handleClose={this.handleAlertClose}
            style={{
              position: 'fixed',
            }}
          />
        ) : null
        }
        <div className="anchor-container-style">
          <div className="create-anchor-container-style">
            {ANCHOR_BUSINESS_DETAILS_FIELDS(this.state).map(
              (field, index) => {
                return (
                  <>
                    {field.component === "text" ? (
                      <>
                        <InputBox
                          id={field.name}
                          label={field.placeholder}
                          isDrawdown={false}
                          initialValue={this.state[field.name] ?? ""}
                          onClick={e => {
                            this.change(
                              e,
                              field.name,
                              field.condition,
                              field.value
                            )
                          }}
                          customClass={{
                            height: '56px',
                            width: '100%',
                            maxWidth: '100%',
                            fontFamily:'Montserrat-Regular',
                          }}
                          customInputClass={{
                            minWidth:'100%',
                            backgroundColor: '#fff',
                          }}
                          error={field.resultState === "has-danger"}
                          helperText={
                            field.resultState === "has-danger" ?
                              field.errorMsg :
                              ""
                          }
                        />
                      </>
                    ) : field.component === "select" ||
                      field.component === "selectMulti" ? (
                      <>
                        <InputBox
                          id={field.name}
                          label={field.placeholder}
                          isDrawdown={true}
                          initialValue={this.state[field.name]?.label ?? ""}
                          onClick={() => { }}
                          onDrawdownSelect={value => {
                            this.dropDownChange(value, field)
                          }}
                          customDropdownClass={{
                            zIndex: '2',
                            marginTop: '2%'
                          }}
                          customClass={{
                            height: "56px",
                            width: "100%",
                            maxWidth: "100%",
                            fontFamily:'Montserrat-Regular',
                          }}
                          options={this.state[field.dataSet]}
                          error={field.resultState === 'has-danger'}
                          helperText={
                            field.resultState === 'has-danger'
                              ? field.errorMsg
                              : ""
                          }
                        />
                      </>
                    ) : null}
                  </>
                );
              }
            )}
          </div>
          <hr />
          <div className="add-director-icon-container">
            <div className="add-director-text-style">Add director</div>
            <img src={circlePlusIcon} className="add-director-icon-style" onClick={this.handleAddDirector} />
          </div>
          <div className="create-anchor-container-style">
            {this.state.directors.map((director, idx) => (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputBox
                    id={`Director ${idx + 1} name`}
                    label={`Director ${idx + 1} name`}
                    isDrawdown={false}
                    initialValue={director.name}
                    onClick={e => this.handleDirectorNameChange(e, idx, 2)}
                    customClass={{
                      height: '56px',
                      width: '100%',
                      maxWidth: '100%',
                      fontFamily:'Montserrat-Regular',
                    }}
                    customInputClass={{
                      minWidth: '100%',
                      backgroundColor: '#fff',
                    }}
                    error={director.resultState === "has-danger"}
                    helperText={
                      director.resultState === "has-danger"
                        ? "Enter valid director name"
                        : ""
                    }
                  />
                  <img src={circleMinusIcon} className="delete-director-icon-style" onClick={this.handleRemoveDirector(idx)} />
                </div>
              </>
            ))}
          </div>
          <hr />
          <div className="submit-button-container-style">
            <Button
              label="SUBMIT"
              onClick={this.handleSubmit}
              buttonType="primary"
              customStyle={{
                height: '2.5rem',
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'center',
                boxShadow: 'none',
                backgroundColor: 'white',
                fontFamily: 'Montserrat-Regular',
                padding: '1.5rem',
                border: '1px solid #134CDE',
                borderRadius: '5px',
              }}
            />
            <Button
              label="CLEAR"
              onClick={this.handleClear}
              buttonType="primaryBTN"
              customStyle={{
                color: 'white',
                backgroundColor: "#D32F2F",
                height: '2.5rem',
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'center',
                boxShadow: 'none',
                fontFamily: 'Montserrat-Regular',
                padding: '1.5rem',
                borderRadius: '5px',
              }}

            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    stateCity: state.stateCity?.stateCityData
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addAnchorFormWatcher,
      stateCityWatcher
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAnchor);
