import { updatePreLoaderWatcher } from 'actions/user';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getBusinessModelApi } from 'serviceFeeRecon/apis/createBusinessModel.api';
import { CheckBusinessModelExistApi } from 'serviceFeeRecon/apis/createBusinessModel.api';
import { getSRFProductDetailsApi } from 'serviceFeeRecon/apis/createBusinessModel.api';
import { createBusinessModelApi } from 'serviceFeeRecon/apis/createBusinessModel.api';
import { getSFRProductsApi } from 'serviceFeeRecon/apis/createBusinessModel.api';
import { getSFRCompaniesApi } from 'serviceFeeRecon/apis/createBusinessModel.api';

export function* getSFRCompaniesEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getSFRCompaniesApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getSFRCompaniesWatcherSaga() {
  yield takeLatest('GET_SFR_COMPANIES_WATCHER', getSFRCompaniesEffectSaga);
}

export function* getSFRProductsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getSFRProductsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getSFRProductsWatcherSaga() {
  yield takeLatest('GET_SFR_PRODUCTS_WATCHR', getSFRProductsEffectSaga);
}

export function* getSRFProductsDetailsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getSRFProductDetailsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getSRFProductDetailsWatcherSaga() {
  yield takeLatest('GET_SRF_PRODUCT_DETAILS_WATCHER', getSRFProductsDetailsEffectSaga);
}

export function* createBusinessModelEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(createBusinessModelApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* createBusinessModelWatcherSaga() {
  yield takeLatest('CREATE_BUSINESS_MODEL_WATCHER', createBusinessModelEffectSaga);
}

export function* getBusinessModelEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getBusinessModelApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data.data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}
export function* getBusinessModelWatcherSaga() {
  yield takeLatest('GET_BUSINESS_MODEL_WATCHER', getBusinessModelEffectSaga);
}

export function* checkBusinessModelExistEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(CheckBusinessModelExistApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* checkBusinessModelExistWatcherSaga() {
  yield takeLatest('CHECK_BUSINESS_MODEL_EXIST', checkBusinessModelExistEffectSaga);
}
