import { takeLatest, call } from 'redux-saga/effects';
import { uploadExcelApi } from '../apis/DataUpload.api';

export function* uploadExcelSaga(action) {
  try {
    const response = yield call(uploadExcelApi, action.payload);
    action.resolve(response);
  } catch (e) {
    action.reject(e);
  }
}

export function* uploadExcelWatcherSaga() {
  yield takeLatest('DATA_UPLOAD_WATCHER', uploadExcelSaga);
}
