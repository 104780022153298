export const selectorFormJsonFields = () => [
  {
    title: 'First Name',
    name: 'first_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Middle Name',
    name: 'middle_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Last Name',
    name: 'last_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Dob',
    name: 'dob',
    presentInLoanApi: 'No',
    Type: 'dob',
    Readonly: true,
  },
  {
    title: 'Appl Pan',
    name: 'appl_pan',
    presentInLoanApi: 'No',
    Type: 'pan',
    Readonly: true,
  },
  {
    title: 'Gender',
    name: 'gender',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Appl Phone',
    name: 'appl_phone',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Address',
    name: 'resi_addr_ln1',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'City',
    name: 'city',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'State',
    name: 'state',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Pincode',
    name: 'pincode',
    presentInLoanApi: 'No',
    Type: 'pincode',
    Readonly: true,
  },
  {
    title: 'Product Type Code',
    name: 'product_type_code',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Loan APP Id',
    name: 'loan_app_id',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Enquiry Purpose',
    name: 'enquiry_purpose',
    presentInLoanApi: 'No',
    Type: 'string',
  },
  {
    title: 'Enquiry Stage',
    name: 'enquiry_stage',
    presentInLoanApi: 'No',
    Type: 'string',
  },
  {
    title: 'Bureau Type',
    name: 'bureau_type',
    presentInLoanApi: 'No',
    Type: 'string',
  },
  {
    title: 'Dscr',
    name: 'dscr',
    presentInLoanApi: 'No',
    Type: 'float',
  },
  {
    title: 'Consent',
    name: 'consent',
    presentInLoanApi: 'No',
    Type: 'confirmation',
  },
  {
    title: 'Consent Timestamp',
    name: 'consent_timestamp',
    presentInLoanApi: 'No',
    Type: 'dateTime',
  },

  {
    title: 'Request Id A Score',
    name: 'request_id_a_score',
    presentInLoanApi: 'No',
    Type: 'string',
  },
  {
    title: 'Request Id B Score',
    name: 'request_id_b_score',
    presentInLoanApi: 'No',
    Type: 'string',
  },
  {
    title: 'Ceplr Cust Id',
    name: 'ceplr_cust_id',
    presentInLoanApi: 'No',
    Type: 'string',
  },
  {
    title: 'Monthly Income',
    name: 'monthly_income',
    presentInLoanApi: 'No',
    Type: 'float',
  },
  {
    title: 'Sanction Amount',
    name: 'sanction_amount',
    presentInLoanApi: 'No',
    Type: 'float',
  },
  {
    title: 'Tenure',
    name: 'tenure',
    presentInLoanApi: 'No',
    Type: 'integer',
  },
  {
    title: 'Interest Rate',
    name: 'interest_rate',
    presentInLoanApi: 'No',
    Type: 'float',
  },
  {
    title: 'Co Lender Shortcode',
    name: 'co_lender_shortcode',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co Lender Assignment Id',
    name: 'co_lender_assignment_id',
    presentInLoanApi: 'No',
    Type: 'number',
    Readonly: true,
  },
  {
    title: 'Co Lender Full Name',
    name: 'co_lender_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower First Name',
    name: 'cb_first_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower Middle Name',
    name: 'cb_middle_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower Last Name',
    name: 'cb_last_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower DOB',
    name: 'cb_dob',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower PAN',
    name: 'cb_appl_pan',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower-Gender',
    name: 'cb_gender',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower Phone',
    name: 'cb_appl_phone',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower Address',
    name: 'cb_address',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower City',
    name: 'cb_city',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower State',
    name: 'cb_state',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Co-Borrower Pincode',
    name: 'cb_pincode',
    presentInLoanApi: 'No',
    Type: 'number',
    Readonly: true,
  },
  {
    title: 'Borrower Profile',
    name: 'borrower_profile',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
];
