import React, { useState, useEffect } from 'react';
import UploadFileInput from '../../components/uploadFileInput/UploadFileInput';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import TextField from '@mui/material/TextField';
// import { AlertBox } from '../../../components/CustomAlertbox';
import SlidingAlert from '../../components/SlidingAlert/SlidingAlert'
import { storedList } from '../../../util/localstorage';
import { useDispatch, useSelector } from 'react-redux';
import { patchLapDetailsWatcher, putLapDraftSaverWatcher, patchLapSaveAsDraftDetailsWatcher, subSectionDeleteWatcher, patchLapDocDeleteWatcher } from '../../actions/lap.action';
import { ITRInputTittle, BankList, BankType } from './data';
import './financialDocument.css';
import '../bookLoans/bookLoans.style.css';
import { getBookLoanDetailsWatcher, getLapLoanDocumentsWatcher, GSTINVerifyWatcher } from './../../actions/bookLoan.action';
import { postComprehensiveLogsWatcher } from '../../../actions/comprehensiveLogs.action';
import { SectionData } from '../../config/sectionData';
import getSectionStatus from '../bookLoans/GetLeadSectionStatus/GetLeadSectionStatus';
import { documentCode } from '../../config/docCode';
import { getLeadStatusWatcher } from '../../actions/lead.action';
import { LeadNewStatus } from '../../config/LeadStatus';
import Preloader from '../../../components/custom/preLoader';
import getSubSectionRemarks from '../bookLoans/GetLeadSectionStatus/GetLeadSubSectionRemarks';
import PasswordViewer from '../../components/PasswordViewer';
import { getSubmitButton } from '../../util/resuableJsx/reusableJsx';
import { getSaveAsDraftButton } from '../../util/resuableJsx/reusableJsx';
import { bookLoansFormJsonFields, FinancialDocDeptEnum, FinancialDocFieldEnum } from './bookLoansFormJson';
import './bookLoans.scss'
import { ONLY_NUMBERS, ALLOW_ONLY_SINGLE_SPACE } from '../../constants/pattern_constants';
import { Financial_Docs_Label, Comment } from '../../constants/string_constants';
import { Number_Constants } from '../../constants/number_constants';
import { verifyGSTIN } from "../../../util/helper";

const UploadComponent = (props) => {
    const LAST_6_MONTH_BANK_STATEMENT = 'Last 6 months Bank Statement';
    const ADD_STATEMENT = 'Add Statement';
    const PDF_UPTO_10_MB = 'PDF upto 10MB';
    const PDF_EXTENTION = '.pdf';
    const FILE_AS_STR = 'file';
    const BANK_STATEMENT = 'Bank Statement'
    const GST_CERTIFICATE = 'GST Certificate';
    const GSTR = 'GSTR';
    const ITR = 'ITR';
    const BS_1 = 'bs1';
    const GST_1 = 'gst1';
    const GST_2 = 'gst2';
    const CHECK_1 = 'check1'
    const CHECK_2 = 'check2'
    const CHECK_4 = 'check4'
    const FD_SUB_SECTION_CODE = 'financial_doc_gst'
    const VERIFY_AND_NEXT = 'Verify & Next'
    const SAVE_AS_DRAFT = 'Save as Draft'
    const MAGIC_NUMBERS = {
        zero: 0,
        twelve: 12,
        five_hundered: 500
    }
    const SECTION_STATUS_CHECK = {
        in_progress: 'inProgress',
        completed: 'completed'
    }
    const SECTION_STATUS = {
        deviation: 'deviation', 
        approved: 'approved', 
        rejected: 'rejected',
        failed: "failed"
    };
    const STATUS = {
        success: 'success',
        error: 'error',
        failed: 'failed'
    };
    const NAV_STATE = {
        financial_docs: 'Financial Docs',
        additional_docs: 'Additional Docs',
        property_details: 'Property Details'
    };
    const SUCCESS_ALERT = {
        draft_saved_successfully: 'Draft saved successfully',
        gstn_verified_successfully: 'GSTIN Verified Successfully',
        verify_and_next_successfull: 'Financial documents uploaded successfully'
    };
    const ERROR_ALERT = {
        gst_rejected: 'GST Rejected',
        submit_rejected: 'Submit Rejected',
        something_went_wrong: 'Something went Wrong',
        please_upload_gst_certificate: 'please upload GST Certificate',
        error_while_resetting_draft: 'Error while resetting draft',
        error_while_saving_draft: 'Error while saving draft',
        lead_rejected: `Your lead has been rejected`
    };

    const initialCheckboxes = {
        check1: false,
        check2: false,
        check4: false,
    };

    const documentCodes = {
        ITR: [documentCode.itr1, documentCode.itr2],
        GSTR: [documentCode.gstr],
        BS: [documentCode.lap_bank_statement],
    };

    const sectionStatus = ['deviation', 'approved', 'rejected'];

    const handleCheckboxChange = (id) => {
        const newCheckBoxes = {
            ...checkboxes,
            [id]: !checkboxes[id],
        }
        setCheckboxes({
            ...checkboxes,
            [id]: !checkboxes[id],
        });
        if (checkboxes[id] && documentList.length) {
            setDisabled(true);
            handleCheckboxDeselect(id);
        }
        // if (id === CHECK_1 && checkboxes[id]) {
        //     setIsValidItrSection(false)
        // }
        // if (id === CHECK_2 && !newCheckBoxes[id]) {
        //     handleGstrSectionDelete();
        // }
        if(id === CHECK_4 && !newCheckBoxes[id]) {
            handleBsSectionDelete();
        }
    };

    const handleGstrSectionDelete = (id) => {
        const payload = {
            loan_app_id: loanAppId,
            section_code: MAGIC_NUMBERS.five_hundered,
            sub_section_code: FD_SUB_SECTION_CODE,
            tokenData: {
                user_id: user?._id,
                product_id: LAPProductId,
                company_id: LAPCompanyId,
            },
        };
        setGstSectionValidation({
            isValidGstr: false,
            isValidGstCertificate: false
        })
        // new Promise((resolve, reject) => {
        //     dispatch(subSectionDeleteWatcher(payload, resolve, reject));
        // })
        //     .then((response) => { })
        //     .catch((error) => { });
    };
    const handleBsSectionDelete = () => {
         // removing BS Doc fields
         bankDocuments.reverse().forEach(doc => {
             removeBankStatement(doc.id, false);
         });
        // resting other BS fields
        let postData = {
            section: 'financial-documents',
            borro_bank_code: null,
            borro_bank_name: null,
            borro_bank_branch: null,
            borro_bank_acc_num: null,
            borro_bank_ifsc: null,
            borro_bank_type: null,
            doc_key: null,
            doc_code: documentCode.lap_bank_statement,
            financial_docs_comment: null,
        };
    
        postData.loan_app_id=loanAppId,
        postData.userData= {
                user: user,
                user_id: user?._id,
                product_id: LAPProductId,
                company_id: LAPCompanyId,
                loan_app_id: loanAppId,
            };
            
        // return;

        saveLogs(loanAppId, 'financial_documents_BS_reset');
        new Promise((resolve, reject) => {
            dispatch(patchLapSaveAsDraftDetailsWatcher(postData, resolve, reject));
        })
            .then((response) => {
                setDocumentList([]);
                setBankDetailstate({});
                setDocPassword('');
                setBankInputItems(bankInputInitialValue);
                setSelectReasonComment('');
                setBankDocuments(bankInputInitialValue.map((givenObj) => {
                    const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
                    if (matchingObj) {
                        return {
                            ...givenObj,
                            s3_url: matchingObj.file_url,
                            doc: matchingObj,
                        };
                    }
                    return givenObj;
                })
                )
                
                setBankStatementCount(MAGIC_NUMBERS.zero)
                getLoanDocuments(MAGIC_NUMBERS.zero);
           
                // setDisableDraftButton(true);
            })
            .catch((error) => {
                showAlert(error?.message ?? ERROR_ALERT.error_while_resetting_draft, STATUS.error);
               
            });
    }
    const handleCheckboxDeselect = (id) => {
        const index = Object.keys(initialCheckboxes).indexOf(id);
        const keys = Object.keys(documentCodes);
        const key = keys[index];
        const correspondingArray = documentCodes[key];
        const payload = {
            loanAppId: loanAppId,
            tokenData: {
                user: user,
                user_id: user?._id,
                product_id: LAPProductId,
                company_id: LAPCompanyId,
            },
            code: correspondingArray,
        };
        new Promise((resolve, reject) => {
            dispatch(patchLapDocDeleteWatcher(payload, resolve, reject));
        })
            .then((response) => {
                getLoanDocuments();
            })
            .catch((error) => { });
    };

    const navigateToNextSection = (status = STATUS.success) => {
        setNavIconPrefixState((prevState) => ({
            ...prevState,
            [NAV_STATE.financial_docs]: status,
        }));
        setNavState(NAV_STATE.additional_docs);
    }
    const [checkboxes, setCheckboxes] = useState(initialCheckboxes);

    const [disable, setDisabled] = useState(true);

    const { setNavIconPrefixState, setNavState, loanAppId, LAPCompanyId, LAPProductId, navIconPrefixState, documents } = props;
    const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.profile.loading);
    const [selectReasonComment, setSelectReasonComment] = useState('');
    // const [onlyView, setOnlyView] = useState(false);
    const [loader, setLoader] = useState(false);
    const [ITRstate, setITRstate] = useState({});
    const [GSTstate, setGSTstate] = useState({});
    const [FSIstate, setFSIstate] = useState({});
    const [BankDetailstate, setBankDetailstate] = useState({});
    const [BankStatementstate, setBankStatementstate] = useState({});
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('');
    let intervalId, fileInterval;
    const [GSTIn, setGSTIn] = useState('');
    const sectionName = 'financial-documents';
    const [disableFields, setDisableFields] = useState(false);
    const [disableDraftButton, setDisableDraftButton] = useState(false);
    const [sectionStatusCheck, setSectionStatusCheck] = useState('');
    const [allDocuments, setAllDocuments] = useState(documents);
    const [statusObject, setStatusObject] = useState('');
    const [entityDetails, setEntityDetails] = useState(false);
    const [leadStatus, setLeadStatus] = useState('');
    const [documentList, setDocumentList] = useState([]);
    const [isLeadRejected, setIsLeadRejected] = useState(false);
    const [uploadedBS, setUploadedBS] = useState([]);
    const [disableAddBs, setDisableAddBs] = useState(true);
    const [isValidAccNo, setIsValidAccNo] = useState(true);
    const bankInputInitialValue = [
        {
            id: BS_1,
            name: LAST_6_MONTH_BANK_STATEMENT,
            fileSize: PDF_UPTO_10_MB,
            acceptFileType: PDF_EXTENTION,
            fileType: FILE_AS_STR,
            documentCode: documentCode.lap_bank_statement,
            docIndex: MAGIC_NUMBERS.zero,
        },
    ]
    const [bankInputItems, setBankInputItems] = useState(bankInputInitialValue);

    const [gstinItems, setGstinItem] = useState([
        {
            id: GST_1,
            name: GST_CERTIFICATE,
            fileSize: PDF_UPTO_10_MB,
            acceptFileType: PDF_EXTENTION,
            fileType: FILE_AS_STR,
            documentCode: documentCode.gst_certificate,
        },
    ]);

    const [gstrItems, setGstrItems] = useState([
        {
            id: GST_2,
            name: GSTR,
            fileSize: PDF_UPTO_10_MB,
            acceptFileType: PDF_EXTENTION,
            fileType: FILE_AS_STR,
            documentCode: documentCode.gstr,
        },
    ]);
    const [bankStatementCount, setBankStatementCount] = useState(0);
    const [gstinDisable, setGSTinDisable] = useState(false);
    const [isGstVerified, setIsGstVerified] = useState(false);
    const [isEnabledGstFields, setIsEnabledGstFields] = useState(false);
    const [gstButtonState, setGstButtonState] = useState('button');
    const [gstBorder, setGstBorder] = useState('border: 1px solid #BBBFCC');
    const [docPassword, setDocPassword] = useState('');
    const [isValidGstIn, setIsValidGstIn] = useState(true);

    useEffect(() => {
        if (loanAppId && LAPCompanyId && LAPProductId && props.type === 'view') {
            getLoanDocuments();
            // fetchLeadStatus();
            fetchLoanDetails();
        }

    }, []);

    useEffect(() => {
        if (loanAppId && LAPCompanyId && LAPProductId && props.type === 'view') {
            getLoanDocuments();
        }
    }, [entityDetails]);

    useEffect(() => {
        if (leadStatus && statusObject) {
            if (leadStatus === LeadNewStatus.Pending && statusObject.section_status === LeadNewStatus.InProgress) {
                setDisabled(false);
            }
        }
    }, [leadStatus, statusObject]);

    const [financialDocuments, setFinancialDocuments] = useState(
        ITRInputTittle.map((givenObj) => {
            const matchingObj = documents?.find((otherObj) => otherObj.code === givenObj.documentCode);
            if (matchingObj) {
                return {
                    ...givenObj,
                    s3_url: matchingObj.file_url,
                    doc: matchingObj,
                };
            }
            return givenObj;
        }),
    );

    const handleCommentSection = (event) => {
        const formattedStr = (event.target.value || "").trimStart().replaceAll(ALLOW_ONLY_SINGLE_SPACE, " ");
        const limittedValue = Array.from(formattedStr).splice(Number_Constants.ZERO, Number_Constants.TWO_HUNDRED).join("");
        setSelectReasonComment(limittedValue);
    }

    const [gstrDocuments, setGstrDocuments] = useState(
        gstinItems.map((givenObj) => {
            const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
            if (matchingObj) {
                return {
                    ...givenObj,
                    s3_url: matchingObj.file_url,
                    doc: matchingObj,
                };
            }
            return givenObj;
        }),
    );

    const [gstr, setGstr] = useState(
        gstrItems.map((givenObj) => {
            const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
            if (matchingObj) {
                return {
                    ...givenObj,
                    s3_url: matchingObj.file_url,
                    doc: matchingObj,
                };
            }
            return givenObj;
        }),
    );

    const [bankDocuments, setBankDocuments] = useState(
        bankInputItems.map((givenObj) => {
            const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
            if (matchingObj) {
                return {
                    ...givenObj,
                    s3_url: matchingObj.file_url,
                    doc: matchingObj,
                };
            }
            return givenObj;
        }),
    );
    const isItrUrlValid = (financialDocuments[0].s3_url !== undefined) ? true : false;
    const [isValidItrSection, setIsValidItrSection] = useState(isItrUrlValid);
    const [gstSectionValidation, setGstSectionValidation] = useState({
        isValidGstr: gstr[0].s3_url !== undefined ? true : false, 
        isValidGstCertificate: gstrDocuments[0].s3_url !== undefined ? true : false
    });
    const checkBoxList = [
        { id: CHECK_1, value: ITR, label: ITR },
        { id: CHECK_2, value: GSTR, label: GSTR },
        { id: CHECK_4, value: BANK_STATEMENT, label: BANK_STATEMENT },
    ];

    useEffect(() => {
        if (loanAppId && props.type === 'view') {
            fetchLoanDetails();
        }
    }, [loanAppId]);

    useEffect(() => {
        if (navIconPrefixState[NAV_STATE.financial_docs] === STATUS.success) {
            setDisableFields(true);
        }
    }, []);

    const handleDropdownBankNameChange = (event, data) => {
        setBankDetailstate((prevData) => ({
            ...prevData,
            borro_bank_name: event?.label ?? null,
            borro_bank_code: event?.value ?? null,
        }));
    };
    const handleDropdownAccTypeChange = (event, data) => {
        setBankDetailstate((prevData) => ({
            ...prevData,
            borro_bank_type: event?.value ?? null,
        }));
    };

    const handleAccNoChange = (event) => {
        const accNo = event.value;
        const newIsValidAccNo = ONLY_NUMBERS.test(accNo);
        setIsValidAccNo(newIsValidAccNo);
        setBankDetailstate((prevData) => ({
            ...prevData,
            borro_bank_acc_num: event.value ?? null,
        }));
    };
    const handlePasswordChange = (event) => {
        setBankDetailstate((prevData) => ({
            ...prevData,
            doc_key: event.value ?? null,
        }));
    };
    const showAlert = (msg, type) => {
        setAlert(true);
        setSeverity(type);
        setAlertMessage(msg);
    };

    const getLoanDocuments = (count = 0) => {
        setLoader(true);
        const payload = {
            loanAppID: loanAppId,
            companyId: LAPCompanyId,
            productId: LAPProductId,
            user: user,
            user_id: user?._id,
        };
        new Promise((resolve, reject) => {
            dispatch(getLapLoanDocumentsWatcher(payload, resolve, reject));
        })
            .then((response) => {
                if (response && Array.isArray(response)) {
                    const codes = Object.keys(documentCodes)
                        .map((key) => documentCodes[key])
                        .flat();
                    const total_docs = response.filter((doc) => codes.includes(doc.code));
                    setDocumentList((prevState) => total_docs);
                    if (response.some((item) => documentCodes.ITR.includes(item.code))) {
                        checkboxes[CHECK_1] = true;
                    }
                    if (response.some((item) => documentCodes.GSTR.includes(item.code))) {
                        checkboxes[CHECK_2] = true;
                    }
                    if (response.some((item) => documentCodes.BS.includes(item.code))) {
                        checkboxes[CHECK_4] = true;
                        const financialDocDetails = response.filter((item) => item.code === documentCode.lap_bank_statement);
                        setBankDetailstate((prevData) => ({
                            ...prevData,
                            doc_key: financialDocDetails.length ? financialDocDetails[0].doc_key : '',
                        }));
                    }
                    const getMatchingObjFunc = (response, fdFieldItems) => {
                       const getMatchingObj = (givenObj) => {
                            const matchingObj = response?.find((otherObj) => otherObj.code === givenObj.documentCode);
                            if (matchingObj) {
                                return {
                                    ...givenObj,
                                    s3_url: matchingObj.file_url,
                                    doc: matchingObj,
                                };
                            }
                            return givenObj;
                        }
                        return getMatchingObj
                    }
                    if (response) {
                        const getMatchingObj = getMatchingObjFunc(response) 
                        setFinancialDocuments(
                            ITRInputTittle.map(givenObj => getMatchingObj(givenObj)),
                        );
                        setGstrDocuments(
                            gstinItems.map(givenObj => getMatchingObj(givenObj)),
                        );
                        setGstr(
                            gstrItems.map(givenObj => getMatchingObj(givenObj)),
                        );
                        const isLapState = response?.some((otherObj) => otherObj.code === documentCode.lap_bank_statement)
                        if (isLapState) {
                            const matchingObj = response?.find((otherObj) => otherObj.code === documentCode.lap_bank_statement);
                            const bankInputItemsUpdate = [];
                            const bankDocumentsUpdate = [];
                            if (matchingObj?.doc_key && matchingObj?.doc_key != '') {
                                setDocPassword(matchingObj.doc_key);
                            }
                            matchingObj?.additional_file_url?.map((item) => {
                                if (item) {
                                    if (bankDocumentsUpdate.length) {
                                        bankInputItemsUpdate.push({
                                            id: `bs${bankInputItemsUpdate.length + 1}`,
                                            name: `${bankInputItemsUpdate.length ? ADD_STATEMENT : LAST_6_MONTH_BANK_STATEMENT}`,
                                            fileSize: PDF_UPTO_10_MB,
                                            acceptFileType: PDF_EXTENTION,
                                            fileType: FILE_AS_STR,
                                            documentCode: documentCode.lap_bank_statement,
                                            docIndex: bankDocumentsUpdate.length,
                                            s3_url: item,
                                            doc: matchingObj
                                        });
                                    }
                                    bankDocumentsUpdate.push({
                                        id: `bs${bankInputItemsUpdate.length + 1}`,
                                        name: `${bankInputItemsUpdate.length ? ADD_STATEMENT : LAST_6_MONTH_BANK_STATEMENT}`,
                                        fileSize: PDF_UPTO_10_MB,
                                        acceptFileType: PDF_EXTENTION,
                                        fileType: FILE_AS_STR,
                                        documentCode: documentCode.lap_bank_statement,
                                        docIndex: bankInputItemsUpdate.length,
                                        s3_url: item,
                                        doc: matchingObj,
                                    });
                                }
                            });
                            setBankDocuments(bankDocumentsUpdate);
                            setBankInputItems(bankDocumentsUpdate);
                            setBankStatementCount(matchingObj?.additional_file_url?.length);
                            if (matchingObj?.additional_file_url?.length === count) {
                                clearInterval(fileInterval);
                            }
                        } else {
                            setBankDocuments(
                                bankInputInitialValue.map((givenObj) => {
                                    return givenObj;
                                }),
                            );
                        }
                    }
                    setDisabled((prevState) => (total_docs.length ? false : true));
                    setLoader(false);
                }
            })
            .catch((error) => {
                showAlert(error.response?.data?.message, STATUS.error);
                setLoader(false);
                if (fileInterval) clearInterval(fileInterval);
            });
    };
    const handleFinanceDocumentDraft = (flag) => {
        setDisableDraftButton(true);
        let postData = {
            section: 'financial-documents',
            borro_bank_code: '',
            borro_bank_name: '',
            borro_bank_branch: '',
            borro_bank_acc_num: '',
            borro_bank_ifsc: '',
            borro_bank_type: '',
            doc_key: '',
            doc_code: documentCode.lap_bank_statement,
            financial_docs_comment: ''
        };
        if (!flag) {
            postData.borro_bank_name = BankDetailstate.borro_bank_name ?? '';
            postData.borro_bank_acc_num = BankDetailstate.borro_bank_acc_num ?? '';
            postData.borro_bank_type = BankDetailstate.borro_bank_type ?? '';
            postData.doc_key = BankDetailstate.doc_key ?? '';
            postData.borro_bank_code = BankDetailstate.borro_bank_code ?? '';
            postData.fina_docs_gstin = BankDetailstate.fina_docs_gstin ?? GSTIn ?? '';
            postData.financial_docs_comment = selectReasonComment
        }
    
        postData.loan_app_id=loanAppId,
        postData.userData= {
                user: user,
                user_id: user?._id,
                product_id: LAPProductId,
                company_id: LAPCompanyId,
                loan_app_id: loanAppId,
            };
            
        // return;

        saveLogs(loanAppId, 'financial_documents_save_as_draft');
        new Promise((resolve, reject) => {
            dispatch(patchLapSaveAsDraftDetailsWatcher(postData, resolve, reject));
        })
            .then((response) => {
                setDisableDraftButton(false);
                if (!flag) showAlert(SUCCESS_ALERT.draft_saved_successfully, STATUS.success);
                // fetchLoanDetails();
                // getLoanDocuments();
                // setDisableDraftButton(true);
            })
            .catch((error) => {
                showAlert(error?.message ?? ERROR_ALERT.error_while_saving_draft, STATUS.error);
                setDisableDraftButton(true);
            });
    };

    const bankStatementStyle = {
        marginTop: '20px',
        width: '100%',
        columnGap: '42px',
    };

    const bankInputBoxStyle = {
        height: '58px',
        maxWidth: '45vw',
        marginBottom: '30px',
    };
    const headingStyle = {
        marginTop: '20px',
        position: 'relative',
        top: '10px',
        fontWeight: '600',
        color: '#32325d',
        marginLeft: "1.7%",
        marginBottom: '14px',
    };

    const uploadTittleStyle = {
        position: 'relative',
        top: '10px',
        fontWeight: '600',
        color: '#32325d',
        marginLeft: "1.7%",
        marginBottom: '14px',
        fontSize: '0.9375rem'
    };
    const h6passwordStyle = {
        marginTop: '20px',
        marginLeft: "1.7%",
    };

    const handleSubmit = (event) => {
        if (isLeadRejected) {
            return showAlert(ERROR_ALERT.lead_rejected, STATUS.error);
        }
        setDisableDraftButton(true);
        let matchingObj = {};
        const postData = {
            section: 'financial-documents',
            fina_docs_gstin: '',
            borro_bank_code: '',
            borro_bank_name: '',
            borro_bank_branch: '',
            borro_bank_acc_num: '',
            borro_bank_ifsc: '',
            borro_bank_type: '',
            doc_key: '',
            doc_code: documentCode.lap_bank_statement,
            financial_docs_comment: selectReasonComment
        };
        if (BankDetailstate) {
            postData.borro_bank_name = BankDetailstate.borro_bank_name ?? '';
            postData.borro_bank_acc_num = BankDetailstate.borro_bank_acc_num ?? '';
            postData.borro_bank_type = BankDetailstate.borro_bank_type ?? '';
            postData.doc_key = BankDetailstate.doc_key ?? '';
            postData.borro_bank_code = BankDetailstate.borro_bank_code ?? '';
        }
        if (GSTIn) {
            postData.fina_docs_gstin = GSTIn;
        }
        postFinancialDetails(postData);
    };

    const setStatusCheckApi = async (loanAppID, sectionCode, subSectionCode, dispatch, flag) => {
        intervalId = setInterval(async () => {
            try {
                let status = await getSectionStatus(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch, flag);
                let subSectionRemarks = await getSubSectionRemarks(loanAppID, user, LAPCompanyId, LAPProductId, sectionCode, subSectionCode, dispatch);
                const status_list = [SECTION_STATUS.approved, SECTION_STATUS.deviation, SECTION_STATUS.rejected, SECTION_STATUS.failed];
                let sectionStatus = status?.section_status ?? '';
                if (typeof status == 'object') {
                    if ([status?.sub_section_status].includes(SECTION_STATUS.approved)) {
                        status = SECTION_STATUS.approved;
                    } else if ([status?.sub_section_status].includes(SECTION_STATUS.deviation)) {
                        status = SECTION_STATUS.deviation;
                    } else {
                        status = status?.sub_section_status;
                    }
                } else {
                    status = status?.toLowerCase();
                }
                if (status_list.includes(status)) {
                    clearInterval(intervalId);
                }
                if (subSectionCode === SectionData.financial_docs.financial_doc_section_submit.sub_section_code) {
                    if (status === SECTION_STATUS.approved && sectionStatus == SECTION_STATUS.approved) {
                        setNavState(NAV_STATE.additional_docs);
                        setNavIconPrefixState((prevState) => ({
                            ...prevState,
                            [NAV_STATE.financial_docs]: STATUS.success,
                        }));
                        setSectionStatusCheck(SECTION_STATUS_CHECK.completed);
                    } else if (status == SECTION_STATUS.rejected || sectionStatus == SECTION_STATUS.rejected) {
                        showAlert(ERROR_ALERT.submit_rejected, STATUS.error);
                        setGstButtonState('button');
                        setSectionStatusCheck('');
                        setIsLeadRejected(true);
                    }
                }

                if (subSectionCode === SectionData.financial_docs.financial_doc_gst.sub_section_code) {
                    if ([SECTION_STATUS.approved, SECTION_STATUS.deviation].includes(status)) {
                        setGstBorder('1px solid green');
                        showAlert(SUCCESS_ALERT.gstn_verified_successfully, STATUS.success);
                        setGstButtonState('icon');
                        setGSTinDisable(true);
                    }
                    if (status == SECTION_STATUS.rejected) {
                        showAlert(subSectionRemarks || ERROR_ALERT.gst_rejected, STATUS.error);
                        setGstButtonState('button');
                    }
                    if (status == STATUS.failed) {
                        showAlert(ERROR_ALERT.something_went_wrong, STATUS.error);
                        setGstButtonState('button');
                    }
                }

                if (sectionStatus == SECTION_STATUS.approved) {
                    if (subSectionCode === SectionData.financial_docs.financial_doc_section_submit.sub_section_code) {
                        setNavState(NAV_STATE.additional_docs);
                        setNavIconPrefixState((prevState) => ({
                            ...prevState,
                            [NAV_STATE.financial_docs]: STATUS.success,
                        }));
                        setSectionStatusCheck(SECTION_STATUS_CHECK.completed);
                    }
                    clearInterval(intervalId);
                } else if (sectionStatus == SECTION_STATUS.deviation) {
                    if (subSectionCode === SectionData.financial_docs.financial_doc_section_submit.sub_section_code) {
                        setNavState(NAV_STATE.additional_docs);
                        setNavIconPrefixState((prevState) => ({
                            ...prevState,
                            [NAV_STATE.financial_docs]: STATUS.success,
                        }));
                        setSectionStatusCheck(SECTION_STATUS_CHECK.completed);
                    }
                    clearInterval(intervalId);
                } else if (sectionStatus == SECTION_STATUS.rejected) {
                    clearInterval(intervalId);
                }
            } catch (error) {
                clearInterval(intervalId);
            }
        }, 10000);
    };
    const isAllFieldsValid = (updatedBankItems, allUploadedBS) => {
        const isAllBsUploaded = updatedBankItems.reduce((acc, curr) => {
            const isCurrBsUploaded = allUploadedBS.includes(curr.id);
            return isCurrBsUploaded && acc;
        } ,  true);
        if(isAllBsUploaded) {
            setDisabled(false);
            setDisableAddBs(false);
        }
    }
    const addBankStatement = () => {
        const newItemId = `bs${bankInputItems?.length + 1}`;
        const newItem = {
            id: newItemId,
            name: bankInputItems.length === MAGIC_NUMBERS.zero ? LAST_6_MONTH_BANK_STATEMENT : ADD_STATEMENT,
            fileSize: PDF_UPTO_10_MB,
            acceptFileType: PDF_EXTENTION,
            fileType: FILE_AS_STR,
            documentCode: documentCode.lap_bank_statement,
            docIndex: bankDocuments.length,
        };
        if (bankDocuments.length < MAGIC_NUMBERS.twelve) {
            setBankInputItems([...bankInputItems, newItem]);
            setBankDocuments([...bankDocuments, newItem]);
            setDisabled(true);
            setDisableAddBs(true);
        } else {
            setAlert({
                open: true,
                severity: 'warning',
                alertMessage: 'You have reached the maximum number of bank statements',
            });
        }
    };

    const handleAlertClose = () => {
        setAlert(false);
        setSeverity('');
        setAlertMessage('');
    };
    const removeBankStatement = (id, shouldGetDocsFromDb = true) => {
        const updatedItems = bankInputItems.filter((item) => item.id !== id).map((item, index) => {
            return {...item, id: `bs${index+1}`}
        });
        const updatedBankItems = bankDocuments.filter((item) => item.id !== id).map((item, index) => {
            return {...item, id: `bs${index+1}`}
        });
        setBankInputItems(updatedItems);
        setBankDocuments(updatedBankItems);
        deleteBankStatement(id, shouldGetDocsFromDb);
        isAllFieldsValid(updatedBankItems, uploadedBS);
    };

    const handleDataFromChild = (event, doc) => {
        setLoader(true);
        const updatedDocs = [...uploadedBS, event];
        setUploadedBS(updatedDocs);
        isAllFieldsValid(bankDocuments, updatedDocs);
        const type = String(event).split('');
        if (type.length > 2 && type[0] == 'b' && type[1] == 's') {
            fileInterval = setInterval(() => {
                setLoader(false);
            }, 3000);
        } else {
            setLoader(false);

            // getLoanDocuments();
        }
    };
    const deleteBankStatement = (id, shouldGetDocsFromDb = true) => {
        const payload = {
            loanAppId: loanAppId,
            tokenData: {
                user: user,
                user_id: user?._id,
                product_id: LAPProductId,
                company_id: LAPCompanyId,
            },
            code: documentCodes.BS,
            codeIndex: id?.split('bs')?.reverse()?.[0],
        };
        if (payload.code && payload.codeIndex) {
            new Promise((resolve, reject) => {
                dispatch(patchLapDocDeleteWatcher(payload, resolve, reject));
            })
                .then((response) => {
                    if(shouldGetDocsFromDb) {
                        getLoanDocuments();
                    }
                })
                .catch((error) => {
                    getLoanDocuments();
                });
        }
    };

    const topHeadingStyle = {
        fontFamily: 'Montserrat-SemiBold',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#161719',
        marginLeft: "1.7%"
    };

    const setInitialData = (response) => {
        let financialStatus = response;
        let updatedData = {
            ...BankDetailstate,
        };
        if (financialStatus && Object.values(financialStatus).length) {
            if(financialStatus.data && Array.isArray(financialStatus.data)) {
                updatedData = {
                    ...updatedData,
                    ...financialStatus.data[MAGIC_NUMBERS.zero]
                }
                if (updatedData.borro_bank_acc_num || updatedData.borro_bank_name || updatedData.borro_bank_type) checkboxes[CHECK_4] = true;
                setSelectReasonComment(financialStatus?.data[0]?.financial_docs_comment);
                const comment = financialStatus.data[MAGIC_NUMBERS.zero].fina_docs_comment || ""
                setSelectReasonComment(comment)
                
            }
        }

        setBankDetailstate((prevData) => ({
            ...prevData,
            ...updatedData,
        }));

        if (financialStatus.bankStatements?.length) {
            let bankInputItems = financialStatus.bankStatements.map((item) => {
                return {
                    id: `bs${bankInputItems?.length + 1}`,
                    name: item,
                    fileSize: PDF_UPTO_10_MB,
                    acceptFileType: PDF_EXTENTION,
                };
            });
            setBankInputItems(bankInputItems);
        }
        if (financialStatus.financial_documents.fina_docs_gstin) {
            setGSTIn(financialStatus.financial_documents.fina_docs_gstin);
        }
    };

    const fetchLoanDetails = () => {
        new Promise((resolve, reject) => {
            dispatch(
                getBookLoanDetailsWatcher(
                    {
                        loan_app_id: loanAppId,
                        user: user,
                        user_id: user?._id,
                    },
                    resolve,
                    reject,
                ),
            );
        })
            .then((response) => {
                setInitialData(response);
                setLeadStatus(response?.lead_status);
                setLoader(false);
            })
            .catch((error) => { });
    };

    const postFinancialDetails = (payload) => {
        payload = {
            ...payload,
            user: user,
            user_id: user?._id,
            lap_product_id: LAPProductId,
            lap_company_id: LAPCompanyId,
            loan_app_id: loanAppId,
            section_sequence_no: SectionData.financial_docs.section_sequence_no,
            section_name: SectionData.financial_docs.section_name,
            sub_section_code: SectionData.financial_docs.financial_doc_section_submit.sub_section_code,
            sub_section_name: SectionData.financial_docs.financial_doc_section_submit.sub_section_name,
            sub_section_sequence_no: SectionData.financial_docs.financial_doc_section_submit.sub_section_sequence_no,
            section_code: SectionData.financial_docs.section_code,
        };
        saveLogs(loanAppId, 'financial_documents_verify_and_next');
        new Promise((resolve, reject) => {
            dispatch(patchLapDetailsWatcher(payload, resolve, reject));
        })
            .then((response) => {
                setSectionStatusCheck(SECTION_STATUS_CHECK.in_progress);
                setStatusCheckApi(loanAppId, SectionData.financial_docs.section_code, SectionData.financial_docs.financial_doc_section_submit.sub_section_code, dispatch, true);
                setTimeout(() => {
                    showAlert(SUCCESS_ALERT.verify_and_next_successfull, STATUS.success);
                }, 3100);
                // getLoanDocuments();
            })
            .catch((error) => {
                showAlert(error?.response?.data?.message, STATUS.error);
            });
    };

    const change = (e, type, name) => {
        const newValue = e.value;
        if(newValue !== undefined) {
            setGSTIn(newValue);
            const isValidGstinValue = verifyGSTIN(newValue);
            setIsValidGstIn(isValidGstinValue);
        }
        const buttonLable = e.target?.textContent;
        if (buttonLable === 'Verify') {
            if (name === 'gstin_value') {
                const payload = {
                    loan_app_id: loanAppId,
                    companyId: LAPCompanyId,
                    productId: LAPProductId,
                    user: user,
                    user_id: user?._id,
                    gstin_no: GSTIn
                };
                setGstButtonState('loader')
                new Promise((resolve, reject) => {
                dispatch(GSTINVerifyWatcher(payload, resolve, reject));
                }).then((response) => {
                                showAlert(SUCCESS_ALERT.gstn_verified_successfully, STATUS.success);
                        setGstButtonState('icon');
                        setIsGstVerified(true);
                        setIsEnabledGstFields(true);
                        setGstBorder('1px solid green');
})
                    .catch((error)=>{
                        const errorMsg = error?.response?.data?.message;
                        showAlert(errorMsg, 'error');
                        const shouldEnableOtherGSTFields = !["Invalid GSTIN provided", "Something went wrong, Please contact the administrator"].includes(errorMsg);
                        if(shouldEnableOtherGSTFields) {
                            setIsEnabledGstFields(true);
                        } else {
                            setIsEnabledGstFields(false);
                        }
                        setGstButtonState('button');
                    })
            }
        }
    };

    const gstValueVerify = async (value, name, type) => {
        setGstButtonState('loader');
        if (name === 'gstin_value') {
            await callValidator(
                {
                    fina_docs_gstin: value,
                    sequence: 1101,
                    section_code: 'financial_doc',
                    section_name: 'Financial Document',
                    sub_section_code: 'financial_doc_gst',
                    section_sequence_no: 500,
                    sub_section_name: 'Financial Document GST Check',
                    sub_section_sequence_no: 1,
                },
                'gst',
            );
        }
    };

    const callValidator = async (payload, stage) => {
        const body = {
            ...payload,
            section: 'financial-documents',
            loan_app_id: loanAppId,
            company_id: LAPCompanyId,
            user: user,
            user_id: user?._id,
            product_id: LAPProductId,
            lap_company_id: LAPCompanyId,
            lap_product_id: LAPProductId,
        };
        saveLogs(loanAppId, 'financial_documents_gst_verify');
        new Promise((resolve, reject) => {
            dispatch(patchLapDetailsWatcher(body, resolve, reject));
        })
            .then((response) => {
                setStatusCheckApi(loanAppId, body.section_code, body.sub_section_code, dispatch, false);
            })
            .catch((error) => {
                if (stage === 'gst') setGstButtonState('button');
                showAlert(error?.response?.data?.message ?? error?.message ?? ERROR_ALERT.error_while_saving_draft, STATUS.error);
            });
    };

    const saveLogs = (identifier, event_name) => {
        new Promise((resolve, reject) => {
            const payload = {
                user_id: user?._id,
                identifier: identifier,
                log_details: [
                    {
                        user_id: user?._id,
                        event_name: event_name,
                        timestamp: new Date(),
                    },
                ],
            };
            if (payload?.user_id && identifier && event_name) {
                dispatch(postComprehensiveLogsWatcher(payload, resolve, reject));
            }
        });
    };

    const getFields = (field) => {
        const isChange = props.type === 'view' || navIconPrefixState[NAV_STATE.property_details] == STATUS.success ? false : true; // sectionStatus.includes(statusObject?.section_status) ? false : true
        switch (field) {
            case FinancialDocFieldEnum.fd_itr:
                return <>{financialDocuments.map((doc, index) => (
                <><UploadFileInput 
                    onDataCallback={(event) => {
                        handleDataFromChild(event, [doc]);
                        if (index === 0) {
                            setIsValidItrSection(true);
                        }
                    }}
                    items={[doc]} 
                    title="" 
                    setState={setITRstate} 
                    loanAppId={loanAppId} 
                    LAPCompanyId={LAPCompanyId} 
                    LAPProductId={LAPProductId} 
                    sectionName={sectionName} 
                    isChange={isChange} 
                    backgroundColorBlur={disableFields ? true : false} 
                    data={{ company_id: LAPCompanyId, product_id: LAPProductId }} 
                    showAlert={showAlert} 
                /></>))}</>;
            case FinancialDocFieldEnum.fd_gstin:
                const gstinValue = disableFields ? GSTIn : (GSTIn ?? '').toUpperCase() ?? ''
                return (
                    <>
                        <InputBox
                        isBoxType={gstButtonState}
                        Buttonlabel={'Verify'}
                        initialValue={gstinValue}
                        isDisabled={gstinDisable || disableFields || isGstVerified}
                        id={'gstin'}
                        label={'GSTIN'}
                        isDrawdown={false}
                        onClick={(event) => change(event, 'GST', 'gstin_value')}
                        customClass={{ height: '58px', width: '100%', maxWidth: '100%', border: gstBorder }}
                        customInputClass={{
                            minWidth: '100%',
                        }}
                        error={!(props.type === 'view') && !isValidGstIn}
                        helperText={gstinValue === "" ? "GSTIN is required" : "Please enter a valid GSTIN No."} />
                    </>
                )
            case FinancialDocFieldEnum.fd_gst_docs:
                return (<><UploadFileInput
                    items={gstrDocuments}
                    title=""
                    setState={setGSTstate}
                    loanAppId={loanAppId}
                    LAPCompanyId={LAPCompanyId}
                    LAPProductId={LAPProductId}
                    sectionName={sectionName}
                    isChange={isChange}
                    backgroundColorBlur={(props.type == 'edit' || props.type) && !isEnabledGstFields ? true : false}
                    data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                    showAlert={showAlert}
                    isPdfOnly={true}
                    onDataCallback={() => {
                        setGstSectionValidation({
                            ...gstSectionValidation,
                            isValidGstCertificate: true
                        })
                    }}
                /> </>)
            case FinancialDocFieldEnum.fd_gst_add_doc:
                return (<><UploadFileInput
                    onDataCallback={(event) => {
                        handleDataFromChild(event, gstr);
                        setGstSectionValidation({
                            ...gstSectionValidation,
                            isValidGstr: true
                        });
                    }}
                    items={gstr}
                    title=""
                    setState={setGSTstate}
                    loanAppId={loanAppId}
                    LAPCompanyId={LAPCompanyId}
                    LAPProductId={LAPProductId}
                    sectionName={sectionName}
                    isChange={isChange}
                    backgroundColorBlur={(props.type == 'edit' || props.type) && !isEnabledGstFields ? true : false}
                    data={{ company_id: LAPCompanyId, product_id: LAPProductId }}
                    showAlert={showAlert}
                    isPdfOnly={true}
                /> </>)
            case FinancialDocFieldEnum.fd_bank_name:
                return <>
                    <InputBox 
                        label="Bank Name" 
                        isDrawdown={disableFields ? false : true} 
                        customClass={bankInputBoxStyle} 
                        initialValue={BankDetailstate.borro_bank_name} 
                        isDisabled={disableFields ? true : false} 
                        customDropdownClass={{ marginTop: '7px', zIndex: 5 }} 
                        options={BankList} 
                        onClick={(value) => handleDropdownBankNameChange(value)} 
                    />
                </>;
            case FinancialDocFieldEnum.fd_bank_acc_no:
                const bankAccNoField = bookLoansFormJsonFields().find((val)=> val.field === FinancialDocFieldEnum.fd_bank_acc_no)
                return <>
                    <InputBox 
                        label="Bank A/C No." 
                        type={'number'} 
                        isDrawdown={false} 
                        customClass={bankInputBoxStyle} 
                        customDropdownClass={{ marginTop: '7px', zIndex: 5 }} 
                        initialValue={BankDetailstate.borro_bank_acc_num} 
                        isDisabled={disableFields ? true : false} 
                        onClick={(e) => handleAccNoChange(e)} 
                        helperText={isValidAccNo ? "" : bankAccNoField.errorHelperTextRequired} 
                        error={!isValidAccNo} 
                    />
                </>
            case FinancialDocFieldEnum.fd_bank_acc_type:
                const getLabel = (bankTypeValue) => {
                   const requiredLabel = BankType.filter(bankTypeObj => bankTypeObj.value == bankTypeValue);
                   return requiredLabel[0]?.label
                }
                const labelValue = getLabel(BankDetailstate.borro_bank_type) || BankDetailstate.borro_bank_type;
                return <>
                    <InputBox 
                        label="Bank A/C Type" 
                        isDrawdown={disableFields ? false : true} 
                        customClass={bankInputBoxStyle} 
                        initialValue={labelValue} 
                        isDisabled={disableFields ? true : false} 
                        customDropdownClass={{ marginTop: '7px', zIndex: 5 }} 
                        options={BankType} 
                        onClick={(value) => handleDropdownAccTypeChange(value)} 
                    />
                </>
            case FinancialDocFieldEnum.fd_bank_statement:
                return <>{bankDocuments.map((bsDoc, index) => {
                    const columnCondition = (index+1) % 2;
                    const column = columnCondition === 0 ? 2 : 1;
                    const currRow = Math.floor(index / 2) + 1;
                    const alignment = column === 1 ? "start" : "end";
                    const bsWrapperStyle = {gridRow: `${currRow} / span 1`, gridColumn: `${column} / span 2`, width: "75%", justifySelf: alignment};
                    return (<>
                        <div style={bsWrapperStyle}>
                            <UploadFileInput 
                                setState={setBankStatementstate}
                                removeItem={removeBankStatement}
                                onDataCallback={(event) => handleDataFromChild(event, BankStatementstate)} 
                                items={[bsDoc]} 
                                loanAppId={loanAppId} 
                                LAPCompanyId={LAPCompanyId} 
                                LAPProductId={LAPProductId} 
                                sectionName={sectionName} 
                                hideRemove={disableFields ? true : false} 
                                isChange={isChange} 
                                data={{ company_id: LAPCompanyId, product_id: LAPProductId }} 
                                showAlert={showAlert} 
                            />
                        </div>
                    </>)
                })}</> 
            case FinancialDocFieldEnum.fd_bs_Password:
                const shouldMaskPassword = docPassword && docPassword != "" && props.type === "view"
                return <>
                    <InputBox 
                        label="Bank Statement Password" 
                        isDrawdown={false} 
                        customClass={bankInputBoxStyle} 
                        initialValue={BankDetailstate.doc_key} 
                        customDropdownClass={{ marginTop: '7px', zIndex: 5 }} 
                        onClick={(e) => handlePasswordChange(e)} 
                        isDisabled={disableFields ? true : false} 
                        isPassword={shouldMaskPassword} 
                        type={props.type === "view" ? "password": "text"} 
                    />
                </>
            default:
                return null;
        }
    }
    const renderFields = (departmentName) => {
        const style = {
            display: 'grid',
            rowGap: '28px',
            gridTemplateColumns: '32.8% 32.8% 32.8%',
            columnGap: '1%',
            width: '98%',
            marginLeft: '1.7%',
        } 
        return (
            <div className='ar-fd-section-pan-file-upload'>
            <>
                <div style={style}>
                    {bookLoansFormJsonFields().map((item, idx) => {
                        return (<>
                            {item.dept == departmentName ? getFields(item.field) : null}
                        </>)
                    })}
                </div>
            </>
            </div>
        )
    }
    const shouldEnableDraftForGst = (gstSectionValidation.isValidGstCertificate && gstSectionValidation.isValidGstr);
    const isValidBs = (BankDetailstate.borro_bank_name && BankDetailstate.borro_bank_acc_num && BankDetailstate.borro_bank_type) ? false : true;
    const shouldEnableDraftForBs = (!disable && !isValidBs);
    
    const shouldEnableVerifyAndNext  = Object.entries(checkboxes).reduce((acc, curr) => {
        const sectionValidation = {
            check1: isValidItrSection,
            check2: shouldEnableDraftForGst,
            check4: shouldEnableDraftForBs
        };

        const [sectionKey, isSectionIncluded] = curr;
        const shouldDisableForCurrentSection = isSectionIncluded ? sectionValidation[sectionKey] : true;
        const shouldDisable = (acc && shouldDisableForCurrentSection);
        
        return shouldDisable
    }, true);
    
    const btnContainerClass = 'book-loan-button-container '; // Object.values(checkboxes).some((value) => value === true) ? 'book-loan-button-container ' : 'book-loan-button-container-absolute-bottom '
    const isAllUnchecked = Object.values(checkboxes).some((value) => value === true);
    const isAddMoreBSDisabled = disableAddBs ? true : (disableFields || loader);
    const stateMentButton = {
        border: 'none',
        outLine: 'none',
        background: 'transparent',
        color: isAddMoreBSDisabled ? '#767888': '#134CDE',
        padding: '10px 16px 10px 5px',
        marginTop: '0px',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left',
        marginLeft: "1.7%",
    };
    return (
        <React.Fragment>
            <div className='financial-documents-container-style'
                style={{
                    padding: '0px 5px',
                }}
            >
                <h2 style={topHeadingStyle}>{Financial_Docs_Label.Upload_Doc_Label}</h2>
                <p className="paraUpload">Select at least 1 document</p>
                <div className="checkBoxStyle">
                    {checkBoxList.map((checkbox) => (
                        <div style={{ display: 'flex', marginRight: '20px' }} key={checkbox.id}>
                            <input className="inputCheckBoxStyle" type="checkbox" checked={checkboxes[checkbox.id]} onChange={() => handleCheckboxChange(checkbox.id)} disabled={disableFields} />
                            <label className="lableStyle-financialDoument">{checkbox.label}</label>
                        </div>
                    ))}
                </div>
                <div>
                    <div>
                        {checkboxes.check1 && (
                            <div>
                                <h2 style={headingStyle}>ITR</h2>
                                {renderFields(FinancialDocDeptEnum.fd_itr)}
                            </div>
                        )}
                        {checkboxes.check2 && (
                            <div>
                                <h2 style={headingStyle}>GSTR</h2>
                                {!entityDetails ? renderFields(FinancialDocDeptEnum.fd_gst) : null}
                            </div>
                        )}
                        {checkboxes.check4 && (
                            <div>
                                <h2 style={headingStyle}>Bank Statement</h2>
                                <h4 style={headingStyle}>Enter Bank Account Details</h4>
                                <div style={bankStatementStyle}>
                                    {renderFields(FinancialDocDeptEnum.fd_bs)}
                                </div>

                                <h3 style={uploadTittleStyle}>Upload Bank Statement</h3>
                                {renderFields(FinancialDocDeptEnum.fd_upload_bs)}
                                <div>
                                    {bankDocuments.length < 12 && !loader && (
                                        <button onClick={!loader && addBankStatement} style={stateMentButton} disabled={isAddMoreBSDisabled}>
                                            Add more bank statement
                                        </button>
                                    )}
                                    <h4 style={h6passwordStyle}>If the files are password-protected, please provide the password.</h4>
                                    {renderFields(FinancialDocDeptEnum.fd_bs_password)}
                                </div>
                            </div>
                        )}
                        {
                            Object.values(checkboxes).some((value) => value === true) ? 
                            (<div style={{ marginTop:'20px' }}>
                            <h3 style={{ fontWeight: 600, fontSize: '1.25rem', marginLeft: '1.7%' }}>Please provide any relevant information here</h3>
                            <div style={{ marginTop: '15px', marginLeft: '1.7%', display: "grid" }}>
                            <TextField
                                sx={{
                                    width: '98%',
                                    color: 'pink',
                                    fontFamily: 'Montserrat-Regular',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '150%',
                                    gridColumn: "1 / span 1",
                                    gridRow: "1 / span 1"
                                }}
                                fullWidth
                                disabled={navIconPrefixState[NAV_STATE.additional_docs] == STATUS.success ? true : props.type === 'view' ? true : false}
                                value={selectReasonComment}
                                variant="outlined"
                                type="text"
                                placeholder="Comment"
                                multiline={true}
                                minRows={5}
                                size="medium"
                                InputLabelProps={{ shrink: false }}
                                InputProps={{ sx: { borderRadius: '8px' } }}
                                onChange={(event) =>handleCommentSection(event)}
                                />
                                <p style={{
                                    gridColumn: "1 / span 1",
                                    gridRow: "1 / span 1",
                                    alignSelf: "end",
                                    margin: "0px",
                                    padding: "16.5px 14px"
                                }} > 
                                {Comment.Comment_Data}
                                </p>
                            </div>
                          </div>)
                          : null
                        }
                    </div>
                </div>
            </div>

            {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}

            <div className={`${btnContainerClass}book-loan-button-alignment-double-button`}>
                {props.type == 'view' ? null : (
                    <React.Fragment>
                        { isAllUnchecked 
                            ? getSubmitButton(VERIFY_AND_NEXT, handleSubmit, sectionStatusCheck == SECTION_STATUS_CHECK.in_progress ? true : false, shouldEnableVerifyAndNext, {})
                            : null
                        }
                        {isAllUnchecked 
                            ? getSaveAsDraftButton(SAVE_AS_DRAFT, () => { handleFinanceDocumentDraft(false); }, disableDraftButton)
                            : null
                        }
                    </React.Fragment>
                )}
            </div>
            {(isLoading || loader) && <Preloader />}
        </React.Fragment>
    );
};

export default UploadComponent;