export const OVERDUES_FIELD = (state) => [
  {
    resultState: state.feesState,
    placeholder: 'Fees i.e (0.02UA | 0.02UP | 0.02RP | 0.02RA)',
    name: 'fees',
    type: 'text',
    condition: 'overdues',
    errorMsg: 'This field is required.',
    value: state.fees,
  },
  {
    resultState: state.subvention_feesState,
    placeholder: 'Subvention Fees i.e (0.02UA | 0.02UP | 0.02RP | 0.02RA)',
    name: 'subvention_fees',
    type: 'text',
    condition: 'overdues',
    errorMsg: 'This field is required.',
    value: state.subvention_fees,
  },
  {
    resultState: state.processing_feesState,
    placeholder: 'Processing Fees i.e (0.02UA | 0.02UP | 0.02RP | 0.02RA)',
    name: 'processing_fees',
    type: 'text',
    condition: 'overdues',
    errorMsg: 'This field is required.',
    value: state.processing_fees,
  },
  {
    resultState: state.usage_feeState,
    placeholder: 'Usage Fee i.e (0.02UA | 0.02UP | 0.02RP | 0.02RA)',
    name: 'usage_fee',
    type: 'text',
    condition: 'overdues',
    errorMsg: 'This field is required.',
    value: state.usage_fee,
  },
  {
    resultState: state.upfront_interestState,
    placeholder: 'Upfront Interest i.e (0.02UA | 0.02UP )',
    name: 'upfront_interest',
    type: 'text',
    condition: 'upfront',
    errorMsg: 'This field is required.',
    value: state.upfront_interest,
  },
  {
    resultState: state.int_valueState,
    placeholder: 'Interest Value i.e (0.02P | 0.02A)',
    name: 'int_value',
    type: 'text',
    condition: 'interest',
    errorMsg: 'This field is required.',
    value: state.int_value,
  },
  {
    resultState: state.interest_free_daysState,
    placeholder: 'Interest Free Days i.e 20',
    name: 'interest_free_days',
    type: 'text',
    condition: 'number',
    errorMsg: 'This field is required.',
    value: state.interest_free_days,
  },
  {
    resultState: state.tenure_in_daysState,
    placeholder: 'Tenure In Days i.e 10',
    name: 'tenure_in_days',
    type: 'text',
    condition: 'number',
    errorMsg: 'This field is required.',
    value: state.tenure_in_days,
  },
  {
    resultState: state.grace_periodState,
    placeholder: 'Grace Period i.e 10',
    name: 'grace_period',
    type: 'text',
    condition: 'number',
    errorMsg: 'This field is required.',
    value: state.grace_period,
  },
  {
    resultState: state.overdue_charges_per_dayState,
    placeholder: 'OverDue Charges Per Day i.e (0.02RP|0.02RA)',
    name: 'overdue_charges_per_day',
    type: 'text',
    condition: 'rear',
    errorMsg: 'This field is required.',
    value: state.overdue_charges_per_day,
  },
  {
    resultState: state.penal_interestState,
    placeholder: 'Penal Interest i.e (0.02RP | 0.02RA)',
    name: 'penal_interest',
    type: 'text',
    condition: 'rear',
    errorMsg: 'This field is required.',
    value: state.penal_interest,
  },
  {
    resultState: state.overdue_daysState,
    placeholder: 'Overdue Days i.e 20',
    name: 'overdue_days',
    type: 'text',
    condition: 'number',
    errorMsg: 'This field is required.',
    value: state.overdue_days,
  },
  {
    resultState: state.penal_interest_daysState,
    placeholder: 'Penal Interest Days i.e 10',
    name: 'penal_interest_days',
    type: 'text',
    condition: 'number',
    errorMsg: 'This field is required.',
    value: state.penal_interest_days,
  },
  {
    resultState: state.upfront_interest_daysState,
    placeholder: 'Upfront Interest Days i.e 10',
    name: 'upfront_interest_days',
    type: 'text',
    condition: 'pureNumber',
    errorMsg: 'This field is required.',
    value: state.upfront_interest_days,
  },
];
