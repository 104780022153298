import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { validateData } from '../../util/validation';
import FormPopUp from 'react-sdk/dist/components/Popup/FormPopup';
import Button from 'react-sdk/dist/components/Button';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import { postfldgWatcher, patchfldgWatcher } from '../../actions/fldg/fldg';

import { storedList } from '../../util/localstorage';
import { AlertBox } from '../../components/CustomAlertbox';

export default function FLDGDetails({ handleClose, handleAlertClose, showalert, singlerow, select }) {
  const user = storedList('user');
  const dispatch = useDispatch();
  const [isopen, setIsOpen] = useState(true);
  const [fldgPercentage, setfldgPercentage] = useState('0');
  const [amount, setAmount] = useState('0');
  const [validationData, setValidationData] = useState({});

  const [ocdAmount, setOcdAmount] = useState('0');
  const [coverageAmount, setCoverageAmount] = useState('0');
  const [afiplAmount, setAfiplAmount] = useState('0');
  const [pCoverageAmount, setpCoverageAmount] = useState('0');

  const [selectedcompanies, setselectedcompanies] = useState({
    label: singlerow?.company?.name || '',
    value: singlerow?.company_id || null,
  });

  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [data, setData] = useState({
    company_id: singlerow.company_id,
    id: singlerow._id,
    amount: singlerow.amount ? singlerow.amount.toString() : '',
    fldg_perc: singlerow.fldg_perc ? singlerow.fldg_perc.toString() : '',
    ocd_amount: singlerow.ocd_amount ? singlerow.ocd_amount.toString() : '',
    total_coverage_amount: singlerow.total_coverage_amount ? singlerow.total_coverage_amount.toString() : '',
    afipl_coverage_amount: singlerow.afipl_coverage_amount ? singlerow.afipl_coverage_amount.toString() : '',
    p2p_coverage_amount: singlerow.p2p_coverage_amount ? singlerow.p2p_coverage_amount.toString() : '',
    user_id: user._id,
  });

  const dataValidation = (type, value, name) => {
    let isValid = validateData(type, value);
    if (!isValid) {
      setValidationData((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    } else {
      setValidationData((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    }
  };

  const options = select.map((option) => ({
    label: option.name,
    value: option._id,
  }));

  const buttonLabel = singlerow.length === 0 ? 'Add' : 'Save';
  const discardbuttonlabel = singlerow.length === 0 ? 'Cancel' : 'Discard';
  const headerStyle = singlerow.length === 0 ? 'Add New FLDG Partner' : 'Edit Details ';

  const handleSubmit = () => {
    let arr = Object.keys(validationData)
      .map((key) => {
        if (validationData[key] === true) {
          return key;
        }
        return null;
      })
      .filter(Boolean);

    if (arr.length === 0) {
      const payload = {
        user_id: user._id,
        company_id: selectedcompanies.value,
        amount: amount,
        fldg_perc: fldgPercentage,
        ocd_amount: ocdAmount,
        total_coverage_amount: coverageAmount,
        afipl_coverage_amount: afiplAmount,
        p2p_coverage_amount: pCoverageAmount,
      };

      if (singlerow.length !== 0) {
        new Promise((resolve, reject) => {
          dispatch(patchfldgWatcher(data, resolve, reject));
        })
          .then((response) => {
            setTimeout(() => {
              handleClose();
            }, 1000);
            showalert('Update Successful!', 'success', 'Your Details have been successfully updated');
          })
          .catch((error) => {
            showalert(error?.response?.data?.message || 'Changes not saved', 'error', 'Please try saving your edits again');
          });
      } else {
        new Promise((resolve, reject) => {
          dispatch(postfldgWatcher(payload, resolve, reject));
        })
          .then((response) => {
            setTimeout(() => {
              handleClose();
            }, 1000);
            showalert(response?.message || 'FLDG Details Added Successfully', 'success', '');
          })
          .catch((error) => {
            showalert(error?.response?.data?.message || 'Unable to add partner fldb details at this time', 'error', 'Please try again');
          });
      }
    } else {
      showalert('Enter all the fields in correct format', 'error', '');
    }
  };

  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} data={datamsg} onClose={handleAlertClose} /> : null}
      <FormPopUp
        customHeaderStyle={{ marginLeft: '1%' }}
        heading={headerStyle}
        isOpen={isopen}
        onClose={handleClose}
        customStyles={{
          position: 'fixed',
          width: '40%',
          height: '100%',
          maxHeight: '100%',
          marginLeft: '31%',
          paddingTop: '2%',
          display: 'flex',
          flexDirection: 'column',
          float: 'right',
          overflowY: 'auto',
        }}
      >
        <div style={{ marginTop: '5%', marginLeft: '1%' }}>
          <InputBox
            label="Partner"
            placeholder="Select Partner*"
            isDrawdown={true}
            options={options}
            helperText={'Enter partner'}
            error={validationData['Select Partner']}
            customClass={{
              height: '60px',
              width: '97%',
              maxWidth: '100%',
              marginTop: '6%',
              zIndex: 10,
              fontFamily: 'Montserrat-Regular',
              fontSize: '18px',
              color: 'var(--Neutrals-Neutral-100, #161719)',
            }}
            initialValue={selectedcompanies.label ? selectedcompanies.label : ''}
            customErrorClass={{ marginTop: '12px' }}
            onClick={(value) => {
              setselectedcompanies(value ? value : '');
            }}
            customDropdownClass={{ fontFamily: 'Montserrat-Regular', fontSize: '16px', color: 'var(--Neutrals-Neutral-100, #161719)' }}
            isDisabled={singlerow?.company?.name ? singlerow?.company?.name : false}
          />
        </div>
        <div
          style={{
            fontFamily: 'Montserrat-Regular',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <div
            style={{
              marginLeft: '2%',
              marginTop: '6%',
              color: 'var(--Neutrals-Neutral-100, #161719)',
              fontFamily: 'Montserrat-SemiBold',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '150%',
            }}
          >
            FLDG Details
          </div>
          <div style={{ marginLeft: '1%', marginTop: '-1%' }}>
            <div style={{ display: 'flex' }}>
              <InputBox
                label="FLDG Percentage*"
                helperText={'Enter valid FLDG Percentage (0-100)'}
                error={validationData['FLDG Percentage']}
                customClass={{
                  height: '50px',
                  width: '48%',
                  maxWidth: '100%',
                  marginTop: '3.25%',
                }}
                initialValue={data.fldg_perc ? data.fldg_perc + '' : fldgPercentage}
                customhelperTextStyle={{ marginTop: '5px' }}
                onClick={(e) => {
                  setfldgPercentage(e.value);
                  setData({ ...data, fldg_perc: e.value });
                  dataValidation('validateFloatInput', e.value, 'FLDG Percentage');
                }}
              />
              <InputBox
                initialValue={data.amount ? data.amount : amount}
                label="Amount*"
                error={validationData['Amount']}
                helperText={'Enter valid amount greater than 0'}
                customhelperTextStyle={{ marginTop: '5px' }}
                customClass={{
                  height: '50px',
                  width: '48%',
                  maxWidth: '100%',
                  marginTop: '3.25%',
                  marginLeft: '10px',
                }}
                onClick={(e) => {
                  setAmount(e.value);
                  setData({ ...data, amount: e.value });
                  dataValidation('float', e.value, 'Amount');
                }}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <InputBox
                initialValue={data.ocd_amount ? data.ocd_amount : ocdAmount}
                label="OCD Amount*"
                customhelperTextStyle={{ marginTop: '5px' }}
                error={validationData['OCD Amount']}
                helperText={'Enter valid ocd amount greater than 0'}
                customClass={{
                  height: '50px',
                  width: '48%',
                  maxWidth: '100%',
                  marginTop: '3.25%',
                }}
                onClick={(e) => {
                  setOcdAmount(e.value);
                  setData({ ...data, ocd_amount: e.value });
                  dataValidation('float', e.value, 'OCD Amount');
                }}
              />
              <InputBox
                initialValue={data.total_coverage_amount ? data.total_coverage_amount : coverageAmount}
                label="Total Coverage amount*"
                customClass={{
                  height: '50px',
                  width: '48%',
                  maxWidth: '100%',
                  marginTop: '3.25%',
                  marginLeft: '10px',
                }}
                customhelperTextStyle={{ marginTop: '5px' }}
                error={validationData['Total Coverage amount']}
                helperText={'Enter valid  coverage amount greater than 0'}
                onClick={(e) => {
                  setCoverageAmount(e.value);
                  setData({ ...data, total_coverage_amount: e.value });
                  dataValidation('float', e.value, 'Total Coverage amount');
                }}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <InputBox
                initialValue={data.afipl_coverage_amount ? data.afipl_coverage_amount : afiplAmount}
                label="AFIPL Coverage Amount*"
                customhelperTextStyle={{ marginTop: '5px' }}
                customClass={{
                  height: '50px',
                  width: '48%',
                  maxWidth: '100%',
                  marginTop: '3.25%',
                }}
                error={validationData['AFIPL Coverage Amount']}
                helperText={'Enter valid afipl coverage amount greater than 0'}
                onClick={(e) => {
                  setAfiplAmount(e.value);
                  setData({ ...data, afipl_coverage_amount: e.value });
                  dataValidation('float', e.value, 'AFIPL Coverage Amount');
                }}
              />
              <InputBox
                initialValue={data.p2p_coverage_amount ? data.p2p_coverage_amount : pCoverageAmount}
                label="P2P Coverage Amount*"
                customhelperTextStyle={{ marginTop: '5px' }}
                customClass={{
                  height: '50px',
                  width: '48%',
                  maxWidth: '100%',
                  marginTop: '3.25%',
                  marginLeft: '10px',
                }}
                error={validationData['P2P Coverage Amount']}
                helperText={'Enter valid p2p coverage amount greater than 0'}
                onClick={(e) => {
                  setpCoverageAmount(e.value);
                  setData({ ...data, p2p_coverage_amount: e.value });
                  dataValidation('float', e.value, 'P2P Coverage Amount');
                }}
              />
            </div>
          </div>
          <div
            style={{
              width: '92%',
              display: 'flex',
              bottom: '4vh',
              position: 'absolute',
            }}
          >
            <Button
              id="Cancel"
              label={discardbuttonlabel}
              buttonType="secondary"
              onClick={handleClose}
              customStyle={{
                width: '49%',
                marginRight: '2%',
                color: 'rgb(71, 91, 216)',
                fontSize: '16px',
                borderRadius: '8px',
                border: '1px solid #475BD8',
                backgroundColor: 'white',
                boxShadow: 'none',
              }}
            />
            <Button
              id="Add"
              label={buttonLabel}
              buttonType="secondary"
              onClick={handleSubmit}
              customStyle={{
                borderRadius: '8px',
                width: '49%',
                fontSize: '16px',
                backgroundColor: '#475BD8',
                color: '#FFFFFF',
              }}
            />
          </div>
        </div>
      </FormPopUp>
    </>
  );
}
