import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { storedList } from '../../util/localstorage';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AlertBox } from '../../components/AlertBox';
import { useParams } from 'react-router-dom';
import Preloader from '../../components/custom/preLoader';
import Buttoned from 'react-sdk/dist/components/Button/Button';
import Table from 'react-sdk/dist/components/Table/Table';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import FormPopUp from 'react-sdk/dist/components/Popup/FormPopup';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import 'react-sdk/dist/styles/_fonts.scss';
import { checkAccessTags } from '../../util/uam';
import DatePicker from 'react-sdk/dist/components/DatePicker/DatePicker';
import { getDisbursementRequestByLoanIdWatcher } from '../../actions/disbursementRequest';
import { compositeTranchDisbursementWatcher, rpsGenerateWatcher } from '../../actions/compositeDisbursement';

const DisburseRequest = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [disbRequestList, setDisbRequestList] = useState([]);
  const { company_id, product_id, loan_id } = useParams();
  const [loanData, setLoanData] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const isLoading = useSelector((state) => state.profile.loading);
  const dispatch = useDispatch();
  const user = storedList('user');
  const [isOpen, setIsOpen] = useState(false);
  const [bankDetails, setBankDetails] = useState(false);
  const [bankRemarks, setBankRemarks] = useState(false);
  const [disbAmount, setDisbAmount] = useState();
  const [bankRemarksData, setBankRemarksData] = useState();
  const [bankDetailsData, setBankDetailsData] = useState();
  const [error, setError] = useState(false);
  const [deductionValue, setDeductionValue] = useState(0);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState([]);
  const [type, setType] = useState({});
  const [rpsPopUp, setRpsPopUp] = useState(false);
  const [finalDisbursement, setFinalDisbursement] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [rpsDate, setRpsDate] = useState();
  const [txnId, setTxnId] = useState();

  const handlrpsPopUpClose = () => {
    setRpsPopUp(false);
  };

  const rpsPopUpConfirmation = () => {
    setRpsPopUp(true);
  };

  const customButton1 = {
    borderRadius: '10px',
    border: '1px solid #475BD8',
    height: '56px',
    fontSize: '16px',
    backgroundColor: 'white',
  };

  const styleRadioCheckboxInput = {
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
  };

  const styleRadioCheckboxLabel = {
    marginLeft: '8px',
    color: '#141519',
    fontFamily: 'Montserrat-SemiBold',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '150%',
  };

  const handleClose = () => {
    setIsOpen(false);
    setDisbAmount();
    setError(false);
    setType('');
  };
  const handleClosed = () => {
    setBankDetails(false);
  };

  const handleClosedd = () => {
    setBankRemarks(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(event + 1);
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };
  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };
  useEffect(() => {
    fetchDisbRequestDetails();
  }, [page]);

  const fetchDisbRequestDetails = () => {
    const filterData = {};
    const params = {
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
      user_id: user._id,
      page: page,
      limit: limit,
    };
    filterData.page = page;
    dispatch(
      getDisbursementRequestByLoanIdWatcher(
        params,
        (result) => {
          setDisbRequestList(result.data.rows);
          setLoanData(result.data);
          setCount(result?.data?.count);
          setDeductionValue(result.data?.deduction);
          setTxnId(result.data?.txn_id);
          let beneDetails = result.data.beneficiary_details;
          beneDetails = beneDetails.map((item) => {
            return { ...item, label: item.s_no + ' - ' + item.relationship, value: item.name };
          });
          setBeneficiaryDetails(beneDetails);
        },
        (error) => {
          console.log(error, 'error');
          // return showAlert(error?.response?.data?.message, 'error');
        },
      ),
    );
  };

  const columns = [
    { id: 'sno', label: 'SR NO.', format: (row, index) => index + 1 },
    { id: 'disbur_amt', label: 'DIS AMOUNT', format: (row) => new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row?.disbur_amt) },
    { id: 'net_disbur_amt', label: 'NET DIS AMOUNT', format: (row) => new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row?.net_disbur_amt) },
    { id: 'Request', label: 'DIS REQUEST DATE & TIME', format: (row) => moment(row?.created_at).format('DD-MM-YYYY . hh:mm A') },
    {
      id: 'utr_number',
      label: 'UTR DETAILS',
      format: (row) => (
        <div>
          <div>{row.utr_number}</div> <div style={{ color: '#767888' }}>{moment(row?.completed_on).format('DD-MM-YYYY hh:mm A')}</div>
        </div>
      ),
    },
    { id: 'beneficiary_relation', label: 'BENE RELATION' },
    {
      id: 'BANK DETAILS',
      label: 'BANK DETAILS',
      format: (row) => (
        <div>
          <Link
            onClick={() => {
              setBankDetailsData(row);
              setBankDetails(true);
            }}
          >
            Click here
          </Link>{' '}
        </div>
      ),
    },
    {
      id: 'BANK REMARKS',
      label: 'BANK REMARKS',
      format: (row) => (
        <div>
          <Link
            onClick={() => {
              setBankRemarksData(row?.bank_remark), setBankRemarks(true);
            }}
          >
            Click here
          </Link>{' '}
        </div>
      ),
    },
    {
      id: '',
      label: 'STATUS',
      format: (row) => (
        <div>
          {row?.status == 'success' ? (
            <div style={{ color: '#008042', backgroundColor: '#EEFFF7', height: '22px', fontSize: '12px', border: '1px solid #008042', borderRadius: '4px', display: 'flex', justifyContent: 'center', padding: '5px', textAlign: 'center', alignItems: 'center' }}>Success</div>
          ) : row.status == 'pending' ? (
            <div style={{ color: '#DB8400', backgroundColor: '#FFECEC', height: '22px', fontSize: '12px', border: '1px solid #DB8400', borderRadius: '4px', display: 'flex', justifyContent: 'center', padding: '5px', textAlign: 'center', alignItems: 'center' }}>Pending</div>
          ) : (
            <div style={{ color: '#B30000', backgroundColor: '#FFECEC', height: '22px', fontSize: '12px', border: '1px solid #B30000', borderRadius: '4px', display: 'flex', justifyContent: 'center', padding: '5px', textAlign: 'center', alignItems: 'center' }}>Failed</div>
          )}
        </div>
      ),
    },
  ];

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '53vh',
    borderRadius: '35px',
    marginLeft: '15%',
    marginRight: '25%',
    marginTop: '60px',
  };

  const handleType = (e) => {
    setType(e);
  };

  const handleSubmit = () => {
    const payload = {
      submitData: {
        company_id: company_id,
        product_id: product_id,
        loan_id: loan_id,
        user_id: user._id,
        loan_app_id: loanData.loan_app_id,
        borrower_id: loanData.borrower_id,
        partner_loan_id: loanData.partner_loan_id,
        partner_loan_app_id: loanData.partner_loan_app_id,
        partner_borrower_id: loanData.partner_borrower_id,
        disbur_amt: disbAmount,
        deduction: deductionValue,
        net_disbur_amt: Number(disbAmount) - Number(deductionValue),
        beneficiary_relation: type?.relationship,
        beneficiary_name: type?.name,
        beneficiary_ifsc: type?.ifsc,
        beneficiary_acc_number: type?.account_number,
        beneficiary_type: type?.account_type,
        beneficiary_bank_name: type?.bank_name,
        sanction_amount: loanData.sanction_amount,
        borrower_mobile: loanData?.loan_request?.appl_phone,
        txn_date: moment().format('YYYY-MM-DD'),
        is_final_disb: finalDisbursement,
      },
      userData: {
        user_id: user._id,
        company_id: company_id,
        product_id: product_id,
        co_lend_flag: 'N',
        // ...filter
      },
    };

    new Promise((resolve, reject) => {
      dispatch(compositeTranchDisbursementWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setIsOpen(false);
        showAlert('Disbursement request successfully', 'success');
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
        console.log(error, 'error');
      });
  };

  const changeDateSelected = (value, name) => {
    const date = moment(value).format('YYYY-MM-DD');
    setRpsDate(date);
    setIsDisabled(date ? false : true);
  };

  const handleOnChange = async (value) => {
    setFinalDisbursement(value === 'true' ? true : false);
  };

  const rpsGenerateSubmit = () => {
    const payload = {
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
      user_id: user._id,
      generateDate: rpsDate,
      txn_id: txnId,
    };

    new Promise((resolve, reject) => {
      dispatch(rpsGenerateWatcher(payload, resolve, reject));
    })
      .then((response) => {
        showAlert(response?.data?.message, 'success');
        setRpsPopUp(false);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
        console.log(error, 'error');
      });
  };

  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}

      {isOpen ? (
        <FormPopUp
          heading={'New Disbursement Request'}
          isOpen={isOpen}
          onClose={handleClose}
          customStyles={{
            position: 'fixed',
            width: '30%',
            height: '100%',
            maxHeight: '100%',
            marginLeft: '35%',
            paddingTop: '2%',
            display: 'flex',
            flexDirection: 'column',
            float: 'right',
            overflowY: 'auto',
            fontSize: '22px',
          }}
        >
          <div
            style={{
              fontFamily: 'Montserrat-Regular',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <div
              style={{
                width: '98%',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: '#6B6F80',
                  fontFamily: 'Montserrat-Semibold',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '150%',
                }}
              >
                AVAILABLE AMOUNT
              </div>
              <div
                style={{
                  width: '100%',
                  color: '#141519',
                  fontFamily: 'Montserrat-Semibold',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '150%',
                }}
              >
                {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanData?.available_amount)}
              </div>
            </div>

            <div style={{ marginTop: '10px', fontFamily: 'Montserrat-Medium', color: 'black', fontSize: '14px' }}>
              <div>
                <InputBox
                  label="Loan ID"
                  customClass={{
                    marginTop: '10px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  customInputClass={{ width: '100%' }}
                  initialValue={loan_id}
                  isDisabled={true}
                />
              </div>

              <div className="disbursement-popup-error">
                <InputBox
                  label="Disbursement Amount"
                  customClass={{
                    marginTop: '15px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  initialValue={disbAmount}
                  isDisabled={false}
                  error={error ? error : false}
                  type="number"
                  helperText={error ? 'The disbursement amount exceeds the available funds. Please reduce the amount.' : false}
                  onClick={(e) => {
                    setDisbAmount(e.value);
                    if (Number(e.value) > Number(loanData?.available_amount)) {
                      setError(true);
                    } else {
                      setError(false);
                    }
                  }}
                />
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', gap: '10px' }}>
                <InputBox
                  label="Deduction (unpaid fees and gst)"
                  customClass={{
                    marginTop: '20px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                    fontSize: '8px',
                  }}
                  customInputClass={{ width: '100%' }}
                  initialValue={new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(deductionValue)}
                  isDisabled={true}
                />
                <InputBox
                  label="Net Disbursement Amount"
                  customClass={{
                    marginTop: '20px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                    padding: '0px',
                  }}
                  initialValue={new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(disbAmount > 0 ? Number(disbAmount) - Number(deductionValue) : deductionValue)}
                  customInputClass={{ width: '100%' }}
                  isDisabled={true}
                />
              </div>

              <div>
                <InputBox
                  label="Beneficiary Relationship"
                  customClass={{
                    marginTop: '15px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  initialValue={type?.name}
                  isDrawdown={true}
                  customDropdownClass={{ marginTop: '16px', zIndex: '1', width: '100%' }}
                  options={beneficiaryDetails}
                  onClick={handleType}
                />
              </div>

              <div>
                <InputBox
                  label="Beneficiary Name"
                  customClass={{
                    marginTop: '15px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  initialValue={type?.name}
                  isDisabled={true}
                />
              </div>

              <div>
                <InputBox
                  label="Bank Name"
                  customClass={{
                    marginTop: '15px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  customInputClass={{ width: '100%' }}
                  initialValue={type?.bank_name}
                  isDisabled={true}
                />
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', gap: '10px' }}>
                <InputBox
                  label="Bank IFSC"
                  customClass={{
                    marginTop: '15px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  customInputClass={{ width: '100%' }}
                  initialValue={type?.ifsc}
                  isDisabled={true}
                />
                <InputBox
                  label="Bank Account Type"
                  customClass={{
                    marginTop: '15px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  customInputClass={{ width: '100%', maxWidth: '100%' }}
                  initialValue={type?.account_type}
                  isDisabled={true}
                />
              </div>

              <div>
                <InputBox
                  label="Bank Account Number"
                  customClass={{
                    marginTop: '15px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  initialValue={type?.account_number}
                  isDisabled={true}
                />
              </div>

              <div
                style={{
                  width: '98%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  marginTop: '40px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    color: '#141519',
                    fontFamily: 'Montserrat-Semibold',
                    fontSize: '18px',
                    fontWeight: '600',
                    lineHeight: '150%',
                  }}
                >
                  This is the final disbursement?
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    height: '24px',
                    gap: '24px',
                  }}
                >
                  <div>
                    <input
                      id="yes-input"
                      type="radio"
                      name="yes_required"
                      value={true}
                      onChange={(event) => {
                        handleOnChange(event.target.value);
                      }}
                      style={styleRadioCheckboxInput}
                      checked={finalDisbursement}
                    />
                    <label htmlFor="yes-input" style={styleRadioCheckboxLabel}>
                      Yes
                    </label>
                  </div>
                  <div>
                    <input
                      id="no-input"
                      type="radio"
                      name="no_required"
                      value={false}
                      onChange={(event) => {
                        handleOnChange(event.target.value);
                      }}
                      style={styleRadioCheckboxInput}
                      checked={!finalDisbursement}
                    />
                    <label htmlFor="no-input" style={styleRadioCheckboxLabel}>
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '5%',
                  marginBottom: '20px',
                }}
              >
                <Buttoned
                  id="cancel"
                  label="Cancel"
                  buttonType="secondary"
                  onClick={handleClose}
                  customStyle={{
                    width: '100%',
                    marginRight: '2%',
                    color: 'rgb(71, 91, 216)',
                    fontSize: '14px',
                    borderRadius: '8px',
                    border: '1px solid #475BD8',
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    padding: '12px',
                  }}
                />
                <Buttoned
                  id="submit"
                  label="Create request"
                  buttonType="secondary"
                  onClick={handleSubmit}
                  isDisabled={error}
                  customStyle={{
                    borderRadius: '8px',
                    width: '100%',
                    fontSize: '14px',
                    backgroundColor: error ? '#ccc' : '#475BD8',
                    color: '#FFFFFF',
                    padding: '12px',
                  }}
                />
              </div>
            </div>
          </div>
        </FormPopUp>
      ) : null}

      {bankDetails ? (
        <FormPopUp
          heading={'Bank Details'}
          isOpen={bankDetails}
          onClose={handleClosed}
          customStyles={{
            position: 'fixed',
            width: '30%',
            height: '100%',
            maxHeight: '100%',
            marginLeft: '35%',
            paddingTop: '2%',
            display: 'flex',
            flexDirection: 'column',
            float: 'right',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              fontFamily: 'Montserrat-Regular',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <div style={{ marginTop: '10px', fontFamily: 'Montserrat-Medium', color: 'black', fontSize: '14px' }}>
              <div>
                <InputBox
                  label="Beneficiary Relationship"
                  customClass={{
                    marginTop: '20px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  customDropdownClass={{ marginTop: '16px', zIndex: '1', width: '100%' }}
                  isDisabled={true}
                  onClick={handleType}
                  initialValue={bankDetailsData?.beneficiary_relation}
                />
              </div>

              <div>
                <InputBox
                  label="Beneficiary Name"
                  customClass={{
                    marginTop: '20px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  customDropdownClass={{ marginTop: '16px', zIndex: '1', width: '100%' }}
                  isDisabled={true}
                  onClick={handleType}
                  initialValue={bankDetailsData?.beneficiary_name}
                />
              </div>

              <div>
                <InputBox
                  label="Bank Name"
                  customClass={{
                    marginTop: '20px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  initialValue={bankDetailsData?.bank_name}
                  isDisabled={true}
                />
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', gap: '10px' }}>
                <InputBox
                  label="Bank IFSC"
                  customClass={{
                    marginTop: '20px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  customInputClass={{ width: '100%' }}
                  initialValue={bankDetailsData?.bank_ifsc_code}
                  isDisabled={true}
                />
                <InputBox
                  label="Bank Account Type"
                  customClass={{
                    marginTop: '20px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  customInputClass={{ width: '100%', maxWidth: '100%' }}
                  initialValue={bankDetailsData?.bank_account_type}
                  isDisabled={true}
                />
              </div>

              <div>
                <InputBox
                  label="Bank Account Number"
                  customClass={{
                    marginTop: '20px',
                    height: '60px',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  initialValue={bankDetailsData?.bank_account_no}
                  isDisabled={true}
                />
              </div>
            </div>
          </div>
        </FormPopUp>
      ) : null}

      {bankRemarks ? (
        <FormPopUp
          heading={'Bank Remarks'}
          isOpen={bankRemarks}
          onClose={handleClosedd}
          customStyles={{
            position: 'fixed',
            width: '30%',
            height: '100%',
            maxHeight: '100%',
            marginLeft: '35%',
            paddingTop: '2%',
            display: 'flex',
            flexDirection: 'column',
            float: 'right',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              fontFamily: 'Montserrat-Regular',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <div
              style={{
                width: '98%',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '50px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  color: '#161719',
                  fontFamily: 'Montserrat-Regular',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '150%',
                }}
              >
                {bankRemarksData}
              </div>
            </div>
          </div>
        </FormPopUp>
      ) : null}

      {rpsPopUp ? (
        <FormPopUp customStyles={{ width: '530px', top: '30%', overflowY: 'unset' }} heading="Generate repayment schedule" onClose={handlrpsPopUpClose} customHeaderStyle={{ display: 'flex', justifyContent: 'center' }} isCancel={true} isOpen={rpsPopUp}>
          <div className="confirmation-popup-text-style" style={{ marginTop: '50px' }}>
            <h4 style={{ color: '#767888', letterSpacing: '1px', textAlign: 'center' }}>Are you sure you want to generate repayment schedule and stop disbursement?</h4>
          </div>
          <div style={{ marginTop: '20px' }}>
            <DatePicker
              label="Select Date"
              // customInputClass={{ height: '58px', width: '100%', maxWidth: '100%' }}
              customInputClass={{ width: '500px', height: '58px' }}
              isBoxType="icon"
              format={'YYYY-MM-DD'}
              disablePast="true"
              // isBoxType={'icon'}
              // age={parseInt(process.env.REACT_APP_AGE_MSME)}
              // startingDate={process.env.REACT_APP_UPPERAGELIMIT_MSME ? parseInt(new Date().getFullYear()) - parseInt(process.env.REACT_APP_UPPERAGELIMIT_MSME) : 1964}
              endingDate={2050}
              onDateChange={(date) => changeDateSelected(date)}
            />
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '49% 49%', gap: '10px', marginTop: '20px' }}>
            <Buttoned variant="outlined" label="Cancel" buttonType="secondary" onClick={handlrpsPopUpClose} customStyle={customButton1}>
              Cancel
            </Buttoned>
            <Buttoned
              variant="contained"
              label="Confirm"
              buttonType="primarys"
              onClick={(event) => rpsGenerateSubmit(event)}
              isDisabled={isDisabled}
              customStyle={{
                borderRadius: '10px',
                height: '56px',
                fontSize: '16px',
                backgroundColor: isDisabled ? '#CCCDD3' : '#475BD8',
                color: isDisabled ? 'white' : '#FFFFFF',
              }}
            >
              Confirm
            </Buttoned>
          </div>
        </FormPopUp>
      ) : null}

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
        <div style={{ backgroundColor: '#F9F8FA', border: ' 1px solid #EDEDED', borderRadius: '8px', marginLeft: '25px', padding: '16px', fontFamily: 'Montserrat-SemiBold' }}>
          <h4 style={{ fontSize: '20px', lineHeight: '150%', fontFamily: 'Montserrat-SemiBold', color: '#141519' }}>Loan Details</h4>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px' }}>
              CUSTOMER NAME
              <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{loanData?.customer}</div>
            </div>
            <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px', marginLeft: '30px' }}>
              LOAN ID
              <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{loan_id === '' || !loan_id ? 'NA' : loan_id}</div>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {checkAccessTags(['tag_loan_queue_mt_disbursement_request_w']) && (
            <Buttoned
              customStyle={{
                marginRight: '17px',
                padding: '13px 26px',
                float: 'right',
                height: '48px',
                radius: '8px',
                borderRadius: '8px',
                fontSize: '16px',
                lineHeight: '150%',
                justifyCcontent: 'center',
                alignItems: 'center',
                gap: '10px',
                fontFamily: 'Montserrat-Regular',
                backgroundColor: '#475BD8',
                fontWeight: '600',
                fontStyle: 'normal',
                lineHeight: '150%',
                backgroundColor: '#475BD8',
              }}
              label="New Disbursement"
              buttonType="primary"
              onClick={() => {
                setIsOpen(true);
              }}
            />
          )}
          {((checkAccessTags(['tag_loan_queue_mt_disbursement_request_r']) && checkAccessTags(['tag_loan_queue_mt_disbursement_request_grps'])) || checkAccessTags(['tag_loan_queue_mt_disbursement_request_w'])) && loanData?.is_pre_emi_stage !== false ? (
            <>
              <Buttoned
                customStyle={{
                  marginRight: '17px',
                  padding: '13px 26px',
                  float: 'right',
                  height: '48px',
                  radius: '8px',
                  borderRadius: '8px',
                  fontSize: '16px',
                  lineHeight: '150%',
                  justifyCcontent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  fontFamily: 'Montserrat-Regular',
                  fontWeight: '600',
                  fontStyle: 'normal',
                  lineHeight: '150%',
                  backgroundColor: 'white',
                }}
                label="Generate RPS"
                buttonType="secondary"
                onClick={() => rpsPopUpConfirmation()}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      {disbRequestList.length ? (
        <div>
          <h2 style={{ fontFamily: 'Montserrat-SemiBold', marginLeft: '25px', fontSize: '20pz', lineHeight: '150%', marginTop: '40px', marginBottom: '-20px' }}>Disbursement Requests</h2>
          <div className="table-alignment-css disb-table" style={{ maxWidth: '100%', padding: '18px', marginLeft: '6.2px', marginTop: '10px' }}>
            <Table customStyle={{ width: '100%', display: 'grid', gridTemplateColumns: '10% 12% 12% 12% 13% 11% 10% 10% 10%' }} data={disbRequestList} columns={columns} />
            {count ? <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={10} /> : null}
          </div>
        </div>
      ) : (
        <div style={containerStyle}>
          <div
            style={{
              fontSize: '26px',
              lineHeight: '48px',
              fontFamily: 'Montserrat-SemiBold',
              color: 'black',
            }}
          >
            Add new disbursement
          </div>
          <div
            style={{
              fontSize: '20px',
              lineHeight: '48px',
              fontFamily: 'Montserrat-Medium',
            }}
          >
            0 disbursement available
          </div>
        </div>
      )}
      {isLoading && <Preloader />}
    </>
  );
};

export default DisburseRequest;
