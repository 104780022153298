import { Saga_Action_Type } from '../constants/string_constants';

export function getLeadSectionStatusWatcher(data, resolve, reject) {
  return {
    type: 'GET_LEAD_SECTION_STATUS',
    payload: data,
    resolve,
    reject,
  };
}
export function getLapLeadSectionStatusWatcher(data, resolve, reject) {
  return {
    type: 'GET_LAP_LEAD_SECTION_STATUS',
    payload: data,
    resolve,
    reject,
  };
}

export function getLapLeadSectionStatusForLoanAppidWatcher(data, resolve, reject) {
  return {
    type: Saga_Action_Type.Get_Lap_Lead_Section_Status_For_Lapid,
    payload: data,
    resolve,
    reject,
  };
}

export function postLapSectionStatusWatcher(payload, resolve, reject) {
  return {
    type: Saga_Action_Type.Post_Lap_Section_Status,
    payload,
    resolve,
    reject,
  };
}
