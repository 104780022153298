export function getLoanUploadStatus(data, resolve, reject) {
  return {
    type: 'GET_LOAN_UPLOAD_STATUS',
    payload: data,
    resolve,
    reject,
  };
}

export function getLoanUpdateStatus(data, resolve, reject) {
  return {
    type: 'GET_LOAN_UPDATE_STATUS',
    payload: data,
    resolve,
    reject,
  };
}

export function getPaymentUpdateStatus(data, resolve, reject) {
  return {
    type: 'GET_PAYMENT_UPDATE_STATUS',
    payload: data,
    resolve,
    reject,
  };
}

export function getRefineLoanDataStatus(data, resolve, reject) {
  return {
    type: 'GET_REFINE_LOAN_DATA_STATUS',
    payload: data,
    resolve,
    reject,
  };
}

export function getDownloadExcel(data, resolve, reject) {
  return {
    type: 'GET_DOWNLOAD_EXCEL',
    payload: data,
    resolve,
    reject,
  };
}

export function getErrorDownloadExcel(data, resolve, reject) {
  return {
    type: 'GET_ERROR_DOWNLOAD_EXCEL',
    payload: data,
    resolve,
    reject,
  };
}
