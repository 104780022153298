import React, { useState, useEffect } from 'react';
import Clock from '../../../../assets/img/clock.svg';
import 'react-sdk/dist/styles/_fonts.scss';
import { storedList } from 'util/localstorage';
import { OfferGenerate } from '../../../components/OfferGenerate/OfferGenerate.component';
import { Field_Label } from '../../../constants/string_constants';
import { LeadNewStatus } from '../../../config/LeadStatus';
import TickCircle from '../../../../assets/img/tick-circle.svg';
import { LogViewer } from '../../../components/LogViewer/LogViewer';
import { getLeadOfferWcher } from '../../../actions/lead.action';
import SlidingAlert from '../../../components/SlidingAlert/SlidingAlert';
import { useDispatch } from 'react-redux';
import LeadRejectedCard from '../../leads/LeadReview/Components/LeadRejectedCard';

const handlePopupVisibility = async (sectionName, isVisible) => {
  try {
  } catch (error) {
    throw error;
  }
};

const OfferPage = (props) => {
  const { leadStatus } = props;
  const [remark, setRemark] = useState('');
  const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
  const [currentOfferDetails, setCurrentOfferDetails] = useState({});
  const [isLeadOffer, setIsLeadOffer] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await getLeadOffer();
    };
    fetchData();
  }, [props.loanAppId]);

  const getLeadOffer = async () => {
    const payload = {
      loan_app_id: props.loanAppId,
      companyId: props.companyId,
      productId: props.productId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLeadOfferWcher(payload, resolve, reject));
    })
      .then((response) => {
        if (response && response?.data) {
          const offer = response?.data;
          console.log('offer', offer);
          setCurrentOfferDetails(offer);
        }
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const showAlert = (msg, type) => {
    try {
      setAlert(true);
      setSeverity(type);
      setAlertMessage(msg);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  return (
    <div style={{ margin: '0px 24px 24px 24px' }}>
      {leadStatus === LeadNewStatus.Rejected ? (
        <LeadRejectedCard leadStatus={LeadNewStatus.Rejected} />
      ) : leadStatus === LeadNewStatus.OfferGenerated || leadStatus === LeadNewStatus.Accepted || leadStatus === LeadNewStatus.Approved || leadStatus === LeadNewStatus.New ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          {leadStatus === LeadNewStatus.OfferGenerated || leadStatus === LeadNewStatus.New ? (
            <div>
              <div className="alert-container">
                <img style={{ margin: '17px 0px 0px 16px' }} alt="icon" src={TickCircle} className="menuIcon" />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="alert-container-title" style={{ fontFamily: 'Montserrat-SemiBold' }}>
                      Application approved successfully & Offer generated
                    </div>
                    <div className="alert-container-msg" style={{ fontFamily: 'Montserrat-Medium' }}>
                      Congratulations! Your loan application has been approved, and we&apos;ve prepared an offer for you. To move forward, please accept the offer. We look forward to assisting you!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div>
            {/* Need to display this offer part once offer is generated*/}
            <OfferGenerate heading={Field_Label.SOFT_OFFER_GENERATED} handlePopupVisibility={handlePopupVisibility} companyId={props.companyId} productId={props.productId} loanAppId={props.loanAppId} leadStatus={props.leadStatus} setNavState={props.setNavState} setIsRejected={props.setIsRejected} isPartnerView={true} offerDetails={currentOfferDetails} />
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', marginTop: '17%' }}>
          <div style={{ textAlign: 'center' }}>
            <div>
              <img src={Clock} alt="clock icon" />
            </div>
            <div style={{ fontFamily: 'Montserrat-SemiBold', fontSize: '18px', color: '#008042', marginTop: '35px', fontWeight: 600 }}>Application submitted successfully</div>
            <div style={{ fontFamily: 'Montserrat-Medium', fontSize: '16px', color: '#161719', marginTop: '15px' }}>
              We&rsquo;re currently reviewing your application. Please check after <br /> some time for the status update. Thank you!
            </div>
          </div>
        </div>
      )}

      {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
};

export default OfferPage;
