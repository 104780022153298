import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getRefundDetailsWatcher, initiateRefundWatcher } from '../../actions/refund';
import Button from 'react-sdk/dist/components/Button/Button';
import moment from 'moment';
import { storedList } from '../../util/localstorage';
import { checkAccessTags } from '../../util/uam';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import { AlertBox } from '../../components/AlertBox';
import 'react-sdk/dist/styles/_fonts.scss';
import { getRefundDataDetailsWatcher } from 'actions/tdsRefund';
import CreateRefund from './CreateRefund';

export default function RefundDemographics(props) {
  const { data, openPopup, setOpenPopup } = props;
  const [loanId, setLoanId] = useState(data.loan_id);
  const [companyId, setCompanyId] = useState(data.company_id);
  const [productId, setProductId] = useState(data.product_id);
  const [refundData, setRefundData] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [refundPopup, setRefundPopup] = useState(openPopup);
  const [refundDataDetails, setRefundDataDetails] = useState([]);

  const dispatch = useDispatch();
  const user = storedList('user');

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  const statuses = {
    NotInitiated: 'Refund not initiated',
    Processed: 'Refund processed',
    Initiated: 'Refund initiated',
    Failed: 'Refund failed',
  };

  useEffect(() => {
    let params = {
      user_id: user._id,
      loan_id: loanId,
      company_id: companyId,
      product_id: productId,
    };
    dispatch(
      getRefundDataDetailsWatcher(
        params,
        (result) => {
          setRefundDataDetails(result?.data);
        },
        (error) => {
          return showAlert(error.response.data.message, 'error');
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (isTagged && checkAccessTags(['tag_refund_request_read', 'tag_refund_request_read_write', 'tag_loan_queue_read_write'])) getRefundDetails();
    if (!isTagged) getRefundDetails();
  }, []);

  const handleAlertClose = () => {
    setAlert(false);
    setAlertMessage('');
    setSeverity('');
    setOpenPopup(false);
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 4000);
  };

  const getRefundDetails = () => {
    const payload = {
      loan_id: loanId,
      user_id: user._id,
      company_id: companyId,
      product_id: productId,
    };
    new Promise((resolve, reject) => {
      dispatch(getRefundDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setRefundData(response.data);
        // setOpenDialog(true);
      })
      .catch((error) => {
        // setOpenDialog(false);
        // onModalClose(error?.response?.data?.message, "error");
        return showAlert(error?.response?.data?.message, 'error');
      });
  };
  const handleClose = () => {
    // setOpenDialog(false);
    // onModalClose("", "");
    setOpenPopup(false);
  };

  const modalStyle = {
    width: '27vw',
    height: '100%',
    maxHeight: '100%',
    marginLeft: '36%',
    paddingTop: '2%',
    marginRight: '1%',
  };
  const customHeaderStyle = {
    marginLeft: '2%',
    fontSize: '1.3vw',
    marginBottom: '7%',
    color: '#303030',
    width: '25vw',
  };
  const boxStyle = { paddingLeft: '2%', float: 'left', fontSize: '0.86vw', fontFamily: 'Montserrat-Regular', fontWeight: '500', color: '#6B6F80' };
  const boxStyle1 = { fontSize: '0.86vw', float: 'right', marginRight: '2%', fontFamily: 'Montserrat-SemiBold', color: '#141519' };

  const handleInitiateRefund = () => {
    const payload = {
      loan_id: loanId,
      user_id: user._id,
      company_id: companyId,
      product_id: productId,
      ...refundData,
    };

    delete payload.int_refund_amount;
    payload.refund_amount = refundData?.int_refund_amount;
    new Promise((resolve, reject) => {
      dispatch(initiateRefundWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setRefundPopup(false);
        return showAlert('Refund request initiated successfully', 'success');
      })
      .catch((error) => {
        setRefundPopup(false);
        return showAlert(error?.response?.data?.message, 'error');
      });
    // setOpenDialog(false);
  };

  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      {refundData && refundPopup && <CreateRefund handleClose={handleClose} data={refundDataDetails} />}
    </>
  );
}
