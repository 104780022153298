import * as React from 'react';
import { useState } from 'react';
import Table from 'react-sdk/dist/components/Table/Table';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import 'react-sdk/dist/styles/_fonts.scss';
import moment from 'moment';
import svg from '../Loan/tableicon.svg';
import Payments from './Payments';

const ReconDetails = (props) => {
  const { data, showAlert } = props;
  const [count, setCount] = useState(data?.length);
  const [originalData, setOriginalData] = useState(data);
  const [reconDetailsData, setreconDetailsData] = useState(data.slice(0, 10));
  const [page, setPage] = useState(0);
  const [pageRepayment, setPageRepayment] = useState(0);
  const [open, setOpen] = useState(false);
  const [usageId, setUsageId] = useState(originalData[0]?.usage_id);
  const [paymentsData, setPaymentsData] = useState(false);

  const onModalClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const f = event * 10;
    const l = event * 10 + 10;
    const sliced = originalData.slice(f, l);
    setreconDetailsData(sliced);
  };

  const handleOpenPayments = (payments, usage_id) => {
    if (!payments.length) {
      showAlert('No repayment records found', 'error');
    } else {
      setOpen(true);
      setPaymentsData(payments);
      setUsageId(usage_id);
    }
  };

  const columns = [
    { id: 'usage_id', label: <span style={{ marginLeft: '-30px' }}>{'USAGE ID'}</span>, format: (rowData) => <span style={{ marginLeft: '-30px' }}>{rowData?.usage_id ? rowData?.usage_id : 'NA'}</span> },
    { id: 'invoice_number', label: <span style={{ marginLeft: '-150px' }}>{'INV NO.'}</span>, format: (rowData) => <span style={{ marginLeft: '-150px' }}>{rowData?.invoice_number || rowData?.invoice_number === 0 ? rowData?.invoice_number : 'NA'}</span> },
    { id: 'drawdown_amount', label: <span style={{ marginLeft: '-150px' }}>{'DD AMT'}</span>, format: (rowData) => <span style={{ marginLeft: '-150px' }}>{rowData?.drawdown_amount || rowData?.drawdown_amount === 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(rowData?.drawdown_amount) : 'NA'}</span> },
    { id: 'usage_fee', label: <span style={{ marginLeft: '-120px' }}>{'USAGE FEE'}</span>, format: (rowData) => <span style={{ marginLeft: '-120px' }}>{rowData?.usage_fee || rowData?.usage_fee === 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(rowData?.usage_fee) : 'NA'}</span> },
    { id: 'due_amount', label: <span style={{ marginLeft: '-100px' }}>{'DUE AMT'}</span>, format: (rowData) => <span style={{ marginLeft: '-100px' }}>{rowData?.due_amount || rowData?.due_amount === 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(rowData?.due_amount) : 'NA'}</span> },
    { id: 'lpi_due', label: <span style={{ marginLeft: '-70px' }}>{'OI DUE'}</span>, format: (rowData) => <span style={{ marginLeft: '-70px' }}>{rowData?.lpi_due || rowData?.lpi_due === 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(rowData?.lpi_due) : 'NA'}</span> },
    { id: 'due_date', label: <span style={{ marginLeft: '-70px' }}>{'DUE DATE'}</span>, format: (rowData) => <span style={{ marginLeft: '-70px' }}>{rowData?.due_date ? moment(rowData?.due_date).format('YYYY-MM-DD') : 'NA'}</span> },
    { id: 'payment_received', label: <span style={{ marginLeft: '-60px' }}>{'PAYMENT RECEIVED '}</span>, format: (rowData) => <div style={{ marginLeft: '-60px' }}>{rowData?.payment_received || rowData?.payment_received === 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(rowData?.payment_received) : 'NA'}</div> },
    { id: 'principal_paid', label: <span style={{ marginLeft: '-40px' }}>{'PRINCIPAL PAID '}</span>, format: (rowData) => <div style={{ marginLeft: '-40px' }}>{rowData?.principal_paid || rowData?.principal_paid === 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(rowData?.principal_paid) : 'NA'}</div> },
    { id: 'interest_paid', label: 'INT PAID', format: (rowData) => <div>{rowData?.interest_paid || rowData?.interest_paid === 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(rowData?.interest_paid) : 'NA'}</div> },
    { id: 'lpi_paid', label: 'OI PAID', format: (rowData) => <div>{rowData?.lpi_paid || rowData?.lpi_paid === 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(rowData?.lpi_paid) : 'NA'}</div> },
    { id: 'status', label: <span style={{ marginLeft: '-10px' }}>{'STATUS'}</span>, format: (rowData) => <span style={{ marginLeft: '-6px' }}>{rowData?.status ? rowData.status : 'NA'}</span> },
    {
      id: 'REPAYMENT',
      label: 'REPAYMENT',
      format: (rowData) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleOpenPayments(rowData?.payments, rowData?.usage_id)}>
          <img src={svg} alt="table icon" />
        </div>
      ),
    },
  ];

  return (
    <>
      {open ? <Payments data={paymentsData} usageId={usageId} open={open} onModalClose={onModalClose} setOpen={setOpen} /> : null}

      <div>
        <div style={{ color: '#141519', margin: '30px', fontSize: '18px', lineHeight: '150%', fontFamily: 'Montserrat-SemiBold' }}>Recon Details</div>
        {reconDetailsData.length ? (
          <div>
            <div style={{ maxWidth: '100%', padding: '20px', marginTop: '-50px' }}>
              <Table customStyle={{ width: '100%' }} data={reconDetailsData} columns={columns} />
              {count ? <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={10} /> : null}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default ReconDetails;
