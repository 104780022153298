export function locDrawdownBulkUploadWatcher(data, resolve, reject) {
  return {
    type: 'LOC_DRAWDOWN_BULK_UPLOAD',
    payload: data,
    resolve,
    reject,
  };
}

export function locDownloadBulkUploadWatcher(data, resolve, reject) {
  return {
    type: 'LOC_DOWNLOAD_BULK_UPLOAD',
    payload: data,
    resolve,
    reject,
  };
}

export function fetchLocBulkUploadDataWatcher(data, resolve, reject) {
  return {
    type: 'FETCH_LOC_DRAWDOWN_BULK_UPLOAD',
    payload: data,
    resolve,
    reject,
  };
}

export function downloadLocBulkSampleFileWatcher(data, resolve, reject) {
  return {
    type: 'DOWNLOAD_LOC_BULK_SAMPLE_FILE',
    payload: data,
    resolve,
    reject,
  };
}
