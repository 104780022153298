export const LoanStatusList = {
    open: { label: 'Open', value: 'open' },
    batch: { label: 'Batch', value: 'batch' },
    manual: { label: 'Manual Kyc', value: 'manual' },
    kyc_data_approved: { label: 'KYC Data Approved', value: 'kyc_data_approved' },
    credit_approved: { label: 'Credit Approved', value: 'credit_approved' },
    co_lender_approval_pending: { label: 'Co-Lender Approval Pending', value: 'co_lender_approval_pending' },
    disbursal_approved: { label: 'Disbursal Approved', value: 'disbursal_approved' },
    disbursal_pending: { label: 'Pending Disbursal', value: 'disbursal_pending' },
    batch: { label: 'Batch', value: 'batch' },
    disbursement_initiated: { label: 'Disbursement Initiated', value: 'disbursement_initiated' },
    disbursed: { label: 'Disbursed', value: 'disbursed' },
    rejected: { label: 'Rejected', value: 'rejected' },
    cancelled: { label: 'Cancelled', value: 'cancelled' },
    line_in_use: { label: 'Line in use', value: 'line_in_use' },
    expired: { label: 'Expired', value: 'expired' },
    active: { label: 'Active', value: 'active' },
    closed: { label: 'Closed', value: 'closed' },
    manual: { label: 'Kyc Incomplete', value: 'manual' },
    foreclosed: { label: 'Foreclosed', value: 'foreclosed' },
    validation_error: { label: 'Validation error', value: 'validation_error' },
    in_review: { label: 'In Review', value: 'in_review' },
  };

  export const BulkUploadStatusList = {
    open: { label: 'Open', value: 'open' },
    rejected: { label: 'Rejected', value: 'rejected' },
    approved: { label: 'Approved', value: 'approved' },
    inProcess:{label:'In Process', value:'inProcess'},
    failed:{label:'Failed',value:'failed'},
    partialSuccess:{label:'Partial Success', value:'partialSuccess'}
  };