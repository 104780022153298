import { FINANCIAL_DOCS_STATUS } from '../../config/financialDocsStatus';
import React from 'react';

const FinancialDocsStatusIcon = ({ status }) => {
  const styles = useStyles();

  const handleStatusCss = (status) => {
    let content;

    switch (status) {
      case FINANCIAL_DOCS_STATUS.parsed_success.value:
        content = {
          ...styles.statusStyle,
          border: '1px solid var(--utility-success-50, #008042)',
          color: 'var(--utility-success-50, #008042)',
          background: 'var(--utility-success-0, #EEFFF7)',
        };
        break;
      case FINANCIAL_DOCS_STATUS.parsed_failure.value:
        content = {
          ...styles.statusStyle,
          border: '1px solid var(--utility-warning-20, #DB8400)',
          color: 'var(--utility-warning-20, #DB8400)',
          background: 'var(--utility-warning-0, #FFF5E6)',
        };
        break;
      case FINANCIAL_DOCS_STATUS.cams_success.value:
        content = {
          ...styles.statusStyle,
          border: '1px solid var(--utility-success-50, #008042)',
          color: 'var(--utility-success-50, #008042)',
          background: 'var(--utility-success-0, #EEFFF7)',
        };
        break;
      case FINANCIAL_DOCS_STATUS.cams_failure.value:
        content = {
          ...styles.statusStyle,
          border: '1px solid var(--utility-warning-20, #DB8400)',
          color: 'var(--utility-warning-20, #DB8400)',
          background: 'var(--utility-warning-0, #FFF5E6)',
        };
        break;
      default:
        content = {
          ...styles.statusStyle,
          border: '1px solid var(--utility-warning-20, #DB8400)',
          color: 'var(--utility-warning-20, #DB8400)',
          background: 'var(--utility-warning-0, #FFF5E6)',
        };
        break;
    }
    return content;
  };
  return <div style={handleStatusCss(status)}>{FINANCIAL_DOCS_STATUS[status] ? FINANCIAL_DOCS_STATUS[status]?.label : status}</div>;
};

const useStyles = () => {
  return {
    statusStyle: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '12px',
      display: 'flex',
      padding: '2px 8px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      whiteSpace: 'nowrap',
    },
  };
};

export default FinancialDocsStatusIcon;
