// data.js

import { documentCode as documentCodeList } from 'msme/config/docCode';

export const FSInputTittle = [
  {
    id: 'fs1',
    name: 'FS 1st Year',
    fileSize: 'PDF upto 10MB',
    acceptFileType: '.pdf',
    fileType: 'file',
    documentCode: documentCodeList.financial_statement1,
  },
  {
    id: 'fs2',
    name: 'FS 2nd Year',
    fileSize: 'PDF upto 10MB',
    acceptFileType: '.pdf',
    fileType: 'file',
    documentCode: documentCodeList.financial_statement2,
  },
  {
    id: 'fs3',
    name: 'FS 3rd Year',
    fileSize: 'PDF upto 10MB',
    acceptFileType: '.pdf',
    fileType: 'file',
    documentCode: documentCodeList.financial_statement3,
  },
];

export const ITRInputTittle = [
  {
    id: 'itr1',
    name: 'ITR 1st Year',
    fileSize: 'PDF upto 10MB',
    acceptFileType: '.pdf',
    fileType: 'file',
    documentCode: documentCodeList.itr1,
  },
  {
    id: 'itr2',
    name: 'ITR 2nd Year',
    fileSize: 'PDF upto 10MB',
    acceptFileType: '.pdf',
    fileType: 'file',
    documentCode: documentCodeList.itr2,
  },
];

export const GSTInputArray = [
  {
    id: 'gst1',
    name: 'GST Certificate',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: documentCodeList.gst_certificate,
  },
  {
    id: 'gst2',
    name: 'GSTR',
    fileSize: 'PDF upto 10MB',
    acceptFileType: '.pdf',
    fileType: 'file',
    documentCode: documentCodeList.gstr,
  },
];

export const BankType = [
  { value: 'Saving', label: 'Savings' },
  { value: 'Personal', label: 'Personal' },
  { value: 'Current', label: 'Current' },
];
