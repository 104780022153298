export function getMonthlyCoaReportsWatcher(data, resolve, reject) {
  return {
    type: 'GET_MONTHLY_COA_REPORTS',
    payload: data,
    resolve,
    reject,
  };
}

export function downloadMonthlyCoaReportsWatcher(data, resolve, reject) {
  return {
    type: 'DOWNLOAD_MONTHLY_COA_REPORTS',
    payload: data,
    resolve,
    reject,
  };
}
