import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from 'react-sdk/dist/components/Button';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Table from 'react-sdk/dist/components/Table';
import actionIcon from '../../../src/assets/collection/images/action.svg';
import BasicDatePicker from 'components/DatePicker/basicDatePicker';
import { verifyDateAfter1800 } from 'util/helper';
import moment from 'moment';
import CustomDatePicker from '../../components/DatePicker/datePickerCustom';
import SelectCompany from '../../components/Company/SelectCompany';
import SelectProduct from '../../components/Product/SelectProduct';

import { storedList } from 'util/localstorage';
import { AlertBox } from 'components/CustomAlertbox';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import LeadLoanLineImage from '../lending/images/newleadloanscreen.svg';
import { P2pPennyDropRejectWatcher, P2pPennyDropReprocessWatcher, getP2pLoansWatcher } from '../../../src/actions/p2pLoans';
import Img from '../../views/lending/images/download-button.svg';
import { CSVLink } from 'react-csv';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import Grid from '@material-ui/core/Grid';
import { ConfirmationPopup } from 'views/lending/ConfirmationPopup';
import Preloader from '../../components/custom/preLoader';

let filterObj = {
  company: null,
  product: null,
  fromDate: null,
  toDate: null,
  searchText: null,
  page: null,
  limit: null,
};

const statusMapping = {
  APPROVED: 'APPROVED',
  DEDUPE_SUCCESS: 'DEDUPE SUCCESS',
  DISBURSED: 'DISBURSED',
  DISB_APPROVED: 'DISBURSHMENT APPROVED',
  KYC_COMPLETED: 'KYC COMPLETED',
  NEW: 'NEW',
  REJECTED: 'REJECTED',
  REJECT_PENDING: 'REJECTION PENDING',
  REJECT_PENNY_DROP: 'PENNY DROP REJECTED',
  OPEN: 'OPEN',
  KYC_APPROVED: 'KYC_APPROVED',
};

const p2pLoans = (props) => {
  const user = storedList('user');
  const [customDate, setCustomDate] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [company, setCompany] = useState('');
  const [product, setProduct] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [rawData, setRawData] = useState([]);
  const [filterData, setFilterdData] = useState(filterObj);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSelected, setIsSelected] = useState(true);
  const [loanStatus, setLoanStatus] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [successCount, setSuccessCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [openPopupForReject, setOpenPopupForReject] = useState(false);
  const [openPopupForReprocess, setOpenPopupForReprocess] = useState(false);
  const [openPopupForGlobalReject, setOpenPopupForGlobalReject] = useState(false);
  const [openPopupForGlobalReprocess, setOpenPopupForGlobalReprocess] = useState(false);
  const [heading, setHeading] = useState('');
  const customDownloadButton = { height: '2.875rem', fontSize: '1rem', display: 'flex', justifyContent: 'center', boxShadow: 'none', backgroundColor: 'white', fontFamily: 'Montserrat-Regular', padding: '1.375rem', border: '0.0625rem solid #134CDE', color: 'rgba(19, 76, 222, 1)', borderRadius: '1.625rem', marginTop: '1.25rem' };
  const customButtonCss = { width: '7.5rem', height: '2.813rem', borderRadius: '0.5rem', fontSize: '0.938', color: '#FFF', backgroundColor: isSelected ? '#C0C1C8' : '#475BD8' };
  const customPopup = { width: '70%', minHeight: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column', float: 'right' };
  const [requiredData, setRequiredData] = useState({});
  const dispatch = useDispatch();
  const styles = useStyles();
  const ApiStatus = [
    { label: 'Total Request', value: selectedRows.length },
    { label: 'Successful Request', value: successCount },
    { label: 'Failed Request', value: failedCount },
  ];
  const isLoading = useSelector((state) => state.profile.loading);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (selectedRowIndex || selectedRowIndex === 0) {
        const dialog = document.querySelector('.dialogContainer > .dialog');
        if (dialog && !dialog.contains(e.target) && e.target.className !== 'actionImage') {
          setSelectedRowIndex(null);
        }
      }
    };
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [selectedRowIndex]);

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleChangePage = (event) => {
    setPage(event);
    filterData.page = event + 1;
  };

  const handleReject = (item) => {
    if (item.is_reject_eligible) {
      setOpenPopupForReject(true);
    }
    setRequiredData(item);
  };

  const handleReprocess = (item) => {
    if (item.is_reprocess_eligible) {
      setOpenPopupForReprocess(true);
    }
    setRequiredData(item);
  };

  const mandatoryFields = {
    company: true,
    product: true,
    fromDate: true,
    toDate: true,
  };

  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
    if (rows.length > 0) {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
    setIsRejectOpen(!isModalVisible);
  };

  const handleClose = (e) => {
    setModalVisible(!isModalVisible);
    setLoanStatus([]);
  };

  const handleGlobalReject = async () => {
    setOpenPopupForGlobalReject(true);
  };

  const handleGlobalReprocess = async () => {
    setOpenPopupForGlobalReprocess(true);
  };

  const handleCustomerProfilePage = (rowdata) => {
    if (company?.value && product?.value) {
      let companyInRow = company;
      handleOpenInNewPage(`/admin/loan/details/${companyInRow.lms_version}/${rowdata.loan_id}/${rowdata.product_id}/${rowdata.company_id}/${product.loan_schema_id}/1`);
    }
  };

  const handleOpenInNewPage = (url, page) => {
    window.open(`${url}`, `${page || '_blank'}`);
  };

  const handleSearch = () => {
    let companyCondition = mandatoryFields['company'] && !company;
    let prouctCondition = mandatoryFields['product'] && !product;
    let fromDateCondition = mandatoryFields['fromDate'] && !fromDate;
    let toDateCondition = mandatoryFields['toDate'] && !toDate;

    if (companyCondition && !filterData?.searchText?.length) return showAlert('Select company', 'error');
    if (prouctCondition && !filterData?.searchText?.length) return showAlert('Select product', 'error');
    if (fromDateCondition && !filterData?.searchText?.length) return showAlert('Select from date', 'error');
    if (toDateCondition && !filterData?.searchText?.length) return showAlert('Select to date', 'error');
    onSearchClick();
  };

  const handleConfirmedReject = () => {
    setOpenPopupForReject(!openPopupForReject);
    if (requiredData.is_reject_eligible) {
      dispatch(
        P2pPennyDropRejectWatcher(
          {
            company_id: requiredData.company_id,
            product_id: requiredData.product_id,
            user_id: user._id,
            loan_id: requiredData.loan_id,
          },
          (result) => {
            showAlert(result?.message, 'success');
            handleSearch();
          },
          (error) => {
            showAlert(error.response.data.message, 'error');
          },
        ),
      );
    }
    setRequiredData({});
    setSelectedRowIndex(null);
  };

  const handleConfirmedReprocess = () => {
    setOpenPopupForReprocess(!openPopupForReprocess);
    if (requiredData.is_reprocess_eligible) {
      dispatch(
        P2pPennyDropReprocessWatcher(
          {
            company_id: requiredData.company_id,
            product_id: requiredData.product_id,
            user_id: user._id,
            loan_id: requiredData.loan_id,
          },
          (result) => {
            showAlert(result?.message, 'success');
            handleSearch();
          },
          (error) => {
            showAlert(error.response.data.message, 'error');
          },
        ),
      );
    }
    setRequiredData({});
    setSelectedRowIndex(null);
  };

  const handleConfirmedGlobalReject = async () => {
    setHeading('Rejection');
    setOpenPopupForGlobalReject(!openPopupForGlobalReject);
    toggleModal();
    let success = 0;
    let failed = 0;
    let loanID = '';
    for (let i = 0; i < selectedRows.length; i++) {
      const row = selectedRows[i];
      const payload = {
        user_id: user._id,
        company_id: row.company_id,
        product_id: row.product_id,
        loan_id: row.loan_id.props.children,
      };
      loanID = row.loan_id.props.children;
      await new Promise((resolve, reject) => {
        dispatch(P2pPennyDropRejectWatcher(payload, resolve, reject));
      })
        .then((response) => {
          success += 1;
          let status = {
            loan_id: loanID,
            status: 'Initiated',
            info: response?.message || 'Rejected succesfully',
          };
          setLoanStatus((prevoius) => [...prevoius, status]);
        })
        .catch((error) => {
          failed += 1;
          let status = {
            loan_id: loanID,
            status: 'Failed',
            info: error?.response?.data?.message,
          };
          setLoanStatus((prevoius) => [...prevoius, status]);
        });
      setSuccessCount(success);
      setFailedCount(failed);
    }
  };

  const handleConfirmedGlobalReprocess = async () => {
    setHeading('Reprocess');
    setOpenPopupForGlobalReprocess(!openPopupForGlobalReprocess);
    toggleModal();
    let success = 0;
    let failed = 0;
    let loanID = '';
    for (let i = 0; i < selectedRows.length; i++) {
      const row = selectedRows[i];
      const payload = {
        user_id: user._id,
        company_id: company.value,
        product_id: product.value,
        loan_id: row.loan_id.props.children,
      };
      loanID = row.loan_id.props.children;
      await new Promise((resolve, reject) => {
        dispatch(P2pPennyDropReprocessWatcher(payload, resolve, reject));
      })
        .then((response) => {
          success += 1;
          let status = {
            loan_id: loanID,
            status: 'Initiated',
            info: response?.message || 'Reprocessed succesfully',
          };
          setLoanStatus((prevoius) => [...prevoius, status]);
        })
        .catch((error) => {
          failed += 1;
          let status = {
            loan_id: loanID,
            status: 'Failed',
            info: error?.response?.data?.message,
          };
          setLoanStatus((prevoius) => [...prevoius, status]);
        });
      setSuccessCount(success);
      setFailedCount(failed);
    }
  };

  const onSearchClick = () => {
    setData([]);
    setFilterdData({
      ...filterData,
      product: product?.value,
      company: company?.value,
      page: page + 1,
      limit: rowsPerPage,
      fromDate: fromDate,
      toDate: toDate,
      searchText: searchText,
    });
    dispatch(
      getP2pLoansWatcher(
        {
          product_id: filterData.product,
          company_id: filterData.company,
          page: filterData.page,
          limit: filterData.limit,
          from_date: filterData.fromDate,
          to_date: filterData.toDate,
          str: filterData.searchText,
          user_id: user._id,
        },
        (result) => {
          setCount(result?.totalCount);
          setRawData(result.data);
        },
        (error) => {
          showAlert(error.response.data.message, 'error');
        },
      ),
    );
  };

  useEffect(() => {
    if (filterData.page !== null) {
      dispatch(
        getP2pLoansWatcher(
          {
            product_id: filterData.product,
            company_id: filterData.company,
            page: filterData.page,
            limit: filterData.limit,
            from_date: filterData.fromDate,
            to_date: filterData.toDate,
            str: filterData.searchText,
            user_id: user._id,
          },
          (result) => {
            setCount(result?.totalCount);
            setRawData(result.data);
          },
          (error) => {
            showAlert(error.response.data.message, 'error');
          },
        ),
      );
    }
  }, [filterData.page]);

  useEffect(() => {
    setData([]);
    rawData.map((item, index) => {
      setData((prevState) => [
        ...prevState,
        {
          sno: page * rowsPerPage + index + 1,
          loan_id: (
            <div
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => {
                handleCustomerProfilePage(item);
              }}
            >
              {item.loan_id}
            </div>
          ),
          company_id: item.company_id,
          product_id: item.product_id,
          partner_loan_id: item.partner_loan_id,
          customer_name: item.first_name + item.last_name,
          loan_amount: item.net_disbur_amt,
          application_date: item.loan_app_date,
          status: statusMapping[item.co_lend_outcome],
          action: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                marginLeft: '1.313rem',
              }}
            >
              <img
                className="actionImage"
                onClick={() => {
                  setSelectedRowIndex(index);
                }}
                style={{ cursor: 'pointer' }}
                src={actionIcon}
                alt="svg"
              />
              {selectedRowIndex === index && (
                <div className="dialogContainer">
                  <div
                    className="dialog"
                    style={{
                      position: 'absolute',
                      width: '10.875rem',
                      height: '3.25rem',
                      bottom: 'calc(100%)',
                      right: 'calc(100% - 0.188rem)',
                      backgroundColor: '#FEFEFE',
                      border: '0.063rem solid #EDEDED',
                      borderRadius: '0.5rem',
                      boxShadow: '0rem 0.25rem 0.625rem 0rem #6F6F6F40',
                      zIndex: '2',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        fontFamily: 'Montserrat-Regular',
                        fontSize: '0.75rem',
                        fontWeight: '600',
                        lineHeight: '0.938rem',
                        color: '#625E5E  ',
                      }}
                    >
                      <span
                        style={{ margin: 'auto', cursor: 'pointer', color: item.is_reprocess_eligible ? ' #181716' : '#ddd8d5' }}
                        onClick={() => {
                          handleReprocess(item);
                        }}
                        onMouseEnter={(e) => {
                          item.is_reprocess_eligible ? (e.target.style.color = '#475BD8') : (e.target.style.color = item.is_reprocess_eligible ? ' #181716' : '#ddd8d5');
                        }}
                        onMouseLeave={(e) => {
                          item.is_reprocess_eligible ? (e.target.style.color = '#625E5E') : (e.target.style.color = item.is_reprocess_eligible ? ' #181716' : '#ddd8d5');
                        }}
                      >
                        Reprocess
                      </span>
                      |
                      <span
                        style={{ margin: 'auto', cursor: 'pointer', color: item.is_reject_eligible ? ' #181716' : '#ddd8d5' }}
                        onClick={() => {
                          handleReject(item);
                        }}
                        onMouseEnter={(e) => {
                          item.is_reject_eligible ? (e.target.style.color = '#475BD8') : (e.target.style.color = item.is_reprocess_eligible ? ' #181716' : '#ddd8d5');
                        }}
                        onMouseLeave={(e) => {
                          item.is_reject_eligible ? (e.target.style.color = '#625E5E') : (e.target.style.color = item.is_reprocess_eligible ? ' #181716' : '#ddd8d5');
                        }}
                      >
                        Reject
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ),
        },
      ]);
    });
  }, [rawData, selectedRowIndex, page]);

  return (
    <div>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      {openPopupForReject ? <ConfirmationPopup openPopup={openPopupForReject} setOpenPopup={setOpenPopupForReject} header={'Confirmation'} confirmationMessage={'Are you sure you want to reject ?'} handleConfirmed={handleConfirmedReject} yes={'Yes'} no={'No'} /> : null}
      {openPopupForReprocess ? <ConfirmationPopup openPopup={openPopupForReprocess} setOpenPopup={setOpenPopupForReprocess} header={'Confirmation'} confirmationMessage={'Are you sure you want to reprocess ?'} handleConfirmed={handleConfirmedReprocess} yes={'Yes'} no={'No'} /> : null}
      {openPopupForGlobalReject ? <ConfirmationPopup openPopup={openPopupForGlobalReject} setOpenPopup={setOpenPopupForGlobalReject} header={'Confirmation'} confirmationMessage={'Are you sure you want to reject ?'} handleConfirmed={handleConfirmedGlobalReject} yes={'Yes'} no={'No'} /> : null}
      {openPopupForGlobalReprocess ? <ConfirmationPopup openPopup={openPopupForGlobalReprocess} setOpenPopup={setOpenPopupForGlobalReprocess} header={'Confirmation'} confirmationMessage={'Are you sure you want to reprocess ?'} handleConfirmed={handleConfirmedGlobalReprocess} yes={'Yes'} no={'No'} /> : null}
      {isModalVisible && (
        <FormPopup heading={`${heading} in Progress`} isOpen={isRejectOpen} onClose={handleClose} customStyles={customPopup}>
          <div onClick={() => toggleModal}>
            <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem', margin: '1rem 0 1rem 0' }}>
              <table className="ir-status-table">
                <tbody>
                  {ApiStatus.map((status, index) => (
                    <tr key={index}>
                      <td style={{ fontWeight: 'bold' }}>{status.label}</td>
                      <td>{status.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {loanStatus?.length > 0 && (
                <CSVLink
                  className="btn-secondary-download-button"
                  style={customDownloadButton}
                  data={loanStatus?.map((el) => {
                    return {
                      'Loan ID': el?.loan_id,
                      Status: el?.status,
                      Remark: el?.info,
                    };
                  })}
                  filename={`rejection-history.csv`}
                >
                  <img src={Img} />
                  CSV
                </CSVLink>
              )}
            </Grid>
            <div className="ir-wrapper">
              <table className="ir-model-table">
                <thead>
                  <tr className="ir-model-table-head">
                    <th>Loan ID</th>
                    <th>Status</th>
                    <th>Info</th>
                  </tr>
                </thead>
                <tbody>
                  {loanStatus.map((status) => (
                    <tr key={status.loan_id} className="ir-model-table-body">
                      <td>{status.loan_id}</td>
                      <td>{status.status}</td>
                      <td>{status.info}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </FormPopup>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '1rem', marginLeft: '1rem' }}>
        <SelectCompany
          placeholder="Company"
          label_name={'Select Company'}
          company={company}
          onCompanyChange={(value) => {
            setCompany(value);
            setProduct('');
          }}
          customStyle={{ marginTop: '0.5rem', zIndex: '1', width: '106%' }}
          height="3.5rem"
          width="12.5rem"
          isP2P={true}
        />
        <SelectProduct placeholder="Product" company={company} product={product} onProductChange={(value) => setProduct(value)} customStyle={{ marginTop: '0.5rem', zIndex: '1', width: '106%', maxWidth: '18.125rem' }} height="3.5rem" width="12.5rem" isP2P={true} />
        <CustomDatePicker
          placeholder="Duration"
          width="12.5rem"
          onDateChange={(date) => {
            if (date.state == 'custom') {
              setCustomDate(false);
              setFromDate('');
              setToDate('');
            } else {
              setCustomDate(true);
              setFromDate(date.fromDate);
              setToDate(date.toDate);
              setFilterdData({
                ...filterData,
                fromDate: moment(date.fromDate).format('YYYY-MM-DD'),
                toDate: moment(date.toDate).format('YYYY-MM-DD'),
              });
            }
          }}
        />
        {!customDate ? (
          <BasicDatePicker
            placeholder="From date"
            value={fromDate || null}
            onDateChange={(date) => {
              setFromDate(verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date);
              setFilterdData({
                ...filterData,
                fromDate: verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date,
              });
            }}
            style={{ width: '12.5rem', borderRadius: '0.5rem' }}
          />
        ) : null}
        {!customDate ? (
          <BasicDatePicker
            placeholder={'To date'}
            value={toDate || null}
            onDateChange={(date) => {
              setToDate(verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date);
              setFilterdData({
                ...filterData,
                toDate: verifyDateAfter1800(moment(date).format('YYYY-MM-DD')) ? moment(date).format('YYYY-MM-DD') : date,
              });
              if (date === null) {
              }
            }}
            style={{ width: '12.5rem', borderRadius: '0.5rem' }}
          />
        ) : null}
        <InputBox
          label="Search By"
          value={searchText}
          onClick={(e) => {
            setSearchText(e.value);
            setFilterdData({
              ...filterData,
              searchText: e.value,
            });
            setPage(0);
          }}
          customClass={{
            width: '18%',
            height: '3.625rem',
            borderRadius: '0.5rem',
            fontSize: '1.063rem',
          }}
          customDropdownClass={{ marginTop: '0.5rem', zIndex: '1' }}
        ></InputBox>
        <Button
          label="Search"
          buttonType="primary"
          onClick={handleSearch}
          customStyle={{
            width: '10.5%',
            height: '3.625rem',
            borderRadius: '0.5rem',
            fontSize: '0.938rem',
          }}
        ></Button>
      </div>
      {data.length ? (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '1rem', marginRight: '1.6rem', marginTop: '2rem' }}>
          <Button label="Reprocess" buttonType="primary" isDisabled={isSelected} onClick={handleGlobalReprocess} customStyle={customButtonCss}></Button>
          <Button label="Reject" buttonType="primary" isDisabled={isSelected} onClick={handleGlobalReject} customStyle={customButtonCss}></Button>
        </div>
      ) : null}
      {data.length ? (
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1.2rem', marginTop: '0rem', width: '97.4%' }}>
          <Table
            customStyle={styles.tableStyle}
            customCellCss={{ width: 'fit-content' }}
            columns={[
              { id: 'sno', label: 'S.NO.' },
              { id: 'loan_id', label: 'LOAN ID' },
              { id: 'customer_name', label: 'CUSTOMER NAME' },
              { id: 'partner_loan_id', label: 'PARTNER LOAN ID' },
              { id: 'loan_amount', label: 'LOAN AMOUNT' },
              { id: 'application_date', label: 'APPLICATION DATE' },
              { id: 'status', label: 'STATUS' },
              { id: 'action', label: 'ACTION' },
            ]}
            data={data}
            showOptions={true}
            isChecKbox={true}
            handleSelectedRows={(rows) => {
              handleSelectedRows(rows);
            }}
          />
          <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={10} />
        </div>
      ) : (
        <>
          <div style={styles.containerStyle}>
            <div>
              <img src={LeadLoanLineImage} alt="Lead Image" style={styles.imageStyle} />
            </div>
            <h2 style={{ fontSize: '1.688rem', lineHeight: '40.5rem', fontFamily: 'Montserrat-SemiBold', padding: '1.875rem' }}>Kindly fill the above fields to get started</h2>
          </div>
        </>
      )}
      {isLoading && <Preloader />}
    </div>
  );
};

export default connect(null, null)(p2pLoans);

const useStyles = () => {
  return {
    tableStyle: {
      width: '100%',
      height: 'auto',
      display: 'grid',
      gridTemplateColumns: ' 4% 8% 15% 15% 15% 10% 15% 10% 8%',
      color: '#161719',
    },
    containerStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '53vh',
      backgroundColor: '#F5F7FF',
      borderRadius: '2.188rem',
      marginLeft: '15%',
      marginRight: '25%',
      marginTop: '5rem',
    },
    imageStyle: {
      marginTop: '5vh',
      width: '100%',
      maxWidth: '25rem',
      height: 'auto',
    },
  };
};
