export function getPartnerProductWatcher(payload, resolve, reject) {
  return {
    type: 'GET_PARTNER_PRODUCT_WATCHER',
    payload,
    resolve,
    reject,
  };
}

export function deleteBusinessModelWatcher(payload, resolve, reject) {
  return {
    type: 'DELETE_BUSINESS_MODEL',
    payload,
    resolve,
    reject,
  };
}
