export const offerGenerateJson = () => {
  return {
    offered_amount: {
      label: 'Max Loan Amount (₹)',
      name: 'offered_amount',
      id: 'offered_amount',
      type: 'string',
      placeholder: 'Loan Amount',
      isRequired: false,
      defaultValue: 10,
    },
    tenure: {
      label: 'Tenure (Months)',
      name: 'tenure',
      id: 'tenure',
      type: 'number',
      placeholder: 'Tenure',
      isRequired: false,
      defaultValue: 10,
    },
    offered_int_rate: {
      label: 'Interest Rate (%)',
      name: 'offered_int_rate',
      id: 'offered_int_rate',
      type: 'number',
      placeholder: 'Interest Rate',
      isRequired: false,
      defaultValue: 10,
    },
  };
};
