import React, { useEffect, useState } from 'react';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button/Button';
import Table from 'react-sdk/dist/components/Table/Table';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import './ServiceFeeRecon.css';
import { useHistory } from 'react-router-dom';
import TrashIcon from '../../assets/Images/trash.svg';
import EyeIcon from '../../assets/Images/eye 1.svg';
import DocumentTextIcon from '../../assets/Images/document-text.svg';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import { demoData } from './demoData';
import { storedList } from 'util/localstorage';
import { useDispatch } from 'react-redux';
import { getPartnerProductWatcher } from 'serviceFeeRecon/actions/reconPartner.action';
import { deleteBusinessModelWatcher } from 'serviceFeeRecon/actions/reconPartner.action';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import SwapVertIcon from '@mui/icons-material/SwapVert';
const user = storedList('user');
const ServiceFeeReconView = () => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [partnerListingData, setPartnerListingData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [selectedBusinessModalDetails, setSelectedBusinessModalDetails] = useState({});
  const [filterType, setFilterType] = useState({ partner: 1, product: 1, status: 1 });
  const [field, setField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const sortFunction = (field) => {
    setField(field);
    const payload = {
      page: parseInt(page) + 1,
      limit: limit,
      user_id: user?._id,
      sortType: filterType[field],
      sortFeild: field,
    };
    if (searchText) {
      payload.name = searchText;
    }
    new Promise((resolve, reject) => {
      dispatch(getPartnerProductWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setTotalCount(response.count);
        let tableData = [];
        response?.rows?.map((item) => {
          let rowData = {
            partner: item.partner,
            product: item.product,
            productId: item._id,
            companyId: item.company_id,
          };
          if (item.model_id !== undefined) {
            rowData['modelId'] = item.model_id;
          }
          if (rowData.modelId) {
            rowData['status'] = 'assigned';
          } else {
            rowData['status'] = 'not_assigned';
          }
          tableData.push(rowData);
        });
        setPartnerListingData([...tableData]);
        setSortType(filterType[field]);
        setFilterType((prevState) => ({
          ...prevState,
          [field]: filterType[field] == 1 ? 0 : 1,
        }));
      })
      .catch((err) => {
        showAlert(err.message ?? 'Something went wrong.', 'error');
      });
  };

  const handleSearchClick = (event) => {
    const value = event.value;
    if (!value && searchText) {
      setPage((prev) => {
        fetchPartnerList();
        return 0;
      });
      return;
    }
    setSearchText(value);
  };
  const handleClose = () => {
    setOpenPopup(false);
  };
  const customButton = {
    borderRadius: '8px',
    width: '240px',
    height: '56px',
    fontSize: '16px',
  };
  const customButton1 = {
    borderRadius: '8px',
    width: '240px',
    height: '56px',
    fontSize: '16px',
    backgroundColor: 'white',
  };
  const handleCreateBusinessModel = () => {
    navigate.push('service-fee-recon/create-business-model');
  };

  const handleChangePage = async (newPage) => {
    setPage(newPage);
  };

  const deleteBusinessModel = (selectedBusinessModalDetails) => {
    const payload = {
      businessModalId: selectedBusinessModalDetails.modelId,
      productId: selectedBusinessModalDetails.productId,
      companyId: selectedBusinessModalDetails.companyId,
      user_id: user?._id,
    };

    new Promise((resolve, reject) => {
      dispatch(deleteBusinessModelWatcher(payload, resolve, reject));
    })
      .then((response) => {
        showAlert(response?.message, 'success');
        fetchPartnerList();
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message ?? 'Something went wrong!', 'error');
      });
  };

  const fetchPartnerList = () => {
    const payload = {
      page: parseInt(page) + 1,
      limit: limit,
      user_id: user?._id,
      sortType: sortType,
      sortFeild: field,
    };
    if (searchText) {
      payload.name = searchText;
    }
    new Promise((resolve, reject) => {
      dispatch(getPartnerProductWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setTotalCount(response.count);
        let tableData = [];
        response?.rows?.map((item) => {
          let rowData = {
            partner: item.partner,
            product: item.product,
            productId: item._id,
            companyId: item.company_id,
          };
          if (item.hasOwnProperty('model_id') && item.model_id !== null) {
            rowData['modelId'] = item.model_id;
          }
          if (rowData.hasOwnProperty('modelId')) {
            rowData['status'] = 'assigned';
          } else {
            rowData['status'] = 'not_assigned';
          }
          tableData.push(rowData);
        });
        setPartnerListingData([...tableData]);
      })
      .catch((err) => {
        showAlert(err.message ?? 'Something went wrong.', 'error');
      });
  };

  useEffect(() => {
    if (page != null && limit != null) {
      fetchPartnerList();
    }
  }, [limit, page]);

  const renderProductStatus = (status) => {
    switch (status) {
      case 'assigned':
        return <div className="status-assigned-style">Assigned</div>;
      case 'not_assigned':
        return <div className="status-not-assigned-style">Not Assigned</div>;
      case 'not_applicable':
        return <div className="status-not-applicable-style">Not Applicable</div>;
      default:
        return <></>;
    }
  };

  const handleViewBusinessModel = (businessModelDetails) => {
    if (!businessModelDetails.hasOwnProperty('modelId')) {
      showAlert('No Business Model available for this Partner', 'error');
      return;
    }
    navigate.push(`service-fee-recon/business-model-details/${businessModelDetails.companyId}/${businessModelDetails.productId}/${businessModelDetails.modelId}`);
  };

  const handleViewReconSummary = (businessModelDetails) => {
    navigate.push(`service-fee-recon/recon-summary`, {
      companyId: businessModelDetails.companyId,
      productId: businessModelDetails.productId,
    });
  };
  const handleConfirmed = () => {
    deleteBusinessModel(selectedBusinessModalDetails);
    setOpenPopup(false);
  };
  const handleDeleteBusinessModel = (businessModelDetails) => {
    if (!businessModelDetails.hasOwnProperty('modelId')) {
      showAlert('No Business Model available for this Partner', 'error');
      return;
    }
    setSelectedBusinessModalDetails(businessModelDetails);
    setOpenPopup(true);
  };

  const renderActions = (businessModelDetails) => {
    return (
      <div className="action-icon-container-style">
        <div className="tool-tip-container-style">
          <img className="action-icon-style" src={EyeIcon} onClick={() => handleViewBusinessModel(businessModelDetails)} />
          <div className="tool-tip-style">View Business Model</div>
        </div>
        <div className="tool-tip-container-style">
          <img className="action-icon-style" src={DocumentTextIcon} onClick={() => handleViewReconSummary(businessModelDetails)} />
          <div className="tool-tip-style">Recon Summary</div>
        </div>
        <div className="tool-tip-container-style">
          <img className="action-icon-style" src={TrashIcon} onClick={() => handleDeleteBusinessModel(businessModelDetails)} />
          <div className="tool-tip-style">Delete</div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      id: 'partner',
      label: (
        <div className="filter-icon">
          <div className="table-header-style">PARTNER</div>
          <SwapVertIcon style={{ height: '20px' }} onClick={() => sortFunction('partner')} />
        </div>
      ),
      format: (rowData) => {
        return <div className="partner-column-data-style">{rowData.partner}</div>;
      },
    },
    {
      id: 'product',
      label: (
        <div className="filter-icon">
          <div className="table-header-style">PRODUCT</div>
          <SwapVertIcon style={{ height: '20px' }} onClick={() => sortFunction('product')} />
        </div>
      ),
      format: (rowData) => {
        return <div className="product-column-data-style">{rowData.product}</div>;
      },
    },
    {
      id: 'status',
      label: (
        <div className="filter-icon">
          <div className="table-header-style">STATUS</div>
          <SwapVertIcon style={{ height: '20px' }} onClick={() => sortFunction('status')} />
        </div>
      ),
      format: (rowData) => {
        return <div>{renderProductStatus(rowData.status)}</div>;
      },
    },
    {
      id: 'action',
      label: <div className="table-header-style">ACTIONS</div>,
      format: (rowData) => {
        return renderActions(rowData);
      },
    },
  ];

  const formateData = (arrayData) => {
    let newArray = [];
    let currPartnerName = '';
    arrayData.map((item, index) => {
      if (currPartnerName !== item.partner) {
        newArray.push({ ...item });
        currPartnerName = item.partner;
      } else {
        newArray.push({
          ...item,
          partner: '',
        });
      }
    });
    return newArray;
  };

  const handleSetRowLimit = (value) => {
    setLimit(value);
  };

  const renderDeletePopup = () => {
    return (
      <>
        <FormPopup heading="Delete" onClose={handleClose} customHeaderStyle={{ paddingLeft: '40%' }} isCancel={true} isOpen={openPopup}>
          <div className="delete-popup-text-style">Are you sure you want to permanently delete the business model?</div>
          <div style={{ display: 'flex', marginTop: '8%' }}>
            <Button label="Cancel" buttonType="secondary" onClick={handleClose} customStyle={customButton1} />
            <Button label="Delete" buttonType="primary" onClick={handleConfirmed} customStyle={customButton} />
          </div>
        </FormPopup>
      </>
    );
  };

  const showAlert = (msg, type) => {
    const element = document.getElementById('TopNavBar');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }

    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);

    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  return (
    <>
      {openPopup ? renderDeletePopup() : null}
      <div className="service-fee-recon-container-style">
        <div className="service-fee-recon-header-style">
          <InputBox
            label={`Search with partner or product name`}
            isDrawdown={false}
            onClick={(e) => {
              if (e.value) setSearchText(e.value);
              handleSearchClick(e);
            }}
            isSearch={true}
            customClass={{
              width: '376px',
              maxWidth: 'none',
              height: '56px',
              borderRadius: '8px',
            }}
            customInputClass={{ maxWidth: 'none', width: '300px' }}
          />
          <Button
            label="Create Business Model"
            buttonType="primary"
            customStyle={{
              width: '14.75rem',
              height: '3rem',
              padding: '0px 0px 0px 0px',
              borderRadius: '0.5rem',
              fontSize: '1rem',
              display: 'flex',
              fontFamily: 'Montserrat-Regular',
              fontWeight: '600',
            }}
            onClick={handleCreateBusinessModel}
          />
        </div>
        <div style={{ marginTop: '2.5rem' }}>
          <Table
            columns={columns}
            data={partnerListingData}
            customStyle={{
              display: 'grid',
              gridTemplateColumns: '28% 28% 28% 16%',
              height: 'max-content',
            }}
          />

          {partnerListingData.length > 0 ? <Pagination key={searchText} itemsPerPage={limit} totalItems={totalCount} onPageChange={handleChangePage} setRowLimit={handleSetRowLimit} rowsPerPageOptions={[10, 20, 30]} showOptions={true} /> : null}
        </div>
        {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
      </div>
    </>
  );
};

export default ServiceFeeReconView;
