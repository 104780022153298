export const loansData = () => [
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    loan_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'active',
    action: 'update',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    loan_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'active',
    action: 'view',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    loan_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'active',
    action: 'view',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    loan_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'active',
    action: 'view',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    loan_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'active',
    action: 'view',
  },
  {
    loan_app_id: 'AMLHAPTRH100000004337',
    loan_id: 'AMLHAPTRH100000004337',
    cust_name: 'Jenny Wilson',
    loan_amt: 1070000,
    app_date: '06-08-2023',
    status: 'active',
    action: 'Resume',
  },
];
