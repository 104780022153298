import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function getDocumentsApi(payload) {
  return axios.post(BASE_URL + `download-all-documents-common/${payload?.loan_app_id}`, payload);
}

export function findDocumentsApi(payload) {
  return axios.post(BASE_URL + `request-download-document/${payload?.loan_app_id}`, payload);
}
