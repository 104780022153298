import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import error from '../../../components/assets/error-24.svg';
import success from '../../../components/assets/success-35.svg';
import info from '../../../components/assets/info-57.svg';
import warning from '../../../components/assets/warning.svg';
import { Alert_Title, Alert_Type } from '../../constants/string_constants';
import { Alert_Font_Color, Alert_Bg_Color } from '../../constants/color_constant';

const SlidingAlert = ({ type, message, handleClose, closeTime = 3000 }) => {
  const [color, setColor] = useState('');
  const [icons, setIcons] = useState(null);
  const [text, setText] = useState(null);
  const [bgColor, setBgColor] = useState(null);
  const timeoutRef = useRef();

  const handleCloseClick = () => {
    clearTimeout(timeoutRef.current);
    handleClose();
  };

  useEffect(() => {
    switch (type) {
      case Alert_Type.Error:
        setColor(Alert_Font_Color[Alert_Type.Error]);
        setIcons(error);
        setText(Alert_Title[Alert_Type.Error]);
        setBgColor(Alert_Bg_Color[Alert_Type.Error]);
        break;
      case Alert_Type.Info:
        setColor(Alert_Font_Color[Alert_Type.Info]);
        setIcons(info);
        setText(Alert_Title[Alert_Type.Info]);
        setBgColor(Alert_Bg_Color[Alert_Type.Info]);
        break;
      case Alert_Type.Success:
        setColor(Alert_Font_Color[Alert_Type.Success]);
        setIcons(success);
        setText(Alert_Title[Alert_Type.Success]);
        setBgColor(Alert_Bg_Color[Alert_Type.Success]);
        break;
      case Alert_Type.Warning:
        setColor(Alert_Font_Color[Alert_Type.Warning]);
        setIcons(warning);
        setText(Alert_Title[Alert_Type.Warning]);
        setBgColor(Alert_Bg_Color[Alert_Type.Warning]);
      default:
    }
  }, [type]);

  const styleParentDiv = {
    border: `1.2px solid ${color}`,
    backgroundColor: bgColor,
  };

  const containerDiv = {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
  };

  const iconImage = {
    height: '24px',
    width: '24px',
    marginTop: '4px',
  };

  const buttonStyle = {
    color: '#272827',
    fontSize: '16px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0px',
  };

  const headingStyle = {
    color: color,
    fontWeight: 'bold',
    fontSize: '18px',
    margin: 0,
    padding: 0,
    fontFamily: 'Montserrat-Regular',
  };
  const contentStyle = {
    color: '#1F1F1F',
    marginBottom: '5px',
  };

  useEffect(() => {
    if (type === Alert_Type.Success) {
      timeoutRef.current = setTimeout(() => {
        handleCloseClick();
      }, closeTime);
    }
  }, []);

  return (
    <div className="parentDiv" style={styleParentDiv}>
      <div style={containerDiv}>
        {icons && <img style={iconImage} src={icons} alt={type} />}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, margin: '0px 5px' }}>
          <p style={headingStyle}>{text}</p>
          <p style={contentStyle}>{message}</p>
        </div>
        <button onClick={handleCloseClick} style={buttonStyle}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M1 1L15.2418 15.0418" stroke="#1A1A1A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.2422 1L1.00039 15.0418" stroke="#1A1A1A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SlidingAlert;
