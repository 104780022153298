import { takeLatest, call, put } from 'redux-saga/effects';
import { updatePreLoaderWatcher } from '../actions/user';
import { getMonthlyCoaReportsAPI, downloadMonthlyCoaReportsAPI } from '../apis/coaReport';

export function* getMonthlyCoaReportsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getMonthlyCoaReportsAPI, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getMonthlyCoaReportsWatcherSaga() {
  yield takeLatest('GET_MONTHLY_COA_REPORTS', getMonthlyCoaReportsEffectSaga);
}

export function* downloadMonthlyCoaReportsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(downloadMonthlyCoaReportsAPI, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* downloadMonthlyCoaReportsWatcherSaga() {
  yield takeLatest('DOWNLOAD_MONTHLY_COA_REPORTS', downloadMonthlyCoaReportsEffectSaga);
}
