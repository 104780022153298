import Button from 'react-sdk/dist/components/Button';

export const getSubmitButton = (label, onClick, isLoading, formValidation) => {
  return (
    <Button
      label={label}
      onClick={onClick}
      buttonType="primarys"
      isLoading={isLoading}
      customStyle={{
        display: 'inline - flex',
        height: '48px',
        width: 'max-content',
        padding: '10px 24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        color: '#FFF',
        fontFamily: 'Montserrat-Regular',
        pointerEvents: !formValidation === true ? 'none' : 'auto',
        fontSize: '16px',
        fontWeight: '800',
        lineHeight: '150%',
        flexShrink: '0',
        borderRadius: '40px',
        background: formValidation ? 'var(--primary-blue-button-gradient, linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%))' : 'var(--neutrals-neutral-30, #CCCDD3)',
      }}
      customLoaderClass={{
        borderTop: '4px solid #fff',
      }}
    />
  );
};

export const getSaveAsDraftButton = (label, onClick, isDisable, isLoading) => {
  return (
    <Button
      id="saveDraft"
      label={label}
      buttonType="secondary"
      customStyle={{
        fontSize: '1rem',
        color: isDisable ? '#ffffff' : '#134CDE',
        border: !isDisable && '1px solid #134CDE',
        height: '3rem',
        width: 'max-content',
        padding: '10px 24px',
        borderRadius: '40px',
        marginRight: '10px',
        pointerEvents: isDisable ? 'none' : 'auto',
        background: isDisable && 'var(--neutrals-neutral-30, #CCCDD3)',
      }}
      onClick={onClick}
      isDisabled={isDisable}
      isLoading={isLoading}
    />
  );
};
