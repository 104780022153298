import { Number_Constants } from "lap/constants/number_constants";
import React from "react";
import { AlertBox } from 'components/AlertBox';

const sanitizeResponse = (value) => {
    const NUMBER_DECIMAL = '$numberDecimal';
    const OBJECT = 'object';
    if (typeof value === OBJECT && value.hasOwnProperty(NUMBER_DECIMAL)) {
      return parseFloat(value.$numberDecimal).toFixed(Number_Constants.TWO);
    }
    return String(value);
};
export const replaceStringVl = (data) => {
  // console.log("data", data);
    const newData = {};
    for (const key in data) {
      const [_, newKey = ""] = key.split('_vl_');
        newData[newKey] = data[key];
    }
    return newData;
};

export const getCamsStateValue = (fieldObj, response) => {
    const {type, field} = fieldObj;
    const prefix = `${type}_vl_`;
    const stateKey = `${prefix}${field}`;
    const errorKey = `${prefix}${field}State`;
    let stateValue = "";
    
    if (response[`${field}`] != undefined && String(response[`${field}`]).length > Number_Constants.ZERO) {
        stateValue = sanitizeResponse(response[`${field}`]);
    } else {
        stateValue = '';
    }
    const errorValue = validateCamsData(type, stateValue) ? "" : "has-danger";
    return {
        stateValue: { [stateKey]: stateValue },
        errorValue: { [errorKey]: errorValue }
    }
}

export const validateCamsData = (type, value = "", isMandatoryField = false) => {
    if(value === "" && !isMandatoryField) {
        return true;
    }
    switch (type) {
      case 'b64':
        const b64 =
          /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
        return b64.test(value);
      case 'string':
        const string = /^.{1,750}$/;
        return string.test(value);
      case 'pincode':
        const pincode = /^(\d{6})$/;
        return pincode.test(value);
      case 'ifsc':
        const ifsc = /^[A-Z]{4}[0]{1}[a-zA-Z0-9]{6}$/;
        return ifsc.test(value);
      case 'mobile':
        const mobile = /^(\d{10})$/;
        return mobile.test(value);
      case 'phone':
        const phone = /^(\d{11})$/;
        return phone.test(value);
      case 'pan':
        const pan =
          /^([A-Z]){3}([ABCFGHLJPTE]){1}([A-Z]){1}([0-9]){4}([A-Z]){1}?$/;
        return pan.test(value);
      case 'email':
        const email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        return email.test(value);
      case 'aadhaar':
        const aadhaar = /(^.{8}[0-9]{4})$/;
        return aadhaar.test(value);
      case 'alphanum':
        const alphanum = /^[a-zA-Z0-9]{1,50}$/;
        return alphanum.test(value);
      case 'date':
        const date = /^(\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$)/;
        return date.test(value);
      case 'dateTime':
        const dateTime =
          /^(\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])\ (0[0-9]|1[0-9]|2[0123])\:([012345][0-9])\:([012345][0-9])$)/;
        return dateTime.test(value);
      case 'dob':
        const dob = /^(\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$)/;
        return dob.test(value);
      case 'float':
        const float = /^(?:\d*\.\d{1,2}|\d+)$/;
        return float.test(value) ? true : value == 0 || value == 0.0;
      case 'passport':
        const passport = /^[A-Z][0-9]{7}$/;
        return passport.test(value);
      case 'number':
        const number = /^[0-9.]+$/;
        return number.test(value);
      case 'integer':
        const integer = /^[-+]?\d*$/;
        return integer.test(value);
      case 'gst':
        const gst =
          /^([0][1-9]|[1-2][0-9]|[3][0-8]|[9][79])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
        return gst.test(value);
      case 'driving':
        const driving = /^([A-Z]{2}[0-9]{2}\s[0-9]{11})+$/;
        return driving.test(value);
      case 'epic':
        const epic = /^([a-zA-Z]){3}([0-9]){7}?$/;
        return epic.test(value);
      case 'ack':
        const ack = /^([0-9]){15}$/;
        return ack.test(value);
      case 'uan':
        const uan = /^\d{12}$/;
        return uan.test(value);
      case 'vpa':
        const vpa = /^\w+.\w+@\w+$/;
        return vpa.test(value);
      case 'twodigit':
        const twodigit = /^\d{2}$/;
        return twodigit.test(value);
      case 'alpha':
        const alpha = /^[A-Za-z\s]{1,250}$/;
        return alpha.test(value);
      case 'singleAlpha':
        const singleAlpha = /^[A-Z\s]{1}$/;
        return singleAlpha.test(value);
      case 'consent':
        const consent = /^\w{1}$/;
        return consent.test(value);
      case 'confirmation':
        const confirmation = /^(Y|N)$/;
        return confirmation.test(value);
      case 'consumerid':
        const consumerid = /^\d{12}/;
        return consumerid.test(value);
      case 'timestamp':
        const timestamp = /^(\d{10})$/;
        return timestamp.test(value);
      case 'txntype':
        const txntype =
          /^(overdue|interest|pf|usage|repayment|manage|emi|waiver|bounce*)$/;
        return txntype.test(value);
      case 'bounce':
        const bounce = /^(bounce*)$/;
        return bounce.test(value);
      case 'emi':
        const emi = /^(emi*)$/;
        return emi.test(value);
      case 'manage':
        const manage = /^(manage*)$/;
        return manage.test(value);
      case 'repayment':
        const repayment = /^(repayment*)$/;
        return repayment.test(value);
      case 'usage':
        const usage = /^(usage*)$/;
        return usage.test(value);
      case 'pf':
        const pf = /^(pf*)$/;
        return pf.test(value);
      case 'interest':
        const interest = /^(interest*)$/;
        return interest.test(value);
      case 'overdue':
        const overdue = /^(overdue*)$/;
        return overdue.test(value);
      case 'txnentry':
        const txnentry = /^(cr|dr*)$/;
        return txnentry.test(value);
      case 'usageTxnentry':
        const dr = /^(dr*)$/;
        return dr.test(value);
      case 'repayTxnentry':
        const cr = /^(cr*)$/;
        return cr.test(value);
      case 'decimalUARAUPRP':
        const decimalUARAUPRP = /^(\d{1,8})(.\d{1,4})?(UA|RA|UP|RP)$/;
        return decimalUARAUPRP.test(value);
      case 'decimalRARP':
        const decimalRARP = /^(\d{1,8})(.\d{1,4})?(RA|RP)$/;
        return decimalRARP.test(value);
      case 'decimalUAUP':
        const decimalUAUP = /^(\d{1,8})(.\d{1,4})?(UA|UP)$/;
        return decimalUAUP.test(value);
      case 'decimalAP':
        const decimalAP = /^(\d{1,8})(.\d{1,4})?(A|P)$/;
        return decimalAP.test(value);
      case 'otp':
        const otp = /^[0-9]{6}$$/;
        return otp.test(value);
      case 'duesArray':
        return value.length;
      case 'ckycnumber':
        const ckycnumber = /^([0-9]){14}$/;
        return ckycnumber.test(value);
      case 'vehicleNo':
        const vehicleNo =
          /^[A-Z]{2}\d[A-Z]{2}\d{4}$|^[A-Z]{2}\d{2}[A-Z0-9]{2}\d{3,4}$|^[A-Z]{2}\d{2}[A-Z]\d{4}$|^[A-Z]{2}\d{6}$|^[A-Z]{3}\d{4}|^[A-Z]{2}\d{1}[A-Z]{3}\d{3,4}$|^[A-Z]{2}\d{1}[A-Z]{1}\d{4}|^[A-Z]{2}\d{2}[A-Z]{1}\d{3}$|^[A-Z]{2}\d[A-Z]{3}\d{4}$|^[A-Z]{2}\d{2}[A-Z]{1,2}\d{2}$|^[0-9]{2}[B,H]{2}[0-9]{4}[A-Z]{1,2}$/;
        return vehicleNo.test(value);
      case 'consentDateTime':
        const dateTimeRegex =
          /^(\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])\ (0[0-9]|1[0-9]|2[0123])\:([012345][0-9])\:([012345][0-9])$)/;
        const isValidDateTime = dateTimeRegex.test(value);
        if (!isValidDateTime) {
          return false;
        }
        const userDate = value;
        const consentTimestampUTC = new Date(userDate + ' GMT+0530');
        const inputDate = new Date(
          Date.UTC(
            userDate.slice(0, 4),
            userDate.slice(5, 7) - 1,
            userDate.slice(8, 10),
            userDate.slice(11, 13),
            userDate.slice(14, 16),
            userDate.slice(17, 19),
          ),
        );
        const serverDate = new Date();
        const isFutureDate = consentTimestampUTC > serverDate;
        if (isFutureDate) {
          return false;
        }
        const year = inputDate.getUTCFullYear();
        const isValidYear = year > 0 && year <= 9999;
        if (!isValidYear) {
          return false;
        }
        const month = inputDate.getUTCMonth() + 1;
        const day = inputDate.getUTCDate();
        const hour = inputDate.getUTCHours();
        const minute = inputDate.getUTCMinutes();
        const second = inputDate.getUTCSeconds();
        const monthString = month < 10 ? '0' + month : month.toString();
        const dayString = day < 10 ? '0' + day : day.toString();
        const hourString = hour < 10 ? '0' + hour : hour.toString();
        const minuteString = minute < 10 ? '0' + minute : minute.toString();
        const secondString = second < 10 ? '0' + second : second.toString();
        const formattedDate = `${year}-${monthString}-${dayString} ${hourString}:${minuteString}:${secondString}`;
        return formattedDate === value;
      case 'object':
          return !!value;
      default:
        return true;
    }
  };