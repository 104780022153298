import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { userListWatcher, toggleUserStatusWatcher, resetPassword, searchUser } from '../actions/user';
import { checkAccessTags } from '../util/uam';
import { storedList } from '../util/localstorage';
import { PasswordReset } from './PasswordReset';
import UserPopUp from './userPopUp';

import './user/User.style.css';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button/Button';
import PlusIcon from 'assets/img/icons/plus-icon.svg';
import Table from 'react-sdk/dist/components/Table/Table';
import ToggleSwitch from 'react-sdk/dist/components/ToggleSwitch/ToggleSwitch';
import resetPasswordIcon from 'assets/img/icons/keyIcon.svg';
import editUserIcon from 'assets/img/icons/editIcon.svg';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import Alert from 'react-sdk/dist/components/Alert/Alert';

const user = storedList('user');

const defaultErrors = {
  nameError: false,
  emailError: false,
  typeError: false,
  companyError: false,
  userRolesError: false,
};

const CreateUser = () => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [userList, setUserList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [displayUsersList, setDisplayUsersList] = useState([]);
  const [label, setLabel] = useState('Users');
  const [readWrite, setReadWrite] = useState(true);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [isDisableReset, setIsDisableReset] = useState(false);
  const [openNewUser, setOpenNewUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;
  const accessReadWrite = isTagged ? checkAccessTags(['tag_user_read_write']) : true;

  React.useEffect(() => {
    if (isTagged && checkAccessTags(['tag_user_read', 'tag_user_read_write'])) fetchUserList();
    if (!isTagged) fetchUserList();
  }, []);

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const fetchUserList = () => {
    const payload = {};
    new Promise((resolve, reject) => {
      dispatch(userListWatcher(payload, resolve, reject));
    })
      .then((response) => {
        response = response.map((user, index) => ({ ...user, 'S.NO.': index + 1 }));
        setUserList(response);
        setDisplayUsersList(response.slice(0, 10));
        setCount(response.length);
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage(error.response.data.message);
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
      });
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const toggleUserStatus = (isActive, id) => {
    const data = {
      id: id,
      status: isActive,
    };
    new Promise((resolve, reject) => {
      dispatch(toggleUserStatusWatcher(data, resolve, reject));
    })
      .then((response) => {
        fetchUserList();
        return showAlert(response.message, 'success');
      })
      .catch((error) => {
        return showAlert(error.response.data.message, 'error');
      });
  };

  const handleSetForUpdate = (row) => {
    setSelectedUser(row);
    return setOpenEditUser(true);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setDisplayUsersList(userList.slice(newPage * 10, newPage * 10 + 10));
  };

  const handleSetForResetPassword = (userData) => {
    const payload = { id: userData._id, type: 'admin' };
    setIsDisableReset(true);
    new Promise((resolve, reject) => {
      dispatch(resetPassword(payload, resolve, reject));
    })
      .then((response) => {
        setIsDisableReset(false);
        fetchUserList();
        return showAlert(response.message, 'success');
      })
      .catch((error) => {
        setIsDisableReset(false);
        return showAlert(error.response.data.message, 'error');
      });
  };

  const handleSearchClick = () => {
    if (userSearch) {
      const payload = { searchstring: userSearch };
      new Promise((resolve, reject) => {
        dispatch(searchUser(payload, resolve, reject));
      })
        .then((response) => {
          response = response.map((user, index) => ({ ...user, 'S.NO.': index + 1 }));
          if (response.length) {
            setUserList(response);
            setDisplayUsersList(response.slice(0));
            setCount(response.length);
          } else {
            fetchUserList();
          }
        })
        .catch((error) => {
          fetchUserList();
          return showAlert(error.message, 'error');
        });
    } else {
      fetchUserList();
    }
  };

  const disableActionButtonStyle = {
    pointerEvents: `${!accessReadWrite ? 'none' : 'auto'}`,
    opacity: `${!accessReadWrite ? '0.5' : '1'}`,
  };

  const columns = [
    {
      id: 'Sr.no',
      label: <div className="table-header-style">SR.NO</div>,
      format: (rowData) => {
        return (
          <React.Fragment>
            <div className="column-data-style">{rowData['S.NO.']}</div>
          </React.Fragment>
        );
      },
    },
    {
      id: 'name-email',
      label: <div className="table-header-style">NAME-EMAIL</div>,
      format: (rowData) => {
        return (
          <React.Fragment>
            <div className="column-data-style">
              <div>{rowData.username}</div>
              <div>{rowData.email}</div>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      id: 'type',
      label: <div className="table-header-style">TYPE</div>,
      format: (rowData) => {
        return (
          <React.Fragment>
            <div className="column-data-style">{rowData.type}</div>
          </React.Fragment>
        );
      },
    },
    {
      id: 'role',
      label: <div className="table-header-style">ROLE</div>,
      format: (rowData) => {
        return (
          <React.Fragment>
            <div className="column-data-style">
              {rowData.userroles.map((role, index) => (
                <span key={`${rowData['S.NO.']} ${index}`}>{`${role}, `}</span>
              ))}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      id: 'status',
      label: <div className="table-header-style">STATUS</div>,
      format: (rowData) => {
        return (
          <React.Fragment>
            <div style={disableActionButtonStyle}>
              <ToggleSwitch
                checked={rowData.status}
                onChange={(value) => {
                  toggleUserStatus(!value, rowData._id);
                }}
              />
            </div>
          </React.Fragment>
        );
      },
    },
    {
      id: 'action',
      label: <div className="table-header-style">ACTION</div>,
      format: (rowData) => {
        return (
          <React.Fragment>
            <div className="tool-tip-container-style" style={disableActionButtonStyle}>
              <img src={editUserIcon} className="action-icon-style" onClick={() => handleSetForUpdate(rowData)} style={{ filter: `${!accessReadWrite ? 'invert(67%) sepia(24%) saturate(240%) hue-rotate(171deg) brightness(85%) contrast(92%)' : ''}` }} />
              <div className="tool-tip-style">Edit user</div>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      id: 'resetPassword',
      label: <div className="table-header-style">RESET PASSWORD</div>,
      format: (rowData) => {
        return (
          <React.Fragment>
            <div className="tool-tip-container-style" style={disableActionButtonStyle}>
              <img src={resetPasswordIcon} className="action-icon-style" onClick={() => handleSetForResetPassword(rowData)} style={{ filter: `${!accessReadWrite ? 'invert(67%) sepia(24%) saturate(240%) hue-rotate(171deg) brightness(85%) contrast(92%)' : ''}` }} />
              <div className="tool-tip-style">Reset password</div>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <>
      <UserPopUp
        openDialog={openNewUser}
        user={selectedUser}
        action={'new'}
        refreshData={() => {
          fetchUserList();
        }}
        handleClose={() => {
          setOpenNewUser(false);
        }}
        showAlert={showAlert}
      />
      <UserPopUp
        openDialog={openEditUser}
        user={selectedUser}
        action={'edit'}
        refreshData={() => {
          fetchUserList();
        }}
        handleClose={() => {
          setOpenEditUser(false);
        }}
        showAlert={showAlert}
      />
      <PasswordReset openPopup={openResetPassword} setOpenPopup={setOpenResetPassword} userData={selectedUser} />

      <div className="user-container">
        <div className="user-header-title">{label} </div>
        <div className="user-header-style">
          <InputBox
            label="Search"
            isDrawdown={false}
            isSearch={true}
            onClick={(e) => {
              if (e.value) {
                setUserSearch(e.value);
              } else {
                handleSearchClick();
              }
            }}
            customClass={{
              width: '376px',
              maxWidth: 'none',
              height: '56px',
              borderRadius: '8px',
              fontFamily: 'Montserrat-Regular',
            }}
            customInputClass={{
              maxWidth: 'none',
              width: '300px',
            }}
          />
          <Button
            customStyle={{
              height: '48px',
              fontSize: '16px',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: 'none',
              backgroundColor: 'white',
              fontFamily: 'Montserrat-Regular',
              padding: '22px',
              border: '1px solid #134CDE',
              borderRadius: '5px',
            }}
            label="Add User"
            buttonType="primary"
            imageButton={PlusIcon}
            imageButtonHover={PlusIcon}
            iconButton="btn-secondary-download-button"
            isDisabled={isTagged ? !checkAccessTags(['tag_user_read_write']) : false}
            onClick={() => {
              setOpenNewUser(true);
            }}
          />
        </div>
        <Table
          columns={columns}
          data={displayUsersList}
          customStyle={{ display: 'grid', gridTemplateColumns: '6% 20% 9% 28% 9% 9% 15%', height: 'max-content' }}
          customCellCss={{
            width: 'auto',
          }}
        />
        {count ? <Pagination key={count} itemsPerPage={10} totalItems={count} onPageChange={handleChangePage} rowsPerPageOptions={[10]} /> : null}
        {readWrite ? (
          alert ? (
            <Alert
              severity={severity}
              message={alertMessage}
              handleClose={handleAlertClose}
              style={{
                position: 'fixed',
              }}
            />
          ) : null
        ) : null}
      </div>
    </>
  );
};

export default CreateUser;
