import axios from 'axios';
import { BASE_URL } from 'constants/apiUrls';

export function getPartnerProductApi(payload) {
  return axios.get(`${BASE_URL}business-model`, {
    params: payload,
  });
}

export function deleteBusinessModelApi(payload) {
  return axios.post(`${BASE_URL}bussiness-model/${payload.businessModalId}`, payload);
}
