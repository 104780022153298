const componentStyles = (theme) => ({
  cardRoot: {
    marginBottom: '30px',
  },
  redBg: {
    background: 'linear-gradient(87deg,' + theme.palette.error.main + ',#f56036)!important',
  },
  blueBg: {
    background: 'linear-gradient(87deg,' + theme.palette.info.main + ',#1171ef)!important',
  },
  greenBg: {
    background: 'linear-gradient(87deg,' + theme.palette.success.main + ',#2dcecc)!important',
  },
  orangeBg: {
    background: 'linear-gradient(87deg,' + theme.palette.warning.main + ',#fbb140)!important',
  },
});

export default componentStyles;
