export default function calculateLoanDataDifference(totalCount, successCount, failureCount, pendingCount) {
  if (totalCount === failureCount) {
    return '#FF0000';
  } else if (totalCount === pendingCount) {
    return '#eda12f';
  } else {
    return '#069952';
  }
}

export function findDataUploadStatusColor(totalCount, successCount, failureCount, pendingCount) {
  if (totalCount === failureCount) {
    return 'failure';
  } else if (totalCount === pendingCount) {
    return 'pending';
  } else {
    return 'success';
  }
}

export function convertTimeOfAttemptDateFormat(actualDateFormat) {
  // Convert string to Date object
  const dateObject = new Date(actualDateFormat);

  // Helper function to format AM/PM
  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // Handle midnight (12 AM)
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${ampm}`;
  }

  // Helper function to get month name
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Format date and time
  const formattedDate = `${dateObject.getDate()} ${monthNames[dateObject.getMonth()]} ${dateObject.getFullYear()} | ${formatAMPM(dateObject)}`;
  return formattedDate;
}
