import * as React from 'react';
import { storedList } from '../../../util/localstorage';
import useDimensions from '../../../hooks/useDimensions';
import InfoIcon from '../../../assets/img/info-circle-amber.svg';
import './CommentViewer.css';
import { remarkReason } from '../../config/remarkReason';

const user = { _id: storedList('user')?._id, id: storedList('user')?.id };

export const CommentViewer = (props) => {
  const { innerWidth, innerHeight } = useDimensions();
  const styles = useStyles({ innerWidth, innerHeight });
  const { head, reasons, additionalComment } = props;
  return (
    <div style={styles.comment}>
      <div style={{ display: 'flex' }}>
        <div style={{ paddingRight: '15px' }}>
          <img src={InfoIcon} className="info-icon-style" color="yellow" alt="info" />
        </div>
        <div style={{ width: '100%' }}>
          <div className="comment-heading-style">{head}</div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {reasons && reasons.length >= 0 && (
              <div style={{ flexBasis: '47.5%' }}>
                <div className="comment-subHeading-style">Reasons</div>
                <ol>
                  {reasons.map((item, index) => {
                    return (
                      <li key={index} className="comment-content-style">
                        {remarkReason[item]}
                      </li>
                    );
                  })}
                </ol>
              </div>
            )}
            {additionalComment && additionalComment.length > 0 && (
              <div style={{ flexBasis: '47.5%' }}>
                <div className="comment-subHeading-style">Custom Comment</div>
                <div className="comment-content-style" style={{ paddingTop: '5px' }}>
                  {additionalComment}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = ({ innerWidth, innerHeight }) => {
  return {
    comment: {
      top: '117px',
      left: '473px',
      padding: '12px',
      borderRadius: '8px',
      border: '1px solid #EDA12F',
      margin: '16px',
      backgroundColor: '#FFF5E6',
    },
  };
};
