import { call, put, takeLatest } from 'redux-saga/effects';
import { getReconSummaryApi, getDownloadReportApi } from '../apis/reconSummary.api';
import { updatePreLoaderWatcher } from '../../actions/user';

export function* getReconSummaryEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const response = yield call(getReconSummaryApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(response.data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getReconSummaryWatcherSaga() {
  yield takeLatest('GET_RECON_SUMMARY_WATCHER', getReconSummaryEffectSaga);
}

export function* getDownloadReportEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const response = yield call(getDownloadReportApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(response.data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getDownloadReportWatcherSaga() {
  yield takeLatest('GET_DOWNLOAD_REPORT_WATCHER', getDownloadReportEffectSaga);
}
