import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function getComprehensiveLogsAPI(payload) {
  return axios.post(`${BASE_URL}fetch-logs`, payload);
}

export function postComprehensiveLogsAPI(payload) {
  return axios.post(`${BASE_URL}save-logs`, payload);
}
