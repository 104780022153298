import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'react-sdk/dist/styles/_fonts.scss';
import Button from 'react-sdk/dist/components/Button';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import { addAccessMetrixWatcher, getAccessMetrixWatcher, updateAccessMetrixWatcher } from '../../actions/accessMetrix';
import WriteIcon from '../../assets/img/icons/editIcon.svg';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import { checkAccessTags } from '../../util/uam';
import { storedList } from '../../util/localstorage';
import Pagination from '../../../node_modules/react-sdk/dist/components/Pagination/Pagination';
import Table from 'react-sdk/dist/components/Table/Table';
const user = storedList('user');

export const AddAccessMetrix = () => {
  const dispatch = useDispatch();
  const [tag, setTag] = useState('');
  const [title, setTitle] = useState('');
  const [roleMetrixList, setTitleList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [buttonTitle, setButtonTitle] = useState('Submit');
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  useEffect(() => {
    if (isTagged && checkAccessTags(['tag_access_matrix_read', 'tag_access_matrix_read_write'])) getRoleMetrixList();
    if (!isTagged) getRoleMetrixList();
  }, [page]);

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 2000);
  };

  const ButtonStyle = {
    width: '15%',
    borderRadius: '16px',
    fontSize: '15px',
    border: '1px solid white',
    color: 'white',
  };

  const InputBoxStyle = {
    width: '100%',
    height: '56px',
    marginRight: '2%',
  };

  const titleStyle = {
    marginLeft: '-40%',
  };

  const titleStyle1 = {
    fontSize: '14px',
    marginLeft: '-40%',
    fontFamily: 'Montserrat-Regular',
  };

  const tagStyle = {
    fontSize: '14px',
    marginRight: '-35%',
    fontFamily: 'Montserrat-Regular',
  };

  const actionStyle = {
    marginLeft: '30%',
  };

  const iconStyle = {
    marginLeft: '35%',
    height: '30px',
    filter: 'invert(33%) sepia(94%) saturate(1094%) hue-rotate(186deg) brightness(92%) contrast(90%)',
  };

  const columns = [
    {
      id: 'Sr. No.',
      label: 'Sr. No.',
      format: (rowData) => {
        return <div style={{ fontFamily: 'Montserrat-Regular' }}>{roleMetrixList.indexOf(rowData) + 1 + page * 10}</div>;
      },
    },
    {
      id: 'title',
      label: <div style={titleStyle}>TITLE</div>,
      format: (rowData) => {
        return <div style={titleStyle1}>{rowData.title}</div>;
      },
    },
    {
      id: 'tag',
      label: 'Tag',
      format: (rowData) => {
        return <div style={tagStyle}>{rowData.tag}</div>;
      },
    },
    {
      id: 'action',
      label: <div style={actionStyle}>ACTION</div>,
      format: (rowData) => (
        <img
          style={iconStyle}
          onClick={() => {
            if (isTagged && checkAccessTags(['tag_access_matrix_read_write'])) {
              handleSetForUpdate(rowData);
            }
          }}
          src={WriteIcon}
        />
      ),
    },
  ];

  const getRoleMetrixList = () => {
    const payload = {
      page,
      limit: 10,
    };
    new Promise((resolve, reject) => {
      dispatch(getAccessMetrixWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setTitleList(response.data.rows);
        setCount(response.data.count);
      })
      .catch((error) => {
        return showAlert(error?.response?.data?.message, 'error');
      });
  };

  const handleSubmit = () => {
    let data = {};
    if (title.match(/^[a-zA-Z\-_\0-9\s]+$/)) {
      new Promise((resolve, reject) => {
        if (buttonTitle === 'Submit') {
          const data = { title, tag };
          dispatch(addAccessMetrixWatcher(data, resolve, reject));
        }
        if (buttonTitle === 'Update') {
          data = {
            _id: selectedRow?._id,
            title: title,
            tag: tag,
          };
          dispatch(updateAccessMetrixWatcher(data, resolve, reject));
        }
      })
        .then((response) => {
          showAlert(response.message, 'success');
          handleClear();
          getRoleMetrixList();
        })
        .catch((error) => {
          return showAlert(error?.response?.data?.message, 'error');
        });
    } else {
      return showAlert('Title must be a string with no special characters except underscore(_) or hyphen (-)', 'error');
    }
  };

  const handleClear = () => {
    setTitle('');
    setButtonTitle('Submit');
    setTag('');
    setSelectedRow('');
  };

  const handleSetForUpdate = (row) => {
    setTitle(row.title);
    setTag(row.tag);
    setButtonTitle('Update');
    setSelectedRow(row);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleChangePage = (event) => {
    setPage(event);
    setTitle('');
    setTag('');
    setButtonTitle('Submit');
    setSelectedRow({});
  };

  const TableStyle = {
    width: '96%',
    marginLeft: '2%',
  };

  const TableStyle1 = {
    width: '20%',
  };

  return (
    <>
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
      <div>
        <header style={{ fontFamily: 'Montserrat-Bold', fontSize: '24px', marginLeft: '2%', marginBottom: '2%' }}>Access matrix</header>
      </div>
      <div style={{ display: 'flex' }}>
        <InputBox
          label="Title"
          type="text"
          placeholder="Title"
          initialValue={title}
          onClick={(event) => {
            setTitle(event.value);
          }}
          customClass={{ ...InputBoxStyle, marginLeft: '2%', fontFamily: 'Montserrat-Regular' }}
        />
        <InputBox
          label="Tag"
          type="text"
          placeholder="Tag (Must be unique)"
          initialValue={tag}
          onClick={(event) => {
            setTag(event.value);
          }}
          customClass={{ ...InputBoxStyle, fontFamily: 'Montserrat-Regular' }}
        />
        <Button buttonType="primary" label={buttonTitle} customStyle={ButtonStyle} isDisabled={isTagged ? !checkAccessTags(['tag_access_matrix_read_write']) : false} onClick={handleSubmit} />
        <Button buttonType="secondary" label="Clear" customStyle={{ ...ButtonStyle, backgroundColor: '#CC0000' }} isDisabled={isTagged ? !checkAccessTags(['tag_access_matrix_read_write']) : false} onClick={handleClear} />
      </div>
      <div>
        {roleMetrixList && roleMetrixList.length ? (
          <>
            {isTagged ? (
              checkAccessTags(['tag_access_matrix_read', 'tag_access_matrix_read_write']) ? (
                <>
                  <Table data={roleMetrixList} columns={columns} customStyle={TableStyle} customCellCss={TableStyle1} />
                  <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={10} />
                </>
              ) : null
            ) : (
              <>
                <Table data={roleMetrixList} columns={columns} customStyle={TableStyle} customCellCss={TableStyle1} />
                <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={10} />
              </>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
