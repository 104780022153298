export function getLapCamsDataWatcher(data, resolve, reject) {
  return {
    type: 'GET_LAP_CAMS_DATA',
    payload: data,
    resolve,
    reject,
  };
}

export function updateLapCamsDataWatcher(data, resolve, reject) {
  // console.log("data ---->", data);
  return {
    type: 'UPDATE_LAP_CAMS_DATA',
    payload: data,
    resolve,
    reject,
  };
}

export function getLapCamDetailsWatcher(data, resolve, reject) {
  return {
    type: 'GET_LAP_CAM_DETAILS',
    payload: data,
    resolve,
    reject,
  };
}
