import * as React from 'react';
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button';
import { validateData } from '../../util/validation';
import { addServiceWatcher } from '../../actions/services';
import { AlertBox } from '../../components/AlertBox';
import useDimensions from '../../hooks/useDimensions';

export default function AddServices(props) {
  const defaultErrors = {
    nameError: false,
    loanTemplatesError: false,
    templateNameError: false,
  };

  const dispatch = useDispatch();
  const ref = useRef();
  const [alert, setAlert] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [serviceSection, setServiceSection] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [serviceUrl, setServiceUrl] = useState('');
  const [inputFileName, setInputFileName] = useState('');
  const { innerWidth, innerHeight } = useDimensions();
  const styles = useStyles({ innerWidth, innerHeight });

  const serviceSectionList = [
    { label: 'Bureau', value: 'Bureau' },
    { label: 'KYC', value: 'KYC' },
    { label: 'Disbursement', value: 'Disbursement' },
    { label: 'E-sign', value: 'E-sign' },
    { label: 'Insurance', value: 'Insurance' },
  ];

  const serviceTypeList = [
    { label: 'GET', value: 'GET' },
    { label: 'POST', value: 'POST' },
  ];

  const handleInputChange = (field, validationType, setValue) => (event) => {
    const value = event.value;
    setValue(value);
    setErrors({
      ...errors,
      [field + 'Error']: !validateData(validationType, value),
    });
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const onFileChangeHandler = (event) => {
    setInputFileName(event.target.files[0]);
  };

  const handleSubmit = () => {
    const file = new FormData();
    const regexp = /^[a-zA-Z0-9--]+$/;
    const regExpURL = /^[a-zA-Z0-9-_]+$/;
    file.append('file', inputFileName);
    if (serviceName == '' || vendorName == '' || serviceSection == '' || serviceType == '' || serviceUrl == '') {
      setAlert(true);
      setSeverity('error');
      setAlertMessage('All fields are required');
      setTimeout(() => {
        handleAlertClose();
      }, 4000);
      return;
    }
    if (serviceName.search(regexp) === -1 || vendorName.search(regexp) === -1) {
      setAlert(true);
      setSeverity('error');
      setAlertMessage('Please enter service or vendor name with no special characters.');
      setTimeout(() => {
        handleAlertClose();
      }, 4000);
      return;
    }
    if (serviceUrl.search(regExpURL) === -1) {
      setAlert(true);
      setSeverity('error');
      setAlertMessage('Please enter service URL with no special characters.');
      setTimeout(() => {
        handleAlertClose();
      }, 4000);
      return;
    }
    const reqData = {
      service_name: serviceName,
      vendor_name: vendorName,
      section: serviceSection,
      type: serviceType,
      url: serviceUrl,
    };

    new Promise((resolve, reject) => {
      dispatch(addServiceWatcher(reqData, file, resolve, reject));
    })
      .then((response) => {
        setAlert(true);
        setSeverity('success');
        setAlertMessage(response.message);
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
        handleClear();
      })
      .catch((error) => {
        setAlert(true);
        setSeverity('error');
        setAlertMessage(error.response.data.message);
        setTimeout(() => {
          handleAlertClose();
        }, 4000);
      });
  };

  const handleClear = () => {
    setServiceName('');
    setVendorName('');
    setServiceSection('');
    setServiceType('');
    setServiceUrl('');
    ref.current.value = '';
  };

  const handleServiceTypeChange = (value) => {
    if (!value) {
      value = '';
    }
    setServiceType(value ? value.value : '');
  };

  const handleServiceSectionChange = (value) => {
    if (!value) {
      value = '';
    }
    setServiceSection(value ? value.value : '');
  };

  return (
    <div style={{ paddingTop: '10px', height: '100%', marginLeft: '1.3rem!important' }}>
      <div style={styles.headerStyle}>Add service</div>
      <div>{alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}</div>
      <div style={{ marginLeft: '1%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <InputBox id={'Service name'} label="Service Name" placeholder="Service name" initialValue={serviceName ? serviceName : ''} onClick={handleInputChange('serviceName', 'string', setServiceName)} customClass={styles.inputBoxStyle}></InputBox>
          <InputBox id={'Vendor name'} label="Vendor name" placeholder="Vendor name" initialValue={vendorName ? vendorName : ''} onClick={handleInputChange('vendorName', 'string', setVendorName)} customClass={styles.inputBoxStyle}></InputBox>
          <InputBox id={'Service section'} label="Service section" placeholder="Service section" initialValue={serviceSection ? serviceSection : ''} isDrawdown={true} options={serviceSectionList} onClick={(value) => handleServiceSectionChange(value)} customDropdownClass={styles.dropdownStyle} customClass={styles.inputBoxStyle} />
          <InputBox id={'Service type'} label="Service type" placeholder="Service type" initialValue={serviceType ? serviceType : ''} isDrawdown={true} options={serviceTypeList} customDropdownClass={styles.dropdownStyle} onClick={(value) => handleServiceTypeChange(value)} customClass={styles.inputBoxStyle}></InputBox>

          <InputBox id={'Service url'} label="Service URL" placeholder="Service URL" initialValue={serviceUrl ? serviceUrl : ''} onClick={handleInputChange('serviceUrl', 'string', setServiceUrl)} customClass={styles.inputBoxStyle}></InputBox>

          <div style={styles.belowTextStyle}>Select the template in .xlsx format only</div>
          <div>
            <input style={styles.fileUploadButtonStyle} ref={ref} type="file" name="file" accept=".xlsx" onChange={(e) => onFileChangeHandler(e, inputFileName)} />
          </div>
        </div>
      </div>
      <Button label="SUBMIT" buttonType="primary" customStyle={styles.buttonStyle} onClick={handleSubmit}></Button>
    </div>
  );
}

const useStyles = ({ innerWidth, innerHeight }) => {
  return {
    inputBoxStyle: {
      width: '50%',
      maxWidth: 'none',
      height: '56px',
      borderRadius: '8px',
      marginTop: '1%',
      marginLeft: '1%',
    },
    dropdownStyle: {
      zIndex: '10',
    },
    headerStyle: {
      fontSize: '1.25rem',
      fontFamily: 'MontSerrat-Bold',
      marginLeft: '2%',
    },
    belowTextStyle: {
      fontSize: '16px',
      fontFamily: 'MontSerrat-Regular',
      marginLeft: '1%',
      marginTop: '1%',
      color: '#141519',
    },
    buttonStyle: {
      borderRadius: '8px',
      width: '145px',
      height: '56px',
      fontSize: '16px',
      marginLeft: '2%',
    },
    fileUploadButtonStyle: {
      display: 'block',
      color: 'black',
    },
  };
};
