import React from 'react';
import { useState, useEffect } from 'react';
import TabButton from 'react-sdk/dist/components/TabButton';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TabSection from './TabSection/tabSection';
import { AuditLog } from 'lap/components/AuditLog/AuditLog';
import { storedList } from 'util/localstorage';
import { useDispatch } from 'react-redux';
import { getLapActivityLogsWatcher, commentDetailsWatcher, updateLeadDetailsWatcher, getLeadOfferWcher } from 'lap/actions/lead.action';
import { ACTIVITY_LOG_ACCORDIAN_SECTION_CODES, ACTIVITY_LOG_SECTION_NAME_AND_CODE } from 'lap/config/activityLogNaming';
import { Alert_Type, Error_Message, Section_Status, AUDIT_LOG_KEYS, Field_Label } from '../../../constants/string_constants';
import { ActionPopup } from 'lap/components/ActionPopup/ActionPopup.component';
import { COMMENT_FIELDS } from './fields';
import { setObjectKeysToDefault, toCamel } from '../../../../../src/util/helper';
import Button from 'react-sdk/dist/components/Button/Button';
import SlidingAlert from '../../../components/SlidingAlert/SlidingAlert';
import { OfferGenerate } from '../../../components/OfferGenerate/OfferGenerate.component';
import { GeneratingOfferComponent } from '../../../components/OfferGenerate/GeneratingOffer.component.jsx';
import { ConditionalOfferComponent } from '../../../components/OfferGenerate/ConditionalOffer.component';
import { OFFER_STATUS } from './helper';
import { LeadStatus } from '../../../config/LeadStatus';
import { postLEADSoftOfferWatcher } from 'lap/actions/lead.action';

function LeadReview() {
  const dispatch = useDispatch();
  const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
  let tabsAvailable = ['Lead Details', 'Documents', 'Cams'];
  const params = useParams();
  const history = useHistory();
  const paramsData = useLocation().search.split('=').slice(-1);
  const selectedTabFromURL = decodeURIComponent(paramsData);
  const { companyId, productId } = history.location.state || {};
  const [selectedTab, setSelectedTab] = useState(tabsAvailable.includes(selectedTabFromURL) ? selectedTabFromURL.toLowerCase() : tabsAvailable[0].toLowerCase());
  const [isEdit, setIsEdit] = useState(false);
  const [auditLogs, setAuditLogs] = useState([]);

  //#region [Action popup comp constants]
  const [title, setTitle] = useState('Add Comment');
  const [commentFields, setCommentFields] = useState(COMMENT_FIELDS);
  const [type, setType] = useState('addComment');
  const commentDataDefault = setObjectKeysToDefault(COMMENT_FIELDS);
  const [data, setData] = useState({ ...commentDataDefault, remarkReasons: [] });
  const [dataErrors, setDataErrors] = useState(commentDataDefault);
  const [openDialog, setOpenDialog] = useState(false);
  const mapSubSection = {
    primary_applicant: 'primary',
    entity_details: 'entity',
    'co-applicant_details': 'co_borrower',
    guarantor_details: 'guarantor',
    financial_documents: 'financial_doc',
    additional_documents: 'additional_doc',
  };
  const [section_logs, setSectionLogs] = useState([]);
  const [currentSectionName, setCurrentSectionName] = useState('primary');
  const [currentSubSectionName, setCurrentSubSectionName] = useState('');
  const [disableApproveBtn, setDisableApproveBtn] = useState('true');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [currSectionPos, setCurrentSectionPos] = useState(0);
  const [isRequestAnUpdate_Reject_Enabled, setRequestAnUpdate_Reject_Enabled] = useState(true);
  const [isApproveButtonEnabled, setIsApproveButtonEnabled] = useState(false);
  const [offer, setOffer] = useState(OFFER_STATUS.none);
  const [leadStatus, setLeadStatus] = useState('');
  const [offerResponse, setOfferResponse] = useState({});
  const [currentOfferDetails, setCurrentOfferDetails] = useState({});
  const [offerLoading, setOfferLoading] = useState(false);
  const [isLeadOffer, setIsLeadOffer] = useState(false);
  //#endregion

  useEffect(async () => {
    setRequestAnUpdate_Reject_Enabled(section_logs.length > 0);
  }, [section_logs]);

  useEffect(() => {
    const fetchData = async () => {
      await getLeadOffer();
    };
    fetchData();
  }, [params.id]);

  const getLeadOffer = () => {
    const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
    const payload = {
      loan_app_id: params.id,
      companyId: companyId,
      productId: productId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLeadOfferWcher(payload, resolve, reject));
    })
      .then((response) => {
        if (response && response?.data) {
          const offer = response?.data;
          if (!offer.offered_amount ?? offer.offered_amount === 0) {
            setLeadStatus(LeadStatus.rejected.value);
          } else if (offer.risk_cat.toUpperCase() == 'High_risk'.toUpperCase()) {
            setLeadStatus(LeadStatus.follow_up_kyc.value);
          } else if (offer?.deviation_cat != 'D0') {
            setLeadStatus(LeadStatus.offer_deviation.value);
          } else {
            setLeadStatus(LeadStatus.offer_generated.value);
          }
          setCurrentOfferDetails(offer);
          setIsLeadOffer(true);
        }
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  useEffect(async () => {
    if (isLeadOffer && currentOfferDetails != null && Object.keys(currentOfferDetails).length > 0 && currentOfferDetails.offered_amount !== 0) {
      setOffer(OFFER_STATUS.soft_offer);
    }
  }, [currentOfferDetails, isLeadOffer]);

  const handlePopupVisibility = async (sectionName, isVisible, sectionInfo) => {
    try {
      setOpenDialog(isVisible);
      if (sectionName == 'approved' || sectionName == 'rejected' || sectionName == 'Request an Update') {
        setTitle(sectionName == 'approved' ? 'Approve' : sectionName == 'Request an Update' ? 'Reqeust an Update' : 'Reject Lead Reason');
      }
      setCurrentSectionName(sectionName);
      setCurrentSubSectionName(sectionInfo?.section_code === 'guarantor' || sectionInfo?.section_code === 'co-borrowers' ? sectionInfo?.section_name : '');
    } catch (error) {
      throw error;
    }
  };

  const handleApproveButton = async (isEnable) => {
    try {
      setIsApproveButtonEnabled(isEnable);
    } catch (error) {
      throw error;
    }
  };

  const handleCommentField = (flag) => {
    let tempField = commentFields;
    tempField[`comment`][`isRequired`] = flag;
    tempField[`remarks`][`isRequired`] = !flag;
    tempField[`reasons`][`isRequired`] = !flag;
    return tempField;
  };

  const handleReject = () => {
    setTitle('Reject Lead Request');
    setType('reject');
    setOpenDialog(true);
    setCurrentSectionName('rejected');
    setCommentFields(handleCommentField(false));
  };

  const handleApprove = (title, id) => {
    setTitle(title);
    setTitle('Approve');
    setType('addComment');
    setOpenDialog(true);
    setCurrentSectionName('approved');
    setCommentFields(handleCommentField(true));
  };

  const handlePopupSubmit = async () => {
    try {
      const commentInfo = {
        type: 'credit_update',
        remarks: data.comment,
        reasons: data.reasons,
        loan_app_id: params.id,
        category: ACTIVITY_LOG_SECTION_NAME_AND_CODE[currentSectionName],
        filter: currentSubSectionName ? { section_code: ACTIVITY_LOG_SECTION_NAME_AND_CODE[currentSectionName], section_name: currentSubSectionName } : { section_code: ACTIVITY_LOG_SECTION_NAME_AND_CODE[currentSectionName] },
      };
      setSectionLogs((prevState) => [...prevState, commentInfo]);
      setOpenDialog(false);
      if (currentSectionName == 'approved' || currentSectionName == 'rejected' || currentSectionName == 'Request an Update') {
        handleApproveReject();
      }
    } catch (error) {
      throw error;
    }
  };

  const handleRequestUpdate = async () => {
    try {
      const { companyId, productId } = history.location.state || {};
      const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
      const payload = {
        loanAppId: params.id,
        companyId,
        productId,
        user: user,
        activityLogs: section_logs,
      };

      new Promise((resolve, reject) => {
        dispatch(commentDetailsWatcher(payload, resolve, reject));
      })
        .then(async (response) => {
          showAlert(title == 'Add Comment' ? response?.message : title == 'Rejected' ? 'Loan was rejected successfully' : 'Request an update successfully', 'success');
          setData('');
          setTitle('Add Comment');
          setSectionLogs([]);
          await fetchActivityLogs(currSectionPos);
        })
        .catch((error) => {
          showAlert(Error_Message.Technical_Error, Alert_Type.Error);
        });
    } catch (error) {
      throw error;
    }
  };

  const handleApproveReject = async () => {
    try {
      const { companyId, productId } = history.location.state || {};
      const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
      const payload = {
        loanAppId: params.id,
        companyId,
        productId,
        user: user._id,
        remarks: type === 'reject' ? data.remarks : data.comment,
        remark_reasons: data.reasons ?? null,
        type: type,
        status: currentSectionName.toLowerCase() === 'request an update' ? 'pending' : currentSectionName.toLowerCase(),
      };
      if (currentSectionName.toLowerCase() === 'approved') {
        setOffer(OFFER_STATUS.in_progress);
      }

      new Promise((resolve, reject) => {
        dispatch(updateLeadDetailsWatcher(payload, resolve, reject));
      })
        .then(async (response) => {
          if (currentSectionName.toLowerCase() === 'request an update') {
            showAlert('Request was submitted sucessfully', 'success');
            await fetchActivityLogs(8); //To load the additional doc comments.
          } else {
            showAlert(title == 'Add Comment' ? response?.message : title == 'Reject Lead Request' ? 'Loan was rejected successfully' : 'Loan was approved successfully', 'success');
          }
          setData('');
          setTitle('Add Comment');
          setSectionLogs([]);
          if (currentSectionName.toLowerCase() === 'approved') {
            setOffer(OFFER_STATUS.conditional_offer);
            setCurrentOfferDetails((prevData) => {
              return { ...prevData, ...response.data };
            });
          } else if (currentSectionName.toLowerCase() === 'Request an Update') {
            setOffer(OFFER_STATUS.soft_offer);
          }
        })
        .catch((error) => {
          showAlert(Error_Message.Technical_Error, Alert_Type.Error);
        });
    } catch (error) {
      throw error;
    } finally {
      await fetchActivityLogs(currSectionPos);
    }
  };

  const handleNavigate = (navState) => {
    history.replace(`/admin/lap/leads-review/${params.id}?tab=${navState}`);
  };

  const fetchActivityLogs = async (section_pos = 0) => {
    try {
      const { companyId, productId } = history.location.state || {};
      const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
      const sectionName = ACTIVITY_LOG_ACCORDIAN_SECTION_CODES[section_pos];
      const payload = {
        loanAppId: params.id,
        companyId,
        productId,
        user: user,
        category: sectionName,
      };
      setCurrentSectionPos(section_pos);
      new Promise((resolve, reject) => {
        dispatch(getLapActivityLogsWatcher(payload, resolve, reject));
      })
        .then((response) => {
          setAuditLogValue(response);
        })
        .catch((error) => {});
    } catch (error) {
      throw error;
    }
  };

  const setAuditLogValue = (auditLogs) => {
    try {
      const index = auditLogs.findIndex((obj) => obj[AUDIT_LOG_KEYS.CATEGORY] === Section_Status.Approved || obj[AUDIT_LOG_KEYS.CATEGORY] === Section_Status.Rejected);
      if (index !== -1 && index !== 0) {
        const movedItem = auditLogs.splice(index, 1)[0];
        auditLogs.unshift(movedItem);
      }
      setAuditLogs(auditLogs);
    } catch (error) {
      throw error;
    }
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const showAlert = (msg, type) => {
    try {
      setAlert(true);
      setSeverity(type);
      setAlertMessage(msg);
    } catch (error) {
      console.log(error);
    }
  };

  const updateOffer = ({ offerData = currentOfferDetails, isROIChange = false }) => {
    const offerPayload = {
      loan_app_id: params.id,
      companyId,
      productId,
      user: user,
      offered_amount: offerData.offered_amount,
      offered_int_rate: offerData.offered_int_rate,
      offered_tenure: offerData.tenure,
      isROIChange,
    };
    setOfferLoading(true);
    new Promise((resolve, reject) => {
      dispatch(postLEADSoftOfferWatcher(offerPayload, resolve, reject));
    })
      .then((response) => {
        setOffer(OFFER_STATUS.soft_offer);
        setLeadStatus(response?.lead_status);
        setOfferResponse(response);
        getLeadOffer();
      })
      .catch((e) => {
        showAlert(Error_Message?.Something_Wrong, Alert_Type?.Error);
      })
      .finally(() => {
        setOfferLoading(false);
      });
  };

  const getOfferAccordion = () => {
    switch (offer) {
      case OFFER_STATUS.in_progress:
        return <GeneratingOfferComponent />;
      case OFFER_STATUS.conditional_offer:
        return <ConditionalOfferComponent offered_amount={currentOfferDetails.offered_amount} tenure={currentOfferDetails?.offered_tenure} offered_int_rate={currentOfferDetails.offered_int_rate} />;
      case OFFER_STATUS.soft_offer:
        return <OfferGenerate heading={Field_Label.SOFT_OFFER_GENERATED} handlePopupVisibility={handlePopupVisibility} companyId={companyId} productId={productId} loanAppId={params.id} leadStatus={leadStatus} offerResponse={offerResponse} offerDetails={currentOfferDetails} updateOffer={updateOffer} />;
      default:
        return null;
    }
  };
  const styles = useStyles({ innerWidth, innerHeight });
  return (
    <div style={{ padding: '0px 24px 24px 24px', backgroundColor: '#fafafa' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ margin: '24px 0px 24px 0px' }}>
              {tabsAvailable.map((navMenu, index) => {
                return (
                  <TabButton
                    label={navMenu}
                    isDisabled={false}
                    key={index}
                    onClick={() => handleNavigate(navMenu)}
                    selected={navMenu.toLowerCase() === selectedTab.toLowerCase()}
                    setSelectedOption={(option) => {
                      setIsEdit(false);
                      setSelectedTab(option.toLowerCase());
                    }}
                  />
                );
              })}
            </div>

            {selectedTab !== 'documents' ? (
              <div style={styles.leadReviewEditViewContainer} onClick={() => setIsEdit(!isEdit)}>
                {isEdit ? 'View' : 'Edit'}
              </div>
            ) : null}
          </div>
          {/* Need to display this offer part once offer is generated*/}
          {getOfferAccordion()}
          <div>
            <TabSection selectedTab={selectedTab} loanAppId={params.id} fetchActivityLogs={fetchActivityLogs} handlePopupVisibility={handlePopupVisibility} handleApproveButton={handleApproveButton} isEdit={isEdit} setIsEdit={setIsEdit} setOffer={setOffer} setLeadStatus={setLeadStatus} offerDetails={currentOfferDetails} offerLoading={offerLoading} updateOffer={updateOffer} setOfferResponse={setOfferResponse} getLeadOffer={getLeadOffer} />
          </div>
          {selectedTab === 'lead details' ? (
            <div style={{ display: 'flex', float: 'right' }}>
              <Button
                buttonType="custom"
                customStyle={{
                  height: '48px',
                  padding: '10px 24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '16px',
                  color: '#134cde',
                  fontFamily: 'Montserrat-Regular',
                  fontSize: '16px',
                  fontWeight: '800',
                  width: 'max-content',
                  lineHeight: '150%',
                  flexShrink: '0',
                  borderRadius: '40px',
                  marginRight: '8px',
                  border: '1px solid #475BD8',
                  pointerEvents: isRequestAnUpdate_Reject_Enabled ? 'auto' : 'none',
                  background: 'var(--neutrals-neutral-30, #FFFFFF)',
                }}
                isDisabled={!isRequestAnUpdate_Reject_Enabled}
                label={'Request an Update'}
                onClick={() => {
                  handleRequestUpdate();
                }}
              />
              <Button
                buttonType="custom"
                customStyle={{
                  height: '48px',
                  padding: '10px 24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '16px',
                  color: '#CC0000',
                  fontFamily: 'Montserrat-Regular',
                  fontSize: '16px',
                  fontWeight: '800',
                  width: 'max-content',
                  lineHeight: '150%',
                  flexShrink: '0',
                  borderRadius: '40px',
                  marginRight: '8px',
                  border: '1px solid #CC0000',
                  background: 'var(--neutrals-neutral-30, #FFFFFF)',
                }}
                label={'Reject'}
                onClick={() => {
                  handleReject();
                }}
              />
              <Button
                buttonType="custom"
                customStyle={{
                  height: '48px',
                  padding: '10px 24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '16px',
                  color: '#FFFFFF',
                  fontFamily: 'Montserrat-Regular',
                  fontSize: '16px',
                  fontWeight: '800',
                  width: 'max-content',
                  lineHeight: '150%',
                  flexShrink: '0',
                  borderRadius: '40px',
                  marginRight: '8px',
                  border: isApproveButtonEnabled ? '1px solid #008042' : null,
                  pointerEvents: isApproveButtonEnabled ? 'auto' : 'none',
                  backgroundColor: isApproveButtonEnabled ? '#008042' : 'var(--neutrals-neutral-30, #CCCDD3)',
                  color: isApproveButtonEnabled ? '#FFFFFF' : 'var(--base-white, #FFF)',
                }}
                isDisabled={!isApproveButtonEnabled}
                label={'Approve'}
                onClick={() => {
                  handleApprove();
                }}
              />
            </div>
          ) : null}
        </div>
        {selectedTab === 'lead details' ? <AuditLog data={auditLogs} /> : null}
      </div>
      <ActionPopup
        heading={title}
        fields={commentFields}
        type={type}
        data={data}
        setData={setData}
        dataErrors={dataErrors}
        setDataErrors={setDataErrors}
        isModalOpen={openDialog}
        button={{
          primary: {
            label: type === 'reject' ? 'Reject' : type == 'approve' ? 'Approve' : 'Submit',
            style: {
              background: type === 'reject' ? '#CC0000' : 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%)',
            },
            action: (comment) => {
              if (type == 'sectionApprove') {
                return;
              }
              if (type == 'addComment' || type == 'reject') {
                handlePopupSubmit(comment);
              } else {
                // handleLeadStatus(leadStatusMappings[type]);
              }
            },
          },
          secondary: {
            label: 'Cancel',
            style: styles['negativeButton'],
            action: (props) => {
              setOpenDialog(false);
              setData('');
            },
          },
        }}
        buttonText={'Comment'}
        onClickOutsideModal={() => {
          setOpenDialog(false);
        }}
        callback={(isModalOpen) => {
          if (!isModalOpen) {
            setData('');
            setOpenDialog(false);
            setTitle('Add Comment');
            setType('addComment');
            setCommentFields(handleCommentField(true));
          }
        }}
      />
      {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}

export default LeadReview;

const useStyles = ({ innerWidth, innerHeight }) => {
  return {
    leadReviewEditViewContainer: {
      width: '66px',
      alignSelf: 'center',
      fontSize: '14px',
      fontFamily: 'Montserrat-Medium',
      color: '#134CDE',
      cursor: 'pointer',
    },
  };
};
