import React, { useState } from 'react';
import TabButton from 'react-sdk/dist/components/TabButton';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import LoanDataStatusDashboard from './LoanDataStatusDashboard/LoanDataStatusDashboard';
import './LoanDataUploadStatus.scss';

const LoanDataUploadStatus = () => {
  const [caseType, setCaseType] = useState('loan upload');
  const [callApi, setCallApi] = useState(false);
  const [searchText, setSearchText] = useState('');

  const searchFileName = (e) => {
    const value = e?.value ? e?.value : undefined;
    if (e.value !== undefined) {
      setSearchText(value);
    } else {
      setCallApi(!callApi);
    }
  };

  const renderCaseTypeScreen = () => {
    return <LoanDataStatusDashboard searchValue={searchText} callApi={callApi} caseType={caseType} />;
  };

  const setDummyFunc = (e) => {
    // To identify the tab in dashboard
  };

  return (
    <React.Fragment>
      <div className="ar-loan-data-upload-status">
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            margin: '0 20px',
          }}
        >
          <div>
            {['Loan Upload', 'Loan Update', 'Payment Update'].map((template, index) => {
              return <TabButton label={template} isDisabled={index === 2 ? true : false} key={index} onClick={(e) => setDummyFunc(e)} selected={template.toLowerCase() === caseType.toLowerCase() ? true : false} setSelectedOption={setCaseType} />;
            })}
          </div>
        </div>
        <div className="ar-loan-data-upload-status-search-box">
          <InputBox
            label={`Search`}
            isSearch={true}
            initialValue={searchText}
            onClick={(e) => {
              searchFileName(e);
            }}
            customClass={{
              width: '300px',
              maxWidth: 'none',
              height: '58px',
              borderRadius: '8px',
              margin: '20px',
              maxWidth: 'none',
              width: '280px',
            }}
          />
        </div>
        <div className="ar-loan-data-upload-status-dashboard">{renderCaseTypeScreen(caseType)}</div>
      </div>
    </React.Fragment>
  );
};

export default LoanDataUploadStatus;
