import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { anchorListWatcher } from '../../actions/anchor';
import { storedList } from '../../util/localstorage';
import { checkAccessTags } from '../../util/uam';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import './anchorList.style.css';
import SDKButton from 'react-sdk/dist/components/Button';
import PlusIcon from 'assets/img/icons/plus-icon.svg';
import SDKTable from 'react-sdk/dist/components/Table';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
const user = storedList('user');

const AnchorList = () => {
  const dispatch = useDispatch();
  const [str, setStr] = useState(null);
  const [anchorList, setAnchorList] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const history = useHistory();

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  React.useEffect(() => {
    if (isTagged && checkAccessTags(['tag_user_read', 'tag_user_read_write'])) fetchAnchorList();
    if (!isTagged) fetchAnchorList();
  }, []);

  const fetchAnchorList = () => {
    const payload = { page, limit, str: str ? str : 'null' };
    dispatch(
      anchorListWatcher(
        payload,
        (response) => {
          setAnchorList(response.rows);
          setCount(response.count);
        },
        (error) => {
          setAlert(true);
          setSeverity('error');
          setAlertMessage(error.response.data.message);
          setTimeout(() => {
            handleAlertClose();
          }, 4000);
        },
      ),
    );
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };
  useEffect(() => {
    if (page >= 0) {
      fetchAnchorList();
    }
  }, [page]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleClick = () => {
    if (page === 0) fetchAnchorList();
    if (page !== 0) setPage(0);
  };

  const columns = [
    {
      id: 'anchor-id',
      label: <div className="table-header-style">ANCHOR ID</div>,
      format: (rowData) => {
        return (
          <div
            className="table-column-data-style"
            onClick={() => {
              history.push(`/admin/anchor_details/${rowData._id}`);
            }}
          >
            {rowData._id}
          </div>
        );
      },
    },
    {
      id: 'anchor-name',
      label: <div className="table-header-style">ANCHOR NAME</div>,
      format: (rowData) => {
        return (
          <div
            className="table-column-data-style"
            onClick={() => {
              history.push(`/admin/anchor_details/${rowData._id}`);
            }}
          >
            {rowData.name}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="anchor-container-style">
        <div className="anchor-header-style">
          <InputBox
            label="Search anchor"
            isDrawdown={false}
            isSearch={true}
            onClick={(e) => {
              if (e.value) {
                setStr(e.value);
              } else {
                handleClick();
              }
            }}
            customClass={{
              maxWidth: 'none',
              width: '40%',
              height: '56px',
              borderRadius: '8px',
              fontFamily: 'Montserrat-Regular',
            }}
            customInputClass={{
              maxWidth: 'none',
              width: '100%',
            }}
          />
          <SDKButton
            customStyle={{
              height: '56px',
              fontSize: '1rem',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: 'none',
              backgroundColor: 'white',
              fontFamily: 'Montserrat-Regular',
              padding: '22px',
              border: '1px solid #134CDE',
              borderRadius: '5px',
            }}
            label="Add Anchor"
            buttonType="primary"
            imageButton={PlusIcon}
            imageButtonHover={PlusIcon}
            iconButton="btn-secondary-download-button"
            onClick={() => {
              history.push(`/admin/anchor`);
            }}
          />
        </div>
        <SDKTable
          columns={columns}
          data={anchorList}
          customStyle={{
            display: 'grid',
            gridTemplateColumns: '45% 45%',
            height: 'max-content',
          }}
          customCellCss={{
            width: '100%',
          }}
        />
        {count ? <Pagination key={count} itemsPerPage={10} totalItems={count} onPageChange={handleChangePage} rowsPerPageOptions={[10]} /> : null}
      </div>
    </>
  );
};

export default AnchorList;
