import axios from 'axios';
import { BASE_URL } from '../../constants/apiUrls';

export function getLapCamsDataApi(payload) {
  return axios.get(BASE_URL + `lap/leads/cams/${payload.loan_app_id}`, { params: payload });
}

export function updateLapCamsDataAPi(payload) {
  // console.log("updateApi payload", payload);
  return axios.patch(BASE_URL + `lap/leads/cams/${payload.loan_app_id}`, payload);
}
