export const businessModelFieldMapping = [
  {
    title: 'Company',
    field: 'select_company_id',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'select_company_product',
    validationMsg: 'Company is Required',
    checked: 'true',
  },
  {
    title: 'Product',
    field: 'select_product_id',
    type: 'string',
    isDrawdown: true,
    isOptional: false,
    dept: 'select_company_product',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Partner',
    field: 'partner_name',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'general_info_model',
    validationMsg: 'Partner is Required',
    checked: 'true',
  },
  {
    title: 'Product',
    field: 'product_name',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'general_info_model',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Product ID',
    field: 'product_id',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'general_info_model',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Duration of Model',
    field: 'duration_of_model',
    type: 'date',
    isDrawdown: false,
    isOptional: false,
    dept: 'general_info_model',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Interest Type',
    field: 'interest_type',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'general_info_model',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Loan Tenure Type',
    field: 'loan_tenure_type',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'general_info_model',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Repayment Type',
    field: 'repayment_type',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'general_info_model',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Partner Fee Shares',
    field: 'processing_fee_partner_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'processing_fees',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Lender Fee Share',
    field: 'processing_fee_lender_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'processing_fees',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Partner Fee Shares',
    field: 'application_fee_partner_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'application_fees',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Lender Fee Share',
    field: 'application_fee_lender_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'application_fees',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Partner Fee Shares',
    field: 'convenient_fee_partner_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'convenience_fees',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Lender Fee Share',
    field: 'convenient_fee_lender_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'convenience_fees',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Partner Fee Shares',
    field: 'misc_fee_partner_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'misc_fees',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Lender Fee Share',
    field: 'misc_fee_lender_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'misc_fees',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Partner Fee Shares',
    field: 'bounce_charge_partner_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'bounce_charge',
    validationMsg: 'Bounce charge is Required',
    checked: 'true',
  },
  {
    title: 'Lender Fee Share',
    field: 'bounce_charge_lender_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'bounce_charge',
    validationMsg: 'Bounce charge is Required',
    checked: 'true',
  },
  {
    title: 'Partner Fee Shares',
    field: 'lpi_partner_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'lpi',
    validationMsg: 'OI is Required',
    checked: 'true',
  },
  {
    title: 'Lender Fee Share',
    field: 'lpi_lender_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'lpi',
    validationMsg: 'OI is Required',
    checked: 'true',
  },
  {
    title: 'Interest Rate',
    field: 'interest_rate',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'interest_rate',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Lender Hurdle Rate',
    field: 'hurdle_rate',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'interest_rate',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Partner GST Share',
    field: 'partner_gst_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'gst',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Lender GST Share',
    field: 'lender_gst_share',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'gst',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Default Cut-off',
    field: 'default_cut_off',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'other_fields',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Portfolio Coverage Percentage',
    field: 'portfolio_coverage_percentage',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'other_fields',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
  {
    title: 'Performance Security Settlement',
    field: 'performance_security_settlement',
    type: 'string',
    isDrawdown: false,
    isOptional: false,
    dept: 'other_fields',
    validationMsg: 'Product is Required',
    checked: 'true',
  },
];

export const fieldsToValidate = [
  'duration_of_model_value',
  'processing_fee_partner_share_value',
  'processing_fee_lender_share_value',
  'application_fee_partner_share_value',
  'application_fee_lender_share_value',
  'convenient_fee_partner_share_value',
  'convenient_fee_lender_share_value',
  'misc_fee_partner_share_value',
  'misc_fee_lender_share_value',
  'bounce_charge_partner_share_value',
  'bounce_charge_lender_share_value',
  'lpi_partner_share_value',
  'lpi_lender_share_value',
  'interest_rate_value',
  'hurdle_rate_value',
  'partner_gst_share_value',
  'lender_gst_share_value',
  'default_cut_off_value',
  'portfolio_coverage_percentage_value',
  'performance_security_settlement_value',
];
