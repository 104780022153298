export const LeadStatus = {
  new: { label: 'New', value: 'new' },
  in_review: { label: 'In Review', value: 'in_review' },
  in_progress: { label: 'In Progress', value: 'in_progress' },
  in_process: { label: 'In Process', value: 'in_process' },
  active: { label: 'Active', value: 'active' },
  pending: { label: 'Pending', value: 'pending' },
  approved: { label: 'Approved', value: 'approved' },
  rejected: { label: 'Rejected', value: 'rejected' },
  deviation: { label: 'Deviation', value: 'deviation' },
  draft: { label: 'Draft', value: 'draft' },
  lead_deviation: { label: 'Lead Deviation', value: 'lead_deviation' },
  offer_generated: { label: 'Offer Generated', value: 'offer_generated' },
  offer_accepted: { label: 'Offer Accepted', value: 'offer_accepted' },
  loan_created: { label: 'Loan Created', value: 'loan_created' },
  accepted: { label: 'Accepted', value: 'Accepted' },
  offer_in_progress: { label: 'Offer In Progress', value: 'offer_in_progress' },
  follow_up_doc: { label: 'Follow Up Doc', value: 'follow_up_doc' },
  follow_up_kyc: { label: 'Follow Up KYC', value: 'follow_up_kyc' },
  offer_deviation: { label: 'Offer Deviation', value: 'offer_deviation' },
  risk_resolved: { label: 'Risk Resolved', value: 'risk_resolved' },
};

export const LeadMapping = {
  100: 'primary',
  200: 'entity',
  300: 'co_borrower-1',
  301: 'co_borrower-2',
  302: 'co_borrower-3',
  303: 'co_borrower-4',
  304: 'co_borrower-5',
  305: 'co_borrower-6',
  306: 'co_borrower-7',
  307: 'co_borrower-8',
  308: 'co_borrower-9',
  309: 'co_borrower-10',
  310: 'co_borrower-11',
  311: 'co_borrower-12',
  312: 'co_borrower-13',
  313: 'co_borrower-14',
  314: 'co_borrower-15',
  400: 'guarantor_details_1',
  401: 'guarantor_details_2',
  402: 'guarantor_details_3',
  403: 'guarantor_details_4',
  404: 'guarantor_details_5',
  500: 'financial_doc',
  600: 'additional_doc',
  700: 'share_holding',
  800: 'property_doc',
};

export const LeadNewStatus = {
  Approved: 'approved',
  Rejected: 'rejected',
  New: 'new',
  Pending: 'pending',
  InReview: 'in_review',
  InProgress: 'in_progress',
  InProcess: 'in_process',
  Active: 'active',
  Draft: 'draft',
  Deviation: 'deviation',
  LeadDeviation: 'lead_deviation',
  OfferGenerated: 'offer_generated',
  Accepted: 'offer_accepted',
  LoanCreated: 'loan_created',
  FollowUpDoc: 'follow_up_doc',
  FollowUpKyc: 'follow_up_kyc',
};
