import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function getLoanUploadStatus(payload) {
  return axios.get(`${BASE_URL}collection/datauploadstatus?upload_type=${payload.uploadType}&pagination=true&page=${payload.pageNumber}`, payload);
}

export function getLoanUpdateStatus(payload) {
  return axios.get(`${BASE_URL}collection/datauploadstatus?upload_type=${payload.uploadType}&pagination=true&page=${payload.pageNumber}`, payload);
}

export function getPaymentUpdateStatus(payload) {
  return axios.get(`${BASE_URL}collection/datauploadstatus?upload_type=${payload.uploadType}&pagination=true&page=${payload.pageNumber}`, payload);
}

export function getRefineLoanUserStatus(payload) {
  return axios.get(`${BASE_URL}collection/datauploadstatus?upload_type=${payload.uploadType}&file_name=${payload.searchValue}&pagination=true&page=${payload.pageNumber}`, payload);
}

export function getDownloadExcelSheet(payload) {
  return axios.get(`${BASE_URL}collection/downloaduploadedloandata/${payload._id}`, { responseType: 'blob' });
}

export function getErrorDownloadExcelSheet(payload) {
  return axios.get(`${BASE_URL}collection/downloaderrorreportdata/${payload._id}`, { responseType: 'blob' });
}
