export const activityLogNamingFormat = {
  primary: 'Primary Applicant',
  entity: 'Entity Details',
  'co borrower': 'Co Borrower Details',
  guarantor: 'Guarantor Details',
  financial_doc: 'Financial Doc',
  additional_doc: 'Additional Doc',
  shareholding: 'Shareholding Structure',
  property: 'Property Details',
  property_doc_credit_team: 'Property Documents (For Credit Team)',
  offer_in_progress: 'Offer In Progress',
  lead_accepted: 'Lead Accepted',
  lead_request_update: 'Lead Request Update',
  new: 'New',
  pending: 'Pending',
  rejected: 'Rejected',
  in_review: 'In Review',
  in_progress: 'In Progress',
  lead_deviation: 'Lead Deviation',
  offer_generated: 'Offer Generated',
  offer_deviation: 'Offer Deviation',
  approved: 'Approved',
  cams_update: 'Cams Update',
  lead_rejected: 'Lead Rejected',
  follow_up_doc: 'Follow Up Doc',
  follow_up_kyc: 'Follow Up Kyc',
  amend_offer_update: 'Amend Offer Update',
};

export const ACTIVITY_LOG_SECTION_NAME_AND_CODE = {
  //#region [Credit and Partner view]
  'Applicant Details': 'primary',
  'Entity Details': 'entity',
  'Co-Borrower Details': 'co_borrower',
  'Guarantor Details': 'guarantor',
  'Property Details': 'property',
  approved: 'approved',
  Rejected: 'rejected',
  rejected: 'rejected',
  //#endregion
  //#region [Credit View]
  'Shareholding Structure': 'shareholding',
  'Property Documents (For Credit Team)': 'property_doc_credit_team',
  'Financial Documents': 'financial_doc',
  'Additional Documents': 'additional_doc',
  //#endregion
  //#region [Partner View]
  'Share Holding Pattern': 'shareholding',
  Offer: 'offer',
  'Financial Docs': 'financial_doc',
  'Additional Docs': 'additional_doc',
  //#endregion
};

export const ACTIVITY_LOG_ACCORDIAN_SECTION_CODES = ['primary', 'entity', 'co_borrower', 'guarantor', 'property', 'financial_doc', 'shareholding', 'property_doc_credit_team', 'additional_doc', 'approved', 'rejected'];
