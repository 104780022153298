// APPLICANTDETAILS JSON

import { documentCode as DocumentListCode } from '../../config/docCode';
// #region Applicant Details
export const KYCDocumentSelfieTittle = [
  {
    id: 'Document_Selfie',
    name: 'Applicant Selfie',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.applicant_selfie,
    isRequired: true,
  },
];

export const KYCDocumentPANTittle = [
  {
    id: 'pan1',
    name: 'PAN',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.applicant_pan,
    isRequired: true,
  },
];

export const KYCDocumentPanXMLTittle = [
  {
    id: 'panXML1',
    name: 'PAN XML',
    fileSize: 'XML/JSON  upto 5MB',
    acceptFileType: '.xml,.json',
    fileType: 'file',
    documentCode: DocumentListCode.applicant_pan_XML,
    isRequired: true,
  },
];

export const KYCAadharFrontTitle = [
  {
    id: 'Aadhar_front1',
    name: 'Aadhaar Front',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.applicant_aadhaar_front,
    isRequired: true,
  },
];

export const KYCAadharBackTitle = [
  {
    id: 'Aadhar_Back1',
    name: 'Aadhaar Back (optional)',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.applicant_aadhaar_back,
  },
];

export const AadharXML = [
  {
    id: 'Aadhar_XML',
    name: 'Aadhaar XML',
    fileSize: 'XML/JSON  upto 5MB',
    acceptFileType: '.xml,.json',
    fileType: 'file',
    documentCode: DocumentListCode.applicant_aadhaar_XML,
    isRequired: true,
  },
];

export const ScreeningReport = [
  {
    id: 'screening_report',
    name: 'Screening Report',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.applicant_screening_report, //Need to confirm the code
  },
];

export const BureauReport = [
  {
    id: 'Bureau_Report',
    name: 'Bureau Report',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.bureau_pull_consent, //Need to confirm the code
    isRequired: true,
  },
];
// #endregion

// #region Entity Details
export const GSTIN = [
  {
    id: 'entity_supportDocuments_gstin_upload',
    name: 'GST Certificate (Optional)',
    DocSectionName: 'GSTN',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.gst_certificate,
    isRequired: true,
  },
];

export const URC_Certificate = [
  {
    id: 'urc_certificate_value',
    name: 'URC',
    DocSectionName: 'Udyam Registration Certificate',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.entity_udhyam_certificate,
    isRequired: true,
  },
];

export const Shop_Establishment_Certificate = [
  {
    id: 'entity_supportDocuments_shop_establishment_upload',
    name: 'Upload Certificate',
    DocSectionName: 'Shop Establishment Certificate',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.entity_shop_establishment,
    isRequired: true,
  },
];

export const Entity_KYC_Partnership_Upload = [
  {
    name: 'Partnership Deed',
    id: 'entity_kyc_partnerShip_deed',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.entity_partnership_deed,
    isRequired: true,
  },
];

export const Entity_KYC_Authority_Letter_Upload = [
  {
    name: 'Authority Letter',
    id: 'entity_kyc_partnerShip_authority_letter',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.entity_authority_certificate,
    isRequired: true,
  },
];

export const Entity_LOD_Upload = [
  {
    name: 'List of Directors',
    id: 'entity_ppl_lod',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.entity_list_directors,
    isRequired: true,
  },
];

export const Entity_AOA_Upload = [
  {
    name: 'AOA',
    id: 'entity_opc_aoa',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.entity_aoa,
    isRequired: true,
  },
];

export const Entity_COI_Upload = [
  {
    name: 'COI',
    id: 'entity_ppl_coi',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.entity_coi,
    isRequired: true,
  },
];

export const Entity_MOA_Upload = [
  {
    name: 'MOA',
    id: 'entity_opc_moa',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.entity_moa,
    isRequired: true,
  },
];
// #endregion

// #region Guarantor Details
export const GuarantorSelfie = [
  {
    id: 'Guarantor_Selfie_1',
    name: 'Guarantor Selfie',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.guar_selfie,
    isRequired: true,
  },
];

export const Guarantor_PanTittle = [
  {
    id: 'Pan_1',
    name: 'PAN',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.guar_pan,
    isRequired: true,
  },
];

export const AadharImage = [
  {
    id: 'aadhar_front',
    name: 'Aadhaar Front',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.guar_aadhaar_front,
    isRequired: true,
  },
  {
    id: 'Aadhar_back',
    name: 'Aadhaar Back (optional)',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.guar_aadhaar_back,
    isRequired: false,
  },
];

export const Guarantor_PanInputTittleXML = [
  {
    id: 'Pan_XML',
    name: 'PAN XML',
    fileSize: 'XML/JSON upto 5MB',
    acceptFileType: '.xml,.json',
    fileType: 'file',
    documentCode: DocumentListCode.guar_pan_XML,
    isRequired: true,
  },
];

export const Guarantor_AadharInputTittleXML = [
  {
    id: 'Aadhar_XML',
    name: 'Aadhaar XML',
    fileSize: 'XML/JSON upto 5MB',
    acceptFileType: '.xml,.json',
    fileType: 'file',
    documentCode: DocumentListCode.guar_aadhaar_XML,
    isRequired: true,
  },
];
// #endregion

// #region Co borrower documents
export const CoBorrowerSelfie = [
  {
    id: 'Borrower_Selfie_1',
    name: 'Co-Borrower Selfie',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.cb_selfie,
    isRequired: true,
  },
];

export const CoBorrowerPanImage = [
  {
    id: 'Pan_1',
    name: 'PAN',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.cb_pan,
    isRequired: true,
  },
];

export const CoBorrowerPanXml = [
  {
    id: 'Pan_1',
    name: 'PAN XML',
    fileSize: 'XML/JSON  upto 5MB',
    acceptFileType: '.xml,.json',
    fileType: 'file',
    documentCode: DocumentListCode.cb_pan_XML,
    isRequired: true,
  },
];

export const CoBorrowerKYCAadharFrontTitle = [
  {
    id: 'Aadhar_Front_1',
    name: 'Aadhaar',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.cb_aadhaar_front,
    isRequired: true,
  },
];

export const CoBorrowerKYCAadharBackTitle = [
  {
    id: 'Aadhar_Back_1',
    name: 'Aadhaar Back (optional)',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.cb_aadhaar_back,
    isRequired: false,
  },
];

export const CoBorrowerKYCAadharBackXml = [
  {
    id: 'Aadhar_Front_1',
    name: 'Aadhaar XML',
    fileSize: 'XML/JSON  upto 5MB',
    acceptFileType: '.xml,.json',
    fileType: 'file',
    documentCode: DocumentListCode.cb_aadhaar_XML,
    isRequired: true,
  },
];
// #endregion

// #region Property Details
export const Property_Ownership_Proof = [
  {
    id: 'Property_Ownership_Proof',
    name: 'Property Ownership Proof',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.prop_property_ownership_proof,
    isRequired: true,
  },
];
export const Property_Ownership_Report = [
  {
    id: 'Property_FI_Report',
    name: 'Property FI Report',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.prop_property_fi_report,
    isRequired: true,
  },
];
export const Property_Legal_Report_Upload = [
  {
    name: 'Legal Report',
    id: 'property_legal_report',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.Property_Legal_Report_Upload,
  },
];
export const Property_Valuation_Report_Upload = [
  {
    name: 'Valuation Report',
    id: 'property_valuation_report',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.Property_Valuation_Report_Upload,
  },
];

export const Property_Title_Report_DocCodes = [
  DocumentListCode.Title_Report_1,
  DocumentListCode.Title_Report_2,
  DocumentListCode.Title_Report_3,
  DocumentListCode.Title_Report_4,
  DocumentListCode.Title_Report_5,
  DocumentListCode.Title_Report_6,
  DocumentListCode.Title_Report_7,
  DocumentListCode.Title_Report_8,
  DocumentListCode.Title_Report_9,
  DocumentListCode.Title_Report_10,
  DocumentListCode.Title_Report_11,
  DocumentListCode.Title_Report_12,
  DocumentListCode.Title_Report_13,
  DocumentListCode.Title_Report_14,
  DocumentListCode.Title_Report_15,
  DocumentListCode.Title_Report_16,
  DocumentListCode.Title_Report_17,
  DocumentListCode.Title_Report_18,
  DocumentListCode.Title_Report_19,
  DocumentListCode.Title_Report_20,
  DocumentListCode.Title_Report_21,
  DocumentListCode.Title_Report_22,
  DocumentListCode.Title_Report_23,
  DocumentListCode.Title_Report_24,
  DocumentListCode.Title_Report_25,
];

export const Property_Title_Report_Upload = Property_Title_Report_DocCodes.map((documentCode, index) => {
  return {
    name: 'Document',
    id: `Title_Report_${index + 1}`,
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode,
  };
});
//#endregion

// #region SHARE HOLDING
export const SHAREHOLDING = [
  {
    id: 'certificate',
    name: 'Shareholding Certificate',
    fileSize: 'JPG, JPEG, PNG, PDF upto 5MB',
    acceptFileType: '.jpg, .jpeg, .png, .pdf',
    fileType: 'file',
    documentCode: DocumentListCode.shareholding_pattern,
    isRequired: true,
  },
];
// #endregion

// #region Additional Documents
export const Additional_Documents = [
  {
    id: 'affidavit',
    name: 'Affidavit',
    fileType: 'file',
    documentCode: DocumentListCode.affidavit,
  },
  {
    id: 'name_change',
    name: 'Name change certificate',
    fileType: 'file',
    documentCode: DocumentListCode.name_change_certificate,
  },
  {
    id: 'lender_noc',
    name: 'Lender NOC',
    fileType: 'file',
    documentCode: DocumentListCode.lender_noc,
  },
  {
    id: 'deviation_certificate',
    name: 'Deviation certificate',
    fileType: 'file',
    documentCode: DocumentListCode.deviation_certificate,
  },
  {
    id: 'clearance_certificate',
    name: 'Clearance certificate',
    fileType: 'file',
    documentCode: DocumentListCode.clearance_certificate,
  },
  {
    id: 'water_bill',
    name: 'Water Bill',
    fileType: 'file',
    documentCode: DocumentListCode.water_bill,
  },
  {
    id: 'house_registry',
    name: 'House Registry',
    fileType: 'file',
    documentCode: DocumentListCode.house_registry,
  },
  {
    id: 'relationship_proof',
    name: 'Relationship proof',
    fileType: 'file',
    documentCode: DocumentListCode.relationship_proof,
  },
  {
    id: 'credit_card',
    name: 'Credit Card Bill',
    fileType: 'file',
    documentCode: DocumentListCode.credit_card_bill,
  },
  {
    id: 'telecom_bill',
    name: 'Telecom Bill',
    fileType: 'file',
    documentCode: DocumentListCode.telecom_bill,
  },
  {
    id: 'self_declaration',
    name: 'Self Declaration',
    fileType: 'file',
    documentCode: DocumentListCode.self_declaration,
  },
  {
    id: 'loan_statement',
    name: 'Loan Statement',
    fileType: 'file',
    documentCode: DocumentListCode.loan_statement,
  },
  {
    id: 'fi_report_2',
    name: 'FI Report 2',
    fileType: 'file',
    documentCode: DocumentListCode.loan_statement,
  },
  {
    id: 'fi_report_3',
    name: 'FI Report 3',
    fileType: 'file',
    documentCode: DocumentListCode.loan_statement,
  },
];
// #endregion

// #region Financial document
export const Financial_Documents = [
  {
    name: 'GST Certificate',
    id: 'gst_certificate',
    fileType: 'file',
    documentCode: DocumentListCode.gst_certificate,
  },
  {
    name: 'GSTR',
    id: 'gstr',
    fileType: 'file',
    documentCode: DocumentListCode.gstr,
  },
  {
    name: 'ITR1',
    id: 'itr1',
    DocSectionName: 'ITR 1st Year',
    fileType: 'file',
    documentCode: DocumentListCode.itr1,
  },
  {
    name: 'ITR2',
    id: 'itr2',
    DocSectionName: 'ITR 2nd Year',
    fileType: 'file',
    documentCode: DocumentListCode.itr2,
  },
  {
    name: 'Bank Statement',
    id: 'bank_statement',
    DocSectionName: 'Bank Statement (6 months) 1',
    fileType: 'file',
    documentCode: DocumentListCode.lap_bank_statement,
  },
];
// #endregion
