export function getDDRpsReportWatcher(data, resolve, reject) {
  return {
    type: 'DD_RPS_REPORT',
    payload: data,
    resolve,
    reject,
  };
}

export function getPrepaymentDetailsWatcher(data, resolve, reject) {
  return {
    type: 'PRE_PAYMENT_DETAIL',
    payload: data,
    resolve,
    reject,
  };
}
