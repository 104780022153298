import { offerGenerateJson } from './offerGenerateJson';
import offerImage from '../../assets/images/Offer_image.svg';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import './OfferGenerate.style.css';
import useDimensions from '../../../hooks/useDimensions';

export const ConditionalOfferComponent = (props) => {
  const { customStyle = {} } = props;
  const heading = 'Conditional Offer Generated';
  const offerFormDetails = offerGenerateJson();
  const { innerWidth, innerHeight } = useDimensions();
  const styles = useStyles({ innerWidth, innerHeight });
  return (
    <div>
      <div className="containerStyle lap-offer" style={customStyle}>
        <div className="offerImageContainer">
          <img className="offerImage" src={offerImage} alt="Offer Image" />
        </div>
        <div className="flex-1 flex-column offer-details-container">
          <h2 className="offerHead">{heading}</h2>
          <div className="field-container">
            {Object.keys(offerFormDetails).map((item, index) => {
              return <InputBox key={index} customClass={styles['textStyleHead']} customInputClass={styles['textStyleData']} id={offerFormDetails[item]['id']} label={offerFormDetails[item]['label']} type={offerFormDetails[item]['type']} name={offerFormDetails[item]['name']} placeholder={offerFormDetails[item]['placeholder']} isRequired={offerFormDetails[item]['isRequired']} initialValue={props[item]} isDrawdown={false} isDisabled={true} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = ({ innerWidth }) => {
  return {
    textStyleHead: {
      background: '#F1F1F3',
      borderRadius: '10px',
      height: '65px',
      borderColor: '#D8D9DE',
      textAlign: 'left',
      color: '#767888',
      marginRight: '10px',
      width: '100%',
    },
    customButton1: {
      borderRadius: '50px',
      border: '1px solid #475BD8',
      width: '240px',
      height: '56px',
      fontSize: '16px',
      marginLeft: '5%',
      backgroundColor: 'white',
    },
    customButton2: {
      borderRadius: '50px',
      width: '240px',
      height: '56px',
      fontSize: '16px',
    },
    textStyleData: {
      textAlign: 'left',
      background: '#F1F1F3',
      fontSize: innerWidth > 1400 ? '14px' : '12px',
      width: '100%',
    },
  };
};
