export function getComprehensiveLogsWatcher(data, resolve, reject) {
  return {
    type: 'GET_COMPREHENSIVE_LOGS',
    payload: data,
    resolve,
    reject,
  };
}
export function postComprehensiveLogsWatcher(payload, resolve, reject) {
  return {
    type: 'POST_COMPREHENSIVE_LOGS',
    payload,
    resolve,
    reject,
  };
}
