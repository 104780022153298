// import all sagas here
import { takeLatest, call, put } from 'redux-saga/effects';
import { postLapApplicantDetailsApi, patchLapDetailsApi, patchLapSaveAsDraftDetailsApi, getLapCompaniesApi, getLapCompaniesOnlyApi, getProductByCompanyIdApi, putLapSaveDraftApi, getLapProductByCompanyIdApi, getLapSubmissionStatusApi, patchLapDocDeleteApi, subSectionDeleteApi, postEsignRequestApi, updateLeadDeviationApi, ammendOfferApi, verifyAadhaarOtpApi, getBicDataApi } from '../apis/lap.api';
import { updatePreLoaderWatcher } from '../../actions/user';

export function* postLapApplicantDetailsEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(postLapApplicantDetailsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* postLapApplicantDetailsWatcherSaga() {
  yield takeLatest('POST_LAP_APPLICANT_DETAILS', postLapApplicantDetailsEffectSaga);
}

export function* patchLapDetailsEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(patchLapDetailsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* patchLapDetailsWatcherSaga() {
  yield takeLatest('PATCH_LAP_DETAILS', patchLapDetailsEffectSaga);
}

export function* patchLapSaveAsDraftDetailsEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(patchLapSaveAsDraftDetailsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* patchLapSaveAsDraftDetailsWatcherSaga() {
  yield takeLatest('PATCH_LAP_SAVE_AS_DRAFT_DETAILS', patchLapSaveAsDraftDetailsEffectSaga);
}

export function* putLapSaveDraftEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(putLapSaveDraftApi, action.payload);
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}
export function* getLapCompaniesEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getLapCompaniesApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getLapCompaniesOnlyEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getLapCompaniesOnlyApi);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getProductByCompanyIdEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    const { data } = yield call(getProductByCompanyIdApi, action.payload);
    action.resolve(data);
  } catch (e) {
    action.reject(e);
  }
}

export function* getProductByCompanyIdWatcherSaga() {
  yield takeLatest('GET_PRODUCT_BY_COMPANY_ID_WATCHER', getProductByCompanyIdEffectSaga);
}

export function* putLapSaveDraftWatcherSaga() {
  yield takeLatest('PUT_LAP_SAVE_DRAFT', putLapSaveDraftEffectSaga);
}

export function* getLapCompaniesWatcherSaga() {
  yield takeLatest('GET_LAP_COMPANIES_WATCHER', getLapCompaniesEffectSaga);
}

export function* getLapCompaniesOnlyWatcherSaga() {
  yield takeLatest('GET_LAP_COMPANIES_ONLY_WATCHER', getLapCompaniesOnlyEffectSaga);
}

export function* getLapProductByCompanyIdEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getLapProductByCompanyIdApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getLapProductByCompanyIdWatcherSaga() {
  yield takeLatest('GET_LAP_PRODUCT_BY_COMPANY_ID_WATCHER', getLapProductByCompanyIdEffectSaga);
}

export function* getLapSubmissionStatusEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getLapSubmissionStatusApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getLapSubmissionStatusWatcherSaga() {
  yield takeLatest('GET_LAP_SUBMISSION_STATUS_WATCHER', getLapSubmissionStatusEffectSaga);
}

export function* patchLapDocDeleteEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(patchLapDocDeleteApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
  }
}

export function* patchLapDocDeleteWatcherSaga() {
  yield takeLatest('LAP_DOC_DELETE', patchLapDocDeleteEffectSaga);
}

export function* getBicDataEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getBicDataApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getLapBicDataWatcherSaga() {
  yield takeLatest('GET_LAP_BIC_DATA', getBicDataEffectSaga);
}

export function* subSectionDeleteEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(subSectionDeleteApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
  }
}

export function* lapSubSectionDeleteWatcherSaga() {
  yield takeLatest('LAP_SUB_SECTION_DELETE', subSectionDeleteEffectSaga);
}

export function* postEsignRequestEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(postEsignRequestApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* postLapEsignRequestWatcherSaga() {
  yield takeLatest('POST_LAP_ESIGN_REQUEST', postEsignRequestEffectSaga);
}

export function* updateLeadDeviationEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(updateLeadDeviationApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* updateLapLeadDeviationWatcherSaga() {
  yield takeLatest('UPDATE_LAP_LEAD_DEVIATION', updateLeadDeviationEffectSaga);
}

export function* ammendOfferAPIEffectSaga(action) {
  // data is obtained after axios call is resolved
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(ammendOfferApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* ammendLapAPIOfferWatcherSaga() {
  yield takeLatest('AMMEND_LAP_OFFER_API', ammendOfferAPIEffectSaga);
}
export function* verifyAadhaarOtpEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(verifyAadhaarOtpApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* lapVerifyAadhaarOtpWatcherSaga() {
  yield takeLatest('LAP_VERIFY_AADHAAR_OTP', verifyAadhaarOtpEffectSaga);
}
