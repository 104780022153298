import { documentCode } from 'lap/config/docCode';
export const AdditionalDocumentData = [
  { value: 'affidavit', label: 'Affidavit', documentCode: documentCode.affidavit },
  { value: 'name_change', label: 'Name Change Certificate', documentCode: documentCode.name_change_certificate },
  { value: 'lender_noc', label: 'Lender NOC', documentCode: documentCode.lender_noc },
  { value: 'deviation_certificate', label: 'Deviation Certificate', documentCode: documentCode.deviation_certificate },
  { value: 'clearance_certificate', label: 'Clearance Certificate', documentCode: documentCode.clearance_certificate },
  { value: 'water_bill', label: 'Water Bill', documentCode: documentCode.water_bill },
  { value: 'house_registry', label: 'House Registry', documentCode: documentCode.house_registry },
  { value: 'relationship_proof', label: 'Relationship Proof', documentCode: documentCode.relationship_proof },
  { value: 'credit_card', label: 'Credit Card Bill', documentCode: documentCode.credit_card_bill },
  { value: 'telecom_bill', label: 'Telecom Bill', documentCode: documentCode.telecom_bill },
  { value: 'self_declaration', label: 'Self Declaration', documentCode: documentCode.self_declaration },
  { value: 'loan_statement', label: 'Loan Statement', documentCode: documentCode.loan_statement },
  { value: 'fi_report_2', label: 'FI Report 2', documentCode: documentCode.fi_report_2 },
  { value: 'fi_report_3', label: 'FI Report 3', documentCode: documentCode.fi_report_3 },
];
