import { takeLatest, call, put } from 'redux-saga/effects';
import { updatePreLoaderWatcher } from '../../actions/user';
import { getLapCamsDataApi, updateLapCamsDataAPi } from '../apis/camsDetails.api';

export function* getLapCamsDataEffectSaga(action) {
  console.log('getCamsDataEffectSaga');
  try {
    yield put(updatePreLoaderWatcher(true));
    //   console.log("action.payload", action.payload);
    const { data } = yield call(getLapCamsDataApi, action.payload);

    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    console.log(e, 'errorrrrrr');
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getLapCamsDataWatcherSaga() {
  yield takeLatest('GET_LAP_CAMS_DATA', getLapCamsDataEffectSaga);
}

export function* updateLapCamsDataEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(updateLapCamsDataAPi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* updateLapCamsDataWatcherSaga() {
  yield takeLatest('UPDATE_LAP_CAMS_DATA', updateLapCamsDataEffectSaga);
}
