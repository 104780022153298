import * as React from 'react';
import { useDispatch } from 'react-redux';
import { storedList } from '../../util/localstorage';
import { useEffect, useState } from 'react';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';

const LabelDropdown = ({ onLabelChange, placeholder, nameField, isLoc, stage, isForeclosureOffered, lapChargesLabels, isReduced, isPartPayment }) => {
  const [label, setLabel] = useState([]);
  const [labels, setLabels] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const user = storedList('user');

  const changeValue = (item) => {
    setLabel(item);
  };

  useEffect(() => {
    let dataObject = isLoc
      ? [{ label: 'Repayment', value: 'repayment' }, { label: 'Processing Fee', value: 'pf' }, { label: 'FLDG', value: 'fldg' }, ...(isPartPayment ? [{ label: 'Part Payment', value: 'partpayment' }] : [])]
      : isReduced
      ? [
          { label: 'Tenure', value: 'Tenure' },
          { label: 'Installment Amount', value: 'Installment Amount' },
        ]
      : [{ label: 'Repayment', value: 'repayment' }, ...(isForeclosureOffered ? [{ label: 'Foreclosure', value: 'foreclosure' }] : []), ...(stage < 4 ? [{ label: 'Advance EMI', value: 'Advance EMI' }] : []), { label: 'FLDG', value: 'fldg' }, ...(isPartPayment ? [{ label: 'Part Payment', value: 'partpayment' }] : []), ...lapChargesLabels];
    setLabels(dataObject);
    if (isReduced) {
      setLabel({ label: 'Tenure', value: 'Tenure' });
    }
  }, [isPartPayment]);

  useEffect(() => {
    onLabelChange(label, nameField);
  }, [label]);

  const inputBoxCss = {
    marginTop: '0px',
    marginLeft: '2px',
    maxHeight: '500px',
    zIndex: 5,
    padding: '0px 16px',
  };

  return (
    <InputBox
      label={placeholder}
      isDrawdown={true}
      initialValue={isReduced ? 'Tenure' : ''}
      options={labels}
      onClick={(item) => {
        changeValue(item);
      }}
      isDisabled={disabled}
      customClass={{ height: '56px', width: '335px', maxWidth: 'none' }}
      customDropdownClass={inputBoxCss}
    />
  );
};

export default LabelDropdown;
