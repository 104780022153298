import * as React from 'react';
import AddUser from './user/addUser';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';

export default function UserPopUp(props) {
  const { open, onModalClose, title, openDialog, handleClose, user, action, refreshData, showAlert, ...other } = props;

  return (
    <>
      {openDialog ? (
        <FormPopup heading="" onClose={handleClose} isCancel={false} isOpen={openDialog}>
          <AddUser action={action} user={user} cancelProcess={handleClose} refreshData={refreshData} showAlert={showAlert} />
        </FormPopup>
      ) : null}
    </>
  );
}
