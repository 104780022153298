export const profileFields = [
    {
        label: "Broken Period Interest",
        type: "text",
        placeholder : "Broken Period Interest",
        helperText : "Enter valid broken period interest",
        value : "broken_period_interest",
        error:"brokenperiodinterestError",
        validationType :"floatEmpty"
    },
    {
        label: "Bounce Charge",
        type: "text",
        placeholder : "Bounce Charge",
        helperText : "Enter valid bounce charge",
        value : "bounce_charge",
        error : "bouncechargeError",
        validationType :"floatEmpty"
    }
]