import axios from 'axios';
import { BASE_URL } from '../../constants/apiUrls';

export function getMsmeLeadReviewDetailsApi(payload) {
  return axios.get(`${BASE_URL}msme/lead/${payload.loanAppId}/review`, {
    params: payload,
  });
}

export function updateLeadDetailsApi(payload) {
  return axios.put(`${BASE_URL}msme/lead/${payload.loanAppId}/status_update/${payload.status}`, payload);
}

export function getMsmeActivityLogsApi(payload) {
  return axios.get(`${BASE_URL}msme/activity-logs/${payload.loanAppId}`, {
    params: payload,
  });
}
export function commentdetailsApi(payload) {
  return axios.post(`${BASE_URL}msme/add-comment`, payload);
}

export function getLeadStatusApi(payload) {
  return axios.get(`${BASE_URL}msme/lead/${payload.loan_app_id}/section`, {
    params: payload,
  });
}

export function getLeadOfferApi(payload) {
  return axios.post(`${BASE_URL}msme/lead/${payload.loan_app_id}/offer`, { payload });
}

// validate Bank Statement API
export function validateBSApi(payload) {
  return axios.post(`${BASE_URL}msme/leads/cams/validate-bank-statement/${payload.loan_app_id}`, payload);
}

// Reset Lead and bring back it to Draft Stage
export function leadResetApi(payload) {
  return axios.post(`${BASE_URL}msme/leads/lead-reset/${payload.loan_app_id}`, payload);
}

export function getCalculateFeesAndChargesWatcherApi(payload) {
  return axios.post(`${BASE_URL}msme/loan/calculateFeesAndCharges`, payload);
}

export function postAadhaarOtpApi(payload) {
  return axios.post(`${BASE_URL}msme/leads/okyc-aadhar-otp`, { payload });
}

export function createMsmeActivityLogApi(payload) {
  return axios.post(`${BASE_URL}msme/activity-logs`, payload);
}

export function updatePreliminaryCheckStatusApi(payload) {
  return axios.post(`${BASE_URL}msme/lead/${payload.loan_app_id}/preliminary_check`, payload);
}

export function changeToLeadDeviationApi(payload) {
  return axios.post(`${BASE_URL}msme/lead/change-to-lead-deviation`, payload);
}
