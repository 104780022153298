export const disbursmentChannelList = [
  {
    value: 'IDFC',
    label: 'IDFC',
  },
  {
    value: 'ICICI',
    label: 'ICICI',
  },
  {
    value: 'PAYTM',
    label: 'PAYTM',
  },
  {
    value: 'RAZORPAY',
    label: 'RAZORPAY',
  },
  {
    value: 'CASHFREE',
    label: 'CASHFREE',
  },
  {
    value: 'PAYU',
    label: 'PAYU',
  },
  {
    value: 'ad hoc',
    label: 'Ad hoc',
  },
];

export const txnTypeList = [
  {
    value: 'usage',
    label: 'Usage',
  },
  {
    value: 'repayment',
    label: 'Repayment',
  },
];
