const REACT_APP_UPPERAGELIMIT_LAP = 60;
const REACT_APP_AGE_LAP = 21;
const SECTION_STATUS = {
  APPROVED: 'approved',
  INREVIEW: 'in_review',
};

module.exports = {
  REACT_APP_UPPERAGELIMIT_LAP,
  REACT_APP_AGE_LAP,
  SECTION_STATUS,
};
