const Number_Constants = {
  ZERO: 0,
  NINE: 9,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIFTEEN: 15,
  THOUSAND: 1000,
  TWO_HUNDRED: 200,
};

module.exports = {
  Number_Constants,
};
