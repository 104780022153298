import * as React from 'react';
import { bookLoansFormJsonFields } from './bookLoansFormJson';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { storedList, saveToStorage } from '../../../util/localstorage';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import { getMsmeLoanDocumentsWatcher } from 'msme/actions/bookLoan.action';
import 'react-sdk/dist/styles/_fonts.scss';
import CustomDatePicker from 'react-sdk/dist/components/DatePicker/DatePicker';
import { validateData } from '../../../util/validation';
import { verifyDateAfter1800 } from '../../../util/helper';
import { patchMsmeDetailsWatcher, patchMsmeDocDeleteWatcher } from '../../actions/msme.action';
import { postComprehensiveLogsWatcher } from '../../../actions/comprehensiveLogs.action';
import Alert from 'react-sdk/dist/components/Alert/Alert';
import { getMsmeCompaniesWatcher } from '../../actions/msme.action';
import { getMsmeProductByCompanyIDWatcher } from '../../actions/msme.action';
import getSectionStatus from './GetLeadSectionStatus/GetLeadSectionStatus';
import { SectionData } from 'msme/config/sectionData';
import { getLeadStatusWatcher } from '../../actions/lead.action';
import AadharVerifyInputbox from './reusableComponents/aadharVerifyInputbox';
import { useHistory } from 'react-router-dom';
import {isValuePresent} from '../../../util/helper';
import getSubSectionRemarks from './GetLeadSectionStatus/GetLeadSubSectionRemarks';
import './bookLoans.style.css';
import './applicantDetailsForm.style.css';
const sectionStatus = ['deviation', 'approved', 'rejected', 'failed'];
import { KYCDocumentPANTittle, KYCDocumentSelfieTittle, KYCDocumentPanXMLTittle, KYCAadharTittle, AadharXML } from './uploadKycData';
import UploadFileInput from '../../components/uploadFileInput/UploadFileInput';
import { States, Cities } from '../../../constants/country-state-city-data';
import moment from 'moment';
import { createLoanIDWatcher } from './../../actions/bookLoan.action';
import { documentCode } from 'msme/config/docCode';
import { getSubmitButton } from 'msme/util/resuableJsx/reusableJsx';
const user = storedList('user');
const BOOK_LOAN_FORM_JSON = bookLoansFormJsonFields();
const sectionName = 'primary-applicants';
const fetchObjectFieldsByDept = (deptName) => {
  const matchingObjects = [];
  for (let object of BOOK_LOAN_FORM_JSON) {
    if (object.dept === deptName) {
      matchingObjects.push(object.field);
    }
  }
  return matchingObjects;
};

const disabledFields = {
  view: ['company_id', 'product_id', 'loan_app_id', 'partner_borrower_id', 'partner_loan_app_id', 'requested_loan_amount', 'tenure_in_months', 'interest_rate', 'pan_value', ...fetchObjectFieldsByDept('Applicant Details'), ...fetchObjectFieldsByDept('KYC Document PAN'), ...fetchObjectFieldsByDept('Current Address'), ...fetchObjectFieldsByDept('Permanent Address'), ...fetchObjectFieldsByDept("Applicant KYC 1")],
  edit: ['company_id', 'product_id', 'loan_app_id', 'partner_loan_app_id', 'partner_borrower_id', 'pan_value', ...fetchObjectFieldsByDept('Applicant Details'), ...fetchObjectFieldsByDept('KYC Document PAN'), ...fetchObjectFieldsByDept('Current Address'), ...fetchObjectFieldsByDept('Permanent Address')],
};

const setDocumentView = (TitleOb, documents) => {
  return TitleOb.map((givenObj) => {
    const matchingObj = documents?.find((otherObj) => otherObj.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
    if (matchingObj) {
      return {
        ...givenObj,
        s3_url: matchingObj.file_url,
        doc: matchingObj,
      };
    } else {
      return givenObj;
    }
  });
};

const checkDocStatus = (data) => {
  for (let ob of data) {
    if (!ob?.s3_url && ob.isRequired) {
      return false;
    }
  }
  return true;
};

export default function ApplicantDetailsForm(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLeadRejected, setIsLeadRejected] = useState(false);
  const [panRejectionStatus, setPanRejectionStatus] = useState(false);

  const useAsyncState = (initialState) => {
    const [state, setState] = useState(initialState);

    const asyncSetState = (value) => {
      return new Promise((resolve) => {
        setState(value);

        setState((current) => {
          resolve(current);

          return current;
        });
      });
    };

    return [state, asyncSetState];
  };
  const { setNavState, setNavIconPrefixState, loanAppId, setLoanAppId, applicantData, setApplicantData, navIconPrefixState, setMSMECompanyId, setMSMEProductID, statusCheck, setStatusCheck, section_code, sub_section_code, documents, MSMECompanyId, MSMEProductId, loanDetailsSubsectionStatus, leadStatus, setLeadStatus, setShouldFetch, loanDetailsStatus } = props;

  const [selfieItem, setSelfiItem] = useState(KYCDocumentSelfieTittle);
  const [panImageItem, setPanImage] = useState(KYCDocumentPANTittle);
  const [panXmlItem, setPanXml] = useState(KYCDocumentPanXMLTittle);
  const [aadharItem, setAadharfront] = useState(KYCAadharTittle);
  const [aadharfrontXmlItem, setAadharfrontXml] = useState(AadharXML);
  const [subSectionStatus, setSubsectionStatus] = useState('');
  const [containsDanger,setContainsDanger] = useState(false);

  const fetchLeadStatus = () => {
    const payload = {
      loan_app_id: loanAppId,
      companyId: MSMECompanyId,
      productId: MSMEProductId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getLeadStatusWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setStatusObject(response.find((item) => item.section_code === 'primary'));
      })
      .catch((error) => {});
  };

  const fetchLeadDetails = () => {
    setSubsectionStatus(loanDetailsSubsectionStatus['100']?.applicant_okyc);
  };

  useEffect(() => {
    if (loanAppId && MSMECompanyId && MSMEProductId) {
      if (loanDetailsStatus && loanDetailsStatus['primary'] == 'rejected') {
        setIsLeadRejected(true);
      }

      fetchLeadStatus();
      getLoanDocuments();
    }
  }, [loanAppId, MSMECompanyId, MSMEProductId, leadStatus, loanDetailsStatus]);

  useEffect(() => {
    if (loanDetailsSubsectionStatus && loanDetailsSubsectionStatus['100']) {
      fetchLeadDetails();
      setPanRejectionStatus(loanDetailsSubsectionStatus['100']?.primary_pan === 'rejected' ? true : false);
    }
  }, [loanDetailsSubsectionStatus]);

  const getLoanDocuments = () => {
    const payload = {
      loanAppID: loanAppId,
      companyId: MSMECompanyId,
      productId: MSMEProductId,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getMsmeLoanDocumentsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        if (response) {
          let stateDoc = {
            applicant_image_value: false,
            aadhaar_front_image_value: false,
            aadhaar_back_image_value: false,
            pan_image_value: false,
            pan_xml_image_value: false,
            aadhaar_xml_image_value: false,
          };

          let data = setDocumentView(KYCDocumentSelfieTittle, response);
          setSelfiItem(data);
          stateDoc['applicant_image_value'] = checkDocStatus(data);

          data = setDocumentView(KYCDocumentPANTittle, response);
          setPanImage(data);
          stateDoc['pan_image_value'] = checkDocStatus(data);

          data = setDocumentView(KYCDocumentPanXMLTittle, response);
          setPanXml(data);
          stateDoc['pan_xml_image_value'] = checkDocStatus(data);

          data = setDocumentView(KYCAadharTittle, response);
          setAadharfront(data);
          stateDoc['aadhaar_front_image_value'] = checkDocStatus(data);

          data = setDocumentView(AadharXML, response);
          setAadharfrontXml(data);
          stateDoc['aadhaar_xml_image_value'] = checkDocStatus(data);

          for (let obj of response) {
            if (obj.code == '116') {
              setSelectedFileType((prevState) => ({
                ...prevState,
                ['Response XML/JSON']: false,
              }));
            }
            if (obj.code == '114') {
              setAadharFileType('Response XML/JSON');
            }
          }
          setDocumentStateData(stateDoc);
        }
      })
      .catch((error) => {});
  };

  let intervalId;
  const [statusObject, setStatusObject] = useState('');
  const [stateData, setStateData] = useState({ ...applicantData } ?? {});
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [company, setCompany] = useState([]);
  const [product, setProduct] = useState([]);
  const [companyID, setCompanyID] = useState();
  const [productID, setProductID] = useState();
  const [validationData, setValidationData] = useState({});
  const [selectedFileType, setSelectedFileType] = useState({ 'Image & PDF': true, 'Validation Doc': false });
  const [selectedAadhaarFileType, setSelectedAadhaarFileType] = useState({ 'Image & PDF': true, 'Validation Doc': false });
  const [paViewPerAddress, setPaViewPerAddress] = useState(true);
  const [states, setStatesState] = useState(States ?? []);
  const [city, setCityState] = useState([]);
  const [perCity, setPerCityState] = useState([]);
  const [newFile, setNewFile] = useState();
  const [panButtonState, setPanButtonState] = useState('button');
  const [panBorder, setPanBorder] = useState('border: 1px solid #BBBFCC');
  const [isPanValid, setIsPanValid] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [backgroundColorBlur, setBackgroundColorBlur] = useState(true);
  const [companyUser, setCompanyUser] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(navIconPrefixState['Applicant Details'] == 'success' ? true : false);
  const [uploadFileState, setUploadFileState] = useState({
    aadhaar_front_image_value_state: 'button',
    aadhaar_back_image_value_state: 'button',
    applicant_image_value_state: 'button',
    pan_image_value_state: 'button',
    aadhaar_xml_image_value_state: 'button',
  });
  const fileInputRef = useRef(null);
  const [uploadSelectedFile, setUploadSelectedFile] = useState({
    aadhaar_front_image_value: '',
    aadhaar_back_image_value: '',
    applicant_image_value: '',
    pan_image_value: '',
    aadhaar_xml_image_value: '',
  });

  const [documentStateData, setDocumentStateData] = useState({
    applicant_image_value: false,
    aadhaar_front_image_value: false,
    aadhaar_back_image_value: false,
    pan_image_value: false,
    pan_xml_image_value: false,
    aadhaar_xml_image_value: false,
  });

  const [sectionStatusCheck, setSectionStatusCheck] = useState('');
  useEffect(() => {
    if ((props.type == 'edit' || props.type == 'view') && applicantData) {
      setStateData(applicantData);
      if (applicantData.loan_app_id) {
        setLoanAppId(applicantData.loan_app_id);
        setPaViewPerAddress(applicantData.address_same ? false : true);
        if (loanDetailsSubsectionStatus && loanDetailsSubsectionStatus[100]) {
          if (['approved', 'deviation'].includes(loanDetailsSubsectionStatus[100]['primary_pan'])) {
            setStatusCheck(true);
            setIsPanValid(true);
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            if (['approved'].includes(loanDetailsSubsectionStatus[100]['primary_pan'])) {
              setPanBorder('1px solid green');
            } else {
              setPanBorder('1px solid yellow');
            }
          }
        }
      }
      if (company.length !== 0 && applicantData.company_id) {
        const data = company.find((item) => item._id === applicantData.company_id);
        dropDownChange(data, 'company_id');
      }
    } else {
      if (company.length !== 0 && user?.company_id) {
        const data = company.find((item) => item._id === user.company_id);
        dropDownChange(data, 'company_id');
        setCompanyUser(true);
      }
    }
  }, [props.type, company, applicantData, user]);

  useEffect(() => {
    if (product.length != 0) {
      if (props.type == 'edit' || props.type == 'view') {
        const data = product.find((item) => item._id === applicantData.product_id);
        dropDownChange(data, 'product_id');
      } else {
        const data = product[0];
        dropDownChange(data, 'product_id');
      }
    }
  }, [props.type, product]);

  const handleInputBoxClick = (event, field) => {
    if (fileInputRef.current) {
      fileInputRef.current.click(field);
    } else {
    }
    setNewFile(field);
  };

  const handleFileInputChange = (field, event) => {
    setUploadFileState((prevState) => ({
      ...prevState,
      [`${field}_state`]: 'loader',
    }));
    const selectedFile = event?.target?.files;
    if (selectedFile[0]['size'] > 5e6) {
      showAlert('File size should not be greater than 5 MB', 'error');
      return;
    }
    const fileType = selectedFile[0]['name'];
    const fileExtension = fileType.split('.').pop();
    if (fileExtension.toLowerCase() != 'pdf' && fileExtension.toLowerCase() != 'png' && fileExtension.toLowerCase() != 'jpg' && fileExtension.toLowerCase() != 'jpeg') {
      showAlert('Only JPG,JPEG,PDF & PNG file is allowed', 'error');
      return;
    }
    if (selectedFile.length > 0) {
      let fileToLoad = selectedFile[0];
      let fileReader = new FileReader();
      fileReader.onload = async (fileLoadedEvent) => {
        setUploadSelectedFile((prevState) => ({
          ...prevState,
          [newFile]: {
            name: fileType,
            data: fileLoadedEvent.target.result,
          },
        }));
      };
      fileReader.readAsDataURL(fileToLoad);
    }

    setUploadFileState((prevState) => ({
      ...prevState,
      [`${field}_state`]: 'button',
    }));

    if (uploadSelectedFile.pan_image_value !== '' && uploadSelectedFile.applicant_image_value !== '' && ((uploadSelectedFile.aadhaar_front_image_value !== '' && uploadSelectedFile.aadhaar_front_image_value !== '') || uploadSelectedFile.aadhaar_xml_image_value !== '')) {
    }
  };

  const calculateAge = (birthMonth, birthDay, birthYear) => {
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth();
    var currentDay = currentDate.getDate();
    var calculatedAge = currentYear - birthYear;

    if (currentMonth < parseInt(birthMonth) - 1) {
      calculatedAge--;
    }
    if (parseInt(birthMonth) - 1 == currentMonth && currentDay < parseInt(birthDay)) {
      calculatedAge--;
    }
    return calculatedAge;
  };

  useEffect(() => {
    if (stateData['curr_addr_state'] || stateData['per_addr_state']) {
      setCurrCitiesData(Cities(stateData['curr_addr_state']));
    }
  }, [stateData['curr_addr_state'] || stateData['per_addr_state']]);

  useEffect(() => {
    let formValidated = false;
    let documentValidated = false;

    bookLoansFormJsonFields().map((item, idx) => {
      if (item.dept == 'Applicant Details' || item.dept == 'select_company' || item.dept == 'select_partner' || item.dept == 'Current Address' || item.dept == 'Loan Requirement' || item.dept == 'Permanent Address' || item.dept == 'Applicant KYC 1') {
        if (item.isOptional == false) {
          if (!stateData[`${item.type}_vl_${item.field}`]) {
            formValidated = true;
          }
        }
      }
    });
    if (selectedFileType['Image & PDF'] && selectedAadhaarFileType['Image & PDF']) {
      if (documentStateData.applicant_image_value && documentStateData.aadhaar_front_image_value && documentStateData.pan_image_value) {
        documentValidated = true;
      }
    }
    if (selectedFileType['Validation Doc'] && selectedAadhaarFileType['Image & PDF']) {
      if (documentStateData.applicant_image_value && documentStateData.aadhaar_front_image_value && documentStateData.pan_xml_image_value) {
        documentValidated = true;
      }
    }
    if (selectedFileType['Validation Doc'] && selectedAadhaarFileType['Validation Doc']) {
      if (documentStateData.applicant_image_value && documentStateData.aadhaar_xml_image_value && documentStateData.pan_xml_image_value) {
        documentValidated = true;
      }
    }
    if (selectedFileType['Image & PDF'] && selectedAadhaarFileType['Validation Doc']) {
      if (documentStateData.applicant_image_value && documentStateData.aadhaar_xml_image_value && documentStateData.pan_image_value) {
        documentValidated = true;
      }
    }
    if (!formValidated && documentValidated) {
      setFormComplete(['approved', 'deviation'].includes(statusObject?.section_status ?? '') ? false : true);
    } else {
      setFormComplete(false);
    }
  }, [stateData, documentStateData]);

  const handleGetPerCities = async (value) => {
    setPerCityState(Cities(value));
  };

  const showAlert = (msg, type) => {
    const element = document.getElementById('TopNavBar');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }

    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);

    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  useEffect(() => {
    if (stateData['curr_addr_state'] || stateData['per_addr_state']) {
      setCurrCitiesData(Cities(stateData['curr_addr_state']));
    }
  }, [stateData['curr_addr_state'] || stateData['per_addr_state']]);

  const handleGetCities = async (value) => {
    setCityState(Cities(value));
  };
  const inputBoxCss = {
    marginTop: '8px',
    maxHeight: '500px',
    zIndex: 1,
    width: '105%',
  };

  const radioButtonLabelColorCss = {
    color: panButtonState === 'icon' ? 'var(--neutrals-neutral-100, #161719)' : '#C0C1C8',
  };
  const radioInputColorCss = {
    accentColor: panButtonState === 'icon' ? '#134CDE' : 'var(--neutrals-neutral-60, #767888)',
  };

  const handleDataFromChild = (id, data) => {
    console.log(`Received data for item with id ${id}:`, data);
  };

  useEffect(() => {
    if (!paViewPerAddress) {
      bookLoansFormJsonFields().map((item, idx) => {
        if (item.dept == 'Permanent Address') {
          let addressField = `${item.type}_vl_${item.field}`;
          setStateData((prevState) => ({
            ...prevState,
            [addressField]: '',
          }));
          let field = item.field.replace('per', 'curr');
          const value = stateData[`${item.type}_vl_${field}`];
          if (value) {
            let perField = `${item.type}_vl_${item.field}`;
            let isValid = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
            setStateData((prevState) => ({
              ...prevState,
              [perField]: value,
            }));
            setValidationData((prevState) => ({
              ...prevState,
              [`${perField}State`]: !isValid ? 'has-danger' : '',
            }));
          }
        }
      });
    } else {
      if (!applicantData) {
        bookLoansFormJsonFields().map((item, idx) => {
          if (item.dept == 'Permanent Address') {
            let field = `${item.type}_vl_${item.field}`;
            setStateData((prevState) => ({
              ...prevState,
              [field]: '',
            }));
          }
        });
      }
    }
  }, [!paViewPerAddress]);

  useEffect(() => {
    if (stateData['date_vl_date_of_birth']) {
      const dob = stateData['date_vl_date_of_birth'];
      const yyyyMmDdRegExp = /^\d{4}-\d{2}-\d{2}$/.test(dob);
      if (yyyyMmDdRegExp) {
        const age = calculateAge(dob.substring(5, 7), dob.substring(8, 10), dob.substring(0, 4));
        setStateData((prevState) => ({
          ...prevState,
          number_vl_age: age,
        }));
        if (props.type == 'view' || props.type == 'edit') {
          setApplicantData((prevState) => ({
            ...prevState,
            number_vl_age: age,
          }));
        }
        let field = `number_vl_age`;
        let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), age);
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: !isValid ? 'has-danger' : '',
        }));
      } else {
        setStateData((prevState) => ({
          ...prevState,
          number_vl_age: ' ',
        }));
      }
    }
  }, [stateData['date_vl_date_of_birth']]);

  const change = (e, type, name) => {
    const buttonText = e.target?.textContent;
    const valued = buttonText;
    if (valued === 'Send OTP') {
    } else if (valued === 'Verify') {
      createLoanAppID();
    } else {
      let values = e.value;
      if (name === 'pan_value') {
        values = e.value.toUpperCase();
        if (values?.length >= 10) {
          values = values.substring(0, 10);
        }
      } else if (name === 'aadhaar_value') {
        values = e.value;

        if (values?.length >= 12) {
          values = values.substring(0, 12);
        }
      } else if (name === 'mobile_number') {
        values = e.value;
        if (values?.length >= 10) {
          values = values.substring(0, 10);
        }
      } else if (name === 'curr_addr_pincode' || name === 'per_addr_pincode') {
        values = e.value;
        if (values?.length >= 6) {
          values = values.substring(0, 6);
        }
      } else if (name === 'curr_addr_ln1' || name === 'per_addr_ln1' || name === 'curr_addr_ln2' || name === 'per_addr_ln2') {
        values = e.value;
        if (values) values = values?.replaceAll(/\\/g, '');
        if (values?.length >= 40) {
          values = values.substring(0, 40);
        }
      } else if (name === 'interest_rate') {
        let rawValue = e.value;

        if (rawValue < 0) {
          values = 0;
        } else if (rawValue > 100) {
          values = 100;
        } else {
          values = rawValue;
        }
      } else {
        values = e.value;
      }

      const value = values;
      let field = `${type}_vl_${name}`;
      let isValid = validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), value);

      if (field.indexOf('curr') != -1 && !paViewPerAddress) {
        const perField = field.replace('curr', 'per');
        let isValidData = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value);
        setStateData((prevState) => ({
          ...prevState,
          [perField]: value,
        }));
        setValidationData((prevState) => ({
          ...prevState,
          [`${perField}State`]: !isValidData ? 'has-danger' : '',
        }));
      }
      setStateData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`${field}State`]: !isValid ? 'has-danger' : '',
      }));
    }
  };

  useEffect(() => {
    dispatch(
      getMsmeCompaniesWatcher(
        (result) => {
          let temp = result;
          let allCompany = [];
          temp.forEach((comp) => {
            allCompany.push({
              label: comp.name,
              value: comp.name,
              _id: comp._id,
            });
          });
          setCompany(allCompany);
        },
        (error) => {},
      ),
    );
  }, []);

  useEffect(() => {
    if (companyID) {
      dispatch(
        getMsmeProductByCompanyIDWatcher(
          companyID,
          (result) => {
            let temp = result;
            let allProduct = [];
            temp.forEach((prod) => {
              if (prod.is_msme_automation_flag === 'Y') {
                allProduct.push({
                  label: prod.name,
                  value: prod.name,
                  _id: prod._id,
                });
              }
            });
            setProduct(allProduct);
          },
          (error) => {},
        ),
      );
    }
  }, [companyID]);

  const dropDownChange = (value, name) => {
    setStateData((prevState) => ({
      ...prevState,
      [`string_vl_${name}`]: value?.value ?? '',
    }));

    if (name === 'company_id') {
      setCompanyID(value._id);
      setProduct([]);
      saveToStorage('msme_company_id', value._id);
      setMSMECompanyId(value._id);
    }

    if (name === 'product_id') {
      setProductID(value._id);
      saveToStorage('msme_product_id', value._id);
      setMSMEProductID(value._id);
    }

    if ((name === 'state' || name === 'curr_addr_state') && value !== undefined && value !== null) {
      handleGetCities(value);
      if (stateData.string_vl_curr_addr_city) {
        setStateData((prevState) => ({
          ...prevState,
          [`string_vl_curr_addr_city`]: '',
        }));
      }
    }

    if (name === 'per_addr_state' && value !== undefined && value !== null) {
      handleGetPerCities(value);
      if (stateData.string_vl_per_addr_city) {
        setStateData((prevState) => ({
          ...prevState,
          [`string_vl_per_addr_city`]: '',
        }));
      }
    }

    if (name.indexOf('curr') != -1 && !paViewPerAddress) {
      const field = `string_vl_${name}`;
      const perField = field.replace('curr', 'per');
      let isValidData = validateData(perField.substring(0, perField.indexOf('_vl_')).toLowerCase(), value?.value);
      setStateData((prevState) => ({
        ...prevState,
        [perField]: value?.value,
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`${perField}State`]: !isValidData ? 'has-danger' : '',
      }));
    }

    const validatingType = name === 'pincode' ? 'pincode' : 'string';
    const isValid = validateData(validatingType, value?.value);

    setValidationData((prevState) => ({
      ...prevState,
      [`${validatingType}_vl_${name}State`]: !isValid ? 'has-danger' : '',
    }));
    let flagOfDanger=false
    if (name.includes('city')){
      const field = `string_vl_${name}`;
      const cityArray = name=='curr_addr_city'?city:perCity
      const validCity=isValuePresent(cityArray, value.value)
      if(validCity===false){
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: 'has-danger',
        }));
        flagOfDanger=true
        setContainsDanger(true);
      }   
    }
    if (name.includes('state')){
      const field = `string_vl_${name}`;
      const validState=isValuePresent(states, value.value)
      if (validState==false){
        setValidationData((prevState) => ({
          ...prevState,
          [`${field}State`]: 'has-danger',
        }));
        flagOfDanger=true
        setContainsDanger(true);
      }
    }
    if (!flagOfDanger){
      setContainsDanger(false);
    }
  };

  const changeDateSelected = (value, name) => {
    const pastYear = new Date(new Date().setFullYear(new Date().getFullYear()));
    const selectedDate = moment(pastYear).format('YYYY-MM-DD');
    if (selectedDate > value) {
      const date = verifyDateAfter1800(moment(value).format('YYYY-MM-DD')) ? moment(value).format('YYYY-MM-DD') : value;
      const isValid = validateData(name.substring(0, name.indexOf('_vl_')).toLowerCase(), date);
      setStateData((prevState) => ({
        ...prevState,
        [name]: date,
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`${name}State`]: !isValid ? 'has-danger' : '',
      }));
    } else {
      const isValid = false;
      setStateData((prevState) => ({
        ...prevState,
        number_vl_age: ' ',
      }));
      setValidationData((prevState) => ({
        ...prevState,
        [`${name}State`]: !isValid ? 'has-danger' : '',
      }));
    }
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const fetchObjectsByDept = (deptName) => {
    const matchingObjects = [];
    for (const object of bookLoansFormJsonFields()) {
      if (object.dept === deptName) {
        matchingObjects.push(object);
      }
    }
    return matchingObjects;
  };

  const createLoanAppID = () => {
    let formValidated = true;
    if (containsDanger==true){
      formValidated = false
    }
    bookLoansFormJsonFields().map((item, idx) => {
      if ((item.dept == 'Applicant Details' || item.dept == 'Loan Requirement' || item.dept == 'select_company' || item.dept == 'Current Address' || item.dept == 'select_partner' || item.dept == 'Permanent Address' || item.field == 'pan_value') && item.isOptional == false) {
        const field = `${item.type}_vl_${item.field}`;
        if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
        if (!stateData[field]) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
      }
    });
    if (formValidated) {
      let data = {
        loan_app_id: loanAppId,
        product_id: productID,
        company_id: companyID,
        address_same: paViewPerAddress ? 0 : 1,
        partner_loan_app_id: stateData.string_vl_partner_loan_app_id,
        partner_borrower_id: stateData.string_vl_partner_borrower_id,
        loan_amount: stateData.float_vl_requested_loan_amount,
        loan_tenure: stateData.number_vl_tenure_in_months,
        loan_interest_rate: stateData.float_vl_interest_rate,
        appl_pan: stateData.pan_vl_pan_value,
        dob: stateData.date_vl_date_of_birth,
        first_name: stateData.string_vl_first_name,
        middle_name: stateData.string_vl_middle_name,
        last_name: stateData.string_vl_last_name,
        email_id: stateData.email_vl_email,
        father_fname: stateData.string_vl_father_full_name,
        type_of_addr: 'Permanent',
        resi_addr_ln1: stateData.string_vl_curr_addr_ln1,
        resi_addr_ln2: stateData.string_vl_curr_addr_ln2,
        city: stateData.string_vl_curr_addr_city,
        state: stateData.string_vl_curr_addr_state,
        pincode: stateData.pincode_vl_curr_addr_pincode,
        per_addr_ln1: stateData.string_vl_per_addr_ln1,
        per_addr_ln2: stateData.string_vl_per_addr_ln2,
        per_city: stateData.string_vl_per_addr_city,
        per_state: stateData.string_vl_per_addr_state,
        per_pincode: stateData.pincode_vl_per_addr_pincode,
        appl_phone: stateData.mobile_vl_mobile_number,
        gender: stateData.string_vl_gender,
        sub_section_code: SectionData.primary.primary_pan.sub_section_code,
        section_code: SectionData.primary.section_code,
        section_sequence_no: SectionData.primary.section_sequence_no,
        section_name: SectionData.primary.section_name,
        sub_section_name: SectionData.primary.primary_pan.sub_section_name,
        sub_section_sequence_no: SectionData.primary.primary_pan.sub_section_sequence_no,
      };
      setPanButtonState('loader');
      new Promise((resolve, reject) => {
        dispatch(createLoanIDWatcher(data, resolve, reject));
      })
        .then((response) => {
          saveLogs(loanAppId ?? response.data.loan_app_id, 'primary_applicant_pan_verify');
          setLoanAppId(response.data.loan_app_id);
          setStatusCheckApi(response.data.loan_app_id, SectionData.primary.section_code, SectionData.primary.primary_pan.sub_section_code, dispatch);
        })
        .catch((error) => {
          saveLogs(loanAppId, 'primary_applicant_pan_verify');
          setPanButtonState('button');
          setPanBorder('1px solid red');
          showAlert(error?.response?.data?.message || 'Something went wrong', 'error');
          setTimeout(() => {
            handleAlertClose();
          }, 4000);
        });
    } else {
      showAlert('Kindly check for errors in fields', 'error');
      setTimeout(() => {
        handleAlertClose();
      }, 4000);
    }
  };

  const dropDownOptions = (row) => {
    switch (row.field) {
      case 'curr_addr_state':
      case 'per_addr_state':
        return states;
      case 'curr_addr_city':
        return city;
      case 'per_addr_city':
        return perCity;
      case 'company_id':
        return company;
      case 'product_id':
        return product;
      default:
        return row.options;
    }
  };

  const rejectionEnabled = (row) => {
    if (['partner_loan_app_id', 'partner_borrower_id', 'first_name', 'middle_name', 'last_name', 'date_of_birth', 'gender', 'mobile_number', 'email', 'father_full_name', 'pan_value'].includes(row?.field) && panRejectionStatus) {
      return true;
    }
    return false;
  };

  const renderFields = (dept) => {
    const deptArray = fetchObjectsByDept(dept);

  
    return (
      <div
        style={{
          display: 'grid',
          rowGap: '28px',
          gridTemplateColumns: '32.8% 32.8% 32.8%',
          columnGap: '1%',
          width: '98%',
        }}
      >
        {deptArray &&
          deptArray.map((row, index) => {
            return (
              <>
                {row.field === 'curr_addr_state' || row.field === 'curr_addr_city' || row.field === 'per_addr_state' || row.field === 'per_addr_city' || row.field === 'curr_addr_sub_area' || row.field === 'per_addr_sub_area' || row.field === 'company_id' ? (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      isDrawdown={disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? false : row.dept == 'Current Address' && panButtonState === 'icon' ? false : row.dept == 'Permanent Address' ? (row.isDrawdown && !paViewPerAddress ? false : row.isDrawdown) : row.isDrawdown}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      onClick={(value) => dropDownChange(value, row.field)}
                      isDisabled={disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value' ? true : row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? true : row.field === 'company_id' && companyUser ? true : false}
                      customDropdownClass={row.field == 'gender' ? { ...inputBoxCss, width: '105%' } : inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        marginTop: (loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? '-3px' : '0px',
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      options={dropDownOptions(row)}
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                    />
                  </>
                ) : row.field === 'aadhaar_value' ? (
                  subSectionStatus === 'in_progress' && leadStatus === 'follow_up_kyc' ? (
                    <AadharVerifyInputbox
                      company_id={MSMECompanyId}
                      product_id={MSMEProductId}
                      row={row}
                      stateData={stateData}
                      props={props}
                      disabledFields={disabledFields}
                      loanAppId={loanAppId}
                      viewPerAddress={paViewPerAddress}
                      isFormDisabled={isFormDisabled}
                      validationData={validationData}
                      statusCheck={statusCheck}
                      setValidationData={setValidationData}
                      validateData={validateData}
                      setStateData={setStateData}
                      sectionCode={'primary'}
                      sectionSequenceNumber={100}
                      showAlert={showAlert}
                      setShouldFetch={setShouldFetch}
                    />
                  ) : (
                    <InputBox
                      id={row.field}
                      label={row.title}
                      isDrawdown={false}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      onClick={(event) => change(event, row.type, row.field)}
                      isDisabled={panRejectionStatus ? true : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && !isPanValid && row.field === 'aadhaar_value' ? true : row.field === 'age' || (!paViewPerAddress && row.dept == 'Permanent Address') || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        marginTop: (props.type != 'edit' && loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? '-3px' : '0px',
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                    />
                  )
                ) : row.field === 'requested_loan_amount' || row.field == 'tenure_in_months' || row.field === 'interest_rate' || row.field === 'curr_addr_pincode' || row.field === 'per_addr_pincode' || row.field === 'mobile_number' || row.field === 'aadhaar_value' ? (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      type={'number'}
                      isDrawdown={false}
                      initialValue={row.field === 'aadhaar_value' ? null : stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      isBoxType={row.field === 'aadhaar_value' ? 'button' : null}
                      Buttonlabel={row.field === 'aadhaar_value' ? 'Send OTP' : null}
                      onClick={(event) => change(event, row.type, row.field)}
                      isDisabled={rejectionEnabled(row) ? false : row.field === 'aadhaar_value' ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value' ? true : row.field === 'age' || (!paViewPerAddress && row.dept == 'Permanent Address') || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        marginTop: (props.type != 'edit' && loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? '-3px' : '0px',
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                    />
                  </>
                ) : row.field === 'aadhaar_front_image_value' || row.field === 'aadhaar_back_image_value' || row.field === 'applicant_image_value' || row.field === 'aadhaar_xml_image_value' || row.field === 'pan_image_value' ? (
                  <>
                    <InputBox
                      isBoxType={uploadFileState[`${row.field}_state`]}
                      Buttonlabel={navIconPrefixState['Applicant Details'] == 'success' ? '' : uploadSelectedFile[row.field] === '' ? 'Upload' : 'Change'}
                      id={row.field}
                      label={row.title}
                      isDrawdown={false}
                      initialValue={uploadSelectedFile[row.field]}
                      onClick={(event) => {
                        handleInputBoxClick(event, row.field);
                      }}
                      isDisabled={disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : row.field === 'age' || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                        backgroundColor: backgroundColorBlur ? 'rgb(244, 244, 244)' : '',
                      }}
                      customInputClass={{
                        marginTop: (props.type != 'edit' && loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? '-3px' : '0px',
                        maxWidth: '82%',
                        backgroundColor: backgroundColorBlur ? 'rgb(244, 244, 244)' : '',
                      }}
                      options={row.options}
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      id={row.field}
                      onChange={(e) => {
                        handleFileInputChange(row.field, e, row.type);
                      }}
                    />
                  </>
                ) : row.type === 'pan' ? (
                  <>
                    <InputBox
                      isBoxType={panButtonState}
                      Buttonlabel={navIconPrefixState['Applicant Details'] == 'success' ? '' : 'Verify'}
                      id={row.field}
                      label={row.title}
                      isDrawdown={false}
                      initialValue={(stateData[`${row.type}_vl_${row.field}`] ?? '').toUpperCase()}
                      onClick={(event) => change(event, 'pan', row.field)}
                      isDisabled={rejectionEnabled(row) ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && loanAppId && isPanValid && row.field != 'aadhaar_value' ? true : row.field === 'age' || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                        border: panBorder,
                        pointerEvents: panButtonState === 'icon' ? 'none' : '',
                      }}
                      customInputClass={{
                        marginTop: (props.type != 'edit' && loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? '-3px' : '0px',
                        maxWidth: '82%',
                        backgroundColor: '#fff',
                      }}
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                    />
                  </>
                ) : row.field === 'gender' ? (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      isDrawdown={panButtonState === 'icon' ? false : true}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      isDisabled={rejectionEnabled(row) ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value' ? true : row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        marginTop: (props.type != 'edit' && loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? '-3px' : '0px',
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      onClick={(event) => change(event, row.type, row.field)}
                      options={row.isDrawdown ? row.options : []}
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                    />
                  </>
                ) : row.type === 'date' ? (
                  <>
                    <CustomDatePicker
                      customInputClass={{ height: '58px', width: '100%', maxWidth: '100%' }}
                      label="Date of Birth"
                      format={'YYYY-MM-DD'}
                      disableFuture={true}
                      age={parseInt(process.env.REACT_APP_AGE_MSME)}
                      startingDate={process.env.REACT_APP_UPPERAGELIMIT_MSME ? parseInt(new Date().getFullYear()) - parseInt(process.env.REACT_APP_UPPERAGELIMIT_MSME) : 1964}
                      endingDate={2050}
                      isBoxType={'icon'}
                      onDateChange={(date) => {
                        changeDateSelected(date, `${row.type}_vl_${row.field}`);
                      }}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ? stateData[`${row.type}_vl_${row.field}`] : '' || null}
                      isDisabled={rejectionEnabled(row) ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value' ? true : isFormDisabled ? true : false}
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                    />
                  </>
                ) : row.field === 'product_id' ? (
                  <>
                    <InputBox
                      key={companyID}
                      id={row.field}
                      label={row.title}
                      isDrawdown={disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? false : row.isDrawdown}
                      initialValue={stateData[`${row.type}_vl_${row.field}`]}
                      onClick={(value) => dropDownChange(value, row.field)}
                      isDisabled={disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value' ? true : row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={{
                        height: '56px',
                        width: '100%',
                        maxWidth: '100%',
                      }}
                      customInputClass={{
                        marginTop: (props.type != 'edit' && loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? '-3px' : '0px',
                        minWidth: '100%',
                        backgroundColor: '#fff',
                      }}
                      options={dropDownOptions(row)}
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                    />
                  </>
                ) : (
                  <>
                    <InputBox
                      id={row.field}
                      label={row.title}
                      isDrawdown={false}
                      initialValue={stateData[`${row.type}_vl_${row.field}`] ?? ''}
                      onClick={(event) => change(event, row.type, row.field)}
                      isDisabled={rejectionEnabled(row) ? false : disabledFields[props.type] && disabledFields[props.type].includes(row.field) ? true : props.type != 'edit' && loanAppId && statusCheck && row.field != 'aadhaar_value' ? true : row.field === 'age' || (!paViewPerAddress && row.dept == 'Permanent Address') || isFormDisabled ? true : false}
                      customDropdownClass={inputBoxCss}
                      customClass={
                        row.field === 'age'
                          ? {
                              height: '56px',
                              width: '100%',
                              maxWidth: '100%',
                              background: '#f4f4f4',
                            }
                          : {
                              height: '56px',
                              width: '100%',
                              maxWidth: '100%',
                            }
                      }
                      customInputClass={
                        row.field === 'age'
                          ? {
                              marginTop: '-3px',
                              minWidth: '100%',
                              backgroundColor: '#f4f4f4',
                              marginTop: (props.type != 'edit' && loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? '-3px' : '0px',
                            }
                          : {
                              minWidth: '100%',
                              backgroundColor: '#fff',
                              marginTop: (props.type != 'edit' && loanAppId && row.field != 'aadhaar_value') || row.field === 'age' || (row.dept == 'Permanent Address' && !paViewPerAddress) || isFormDisabled ? '-3px' : '0px',
                            }
                      }
                      error={row.checked.toLowerCase() === 'true' ? validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' : stateData[`${row.type}_vl_${row.field}`] !== '' && validationData[`${row.type}_vl_${row.field}State`] === 'has-danger'}
                      helperText={row.checked.toLowerCase() === 'true' ? (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '') : stateData[`${row.type}_vl_${row.field}`] !== '' && (validationData[`${row.type}_vl_${row.field}State`] === 'has-danger' ? row.validationMsg : '')}
                    />
                  </>
                )}
              </>
            );
          })}
      </div>
    );
  };

  const deleteFile = async (documentCodes) => {
    const payload = {
      company_id: MSMECompanyId,
      product_id: MSMEProductId,
      user: user,
      loanAppId: loanAppId,
      code: [...documentCodes],
      borrowerId: stateData?.borrower_id,
    };
    new Promise((resolve, reject) => {
      dispatch(patchMsmeDocDeleteWatcher(payload, resolve, reject));
    })
      .then((response) => {
        showAlert(response.message, 'success');
      })
      .catch((error) => {
        showAlert('Error deleting file', 'error');
      });
  };

  const handleCheckboxButton = (event) => {
    if (event.target.value == 'Image & PDF' && selectedFileType['Image & PDF'] && !selectedFileType['Validation Doc']) return;
    else if (event.target.value == 'Validation Doc' && !selectedFileType['Image & PDF'] && selectedFileType['Validation Doc']) return;
    if (selectedFileType[event.target.value]) {
      setSelectedFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: false,
      }));
    } else {
      setSelectedFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: true,
      }));
    }
    if (event.target.value === 'Validation Doc' && documentStateData.pan_xml_image_value) {
      deleteFile([documentCode.applicant_pan_XML]);
      setDocumentStateData({ ...documentStateData, pan_xml_image_value: false });
    } else if (event.target.value === 'Image & PDF' && documentStateData.pan_image_value) {
      deleteFile([documentCode.applicant_pan]);
      setDocumentStateData({ ...documentStateData, pan_image_value: false });
      setUploadSelectedFile((prevState) => ({
        ...prevState,
        pan_image_value: '',
      }));
    }
  };

  const handleAadhaarRadioButton = (event) => {
    if (event.target.value == 'Image & PDF' && selectedAadhaarFileType['Image & PDF'] && !selectedAadhaarFileType['Validation Doc']) return;
    else if (event.target.value == 'Validation Doc' && !selectedAadhaarFileType['Image & PDF'] && selectedAadhaarFileType['Validation Doc']) return;
    if (selectedAadhaarFileType[event.target.value]) {
      setSelectedAadhaarFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: false,
      }));
    } else {
      setSelectedAadhaarFileType((prevState) => ({
        ...prevState,
        [`${event.target.value}`]: true,
      }));
    }
    if (event.target.value === 'Validation Doc' && documentStateData.aadhaar_xml_image_value) {
      deleteFile([documentCode.applicant_aadhaar_XML]);
      setDocumentStateData({ ...documentStateData, aadhaar_xml_image_value: false });
    } else if (event.target.value === 'Image & PDF' && documentStateData.aadhaar_front_image_value) {
      deleteFile([documentCode.applicant_aadhaar_front, documentCode.applicant_aadhaar_back]);
      setDocumentStateData({ ...documentStateData, aadhaar_front_image_value: false, aadhaar_back_image_value: false });
      setUploadSelectedFile((prevState) => ({
        ...prevState,
        aadhaar_front_image_value: '',
        aadhaar_back_image_value: '',
      }));
    }
  };

  const handleSubmit = (event) => {
    let postData = {};
    let formValidated = true;

    bookLoansFormJsonFields().map((item, idx) => {
      if ((item.dept == 'Applicant Details' || item.dept == 'Loan Requirement' || item.dept == 'select_company' || item.dept == 'select_partner' || item.dept == 'Current Address' || item.dept == 'Permanent Address' || item.dept == 'Applicant KYC 1') && item.isOptional == false) {
        const field = `${item.type}_vl_${item.field}`;
        if (stateData[field]?.length > 0 && !validateData(field.substring(0, field.indexOf('_vl_')).toLowerCase(), stateData[field])) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
        if (!stateData[field]) {
          setValidationData((prevState) => ({
            ...prevState,
            [`${field}State`]: 'has-danger',
          }));
          formValidated = false;
        }
      }
    });
    if (formValidated) {
      setSectionStatusCheck('inProgress');
      postData = {
        loan_app_id: loanAppId,
        address_same: paViewPerAddress ? 0 : 1,
        section: 'primary-applicants',
        msme_company_id: companyID,
        msme_product_id: productID,
        user_id: user._id,
        loan_amount: stateData.float_vl_requested_loan_amount,
        loan_tenure: stateData.number_vl_tenure_in_months,
        loan_interest_rate: stateData.float_vl_interest_rate,
        aadhar_card_num: stateData.aadhaar_vl_aadhaar_value,
        section_sequence_no: SectionData.primary.section_sequence_no,
        section_name: SectionData.primary.section_name,
        sub_section_code: SectionData.primary.primary_section_submit.sub_section_code,
        sub_section_name: SectionData.primary.primary_section_submit.sub_section_name,
        sub_section_sequence_no: SectionData.primary.primary_section_submit.sub_section_sequence_no,
        section_code: SectionData.primary.section_code,
      };
      saveLogs(loanAppId, 'primary_applicant_verify_and_next');
      new Promise((resolve, reject) => {
        dispatch(patchMsmeDetailsWatcher(postData, resolve, reject));
      })
        .then((response) => {
          setApplicantData(stateData);
          setStatusCheckApi(loanAppId, SectionData.primary.section_code, SectionData.primary.primary_section_submit.sub_section_code, dispatch);
          showAlert(response?.message, 'success');
        })
        .catch((error) => {
          showAlert(error?.response?.data?.message, 'error');
        });
    } else {
      showAlert('Kindly check for errors in fields', 'error');
      setTimeout(() => {
        handleAlertClose();
      }, 4000);
    }
  };
  const handlePermanentAddress = () => {
    setPaViewPerAddress(!paViewPerAddress);
  };

  const setStatusCheckApi = async (loanAppID, sectionCode, subSectionCode, dispatch) => {
    intervalId = setInterval(async () => {
      try {
        const status = await getSectionStatus(loanAppID, user, companyID, productID, sectionCode, subSectionCode, dispatch);
        const subSectionRemarks = await getSubSectionRemarks(loanAppID, user, MSMECompanyId, MSMEProductId, sectionCode, subSectionCode, dispatch);
        if (status == 'approved') {
          if (subSectionCode == SectionData.primary.primary_pan.sub_section_code) {
            setPanRejectionStatus(false);
            setStatusCheck(true);
            setIsPanValid(true);
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            setPanBorder('1px solid green');
            showAlert('Lead Created Succesfully', 'success');
            setTimeout(() => {
              history.replace({
                pathname: `/admin/msme/lead/${loanAppID}/edit`,
                state: {
                  companyId: companyID,
                  productId: productID,
                },
              });
            }, 1000);
          } else {
            setNavState('Entity Details');
            setNavIconPrefixState((prevState) => ({
              ...prevState,
              'Applicant Details': 'success',
            }));
            setSectionStatusCheck('completed');
            if (setShouldFetch) {
              setShouldFetch((prev) => prev + 1);
            }
          }
          clearInterval(intervalId);
        } else if (status == 'deviation') {
          if (subSectionCode == SectionData.primary.primary_pan.sub_section_code) {
            setPanRejectionStatus(false);
            setStatusCheck(true);
            setIsPanValid(true);
            setBackgroundColorBlur(false);
            setPanButtonState('icon');
            showAlert('There is deviation in PAN validation, but you can still proceed further.', 'info');
            setPanBorder('1px solid yellow');
            setTimeout(() => {
              history.replace({
                pathname: `/admin/msme/lead/${loanAppID}/edit`,
                state: {
                  companyId: companyID,
                  productId: productID,
                },
              });
            }, 1000);
          } else {
            setNavState('Entity Details');
            setNavIconPrefixState((prevState) => ({
              ...prevState,
              'Applicant Details': 'deviation',
            }));
            setSectionStatusCheck('completed');
            if (setShouldFetch) {
              setShouldFetch((prev) => prev + 1);
            }
          }
          clearInterval(intervalId);
        } else if (status == 'rejected') {
          if (subSectionCode == SectionData.primary.primary_pan.sub_section_code) {
            setPanRejectionStatus(true);
            showAlert(subSectionRemarks, 'error');
            setPanButtonState('button');
          } else {
            showAlert(subSectionRemarks, 'error');
            setSectionStatusCheck('completed');
            setIsLeadRejected(true);
          }
          setStatusCheck(false);
          clearInterval(intervalId);
        }
      } catch (error) {
        showAlert(error?.message ?? 'Unable to fetch sub section status.', 'error');
        clearInterval(intervalId);
      }
    }, 10000);
  };

  const renderUploadSelfieUI = () => {
    return (
      <UploadFileInput
        onDataCallback={handleDataFromChild}
        backgroundColorChange={true}
        backgroundColorBlur={props.type && ['edit', 'view'].includes(props.type) && !panRejectionStatus ? false : backgroundColorBlur}
        items={selfieItem}
        title=""
        showAlert={showAlert}
        isXML={false}
        setDocumentStateData={setDocumentStateData}
        loanAppId={loanAppId}
        sectionName={sectionName}
        data={{ company_id: companyID, product_id: productID }}
        MSMECompanyId={MSMECompanyId}
        MSMEProductId={MSMEProductId}
        isChange={props.type && props.type == 'edit' && !sectionStatus.includes(statusObject?.section_status)}
        type={props.type}
      />
    );
  };

  const renderUploadKycDocumentUI = (documentDetails, isXML) => {
    return (
      <div style={{ marginRight: '2rem' }}>
        <UploadFileInput
          backgroundColorChange={true}
          backgroundColorBlur={props.type && ['edit', 'view'].includes(props.type) && !panRejectionStatus ? false : backgroundColorBlur}
          title=""
          showAlert={showAlert}
          isXML={isXML}
          setDocumentStateData={setDocumentStateData}
          loanAppId={loanAppId}
          sectionName={sectionName}
          data={{ company_id: companyID, product_id: productID }}
          MSMECompanyId={MSMECompanyId}
          MSMEProductId={MSMEProductId}
          items={documentDetails}
          isChange={props.type && props.type == 'edit' && !sectionStatus.includes(statusObject?.section_status)}
          type={props.type}
        />
      </div>
    );
  };

  const saveLogs = (identifier, event_name) => {
    new Promise((resolve, reject) => {
      const payload = {
        user_id: user?._id,
        identifier: identifier,
        log_details: [
          {
            user_id: user?._id,
            event_name: event_name,
            timestamp: new Date(),
          },
        ],
      };
      if (payload?.user_id && identifier && event_name) {
        dispatch(postComprehensiveLogsWatcher(payload, resolve, reject));
      }
    });
  };

  return (
    <div className="applicant-details-container">
      <h4 className="applicant-details-header-style">Select Company</h4>
      <div>
        <div style={{ marginBottom: '1.3rem' }}>{renderFields('select_company')}</div>
        <div>{renderFields('select_partner')}</div>
      </div>
      <h4 className="applicant-details-header-style applicant-details-header-gap">Loan Requirement</h4>
      <div>{renderFields('Loan Requirement')}</div>
      <h4 className="applicant-details-header-style applicant-details-header-gap">Applicant Details</h4>
      <div>{renderFields('Applicant Details')}</div>
      <h4 className="applicant-details-sub-header-style applicant-details-sub-header-gap">Registered Address</h4>
      <div>{renderFields('Current Address')}</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h4 className="applicant-details-sub-header-style applicant-details-sub-header-gap">Permanent Address</h4>
        <>
          <input className="applicant-details-checkbox-style" type="checkbox" checked={!paViewPerAddress} onClick={handlePermanentAddress} disabled={panButtonState === 'icon' ? true : false} />
          <div className="applicant-details-checkbox-helper-text-style"> Same as Registered Address</div>
        </>
      </div>
      {<div>{renderFields('Permanent Address')}</div>}
      <h4 className="applicant-details-header-style applicant-details-header-gap">Applicant KYC</h4>
      <div>{renderFields('Applicant KYC 1')}</div>
      <h4 className="applicant-details-sub-header-style">KYC Documents</h4>
      {renderUploadSelfieUI()}
      <div className="applicant-details-kyc-document-container">
        <p className="applicant-details-kyc-header-style">Select PAN Document Type</p>
        <label className="applicant-details-radio-button-label-style" style={radioButtonLabelColorCss}>
          <input type="checkbox" value="Image & PDF" checked={selectedFileType['Image & PDF']} onChange={handleCheckboxButton} className="applicant-details-radio-button-style" style={radioInputColorCss} disabled={props.type == 'view'} />
          Image & PDF
        </label>
        <label className="applicant-details-radio-button-label-style" style={radioButtonLabelColorCss}>
          <input type="checkbox" value="Validation Doc" checked={selectedFileType['Validation Doc']} onChange={handleCheckboxButton} className="applicant-details-radio-button-style" style={radioInputColorCss} disabled={props.type == 'view' ? true : panButtonState === 'icon' ? false : true} />
          Response XML / JSON
        </label>
      </div>
      <div style={{ marginTop: '0px', display: 'flex' }}>
        {selectedFileType['Image & PDF'] ? renderUploadKycDocumentUI(panImageItem, false) : null}
        {selectedFileType['Validation Doc'] ? renderUploadKycDocumentUI(panXmlItem, true) : null}
      </div>
      <div className="applicant-details-kyc-document-container">
        <p className="applicant-details-kyc-header-style">Select Aadhaar Document Type</p>
        <label className="applicant-details-radio-button-label-style" style={radioButtonLabelColorCss}>
          <input type="checkbox" value="Image & PDF" checked={selectedAadhaarFileType['Image & PDF']} className="applicant-details-radio-button-style" style={radioInputColorCss} onChange={handleAadhaarRadioButton} disabled={props.type == 'view'} />
          Image & PDF
        </label>
        <label className="applicant-details-radio-button-label-style" style={radioButtonLabelColorCss}>
          <input type="checkbox" value="Validation Doc" checked={selectedAadhaarFileType['Validation Doc']} onChange={handleAadhaarRadioButton} className="applicant-details-radio-button-style" style={radioInputColorCss} disabled={props.type == 'view' ? true : panButtonState === 'icon' ? false : true} />
          Response XML / JSON
        </label>
      </div>
      <div style={{ marginTop: '0px', display: 'flex' }}>
        {selectedAadhaarFileType['Image & PDF'] ? renderUploadKycDocumentUI(aadharItem, false) : null}
        {selectedAadhaarFileType['Validation Doc'] ? renderUploadKycDocumentUI(aadharfrontXmlItem, true) : null}
      </div>
      {props.type == 'edit' || !applicantData || sectionStatusCheck === 'inProgress' ? <div className="book-loan-button-container book-loan-button-alignment-single-button">{getSubmitButton('Verify & Next', handleSubmit, sectionStatusCheck === 'inProgress' ? true : false, formComplete, {})}</div> : null}
      {alert ? <Alert severity={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
    </div>
  );
}
