import axios from 'axios';
import { BASE_URL } from '../../constants/apiUrls';

export function postLapApplicantDetailsApi(payload) {
  return axios.post(`${BASE_URL}lap/applicant-details`, payload);
}

export function patchLapDetailsApi(payload) {
  return axios.post(`${BASE_URL}lap/section-details`, payload);
}

export function patchLapSaveAsDraftDetailsApi(payload) {
  return axios.post(`${BASE_URL}lap/lead/save-draft`, payload);
}

export function putLapSaveDraftApi(payload) {
  return axios.post(`${BASE_URL}lap/save-draft`, payload);
}

export function getLapCompaniesApi(payload) {
  return axios.get(`${BASE_URL}lap-company/${payload}`, payload);
}

export function getLapCompaniesOnlyApi(payload) {
  return axios.get(`${BASE_URL}lap/company`);
}

export function getProductByCompanyIdApi(payload) {
  return axios.get(`${BASE_URL}${'get_products_by_company_id'}/${payload}`);
}

export function getLapProductByCompanyIdApi(payload) {
  return axios.get(`${BASE_URL}lap-company/${payload}`, payload);
}

export function getLapSubmissionStatusApi(payload) {
  return axios.get(`${BASE_URL}lap/lead/submission-status/${payload.loan_app_id}/code/${payload.codeId}/sequence/${payload.sequenceId}?company_id=${payload.company_id}&&product_id=${payload.product_id}&&userId=${payload.userId}`, payload);
}

export function patchLapDocDeleteApi(payload) {
  return axios.post(`${BASE_URL}lap/delete-docs`, payload);
}

export function getBicDataApi(payload) {
  return axios.post(`${BASE_URL}lap/get-BIC-data/${payload.loanAppId}`, payload);
}

export function subSectionDeleteApi(payload) {
  return axios.delete(`${BASE_URL}lap/lead/${payload.loan_app_id}/section/${payload.section_code}/subsection/${payload.sub_section_code}`, { data: payload });
}

export function postEsignRequestApi(payload) {
  return axios.post(`${BASE_URL}lap/create-esign-request`, payload);
}

export function updateLeadDeviationApi(payload) {
  return axios.put(`${BASE_URL}lap/update-lead-deviation`, payload);
}

export function ammendOfferApi(payload) {
  return axios.post(`${BASE_URL}lap/ammend-offer-api`, payload);
}
export function verifyAadhaarOtpApi(payload) {
  return axios.post(`${BASE_URL}lap/leads/aadhaarCheck`, payload);
}
