import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useEffect, useState } from 'react';
import { transactionHistoryListWatcher } from '../../actions/transactionHistory';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { AlertBox } from '../../components/AlertBox';
import { checkAccessTags } from '../../util/uam';
import { storedList } from '../../util/localstorage';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import Table from 'react-sdk/dist/components/Table';
import { downloadDataInXLSXFormat } from '../../util/helper';
import Button from 'react-sdk/dist/components/Button/Button.js';
import Img from './images/download-button.svg';
import imgH from './images/download-button-hover.svg';

const user = storedList('user');

export default function LOCDrawdown() {
  const dispatch = useDispatch();
  const { company_id, product_id, loan_id } = useParams();
  const [drawdownHistoryList, setDrawdownHistoryList] = useState({});
  const [responseData, setResponseData] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [sortBy, setSortBy] = useState('txn_date_sort');
  const [isAscSort, setIsAscSort] = useState(true);

  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  useEffect(() => {
    if (isTagged && checkAccessTags(['tag_transaction_ledger_read', 'tag_loan_queue_read_write'])) fetchDrawDownList();
    if (!isTagged) fetchDrawDownList();
  }, [page, sortBy, isAscSort]);

  const handleAlertClose = () => {
    setAlert(false);
    setAlertMessage('');
    setSeverity('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(event);
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const fetchDrawDownList = (limit = rowsPerPage, isExport = false) => {
    const payload = {
      loan_id: loan_id,
      company_id,
      product_id,
      page,
      limit: limit,
      sortBy,
      isAscSort,
    };
    return new Promise((resolve, reject) => {
      dispatch(transactionHistoryListWatcher(payload, resolve, reject));
    })
      .then((response) => {
        if (response.data?.count > 0) {
          if (!isExport) {
            setResponseData(response.data.rows);
            setDrawdownHistoryList(response.data.rows.slice(0, rowsPerPage));
            setCount(response.data.count);
          }
          return { res: true, data: response.data.rows };
        } else {
          showAlert('No drawdown records found for the loan id', 'error');
          return { res: false };
        }
      })
      .catch(() => {
        showAlert('No drawdown records found for the loan id', 'error');
        return { res: false };
      });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5e72e4',
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.common.black,
    },
  }));

  const columns = [
    {
      id: 'txn_date_sort',
      label: 'TXN DATE',
      format: (row) => moment(row?.txn_date ? row.txn_date : row.utr_date_time_stamp).format('YYYY-MM-DD'),
      isSorting: true,
    },
    {
      id: 'txn_amount',
      label: 'TXN AMOUNT',
      format: (row) => (row.txn_amount || row.txn_amount == 0 ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(Number(row.txn_amount).toFixed(2)) : 'NA'),
      isSorting: true,
    },
    { id: 'label', label: 'TXN TYPE', isSorting: true },
    {
      id: 'txn_id_sort',
      label: 'TXN ID',
      format: (row) => `${row.txn_entry != 'dr' ? (row.txn_reference ? row.txn_reference : '') : row.txn_id ? row.txn_id : ''}`,
    },
    {
      id: 'utr_number',
      label: 'UTR NUMBER',
      format: (row) => `${row.utr_number ? row.utr_number : ''}`,
    },
    {
      id: 'usage_id_sort',
      label: 'USAGE ID',
      format: (row) => `${row?.usage_id ?? row?._id}`,
      isSorting: true,
    },
    {
      id: 'is_received_sort',
      label: 'STATUS',
      format: (row) => `${row.txn_entry != 'dr' ? (row?.is_received === 'Y' ? 'Approved' : row?.is_received === 'N' ? 'Approval pending' : row?.is_received === 'hold' ? 'Hold' : 'Approval pending') : row.disbursement_status ? row.disbursement_status : ''}`,
      isSorting: true,
    },
    // No need for the "Action" column with the icon here
  ];

  const handleDownload = async (ext) => {
    let downloadData = [];
    const response = await fetchDrawDownList(count, true);
    if (response.res) {
      response.data.map((row) => {
        let obj = {};
        columns.map((column) => {
          obj = { ...obj, [column.label]: column.format ? column.format(row) : row[column.id] };
        });
        downloadData.push(obj);
      });
      const fileName = `Transactions_${loan_id}_${moment().format('DD_MMM_YYYY')}.${ext}`;
      downloadDataInXLSXFormat(fileName, downloadData);
      showAlert('File downloaded successfully', 'success');
    }
  };

  const handleSort = (column) => {
    setSortBy(column.id);
    setIsAscSort((isAscSort) => !isAscSort);
  };

  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onclose={handleAlertClose} /> : null}
      {/* <Grid display={"flex"} pt={2}>
        <Grid item xs={4}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Transaction ledger
          </Typography>
        </Grid>
      </Grid> */}
      <div>
        <div>
          {/* {drawdownHistoryList.length ? (
            <TableContainer
              sx={{
                mt: 1
              }}
              component={Paper}
            >
              <Table
                sx={{
                  minWidth: 700
                }}
                aria-label="customized table"
                id="pdf"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell> Transaction date </StyledTableCell>{" "}
                    <StyledTableCell> Transaction amount </StyledTableCell>{" "}
                    <StyledTableCell> Transaction type </StyledTableCell>{" "}
                    <StyledTableCell> Transaction id </StyledTableCell>{" "}
                    <StyledTableCell> UTR Number</StyledTableCell>{" "}
                    <StyledTableCell> Usage id </StyledTableCell>{" "}
                    <StyledTableCell> Status </StyledTableCell>{" "}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drawdownHistoryList
                    ? drawdownHistoryList.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell scope="row">
                            {moment(item?.txn_date).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell scope="row">
                            {`${Number(item.txn_amount).toFixed(2)}`}
                          </TableCell>
                          <TableCell scope="row">{item.label}</TableCell>
                          <TableCell scope="row">{`${
                            item.txn_entry != "dr"
                              ? item.txn_reference
                                ? item.txn_reference
                                : ""
                              : item.txn_id
                              ? item.txn_id
                              : ""
                          }`}</TableCell>
                          <TableCell scope="row">
                            {`${item.utr_number ? item.utr_number : ""}`}
                          </TableCell>
                          <TableCell scope="row">{`${item?.usage_id ?? item?._id}`}</TableCell>
                          <TableCell scope="row">{`${
                            item.txn_entry != "dr"
                              ? item?.is_received === "Y"
                                ? "Approved"
                                : item?.is_received === "N"
                                ? "Approval pending"
                                : item?.is_received === "hold"
                                ? "Hold"
                                : "Approval pending"
                              : item.disbursement_status
                              ? item.disbursement_status
                              : ""
                          }`}</TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
              {count ? (
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10]}
                />
              ) : null}
            </TableContainer>
          ) : null} */}

          {drawdownHistoryList.length ? (
            <div style={{ padding: '20px', maxWidth: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ color: 'rgba(20, 21, 25, 1)', fontFamily: 'Montserrat-SemiBold', fontSize: '16px', float: 'left', display: 'flex', alignItems: 'center' }}>Transaction History</div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', float: 'right' }}>
                  <Button buttonType="secondary" label="XLSX" customStyle={{ width: '109px', height: '40px', border: '1px solid #475BD8', color: '#475BD8', borderRadius: '26px', color: 'rgb(71, 91, 216)', fontSize: '12px', display: 'flex', justifyContent: 'center', boxShadow: 'none', backgroundColor: 'white' }} onClick={() => handleDownload('xlsx')} imageButton={Img} imageButtonHover={imgH} iconButton="btn-secondary-download-button" />
                  <Button label="CSV" buttonType="secondary" customStyle={{ width: '109px', height: '40px', marginLeft: '12px', border: '1px solid #475BD8', color: '#475BD8', borderRadius: '26px', color: 'rgb(71, 91, 216)', fontSize: '12px', display: 'flex', justifyContent: 'center', boxShadow: 'none', backgroundColor: 'white' }} onClick={() => handleDownload('csv')} imageButton={Img} imageButtonHover={imgH} iconButton="btn-secondary-download-button" />
                </div>
              </div>

              <Table
                customStyle={{ width: '100%' }}
                data={drawdownHistoryList}
                columns={columns}
                handleSort={handleSort}
                isAscending={isAscSort}
                sortBy={sortBy}
                // actions={{ handleActionClick }}
                // handleActionClick={handleActionClick}
              />
              <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={rowsPerPage} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
