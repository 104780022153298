import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import UploadFileInput from '../../lap/components/uploadFileInput/UploadFileInputV2';
import PasswordViewer from 'msme/components/PasswordViewer';
import { documentCode } from 'msme/config/docCode';
import { useEffect } from 'react';
import { storedList } from '../../util/localstorage';
import { useDispatch } from 'react-redux';
import '../../msme/views/financialDocument/FinancialDocument.css';
import { fetchBanksListWatcher } from 'actions/borrowerInfo';
import { getMsmeLoanDocumentsWatcher } from 'msme/actions/bookLoan.action';
import { patchMsmeDocDeleteWatcher } from 'msme/actions/msme.action';
import { viewCustomerDocsWatcher } from 'actions/customer';
import { uploadLoanDocumentsWatcher } from 'actions/loanDocuments';
import { AlertBox } from 'components/CustomAlertbox';
import { DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// styles - start
const bankStatementStyle = {
  marginTop: '10px',
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  columnGap: '10px',
  padding: '5px 3px',
};

const bankInputBoxStyle = {
  height: '58px',
  maxWidth: '45vw',
  width: '32.5%',
  flex: '1',
};

const headingStyle = {
  position: 'relative',
  top: '10px',
  fontWeight: '600',
  color: '#32325d',
};

const uploadTittleStyle = {
  position: 'relative',
  top: '10px',
  fontWeight: '600',
  color: '#000000',
  marginBottom: '14px',
};

const stateMentButton = {
  border: 'none',
  outLine: 'none',
  background: 'transparent',
  color: '#134CDE',
  padding: '10px 16px 10px 5px',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
};

const capitalizeText = (text) => {
  const result = text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return result;
};

// styles - end

const MultipleDocUploadPopup = (props) => {
  const { loanAppId, loanData, handleClose, doc } = props;
  const user = { _id: storedList('user')?._id, id: storedList('user')?.id };
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [BankDetailstate, setBankDetailstate] = useState({});
  const [BankStatementstate, setBankStatementstate] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const sectionName = 'financial-documents';
  const [disableFields, setDisableFields] = useState(false);
  const [BankList, setBankList] = useState([]);
  const [bankInputItems, setBankInputItems] = useState([
    {
      id: 'bs1',
      name: '6 Month Bank Statement',
      fileSize: 'PDF upto 50MB',
      acceptFileType: '.pdf',
      fileType: 'file',
      documentCode: documentCode?.msme_bank_statement,
      docIndex: 1,
    },
  ]);
  let fileInterval;
  const bankTypeOptions = [
    { id: 1, value: 'current', label: 'Current' },
    { id: 2, value: 'saving', label: 'Savings' },
  ];
  const documentCodes = {
    BS: [documentCode?.msme_bank_statement],
  };
  const [bankStatementCount, setBankStatementCount] = useState(0);
  const [docPassword, setDocPassword] = useState('');

  const fetchBanksListDetails = () => {
    new Promise((resolve, reject) => {
      dispatch(
        fetchBanksListWatcher(
          {
            user_id: user._id,
          },
          resolve,
          reject,
        ),
      );
    })
      .then((response) => {
        setBankList(response?.data);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  let documents;
  const [bankDocuments, setBankDocuments] = useState(
    bankInputItems.map((givenObj) => {
      const matchingObj = documents?.find((otherObj) => otherObj?.code === (givenObj?.documentCode ? givenObj?.documentCode : ''));
      if (matchingObj) {
        return {
          ...givenObj,
          s3_url: matchingObj?.file_url,
          doc: matchingObj,
        };
      }
      return givenObj;
    }),
  );

  const getLoanDocuments = (count = 0) => {
    setLoader(true);
    const payload = {
      loanAppID: loanAppId,
      companyId: loanData?.company_id,
      productId: loanData?.product_id,
      user: user,
      user_id: user?._id,
    };
    new Promise((resolve, reject) => {
      dispatch(getMsmeLoanDocumentsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        const filteredVal = response?.filter((item) => item?.code === '213');
        const matchingObj = Array.isArray(filteredVal) ? filteredVal[0] : {};
        const bank_name = BankList.filter((item) => item?.value === matchingObj?.bank_code);

        if (matchingObj?.last_bank_statement === 'Y') {
          setDisableFields(true);
        }
        setBankDetailstate((prev) => ({
          doc_key: matchingObj?.doc_key || '',
          borro_bank_type: matchingObj?.account_type || '',
          borro_bank_acc_num: matchingObj?.account_number || '',
          borro_bank_name: bank_name[0]?.label || '',
          borro_bank_code: matchingObj?.bank_code || '',
          all_uploaded_docs: matchingObj?.additional_file_url,
          doc_key: matchingObj?.doc_key,
        }));
        const bankInputItemsUpdate = [];
        const bankDocumentsUpdate = [];

        matchingObj?.additional_file_url?.map((item, index) => {
          if (item) {
            if (bankDocumentsUpdate.length) {
              bankInputItemsUpdate.push({
                id: `bs${bankInputItemsUpdate.length + 1}`,
                name: `${bankInputItemsUpdate.length ? `Add Statement` : '6 Month Bank Statement'}`,
                fileSize: 'PDF upto 50MB',
                acceptFileType: '.pdf',
                fileType: 'file',
                documentCode: documentCode?.msme_bank_statement,
                docIndex: bankDocumentsUpdate.length + 1,
              });
            }
            bankDocumentsUpdate.push({
              id: `bs${bankInputItemsUpdate.length + 1}`,
              name: `${bankInputItemsUpdate.length ? `Add Statement` : '6 Month Bank Statement'}`,
              fileSize: 'PDF upto 50MB',
              acceptFileType: '.pdf',
              fileType: 'file',
              documentCode: documentCode?.msme_bank_statement,
              docIndex: bankInputItemsUpdate.length + 1,
              s3_url: item,
              doc: matchingObj,
            });
          }
        });
        if (matchingObj) {
          setBankDocuments(bankDocumentsUpdate);
          setBankInputItems([...bankInputItems, ...bankInputItemsUpdate]);
          setBankStatementCount(matchingObj?.additional_file_url?.length);
        }
        if (matchingObj?.additional_file_url?.length === count && fileInterval) {
          clearInterval(fileInterval);
        }
        setLoader(false);
      })
      .catch((error) => {
        showAlert(error.response?.data?.message, 'error');
        setLoader(false);
        if (fileInterval) clearInterval(fileInterval);
      });
  };

  // function --> dropdown for bank name
  const handleDropdownBankNameChange = (event, data) => {
    setBankDetailstate((prevData) => ({
      ...prevData,
      borro_bank_name: event?.label ?? null,
      borro_bank_code: event?.value ?? null,
    }));
  };

  // function --> dropdown for account type change
  const handleDropdownAccTypeChange = (event, data) => {
    setBankDetailstate((prevData) => ({
      ...prevData,
      borro_bank_type: event?.label ?? null,
    }));
  };

  // function --> for capture acc no.
  const handleAccNoChange = (event) => {
    setBankDetailstate((prevData) => ({
      ...prevData,
      borro_bank_acc_num: event.value ?? null,
    }));
  };

  // function --> change in password
  const handlePasswordChange = (event) => {
    setBankDetailstate((prevData) => ({
      ...prevData,
      doc_key: event.value ?? null,
    }));
  };

  // function --> handle file upload
  const handleDataFromChild = (event, docs) => {
    if (!BankDetailstate?.borro_bank_name || !BankDetailstate?.borro_bank_type || !BankDetailstate?.borro_bank_acc_num) return;
    setLoader(true);
    const type = String(event);
    if (type.startsWith('bs')) {
      fileInterval = setInterval(() => {
        getLoanDocuments(bankStatementCount == docs.length ? bankStatementCount : bankStatementCount + 1);
      }, 3000);
    } else {
      clearInterval(fileInterval);
      getLoanDocuments();
    }
  };

  // Alert --> success or error
  const showAlert = (msg, type) => {
    const element = document.getElementById('TopNavBar');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  // function --> Add new entry in state
  const addBankStatement = () => {
    const newItemId = `bs${bankInputItems?.length + 1}`;
    const newItem = {
      id: newItemId,
      name: bankInputItems?.length === 0 ? '6 Month Bank Statement' : 'Add Statement',
      fileSize: 'PDF up to 10MB',
      acceptFileType: '.pdf',
      fileType: 'file',
      documentCode: documentCode?.msme_bank_statement,
      docIndex: bankDocuments?.length + 1,
    };

    if (bankDocuments.length < 12) {
      setBankInputItems([...bankInputItems, newItem]);
      setBankDocuments([...bankDocuments, newItem]);
    } else {
      setAlert({
        open: true,
        severity: 'warning',
        alertMessage: 'You have reached the maximum number of bank statements',
      });
    }
  };

  // function --> close alert
  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  // function -->  delete uploaded bank statement
  const deleteBankStatement = (id) => {
    const payload = {
      loanAppId: loanAppId,
      tokenData: {
        user: user,
        user_id: user?._id,
        product_id: loanData?.product_id,
        company_id: loanData?.company_id,
      },
      code: documentCodes.BS,
      codeIndex: id?.split('bs')?.reverse()?.[0],
    };
    if (payload?.code && payload?.codeIndex) {
      new Promise((resolve, reject) => {
        dispatch(patchMsmeDocDeleteWatcher(payload, resolve, reject));
      })
        .then((response) => {
          showAlert(response?.message || 'Deleted successfully', 'success');
          getLoanDocuments();
        })
        .catch((error) => {
          getLoanDocuments();
        });
    }
  };

  // function --> remove bank statement from local state
  const removeBankStatement = (id) => {
    const updatedItems = bankInputItems.filter((item) => item.id !== id);
    const updatedBankItems = bankDocuments.filter((item) => item.id !== id);
    setBankInputItems(updatedItems);
    setBankDocuments(updatedBankItems);
    deleteBankStatement(id);
  };

  // function --> view for uploaded document
  const handleViewDoc = async (awsurl, doctype) => {
    try {
      let data = {};
      data.awsurl = awsurl;
      data.user_id = user?._id;
      const response = await new Promise((resolve, reject) => {
        dispatch(
          viewCustomerDocsWatcher(
            data,
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            },
          ),
        );
      });
      return response;
    } catch (error) {
      setAlert('Something went wrong', 'error');
      throw error;
    }
  };

  // function --> final document submission with "Y" flag
  const finalSubmit = async () => {
    setLoader(true);
    const allDocs = BankDetailstate?.all_uploaded_docs;
    const last_file_url = allDocs[allDocs?.length - 1];
    const response = await handleViewDoc(last_file_url, 'pdf');
    const dataForUpload = {
      submitData: {
        base64pdfencodedfile: response,
        fileType: 'msme_bank_statement',
        code: '213',
        loan_app_id: loanAppId,
        entity_type: 'Individual',
        bank_name: BankDetailstate?.borro_bank_name,
        account_type: BankDetailstate?.borro_bank_type,
        account_number: BankDetailstate?.borro_bank_acc_num,
        last_bank_statement: 'Y',
        doc_index: allDocs?.length,
      },
      userData: {
        company_id: loanData?.company_id,
        product_id: loanData?.product_id,
        user_id: user?._id,
      },
    };
    try {
      await dispatch(
        uploadLoanDocumentsWatcher(
          dataForUpload,
          (response) => {
            showAlert('Document submitted successfully', 'success');
            setLoader(false);
            setTimeout(handleClose, 1000);
          },
          (error) => {
            throw Error(error);
          },
        ),
      );
    } catch (error) {
      showAlert(error?.response?.data?.message || 'Something went wrong', 'error');
      setLoader(false);
    }
  };

  // componentDidMount --> initial API calls
  useEffect(() => {
    fetchBanksListDetails();
    getLoanDocuments();
  }, []);

  // componentDidUpdate --> set bank name in local state
  useEffect(() => {
    const bank_name = BankList.filter((item) => item?.value === BankDetailstate?.borro_bank_code)[0]?.label;
    setBankDetailstate((prev) => ({ ...prev, borro_bank_name: bank_name }));
  }, [BankList]);

  return (
    <div style={{ padding: '24px', width: 'auto', fontFamily: 'Montserrat-Regular' }}>
      <DialogTitle sx={{ p: 0, fontSize: '24px', fontWeight: '700', display: 'flex', justifyContent: 'space-between', fontFamily: 'Montserrat-Regular' }}>
        {`Upload ${capitalizeText(doc.name)}`}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: '#000000',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div>
          <h3 style={uploadTittleStyle}>Enter Bank Account Details</h3>
          <div style={bankStatementStyle}>
            <InputBox isRequired={true} label="Bank Name" isDrawdown={disableFields ? false : true} customClass={bankInputBoxStyle} initialValue={(BankList.find((item) => item?.value === BankDetailstate?.borro_bank_code) || {}).label || BankDetailstate?.borro_bank_name || ''} isDisabled={disableFields ? true : false} customDropdownClass={{ marginTop: '7px', zIndex: 5 }} options={BankList} onClick={(value) => handleDropdownBankNameChange(value)} />
            <InputBox isRequired={true} label="Bank A/C No." type={'number'} isDrawdown={false} customClass={bankInputBoxStyle} customDropdownClass={{ marginTop: '7px', zIndex: 5 }} initialValue={BankDetailstate?.borro_bank_acc_num} isDisabled={disableFields ? true : false} onClick={(e) => handleAccNoChange(e)} />
            <InputBox isRequired={true} label="Bank A/C Type" isDrawdown={disableFields ? false : true} customClass={bankInputBoxStyle} initialValue={bankTypeOptions.filter((i) => i.value === BankDetailstate?.borro_bank_type)[0]?.label || BankDetailstate?.borro_bank_type} isDisabled={disableFields ? true : false} customDropdownClass={{ marginTop: '7px', zIndex: 5 }} options={bankTypeOptions} onClick={(value) => handleDropdownAccTypeChange(value)} />
          </div>
        </div>
        <div>
          <h3 style={uploadTittleStyle}>Upload Bank Statement</h3>
          <UploadFileInput
            customParentStyle={{ display: 'flex', flexDirection: 'column', width: '32.5%' }}
            customContainerStyle={{ gap: '10px' }}
            customMaxWidth={'100%'}
            setState={setBankStatementstate}
            removeItem={removeBankStatement}
            onDataCallback={(event) => handleDataFromChild(event, bankDocuments)}
            items={bankDocuments}
            loanAppId={loanData?.loan_app_id}
            MSMECompanyId={loanData?.company_id}
            MSMEProductId={loanData?.product_id}
            sectionName={sectionName}
            hideRemove={disableFields ? true : false}
            isChange={!disableFields}
            data={{ company_id: loanData?.company_id, product_id: loanData?.product_id, bank_name: BankDetailstate?.borro_bank_name, account_type: BankDetailstate?.borro_bank_type, account_number: BankDetailstate?.borro_bank_acc_num, method: 'POST' }}
            showAlert={showAlert}
            LAPCompanyId={loanData?.company_id}
            LAPProductId={loanData?.product_id}
          />
        </div>
      </div>
      {BankDetailstate?.all_uploaded_docs?.length > 0 && bankDocuments?.length < 12 && !loader && (
        <button onClick={!loader && addBankStatement} style={stateMentButton} disabled={disableFields || loader ? true : false}>
          Add more bank statement
        </button>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <PasswordViewer docPassword={docPassword} />
        <div>
          <h3 style={uploadTittleStyle}>If the files are password-protected, please provide the password.</h3>
          <InputBox label="Enter File Password" isDrawdown={false} customClass={bankInputBoxStyle} initialValue={BankDetailstate.doc_key} customDropdownClass={{ marginTop: '7px', zIndex: 5 }} onClick={(e) => handlePasswordChange(e)} isDisabled={disableFields ? true : false} />
        </div>
        <div style={{ fontSize: '12px' }}>Note - ScoreMe Analyzer will be called on Submit, make sure to upload the correct file. This can not be changed at later stage.</div>
      </div>
      <div style={{ width: '100%', justifyContent: 'flex-end', display: 'flex', padding: '25px 0 0 0', gap: '10px' }}>
        <button
          style={{
            width: 'auto',
            color: '#134CDE',
            border: 'none',
            width: '172px',
            height: '48px',
            border: '1px solid #134CDE',
            borderRadius: '8px',
            background: '#ffffff',
          }}
          className="search-button"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="search-button"
          onClick={finalSubmit}
          disabled={!!(disableFields || loader)}
          style={{
            width: 'auto',
            color: '#ffffff',
            border: 'none',
            width: '172px',
            height: '48px',
            opacity: disableFields || loader ? '0.6' : '1',
          }}
        >
          {'Submit'}
        </button>
      </div>
    </div>
  );
};

export default MultipleDocUploadPopup;
