import React, { memo, useEffect } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const DataUploadProgressBarUI = (props) => {
  const { totalCount, successCount, failureCount, pendingCount, dataUploadColorValue } = props;
  return (
    <React.Fragment>
      <div className="ar-loan-data-upload-progress-bar-status">{totalCount === failureCount ? <p>Failed</p> : totalCount === pendingCount ? <p>In-Progress</p> : totalCount === successCount ? <p>Completed</p> : <p>Partially Completed</p>}</div>
      <Box className={dataUploadColorValue ? `ar-loan-data-upload-progress-bar ar-loan-data-upload-progress-bar-${dataUploadColorValue}` : ''} sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={totalCount === failureCount ? (failureCount / totalCount) * 100 : totalCount === pendingCount ? (pendingCount / totalCount) * 100 : (successCount / totalCount) * 100} />
      </Box>
      <div className="ar-loan-data-upload-progress-bar-count">
        {totalCount === failureCount ? (
          <div>
            {totalCount}/{failureCount}
            <br></br>Unsent
          </div>
        ) : totalCount === pendingCount ? (
          <div>
            {totalCount}/{pendingCount}
            <br></br>
          </div>
        ) : totalCount === successCount ? (
          <div>
            {totalCount}/{successCount}
            <br></br>Sent
          </div>
        ) : (
          <React.Fragment>
            <div>
              {totalCount}
              <br></br>Sent
            </div>
            <div>
              {pendingCount}
              <br></br>Pending
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(DataUploadProgressBarUI);
