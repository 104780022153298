import React, { useEffect, useState } from 'react';
import SelectCompany from '../../components/Company/SelectCompany';
import LeadLoanLineImage from '../lending/images/newleadloanscreen.svg';
import { useDispatch } from 'react-redux';
//import LeadLoanLineImage from "./images/newleadloanscreen.svg";
import searchIcon from '../../msme/images/searchIcon.svg';
import Table from 'react-sdk/dist/components/Table/Table';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import { colendersListWatcher, toggleStatusWatcher } from '../../actions/colenders.js';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import UploadCard from 'react-sdk/dist/components/UploadCard';

export default function DaManagement({ width, isDisabled, customStyle, height, maxWidth, erroR, isDisbursed }) {
  const [company, setCompany] = useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [colendersList, setColendersList] = useState([]);
  const dispatch = useDispatch();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  // const { data, onModalClose, } = props;
  const [onModalClose, setonModalClose] = useState(false);
  const [nbfcdropdown, setNbfcDropdown] = useState("");
  const [fullArr, setFullArr] = useState([]);
  const [alert, setAlert] = useState(false);
  const [open, setOpen] = useState(false); // State to handle FormPopup
  const [selectedDa, setSelectedDa] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('')
  const [isViewingDocument, setIsViewingDocument] = useState(false); // State to check if viewing document

  const [nbfcData, setNbfcData] = useState([
    { da_no: 'DA001', da_date: '2024-01-01', da_amount: '100000', total_loans: 10, documents: 'Document1' },
    { da_no: 'DA002', da_date: '2024-01-02', da_amount: '200000', total_loans: 20, documents: 'Document2' },
    { da_no: 'DA003', da_date: '2024-01-03', da_amount: '300000', total_loans: 30, documents: 'Document3' },
    { da_no: 'DA004', da_date: '2024-01-04', da_amount: '400000', total_loans: 40, documents: 'Document4' },
    { da_no: 'DA005', da_date: '2024-01-05', da_amount: '500000', total_loans: 50, documents: 'Document5' },
  ]);
  const [daData, setDaData] = useState('');

  const styles = useStyles({ innerWidth, innerHeight });

  const imageStyle = {
    marginTop: '5vh',
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
  };
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '53vh',
    backgroundColor: '#F5F7FF',
    borderRadius: '35px',
    marginLeft: '15%',
    marginRight: '25%',
    marginTop: '80px',
  };
  const inputBoxCss = {
    marginTop: '8px',
    width: '290px',
    maxHeight: 'none',
    minHeight: '330px',
    zIndex: 1,
  };
  const searchButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginLeft: '16px',
  };
  const loandata = [
    { id: 'case_id', label: 'CASE ID' },
    { id: 'partner_loan_id', label: 'PARTNER LOAN ID' },
    { id: 'fullName', label: 'CUSTOMER NAME' },
    {
      id: 'amount',
      label: 'LOAN AMOUNT',
      // format: row =>
      //   product?.isLoc
      //     ? new Intl.NumberFormat("en-IN", {
      //         style: "currency",
      //         currency: "INR"
      //       }).format(row.limit_amount) ?? ""
      //     : new Intl.NumberFormat("en-IN", {
      //         style: "currency",
      //         currency: "INR"
      //       }).format(row.sanction_amount)
    },
    { id: 'created_at', label: 'APPLICATION DATE' },

    { id: 'REPAYMENT STATUS', label: 'REPAYMENT STATUS' },
    {
      id: 'status',
      label: 'STATUS',
      // format: row =>
      //   row.status === "manual" ? (
      //     <>
      //       <Tooltip
      //         title="Doc is Incomplete. Please click to add doc details"
      //         placement="top"
      //         arrow
      //       >
      //         <Link onClick={() => handleKycIncomplete(row)}>
      //           {statusToDisplay[row.status]}
      //         </Link>
      //       </Tooltip>
      //     </>
      //   ) : row.status === "validation_error" ? (
      //     <>
      //       {statusToDisplay[row.status]}
      //       <div>
      //         <HtmlTooltip
      //           title={
      //             <div>
      //               {row?.validations ? row.validations[0].remarks : ""}
      //             </div>
      //           }
      //         >
      //           <img src={InfoIcons} alt="hello" />
      //         </HtmlTooltip>
      //       </div>
      //     </>
      //   ) : row.status === "rejected" ? (
      //     <>
      //       {statusToDisplay[row.status]}
      //       <div>
      //         <HtmlTooltip
      //           title={
      //             <div>
      //               <div>
      //                 <span style={{ fontFamily: "Montserrat-SemiBold" }}>
      //                   Rejection reason:{" "}
      //                 </span>
      //                 {row?.reason ? getReason(row.reason):""}
      //               </div>
      //               <div style={{ marginTop: "10px" }}>
      //                 {" "}
      //                 <span style={{ fontFamily: "Montserrat-SemiBold" }}>
      //                   Rejection remark:{" "}
      //                 </span>{" "}
      //                 {row?.reason ? row.remarks : ""}
      //               </div>
      //               <div style={{ marginTop: "10px", color: "gray" }}>
      //                 {row?.reason ? row.rejected_by : ""}
      //               </div>
      //               <div style={{ color: "gray" }}>
      //                 {row?.reason
      //                   ? moment(row.rejection_date_time).format(
      //                       "DD-MM-YYYY, h:mm a"
      //                     )
      //                   : ""}
      //               </div>
      //             </div>
      //           }
      //         >
      //           <img src={InfoIcons} alt="hello" />
      //         </HtmlTooltip>
      //       </div>
      //     </>
      //   ) : (
      //     statusToDisplay[row.status]
      //   )
    },
  ];
  const columns = [
    {
      id: 'da_no',
      label: <div className="customer-label-style">DA NO.</div>,
      format: (rowData) => {
        return (
            <div className="customer-data-style" onClick={() => handleDaNoClick(rowData)}>
            <a href="#">{rowData.da_no}</a>
        </div>
        );
      },
    },

    {
      id: 'da_date',
      label: 'DA DATE',
      //format: (rowData) => `${rowData.customer_name}`,
    },
    {
      id: 'da_amount',
      label: 'DA AMOUNT',
      // format: (rowData) => rowData?.no_of_loans,
    },
    {
      id: 'total_loans',
      label: 'TOTAL LOANS',
      //format: (rowData) => rowData?.no_of_lines,
    },
    {
      id: 'documents',
      label: 'DOCUMENTS',
      format: (rowData) => {
        return (
            <div className="customer-data-style" onClick={() => handleViewDocumentClick(rowData)}>
                <a href="#">{rowData.documents}</a>
            </div>
        );
    },    },
  ];
  const handleDaNoClick = (rowData) => {
    setSelectedDa(rowData);
    setOpen(true);
    setIsViewingDocument(false); // Reset isViewingDocument


  };
  const handleViewDocumentClick = () => {
    setIsViewingDocument(true);
    setOpen(true);
};
  //   const fetchColendersList = () => {
  //     const payload = {};
  //     new Promise((resolve, reject) => {
  //       dispatch(colendersListWatcher(payload, resolve, reject));
  //     })
  //       .then((response) => {
  //         setColendersList(response.filter(filterData));
  //         setFullArr(response);

  //       })
  //       .catch((error) => {
  //         // setAlert(true);
  //         // setSeverity('error');
  //         // setAlertMessage(error.response.data.message);
  //         // setTimeout(() => {
  //         //   handleAlertClose();
  //         // }, 4000);
  //       });
  //   };
  //   React.useEffect(() => {
  //      fetchColendersList();
  //     // fetchColendersList();
  //   }, []);
  const fetchColendersList = () => {
    const payload = {};
    new Promise((resolve, reject) => {
      dispatch(colendersListWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setColendersList(response);
        setFullArr(response);

        // Map the colenders list to the format expected by InputBox
        const options = response.map((item) => ({
          label: item.co_lender_name,
          value: item.co_lender_name,
        }));
        setDropdownOptions(options);
      })
      .catch((error) => {
        // Handle error
      });
  };

  useEffect(() => {
    fetchColendersList();
  }, []);
  const customCss = { height: '58px', width: !isDisbursed ? '15vw' : '15vw' };
  const handleClose = () => {
    setOpen(false);
    setonModalClose(true);
  };
  const uploadCardHeadings = [
  'DA agreement',
  'Power of attorney',
  'Undertaking',
  'KYC details of signing authority',
  'Stamp duty paid on transfer',
  'PAN',
  'Aadhar front',
  'Aadhar back',
];

const renderUploadCards = () => {
  return uploadCardHeadings.map((heading, index) => (
    <UploadCard key={index} heading={heading} style={{ margin: '1%', width:"100%" }} />

  ));
};

  return (
    <>
      <div style={{ margin: '0px 24px 24px 24px' }}>
        {/* {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null} */}
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', marginBottom: '20px', justifyContent: 'space-between' }}>
          <div style={{ width: '400px', display: 'flex', alignItems: 'center',marginLeft: '20px' }}>
            <InputBox
              id={'nbfcData'}
              isDrawdown={true}
              // initialValue={company?.label ? company.label : ''}
              placeholder={'Select NBFC'}
              label={'Select NBFC'}
              options={dropdownOptions}
              //error={error}
              //helperText={helperText}
               onClick={(e)=>setNbfcDropdown(e.value)}
              isDisabled={disabled || isDisabled}
              customClass={height || width ? { height: height, width: width, maxWidth: maxWidth, pointerEvents: user?.type && user.type === 'nbfcData' ? 'none' : 'auto' } : customCss}
              customDropdownClass={customStyle ? customStyle : { marginTop: '8px', zIndex: '1', width: !isDisbursed ? '15vw' : '17vw' }}
            />
            <div
              className="search-button"
              style={searchButtonStyle}
              // onClick={() => {
              //   handleSearchClick({
              //     company,
              //     product,
              //     fromDate,
              //     toDate,
              //     status,
              //     searchText,
              //     flag: 'globalSearch',
              //   });
              // }}
            >
              <img src={searchIcon} alt="search" style={{ height: '24px', width: '48px' }} />
            </div>
          </div>
        </div>
        
        <Table customStyle={styles.customTableClass} data={nbfcData} columns={columns} />
        {/* {count ? <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={rowsPerPage} /> : null} */}
      
      {open && (
        <FormPopup onClose={handleClose} heading={"Total loan of"} isOpen={open} customStyles={{ width: '75%', overflowX: 'hidden',
        ...(isViewingDocument
          ? {} // No additional styles when viewing documents
          : {
            width: '85%', 
            marginLeft: '62px',
            marginTop: '-217px'
            }
        ), }}>
        {isViewingDocument ? (
                     <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '100%' }}>
                     {renderUploadCards()}
                   </div>
                   ) : (
                       <div>
                           <div style={{ width: '100%', display: 'grid'}}>
                               <Table customStyle={{ width: '100%' }} 
                               data={[selectedDa]} 
                               columns={loandata} />
                           </div>
                       </div>
                   )}
               </FormPopup>
            )}
       </div>
    </>
  );
}

const useStyles = () => {
  return {
    customButtonClass: {
      fontSize: '16px',
      fontFamily: 'Montserrat-SemiBold',
      borderRadius: '8px',
      width: '145px',
      height: '56px',
    },
    customClass: {
      height: '56px',
      width: '20vw',
      maxWidth: '100%',
      paddingTop: '0.4%',
      marginLeft: '2%',
      fontSize: '0.8vw',
      marginRight: '2%',
    },
    customTableClass: {
      width: '95%',
      marginLeft: '2%',
      display: 'grid',
      gridTemplateColumns: `18% 18% 26% 21% 12%`,
      overflowX: 'hidden',
    },
    customInputClass: {
      width: '100%',
      maxWidth: '100%',
      paddingTop: '0.3%',
      paddingBottom: '0.1%',
      fontSize: '1vw',
      fontFamily: 'Montserrat-Regular',
      marginLeft: '2%',
    },
    
      centeredPopup : {
        position: 'fixed !important',
        top: 50% '!important',
        left: 50% '!important',
        transform: 'translate(-50%, -50%) !important',
      }
    
  };
};
