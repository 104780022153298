import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function locDrawdownGetLineDetailsApi(payload) {
  return axios.post(`${BASE_URL}get-loc-drawdown-line-details`, payload);
}

export function locDrawdownCloseLineApi(payload) {
  return axios.post(`${BASE_URL}loc-drawdown-close-line`, payload);
}

export function locDrawdownDownloadNOCApi(payload) {
  return axios.post(`${BASE_URL}loc-drawdown-download-noc`, payload);
}
