import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup } from '@mui/material';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button';
import Table from 'react-sdk/dist/components/Table/Table';
import { storedList } from '../../util/localstorage';
import Pagination from 'react-sdk/dist/components/Pagination/Pagination';
import { useDispatch } from 'react-redux';
import { AlertBox } from 'components/CustomAlertbox';
import { checkAccessTags } from '../../../src/util/uam';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';
import { getRestructureLoanWatcher, postRestructureLoanWatcher, getRestructureLoanTableWatcher, postLoanSimulatorWatcher } from '../../actions/restructureLoan';
import { useParams } from 'react-router-dom';
import moment from 'moment';
const user = storedList('user');

function formatDate2(dateString) {
  const timestamp = moment(dateString).valueOf();
  return moment.unix(timestamp / 1000).format('MM-DD-YYYY • hh:mm A');
}
export default function RestructureLoan(prop) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [restructureData, setRestructureData] = useState({});
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [restructureList, setRestructureList] = useState([]);
  const [popupResponse, setPopupResponse] = useState({});
  const [simulator, setSimulator] = useState({});
  const [instalmentCountChecked, setInstalmentCountChecked] = useState(false);
  const [interestRateChecked, setInterestRateChecked] = useState(false);
  const [instalmentAmountChecked, setInstalmentAmountChecked] = useState(false);
  const [instalmentCountInput, setInstalmentCountInput] = useState('');
  const [intrestRateInput, setIntrestRateInput] = useState('');
  const [instalmentAmountInput, setInstalmentAmountInput] = useState('');
  const [instalmentCountChange, setInstalmentCountChange] = useState('');
  const [intrestRateChange, setIntrestRateChange] = useState('');
  const [instalmentAmountChange, setInstalmentAmountChange] = useState('');
  const [applyBtn, setApplyBtn] = useState(false);
  const [isPreEmiStage, setIsPreEmiStage] = useState(false);
  const { loan_id, company_id, product_id } = useParams();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event) => {
    setPage(event + 1);
  };
  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };
  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };
  const isTagged = process.env.REACT_APP_BUILD_VERSION > 1 ? user?.access_metrix_tags?.length : false;

  const handleInstalmentCountChange = () => {
    setInstalmentCountChecked(!instalmentCountChecked);
    setInstalmentAmountChecked(false);
    if (!isPreEmiStage) {
      setInstalmentCountChecked(!instalmentCountChecked);
      setInstalmentAmountChecked(false);
      if (instalmentCountChecked) {
        setInstalmentCountInput(restructureData?.emiCount === '' || !restructureData?.emiCount ? 'NA' : restructureData?.emiCount.split('/')[0]);
      }
    }
  };

  const handleInterestRateChange = () => {
    if (instalmentCountChecked) {
      setInstalmentAmountChecked(false);
      setInterestRateChecked(!interestRateChecked);
    } else {
      setInstalmentAmountChecked(false);
      setInterestRateChecked(!interestRateChecked);
      setInstalmentCountChecked(false);
    }
    if (!interestRateChecked) {
      setIntrestRateInput(restructureData?.int_rate === '' || !restructureData?.int_rate ? 'NA' : restructureData?.int_rate);
    }
    if (isPreEmiStage) {
      setInterestRateChecked(!interestRateChecked);
    }
  };

  const handleInstalmentAmountChange = () => {
    if (interestRateChecked) {
      setInstalmentAmountChecked(!instalmentAmountChecked);
      setInstalmentCountChecked(false);
    } else {
      setInstalmentAmountChecked(!instalmentAmountChecked);
      setInterestRateChecked(!interestRateChecked);
      setInstalmentCountChecked(false);
    }

    if (!isPreEmiStage) {
      setInstalmentAmountChecked(!instalmentAmountChecked);
      setInstalmentCountChecked(false);
      if (!instalmentAmountChecked) {
        setInstalmentAmountInput(restructureData?.installmentamount === '' || !restructureData?.installmentamount ? 'NA' : restructureData?.installmentamount);
      }
    }
  };

  const customStyles = {
    position: 'fixed',
    left: 'auto',
    right: '-260px',
    height: '98%',
    overflowX: 'hidden',
  };
  const restructureLoanDetails = async () => {
    const payload = {
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
      user_id: user._id,
    };
    new Promise((resolve, reject) => {
      dispatch(getRestructureLoanWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setRestructureData(response);
        setInstalmentCountInput(response.emiCount.split('/')[0]);
        setIntrestRateInput(response?.int_rate === '' || !response?.int_rate ? 'NA' : response?.int_rate);
        setInstalmentAmountInput(response?.installmentamount === '' || !response?.installmentamount ? 'NA' : response?.installmentamount);
        setIsPreEmiStage(response?.is_pre_emi_stage);
      })
      .catch((error) => {
        return showAlert(error.response.data.message, 'error');
      });
  };
  useEffect(() => {
    restructureLoanDetails();
  }, []);

  const getRestructureDetails = () => {
    const payload = {
      loan_id: loan_id,
      page: page,
      limit: 10,
    };
    new Promise((resolve, reject) => {
      dispatch(getRestructureLoanTableWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setCount(response?.data?.count);
        const tableData = response?.data?.rows.map((i) => {
          return {
            ...i,
            restructure_date_time: i.restructure_date_time ? formatDate2(i.restructure_date_time) : 'NA',
            new_installment_amt: i.new_installment_amt ? parseFloat(i.new_installment_amt).toFixed(2) : 'NA',
          };
        });
        setRestructureList(tableData);
      })
      .catch((error) => {
        return showAlert('No Data available', 'error');
      });
  };

  const submitRestructureData = () => {
    const data = {
      loan_id: loan_id,
      company_id: company_id,
      product_id: product_id,
      user_id: user._id,
      new: {
        interest_rate: intrestRateChange,
        installment_count: Math.round(instalmentCountChange),
        installment_amount: instalmentAmountChange,
      },
      old: {
        interest_rate: restructureData.int_rate,
        installment_count: restructureData.emiCount.split('/')[0],
        installment_amount: restructureData.installmentamount,
      },
    };

    new Promise((resolve, reject) => {
      dispatch(postRestructureLoanWatcher(data, resolve, reject));
    })
      .then((response) => {
        setPopupResponse(response?.data);
        return showAlert(response?.message || 'Loan is restructured successfully', 'success');
      })
      .catch((error) => {
        return showAlert('Error while loan simulation', 'error');
      });
  };
  useEffect(() => {
    restructureLoanDetails();
    getRestructureDetails();
  }, [page, popupResponse]);

  const loanSimulatorData = () => {
    const newData = {};
    if (instalmentCountChecked) {
      newData['installment_count'] = instalmentCountInput;
    }
    if (interestRateChecked) {
      newData['interest_rate'] = intrestRateInput;
    }
    if (instalmentAmountChecked) {
      newData['installment_amount'] = instalmentAmountInput;
    }
    const data = {
      loan_id: loan_id,
      unbilled_principal: restructureData.unbilled_principal,
      company_id: company_id,
      product_id: product_id,
      user_id: user._id,
      new: newData,

      old: {
        interest_rate: restructureData.int_rate,
        installment_count: restructureData.emiCount.split('/')[0],
        installment_amount: restructureData.installmentamount.toString(),
      },
    };
    dispatch(
      postLoanSimulatorWatcher(
        data,
        (response) => {
          setSimulator(response?.data);
          setInstalmentCountChange(response.data.installment_count);
          setIntrestRateChange(response.data.interest_rate);
          setInstalmentAmountChange(response.data.installment_amount);
          setInstalmentAmountInput(response.data.installment_amount);
          setIntrestRateInput(response.data.interest_rate);
          setInstalmentCountInput(response.data.installment_count);
          setApplyBtn(true);
        },
        (err) => {
          return showAlert('Loan restructuring is not allowed on a due date, please try again later', 'error');
        },
      ),
    );
  };

  const tableStyle = {
    width: '99%',
    height: 'auto',
    overflowX: 'hidden',
    marginLeft: '10px',
    color: '#E5E5E8',
    display: 'grid',
    gridTemplateColumns: '12% 12% 10% 14% 12% 19% 15%',
  };
  const columns = [
    { id: 'pre_int_rate', label: 'PRE INT RATE' },
    { id: 'pre_emi_count', label: 'PRE EMI COUNT' },
    { id: 'pre_inst_amt', label: 'PRE INTR AMT' },
    { id: 'new_interest_rate', label: 'NEW INTREST RATE' },
    { id: 'new_emi_count', label: 'NEW EMI COUNT' },
    { id: 'new_installment_amt', label: 'NEW INSTALMENT AMOUNT' },
    { id: 'restructure_date_time', label: 'RESTRUCTURE DATE & TIME' },
  ];

  const resetInputValues = () => {
    setInstalmentCountInput(restructureData?.emiCount === '' || !restructureData?.emiCount ? 'NA' : restructureData?.emiCount.split('/')[0]);
    setIntrestRateInput(restructureData?.int_rate === '' || !restructureData?.int_rate ? 'NA' : restructureData?.int_rate);
    setInstalmentAmountInput(restructureData?.installmentamount === '' || !restructureData?.installmentamount ? 'NA' : restructureData?.installmentamount);
    if (applyBtn) {
      setApplyBtn(false);
    }
  };
  const handleCalculateClick = () => {
    loanSimulatorData();
    setApplyBtn(true);
  };

  const handleApplyClick = () => {
    handleOpen();
  };
  return (
    <>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      {open ? (
        <FormPopup onClose={handleClose} heading="Restructure Confirmation" isOpen={handleOpen} customStyles={customStyles}>
          <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{loan_id}</div>
          <div style={{ marginTop: '2em', marginBottom: '2.5em' }}>Do you confirm that you want you want to make the below changes?</div>
          <h4 style={{ fontSize: '20px', lineHeight: '150%', fontFamily: 'Montserrat-SemiBold', color: '#141519', marginBottom: '1.5em' }}>Current Values</h4>
          <div style={{ marginBottom: '2.5em', display: 'flex', flexWrap: 'wrap', rowGap: '1em' }}>
            <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px', width: '50%' }}>
              INSTALMENT COUNT
              <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{restructureData?.emiCount === '' || !restructureData?.emiCount ? 'NA' : restructureData?.emiCount}</div>
            </div>
            <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px', width: '50%' }}>
              INTREST RATE
              <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{restructureData?.int_rate === '' || !restructureData?.int_rate ? 'NA' : restructureData?.int_rate}</div>
            </div>

            <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px', width: '50%' }}>
              INSTALMENT AMOUNT
              <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{restructureData?.installmentamount === '' || !restructureData?.installmentamount ? 'NA' : restructureData?.installmentamount}</div>
            </div>
          </div>

          <h4 style={{ fontSize: '20px', lineHeight: '150%', fontFamily: 'Montserrat-SemiBold', color: '#141519', marginBottom: '1.5em' }}>New Values</h4>
          <div style={{ marginBottom: '2.5em', display: 'flex', flexWrap: 'wrap', rowGap: '1em' }}>
            <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px', width: '50%' }}>
              INSTALMENT COUNT
              <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{instalmentCountChange === '' || !instalmentCountChange ? 'NA' : Math.round(instalmentCountChange)}</div>
            </div>
            <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px', width: '50%' }}>
              INTREST RATE
              <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{intrestRateChange === '' || !intrestRateChange ? 'NA' : intrestRateChange}</div>
            </div>
            <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px', width: '50%' }}>
              INSTALMENT AMOUNT
              <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{instalmentAmountChange === '' || !instalmentAmountChange ? 'NA' : parseFloat(instalmentAmountChange).toFixed(2)}</div>
            </div>
          </div>
          <div style={{ display: 'flex', position: 'absolute', bottom: 0, marginBottom: '20px' }}>
            <Button label="Cancel" buttonType="secondary" customStyle={{ borderRadius: '8px', width: '240px', height: '56px', fontSize: '16px' }} onClick={handleClose} />
            <Button
              label="Submit"
              buttonType="primary"
              customStyle={{ borderRadius: '8px', width: '240px', height: '56px', fontSize: '16px' }}
              onClick={() => {
                submitRestructureData();
                handleClose();
              }}
            />
          </div>
        </FormPopup>
      ) : null}

      <div style={{ width: '97%', marginTop: '30px', border: ' 1px solid #EDEDED', borderRadius: '8px', marginLeft: '25px', padding: '16px', fontFamily: 'Montserrat-SemiBold' }}>
        <h4 style={{ fontSize: '20px', lineHeight: '150%', fontFamily: 'Montserrat-SemiBold', color: '#141519' }}>Current Loan Details</h4>
        <div style={{ display: 'grid', gridTemplateColumns: '17% 30% 19% 19% 19%', marginTop: '16px' }}>
          <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px' }}>
            UNBILLED PRINCIPAL
            <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{restructureData?.unbilled_principal === '' || !restructureData?.unbilled_principal ? 'NA' : restructureData?.unbilled_principal}</div>
          </div>
          <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px', marginLeft: '20%' }}>
            REMAINING EMI
            <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{restructureData?.emiCount === '' || !restructureData?.emiCount ? 'NA' : restructureData?.emiCount}</div>
          </div>
          <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px' }}>
            INTREST RATE
            <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{restructureData?.int_rate === '' || !restructureData?.int_rate ? 'NA' : restructureData?.int_rate}</div>
          </div>
          <div style={{ fontFamily: 'Montserrat-Regular', fontSize: '12px' }}>
            INSTALLMENT AMOUNT
            <div style={{ color: 'black', fontFamily: 'Montserrat-Regular', fontWeight: '800', fontSize: '16px' }}>{restructureData?.installmentamount === '' || !restructureData?.installmentamount ? 'NA' : restructureData?.installmentamount}</div>
          </div>
        </div>
      </div>

      <div style={{ width: '97%', marginTop: '30px', border: ' 1px solid #EDEDED', borderRadius: '8px', marginLeft: '25px', padding: '16px', fontFamily: 'Montserrat-Regular' }}>
        <h4 style={{ fontSize: '20px', lineHeight: '150%', fontFamily: 'Montserrat-SemiBold', color: '#141519' }}>Loan Simulator</h4>
        <div style={{ display: 'grid', gridTemplateColumns: '17% 30% 19% 19% 19%', marginTop: '16px' }}>
          <h3 style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Montserrat-SemiBold', color: '#141519' }}>Select Parameter</h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '16px' }}>
          <FormControlLabel control={<Checkbox checked={instalmentCountChecked} onChange={handleInstalmentCountChange} disabled={(isTagged && checkAccessTags(['tag_restructure_loan_write']) ? false : true) || isPreEmiStage} />} label={'Instalment count'} />
          <FormControlLabel control={<Checkbox checked={interestRateChecked} onChange={handleInterestRateChange} disabled={isTagged && checkAccessTags(['tag_restructure_loan_write']) ? false : true} />} label={'Interest Rate'} />
          <FormControlLabel control={<Checkbox checked={instalmentAmountChecked} onChange={handleInstalmentAmountChange} disabled={(isTagged && checkAccessTags(['tag_restructure_loan_write']) ? false : true) || isPreEmiStage} />} label={'Instalment Amount'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '16px', gap: '24px' }}>
          <InputBox
            label={'Instalment count'}
            initialValue={instalmentCountInput}
            isDisabled={!instalmentCountChecked}
            onClick={(e) => {
              setInstalmentCountInput(e.value);
            }}
            customClass={{
              minWidth: '32%',
              marginBottom: '2rem',
              height: '56px',
              fontFamily: 'Montserrat-Regular',
            }}
          />
          <InputBox
            label={'Interest Rate(%)'}
            initialValue={intrestRateInput}
            isDisabled={!interestRateChecked}
            onClick={(e) => {
              setIntrestRateInput(() => e.value);
            }}
            customClass={{
              minWidth: '32%',
              marginBottom: '2rem',
              height: '56px',
              fontFamily: 'Montserrat-Regular',
            }}
          />
          <InputBox
            label={'Instalment Amount'}
            initialValue={instalmentAmountInput}
            isDisabled={!instalmentAmountChecked}
            onClick={(e) => {
              setInstalmentAmountInput(() => e.value);
            }}
            customClass={{
              minWidth: '32%',
              marginBottom: '2rem',
              height: '56px',
              fontFamily: 'Montserrat-Regular',
            }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', paddingRight: '10px' }}>
          <Button
            label="Reset"
            customStyle={{ borderRadius: '8px', width: '200px', height: '52px', fontSize: '16px' }}
            buttonType="secondary"
            onClick={() => {
              resetInputValues();
            }}
          />
          {!applyBtn ? <Button label="Calculate" customStyle={{ borderRadius: '8px', width: '200px', height: '52px', fontSize: '16px' }} buttonType="primary" onClick={handleCalculateClick} isDisabled={!instalmentCountChecked && !interestRateChecked && !instalmentAmountChecked} /> : <Button label="Apply" customStyle={{ borderRadius: '8px', width: '200px', height: '52px', fontSize: '16px' }} buttonType="primary" onClick={handleApplyClick} />}
        </div>
      </div>
      <h4 style={{ fontSize: '20px', lineHeight: '150%', fontFamily: 'Montserrat-SemiBold', color: '#141519', marginTop: '3em', marginLeft: '1.5em' }}>Restructure History</h4>
      <Table customStyle={tableStyle} data={restructureList} columns={columns} />
      <Pagination onPageChange={handleChangePage} totalItems={count} itemsPerPage={rowsPerPage} />
    </>
  );
}
