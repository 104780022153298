import { takeLatest, call, put } from 'redux-saga/effects';
import { repaymentV2FormPostApi, getLapChargesDetailsApi } from '../apis/repaymentV2';
import { updatePreLoaderWatcher } from '../actions/user';

export function* repaymentV2FormPostEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(repaymentV2FormPostApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* repaymentV2FormPostWatcherSaga() {
  yield takeLatest('REPAYMENTV2_FORM_POST_WATCHER', repaymentV2FormPostEffectSaga);
}

export function* getLapChargesDetailsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(getLapChargesDetailsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* getLapChargesDetailsWatcherSaga(action) {
  yield takeLatest('GET_LAP_CHARGES_DETAILS', getLapChargesDetailsEffectSaga);
}
