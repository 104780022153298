// import all actions here
export function postLapApplicantDetailsWatcher(payload, resolve, reject) {
  return {
    type: 'POST_LAP_APPLICANT_DETAILS',
    payload,
    resolve,
    reject,
  };
}

export function patchLapDetailsWatcher(payload, resolve, reject) {
  return {
    type: 'PATCH_LAP_DETAILS',
    payload,
    resolve,
    reject,
  };
}

export function patchLapSaveAsDraftDetailsWatcher(payload, resolve, reject) {
  return {
    type: 'PATCH_LAP_SAVE_AS_DRAFT_DETAILS',
    payload,
    resolve,
    reject,
  };
}
export function putLapDraftSaverWatcher(payload, resolve, reject) {
  return {
    type: 'PUT_LAP_SAVE_DRAFT',
    payload,
    resolve,
    reject,
  };
}

export function getLapCompaniesWatcher(data, resolve, reject) {
  return {
    type: 'GET_LAP_COMPANIES_WATCHER',
    payload: data,
    resolve,
    reject,
  };
}

export function getLapCompaniesOnlyWatcher(resolve, reject) {
  return {
    type: 'GET_LAP_COMPANIES_ONLY_WATCHER',
    resolve,
    reject,
  };
}

export function getProductByCompanyIDWatcher(data, resolve, reject) {
  return {
    type: 'GET_PRODUCT_BY_COMPANY_ID_WATCHER',
    payload: data,
    resolve,
    reject,
  };
}

export function getLapProductByCompanyIDWatcher(data, resolve, reject) {
  return {
    type: 'GET_LAP_PRODUCT_BY_COMPANY_ID_WATCHER',
    payload: data,
    resolve,
    reject,
  };
}

export function getLapSubmissionStatusWatcher(data, resolve, reject) {
  return {
    type: 'GET_LAP_SUBMISSION_STATUS_WATCHER',
    payload: data,
    resolve,
    reject,
  };
}

export function patchLapDocDeleteWatcher(payload, resolve, reject) {
  return {
    type: 'LAP_DOC_DELETE',
    payload,
    resolve,
    reject,
  };
}

export function subSectionDeleteWatcher(payload, resolve, reject) {
  return {
    type: 'LAP_SUB_SECTION_DELETE',
    payload,
    resolve,
    reject,
  };
}

export function updateLeadDeviationWatcher(payload, resolve, reject) {
  return {
    type: 'UPDATE_LAP_LEAD_DEVIATION',
    payload,
    resolve,
    reject,
  };
}

export function ammendOfferAPIWatcher(payload, resolve, reject) {
  return {
    type: 'AMMEND_LAP_OFFER_API',
    payload,
    resolve,
    reject,
  };
}
export function verifyAadhaarOtpWatcher(payload, resolve, reject) {
  return {
    type: 'LAP_VERIFY_AADHAAR_OTP',
    payload,
    resolve,
    reject,
  };
}

export function postEsignRequestWatcher(payload, resolve, reject) {
  return {
    type: 'POST_LAP_ESIGN_REQUEST',
    payload,
    resolve,
    reject,
  };
}

export function getBicDataWatcher(payload, resolve, reject) {
  return {
    type: 'GET_LAP_BIC_DATA',
    payload,
    resolve,
    reject,
  };
}
