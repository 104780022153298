import axios from 'axios';
import { BASE_URL } from '../../constants/apiUrls';

export function getReconSummaryApi(payload) {
  return axios.get(`${BASE_URL}partner-recon`, {
    params: payload,
  });
}

export function getDownloadReportApi(payload) {
  return axios.post(BASE_URL + 'service-fee-report', payload);
}
