import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import InputBox from 'react-sdk/dist/components/InputBox/InputBox';
import Button from 'react-sdk/dist/components/Button/Button';
import useDimensions from '../../../hooks/useDimensions';
import './ActionPopup.style.css';
import { toCamel } from 'util/helper';
import { TextField } from '@material-ui/core';
import { Field_Label } from '../../constants/string_constants';

export const ActionPopupContent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  let backgroundColor = {
    backgroundColor: 'var(--neutrals-neutral-30, #CCCDD3)',
    color: 'var(--base-white, #FFF)',
    borderColor: 'var(--neutrals-neutral-30, #CCCDD3)',
  };
  if (props.type === Field_Label.ACTION_POPUP_CONTENT_TYPE_REJECT) {
    backgroundColor = {
      background: 'linear-gradient(180deg, #CC0000 0%, #CC0000 100%, #CC0000 100%)',
      backgroundColor: 'var(--neutrals-neutral-30, #CC0000)',
      color: 'var(--base-white, #FFF)',
      borderColor: 'var(--neutrals-neutral-30, #CC0000)',
    };
  }
  const { innerWidth, innerHeight } = useDimensions();
  const dispatch = useDispatch();
  const styles = useStyles({ innerWidth, innerHeight });
  const onChangeValue = (e) => {
    const { id, value } = e.target;

    props.setData({
      ...props.data,
      [id]: value,
    });

    props.setDataErrors({
      ...props.dataErrors,
      [id]: null,
    });

    setIsDisabled(false);
  };

  const onChangeDropdown = (e, id) => {
    const { label, value } = e;

    props.setData({
      ...props.data,
      [id]: label,
    });

    props.setDataErrors({
      ...props.dataErrors,
      [id]: null,
    });

    setIsDisabled(false);
  };

  const handleValidations = () => {
    var errors = {};

    for (let attribute in props.fields) {
      if (props.fields[attribute] && props.fields[attribute].isRequired) {
        if (!props.data[attribute] || props.data[attribute].length == 0) {
          errors[attribute] = ` ${toCamel(props.fields[attribute].label, true)} is required`;
        }
      }
    }

    return errors;
  };

  const onSecondaryAction = () => {
    if (props.button['secondary'].action) {
      props.button['secondary'].action();
    }
  };

  const onPrimaryAction = () => {
    let errors = handleValidations();
    if (Object.keys(errors).length != 0) {
      props.setDataErrors(errors);
      return;
    }

    if (props.button['primary'].action) {
      props.button['primary'].action();
    }
  };

  return (
    <React.Fragment>
      {/* <div style={{ paddingTop: innerHeight > 800 ? '6vh' : '8vh' }}> */}
      <div style={{ maxHeight: '73vh' }}>
        <div className="action-popup-input-container">
          {Object.keys(props.fields).map((field, index) => {
            return (
              <React.Fragment key={index}>
                {props.fields[field]['category'] == 'dropdown' && props.fields[field]['section'].includes(props.type) ? (
                  <div key={index} style={{ display: props.fields[field]['section'].includes(props.type) ? 'initial' : 'none' }}>
                    <InputBox
                      customClass={styles['inputBox']}
                      customInputClass={styles['inputBoxInner']}
                      customDropdownClass={styles['dropdown']}
                      id={props.fields[field]['id']}
                      label={props.fields[field]['label']}
                      type={props.fields[field]['type']}
                      name={props.fields[field]['name']}
                      // autoComplete="off"
                      isDrawdown={true}
                      options={props.fields[field]['options'] ?? []}
                      placeholder={props.fields[field]['placeholder']}
                      error={props.dataErrors[field] ? true : false}
                      helperText={props.dataErrors[field]}
                      isRequired={props.fields[field]['isRequired']}
                      initialValue={props.data[field]}
                      onClick={(e) => onChangeDropdown(e, props.fields[field]['id'])}
                      onDrawdownSelect={(value) => {
                        let selectedReason = props.fields[field]?.options?.find((item) => item.label === value);
                        if (selectedReason) {
                          props.setData({
                            ...props.data,
                            reasons: selectedReason.value,
                          });
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div key={index} style={{ display: props.fields[field]['section'].includes(props.type) ? 'initial' : 'none' }}>
                    {props?.heading === 'Reqeust an Update' ? <div style={{ fontSize: '16px', color: '#767888', fontFamily: 'Montserrat-Medium', marginBottom: '4%' }}>You can request for additional update only</div> : null}
                    {props.fields[field]['section'].includes(props.type) && (
                      <TextField
                        id={props.fields[field]['id']}
                        label={props.fields[field]['label']}
                        type={props.fields[field]['type']}
                        name={props.fields[field]['name']}
                        style={{ marginTop: '20px' }}
                        inputProps={{
                          style: {
                            color: 'black',
                          },
                        }}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={6}
                        placeholder={props.fields[field]['placeholder']}
                        required={props.fields[field]['isRequired']}
                        value={props.data[field]}
                        error={props.dataErrors[field] ? true : false}
                        helperText={props.dataErrors[field]}
                        onChange={(e) => onChangeValue(e)}
                      />
                    )}
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {/* </div> */}

      <div
        className="action-popup-footer-container"
        style={{
          width: innerWidth > 900 ? '37vw' : innerWidth > 600 ? '45vw' : '70vw',
        }}
      >
        <div className="action-popup-button-container">
          {props.button['secondary'] ? (
            <Button
              buttonType="custom"
              customStyle={{
                width: '300px',
                height: '48px',
                border: '1px solid #475BD8',
                color: '#475BD8',
                borderRadius: '26px',
                color: 'rgb(71, 91, 216)',
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'center',
                boxShadow: 'none',
                backgroundColor: 'white',
                padding: '13px 44px',
              }}
              customLoaderClass={styles['buttonLoader']}
              isLoading={isLoading}
              isDisabled={isDisabled}
              label={props.button['secondary']['label'] ? props.button['secondary']['label'] : 'Cancel'}
              onClick={() => {
                onSecondaryAction();
              }}
            />
          ) : (
            <div />
          )}
          {props.button['primary'] ? (
            <Button
              buttonType="custom"
              customStyle={
                isDisabled
                  ? { ...styles['button'], ...backgroundColor }
                  : props.button['primary']['style']
                  ? {
                      ...styles['button'],
                      ...props.button['primary']['style'],
                    }
                  : { ...styles['button'] }
              }
              customLoaderClass={styles['buttonLoader']}
              isLoading={isLoading}
              isDisabled={isDisabled}
              label={props.button['primary']['label'] ? props.button['primary']['label'] : 'Submit'}
              onClick={() => {
                onPrimaryAction();
              }}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const useStyles = ({ innerWidth, innerHeight }) => {
  return {
    headerText: {
      fontFamily: 'Montserrat-Medium',
      color: '#161719',
      fontWeight: 600,
      fontSize: '16px',
      margin: '0px 0px 3px 0px',
    },
    inputBox: {
      height: '55px',
      maxWidth: '100vw',
      paddingTop: '5px',
      border: '1px solid #BBBFCC',
    },
    inputBoxInner: {
      backgroundColor: '#FFF',
    },
    button: {
      height: '48px',
      width: '300px',
      borderRadius: '48px',
      marginLeft: '18px',
      fontSize: '16px',
      padding: '13px 44px',
      textAlign: 'center',
      alignItems: 'center',
      background: 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%)',
      color: '#FFF',
      fontFamily: 'Montserrat-Regular',
    },
    buttonLoader: {
      border: '3px solid white',
      borderTop: '3px solid transparent',
      marginLeft: '40%',
    },
    dropdown: {
      zIndex: 1000,
      marginTop: '8px',
      minHeight: '300px',
    },
    helperTextStyle: {
      // marginTop: "97px"
    },
    footerList: {
      fontFamily: 'Montserrat-Medium',
      color: '#161719',
      fontWeight: 500,
      marginBottom: '10px',
      fontSize: '14px',
    },
  };
};
