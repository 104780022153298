import { takeLatest, call, put } from 'redux-saga/effects';
import { locDrawdownGetLineDetailsApi, locDrawdownCloseLineApi, locDrawdownDownloadNOCApi } from '../apis/locDrawdownLineDetails';
import { updatePreLoaderWatcher } from '../actions/user';

export function* locDrawdownGetLineDetailsEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(locDrawdownGetLineDetailsApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* locDrawdownGetLineDetailsWatcherSaga(action) {
  yield takeLatest('LOC_DRAWDOWN_GET_LINE_DETAILS', locDrawdownGetLineDetailsEffectSaga);
}

export function* locDrawdownCloseLineEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(locDrawdownCloseLineApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* locDrawdownCloseLineWatcherSaga(action) {
  yield takeLatest('LOC_DRAWDOWN_CLOSE_LINE', locDrawdownCloseLineEffectSaga);
}

export function* locDrawdownDownloadNocEffectSaga(action) {
  try {
    yield put(updatePreLoaderWatcher(true));
    const { data } = yield call(locDrawdownDownloadNOCApi, action.payload);
    yield put(updatePreLoaderWatcher(false));
    action.resolve(data);
  } catch (e) {
    yield put(updatePreLoaderWatcher(false));
    action.reject(e);
  }
}

export function* locDrawdownDownloadNocWatcherSaga(action) {
  yield takeLatest('LOC_DRAWDOWN_DOWNLOAD_NOC', locDrawdownDownloadNocEffectSaga);
}
