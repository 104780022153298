import { GET_DOCUMENT_LIST, GET_FINAL_SECTION_VALIDATION_CHECKLIST, GET_OWNER_DETAILS, GET_SHARE_HOLDERS_DETAILS, GET_PROPERTY_TITLE_DOCUMENTS, validateTitleDocumentName, getCustomInputBoxData } from "./helper";
import { Entity_Type, Field_Label, Section_Status_Name } from "../../../constants/string_constants";
import { States } from '../../../../constants/country-state-city-data';
import { validateData } from '../../../utils/validation';
import { ErrorConstants } from '../../../constants/error_constants';
import { Property_Legal_Report_Upload, Property_Valuation_Report_Upload } from '../../../views/bookLoans/uploadKycData';
import { 
  PRIMARY_VALIDATION_CHECKLIST, 
  ENTITY_VALIDATION_CHECKLIST,
  PROPERTY_VALIDATION_CHECKLIST,
  COBORROWER_VALIDATION_CHECKLIST,
  GUARANTOR_VALIDATION_CHECKLIST,
  FINANCIAL_VALIDATION_CHECKLIST
 } from "./mockData";
import { getfillersFromList } from "../../../utils/helper";
import { Number_Constants } from "lap/constants/number_constants";
import _isEmpty from "lodash/isEmpty"
import { documentCode as DocumentListCode } from "../../../config/docCode";

const getDobField = (stateKey, value) => {
  return  {
    label: 'Date of Birth',
    value,
    type: 'custom_date_picker',
    direction: 'column',
    stateKey
  };
}
const getAgeField = (value, dateStateKey) => {
  return {
    label: 'Age',
    value: value,
    type: 'custom_age_field',
    direction: 'column',
    isDisabled: true,
    stateKey: "",
    dateStateKey,
    validator: (newValue) => {
      return [newValue !== "", "Age is required"]
    }
  };
}
const getGenderField = (stateKey, value) => {
  return {
    label: 'Gender',
    value,
    type: 'custom_dropdown',
    direction: 'column',
    options: [
      { value: 'Female', label: 'Female' },
      { value: 'Male', label: 'Male' },
      { value: 'Others', label: 'Others' },
    ],
    stateKey
  }
}
const getEntityTypeField = (stateKey, value) => {
  return {
    label: 'Entity Type',
    value,
    type: 'custom_dropdown',
    direction: 'column',
    placeholder: "",
    options: [
      { value: 'Individual', label: 'Individual' },
      { value: 'Proprietor', label: 'Proprietor' },
      { value: 'Partnership', label: 'Partnership' },
      { value: 'Private Limited', label: 'Private Limited' }
    ],
    stateKey,
  }
}
const getAddrLine1Field = (stateKey, value) => {
  return {
    label: 'Address Line 1',
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    validator: (newValue) => {
      const isValid = validateData("string", newValue);
      return [isValid, "Address Line 1 is required"]
    },
    // helperText: "Address Line 1 is required",
    maxLength: 65
  };
}
const getAddrLine2Field = (stateKey, value) => {
  return {
    label: 'Address Line 2 (optional)',
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    validator: (newValue) => {
      const isValid = newValue === "" ? true : validateData("string", newValue);
      return [isValid, "Address Line 2 is required"]
    },
    // helperText: "Address Line 2 is required",
    maxLength: 65
  };
}
const getStateField = (stateKey, value, cityKey, selectedCity) => {
  return {
    label: 'State',
    value,
    type: 'custom_dropdown',
    direction: 'column',
    options: States,
    stateKey,
    isStateDropdown: true,
    cityKey,
    selectedCity,
    validator: (newValue) => {
      const isValid = validateData("string", newValue);
      return [isValid, 'State is required'];
    }
  };
}
const getCityField = (stateKey, value, selectedState, selectedStateKey) => {
  return  {
    label: 'City',
    value,
    type: 'custom_dropdown',
    direction: 'column',
    isCityDropdown: true,
    selectedState,
    stateKey,
    selectedStateKey,
    validator: (newValue) => {
      const isValid = validateData("string", newValue);
      return [isValid, 'City is required'];
    }
  };
}
const getPincodeField = (stateKey, pincode) => {
  return  {
    label: 'Pincode',
    value: pincode?.toString(),
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    validator: (newValue) => { 
      const isValid = validateData("pincode", newValue);
      return [isValid, 'Pincode should be of 6 digits']
    },
    maxLength: 6
  };
}
const getNatureOfOwnership = (stateKey, value) => {
  return {
    label: 'Nature of Ownership',
    value,
    type: 'custom_dropdown',
    options: [
      { value: 'Owned', label: 'Owned' },
      { value: 'Rented', label: 'Rented' }
    ],
    direction: 'column',
    stateKey,
    validator: (newValue) => {
      const isValid = validateData("string", newValue);
      return [isValid, 'Nature of Ownership is required']
    }
  };
}
const getNoOfYears = (stateKey, value, isCurrAdd = false) => {
  const addr = isCurrAdd ? "Current Address" : "Permanent Address";
  return {
    label: `No of Years at ${addr}`,
    value,
    type: 'custom_dropdown',
    direction: 'column',
    stateKey,
    validator: (newValue) => {
      const isValid = validateData("string", newValue);
      return [isValid, `No. of Years at ${addr} is required`]
    },
    options: [
      { value: '0 - 1 Years', label: '0 - 1 Years' },
      { value: '1 - 2 Years', label: '1 - 2 Years' },
      { value: '2 - 3 Years', label: '2 - 3 Years' },
      { value: '3 - 4 Years', label: '3 - 4 Years' },
      { value: '4 - 5 Years', label: '4 - 5 Years' },
      { value: '5 - 6 Years', label: '5 - 6 Years' },
      { value: '6 - 7 Years', label: '6 - 7 Years' },
      { value: '7 - 8 Years', label: '7 - 8 Years' },
      { value: '8 - 9 Years', label: '8 - 9 Years' },
      { value: '9 - 10 Years', label: '9 - 10 Years' },
      { value: '10 - 11 Years', label: '10 - 11 Years' },
      { value: '11 - 12 Years', label: '11 - 12 Years' },
    ],
  }
}
const getOccupationType = (stateKey, value) => {
  return {
    label: 'Occupation Type',
    value,
    type: 'custom_dropdown',
    direction: 'column',
    options: [
      { value: 'Self Employed', label: 'Self Employed' }
    ],
    stateKey
  };
}
const getPanField = (stateKey, value) => {
  return {
    label: 'Pan',
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    isDisabled: true,
    stateKey
  };
}
const getEmailField = (stateKey, value, isOptional) => {
  return  {
    label: `Email${isOptional ? " (optional)" : ""}`,
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    validator: (newValue) => { 
      const isValid = (isOptional && newValue === "") || validateData("email", newValue);
      return [isValid, 'Invalid email format. Please use example@example.com']
    },
    // helperText: 'Invalid email format. Please use example@example.com',
    // maxLength: 65
  };
}
const getMobileNoField = (stateKey, value) => {
  return {
    label: 'Mobile No.',
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    validator: (newValue) => { 
      let isValid = true;
      let helperText = "";
      const isValidMobileNo = /^[5-9]\d*$/.test(newValue);
      const decimalPattern = /^[0-9]*$/;
      if (!isValidMobileNo) {
        if (!decimalPattern.test(newValue)) {
          isValid = false;
          helperText = "Special characters are not allowed";
        } else {
          isValid = false;
          helperText = "Mobile No. should start with 5, 6, 7, 8, or 9";
        }
      } else if (newValue.length !== 10) {
        isValid = false;
        helperText = "Mobile no. should have 10 digits";
      }
      return [isValid, helperText]
    },
  };
}
const getFirstNameField = (stateKey, value) => {
  return {
    label: 'First Name',
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    isDisabled: true,
  };
}
const getMiddleNameField = (stateKey, value) => {
  return {
    label: 'Middle Name (optional)',
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    isDisabled: true,
  };
}
const getLastNameField = (stateKey, value) => {
  return  {
    label: 'Last Name',
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    isDisabled: true,
  };
}
const getFathersFullNameField = (stateKey, value) => {
  return {
    label: `Father's Full Name`,
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    validator: (newValue) => {
      let isValid = true;
      let helperText = "";
      if (newValue === "") {
        isValid = false;
        helperText = "Father Full Name is required";
      } else {
        isValid = validateData("formatname", newValue);
        helperText = "Special characters and numbers are not allowed";
      }
      return [isValid, helperText]
    }
  };
}
const getAadharField = (stateKey, value) => {
  return {
    label: 'Aadhar Number',
    value,
    type: 'custom_input_text_field',
    direction: 'column',
    stateKey,
    isDisabled: true
  };
}

const getLoanTenure = (stateKey, value) => {
  return {
    label: 'Tenure In Months',
    value,
    type: 'custom_dropdown',
    direction: 'column',
    stateKey,
    options: [
      { value: '12 months', label: '12' },
      { value: '24 months', label: '24' },
      { value: '36 months', label: '36' },
      { value: '48 months', label: '48' },
      { value: '60 months', label: '60' },
      { value: '72 months', label: '72' },
      { value: '84 months', label: '84' },
      { value: '96 months', label: '96' },
      { value: '108 months', label: '108' },
      { value: '120 months', label: '120' }
    ],
    validator: (newValue) => {
      const isValid = ["12 months", "24 months", ""].includes(newValue);
      const helperText = "Select the range between 12 months - 24 months";
      return [isValid, helperText];
    }
  };
}

export const LEAD_DETAILS_TAB_EDIT_ACCORDION_FIELDS = ({leadDetails, documents, allDocuments, titleDocDetails, allAccordionState}) =>{ 
    const {
      first_name,
      middle_name,
      last_name,
      dob, 
      gender,
      appl_phone,
      email_id,
      father_fname,
      occupation_type,
      entity_details,
      appl_pan,
      loan_amount,
      loan_tenure,
      resi_addr_ln1,
      resi_addr_ln2,
      state,
      city,
      pincode,
      nature_of_ownership,
      house_ownership,
      no_of_years_current_addr,
      loan_interest_rate,
      per_addr_ln1,
      per_addr_ln2,
      per_state,
      per_city,
      per_pincode,
      no_of_years_permanent_addr,
      guarantor,
      coborrower,
      property,
      share_holders,
      type_of_loan,
      addi_docs_comment = '',
      product_id,
      company_id,
      loan_app_id,
      property_documents
    } = leadDetails
    const primarySectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.primary_final_submit?.validation_checklist ?? PRIMARY_VALIDATION_CHECKLIST)
    const enitySectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.entity_final_submit?.validation_checklist ?? ENTITY_VALIDATION_CHECKLIST )
    const propertySectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.property_final_submit?.validation_checklist ?? PROPERTY_VALIDATION_CHECKLIST)
    const financialSectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.financial_doc_final_submit?.validation_checklist ?? FINANCIAL_VALIDATION_CHECKLIST)
    const propertyOwnerDetails = GET_OWNER_DETAILS(property?.prop_property_owner_details, leadDetails, true);
    const shareHolders = GET_SHARE_HOLDERS_DETAILS(share_holders, leadDetails, true);
    const additionalDocs = GET_DOCUMENT_LIST(documents);
    const propertyTitleDocuments = GET_PROPERTY_TITLE_DOCUMENTS(allDocuments, titleDocDetails, property_documents?.title_documents);
    const {valuationReport, legalReport} = allDocuments;
    const documentNameLabel = "Document Name";
    const titleErrorValidationData = propertyTitleDocuments.reduce((acc, curr) => {
      const idValue = parseInt(curr.id, 10) || 0;
    const documentCode = DocumentListCode[`Title_Report_${idValue}`];
      return validateTitleDocumentName(curr.name, documentCode, acc, documentNameLabel);
    },{});
    const shouldDisableTitleReportSubmit = Object.values(titleErrorValidationData).filter(val => val !== "").length > 0;
    const getAddressTitle = () => {
      if(entity_details?.entity_type?.toLowerCase() === Field_Label.Individual){
        return Field_Label.Current_Address
      } return Field_Label.Communication_Address
    }
    let financeCheckList =[]
    if (financialSectionValidationCheckList?.length) {
      const setCheckList = (sectionTitle, checkListKeyWord) => {
        const checkList = financialSectionValidationCheckList.filter(item => item?.label?.includes(checkListKeyWord))
        if (checkList?.length) {
          financeCheckList = [...financeCheckList, {
            label: sectionTitle,
            value: '',
            type: 'subHeading',
            direction: 'row',

          }, ...checkList, ...getfillersFromList(checkList, Number_Constants.FOUR,true),]
        }
      }
      setCheckList('ITR', 'ITR')
      setCheckList('GST', 'GST')
      setCheckList('Financial Statement', 'BANK')
    }
    const actualPropertyValidator = (newValue) => {
      if (newValue === "") {
        return [false, 'Acutal Property Value is required'];
      } else {
        const numberPattern = /^[0-9]*$/;
        const isValid = numberPattern.test(newValue);
        return [isValid, "Special characters are not allowed"];
      }
    }
    const actualPropertyStateKey = "property-documents:prop_value";
    const PRIMARY_APPLICANT = "primary-applicants"
    const {
      initialValue,
      isValidValue,
      helperText
    } = getCustomInputBoxData(property_documents?.prop_value, actualPropertyValidator, actualPropertyStateKey, PRIMARY_APPLICANT, allAccordionState);
    const shouldDisableAcutalPropertyValueSubmit = !isValidValue;
    const fillers = (list) => {
      const listLength = list?.length ?? 0
      let fillerSections = []
      if (listLength) {
        let requiredFillersCount = 4 - (listLength % 4)
        for (let i = 0; i < requiredFillersCount; i++) {
          fillerSections.push({
            label: '',
            value: '',
            type: 'filler',
            direction: 'column',
          })
        }
      }
      return fillerSections
    }
    return [
    {
      section: 'applicant_details',
      sectionName: 'Applicant Details',
      sectionStatusName: Section_Status_Name.Primary_Applicant,
      isContainsAccordion: false,
      isHideSection: appl_pan? false: true,
      isSubAccordion: false,
      subAccordionData: [],
      fieldsArr: [
        getFirstNameField("first_name", first_name),
        getMiddleNameField("middle_name", middle_name),
        getLastNameField("last_name", last_name),
        getDobField("dob", dob),
        getAgeField(dob, "dob"),
        getGenderField("gender", gender),
        getMobileNoField("appl_phone", appl_phone),
        getEmailField("email_id", email_id),
        getFathersFullNameField("father_fname", father_fname),
        getOccupationType("occupation_type", occupation_type),
        getEntityTypeField("entity-details:entity_type", entity_details?.entity_type),
        getPanField("appl_pan", appl_pan),
        {
          label: 'loan details',
          value: '',
          type: 'separator',
          direction: 'row',
        },
        {
          label: 'Requested Loan Amount',
          value: loan_amount,
          type: 'custom_input_text_field',
          direction: 'column',
          stateKey: "loan_amount",
          validator: (newValue) => {
            let isValid = true;
            let helperText = ""
            if(newValue === "") {
              isValid = false;
              helperText = "Requested Loan Amount is required";
            } else {
              isValid = validateData("loanamount", newValue);
              helperText = "Provide the amount between Rs.1 Lakh - Rs.2 Crores";
            }
            return [isValid, helperText]
          }
        },
        getLoanTenure("loan_tenure", loan_tenure),
        // {
        //   label: 'Tenure In Months',
        //   value: loan_tenure,
        //   type: 'custom_input_text_field',
        //   direction: 'column',
        //   stateKey: "loan_tenure",
        //   options: [
        //     { value: '12 months', label: '12' },
        //     { value: '24 months', label: '24' },
        //     { value: '36 months', label: '36' },
        //     { value: '48 months', label: '48' },
        //     { value: '60 months', label: '60' },
        //     { value: '72 months', label: '72' },
        //     { value: '84 months', label: '84' },
        //     { value: '96 months', label: '96' },
        //     { value: '108 months', label: '108' },
        //     { value: '120 months', label: '120' }
        //   ],
        //   validator: (newValue) => {
        //     const isValid = [""]
        //   }
        // },
        {
          label: 'Loan Type',
          value: type_of_loan,
          type: 'custom_dropdown',
          direction: 'column',
          options: [
            { value: 'Regular', label: 'Regular' },
            { value: 'Balance Transfer', label: 'Balance Transfer' },
          ],
          stateKey: "type_of_loan",
          validator: (newValue) => {
            const isValid = newValue !== "";
            return [isValid, "Type of Loan is required"]
          }
        },
        {
          label: 'Requested Interest Rate(%)',
          value: `${loan_interest_rate}`,
          type: 'custom_input_text_field',
          direction: 'column',
          stateKey: "loan_interest_rate",
          validator: (newValue) => {
          let [isValid, helperText] = [true, ""];
          if(newValue === "") {
            isValid = false;
            helperText = "Requested Interest Rate is required";
          } else {
            const valAsNum = parseFloat(newValue);
            isValid = validateData("number", newValue) && (valAsNum < 101) && (valAsNum >= 0);
            helperText = "This field only accepts numbers between 0 to 100";
          }

            return [isValid, helperText];
          }
        },
        {
          label: getAddressTitle(),
          value: '',
          type: 'separator',
          direction: 'row',
        },
        getAddrLine1Field("resi_addr_ln1", resi_addr_ln1),
        getAddrLine2Field("resi_addr_ln2", resi_addr_ln2),
        getStateField("state", state, "city", city),
        getCityField("city", city, state, "state"),
        getPincodeField("pincode", pincode),
        getNatureOfOwnership("nature_of_ownership", nature_of_ownership),
        getNoOfYears("no_of_years_current_addr", no_of_years_current_addr, true),
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: 'permanent address',
          value: '',
          type: 'separator',
          direction: 'row',
        },
        getAddrLine1Field("per_addr_ln1", per_addr_ln1),
        getAddrLine2Field("per_addr_ln2", per_addr_ln2),
        getStateField("per_state", per_state, "per_city", per_city),
        getCityField("per_city", per_city, per_state, "per_state"),
        getPincodeField("per_pincode", per_pincode),
        getNatureOfOwnership("house_ownership", house_ownership),
        // {
        //   label: 'No. of Years',
        //   value: no_of_years_permanent_addr,
        //   type: 'custom_input_text_field',
        //   direction: 'column',
        //   stateKey: "no_of_years_permanent_addr"
        // },
        getNoOfYears("no_of_years_permanent_addr", no_of_years_permanent_addr, false),
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        ...primarySectionValidationCheckList
      ],
    },
    {
      section: 'entity_details',
      sectionName: 'Entity Details',
      sectionStatusName: Section_Status_Name.Entity_Details,
      isHideSection: _isEmpty(entity_details) ? true : false,
      isContainsAccordion: false,
      isSubAccordion: false,
      subAccordionData: [],
      fieldsArr: [
        getEntityTypeField("entity-details:entity_type", entity_details?.entity_type),
        {
          label: 'Business Commencement Date',
          value: entity_details?.date_of_incorporation,
          type: 'custom_date_picker',
          direction: 'column',
          stateKey: "entity-details:date_of_incorporation",
        },
        {
          label: 'Entity Name',
          value: entity_details?.entity_name,
          type: 'custom_input_text_field',
          direction: 'column',
          stateKey: "entity-details:entity_name",
          validator: (newValue) => {
            if(newValue === "") {
              return [false, 'Entity Name is required']
            } else {
              const pattern = /^[a-zA-Z\s]{1,65}$/;
              const isValid = pattern.test(newValue);
              return [isValid, 'Special characters and numbers are not allowed'];
            }
          },
        },
        {
          label: 'Nature of Business',
          value: entity_details?.nature_of_business,
          type: 'custom_dropdown',
          options: [
            { value: 'Retail Shop', label: 'Retail Shop' },
            { value: 'Goods Trading', label: 'Goods Trading' },
            { value: 'Manufacturing', label: 'Manufacturing' },
            { value: 'Job Work', label: 'Job Work' },
            { value: 'Recycling Business', label: 'Recycling Business' },
            { value: 'Steel Scrap', label: 'Steel Scrap' },
            { value: 'Trading and Service', label: 'Trading and Service' },
          ],
          direction: 'column',
          stateKey: "entity-details:nature_of_business",
          validator: (newValue) => {
            const isValid = validateData("string", newValue);
            return [isValid, 'Nature of Business is required']
          },
        },
        {
          label: 'Work Profile',
          value: entity_details?.work_profile,
          type: 'custom_input_text_field',
          direction: 'column',
          stateKey: "entity-details:work_profile",
          validator: (newValue) => {
            if (newValue === "") {
              return [false, 'Work Profile is required']
            } else if (newValue.length > 65) {
              return [false, 'Max Character length limit is 65'];
            } else{
              const pattern = /^[a-zA-Z\s]+$/;
              const isValid = pattern.test(newValue);
              return [isValid, 'Special characters and numbers are not allowed'];
            }
          }
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: 'Current Address',
          value: '',
          type: 'separator',
          direction: 'row',
        },
        getAddrLine1Field("entity-details:com_addr_ln1", entity_details?.com_addr_ln1),
        getAddrLine2Field("entity-details:com_addr_ln2", entity_details?.com_addr_ln2),
        getStateField("entity-details:com_state", entity_details?.com_state, "entity-details:com_city", entity_details?.com_city),
        getCityField("entity-details:com_city", entity_details?.com_city, entity_details?.com_state, "entity-details:com_state"),
        getPincodeField("entity-details:com_pincode", entity_details?.com_pincode),
        getNatureOfOwnership("entity-details:com_nature_of_ownership", entity_details?.com_nature_of_ownership),
        getNoOfYears("entity-details:no_of_years_com_addr", entity_details?.no_of_years_com_addr, true),
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: 'Permanent Address',
          value: '',
          type: 'separator',
          direction: 'row',
        },
        getAddrLine1Field("entity-details:res_addr_ln1", entity_details?.res_addr_ln1),
        getAddrLine2Field("entity-details:res_addr_ln2", entity_details?.res_addr_ln2),
        getStateField("entity-details:res_state", entity_details?.res_state, "entity-details:res_city", entity_details?.res_city),
        getCityField("entity-details:res_city", entity_details?.res_city, entity_details?.res_state, "entity-details:res_state"),
        getPincodeField("entity-details:res_pincode", entity_details?.res_pincode),
        getNatureOfOwnership("entity-details:res_nature_of_ownership", entity_details?.res_nature_of_ownership),
        getNoOfYears("entity-details:", entity_details?.no_of_years_res_addr, false),
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        }, 
        ...enitySectionValidationCheckList
      ],
    },
    {
      section: 'co_borrower_details',
      sectionName: 'Co-Borrower Details',
      isHideSection: coborrower?.filter((item)=>!item?.is_skip)?.length ? false : true,
      isContainsAccordion: true,
      isSubAccordion: false,
      subAccordionData: coborrower?.map((item, index)=>{
        const {
          cb_fname,
          cb_mname,
          cb_lname,
          cb_dob,
          cb_gender,
          cb_mobile,
          cb_father_name,
          cb_pan,
          cb_aadhaar,
          cb_email,
          cb_relationship,
          cb_entity_shareholder,
          cb_property_owner,
          cb_resi_addr_ln1,
          cb_resi_addr_ln2,
          cb_state,
          cb_city,
          cb_pincode,
          cb_per_addr_ln1,
          cb_per_addr_ln2,
          cb_per_state,
          cb_per_city,
          cb_per_pincode,
          _id
        } = item
        const coBorrowerIndex = index+1
        const coBorrowerSectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.[`Co-Borrower-${coBorrowerIndex}_final_submit`]?.validation_checklist ?? COBORROWER_VALIDATION_CHECKLIST)
        const coBorrowerKeyPrefix = `co-borrower-details:${_id}`;
        return {
          subIndex: coBorrowerIndex,
          section: 'co_borrower_details',
          sectionName: 'Co-Borrower Details',
          sectionStatusName: `co_borrower-${coBorrowerIndex}`,
          isContainsAccordion: false,
          isSubAccordion: true,
          fieldsArr: [
            getFirstNameField(`${coBorrowerKeyPrefix}:cb_fname`, cb_fname),
            getMiddleNameField(`${coBorrowerKeyPrefix}:cb_mname`, cb_mname),
            getLastNameField(`${coBorrowerKeyPrefix}:cb_lname`, cb_lname),
            getDobField(`${coBorrowerKeyPrefix}:cb_dob`, cb_dob),
            getAgeField(cb_dob, `${coBorrowerKeyPrefix}:cb_dob`),
            getGenderField(`${coBorrowerKeyPrefix}:cb_gender`, cb_gender),
            getMobileNoField(`${coBorrowerKeyPrefix}:cb_mobile`, cb_mobile),
            getEmailField(`${coBorrowerKeyPrefix}:cb_email`, cb_email, true),
            getFathersFullNameField(`${coBorrowerKeyPrefix}:cb_father_name`, cb_father_name),
            getPanField(`${coBorrowerKeyPrefix}:cb_pan`, cb_pan),
            getAadharField(`${coBorrowerKeyPrefix}:cb_aadhaar`, cb_aadhaar),
            {
              label: 'Relationship with Applicant',
              value: cb_relationship,
              type: 'custom_dropdown',
              direction: 'column',
              stateKey: `${coBorrowerKeyPrefix}:cb_relationship`,
              options: [
                { value: 'Spouse', label: 'Spouse' },
                { value: 'Mother', label: 'Mother' },
                { value: 'Father', label: 'Father' },
                { value: 'Brother', label: 'Brother' }
              ],
              validator: (newValue) => {
                return [newValue !== "", "Relationship with Applicant is required"]
              }
            },
            {
              label: 'Entity Shareholder',
              value: cb_entity_shareholder,
              type: 'custom_yes_or_no_radio_btns',
              direction: 'column',
              stateKey: `${coBorrowerKeyPrefix}:cb_entity_shareholder`,
              itemIndex: index,
              validator: (newValue) => {
                return [newValue !== "", "Entity Shareholder is required"]
              }
            },
            {
              label: 'Property Owner',
              value: cb_property_owner,
              type: 'custom_yes_or_no_radio_btns',
              direction: 'column',
              stateKey: `${coBorrowerKeyPrefix}:cb_property_owner`,
              itemIndex: index,
              validator: (newValue) => {
                return [newValue !== "", "Property Owner is required"]
              }
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'Current Address',
              value: '',
              type: 'separator',
              direction: 'row',
            },
            getAddrLine1Field(`${coBorrowerKeyPrefix}:cb_resi_addr_ln1`, cb_resi_addr_ln1),
            getAddrLine2Field(`${coBorrowerKeyPrefix}:cb_resi_addr_ln2`, cb_resi_addr_ln2),
            getStateField(`${coBorrowerKeyPrefix}:cb_state`, cb_state, `${coBorrowerKeyPrefix}:cb_city`, cb_city),
            getCityField(`${coBorrowerKeyPrefix}:cb_city`, cb_city, cb_state, `${coBorrowerKeyPrefix}:cb_state`),
            getPincodeField(`${coBorrowerKeyPrefix}:cb_pincode`, cb_pincode),
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'Permanent Address',
              value: '',
              type: 'separator',
              direction: 'row',
            },
            getAddrLine1Field(`${coBorrowerKeyPrefix}:cb_per_addr_ln1`, cb_per_addr_ln1),
            getAddrLine2Field(`${coBorrowerKeyPrefix}:cb_per_addr_ln2`, cb_per_addr_ln2),
            getStateField(`${coBorrowerKeyPrefix}:cb_per_state`, cb_per_state, `${coBorrowerKeyPrefix}:cb_per_city`, cb_per_city),
            getCityField(`${coBorrowerKeyPrefix}:cb_per_city`, cb_per_city, cb_per_state, `${coBorrowerKeyPrefix}:cb_per_state`),
            getPincodeField(`${coBorrowerKeyPrefix}:cb_per_pincode`, cb_per_pincode),
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'Validation Checklist',
              value: '',
              type: 'separator',
              direction: 'row',
            },
            ...coBorrowerSectionValidationCheckList,
          ],
        }
      }) ?? [],
      fieldsArr: [],
    },
    {
      section: 'guarantor_details',
      sectionName: 'Guarantor Details',
      isHideSection: guarantor?.filter((item)=>!item?.is_skip)?.length ? false : true,
      isContainsAccordion: true,
      isSubAccordion: false,
      subAccordionData: guarantor?.map((item, index)=>{
        const {
          gua_fname,
          gua_mname,
          gua_lname,
          gua_dob,
          gua_gender,
          gua_mobile,
          gua_email,
          gua_father_name,
          gua_pan,
          gua_resi_addr_ln1,
          gua_resi_addr_ln2,
          gua_state,
          gua_city,
          gua_pincode,
          gua_per_addr_ln1,
          gua_per_addr_ln2,
          gua_per_state,
          gua_per_city,
          gua_per_pincode,
          gua_aadhaar,
          _id
        } = item
        const guarantorIndex = index+1
        const guarantorSectionValidationCheckList = GET_FINAL_SECTION_VALIDATION_CHECKLIST(leadDetails?.[`Guarantor-${guarantorIndex}_final_submit`]?.validation_checklist ?? GUARANTOR_VALIDATION_CHECKLIST)
        const guarantorKeyPrefix = `guarantor-details:${_id}`;
        return {
          subIndex: guarantorIndex,
          section: 'guarantor_details',
          sectionName: 'Guarantor Details',
          isContainsAccordion: false,
          sectionStatusName: `Guarantor-${index}`,
          isSubAccordion: true,
          fieldsArr: [
            getFirstNameField(`${guarantorKeyPrefix}:gua_fname`, gua_fname),
            getMiddleNameField(`${guarantorKeyPrefix}:gua_mname`, gua_mname),
            getLastNameField(`${guarantorKeyPrefix}:gua_lname`, gua_lname),
            getDobField(`${guarantorKeyPrefix}:gua_dob`, gua_dob),
            getAgeField(gua_dob, `${guarantorKeyPrefix}:gua_dob`),
            getGenderField(`${guarantorKeyPrefix}:gua_gender`, gua_gender),
            getMobileNoField(`${guarantorKeyPrefix}:gua_mobile`, gua_mobile),
            getEmailField(`${guarantorKeyPrefix}:gua_email`, gua_email, true),
            getFathersFullNameField(`${guarantorKeyPrefix}:gua_father_name`, gua_father_name),
            getPanField(`${guarantorKeyPrefix}:gua_pan`, gua_pan),
            getAadharField(`${guarantorKeyPrefix}:gua_aadhaar`, gua_aadhaar),
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'Current Address',
              value: '',
              type: 'separator',
              direction: 'row',
            },
            getAddrLine1Field(`${guarantorKeyPrefix}:gua_resi_addr_ln1`, gua_resi_addr_ln1),
            getAddrLine2Field(`${guarantorKeyPrefix}:gua_resi_addr_ln2`, gua_resi_addr_ln2),
            getStateField(`${guarantorKeyPrefix}:gua_state`, gua_state, `${guarantorKeyPrefix}:gua_city`, gua_city),
            getCityField(`${guarantorKeyPrefix}:gua_city`, gua_city, gua_state, `${guarantorKeyPrefix}:gua_state`),
            getPincodeField(`${guarantorKeyPrefix}:gua_pincode`, gua_pincode),
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'Permanent Address',
              value: '',
              type: 'separator',
              direction: 'row',
            },
            getAddrLine1Field(`${guarantorKeyPrefix}:gua_per_addr_ln1`, gua_per_addr_ln1),
            getAddrLine2Field(`${guarantorKeyPrefix}:gua_per_addr_ln2`, gua_per_addr_ln2),
            getStateField(`${guarantorKeyPrefix}:gua_per_state`, gua_per_state, `${guarantorKeyPrefix}:gua_per_city`, gua_per_city),
            getCityField(`${guarantorKeyPrefix}:gua_per_city`, gua_per_city, gua_per_state, `${guarantorKeyPrefix}:gua_per_state`),
            getPincodeField(`${guarantorKeyPrefix}:gua_per_pincode`, gua_per_pincode),
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'Validation Checklist',
              value: '',
              type: 'separator',
              direction: 'row',
            },
            ...guarantorSectionValidationCheckList,
          ],
        }
      }) ?? [],
      fieldsArr: [],
    },
    {
      section: 'property_details',
      sectionName: 'Property Details',
      sectionStatusName: Section_Status_Name.Property_Details,
      isHideSection: _isEmpty(property) ? true : false,
      isContainsAccordion: false,
      isSubAccordion: false,
      subAccordionData: [],
      fieldsArr: [
        {
          label: 'Approx Value Of Property',
          value: property?.prop_approx_property_value?.toString(),
          type: 'custom_input_text_field',
          direction: 'column',
          stateKey: "property-details:prop_approx_property_value",
          validator: (newValue) => {
            if (newValue === "") {
              return [false, 'Approx value of property is required']
            } else {
              const isValid = validateData("number", newValue);
              return [isValid, ErrorConstants.SPECIAL_CHARACTERS_ARE_NOT_ALLOWED];
            }
          }
        },
        {
          label: 'Property Type',
          value: property?.prop_property_type,
          type: 'custom_dropdown',
          direction: 'column',
          stateKey: "property-details:prop_property_type",
          options: [
            { value: 'Occupied Residential Property', label: 'Occupied Residential Property' },
            { value: 'Self - occupied Commercial Property', label: 'Self - occupied Commercial Property' },
            { value: 'Constructed Residential Property', label: 'Constructed Residential Property' },
            { value: 'Constructed Commercial Property', label: 'Constructed Commercial Property' }
          ],
          validator: (newValue) => {
              return [newValue !== "", 'Property type is required'];
          }
        },
        {
          label: 'Plot Size',
          value: property?.prop_plot_size?.toString(),
          type: 'custom_input_text_field',
          direction: 'column',
          stateKey: "property-details:prop_plot_size",
          maxLength: 9,
          validator: (newValue) => {
            if (newValue === "") {
              return [false, 'Plot size is required'];
            } else {
              const max6Digit2DeciamlValRegex = /^\d{0,6}(?:\.\d{0,2})?$/;
              const isValid = max6Digit2DeciamlValRegex.test(newValue);
              return [isValid, "Please enter the plot size with up to 6 digits."];
            }
          }
        },
        {
          label: 'Build-up Area',
          value: property?.prop_built_up_area?.toString(),
          type: 'custom_input_text_field',
          direction: 'column',
          stateKey: "property-details:prop_built_up_area",
          validator: (newValue) => {
            if (newValue === "") {
              return [false, 'Built-up area is required'];
            } else {
              const max6Digit2DeciamlValRegex = /^\d{0,6}(?:\.\d{0,2})?$/;
              const isValid = max6Digit2DeciamlValRegex.test(newValue);
              return [isValid, "Please enter the Built-up Area with up to 6 digits."];
            }
          }
        },
        {
          label: 'Property Address',
          value: property?.prop_property_address,
          type: 'custom_input_text_field',
          direction: 'column',
          stateKey: "property-details:prop_property_address",
          validator: (newValue) => {
              return [newValue !== "", 'Property address is required'];
            
          }
        },
        {
          label: 'Nature Of Property',
          value: property?.prop_nature_of_property,
          type: 'custom_dropdown',
          direction: 'column',
          stateKey: "property-details:prop_nature_of_property",
          options: [
            { value: 'Non-Agriculture', label: 'Non-Agriculture' }
          ],
          validator: (newValue) => {
            const isValid = validateData("string", newValue);
            return [isValid, 'Nature of property is required']
          },
        },
        {
          label: 'Property Jurisdiction',
          value: property?.prop_property_jurisdiction,
          type: 'custom_dropdown',
          direction: 'column',
          stateKey: "property-details:prop_property_jurisdiction",
          validator: (newValue) => {
            const isValid = validateData("string", newValue);
            return [isValid, 'Property Jurisdiction is required']
          },
          options: [
            { value: 'Municipal Corporation', label: 'Municipal Corporation' },
            { value: 'Gram Panchayat', label: 'Gram Panchayat' },
            { value: 'Town & Country Planning', label: 'Town & Country Planning' },
            { value: 'Tehsildar', label: 'Tehsildar' },
            { value: 'Zilla Parishad', label: 'Zilla Parishad' },
            { value: 'Society', label: 'Society' },
            { value: 'Others', label: 'Others' }
          ]
        },
        {
          label: '',
          value: '',
          type: 'filler',
          direction: 'column',
        },
        {
          label: 'Ownership Details',
          value: '',
          type: 'separator',
          direction: 'row',
        },
        ...propertyOwnerDetails,
        ...fillers(propertyOwnerDetails),
        ...propertySectionValidationCheckList
      ],
    },
    {
      section: 'financial_documents',
      sectionName: 'Financial Documents',
      sectionStatusName: Section_Status_Name.Financial_Documents,
      isContainsAccordion: false,
      isHideSection: financeCheckList?.length ? false : true,
      isSubAccordion: false,
      subAccordionData: [],
      fieldsArr: financeCheckList
    },
    {
      section: 'shareholding_structure',
      sectionName: 'Shareholding Structure',
      sectionStatusName: Section_Status_Name.Share_Holding_Details,
      isContainsAccordion: false,
      isHideSection: !entity_details?.entity_type ? true : entity_details?.entity_type === Entity_Type.Individual ? true : !shareHolders?.length ? true: false,
      isSubAccordion: false,
      subAccordionData: [],
      fieldsArr: [
        ...shareHolders,
      ]
    },
    {
      section: 'property_documents',
      sectionName: 'Property Documents (For Credit Team)',
      sectionStatusName: Section_Status_Name.Property_Documents,
      isContainsAccordion: false,
      isSubAccordion: false,
      subAccordionData: [],
      fieldsArr: [
          
            {
              label: 'Valuation Report',
              value: 'file2.pdf',
              type: 'input_upload_field',
              direction: 'column',
              loanAppId: loan_app_id, 
              company_id, 
              product_id,
              isDisabled: false,
              propertyDocs: ([valuationReport] || Property_Valuation_Report_Upload).map(val => {
                return {...val, name: "Report"}
              })
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'Value',
              header: 'Acutal Property Value',
              value: property_documents?.prop_value,
              type: 'custom_input_text_field',
              direction: 'column',
              stateKey: actualPropertyStateKey,
              validator: actualPropertyValidator
            },
            {
              label: 'Submit',
              value: 'submit',
              type: 'input_button_field',
              direction: 'column',
              section: "property_documents",
              loanAppId: loan_app_id, 
              company_id, 
              product_id,
              isDisabled: shouldDisableAcutalPropertyValueSubmit
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'Approve',
              value: 'Approve',
              type: 'input_button_field',
              direction: 'column',
              loanAppId: loan_app_id, 
              company_id, 
              product_id,
              isDisabled: true
            },
            {
              label: "",
              value: '',
              type: 'accordion_divider',
              direction: 'column',
            },
            {
              label: 'Legal Report',
              value: 'file.pdf',
              type: 'input_upload_field',
              direction: 'column',
              loanAppId: loan_app_id, 
              company_id, 
              product_id,
              isDisabled: false,
              propertyDocs: ([legalReport] || Property_Legal_Report_Upload).map(val => {
                return {...val, name: "Report"}
              }) 
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'title document',
              value: '',
              type: 'separator',
              direction: 'row',
            },
            {
              label: "",
              value: "",
              type: "title_document_row",
              direction: 'column',
              loanAppId: loan_app_id, 
              company_id, 
              product_id,
              documentNameLabel,
              documents: propertyTitleDocuments
            }, 
            {
              label: '',
              value: '',
              type: 'filler',
              direction: 'column',
            },
            {
              label: 'Submit',
              value: 'submit',
              type: 'input_button_field',
              direction: 'column',
              loanAppId: loan_app_id, 
              company_id, 
              product_id,
              section: "title_document",
              isDisabled: shouldDisableTitleReportSubmit
            },
      ]
    },
    {
      section: 'additional_documents',
      sectionName: 'Additional Documents',
      sectionStatusName: 'Additional Document',
      isContainsAccordion: false,
      isSubAccordion: false,
      subAccordionData: [],
      fieldsArr: [
        ...additionalDocs,
        {
          label: 'Comment',
          value: addi_docs_comment,
          type: 'subHeading',
          direction: 'column',
        },
      ]
    },
  ]};