import React, { useEffect, useState } from 'react';
import { storedList } from 'util/localstorage';
import { useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import EditableAccordian from '../../Components/EditableAccordion.jsx';
import { getBookLoanDetailsWatcher } from '../../../../../actions/bookLoan.action';
import { getLapCamsDataWatcher, updateLapCamsDataWatcher } from '../../../../../actions/camsDetails';
// import { getCamsDataWatcher as getLapCamsDataWatcher, updateCamsDataWatcher as updateLapCamsDataWatcher } from 'actions/camsDetails';
import { GSTData, CommercialBureau, getCamsConsumerBureau } from '../../mockData';
import { getCamsStateValue, replaceStringVl, validateCamsData } from "./helper";
import { checkAccessTags } from '../../../../../../util/uam';
import ArrowDown from '../../../../../assets/ArrowDown.svg';
import ArrowUp from '../../../../../assets/ArrowUp.svg';
import SlidingAlert from 'lap/components/SlidingAlert/SlidingAlert';
import './CamsDetailsTab.scss';

const user = { _id: storedList('user')?._id, id: storedList('user')?.id };

const parentButtonStyle = {
    position: 'relative',
    padding: "20px",
    width: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'right',
};

const buttonSaveStyle = {
    padding: '12px 24px',
    borderRadius: '40px',
    backgroundColor: "#fff",
    cursor: 'pointer',
    outline: 'none',
    border: '1px solid #134cde',
    gap: '10px',
    color: '#134cde',
    width: 'max-content',
};

const buttonNextStyle = {
    padding: '12px 24px',
    borderRadius: '40px',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    gap: '10px',
    background: 'linear-gradient(180deg, #134CDE 0%, #163FB7 100%, #163FB7 100%)',
    color: 'white',
    width: 'max-content',
};

const disabledNextBtnStyle = {
    background: "none",
    backgroundColor: "rgb(204, 205, 211)",
    color: "white",
	opacity: "0.65",
    boxShadow: "none",
	background: "none"
}


function CamsDetailsTab(props) {
    const { loanAppId, isEdit, setIsEdit } = props;
    const [storedFieldValues, setStoredFieldValues] = useState({});
    const [validationErrorValues, setValidationErrorValues] = useState({});
    const [leadDetails, setLeadDetails] = useState({});
    const [LAPCompanyId, setLAPCompanyId] = useState('');
    const [LAPProductId, setLAPProductId] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [openSection, setOpenSection] = useState(null);
    const [noOfCoBorrowers, setNoOfCoBorrowers] = useState(0);
    const [noOfGuarantors, setNoOfGuarantors] = useState(0);;
    const camsSeedData = [GSTData, getCamsConsumerBureau(noOfCoBorrowers, noOfGuarantors), CommercialBureau];
    const dispatch = useDispatch();
    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };
    // console.log("isEdit", isEdit)

    const styles = {
        accordionSection: {
            border: '1px solid #C4C8D3',
            marginBottom: '10px',
            borderRadius: '0.5rem',
            overflow: 'hidden',
            width: '97.2%',
            margin: 'auto',
            paddingBottom: openSection == 'section1' ? ' 22px' : '0px',
        },
    
        accordionSectionBureau: {
            border: '1px solid #C4C8D3',
            marginBottom: '10px',
            borderRadius: '0.5rem',
            overflow: 'hidden',
            width: '97.2%',
            margin: 'auto',
            paddingBottom: openSection == 'section2' ? ' 22px' : '0px',
        },
    
        accordionHeader: {
            //   backgroundColor: '#FAFAFA',
            padding: '18px',
            cursor: 'pointer',
            fontSize: '1.125rem',
            color: '#1C1C1C',
            fontWeight: '600',
            display: 'flex',
            justifyContent: 'space-between',
        },
    
        accordionContent: {
            padding: '10px',
            display: 'none',
        },
    };
    const ERROR = "error";
    const SUCCESS = "success";
    const ALERT_MESSAGE = {
        failed_to_fetch_cams_data: 'Failed to fetch cams data.',
        something_went_wrong: 'Something went Wrong'
    }

    const handleAlertClose = () => {
        setAlert(false);
        setSeverity('');
        setAlertMessage('');
    };
    
    const showAlert = (msg, type) => {
        try {
          setAlert(true);
          setSeverity(type);
          setAlertMessage(msg);
          setTimeout(() => {
            handleAlertClose();
          }, 3000);
        } catch (error) {
          console.log(error)
        }
    };

    const getCamsMandatoryPayloadFields = () => {
        // console.log("setting payload")
        // console.log("LAPCompanyId", LAPCompanyId);
        // console.log("LAPProductId", LAPProductId);
        // console.log("loanAppId", loanAppId);
        return {
            loan_app_id: loanAppId,
            company_id: LAPCompanyId,
            user_id: user.id ? user.id : user._id,
            user:{
                _id: user.id ? user.id : user._id,
            },
            product_id: LAPProductId,
        }
    }
    const getLoanData = () => {
        if (loanAppId) {
        //   console.log("calling getBookLoanDetailsWatcher");
          const payload = {
            loan_app_id: loanAppId,
            user: user,
          };
          new Promise((resolve, reject) => {
            dispatch(getBookLoanDetailsWatcher(payload, resolve, reject));
          })
            .then((response) => {
                if(response?.data?.length){
                    setLeadDetails((prevData)=> ({...prevData, ...response?.data[0]}));
                }
            })
            .catch((e) => {
                showAlert(ALERT_MESSAGE.something_went_wrong, ERROR);
            });
        }
    };
    // PATCH UPDATE CAMS DATA API
    const updateCamsData = async () => {
        const updatedStateData = replaceStringVl(storedFieldValues);
        try {
            const payload = {
                ...getCamsMandatoryPayloadFields(),
                ...updatedStateData,
            };

            for (let key in payload) {
                if (payload[key] === '') {
                    delete payload[key];
                }
            }
            // console.log("updatePayload", payload);

            new Promise((resolve, reject) => {
                dispatch(updateLapCamsDataWatcher(payload, resolve, reject));
            })
                .then((response) => {
                    showAlert(response?.message, SUCCESS);
                    setAlert(true);
                    setSeverity('success');
                    setAlertMessage(response?.message);
                    setTimeout(() => {
                        handleAlertClose();
                    }, 4000);
                    setIsEdit(false);
                })
                .catch((error) => {
                    showAlert(error?.message, ERROR);
                    setAlert(true);
                    setSeverity('error');
                    setAlertMessage(error?.message);
                    setTimeout(() => {
                        handleAlertClose();
                    }, 4000);
                });
        } catch (error) {
            console.error('Error in getCamsData:', error);
        }
    };
    // showAlert("testing", SUCCESS);
    const updateStoredFieldValuesWithCamsData = (data) => {
        let newStoredFieldValues = {};
        let newValidationErrorValues = {}
        
        camsSeedData.forEach(mainAccordianData => {
            mainAccordianData.forEach(subAccordian => {
                subAccordian?.data.forEach(fieldSeedData => {
                    const {stateValue, errorValue} = getCamsStateValue(fieldSeedData, data);
                    newStoredFieldValues = {...newStoredFieldValues, ...stateValue }
                    newValidationErrorValues = {...newValidationErrorValues, ...errorValue }
                });
            })
        });
        setStoredFieldValues(newStoredFieldValues);
        setValidationErrorValues(newValidationErrorValues);
    }

    const getCamsData = (isOnDiscard = false) => {
        const payload = getCamsMandatoryPayloadFields();
        new Promise((resolve, reject) => {
            dispatch(getLapCamsDataWatcher(payload, resolve, reject));
        })
            .then((response) => {
                updateStoredFieldValuesWithCamsData(response?.data);
                if(isOnDiscard) {
                    showAlert(response?.message, SUCCESS);
                    setAlert(true);
                    setSeverity('success');
                    setAlertMessage("Changes discarded successfully.");
                    setTimeout(() => {
                        handleAlertClose();
                    }, 4000);
                    setIsEdit(false);
                }
            })
            .catch((error) => {
                console.log('error::', error);
                showAlert(error?.response?.data?.message || ALERT_MESSAGE.failed_to_fetch_cams_data, ERROR);
            });
    };

    const onFieldValueChange = (e) => {
        const { name, value } = e.target;
        const [type = ""] = name.split("_");
        // console.log("e.target", e.target, JSON.stringify(e))
        const mandatoryFields = ["urc_pan", "urc_udym_reg_dt", "urc_turnover", "urc_reg_no", "urc_pin", "urc_owner_name", "urc_org_type", "urc_nic", "urc_name_of_ent", "urc_investment", "urc_incorporation_dt", "urc_ent_activity", "urc_commencement_dt"]
        const [_, fieldName =""] = (name.split("_vl_") || []);
        const isMandatoryField = mandatoryFields.includes(fieldName);
        const isValid = validateCamsData(type, value, isMandatoryField);
        setStoredFieldValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setValidationErrorValues((prevState) => ({
            ...prevState,
            [`${name}State`]: isValid ? "" : "has-danger"
        }));
    };

    const onSubmit = () => {
        updateCamsData();
    }
    const onDiscard = () => {
        getCamsData(true);
    }

    useEffect(() => {
        if(_isEmpty(leadDetails)){
            getLoanData();
        }
    }, [loanAppId]);

    useEffect(() => {
        if(!_isEmpty(leadDetails)){
            const coborrowers = leadDetails?.coborrower || [];
            const guarantors = leadDetails?.guarantor || [];
            setLAPCompanyId(leadDetails?.company_id);
            setLAPProductId(leadDetails?.product_id);
            setNoOfCoBorrowers(coborrowers.length);
            setNoOfGuarantors(guarantors.length);
        }
    }, [leadDetails]);

    useEffect(() => {
        getCamsData();
    }, [loanAppId, LAPCompanyId, LAPProductId]);

    const [updatedGSTData, updatedConsumerBureau, updatedCommercialBureau] = camsSeedData.map(accordion => {
        return accordion.map(subAccordion => {
            const data = subAccordion.data.map(field => {
                return {...field, validationmsg: `Please enter valid ${field.title}`}
            })
            return {
                title: subAccordion.title,
                data
            }
        })
    })
    // console.log("updatedConsumerBureau", updatedConsumerBureau);
    const errorValues = isEdit ? validationErrorValues : {};
    const shouldDisableSave = Object.values(errorValues).includes("has-danger");
    const saveBtnStyle = shouldDisableSave ? {...buttonNextStyle, ...disabledNextBtnStyle} : buttonNextStyle;
    return (
        <div className={`camsSection ${isEdit ? "editMode": "viewMode"}`}>
            {/* GST Accordion              */}
            <EditableAccordian
                accordionData={updatedGSTData}
                customClass={{
                    width: '97.3%',
                    marginLeft: '1.25%',
                    alignSelf: 'center',
                }}
                stateData={storedFieldValues}
                isEditable={isEdit}
                onChange={onFieldValueChange}
                key={'cam'}
                validationData={errorValues}
            />

            {/* Consumer Accordion              */}
            <div style={{ marginTop: '1.23rem' }}>
                <div style={styles.accordionSectionBureau} className="accordion-section">
                    <div style={styles.accordionHeader} className="accordion-header" onClick={() => toggleSection('section3')}>
                        <div> Consumer Bureau </div>
                        <div>
                            <img src={openSection ? ArrowUp : ArrowDown} alt="ArrowDown" />
                        </div>
                    </div>
                    {openSection === 'section3' && (
                        <div className="accordion-content">
                            <EditableAccordian
                                accordionData={updatedConsumerBureau}
                                customClass={{
                                    width: '97.3%',
                                    marginLeft: '1.25%',
                                    alignSelf: 'center',
                                }}
                                stateData={storedFieldValues}
                                isEditable={isEdit}
                                onChange={onFieldValueChange}
                                key={'cam'}
                                validationData={errorValues}
                             />
                        </div>
                    )}
                </div>
            </div>

            {/* Commercial Accordion              */}
            <EditableAccordian
                accordionData={updatedCommercialBureau}
                customClass={{
                    width: '97.3%',
                    marginLeft: '1.25%',
                    alignSelf: 'center',
                }}
                stateData={storedFieldValues}
                isEditable={isEdit}
                onChange={onFieldValueChange}
                key={'cam'}
                validationData={errorValues}
            />

            {/* Financial Accordion             
            <EditableAccordian
                accordionData={FinancialData}
                customClass={{
                    width: '97.3%',
                    marginLeft: '1.25%',
                    alignSelf: 'center',
                }}
                stateData={storedFieldValues}
                isEditable={true}
                onChange={onFieldValueChange}
                key={'cam'}
                validationData={{}}
            /> */}
            <div style={parentButtonStyle} className="parentButtonStyle">
                {/* {!checkAccessTags(['tag_offer_deviation_risk']) && checkAccessTags(['tag_msme_lead_view_int_read_write',"tag_msme_lead_view_int_cams_read_write"]) ? */}
                    {
                        isEdit 
                            ? <>
                                <button onClick={onDiscard} style={buttonSaveStyle} className="buttonSave">
                                    Discard
                                </button>
                                <button onClick={onSubmit} style={saveBtnStyle} className="buttonNext" disabled={shouldDisableSave}>
                                    Submit
                                </button> 
                            </> 
                            : null
                    }
                {/* : null} */}
            </div>
            {alert ? <SlidingAlert type={severity} message={alertMessage} handleClose={handleAlertClose} /> : null}
        </div>
    )
}

export default CamsDetailsTab
