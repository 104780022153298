import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { loanCreationJsonFields } from '../loanCreation/loanCreationJson';
import { CustomAccordion, DocumentsList, Tooltip } from '../../../components/msme.component';
import useDimensions from 'hooks/useDimensions';
import Grid from '@mui/material/Grid';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getBorrowerDetailsByIdWatcher } from '../../../../actions/borrowerInfo';
import Preloader from '../../../../components/custom/preLoader';
import TabButton from 'react-sdk/dist/components/TabButton';
import SanctionLenderLead from '../../leads/sanctionAndLender/sanctionLenderLead.view';
import { Natch } from 'msme/views/msme.view';
import Validation from '../../../../views/lending/manage/validation';
import { AlertBox } from '../../../../components/CustomAlertbox';
import { getMsmeLeadReviewDetailsWatcher } from 'msme/actions/lead.action';
import { storedList } from 'util/localstorage';
import { convertDataIntoAccordionData } from 'util/msme/helper';
import StatusIcon from 'msme/components/StatusIcon/StatusIcon';
import { deepCopy } from 'util/msme/helper';
import { AuditLog } from 'msme/components/AuditLog/AuditLog';
import { getMsmeActivityLogsWatcher } from 'msme/actions/lead.action';
import { getInsuranceDetailsWatcher, executeInsuranceDetailsWatcher } from 'msme/actions/msme.action';
import { getMsmeLoanDocumentsWatcher } from 'msme/actions/bookLoan.action';
import { docSectionCode } from 'msme/config/docSectionCode';
import { documentCode as documentCodeList } from 'msme/config/docCode';
import CamsSection from '../loanCreation/camsSection';
import { checkAccessTags } from 'util/uam';
import { toOrdinalCounting } from 'util/msme/helper';
import CustomButton from 'react-sdk/dist/components/Button/Button';
import moreSquareIcon from 'assets/img/more-square.svg';
import RepaymentScheduleListDemographics from '../../../../views/lending/repaymentScheduleListDemographics';
import RepaymentDemographics from '../../../../views/lending/repaymentDemographics';
import TransactionsHistoryList from '../../../../views/lending/transactionsHistoryList';
import RefundDemographics from '../../../../views/lending/RefundDemographics';
import ChargeRecordForm from '../../../../views/lending/Charges/chargeRecordForm';
import StatementOfAccount from '../../../../views/lending/statementOfAccount';
import StatusLogs from '../../../../views/lending/StatusLogs';
import { getAllCompaniesWatcher } from 'actions/company';
import { getProductByIdWatcher } from 'actions/product';
import './loanDetailsAccordion.css';
import { convertIntoINR } from 'util/msme/helper';
import { isValidField } from 'util/msme/helper';
import FormPopup from 'react-sdk/dist/components/Popup/FormPopup';

const user = { _id: storedList('user')?._id, id: storedList('user')?.id };

export default function loanDetailsAccordion(props) {
  const { innerWidth, innerHeight } = useDimensions();
  const isLoading = useSelector((state) => state.profile.loading);
  const styles = useStyles({ innerWidth, innerHeight });
  const { loan_id, company_id, product_id } = useParams();
  const [loanData, setLoanData] = useState(null);
  const dispatch = useDispatch();
  const paramsData = useLocation().search.split('=').slice(-1);
  const selectedTab = decodeURIComponent(paramsData);
  const [validationData, setValidationData] = useState({});
  const [loanDetailsData, setLoanDetailsData] = useState(null);
  const [leadDetailData, setLeadDetailData] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [sectionRemarks, setSectionRemarks] = useState({});
  const [validationRemarks, setValidationRemarks] = useState({});
  const [disableApproveBtn, setDisableApproveBtn] = useState('false');
  const [accordionData, setAccordionData] = useState([]);
  const [leadStatus, setLeadStatus] = useState('');
  const [auditLogs, setAuditLogs] = useState([]);
  const [loanDocuments, setLoanDocuments] = useState([]);
  const [isReject, setIsReject] = useState(false);
  const [rejectReason, setRejectReason] = useState(false);
  const [rejectRemark, setRejectRemark] = useState(false);
  const [isSl, setIsSl] = useState(false);
  const [isLba, setIsLba] = useState(false);
  const [viewFile, setViewFile] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [insuranceData, setInsuranceData] = useState({});
  const [insurancePopup, setInsurancePopup] = useState(false);
  const [insurancePopupData, setInsurancePopupData] = useState({});

  const useAsyncState = (initialState) => {
    const [state, setState] = useState(initialState);

    const asyncSetState = (value) => {
      return new Promise((resolve) => {
        setState(value);

        setState((current) => {
          resolve(current);

          return current;
        });
      });
    };

    return [state, asyncSetState];
  };
  const [showActionList, setShowActionList] = useState(false);
  const [repaymentScheduleList, setRepaymentScheduleListModel] = useState(false);
  const [transactionHistoryList, setTransactionHistoryListModel] = useState(false);
  const [refundModel, setRefundModel] = useState(false);
  const [chargeRecordModel, setChargeRecordModel] = useState(false);
  const [statementOfAccount, setStatementOfAccount] = useState(false);
  const [openStatusLogs, setOpenStatusLogs] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [repayment, setRepaymentModel] = useState(false);
  const [optionData, setOptionData] = useState({});
  const [companyData, setCompanyData] = useAsyncState(null);
  const [productData, setProductData] = useAsyncState(null);
  const tabsAvailable = checkAccessTags(['tag_msme_lead_view_int_read']) || checkAccessTags(['tag_msme_lead_view_int_read_write']) ? ['Lead Details', 'Documents', 'Cams', 'Loan Details', 'SL & LBA', 'NACH'] : ['Loan Details', 'SL & LBA', 'NACH'];
  const [leadDetailSection, setLeadDetailSection] = useState(tabsAvailable.includes(selectedTab) ? selectedTab.toLowerCase() : tabsAvailable[0].toLowerCase());
  const params = useParams();
  const history = useHistory();

  const options = ['Repayment', 'Repayment Schedule', 'Transaction History', 'Refund', 'Settlement Offer', 'Loan Recon', 'Record Charge', 'Status Change Logs', 'Fees And Charges', 'Statement of account', 'Waiver Request', 'Foreclosure Details'];

  if (checkAccessTags(['tag_msme_lead_view_int_read']) || checkAccessTags(['tag_msme_lead_view_int_read_write'])) {
    tabsAvailable.push(...options);
  }

  const newRef = useRef(null);
  const maxTabsToShowInMain = 4;
  const [mainTabs, setMainTabs] = useState(tabsAvailable.slice(0, maxTabsToShowInMain));
  const [remainingTabs, setRemainingTabs] = useState(tabsAvailable.slice(maxTabsToShowInMain));

  const handleOpenInNewPage = (url, page) => {
    window.open(`${url}`, `${page || '_blank'}`);
  };

  const handleActionMenuItemClick = async (action) => {
    switch (action) {
      case 'Repayment':
        setShowActionList(false);
        dispatch(
          getAllCompaniesWatcher(
            async (result) => {
              const companyResp = result.filter((item) => parseInt(company_id) === item._id)[0];
              await setCompanyData(companyResp);
              dispatch(
                getProductByIdWatcher(
                  product_id,
                  async (productResp) => {
                    await setProductData(productResp);
                    setRepaymentModel(true);
                  },
                  (productError) => {},
                ),
              );
            },
            (error) => {},
          ),
        );
        break;

      case 'Repayment Schedule':
        setShowActionList(false);
        dispatch(
          getAllCompaniesWatcher(
            async (result) => {
              const companyResp = result.filter((item) => parseInt(company_id) === item._id)[0];
              await setCompanyData(companyResp);
              dispatch(
                getProductByIdWatcher(
                  product_id,
                  async (productResp) => {
                    await setProductData(productResp);
                    setRepaymentScheduleListModel(true);
                  },
                  (productError) => {},
                ),
              );
            },
            (error) => {},
          ),
        );
        break;

      case 'Transaction History':
        setShowActionList(false);
        setTransactionHistoryListModel(true);
        break;

      case 'Refund':
        setShowActionList(false);
        setRefundModel(true);
        break;

      case 'Settlement Offer':
        setShowActionList(false);
        handleOpenInNewPage(`/admin/msme/settlement-request/${loan_id}/${company_id}/${product_id}`);
        break;

      case 'Loan Recon':
        setShowActionList(false);
        handleOpenInNewPage(`/admin/msme/loan-recon-details/${company_id}/${product_id}/${loan_id}`);
        break;

      case 'Statement of account':
        setShowActionList(false);
        setStatementOfAccount(true);
        break;

      case 'Record Charge':
        setShowActionList(false);
        dispatch(
          getAllCompaniesWatcher(
            async (result) => {
              const companyResp = result.filter((item) => parseInt(company_id) === item._id)[0];
              await setCompanyData(companyResp);
              dispatch(
                getProductByIdWatcher(
                  product_id,
                  async (productResp) => {
                    await setProductData(productResp);
                    setChargeRecordModel(true);
                  },
                  (productError) => {},
                ),
              );
            },
            (error) => {},
          ),
        );
        break;

      case 'Status Change Logs':
        setShowActionList(false);
        setOpenStatusLogs(true);
        break;

      case 'Fees And Charges':
        setShowActionList(false);
        handleOpenInNewPage(`/admin/msme/charges/${company_id}/${product_id}/${loan_id}`);
        break;

      case 'Waiver Request':
        setShowActionList(false);
        handleOpenInNewPage(`/admin/msme/waiver-request-list/${company_id}/${product_id}/${loan_id}`);
        break;

      case 'Foreclosure Details':
        setShowActionList(false);
        handleOpenInNewPage(`/admin/msme/foreclosure-offers-requests/${loan_id}/${company_id}/${product_id}`);
        break;
    }
  };

  const objectsNotRequired = ['bene_confirm_bank_acc_no', 'borro_confirm_bank_acc_no'];
  const newArray = loanCreationJsonFields.filter((obj) => !objectsNotRequired.includes(obj.field));
  const groupedData = newArray.reduce((result, item) => {
    if (!result[item.dept]) {
      result[item.dept] = [];
    }
    result[item.dept].push({
      title: item.title,
      type: item.type,
      field: item.field,
      isOptional: item.isOptional,
      validationmsg: item.validationmsg,
    });
    return result;
  }, {});

  if (checkAccessTags(['tag_msme_lead_view_int_read']) || checkAccessTags(['tag_msme_lead_view_int_read_write'])) {
    groupedData['Loan Details'].push(
      {
        title: 'Interest type',
        field: 'interest_rate_type',
      },
      {
        title: 'EMI',
        field: 'emi_amount',
      },
      {
        title: 'Total interest amount',
        field: 'total_interest',
      },
    );
  }

  const initializeLoanDocuments = (documents) => {
    const subsectionsDocList = [];

    for (const key in docSectionCode) {
      if (docSectionCode.hasOwnProperty(key)) {
        if (key === 'Co-Applicant' || key === 'Guarantor') {
          const DocList = documents.filter((item) => docSectionCode[key].includes(item.code));
          if (DocList.length) {
            const groupedCoApplicantDocList = DocList.reduce((acc, obj) => {
              const key = obj.borrower_id;

              if (!acc[key]) {
                acc[key] = [];
              }

              acc[key].push(obj);

              return acc;
            }, {});
            let count = 1;
            for (const newkey in groupedCoApplicantDocList) {
              if (groupedCoApplicantDocList.hasOwnProperty(newkey)) {
                const subsectionDocList = {
                  title: `${key}-${count}`,
                };
                subsectionDocList['docs'] = groupedCoApplicantDocList[newkey];
                subsectionsDocList.push(subsectionDocList);
                count = count + 1;
              }
            }
          }
        } else if (key === 'Financial Documents') {
          let DocListFinancialSection = documents.filter((item) => docSectionCode[key].includes(item.code));
          const bankStatementDoc = DocListFinancialSection.filter((item) => item.code === documentCodeList.msme_bank_statement);
          if (bankStatementDoc.length) {
            DocListFinancialSection = DocListFinancialSection.filter((item) => item.code !== documentCodeList.msme_bank_statement);
            for (let i = 0; i < bankStatementDoc[0].additional_file_url.length; i++) {
              if (bankStatementDoc[0].additional_file_url[i]) {
                let newBankDoc = { ...bankStatementDoc[0] };
                newBankDoc.file_url = bankStatementDoc[0].additional_file_url[i];
                newBankDoc.doc_index = i;
                newBankDoc.file_type = `${newBankDoc.file_type} ${toOrdinalCounting(i + 1)} year`;
                DocListFinancialSection.push(newBankDoc);
              } else {
                break;
              }
            }
          }
          const subsectionDocList = {
            title: key,
          };
          subsectionDocList['docs'] = DocListFinancialSection;
          if (subsectionDocList['docs'].length) subsectionsDocList.push(subsectionDocList);
        } else {
          const subsectionDocList = {
            title: key,
          };
          subsectionDocList['docs'] = documents.filter((item) => docSectionCode[key].includes(item.code));
          if (subsectionDocList['docs'].length) subsectionsDocList.push(subsectionDocList);
        }
      }
    }
    setLoanDocuments(subsectionsDocList);
  };

  const getLoanDocuments = () => {
    const payload = {
      loanAppID: loanDetailsData.loan_app_id,
      companyId: company_id,
      productId: product_id,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getMsmeLoanDocumentsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setViewFile(response);
        initializeLoanDocuments(response);
      })
      .catch((error) => {
        showAlert(error.response?.data?.message, 'error');
      });
  };

  const fetchActivityLogs = () => {
    const payload = {
      loanAppId: loanDetailsData.loan_app_id,
      companyId: company_id,
      productId: product_id,
      user: user,
    };
    new Promise((resolve, reject) => {
      dispatch(getMsmeActivityLogsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setAuditLogs(response);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const fetchLoandetails = () => {
    const params = {
      company_id: company_id,
      product_id: product_id,
      loan_id: loan_id,
    };
    dispatch(
      getBorrowerDetailsByIdWatcher(
        params,
        (result) => {
          setIsSl(result?.data?.sl_req_sent ? result?.data?.sl_req_sent : false);
          setIsLba(result?.data.lba_req_sent ? result?.data?.lba_req_sent : false);
          setOptionData(result.data);
          let loanData = result.data;
          for (let item in loanData) {
            if (isValidField(item)) {
              loanData[item] = loanData[item];
            }
          }
          loanData['processing_fees_amt'] = parseFloat(loanData['processing_fees_amt']) + parseFloat(loanData['gst_on_pf_amt'] ?? 0);
          loanData['processing_fees_amt'] = convertIntoINR(loanData['processing_fees_amt']);
          loanData['conv_fees'] = convertIntoINR(loanData['conv_fees']);
          loanData['net_disbur_amt'] = convertIntoINR(loanData['net_disbur_amt']);
          loanData['sanction_amount'] = convertIntoINR(loanData['sanction_amount']);
          delete loanData.gst_on_pf_amt;
          setValidationData({ ...result.data, loanStatus: loanData.status });
          setLoanDetailsData({ ...result.data });
          setLoanData(
            Object.keys(groupedData).map((dept) => {
              return {
                title: dept === 'Enter Bank Beneficiary Details' ? 'Bank Beneficiary Details' : dept === 'Enter Borrower Bank Details' ? 'Borrower Bank Details' : dept,
                data: groupedData[dept].map((item) => ({
                  head: <span style={styles['accordionHeader']}>{item.title.toUpperCase()}</span>,
                  body: <span style={styles['accordionBody']}>{item?.field === 'loan_amt' ? (loanData['sanction_amount'] ? loanData['sanction_amount'] : 'N/A') : loanData[item?.field] && item.type === 'Date' ? loanData[item?.field].substring(0, 10) : loanData[item?.field] ? loanData[item?.field] : 'N/A'} </span>,
                })),
              };
            }),
          );
        },
        (error) => {
          //   return showAlert(error.response.data.message, "error");
        },
      ),
    );
  };

  const formatAccordHeader = (label) => <span style={styles['accordionHeader']}>{label.toUpperCase()}</span>;
  const formatAccordBody = (value) => <span style={styles['accordionBody']}>{typeof value === 'string' && value.trim() ? value : 'N/A'}</span>;
  const formatValidationChecklistStatus = (value, remarks) => (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '105px' }}>
        <StatusIcon status={value.toLowerCase()} />
        {value == 'in_review' || value == 'rejected' ? <Tooltip content={remarks ? remarks : ''} /> : <div style={{ width: '21px', height: '18px', backgroundColor: 'transparent' }} />}
      </div>
    </React.Fragment>
  );

  const fetchLeadDetails = () => {
    const payload = {
      loanAppId: loanDetailsData.loan_app_id,
      companyId: company_id,
      productId: product_id,
      user: user,
    };

    new Promise((resolve, reject) => {
      dispatch(getMsmeLeadReviewDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        delete response.company_id;
        delete response.product_id;
        setLeadStatus(response.lead_status);
        delete response.lead_status;
        setLeadDetailData(response);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message, 'error');
      });
  };

  const formatStatus = (value) => <StatusIcon status={value.toLowerCase()} />;

  const formattedData = (leadArray) => {
    let leadReviewData = leadArray;
    let tempReviewData = deepCopy(leadReviewData);
    const statusCheck = {};
    const bottomComponents = {};
    let tempRemarks = {};
    let tempValidationRemarks = {};

    for (let lead in tempReviewData) {
      let accordionStatus = tempReviewData[lead].status;

      if (tempReviewData[lead].status) {
        statusCheck[lead] = formatStatus(tempReviewData[lead].status);
        delete tempReviewData[lead].status;
      }

      if (tempReviewData[lead].validation_checklist_remarks) {
        tempValidationRemarks = {
          ...tempValidationRemarks,
          ...tempReviewData[lead].validation_checklist_remarks,
        };
        delete tempReviewData[lead].validation_checklist_remarks;
      }

      if (tempReviewData[lead]) {
        tempRemarks[lead] = tempReviewData[lead].remarks;
        delete tempReviewData[lead].remarks;
      }
    }

    var coapplicant_details = tempReviewData['co-applicant_details'] ? Object.keys(tempReviewData['co-applicant_details']) : [];
    var guarantor_details = tempReviewData['guarantor_details'] ? Object.keys(tempReviewData['guarantor_details']) : [];

    for (let coapplicant of coapplicant_details) {
      let accordionStatus = tempReviewData['co-applicant_details'][coapplicant].status;
      if (tempReviewData['co-applicant_details'][coapplicant].status) {
        statusCheck[coapplicant] = formatStatus(tempReviewData['co-applicant_details'][coapplicant].status);
        delete tempReviewData['co-applicant_details'][coapplicant].status;
      }

      if (tempReviewData['co-applicant_details'][coapplicant].validation_checklist_remarks) {
        tempValidationRemarks = {
          ...tempValidationRemarks,
          ...tempReviewData['co-applicant_details'][coapplicant].validation_checklist_remarks,
        };
        delete tempReviewData['co-applicant_details'][coapplicant].validation_checklist_remarks;
      }

      if (tempReviewData['co-applicant_details'][coapplicant]) {
        tempRemarks[coapplicant] = tempReviewData['co-applicant_details'][coapplicant].remarks;
        delete tempReviewData['co-applicant_details'][coapplicant].remarks;
      }
    }

    for (let guarantor of guarantor_details) {
      let accordionStatus = tempReviewData['guarantor_details'][guarantor].status;
      if (tempReviewData['guarantor_details'][guarantor].status) {
        statusCheck[guarantor] = formatStatus(tempReviewData['guarantor_details'][guarantor].status);
        delete tempReviewData['guarantor_details'][guarantor].status;
      }

      if (tempReviewData['guarantor_details'][guarantor].validation_checklist_remarks) {
        tempValidationRemarks = {
          ...tempValidationRemarks,
          ...tempReviewData['guarantor_details'][guarantor].validation_checklist_remarks,
        };
        delete tempReviewData['guarantor_details'][guarantor].validation_checklist_remarks;
      }

      if (tempReviewData['guarantor_details'][guarantor]) {
        tempRemarks[guarantor] = tempReviewData['guarantor_details'][guarantor].remarks;
        delete tempReviewData['guarantor_details'][guarantor].remarks;
      }
    }
    setSectionRemarks(tempRemarks);
    setValidationRemarks(tempValidationRemarks);

    let data = convertDataIntoAccordionData(
      tempReviewData,
      formatAccordHeader,
      formatAccordBody,
      ['validation_checklist'],
      {
        validation_checklist: (value, key) => {
          return formatValidationChecklistStatus(value, tempValidationRemarks[key]);
        },
      },
      statusCheck,
      bottomComponents,
      [...coapplicant_details, ...guarantor_details],
    );

    let allApproved = data.every((item) => item.rightComponent.props.status.toUpperCase() === 'APPROVED');
    setDisableApproveBtn(!allApproved);

    setAccordionData(data);
  };

  useEffect(() => {
    fetchLoandetails();
  }, []);

  useEffect(() => {
    if (loanDetailsData && loanDetailsData.loan_app_id) {
      fetchLeadDetails();
      fetchActivityLogs();
      getLoanDocuments();
      fetchInsurance();
    }
  }, [loanDetailsData]);

  useEffect(() => {
    if (Object.keys(leadDetailData).length != 0) {
      formattedData(leadDetailData);
    }
  }, [leadDetailData]);

  const handleNavigate = (navState) => {
    history.replace(`/admin/msme/loan_details/${loan_id}/${company_id}/${product_id}?tab=${navState}`);
  };

  const onValidationError = (message) => {
    showAlert(message, 'error');
  };

  const onValidationSuccess = (message) => {
    showAlert(message, 'success');
  };

  const showAlert = (msg, type) => {
    setAlert(true);
    setSeverity(type);
    setAlertMessage(msg);
    setTimeout(() => {
      handleAlertClose();
    }, 3000);
  };

  const handleAlertClose = () => {
    setAlert(false);
    setSeverity('');
    setAlertMessage('');
  };

  const handleTabClick = (clickedTab) => {
    if (options.includes(clickedTab)) {
      handleActionMenuItemClick(clickedTab);
    } else {
      handleNavigate(clickedTab);
      setLeadDetailSection(clickedTab.toLowerCase());
      const selectedIndex = remainingTabs.findIndex((tab) => tab === clickedTab);
      const newRemainingTabs = [...remainingTabs];
      newRemainingTabs[selectedIndex] = mainTabs[maxTabsToShowInMain - 1];
      const newMainTabs = [...mainTabs];
      newMainTabs[maxTabsToShowInMain - 1] = clickedTab;
      setMainTabs(newMainTabs);
      setRemainingTabs(newRemainingTabs);
      setShowActionList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showActionList]);

  const handleOutsideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target) && e.target !== document.querySelector('#more-option')) {
      if (showActionList) {
        setShowActionList(false);
      }
    }
  };

  const handleClose = (message, type) => {
    setRepaymentScheduleListModel(false);
    setTransactionHistoryListModel(false);
    setRepaymentModel(false);
    if (message) {
      showAlert(message, type);
    }
  };

  const fetchInsurance = () => {
    const payload = {
      msme_company_id: company_id,
      msme_product_id: product_id,
      user_id: user?._id,
      loan_id: loan_id,
      loan_app_id: loanDetailsData.loan_app_id,
    };
    new Promise((resolve, reject) => {
      dispatch(getInsuranceDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setInsuranceData(response?.data ?? {});
      })
      .catch((error) => {
        showAlert(error?.response?.data?.message ?? 'Technical error, please try again.', 'error');
      });
  };

  const executeInsurance = () => {
    const payload = {
      msme_company_id: company_id,
      msme_product_id: product_id,
      user_id: user?._id,
      loan_id: loan_id,
      loan_app_id: loanDetailsData.loan_app_id,
    };
    setIsLoader(true);
    new Promise((resolve, reject) => {
      dispatch(executeInsuranceDetailsWatcher(payload, resolve, reject));
    })
      .then((response) => {
        setIsLoader(false);
        setInsurancePopup(true);
        setInsurancePopupData(response);
      })
      .catch((error) => {
        setIsLoader(false);
        setInsurancePopup(true);
        setInsurancePopupData({ message: 'There has been some issue. Please try again.' });
      });
  };

  const canExecuteInsurance = () => {
    if (Number(loanDetailsData?.stage ?? 0) === 4 && loanDetailsData?.status === 'disbursed' && checkAccessTags(['tag_loan_execute_insurance'])) {
      if (insuranceData?.insurance_opted && !(insuranceData?.is_save_proposal_submitted && insuranceData?.is_buy_product_submitted && insuranceData?.is_get_insurance_doc_submitted)) {
        return true;
      }
    }
    return false;
  };

  return (
    <div style={{ margin: '24px 0px 24px 24px' }}>
      {alert ? <AlertBox severity={severity} msg={alertMessage} onClose={handleAlertClose} /> : null}
      {mainTabs.map((navMenu, index) => {
        return <TabButton label={navMenu} isDisabled={false} key={index} onClick={() => handleNavigate(navMenu)} selected={navMenu.toLowerCase() === leadDetailSection.toLowerCase()} setSelectedOption={setLeadDetailSection} />;
      })}

      {remainingTabs.length > 0 && (
        <div style={{ position: 'relative', display: 'inline' }}>
          <img
            id="more-option"
            onClick={() => {
              setShowActionList(!showActionList);
            }}
            style={styles['optionIcon']}
            src={moreSquareIcon}
            alt="Option List Icon"
            onMouseEnter={(e) => {
              e.target.style.transform = 'scale(1.1)';
            }}
            onMouseLeave={(e) => {
              e.target.style.transform = 'scale(1)';
            }}
          />

          {showActionList && (
            <div style={styles['optionList']} ref={newRef}>
              {remainingTabs.map((tab, index) => (
                <div
                  key={index}
                  className="option-item"
                  onClick={() => {
                    handleTabClick(tab);
                  }}
                  style={styles['optionListItem']}
                >
                  <p style={styles['optionListText']}>{tab}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div
        style={{
          display: leadDetailSection === 'lead details' ? 'flex' : null,
          flexDirection: leadDetailSection === 'lead details' ? 'row' : null,
        }}
      >
        {leadDetailSection === 'lead details' && <CustomAccordion accordionData={accordionData} customRightComponentContainer={{ marginTop: '20px' }} />}
        {leadDetailSection === 'lead details' && <AuditLog data={auditLogs} />}
        {leadDetailSection === 'documents' && <DocumentsList loanDocuments={loanDocuments} companyId={company_id} productId={product_id} loanAppId={loanDetailsData?.loan_app_id} fetchLoanDocuments={getLoanDocuments} isEdit={false} />}
        {leadDetailSection === 'cams' && <CamsSection leadStatus={leadStatus} companyId={company_id} productId={product_id} loanAppId={loanDetailsData?.loan_app_id} />}
        {leadDetailSection === 'loan details' && (
          <div>
            <div style={{ position: 'absolute', top: '90px', right: '30px' }}>
              <Validation isReject={isReject} setIsReject={setIsReject} rejectReason={rejectReason} setRejectReason={setRejectReason} rejectRemark={rejectRemark} setRejectRemark={setRejectRemark} data={validationData} onError={onValidationError} onSuccess={onValidationSuccess} lmsVersion={'origin_lms'} isMsme={true} />
            </div>
            <CustomAccordion accordionData={loanData} />
            {checkAccessTags(['tag_msme_loan_details_btn_reject']) && ['open', 'kyc_data_approved', 'credit_approved'].includes(validationData?.status) && (
              <Grid display={'flex'} justifyContent={'right'} alignItems={'center'} m={2}>
                <CustomButton
                  label="Reject"
                  buttonType="secondary"
                  customStyle={{ color: 'red', border: '1px solid red', height: '48px', fontSize: '16px', fontWeight: '600', padding: '12px 24px 12px 24px', width: '101px', marginRight: '0px', marginTop: '-30px', borderRadius: '30px' }}
                  onClick={() => {
                    setIsReject(true);
                  }}
                />
              </Grid>
            )}
            {canExecuteInsurance() && (
              <CustomButton
                isLoading={isLoader}
                label="Execute Insurance"
                buttonType="primary"
                customStyle={{ color: '#ffffff', height: '48px', fontSize: '16px', fontWeight: '600', padding: '12px 24px 12px 24px', marginRight: '0px', marginTop: '-20px', borderRadius: '30px' }}
                onClick={() => {
                  executeInsurance();
                }}
              />
            )}
            <>
              {insurancePopup && (
                <FormPopup
                  customStyles={{ width: '31.813rem', top: '50%' }}
                  heading="Generate Proposal"
                  onClose={() => {
                    setInsurancePopup(false);
                  }}
                  customHeaderStyle={{ paddingLeft: '0%', justifyContent: 'center' }}
                  isCancel={true}
                  isOpen={insurancePopup}
                >
                  <div className="confirmation-popup-text-style">{insurancePopupData?.message ?? 'There has been some issue. Please try again.'}</div>
                  <div style={{ display: 'flex', marginTop: '12%', marginBottom: '0%', justifyContent: 'center' }}>
                    <CustomButton
                      label="Okay"
                      buttonType="primary"
                      customStyle={{ color: '#ffffff', height: '48px', fontSize: '16px', fontWeight: '600', padding: '12px 24px 12px 24px', marginRight: '0px', marginTop: '-30px', borderRadius: '30px' }}
                      onClick={() => {
                        setInsurancePopup(false);
                      }}
                    />
                  </div>
                </FormPopup>
              )}
            </>
          </div>
        )}
        {leadDetailSection === 'sl & lba' && <SanctionLenderLead isLba={isLba} isSl={isSl} setIsLba={setIsLba} setIsSl={setIsSl} viewFile={viewFile} setViewFile={setViewFile} loanAppID={loanDetailsData?.loan_app_id} />}
        {leadDetailSection === 'nach' && <Natch />}
      </div>
      {isLoading && <Preloader />}
      {repayment && <RepaymentDemographics onModalClose={handleClose} title={companyData.lms_version === 'origin_lms' ? 'Repayment V2' : 'Repayment'} data={optionData} isOriginLms={companyData.lms_version === 'origin_lms'} product={productData} isOpen={repayment} />}
      {repaymentScheduleList && <RepaymentScheduleListDemographics onModalClose={handleClose} title="Repayment Schedule List" data={optionData} openDialog={openDialog} setOpenDialog={setOpenDialog} company={companyData} product={productData} />}
      {transactionHistoryList && <TransactionsHistoryList onModalClose={handleClose} title="Transaction History" data={optionData} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
      {refundModel ? <RefundDemographics data={optionData} openPopup={refundModel} setOpenPopup={setRefundModel} /> : null}
      {statementOfAccount ? <StatementOfAccount data={optionData} onModalClose={handleClose} openDialog={statementOfAccount} setOpenDialog={setStatementOfAccount} /> : null}
      {chargeRecordModel ? <ChargeRecordForm data={optionData} onModalClose={handleClose} openDialog={chargeRecordModel} setOpenDialog={setChargeRecordModel} company={companyData} product={productData} /> : null}
      {openStatusLogs ? <StatusLogs data={optionData} openDialog={openStatusLogs} setOpenDialog={setOpenStatusLogs} showAlert={showAlert} /> : null}
    </div>
  );
}

const useStyles = ({ innerWidth, innerHeight }) => {
  return {
    accordionHeader: {
      fontFamily: 'Montserrat-Regular',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#6B6F80',
    },
    accordionBody: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '16px',
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#151515',
    },
    optionIcon: {
      cursor: 'pointer',
      transition: 'transform 0.2s ease-in-out',
    },
    optionList: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      bottom: '-18px',
      width: '240px',
      right: 0,
      transform: 'translate(0, 100%)',
      padding: '5px 0px',
      backgroundColor: '#FEFEFE',
      border: '1px solid #EDEDED',
      borderRadius: '8px',
      boxShadow: '0px 4px 10px 0px #6F6F6F40',
      zIndex: '2',
    },
    optionListItem: {
      display: 'flex',
      padding: '10px 14px',
      aligItems: 'center',
      gap: '8px',
      alignSelf: 'stretch',
      cursor: 'pointer',
    },
    optionListText: {
      fontFamily: 'Montserrat-Medium',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      letterSpacing: '0em',
      marginBottom: 0,
      textAlign: 'left',
    },
  };
};
