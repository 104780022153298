import axios from 'axios';
import { BASE_URL } from '../constants/apiUrls';

export function fetchLocBulkUploadDataApi(payload) {
  return axios.post(`${BASE_URL}fetch-loc-drawdown-bulk-upload`, payload);
}

export function locDrawdownBulkUploadApi(payload) {
  return axios.post(`${BASE_URL}loc-drawdown-bulk-upload`, payload);
}

export function locDownloadBulkUploadApi(payload) {
  return axios.post(`${BASE_URL}download-loc-drawdown-bulk-upload-file/${payload.id}`, payload);
}

export function downloadLocBulkSampleFileApi(payload) {
  return axios.post(`${BASE_URL}download-loc-bulk-sample-file`, payload);
}
