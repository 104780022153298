export const remarkReason = {
  entity_details_correction: 'Udyam Certificate with correct name and address, other details as filled in the lead details is required',
  entity_DOI_correction: 'In Udyam Certificate the Date of Incorporation should be before or same as Date of Commencement of business',
  udhyam_certificate_correction: 'Full copy of Udyam Certificate required',
  certificate_lts_bank_statement_correction: 'Latest bank statement of full 6 months required',
  certificate_aadhar_front_correction: 'Aadhaar Front need to be uploaded again',
  certificate_aadhar_back_correction: 'Aadhaar Back need to be uploaded again',
  aadhar_validation_required: 'Aadhaar validation required OR Self attest & OSV required',
  certificate_overdue_clearance_required: 'Overdue clearance proof/Statement of Accounts required',
  certificate_name_mismatch_declaration_required: 'Name mismatch Declaration Required',
  certificate_DOB_mismatch_declaration_required: 'DOB mismatch Declaration Required',
  certificate_sales_lender_required: 'Sales Ledger required for last 2 months',
  certificate_clear_pan_required: 'Clear PAN Required',
  certificate_clear_selfie_required: 'Clear Selfie Required',
  address_correction: 'Current / Permanent / Communication Address need to Complete / Correct Update in System as per proof',
};
