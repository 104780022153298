const File_Label = {
  Upload: 'Upload',
  Change: 'Change',
};

const Field_Type = {
  Number: 'number',
  Text: 'text',
};

const Field_Label = {
  Auto_populate: '{Auto populate}',
  RESPONSE_XML_JSON: 'Response XML/JSON',
  IMAGE_PDF: 'Image & PDF',
  Pan_XML: 'Pan_XML',
  Pan_1: 'Pan_1',
  Aadhar_XML: 'Aadhar_XML',
  aadhar_front: 'aadhar_front',
  Aadhar_back: 'Aadhar_back',
  Guarantor_success_message: 'Guarantor details added successfully',
  Tab_Lead_Details: 'Lead Details',
  Tab_Documents: 'Documents',
  Tab_Cams: 'Cams',
  Individual: 'individual',
  Current_Address: 'current address',
  Communication_Address: 'communication address',
  PAN_FILE_TYPE: 'panFileType',
  AADHAAR_FILE_TYPE: 'aadhaarFileType',
  Offer_Rejected: 'Offer Rejected',
  Offer_Rejection_Appologize: 'We are sorry to inform that this application is rejected',
  APPROVE_CONFIRMATION_POPUP: 'This is the final confirmation. Post acceptance you can only decrease the loan amount,Tenure,ROI cannot be changed. Please take decision carefully.',
  REJECT_CONFIRMATION_POPUP: 'Are you sure, do you want to reject the offer?',
  CANCEL: 'Cancel',
  RESET: 'Reset',
  SUBMIT: 'Submit',
  REQUEST_AN_UPDATE: 'Request an Update',
  AMEND: 'Amend',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  ACCEPT: 'Accept',
  YES: 'Yes',
  NO: 'No',
  SOFT_OFFER_GENERATED: 'Soft Offer Generated',
  MARK_FINAL_OFFER: 'Mark This As Final Offer',
  BACK_TO_LOAN_DETAILS: 'Back to Loan Details',
  ACCEPT_OFFER: 'Accept Offer?',
  REJECT_OFFER: 'Reject Offer?',
  VALIDATION_IN_PROGRESS: 'Validation In-Progress',
  ACTION_POPUP_CONTENT_TYPE_REJECT: 'reject',
  GSTIN: 'GSTIN',
  Shop_Establishment_cert: 'Shop Establishment Certificate',
};

const USER_ROLES = {
  CREDIT: 'CREDIT',
  PARTNER: 'PARTNER',
  RISK: 'RISK',
};

const LEAD_INFO_MESSAGES = {
  RISK: 'Application is in review with Risk team',
  RISK_AND_CREDIT: 'Application is in review with Risk team. Please review the application once risk has approved the offer',
  RISK_RESOLVED: 'Risk has approved the offer',
  PARTNER_UPLOADED_DOCS: 'Partner has updated the required documents',
  RIKS_USER_DEVIATION: 'Deviation is raised. Please review the application',
  CREDIT_USER_DEVIATION: 'Deviation is raised. Please review the application',
  OFFER_GENERATED: 'Offer Generated.',
};

const Error_Message = {
  Technical_Error: 'Technical error, please try again.',
  Something_Wrong: 'Something went wrong',
};

const Success_Message = {
  Pan_Success: 'PAN verified successfully',
  Lead_Submit: 'Lead submitted successfully',
};
const Lap_Screens = {
  Guarantor_Details: 'Guarantor Details',
  Share_Holding_pattern: 'Share Holding Pattern',
  Property_Details: 'Property Details',
};

const Application_Mode = {
  View: 'view',
  Edit: 'edit',
  Change: 'Change',
  Views: 'View',
};

const Additional_Docs_Label = {
  Upload_Doc_Label: 'Upload additional documents',
  Select_Document_Label: 'Select Document',
};

const Financial_Docs_Label = {
  Upload_Doc_Label: 'Upload financial documents',
};

const Shareholding_Label = {
  header_label: 'Shareholding pattern',
};

const Saga_Action_Type = {
  Fetch_Lap_Loan_Document: 'FETCH_LAP_LOAN_DOCUMENT',
  Get_Lap_Lead_Section_Status_For_Lapid: 'GET_LAP_LEAD_SECTION_STATUS_FOR_LAPID',
  Post_Lap_Section_Status: 'POST_LAP_SECTION_STATUS',
};

const Multi_Values_Section = ['guarantor', 'co_borrower'];

const Comment = {
  Comment_Data: '(Max: 200 Characters)',
};

const Alert_Type = {
  Error: 'error',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
};

const Section_Status_Type = {
  Success: 'success',
  Deviation: 'deviation',
};
const Section_Status = {
  Approved: 'approved',
  In_Review: 'in_review',
  Rejected: 'rejected',
  In_Progress: 'in_progress',
  PENDING: 'pending',
  NOT_APPLICABLE: 'not_applicable',
  YET_TO_START: 'yet_to_start',
};
const LEAD_STATUS = {
  OFFER_GENERATED: 'offer_generated',
};
const Sub_Section_Code = {
  Guarantor: {
    Guarantor_Pan: 'guarantor_pan',
    Guarantor_Bureau: 'guarantor_bureau',
    Guarantor_Section_Submit: 'guarantor_section_submit',
  },
};
const Alert_Title = {
  [Alert_Type.Error]: 'Error',
  [Alert_Type.Info]: 'Info',
  [Alert_Type.Success]: 'Successful',
  [Alert_Type.Warning]: 'Warning',
};
const shareHoldingPattern = 'shareholding_pattern';

const Document_Sections = {
  Applicant: 'Applicant',
  Guarantor: 'Guarantor',
  CoBorrower: 'Co-borrower',
  Guarantor: 'Guarantor',
  Shareholding: 'Shareholding',
  Property: 'Property',
  Entity: 'Entity',
  Financial: 'Financial',
  AdditionalDocuments: 'Additional Documents',
  Property: 'Property',
};

const Verify_Status = {
  Icon: 'icon',
};

const Section_Status_Name = {
  Primary_Applicant: 'Primary Applicant',
  Entity_Details: 'Entity Details',
  Property_Details: 'Property details',
  Financial_Documents: 'Financial Document',
  Share_Holding_Details: 'share-holding-details',
  Additional_Document: 'Additional Document',
  Property_Documents: 'property-documents',
};
const AUDIT_LOG_KEYS = {
  CATEGORY: 'category',
};

const Entity_Type = {
  Individual: 'Individual',
  Proprietor: 'Proprietor',
  PrivateLimited: 'Private Limited',
  Partnership: 'Partnership',
};

const Export_Type = {
  XLSX: 'xlsx',
  CSV: 'csv',
};

module.exports = {
  File_Label,
  Application_Mode,
  Lap_Screens,
  Field_Type,
  Field_Label,
  Saga_Action_Type,
  Alert_Type,
  Alert_Title,
  Additional_Docs_Label,
  Section_Status_Type,
  Section_Status,
  Sub_Section_Code,
  Error_Message,
  Success_Message,
  shareHoldingPattern,
  Document_Sections,
  Financial_Docs_Label,
  Shareholding_Label,
  Comment,
  Verify_Status,
  Multi_Values_Section,
  Section_Status_Name,
  AUDIT_LOG_KEYS,
  Entity_Type,
  Export_Type,
  LEAD_STATUS,
  USER_ROLES,
  LEAD_INFO_MESSAGES,
};
