import React from 'react';

export const loanDetailsJson = () => [
  {
    title: 'First Name',
    name: 'first_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Last Name',
    name: 'last_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Type of addr',
    name: 'type_of_addr',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Resi addr ln1',
    name: 'resi_addr_ln1',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'City',
    name: 'city',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'State',
    name: 'state',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Pincode',
    name: 'pincode',
    presentInLoanApi: 'No',
    Type: 'pincode',
    Readonly: true,
  },
  {
    title: 'Appl Pan',
    name: 'appl_pan',
    presentInLoanApi: 'No',
    Type: 'pan',
    Readonly: true,
  },
  {
    title: 'Dob',
    name: 'dob',
    presentInLoanApi: 'No',
    Type: 'dob',
    Readonly: true,
  },
  {
    title: 'Age',
    name: 'Age',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Father Name',
    name: 'father_fname',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'CKYC NUMBER',
    name: 'ckyc_number',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Loan id',
    name: 'loan_id',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Arthmate Sanctioned Amount',
    name: 'sanction_amount',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'CBI Sanctioned Amount',
    name: 'cbi_sanctioned_amount',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Arthmate Approval Date',
    name: 'final_approve_date',
    presentInLoanApi: 'No',
    Type: 'dateTime',
    Readonly: true,
  },
  {
    title: 'Loan app id',
    name: 'loan_app_id',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Borrower id',
    name: 'borrower_id',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Loan app date',
    name: 'loan_app_date',
    presentInLoanApi: 'No',
    Type: 'dateTime',
    Readonly: true,
  },
  {
    title: 'Processing fees amt',
    name: 'processing_fees_amt',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Gst on pf amt',
    name: 'gst_on_pf_amt',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Net disbur amt',
    name: 'net_disbur_amt',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Int type',
    name: 'int_type',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Loan int rate',
    name: 'loan_int_rate',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Repayment type',
    name: 'repayment_type',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Tenure type',
    name: 'tenure_type',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Tenure',
    name: 'tenure',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'First inst date',
    name: 'first_inst_date',
    presentInLoanApi: 'No',
    Type: 'dateTime',
    Readonly: true,
  },
  {
    title: 'Emi count',
    name: 'emi_count',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Final remarks',
    name: 'final_remarks',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Business name',
    name: 'business_name',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Business address',
    name: 'business_address',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Business city',
    name: 'business_city',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Business state',
    name: 'business_state',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Business pin code',
    name: 'business_pin_code',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Constitution',
    name: 'business_entity_type',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Business address ownership',
    name: 'business_address_ownership',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Program type',
    name: 'program_type',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Nature of business',
    name: 'nature_of_business',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Business pan',
    name: 'business_pan',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Gst number',
    name: 'gst_number',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Gst verification response',
    name: 'gst_verification_response',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Udyam reg no',
    name: 'udyam_reg_no',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Business vintage overall',
    name: 'business_vintage_overall',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Business establishment proof type',
    name: 'business_establishment_proof_type',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'MSME CBR RANK()',
    name: 'commercial_bureau_rank',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Bureau type',
    name: 'bureau_type',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
  {
    title: 'Bureau score',
    name: 'bureau_score',
    presentInLoanApi: 'No',
    Type: 'string',
    Readonly: true,
  },
];
